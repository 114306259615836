// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddButton from 'Ui/components/settings/common/add-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {deleteDealership} from 'Services/dealership-service';
import {getMembershipHierarchyDealerships} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import dialogTypes from 'Ui/components/common/dialog-types';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';

function formatAddress({
    city,
    postalCode,
    state
}) {
    let address = '';

    if (city) {
        address += city;
    }

    if (state) {
        address += city ? `, ${state}` : state;
    }

    if (postalCode) {
        address += city || state ? ` ${postalCode}` : postalCode;
    }

    return address;
}

function formatDealershipAddressElements(dealership) {
    const {
        addressElements,
        city,
        state,
        zip
    } = dealership;

    if (addressElements) {
        const addressElementsByClass = addressElements.reduce((addressElementsMap, addressElement) => {
            addressElementsMap.set(addressElement.elementClass, addressElement.elementValue);

            return addressElementsMap;
        }, new Map());

        return formatAddress({
            city: addressElementsByClass.get('city'),
            postalCode: addressElementsByClass.get('postalCode'),
            state: addressElementsByClass.get('state')
        });
    }

    return formatAddress({
        city,
        postalCode: zip,
        state
    });
}

function getColumns(translations, openDealershipDialog, fetchDealerships) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openDealershipDialog({
                            dealership: original,
                            fetchDealerships,
                            translations
                        })}
                    >
                        {value}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_LOCATION,
            accessor: 'formattedAddress'
        },
        {
            Header: translations.my_team_phone,
            accessor: 'phone',
            width: 130
        },
        {
            Header: translations.my_team_email,
            accessor: 'email',
            width: 190
        }
    ];
}

function getFilterComponent(translations, openDealershipDialog, fetchDealerships) {
    return (
        <div className='extra-table-options'>
            <AddButton
                label={replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                    '0': translations.ONLINK_DEALERSHIP
                })}
                onClick={() => openDealershipDialog({
                    dealership: null,
                    fetchDealerships,
                    translations
                })}
            />
        </div>
    );
}

function DealershipSettings(props) {
    const {
        addToast,
        closeConfirmation,
        membershipId,
        openConfirmation,
        openDealershipDialog,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [hierarchyDealerships, setHierarchyDealerships] = React.useState([]);

    async function fetchDealerships(isMounted = () => true) {
        setLoading(true);

        const {dealerships} = await getMembershipHierarchyDealerships(membershipId);

        if (isMounted()) {
            const transformedDealerships = dealerships.map((dealership) => ({
                ...dealership,
                formattedAddress: formatDealershipAddressElements(dealership)
            }));

            setHierarchyDealerships(transformedDealerships);
            setLoading(false);
        }
    }

    function deleteDealershipHandler(dealership) {
        const lowerCaseDealership = translations.ONLINK_DEALERSHIP.toLowerCase();

        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': lowerCaseDealership
            }),
            onCancel: closeConfirmation,
            showLoadingIcon: true,
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_DEALERSHIP
            }),
            async onContinue() {
                try {
                    await deleteDealership(dealership.membershipId);
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': lowerCaseDealership
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }

                closeConfirmation();
                fetchDealerships();
            }
        });
    }

    React.useEffect(() => fetchEffectData(fetchDealerships), []);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, openDealershipDialog, fetchDealerships)}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                deleteHandler={deleteDealershipHandler}
                filterComponent={getFilterComponent(translations, openDealershipDialog, fetchDealerships)}
                loading={loading}
                noDataAvailableMessage={translations.ONLINK_NO_DEALERSHIPS_FOUND}
                rows={hierarchyDealerships}
                searchable={true}
                showDeleteColumn={isAuthorized({
                    myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                }, props)}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

DealershipSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openDealershipDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        myJdPermissions: state.account.myJdPermissions,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openDealershipDialog(props) {
            dispatch(openDialog(dialogTypes.DEALERSHIP_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealershipSettings);
