// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function DetailsBlock(props) {
    const {
        children,
        label
    } = props;

    return (
        <div className='job-details-block'>
            {
                label &&
                <div className='job-details-label'>
                    {`${label}`}
                </div>
            }
            <div className='job-details-content'>
                {children}
            </div>
        </div>
    );
}

DetailsBlock.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string
};

export default DetailsBlock;
