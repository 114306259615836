// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';

function getTabs(tabs, activeTab, setActiveTab) {
    let activeTabElement = null;

    const tabComponents = tabs.map((tab) => {
        const active = tab.name === activeTab;

        if (active) {
            activeTabElement = tab.element;
        }

        const className = clsx('tab-header title-1', {
            active
        });

        return (
            <div
                className={className}
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
            >
                {tab.title}
            </div>
        );
    });

    return {
        activeTabElement,
        tabComponents
    };
}

function MobileNavTabs(props) {
    const {
        initialActiveTab,
        tabs
    } = props;

    const initialTab = initialActiveTab || tabs.length > 0 && tabs[0].name;
    const [activeTab, setActiveTab] = React.useState(() => initialTab);

    if (tabs.length === 0) {
        return null;
    }

    const {
        activeTabElement,
        tabComponents
    } = getTabs(tabs, activeTab, setActiveTab);

    return (
        <>
            <div className='tab-header-container'>
                {tabComponents}
            </div>
            {activeTabElement}
        </>
    );
}

MobileNavTabs.defaultProps = {
    tabs: []
};

MobileNavTabs.propTypes = {
    initialActiveTab: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        element: PropTypes.node,
        name: PropTypes.string,
        title: PropTypes.string
    }))
};

export default MobileNavTabs;
