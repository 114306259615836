// Unpublished Work © 2022-2024 Deere & Company.

export default {
    ordered: {
        color: 'green'
    },
    pending: {
        color: 'orange'
    },
    received: {
        color: 'green'
    }
};
