// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {IconHddocPlay, IconPause} from '@deere/icons';
import {NUM_DELTAS} from 'Ui/constants/map-constants';

const MS_PER_FRAME_FOR_TWENTY_FPS = 50;
const ICON_STYLE = {
    style: {
        width: '24px',
        height: '24px',
        fill: '#fff'
    }
};
const ICON_PLAY_STYLE = createIconFillStyle('#fff');

function calculateDeltaLatAndLon(playbackVariables) {
    const {
        reducedBreadcrumbs,
        playbackRef
    } = playbackVariables;

    const currentBreadcrumb = reducedBreadcrumbs[playbackRef.current.rangeIndex];
    const nextBreadcrumb = reducedBreadcrumbs[playbackRef.current.rangeIndex + 1];

    playbackVariables.deltaLat = (Number(nextBreadcrumb.lat) - Number(currentBreadcrumb.lat)) / NUM_DELTAS;
    playbackVariables.deltaLon = (Number(nextBreadcrumb.lon) - Number(currentBreadcrumb.lon)) / NUM_DELTAS;
    playbackVariables.lat = currentBreadcrumb.lat;
    playbackVariables.lon = currentBreadcrumb.lon;
    playbackVariables.currentBreadcrumb = currentBreadcrumb;
    playbackVariables.nextBreadcrumb = nextBreadcrumb;
}

function resetPlayback(playbackVariables) {
    const {
        reducedBreadcrumbs,
        playbackRef,
        setFunctions
    } = playbackVariables;

    if (reducedBreadcrumbs.length - 1 <= playbackRef.current.rangeIndex) {
        playbackRef.current.rangeIndex = 0;
        setFunctions.setRangeIndex(0);
        setFunctions.updateMapMarker(reducedBreadcrumbs[0]);
    }

    calculateDeltaLatAndLon(playbackVariables);
}

function cleanupBreadCrumbTransition(playbackVariables) {
    const {
        nextBreadcrumb,
        playbackRef,
        setFunctions,
        startOfLoopIndex
    } = playbackVariables;

    playbackRef.current.rangeIndex += 1;
    playbackRef.current.breadcrumbTransitionProgress = 0;

    if (playbackRef.current.rangeIndex === startOfLoopIndex) {
        playbackRef.current.isPlaying = false;
    }

    setFunctions.setRangeIndex(playbackRef.current.rangeIndex);
    setFunctions.updateMapMarker(nextBreadcrumb);
}

function updateMarkerInTransition(playbackVariables) {
    const {
        currentBreadcrumb,
        playbackRef,
        setFunctions
    } = playbackVariables;

    playbackRef.current.breadcrumbTransitionProgress += 1;

    playbackVariables.lat = Number(playbackVariables.lat) + playbackVariables.deltaLat;
    playbackVariables.lon = Number(playbackVariables.lon) + playbackVariables.deltaLon;

    setFunctions.updateMapMarker({
        ...currentBreadcrumb,
        lat: playbackVariables.lat,
        lon: playbackVariables.lon
    });
}

function smoothMarkerTransition(playbackVariables) {
    const {
        playbackRef,
        setFunctions
    } = playbackVariables;

    if (playbackRef.current.breadcrumbTransitionProgress < NUM_DELTAS && playbackRef.current.isPlaying) {
        updateMarkerInTransition(playbackVariables);

        const timeoutId = setTimeout(() => smoothMarkerTransition(playbackVariables), MS_PER_FRAME_FOR_TWENTY_FPS);

        setFunctions.setTimeoutId(timeoutId);
    } else if (playbackRef.current.isPlaying) {
        cleanupBreadCrumbTransition(playbackVariables);

        if (!playbackRef.current.isPlaying) {
            return;
        }

        resetPlayback(playbackVariables);

        smoothMarkerTransition(playbackVariables);
    }
}

function PlayPauseButtons(props) {
    const {
        updateMapMarker,
        reducedBreadcrumbs,
        setRangeIndex,
        playbackRef
    } = props;

    const [timeoutId, setTimeoutId] = React.useState(() => 0);

    React.useEffect(() => {
        return () => {
            playbackRef.current.isPlaying = false;
            clearTimeout(timeoutId);
        };
    }, []);

    function handlePlayBackClick() {
        playbackRef.current.isPlaying = true;
        playbackRef.current.lastLoop = false;

        const playbackVariables = {
            deltaLat: null,
            deltaLon: null,
            lat: null,
            lon: null,
            currentBreadcrumb: null,
            nextBreadcrumb: null,
            reducedBreadcrumbs,
            playbackRef,
            startOfLoopIndex: playbackRef.current.rangeIndex,
            setFunctions: {
                setRangeIndex,
                updateMapMarker,
                setTimeoutId
            }
        };

        resetPlayback(playbackVariables);

        smoothMarkerTransition(playbackVariables);
    }

    const iconPlay = (
        <OnLinkIconButton
            className='playback-play-button'
            onClick={handlePlayBackClick}
        >
            <IconHddocPlay
                iconHddocPlay={ICON_STYLE}
                primary={ICON_PLAY_STYLE}
            />
        </OnLinkIconButton>
    );

    const iconPause = (<OnLinkIconButton
        className='playback-pause-button'
        onClick={() => {
            playbackRef.current.isPlaying = false;
            setTimeoutId(0);
        }}
    >
        <IconPause iconPause={ICON_STYLE}/>
    </OnLinkIconButton>);

    const IconButton = playbackRef.current.isPlaying ? iconPause : iconPlay;

    return (
        IconButton
    );
}

PlayPauseButtons.propTypes = {
    playbackRef: PropTypes.reference,
    reducedBreadcrumbs: PropTypes.arrayOf(PropTypes.shape()),
    setRangeIndex: PropTypes.func,
    updateMapMarker: PropTypes.func
};

export default PlayPauseButtons;
