// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Icon} from '@deere/icons';

const GRAY_FILL = {
    fill: '#767676'
};

function ItemContent(props) {
    const {
        description,
        iconName,
        label,
        leftIcon,
        rightIcon
    } = props;

    const itemIcon = React.useMemo(() => iconName ? (
        <Icon
            height={32}
            iconName={iconName}
            iconStyles={{
                icon: GRAY_FILL,
                primary: GRAY_FILL
            }}
            width={32}
        />
    ) : null, [iconName]);

    const itemText = React.useMemo(() => description || label ? (
        <div className='label-container opener'>
            {
                label &&
                <span className='label'>{label}</span>
            }
            {
                description &&
                <p className='description'>{description}</p>
            }
        </div>
    ) : null, [description, label]);

    return (
        <>
            {leftIcon}
            {itemIcon}
            {itemText}
            {rightIcon}
        </>
    );
}

ItemContent.propTypes = {
    description: PropTypes.node,
    iconName: PropTypes.string,
    label: PropTypes.node,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node
};

export default ItemContent;
