// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Polyline} from '@react-google-maps/api';
import {MACHINE_STATUS_POLYLINE_COLORS} from 'Ui/constants/map-constants';

const POLYLINE_OPTIONS = {
    strokeOpacity: 1.0,
    strokeWeight: 2
};

function MachineStatePolylines({polylines}) {
    return polylines.map(({
        path, type
    }, i) =>
        (<Polyline
            key={i}
            options={{
                ...POLYLINE_OPTIONS,
                strokeColor: MACHINE_STATUS_POLYLINE_COLORS[type],
                zIndex: i
            }}
            path={path}
        />)
    );
}

MachineStatePolylines.propTypes = {
    polylines: PropTypes.arrayOf(PropTypes.shape())
};

export default MachineStatePolylines;
