// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {MAX_ENGINE_HOURS} from 'Ui/constants/equipment-constants';
import equipmentStatusConstants from 'Ui/constants/equipment-status-constants';

function getAvailableStatuses(translations) {
    return Object.keys(equipmentStatusConstants).map((equipmentStatus) => {
        const statusConstant = equipmentStatusConstants[equipmentStatus];
        const statusLabel = translations[statusConstant.label] || statusConstant.label;

        return {
            id: equipmentStatus,
            title: statusLabel
        };
    });
}

function mapEquipmentArea(equipmentArea) {
    return {
        id: equipmentArea.equipmentAreaId,
        title: equipmentArea.name
    };
}

function CheckEquipmentDialogForm(props) {
    const {
        equipment,
        equipmentAreaId,
        equipmentAreas,
        note,
        saveFunc,
        setEquipmentAreaId,
        setNote,
        setStatus,
        setTotalHours,
        setValid,
        status,
        totalHours,
        translations
    } = props;

    const availableStatuses = getAvailableStatuses(translations);
    const noneText = capitalizeFirstLetter(translations.NONE);
    const availableEquipmentAreas = [
        {
            id: undefined,
            title: noneText
        },
        ...equipmentAreas.map(mapEquipmentArea)
    ];
    const lastMeterHours = isNullOrUndefined(equipment.lastMeterHours) ?
        '' :
        formatNumber(equipment.lastMeterHours, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1
        });

    const valueGreaterThanOrEqual = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    });

    return (
        <form
            className='equipment-dialog-form'
            onSubmit={saveFunc}
        >
            <div className='settings-group mobile'>
                <ValidationMultiSelect
                    className='multiselect'
                    defaultSelectLabel={translations.ONLINK_SELECT_EQUIPMENT_STATUS}
                    error={translations.REQUIRED_FIELD_TEXT}
                    hideFilter={true}
                    items={availableStatuses}
                    label={translations.STATUS}
                    multiple={false}
                    name='status'
                    onChange={(ids) => setStatus(ids[0])}
                    selectedIds={[status]}
                    setValid={setValid}
                    tabIndex={0}
                />
                <MultiSelect
                    className='multiselect'
                    defaultSelectLabel={translations.NONE}
                    hideFilter={true}
                    items={availableEquipmentAreas}
                    label={translations.AREA}
                    multiple={false}
                    onChange={(ids) => setEquipmentAreaId(ids[0])}
                    selectedIds={[equipmentAreaId]}
                    tabIndex={0}
                />
            </div>
            <ValidationInput
                debounceTimeout={window.props.debounceTimeout}
                errors={{
                    rangeUnderflow: valueGreaterThanOrEqual
                }}
                label={translations.HOURS}
                max={MAX_ENGINE_HOURS}
                min={0}
                name='totalHours'
                onChange={(e) => setTotalHours(e.target.value)}
                placeholder={lastMeterHours}
                setValid={setValid}
                step='any'
                tabIndex={0}
                type='number'
                value={totalHours}
            />
            {
                (equipment.lastReportedHedId || equipment.lastReportedMtgId) &&
                <div className='footnote'>
                    {translations.ONLINK_TELEMATICALLY_ENABLED_MESSAGE}
                </div>
            }
            <TextArea
                allowResize={true}
                debounceTimeout={window.props.debounceTimeout}
                label={translations.NOTE}
                name='note'
                onChange={(e) => setNote(e.target.value)}
                rows={3}
                tabIndex={0}
                value={note}
            />
        </form>
    );
}

CheckEquipmentDialogForm.propTypes = {
    equipment: PropTypes.equipment,
    equipmentAreaId: PropTypes.string,
    equipmentAreas: PropTypes.arrayOf(PropTypes.object),
    note: PropTypes.string,
    saveFunc: PropTypes.func,
    setEquipmentAreaId: PropTypes.func,
    setNote: PropTypes.func,
    setStatus: PropTypes.func,
    setTotalHours: PropTypes.func,
    setValid: PropTypes.func,
    status: PropTypes.string,
    totalHours: PropTypes.stringOrNumber,
    translations: PropTypes.translations
};

export default CheckEquipmentDialogForm;
