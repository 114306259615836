// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import {formatNumberWithUnit} from 'Utils/data-utils';
import {formatLocalizedTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {DEGREES_F, MILES_PER_HOUR} from 'Common/constants/data-unit-constants';
import {convertToFormattedUoM, convertToUoM} from 'Utils/unit-conversion-utils';
import moment from 'moment';

function mapForecastData(data, tempConversionConfig, windConversionConfig) {
    const temperature = data.temp ? convertToFormattedUoM(data.temp, tempConversionConfig) : ' ';
    const windSpeed = data.wspd ? Math.round(convertToUoM(data.wspd, windConversionConfig)) : ' ';

    return [
        formatLocalizedTime(moment(data.fcst_valid_local), TIME_UNIT_OPTIONS.minute),
        <img
            alt={data.phrase_22char}
            className='dashboard-panel-icon'
            src={getWeatherIcon(data.icon_code)}
        />,
        temperature,
        formatNumberWithUnit(data.pop, 'percent', 0),
        formatNumberWithUnit(data.rh, 'percent', 0),
        `${data.wdir_cardinal} ${windSpeed}`
    ];
}

function HourlyForecast(props) {
    const {
        featureToggles,
        forecastData,
        translations,
        unitOfMeasure,
        windSpeedUnitOfMeasure
    } = props;

    const headers = [
        translations.TIME,
        '',
        translations.ONLINK_TEMP,
        translations.ONLINK_PRECIP,
        translations.ONLINK_HUMID,
        translations.weather_WIND
    ];

    const conversionConfig = {
        featureToggles,
        translations,
        unitOfMeasure
    };

    const tempConversionConfig = {
        ...conversionConfig,
        dataUnit: DEGREES_F
    };

    const windConversionConfig = {
        ...conversionConfig,
        unitOfMeasure: windSpeedUnitOfMeasure,
        dataUnit: MILES_PER_HOUR
    };

    const rows = forecastData.map((data) => mapForecastData(data, tempConversionConfig, windConversionConfig));

    return (
        <BasicTable
            className='forecast-table hourly'
            headers={headers}
            rows={rows}
            title={`${translations.NEXT} ${rows.length} ${translations.HOURS}`}
        />
    );
}

HourlyForecast.propTypes = {
    featureToggles: PropTypes.featureToggles,
    forecastData: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    windSpeedUnitOfMeasure: PropTypes.string
};

export default HourlyForecast;
