// Unpublished Work © 2023-2024 Deere & Company.

import {FUEL, GAS, TOTAL_WATER_IRRIGATION, WATER_FLOW, WATER_TRANSFER} from 'Common/constants/data-group-constants';
import {
    DEGREES_C, DEGREES_F, FEET, FEET_AND_INCHES, FEET_AND_INCHES_GRAPH,
    GALLONS, INCHES, INCHES_WEATHER, KILOGRAM, KILOMETERS_PER_HOUR,
    LITERS, METERS, MILES_PER_HOUR, MILLIMETERS, OUNCES, POUNDS, QUARTS
} from 'Common/constants/data-unit-constants';
import {CONVERSION_UNIT, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {UNIT_CONFIG, UNITS} from 'Common/constants/units/unit-config-constants';

function createUnitConfig(unit, formatting) {
    return {
        formatting,
        unit
    };
}

const FAHRENHEIT_TO_CELSIUS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.FAHRENHEIT, {
        suffix: UNITS.fahrenheit
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.CELSIUS, {
        suffix: UNITS.celsius
    })
};

const FEET_TO_METERS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.FEET, {
        decimalPlaces: 2,
        suffix: UNITS.feet
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.METERS, UNIT_CONFIG.METERS)
};

const FEET_AND_INCHES_TO_METERS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.FEET, UNIT_CONFIG.FEET_AND_INCHES),
    [UNIT_OF_MEASURE.METRIC]: FEET_TO_METERS_CONVERSION[UNIT_OF_MEASURE.METRIC]
};

const INCHES_TO_MILLIMETERS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.INCHES, {
        decimalPlaces: 3,
        suffix: UNITS.inches
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.MILLIMETER, {
        decimalPlaces: 1,
        includeSpace: true,
        suffix: UNITS.mm
    })
};

const POUNDS_TO_KILOGRAMS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.LB, {
        decimalPlaces: 2,
        includeSpace: true,
        suffix: 'lb'
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.KG, {
        decimalPlaces: 2,
        includeSpace: true,
        suffix: 'kg'
    })
};

const QUARTS_TO_LITERS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.QT, {
        decimalPlaces: 1,
        includeSpace: true,
        suffix: UNITS.quarts
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.L, {
        decimalPlaces: 2,
        includeSpace: true,
        suffix: UNITS.liters
    })
};

const GALLONS_TO_LITERS_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.GAL, {
        decimalPlaces: 2,
        includeSpace: true,
        suffix: UNITS.gallons
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.L, {
        decimalPlaces: 2,
        includeSpace: true,
        suffix: UNITS.liters
    })
};

const MPH_TO_KPH_CONVERSION = {
    [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.MPH, {
        includeSpace: true,
        suffix: 'mph'
    }),
    [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.KPH, {
        includeSpace: true,
        suffix: 'kph'
    })
};

export const UNIT_CONVERSION_CONFIG = {
    [DEGREES_C]: FAHRENHEIT_TO_CELSIUS_CONVERSION,
    [DEGREES_F]: FAHRENHEIT_TO_CELSIUS_CONVERSION,
    [FEET]: FEET_TO_METERS_CONVERSION,
    [FEET_AND_INCHES]: FEET_AND_INCHES_TO_METERS_CONVERSION,
    [FEET_AND_INCHES_GRAPH]: {
        ...FEET_AND_INCHES_TO_METERS_CONVERSION,
        [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.FEET, UNIT_CONFIG.FEET_AND_INCHES_GRAPH)
    },
    [GALLONS]: GALLONS_TO_LITERS_CONVERSION,
    [INCHES]: INCHES_TO_MILLIMETERS_CONVERSION,
    [INCHES_WEATHER]: {
        ...INCHES_TO_MILLIMETERS_CONVERSION,
        [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.INCHES, {
            decimalPlaces: 2,
            suffix: UNITS.inches
        })
    },
    [KILOGRAM]: POUNDS_TO_KILOGRAMS_CONVERSION,
    [KILOMETERS_PER_HOUR]: MPH_TO_KPH_CONVERSION,
    [LITERS]: {
        ...QUARTS_TO_LITERS_CONVERSION,
        [FUEL]: GALLONS_TO_LITERS_CONVERSION,
        [GAS]: GALLONS_TO_LITERS_CONVERSION,
        [TOTAL_WATER_IRRIGATION]: GALLONS_TO_LITERS_CONVERSION,
        [WATER_FLOW]: GALLONS_TO_LITERS_CONVERSION,
        [WATER_TRANSFER]: GALLONS_TO_LITERS_CONVERSION
    },
    [METERS]: FEET_TO_METERS_CONVERSION,
    [MILES_PER_HOUR]: MPH_TO_KPH_CONVERSION,
    [MILLIMETERS]: INCHES_TO_MILLIMETERS_CONVERSION,
    [OUNCES]: {
        [UNIT_OF_MEASURE.ENGLISH]: createUnitConfig(CONVERSION_UNIT.FLOZ, {
            decimalPlaces: 2,
            includeSpace: true,
            suffix: UNITS.ounces
        }),
        [UNIT_OF_MEASURE.METRIC]: createUnitConfig(CONVERSION_UNIT.L, {
            decimalPlaces: 3,
            includeSpace: true,
            suffix: UNITS.liters
        })
    },
    [POUNDS]: POUNDS_TO_KILOGRAMS_CONVERSION,
    [QUARTS]: QUARTS_TO_LITERS_CONVERSION
};
