// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function LinkProfileCard(props) {
    const {
        children,
        organization,
        translations,
        username
    } = props;

    return (
        <div className='link-user-card'>
            {children}
            <div className='link-user-info'>
                <div className='link-info-row'>
                    <div>{translations.find_customer_results_username}</div>
                    <div className='link-user-bold'>{username}</div>
                </div>
                {
                    organization.label && organization.name &&
                    <div className='link-info-row'>
                        <div>{organization.label}</div>
                        <div className='link-user-bold'>{organization.name}</div>
                    </div>
                }
            </div>
        </div>
    );
}

LinkProfileCard.defaultProps = {
    organization: {}
};

LinkProfileCard.propTypes = {
    children: PropTypes.node,
    organization: PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string
    }),
    translations: PropTypes.translations,
    username: PropTypes.string
};

export default LinkProfileCard;
