// Unpublished Work © 2023-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createDealership(fleetId, dealershipData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post(`/api/fleets/${fleetId}/dealerships`, dealershipData);

    return response.data;
}

async function updateDealership(dealershipId, dealershipData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/dealerships/${dealershipId}`, dealershipData);

    return response.data;
}

async function deleteDealership(dealershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/dealerships/${dealershipId}`);

    return response.data;
}

export {
    createDealership,
    updateDealership,
    deleteDealership
};
