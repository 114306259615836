// Unpublished Work © 2023-2024 Deere & Company.

import {cloneDeep, isEqual, sortBy} from 'lodash';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {CUSTOM_ROUTE} from 'Ui/constants/workboard-constants';

// constants passed to GridLayouts from job-assignments-form.js & job-item.js
const JOB_ROW_HEIGHT = 115;
const WORK_ITEM_ROW_HEIGHT = 50;

const JOB_BASE_PIXELS = 86;
const Y_MARGIN = 10;
const ADD_OPERATOR_PIXELS = 34;

// actual pixel values of a workItemInput with no extra rows minus note height
const WORKBOARD_REFACTOR_BASE_PIXELS = 182.03;
const NO_TOGGLE_BASE_PIXELS = 362.28;
const READONLY_BASE_PIXELS = 81;
const READONLY_MOBILE_BASE_PIXELS = 231;

// pixel values shared between views
const COLLAPSED_WORKITEM_ASSIGNED_EQUIPMENT = 50;
const COLLAPSED_WORKITEM_UNASSIGNED_EQUIPMENT = 70;
const EXTRA_ROW = 66.02;
const READONLY_EXTRA_ROW = 51;

// est/act hours inputs are slightly taller than other inputs, so that row is misaligned
// need to account for that when calculating the height of workItems with extra rows
const MISALIGNED_INPUT_PIXELS = 12;

// algebra'ed from https://github.com/react-grid-layout/react-grid-layout#grid-item-heights-and-widths
function convertPixelsToWorkItemGridUnits(pixels) {
    return (Y_MARGIN + pixels) / (Y_MARGIN + WORK_ITEM_ROW_HEIGHT);
}

function convertPixelsToJobItemGridUnits(pixels) {
    return (Y_MARGIN + pixels) / (Y_MARGIN + JOB_ROW_HEIGHT);
}

function sortBySeq(items, property) {
    const sortedItems = sortBy(items, 'seq');

    return sortedItems.map((item) => item[property]);
}

function getLayoutOrder(currentLayout) {
    const sortedLayout = sortBy(currentLayout, 'y');

    return sortedLayout.map(({i}) => i);
}

function checkLayoutUnchanged(orderRef, updatedOrder) {
    if (isEqual(orderRef.current, updatedOrder)) {
        return true;
    }

    orderRef.current = updatedOrder;

    return false;
}

function getBasePixels(isMobile, isWorkboardRefactor, readOnly) {
    if (readOnly) {
        return isMobile ? READONLY_MOBILE_BASE_PIXELS : READONLY_BASE_PIXELS;
    }

    return !isMobile && isWorkboardRefactor ? WORKBOARD_REFACTOR_BASE_PIXELS : NO_TOGGLE_BASE_PIXELS;
}

function getExtraRowsCount(equipmentCount, isCustomRoute) {
    const equipmentRows = Math.ceil((equipmentCount - 2) / 2);

    let additionalRowsCount = Math.max(equipmentRows, 0);

    if (equipmentRows < 1 && isCustomRoute) {
        additionalRowsCount += 1;
    }

    return additionalRowsCount;
}

function getWorkItemCounts(workItemsCollapseStatus, workItemsForJob) {
    let collapsedWorkItemsWithAssignedEquipmentCount = 0,
        collapsedWorkItemsWithUnassignedEquipmentCount = 0,
        expandedWorkItemsCount = 0,
        expandedEquipmentCount = 0,
        misalignedInputRowsCount = 0,
        extraRowsCount = 0;

    for (const [workItemId, isCollapsed] of workItemsCollapseStatus.entries()) {
        const currentWorkItem = workItemsForJob.find((item) => item.workItemId === workItemId);

        if (isCollapsed) {
            const hasUnassignedEquipment = currentWorkItem?.equipment?.some((item) => isNullOrUndefined(item.equipmentId));

            hasUnassignedEquipment ?
                collapsedWorkItemsWithUnassignedEquipmentCount += 1 :
                collapsedWorkItemsWithAssignedEquipmentCount += 1;
        } else {
            expandedWorkItemsCount += 1;

            const equipmentLength = currentWorkItem?.equipment?.length || 0;
            const isCustomRoute = currentWorkItem?.route === CUSTOM_ROUTE;

            extraRowsCount += getExtraRowsCount(equipmentLength, isCustomRoute);
            expandedEquipmentCount += equipmentLength;
            misalignedInputRowsCount += Number(equipmentLength > 2 || isCustomRoute);
        }
    }

    return {
        collapsedWorkItemsWithAssignedEquipmentCount,
        collapsedWorkItemsWithUnassignedEquipmentCount,
        expandedWorkItemsCount,
        expandedEquipmentCount,
        misalignedInputRowsCount,
        extraRowsCount
    };
}

function getJobWorkItemsPixels(
    workItemsForJob,
    workItemsCollapseStatus,
    basePixels,
    useColumnLayout,
    isWorkboardRefactor,
    readOnly,
    noteHeight
) {
    const {
        collapsedWorkItemsWithAssignedEquipmentCount,
        collapsedWorkItemsWithUnassignedEquipmentCount,
        expandedWorkItemsCount,
        expandedEquipmentCount,
        misalignedInputRowsCount,
        extraRowsCount
    } = getWorkItemCounts(workItemsCollapseStatus, workItemsForJob);

    const extraRowHeight = readOnly ? READONLY_EXTRA_ROW : EXTRA_ROW;
    const additionalRowsCount = useColumnLayout ? expandedEquipmentCount : extraRowsCount;
    const collapsedWorkItemsPixels = collapsedWorkItemsWithAssignedEquipmentCount * COLLAPSED_WORKITEM_ASSIGNED_EQUIPMENT +
        collapsedWorkItemsWithUnassignedEquipmentCount * COLLAPSED_WORKITEM_UNASSIGNED_EQUIPMENT;
    const expandedWorkItemsPixels = expandedWorkItemsCount * (basePixels + noteHeight) + additionalRowsCount * extraRowHeight;
    const hasExpandedWorkItems = expandedWorkItemsCount > 0;

    let totalWorkItemsPixels = collapsedWorkItemsPixels + workItemsCollapseStatus.size * Y_MARGIN;

    if (!readOnly && (isWorkboardRefactor || hasExpandedWorkItems || workItemsCollapseStatus.size === 0)) {
        totalWorkItemsPixels += ADD_OPERATOR_PIXELS;
    }

    if (hasExpandedWorkItems) {
        totalWorkItemsPixels += expandedWorkItemsPixels;
    }

    if (!useColumnLayout && !readOnly) {
        totalWorkItemsPixels -= misalignedInputRowsCount * MISALIGNED_INPUT_PIXELS;
    }

    return totalWorkItemsPixels;
}

function getWorkItemPixels(
    workItem,
    workItemsCollapseStatus,
    useColumnLayout,
    extraRowsCount,
    basePixels,
    readOnly,
    noteHeight
) {
    if (workItemsCollapseStatus) {
        const hasUnassignedEquipment = workItem.equipment?.some((item) => isNullOrUndefined(item.equipmentId));

        return hasUnassignedEquipment ? COLLAPSED_WORKITEM_UNASSIGNED_EQUIPMENT :
            COLLAPSED_WORKITEM_ASSIGNED_EQUIPMENT;
    }

    const strangePaddingMarginPixels = !useColumnLayout && !readOnly && extraRowsCount > 0 ? MISALIGNED_INPUT_PIXELS : 0;
    const extraRowHeight = readOnly ? READONLY_EXTRA_ROW : EXTRA_ROW;

    return basePixels + noteHeight + extraRowsCount * extraRowHeight - strangePaddingMarginPixels;
}

function getJobLayout(flattenedJobs, collapseStatus, isMobile, isWorkboardRefactor, readOnly, noteHeight) {
    const basePixels = getBasePixels(isMobile, isWorkboardRefactor, readOnly);
    const useColumnLayout = isMobile || !isWorkboardRefactor;

    return flattenedJobs.map((job, index) => {
        const {
            jobId = '',
            jobTemplateId,
            workItems
        } = job;

        const jobIdForCollapseStatus = `${jobId} ${jobTemplateId}`;
        const workItemsCollapseStatus = collapseStatus.get(jobIdForCollapseStatus) || new Map();
        const totalWorkItemsPixels = getJobWorkItemsPixels(workItems, workItemsCollapseStatus, basePixels, useColumnLayout, isWorkboardRefactor, readOnly, noteHeight);

        return {
            h: convertPixelsToJobItemGridUnits(totalWorkItemsPixels + JOB_BASE_PIXELS),
            i: jobIdForCollapseStatus,
            w: 1,
            x: 0,
            y: index
        };
    });
}

function changeJobLayout(currentLayout, orderRef, setValues, templatesById) {
    const updatedOrder = getLayoutOrder(currentLayout);

    if (checkLayoutUnchanged(orderRef, updatedOrder)) {
        return;
    }

    setValues((prevValues) => {
        const newValues = cloneDeep(prevValues);

        updatedOrder.forEach((jobKey, index) => {
            const [
                jobId, jobTemplateId
            ] = jobKey.split(' ');

            const jobTemplate = templatesById.get(jobTemplateId);

            const job = newValues.jobs[jobTemplate?.jobCategory]?.find((job) => {
                return job.jobTemplateId === jobTemplateId && (jobId === '' || job.jobId === jobId);
            });

            job.seq = index + 1;
        });

        return newValues;
    });
}

function getWorkItemLayout(job, collapseStatusForJob, isMobile, isWorkboardRefactor, readOnly, noteHeight) {
    const basePixels = getBasePixels(isMobile, isWorkboardRefactor, readOnly);
    const useColumnLayout = isMobile || !isWorkboardRefactor;

    return sortBy(job.workItems, 'seq').map((workItem, index) => {
        const workItemsCollapseStatus = collapseStatusForJob?.get(workItem.workItemId);
        const equipmentCount = workItem.equipment ? workItem.equipment.length : 0;
        const extraRowsCount = useColumnLayout ? equipmentCount : getExtraRowsCount(equipmentCount, workItem.route === CUSTOM_ROUTE);

        const workItemPixels = getWorkItemPixels(workItem, workItemsCollapseStatus, useColumnLayout, extraRowsCount, basePixels, readOnly, noteHeight);

        return {
            h: convertPixelsToWorkItemGridUnits(workItemPixels),
            i: workItem.workItemId,
            w: 1,
            x: 0,
            y: index
        };
    });
}

function changeWorkItemLayout(currentLayout, orderRef, setValues, job) {
    const updatedOrder = getLayoutOrder(currentLayout);

    if (checkLayoutUnchanged(orderRef, updatedOrder)) {
        return;
    }

    const {
        jobId,
        jobTemplate,
        jobTemplateId
    } = job;

    setValues((prevValues) => {
        const newValues = cloneDeep(prevValues);
        const jobCategory = jobTemplate?.jobCategory;

        let changedJob;

        if (jobCategory) {
            changedJob = newValues.jobs[jobCategory]?.find((prevJob) => prevJob.jobTemplateId === jobTemplateId && prevJob.jobId === jobId);
        } else {
            changedJob = newValues.jobs['Deleted Jobs']?.find((prevJob) => prevJob.jobTemplateId === jobTemplateId && prevJob.jobId === jobId);
        }

        updatedOrder.forEach((workItemId, index) => {
            const jobToUpdate = changedJob.workItems.find((workItem) => workItem.workItemId === workItemId);

            jobToUpdate.seq = index + 1;
        });

        changedJob.workItems = sortBy(changedJob.workItems, 'seq');

        return newValues;
    });
}

function getByOperatorLayout(
    workItemsByOperator,
    userOrder,
    collapseStatus,
    isMobile,
    isWorkboardRefactor,
    readOnly,
    noteHeight
) {
    const basePixels = getBasePixels(isMobile, isWorkboardRefactor, readOnly);
    const useColumnLayout = isMobile || !isWorkboardRefactor;

    return Object.keys(workItemsByOperator).map((appUserId) => {
        const workItemsCollapseStatus = collapseStatus.get(appUserId) || new Map();
        const totalWorkItemsPixels = getJobWorkItemsPixels(
            workItemsByOperator[appUserId],
            workItemsCollapseStatus,
            basePixels,
            useColumnLayout,
            isWorkboardRefactor,
            readOnly,
            noteHeight
        );

        const currentUserOrder = userOrder.find((user) => user.userId === appUserId);

        return {
            h: convertPixelsToJobItemGridUnits(totalWorkItemsPixels + JOB_BASE_PIXELS),
            i: appUserId,
            w: 1,
            x: 0,
            y: currentUserOrder.seq
        };
    });
}

function changeOperatorLayout(currentLayout, orderRef, setValues) {
    const updatedOrder = getLayoutOrder(currentLayout);

    if (checkLayoutUnchanged(orderRef, updatedOrder)) {
        return;
    }

    setValues((prevValues) => ({
        ...prevValues,
        userOrder: prevValues.userOrder.map((user) => ({
            ...user,
            seq: updatedOrder.indexOf(user.userId) + 1
        }))
    }));
}

function getByOperatorWorkItemLayout(
    workItemsForOperator,
    userWorkItemOrder,
    collapseStatus,
    isMobile,
    isWorkboardRefactor,
    readOnly,
    noteHeight
) {
    const basePixels = getBasePixels(isMobile, isWorkboardRefactor, readOnly);
    const useColumnLayout = isMobile || !isWorkboardRefactor;

    return workItemsForOperator.map((workItem) => {
        const workItemsCollapseStatus = collapseStatus.get(workItem.workItemId);
        const equipmentCount = workItem.equipment ? workItem.equipment.length : 0;
        const extraRowsCount = useColumnLayout ? equipmentCount : getExtraRowsCount(equipmentCount, workItem.route === CUSTOM_ROUTE);

        const workItemPixels = getWorkItemPixels(
            workItem,
            workItemsCollapseStatus,
            useColumnLayout,
            extraRowsCount,
            basePixels,
            readOnly,
            noteHeight
        );
        const workItemIndex = userWorkItemOrder.find((workItemSeq) => workItemSeq.workItemId === workItem.workItemId);

        return {
            h: convertPixelsToWorkItemGridUnits(workItemPixels),
            i: workItem.workItemId,
            w: 1,
            x: 0,
            y: workItemIndex.seq
        };
    });
}

function changeByOperatorWorkItemLayout(currentLayout, orderRef, setValues, appUserId) {
    const updatedOrder = getLayoutOrder(currentLayout);

    if (checkLayoutUnchanged(orderRef, updatedOrder)) {
        return;
    }

    setValues((prevValues) => {
        const newValues = cloneDeep(prevValues);
        const changedUser = newValues.userOrder.find((user) => user.userId === appUserId);

        updatedOrder.forEach((workItemId, index) => {
            const jobToUpdate = changedUser.workItems.find((workItem) => workItem.workItemId === workItemId);

            jobToUpdate.seq = index + 1;
        });

        changedUser.workItems = sortBy(changedUser.workItems, 'seq');

        return newValues;
    });
}

export {
    changeByOperatorWorkItemLayout,
    changeJobLayout,
    changeOperatorLayout,
    changeWorkItemLayout,
    getJobLayout,
    getByOperatorLayout,
    getByOperatorWorkItemLayout,
    getWorkItemLayout,
    sortBySeq
};
