// Unpublished Work © 2020-2024 Deere & Company.

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export function openDialog(dialogType, props = {}) {
    return {
        type: OPEN_DIALOG,
        dialogType,
        props
    };
}

export function closeDialog(dialogType) {
    return {
        type: CLOSE_DIALOG,
        dialogType
    };
}
