// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useToggledMemo} from 'Ui/react-hooks/use-toggled-memo';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';

function SidebarHeader(props) {
    const {
        children,
        title
    } = props;

    const titleElement = React.useMemo(() => (
        <Typography variant='h3'>
            {title}
        </Typography>
    ), [title]);

    const stackPaddingX = useToggledMemo({
        disabledCallback: () => '0',
        enabledCallback: () => '12px',
        toggle: ONLINK_NAVIGATION_REDESIGN
    });

    return (
        <Stack
            alignItems='flex-end'
            borderBottom='1px solid'
            borderColor='grey.900'
            direction='row'
            justifyContent='space-between'
            marginY='7px'
            paddingX={stackPaddingX}
            paddingY='5px'
            spacing='10px'
        >
            {titleElement}
            {children}
        </Stack>
    );
}

SidebarHeader.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string
};

export default SidebarHeader;
