// Unpublished Work © 2021-2024 Deere & Company.

export const HOME = '/home';
export const DRILL_IN = '/home/details/:type';
export const CURRENT_CONDITIONS = '/home/details/weather_course';
export const PLAYBOOKS = '/home/details/:type/PlayBooks';
export const TWC = '/home/details/:type/TWC';
export const FORETEES = '/home/details/:type/ForeTees';
export const DRILL_IN_CHART = '/home/details/:type/:tileType';
export const SETTINGS = '/home/details/settings';
export const FLEET = '/equipment/fleet';
export const SUGGESTED_MAINTENANCE = '/equipment/suggestedMaintenance';
export const SERVICE_HISTORY = '/equipment/serviceHistory';
export const ASSIGNMENTS = '/equipment/assignments';
export const HOC = '/equipment/hoc';
export const SHOP = '/equipment/shop';
export const EQUIPMENT_DETAILS = '/equipmentDetails';
export const EQUIPMENT_REPORT = '/equipment/report';

export const INVENTORY = '/equipment/parts/inventory';
export const PARTS_HISTORY = '/equipment/parts/history';
export const PARTS_RECOMMENDED = '/equipment/parts/recommended';
export const PARTS_ORDERS = '/equipment/parts/orders';

export const WORKBOARD = '/labor/workboard';
export const MY_WORK = '/labor/myWork';
export const ANALYZE_COURSE_OPERATIONS = '/labor/report/:type';
export const LABOR_REPORT = '/labor/report/labor';
export const WORKBOARD_REPORT = '/labor/report/workboard';
export const WORKBOARD_PRESENTATION = '/labor/workboard/presentation/:membershipId/:workboardId?';

export const BASE_HOME_ROUTES = [HOME];
export const BASE_FLEET_ROUTES = [FLEET, HOC];
export const BASE_LABOR_ROUTES = [WORKBOARD, MY_WORK];
export const BASE_FLEET_MAINTENANCE_ROUTES = [SUGGESTED_MAINTENANCE, SHOP, ASSIGNMENTS];
export const BASE_PART_ROUTES = [INVENTORY, PARTS_RECOMMENDED, PARTS_ORDERS];
