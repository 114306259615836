// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function addModelMaintenance(modelMaintenanceData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/modelMaintenance', modelMaintenanceData);

    return response.data;
}

async function deleteModelMaintenance(modelMaintenanceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/modelMaintenance/${modelMaintenanceId}`);

    return response.data;
}

async function getModelMaintenance(modelMaintenanceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/modelMaintenance/${modelMaintenanceId}`);

    return response.data;
}

async function updateModelMaintenance(modelMaintenanceId, modelMaintenanceData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/modelMaintenance/${modelMaintenanceId}`, modelMaintenanceData);

    return response.data;
}

export {
    addModelMaintenance,
    deleteModelMaintenance,
    getModelMaintenance,
    updateModelMaintenance
};
