// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {joinClassNames} from 'Utils/html-utils';
import PropTypes from 'Utils/prop-type-utils';

function getClassNames(props) {
    if (isNullOrUndefined(props.onClick)) {
        return joinClassNames('dashboard-panel', props.className);
    }

    return joinClassNames('dashboard-panel', 'clickable', props.className);
}

function Panel(props) {
    const {
        children,
        icon,
        onClick,
        translations,
        title
    } = props;

    return (
        <div
            className={getClassNames(props)}
            onClick={onClick}
        >
            {
                title && <div className='dashboard-panel-header'>
                    <div className='dashboard-panel-title'>{translations[title] || title}</div>
                    {icon}
                </div>
            }
            {children}
        </div>
    );
}

Panel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    title: PropTypes.string,
    translations: PropTypes.translations
};

export default Panel;
