// Unpublished Work © 2020-2024 Deere & Company.

import {isNullOrUndefined} from 'Common/utils/validation-utils';

const graphValues = {
    waterMonthlyOld: 'water_energy_3',
    waterMonthly: 'water_energy_5',
    waterYearlyOld: 'water_energy_4',
    waterYearly: 'water_energy_6',
    performanceEnergyWithDewpt: 'weather_6',
    performanceEnergy: 'weather_5'
};

function redirect(path, history) {
    if (isNullOrUndefined(history)) {
        window.location.assign(path);
    } else {
        history.push(path);
    }
}

function checkForRedirects(tile) {
    switch (tile) {
        case graphValues.waterMonthlyOld:
            return graphValues.waterMonthly;
        case graphValues.waterYearlyOld:
            return graphValues.waterYearly;
        case graphValues.performanceEnergy:
            return graphValues.performanceEnergyWithDewpt;
        default:
            return tile;
    }
}

function homeRedirect(membershipId, category, history) {
    redirect(`home/details/${category}`, history);
}

function graphRedirect(membershipId, category, history, tile, categoryType) {
    redirect(`/home/details/${categoryType}/${checkForRedirects(tile)}`, history);
}

function foreTeesRedirect(membershipId, category, history) {
    redirect('/home/details/rounds/ForeTees', history);
}

function playBooksRedirect(membershipId, category, history) {
    redirect('/home/details/fert_chem/PlayBooks', history);
}

function weatherGraphRedirect(membershipId, category, history) {
    redirect('/home/details/weather_course/TWC', history);
}

export {
    redirect,
    homeRedirect,
    graphRedirect,
    foreTeesRedirect,
    playBooksRedirect,
    weatherGraphRedirect
};
