// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function authenticate(data) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/auth', data);

    return response;
}

export {
    authenticate
};
