// Unpublished Work © 2022-2024 Deere & Company.

import accounting from 'accounting';
import {getInventories} from 'Services/membership-service';
import {getCompatibleParts} from 'Services/parts-service';
import {getPartBins} from 'Services/inventory-service';
import {getEquipmentServiceStatus} from 'Services/equipment-service';
import {RECOMMENDED} from 'Common/constants/service-status';
import {alphaNumericCompare} from 'Ui/models/maintenance';

async function getCompatiblePartsData(equipmentId) {
    const [
        {inventories},
        {compatibleParts}
    ] = await Promise.all([
        getInventories(),
        getCompatibleParts(equipmentId)
    ]);

    const {partBins} = inventories?.length ? await getPartBins(inventories[0].inventoryId) : {};

    return compatibleParts.map((compatiblePart) => {
        const partBin = partBins && partBins.find((partBin) => partBin.partId === compatiblePart.partId);
        const stock = partBin ? accounting.formatNumber(partBin.quantity) : '';

        return {
            ...compatiblePart,
            stock
        };
    });
}

export async function getRowData(equipment) {
    if (equipment?.equipmentId) {
        const {serviceStatus} = await getEquipmentServiceStatus(equipment.equipmentId, RECOMMENDED);

        if (serviceStatus?.length) {
            return getCompatiblePartsData(equipment.equipmentId);
        }
    }

    return [];
}

export function getColumns(translations) {
    return [
        {
            Header: translations.NAME,
            accessor: 'partName',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.MANUFACTURER,
            accessor: 'partManufacturerName'
        },
        {
            Header: translations.PART_NUMBER,
            accessor: 'partNumber'
        },
        {
            Header: translations.TYPE,
            accessor: 'partType'
        },
        {
            Header: translations.ONLINK_STOCK,
            accessor: 'stock',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 70
        }
    ];
}
