// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Typography from '@mui/material/Typography';

function NoDataMessage(props) {
    const {
        children,
        hasData,
        imageSrc,
        noDataMessage
    } = props;

    if (hasData) {
        return children;
    }

    return (
        <>
            {
                imageSrc &&
                <div className={'no-image-header'}>
                    <img
                        className={'no-data-image'}
                        src={imageSrc}
                    />
                </div>
            }
            <Typography
                textAlign='center'
                variant='h1'
            >{noDataMessage}</Typography>
        </>
    );
}

NoDataMessage.defaultProps = {
    hasData: false
};

NoDataMessage.propTypes = {
    children: PropTypes.node,
    hasData: PropTypes.bool,
    imageSrc: PropTypes.imageSource,
    noDataMessage: PropTypes.string
};

export default NoDataMessage;
