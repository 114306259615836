// Unpublished Work © 2021-2024 Deere & Company.

import {hasAppAccess, hasPermissions} from 'Common/utils/permission-handler';
import {hasMyJDPermissions} from 'Common/utils/jd-permission-handler';
import {setInArray} from 'Common/utils/array-handlers';

function isAuthorized({
    appAccess: requiredAppKey,
    myJdPermissions: requiredJdPermissions,
    migrationJdPermissions,
    permission: requiredPermission,
    requireMyJDSession,
    toggle: requiredToggle,
    requireMigration
}, {
    appKeys: userAppKeys,
    featureToggles: userToggles,
    hasMyJDSession,
    isMigrated,
    myJdPermissions: userJdPermissions = {},
    permissions: userPermissions,
    superUser
}) {
    const credentials = {
        permissionMap: userPermissions,
        superUser
    };

    const hasFeatureToggle = !requiredToggle || userToggles[requiredToggle];
    const hasPermittedJDSession = !requireMyJDSession || hasMyJDSession;
    const hasPermittedJDPermissions = !isMigrated || hasMyJDPermissions(userJdPermissions, ...setInArray(requiredJdPermissions));
    const hasMigrationMyJdPermissions = !migrationJdPermissions || hasMyJDPermissions(userJdPermissions, ...setInArray(migrationJdPermissions));
    const isAccessible = isMigrated || !requiredAppKey || hasAppAccess(userAppKeys, requiredAppKey);
    const isPermitted = isMigrated || !requiredPermission || hasPermissions(credentials, requiredPermission);
    const migratedMMembershipOnly = !requireMigration || isMigrated;

    return Boolean(hasFeatureToggle && hasPermittedJDSession && hasPermittedJDPermissions && hasMigrationMyJdPermissions && isAccessible && isPermitted && migratedMMembershipOnly);
}

export {
    isAuthorized
};
