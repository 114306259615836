// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {getActiveMembershipUsers} from 'Services/membership-service';
import {getMyJdOperatorLinksByAppUserIds, getMyJdUserLinksByAppUserIds} from 'Services/my-jd-user-service';
import {NOT_INVITED, REJECTED, STATUS_TRANSLATION_MAP} from 'Common/constants/invite-statuses';
import {getCurrentMembershipInviteStatuses} from 'Services/invite-status-service';

async function makeInviteStatusMap() {
    const {appUserInviteStatuses} = await getCurrentMembershipInviteStatuses();

    return appUserInviteStatuses.reduce((statusMap, status) => {
        statusMap[status.appUserId] = {
            inviteStatus: status.inviteStatus,
            email: status.email
        };
        return statusMap;
    }, {});
}

async function getLinkedUsers() {
    const users = await getActiveMembershipUsers();

    const appUserIds = users.map(({appUserId}) => appUserId);

    const [
        linkedStaff,
        linkedOperators
    ] = await Promise.all([
        getMyJdUserLinksByAppUserIds(appUserIds),
        getMyJdOperatorLinksByAppUserIds(appUserIds)
    ]);

    const linkedUsersSet = new Set(linkedStaff.concat(linkedOperators).map(({appUserId}) => appUserId));

    return {
        users,
        linkedStaff,
        linkedOperators,
        linkedUsersSet
    };
}

function filterUsersByInviteStatus(users, linkedUsersSet, inviteStatusMap, filterInvitedUsers = false) {
    return users.filter(({appUserId}) => {
        const userInviteStatus = inviteStatusMap[appUserId]?.inviteStatus;

        return !linkedUsersSet.has(appUserId) &&
                (!filterInvitedUsers || !userInviteStatus || userInviteStatus === REJECTED);
    });
}

function getLinkedNames(users, linkedUsersSet) {
    const linkedNames = new Set();

    users.forEach((user) => {
        if (linkedUsersSet.has(user.appUserId)) {
            linkedNames.add(`${user.firstName} ${user.lastName}`);
        }
    });

    return linkedNames;
}

async function loadMigrationDataTable({
    filterInvitedUsers,
    isMounted,
    setLoading,
    setRows,
    setInvites,
    setSelected,
    translations,
    userRoles
}) {
    setLoading(true);

    const [
        {
            users,
            linkedUsersSet
        },
        inviteStatusMap
    ] = await Promise.all([
        getLinkedUsers(),
        makeInviteStatusMap()
    ]);

    const linkedNames = getLinkedNames(users, linkedUsersSet);

    const userRows = filterUsersByInviteStatus(users, linkedUsersSet, inviteStatusMap, filterInvitedUsers).map((user) => {
        const currentUserStatus = inviteStatusMap[user.appUserId];
        const name = `${user.firstName} ${user.lastName}`;

        return {
            appUserId: user.appUserId,
            email: currentUserStatus?.email || user.email,
            isDuplicate: linkedNames.has(name),
            name,
            roleName: userRoles.find((role) => role.userRoleId === user.userRoleId)?.title,
            status: translations[STATUS_TRANSLATION_MAP[currentUserStatus?.inviteStatus || NOT_INVITED]],
            title: user.title
        };
    });

    const userInvites = userRows.reduce((invites, userRow) => {
        invites[userRow.appUserId] = {
            email: userRow.email,
            name: userRow.name
        };
        return invites;
    }, {});

    if (isMounted()) {
        setRows(userRows);
        setInvites(userInvites);
        setSelected(new Set());
        setLoading(false);
    }
}

export {
    getLinkedUsers,
    filterUsersByInviteStatus,
    makeInviteStatusMap,
    loadMigrationDataTable
};
