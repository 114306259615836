// Unpublished Work © 2024 Deere & Company.

function reduceFeatureToggles(featureToggles, featureToggle) {
    featureToggles[featureToggle.featureName] = featureToggle.isActive;

    return featureToggles;
}

export {
    reduceFeatureToggles
};
