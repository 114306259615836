// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {useSave} from 'Ui/react-hooks/use-save';
import {createSensor} from 'Services/vendor-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {addToast} from 'Store/actions/toasts';
import {TOAST_TYPE} from '@deere/toast';
import {replaceTranslationNames} from 'Utils/translation-utils';

const SENSOR = 'Sensor';

function SensorCreateDialog(props) {
    const {
        closeDialog,
        invalidInputs,
        setValid,
        translations,
        updateSensorIds,
        sensorIds,
        addToast
    } = props;

    const [sensorId, setSensorId] = React.useState('');
    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        if (sensorIds.includes(sensorId)) {
            addToast({
                message: replaceTranslationNames(translations.ONLINK_DUPLICATE_ITEM, {
                    '0': SENSOR
                }),
                type: TOAST_TYPE.ERROR
            });
        } else {
            await createSensor({
                sensorId,
                sensorType: 'pogo'
            });

            updateSensorIds(sensorId);

            closeDialog();
        }
    }, {
        invalidInputs
    });

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='sensor-create-dialog'
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={SENSOR}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    debounceTimeout={window.props.debounceTimeout}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={SENSOR}
                    name='sensorId'
                    onChange={(event) => setSensorId(event.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={sensorId}
                />
            </form>
        </FormDialog>
    );
}

SensorCreateDialog.propTypes = {
    addToast: PropTypes.func,
    closeDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    sensorIds: PropTypes.arrayOf(PropTypes.string),
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateSensorIds: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.SENSOR_CREATE_DIALOG));
        },
        addToast(value) {
            dispatch(addToast(value));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(SensorCreateDialog));
