// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import EditableDepartmentSettings from 'Ui/components/settings/users/editable-department-settings';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {createDepartment, updateDepartmentById} from 'Services/department-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function DepartmentEditDialog(props) {
    const {
        closeDialog,
        department,
        getDepartments,
        invalidInputs,
        setValid,
        translations
    } = props;

    const disableSave = invalidInputs.size > 0;

    const [name, setName] = React.useState(() => department && department.name || '');

    async function saveDepartment() {
        const departmentData = {
            name
        };

        if (department) {
            return updateDepartmentById(departmentData, department.departmentId);
        }

        return createDepartment(departmentData);
    }

    async function onSave(event) {
        event.preventDefault();

        if (!disableSave) {
            await saveDepartment();
            await getDepartments();

            closeDialog();
        }
    }

    const title = department ?
        translations.ONLINK_EDIT_DEPARTMENT :
        translations.ONLINK_ADD_DEPARTMENT;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={disableSave}
            onSave={onSave}
            saveLabel={translations.save}
            title={title}
            translations={translations}
        >
            <EditableDepartmentSettings
                name={name}
                onChange={(event) => setName(event.target.value)}
                onSave={onSave}
                setValid={setValid}
                translations={translations}
            />
        </FormDialog>
    );
}

DepartmentEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    department: PropTypes.object,
    getDepartments: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.DEPARTMENT_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(DepartmentEditDialog));
