// Unpublished Work © 2021-2024 Deere & Company.

export const TITLE = 'title';
export const USER = 'user';
export const DEFAULT_TRAVEL_ROUTES = [
    {
        id: 'Leap Frog',
        title: 'ONLINK_LEAP_FROG_ROUTE'
    },
    {
        id: 'Even',
        title: 'ONLINK_EVEN_ROUTE'
    },
    {
        id: 'Odd',
        title: 'ONLINK_ODD_ROUTE'
    },
    {
        id: 'Front 9',
        title: 'ONLINK_FRONT_NINE_ROUTE'
    },
    {
        id: 'Back 9',
        title: 'ONLINK_BACK_NINE_ROUTE'
    },
    {
        id: 'Custom',
        title: 'CUSTOM'
    }
];

export const PRESENTATION_PROPERTIES = {
    grouping: 'workboard_presentation_groups',
    columnCount: 'workboard_presentation_column_count',
    fontSize: 'workboard_presentation_font_size',
    showEstimatedHours: 'workboard_presentation_show_estimated_hours',
    showPPE: 'workboard_show_protection_icons',
    tileOpacity: 'workboard_tile_opacity',
    showRoute: 'workboard_show_route',
    showEmployeeGroupColor: 'workboard_show_employee_group_color',
    showEmployeeImage: 'workboard_show_employee_image'
};

export const CUSTOM_ROUTE = 'Custom';
