// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Responsive, WidthProvider} from 'react-grid-layout';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import Tile from 'Ui/components/layout-editor/tile';
import cols from 'Common/constants/grid-cols';
import {createDraggableLayouts, mergeLayouts} from 'Utils/layout-utils';
import {fixPositions, isTileVisible, sortTiles} from 'Utils/tile-utils';
import {tileTitleTranslationKeys} from 'Ui/components/common/tile-constants/translations';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const nonMobileBreakpoints = {
    '3': 888,
    '2': 455,
    '1': 0
};

const mobileBreakpoints = {
    ...nonMobileBreakpoints,
    '2': 767
};

const GRID_LAYOUT_MARGIN = 20;

const ResponsiveGridLayout = WidthProvider(Responsive);

function triggerResize() {
    window.dispatchEvent(new Event('resize'));
}

function EditableGridLayout(props) {
    const {
        featureToggles,
        isDefaultLayout,
        isMigrated,
        isMobile,
        layout,
        myJdPermissions,
        onLayoutChange,
        translations
    } = props;

    const [draggableLayouts, setDraggableLayouts] = React.useState(() => createDraggableLayouts(layout));
    const [tiles, setTiles] = React.useState(() => sortTiles(draggableLayouts)[1]);

    React.useEffect(() => {
        window.addEventListener('orientationchange', triggerResize);
        window.addEventListener('visibilitychange', triggerResize);

        return () => {
            window.removeEventListener('orientationchange', triggerResize);
            window.removeEventListener('visibilitychange', triggerResize);
        };
    }, []);

    React.useEffect(() => {
        const newDraggableLayouts = createDraggableLayouts(layout);
        const sortedTiles = sortTiles(newDraggableLayouts)[1];

        setDraggableLayouts(newDraggableLayouts);
        setTiles(sortedTiles);
    }, [layout]);

    function changeLayout(currentLayout, currentLayouts) {
        const mergedDraggableLayouts = mergeLayouts(draggableLayouts, currentLayouts);

        setDraggableLayouts(mergedDraggableLayouts);
        onLayoutChange(mergedDraggableLayouts);
    }

    function removeTile(tileId) {
        const draggableLayoutsWithoutTile = Object.keys(draggableLayouts).reduce((draggableLayoutsByColumn, columnCount) => {
            const filteredDraggableLayouts = draggableLayouts[columnCount].filter((tilePosition) => tilePosition.tileId !== tileId);

            draggableLayoutsByColumn[columnCount] = fixPositions(filteredDraggableLayouts, cols[columnCount]);

            return draggableLayoutsByColumn;
        }, {});
        const filteredTiles = tiles.filter((tile) => tile.id !== tileId);

        setDraggableLayouts(draggableLayoutsWithoutTile);
        setTiles(filteredTiles);
    }

    const responsiveBreakpoints = isMobile ?
        mobileBreakpoints :
        nonMobileBreakpoints;

    const filteredTiles = tiles?.filter(({id}) => isTileVisible(id, {
        featureToggles,
        isMigrated,
        myJdPermissions
    }));

    return (
        <ResponsiveGridLayout
            breakpoints={responsiveBreakpoints}
            className='editable-grid-container'
            cols={cols}
            draggableCancel='.close'
            isDraggable={!isDefaultLayout}
            isResizable={false}
            layouts={draggableLayouts}
            margin={[GRID_LAYOUT_MARGIN, GRID_LAYOUT_MARGIN]}
            onLayoutChange={changeLayout}
            rowHeight={60}
        >
            {
                filteredTiles?.map((tile) => (
                    <div key={tile.index}>
                        <Tile
                            id={tile.id}
                            onRemove={removeTile}
                            removable={!isDefaultLayout && filteredTiles.length > 1}
                            title={translations[tileTitleTranslationKeys[tile.name]]}
                        />
                    </div>
                ))
            }
        </ResponsiveGridLayout>
    );
}

EditableGridLayout.propTypes = {
    featureToggles: PropTypes.featureToggles,
    isDefaultLayout: PropTypes.bool,
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    layout: PropTypes.layout,
    myJdPermissions: PropTypes.myJdPermissions,
    onLayoutChange: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        myJdPermissions: state.account.myJdPermissions,
        isMigrated: state.membership.isMigrated
    };
}

export default connect(mapStateToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(EditableGridLayout));
