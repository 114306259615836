// Unpublished Work © 2023-2024 Deere & Company.

export const equipmentAreaTranslationKeys = {
    Approaches: 'APPROACHES',
    'Approaches Special': 'APPROACHES_SPECIAL',
    'Bunker Faces': 'ONLINK_BUNKER_FACES',
    'Bunker Faces Special': 'ONLINK_BUNKER_FACES_SPECIAL',
    'Bunkers': 'ONLINK_BUNKERS',
    'Collars': 'COLLARS',
    'Collars Special': 'ONLINK_COLLARS_SPECIAL',
    'Fairways': 'FAIRWAYS',
    'Fairways Special': 'ONLINK_FAIRWAYS_SPECIAL',
    'Greens': 'GREENS',
    'Greens Special': 'ONLINK_GREENS_SPECIAL',
    'Intermediate': 'INTERMEDIATE',
    'Intermediate Special': 'ONLINK_INTERMEDIATE_SPECIAL',
    'Other': 'OTHER',
    'Rough': 'ROUGH',
    'Rough Special': 'ONLINK_ROUGH_SPECIAL',
    'Surrounds': 'ONLINK_BUNKER_SURROUNDS',
    'Surrounds Special': 'ONLINK_BUNKER_SURROUNDS_SPECIAL',
    'Tees': 'TEES',
    'Tees Special': 'ONLINK_TEES_SPECIAL'
};
