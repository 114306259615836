// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MapControls} from '@deere/isg.component-library';
import {useGoogleMap} from '@react-google-maps/api';

function GoogleMapControls(props) {
    const map = useGoogleMap();

    const {latLngBounds} = props;

    return (
        <MapControls
            getExtent={() => {
                return {
                    north: latLngBounds.getNorthEast().lat(),
                    south: latLngBounds.getSouthWest().lat(),
                    east: latLngBounds.getNorthEast().lng(),
                    west: latLngBounds.getSouthWest().lng()
                };
            }}
            map={map}
            translations={{
                MAP: props.translations.MAP,
                SATELLITE: props.translations.SATELLITE,
                MAP_TYPE: props.translations.MAP_TYPE,
                ZOOM_IN: props.translations.ZOOM_IN,
                ZOOM_OUT: props.translations.ZOOM_OUT,
                ZOOM_TO_EXTENT: props.translations.ZOOM_TO_EXTENT
            }}
        />
    );
}

GoogleMapControls.propTypes = {
    getBoundZoomToExtent: PropTypes.func,
    latLngBounds: PropTypes.shape(),
    translations: PropTypes.translations
};

export default GoogleMapControls;
