// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {getDateRange} from 'Utils/time-utils';
import moment from 'moment';
import {filterSuccessfulResponse, reduceResponseData} from 'Common/utils/response-mapper';

async function getMyJDBreadcrumbs(machineId, orgId, dateSelected) {
    const axiosClient = createUiAxiosClient();

    const dateRange = getDateRange(dateSelected, 'day', 'day');

    if (dateRange.startDate && dateRange.endDate) {
        const response = await axiosClient.get(`/myJohnDeere/machines/${machineId}/${orgId}/breadcrumbs`, {
            params: dateRange
        });

        return response.data;
    }

    return {};
}

async function getMyJDMachineMeasurements(machines) {
    if (machines.length === 0) {
        return [];
    }

    const axiosClient = createUiAxiosClient();

    const machineIds = machines.map(({myJDId}) => myJDId);
    const response = await axiosClient.post('/myJohnDeere/machines/machineMeasurements', machineIds, {
        params: {
            endDate: moment().toISOString(),
            startDate: moment().startOf('day').toISOString()
        }
    });

    return response.data;
}

async function getMyJDMachinesWithBreadcrumbs(machineAndOrgIds, dateSelected) {
    const axiosClient = createUiAxiosClient();

    const params = getDateRange(dateSelected, 'day', 'day');

    if (params.startDate && params.endDate) {
        const response = await axiosClient.post('/myJohnDeere/machines/hasBreadcrumbs', machineAndOrgIds, {
            params
        });

        return response.data;
    }

    return {};
}

async function getMyJDMachineBatteryMeasurements(machineAndOrgIds) {
    const axiosClient = createUiAxiosClient();
    const now = moment();

    const endDate = now.toISOString();
    const startDate = now.startOf('day').toISOString();
    const utcOffset = now.format('Z');

    const response = await axiosClient.post('/api/myJohnDeere/machines/batteryMeasurements', machineAndOrgIds, {
        params: {
            endDate,
            startDate,
            utcOffset
        }
    });

    return response.data
        .filter(filterSuccessfulResponse)
        .reduce(reduceResponseData, []);
}

export {
    getMyJDBreadcrumbs,
    getMyJDMachineMeasurements,
    getMyJDMachinesWithBreadcrumbs,
    getMyJDMachineBatteryMeasurements
};
