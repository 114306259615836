// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {redirect} from 'Utils/redirect-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {MY_JOHN_DEERE} from 'Ui/constants/replace-constants';

function MyJohnDeereLogin(props) {
    const {translations} = props;

    return (
        <div className='linked-account-body'>
            <h3 className='linked-account-header'>{translations.ONLINK_LOGIN_WITH_MYJD_PROMPT}</h3>
            <OnlinkButton
                className='primary'
                onClick={() => redirect('/myjd-login?redirect=home/details/settings')}
            >
                {
                    replaceTranslationNames(translations.ONLINK_MYJOHNDEERE_SIGNIN, {
                        '0': MY_JOHN_DEERE
                    })
                }
            </OnlinkButton>
        </div>
    );
}

MyJohnDeereLogin.propTypes = {
    translations: PropTypes.translations
};

export default MyJohnDeereLogin;
