// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Box from '@mui/material/Box';
import DataTable from 'Ui/components/common/data-table/data-table';
import {dateCompare} from 'Ui/models/maintenance';
import {dataTableFormatTime} from 'Utils/time-utils';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';

function getColumns(translations, costReportData, currencyCell) {
    const formattedDate = dataTableFormatTime(costReportData.dateSelect, costReportData.dateScale);

    return [
        {
            Header: translations.DATE,
            accessor: 'dateSelect',
            className: 'sticky',
            headerClassName: 'sticky',
            sortMethod: dateCompare,
            Cell: (row) => dataTableFormatTime(row.value, row.original.dateScale),
            Footer: (
                <span className='bold-text'>{`${formattedDate} ${translations.ONLINK_TOTAL}`}</span>
            )
        },
        {
            Header: translations.SALES_SYSTEM_TRANSACTION_PURCHASE,
            accessor: 'purchase',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            Cell: currencyCell,
            Footer: (
                <span className='bold-text'>
                    {
                        currencyCell({
                            value: costReportData.purchase
                        })
                    }
                </span>
            )
        },
        {
            Header: translations.ONLINK_PROPERTY_TAX,
            accessor: 'propertyTax',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            Cell: currencyCell,
            Footer: (
                <span className='bold-text'>
                    {
                        currencyCell({
                            value: costReportData.propertyTax
                        })
                    }
                </span>
            )
        },
        {
            Header: translations.ONLINK_SALES_TAX,
            accessor: 'salesTax',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            Cell: currencyCell,
            Footer: (
                <span className='bold-text'>
                    {
                        currencyCell({
                            value: costReportData.salesTax
                        })
                    }
                </span>
            )
        },
        {
            Header: translations.PARTS,
            accessor: 'partsCost',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            Cell: currencyCell,
            Footer: (
                <span className='bold-text'>
                    {
                        currencyCell({
                            value: costReportData.partsCost
                        })
                    }
                </span>
            )
        },
        {
            Header: translations.ONLINK_SERVICE,
            accessor: 'serviceCost',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            Cell: currencyCell,
            Footer: (
                <span className='bold-text'>
                    {
                        currencyCell({
                            value: costReportData.serviceCost
                        })
                    }
                </span>
            )
        },
        {
            Header: translations.ONLINK_TOTAL,
            accessor: 'totalCost',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            Cell: currencyCell,
            Footer: (
                <span className='bold-text'>
                    {
                        currencyCell({
                            value: costReportData.totalCost
                        })
                    }
                </span>
            )
        }
    ];
}

function CostReportDataTable(props) {
    const {
        costReportData,
        featureToggles,
        membership,
        translations
    } = props;

    const currencyCell = React.useCallback(({value}) => getFormattedCurrency(value, {
        currencyPreference: membership.currencyPreference,
        featureToggles
    }), [membership.currencyPreference, featureToggles]);

    return (
        <Box
            className='graph-table-container'
            position='relative'
        >
            <DataTable
                columns={getColumns(translations, costReportData, currencyCell)}
                defaultSorted={[{
                    desc: false,
                    id: 'dateSelect'
                }]}
                rows={costReportData.dateChildren}
                translations={translations}
            />
        </Box>
    );
}

CostReportDataTable.propTypes = {
    costReportData: PropTypes.object,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export default CostReportDataTable;
