// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function putWorkItem(workItemId, jobId, workItemData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/workItem/${workItemId}/jobs/${jobId}`, workItemData);

    return response.data;
}

async function putWorkItemForOperator(workItemId, jobId, workItemData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/workItem/${workItemId}/jobs/${jobId}/operator`, workItemData);

    return response.data;
}

async function postWorkItem(workItemData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/workItem', workItemData);

    return response.data;
}

async function deleteWorkItem(workItemId, jobId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/workItem/${workItemId}/jobs/${jobId}`);

    return response.data;
}

async function postWorkItemLog(workItemId, payload) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post(`/api/workItem/${workItemId}/log`, payload);

    return response.data;
}

async function getWorkItemLogs(workItemId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/workItem/${workItemId}/log`);

    return response.data;
}

export {
    putWorkItem,
    putWorkItemForOperator,
    postWorkItemLog,
    getWorkItemLogs,
    postWorkItem,
    deleteWorkItem
};
