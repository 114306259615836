// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'Utils/prop-type-utils';

function StepInstructionsForm(props) {
    const {
        steps,
        subtitles,
        title
    } = props;

    return (
        <Stack
            direction='column'
            flex={1}
            overflow='auto'
            padding='12px'
        >
            <Typography
                fontSize={24}
                fontWeight='bold'
                paddingBottom='20px'
            >
                {title}
            </Typography>
            {
                subtitles.map((subtitle) => (
                    <Typography
                        key={subtitle}
                        paddingBottom='20px'
                    >
                        {subtitle}
                    </Typography>
                ))
            }
            {
                steps.map((step, index) => (
                    <React.Fragment key={step.title}>
                        <Stack
                            direction='row'
                            flex={1}
                            gap='10px'
                            marginBottom={step.additionalComponent ? '0' : '20px'}
                        >
                            <Typography
                                color='#d5d5d5'
                                fontSize={24}
                                fontWeight='bold'
                            >
                                {index + 1}
                            </Typography>
                            <Box
                                backgroundColor='#d5d5d5'
                                borderRadius='6px'
                                padding='12px'
                                width='100%'
                            >
                                <Typography
                                    fontWeight='bold'
                                >
                                    {step.title}
                                </Typography>
                                {
                                    step.body?.map((content, bodyIndex) => (
                                        <Typography
                                            key={content}
                                            paddingBottom={step.body.length === bodyIndex + 1 ? null : '20px'}
                                        >
                                            {content}
                                        </Typography>
                                    ))
                                }
                            </Box>
                        </Stack>
                        {
                            step.additionalComponent &&
                            <Box
                                marginBottom='10px'
                                paddingLeft='12px'
                            >
                                {step.additionalComponent}
                            </Box>
                        }
                    </React.Fragment>
                ))
            }
        </Stack>
    );
}

StepInstructionsForm.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object),
    subtitles: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
};

export default StepInstructionsForm;
