// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {getEquipmentHistory} from 'Services/equipment-service';
import Status from 'Ui/components/common/data-table/status';
import {
    formatTimeOrDefault,
    getStatusLabelForEquipmentHistory
} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {RECOMMENDED} from 'Common/constants/service-status';
import accounting from 'accounting';
import {alphaNumericCompare, dateCompare, genericServiceCompare} from 'Ui/models/maintenance';
import {PLATFORM_DATA_ADDED, FUEL_ADDED, DEFAULT_TYPES, OPTIONAL_TYPES} from 'Ui/constants/equipment-history-constants';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import store from 'Store/store';
import dialogTypes from 'Ui/components/common/dialog-types';
import serviceStatusConstants from 'Ui/constants/service-status-constants';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';

export async function getRowData(equipment, translations) {
    if (equipment?.equipmentId) {
        const {equipmentHistory} = await getEquipmentHistory(equipment.equipmentId);
        const typeTranslationMap = new Map([...DEFAULT_TYPES, ...OPTIONAL_TYPES].map((type) => [type.key, type.translation]));

        return equipmentHistory.filter((history) => history.serviceStatus !== RECOMMENDED &&
            history.equipmentHistoryType !== PLATFORM_DATA_ADDED &&
            history.equipmentHistoryType !== FUEL_ADDED)
            .map((history) => {
                const serviceStatusOption = serviceStatusConstants[history.serviceStatus];

                return {
                    ...history,
                    type: history.equipmentHistoryType,
                    equipmentHistoryType: translations[typeTranslationMap.get(history.equipmentHistoryType)],
                    formattedStatus: getStatusLabelForEquipmentHistory(history, equipment, translations),
                    serviceType: history.serviceType === 'none' ? '' : `${history.serviceType} (${serviceStatusOption.title})`,
                    formattedTimeOfEvent: formatTimeOrDefault(history.timeOfEvent)
                };
            });
    }

    return [];
}

export function getFilterComponent(translations) {
    const availableTypeOptions = [...DEFAULT_TYPES, ...OPTIONAL_TYPES].map(({
        key, translation
    }) => {
        return {
            id: key,
            title: translations[translation]
        };
    });

    const [typesSelected, setTypesSelected] = React.useState(DEFAULT_TYPES.map((o) => o.key));

    const typeFilterDropDown = (
        <div className={'selected-types'}>
            <MultiSelect
                autoFocus={true}
                className={'selected-types'}
                items={availableTypeOptions}
                labels={{
                    placeholder: translations.ONLINK_HISTORY_TYPE_PLACEHOLDER,
                    multiSelect: translations.ONLINK_TYPES,
                    emptyList: translations.NO_TYPES,
                    allSelect: translations.ONLINK_ALL_TYPES,
                    selectAllItems: translations.ONLINK_HISTORY_SELECT_ALL_TYPES
                }}
                name='selectedTypes'
                onChange={setTypesSelected}
                selectedIds={typesSelected}
            />
        </div>
    );

    return {
        typesSelected,
        typeFilterDropDown
    };
}

function rowAccessor(row) {
    return row;
}

export function getColumns(translations, updateEquipment) {
    return [
        {
            Header: translations.DATE,
            accessor(row) {
                return row;
            },
            id: 'formattedTimeOfEvent',
            width: 150,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'timeOfEvent');
            },
            Cell(row) {
                return row.original.formattedTimeOfEvent;
            }
        },
        {
            Header: translations.TYPE,
            accessor: 'equipmentHistoryType',
            width: 120
        },
        {
            Header: translations.STATUS,
            accessor: rowAccessor,
            id: 'statusTo',
            width: 150,
            sortMethod(a, b) {
                return alphaNumericCompare(a.formattedStatus, b.formattedStatus);
            },
            Cell(row) {
                return (
                    <Status
                        formattedStatus={row.original.formattedStatus}
                        status={row.original.statusTo}
                    />
                );
            }
        },
        {
            Header: translations.ONLINK_SERVICE,
            accessor: rowAccessor,
            id: 'service',
            width: 260,
            sortMethod(a, b) {
                return alphaNumericCompare(a.serviceType, b.serviceType);
            },
            Cell(row) {
                if (row.original.serviceId) {
                    return (
                        <EditLink
                            onClick={() => {
                                store.dispatch(openReduxDialog(dialogTypes.SERVICE_FORM_DIALOG, {
                                    onSave: updateEquipment,
                                    serviceId: row.original.serviceId,
                                    translations
                                }));
                            }}
                        >
                            {row.original.serviceType}
                        </EditLink>
                    );
                }
                return '';
            }
        },
        {
            Header: translations.NOTE,
            accessor: rowAccessor,
            id: 'note',
            Cell(row) {
                return (
                    <Tooltip
                        placement='bottom'
                        title={row.original.note}
                    >
                        <div className='basic-table-cell-block'>
                            {row.original.note}
                        </div>
                    </Tooltip>
                );
            }
        },
        {
            Header: translations.HOURS,
            accessor: 'totalHours',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 90,
            Cell(row) {
                return accounting.formatNumber(row.original.totalHours);
            }
        }
    ];
}
