// Unpublished Work © 2023-2024 Deere & Company.

import {
    CLIPPING_YIELD,
    CLIPPING_YIELD_WEIGHT,
    CLIPPING_YIELD_VOLUME,
    FIRMNESS,
    FIRMNESS_F355_EQUIVALENTS,
    FIRMNESS_GRAVITIES
} from 'Common/constants/data-group-constants';
import * as UNIT_NAMES from 'Common/constants/data-unit-constants';

export const UNIT_OF_MEASURE = {
    ENGLISH: 'english',
    METRIC: 'metric'
};

export const CONVERSION_UNIT = {
    CELSIUS: 'C',
    FAHRENHEIT: 'F',
    FEET: 'ft',
    FLOZ: 'floz',
    GAL: 'gal',
    GMAX: 'Gmax',
    INCHES: 'in',
    KG: 'kg',
    KPH: 'km1hr-1',
    L: 'l',
    LB: 'lb',
    MILLIMETER: 'mm',
    MPH: 'mi1hr-1',
    QT: 'qt',
    METERS: 'm'
};

export const CLIPPING_YIELD_TYPES = [
    {
        dataType: CLIPPING_YIELD,
        measurements: [UNIT_NAMES.BASKET]
    },
    {
        dataType: CLIPPING_YIELD_WEIGHT,
        measurements: [UNIT_NAMES.KILOGRAM, UNIT_NAMES.POUNDS]
    },
    {
        dataType: CLIPPING_YIELD_VOLUME,
        measurements: [UNIT_NAMES.LITERS, UNIT_NAMES.QUARTS]
    }
];

export const FIRMNESS_TYPES = [
    {
        dataType: FIRMNESS,
        measurements: [UNIT_NAMES.INCHES, UNIT_NAMES.MM]
    },
    {
        dataType: FIRMNESS_F355_EQUIVALENTS,
        measurements: [UNIT_NAMES.F355_EQUIVALENTS]
    },
    {
        dataType: FIRMNESS_GRAVITIES,
        measurements: [UNIT_NAMES.GRAVITIES]
    }
];

export const CURRENCY_PREFERENCE = {
    USD: 'USD'
};

export const NONE = 'none';
