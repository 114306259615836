// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {createManufacturer, updateManufacturer} from 'Services/manufacturer-service';
import {closeDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {useSave} from 'Ui/react-hooks/use-save';
import {useDeepMemo} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import dialogTypes from 'Ui/components/common/dialog-types';
import {TOAST_TYPE} from '@deere/toast';

function ManufacturerDialog(props) {
    const {
        addToast,
        closeManufacturerDialog,
        existingManufacturers,
        invalidInputs,
        inventoryIdRef,
        manufacturer,
        membershipId,
        onClose,
        setValid,
        translations
    } = props;

    const [manufacturerName, setManufacturerName] = React.useState(() => manufacturer?.name || '');
    const isInvalid = useDeepMemo(
        () => manufacturer?.name !== manufacturerName && existingManufacturers.has(manufacturerName),
        [existingManufacturers, manufacturer, manufacturerName]
    );

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        try {
            const manufacturerData = {
                inventoryId: inventoryIdRef.current,
                membershipId,
                name: manufacturerName
            };

            manufacturer ?
                await updateManufacturer(manufacturer.manufacturerId, manufacturerData) :
                await createManufacturer(manufacturerData);

            onClose();
            closeManufacturerDialog();
        } catch (e) {
            const {message} = e.response.data;

            addToast({
                message: translations[message] || message,
                type: TOAST_TYPE.ERROR
            });
        }
    }, {
        invalidInputs
    });

    const title = manufacturer ? translations.EDIT_TYPE : translations.ONLINK_ADD_ITEM;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeManufacturerDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={replaceTranslationNames(title, {
                '0': translations.MANUFACTURER
            })}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    autoFocus={true}
                    debounceTimeout={window.props.debounceTimeout}
                    errors={{
                        customError: translations.ONLINK_MANUFACTURER_NAME_EXISTS,
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    invalidCustomError={isInvalid}
                    label={translations.MANUFACTURER}
                    name='name'
                    onChange={(event) => setManufacturerName(event.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={manufacturerName}
                />
            </form>
        </FormDialog>
    );
}

ManufacturerDialog.propTypes = {
    addToast: PropTypes.func,
    closeManufacturerDialog: PropTypes.func,
    existingManufacturers: PropTypes.instanceOf(Set),
    invalidInputs: PropTypes.instanceOf(Set),
    inventoryIdRef: PropTypes.reference,
    manufacturer: PropTypes.object,
    membershipId: PropTypes.string,
    onClose: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeManufacturerDialog() {
            dispatch(closeDialog(dialogTypes.MANUFACTURER_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(ManufacturerDialog));
