// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import EquipmentName from 'OnEquip/equipment/common/data-table/equipment-name';
import ServiceStatus from 'OnEquip/equipment/common/data-table/service-status';
import {getMaintenanceDue} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatDateOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {
    alphaNumericCompare,
    dateCompare,
    formatAssignees,
    formatModelAndManufacturerName, genericServiceCompare,
    numericCompare
} from 'Ui/models/maintenance';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {MAINTENANCE} from 'OnEquip/equipment/constants/equipment-details-tabs';
import {SHOP} from 'Common/constants/routes';
import {IN_PROCESS, SCHEDULED} from 'Common/constants/service-status';
import accounting from 'accounting';
import {VIEW_WORKBOARDS} from 'Common/constants/business-activities';

function getColumns(translations, membershipId, history) {
    const onequipUrl = `${window.props.onLinkLegacyUri}/onequip/#m/${membershipId}/fleet/{0}/shop`;
    const equipmentUrl = `${onequipUrl}/equipment/{1}`;
    const serviceUrl = `${SHOP}/serviceForm?serviceId={0}`;

    return [
        {
            Header: translations.DATE,
            accessor(row) {
                return row;
            },
            id: 'formattedDate',
            width: 100,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'dateScheduled');
            },
            Cell(row) {
                return row.original.formattedDate;
            }
        },
        {
            Header: translations.STATUS,
            accessor: 'status',
            width: 100,
            Cell(row) {
                return (
                    <ServiceStatus
                        status={row.original.status}
                        translations={translations}
                    />
                );
            }
        },
        {
            Header: translations.ONLINK_ASSIGNEES,
            accessor: 'assignees',
            sortMethod: alphaNumericCompare,
            requiredMyJdPermissions: VIEW_WORKBOARDS
        },
        {
            Header: translations.EQUIPMENT_LIST,
            accessor: 'equipmentName',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                return (
                    <EquipmentName
                        equipment={row.original}
                        tab={MAINTENANCE}
                        type={SHOP}
                        url={equipmentUrl}
                    />
                );
            }
        },
        {
            Header: translations.MODEL,
            accessor: 'modelAndManufacturerName',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.TYPE,
            accessor: 'equipmentTypeName',
            sortMethod: alphaNumericCompare,
            width: 140
        },
        {
            Header: translations.ONLINK_GROUP,
            accessor: 'serviceGroup',
            sortMethod: alphaNumericCompare,
            width: 130
        },
        {
            Header: translations.ONLINK_SERVICE,
            accessor: 'serviceTypeName',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                const {original: service} = row;

                const filledInServiceUrl = serviceUrl.replace('{0}', service.serviceId);

                return (
                    <EditLink onClick={() => history.push(filledInServiceUrl)}>
                        {service.serviceTypeName}
                    </EditLink>
                );
            }
        },
        {
            Header: replaceTranslationNames(translations.ONLINK_EST_WITH_UNIT, {
                '0': translations.mins
            }),
            accessor: 'estDuration',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod: numericCompare,
            width: 120,
            Cell(row) {
                return accounting.formatNumber(row.original.estDuration, 0);
            }
        },
        {
            Header: replaceTranslationNames(translations.ONLINK_ACT_WITH_UNIT, {
                '0': translations.mins
            }),
            accessor: 'actualDuration',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod: numericCompare,
            width: 120,
            Cell(row) {
                return accounting.formatNumber(row.original.actualDuration, 0);
            }
        }
    ];
}

function Shop(props) {
    const {
        history,
        membershipId,
        translations
    } = props;

    const [services, setServices] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const equipmentContext = useEquipmentContext();

    equipmentContext.current = async (isMounted = () => true) => {
        setLoading(true);

        const services = await getMaintenanceDue(`${IN_PROCESS},${SCHEDULED}`);

        if (isMounted()) {
            const formattedServices = services.map((service) => ({
                ...service,
                assignees: formatAssignees(service),
                formattedDate: formatDateOrDefault(service.dateScheduled),
                modelAndManufacturerName: formatModelAndManufacturerName(service)
            }));

            setServices(formattedServices);
            setLoading(false);
        }
    };

    React.useEffect(() => fetchEffectData(equipmentContext.current), [membershipId]);

    return (
        <DataTable
            columns={getColumns(translations, membershipId, history)}
            defaultSorted={[{
                desc: false,
                id: 'formattedDate'
            }]}
            loading={loading}
            noDataAvailableMessage={translations.ONLINK_NO_SCHEDULED_OR_IN_PROCESS_SERVICE}
            rows={services}
            searchable={true}
            showPagination={true}
            translations={translations}
        />
    );
}

Shop.propTypes = {
    history: PropTypes.history,
    location: PropTypes.location,
    membershipId: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(withRouter(Shop));
