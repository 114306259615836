// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {GoogleMap} from '@react-google-maps/api';
import {mapOptions} from 'Ui/constants/map-constants';
import GoogleMapControls from 'Ui/components/map/map-elements/map-controls';
import {useDeepEffect} from 'Utils/react-utils';

const ADJUSTMENT_NUMBER = 0.001;
const MAX_LATITUDE = 90;
const MAX_LONGITUDE = 180;

function fixMapSingleMarkerZoomLevel(latLngBounds) {
    const northEastBounds = latLngBounds.getNorthEast();

    if (northEastBounds.equals(latLngBounds.getSouthWest())) {
        const lat = northEastBounds.lat();
        const lng = northEastBounds.lng();

        const extendPoints = [
            {
                lat: lat + ADJUSTMENT_NUMBER > MAX_LATITUDE ? lat : lat + ADJUSTMENT_NUMBER,
                lng: lng + ADJUSTMENT_NUMBER > MAX_LONGITUDE ? lng : lng + ADJUSTMENT_NUMBER
            },
            {
                lat: lat - ADJUSTMENT_NUMBER < MAX_LATITUDE * -1 ? lat : lat - ADJUSTMENT_NUMBER,
                lng: lng - ADJUSTMENT_NUMBER < MAX_LONGITUDE * -1 ? lng : lng - ADJUSTMENT_NUMBER
            }
        ];

        extendPoints.forEach((extendPoint) => latLngBounds.extend(extendPoint));
    }

    return latLngBounds;
}

function Map(props) {
    const {
        children,
        loading,
        map,
        mapBounds,
        setMap,
        translations
    } = props;

    const [latLngBounds, setLatLngBounds] = React.useState(() => new google.maps.LatLngBounds());

    useDeepEffect(() => {
        if (!loading && map && mapBounds.length > 0) {
            const newLatLngBounds = mapBounds.reduce((bounds, point) => bounds.extend(point), new google.maps.LatLngBounds());

            fixMapSingleMarkerZoomLevel(newLatLngBounds);
            setLatLngBounds(newLatLngBounds);

            map.fitBounds(newLatLngBounds);
        }
    }, [map, mapBounds, loading]);

    return (
        <div className='map'>
            <GoogleMap
                mapContainerStyle={{
                    height: '100%'
                }}
                onLoad={setMap}
                options={mapOptions}
            >
                {children}
                <GoogleMapControls
                    latLngBounds={latLngBounds}
                    translations={translations}
                />
            </GoogleMap>
        </div>
    );
}

Map.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    map: PropTypes.map,
    mapBounds: PropTypes.arrayOf(PropTypes.mapPosition),
    setMap: PropTypes.func,
    translations: PropTypes.translations
};

export default Map;
