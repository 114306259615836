// Unpublished Work © 2023-2024 Deere & Company.

import {datadogRum} from '@datadog/browser-rum';

const rumEnvironments = ['qual', 'cert', 'prod'];

export function initRum({
    env, ddVersion
}) {
    if (rumEnvironments.includes(env)) {
        datadogRum.init({
            applicationId: '27c4da40-3c3d-4784-b7b4-369da7e9ec95',
            clientToken: 'pubc49485a5741d82dca985047f5d8c7796',
            site: 'datadoghq.com',
            service: 'onlink',
            env,
            version: ddVersion,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackFrustrations: true,
            defaultPrivacyLevel: 'mask-user-input',
            allowedTracingUrls: [
                /^https:\/\/[a-zA-Z0-9.-]*\.deere.com/,
                /^https:\/\/[a-zA-Z0-9.-]*\.johndeerecloud.com/
            ]
        });

        datadogRum.setGlobalContextProperty('jd_component', 'onlink');
    }
}
