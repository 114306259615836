// Unpublished Work © 2022-2024 Deere & Company.

import {IconCircleAdd, IconCircleMinus, IconSwap} from '@deere/icons';
import {createIconFillStyle} from 'Utils/icon-utils';
import ActionsColumn from 'Ui/components/common/data-table/actions-column';
import React from 'react';

const ICON_STYLE = {
    style: {
        height: '12px',
        width: '12px',
        marginLeft: '5px'
    }
};

function getActionColumn(
    row,
    openTransferInventoryDialog,
    openPullInventoryDialog,
    openStockInventoryDialog,
    membershipHierarchy,
    partsData,
    dialogOnClose
) {
    const stockGreaterThanZero = row.original.quantity > 0;

    const actions = [];

    if (membershipHierarchy.children?.length > 0 && stockGreaterThanZero) {
        actions.push({
            buttonClassName: 'inline-icon-btn part-actions',
            iconComponent: IconSwap,
            iconProps: {
                iconSwap: ICON_STYLE,
                primary: createIconFillStyle('#357edd')
            },
            onClick: () => openTransferInventoryDialog({
                availableParts: partsData.formattedPartsFromInventoryBins,
                initialSelectedPart: row.original,
                inventories: partsData.inventories,
                membershipHierarchy,
                onClose: dialogOnClose
            })
        });
    }

    if (stockGreaterThanZero) {
        actions.push({
            buttonClassName: 'inline-icon-btn part-actions',
            iconComponent: IconCircleMinus,
            iconProps: {
                iconCircleMinus: ICON_STYLE,
                primary: createIconFillStyle('#ff4136')
            },
            onClick: () => openPullInventoryDialog({
                availableParts: partsData.formattedPartsFromInventoryBins,
                initialSelectedPart: row.original,
                inventories: partsData.inventories,
                onClose: dialogOnClose
            })
        });
    }

    actions.push({
        buttonClassName: 'inline-icon-btn part-actions',
        iconComponent: IconCircleAdd,
        iconProps: {
            iconCircleAdd: ICON_STYLE,
            primary: createIconFillStyle('#19a974')
        },
        onClick: () => openStockInventoryDialog({
            availableParts: partsData.parts,
            initialSelectedPart: row.original,
            inventories: partsData.inventories,
            onClose: dialogOnClose
        })
    });

    return (
        <ActionsColumn actions={actions}/>
    );
}

export {
    getActionColumn
};
