// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import PartDialogForm from 'OnEquip/common/part-dialog-form';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import {useSave} from 'Ui/react-hooks/use-save';
import {asNumber} from 'Utils/conversion-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {getManufacturers, getPartTypes} from 'Services/inventory-service';
import {createPart, updatePart} from 'Services/parts-service';
import dialogTypes from 'Ui/components/common/dialog-types';
import {sortBy} from 'lodash';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {TOAST_TYPE} from '@deere/toast';

async function getPartTypesForInventory(inventories) {
    const partTypesPromises = inventories.map(async (inventory) => {
        const {partTypes} = await getPartTypes(inventory.inventoryId);

        return partTypes.map((partType) => ({
            ...partType,
            id: partType.partTypeId
        }));
    });

    const allInventoryPartTypes = await Promise.all(partTypesPromises);

    const combinedInventoryPartTypes = allInventoryPartTypes
        .reduce((combined, inventoryPartTypes) => combined.concat(inventoryPartTypes), []);

    return sortBy(combinedInventoryPartTypes, (item) => item.title?.toString().toLowerCase());
}

async function getManufacturersForInventory(inventories, part) {
    const {manufacturers} = await getManufacturers();

    const combinedInventoryManufacturers = manufacturers.map((manufacturer) => ({
        ...manufacturer,
        id: manufacturer.manufacturerId,
        title: manufacturer.name
    }));

    if (part && combinedInventoryManufacturers.every(({manufacturerId}) => manufacturerId !== part.manufacturerId)) {
        combinedInventoryManufacturers.push({
            ...part,
            id: part.manufacturerId,
            title: part.manufacturerName
        });
    }

    const filteredManufacturers = combinedInventoryManufacturers.filter(({title}) => title);

    return {
        allManufacturers: filteredManufacturers,
        membershipManufacturers: filteredManufacturers.filter(({inventoryId}) => inventoryId === inventories[0].inventoryId)
    };
}

function switchShowManufacturersToggle(setShowOnlyMyManufacturers, showOnlyMyManufacturers, setValues, manufacturerCollection, selectedManufacturerId) {
    const nextToggleValue = !showOnlyMyManufacturers;

    if (nextToggleValue) {
        const selectedModelFound = manufacturerCollection.membershipManufacturers.some(({id}) => id === selectedManufacturerId);

        if (!selectedModelFound) {
            setValues((prevValues) => ({
                ...prevValues,
                manufacturerId: null
            }));
        }
    }

    setShowOnlyMyManufacturers(nextToggleValue);
}

function initializeState({
    manufacturerId = null,
    name = '',
    restockTarget = '',
    partNumber = '',
    thresholdLevel = ''
}) {
    const [values, setValues] = React.useState(() => ({
        manufacturerId,
        name,
        restockTarget,
        partNumber,
        partTypeId: null,
        thresholdLevel
    }));

    const [manufacturerCollection, setManufacturerCollection] = React.useState({
        allManufacturers: [],
        membershipManufacturers: []
    });
    const [partTypeCollection, setPartTypesCollection] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showOnlyMyManufacturers, setShowOnlyMyManufacturers] = React.useState(true);

    return {
        loading,
        manufacturerCollection,
        partTypeCollection,
        setLoading,
        setManufacturerCollection,
        setPartTypesCollection,
        setValues,
        values,
        showOnlyMyManufacturers,
        setShowOnlyMyManufacturers
    };
}

function PartDialog(props) {
    const {
        addToast,
        allParts,
        closeDialog,
        invalidInputs,
        inventories,
        onPartSave,
        part,
        setValid,
        translations
    } = props;

    const {
        loading,
        manufacturerCollection,
        partTypeCollection,
        setLoading,
        setManufacturerCollection,
        setPartTypesCollection,
        setValues,
        values,
        showOnlyMyManufacturers,
        setShowOnlyMyManufacturers
    } = initializeState(part || {});

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const [inventoryPartTypes, inventoryManufacturers] = await Promise.all([
            getPartTypesForInventory(inventories),
            getManufacturersForInventory(inventories, part)
        ]);

        if (isMounted()) {
            const initialPartType = part &&
                inventoryPartTypes.find(({title}) => title === part.partType) ||
                inventoryPartTypes[0];

            setValues((prevValues) => ({
                ...prevValues,
                partTypeId: initialPartType ? initialPartType.id : null
            }));

            setPartTypesCollection(inventoryPartTypes);
            setManufacturerCollection(inventoryManufacturers);
            setLoading(false);
        }
    }), [inventories]);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const partTypeSelected = partTypeCollection.find(({id}) => id === values.partTypeId);
        const manufacturerSelected = manufacturerCollection.allManufacturers.find(({id}) => id === values.manufacturerId);

        const partData = {
            ...values,
            inventoryId: partTypeSelected.inventoryId,
            manufacturerName: manufacturerSelected.name,
            partType: partTypeSelected.title,
            restockTarget: asNumber(values.restockTarget),
            thresholdLevel: asNumber(values.thresholdLevel)
        };

        const partAlreadyExists = allParts.find(({
            partNumber, manufacturerId
        }) => partNumber === partData.partNumber && manufacturerId === partData.manufacturerId);

        if (partAlreadyExists) {
            addToast({
                message: translations.ONLINK_PART_EXISTS,
                type: TOAST_TYPE.ERROR
            });
        } else {
            const {part: savedPart} = part ?
                await updatePart(part.partId, partData) :
                await createPart(partData);

            onPartSave(savedPart);

            closeDialog();
        }
    }, {
        disabled: loading,
        invalidInputs
    });

    const title = part ?
        replaceTranslationNames(translations.EDIT_TYPE, {
            '0': translations.ONLINK_PART
        }) :
        translations.ONLINK_NEW_PART;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={title}
            translations={translations}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                {
                    !part &&
                    <SwitchInput
                        checked={showOnlyMyManufacturers}
                        className={'manufacturer-toggle'}
                        name='showOnlyMyManufacturers'
                        offLabel={`${capitalizeFirstLetter(translations.ONLINK_ONLY_MY_MANUFACTURERS)}`}
                        onChange={() => switchShowManufacturersToggle(setShowOnlyMyManufacturers, showOnlyMyManufacturers, setValues, manufacturerCollection, values.manufacturerId)}
                        translations={translations}
                    />
                }
                <PartDialogForm
                    allManufacturers={manufacturerCollection.allManufacturers}
                    inventories={inventories}
                    isEdit={Boolean(part)}
                    manufacturerCollection={showOnlyMyManufacturers && !part ? manufacturerCollection.membershipManufacturers : manufacturerCollection.allManufacturers}
                    onSave={saveFunc}
                    partTypeCollection={partTypeCollection}
                    setValid={setValid}
                    setValues={setValues}
                    translations={translations}
                    updateManufacturers={async () => {
                        setLoading(true);

                        const inventoryManufacturers = await getManufacturersForInventory(inventories, part);

                        setManufacturerCollection(inventoryManufacturers);
                        setLoading(false);
                    }}
                    updatePartTypes={async () => {
                        setLoading(true);

                        const inventoryPartTypes = await getPartTypesForInventory(inventories);

                        setPartTypesCollection(inventoryPartTypes);
                        setLoading(false);
                    }}
                    values={values}
                />
            </LoadingWrapper>
        </FormDialog>
    );
}

PartDialog.defaultProps = {
    allParts: []
};

PartDialog.propTypes = {
    addToast: PropTypes.func,
    allParts: PropTypes.arrayOf(PropTypes.object),
    closeDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    inventories: PropTypes.arrayOf(PropTypes.object),
    onPartSave: PropTypes.func,
    part: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.PART_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(PartDialog));
