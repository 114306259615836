// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Pie} from 'react-chartjs-2';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {sortBy} from 'lodash';

function sortData(name) {
    return name?.toLowerCase()?.trim();
}

function getDatasetAndLabels(chartDataForSelector, tertiarySelector) {
    const statusToDaysMap = chartDataForSelector.reduce((statusMap, chartData) => {
        const {
            name,
            optionMap
        } = chartData;

        if (!optionMap || name !== tertiarySelector) {
            return statusMap;
        }

        return Object.keys(optionMap).reduce((daysForStatus, status) => {
            if (!statusMap.has(status)) {
                statusMap.set(status, 0);
            }

            const currentDays = statusMap.get(status);

            statusMap.set(status, currentDays + optionMap[status]);

            return statusMap;
        }, statusMap);
    }, new Map());

    const sortedStatuses = sortBy(Array.from(statusToDaysMap.keys()), sortData);

    const dataset = sortedStatuses.reduce((statusDataset, status, index) => {
        statusDataset.backgroundColor.push(GRAPH_COLORS[index]);
        statusDataset.data.push(statusToDaysMap.get(status));

        return statusDataset;
    }, {
        backgroundColor: [],
        data: []
    });

    return {
        datasets: [dataset],
        labels: sortedStatuses
    };
}

function WorkboardPieChart(props) {
    const {
        chartDataForSelector,
        tertiarySelector,
        translations
    } = props;

    return (
        <Pie
            data={getDatasetAndLabels(chartDataForSelector, tertiarySelector)}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(tooltipItem) {
                                const {
                                    label,
                                    parsed
                                } = tooltipItem;

                                return `${label}: ${parsed} ${translations.DAYS}`;
                            }
                        }
                    }
                }
            }}
            type='pie'
        />
    );
}

WorkboardPieChart.propTypes = {
    chartDataForSelector: PropTypes.arrayOf(PropTypes.object),
    tertiarySelector: PropTypes.string,
    translations: PropTypes.translations
};

export default WorkboardPieChart;
