// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddButton from 'Ui/components/settings/common/add-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {deleteJobCategoryById, getJobTemplates} from 'Services/job-service';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import dialogTypes from 'Ui/components/common/dialog-types';
import {TOAST_TYPE} from '@deere/toast';

function getColumns(translations, jobCategories, openJobCategoryDialog, fetchJobTemplates) {
    return [
        {
            Header: translations.ONLINK_JOB_CATEGORY,
            accessor: 'title',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openJobCategoryDialog({
                            jobCategory: original,
                            membershipJobCategories: jobCategories,
                            updateJobCategories: fetchJobTemplates
                        })}
                    >
                        {value}
                    </EditLink>
                );
            }
        }
    ];
}

function getFilterComponent(translations, jobCategories, openJobCategoryDialog, fetchJobTemplates) {
    return (
        <div className='extra-table-options'>
            <AddButton
                className='add-job-category-button'
                label={replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                    '0': translations.ONLINK_JOB_CATEGORY
                })}
                onClick={() => openJobCategoryDialog({
                    jobCategory: null,
                    membershipJobCategories: jobCategories,
                    updateJobCategories: fetchJobTemplates
                })}
            />
        </div>
    );
}

function JobCategoriesSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        openJobCategoryDialog,
        translations
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [jobCategories, setJobCategories] = React.useState([]);
    const [jobTemplates, setJobTemplates] = React.useState([]);

    async function fetchJobTemplates(isMounted = () => true) {
        setLoading(true);

        const {
            templates, jobCategories
        } = await getJobTemplates();

        if (isMounted()) {
            setJobCategories(jobCategories);
            setJobTemplates(templates);
            setLoading(false);
        }
    }

    function deleteJobCategory(jobCategory) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_JOB_CATEGORY.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_JOB_CATEGORY
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteJobCategoryById(jobCategory.jobCategoryId);

                    await fetchJobTemplates();
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.ONLINK_JOB_CATEGORY.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    useDeepEffect(() => fetchEffectData(fetchJobTemplates), []);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, jobCategories, openJobCategoryDialog, fetchJobTemplates)}
                defaultSorted={[{
                    desc: false,
                    id: 'title'
                }]}
                deleteFilter={(jobCategory) => !jobTemplates.some((template) => template.jobCategory === jobCategory.title)}
                deleteHandler={deleteJobCategory}
                filterComponent={getFilterComponent(translations, jobCategories, openJobCategoryDialog, fetchJobTemplates)}
                loading={loading}
                rows={jobCategories}
                searchable={true}
                showDeleteColumn={true}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

JobCategoriesSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openJobCategoryDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openJobCategoryDialog(props) {
            dispatch(openDialog(dialogTypes.JOB_CATEGORY_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCategoriesSettings);
