// Unpublished Work © 2022-2024 Deere & Company.

import {isNullOrUndefined} from 'Common/utils/validation-utils';

function updateWorkItem(propertyName, value, workItems, workItemIndex) {
    const currentWorkItem = workItems[workItemIndex];

    if (currentWorkItem[propertyName] === value || isNullOrUndefined(value)) {
        const {
            [propertyName]: removedValue,
            ...updatedWorkItem
        } = currentWorkItem;

        workItems[workItemIndex] = updatedWorkItem;
    } else {
        currentWorkItem[propertyName] = value;
    }
}

function setEquipmentProperties(equipment, selectedEquipment, selectedEquipmentId) {
    equipment.equipmentId = selectedEquipmentId;
    equipment.equipmentName = selectedEquipment.equipmentName;
    equipment.manufacturerName = selectedEquipment.manufacturerName;
    equipment.modelName = selectedEquipment.modelName;
    equipment.status = selectedEquipment.status;
}

function updateWorkItemEquipment({
    equipmentIndex,
    equipmentInUseMap,
    selectedEquipment,
    selectedEquipmentId,
    workItem
}) {
    const equipment = workItem.equipment[equipmentIndex];

    if (!isNullOrUndefined(selectedEquipmentId)) {
        equipmentInUseMap.set(equipment.equipmentId, null);
        equipmentInUseMap.set(selectedEquipmentId, workItem.workItemId);

        setEquipmentProperties(equipment, selectedEquipment, selectedEquipmentId);
    } else {
        const {
            equipmentId, equipmentName, manufacturerName, modelName, status, ...updatedEquipment
        } = equipment;

        equipmentInUseMap.set(equipmentId, null);
        workItem.equipment[equipmentIndex] = updatedEquipment;
    }
}

export {
    updateWorkItem,
    updateWorkItemEquipment
};
