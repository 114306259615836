// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Footer from 'Ui/components/footer/footer';
import Map from 'Ui/components/map/map-elements/map';
import {defaultCaryNCLatLong} from 'Ui/constants/map-constants';
import {useDeepMemo} from 'Utils/react-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

function MapWrapper(props) {
    const {
        children,
        itemLocations,
        loading,
        map,
        playbackControls,
        setMap,
        translations,
        isMigrated
    } = props;

    const mapBounds = useDeepMemo(() => {
        const mapBoundsForItems = itemLocations.map((location) => ({
            lat: location.lat,
            lng: isNullOrUndefined(location.lng) ? location.lon : location.lng
        }));

        if (mapBoundsForItems.length === 0) {
            mapBoundsForItems.push(defaultCaryNCLatLong);
        }

        return mapBoundsForItems;
    }, [itemLocations]);

    return (
        <div className='map-wrapper'>
            <Map
                loading={loading}
                map={map}
                mapBounds={mapBounds}
                setMap={setMap}
                translations={translations}
            >
                {children}
            </Map>
            {
                !isMigrated &&
                <Footer
                    hideContent={true}
                    translations={translations}
                />
            }
            {playbackControls}
        </div>
    );
}

MapWrapper.propTypes = {
    children: PropTypes.node,
    isMigrated: PropTypes.bool,
    itemLocations: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    map: PropTypes.map,
    playbackControls: PropTypes.element,
    setMap: PropTypes.func,
    translations: PropTypes.translations
};

export default MapWrapper;
