// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import InputList from 'Ui/components/common/form/input-list';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {onManualDataChange} from 'Ui/components/manual-data/manual-data-utils';
import {isEmptyString} from 'Common/utils/validation-utils';
const VALUE_MIN = 0;
const VALUE_MAX = 2000000000000;

function WaterFlowInputList(props) {
    const {
        addItemLabel,
        dataUnit,
        headerLabel,
        isInvalid,
        name,
        onChange,
        setValid,
        setValues,
        translations,
        values
    } = props;

    return (
        <InputList
            addItemLabel={addItemLabel}
            headerLabel={headerLabel}
            listClassName='end-margin'
            onAdd={(event) => {
                event.preventDefault();

                onManualDataChange({
                    dataUnit,
                    name,
                    setValues,
                    value: values.concat({
                        'data_value': '',
                        sub: ''
                    })
                });
            }}
            onDelete={(event, index) => {
                event.preventDefault();

                onManualDataChange({
                    dataUnit,
                    name,
                    setValues,
                    value: values.filter((value, i) => i !== index)
                });
            }}
        >
            {
                values.map((value, index) => {
                    const {
                        data_value: dataValue,
                        sub: location
                    } = value;

                    const missingDataValue = isEmptyString(dataValue);
                    const missingLocation = isEmptyString(location);

                    return (
                        <React.Fragment key={`${name}${index}`}>
                            <ValidationInput
                                debounceTimeout={window.props.debounceTimeout}
                                errors={{
                                    customError: translations.REQUIRED_FIELD_TEXT
                                }}
                                invalidCustomError={isInvalid || !missingDataValue && missingLocation}
                                label={translations.ONLINK_LOCATION}
                                name={`${name}_sub${index}`}
                                onChange={(event) => onChange(event.target.value, 'sub', index)}
                                setValid={setValid}
                                tabIndex={0}
                                type='text'
                                value={location}
                            />
                            <ValidationInput
                                debounceTimeout={window.props.debounceTimeout}
                                errors={{
                                    customError: translations.REQUIRED_FIELD_TEXT,
                                    rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${VALUE_MAX}`,
                                    rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${VALUE_MIN}`
                                }}
                                invalidCustomError={isInvalid || missingDataValue && !missingLocation}
                                label={`${translations['Observation.QUANTITY']} (${translations[dataUnit]})`}
                                max={VALUE_MAX}
                                min={VALUE_MIN}
                                name={`${name}_data_value${index}`}
                                onChange={(event) => onChange(event.target.value, 'data_value', index)}
                                setValid={setValid}
                                step='any'
                                tabIndex={0}
                                type='number'
                                value={dataValue}
                            />
                        </React.Fragment>
                    );
                })
            }
        </InputList>
    );
}

WaterFlowInputList.propTypes = {
    addItemLabel: PropTypes.string,
    dataUnit: PropTypes.string,
    headerLabel: PropTypes.string,
    isInvalid: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.arrayOf(PropTypes.object)
};

export default WaterFlowInputList;
