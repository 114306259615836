// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import WorkBoardWeather from 'OnLabor/my-work/work-board-tile/work-board-weather';
import {formatLocalizedTime} from 'Utils/time-utils';
import {sortBy} from 'lodash';
import moment from 'moment';

function getFormattedLocalizedTime(dateSelect) {
    const date = moment(dateSelect).toDate();

    const formattedDate = formatLocalizedTime(date, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    const formattedTime = formatLocalizedTime(date, {
        weekday: 'long'
    });

    return `${formattedDate} - ${formattedTime}`;
}

function formatWorkboardButtons(workboards, translations, setWorkboardInDialog) {
    const sortedWorkboards = sortBy(workboards, 'startTime');

    return sortedWorkboards.map((workboard) => (
        <div
            className='work-board-item'
            key={workboard.workboardId}
        >
            <span className={'work-item-info bold-body-text-section-title'}>
                {workboard.name}
            </span>
            <OnlinkButton
                className='primary'
                onClick={() => setWorkboardInDialog(workboard)}
            >
                {translations.VIEW}
            </OnlinkButton>
        </div>
    ));
}

function WorkBoardTileItem(props) {
    const {
        setWorkboardInDialog, translations, weather, workboards
    } = props;

    const {dateSelect} = workboards[0];

    return (
        <>
            <div className={'work-board-title'}>
                <span className='bold-body-text-section-title'>{getFormattedLocalizedTime(dateSelect)}</span>
                <div>
                    <WorkBoardWeather
                        date={dateSelect}
                        weather={weather}
                    />
                </div>
            </div>
            <div>
                {formatWorkboardButtons(workboards, translations, setWorkboardInDialog)}
            </div>
        </>
    );
}

WorkBoardTileItem.propTypes = {
    setWorkboardInDialog: PropTypes.func,
    translations: PropTypes.translations,
    weather: PropTypes.object,
    workboards: PropTypes.arrayOf(PropTypes.object)
};

export default WorkBoardTileItem;
