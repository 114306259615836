// Unpublished Work © 2020-2024 Deere & Company.

export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP';

export function updateMembership(membership) {
    return {
        type: UPDATE_MEMBERSHIP,
        membership
    };
}
