// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import React from 'react';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {EMPLOYEE_STATUS_COLORS} from 'Common/constants/valid-hex-color';
import {MultiSelect} from '@deere/form-controls';

function getEmployeeStatusColors() {
    return EMPLOYEE_STATUS_COLORS.map((color) => {
        return {
            id: color,
            Icon: <span className={`color-item ${color}`}/>
        };
    });
}

function EditableEmployeeStatusSettings(props) {
    const {
        setValid,
        translations,
        status,
        setStatus,
        color,
        setColor,
        isAvailable,
        setIsAvailable
    } = props;

    return (
        <form className='employee-status-dialog-form'>
            <div className='settings-group mobile'>
                <ValidationInput
                    label={translations.STATUS}
                    name='status'
                    onChange={(event) => setStatus(event.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={status}
                />
                <MultiSelect
                    className='multiselect color'
                    containerHeight={250}
                    hideFilter={true}
                    items={getEmployeeStatusColors()}
                    label={translations.ONLINK_COLOR}
                    multiple={false}
                    name='color'
                    onChange={(selectedIds) => {
                        const selectedColorId = selectedIds[0];

                        if (selectedColorId) {
                            setColor(selectedColorId);
                        }
                    }}
                    selectedIds={[color]}
                    tabIndex={0}
                />
            </div>
            <div className='settings-group mobile'>
                <SwitchInput
                    checked={isAvailable}
                    name='isAvailable'
                    onChange={() => setIsAvailable(!isAvailable)}
                    onLabel={translations.ONLINK_IS_AVAILABLE_FOR_WORK}
                    translations={translations}
                />
            </div>
        </form>
    );
}

EditableEmployeeStatusSettings.propTypes = {
    color: PropTypes.string,
    isAvailable: PropTypes.bool,
    setColor: PropTypes.func,
    setIsAvailable: PropTypes.func,
    setStatus: PropTypes.func,
    setValid: PropTypes.func,
    status: PropTypes.string,
    translations: PropTypes.translations
};

export default EditableEmployeeStatusSettings;
