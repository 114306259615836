// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiselectFilterList} from '@deere/isg.component-library';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {useDeepMemo} from 'Utils/react-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {cloneDeep} from 'lodash';
import dialogTypes from 'Ui/components/common/dialog-types';
import {closeDialog, openDialog} from 'Store/actions/dialogs';

function subtractWorkHoursForJobs(jobsToDeselect, operators, setValid) {
    jobsToDeselect.forEach((deselectedJob) => {
        deselectedJob.workItems.forEach((workItem) => {
            setValid(`operator-${workItem.workItemId}`, true);

            if (workItem.appUserId) {
                const operatorForWorkItem = operators?.find((operator) => operator.appUserId === workItem.appUserId);

                if (operatorForWorkItem) {
                    operatorForWorkItem.minutesWorkedForWeek -= workItem.actualDuration;
                }
            }
        });
    });
}

function SelectJobsCategoryInputs(props) {
    const {
        closeConfirmation,
        mappedTemplates,
        membership,
        openConfirmation,
        setOperators,
        setValid,
        setValues,
        templatesById,
        translations,
        values
    } = props;

    const jobCategories = Object.keys(values.jobs);

    const highestSeq = useDeepMemo(() => jobCategories.reduce((maxSeq, jobCategory) => {
        const {[jobCategory]: selectedJobsForCategory} = values.jobs;

        return selectedJobsForCategory.reduce((jobCategoryMaxSeq, {seq = 0}) => Math.max(jobCategoryMaxSeq, seq), maxSeq);
    }, 0), [values.jobs]);

    const selectedJobs = jobCategories.reduce((jobs, jobCategory) => {
        const {[jobCategory]: selectedJobsForCategory} = values.jobs;

        jobs.push(...selectedJobsForCategory);

        return jobs;
    }, []);

    const selectedIds = selectedJobs.map((job) => job.jobTemplateId);

    function setOperatorsAndValues(ids, jobsToDeselect) {
        setOperators((prevOperators) => {
            const clonedOperators = cloneDeep(prevOperators.operatorsMasterList);

            subtractWorkHoursForJobs(jobsToDeselect, clonedOperators, setValid);

            return {
                ...prevOperators,
                operatorsMasterList: clonedOperators
            };
        });

        setValues((prevValues) => {
            const newValues = {
                ...prevValues,
                jobs: {
                    ...prevValues.jobs
                }
            };

            let seqIndex = 1;

            newValues.jobs = ids.reduce((templatesByCategoryMap, jobTemplateId) => {
                const jobTemplate = templatesById.get(jobTemplateId);
                const {[jobTemplate.jobCategory]: jobsForCategory = [] } = prevValues.jobs;

                const existingJob = jobsForCategory?.find((job) => job.jobTemplateId === jobTemplateId);

                const seq = isNullOrUndefined(existingJob?.seq) ? highestSeq + seqIndex : existingJob.seq;

                if (!templatesByCategoryMap[jobTemplate.jobCategory]) {
                    templatesByCategoryMap[jobTemplate.jobCategory] = [];
                }

                if (isNullOrUndefined(seq)) {
                    seqIndex += 1;
                }

                templatesByCategoryMap[jobTemplate.jobCategory].push({
                    estDuration: jobTemplate.estDuration,
                    jobTemplate,
                    jobTemplateId,
                    seq,
                    startTime: membership.properties.workboard_default_start_time,
                    title: jobTemplate.title,
                    workItems: existingJob?.workItems || [],
                    jobId: existingJob?.jobId
                });

                return templatesByCategoryMap;
            }, {});

            return newValues;
        });
    }

    function onJobChange(ids) {
        const jobsToDeselect = selectedJobs
            .filter((selectedJob) => !ids.includes(selectedJob.jobTemplateId));
        const deselectedJobHasWorkItems = jobsToDeselect.length > 0 &&
            jobsToDeselect.some((deselectedJob) => deselectedJob.workItems.length > 0);

        if (deselectedJobHasWorkItems) {
            const message = jobsToDeselect.length > 1 ? translations.ONLINK_DESELECT_ALL_JOBS_WARNING : translations.ONLINK_REMOVE_JOB_WARNING;

            openConfirmation({
                title: translations.ONLINK_REMOVE_JOB,
                message,
                onContinue: () => {
                    setOperatorsAndValues(ids, jobsToDeselect);
                    closeConfirmation();
                },
                onCancel: () => {
                    closeConfirmation();
                }
            });
        } else {
            setOperatorsAndValues(ids, jobsToDeselect);
        }
    }

    return (
        <NoDataMessage
            hasData={mappedTemplates.length > 0}
            noDataMessage={translations.ONLINK_NO_JOB_TEMPLATES_FOUND}
        >
            <MultiselectFilterList
                emptyListLabel={translations.ONLINK_SELECT_JOBS}
                items={mappedTemplates}
                noItemMatchLabel={translations.ONLINK_SELECT_JOBS}
                onChange={onJobChange}
                selectAllItemLabel={translations.SELECT_ALL}
                selectedIds={selectedIds}
            />
        </NoDataMessage>
    );
}

SelectJobsCategoryInputs.propTypes = {
    closeConfirmation: PropTypes.func,
    mappedTemplates: PropTypes.arrayOf(PropTypes.object),
    membership: PropTypes.membership,
    openConfirmation: PropTypes.func,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    templatesById: PropTypes.instanceOf(Map),
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectJobsCategoryInputs);
