// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {matchPath, withRouter} from 'react-router-dom';
import TertiaryNavBar from 'Ui/components/tertiary-navbar/tertiary-navbar';
import {BASE_ROUTES} from 'Ui/constants/routes/base-route-config';
import {getTertiaryNavItemsByRoute} from 'Ui/constants/tertiary-navbar/items-by-route';

const ROUTE_CONFIGS = Object.values(BASE_ROUTES);

function TertiaryNavBarWrapper(props) {
    const {
        location: {pathname},
        translations,
        isMigrated,
        hasSubscription
    } = props;

    const matchedRouteConfig = React.useMemo(
        () => ROUTE_CONFIGS.find((routeConfig) => matchPath(pathname, routeConfig)),
        [pathname]
    );

    if (!matchedRouteConfig) {
        return null;
    }

    const tertiaryNavItems = getTertiaryNavItemsByRoute(matchedRouteConfig.path, isMigrated);

    if (!tertiaryNavItems) {
        return null;
    }

    const {
        childComponent: ChildComponent,
        ...navBarProps
    } = tertiaryNavItems;

    return (
        <TertiaryNavBar
            {...navBarProps}
            translations={translations}
        >
            {
                ChildComponent &&
                <ChildComponent
                    hasSubscription={hasSubscription}
                    isMigrated={isMigrated}
                    translations={translations}
                />
            }
        </TertiaryNavBar>
    );
}

TertiaryNavBarWrapper.propTypes = {
    hasSubscription: PropTypes.bool,
    isMigrated: PropTypes.bool,
    location: PropTypes.location,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export default connect(mapStateToProps)(withRouter(TertiaryNavBarWrapper));
