// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Footer from 'Ui/components/footer/footer';
import {Route, Switch} from 'react-router-dom';
import Login from 'Ui/components/login/login';
import LinkProfilePrompt from 'Ui/components/login/link-profile-prompt';
import LinkProfileConfirm from 'Ui/components/login/link-profile-confirm';
import LinkedMembershipSelect from 'Ui/components/login/linked-membership-select';
import LinkReminder from 'Ui/components/login/link-reminder';
import LinkError from 'Ui/components/login/link-error';
import Helmet from 'react-helmet';
import {ONLINK_LOGIN} from 'Ui/constants/tab-header-constants';

const routes = [
    {
        component: Login,
        exact: true,
        path: '/login'
    },
    {
        component: LinkProfilePrompt,
        exact: true,
        path: '/profile/link'
    },
    {
        component: LinkProfileConfirm,
        exact: true,
        path: '/profile/link/confirm'
    },
    {
        component: LinkReminder,
        exact: true,
        path: '/profile/link/reminder'
    },
    {
        component: LinkError,
        exact: true,
        path: '/profile/link/error'
    },
    {
        component: LinkedMembershipSelect,
        exact: true,
        path: '/login/membership'
    }
];

function EntryPublic(props) {
    return (
        <Switch>
            {
                routes.map((routeConfig) => {
                    const {
                        component: Component,
                        ...routeProps
                    } = routeConfig;

                    return (
                        <Route
                            {...routeProps}
                            key={routeProps.path}
                        >
                            <Helmet>
                                <title>{ONLINK_LOGIN}</title>
                            </Helmet>
                            <div className='public-content'>
                                <div className='public-dashboard'>
                                    <Component
                                        redirectParam={props.location.search}
                                        translations={props.translations}
                                    />
                                </div>
                                <Footer translations={props.translations}/>
                            </div>
                        </Route>
                    );
                })
            }
        </Switch>
    );
}

EntryPublic.propTypes = {
    location: PropTypes.location,
    translations: PropTypes.translations
};

export default EntryPublic;
