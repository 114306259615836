// Unpublished Work © 2023-2024 Deere & Company.

import {convert} from '@deere/unit-system';
import {configForUnit} from 'Common/utils/units/config-for-unit';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {UNIT_CONVERSION_CONFIG} from 'Common/constants/units/conversion-constants';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {UNIT_NAME_CONFIG} from 'Common/constants/units/unit-name';
import {CONVERSION_UNIT, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {FUEL_COST, GAS_COST, GREEN_SPEED} from 'Common/constants/data-group-constants';
import {
    DOLLARS, DOLLARS_PER_KILOWATT_HOUR, DOLLARS_PER_LITER, F355_EQUIVALENTS,
    FEET, FEET_AND_INCHES, FEET_AND_INCHES_GRAPH, GALLONS, GRAVITIES,
    INCHES, KILOWATT_HOURS, LITERS, METERS, POUNDS, QUARTS
} from 'Common/constants/data-unit-constants';
import {round} from 'lodash';

const DATATYPE_UNIT_CONVERTER = {
    [F355_EQUIVALENTS]: CONVERSION_UNIT.GMAX,
    [FEET]: CONVERSION_UNIT.FEET,
    [GALLONS]: CONVERSION_UNIT.GAL,
    [GRAVITIES]: CONVERSION_UNIT.GMAX,
    [INCHES]: CONVERSION_UNIT.INCHES,
    [LITERS]: CONVERSION_UNIT.L,
    [METERS]: CONVERSION_UNIT.METERS,
    [POUNDS]: CONVERSION_UNIT.LB,
    [QUARTS]: CONVERSION_UNIT.QT
};

function getUnitOfMeasureOverride(unitOfMeasureOverrides, defaultUnitOfMeasure, overrideType) {
    const uomOverride = unitOfMeasureOverrides?.find(({dataType}) => dataType === overrideType);

    return uomOverride?.unitOfMeasure || defaultUnitOfMeasure;
}

function determineConversionConfig({
    dataType,
    dataUnit,
    isConfigForGraph
}) {
    let unitConversionConfig;

    if (dataType === GREEN_SPEED) {
        const greenSpeedUnit = isConfigForGraph ? FEET_AND_INCHES_GRAPH : FEET_AND_INCHES;

        unitConversionConfig = UNIT_CONVERSION_CONFIG[greenSpeedUnit];
    } else {
        unitConversionConfig = UNIT_CONVERSION_CONFIG[dataUnit];
    }

    return unitConversionConfig?.[dataType] || unitConversionConfig;
}

function getUoMConfig({
    dataType,
    dataUnit,
    isConfigForGraph = false,
    unitOfMeasure = UNIT_OF_MEASURE.ENGLISH,
    unitOfMeasureOverrides = []
}) {
    const uomOverride = getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, dataType);

    const unitConversionConfig = determineConversionConfig({
        dataType,
        dataUnit,
        isConfigForGraph
    });

    return unitConversionConfig?.[uomOverride] || {
        formatting: configForUnit(dataUnit)
    };
}

function roundValue(value, conversionConfig, defaultDecimalPlaces = 2) {
    const {formatting: {decimalPlaces = defaultDecimalPlaces}} = getUoMConfig(conversionConfig);

    return round(value, decimalPlaces);
}

function convertToMultipleDataUnitValues(value, dataUnit, {conversionFactors}) {
    if (conversionFactors) {
        const conversionFactor = conversionFactors[dataUnit];
        const valueConvertedToSingleUnit = value * conversionFactor;

        const maxConversionFactor = Math.max(...Object.values(conversionFactors));

        const value1 = Math.floor(valueConvertedToSingleUnit / maxConversionFactor);
        const value2 = valueConvertedToSingleUnit - value1 * maxConversionFactor;

        return [value1, value2];
    }

    return value;
}

function determineSourceAndTargetUnits(metricToImperial, {
    englishUnit,
    metricUnit
}) {
    return metricToImperial ? {
        sourceUnit: metricUnit,
        targetUnit: englishUnit
    } : {
        sourceUnit: englishUnit,
        targetUnit: metricUnit
    };
}

function convertValue(value, {
    sourceUnit,
    targetUnit
}) {
    const resolvedSourceUnit = DATATYPE_UNIT_CONVERTER[sourceUnit] || sourceUnit;
    const resolvedTargetUnit = DATATYPE_UNIT_CONVERTER[targetUnit] || targetUnit;

    return convert(value, resolvedSourceUnit, resolvedTargetUnit);
}

function getGreenSpeedByUomOverride(dataUnit, unitConversionConfigByUom, value) {
    if (dataUnit && unitConversionConfigByUom?.unit === UNITS.meters) {
        return convertValue(Number.parseFloat(value), {
            sourceUnit: dataUnit,
            targetUnit: UNITS.meters
        });
    }

    if (dataUnit === METERS) {
        const feetValue = convertValue(Number.parseFloat(value), {
            sourceUnit: UNITS.meters,
            targetUnit: FEET
        });

        return convertToMultipleDataUnitValues(feetValue, FEET, unitConversionConfigByUom.formatting);
    }

    return convertToMultipleDataUnitValues(value, dataUnit, unitConversionConfigByUom.formatting);
}

const FUEL_GAS_COST_CONVERSION = {
    callback(value, {
        metricToImperial,
        unitOfMeasure,
        useNonConverted
    }) {
        if (!useNonConverted && unitOfMeasure === UNIT_OF_MEASURE.METRIC) {
            return convertValue(value, determineSourceAndTargetUnits(metricToImperial, {
                englishUnit: UNITS.liters, // This is using a metric unit intentionally
                metricUnit: UNITS.gallons // This is using an english unit intentionally
            }));
        }

        return value;
    }
};

const SPECIAL_DATA_TYPE_CONVERSIONS = {
    [FUEL_COST]: FUEL_GAS_COST_CONVERSION,
    [GAS_COST]: FUEL_GAS_COST_CONVERSION,
    [GREEN_SPEED]: {
        callback(value, {
            dataUnit,
            unitConversionConfig,
            unitOfMeasure,
            unitOfMeasureOverrides,
            useNonConverted
        }) {
            if (useNonConverted) {
                return dataUnit === INCHES || dataUnit === FEET ?
                    convertToMultipleDataUnitValues(value, dataUnit, unitConversionConfig[UNIT_OF_MEASURE.ENGLISH].formatting) :
                    value;
            }

            const uom = getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, GREEN_SPEED);

            return getGreenSpeedByUomOverride(dataUnit, unitConversionConfig[uom], value);
        },
        disableMetricToImperialConversion: true
    }
};

const isMetricUoM = (targetUoM) => targetUoM === UNIT_OF_MEASURE.METRIC;

function convertToUoM(value, {
    dataType,
    dataUnit,
    isConfigForGraph = false,
    metricToImperial = false,
    shouldConvertValue = isMetricUoM,
    unitOfMeasure = UNIT_OF_MEASURE.ENGLISH,
    unitOfMeasureOverrides = [],
    useNonConverted = false
}) {
    if (isNullOrUndefined(value)) {
        return value;
    }

    const unitConversionConfig = determineConversionConfig({
        dataType,
        dataUnit,
        isConfigForGraph
    });
    const specialConversion = SPECIAL_DATA_TYPE_CONVERSIONS[dataType];

    if (specialConversion && (!specialConversion.disableMetricToImperialConversion || !metricToImperial)) {
        return specialConversion.callback(value, {
            dataUnit,
            metricToImperial,
            unitConversionConfig,
            unitOfMeasure,
            unitOfMeasureOverrides,
            useNonConverted
        });
    }

    const targetUoM = getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, dataType);

    return unitConversionConfig && !useNonConverted && shouldConvertValue(targetUoM) ?
        convertValue(value, determineSourceAndTargetUnits(metricToImperial, {
            englishUnit: unitConversionConfig[UNIT_OF_MEASURE.ENGLISH].unit,
            metricUnit: unitConversionConfig[UNIT_OF_MEASURE.METRIC].unit
        })) :
        value;
}

function getCurrencySymbol({
    currencyPreference,
    locale = 'en'
}) {
    const currencyFormatter = new Intl.NumberFormat(locale, {
        currency: currencyPreference,
        currencyDisplay: 'narrowSymbol',
        style: 'currency'
    });

    const currencyParts = currencyFormatter.formatToParts();
    const {value: currencySymbol} = currencyParts.find(({type}) => type === 'currency');

    return currencySymbol;
}

function getDataUnitOverride({
    currencyPreference,
    dataUnit,
    dataType,
    locale,
    unitOfMeasure
}) {
    const currencyOption = {
        currencyPreference,
        locale
    };

    if (dataType === FUEL_COST || dataType === GAS_COST) {
        const convertedUnitAndTranslation = unitOfMeasure === UNIT_OF_MEASURE.ENGLISH ? {
            convertedUnit: GALLONS,
            translationKey: 'ONLINK_GALLON'
        } : {
            convertedUnit: LITERS,
            translationKey: 'ONLINK_LITER'
        };

        return {
            ...convertedUnitAndTranslation,
            currencySymbol: getCurrencySymbol(currencyOption)
        };
    }

    switch (dataUnit) {
        case DOLLARS:
            return {
                currencySymbol: getCurrencySymbol(currencyOption)
            };
        case DOLLARS_PER_KILOWATT_HOUR:
            return {
                convertedUnit: KILOWATT_HOURS,
                currencySymbol: getCurrencySymbol(currencyOption)
            };
        case DOLLARS_PER_LITER:
            return {
                convertedUnit: LITERS,
                currencySymbol: getCurrencySymbol(currencyOption),
                translationKey: 'ONLINK_LITER'
            };
        default:
            return null;
    }
}

function getDataUnitAndCurrencyPreferenceParts({
    currencyPreference,
    dataType,
    dataUnit,
    locale,
    unitOfMeasure = UNIT_OF_MEASURE.ENGLISH,
    unitOfMeasureOverrides = []
}) {
    const dataUnitOverride = getDataUnitOverride({
        currencyPreference,
        dataType,
        dataUnit,
        locale,
        unitOfMeasure
    });

    if (dataUnitOverride) {
        return dataUnitOverride;
    }

    const uomWithOverrides = getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, dataType);
    const baseConfigForDataUnit = UNIT_NAME_CONFIG[dataUnit];
    const configForDataUnit = baseConfigForDataUnit?.[dataType] || baseConfigForDataUnit;

    return {
        convertedUnit: configForDataUnit ? configForDataUnit[uomWithOverrides] : dataUnit
    };
}

export {
    convertToMultipleDataUnitValues,
    convertToUoM,
    determineConversionConfig,
    getCurrencySymbol,
    getDataUnitAndCurrencyPreferenceParts,
    getUnitOfMeasureOverride,
    getUoMConfig,
    isMetricUoM,
    roundValue
};
