// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import SidebarWrapper from 'Ui/components/map/sidebar/sidebar-wrapper';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {clsx} from 'clsx';

function MapPage(props) {
    const {
        bottomSheetRef,
        listComponent,
        mapComponent,
        featureToggles
    } = props;

    const parentClass = clsx('map-parent', {
        'map-tertiary-nav': featureToggles[ONLINK_NAVIGATION_REDESIGN]
    });
    const sideBarClass = clsx('map-sidebar', {
        'map-tertiary-nav': featureToggles[ONLINK_NAVIGATION_REDESIGN]
    });

    return (
        <div className={parentClass}>
            <SidebarWrapper bottomSheetRef={bottomSheetRef}>
                <div className={sideBarClass}>
                    {listComponent}
                </div>
            </SidebarWrapper>
            {mapComponent}
        </div>
    );
}

MapPage.propTypes = {
    bottomSheetRef: PropTypes.reference,
    featureToggles: PropTypes.featureToggles,
    listComponent: PropTypes.node,
    mapComponent: PropTypes.node
};

export default MapPage;
