// Unpublished Work © 2020-2024 Deere & Company.

import * as roleActions from 'Store/actions/role';

const DEFAULT_STATE = {
    userRoles: []
};

const role = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case roleActions.UPDATE_USER_ROLES:
            return Object.assign({}, state, {
                userRoles: action.userRoles
            });
        default:
            return state;
    }
};

export default role;
