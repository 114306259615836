// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import CheckList from 'Ui/components/common/form/check-list';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';

function getMultiSelectItems(allMultiSelectOptions, multiSelectSelectedIds) {
    const multiSelectItems = {
        selectedItems: [],
        unselectedItems: []
    };

    return allMultiSelectOptions.reduce((items, selectOption) => {
        multiSelectSelectedIds.includes(selectOption.id) ?
            items.selectedItems.push(selectOption) :
            items.unselectedItems.push({
                id: selectOption.id,
                title: selectOption.label
            });

        return items;
    }, multiSelectItems);
}

function getCheckListItems(multiSelectSelectedIds, selectedMultiSelectItems, additionalCheckListItems) {
    const combinedItems = [...selectedMultiSelectItems, ...additionalCheckListItems];

    return multiSelectSelectedIds.map((selectedId) => combinedItems.find((item) => item.id === selectedId));
}

function MultiSelectWithCheckList(props) {
    const {
        additionalCheckListItems,
        allMultiSelectOptions,
        checkListName,
        checkListSelectedIds,
        defaultSelectLabel,
        label,
        multiSelectName,
        multiSelectSelectedIds,
        onChange,
        onDelete,
        showDelete
    } = props;

    const {
        selectedItems: selectedMultiSelectItems,
        unselectedItems: unselectedMultiSelectItems
    } = React.useMemo(
        () => getMultiSelectItems(allMultiSelectOptions, multiSelectSelectedIds),
        [allMultiSelectOptions, multiSelectSelectedIds]
    );

    const checkListItems = React.useMemo(
        () => getCheckListItems(multiSelectSelectedIds, selectedMultiSelectItems, additionalCheckListItems),
        [multiSelectSelectedIds, selectedMultiSelectItems, additionalCheckListItems]
    );

    return (
        <div className='multiselect-checklist'>
            <label className='multiselect-checklist-label'>
                {label}
            </label>
            <CheckList
                items={checkListItems}
                name={checkListName}
                onChange={onChange}
                onDelete={onDelete}
                selectedIds={checkListSelectedIds}
                showDelete={showDelete}
            />
            <MultiSelect
                autoFocus={true}
                enableVirtualization
                hideCheckboxes={true}
                items={unselectedMultiSelectItems}
                labels={{
                    multiSelect: label,
                    placeholder: defaultSelectLabel
                }}
                name={multiSelectName}
                onChange={(selectedIds) => onChange(multiSelectName, [...multiSelectSelectedIds, ...selectedIds])}
                selectedIds={multiSelectSelectedIds}
                single={true}
                width='inherit'
            />
        </div>
    );
}

MultiSelectWithCheckList.defaultProps = {
    additionalCheckListItems: []
};

MultiSelectWithCheckList.propTypes = {
    additionalCheckListItems: PropTypes.checkListItemsList,
    allMultiSelectOptions: PropTypes.checkListItemsList,
    checkListName: PropTypes.string,
    checkListSelectedIds: PropTypes.arrayOf(PropTypes.stringOrNumber),
    defaultSelectLabel: PropTypes.string,
    label: PropTypes.string,
    multiSelectName: PropTypes.string,
    multiSelectSelectedIds: PropTypes.arrayOf(PropTypes.stringOrNumber),
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    showDelete: PropTypes.func
};

export default MultiSelectWithCheckList;
