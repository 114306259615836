// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import PropTypes from 'Utils/prop-type-utils';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import moment from 'moment';
import {useSave} from 'Ui/react-hooks/use-save';
import {
    changeEmployeeStatus,
    combineConsecutiveEvents,
    deleteEmployeeStatus,
    getAddEditFooterActions
} from 'OnLabor/workboard/utils/time-off-utils';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {BasicDialog} from '@deere/basic-dialog';
import {getActiveMembershipUsers, getEmployeeStatusOptions, getTimeOff} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {formatLocalizedTime} from 'Utils/time-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import TimeOffInputs from 'Ui/components/common/time-off-inputs';
import DataTable from 'Ui/components/common/data-table/data-table';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import {CONFLICT} from 'Common/constants/errors';

const SUCCESS_CODE = 200;

function getOptionsMultiselectItems(employeeStatusOptions) {
    return employeeStatusOptions?.map((data) => (

        {
            ...data,
            title: data.name,
            id: data.employeeStatusOptionId
        }
    ));
}

function getUsersMultiselectItems(user) {
    return user?.map((data) => (
        {
            ...data,
            title: `${data.firstName} ${data.lastName}`,
            id: data.appUserId
        }
    ));
}

function getTableData(availabilityData) {
    const restructuredEvents = combineConsecutiveEvents(availabilityData, false);

    return restructuredEvents?.map((data) => (
        {
            name: `${data.firstName} ${data.lastName}`,
            from: formatLocalizedTime(moment(data.start).toDate(), {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            }),
            to: formatLocalizedTime(moment(data.end).toDate(), {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            }),
            leaveType: data.employeeStatusOption
        }
    ));
}

function inititializeState(timeOffValues) {
    const [availabilityData, setAvailabilityData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [values, setValues] = React.useState({
        name: timeOffValues?.appUserId,
        leaveType: timeOffValues?.employeeStatusOptionId,
        fromDate: timeOffValues?.dateSelect ? moment(timeOffValues.dateSelect) : moment(),
        toDate: timeOffValues?.toDate ? moment(timeOffValues.toDate) : moment()
    });

    const statusOptionsRef = React.useRef([]);
    const usersRef = React.useRef([]);

    return {
        availabilityData,
        setAvailabilityData,
        loading,
        setLoading,
        values,
        setValues,
        statusOptionsRef,
        usersRef
    };
}

function AddTimeOffDialog(props) {
    const {
        closeAddTimeOffDialog,
        closeConfirmation,
        openConfirmation,
        invalidInputs,
        onClose,
        setValid,
        translations,
        timeOffValues,
        addToast
    } = props;

    const {
        availabilityData,
        setAvailabilityData,
        loading,
        setLoading,
        values,
        setValues,
        statusOptionsRef,
        usersRef
    } = inititializeState(timeOffValues);

    function onChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    async function fetchData(isMounted = () => true) {
        setLoading(true);
        const [{employeeStatus}, {employeeStatusOptions}, users] = await Promise.all([
            !timeOffValues ? getTimeOff({
                startDate: moment().format('YYYYMMDD')
            }) : Promise.resolve({}),
            getEmployeeStatusOptions(),
            getActiveMembershipUsers()
        ]);

        const unavailableTimeOffEvents = employeeStatus?.filter((event) => event.isAvailable === false);

        if (isMounted()) {
            setAvailabilityData(getTableData(unavailableTimeOffEvents));
            statusOptionsRef.current = getOptionsMultiselectItems(employeeStatusOptions);
            usersRef.current = getUsersMultiselectItems(users);
            setLoading(false);
        }
    }

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        setLoading(true);
        let clearResult = SUCCESS_CODE;

        if (timeOffValues) {
            clearResult = await deleteEmployeeStatus({
                appUserId: timeOffValues.appUserId,
                employeeStatusOptionId: timeOffValues.employeeStatusOptionId
            }, timeOffValues);
        }

        if (clearResult !== CONFLICT) {
            await changeEmployeeStatus({
                appUserId: values.name,
                employeeStatusOptionId: values.leaveType
            }, values);
        }

        if (onClose) {
            onClose();
        }

        setLoading(false);
        closeAddTimeOffDialog();
    }, {
        disabled: loading,
        invalidInputs
    });

    async function deleteHandler() {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_TIME_OFF.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_TIME_OFF
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    setLoading(true);
                    await deleteEmployeeStatus({
                        appUserId: timeOffValues.appUserId,
                        employeeStatusOptionId: timeOffValues.employeeStatusOptionId
                    }, timeOffValues);

                    if (onClose) {
                        onClose();
                    }
                    setLoading(false);
                    closeAddTimeOffDialog();
                } catch (e) {
                    if (e.response.status !== CONFLICT) {
                        addToast({
                            message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                                '0': translations.ONLINK_TIME_OFF.toLowerCase()
                            }),
                            type: TOAST_TYPE.ERROR
                        });
                    }
                }
            },
            onCancel: closeConfirmation
        });
    }

    const tableColumns = [
        {
            Header: translations.NAME,
            accessor: 'name'
        },
        {
            Header: translations.ONLINK_FROM,
            accessor: 'from'
        },
        {
            Header: translations.ONLINK_TO,
            accessor: 'to'
        },
        {
            Header: translations.ONLINK_LEAVE_TYPE,
            accessor: 'leaveType'
        }
    ];

    React.useEffect(() => fetchEffectData(fetchData), []);

    const title = timeOffValues ? translations.ONLINK_EDIT_TIME_OFF : translations.ONLINK_ADD_TIME_OFF;

    return (
        <BasicDialog
            className={'add-time-off-dialog dropdown-overflow-dialog'}
            closeHandler={closeAddTimeOffDialog}
            footerActions={getAddEditFooterActions(!isNullOrUndefined(timeOffValues), translations, closeAddTimeOffDialog, saveFunc, addToast, disableSave, deleteHandler)}
            show={true}
            title={title}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading || isSaving}
                size='50px'
            >
                <div className='add-todo-form'>
                    <TimeOffInputs
                        onChange={onChange}
                        setValid={setValid}
                        statusOptionsRef={statusOptionsRef}
                        translations={translations}
                        usersRef={usersRef}
                        values={values}
                    />
                    {!isNullOrUndefined(timeOffValues) || <DataTable
                        columns={tableColumns}
                        headerComponent={translations.ONLINK_UPCOMING_TIME_OFF}
                        rows={availabilityData}
                        translations={translations}
                    />}
                </div>
            </LoadingWrapper>
        </BasicDialog>
    );
}

AddTimeOffDialog.propTypes = {
    addToast: PropTypes.func,
    closeAddTimeOffDialog: PropTypes.func,
    closeConfirmation: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    onClose: PropTypes.func,
    openConfirmation: PropTypes.func,
    setValid: PropTypes.func,
    timeOffValues: PropTypes.object,
    translations: PropTypes.object

};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeAddTimeOffDialog() {
            dispatch(closeDialog(dialogTypes.ADD_TIME_OFF_DIALOG));
        },
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(AddTimeOffDialog));
