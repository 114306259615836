// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ErrorView from 'Ui/components/dashboard/error-view';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {switchOrg} from 'Utils/membership/switch-membership';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';

function PersistentHeaderChildWrapper(props) {
    const {
        children,
        membershipOrgId,
        selectedOrganizations: [selectedOrganization],
        translations
    } = props;

    const selectedOrgId = selectedOrganization?.id;
    const orgIdsMatch = React.useMemo(
        () => selectedOrganization?.id === membershipOrgId?.toString(),
        [membershipOrgId, selectedOrgId]
    );
    const errorView = useDeepMemo(() => <ErrorView translations={translations}/>, [translations]);

    const [loading, setLoading] = React.useState(true);
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);
        setHasError(false);

        if (!orgIdsMatch) {
            try {
                await switchOrg(selectedOrgId, translations);
            } catch (e) {
                setHasError(true);
            }
        }

        if (isMounted()) {
            setLoading(false);
        }
    }), [selectedOrgId]);

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            {orgIdsMatch && !hasError ? children : errorView}
        </LoadingWrapper>
    );
}

PersistentHeaderChildWrapper.propTypes = {
    children: PropTypes.node,
    membershipOrgId: PropTypes.number,
    selectedOrganizations: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export default PersistentHeaderChildWrapper;
