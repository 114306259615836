// Unpublished Work © 2021-2024 Deere & Company.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import Panel from 'Ui/components/panel/panel';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import {useDeepMemo} from 'Utils/react-utils';
import OnLinkProgressBar from 'Ui/components/common/onlink-progress-bar';
import clsx from 'clsx';
import {Button} from '@deere/isg.component-library/lib/Button';
import {redirect} from 'Utils/redirect-utils';
import Stack from '@mui/material/Stack';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip';
import DataTable from 'Ui/components/common/data-table/data-table';

const NUM_ROWS = 5;
const ONE_HUNDRED = 100;
const RED_LIMIT = 0.8;

function getThresholdRatio(part) {
    return (part.quantity + part.quantityOrdered) / part.thresholdLevel;
}

function getColumns(translations) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            Cell(row) {
                const {name} = row.original;

                return (
                    <Tooltip
                        placement='bottom'
                        title={name}
                    >
                        <span className='basic-table-cell-block'>
                            {name}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            Header: `${translations.ONLINK_PART } #`,
            accessor: 'partNumber',
            width: 100,
            Cell(row) {
                const {partNumber} = row.original;

                return (
                    <Tooltip
                        placement='bottom'
                        title={partNumber}
                    >
                        <span className='basic-table-cell-block'>
                            {partNumber}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            Header: translations.ONLINK_STOCK,
            accessor: 'quantity',
            width: 80
        },
        {
            Header: translations.ONLINK_THRESHOLD,
            accessor: 'thresholdLevel',
            width: 110
        },
        {
            accessor: 'thresholdRatio',
            width: 80,
            Cell(row) {
                const barColorRed = row.original.thresholdRatio < RED_LIMIT;

                return (
                    <OnLinkProgressBar
                        barClassName='low-inventory-progress-bar'
                        fillClassName={clsx('onlink-progress-bar-fill', {
                            'red': barColorRed,
                            'yellow': !barColorRed
                        })}
                        percentFilled={ONE_HUNDRED}
                    />
                );
            }
        }
    ];
}

function createRows(partBins) {
    if (partBins.length === 0) {
        return [];
    }

    const reducedPartBins = partBins.reduce((reducedPartBins, partBin) => {
        const thresholdRatio = getThresholdRatio(partBin);

        if (partBin.thresholdLevel > 0 && thresholdRatio < 1) {
            reducedPartBins.push(
                {
                    ...partBin,
                    thresholdRatio
                }
            );
        }

        return reducedPartBins;
    }, []);

    const sortedPartBins = reducedPartBins.sort((a, b) => a.thresholdRatio - b.thresholdRatio);

    return sortedPartBins.slice(0, NUM_ROWS);
}

function LowInventory(props) {
    const {
        history,
        tile: {partBins},
        translations
    } = props;

    const rows = useDeepMemo(() => createRows(partBins), [partBins]);

    return (
        <Panel
            title={translations.ONLINK_LOW_INVENTORY_PARTS}
            translations={translations}
        >
            <div>
                {rows.length > 0 ? (
                    <DataTable
                        className='low-inventory-table'
                        columns={getColumns(translations)}
                        defaultSorted={[{
                            desc: false,
                            id: 'thresholdRatio'
                        }]}
                        noDataAvailableMessage={translations.NO_EQUIPMENT_FOUND}
                        rows={rows}
                        translations={translations}
                    />
                ) : <NoDataMessage translations={translations}/>}
                <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='flex-end'
                    width='100%'
                >
                    <Button
                        onClick={() => redirect('/equipment/parts/orders', history)}
                        sx={{
                            fontWeight: 'bold'
                        }}
                        variant='tertiary'
                    >
                        {`${translations.ORDERS} >`}
                    </Button>
                </Stack>
            </div>
        </Panel>
    );
}

LowInventory.propTypes = {
    history: PropTypes.history,
    tile: PropTypes.object,
    translations: PropTypes.translations
};

export default withRouter(LowInventory);
