// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {formatLocalizedTime} from 'Utils/time-utils';
import moment from 'moment';

function TeeSheetCard(props) {
    const {data} = props;

    return (
        <div className='table-group-card tee-sheets-card'>
            <div className='card-header'>
                <div className='card-header-item cell-left'>
                    {formatLocalizedTime(moment(data.date, 'L'), {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    })}
                </div>
                <div className='card-header-time not-name'>
                    {formatLocalizedTime(moment(data.time, 'LT'), {
                        hour: 'numeric',
                        minute: 'numeric'
                    })}
                </div>
                <div className='card-header-item cell-right'>
                    {data.course}
                </div>
            </div>
            <div>
                <div className='card-header-labels'>
                    <div className='card-header-item name'>
                        {'NAME'}
                    </div>
                    <div className='card-header-item not-name'>
                        {'TRANS'}
                    </div>
                    <div className='card-header-item not-name'>
                        {'HOLES'}
                    </div>
                    <div className='card-header-item not-name'>
                        {'HCP'}
                    </div>
                </div>
                <div className='table-list'>
                    {
                        data.player.map((player, i) => (
                            <div
                                className='table-list-items'
                                key={i}
                            >
                                <div className='table-cell name'>
                                    {player.name}
                                </div>
                                <div className='table-cell not-name'>
                                    {player.trans}
                                </div>
                                <div className='table-cell not-name'>
                                    {player.holes}
                                </div>
                                <div className='table-cell not-name'>
                                    {player.handicap}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

TeeSheetCard.propTypes = {
    data: PropTypes.object
};

export default TeeSheetCard;
