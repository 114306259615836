// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {Input, Datepicker, TextArea, MultiSelect} from '@deere/form-controls';
import {closeDialog} from 'Store/actions/dialogs';
import {getHocDataByTime, postHocManualData, putHocManualData} from 'Services/hoc-service';
import dialogTypes from 'Ui/components/common/dialog-types';
import {fetchEffectData} from 'Utils/react-utils';
import {HOC} from 'Common/constants/data-group-constants';
import {INCHES, MM} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import moment from 'moment';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';
import ErrorView from 'Ui/components/dashboard/error-view';
import Box from '@mui/material/Box';

const VALUE = 'value';
const DATA_UNIT = 'dataUnit';
const MAX_HOC = 2000000;

function initializeState(initialDate) {
    const [hoc, setHoc] = React.useState(() => {});
    const [loading, setLoading] = React.useState(() => false);
    const [dateSelected, setDateSelected] = React.useState(() => initialDate);

    return {
        hoc,
        setHoc,
        loading,
        setLoading,
        dateSelected,
        setDateSelected
    };
}

function HocEditDialog(props) {
    const {
        closeDialog,
        dataGroup,
        initialDate,
        membership,
        myJdPermissions,
        translations,
        updateCourseMembership
    } = props;

    const {
        hoc,
        setHoc,
        loading,
        setLoading,
        dateSelected,
        setDateSelected
    } = initializeState(initialDate);

    function hocOnChangeHandler(dataType, newValue, unit) {
        const newHocValues = Object.assign([], hoc.hocValues);

        const index = newHocValues.findIndex((hocValue) => hocValue.dataType === dataType);

        newHocValues[index][unit] = newValue;

        setHoc((prevValues) => ({
            ...prevValues,
            hocValues: newHocValues
        }));
    }

    function textAreaOnChangeHandler(event) {
        const {value} = event.target;

        setHoc((prevValues) => ({
            ...prevValues,
            note: value
        }));
    }

    function getStartingUnit(dataUnit, hasCurrentValue) {
        if (dataUnit) {
            return dataUnit;
        }

        return !hasCurrentValue && membership.unitOfMeasure === UNIT_OF_MEASURE.METRIC ? MM : INCHES;
    }

    async function saveChanges() {
        setLoading(true);

        const hocManualData = hoc.hocValues.filter((hoc) => hoc.value).map((hoc) => ({
            'data_type': hoc.dataType,
            'data_unit': hoc.dataUnit,
            value: Number.parseFloat(hoc.value)
        }));

        const editHocManualData = {
            timeSample: dateSelected.format('YYYY-MM-DD hh:mm:ss'),
            note: hoc.note,
            dataGroup,
            membershipId: hoc.membershipId,
            manualData: hocManualData
        };

        const updateManualData = hoc.originDate === dateSelected.format('YYYYMMDD').toString() && hoc.manualDataId;

        if (updateManualData) {
            await putHocManualData(editHocManualData, hoc.manualDataId);
        } else {
            await postHocManualData(editHocManualData);
        }

        updateCourseMembership({
            dateSelected
        });

        setLoading(false);
        closeDialog();
    }

    const hocRows = hoc?.hocValues?.map((hocValue, i) => {
        const {
            label,
            dataType,
            dataUnit,
            value
        } = hocValue;

        const UomItems = [
            {
                title: translations.in?.toUpperCase(),
                id: 'inches'
            },
            {
                title: translations.MM?.toUpperCase(),
                id: 'mm'
            },
            {
                title: translations.cm?.toUpperCase(),
                id: 'cm'
            }
        ];

        return (
            <div
                className={'hoc-input'}
                key={i}
            >
                <Input
                    className='hoc-data-value input-section'
                    label={translations[label]}
                    max={MAX_HOC}
                    min={0}
                    onChange={(event) => hocOnChangeHandler(dataType, event.target.value, VALUE)}
                    step={0.005}
                    tabIndex={0}
                    type='number'
                    value={value}
                />
                {
                    <MultiSelect
                        className={'multiselect hoc-dropdown'}
                        hideFilter={true}
                        items={UomItems}
                        label={' '}
                        multiple={false}
                        name={'uom'}
                        onChange={(selectedIds) => {
                            hocOnChangeHandler(dataType, selectedIds[0], DATA_UNIT);
                        }}
                        selectedIds={[dataUnit]}
                        tabIndex={0}
                    />
                }
            </div>
        );
    });

    const cantEditHoc = !isAuthorized({
        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
    },
    {
        isMigrated: membership.isMigrated,
        myJdPermissions
    });

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (cantEditHoc) {
            return;
        }

        setLoading(true);

        const newHocData = await getHocDataByTime(membership, dateSelected.format('YYYYMMDD'), dataGroup);

        const hocDataWithDefaultUnit = newHocData?.hocValues.map((hocValue) => {
            const currentValue = hocValue.currentValue?.toString();

            return {
                ...hocValue,
                dataUnit: getStartingUnit(hocValue.dataUnit, currentValue),
                value: hocValue.dataValues.length ? hocValue.dataValues[0].dataValue : currentValue
            };
        });

        if (isMounted()) {
            setHoc({
                ...newHocData,
                hocValues: hocDataWithDefaultUnit
            });
            setLoading(false);
        }
    }), [dateSelected]);

    const errorView = (
        <Box flex={1}>
            <ErrorView translations={translations}/>
        </Box>
    );

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='hoc-dialog form-dialog'
            closeHandler={closeDialog}
            footerLoading={loading}
            onSave={saveChanges}
            saveLabel={translations.save}
            title={`${hoc?.name ? hoc.name.toUpperCase() : ''} ${translations.HEIGHTS_OF_CUT}`}
            translations={translations}
        >
            {cantEditHoc ? errorView : <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                <div className='hoc-dialog-content'>
                    <div className='title-row'>
                        <Datepicker
                            dateFormat='L'
                            large={true}
                            locale={getLanguagePreference()}
                            onChange={setDateSelected}
                            selected={dateSelected}
                        />
                        <TextArea
                            name='notes'
                            onChange={textAreaOnChangeHandler}
                            placeholder={`${translations.NOTES}:`}
                            tabIndex={0}
                            value={hoc?.note || ''}
                        />
                    </div>
                    {hocRows}
                </div>
            </LoadingWrapper>}
        </FormDialog>
    );
}

HocEditDialog.defaultProps = {
    dataGroup: HOC
};

HocEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    dataGroup: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    initialDate: PropTypes.instanceOf(moment),
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    translations: PropTypes.translations,
    updateCourseMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => {
            dispatch(closeDialog(dialogTypes.HOC_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HocEditDialog);
