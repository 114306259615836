// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'prop-types';
import {
    getWorkItemsPerColumn,
    getSharedWorkItemDetails
} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import _ from 'lodash';

function getFilteredWorkItems(workItems, workItemsByEmployee) {
    let workItemsCopy = workItems.slice();

    const secondaryJobs = {};

    workItemsByEmployee.forEach((workItemGroup) => {
        const groupAppUserId = workItemGroup[0].appUserId;

        secondaryJobs[groupAppUserId] = workItemGroup;
        secondaryJobs[groupAppUserId].shift();
        secondaryJobs[groupAppUserId]?.forEach((job) => {
            workItemsCopy = _.reject(workItemsCopy, (item) => {
                return item.appUserId === groupAppUserId && item.jobId === job.jobId;
            });
        });
    });

    const filteredWorkItems = workItemsCopy.map((item) => ({
        ...item,
        secondaryJobs: secondaryJobs[item.appUserId]
    }));

    return _.groupBy(filteredWorkItems, 'jobId');
}

function formatWorkItems(workItems, showEstimatedHours, showPPE, showEmployeeGroupColor, showEmployeeImage) {
    return workItems.map((item, i) => {
        const workItemDetails = getSharedWorkItemDetails(item, showPPE, false);
        const imgUrl = item.profilePicUrl;

        return (
            <div
                className='user-container'
                key={i}
            >
                <div className='image-container'>
                    {
                        showEmployeeGroupColor &&
                        <div
                            className='group-color'
                            key={i}
                            style={{
                                backgroundColor: item.color
                            }}
                        />
                    }
                    {
                        showEmployeeImage &&
                        <img
                            className={`user-image${imgUrl ? '' : ' hide-image'}`}
                            src={imgUrl}
                        />
                    }
                </div>
                <div className='work-item-details-block'>
                    <h3 className='user-details'>{item.appUserName} <span className='est-hours'>{showEstimatedHours ? item.estHours : ''}</span></h3>
                    {workItemDetails}
                    <div className='secondary-jobs-list'>
                        {
                            item.secondaryJobs.length > 0 &&
                            <ul className='secondary-jobs'>
                                {
                                    item.secondaryJobs.map((secondaryItem) => {
                                        const secondaryJobWorkItemDetails = getSharedWorkItemDetails(secondaryItem, showPPE, true);

                                        return (
                                            <li key={`${secondaryItem.title}-${secondaryItem.appUserName}`}>
                                                <h3 className='title'>{secondaryItem.title} <span className='est-hours'>{showEstimatedHours ? secondaryItem.estHours : ''}</span></h3>
                                                {secondaryJobWorkItemDetails}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        }
                    </div>
                </div>
            </div>
        );
    });
}

function formatJobView(workItemsByEmployee, workItems, properties) {
    const {
        showEstimatedHours, tileOpacity, showPPE, fontSize, columnCount, showEmployeeGroupColor, showEmployeeImage
    } = properties;

    const workItemsByJob = getFilteredWorkItems(workItems, workItemsByEmployee, columnCount, tileOpacity);
    const workItemsPerColumn = getWorkItemsPerColumn(workItemsByJob, columnCount).filter(Boolean);

    return Array.from({
        length: columnCount
    }, (x, i) => {
        return (
            <div
                className='grid-column'
                key={i}
            >
                {
                    workItemsPerColumn[i]?.map((key, index) => {
                        const hasSecondaryName = Boolean(workItemsByJob[key][0].secondaryName);

                        return (
                            <div
                                className={`presentation-job-item ${tileOpacity}`}
                                key={index}
                                style={{
                                    fontSize
                                }}
                            >
                                <div className='job-title-container'>
                                    <h3 className='job-title'>{workItemsByJob[key][0].title}</h3>
                                    {
                                        hasSecondaryName &&
                                        <h3 className='secondary-job-title'>{`(${workItemsByJob[key][0].secondaryName})`}</h3>
                                    }
                                </div>
                                {formatWorkItems(workItemsByJob[key], showEstimatedHours, showPPE, showEmployeeGroupColor, showEmployeeImage)}
                            </div>
                        );
                    })
                }
            </div>
        );
    });
}

function PresentationJobView(props) {
    const {
        workItems,
        workItemsByEmployee,
        properties
    } = props;

    return (
        <div className='jobs-container'>
            {
                formatJobView(workItemsByEmployee, workItems, properties)
            }
        </div>
    );
}

PresentationJobView.propTypes = {
    properties: PropTypes.object,
    workItems: PropTypes.arrayOf(PropTypes.object),
    workItemsByEmployee: PropTypes.array
};

export default PresentationJobView;
