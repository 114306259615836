// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Input} from '@deere/form-controls';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import AddressFieldInputs from 'Ui/components/settings/common/form/address-field-inputs';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {createDealership, updateDealership} from 'Services/dealership-service';
import {closeDialog} from 'Store/actions/dialogs';
import {useSave} from 'Ui/react-hooks/use-save';
import {getAddressFormatElements, mapAddressElementsByClass, resetAddressElementInputs} from 'Utils/address-format';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import dialogTypes from 'Ui/components/common/dialog-types';

function DealershipDialog(props) {
    const {
        closeDealershipDialog,
        dealership,
        fetchDealerships,
        fleetId,
        invalidInputs,
        setValid,
        translations
    } = props;

    const [addressFields, setAddressFields] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [values, setValues] = React.useState(() => {
        const dealershipOrDefault = dealership || {};

        return {
            address1: dealershipOrDefault.address1 || '',
            address2: dealershipOrDefault.address2 || '',
            addressElements: mapAddressElementsByClass(dealershipOrDefault.addressElements),
            city: dealershipOrDefault.city || '',
            country: dealershipOrDefault.country || 'US',
            email: dealershipOrDefault.email || '',
            name: dealershipOrDefault.name || '',
            phone: dealershipOrDefault.phone || '',
            state: dealershipOrDefault.state,
            zip: dealershipOrDefault.zip || ''
        };
    });

    const title = dealership ? translations.EDIT_TYPE : translations.ONLINK_ADD_ITEM;

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const validAddressFields = addressFields.map(({addressFormatElementClass}) => addressFormatElementClass);
        const addressElements = Object.keys(values.addressElements)
            .filter((key) => validAddressFields?.includes(key))
            .map((key) => ({
                elementClass: key,
                elementValue: values.addressElements[key]
            }));

        const dealershipData = {
            ...values,
            addressElements
        };

        dealership ?
            await updateDealership(dealership.membershipId, dealershipData) :
            await createDealership(fleetId, dealershipData);

        fetchDealerships();

        closeDealershipDialog();
    }, {
        disabled: loading,
        invalidInputs
    });

    function onChange(event) {
        const {
            name,
            value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onAddressElementChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            addressElements: {
                ...prevValues.addressElements,
                [name]: value
            }
        }));
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const addressFormatElements = await getAddressFormatElements(values.country);

        if (isMounted()) {
            setAddressFields(addressFormatElements);

            resetAddressElementInputs(addressFormatElements, values.addressElements, onAddressElementChange);

            setLoading(false);
        }
    }), [values.country]);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDealershipDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={replaceTranslationNames(title, {
                '0': translations.ONLINK_DEALERSHIP
            })}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    autoComplete='organization'
                    autoFocus={true}
                    debounceTimeout={window.props.debounceTimeout}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.NAME}
                    name='name'
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={values.name}
                />
                <AddressFieldInputs
                    {...values}
                    addressFields={addressFields}
                    loading={loading}
                    onAddressElementChange={onAddressElementChange}
                    onInputChange={onChange}
                    setValid={setValid}
                    translations={translations}
                />
                <div className='settings-group mobile'>
                    <Input
                        autoComplete='tel'
                        debounceTimeout={window.props.debounceTimeout}
                        label={translations.ONLINK_PHONE}
                        name='phone'
                        onChange={onChange}
                        tabIndex={0}
                        type='tel'
                        value={values.phone}
                    />
                    <Input
                        autoComplete='email'
                        debounceTimeout={window.props.debounceTimeout}
                        label={translations.my_team_email}
                        name='email'
                        onChange={onChange}
                        tabIndex={0}
                        type='email'
                        value={values.email}
                    />
                </div>
            </form>
        </FormDialog>
    );
}

DealershipDialog.propTypes = {
    closeDealershipDialog: PropTypes.func,
    dealership: PropTypes.membership,
    featureToggles: PropTypes.featureToggles,
    fetchDealerships: PropTypes.func,
    fleetId: PropTypes.string,
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        fleetId: state.membership.fleetId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDealershipDialog() {
            dispatch(closeDialog(dialogTypes.DEALERSHIP_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(DealershipDialog));
