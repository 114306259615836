// Unpublished Work © 2023-2024 Deere & Company.

import {setInArray} from 'Common/utils/array-handlers';
import {isEmptyArray} from 'Common/utils/validation-utils';
import {ELECTRICAL_COST, FUEL, HOC, HOC_SPEC, REVENUE, WATER_COST} from 'Common/constants/data-group-constants';
import {
    MANUAL_DATA_GROUP_REQUIREMENTS,
    MANUAL_DATA_PERMISSIONS_BY_REQUEST_TYPE
} from 'Common/constants/business-activities/manual-data-groups';

function canAccessData(requiredJdPermissions = [], myJdPermissions = {}, requireAllJdPermissions = true) {
    const requirements = setInArray(requiredJdPermissions);

    if (requireAllJdPermissions) {
        return requirements.every((requirement) => myJdPermissions[requirement]);
    }

    return requiredJdPermissions.length ?
        requirements.some((requirement) => myJdPermissions[requirement]) :
        true;
}

function hasMyJDPermissions(userJdPermissions, ...requiredJdPermissions) {
    const filteredJdPermissions = requiredJdPermissions.filter(Boolean);

    return isEmptyArray(filteredJdPermissions) || filteredJdPermissions.some((jdPermission) => userJdPermissions[jdPermission]);
}

function addAdditionalNeededPermissions(permissionToAdd, permissionNeeded) {
    const permissionsNeeded = setInArray(permissionNeeded);

    if (permissionToAdd) {
        return permissionsNeeded.concat(permissionToAdd);
    }

    return permissionsNeeded;
}

function getManualDataMyJdPermissions(dataGroup, requestType) {
    switch (dataGroup) {
        case ELECTRICAL_COST:
        case FUEL:
        case REVENUE:
        case WATER_COST:
            return addAdditionalNeededPermissions(MANUAL_DATA_GROUP_REQUIREMENTS[dataGroup], MANUAL_DATA_PERMISSIONS_BY_REQUEST_TYPE[requestType]);
        case HOC:
        case HOC_SPEC:
            return MANUAL_DATA_GROUP_REQUIREMENTS[dataGroup];
        default:
            return addAdditionalNeededPermissions(MANUAL_DATA_GROUP_REQUIREMENTS[dataGroup], MANUAL_DATA_PERMISSIONS_BY_REQUEST_TYPE[requestType]);
    }
}

export {
    canAccessData,
    hasMyJDPermissions,
    addAdditionalNeededPermissions,
    getManualDataMyJdPermissions
};
