// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import appAccess from 'Common/constants/app-access';
import ThresholdSettings from 'Ui/components/settings/notifications/threshold-settings';
import {hasAppAccess} from 'Common/utils/permission-handler';
import {connect} from 'react-redux';

const ALLOWED_ONLABOR_THRESHOLDS = new Set([
    'labor_overtime_cost',
    'labor_overtime_hours'
]);

function LaborThresholdSettings({
    appKeys,
    typesFunction,
    isMigrated
}) {
    const laborFilter = (threshold) => {
        if (isMigrated || hasAppAccess(appKeys, appAccess.ONGOLF)) {
            return true;
        }

        return hasAppAccess(appKeys, appAccess.ONLABOR) &&
            ALLOWED_ONLABOR_THRESHOLDS.has(threshold.dataType);
    };

    return (
        <ThresholdSettings
            category='Labor'
            filterFunction={laborFilter}
            key='Labor'
            typesFunction={typesFunction}
        />
    );
}

LaborThresholdSettings.propTypes = {
    appKeys: PropTypes.appKeys,
    isMigrated: PropTypes.bool,
    typesFunction: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        isMigrated: state.membership.isMigrated
    };
}

export default connect(mapStateToProps)(LaborThresholdSettings);
