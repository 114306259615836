// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import appAccess from 'Common/constants/app-access';
import {COURSE} from 'Common/constants/membership-type';
import permissions from 'Common/constants/permissions';
import {
    IconDataManager,
    IconMowingOrder,
    IconNotification,
    IconPartners,
    IconPlan,
    IconProfileCard,
    IconEquipmentTurf
} from '@deere/icons';
import EditableSettings from 'Ui/components/settings/common/editable-settings';
import EditableAccountSettings from 'Ui/components/settings/membership/editable-account-settings';
import AccountSettings from 'Ui/components/settings/membership/account-settings';
import UserSettings from 'Ui/components/settings/users/user-settings';
import EditableUserSettings from 'Ui/components/settings/users/editable-user-settings';
import RoleSettings from 'Ui/components/settings/users/role-settings';
import DepartmentSettings from 'Ui/components/settings/users/department-settings';
import PlaybooksSettings from 'Ui/components/settings/partners/playbooks-settings';
import WorkboardSettings from 'Ui/components/settings/labor/workboard-settings';
import EditableWorkboardSettings from 'Ui/components/settings/labor/editable-workboard-settings';
import MyWorkSettings from 'Ui/components/settings/labor/my-work-settings';
import EditableMyWorkSettings from 'Ui/components/settings/labor/editable-my-work-settings';
import JobsSettings from 'Ui/components/settings/labor/jobs-settings';
import JobCategoriesSettings from 'Ui/components/settings/labor/job-categories-settings';
import EmployeeStatusesSettings from 'Ui/components/settings/labor/employee-statuses-settings';
import ThresholdSettings from 'Ui/components/settings/notifications/threshold-settings';
import AccountingThresholdSettings from 'Ui/components/settings/notifications/accounting-threshold-settings';
import LaborThresholdSettings from 'Ui/components/settings/notifications/labor-threshold-settings';
import QuickTodoSettings from 'Ui/components/settings/labor/quick-todo-settings';
import MowingDirectionsSettings from 'Ui/components/settings/labor/mowing-directions-settings';
import MyJohnDeereOrganizations from 'Ui/components/settings/myJohnDeere/myJohnDeere-organizations';
import MyJohnDeereEquipment from 'Ui/components/settings/myJohnDeere/myJohnDeere-equipment';
import MyJohnDeereLinkedAccount from 'Ui/components/settings/myJohnDeere/myJohnDeere-linked-account';
import MyJohnDeereConnections from 'Ui/components/settings/myJohnDeere/myJohnDeere-connections';
import PreferenceSettings from 'Ui/components/settings/membership/preference-settings';
import EditablePreferenceSettings from 'Ui/components/settings/membership/editable-preference-settings';
import PogoCourseNameSettings from 'Ui/components/settings/partners/pogo-course-name-settings';
import PogoSensorSettings from 'Ui/components/settings/partners/pogo-sensor-settings';
import ForeTeesSettings from 'Ui/components/settings/partners/foretees-settings';
import BinLocationsSettings from 'Ui/pages/settings/fleet/bin-locations-settings';
import DealershipSettings from 'Ui/pages/settings/fleet/dealership-settings';
import PurchaseOrdersSettings from 'Ui/pages/settings/fleet/purchase-orders-settings';
import ManufacturerSettings from 'Ui/pages/settings/fleet/manufacturer-settings';
import PartTypesSettings from 'Ui/pages/settings/fleet/part-types-settings';
import PartsSettings from 'Ui/pages/settings/fleet/parts-settings';
import MaintenanceSettings from 'Ui/pages/settings/fleet/maintenance-settings';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {POGO} from 'Ui/constants/replace-constants';
import {FORETEES_EXCLUDED_FACILITIES} from 'Ui/constants/membership-properties';
import {hasPogoSensors} from 'Common/utils/vendor-utils';
import {getSpecifiedMembershipUsers} from 'Services/membership-service';
import {
    EDIT_ORGANIZATION_MYJD,
    MANAGE_WORKBOARDS, MANAGE_EQUIPMENT_SETTINGS
} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import MyJohnDeereStaffMigration from 'Ui/pages/settings/myJohnDeere/myJohnDeere-staff-migration';
import MyJohnDeereOperatorMigration from 'Ui/pages/settings/myJohnDeere/myJohnDeere-operator-migration';
import MyJohnDeereMergeExistingOperator from 'Ui/pages/settings/myJohnDeere/myJohnDeere-merge-existing-operator';
import MyJohnDeereMergeExistingStaffMember from 'Ui/pages/settings/myJohnDeere/myJohnDeere-merge-existing-staff';
import {JD_INVITE_SCREENS} from 'Common/constants/feature-toggles';

const ICON_STYLE = {
    style: {
        height: '64px',
        width: '64px'
    }
};

function typesFunction(threshold) {
    return threshold.dataLabel;
}

function createSettingsTile({
    appAccess,
    dialogProps,
    icon,
    tileName,
    myJdPermissions
}) {
    return {
        appAccess,
        dialogProps,
        icon,
        myJdPermissions,
        name: tileName,
        tileId: tileName
    };
}

async function hasParentFacilityWithoutUsers(membershipId) {
    if (membershipId) {
        const {users} = await getSpecifiedMembershipUsers(membershipId);

        return users.length === 0;
    }

    return true;
}

async function displayPogoTab(membership) {
    const parentFacilityWithoutUsers = await hasParentFacilityWithoutUsers(membership.parent?.membershipId);
    const facilityWithoutUsers = await hasParentFacilityWithoutUsers(membership.membershipId);

    return hasPogoSensors(membership, parentFacilityWithoutUsers, facilityWithoutUsers);
}

async function getSettingsTiles(translations, membership, featureToggles) {
    const vendorTile = createSettingsTile({
        dialogProps: {
            content: [
                {
                    element: <PlaybooksSettings/>,
                    id: 'Playbooks',
                    label: 'Playbooks'
                }
            ],
            title: translations.ONLINK_PARTNERS
        },
        icon: <IconPartners iconPartners={ICON_STYLE}/>,
        tileName: 'Partners'
    });

    // The excluded facilities have a unique setup that cannot be manually managed by the user
    if (membership.membershipType === COURSE && !FORETEES_EXCLUDED_FACILITIES.includes(membership.parent?.membershipId)) {
        vendorTile.dialogProps.content.push(
            {
                element: <ForeTeesSettings/>,
                id: 'ForeTees',
                label: 'ForeTees'
            }
        );
    }

    if (!membership.isMigrated && await displayPogoTab(membership)) {
        vendorTile.dialogProps.content.push(
            {
                element: <PogoSensorSettings/>,
                id: 'POGOSensors',
                label: 'POGO Sensors'
            }
        );
    }

    if (membership.membershipType === COURSE) {
        vendorTile.dialogProps.content.push({
            element: <PogoCourseNameSettings/>,
            id: 'POGOCourseName',
            label: replaceTranslationNames(translations.ONLINK_VENDOR_COURSE_NAME, {
                '0': POGO
            })
        });
    }

    const showMigrationTabsForConnectedOrgsWithToggle = featureToggles[JD_INVITE_SCREENS] && Boolean(membership.orgId);

    return [
        createSettingsTile({
            dialogProps: {
                content: [
                    {
                        element: (
                            <EditableSettings
                                component={AccountSettings}
                                editableComponent={EditableAccountSettings}
                                id='Account'
                            />
                        ),
                        id: 'Account',
                        label: translations.ONLINK_ACCOUNT
                    },
                    {
                        element: (
                            <EditableSettings
                                component={PreferenceSettings}
                                editableComponent={EditablePreferenceSettings}
                                id='preferences'
                            />
                        ),
                        id: 'preferences',
                        label: translations.preferences
                    }
                ],
                title: translations.ONLINK_MEMBERSHIP_SETTINGS
            },
            icon: <IconPlan iconPlan={ICON_STYLE}/>,
            tileName: 'Membership'
        }),
        createSettingsTile({
            dialogProps: {
                content: [
                    {
                        element: (
                            <EditableSettings
                                component={UserSettings}
                                editableComponent={EditableUserSettings}
                            />
                        ),
                        id: 'User',
                        label: translations.ONLINK_USERS
                    },
                    {
                        element: <RoleSettings/>,
                        id: 'Role',
                        label: translations.ONLINK_ROLES,
                        permission: permissions.EDIT_ROLES
                    },
                    {
                        element: <DepartmentSettings/>,
                        id: 'Departments',
                        label: translations.ONLINK_DEPARTMENTS
                    }
                ],
                title: translations.USER_SETTINGS
            },
            icon: <IconProfileCard iconProfileCard={ICON_STYLE}/>,
            tileName: 'Users'
        }),
        createSettingsTile({
            appAccess: [appAccess.ONGOLF, appAccess.ONLABOR],
            dialogProps: {
                content: [
                    {
                        element: (
                            <AccountingThresholdSettings categoryTranslation={translations.ONLINK_CATEGORIES}/>
                        ),
                        id: 'Accounting',
                        label: translations.ONLINK_ACCOUNTING,
                        appAccess: appAccess.ONGOLF
                    },
                    {
                        element: (
                            <ThresholdSettings
                                category='Environment'
                                key='Environment'
                                typesFunction={typesFunction}
                            />
                        ),
                        id: 'Environment',
                        label: translations.environment,
                        appAccess: appAccess.ONGOLF
                    },
                    {
                        element: (
                            <ThresholdSettings
                                category='Equipment'
                                key='Equipment'
                                typesFunction={typesFunction}
                            />
                        ),
                        id: 'Equipment',
                        label: translations.IWP_EQUIPMENT_LABEL,
                        appAccess: appAccess.ONGOLF
                    },
                    {
                        element: (
                            <ThresholdSettings
                                category='Grounds'
                                key='Grounds'
                                typesFunction={typesFunction}
                            />
                        ),
                        id: 'Grounds',
                        label: translations.ONLINK_GROUNDS,
                        appAccess: appAccess.ONGOLF
                    },
                    {
                        element: (
                            <LaborThresholdSettings typesFunction={typesFunction}/>
                        ),
                        id: 'Labor',
                        label: translations.Labor,
                        appAccess: [appAccess.ONGOLF, appAccess.ONLABOR]
                    },
                    {
                        element: (
                            <ThresholdSettings
                                category='Resources'
                                key='Resources'
                                typesFunction={typesFunction}
                            />
                        ),
                        id: 'Resources',
                        label: translations.ONLINK_RESOURCES,
                        appAccess: appAccess.ONGOLF
                    },
                    {
                        element: (
                            <ThresholdSettings
                                category='Water'
                                key='Water'
                                typesFunction={typesFunction}
                            />
                        ),
                        id: 'Water',
                        label: translations.WATER,
                        appAccess: appAccess.ONGOLF
                    }
                ],
                title: translations.ONLINK_NOTIFICATION_SETTINGS
            },
            icon: <IconNotification iconNotification={ICON_STYLE}/>,
            tileName: 'Notifications'
        }),
        createSettingsTile({
            appAccess: appAccess.ONLABOR,
            dialogProps: {
                content: [
                    {
                        element: (
                            <EditableSettings
                                component={WorkboardSettings}
                                editableComponent={EditableWorkboardSettings}
                                id='Workboard'
                            />
                        ),
                        id: 'Workboard',
                        label: translations.ONLINK_WORKBOARD,
                        myJdPermissions: MANAGE_WORKBOARDS,
                        show: !membership.isMigrated
                    },
                    {
                        element: (
                            <EditableSettings
                                component={MyWorkSettings}
                                editableComponent={EditableMyWorkSettings}
                                id='MyWork'
                            />
                        ),
                        id: 'MyWork',
                        label: translations.ONLINK_MY_WORK,
                        myJdPermissions: MANAGE_WORKBOARDS,
                        show: !membership.isMigrated
                    },
                    {
                        element: <JobsSettings/>,
                        id: 'Jobs',
                        label: translations.JOBS,
                        myJdPermissions: MANAGE_WORKBOARDS
                    },
                    {
                        element: <JobCategoriesSettings/>,
                        id: 'JobCategories',
                        label: translations.ONLINK_JOB_CATEGORIES,
                        myJdPermissions: MANAGE_WORKBOARDS
                    },
                    {
                        element: <MowingDirectionsSettings/>,
                        id: 'MowingDirections',
                        label: translations.ONLINK_MOWING_DIRECTIONS,
                        myJdPermissions: MANAGE_WORKBOARDS
                    },
                    {
                        element: <QuickTodoSettings/>,
                        id: 'QuickTODOs',
                        label: translations.ONLINK_QUICK_TODOS,
                        myJdPermissions: MANAGE_WORKBOARDS
                    },
                    {
                        element: <EmployeeStatusesSettings/>,
                        id: 'Statuses',
                        label: translations.STATUSES,
                        myJdPermissions: MANAGE_WORKBOARDS
                    }
                ],
                title: translations.ONLINK_LABOR_SETTINGS
            },
            icon: <IconMowingOrder iconMowingOrder={ICON_STYLE}/>,
            tileName: 'Labor'
        }),
        createSettingsTile({
            appAccess: appAccess.ONEQUIP,
            dialogProps: {
                content: [
                    {
                        element: <BinLocationsSettings/>,
                        id: 'BinLocations',
                        label: translations.ONLINK_BIN_LOCATIONS,
                        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                    },
                    {
                        element: <DealershipSettings/>,
                        id: 'Dealerships',
                        label: translations.ONLINK_DEALERSHIPS,
                        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                    },
                    {
                        element: <MaintenanceSettings/>,
                        id: 'Maintenance',
                        label: translations.ONLINK_MAINTENANCE,
                        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                    },
                    {
                        element: <ManufacturerSettings/>,
                        id: 'Manufacturers',
                        label: translations.ONLINK_MANUFACTURERS,
                        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                    },
                    {
                        element: <PartsSettings/>,
                        id: 'Parts',
                        label: translations.PARTS,
                        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                    },
                    {
                        element: <PartTypesSettings/>,
                        id: 'PartTypes',
                        label: translations.ONLINK_PART_TYPES,
                        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                    },
                    {
                        element: <PurchaseOrdersSettings/>,
                        id: 'PurchaseOrders',
                        label: translations.ONLINK_PURCHASE_ORDERS,
                        show: !membership.isMigrated
                    }
                ],
                title: translations.ONLINK_FLEET_SETTINGS
            },
            icon: <IconEquipmentTurf iconEquipmentTurf={ICON_STYLE}/>,
            tileName: 'Fleet'
        }),
        vendorTile,
        createSettingsTile({
            dialogProps: {
                content: [
                    {
                        element: <MyJohnDeereLinkedAccount/>,
                        id: 'Linked_Account',
                        label: translations.ONLINK_LINKED_ACCOUNTS
                    },
                    {
                        element: <MyJohnDeereConnections/>,
                        id: 'Connections',
                        label: translations.tool_connections,
                        permission: permissions.MANAGE_MYJD_CONFIGURATION
                    },
                    {
                        element: <MyJohnDeereOrganizations/>,
                        id: 'Organizations',
                        label: translations.ORGANIZATIONS
                    },
                    {
                        element: <MyJohnDeereEquipment/>,
                        id: 'Equipment',
                        label: translations.EQUIPMENT_LIST
                    },
                    {
                        element: <MyJohnDeereStaffMigration/>,
                        id: 'Staff_Migration',
                        label: translations.ONLINK_STAFF_MEMBER_MIGRATION,
                        migrationJdPermissions: EDIT_ORGANIZATION_MYJD,
                        show: showMigrationTabsForConnectedOrgsWithToggle
                    },
                    {
                        element: <MyJohnDeereOperatorMigration/>,
                        id: 'Operator_Migration',
                        label: translations.ONLINK_OPERATOR_MIGRATION,
                        migrationJdPermissions: EDIT_ORGANIZATION_MYJD,
                        show: showMigrationTabsForConnectedOrgsWithToggle
                    },
                    {
                        element: <MyJohnDeereMergeExistingStaffMember/>,
                        id: 'Merge_Existing_Staff',
                        label: translations.ONLINK_MERGE_WITH_EXISTING_STAFF_MEMBER,
                        migrationJdPermissions: EDIT_ORGANIZATION_MYJD,
                        show: showMigrationTabsForConnectedOrgsWithToggle
                    },
                    {
                        element: <MyJohnDeereMergeExistingOperator/>,
                        id: 'Merge_Existing_Operator',
                        label: translations.ONLINK_MERGE_WITH_EXISTING_OPERATOR,
                        migrationJdPermissions: EDIT_ORGANIZATION_MYJD,
                        show: showMigrationTabsForConnectedOrgsWithToggle
                    }
                ],
                title: translations.ONLINK_MYJOHNDEERE_SETTINGS
            },
            icon: <IconDataManager iconDataManager={ICON_STYLE}/>,
            tileName: 'my_john_deere'
        })
    ];
}

async function getAvailableSettings(translations, membership, appKeys, permissions, myJdPermissions, featureToggles) {
    const userAuth = {
        appKeys,
        isMigrated: membership.isMigrated,
        myJdPermissions,
        permissions
    };

    const settings = await getSettingsTiles(translations, membership, featureToggles);
    const filteredSettings = settings.filter((tile) => {
        const authorized = isAuthorized(tile, userAuth);

        if (authorized) {
            tile.dialogProps.content = tile.dialogProps.content.filter((tab) =>
                isAuthorized(tab, userAuth) && (isNullOrUndefined(tab.show) || tab.show)
            );
        }

        return authorized;
    });

    return filteredSettings;
}

export {
    getSettingsTiles,
    getAvailableSettings
};
