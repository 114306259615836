// Unpublished Work © 2021-2024 Deere & Company.

export default {
    'hoc_fairways': 'ONLINK_FAIRWAYS_HOC',
    'hoc_fairways_spec': 'ONLINK_FAIRWAYS_SPEC_HOC',
    'hoc_rough_spec': 'ONLINK_ROUGH_SPEC_HOC',
    'hoc_collars_spec': 'ONLINK_COLLARS_SPEC_HOC',
    'hoc_bunker_surrounds_spec': 'ONLINK_SURROUNDS_SPEC_HOC',
    'hoc_apps_spec': 'ONLINK_APPROACHES_SPEC',
    'hoc_apps': 'ONLINK_APPROACHES',
    'hoc_bunker_faces': 'ONLINK_BUNKER_FACES_HOC',
    'hoc_tees': 'ONLINK_TEES_HOC',
    'hoc_greens_spec': 'ONLINK_GREENS_SPEC_HOC',
    'hoc_int': 'ONLINK_INT_HOC',
    'hoc_int_spec': 'ONLINK_INT_SPEC_HOC',
    'hoc_rough': 'ONLINK_ROUGH_HOC',
    'hoc_bunker_faces_spec': 'ONLINK_BUNKER_FACES_SPEC_HOC',
    'hoc_bunker_surrounds': 'ONLINK_BUNKER_SURROUNDS_HOC',
    'hoc_tees_spec': 'ONLINK_TEES_SPEC_HOC',
    'hoc_greens': 'ONLINK_GREENS_HOC',
    'hoc_collars': 'ONLINK_COLLARS_HOC',
    'other': 'ONLINK_OTHER_HOC'
};
