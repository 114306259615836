// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {connect} from 'react-redux';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import ValidationInput from 'Ui/components/common/form/validation-input';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {useSave} from 'Ui/react-hooks/use-save';
import {createTodo, updateTodo} from 'Services/todo-service';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {DATE_FORMATS} from 'Utils/time-utils';
import moment from 'moment/moment';
import {validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {Datepicker} from '@deere/form-controls';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {fetchEffectData} from 'Utils/react-utils';
import {fetchAndFormatOperator} from 'OnLabor/workboard/utils/fetch-operators';
import PropTypes from 'Utils/prop-type-utils';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

function initializeState(todo, membershipId) {
    const [assignees, setAssignees] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [todoValues, setTodoValues] = React.useState(() => ({
        dateSelect: todo?.dateSelect || moment().format(DATE_FORMATS.day),
        appUserId: todo?.appUserId,
        title: todo?.title || '',
        membershipId
    }));
    const [validatedDate, setValidatedDate] = React.useState();

    return {
        assignees,
        setAssignees,
        loading,
        setLoading,
        todoValues,
        setTodoValues,
        validatedDate,
        setValidatedDate
    };
}

function AddTodoDialog(props) {
    const {
        translations,
        todo,
        closeDialog,
        invalidInputs,
        setValid,
        membershipId,
        fetchTodos
    } = props;

    const {
        assignees,
        setAssignees,
        loading,
        setLoading,
        todoValues,
        setTodoValues,
        validatedDate,
        setValidatedDate
    } = initializeState(todo, membershipId);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        if (!isNullOrUndefined(todo)) {
            await updateTodo(todoValues, todo.todoId);
        } else {
            await createTodo(todoValues);
        }

        await fetchTodos();
        closeDialog();
    }, {
        disabled: loading,
        invalidInputs
    });

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const validatedDateTemp = validateDate(todoValues.dateSelect, setValid);

        setValidatedDate(validatedDateTemp);

        await fetchAndFormatOperator({
            date: validatedDateTemp,
            isMounted,
            setOperators: setAssignees
        });

        setLoading(false);
    }), [todoValues.dateSelect]);

    function onValuesChange(name, value) {
        setTodoValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const dialogTitle = !isNullOrUndefined(todo) ?
        translations.ONLINK_EDIT_TODO :
        translations.ONLINK_ADD_TODO;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className={'quick-todo-dialog dropdown-overflow-dialog'}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={dialogTitle}
            translations={translations}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading || isSaving}
                size='50px'
            >
                <form
                    className='add-todo-form'
                    onSubmit={saveFunc}
                >
                    <div className={'settings-group mobile'}>
                        <Datepicker
                            dateFormat='LL'
                            error={validatedDate ? '' : translations.INVALIDATED_DATE}
                            label={translations.DATE}
                            large={true}
                            locale={getLanguagePreference()}
                            onChange={(date) => onValuesChange('dateSelect', date.format(DATE_FORMATS.day))}
                            onChangeRaw={(event) => onValuesChange('dateSelect', moment(event.target.value).format(DATE_FORMATS.day))}
                            selected={validatedDate}
                        />
                        <ValidationMultiSelect
                            className='mobile-required-multiselect'
                            component={SortedMultiSelect}
                            defaultSelectLabel={translations.ONLINK_SELECT_ASSIGNEE}
                            disabled={false}
                            hideCheckboxes={true}
                            items={assignees}
                            label={translations.ONLINK_ASSIGNEE}
                            multiple={false}
                            name='assignees'
                            onChange={(event) => onValuesChange('appUserId', event[0])}
                            required={true}
                            selectedIds={[todoValues.appUserId]}
                            setValid={setValid}
                            tabIndex={0}
                        />
                    </div>
                    <ValidationInput
                        autoFocus={true}
                        label={translations.TITLE}
                        name='title'
                        onChange={(event) => onValuesChange('title', event.target.value)}
                        required={true}
                        setValid={setValid}
                        tabIndex={0}
                        type='text'
                        value={todoValues.title}
                    />
                </form>
            </LoadingWrapper>
        </FormDialog>
    );
}

AddTodoDialog.propTypes = {
    closeDialog: PropTypes.func,
    fetchTodos: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipId: PropTypes.string,
    setValid: PropTypes.func,
    todo: PropTypes.object,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeDialog(dialogTypes.ADD_TODO_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(AddTodoDialog));
