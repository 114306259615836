// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import {getNavLinks} from '@deere/onlink-navbar/lib/utils/data-utils';
import {IconCaretDirection} from 'Ui/components/main-navbar';
import {clsx} from 'clsx';

function SecondaryNavDropdown(props) {
    const {
        active,
        history,
        location,
        links,
        translations,
        name
    } = props;

    const [open, setOpen] = React.useState(() => false);

    const dropDownProps = {
        history,
        location,
        onClick: () => setOpen(!open),
        translations
    };

    const getOverlay = React.useCallback(() => open && (
        <div
            className='nested-dropdown overlay'
            key={1}
            onClick={() => setOpen(!open)}
        />
    ), [open]);

    const navLinks = getNavLinks(links, dropDownProps);

    const dropdown = (
        <div className={'sidebar-dropdown account-menu small-body-text-bold secondary-nav-dropdown'}>
            <div className={'secondary-nav-dropdown-content-wrapper'}>
                {navLinks}
            </div>
        </div>
    );

    const className = clsx('dropdown-links medium-section-title', {
        'open-dropdown': open,
        'active-dropdown': active
    });

    return (
        <>
            <div className={'secondary-nav-dropdown-wrapper'}>
                <div
                    className={className}
                    onClick={() => setOpen(!open)}
                >
                    <span
                        className='span-divider'
                    >
                        {name}
                        <IconCaretDirection
                            caretUp={open}
                            color='#fff'
                        />
                    </span>
                </div>
                {open && dropdown}
            </div>
            {getOverlay()}
        </>
    );
}

SecondaryNavDropdown.propTypes = {
    active: PropTypes.bool,
    history: PropTypes.history,
    links: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.location,
    name: PropTypes.string,
    translations: PropTypes.translations
};

export default withRouter(SecondaryNavDropdown);
