// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {fetchEffectData, useLazyRef} from 'Utils/react-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {MAX_FILE_SIZE} from 'Common/constants/files';
import {IconPerson} from '@deere/icons';
import {getProfilePic} from 'Services/user-service';
import {v4} from 'uuid';
import {getBase64DataURL} from 'Utils/html-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

const ICON_PERSON_STYLE = {
    style: {
        backgroundColor: '#ddd',
        minHeight: '80px',
        minWidth: '80px'
    }
};

const ICON_PERSON_PRIMARY_STYLE = createIconFillStyle('#aaa');

function ProfilePicInput(props) {
    const {
        profilePicUrl,
        setFiles,
        translations
    } = props;

    const [loading, setLoading] = React.useState(() => true);
    const [profilePicSrc, setProfilePicSrc] = React.useState(() => null);
    const inputId = useLazyRef(() => v4());

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {
            imageData, extension
        } = await getProfilePic(profilePicUrl);

        if (isMounted()) {
            setProfilePicSrc(imageData ? getBase64DataURL(`image/${extension}`, imageData) : null);
            setLoading(false);
        }
    }), [profilePicUrl]);

    const newImageInputs = (
        <label
            className='file-input'
            htmlFor={inputId.current}
        >
            <div className='file-input-btn'>
                <span className='file-input-btn-text'>
                    {translations.ONLINK_SELECT_IMAGE}
                </span>
            </div>
        </label>
    );

    const editImageInputs = (
        <div className='change-image-container'>
            <label
                className='file-input'
                htmlFor={inputId.current}
            >
                <div className='file-input-btn'>
                    <span className='file-input-btn-text'>
                        {translations.ONLINK_CHANGE_IMAGE}
                    </span>
                </div>
            </label>
            <OnlinkButton
                className='cancel'
                onClick={() => {
                    setProfilePicSrc(null);
                    setFiles(null);
                }}
                type='button'
            >
                {translations.ONLINK_REMOVE_IMAGE}
            </OnlinkButton>
        </div>
    );

    return (
        <div className='profile-pic-input-container'>
            <LoadingWrapper
                className='dashboard-loading-icon'
                loading={loading}
                size='50px'
            >
                {
                    profilePicSrc ? (
                        <img
                            alt='Profile Pic'
                            height={80}
                            src={profilePicSrc}
                            width={80}
                        />
                    ) : (
                        <IconPerson
                            iconPerson={ICON_PERSON_STYLE}
                            primary={ICON_PERSON_PRIMARY_STYLE}
                        />
                    )
                }
            </LoadingWrapper>
            <div className='profile-pic-input'>
                <input
                    accept='image/*'
                    className='file-input-hidden'
                    id={inputId.current}
                    onChange={(event) => {
                        const eventFiles = event.target.files;
                        const fileReader = new FileReader();

                        fileReader.addEventListener('load', (loadEvent) => setProfilePicSrc(loadEvent.target.result));

                        if (eventFiles && eventFiles.length > 0) {
                            fileReader.readAsDataURL(eventFiles[0]);
                            setFiles(eventFiles);
                        } else {
                            setProfilePicSrc(null);
                            setFiles(null);
                        }
                    }}
                    tabIndex={0}
                    type='file'
                />
                {profilePicSrc ? editImageInputs : newImageInputs}
                <span className='max-image'>
                    {
                        replaceTranslationNames(translations.ONLINK_MAX_IMAGE_SIZE, {
                            '': MAX_FILE_SIZE
                        })
                    }
                </span>
            </div>
        </div>
    );
}

ProfilePicInput.propTypes = {
    profilePicUrl: PropTypes.string,
    setFiles: PropTypes.func,
    translations: PropTypes.translations
};

export default ProfilePicInput;
