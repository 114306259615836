// Unpublished Work © 2021-2024 Deere & Company.

export const defaultCaryNCLatLong = {
    lat: 35.755979,
    lng: -78.786328
};

export const mapOptions = {
    disableDoubleClickZoom: false,
    draggable: true,
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.HYBRID,
    minZoom: -1,
    streetViewControl: false,
    tilt: 0,
    zoom: 11,
    zoomControl: false,
    rotateControl: false
};

export const bottomSheet = {
    mainNavAndSecondaryNav: 118,
    sheetTab: 32,
    halfway: 1
};

export const MACHINE_STATUS_IDLE_COLOR = '#c0111c';

export const MACHINE_STATUS_POLYLINE_COLORS = {
    working: '#2df41e',
    transport: '#649ed1',
    location: '#00ffc1'
};

export const NUM_DELTAS = 5;
