// Unpublished Work © 2021-2024 Deere & Company.

import ThresholdSettings from 'Ui/components/settings/notifications/threshold-settings';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

const thresholdsWithFootNotes = new Set([
    'total_chem_cost',
    'total_fert_cost',
    'chem_cost',
    'fert_cost'
]);

function getTypes(threshold) {
    return thresholdsWithFootNotes.has(threshold.dataType) ? `${threshold.dataLabel} *` : threshold.dataLabel;
}

export function AccountingThresholdSettings(props) {
    const {categoryTranslation} = props;

    const playbookIndicator =
        (<div className={'extra-table-options right-aligned'}>
            <span className={'playbooks-indicator small-body-text'}>{`* Playbooks ${categoryTranslation}`}</span>
        </div>);

    return (<ThresholdSettings
        category={'Accounting'}
        key={'Accounting'}
        optionalComponent={playbookIndicator}
        typesFunction={getTypes}
    />);
}

AccountingThresholdSettings.propTypes = {
    categoryTranslation: PropTypes.string
};

export default AccountingThresholdSettings;
