// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import OnLinkDialog from 'Ui/components/common/onlink-dialog/onlink-dialog';
import {useDeepMemo} from 'Utils/react-utils';
import {SETTINGS} from 'Common/constants/routes';

function mapRoutableContent(content, tileId, basePath) {
    return content.map((item) => {
        const lowerCaseTabId = item.id.toLowerCase();

        return {
            ...item,
            id: lowerCaseTabId,
            path: `${basePath}/${tileId}/${lowerCaseTabId}`
        };
    });
}

function SettingsDialog(props) {
    const {
        basePath,
        content,
        history,
        match,
        title,
        tileId
    } = props;

    const routableContent = useDeepMemo(() => mapRoutableContent(content, tileId, basePath), [content, tileId, basePath]);
    const activeTab = match.params.tab ?? routableContent[0]?.id;
    const hasValidTab = activeTab && routableContent.some((item) => item.id === activeTab);

    return (
        <OnLinkDialog
            activeTab={activeTab}
            className='settings-dialog'
            closeHandler={() => history.push(basePath)}
            content={routableContent}
            large={true}
            onTabClick={(tabId) => history.push(`${basePath}/${tileId}/${tabId}`)}
            routable={true}
            showError={!hasValidTab}
            title={title}
        />
    );
}

SettingsDialog.defaultProps = {
    basePath: SETTINGS
};

SettingsDialog.propTypes = {
    basePath: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.dialogContent),
    history: PropTypes.history,
    match: PropTypes.match,
    tileId: PropTypes.string,
    title: PropTypes.string
};

export default withRouter(SettingsDialog);
