// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';

function FilterView(props) {
    const [isFilterVisible, setIsFilterVisible] = React.useState(() => false);

    const {
        filterComponent,
        listComponent,
        membershipId
    } = props;

    React.useEffect(() => {
        setIsFilterVisible(false);
    }, [membershipId]);

    const renderedComponent = isFilterVisible ? filterComponent : listComponent;

    return React.cloneElement(renderedComponent, {
        setIsFilterVisible() {
            setIsFilterVisible(!isFilterVisible);
        }
    });
}

FilterView.propTypes = {
    filterComponent: PropTypes.node,
    listComponent: PropTypes.node,
    membershipId: PropTypes.string
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId
    };
}

export default connect(mapStateToProps)(FilterView);
