// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'Utils/prop-type-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {clsx} from 'clsx';
import TertiaryNavBar from 'Ui/components/tertiary-navbar/tertiary-navbar';
import {mapNavLinks} from 'Ui/constants/secondary-navbar-constants';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';

function SidebarLayout(props) {
    const {
        children,
        featureToggles,
        header,
        loading,
        sidebarRef,
        translations
    } = props;

    const className = clsx('map-sidebar-body', {
        hidden: loading
    });

    return (
        <LoadingWrapper
            alwaysRenderChildren={true}
            className='map-sidebar-loading-icon'
            loading={loading}
            size='50px'
        >
            {
                featureToggles[ONLINK_NAVIGATION_REDESIGN] && <TertiaryNavBar
                    headerText='MAP'
                    tabs={mapNavLinks}
                    translations={translations}
                />
            }
            <div
                className={className}
                ref={sidebarRef}
            >
                <div className='map-sidebar-header'>
                    {header}
                </div>
                <div className='map-sidebar-content'>
                    {children}
                </div>
            </div>
        </LoadingWrapper>
    );
}

SidebarLayout.propTypes = {
    children: PropTypes.node,
    featureToggles: PropTypes.featureToggles,
    header: PropTypes.node,
    loading: PropTypes.bool,
    sidebarRef: PropTypes.reference,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(SidebarLayout);
