// Unpublished Work © 2023-2024 Deere & Company.

import {cloneDeep} from 'lodash';

function setStatusForAll(setCollapseStatus, id, status) {
    setCollapseStatus((prevCollapseStatus) => {
        const clonedPrevCollapseStatus = cloneDeep(prevCollapseStatus);

        const newStatusesMap = new Map();

        const existingMap = clonedPrevCollapseStatus.get(id);

        existingMap.forEach((value, workItemId) => newStatusesMap.set(workItemId, status));

        clonedPrevCollapseStatus.set(id, newStatusesMap);

        return clonedPrevCollapseStatus;
    });
}

function setStatusForWorkItem(setCollapseStatus, id, workItemId, status) {
    setCollapseStatus((prevCollapseStatus) => {
        const clonedPrevCollapseStatus = cloneDeep(prevCollapseStatus);

        const existingMap = clonedPrevCollapseStatus.get(id);

        existingMap.set(workItemId, status);

        return clonedPrevCollapseStatus;
    });
}

export {
    setStatusForAll,
    setStatusForWorkItem
};
