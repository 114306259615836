// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import OnlinkButton from 'Ui/components/common/onlink-button';
import React from 'react';
import {useDeepMemo} from 'Utils/react-utils';
import {IconRemove, IconSave, IconCheckmark, IconCircleAdd} from '@deere/icons';
import {DropdownMenu} from '@deere/isg.component-library/lib/DropdownMenu';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import PropTypes from 'Utils/prop-type-utils';
import {STATUS} from 'Utils/parts-utils';
import {openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {connect} from 'react-redux';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function dropdownItemsOrDefault(condition, ...items) {
    return condition ? items : [];
}

function AddEditPurchaseOrderDialogActions(props) {
    const {
        closeDialog,
        disableSave,
        isEdit,
        isMobile,
        openReceiveOrderDialog,
        receiveOrderOnClose,
        saveFunc,
        selectedParts,
        receiveAndCompleteFunc,
        translations,
        values,
        partRequestsToRemove,
        purchaseOrder,
        hasEditFinancialDataPermissions
    } = props;
    const disabled = disableSave || selectedParts.length === 0;

    const dropdownItems = useDeepMemo(() => {
        return [
            ...dropdownItemsOrDefault(
                isEdit && values.startingStatus !== STATUS.COMPLETED,
                {
                    title: translations.ONLINK_RECEIVE_PARTIAL,
                    disabled,
                    Icon: <IconCircleAdd iconCircleAdd={ICON_STYLE}/>,
                    onClick: () => openReceiveOrderDialog({
                        receiveOrderOnClose,
                        receivableParts: selectedParts,
                        translations,
                        values,
                        partRequestsToRemove,
                        purchaseOrder
                    })
                }
            ),
            ...dropdownItemsOrDefault(
                isEdit && values.startingStatus !== STATUS.COMPLETED,
                {
                    title: translations.ONLINK_RECEIVE_AND_COMPLETE,
                    disabled,
                    Icon: <IconCheckmark iconCheckmark={ICON_STYLE}/>,
                    onClick: receiveAndCompleteFunc
                }
            ),
            ...dropdownItemsOrDefault(
                isMobile,
                {
                    title: translations.CANCEL,
                    onClick: closeDialog,
                    Icon: <IconRemove iconRemove={ICON_STYLE}/>
                }),
            ...dropdownItemsOrDefault(
                isMobile && values.startingStatus !== STATUS.COMPLETED,
                {
                    title: translations.save,
                    disabled,
                    onClick: saveFunc,
                    Icon: <IconSave iconSave={ICON_STYLE}/>
                })
        ];
    }, [disableSave, isEdit, isMobile, receiveOrderOnClose, saveFunc, selectedParts, translations, values]);

    return (
        <div className='footer-right-buttons-row'>
            {
                dropdownItems.length > 0 && hasEditFinancialDataPermissions &&
                <DropdownMenu
                    buttonTitle={isMobile ? translations.ACTIONS : translations.ONLINK_OTHER_ACTIONS}
                    className='onlink-action-dropdown footer-elem'
                    items={dropdownItems}
                />
            }
            {
                !isMobile &&
                <>
                    <OnlinkButton
                        className='onlink-btn footer-elem'
                        onClick={closeDialog}
                    >
                        {translations.CANCEL}
                    </OnlinkButton>
                    {
                        values.startingStatus !== STATUS.COMPLETED && hasEditFinancialDataPermissions &&
                        <OnlinkButton
                            className='onlink-btn footer-elem primary'
                            disabled={disabled}
                            onClick={saveFunc}
                        >
                            {translations.save}
                        </OnlinkButton>
                    }
                </>
            }
        </div>
    );
}

AddEditPurchaseOrderDialogActions.propTypes = {
    closeDialog: PropTypes.func,
    disableSave: PropTypes.bool,
    hasEditFinancialDataPermissions: PropTypes.bool,
    isEdit: PropTypes.bool,
    isMobile: PropTypes.bool,
    openReceiveOrderDialog: PropTypes.func,
    partRequestsToRemove: PropTypes.instanceOf(Set),
    purchaseOrder: PropTypes.object,
    receiveAndCompleteFunc: PropTypes.func,
    receiveOrderOnClose: PropTypes.func,
    saveFunc: PropTypes.func,
    selectedParts: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapDispatchToProps(dispatch) {
    return {
        openReceiveOrderDialog(props) {
            dispatch(openDialog(dialogTypes.RECEIVE_ORDER_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(AddEditPurchaseOrderDialogActions));
