// Unpublished Work © 2023-2024 Deere & Company.

export const STATUS = {
    PENDING: 'pending',
    COMPLETED: 'completed',
    RECEIVED: 'received',
    ORDERED: 'ordered'
};

function formatDistributorItem(distributor) {
    const {
        addressElements,
        city,
        state
    } = distributor;

    if (addressElements) {
        const addressElement = addressElements.find(({elementClass}) => elementClass === 'addressLine1');

        return addressElement?.elementValue;
    }

    return state ? `${city}, ${state}` : '';
}

function formatDistributors(distributors, purchaseOrder) {
    const formattedDistributors = distributors.map((distributor) => ({
        title: distributor.name,
        subtitle: formatDistributorItem(distributor),
        id: distributor.membershipId
    }));

    if (purchaseOrder && !formattedDistributors.find((distributor) => distributor.id === purchaseOrder.distributorId)) {
        formattedDistributors.push({
            title: purchaseOrder?.distributor?.name,
            id: purchaseOrder.distributorId
        });
    }

    return formattedDistributors;
}

function formatAvailablePartsData(partsData) {
    return partsData?.reduce((filteredParts, part) => {
        if (part.name) {
            filteredParts.push({
                ...part,
                quantity: '',
                unitCost: ''
            });
        }

        return filteredParts;
    }, []);
}

function getSelectedParts(purchaseOrder) {
    if (purchaseOrder?.partsList) {
        return Object.keys(purchaseOrder.partsList).map((partId) => {
            const partObject = purchaseOrder.partsList[partId];

            return {
                ...partObject,
                id: partObject.partId,
                title: partObject.name,
                subtitle: `${partObject.manufacturerName} - ${partObject.partNumber}`
            };
        });
    }

    return [];
}

function getStatusItems(translations) {
    return [
        {
            id: STATUS.COMPLETED,
            title: translations.COMPLETED_STATUS
        },
        {
            id: STATUS.ORDERED,
            title: translations.ONLINK_ORDERED
        },
        {
            id: STATUS.PENDING,
            title: translations.PENDING_TELEMATICS_STATE
        },
        {
            id: STATUS.RECEIVED,
            title: translations.my_team_received
        }
    ];
}

function getPurchaseOrderStatusItem(translations, status) {
    const statusItems = [{
        id: STATUS.COMPLETED,
        title: translations.COMPLETED_STATUS
    }];

    if (status === STATUS.RECEIVED) {
        statusItems.push({
            id: STATUS.RECEIVED,
            title: translations.my_team_received
        });

        return statusItems;
    }

    if (status !== STATUS.COMPLETED) {
        statusItems.push({
            id: STATUS.ORDERED,
            title: translations.ONLINK_ORDERED
        });

        if (status !== STATUS.ORDERED) {
            statusItems.push({
                id: STATUS.PENDING,
                title: translations.PENDING_TELEMATICS_STATE
            });
        }
    }

    return statusItems;
}

export {
    formatDistributorItem,
    formatDistributors,
    formatAvailablePartsData,
    getSelectedParts,
    getPurchaseOrderStatusItem,
    getStatusItems
};
