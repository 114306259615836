// Unpublished Work © 2020-2024 Deere & Company.

import equipmentPositions from 'Ui/components/common/tile-constants/drill-ins/equipment';
import fertChemPositions from 'Ui/components/common/tile-constants/drill-ins/fert-chem';
import laborPositions from 'Ui/components/common/tile-constants/drill-ins/labor';
import roundsPositions from 'Ui/components/common/tile-constants/drill-ins/rounds';
import waterEnergyPositions from 'Ui/components/common/tile-constants/drill-ins/water-energy';
import weatherCoursePositions from 'Ui/components/common/tile-constants/drill-ins/weather-course';

export default {
    equipment: equipmentPositions,
    'fert_chem': fertChemPositions,
    labor: laborPositions,
    rounds: roundsPositions,
    'water_energy': waterEnergyPositions,
    'weather_course': weatherCoursePositions
};
