// Unpublished Work © 2022-2024 Deere & Company.

export const PLATFORM_DATA_ADDED = 'platform data added';
export const FUEL_ADDED = 'fuel added';

export const DEFAULT_TYPES = [
    {
        key: 'add',
        translation: 'ADD'
    },
    {
        key: 'service',
        translation: 'ONLINK_SERVICE'
    },
    {
        key: 'transfer',
        translation: 'TRANSFER'
    },
    {
        key: 'note added',
        translation: 'ONLINK_HISTORY_TYPE_NOTE'
    }
];

export const OPTIONAL_TYPES = [
    {
        key: 'status change',
        translation: 'ONLINK_HISTORY_TYPE_STATUS'
    },
    {
        key: 'Terminal Data Received',
        translation: 'ONLINK_HISTORY_TYPE_PLATFORM'
    }
];
