// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import RadioButton from 'Ui/components/common/form/radio-button';
import {NONE, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import * as UNIT_NAMES from 'Common/constants/data-unit-constants';
import {updateMembershipData} from 'Services/membership-service';
import {updateMembership} from 'Store/actions/membership';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {getUOMOverrideUnits} from 'Common/constants/uom-override-units';
import {
    CLIPPING_YIELD,
    CLIPPING_YIELD_VOLUME,
    CLIPPING_YIELD_WEIGHT,
    FIRMNESS,
    FIRMNESS_F355_EQUIVALENTS, FIRMNESS_GRAVITIES,
    GREEN_SPEED,
    WSPD
} from 'Common/constants/data-group-constants';
import {getDataType} from 'Common/utils/unit-of-measure-utils';

function getOptionsFromUnits(unitValues, unitsForDropdown) {
    return unitsForDropdown.map((unit) => unitValues[unit]);
}

function getOptions(unitValues) {
    const clippingYieldOptions = getOptionsFromUnits(unitValues,
        [NONE, UNIT_NAMES.BASKET, UNIT_NAMES.KILOGRAM, UNIT_NAMES.POUNDS, UNIT_NAMES.LITERS, UNIT_NAMES.QUARTS]
    );

    const firmnessOptions = getOptionsFromUnits(unitValues,
        [NONE, UNIT_NAMES.F355_EQUIVALENTS, UNIT_NAMES.GRAVITIES, UNIT_NAMES.INCHES, UNIT_NAMES.MM]
    );

    const greenSpeedOptions = getOptionsFromUnits(unitValues,
        [NONE, UNIT_NAMES.FEET_AND_INCHES, UNIT_NAMES.METERS]
    );

    const windSpeedOptions = getOptionsFromUnits(unitValues,
        [NONE, UNIT_NAMES.MILES_PER_HOUR, UNIT_NAMES.KILOMETERS_PER_HOUR]
    );

    return {
        clippingYieldOptions,
        firmnessOptions,
        greenSpeedOptions,
        windSpeedOptions
    };
}

function EditablePreferenceSettings(props) {
    const {
        membership,
        setIsEditing,
        setValid,
        translations,
        updateMembership
    } = props;

    const UNITS = getUOMOverrideUnits(translations);

    const {
        clippingYieldOptions,
        firmnessOptions,
        greenSpeedOptions,
        windSpeedOptions
    } = getOptions(UNITS);

    const preferredOverrides = React.useMemo(
        () => membership.preferredDataTypes.reduce(
            (overridesObject, currentOverride) => ({
                ...overridesObject,
                [currentOverride.dataType]: currentOverride.measurement
            }),
            {}),
        [membership.preferredDataTypes]
    );

    const [unitOfMeasure, setUnitOfMeasure] = React.useState(membership.unitOfMeasure);

    const [values, setValues] = React.useState({
        'clipping_yield': preferredOverrides[CLIPPING_YIELD] || preferredOverrides[CLIPPING_YIELD_VOLUME] || preferredOverrides[CLIPPING_YIELD_WEIGHT] || NONE,
        firmness: preferredOverrides[FIRMNESS] || preferredOverrides[FIRMNESS_F355_EQUIVALENTS] || preferredOverrides[FIRMNESS_GRAVITIES] || NONE,
        'green_speed': preferredOverrides[GREEN_SPEED] || NONE,
        wspd: preferredOverrides[WSPD] || NONE
    });

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onRadioChange(event) {
        setUnitOfMeasure(event.target.value);
    }

    async function onSave() {
        const overrides = [];

        Object.keys(values).forEach((valueKey) => {
            const measurement = values[valueKey];

            if (measurement && measurement !== NONE) {
                const dataType = getDataType(valueKey, measurement);

                overrides.push({
                    dataType,
                    unitOfMeasure: UNITS[measurement]?.unitOfMeasure || null,
                    measurement
                });
            }
        });

        const membershipData = {
            ...membership,
            overrides,
            unitOfMeasure
        };

        const {membership: updatedMembership} = await updateMembershipData(membershipData, membership.membershipId);

        updateMembership({
            ...membership,
            ...updatedMembership
        });
        setIsEditing();
    }

    return (
        <>
            <form className='settings-body editable-preferences-settings'>
                <fieldset>
                    <div className='unit-of-measure-label'>
                        {translations.UNIT_OF_MEASURE}
                    </div>
                    <RadioButton
                        className='inline-radio-button'
                        id={UNIT_OF_MEASURE.ENGLISH}
                        isSelected={unitOfMeasure === UNIT_OF_MEASURE.ENGLISH}
                        label={translations.UNIT_OF_MEASURE0}
                        onChange={onRadioChange}
                        value={UNIT_OF_MEASURE.ENGLISH}
                    />
                    <RadioButton
                        className='inline-radio-button'
                        id={UNIT_OF_MEASURE.METRIC}
                        isSelected={unitOfMeasure === UNIT_OF_MEASURE.METRIC}
                        label={translations.METRIC}
                        onChange={onRadioChange}
                        value={UNIT_OF_MEASURE.METRIC}
                    />
                </fieldset>
                {
                    <>
                        <div className='defaults-label'>
                            {translations.ONLINK_DEFAULTS}
                        </div>
                        <ValidationMultiSelect
                            className='multiselect'
                            items={windSpeedOptions}
                            label={translations.WIND_SPEED}
                            name='windSpeed'
                            onChange={(selectedIds) => onValuesChange(WSPD, selectedIds[0])}
                            selectedIds={values[WSPD] ? [values[WSPD]] : []}
                            setValid={setValid}
                            tabIndex={0}
                        />
                        <ValidationMultiSelect
                            className='multiselect'
                            items={greenSpeedOptions}
                            label={translations.ONLINK_GREEN_SPEED}
                            name='greenSpeed'
                            onChange={(selectedIds) => onValuesChange(GREEN_SPEED, selectedIds[0])}
                            selectedIds={values[GREEN_SPEED] ? [values[GREEN_SPEED]] : []}
                            setValid={setValid}
                            tabIndex={0}
                        />
                        <ValidationMultiSelect
                            className='multiselect'
                            items={clippingYieldOptions}
                            label={translations.CLIPPING_YIELD}
                            name='clippingYield'
                            onChange={(selectedIds) => onValuesChange(CLIPPING_YIELD, selectedIds[0])}
                            selectedIds={values[CLIPPING_YIELD] ? [values[CLIPPING_YIELD]] : []}
                            setValid={setValid}
                            tabIndex={0}
                        />
                        <ValidationMultiSelect
                            className='multiselect space'
                            items={firmnessOptions}
                            label={translations.Firmness}
                            name='firmness'
                            onChange={(selectedIds) => onValuesChange(FIRMNESS, selectedIds[0])}
                            selectedIds={values[FIRMNESS] ? [values[FIRMNESS]] : []}
                            setValid={setValid}
                            tabIndex={0}
                        />
                    </>
                }
            </form>

            <SaveContentBar
                onCancelClick={setIsEditing}
                onSaveClick={onSave}
                translations={translations}
            />
        </>
    );
}

EditablePreferenceSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateMembership(value) {
            dispatch(updateMembership(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EditablePreferenceSettings));
