// Unpublished Work © 2020-2024 Deere & Company.

import {graphRedirect} from 'Utils/redirect-utils';
import {VIEW_EQUIPMENT_LIST_API} from 'Common/constants/business-activities';

const EQUIPMENT_1_ID = 'be4ade6b-4c17-432d-b746-496f2a2051d8';
const EQUIPMENT_2_ID = 'a3125a0f-7373-457d-981b-766f3c86e141';
const EQUIPMENT_3_ID = '8f0f8b8b-76bd-439e-b53a-aec73412ee02';
const EQUIPMENT_4_ID = 'fd3eea69-8c10-4a63-a2b3-5d5aba7c4db1';

const EQUIPMENT_URL = 'panelTypes/equipment';

export default {
    [EQUIPMENT_1_ID]: {
        data: [],
        dateSelect: 'month',
        myJdPermissions: VIEW_EQUIPMENT_LIST_API,
        name: 'equipment_1',
        onClick: graphRedirect,
        tileId: EQUIPMENT_1_ID,
        url: EQUIPMENT_URL
    },
    [EQUIPMENT_2_ID]: {
        data: [
            'total_equipment_cost',
            'percent_uptime'
        ],
        dateSelect: 'month',
        dateSelectionTypes: ['month', 'year'],
        graphTitle: 'Equipment',
        name: 'equipment_2',
        onClick: graphRedirect,
        tileId: EQUIPMENT_2_ID,
        url: EQUIPMENT_URL,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    },
    [EQUIPMENT_3_ID]: {
        data: [
            'total_equipment_cost',
            'percent_uptime'
        ],
        dateSelect: 'year',
        dateSelectionTypes: ['month', 'year'],
        graphTitle: 'Equipment',
        name: 'equipment_3',
        onClick: graphRedirect,
        tileId: EQUIPMENT_3_ID,
        url: EQUIPMENT_URL,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    },
    [EQUIPMENT_4_ID]: {
        columnCount: 2,
        data: [
            'fuel',
            'fuel_cost',
            'total_fuel_cost',
            'gas',
            'gas_cost',
            'total_gas_cost'
        ],
        dateSelect: 'month',
        name: 'equipment_4',
        onClick: graphRedirect,
        tileId: EQUIPMENT_4_ID,
        url: EQUIPMENT_URL,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    }
};
