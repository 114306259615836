// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Line} from 'react-chartjs-2';
import {configForUnit} from 'Utils/data-utils';
import {getGraphTimeScaleUnit, translateGraphLabel} from 'Utils/graph-utils';
import {getDataUnit} from 'Utils/manual-data-utils';
import {forceLocalTimeZone, formatLocalizedTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import {
    formatUoMValue,
    getCurrencyConfig,
    getFormattedCurrency,
    getFormatting,
    getPreferredDataType,
    getUoMConfig
} from 'Utils/unit-conversion-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {DOLLARS} from 'Common/constants/data-unit-constants';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {sortBy} from 'lodash';
import moment from 'moment';

function getDatasetLabel(label, formatting, translations) {
    const formattingConfig = formatting.dataUnits ? formatting.dataUnits[0] : formatting;

    if (formattingConfig.suffix) {
        return `${label} (${translations[formattingConfig.suffix] || formattingConfig.suffix})`;
    }

    if (formattingConfig.prefix) {
        return `${label} (${translations[formattingConfig.prefix] || formattingConfig.prefix})`;
    }

    return label;
}

function formatValue(value, {
    currencyPreference,
    featureToggles,
    formatting,
    translations,
    unit
}) {
    if (unit === DOLLARS) {
        return getFormattedCurrency(value, {
            currencyPreference,
            featureToggles
        });
    }

    return formatUoMValue(value, {
        formatting: getFormatting(formatting),
        translations
    });
}

function getLabels(chartData, isDaily) {
    const dates = Object.keys(chartData).reduce((xAxisDates, dataType) => {
        const timeSelects = isDaily ?
            chartData[dataType].data.map((x) => forceLocalTimeZone(x.timeSample)) :
            chartData[dataType].data.map((x) => moment(x.timeSelect));

        return xAxisDates.concat(timeSelects);
    }, []);

    const unique = new Set(dates);
    const merged = Array.from(unique);

    return sortBy(merged);
}

function getDataByUnit(item, featureToggles, membership, translations, useNonConverted) {
    return item.data.reduce((dataByUnit, dataItem) => {
        const defaultDataUnit = dataItem.dataUnit || dataItem.dataUnits || item.dataUnits;

        const dataUnit = useNonConverted ?
            getDataUnit({
                currencyPreference: membership.currencyPreference,
                dataType: dataItem.dataType,
                dataUnit: defaultDataUnit,
                featureToggles,
                translations
            }) :
            getPreferredDataType(membership.preferredDataTypes, membership.unitOfMeasure, dataItem.dataType) || defaultDataUnit;

        if (!dataByUnit.has(dataUnit)) {
            dataByUnit.set(dataUnit, []);
        }

        dataByUnit.get(dataUnit).push(dataItem);

        return dataByUnit;
    }, new Map());
}

function getUoMFormattingConfig({
    dataType,
    featureToggles,
    isConfigForGraph,
    membership,
    unit,
    useNonConverted
}) {
    return useNonConverted ?
        configForUnit(unit, isConfigForGraph) :
        getUoMConfig({
            dataType,
            dataUnit: unit,
            isConfigForGraph,
            featureToggles,
            unitOfMeasure: membership.unitOfMeasure,
            unitOfMeasureOverrides: membership.unitOfMeasureOverrides
        }).formatting;
}

function getFormattingConfig({
    dataType,
    featureToggles,
    formattedCurrency,
    membership,
    unit,
    useNonConverted
}) {
    if (unit === DOLLARS) {
        return getCurrencyConfig(formattedCurrency);
    }

    return getUoMFormattingConfig({
        dataType,
        featureToggles,
        isConfigForGraph: false,
        membership,
        unit,
        useNonConverted
    });
}

function calculatePointValueIfDataIsArray(dataType, formatting, value) {
    if (Array.isArray(value) && formatting.conversionFactors) {
        const maxConversionFactor = Math.max(...Object.values(formatting.conversionFactors));

        return (value[0] * maxConversionFactor + value[1]) / maxConversionFactor;
    }

    return value;
}

function getDataSets(chartData, membership, translations, featureToggles, isDaily) {
    let i = 0;

    const formattedCurrency = getFormattedCurrency(0, {
        currencyPreference: membership.currencyPreference,
        featureToggles
    });

    return Object.keys(chartData).reduce((datasets, dataType) => {
        const value = chartData[dataType];
        const {
            config,
            useField
        } = value;
        const graphLabel = translateGraphLabel(config, dataType, translations);

        getDataByUnit(value, featureToggles, membership, translations, isDaily).forEach((items, unit) => {
            const color = GRAPH_COLORS[i];
            const sortedItems = isDaily ? sortBy(items, 'timeSample') : sortBy(items, 'timeSelect');
            const formatting = getFormattingConfig({
                dataType,
                featureToggles,
                formattedCurrency,
                membership,
                unit,
                useNonConverted: isDaily
            });

            datasets.push({
                backgroundColor: color,
                borderColor: color,
                data: sortedItems.map((point) => {
                    const {
                        [`${useField}Value`]: pointValue,
                        dataValue,
                        timeSelect,
                        timeSample
                    } = point;

                    const adjustedTime = isDaily ? forceLocalTimeZone(timeSample) : timeSelect;

                    const yValue = !isNullOrUndefined(pointValue) ? pointValue : dataValue;

                    return {
                        x: moment(adjustedTime),
                        y: calculatePointValueIfDataIsArray(value.dataType, formatting, yValue),
                        yAxesData: yValue
                    };
                }),
                fill: false,
                formatting,
                label: getDatasetLabel(graphLabel, formatting, translations),
                useField,
                yAxisID: unit
            });

            i += 1;
        });

        return datasets;
    }, []);
}

function getYAxes(chartData, membership, translations, featureToggles, useNonConverted) {
    let isRightAligned = false;

    return Object.keys(chartData).reduce((yAxes, dataType) => {
        const value = chartData[dataType];

        getDataByUnit(value, featureToggles, membership, translations, useNonConverted).forEach((items, unit) => {
            const formatting = getUoMFormattingConfig({
                dataType,
                featureToggles,
                isConfigForGraph: true,
                membership,
                unit,
                useNonConverted
            });

            if (!yAxes[unit]) {
                yAxes[unit] = {
                    axis: 'y',
                    display: 'auto',
                    position: isRightAligned ? 'right' : 'left',
                    ticks: {
                        callback(number) {
                            return formatValue(number, {
                                currencyPreference: membership.currencyPreference,
                                featureToggles,
                                formatting,
                                translations,
                                unit
                            });
                        },
                        precision: 0
                    },
                    type: 'linear'
                };

                isRightAligned = !isRightAligned;
            }
        });

        return yAxes;
    }, {});
}

function LineChart(props) {
    const {
        chartData,
        featureToggles,
        membership,
        timeScale,
        translations
    } = props;

    const timeScaleUnit = getGraphTimeScaleUnit(timeScale);
    const isDaily = timeScale === 'day';

    return (
        <Line
            data={{
                datasets: getDataSets(chartData, membership, translations, featureToggles, isDaily),
                labels: getLabels(chartData, isDaily)
            }}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(tooltipItems) {
                                const [{raw}] = tooltipItems;

                                return formatLocalizedTime(raw.x, TIME_UNIT_OPTIONS[timeScaleUnit]);
                            },
                            label(tooltipItem) {
                                const {
                                    dataset, raw
                                } = tooltipItem;
                                const {
                                    formatting, label, useField, yAxisID
                                } = dataset;

                                const rawNumber = yAxisID === DOLLARS ? raw.y : raw.yAxesData;
                                const formattedNumber = formatValue(rawNumber, {
                                    currencyPreference: membership.currencyPreference,
                                    featureToggles,
                                    formatting,
                                    translations,
                                    unit: yAxisID
                                });

                                const valueType = capitalizeFirstLetter(useField);

                                return `${label}: ${formattedNumber} ${valueType}`;
                            }
                        }
                    }
                },
                scales: {
                    ...getYAxes(chartData, membership, translations, featureToggles, isDaily),
                    timeAxis: {
                        axis: 'x',
                        position: 'bottom',
                        ticks: {
                            callback(tickValue, index, ticks) {
                                return formatLocalizedTime(moment(ticks[index].value), TIME_UNIT_OPTIONS[timeScaleUnit]);
                            }
                        },
                        time: {
                            unit: timeScaleUnit
                        },
                        type: 'time'
                    }
                }
            }}
            type='line'
        />
    );
}

LineChart.propTypes = {
    chartData: PropTypes.object,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export default LineChart;
