// Unpublished Work © 2021-2024 Deere & Company.

import {getMowingPatternCompositeImage} from 'Utils/icon-utils';
const SPACE_CHARACTER = / /g;
const DEFAULT_ICON_ALIAS = {
    background: {
        alias: 'background'
    },
    clock: {
        alias: 'clock'
    }
};

const CUTTING_STYLE_ALIAS = {
    single: {
        match: 'Single'
    },
    double: {
        match: 'Double'
    },
    contour: {
        match: 'Contour',
        alias: 'contour'
    },
    twoColors: {
        match: '2 Colors',
        alias: 'twoColor'
    },
    backtrack: {
        match: 'Backtrack'
    }
};

const CLEANUP_PASS_ALIAS = {
    teeToGreen: {
        match: 'Tee to Green',
        alias: 'teeToGreen'
    },
    greenToTee: {
        match: 'Green to Tee',
        alias: 'greenToTee'
    },
    clockwise: {
        match: 'Clockwise',
        alias: 'clockwise'
    },
    counterClockwise: {
        match: 'Counter Clockwise',
        alias: 'counterClockwise'
    }
};

const MOWING_DIRECTION_ALIAS = {
    direction126: {
        match: '12-6',
        cutAlias: 'cut126',
        arrowAlias: 'arrow126'
    },
    direction39: {
        match: '3-9',
        cutAlias: 'cut39',
        arrowAlias: 'arrow39'
    },
    direction115: {
        match: '11-5',
        cutAlias: 'cut115',
        arrowAlias: 'arrow115'
    },
    direction71: {
        match: '7-1',
        cutAlias: 'cut71',
        arrowAlias: 'arrow71'
    },
    direction104: {
        match: '10-4',
        cutAlias: 'cut104',
        arrowAlias: 'arrow104'
    },
    direction82: {
        match: '8-2',
        cutAlias: 'cut82',
        arrowAlias: 'arrow82'
    },
    direction612: {
        match: '6-12',
        cutAlias: 'cut126',
        arrowAlias: 'arrow126'
    },
    direction93: {
        match: '9-3',
        cutAlias: 'cut39',
        arrowAlias: 'arrow39'
    },
    direction511: {
        match: '5-11',
        cutAlias: 'cut115',
        arrowAlias: 'arrow115'
    },
    direction17: {
        match: '1-7',
        cutAlias: 'cut71',
        arrowAlias: 'arrow71'
    },
    direction410: {
        match: '4-10',
        cutAlias: 'cut104',
        arrowAlias: 'arrow104'
    },
    direction28: {
        match: '2-8',
        cutAlias: 'cut82',
        arrowAlias: 'arrow82'
    },
    greenToTee: {
        match: 'Single-GreentoTee',
        arrowAlias: 'arrow6',
        cutAlias: 'backgroundDark'
    },
    teeToGreen: {
        match: 'Single-TeetoGreen',
        arrowAlias: 'arrow12'
    },
    awayRight: {
        match: '50/50-AwayRight',
        arrowAlias: 'backTrack612',
        cutAlias: 'twoColor'
    },
    awayLeft: {
        match: '50/50-AwayLeft',
        arrowAlias: 'backTrack126',
        cutAlias: 'fiftyFiftyAwayLeft'
    },
    circleClockwise: {
        match: 'Circle-Clockwise',
        arrowAlias: 'arrowClockwise',
        cutAlias: 'circleCenter',
        backgroundAlias: 'backgroundDark'
    },
    circleCounterClockwise: {
        match: 'Circle-CounterClockwise',
        arrowAlias: 'arrowCounterClockWise',
        cutAlias: 'circleCenter',
        backgroundAlias: 'backgroundDark'
    }
};

const BACK_TRACK_MOWING_DIRECTION_ALIAS = {

    direction17: {
        match: '1-7',
        alias: 'backTrack17'
    },
    direction28: {
        match: '2-8',
        alias: 'backTrack28'
    },
    direction39: {
        match: '3-9',
        alias: 'backTrack39'
    },
    direction410: {
        match: '4-10',
        alias: 'backTrack410'
    },
    direction511: {
        match: '5-11',
        alias: 'backTrack511'
    },
    direction612: {
        match: '6-12',
        alias: 'backTrack612'
    },
    direction71: {
        match: '7-1',
        alias: 'backTrack71'
    },
    direction82: {
        match: '8-2',
        alias: 'backTrack82'
    },
    direction93: {
        match: '9-3',
        alias: 'backTrack93'
    },
    direction104: {
        match: '10-4',
        alias: 'backTrack104'
    },
    direction115: {
        match: '11-5',
        alias: 'backTrack115'
    },
    direction126: {
        match: '12-6',
        alias: 'backTrack126'
    }
};

function iconAliasForMowingDirection(direction) {
    const iconAliasArray = [];
    const directionNoSpace = direction?.replace(SPACE_CHARACTER, '');

    switch (directionNoSpace) {
        case MOWING_DIRECTION_ALIAS.direction126.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction126.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction126.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction39.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction39.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction39.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction115.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction115.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction115.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction71.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction71.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction71.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction104.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction104.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction104.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction82.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction82.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction82.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction612.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction612.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction612.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction93.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction93.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction93.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction511.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction511.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction511.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction17.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction17.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction17.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction410.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction410.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction410.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.direction28.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction28.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.direction28.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.awayLeft.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.awayLeft.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.awayLeft.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.awayRight.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.awayRight.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.awayRight.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.greenToTee.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.greenToTee.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.greenToTee.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.teeToGreen.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.teeToGreen.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.circleClockwise.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.circleClockwise.backgroundAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.circleClockwise.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.circleClockwise.arrowAlias);
            break;
        case MOWING_DIRECTION_ALIAS.circleCounterClockwise.match:
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.circleCounterClockwise.backgroundAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.circleCounterClockwise.cutAlias);
            iconAliasArray.push(MOWING_DIRECTION_ALIAS.circleCounterClockwise.arrowAlias);
            break;
        case CUTTING_STYLE_ALIAS.contour.match:
            iconAliasArray.push(CUTTING_STYLE_ALIAS.contour.alias);
            break;
        default:
    }

    return iconAliasArray;
}

function iconAliasForBacktrackMowingDirection(direction) {
    const iconAliasArray = [];
    const directionNoSpace = direction?.replace(SPACE_CHARACTER, '');

    switch (directionNoSpace) {
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction126.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction126.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction39.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction39.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction115.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction115.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction71.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction71.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction104.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction104.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction82.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction82.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction612.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction612.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction93.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction93.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction511.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction511.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction17.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction17.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction410.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction410.alias);
            break;
        case BACK_TRACK_MOWING_DIRECTION_ALIAS.direction28.match:
            iconAliasArray.push(BACK_TRACK_MOWING_DIRECTION_ALIAS.direction28.alias);
            break;
        default:
    }

    return iconAliasArray;
}

/* eslint-disable max-statements */
function iconAliasForMowingPattern(mowingPattern) {
    let iconAliasArray = [];

    iconAliasArray.push(DEFAULT_ICON_ALIAS.background.alias);

    switch (mowingPattern.cleanupPass) {
        case CUTTING_STYLE_ALIAS.contour.match:
            iconAliasArray.push(CUTTING_STYLE_ALIAS.contour.alias);
            break;
        default:
    }

    switch (mowingPattern.cuttingStyle) {
        case CUTTING_STYLE_ALIAS.single.match:
            iconAliasArray = iconAliasArray.concat(iconAliasForMowingDirection(mowingPattern.direction));
            break;
        case CUTTING_STYLE_ALIAS.double.match: {
            const first = iconAliasForMowingDirection(mowingPattern.direction);
            const second = iconAliasForMowingDirection(mowingPattern.direction2);

            if (first.length > 0) {
                iconAliasArray.push(first[0]);
            }
            if (second.length > 0) {
                iconAliasArray.push(second[0]);
            }
            if (first.length > 1) {
                iconAliasArray.push(first[1]);
            }
            if (second.length > 1) {
                iconAliasArray.push(second[1]);
            }
            break;
        }
        case CUTTING_STYLE_ALIAS.contour.match:
            iconAliasArray.push(CUTTING_STYLE_ALIAS.contour.alias);
            break;
        case CUTTING_STYLE_ALIAS.twoColors.match:
            iconAliasArray.push(CUTTING_STYLE_ALIAS.twoColors.alias);
            break;
        case CUTTING_STYLE_ALIAS.backtrack.match:
            iconAliasArray = iconAliasArray.concat(iconAliasForBacktrackMowingDirection(mowingPattern.direction));
            break;
        default:
    }

    switch (mowingPattern.cleanupPass) {
        case CLEANUP_PASS_ALIAS.teeToGreen.match:
            iconAliasArray.push(CLEANUP_PASS_ALIAS.teeToGreen.alias);
            break;
        case CLEANUP_PASS_ALIAS.greenToTee.match:
            iconAliasArray.push(CLEANUP_PASS_ALIAS.greenToTee.alias);
            break;
        case CLEANUP_PASS_ALIAS.clockwise.match:
            iconAliasArray.push(CLEANUP_PASS_ALIAS.clockwise.alias);
            break;
        case CLEANUP_PASS_ALIAS.counterClockwise.match:
            iconAliasArray.push(CLEANUP_PASS_ALIAS.counterClockwise.alias);
            break;
        default:
    }

    iconAliasArray.push(DEFAULT_ICON_ALIAS.clock.alias);

    return iconAliasArray;
}

async function getMowingPatternImage(mowingPattern) {
    const iconAliasArray = iconAliasForMowingPattern(mowingPattern);

    return getMowingPatternCompositeImage(iconAliasArray);
}

async function getMowingPatternImageForWorkboard(workboard) {
    const mowingPatterns = workboard?.mowingPatterns?.filter((mowingPattern) => mowingPattern.cuttingStyle);

    const mowingPatternImagePromises = mowingPatterns?.map(async (mowingPattern) => {
        const imgSrc = await getMowingPatternImage(mowingPattern);

        return {
            ...mowingPattern,
            imgSrc
        };
    });

    return mowingPatternImagePromises ? await Promise.all(mowingPatternImagePromises) : [];
}

export {
    getMowingPatternImageForWorkboard,
    getMowingPatternImage,
    DEFAULT_ICON_ALIAS,
    CUTTING_STYLE_ALIAS,
    CLEANUP_PASS_ALIAS,
    MOWING_DIRECTION_ALIAS,
    BACK_TRACK_MOWING_DIRECTION_ALIAS
};
