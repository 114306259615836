// Unpublished Work © 2023-2024 Deere & Company.

function hasAddressElementInDropdown(dropDownElements, addressElement) {
    return dropDownElements.some((dropDownElement) =>
        dropDownElement.abbreviation === addressElement ||
        dropDownElement.name === addressElement
    );
}

function resetAddressElementInputs(addressFormatElements, addressElements, onAddressElementChange) {
    addressFormatElements.forEach((addressField) => {
        const {
            addressFormatElementClass,
            dropDownElements
        } = addressField;

        const {[addressFormatElementClass]: addressElement} = addressElements;

        if (dropDownElements?.length && !hasAddressElementInDropdown(dropDownElements, addressElement)) {
            onAddressElementChange(addressFormatElementClass, null);
        }
    });
}

export {
    resetAddressElementInputs
};
