// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconAdd} from '@deere/icons';
import EditLink from 'Ui/components/common/data-table/edit-link';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import SelectJobsCategoryInputs from 'OnLabor/workboard/common/select-jobs-category-inputs';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {openDialog} from 'Store/actions/dialogs';
import {useDeepMemo} from 'Utils/react-utils';
import {formatMinutesToHours} from 'Utils/time-utils';
import {fetchJobTemplates} from 'OnLabor/workboard/utils/fetch-job-templates';
import dialogTypes from 'Ui/components/common/dialog-types';

const ICON_ADD_STYLE = {
    style: {
        display: 'inline',
        fill: '#156cb6',
        height: '18px',
        marginRight: '5px',
        width: '18px'
    }
};

function SelectJobsForm(props) {
    const {
        loading,
        openJobDialog,
        setOperators,
        setTemplates,
        setValid,
        setValues,
        templates,
        translations,
        values
    } = props;

    const [showInactive, setShowInactive] = React.useState(false);

    const templatesById = useDeepMemo(() => templates.reduce((templatesByIdMap, template) => {
        templatesByIdMap.set(template.jobTemplateId, template);

        return templatesByIdMap;
    }, new Map()), [templates]);

    const mappedTemplates = useDeepMemo(() => {
        const filteredTemplates = showInactive ?
            templates :
            templates.filter((template) => !template.inactive ||
                values.jobs[template.jobCategory]?.some((job) => job.jobTemplateId === template.jobTemplateId));

        return filteredTemplates.map((template) => {
            const {
                estDuration,
                jobCategory,
                jobTemplateId,
                title
            } = template;

            const deletedJobCategory = jobCategory === translations.ONLINK_DELETED_JOBS;

            return {
                id: jobTemplateId,
                subtitle: `${formatMinutesToHours(estDuration)} ${translations.HOURS}`,
                title: title ?? '',
                jobCategory,
                extraContent: deletedJobCategory ?
                    (
                        <div className={'deleted-jobs-category'}>{translations.ONLINK_DELETED_JOBS}</div>
                    ) : null
            };
        });
    }, [showInactive, templates, translations.HOURS]);

    return (
        <div
            className='add-workboard-form select-form-columns'
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading.templates}
                size='50px'
            >
                <SwitchInput
                    checked={showInactive}
                    className='end-margin'
                    name='showInactive'
                    offLabel={translations.ONLINK_SHOW_INACTIVE}
                    onChange={() => setShowInactive(!showInactive)}
                    onLabel={translations.ONLINK_HIDE_INACTIVE}
                    translations={translations}
                />
                <SelectJobsCategoryInputs
                    mappedTemplates={mappedTemplates}
                    setOperators={setOperators}
                    setValid={setValid}
                    setValues={setValues}
                    templatesById={templatesById}
                    translations={translations}
                    values={values}
                />
                <EditLink
                    onClick={() => {
                        openJobDialog({
                            getUpdatedJobTemplates: async () => await fetchJobTemplates({
                                deletedJobs: values.jobs[translations.ONLINK_DELETED_JOBS],
                                isMounted: () => true,
                                setTemplates,
                                translations
                            })
                        });
                    }}
                >
                    <div className='workboard-add-jobs'>
                        <IconAdd iconAdd={ICON_ADD_STYLE}/>
                        <div>{translations.ONLINK_ADD_NEW_JOB}</div>
                    </div>
                </EditLink>
            </LoadingWrapper>
        </div>
    );
}

SelectJobsForm.propTypes = {
    loading: PropTypes.object,
    openJobDialog: PropTypes.func,
    setOperators: PropTypes.func,
    setTemplates: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    templates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapDispatchToProps(dispatch) {
    return {
        openJobDialog(props) {
            dispatch(openDialog(dialogTypes.JOB_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(SelectJobsForm);
