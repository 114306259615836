// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import ErrorView from 'Ui/components/dashboard/error-view';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {WORKBOARD} from 'Common/constants/routes';
import SettingsDialog from 'Ui/components/settings/settings-dialog';
import {getAvailableSettings} from 'Utils/settings-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {BASE_ROUTES} from 'Ui/constants/routes/base-route-config';

const ROUTES = [
    {
        ...BASE_ROUTES[WORKBOARD]
    }
];

function createLaborSettingsDialogRoute(settings) {
    const laborTile = settings?.find((tile) => tile.tileId === 'Labor');

    if (!laborTile) {
        return null;
    }

    const lowerCaseTileId = laborTile.tileId.toLowerCase();
    const basePath = `${WORKBOARD}/settings/${lowerCaseTileId}`;

    return (
        <Route
            key={laborTile.tileId}
            path={[`${basePath}/:tab`, basePath]}
        >
            <SettingsDialog
                basePath={WORKBOARD}
                tileId={`settings/${lowerCaseTileId}`}
                {...laborTile.dialogProps}
            />
        </Route>
    );
}

function Labor(props) {
    const {
        translations,
        appKeys,
        featureToggles,
        membership,
        myJdPermissions,
        permissions
    } = props;

    const [availableSettings, setAvailableSettings] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const settings = await getAvailableSettings(translations, membership, appKeys, permissions, myJdPermissions, featureToggles);

        if (isMounted()) {
            setAvailableSettings(settings);
        }
    }), [membership, appKeys]);

    return (
        <>
            {
                ROUTES.map(({
                    component,
                    path,
                    toggle,
                    myJdPermissions
                }) => {
                    const showComponent = isAuthorized({
                        toggle,
                        myJdPermissions
                    }, {
                        ...props,
                        isMigrated: membership.isMigrated
                    });
                    const Component = showComponent ? component : ErrorView;

                    return (
                        <Route
                            key={path}
                            path={path}
                        >
                            <Component translations={translations}/>
                        </Route>
                    );
                })
            }
            {
                <Switch>
                    {
                        createLaborSettingsDialogRoute(availableSettings, translations)
                    }
                </Switch>
            }
        </>
    );
}

Labor.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.object,
    myJdPermissions: PropTypes.myJdPermissions,
    permissions: PropTypes.legacyPermissions,
    superUser: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        superUser: state.account.superUser,
        membership: state.membership,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions
    };
}

export default connect(mapStateToProps)(Labor);
