// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import appKeys from 'Ui/components/common/app-keys';
import permissions from 'Common/constants/permissions';
import EditableUserSettingsInputs from 'Ui/components/settings/users/editable-user-settings-inputs';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import {asNumber} from 'Utils/conversion-utils';
import {translateErrorMessage} from 'Utils/error-utils';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {isEmptyArray, isNullOrUndefined} from 'Common/utils/validation-utils';
import {validateFile} from 'Utils/validation-utils';
import {getDepartments, getEquipmentAreas, getEquipmentTypes} from 'Services/membership-service';
import {createUser, updateUserById, uploadProfilePic} from 'Services/user-service';
import {updateEquipmentAreas, updateEquipmentTypes} from 'Store/actions/equipment';
import {hasPermissions} from 'Common/utils/permission-handler';
import {addToast as addToastAction} from 'Store/actions/toasts';
import parsePhoneNumberFromString, {getCountryCallingCode} from 'libphonenumber-js/min';
import {TOAST_TYPE} from '@deere/toast';

const APP_KEY_IDS = new Set(appKeys.map(({id}) => id));

function stripInvalidKeys(userAppKeys) {
    if (userAppKeys) {
        return userAppKeys.filter((key) => APP_KEY_IDS.has(key));
    }

    return [];
}

function valueOrEmptyString(value) {
    return isNullOrUndefined(value) ? '' : value;
}

async function fetchDepartments(setDepartmentItems, isMounted) {
    const {departments} = await getDepartments();

    if (isMounted()) {
        const departmentItems = departments.map((department) => ({
            id: department.departmentId,
            title: department.name
        }));

        setDepartmentItems(departmentItems);
    }
}

async function fetchEquipmentAreas(updateEquipmentAreas, isMounted, translations) {
    const {equipmentAreas} = await getEquipmentAreas(translations);

    if (isMounted()) {
        updateEquipmentAreas(equipmentAreas);
    }
}

async function fetchEquipmentTypes(updateEquipmentTypes, isMounted) {
    const {equipmentTypes} = await getEquipmentTypes();

    if (isMounted()) {
        updateEquipmentTypes(equipmentTypes);
    }
}

function getStandardizedPhoneNumber(phone, countryCallingCode) {
    if (phone) {
        const parsedPhone = parsePhoneNumberFromString(`+${countryCallingCode}${phone}`);

        return parsedPhone.nationalNumber;
    }

    return phone;
}

function createUserData(userValues, profilePicUrl, credentials) {
    const phone = getStandardizedPhoneNumber(userValues.phone, userValues.countryCallingCode);

    const userData = {
        appKeys: userValues.appKeys,
        checkAlarm: userValues.checkAlarm,
        color: userValues.color,
        countryCallingCode: phone ? userValues.countryCallingCode : '',
        departmentIds: userValues.departmentIds,
        email: userValues.email,
        equipmentTypes: userValues.equipmentTypes,
        firstName: userValues.firstName,
        inactive: userValues.inactive,
        lastName: userValues.lastName,
        password: userValues.password,
        phone,
        profilePicUrl,
        title: userValues.title,
        userRoleId: userValues.userRoleId
    };

    if (credentials.isMigrated || hasPermissions(credentials, permissions.VIEW_HOURLY_RATES)) {
        userData.compensation = {
            benefitsPercent: asNumber(userValues.benefitsPercent),
            hourlyRate: asNumber(userValues.hourlyRate),
            overtimeRate: asNumber(userValues.overtimeRate),
            taxPercent: asNumber(userValues.taxPercent)
        };

        if (credentials.isMigrated || hasPermissions(credentials, permissions.VIEW_SALARIES)) {
            userData.compensation = {
                ...userData.compensation,
                salaryMonthly: asNumber(userValues.salaryMonthly),
                salaryWeekly: asNumber(userValues.salaryWeekly)
            };
        }
    }

    return userData;
}

async function saveProfilePic(files, translations) {
    if (!files) {
        return null;
    }

    const [file] = files;

    if (file) {
        validateFile(
            file,
            window.props.imageUploadExtensions,
            translations.ONLINK_IMPORT_FILE_TO_LARGE_ERROR,
            translations.ONLINK_IMPORT_FILE_MUST_BE_IMAGE
        );

        const {filePath} = await uploadProfilePic(file);

        return filePath;
    }

    return undefined;
}

async function saveUser(existingUser, userData, translations) {
    try {
        if (!existingUser) {
            await createUser(userData);
        } else {
            await updateUserById(userData, existingUser.appUserId);
        }
    } catch (e) {
        const errorMessage = translateErrorMessage(e.response.data.message, translations);

        throw new Error(errorMessage);
    }
}

function initializeState(user, featureToggles, membershipCountry, equipmentAreas = [], equipmentTypes = [], userRoles = []) {
    const [countryCode, setCountryCode] = React.useState(membershipCountry);
    const [departmentItems, setDepartmentItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [userValues, setUserValues] = React.useState(() => {
        const userOrDefault = user || {};
        const {compensation = {}} = userOrDefault;

        return {
            appKeys: stripInvalidKeys(userOrDefault.appKeys),
            benefitsPercent: valueOrEmptyString(compensation.benefitsPercent),
            checkAlarm: userOrDefault.checkAlarm || false,
            confirmPassword: '',
            color: userOrDefault.color || 'none',
            countryCallingCode: userOrDefault.countryCallingCode || getCountryCallingCode(countryCode),
            departmentIds: userOrDefault.departmentIds || [],
            email: userOrDefault.email || '',
            equipmentTypes: userOrDefault.equipmentTypes || [],
            firstName: userOrDefault.firstName || '',
            textMessageSignup: userOrDefault.textMessageSignup,
            initialConsent: userOrDefault.textMessageSignup,
            hourlyRate: valueOrEmptyString(compensation.hourlyRate),
            inactive: userOrDefault.inactive || false,
            lastName: userOrDefault.lastName || '',
            overtimeRate: valueOrEmptyString(compensation.overtimeRate),
            password: '',
            phone: userOrDefault.phone || '',
            profilePicUrl: userOrDefault.profilePicUrl || '',
            salaryMonthly: valueOrEmptyString(compensation.salaryMonthly),
            salaryWeekly: valueOrEmptyString(compensation.salaryWeekly),
            taxPercent: valueOrEmptyString(compensation.taxPercent),
            title: userOrDefault.title || '',
            userRoleId: userOrDefault.userRoleId
        };
    });

    const equipmentAreaItems = useDeepMemo(() => equipmentAreas.map((equipmentArea) => ({
        id: equipmentArea.equipmentAreaId,
        title: equipmentArea.name
    })), [equipmentAreas]);
    const equipmentTypeItems = useDeepMemo(() => equipmentTypes.map((equipmentType) => ({
        id: equipmentType.equipmentTypeId,
        title: equipmentType.name
    })), [equipmentTypes]);
    const userRoleItems = useDeepMemo(() => userRoles.map((userRole) => ({
        id: userRole.userRoleId,
        title: userRole.title
    })), [userRoles]);

    return {
        countryCode,
        departmentItems,
        files,
        loading,
        setFiles,
        equipmentAreaItems,
        equipmentTypeItems,
        setCountryCode,
        setDepartmentItems,
        setLoading,
        setUserValues,
        userRoleItems,
        userValues
    };
}

function EditableUserSettings(props) {
    const {
        activeAppUserId,
        addToast,
        equipmentAreas,
        equipmentTypes,
        featureToggles,
        invalidInputs,
        membershipCountry,
        setIsEditing,
        setValid,
        superUser,
        translations,
        updateEquipmentAreas,
        updateEquipmentTypes,
        user,
        userPermissions,
        userRoles
    } = props;

    const {
        countryCode,
        departmentItems,
        files,
        loading,
        setFiles,
        equipmentAreaItems,
        equipmentTypeItems,
        setCountryCode,
        setDepartmentItems,
        setLoading,
        setUserValues,
        userRoleItems,
        userValues
    } = initializeState(user, featureToggles, membershipCountry, equipmentAreas, equipmentTypes, userRoles);

    const credentials = {
        permissionMap: userPermissions,
        superUser
    };

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const promises = [fetchDepartments(setDepartmentItems, isMounted)];

        if (isEmptyArray(equipmentAreas)) {
            promises.push(fetchEquipmentAreas(updateEquipmentAreas, isMounted, translations));
        }

        if (isEmptyArray(equipmentTypes)) {
            promises.push(fetchEquipmentTypes(updateEquipmentTypes, isMounted));
        }

        await Promise.all(promises);
    }), []);

    async function onSave() {
        setLoading(true);

        try {
            const profilePicUrl = await saveProfilePic(files, translations);
            const userData = createUserData(userValues, profilePicUrl, credentials);

            await saveUser(user, userData, translations);

            setIsEditing();
        } catch (e) {
            addToast({
                message: e.message,
                type: TOAST_TYPE.ERROR
            });
        }

        setLoading(false);
    }

    return (
        <>
            <EditableUserSettingsInputs
                activeAppUserId={activeAppUserId}
                countryCode={countryCode}
                credentials={credentials}
                departmentItems={departmentItems}
                equipmentAreaItems={equipmentAreaItems}
                equipmentTypeItems={equipmentTypeItems}
                setCountryCode={setCountryCode}
                setFiles={setFiles}
                setUserValues={setUserValues}
                setValid={setValid}
                translations={translations}
                user={user}
                userRoleItems={userRoleItems}
                userValues={userValues}
            />
            <SaveContentBar
                disabled={invalidInputs.size > 0 || loading}
                onCancelClick={setIsEditing}
                onSaveClick={onSave}
                translations={translations}
            />
        </>
    );
}

EditableUserSettings.propTypes = {
    activeAppUserId: PropTypes.string,
    addToast: PropTypes.func,
    equipmentAreas: PropTypes.arrayOf(PropTypes.object),
    equipmentTypes: PropTypes.arrayOf(PropTypes.object),
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipCountry: PropTypes.string,
    setIsEditing: PropTypes.func,
    setValid: PropTypes.func,
    superUser: PropTypes.bool,
    translations: PropTypes.translations,
    updateEquipmentAreas: PropTypes.func,
    updateEquipmentTypes: PropTypes.func,
    user: PropTypes.user,
    userPermissions: PropTypes.legacyPermissions,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        activeAppUserId: state.account.appUserId,
        equipmentAreas: state.equipment.equipmentAreas,
        equipmentTypes: state.equipment.equipmentTypes,
        featureToggles: state.account.featureToggles,
        membershipCountry: state.membership.country,
        superUser: state.account.superUser,
        translations: state.translations,
        userPermissions: state.account.permissions,
        userRoles: state.role.userRoles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastAction(value));
        },
        updateEquipmentAreas(value) {
            dispatch(updateEquipmentAreas(value));
        },
        updateEquipmentTypes(value) {
            dispatch(updateEquipmentTypes(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EditableUserSettings));
