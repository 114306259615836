// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import moment from 'moment';
import WorkItemDetailsDialogInputs from 'OnLabor/my-work/my-work-dialog/work-item-details-dialog-inputs';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {getJobWithLimitedWorkboardData} from 'Services/job-service';
import {getMowingPatternImage} from 'OnLabor/common/utils/mowing-pattern-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {MINUTES} from 'Utils/time-utils';
import ConfirmationDialog from 'Ui/components/dialogs/confirmation-dialog';
import {postWorkItemLog, putWorkItemForOperator} from 'Services/work-item-service';
import logStateConstants from 'OnEquip/equipment/service-form/utils/log-state-constants';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import DetailsBlock from 'OnLabor/my-work/my-work-tile/details-block';
import {TRUE} from 'Ui/constants/membership-properties';
import WorkItemEquipmentEngineHoursDialog from 'OnLabor/my-work/my-work-dialog/work-item-equipment-engine-hours-dialog';
import {sortBy} from 'lodash';
import {createPpeIconMap} from 'Utils/icon-utils';

const ICON_STYLE = {
    style: {
        height: '32px',
        width: '32px',
        marginRight: '5px'
    }
};

const PPE_ICON_MAP = createPpeIconMap(ICON_STYLE);

function initializeState(workItem) {
    const [totalTime, setTotalTime] = React.useState(() => moment.duration(0));
    const [imgSrc, setImgSrc] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [mowingPattern, setMowingPattern] = React.useState({});
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
    const [showEngineHoursDialog, setShowEngineHoursDialog] = React.useState(false);
    const [workBoardNote, setWorkBoardNote] = React.useState('');
    const [workItemInputs, setWorkItemInputs] = React.useState(() => ({
        preStartCheck: Boolean(workItem.preStartCheck),
        operatorNote: workItem.operatorNote ? workItem.operatorNote : ''
    }));

    return {
        totalTime,
        setTotalTime,
        imgSrc,
        setImgSrc,
        loading,
        setLoading,
        mowingPattern,
        setMowingPattern,
        showConfirmationDialog,
        setShowConfirmationDialog,
        workBoardNote,
        setWorkBoardNote,
        showEngineHoursDialog,
        setShowEngineHoursDialog,
        workItemInputs,
        setWorkItemInputs
    };
}

function buildMowingPatternInfo(mowingPattern, translations) {
    const cuttingStyle = translations[mowingPattern.cuttingStyle] || mowingPattern.cuttingStyle;
    const direction = translations[mowingPattern.direction] || mowingPattern.direction;
    const direction2 = translations[mowingPattern.direction2] || mowingPattern.direction2;
    const cleanupPass = translations[mowingPattern.cleanupPass] || mowingPattern.cleanupPass;

    let mowingPatternInfo = `${cuttingStyle} - ${direction}`;

    if (direction2) {
        mowingPatternInfo += ` (${direction2})`;
    }

    if (cleanupPass) {
        mowingPatternInfo += `, ${cleanupPass}`;
    }

    return mowingPatternInfo;
}

function buildWorkItemInfo(workItem, translations) {
    const route = translations[workItem.route] || workItem.route;

    return workItem.routeCustomDefn?.length > 0 ?
        `${route} - ${translations.ONLINK_HOLES} ${workItem.routeCustomDefn.join(', ')}` :
        route;
}

function WorkItemDetailsDialog(props) {
    const {
        closeDialog,
        translations,
        workItem,
        properties
    } = props;

    const {
        totalTime,
        setTotalTime,
        imgSrc,
        setImgSrc,
        loading,
        setLoading,
        mowingPattern,
        setMowingPattern,
        showConfirmationDialog,
        setShowConfirmationDialog,
        workBoardNote,
        setWorkBoardNote,
        showEngineHoursDialog,
        setShowEngineHoursDialog,
        workItemInputs,
        setWorkItemInputs
    } = initializeState(workItem);

    function setStateIfMounted(isMounted, imageComposite, workBoardMowingPattern, note) {
        if (isMounted()) {
            setImgSrc(imageComposite);
            setMowingPattern(workBoardMowingPattern);
            setWorkBoardNote(note);
            setLoading(false);
        }
    }

    async function updateWorkItem() {
        const hasPreStartCheckProperty = properties.mywork_show_pre_start_check === TRUE;
        const hasOperatorNoteProperty = properties.mywork_show_operator_note === TRUE;

        const workItemInputsData = {
            ...hasPreStartCheckProperty && {
                preStartCheck: workItemInputs.preStartCheck
            },
            ...hasOperatorNoteProperty && {
                operatorNote: workItemInputs.operatorNote
            },
            appUserId: workItem.appUserId
        };

        if (hasPreStartCheckProperty || hasOperatorNoteProperty) {
            await putWorkItemForOperator(workItem.workItemId, workItem.jobId, workItemInputsData);
        }
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const job = await getJobWithLimitedWorkboardData(workItem.jobId);

        const workBoardMowingPattern = job.mowingPatterns?.find((mowingPattern) => mowingPattern.area === job.jobTemplate?.jobCategory);

        let imageComposite = null;

        if (workBoardMowingPattern?.cuttingStyle) {
            imageComposite = await getMowingPatternImage(workBoardMowingPattern);
        }

        setStateIfMounted(isMounted, imageComposite, workBoardMowingPattern, job.workboardNote);
    }), [workItem]);

    const elapsedTimeElement = (
        <div className='work-item-details-elapsed-time medium-section-title'>
            {`${translations.ONLINK_ELAPSED_TIME}: ${moment.utc(totalTime.asMilliseconds()).format('HH:mm:ss')}`}
        </div>
    );

    const sortedEquipment = useDeepMemo(() => sortBy(workItem.equipment, 'equipmentName'), [workItem.equipment]);

    function showEngineHoursOrCloseDialog() {
        const filterOutMtgMachines = sortedEquipment.filter((equipment) => !equipment.lastReportedMtgId);

        if (filterOutMtgMachines.length) {
            setShowEngineHoursDialog(true);
        } else {
            closeDialog();
        }
    }

    return (
        <>
            <FormDialog
                cancelLabel={translations.CANCEL}
                className='work-item-details onlink-dialog'
                closeHandler={closeDialog}
                footerLoading={loading}
                onSave={async () => {
                    await updateWorkItem();
                    closeDialog();
                }}
                saveLabel={translations.save}
                title={workItem.job.title}
                translations={translations}
            >
                <div className='settings-body'>
                    <LoadingWrapper
                        className='table-loading-icon'
                        loading={loading}
                        size='50px'
                    >
                        {
                            workBoardNote &&
                            <div className='work-item-details-text-line-italic medium-section-title work-board-note'>
                                {workBoardNote}
                            </div>
                        }
                        {
                            (workItem.job?.jobTemplate?.secondaryName || workItem.route || mowingPattern?.cuttingStyle || workItem.equipment?.length > 0 || imgSrc) &&
                            <div className='mowing-pattern-section'>
                                <div className='mowing-pattern-text-section'>
                                    {
                                        workItem.job?.jobTemplate?.secondaryName &&
                                        <DetailsBlock>
                                            {`(${workItem.job.jobTemplate.secondaryName})`}
                                        </DetailsBlock>
                                    }
                                    {
                                        workItem.route && properties.mywork_show_route === TRUE &&
                                        <DetailsBlock label={translations.ONLINK_ROUTE}>
                                            <div className='work-item-details-text-line'>
                                                {buildWorkItemInfo(workItem, translations)}
                                            </div>
                                        </DetailsBlock>
                                    }
                                    {

                                        mowingPattern?.cuttingStyle &&
                                        <DetailsBlock label={translations.ONLINK_MOWING_PATTERN}>
                                            <div className='work-item-details-text-line'>
                                                {buildMowingPatternInfo(mowingPattern, translations)}
                                            </div>
                                        </DetailsBlock>
                                    }
                                    {
                                        workItem.equipment &&
                                        <DetailsBlock label={translations.IWP_EQUIPMENT_LABEL}>
                                            {
                                                sortedEquipment.map((equipmentItem) => {
                                                    const description = `${equipmentItem.manufacturerName} ${equipmentItem.modelName}`;

                                                    return (
                                                        <div
                                                            className='work-item-details-text-line'
                                                            key={equipmentItem.equipmentId}
                                                        >
                                                            {`${equipmentItem.equipmentName} (${description})`}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </DetailsBlock>
                                    }
                                    {
                                        workItem.note &&
                                        <DetailsBlock label={translations.NOTE}>
                                            <div className='work-item-details-text-line-italic'>
                                                {workItem.note}
                                            </div>
                                        </DetailsBlock>
                                    }
                                    {
                                        properties.mywork_show_protection_icons === TRUE &&
                                        <DetailsBlock>
                                            {
                                                workItem.job?.jobTemplate?.ppeList?.map((ppeName) => PPE_ICON_MAP[ppeName])
                                            }
                                        </DetailsBlock>
                                    }
                                </div>
                                {
                                    imgSrc &&
                                    <img
                                        className='mowing-pattern-image'
                                        src={imgSrc}
                                    />
                                }
                            </div>
                        }
                        <WorkItemDetailsDialogInputs
                            properties={properties}
                            setShowConfirmationDialog={() => setShowConfirmationDialog(true)}
                            setTotalTime={setTotalTime}
                            setWorkItemInputs={setWorkItemInputs}
                            showEngineHoursOrCloseDialog={showEngineHoursOrCloseDialog}
                            translations={translations}
                            updateWorkItem={updateWorkItem}
                            workItem={workItem}
                            workItemInputs={workItemInputs}
                        />
                        <div className='mowing-pattern-text-section'>
                            {
                                properties.mywork_show_estimated_hours === TRUE &&
                                <div className='work-item-details-estimated-time medium-section-title'>
                                    {`${translations.ONLINK_ESIMATED_TIME}: ${
                                        moment.utc(moment.duration(workItem.estDuration, MINUTES).asMilliseconds())
                                            .format('HH:mm:ss')}`
                                    }
                                </div>
                            }

                            {elapsedTimeElement}
                        </div>
                    </LoadingWrapper>
                </div>
            </FormDialog>
            {
                showConfirmationDialog &&
                <ConfirmationDialog
                    cancelTitle={translations.CANCEL}
                    continueTitle={translations.ONLINK_MARK_AS_COMPLETE}
                    message={elapsedTimeElement}
                    onCancel={() => {
                        setShowConfirmationDialog(false);
                    }}
                    onContinue={async () => {
                        await postWorkItemLog(workItem.workItemId, {
                            appUserId: workItem.appUserId,
                            logType: logStateConstants.stop
                        });
                        await updateWorkItem();
                        showEngineHoursOrCloseDialog();
                    }}
                    title={translations.confirm}
                />
            }
            {
                showEngineHoursDialog &&
                <WorkItemEquipmentEngineHoursDialog
                    onClose={closeDialog}
                    translations={translations}
                    workItemEquipment={sortedEquipment}
                />
            }
        </>
    );
}

WorkItemDetailsDialog.propTypes = {
    closeDialog: PropTypes.func,
    properties: PropTypes.object,
    translations: PropTypes.translations,
    workItem: PropTypes.object
};

export default WorkItemDetailsDialog;
