// Unpublished Work © 2023-2024 Deere & Company.

import LayoutSelect from 'Ui/features/home/dashboard/layout-select';
import {
    courseOperationsNavLinks,
    dashboardNavLinks,
    fleetMaintenanceLinks,
    fleetSetupNavLinks,
    onLaborNavLinks,
    fleetAnalyzeNavLinks,
    partsLinks
} from 'Ui/constants/secondary-navbar-constants';
import {
    ANALYZE_COURSE_OPERATIONS, ASSIGNMENTS, DRILL_IN, DRILL_IN_CHART, FLEET, FORETEES, HOC, HOME, INVENTORY,
    MY_WORK, PARTS_ORDERS, PARTS_RECOMMENDED, PLAYBOOKS, SHOP, SUGGESTED_MAINTENANCE, TWC, WORKBOARD, EQUIPMENT_REPORT,
    SERVICE_HISTORY, PARTS_HISTORY
} from 'Common/constants/routes';
import {createLink} from 'Utils/navigation-utils';
import appAccess from 'Common/constants/app-access';
import {VIEW_WORKBOARDS} from 'Common/constants/business-activities';

const homeLink = {
    ...createLink('/home', 'DASHBOARD', true),
    prioritizeList: dashboardNavLinks.map(({path}) => path)
};
const workboardLink = {
    ...createLink('/labor/workboard', 'ONLINK_WORK_AND_SCHEDULES', true),
    appAccess: appAccess.ONLABOR,
    myJdPermissions: VIEW_WORKBOARDS
};

const dashboardTertiaryNav = {
    headerText: 'home',
    tabs: [homeLink, ...dashboardNavLinks]
};

const partsTertiaryNav = {
    headerText: 'PARTS',
    tabs: partsLinks
};

const workboardTertiaryNav = {
    headerText: 'Labor',
    tabs: [workboardLink, onLaborNavLinks[2]] // my work is the second tab
};

const courseOperationsTertiaryNav = {
    headerText: 'ONLINK_COURSE_OPERATIONS',
    tabs: courseOperationsNavLinks
};

const migratedCourseOperationsTertiaryNav = {
    headerText: 'ONLINK_COURSE_OPERATIONS',
    tabs: [
        ...courseOperationsNavLinks,
        ...fleetAnalyzeNavLinks
    ]
};

const fleetSetupTertiaryNav = {
    headerText: 'ONLINK_FLEET',
    tabs: fleetSetupNavLinks
};

const fleetAnalyzeTertiaryNav = {
    headerText: 'ONLINK_ANALYZE_FLEET',
    tabs: fleetAnalyzeNavLinks
};

const TERTIARY_NAV_ITEMS_BY_ROUTE = {
    [FORETEES]: dashboardTertiaryNav,
    [PLAYBOOKS]: dashboardTertiaryNav,
    [TWC]: dashboardTertiaryNav,
    [DRILL_IN_CHART]: dashboardTertiaryNav,
    [DRILL_IN]: dashboardTertiaryNav,
    [HOME]: {
        ...dashboardTertiaryNav,
        childComponent: LayoutSelect
    },
    [MY_WORK]: workboardTertiaryNav,
    [WORKBOARD]: workboardTertiaryNav,
    [ANALYZE_COURSE_OPERATIONS]: courseOperationsTertiaryNav,
    [FLEET]: fleetSetupTertiaryNav,
    [HOC]: fleetSetupTertiaryNav,
    [EQUIPMENT_REPORT]: fleetAnalyzeTertiaryNav,
    [SERVICE_HISTORY]: fleetAnalyzeTertiaryNav
};

function getTertiaryNavItemsByRoute(path, isMigrated) {
    const fleetOperationsTertiaryNav = {
        headerText: 'ONLINK_FLEET_MAINTENANCE',
        tabs: fleetMaintenanceLinks
    };

    if (isMigrated) {
        return {
            ...TERTIARY_NAV_ITEMS_BY_ROUTE,
            [SUGGESTED_MAINTENANCE]: fleetOperationsTertiaryNav,
            [SHOP]: fleetOperationsTertiaryNav,
            [ASSIGNMENTS]: fleetOperationsTertiaryNav,
            [INVENTORY]: partsTertiaryNav,
            [PARTS_HISTORY]: partsTertiaryNav,
            [PARTS_RECOMMENDED]: partsTertiaryNav,
            [PARTS_ORDERS]: partsTertiaryNav,
            [EQUIPMENT_REPORT]: migratedCourseOperationsTertiaryNav,
            [SERVICE_HISTORY]: migratedCourseOperationsTertiaryNav,
            [ANALYZE_COURSE_OPERATIONS]: migratedCourseOperationsTertiaryNav
        }[path];
    }

    fleetOperationsTertiaryNav.tabs = [
        ...fleetMaintenanceLinks,
        ...partsLinks
    ];

    TERTIARY_NAV_ITEMS_BY_ROUTE[SUGGESTED_MAINTENANCE] = fleetOperationsTertiaryNav;
    TERTIARY_NAV_ITEMS_BY_ROUTE[SHOP] = fleetOperationsTertiaryNav;
    TERTIARY_NAV_ITEMS_BY_ROUTE[ASSIGNMENTS] = fleetOperationsTertiaryNav;
    TERTIARY_NAV_ITEMS_BY_ROUTE[INVENTORY] = fleetOperationsTertiaryNav;
    TERTIARY_NAV_ITEMS_BY_ROUTE[PARTS_RECOMMENDED] = fleetOperationsTertiaryNav;
    TERTIARY_NAV_ITEMS_BY_ROUTE[PARTS_ORDERS] = fleetOperationsTertiaryNav;

    return TERTIARY_NAV_ITEMS_BY_ROUTE[path];
}

export {
    getTertiaryNavItemsByRoute
};
