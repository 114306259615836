// Unpublished Work © 2022-2024 Deere & Company.

export const PPE_ICONS = {
    CHAPS: 'chaps',
    EYE_PROTECTION: 'eyeProtection',
    GLOVES: 'gloves',
    HARD_HAT: 'hardHat',
    HEARING_PROTECTION: 'hearingProtection',
    PROTECTIVE_FOOTWEAR: 'protectiveFootwear',
    RESPIRATOR: 'respirator',
    SAFETY_SUIT: 'safetySuit',
    VEST: 'vest',
    SUN_HAT: 'sunHat',
    SUNSCREEN: 'sunscreen'
};

export const HOLE_ITEMS = [
    {
        id: '1',
        title: '1'
    },
    {
        id: '2',
        title: '2'
    },
    {
        id: '3',
        title: '3'
    },
    {
        id: '4',
        title: '4'
    },
    {
        id: '5',
        title: '5'
    },
    {
        id: '6',
        title: '6'
    },
    {
        id: '7',
        title: '7'
    },
    {
        id: '8',
        title: '8'
    },
    {
        id: '9',
        title: '9'
    },
    {
        id: '10',
        title: '10'
    },
    {
        id: '11',
        title: '11'
    },
    {
        id: '12',
        title: '12'
    },
    {
        id: '13',
        title: '13'
    },
    {
        id: '14',
        title: '14'
    },
    {
        id: '15',
        title: '15'
    },
    {
        id: '16',
        title: '16'
    },
    {
        id: '17',
        title: '17'
    },
    {
        id: '18',
        title: '18'
    }
];

export const AREAS = ['Approaches', 'Collars', 'Fairways', 'Greens', 'Intermediate', 'Rough', 'Surrounds', 'Tees'];
