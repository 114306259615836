// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import GenericDataPanel from 'Ui/components/panel/generic-data-panel';

function Tile(props) {
    const {
        columnCount,
        component: CustomComponent,
        featureToggles,
        fetchDate,
        onClick,
        thresholds,
        tileData,
        translations
    } = props;

    if (CustomComponent) {
        return (
            <CustomComponent
                fetchDate={fetchDate}
                onClick={onClick}
                thresholds={thresholds}
                tile={tileData}
                translations={translations}
            />
        );
    }

    return (
        <GenericDataPanel
            columnCount={columnCount}
            featureToggles={featureToggles}
            onClick={onClick}
            panel={tileData}
            thresholds={thresholds}
            translations={translations}
        />
    );
}

Tile.propTypes = {
    columnCount: PropTypes.number,
    component: PropTypes.elementType,
    featureToggles: PropTypes.featureToggles,
    fetchDate: PropTypes.string,
    onClick: PropTypes.func,
    thresholds: PropTypes.thresholds,
    tileData: PropTypes.tileData,
    translations: PropTypes.translations
};

export default Tile;
