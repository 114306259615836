// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import Account from 'Ui/components/main-navbar/persistent-header/primary-navbar/account';
import Membership from 'Ui/components/main-navbar/persistent-header/shared-navbar/membership';
import jdImage from 'Ui/images/jd-ag.svg';
import onlinkImage from 'Ui/images/onlink.png';
import 'Sass/persistent-header/ph-primary-navbar.scss';

function PrimaryNavBar(props) {
    const {
        isMobile,
        links,
        translations,
        user
    } = props;

    return (
        <div className='main-navbar ph-navbar'>
            <Stack direction='row'>
                <img
                    alt='John Deere Logo'
                    className='jd-image'
                    role='img'
                    src={jdImage}
                />
                <div className='vertical-line'/>
                <img
                    alt='OnLink Logo'
                    className='onlink-image'
                    role='img'
                    src={onlinkImage}
                />
            </Stack>
            {
                !isMobile &&
                <Stack direction='row'>
                    <Membership/>
                    <div className='vertical-line'/>
                    <Account
                        links={links}
                        translations={translations}
                        user={user}
                    />
                </Stack>
            }
        </div>
    );
}

PrimaryNavBar.propTypes = {
    isMobile: PropTypes.bool,
    links: PropTypes.object,
    translations: PropTypes.translations,
    user: PropTypes.object
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(PrimaryNavBar);
