// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function GroupValidation(props) {
    const {
        children,
        error,
        invalidCustomError,
        name,
        setValid
    } = props;

    React.useEffect(() => {
        setValid(name, !invalidCustomError);

        return () => setValid(name, true);
    }, [name, invalidCustomError]);

    return (
        <>
            <div className={invalidCustomError ? 'required-group' : ''}>
                {
                    children
                }
            </div>
            {
                invalidCustomError &&
                <div className='required-group-error-text'>
                    {
                        error
                    }
                </div>
            }
        </>
    );
}

GroupValidation.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    error: PropTypes.string,
    invalidCustomError: PropTypes.bool,
    name: PropTypes.string,
    setValid: PropTypes.func
};

export default GroupValidation;
