// Unpublished Work © 2020-2024 Deere & Company.

export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';

export function setTranslations(translations) {
    return {
        type: SET_TRANSLATIONS,
        translations
    };
}
