// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createDepartment(departmentData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/departments', departmentData);

    return response.data;
}

async function deleteDepartmentById(departmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/departments/${departmentId}`);

    return response.data;
}

async function updateDepartmentById(departmentData, departmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/departments/${departmentId}`, departmentData);

    return response.data;
}

export {
    createDepartment,
    deleteDepartmentById,
    updateDepartmentById
};
