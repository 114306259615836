// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import store from 'Store/store';
import {
    getEquipmentAreas,
    getEquipmentTypes,
    getUserRoles
} from 'Services/membership-service';
import {
    setMyJdPermissions,
    setFeatureToggles,
    updateUser,
    setPermissions
} from 'Store/actions/account';
import {
    refreshMyJdPermissions,
    updateUserSessionForOrg,
    updateSessionMembership
} from 'Services/session-service';
import {updateHashedUserValues} from 'Store/actions/analytics';
import {updateEquipmentAreas, updateEquipmentTypes} from 'Store/actions/equipment';
import {updateMembership} from 'Store/actions/membership';
import {updateUserRoles} from 'Store/actions/role';
import {redirect} from 'Utils/redirect-utils';
import {getSwitchUserActions} from 'Src/ui-core/utils/membership/switch-user';

async function getMembershipActions(membership) {
    const updateMembershipAction = updateMembership(membership);
    const updateHashedUserValuesAction = updateHashedUserValues({
        hashedCurrentMembershipId: membership.hashedMembershipId
    });

    return [
        updateMembershipAction,
        updateHashedUserValuesAction
    ];
}

async function getMyJdPermissionsAction(hasMyJDSession, orgId) {
    let myJdPermissions = {};

    if (hasMyJDSession && orgId) {
        myJdPermissions = await refreshMyJdPermissions();
    }

    return setMyJdPermissions(myJdPermissions);
}

async function getEquipmentAreasAction(translations) {
    const {equipmentAreas} = await getEquipmentAreas(translations);

    return updateEquipmentAreas(equipmentAreas);
}

async function getEquipmentTypesAction() {
    const {equipmentTypes} = await getEquipmentTypes();

    return updateEquipmentTypes(equipmentTypes);
}

async function getUserRolesAction(isMigrated) {
    const {userRoles = [] } = isMigrated ? {} : await getUserRoles();

    return updateUserRoles(userRoles);
}

function dispatchActions(actions) {
    store.dispatch((dispatch) => {
        actions.flat(Infinity).forEach((action) => dispatch(action));
    });
}

async function switchMembership(membershipId, currentMembershipId, hasMyJDSession, translations) {
    if (membershipId === currentMembershipId) {
        return;
    }

    const {
        membership,
        hashedCurrentMembershipId
    } = await updateSessionMembership(membershipId);

    membership.hashedMembershipId = hashedCurrentMembershipId;

    const allActions = await Promise.all([
        getSwitchUserActions(),
        getMembershipActions(membership),
        getMyJdPermissionsAction(hasMyJDSession, membership.orgId),
        getEquipmentAreasAction(translations),
        getEquipmentTypesAction(),
        getUserRolesAction(membership.isMigrated)
    ]);

    dispatchActions(allActions);
}

async function switchOrg(orgId, translations) {
    const {
        membership,
        hashedCurrentMembershipId,
        myJdPermissions,
        appUser,
        hashedAppUserId,
        hashedUserMembershipId,
        featureToggles,
        permissionMap,
        appKeyMap
    } = await updateUserSessionForOrg(orgId);

    membership.hashedMembershipId = hashedCurrentMembershipId;

    const allActions = await Promise.all([
        getMembershipActions(membership),
        getEquipmentAreasAction(translations),
        getEquipmentTypesAction(),
        setMyJdPermissions(myJdPermissions),
        updateHashedUserValues({
            hashedMembershipId: hashedUserMembershipId,
            hashedAppUserId
        }),
        updateUser({
            ...appUser,
            appKeys: appKeyMap
        }),
        setFeatureToggles(featureToggles),
        setPermissions(permissionMap)
    ]);

    dispatchActions(allActions);
}

function reauthenticateMembership(membershipId) {
    redirect(`/api/myjd-create-session?membershipId=${membershipId}`);
}

export {
    switchMembership,
    reauthenticateMembership,
    switchOrg
};
