// Unpublished Work © 2023-2024 Deere & Company.

import {capitalizeFirstLetter} from 'Utils/translation-utils';
import {NONE, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import * as UNIT_NAMES from 'Common/constants/data-unit-constants';

export function getUOMOverrideUnits(translations) {
    return {
        [UNIT_NAMES.BASKET]: {
            id: UNIT_NAMES.BASKET,
            title: translations.ONLINK_BASKETS
        },
        [UNIT_NAMES.KILOGRAM]: {
            id: UNIT_NAMES.KILOGRAM,
            title: translations.KILOGRAMS,
            unitOfMeasure: UNIT_OF_MEASURE.METRIC
        },
        [UNIT_NAMES.POUNDS]: {
            id: UNIT_NAMES.POUNDS,
            title: translations.POUNDS,
            unitOfMeasure: UNIT_OF_MEASURE.ENGLISH
        },
        [UNIT_NAMES.LITERS]: {
            id: UNIT_NAMES.LITERS,
            title: translations.STANDARD_UOM_LITER,
            unitOfMeasure: UNIT_OF_MEASURE.METRIC
        },
        [UNIT_NAMES.QUARTS]: {
            id: UNIT_NAMES.QUARTS,
            title: translations.QUARTS,
            unitOfMeasure: UNIT_OF_MEASURE.ENGLISH
        },
        [UNIT_NAMES.F355_EQUIVALENTS]: {
            id: UNIT_NAMES.F355_EQUIVALENTS,
            title: 'F355'
        },
        [UNIT_NAMES.GRAVITIES]: {
            id: UNIT_NAMES.GRAVITIES,
            title: `${translations.ONLINK_GRAVITIES} (Clegg)`
        },
        [UNIT_NAMES.INCHES]: {
            id: UNIT_NAMES.INCHES,
            title: capitalizeFirstLetter(translations.INCHES),
            unitOfMeasure: UNIT_OF_MEASURE.ENGLISH
        },
        [UNIT_NAMES.MM]: {
            id: UNIT_NAMES.MM,
            title: translations.ONLINK_MILLIMETERS,
            unitOfMeasure: UNIT_OF_MEASURE.METRIC
        },
        [UNIT_NAMES.FEET_AND_INCHES]: {
            id: UNIT_NAMES.FEET_AND_INCHES,
            title: translations.ONLINK_FEET_AND_INCHES,
            unitOfMeasure: UNIT_OF_MEASURE.ENGLISH
        },
        [UNIT_NAMES.METERS]: {
            id: UNIT_NAMES.METERS,
            title: translations.meters,
            unitOfMeasure: UNIT_OF_MEASURE.METRIC
        },
        [UNIT_NAMES.MILES_PER_HOUR]: {
            id: UNIT_NAMES.MILES_PER_HOUR,
            title: translations.MILES_PER_HOUR,
            unitOfMeasure: UNIT_OF_MEASURE.ENGLISH
        },
        [UNIT_NAMES.KILOMETERS_PER_HOUR]: {
            id: UNIT_NAMES.KILOMETERS_PER_HOUR,
            title: translations.KILOMETERS_PER_HOUR,
            unitOfMeasure: UNIT_OF_MEASURE.METRIC
        },
        [NONE]: {
            id: NONE,
            title: capitalizeFirstLetter(translations.NONE),
            unitOfMeasure: UNIT_OF_MEASURE.METRIC
        }
    };
}
