// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {connect} from 'react-redux';
import dialogTypes from 'Ui/components/common/dialog-types';
import {closeDialog} from 'Store/actions/dialogs';
import PropTypes from 'Utils/prop-type-utils';
import {useSave} from 'Ui/react-hooks/use-save';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import React from 'react';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {addModelMaintenance, getModelMaintenance, updateModelMaintenance} from 'Services/model-maintenance-service';
import PartSelector from 'Ui/components/common/parts-selectors/part-selector';
import MultiSelectWithChecklist from 'Ui/components/common/form/multi-select-with-checklist';
import {fetchEffectData} from 'Utils/react-utils';
import {getServiceTasks, getServiceTypes} from 'Services/maintenance-service';
import {getModels, getFleetEquipmentById} from 'Services/fleet-service';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import SelectedPartQuantity from 'Ui/components/common/parts-selectors/selected-part-quantity';
import {getPartBins} from 'Services/inventory-service';
import ValidationInput from 'Ui/components/common/form/validation-input';
import GroupValidation from 'Ui/components/common/form/group-validation';
import {
    formatModels,
    formatParts,
    filterAndFormatServiceTaskTypes,
    formatServiceTypes
} from 'Utils/multiselect/service-multiselect-mappers';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {TextField} from '@deere/isg.component-library/lib/TextField';
import {MAX_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {getInventories} from 'Services/membership-service';
import SwitchInput from 'Ui/components/common/form/switch-input';

const MIN_INPUT = 1;

function switchShowModelsToggle(setShowOnlyMyModels, showOnlyMyModels, setValues, formData, selectedModelId) {
    const nextToggleValue = !showOnlyMyModels;

    if (nextToggleValue) {
        const selectedModelFound = formData.filteredModels.some((model) => model.modelId === selectedModelId);

        if (!selectedModelFound) {
            setValues((prevValues) => ({
                ...prevValues,
                modelId: []
            }));
        }
    }

    setShowOnlyMyModels(nextToggleValue);
}

function initializeState() {
    const [values, setValues] = React.useState({
        everyHours: '',
        everyMonths: '',
        initialHours: '',
        initialMonths: '',
        hourThreshold: '',
        dayThreshold: '',
        estDuration: '',
        modelId: [],
        serviceTypeId: [],
        serviceTasksCheckedIds: [],
        serviceTasksSelectedIds: []
    });
    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({
        filteredModels: [],
        fullListModels: [],
        parts: [],
        serviceTasks: [],
        serviceTypes: []
    });
    const [selectedParts, setSelectedParts] = React.useState([]);
    const [inventories, setInventories] = React.useState([]);
    const [showOnlyMyModels, setShowOnlyMyModels] = React.useState(true);

    return {
        values,
        setValues,
        loading,
        setLoading,
        formData,
        setFormData,
        selectedParts,
        setSelectedParts,
        inventories,
        setInventories,
        showOnlyMyModels,
        setShowOnlyMyModels
    };
}

function AddEditMaintenanceDialog(props) {
    const {
        closeDialog,
        invalidInputs,
        initialModelMaintenanceId,
        featureToggles,
        onClose,
        membership,
        setValid,
        translations
    } = props;

    const {
        values,
        setValues,
        loading,
        setLoading,
        formData,
        setFormData,
        selectedParts,
        setSelectedParts,
        inventories,
        setInventories,
        showOnlyMyModels,
        setShowOnlyMyModels
    } = initializeState();

    async function fetchFormData(isMounted) {
        setLoading(true);
        const {inventories} = await getInventories();
        const [{serviceTaskTypes}, {serviceTypes}, {models}, {equipment}, {modelMaintenance}, {partBins}] = await Promise.all([
            getServiceTasks(),
            getServiceTypes(),
            getModels(),
            getFleetEquipmentById(membership.fleetId),
            initialModelMaintenanceId ? getModelMaintenance(initialModelMaintenanceId) : {
                modelMaintenance: {}
            },
            getPartBins(inventories[0]?.inventoryId)
        ]);

        const previousServiceTaskSelectedIds = modelMaintenance.serviceTasks || [];
        const previousServiceTaskCheckedIds = previousServiceTaskSelectedIds.filter((serviceTask) => serviceTask.isUsed)
            .map((serviceTask) => serviceTask.serviceTaskTypeId);
        const previousParts = formatParts(modelMaintenance.partsList || []);

        if (isMounted()) {
            setValues((prevValues) => ({
                ...prevValues,
                modelId: [modelMaintenance.modelId],
                serviceTypeId: [modelMaintenance.serviceTypeId],
                serviceTasksSelectedIds: previousServiceTaskSelectedIds.map((serviceTask) => serviceTask.serviceTaskTypeId),
                serviceTasksCheckedIds: previousServiceTaskCheckedIds,
                everyHours: modelMaintenance.everyHours || '',
                everyMonths: modelMaintenance.everyMonths || '',
                initialHours: modelMaintenance.initialHours || '',
                initialMonths: modelMaintenance.initialMonths || '',
                estDuration: modelMaintenance.estDuration || '',
                hourThreshold: modelMaintenance.hourThreshold || '',
                dayThreshold: modelMaintenance.dayThreshold || ''
            }));
            setSelectedParts(previousParts);

            const {
                filteredModels,
                fullListModels
            } = formatModels(models, equipment, false, modelMaintenance?.modelId);

            setFormData({
                filteredModels,
                fullListModels,
                parts: formatParts(partBins, true),
                serviceTasks: filterAndFormatServiceTaskTypes(
                    serviceTaskTypes, previousServiceTaskSelectedIds),
                serviceTypes: formatServiceTypes(serviceTypes, modelMaintenance?.serviceTypeId)
            });

            setInventories(inventories);
        }

        setLoading(false);
    }

    React.useEffect(() => fetchEffectData(fetchFormData), [membership]);

    function onChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const saveServiceTasksData = values.serviceTasksSelectedIds.map((serviceTaskId) => {
            const fullService = formData.serviceTasks.find(({serviceTaskTypeId}) => serviceTaskTypeId === serviceTaskId);
            const isChecked = values.serviceTasksCheckedIds.some((serviceTasksCheckedId) => serviceTasksCheckedId === serviceTaskId);

            return {
                ...fullService,
                isUsed: isChecked
            };
        });

        const payload = {
            dayThreshold: parseInt(values.dayThreshold, 10) || null,
            estDuration: parseInt(values.estDuration, 10) || null,
            everyHours: parseInt(values.everyHours, 10) || null,
            everyMonths: parseInt(values.everyMonths, 10) || null,
            fleetId: membership.fleetId,
            hourThreshold: parseInt(values.hourThreshold, 10) || null,
            initialHours: parseInt(values.initialHours, 10) || null,
            initialMonths: parseInt(values.initialMonths, 10) || null,
            modelId: values.modelId[0],
            partsList: selectedParts.map((part) => ({
                ...part,
                quantity: parseInt(part.quantity, 10)
            })),
            serviceTasks: saveServiceTasksData,
            serviceTypeId: values.serviceTypeId[0]
        };

        initialModelMaintenanceId ?
            await updateModelMaintenance(initialModelMaintenanceId, payload) :
            await addModelMaintenance(payload);

        if (onClose) {
            onClose();
        }
        closeDialog();
    }, {
        invalidInputs
    });

    const {
        emptyIntervalError,
        initialAndEveryValuesError,
        numberInputErrors
    } = React.useMemo(() => {
        const emptyIntervalError = !(values.initialHours || values.initialMonths || values.everyHours || values.everyMonths);
        const initialAndEveryValuesError = Boolean((values.initialHours || values.initialMonths) && (values.everyHours || values.everyMonths));

        const numberInputErrors = {
            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`,
            rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
                '0': MIN_INPUT
            })
        };

        return {
            emptyIntervalError,
            initialAndEveryValuesError,
            numberInputErrors
        };
    }, [values.initialHours, values.initialMonths, values.everyHours, values.everyMonths]);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='add-edit-maintenance-dialog'
            closeHandler={closeDialog}
            disableSave={disableSave}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={initialModelMaintenanceId ? translations.ONLINK_EDIT_MAINTENANCE : translations.ONLINK_ADD_MAINTENANCE}
            translations={translations}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading || isSaving}
                size='50px'
            >
                <form className='add-edit-maintenance-form'>
                    {
                        !initialModelMaintenanceId &&
                        <SwitchInput
                            checked={showOnlyMyModels}
                            name='showOnlyMyParts'
                            offLabel={`${capitalizeFirstLetter(translations.ONLINK_ONLY_MY_MODELS)}`}
                            onChange={() => switchShowModelsToggle(setShowOnlyMyModels, showOnlyMyModels, setValues, formData, values.modelId[0])}
                            translations={translations}
                        />
                    }
                    <ValidationMultiSelect
                        autoFocus={true}
                        component={MultiSelect}
                        disabled={Boolean(initialModelMaintenanceId)}
                        items={showOnlyMyModels && !initialModelMaintenanceId ? formData.filteredModels : formData.fullListModels}
                        label={translations.MODEL}
                        labels={{
                            placeholder: translations.ONLINK_SELECT_MODEL
                        }}
                        name='modelId'
                        onChange={(modelIds) => setValues((prevValues) => ({
                            ...prevValues,
                            modelId: modelIds
                        }))}
                        required={true}
                        selectedIds={values.modelId}
                        setValid={setValid}
                        single={true}
                    />
                    {
                        !initialModelMaintenanceId &&
                        <div className='model-select-subtext'>
                            {translations.ONLINK_SELECT_MODEL_SUBTEXT}
                        </div>
                    }
                    <ValidationMultiSelect
                        autoFocus={true}
                        component={MultiSelect}
                        disabled={Boolean(initialModelMaintenanceId)}
                        enableVirtualization
                        items={formData.serviceTypes}
                        label={translations.ONLINK_SERVICE_TYPE}
                        labels={{
                            placeholder: translations.ONLINK_SELECT_SERVICE_TYPE
                        }}
                        name='serviceTypeId'
                        onChange={(serviceTypeIds) => setValues((prevValues) => ({
                            ...prevValues,
                            serviceTypeId: serviceTypeIds
                        }))}
                        required={true}
                        selectedIds={values.serviceTypeId}
                        setValid={setValid}
                        single={true}
                    />
                    <MultiSelectWithChecklist
                        allMultiSelectOptions={formData.serviceTasks}
                        checkListName='serviceTasksCheckedIds'
                        checkListSelectedIds={values.serviceTasksCheckedIds}
                        defaultSelectLabel={translations.ONLINK_ADD_SERVICE_TASK}
                        label={translations.ONLINK_SERVICE_TASKS}
                        multiSelectName='serviceTasksSelectedIds'
                        multiSelectSelectedIds={values.serviceTasksSelectedIds}
                        onChange={(name, value) => setValues((prevValues) => ({
                            ...prevValues,
                            [name]: value
                        }))}
                        onDelete={(serviceTask) => {
                            setValues((prevValues) => ({
                                ...prevValues,
                                serviceTasksSelectedIds: prevValues.serviceTasksSelectedIds.filter((id) => id !== serviceTask.id),
                                serviceTasksCheckedIds: prevValues.serviceTasksCheckedIds.filter((id) => id !== serviceTask.id)
                            }));

                            if (serviceTask.inactive) {
                                setFormData((prevFormValues) => ({
                                    ...prevFormValues,
                                    serviceTasks: prevFormValues.serviceTasks.filter(({serviceTaskTypeId}) => serviceTaskTypeId !== serviceTask.id)
                                }));
                            }
                        }}
                        showDelete={() => true}
                    />
                    <GroupValidation
                        error={
                            emptyIntervalError ? translations.ONLINK_MISSING_INTERVAL_ERROR : ''
                        }
                        invalidCustomError={emptyIntervalError}
                        name='interval-inputs'
                        setValid={setValid}
                    >
                        <div className='settings-group mobile'>
                            <ValidationInput
                                component={TextField}
                                errors={{
                                    ...numberInputErrors,
                                    customError: translations.ONLINK_INVALID_INTERVAL_ERROR
                                }}
                                inputProps={{
                                    min: MIN_INPUT,
                                    max: MAX_NUMERIC_VALUE
                                }}
                                invalidCustomError={Boolean(values.everyHours) && initialAndEveryValuesError}
                                label={translations.ONLINK_EVERY_HOURS}
                                name='everyHours'
                                onChange={(e) => onChange('everyHours', e.target.value)}
                                setValid={setValid}
                                type='number'
                                value={values.everyHours}
                            />
                            <ValidationInput
                                component={TextField}
                                errors={{
                                    ...numberInputErrors,
                                    customError: translations.ONLINK_INVALID_INTERVAL_ERROR
                                }}
                                inputProps={{
                                    min: MIN_INPUT,
                                    max: MAX_NUMERIC_VALUE
                                }}
                                invalidCustomError={Boolean(values.everyMonths) && initialAndEveryValuesError}
                                label={translations.ONLINK_EVERY_MONTHS}
                                name='everyMonths'
                                onChange={(e) => onChange('everyMonths', e.target.value)}
                                setValid={setValid}
                                type='number'
                                value={values.everyMonths}
                            />
                        </div>
                        <div className='settings-group mobile'>
                            <ValidationInput
                                component={TextField}
                                errors={{
                                    ...numberInputErrors,
                                    customError: translations.ONLINK_INVALID_INTERVAL_ERROR
                                }}
                                inputProps={{
                                    min: MIN_INPUT,
                                    max: MAX_NUMERIC_VALUE
                                }}
                                invalidCustomError={Boolean(values.initialHours) && initialAndEveryValuesError}
                                label={translations.ONLINK_FIRST_HOURS}
                                name='initialHours'
                                onChange={(e) => onChange('initialHours', e.target.value)}
                                setValid={setValid}
                                type='number'
                                value={values.initialHours}
                            />
                            <ValidationInput
                                component={TextField}
                                errors={{
                                    ...numberInputErrors,
                                    customError: translations.ONLINK_INVALID_INTERVAL_ERROR
                                }}
                                inputProps={{
                                    min: MIN_INPUT,
                                    max: MAX_NUMERIC_VALUE
                                }}
                                invalidCustomError={Boolean(values.initialMonths) && initialAndEveryValuesError}
                                label={translations.ONLINK_FIRST_MONTHS}
                                name='initialMonths'
                                onChange={(e) => onChange('initialMonths', e.target.value)}
                                setValid={setValid}
                                type='number'
                                value={values.initialMonths}
                            />
                        </div>
                    </GroupValidation>
                    <ValidationInput
                        component={TextField}
                        errors={numberInputErrors}
                        fullWidth={true}
                        inputProps={{
                            min: MIN_INPUT,
                            max: MAX_NUMERIC_VALUE
                        }}
                        label={translations.ONLINK_ESTIMATED_DURATION_MINUTES}
                        name='estDuration'
                        onChange={(e) => onChange('estDuration', e.target.value)}
                        setValid={setValid}
                        type='number'
                        value={values.estDuration}
                    />
                    <div className='settings-group mobile'>
                        <ValidationInput
                            component={TextField}
                            errors={numberInputErrors}
                            inputProps={{
                                min: MIN_INPUT,
                                max: MAX_NUMERIC_VALUE
                            }}
                            label={translations.ONLINK_HOUR_THRESHOLD}
                            name='hourThreshold'
                            onChange={(e) => onChange('hourThreshold', e.target.value)}
                            setValid={setValid}
                            type='number'
                            value={values.hourThreshold}
                        />
                        <ValidationInput
                            component={TextField}
                            errors={numberInputErrors}
                            inputProps={{
                                min: MIN_INPUT,
                                max: MAX_NUMERIC_VALUE
                            }}
                            label={translations.ONLINK_DAY_THRESHOLD}
                            name='dayThreshold'
                            onChange={(e) => onChange('dayThreshold', e.target.value)}
                            setValid={setValid}
                            type='number'
                            value={values.dayThreshold}
                        />
                    </div>
                    <PartSelector
                        additionalSubTitleSelector='name'
                        availableParts={formData.parts}
                        currencyPreference={membership.currencyPreference}
                        defaultSelectLabel={translations.ONLINK_ADD_PART}
                        featureToggles={featureToggles}
                        inventories={inventories}
                        label={translations.PARTS}
                        partsInputComponent={SelectedPartQuantity}
                        selectedParts={selectedParts}
                        setSelectedParts={setSelectedParts}
                        setValid={setValid}
                        showAddNewPartOption={true}
                        subTitleSelector='manufacturerName'
                        titleSelector='partNumber'
                        translations={translations}
                    />
                </form>
            </LoadingWrapper>
        </FormDialog>
    );
}

AddEditMaintenanceDialog.propTypes = {
    closeDialog: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    initialModelMaintenanceId: PropTypes.string,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    onClose: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeDialog(dialogTypes.ADD_EDIT_MAINTENANCE_DIALOG));
        }
    };
}

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(AddEditMaintenanceDialog));
