// Unpublished Work © 2023-2024 Deere & Company.

const MAP_FLEET_EQUIPMENT_STUB = {
    engineHours: 100,
    equipmentName: 'Stub',
    equipmentType: 'Mower',
    modelName: '200X',
    reportTime: '',
    serialNumber: '',
    icon: {
        name: 'icon-7700a',
        iconStyle: {
            primaryColor: '#367c2b',
            secondaryColor: '#ffde00',
            stripeColor: '#ffde00'
        }
    },
    lat: 41.6,
    lon: -93.8,
    myJDId: 123456
};

export {
    MAP_FLEET_EQUIPMENT_STUB
};
