// Unpublished Work © 2020-2024 Deere & Company.

import tileProperties from 'Common/constants/tile-properties';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {cloneDeep, sortBy} from 'lodash';
import {utils} from 'react-grid-layout';
import tileInfo from 'Ui/components/common/tile-constants/tile-info';

function fixPositions(positions, columnCount) {
    const positionMap = positions.reduce((positionsById, position) => {
        positionsById.set(position.i, position);

        return positionsById;
    }, new Map());

    const newPositions = cloneDeep(positions);

    utils.correctBounds(newPositions, {
        cols: columnCount
    });

    return utils.compact(newPositions, 'vertical', columnCount).map((compactPosition) => ({
        ...positionMap.get(compactPosition.i),
        ...compactPosition
    }));
}

function getTileArray(layout) {
    return Object.keys(layout).reduce((tilePositions, columnCount) => {
        const tilePositionsByColumn = layout[columnCount];
        const columnCountNum = Number(columnCount);
        const mappedTilePositions = tilePositionsByColumn.map((tilePosition) => ({
            tileId: tilePosition.tileId,
            columnCount: columnCountNum,
            position: {
                i: tilePosition.i,
                x: tilePosition.x,
                y: tilePosition.y,
                w: tilePosition.w,
                h: tilePosition.h
            }
        }));

        return tilePositions.concat(mappedTilePositions);
    }, []);
}

function getColumnHeights(tilePositions) {
    const initialColumnHeights = {
        '1': {
            '0': 0
        },
        '2': {
            '0': 0,
            '1': 0
        },
        '3': {
            '0': 0,
            '1': 0,
            '2': 0
        }
    };

    return tilePositions.reduce((columnHeights, tilePosition) => {
        const {
            columnCount,
            position
        } = tilePosition;

        columnHeights[columnCount][position.x] += position.h;

        return columnHeights;
    }, initialColumnHeights);
}

function createNewPosition(columnCount, columnHeight, tileProperty) {
    const shortestColIndex = Object.keys(columnHeight).reduce((shortestColIndex, xIndex) => {
        if (columnHeight[xIndex] < columnHeight[shortestColIndex]) {
            return xIndex;
        }

        return shortestColIndex;
    });

    const tilePosition = {
        ...tileProperty.position,
        i: tileProperty.tile.id,
        x: Number(shortestColIndex),
        y: columnHeight[shortestColIndex]
    };

    columnHeight[shortestColIndex] += tileProperty.position.h;

    return tilePosition;
}

function getTilePositions(tilePositions, activeTiles, cols) {
    const activeTileIds = Object.keys(activeTiles).filter((tileId) => activeTiles[tileId]);
    const existingTiles = new Map();
    const tilePositionsMap = new Map();

    const existingTilePositions = tilePositions.filter((tilePosition) => {
        const {
            columnCount,
            tileId
        } = tilePosition;

        const columnCountStr = columnCount.toString();

        if (!existingTiles.has(columnCountStr)) {
            existingTiles.set(columnCountStr, new Map());
        }

        existingTiles.get(columnCountStr).set(tileId, tilePosition);

        return activeTiles[tileId];
    });

    const columnHeights = getColumnHeights(existingTilePositions);
    const columnCounts = Object.keys(columnHeights);

    const tilePositionsByColumn = columnCounts.reduce((positionsByColumn, columnCount) => {
        const existingTilesForColumn = existingTiles.get(columnCount);

        const activeTilePositions = activeTileIds.map((tileId) => {
            const position = existingTilesForColumn && existingTilesForColumn.has(tileId) ?
                existingTilesForColumn.get(tileId).position :
                createNewPosition(columnCount, columnHeights[columnCount], tileProperties[tileId]);

            tilePositionsMap.set(position.i, tileProperties[tileId]);

            return position;
        });

        positionsByColumn.set(columnCount, fixPositions(activeTilePositions, cols[columnCount]));

        return positionsByColumn;
    }, new Map());

    return columnCounts.reduce((activeTilePositions, columnCount) => {
        const columnCountNum = Number(columnCount);

        const activeTilePositionsByColumn = tilePositionsByColumn.get(columnCount).map((position) => ({
            tile: tilePositionsMap.get(position.i).tile,
            columnCount: columnCountNum,
            position: {
                i: position.i,
                x: position.x,
                y: position.y,
                w: position.w,
                h: position.h
            }
        }));

        return activeTilePositions.concat(activeTilePositionsByColumn);
    }, []);
}

function isTileVisible(tileId, {
    featureToggles,
    isMigrated,
    myJdPermissions
}) {
    const tileInfoForTileId = tileInfo[tileId];

    if (tileInfoForTileId) {
        return isAuthorized(tileInfoForTileId, {
            featureToggles,
            isMigrated,
            myJdPermissions
        });
    }

    return false;
}

function mapTilePositionForSave(tilePosition) {
    return {
        tileId: tilePosition.tile ? tilePosition.tile.id : tilePosition.tileId,
        position: tilePosition.position,
        columnCount: tilePosition.columnCount
    };
}

function sortTiles(tiles) {
    return Object.keys(tiles).reduce((sortedTiles, columnCount) => {
        const columns = sortBy(tiles[columnCount], 'x', 'y');

        sortedTiles[columnCount] = columns.map((column) => ({
            id: column.tileId,
            index: column.i,
            name: column.tileType
        }));

        return sortedTiles;
    }, {});
}

export {
    fixPositions,
    getTileArray,
    getTilePositions,
    isTileVisible,
    mapTilePositionForSave,
    sortTiles
};
