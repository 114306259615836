// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {getAddressFieldsByCountry} from 'Services/membership-service';
import {SUPPORTED_COUNTRY_CODES} from '@deere/onlink-shared-constants';
import {IconEdit} from '@deere/icons';
import {camelCase} from 'lodash';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#fff');

function getAddressElementsByRow(addressFormatElements, addressElements) {
    return addressFormatElements.reduce((addressElementsByRow, addressFormatElement) => {
        const {
            addressFormatElementClass,
            column,
            row
        } = addressFormatElement;

        const addressElement = addressElements.find(({elementClass}) => elementClass === addressFormatElementClass);

        if (addressElement?.elementValue) {
            if (!addressElementsByRow[row]) {
                addressElementsByRow[row] = {
                    addressElements: [],
                    rowKey: ''
                };
            }

            addressElementsByRow[row].addressElements[column] = addressElement.elementValue;
            addressElementsByRow[row].rowKey += addressFormatElementClass;
        }

        return addressElementsByRow;
    }, []);
}

function createMembershipDetails(addressElementsByRow, membership, translations) {
    const {country} = membership;

    const countryItem = SUPPORTED_COUNTRY_CODES.find((supportedCountry) => supportedCountry.code === country);

    return (
        <>
            {
                addressElementsByRow.map((addressElementsByColumn) => (
                    <div
                        className='settings-block-row'
                        key={addressElementsByColumn.rowKey}
                    >
                        {addressElementsByColumn.addressElements.join(' ')}
                    </div>
                ))
            }
            <div className='settings-block-row'>
                {translations[countryItem?.translationKey] || country}
            </div>
        </>
    );
}

function AccountSettings(props) {
    const {
        featureToggles,
        membership,
        setIsEditing,
        translations
    } = props;

    const country = membership.country;

    const [addressFormatElements, setAddressFormatElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {addressFormatElements: addressFormatElementsData} = await getAddressFieldsByCountry(country);

        if (isMounted()) {
            const transformedAddressFormatElements = addressFormatElementsData.map((addressFormatElement) => ({
                ...addressFormatElement,
                addressFormatElementClass: camelCase(addressFormatElement.addressFormatElementClass),
                column: addressFormatElement.column - 1,
                row: addressFormatElement.row - 1
            }));

            setAddressFormatElements(transformedAddressFormatElements);
            setLoading(false);
        }
    }), [country]);

    const addressElementsByRow = getAddressElementsByRow(addressFormatElements, membership.addressElements);

    return (
        <div className='settings-body account-settings'>
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <div className='details-content'>
                    <div className='settings-group'>
                        <div className='settings-block-row'>
                            {membership.name}
                        </div>
                        {createMembershipDetails(addressElementsByRow, membership, translations, featureToggles)}
                    </div>
                    <div className='settings-group horizontal'>
                        <div className='settings-block'>
                            <div className='settings-block-row'>{translations.latitude}</div>
                            <div className='settings-block-row'>{membership.latitude}</div>
                        </div>
                        <div className='settings-block'>
                            <div className='settings-block-row'>{translations.longitude}</div>
                            <div className='settings-block-row'>{membership.longitude}</div>
                        </div>
                    </div>
                    <div className='settings-group'>
                        <div className='settings-block-row'>{translations.ONLINK_MEMBERSHIP_TYPE}</div>
                        <div className='settings-block-row capitalize'>{membership.membershipType}</div>
                    </div>
                </div>
            </LoadingWrapper>
            <OnlinkButton
                className='primary'
                leftIcon={
                    <IconEdit
                        iconEdit={ICON_EDIT_STYLE}
                        primary={PRIMARY_STYLE}
                    />
                }
                onClick={setIsEditing}
            >
                {translations.EDIT}
            </OnlinkButton>
        </div>
    );
}

AccountSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(AccountSettings);
