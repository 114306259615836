// Unpublished Work © 2022-2024 Deere & Company.

import * as workboardActions from 'Store/actions/workboard';

const DEFAULT_STATE = {
    showWorkboards: true,
    showTimeOff: false
};

const workboard = (state = DEFAULT_STATE, action) => {
    if (action.type === workboardActions.UPDATE_WORKBOARD_LAYERS) {
        return Object.assign({}, state, {
            showWorkboards: action.workboardLayers.showWorkboards,
            showTimeOff: action.workboardLayers.showTimeOff
        });
    }
    return state;
};

export default workboard;
