// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Redirect, Route, Switch} from 'react-router-dom';

function OnLinkDialogSwitch(props) {
    const {content} = props;

    const routes = content.map((route, index) => (
        <Route
            exact={true}
            key={`${route.path}${index}`}
            path={route.path}
        >
            {route.element}
        </Route>
    ));

    return (
        <Switch>
            {routes}
            <Redirect
                exact={true}
                from='*'
                to={content[0].path}
            />
        </Switch>
    );
}

OnLinkDialogSwitch.propTypes = {
    content: PropTypes.arrayOf(PropTypes.dialogContent)
};

export default OnLinkDialogSwitch;
