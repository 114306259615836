// Unpublished Work © 2023-2024 Deere & Company.

import {isNullOrUndefined} from 'Common/utils/validation-utils';

const DATA_BOTTOM_MARGIN = 5;
const DATA_LEFT_MARGIN = 5;
const HEADER_BOTTOM_MARGIN = 8;
const PAGE_MARGIN = 15;

function setXPosition(position, xOld, xNew, dataLeftMargin) {
    position.x = position.x >= xOld + dataLeftMargin ?
        xNew + dataLeftMargin :
        xNew;
}

function addPageWhenOutOfSpace(pdf, position, {
    continueCallback,
    dataBottomMargin,
    dataLeftMargin,
    pageMargin
}) {
    const {pageSize} = pdf.internal;

    if (position.y + dataBottomMargin + pageMargin >= pageSize.getHeight() - pageMargin) {
        const halfPageWidth = pageSize.getWidth() / 2;

        position.y = pageMargin;

        if (position.x >= halfPageWidth) {
            pdf.addPage();

            setXPosition(position, halfPageWidth, pageMargin, dataLeftMargin);
        } else {
            setXPosition(position, pageMargin, halfPageWidth, dataLeftMargin);
        }

        if (continueCallback) {
            continueCallback();
        }
    }
}

function printText(pdf, position, {
    bottomMargin,
    continueCallback,
    dataBottomMargin,
    dataLeftMargin,
    maxWidth,
    pageMargin,
    text
}) {
    const lines = pdf.splitTextToSize(text, maxWidth);

    lines.forEach((line) => {
        addPageWhenOutOfSpace(pdf, position, {
            continueCallback,
            dataBottomMargin,
            dataLeftMargin,
            pageMargin
        });

        pdf.text(line, position.x, position.y);

        position.y += bottomMargin;
    });
}

function printHeader(pdf, position, {
    bold,
    dataLeftMargin,
    headerBottomMargin,
    pageMargin,
    text
}) {
    const {
        fontName,
        fontStyle
    } = pdf.getFont();
    const halfPageWidth = pdf.internal.pageSize.getWidth() / 2;

    if (bold) {
        pdf.setFont(fontName, fontStyle, 'bold');
    }

    printText(pdf, position, {
        bottomMargin: headerBottomMargin,
        dataBottomMargin: 0,
        dataLeftMargin,
        maxWidth: halfPageWidth - pageMargin,
        pageMargin,
        text
    });

    pdf.setFont(fontName, fontStyle, 'normal');
}

function printRow(pdf, position, {
    dataBottomMargin,
    dataLeftMargin,
    headerBottomMargin,
    headerText,
    pageMargin,
    text,
    translations
}) {
    const halfPageWidth = pdf.internal.pageSize.getWidth() / 2;

    printText(pdf, position, {
        bottomMargin: dataBottomMargin,
        continueCallback() {
            if (headerText) {
                position.x -= dataLeftMargin;

                printHeader(pdf, position, {
                    bold: true,
                    dataLeftMargin,
                    headerBottomMargin,
                    pageMargin,
                    text: `${headerText} (${translations.CONT})`
                });

                position.x += dataLeftMargin;
            }
        },
        dataBottomMargin,
        dataLeftMargin,
        maxWidth: halfPageWidth - pageMargin - dataLeftMargin - dataLeftMargin,
        pageMargin,
        text
    });
}

function addDataBlock(pdf, position, {
    data = [],
    dataBottomMargin = DATA_BOTTOM_MARGIN,
    dataLeftMargin = DATA_LEFT_MARGIN,
    header,
    headerBottomMargin = HEADER_BOTTOM_MARGIN,
    pageMargin = PAGE_MARGIN,
    secondaryHeader,
    translations
}) {
    if (!isNullOrUndefined(header)) {
        printHeader(pdf, position, {
            bold: true,
            dataLeftMargin,
            headerBottomMargin: isNullOrUndefined(secondaryHeader) ? headerBottomMargin : dataBottomMargin,
            pageMargin,
            text: header
        });
    }

    if (!isNullOrUndefined(secondaryHeader)) {
        printHeader(pdf, position, {
            bold: false,
            dataLeftMargin,
            headerBottomMargin,
            pageMargin,
            text: secondaryHeader
        });
    }

    position.x += dataLeftMargin;

    data.forEach((items) => {
        items.forEach((item) => {
            printRow(pdf, position, {
                dataBottomMargin,
                dataLeftMargin,
                headerBottomMargin,
                headerText: header,
                pageMargin,
                text: item,
                translations
            });
        });

        position.y += dataBottomMargin;
    });

    position.x -= dataLeftMargin;
}

export {
    addDataBlock
};
