// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {JOHN_DEERE_MANUFACTURER_IDS} from 'Ui/constants/john-deere-manufacturer-ids';
import {ANALYTICS_EVENTS, trackOnLinkEvent} from 'Utils/analytics-utils';

function PartsCatalogLink(props) {
    const {
        manufacturerId,
        value,
        url,
        analyticsEventData
    } = props;

    if (JOHN_DEERE_MANUFACTURER_IDS.includes(manufacturerId)) {
        return (
            <EditLink
                onClick={() => {
                    trackOnLinkEvent(ANALYTICS_EVENTS.TRACK_EVENT, analyticsEventData);
                    window.open(url, '_blank');
                }}
            >
                {value}
            </EditLink>
        );
    }

    return value;
}

PartsCatalogLink.propTypes = {
    analyticsEventData: PropTypes.object,
    manufacturerId: PropTypes.string,
    url: PropTypes.string,
    value: PropTypes.string
};

export default PartsCatalogLink;
