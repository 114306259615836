// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddButton from 'Ui/components/settings/common/add-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {deleteDepartmentById} from 'Services/department-service';
import {getDepartments} from 'Services/membership-service';
import {closeDialog, openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {fetchEffectData} from 'Utils/react-utils';
import dialogTypes from 'Ui/components/common/dialog-types';
import {TOAST_TYPE} from '@deere/toast';

function getColumns(translations, openDialog, fetchDepartments) {
    return [{
        Header: translations.ONLINK_DEPARTMENTS,
        accessor: 'name',
        Cell(row) {
            const {
                original,
                value
            } = row;

            return (
                <EditLink
                    onClick={() => {
                        openDialog({
                            department: original.department,
                            getDepartments: fetchDepartments
                        });
                    }}
                >
                    {value}
                </EditLink>
            );
        }
    }];
}

function getFilterComponent(translations, openDialog, fetchDepartments) {
    return (
        <AddButton
            className='add-department-button'
            label={translations.ONLINK_ADD_DEPARTMENT}
            onClick={() => {
                openDialog({
                    department: null,
                    getDepartments: fetchDepartments
                });
            }}
        />
    );
}

function DepartmentSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        openDialog,
        translations
    } = props;

    const [departments, setDepartments] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    async function fetchDepartments(isMounted = () => true) {
        setLoading(true);

        const {departments: departmentsData} = await getDepartments();

        if (isMounted()) {
            const formattedDepartments = departmentsData.map((department) => ({
                department,
                name: department.name
            }));

            setDepartments(formattedDepartments);
            setLoading(false);
        }
    }

    function deleteDepartment(data) {
        const {departmentId} = data.department;

        openConfirmation({
            message: translations.ONLINK_DEPARTMENT_THIS_CANNOT_BE_UNDONE,
            title: translations.ONLINK_DELETE_DEPARTMENT,
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteDepartmentById(departmentId);

                    await fetchDepartments();
                } catch (e) {
                    addToast({
                        message: translations.ONLINK_CONFIRM_DEPARTMENT_DELETION_FAILED,
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    React.useEffect(() => fetchEffectData(fetchDepartments), []);

    return (
        <DataTable
            columns={getColumns(translations, openDialog, fetchDepartments)}
            defaultSorted={[{
                desc: false,
                id: 'name'
            }]}
            deleteHandler={deleteDepartment}
            filterComponent={getFilterComponent(translations, openDialog, fetchDepartments)}
            loading={loading}
            rows={departments}
            searchable={true}
            showDeleteColumn={true}
            translations={translations}
        />
    );
}

DepartmentSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openReduxDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openDialog(props) {
            dispatch(openReduxDialog(dialogTypes.DEPARTMENT_EDIT_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentSettings);
