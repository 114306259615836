// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import DataTable from 'Ui/components/common/data-table/data-table';
import React from 'react';
import {fetchEffectData} from 'Utils/react-utils';
import {ALREADY_EXISTS, INVITE_SENT, STATUS_TRANSLATION_MAP} from 'Common/constants/invite-statuses';
import {cloneDeep} from 'lodash';
import EditableCell from 'Ui/components/common/data-table/editable-cell';
import PropTypes from 'Utils/prop-type-utils';
import Box from '@mui/material/Box';
import {Button} from '@deere/isg.component-library';
import {loadMigrationDataTable} from 'Utils/myJohnDeereMigration/user-migration-table-data';

function getEmailColumn(row, translations, setValid, editableEmailColumn, setInvites, isRowDisabled, disabledCell) {
    const user = row.original;

    if (editableEmailColumn) {
        return (
            <EditableCell
                disabled={user.inviteStatus === INVITE_SENT || isRowDisabled(row)}
                errors={{
                    typeMismatch: translations.ONLINK_EMAIL_VALIDATE
                }}
                initialValue={user.email}
                name={`staffMigrationEmail${user.appUserId}`}
                onChange={({target: {value}}) => {
                    setInvites((prevInvites) => {
                        const invites = cloneDeep(prevInvites);

                        invites[user.appUserId].email = value;

                        return invites;
                    });
                }}
                setValid={setValid}
                type='email'
            />
        );
    }

    return disabledCell(row);
}

function getColumns({
    editableEmailColumn,
    isRowDisabled,
    selected,
    setInvites,
    setSelected,
    setValid,
    translations
}) {
    function disabledCell(row) {
        return isRowDisabled(row) ? (
            <Box
                color='#aaa'
                component='span'
            >
                {row.value}
            </Box>
        ) : row.value;
    }

    return [
        {
            className: 'left-aligned',
            headerClassName: 'right-aligned',
            width: 25,
            resizable: false,
            sortable: false,
            Cell(row) {
                const user = row.original;

                return isRowDisabled(row) ? null : (
                    <input
                        checked={selected.has(user.appUserId)}
                        className='table-row-checkbox'
                        disabled={user.inviteStatus === INVITE_SENT}
                        onChange={() => {
                            const clonedSelectedStaff = new Set(selected);

                            clonedSelectedStaff.has(user.appUserId) ?
                                clonedSelectedStaff.delete(user.appUserId) :
                                clonedSelectedStaff.add(user.appUserId);

                            setSelected(clonedSelectedStaff);
                        }}
                        type='checkbox'
                    />
                );
            }
        },
        {
            Header: translations.NAME,
            accessor: 'name',
            Cell: disabledCell
        },
        {
            Header: translations.TITLE,
            accessor: 'title',
            Cell: disabledCell
        },
        {
            Header: translations['USER_MANAGEMENT.COLUMN_HEADER.ROLE'],
            accessor: 'roleName',
            Cell: disabledCell
        },
        {
            Header: translations.my_team_email,
            accessor: 'email',
            Cell: (row) => getEmailColumn(row, translations, setValid, editableEmailColumn, setInvites, isRowDisabled, disabledCell)
        },
        {
            Header: translations.STATUS,
            accessor: 'status',
            Cell: (row) => disabledCell({
                ...row,
                value: isRowDisabled(row) ? translations[STATUS_TRANSLATION_MAP[ALREADY_EXISTS]] : row.value
            })
        }
    ];
}

function UserMigrationTable(props) {
    const {
        disableDuplicateUsers,
        disableMigrate,
        editableEmailColumn,
        filterInvitedUsers,
        loading,
        onMigrate,
        rows,
        selected,
        setInvites,
        setLoading,
        setRows,
        setSelected,
        setValid,
        translations,
        userRoles
    } = props;

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        await loadMigrationDataTable({
            filterInvitedUsers,
            isMounted,
            setLoading,
            setRows,
            setInvites,
            setSelected,
            translations,
            userRoles
        });
    }), []);

    const isRowDisabled = React.useCallback(({original: user}) => {
        return disableDuplicateUsers && user.isDuplicate;
    }, [disableDuplicateUsers]);

    return (
        <div className='user-migration-table'>
            <DataTable
                columns={getColumns({
                    editableEmailColumn,
                    isRowDisabled,
                    selected,
                    setInvites,
                    setSelected,
                    setValid,
                    translations
                })}
                defaultSorted={[{
                    desc: false,
                    id: 'roleName'
                }]}
                loading={loading}
                rows={rows}
                searchable={true}
                translations={translations}
            />
            <Button
                disabled={disableMigrate}
                onClick={onMigrate}
                sx={{
                    marginLeft: '10px'
                }}
                variant='primary'
            >
                {translations.ONLINK_MIGRATE}
            </Button>
        </div>
    );
}

UserMigrationTable.propTypes = {
    disableDuplicateUsers: PropTypes.bool,
    disableMigrate: PropTypes.bool,
    editableEmailColumn: PropTypes.bool,
    filterInvitedUsers: PropTypes.bool,
    loading: PropTypes.bool,
    onMigrate: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.instanceOf(Set),
    setInvites: PropTypes.func,
    setLoading: PropTypes.func,
    setRows: PropTypes.func,
    setSelected: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export default UserMigrationTable;
