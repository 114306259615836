// Unpublished Work © 2023-2024 Deere & Company.

import Dashboard from 'Ui/components/dashboard/dashboard';
import GraphPanel from 'Ui/components/graph/graph-panel';
import MyWork from 'OnLabor/my-work/my-work';
import PlayBooks from 'Ui/components/graph/PlayBooks/play-books';
import ReportWrapper from 'OnLabor/report/report-wrapper';
import SettingsDashboard from 'Ui/components/settings/settings-dashboard';
import TeeSheets from 'Ui/components/graph/tee-sheets';
import WeatherGraph from 'Ui/components/graph/weather/weather-graph';
import Workboard from 'Ui/components/onlabor/workboard/workboard';
import {ONLINK_SETTINGS} from 'Ui/constants/tab-header-constants';
import {
    VIEW_COURSE_MEASUREMENT_DATA,
    VIEW_COURSE_PLAYER_DATA,
    VIEW_EQUIPMENT_LIST_API,
    VIEW_FINANCIAL_DATA,
    VIEW_PARTS,
    VIEW_WORKBOARD_SUMMARY_DATA,
    VIEW_WORKBOARDS
} from 'Common/constants/business-activities';
import {
    ANALYZE_COURSE_OPERATIONS, ASSIGNMENTS, DRILL_IN, DRILL_IN_CHART, FLEET, FORETEES, HOC, HOME, INVENTORY,
    MY_WORK, PARTS_ORDERS, PARTS_RECOMMENDED, PLAYBOOKS, SETTINGS, SHOP, SUGGESTED_MAINTENANCE, TWC, WORKBOARD,
    EQUIPMENT_REPORT, SERVICE_HISTORY, PARTS_HISTORY
} from 'Common/constants/routes';
import appAccess from 'Common/constants/app-access';
import permissions from 'Common/constants/permissions';
import {courseOperationsNavLinks, fleetAnalyzeNavLinks} from 'Ui/constants/secondary-navbar-constants';
import {PARTS_HISTORY_PAGE} from 'Common/constants/feature-toggles';

const LABOR_REQUIREMENTS = {
    myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA
};
const EQUIPMENT_REQUIREMENTS = {
    myJdPermissions: VIEW_EQUIPMENT_LIST_API
};
const FERT_CHEM_REQUIREMENTS = {
    myJdPermissions: VIEW_FINANCIAL_DATA
};

const REQUIRE_VIEW_COURSE_MEASUREMENT_DATA = {
    myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
};

const BASE_ROUTES = {
    [HOME]: {
        appAccess: appAccess.ONGOLF,
        component: Dashboard,
        exact: true,
        path: HOME
    },
    [SETTINGS]: {
        component: SettingsDashboard,
        path: SETTINGS,
        tabHeader: ONLINK_SETTINGS
    },
    [DRILL_IN]: {
        appAccess: appAccess.ONGOLF,
        component: Dashboard,
        exact: true,
        path: DRILL_IN,
        paramsAuth: {
            type: {
                labor: LABOR_REQUIREMENTS,
                'water_energy': {
                    myJdPermissions: [VIEW_COURSE_MEASUREMENT_DATA]
                },
                'fert_chem': {
                    myJdPermissions: [VIEW_FINANCIAL_DATA, VIEW_COURSE_MEASUREMENT_DATA]
                },
                equipment: EQUIPMENT_REQUIREMENTS,
                rounds: {
                    myJdPermissions: [VIEW_COURSE_PLAYER_DATA, VIEW_COURSE_MEASUREMENT_DATA]
                }
            }
        }
    },
    [PLAYBOOKS]: {
        appAccess: appAccess.ONAPPLY,
        component: PlayBooks,
        exact: true,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        path: PLAYBOOKS
    },
    [TWC]: {
        appAccess: appAccess.ONGOLF,
        component: WeatherGraph,
        exact: true,
        path: TWC
    },
    [FORETEES]: {
        appAccess: appAccess.ONGOLF,
        component: TeeSheets,
        exact: true,
        path: FORETEES,
        myJdPermissions: VIEW_COURSE_PLAYER_DATA
    },
    [DRILL_IN_CHART]: {
        appAccess: appAccess.ONGOLF,
        component: GraphPanel,
        exact: true,
        path: DRILL_IN_CHART,
        paramsAuth: {
            tileType: {
                'equipment_1': EQUIPMENT_REQUIREMENTS,
                'equipment_2': EQUIPMENT_REQUIREMENTS,
                'equipment_3': EQUIPMENT_REQUIREMENTS,
                'equipment_4': EQUIPMENT_REQUIREMENTS,
                'fert_chem_1': FERT_CHEM_REQUIREMENTS,
                'fert_chem_2': FERT_CHEM_REQUIREMENTS,
                'fert_chem_3': {
                    myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
                },
                'labor_1': LABOR_REQUIREMENTS,
                'labor_2': LABOR_REQUIREMENTS,
                'labor_3': LABOR_REQUIREMENTS,
                'labor_4': LABOR_REQUIREMENTS,
                'water_energy_2': {
                    myJdPermissions: [VIEW_FINANCIAL_DATA, VIEW_COURSE_MEASUREMENT_DATA]
                },
                'water_energy_3': REQUIRE_VIEW_COURSE_MEASUREMENT_DATA,
                'water_energy_4': REQUIRE_VIEW_COURSE_MEASUREMENT_DATA,
                'water_energy_5': REQUIRE_VIEW_COURSE_MEASUREMENT_DATA,
                'water_energy_6': REQUIRE_VIEW_COURSE_MEASUREMENT_DATA,
                'rounds_2': REQUIRE_VIEW_COURSE_MEASUREMENT_DATA,
                'rounds_1': REQUIRE_VIEW_COURSE_MEASUREMENT_DATA
            }
        }
    },
    [SUGGESTED_MAINTENANCE]: {
        appAccess: appAccess.ONEQUIP,
        path: SUGGESTED_MAINTENANCE
    },
    [ASSIGNMENTS]: {
        appAccess: appAccess.ONEQUIP,
        path: ASSIGNMENTS
    },
    [SHOP]: {
        appAccess: appAccess.ONEQUIP,
        path: SHOP
    },
    [INVENTORY]: {
        appAccess: appAccess.ONEQUIP,
        path: INVENTORY
    },
    [PARTS_HISTORY]: {
        appAccess: appAccess.ONEQUIP,
        exact: true,
        myJdPermissions: VIEW_PARTS,
        migratedMembershipAccessOnly: true,
        path: PARTS_HISTORY,
        toggle: PARTS_HISTORY_PAGE
    },
    [PARTS_RECOMMENDED]: {
        appAccess: appAccess.ONEQUIP,
        exact: true,
        path: PARTS_RECOMMENDED
    },
    [PARTS_ORDERS]: {
        appAccess: appAccess.ONEQUIP,
        exact: true,
        path: PARTS_ORDERS
    },
    [MY_WORK]: {
        component: MyWork,
        path: MY_WORK,
        permission: permissions.CAN_ACCESS_MY_WORK
    },
    [WORKBOARD]: {
        appAccess: appAccess.ONLABOR,
        component: Workboard,
        path: WORKBOARD,
        myJdPermissions: VIEW_WORKBOARDS
    },
    [ANALYZE_COURSE_OPERATIONS]: {
        appAccess: appAccess.ONLABOR,
        component: ReportWrapper,
        className: 'onlabor-redesign-container',
        path: ANALYZE_COURSE_OPERATIONS,
        secondaryNavBar: {
            navLinks: courseOperationsNavLinks
        },
        paramsAuth: {
            type: {
                workboard: {
                    myJdPermissions: [VIEW_WORKBOARD_SUMMARY_DATA]
                },
                labor: {
                    myJdPermissions: [VIEW_WORKBOARD_SUMMARY_DATA]
                }
            }
        }
    },
    [FLEET]: {
        appAccess: appAccess.ONLABOR,
        path: FLEET,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    },
    [HOC]: {
        appAccess: appAccess.ONLABOR,
        path: HOC
    },
    [EQUIPMENT_REPORT]: {
        appAccess: appAccess.ONEQUIP,
        path: EQUIPMENT_REPORT
    },
    [SERVICE_HISTORY]: {
        appAccess: appAccess.ONEQUIP,
        path: SERVICE_HISTORY
    }
};

const PH_BASE_HOME_ROUTES = [
    HOME
];
const PH_BASE_FLEET_ROUTES = [
    FLEET,
    HOC
];
const PH_BASE_LABOR_ROUTES = [
    WORKBOARD,
    MY_WORK
];
const PH_BASE_FLEET_MAINTENANCE_ROUTES = [
    SUGGESTED_MAINTENANCE,
    SHOP,
    ASSIGNMENTS
];
const PH_BASE_PART_ROUTES = [
    INVENTORY,
    PARTS_HISTORY,
    PARTS_RECOMMENDED,
    PARTS_ORDERS
];

const CONTRIBUTION_PRODUCT_IDS = {
    HOME: '474a7a34-8324-4a41-9172-ab6cb05ebf90',
    FLEET: '686ae3ea-b267-489c-9bf5-217b46efdabf',
    LABOR: '268feaca-10c6-477a-8992-edb2bb4385c6',
    FLEET_MAINTENANCE: '1f9f95d4-e42b-4014-85c8-4bfc233069be',
    PART: 'c4ff89e7-79d4-4e45-85c6-71a55322a618',
    COURSE_OPERATIONS: '856369d4-9fd9-45e5-8e2e-d71a8a51205c'
};

function getBaseRouteConfig(path, isMigrated) {
    if (isMigrated && path === ANALYZE_COURSE_OPERATIONS) {
        return {
            ...BASE_ROUTES[path],
            secondaryNavBar: {
                navLinks: [
                    ...courseOperationsNavLinks,
                    ...fleetAnalyzeNavLinks
                ]
            }
        };
    }

    return BASE_ROUTES[path];
}

export {
    BASE_ROUTES,
    getBaseRouteConfig,
    PH_BASE_HOME_ROUTES,
    PH_BASE_FLEET_ROUTES,
    PH_BASE_LABOR_ROUTES,
    PH_BASE_FLEET_MAINTENANCE_ROUTES,
    PH_BASE_PART_ROUTES,
    CONTRIBUTION_PRODUCT_IDS
};
