// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import TabMenu from 'Ui/components/tab/tab-menu';
import {joinClassNames} from 'Utils/html-utils';

class TabLayout extends React.Component {
    constructor(props) {
        super(props);

        this.onTabClick = this.onTabClick.bind(this);

        this.state = {
            activeTab: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.tabs) !== JSON.stringify(this.props.tabs)) {
            this.onTabClick(0);
        }
    }

    onTabClick(index) {
        this.setState({
            activeTab: index
        });
    }

    render() {
        return (
            <div className={joinClassNames('tab-layout', this.props.className)}>
                <TabMenu
                    activeTab={this.state.activeTab}
                    onTabClick={this.onTabClick}
                    tabs={this.props.tabs}
                />
                {
                    React.Children.toArray(this.props.children)[this.state.activeTab]
                }
            </div>
        );
    }
}

TabLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.string)
};

export default TabLayout;
