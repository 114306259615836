// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {isEmptyString} from 'Common/utils/validation-utils';
function PasswordInput(props) {
    const {
        confirmPassword,
        errors,
        password,
        translations,
        ...otherProps
    } = props;

    const passwordErrors = {
        ...errors,
        customError: translations.ONLINK_PASSWORDS_DO_NOT_MATCH
    };

    const valid = isEmptyString(password) && isEmptyString(confirmPassword) || password === confirmPassword;

    return (
        <>
            <ValidationInput
                {...otherProps}
                autoComplete='new-password'
                errors={passwordErrors}
                invalidCustomError={!valid}
                label={translations.password}
                name='password'
                type='password'
                value={password}
            />
            <ValidationInput
                {...otherProps}
                autoComplete='new-password'
                errors={passwordErrors}
                invalidCustomError={!valid}
                label={translations.ONLINK_CONFIRM_PASSWORD}
                name='confirmPassword'
                type='password'
                value={confirmPassword}
            />
        </>
    );
}

PasswordInput.propTypes = {
    confirmPassword: PropTypes.stringOrNumber.isRequired,
    errors: PropTypes.errors.isRequired,
    onChange: PropTypes.func.isRequired,
    password: PropTypes.stringOrNumber.isRequired,
    setValid: PropTypes.func.isRequired,
    translations: PropTypes.translations
};

export default PasswordInput;
