// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import TabLayout from 'Ui/components/tab/tab-layout';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import EditTableRow from 'Ui/components/graph/edit-table-row';
import {configForUnit} from 'Utils/data-utils';
import {getDataUnit} from 'Utils/manual-data-utils';
import {dataTableFormatTime, DATE_TIME_FORMAT_OPTIONS, forceLocalTimeZone, formatLocalizedTime} from 'Utils/time-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {formatUoMValue, getFormattedCurrency, getPreferredDataType, getUoMConfig} from 'Utils/unit-conversion-utils';
import {FUEL_COST, GAS_COST, HOC, HOC_SPEC} from 'Common/constants/data-group-constants';
import {DOLLARS} from 'Common/constants/data-unit-constants';
import {COURSE} from 'Common/constants/membership-type';
import {CURRENCY_PREFERENCE} from 'Common/constants/preference-constants';
import {BY_TYPE} from 'Ui/components/graph/constants/graph-filters';
import {groupBy, orderBy, sortBy} from 'lodash';
import moment from 'moment';

const DEFAULT_TYPES = [
    'avg',
    'min',
    'max'
];

const NOTE_COLUMN = {
    headerTranslationName: 'NOTE',
    accessor: 'note'
};

const CUSTOM_DAILY_COLUMNS = {
    'HOC Apps': [NOTE_COLUMN],
    'HOC Collars': [NOTE_COLUMN],
    'HOC Fairways': [NOTE_COLUMN],
    'HOC Greens': [NOTE_COLUMN],
    'HOC Intermediate': [NOTE_COLUMN],
    'HOC Rough': [NOTE_COLUMN],
    'HOC Settings': [NOTE_COLUMN],
    'HOC Tees': [NOTE_COLUMN],
    'HOC Tees Special': [NOTE_COLUMN],
    'HOC Greens Special': [NOTE_COLUMN],
    'HOC Fairways Special': [NOTE_COLUMN],
    'HOC Rough Special': [NOTE_COLUMN],
    'HOC Collars Special': [NOTE_COLUMN],
    'HOC Approaches Special': [NOTE_COLUMN],
    'HOC Intermediate Special': [NOTE_COLUMN],
    'HOC Bunker Faces Special': [NOTE_COLUMN],
    'HOC Bunker Surrounds Special': [NOTE_COLUMN],
    'HOC Other Special': [NOTE_COLUMN],
    'HOC Bunker Faces': [NOTE_COLUMN],
    'HOC Bunker Surrounds': [NOTE_COLUMN],
    'Height of Cut': [NOTE_COLUMN]
};

const DATA_SOURCES = {
    'OnEquip': 'Maintenance',
    'OnLabor': 'Workboards',
    'OnApply': 'Playbooks'
};

function getTabs(tableData, translations, timeScale) {
    const tableDataKeys = Object.keys(tableData);

    if (timeScale === 'day') {
        return tableDataKeys.map((key) => tableData[key].key).sort();
    }

    if (tableDataKeys.some((key) => tableData[key].useField === 'total')) {
        return ['total', ...DEFAULT_TYPES];
    }

    return DEFAULT_TYPES;
}

function setHeaderCountForKey(keyMap, header, key) {
    if (!keyMap.has(key)) {
        keyMap.set(key, new Map());
    }

    const headerMap = keyMap.get(key);
    const currentValue = headerMap.get(header) || 0;

    headerMap.set(header, currentValue + 1);
}

function getHeaderCount(headers, key, tableData) {
    const headersByKey = new Map();

    return tableData[key].data.reduce((headerCount, data) => {
        const header = data.timeSelect;

        setHeaderCountForKey(headersByKey, header, key);

        const count = Math.max(headerCount.get(header) || 0, headersByKey.get(key).get(header));

        headerCount.set(header, count);

        return headerCount;
    }, headers);
}

function createDataWithProperties(data, key, useField, dataLabel, dataUnit) {
    const newData = {
        ...data,
        dataLabel,
        key,
        useField
    };

    if (!newData.dataUnits) {
        newData.dataUnits = dataUnit;
    }

    if (!newData.dataSource) {
        newData.dataSource = 'Manual';
    }

    return newData;
}

function mapDataByTab(dataMap, data, tab, header) {
    if (!dataMap.has(tab)) {
        dataMap.set(tab, new Map());
    }

    const dataByTab = dataMap.get(tab);

    if (!dataByTab.has(header)) {
        dataByTab.set(header, []);
    }

    dataByTab.get(header).push(data);

    return dataMap;
}

function groupDailyData(dataMap, tableData, headers) {
    headers.forEach((count, header) => {
        const dataForHeader = tableData.data.filter((data) => data.timeSelect === header);

        dataForHeader.forEach((data) => {
            const newData = createDataWithProperties(data, tableData.key, tableData.useField, tableData.dataLabel, data.dataUnit);

            mapDataByTab(dataMap, newData, tableData.key, header);
        });
    });
}

function groupMonthlyAndYearlyData(dataMap, tableData, headers, tabs) {
    headers.forEach((count, header) => {
        const dataForHeader = tableData.data.filter((data) => data.timeSelect === header);
        const paddedDataForHeader = dataForHeader.concat(...Array(count - dataForHeader.length));

        paddedDataForHeader.forEach((data) => {
            const newData = createDataWithProperties(data, tableData.key, tableData.useField, tableData.dataLabel, tableData.dataUnits);

            tabs.forEach((tab) => mapDataByTab(dataMap, newData, tab, header));
        });
    });
}

function groupData(tableData, tabs, timeScale) {
    const tableKeys = Object.keys(tableData);
    const headers = tableKeys.reduce((headers, key) => getHeaderCount(headers, key, tableData), new Map());
    const groupDataCallback = timeScale === 'day' ? groupDailyData : groupMonthlyAndYearlyData;

    return tableKeys.reduce((dataMap, key) => {
        const dataForKey = tableData[key];

        groupDataCallback(dataMap, dataForKey, headers, tabs);

        return dataMap;
    }, new Map());
}

function getValue(data, tab) {
    const {
        [`${tab}Value`]: value,
        dataValue,
        totalValue
    } = data;

    return isNullOrUndefined(value) ?
        totalValue || dataValue :
        value;
}

function checkForWeather6(data, tab, panelType) {
    const willHide = data.useField === 'total' !== (tab === 'total');

    return panelType === 'weather_6' && willHide;
}

function getFormattedValue({
    currencyPreference = CURRENCY_PREFERENCE.USD,
    data,
    panelType,
    preferredDataTypes,
    tab,
    translations,
    unitOfMeasure,
    unitOfMeasureOverrides,
    useNonConverted
}) {
    const {
        dataType,
        dataUnits
    } = data;

    const willHide = tab === 'total' && dataUnits === 'percent' || checkForWeather6(data, tab, panelType);

    const value = willHide ? null : getValue(data, tab);

    if (dataUnits === DOLLARS && !(dataType === FUEL_COST || dataType === GAS_COST)) {
        return getFormattedCurrency(value, {
            currencyPreference
        });
    }

    const formatting = useNonConverted ?
        configForUnit(getDataUnit({
            currencyPreference,
            dataType,
            dataUnit: dataUnits,
            translations
        })) :
        getUoMConfig({
            dataType,
            dataUnit: getPreferredDataType(preferredDataTypes, unitOfMeasure, dataType) || dataUnits,
            unitOfMeasure,
            unitOfMeasureOverrides
        }).formatting;

    return isNullOrUndefined(value) ?
        '' :
        formatUoMValue(value, {
            formatting: {
                ...formatting,
                noDecimalFormatting: useNonConverted,
                dataUnits: Array.isArray(formatting.dataUnits) ?
                    formatting.dataUnits : [{
                        ...formatting,
                        includeSpace: true
                    }]
            },
            translations
        });
}

function getDataValue({
    data,
    dateSelect,
    formattedValue,
    membershipId,
    onManualDataUpdate,
    showEditButton,
    translations,
    membershipType,
    featureToggles
}) {
    const isSelectedMembership = membershipId === data.membershipId;

    const dontEditHoc = (data.dataGroup === HOC || data.dataGroup === HOC_SPEC) && membershipType !== COURSE;

    if (data.dataSource === 'Manual' && showEditButton && isSelectedMembership && !dontEditHoc) {
        return (
            <EditTableRow
                dataGroup={data.dataGroup}
                dateSelect={dateSelect}
                featureToggles={featureToggles}
                manualDataId={data.id}
                onManualDataUpdate={onManualDataUpdate}
                translations={translations}
                value={formattedValue}
            />
        );
    }

    return formattedValue;
}

function addHeaders(headers, header, timeScale, numElements, numLabels) {
    const newHeader = dataTableFormatTime(header, timeScale);
    const count = Math.ceil(numElements / numLabels);

    for (let i = 0; i < count; i++) {
        headers.push(newHeader);
    }
}

function addDailyHeaders(headers, dataByHeader) {
    dataByHeader.forEach((data) => {
        const localTime = forceLocalTimeZone(data.timeSample);
        const formattedTime = formatLocalizedTime(localTime, DATE_TIME_FORMAT_OPTIONS);

        headers.push(formattedTime);
    });
}

function getDailyKey(translations, tab) {
    const dailyOverrides = {
        APPROACHES: 'HOC Apps',
        COLLARS: 'HOC Collars',
        FAIRWAYS: 'HOC Fairways',
        GREENS: 'HOC Greens',
        INTERMEDIATE: 'HOC Intermediate',
        ROUGH: 'HOC Rough',
        TEES: 'HOC Tees',
        ONLINK_TEES_SPEC: 'HOC Tees Special',
        ONLINK_GREENS_SPEC: 'HOC Greens Special',
        ONLINK_FAIRWAYS_SPEC: 'HOC Fairways Special',
        ONLINK_ROUGH_SPEC: 'HOC Rough Special',
        ONLINK_COLLARS_SPEC: 'HOC Collars Special',
        ONLINK_APPROACHES_SPEC: 'HOC Approaches Special',
        ONLINK_INTERMEDIATE_SPEC: 'HOC Intermediate Special',
        ONLINK_BUNKER_FACES_SPEC: 'HOC Bunker Faces Special',
        ONLINK_BUNKER_SURROUNDS_SPEC: 'HOC Bunker Surrounds Special',
        ONLINK_OTHER_SPEC: 'HOC Other Special',
        ONLINK_BUNKER_FACES: 'HOC Bunker Faces',
        ONLINK_BUNKER_SURROUNDS: 'HOC Bunker Surrounds',
        OTHER: 'Height of Cut'
    };

    return dailyOverrides[tab] || tab;
}

function createRowsWithHeaders(primarySelector, customColumns, translations) {
    const rows = {
        labels: [primarySelector === BY_TYPE ? translations.ONLINK_DATA_TYPE : translations.ONLINK_LOCATION],
        values: [translations.VALUE]
    };

    if (customColumns) {
        customColumns.forEach((customColumn) => {
            rows[customColumn.accessor] = [translations[customColumn.headerTranslationName]];
        });
    } else {
        rows.areaNames = [translations.AREA];
        rows.dataSources = [translations.DATA_SOURCE];
    }

    return rows;
}

function addDataToRows(rows, data, customColumns) {
    if (customColumns) {
        customColumns.forEach((customColumn) => {
            rows[customColumn.accessor].push(data[customColumn.accessor]);
        });
    } else {
        rows.areaNames.push(data.areaName);
        rows.dataSources.push(data.dataSource);
    }
}

function createDailyRowsAndHeaders({
    dateSelect,
    featureToggles,
    membership,
    panelType,
    primarySelector,
    onManualDataUpdate,
    tab,
    tableData,
    translations,
    useNonConverted
}) {
    const {
        currencyPreference,
        membershipId,
        membershipType,
        preferredDataTypes,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = membership;

    const headers = [translations.DATE];
    const customColumns = CUSTOM_DAILY_COLUMNS[tab];
    const rows = createRowsWithHeaders(primarySelector, customColumns, translations);
    const date = moment(dateSelect);

    let dataTimeScale = '';

    tableData.forEach((dataByHeader) => {
        const descendingData = orderBy(dataByHeader, 'timeSample', ['desc']);

        descendingData.reduce((labels, data) => {
            const {
                dataLabel,
                key,
                membershipName,
                timeScale = dataTimeScale
            } = data;
            const label = primarySelector === BY_TYPE ?
                translations[dataLabel] || dataLabel :
                membershipName;

            dataTimeScale = timeScale;

            const formattedValue = getFormattedValue({
                currencyPreference,
                data,
                featureToggles,
                panelType,
                preferredDataTypes,
                tab,
                translations,
                unitOfMeasure,
                useNonConverted,
                unitOfMeasureOverrides
            });

            const dataValue = getDataValue({
                data,
                dateSelect: date,
                formattedValue,
                membershipId,
                onManualDataUpdate,
                showEditButton: true,
                translations,
                membershipType,
                featureToggles
            });

            rows.labels.push(label);
            rows.values.push(dataValue);

            addDataToRows(rows, data, customColumns);

            labels.add(key);

            return labels;
        }, new Set());

        addDailyHeaders(headers, descendingData);
    });

    return {
        headers,
        rows
    };
}

function getUniqueDataSources(dataSources, data, translations) {
    const dataSource = DATA_SOURCES[data.dataSource] || data.dataSource;

    dataSources.add(translations[dataSource] || dataSource);

    return dataSources;
}

function buildNonDailyDataSource({
    data,
    dataSources,
    featureToggles,
    panelType,
    preferredDataTypes,
    primarySelector,
    tab,
    translations,
    unitOfMeasure,
    unitOfMeasureOverrides
}) {
    const groupByKey = primarySelector === BY_TYPE ? 'key' : 'dataType';
    const filteredData = data.filter((dataItem) => getFormattedValue({
        data: dataItem,
        featureToggles,
        panelType,
        preferredDataTypes,
        tab,
        translations,
        unitOfMeasure,
        unitOfMeasureOverrides
    }));
    const groupedData = groupBy(filteredData, groupByKey);

    let longestArray = 1;
    const uniqueDataSources = Object.keys(groupedData).reduce((dataSourcesForRow, key) => {
        const dataForKey = groupedData[key];

        longestArray = Math.max(longestArray, dataForKey.length);

        return dataForKey.reduce((accumulator, currentValue) => getUniqueDataSources(accumulator, currentValue, translations), dataSourcesForRow);
    }, new Set());

    const formattedDataSources = Array.from(uniqueDataSources).join(', ');

    for (let i = 0; i < longestArray; i++) {
        dataSources.push(formattedDataSources);
    }
}

function createRowsAndHeaders({
    featureToggles,
    membership,
    panelType,
    primarySelector,
    tab,
    tableData,
    translations
}) {
    const {
        currencyPreference,
        membershipType,
        preferredDataTypes,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = membership;
    const headers = [translations.DATE];
    const rows = {};
    const dataSources = [translations.DATA_SOURCE];

    let dataTimeScale = '';

    tableData.forEach((dataByHeader, header) => {
        const sorted = sortBy(dataByHeader, 'dataSource');

        buildNonDailyDataSource({
            data: sorted,
            dataSources,
            featureToggles,
            panelType,
            preferredDataTypes,
            primarySelector,
            tab,
            translations,
            unitOfMeasure,
            unitOfMeasureOverrides
        });

        const allLabels = sorted.reduce((labels, data) => {
            const {
                key,
                timeScale = dataTimeScale
            } = data;

            const dataKey = translations[key] || key;

            if (!isNullOrUndefined(dataKey)) {
                rows[dataKey] = rows[dataKey] || [dataKey];
                dataTimeScale = timeScale;

                const formattedValue = getFormattedValue({
                    currencyPreference,
                    data,
                    featureToggles,
                    panelType,
                    preferredDataTypes,
                    tab,
                    translations,
                    unitOfMeasure,
                    unitOfMeasureOverrides
                });

                const dataValue = getDataValue({
                    data,
                    formattedValue,
                    membershipId: primarySelector,
                    showEditButton: false,
                    translations,
                    membershipType,
                    featureToggles
                });

                rows[dataKey].push(dataValue);
            }

            labels.add(dataKey);

            return labels;
        }, new Set());

        addHeaders(headers, header, dataTimeScale, sorted.length, allLabels.size);
    });

    rows.dataSources = dataSources;

    return {
        headers,
        rows
    };
}

function createTables(tabs, {
    dateSelect,
    featureToggles,
    membership,
    panelType,
    primarySelector,
    onManualDataUpdate,
    tableData,
    timeScale,
    translations
}) {
    const isDaily = timeScale === 'day';
    const tableDataMap = groupData(tableData, tabs, timeScale);
    const createRowsAndHeadersCallback = isDaily ? createDailyRowsAndHeaders : createRowsAndHeaders;
    const useNonConverted = timeScale === 'day';

    return tabs.map((tab) => {
        const dataKey = isDaily ? getDailyKey(translations, tab) : tab;

        if (tableDataMap.has(dataKey)) {
            const unsortedTabData = tableDataMap.get(dataKey);
            const sortedTabData = new Map(orderBy([...unsortedTabData.entries()], [], 'desc'));

            const {
                headers,
                rows
            } = createRowsAndHeadersCallback({
                dateSelect,
                featureToggles,
                membership,
                panelType,
                primarySelector,
                onManualDataUpdate,
                tab: dataKey,
                tableData: sortedTabData,
                translations,
                useNonConverted
            });

            return (
                <BasicTable
                    className='graph-data-table'
                    headers={headers}
                    key={tab}
                    rows={rows}
                />
            );
        }

        return (
            <BasicTable
                className='graph-data-table'
                key={tab}
            />
        );
    });
}

function GraphDataTable(props) {
    const {
        tableData,
        timeScale,
        translations
    } = props;

    const tabs = getTabs(tableData, translations, timeScale);

    return (
        <TabLayout
            className='graph-tab-layout'
            tabs={tabs.map((tab) => translations[`ONLINK_${tab.toUpperCase()}`] || translations[tab] || tab)}
        >
            {createTables(tabs, props)}
        </TabLayout>
    );
}

GraphDataTable.propTypes = {
    dateSelect: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    onManualDataUpdate: PropTypes.func,
    panelType: PropTypes.string,
    primarySelector: PropTypes.string,
    tableData: PropTypes.object,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export default GraphDataTable;
