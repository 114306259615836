// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {cloneDeep} from 'lodash';

function ServiceCheckbox(props) {
    const {
        selectedServices,
        serviceId,
        setSelectedServices
    } = props;

    function onChange() {
        const clonedSelectedServices = cloneDeep(selectedServices);

        clonedSelectedServices.has(serviceId) ?
            clonedSelectedServices.delete(serviceId) :
            clonedSelectedServices.add(serviceId);

        setSelectedServices(clonedSelectedServices);
    }

    return (
        <input
            checked={selectedServices.has(serviceId)}
            className='table-row-checkbox'
            onChange={onChange}
            type='checkbox'
        />
    );
}

ServiceCheckbox.propTypes = {
    selectedServices: PropTypes.instanceOf(Set),
    serviceId: PropTypes.string,
    setSelectedServices: PropTypes.func
};

export default ServiceCheckbox;
