// Unpublished Work © 2022-2024 Deere & Company.

import equipmentStatusConstants from 'Ui/constants/equipment-status-constants';
import {formatLocalizedTime} from 'Utils/time-utils';
import accounting from 'accounting';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import moment from 'moment';

function formatStatus(equipment, translations) {
    const statusConstant = equipmentStatusConstants[equipment.status] || {};
    const statusLabel = translations[statusConstant.label] || statusConstant.label || equipment.status;

    return equipment.fleetId !== equipment.fleetInUseId && equipment.fleetInUseName ?
        `${statusLabel} @ ${equipment.fleetInUseName}` :
        statusLabel;
}

function getStatusLabelForEquipmentHistory(history, equipment, translations) {
    const statusConstant = equipmentStatusConstants[history.statusTo.toLowerCase()] || {};

    const statusLabel = translations[statusConstant.label] || statusConstant.label || history.statusTo;
    const canViewHistoryRecordLocation = history.assignedToId !== equipment.fleetId &&
        history.assignedToId !== equipment.shopId;

    return canViewHistoryRecordLocation && history.assignedToName ?
        `${statusLabel} @ ${history.assignedToName}` :
        statusLabel;
}

function formatTimeOrDefault(time) {
    if (time) {
        const date = moment(time).toDate();

        const formattedDate = formatLocalizedTime(date, {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        const formattedTime = formatLocalizedTime(date, {
            hour: 'numeric',
            minute: 'numeric'
        });

        return `${formattedDate} ${formattedTime}`;
    }

    return '';
}

function formatDateOrDefault(time) {
    return time && formatLocalizedTime(moment(time).toDate(), {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    }) || '';
}

function formatNumberOrDefault(number, precision) {
    return !isNullOrUndefined(number) ? accounting.formatNumber(number, precision) : '';
}

function formatArrayToStringOrDefault(array, separator = ' ') {
    return array.filter((name) => !isNullOrUndefined(name)).join(separator) || '';
}

export {
    formatStatus,
    formatDateOrDefault,
    formatTimeOrDefault,
    formatNumberOrDefault,
    getStatusLabelForEquipmentHistory,
    formatArrayToStringOrDefault
};
