// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import WorkBoardJobDetailsSubCard
    from 'OnLabor/my-work/work-board-dialog/work-board-dialog-jobs/work-board-job-details-sub-card';
import {TRUE} from 'Ui/constants/membership-properties';
import {TITLE, USER} from 'Ui/constants/workboard-constants';

function getJobDetailsSubCards(items, showName, showRoute, secondaryJobs, translations) {
    return items.map((item, index) => {
        const showAdditionalJobs = secondaryJobs && secondaryJobs[item.user]?.length > 0;

        const additionalJobs = showAdditionalJobs ? secondaryJobs[item.user]?.map((secondaryJob, index) => {
            return (
                <div key={`${secondaryJob.toString()} ${index}`}>
                    {
                        index > 0 &&
                        <span className='panel-horizontal-divider space'/>
                    }
                    <WorkBoardJobDetailsSubCard
                        item={secondaryJob}
                        showName={TITLE}
                        showRoute={showRoute}
                        translations={translations}
                    />
                </div>
            );
        }) : null;

        return (
            <div key={`${item.toString()} ${index}`}>
                {
                    index > 0 &&
                    <span className='panel-horizontal-divider space'/>
                }
                <WorkBoardJobDetailsSubCard
                    item={item}
                    showName={showName}
                    showRoute={showRoute}
                    translations={translations}
                />
                {
                    showAdditionalJobs &&
                    <>
                        <div className='job-details-label additional-jobs'>
                            {translations.ONLINK_ADDITIONAL_JOBS}
                        </div>
                        <div className='work-board-secondary-job-block'>
                            {additionalJobs}
                        </div>
                    </>
                }
            </div>
        );
    });
}

function WorkBoardJobDetailsCard(props) {
    const {
        secondaryJobs,
        translations,
        items,
        properties
    } = props;
    const showRoute = properties.workboard_show_route === TRUE;

    const [title, showName] = secondaryJobs ? [TITLE, USER] : [USER, TITLE];

    return (
        <div className='job-card-border'>
            <div className='bold-body-text-section-title'>
                {items[0][title]}
            </div>
            {
                secondaryJobs && items[0].secondaryName &&
                <div className='secondary-name'>
                    {`(${items[0].secondaryName})`}
                </div>
            }
            <div
                className='work-board-job-detail-block'
                key={showName}
            >
                {
                    getJobDetailsSubCards(items, showName, showRoute, secondaryJobs, translations)
                }
            </div>
        </div>
    );
}

WorkBoardJobDetailsCard.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    properties: PropTypes.object,
    secondaryJobs: PropTypes.object,
    translations: PropTypes.translations
};

export default WorkBoardJobDetailsCard;
