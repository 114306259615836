// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Tab from 'Ui/components/tab/tab';

function TabMenu(props) {
    return (
        <div className='tab-menu-wrapper'>
            <div className='tab-menu'>
                {
                    props.tabs.map((tab, index) => (
                        <Tab
                            active={props.activeTab === index}
                            key={`${tab}${index}`}
                            onTabClick={() => props.onTabClick(index)}
                            text={tab}
                        />
                    ))
                }
            </div>
        </div>
    );
}

TabMenu.propTypes = {
    activeTab: PropTypes.number,
    onTabClick: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.string)
};

export default TabMenu;
