// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Line} from 'react-chartjs-2';
import {getGraphTimeScaleUnit} from 'Utils/graph-utils';
import {formatLocalizedTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {formatNumber, getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {MINUTES_PER_HOUR} from 'Ui/constants/time-constants';
import {sortBy} from 'lodash';
import moment from 'moment';
import {VIEW_FINANCIAL_DATA} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {connect} from 'react-redux';

const ACTUAL_COST_INDEX = 0;
const ESTIMATED_COST_INDEX = 1;
const ACTUAL_HOURS_INDEX = 2;
const ESTIMATED_HOURS_INDEX = 3;
const OVERTIME_COST_INDEX = 4;
const OVERTIME_HOURS_INDEX = 5;

const ACCUMULATOR_CALLBACKS = [
    ({laborCost}) => laborCost,
    ({estLaborCost}) => estLaborCost,
    ({actualDuration}) => actualDuration / MINUTES_PER_HOUR,
    ({estDuration}) => estDuration / MINUTES_PER_HOUR,
    ({overtimeCost}) => overtimeCost,
    ({overtimeDuration}) => overtimeDuration / MINUTES_PER_HOUR
];

const FILTERED_ACCUMULATOR_CALLBACKS = [
    ({actualDuration}) => actualDuration / MINUTES_PER_HOUR,
    ({estDuration}) => estDuration / MINUTES_PER_HOUR,
    ({overtimeDuration}) => overtimeDuration / MINUTES_PER_HOUR
];

function createDataset(color, label, yAxisID) {
    return {
        backgroundColor: color,
        borderColor: color,
        data: [],
        fill: false,
        label,
        yAxisID
    };
}

function getDatasets(chartDataForSelector, translations, hasViewFinancialDataPermission) {
    const datasetsForChart = hasViewFinancialDataPermission ? [
        createDataset(GRAPH_COLORS[ACTUAL_COST_INDEX], translations.ONLINK_ACTUAL_COST, 'currencyAxis'),
        createDataset(GRAPH_COLORS[ESTIMATED_COST_INDEX], translations.ONLINK_ESTIMATED_COST, 'currencyAxis'),
        createDataset(GRAPH_COLORS[ACTUAL_HOURS_INDEX], translations.ONLINK_ACTUAL_HOURS, 'hourAxis'),
        createDataset(GRAPH_COLORS[ESTIMATED_HOURS_INDEX], translations.ONLINK_EST_LABOR_HOURS, 'hourAxis'),
        createDataset(GRAPH_COLORS[OVERTIME_COST_INDEX], translations.ONLINK_OVERTIME_COST, 'currencyAxis'),
        createDataset(GRAPH_COLORS[OVERTIME_HOURS_INDEX], translations.ONLINK_OVERTIME_HOURS, 'hourAxis')
    ] : [
        createDataset(GRAPH_COLORS[ACTUAL_HOURS_INDEX], translations.ONLINK_ACTUAL_HOURS, 'hourAxis'),
        createDataset(GRAPH_COLORS[ESTIMATED_HOURS_INDEX], translations.ONLINK_EST_LABOR_HOURS, 'hourAxis'),
        createDataset(GRAPH_COLORS[OVERTIME_HOURS_INDEX], translations.ONLINK_OVERTIME_HOURS, 'hourAxis')
    ];

    const dateSelectToIndexMaps = Array.from({
        length: datasetsForChart.length
    }, () => new Map());

    const sortedChartData = sortBy(chartDataForSelector, 'dateSelect');

    return sortedChartData.reduce((datasets, data) => {
        const xValue = moment(data.dateSelect);

        return datasets.reduce((datasetsForDateSelect, dataset, index) => {
            const dateSelectToIndexMap = dateSelectToIndexMaps[index];
            const yValue = hasViewFinancialDataPermission ? ACCUMULATOR_CALLBACKS[index](data) : FILTERED_ACCUMULATOR_CALLBACKS[index](data);

            if (yValue) {
                if (!dateSelectToIndexMap.has(data.dateSelect)) {
                    dateSelectToIndexMap.set(data.dateSelect, dataset.data.length);

                    dataset.data.push({
                        x: xValue,
                        y: yValue
                    });
                } else {
                    const dateSelectIndex = dateSelectToIndexMap.get(data.dateSelect);

                    dataset.data[dateSelectIndex].y += yValue;
                }
            }

            return datasetsForDateSelect;
        }, datasets);
    }, datasetsForChart);
}

function getLabels(chartDataForSelector) {
    const labels = chartDataForSelector.reduce((allLabels, item) => {
        allLabels.add(item.dateSelect);

        return allLabels;
    }, new Set());

    return Array.from(labels);
}

function WorkboardLineChart(props) {
    const {
        chartDataForSelector,
        featureToggles,
        membership,
        timeScale,
        translations,
        isMigrated,
        myJdPermissions
    } = props;

    const timeScaleUnit = getGraphTimeScaleUnit(timeScale);

    const hasViewFinancialDataPermission = React.useMemo(() => isAuthorized({
        myJdPermissions: VIEW_FINANCIAL_DATA
    }, {
        myJdPermissions,
        isMigrated
    }), [isMigrated, myJdPermissions]);

    return (
        <Line
            data={{
                datasets: getDatasets(chartDataForSelector, translations, hasViewFinancialDataPermission),
                labels: getLabels(chartDataForSelector)
            }}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(tooltipItems) {
                                const [{parsed}] = tooltipItems;

                                return timeScale !== 'day' ?
                                    formatLocalizedTime(parsed.x, TIME_UNIT_OPTIONS[timeScaleUnit]) :
                                    translations.ONLINK_TOTAL;
                            },
                            label(tooltipItem) {
                                const {
                                    dataset,
                                    parsed
                                } = tooltipItem;

                                const formattedValue = dataset.yAxisID === 'currencyAxis' ?
                                    getFormattedCurrency(parsed.y, {
                                        currencyPreference: membership.currencyPreference,
                                        featureToggles
                                    }) :
                                    `${formatNumber(parsed.y)} ${translations.HOURS_UOM}`;

                                return `${dataset.label}: ${formattedValue}`;
                            }
                        }
                    }
                },
                scales: {
                    currencyAxis: hasViewFinancialDataPermission && {
                        axis: 'y',
                        position: 'left',
                        ticks: {
                            callback(tickValue) {
                                return getFormattedCurrency(tickValue, {
                                    currencyPreference: membership.currencyPreference,
                                    featureToggles
                                });
                            }
                        },
                        type: 'linear'
                    },
                    dateAxis: {
                        axis: 'x',
                        position: 'bottom',
                        ticks: {
                            callback(tickValue, index, ticks) {
                                return timeScale !== 'day' ?
                                    formatLocalizedTime(moment(ticks[index].value), TIME_UNIT_OPTIONS[timeScaleUnit]) :
                                    translations.ONLINK_TOTAL;
                            }
                        },
                        time: {
                            unit: timeScaleUnit
                        },
                        type: 'time'
                    },
                    hourAxis: {
                        axis: 'y',
                        position: hasViewFinancialDataPermission ? 'right' : 'left',
                        ticks: {
                            callback(tickValue) {
                                return `${formatNumber(tickValue)} ${translations.HOURS_UOM}`;
                            }
                        },
                        type: 'linear'
                    }
                }
            }}
            type='line'
        />
    );
}

WorkboardLineChart.propTypes = {
    chartDataForSelector: PropTypes.arrayOf(PropTypes.object),
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(WorkboardLineChart);
