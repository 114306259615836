// Unpublished Work © 2021-2024 Deere & Company.

function getSearchParams(search) {
    const searchParams = new URLSearchParams(search);

    return {
        equipmentId: searchParams.get('equipmentId'),
        redirect: searchParams.get('redirect'),
        serviceId: searchParams.get('serviceId')
    };
}

export {
    getSearchParams
};
