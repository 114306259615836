// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Panel from 'Ui/components/panel/panel';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import WorkBoardDialog from 'OnLabor/my-work/work-board-dialog/work-board-dialog';
import WorkBoardTileItem from 'OnLabor/my-work/work-board-tile/work-board-tile-item';
import {getConvertedWeatherData} from 'Services/panel-service';
import {fetchEffectData} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import moment from 'moment';
import {findLast} from 'lodash';
import {MYWORK_WORKBOARD_VIEW} from 'Ui/constants/membership-properties';
import {DEGREES_F} from 'Common/constants/data-unit-constants';

function getWorkboardItems(workboardsForDate, weather, translations, setWorkboardInDialog) {
    if (workboardsForDate.length === 0) {
        return (
            <h1 className='no-data-message'>
                {translations.ONLINK_NO_WORKBOARDS_TO_DISPLAY}
            </h1>
        );
    }

    return workboardsForDate.map((workboards, index) => (
        <React.Fragment key={workboards[0].workboardId}>
            {
                index > 0 &&
                <span className='panel-horizontal-divider'/>
            }
            <WorkBoardTileItem
                setWorkboardInDialog={setWorkboardInDialog}
                translations={translations}
                weather={weather}
                workboards={workboards}
            />
        </React.Fragment>
    ));
}

function getWorkboardsForDate(workboardsByDate, currentOnly) {
    if (currentOnly) {
        const now = new Date();
        const dateSelect = formatTime(now, DATE_FORMATS.day);

        const workboardsForDate = workboardsByDate[dateSelect];

        const currentWorkBoard = findLast(workboardsForDate, (workboard) => {
            return moment(workboard.startTime).isSameOrBefore(now, 'hours');
        });

        return currentWorkBoard ? [[currentWorkBoard]] : [];
    }

    return Object.values(workboardsByDate);
}

function WorkBoardsTile(props) {
    const {
        membershipId,
        translations,
        workboardsByDate,
        workboardViewSetting,
        featureToggles,
        unitOfMeasure
    } = props;

    const [loading, setLoading] = React.useState(() => true);
    const [workboardInDialog, setWorkboardInDialog] = React.useState(() => null);
    const [weather, setWeather] = React.useState(() => null);

    const currentOnly = workboardViewSetting === MYWORK_WORKBOARD_VIEW.CURRENT;

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const dateSelect = formatTime(new Date(), DATE_FORMATS.day);
        const twcData = await getConvertedWeatherData('TWC', dateSelect, {
            dataUnit: DEGREES_F,
            featureToggles,
            translations,
            unitOfMeasure
        });

        if (isMounted()) {
            setWeather(twcData);
            setLoading(false);
        }
    }), [membershipId]);

    const workboardsForDate = React.useMemo(() => getWorkboardsForDate(workboardsByDate, currentOnly), [workboardsByDate, currentOnly]);

    return (
        <Panel
            className='my-work-tile'
            title='ONLINK_WORKBOARDS'
            translations={translations}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                {getWorkboardItems(workboardsForDate, weather, translations, setWorkboardInDialog)}
                {
                    workboardInDialog &&
                    <WorkBoardDialog
                        closeDialog={() => setWorkboardInDialog(null)}
                        translations={translations}
                        workboardInDialog={workboardInDialog}
                    />
                }
            </LoadingWrapper>
        </Panel>
    );
}

WorkBoardsTile.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membershipId: PropTypes.string,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    workboardsByDate: PropTypes.object,
    workboardViewSetting: PropTypes.string
};

export default WorkBoardsTile;
