// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import PhoneInput from 'Ui/components/common/form/phone-input';
import {isPossiblePhoneNumber} from 'libphonenumber-js/min';

function isPhoneNumberValid(phoneNumber, countryCallingCode) {
    return phoneNumber.length === 0 || isPossiblePhoneNumber(`+${countryCallingCode}${phoneNumber}`);
}

function ValidationPhoneInput(props) {
    const {
        countryCallingCode,
        error,
        name,
        setValid,
        value,
        ...otherProps
    } = props;

    const [isValid, setIsValid] = React.useState(() => isPhoneNumberValid(value, countryCallingCode));

    React.useEffect(() => {
        const valid = isPhoneNumberValid(value, countryCallingCode);

        setIsValid(valid);
        setValid(name, valid);

        return () => {
            setValid(name, true);
        };
    }, [countryCallingCode, value]);

    return (
        <PhoneInput
            {...otherProps}
            countryCallingCode={countryCallingCode}
            error={isValid ? null : error}
            value={value}
        />
    );
}

ValidationPhoneInput.propTypes = {
    countryCallingCode: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    setValid: PropTypes.func.isRequired,
    value: PropTypes.stringOrNumber.isRequired
};

export default ValidationPhoneInput;
