// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ServiceStatus from 'OnEquip/equipment/common/data-table/service-status';
import {DECLINED} from 'Common/constants/service-status';
import accounting from 'accounting';

function Duration(props) {
    const {
        service,
        translations
    } = props;
    const {
        actualDuration,
        status
    } = service;

    if (status === DECLINED) {
        return (
            <ServiceStatus
                status={status}
                translations={translations}
            />
        );
    }

    return accounting.formatNumber(actualDuration, 0);
}

Duration.propTypes = {
    service: PropTypes.shape({
        actualDuration: PropTypes.number,
        status: PropTypes.string
    }),
    translations: PropTypes.translations
};

export default Duration;
