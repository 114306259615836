// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import {joinClassNames} from 'Utils/html-utils';
import 'Sass/persistent-header/ph-dropdown.scss';

function DropdownMenu(props) {
    const {
        anchorRef,
        children,
        className,
        closeDropdown,
        history,
        isOpen
    } = props;

    const onClickAway = React.useCallback((e) => {
        e.preventDefault();

        closeDropdown();
    }, [closeDropdown]);

    React.useEffect(() => {
        if (history.action !== 'REPLACE') {
            closeDropdown();
        }
    }, [history.location.pathname]);

    return (
        <Popper
            anchorEl={anchorRef.current}
            componentsProps={{
                root: {
                    className: joinClassNames('ph-popper dropdown-menu', className)
                }
            }}
            modifiers={[{
                name: 'flip'
            }]}
            open={isOpen}
            placement='bottom-start'
        >
            <ClickAwayListener
                mouseEvent='onClick'
                onClickAway={onClickAway}
                touchEvent='onTouchEnd'
            >
                <div className='dropdown-menu-content'>
                    {children}
                </div>
            </ClickAwayListener>
        </Popper>
    );
}

DropdownMenu.propTypes = {
    anchorRef: PropTypes.reference,
    children: PropTypes.node,
    className: PropTypes.string,
    closeDropdown: PropTypes.func,
    history: PropTypes.history,
    isOpen: PropTypes.bool
};

export default withRouter(DropdownMenu);
