// Unpublished Work © 2022-2024 Deere & Company.

import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import ValidationDate from 'Ui/components/common/form/validation-date';
import {Datepicker} from '@deere/form-controls';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import moment from 'moment';
import {DATE_FORMATS} from 'Utils/time-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';

function TimeOffInputs(props) {
    const {
        onChange,
        setValid,
        translations,
        values,
        usersRef,
        statusOptionsRef
    } = props;

    const {
        fromDateError,
        toDateError
    } = useDeepMemo(() => {
        const fromDateError = isNullOrUndefined(values.fromDate) ? translations.REQUIRED_FIELD_TEXT : '';

        let toDateError = isNullOrUndefined(values.toDate) ? translations.REQUIRED_FIELD_TEXT : '';

        if (values.toDate?.isBefore(values.fromDate, 'day')) {
            toDateError = translations.ONLINK_DATES_OUT_OF_ORDER;
        }

        return {
            fromDateError,
            toDateError
        };
    }, [values.fromDate, values.toDate]);

    const locale = getLanguagePreference();

    return (
        <>
            <div className='settings-group mobile'>
                <ValidationMultiSelect
                    component={SortedMultiSelect}
                    defaultSelectLabel={translations.ONLINK_SELECT_ASSIGNEE}
                    error={translations.REQUIRED_FIELD_TEXT}
                    items={usersRef.current}
                    label={translations.NAME}
                    name='name'
                    onChange={(ids) => {
                        onChange('name', ids[0]);
                    }}
                    required={true}
                    selectedIds={[values.name]}
                    setValid={setValid}
                    tabIndex={0}
                />
                <ValidationMultiSelect
                    component={SortedMultiSelect}
                    defaultSelectLabel={translations.ONLINK_SELECT_LEAVE_TYPE}
                    error={translations.REQUIRED_FIELD_TEXT}
                    items={statusOptionsRef.current}
                    label={translations.ONLINK_LEAVE_TYPE}
                    name='leaveType'
                    onChange={(ids) => {
                        onChange('leaveType', ids[0]);
                    }}
                    required={true}
                    selectedIds={[values.leaveType]}
                    setValid={setValid}
                    tabIndex={0}
                />
            </div>
            <div className='settings-group mobile'>
                <ValidationDate
                    component={Datepicker}
                    dateFormat='L'
                    error={fromDateError}
                    label={translations.ONLINK_FROM}
                    large={true}
                    locale={locale}
                    name='fromDate'
                    onChange={(date) => {
                        onChange('fromDate', moment(date.format(DATE_FORMATS.day)));
                    }}
                    required={true}
                    selected={values.fromDate}
                    setValid={setValid}
                />
                <ValidationDate
                    component={Datepicker}
                    dateFormat='L'
                    error={toDateError}
                    label={translations.ONLINK_TO}
                    large={true}
                    locale={locale}
                    name='toDate'
                    onChange={(date) => {
                        onChange('toDate', moment(date.format(DATE_FORMATS.day)));
                    }}
                    required={true}
                    selected={values.toDate}
                    setValid={setValid}
                />
            </div>
        </>
    );
}

TimeOffInputs.propTypes = {
    onChange: PropTypes.func,
    setValid: PropTypes.func,
    statusOptionsRef: PropTypes.object,
    translations: PropTypes.translations,
    usersRef: PropTypes.object,
    values: PropTypes.object
};

export default TimeOffInputs;
