// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {THRESHOLD_CLASSNAMES, WARNING_BATTERY_THRESHOLD, EXTREME_BATTERY_THRESHOLD} from 'Ui/constants/threshold-constants';

const colors = {
    extremeThreshold: '#e7040f',
    warningThreshold: '#ffb700',
    normalThreshold: '#19a974'
};

function applyThreshold(config, value) {
    const {thresholds} = config;

    if (thresholds) {
        if (value > thresholds.highExtreme.value) {
            return {
                color: colors.extremeThreshold,
                isHigh: true
            };
        } else if (value > thresholds.highWarning.value) {
            return {
                color: colors.extremeThreshold,
                isHigh: true
            };
        } else if (value > thresholds.highNormal.value) {
            return {
                color: colors.warningThreshold,
                isHigh: true
            };
        } else if (value < thresholds.lowExtreme.value) {
            return {
                color: colors.extremeThreshold,
                isLow: true
            };
        } else if (value < thresholds.lowWarning.value) {
            return {
                color: colors.extremeThreshold,
                isLow: true
            };
        } else if (value < thresholds.lowNormal.value) {
            return {
                color: colors.warningThreshold,
                isLow: true
            };
        }
    }

    return {
        color: colors.normalThreshold
    };
}

function getValueWithThreshold(config, value, displayValue) {
    if (!config) {
        return displayValue;
    }

    let updatedDisplayValue = displayValue;

    const thresholdResult = applyThreshold(config, value);

    if (thresholdResult.isHigh) {
        updatedDisplayValue = `${displayValue} ▲`;
    } else if (thresholdResult.isLow) {
        updatedDisplayValue = `${displayValue} ▼`;
    }

    return (
        <span
            className='threshold-value'
            style={{
                color: thresholdResult.color
            }}
        >
            {updatedDisplayValue}
        </span>
    );
}

function getElectricMowerThreshold(batteryLevel) {
    if (batteryLevel < EXTREME_BATTERY_THRESHOLD) {
        return {
            backgroundClassName: THRESHOLD_CLASSNAMES.extremeThreshold,
            textClassName: THRESHOLD_CLASSNAMES.extremeThresholdText
        };
    }

    if (batteryLevel < WARNING_BATTERY_THRESHOLD) {
        return {
            backgroundClassName: THRESHOLD_CLASSNAMES.warningThreshold,
            textClassName: THRESHOLD_CLASSNAMES.warningThresholdText
        };
    }

    return {
        backgroundClassName: THRESHOLD_CLASSNAMES.normalThreshold,
        textClassName: THRESHOLD_CLASSNAMES.normalThresholdText
    };
}

export {
    applyThreshold,
    getElectricMowerThreshold,
    getValueWithThreshold
};
