// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';

function ListItem(props) {
    const {
        active,
        icon,
        onClick,
        text
    } = props;

    const className = clsx('list-item subtitle-1', {
        active
    });

    return (
        <Tooltip
            placement='bottom'
            title={text}
        >
            <div
                className={className}
                onClick={onClick}
            >
                {text}
                {icon}
            </div>
        </Tooltip>
    );
}

ListItem.propTypes = {
    active: PropTypes.bool,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    text: PropTypes.string
};

export default ListItem;
