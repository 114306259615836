// Unpublished Work © 2022-2024 Deere & Company.

import {getEquipmentJobHistory} from 'Services/equipment-service';
import {dateCompare, genericServiceCompare} from 'Ui/models/maintenance';
import {formatDateOrDefault, formatNumberOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {formatRouteWithCustomHoles} from 'Ui/models/job-model';

export async function getRowData(equipment, translations) {
    if (equipment?.equipmentId) {
        const {jobHistory} = await getEquipmentJobHistory(equipment.equipmentId);

        return jobHistory.map((historyEntry) => ({
            ...historyEntry,
            formattedDateSelect: formatDateOrDefault(historyEntry.dateSelect),
            route: formatRouteWithCustomHoles(historyEntry.route, historyEntry.routeCustomDefn, translations),
            actualDuration: formatNumberOrDefault(historyEntry.actualDuration, 1)
        }));
    }

    return [];
}

export function getColumns(translations) {
    return [
        {
            Header: translations.DATE,
            accessor(row) {
                return row;
            },
            id: 'formattedDateSelect',
            width: 120,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'dateSelect');
            },
            Cell(row) {
                return row.original.formattedDateSelect;
            }
        },
        {
            Header: translations.ONLINK_ROUTE,
            accessor: 'route'
        },
        {
            Header: translations.jobs_job,
            accessor: 'jobTitle'
        },
        {
            Header: translations.OPERATOR,
            accessor: 'operator'
        },
        {
            Header: translations.ONLINK_JOB_DURATION,
            accessor: 'actualDuration',
            className: 'right-aligned',
            headerClassName: 'right-aligned'
        }
    ];
}
