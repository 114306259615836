// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Input, MultiSelect} from '@deere/form-controls';
import appKeys from 'Ui/components/common/app-keys';
import permissions from 'Common/constants/permissions';
import InputList from 'Ui/components/common/form/input-list';
import SwitchInput from 'Ui/components/common/form/switch-input';
import ValidationInput from 'Ui/components/common/form/validation-input';
import EquipmentTypeInput from 'Ui/components/settings/common/form/equipment-type-input';
import PasswordInput from 'Ui/components/settings/common/form/password-input';
import ProfilePicInput from 'Ui/components/settings/common/form/profile-pic-input';
import ValidationPhoneInput from 'Ui/components/settings/common/form/validation-phone-input';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {hasPermissions} from 'Common/utils/permission-handler';
import {VALID_HEX_COLORS} from 'Common/constants/valid-hex-color';

const SHARED_MULTISELECT_PROPS = {
    className: 'multiselect',
    hideFilter: true,
    tabIndex: 0
};

function getConstants(onNonEventChange, onChange, user, activeAppUserId, properties, translations) {
    const colorGroupItems = [
        {
            id: 'none',
            Icon: <span>{capitalizeFirstLetter(translations.NONE)}</span>
        },
        ...VALID_HEX_COLORS
    ];

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        onChange,
        tabIndex: 0
    };

    const sharedSwitchProps = {
        onChange: onNonEventChange,
        translations
    };

    const isCurrentUser = user && activeAppUserId === user.appUserId;
    const valueGreaterThanOrEqual = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    });

    return {
        colorGroupItems,
        sharedInputProps,
        sharedSwitchProps,
        isCurrentUser,
        valueGreaterThanOrEqual
    };
}

function EditableUserSettingsInputs(props) {
    const {
        activeAppUserId,
        credentials,
        departmentItems,
        equipmentAreaItems,
        equipmentTypeItems,
        membership,
        setCountryCode,
        setFiles,
        setUserValues,
        setValid,
        translations,
        user,
        userRoleItems,
        userValues
    } = props;

    function onChange(event) {
        const {
            name, value
        } = event.target;

        setUserValues((prevUserValues) => ({
            ...prevUserValues,
            [name]: value
        }));
    }

    function onNonEventChange(name, value) {
        onChange({
            target: {
                name,
                value
            }
        });
    }

    const {
        colorGroupItems,
        sharedInputProps,
        sharedSwitchProps,
        isCurrentUser,
        valueGreaterThanOrEqual
    } = getConstants(onNonEventChange, onChange, user, activeAppUserId, membership.properties, translations);

    const {isMigrated} = membership;

    const propsBasedOnToggle = {
        defaultCountry: membership.country
    };

    return (
        <form className='settings-body'>
            <div className='settings-group mobile'>
                <ValidationInput
                    {...sharedInputProps}
                    autoComplete='given-name'
                    autoFocus={true}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.FIRST_NAME}
                    name='firstName'
                    required={true}
                    setValid={setValid}
                    type='text'
                    value={userValues.firstName}
                />
                <ValidationInput
                    {...sharedInputProps}
                    autoComplete='family-name'
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.LAST_NAME}
                    name='lastName'
                    required={true}
                    setValid={setValid}
                    type='text'
                    value={userValues.lastName}
                />
            </div>
            {
                <div className='settings-group mobile'>
                    <ProfilePicInput
                        profilePicUrl={userValues.profilePicUrl}
                        setFiles={setFiles}
                        translations={translations}
                    />

                    <MultiSelect
                        className='multiselect color'
                        containerHeight={500}
                        hideFilter={true}
                        items={colorGroupItems}
                        label={translations.ONLINK_GROUP_COLOR}
                        multiple={false}
                        name='color'
                        onChange={(selectedIds) => {
                            const selectedColorId = selectedIds[0];

                            if (selectedColorId) {
                                onNonEventChange('color', selectedColorId);
                            }
                        }}
                        selectedIds={[userValues.color]}
                        tabIndex={0}
                    />
                </div>
            }
            <div className='settings-group mobile'>
                <ValidationPhoneInput
                    {...propsBasedOnToggle}
                    countryCallingCode={userValues.countryCallingCode}
                    enableSearch={true}
                    error={translations.ONLINK_PHONE_VALIDATE}
                    label={translations.ONLINK_PHONE}
                    name='phone'
                    onChange={(phoneNumber, callingCode, callingCodeCountry) => {
                        onNonEventChange('phone', phoneNumber);

                        onNonEventChange('countryCallingCode', callingCode);
                        setCountryCode(callingCodeCountry);
                    }}
                    placeholder=''
                    setValid={setValid}
                    value={userValues.phone}
                />
                <ValidationInput
                    {...sharedInputProps}
                    autoComplete='email'
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        typeMismatch: translations.ONLINK_EMAIL_VALIDATE
                    }}
                    label={translations.my_team_email}
                    name='email'
                    required={true}
                    setValid={setValid}
                    type='email'
                    value={userValues.email}
                />
            </div>
            <div className='settings-group mobile'>
                {
                    (!user || isCurrentUser || hasPermissions(credentials, permissions.EDIT_APP_ACCESS)) &&
                    <PasswordInput
                        {...sharedInputProps}
                        confirmPassword={userValues.confirmPassword}
                        errors={{
                            valueMissing: translations.REQUIRED_FIELD_TEXT
                        }}
                        password={userValues.password}
                        required={!user}
                        setValid={setValid}
                        translations={translations}
                    />
                }
            </div>
            <div className='settings-group mobile'>
                <Input
                    {...sharedInputProps}
                    autoComplete='organization-title'
                    debounceTimeout={window.props.debounceTimeout}
                    label={translations.TITLE}
                    name='title'
                    type='text'
                    value={userValues.title}
                />
                <MultiSelect
                    {...SHARED_MULTISELECT_PROPS}
                    defaultSelectLabel={translations.ONLINK_SELECT_ROLE}
                    disabled={!isMigrated && !hasPermissions(credentials, permissions.EDIT_ROLES)}
                    items={userRoleItems}
                    label={translations['USER_MANAGEMENT.COLUMN_HEADER.ROLE']}
                    multiple={false}
                    name='userRoleId'
                    onChange={(selectedIds) => onNonEventChange('userRoleId', selectedIds[0])}
                    selectedIds={[userValues.userRoleId]}
                />
            </div>
            <div className='settings-group mobile switch start-margin end-margin'>
                <SwitchInput
                    {...sharedSwitchProps}
                    checked={userValues.inactive}
                    name='inactive'
                    onLabel={translations.inactive}
                />
            </div>
            <div className='settings-group mobile'>
                {
                    !isCurrentUser &&
                    (isMigrated || hasPermissions(credentials, permissions.EDIT_APP_ACCESS)) &&
                    <MultiSelect
                        {...SHARED_MULTISELECT_PROPS}
                        allItemsLabel={translations.ONLINK_ALL_APPS}
                        defaultSelectLabel={translations.ONLINK_SELECT_APPS}
                        items={appKeys}
                        label={translations.ONLINK_APPLICATION_ACCESS}
                        multiple={true}
                        multipleItemsSelectedLabel={translations.ONLINK_APPS}
                        name='appKeys'
                        onChange={(selectedIds) => onNonEventChange('appKeys', selectedIds)}
                        selectedIds={userValues.appKeys}
                    />
                }
                <MultiSelect
                    {...SHARED_MULTISELECT_PROPS}
                    allItemsLabel={translations.ONLINK_ALL_DEPARTMENTS}
                    defaultSelectLabel={translations.ONLINK_SELECT_DEPARTMENTS}
                    items={departmentItems}
                    label={translations.ONLINK_DEPARTMENTS}
                    multiple={true}
                    multipleItemsSelectedLabel={translations.ONLINK_DEPARTMENTS}
                    name='departmentIds'
                    onChange={(selectedIds) => onNonEventChange('departmentIds', selectedIds)}
                    selectedIds={departmentItems.length > 0 ? userValues.departmentIds : []}
                />
            </div>
            <InputList
                addItemLabel={translations.ONLINK_ADD_ELIGIBILITY}
                headerLabel={translations.ONLINK_EQUIPMENT_ELIGIBILITY}
                listClassName='equipment-eligibility end-margin'
                onAdd={(event) => {
                    event.preventDefault();

                    const [firstEquipmentType] = equipmentTypeItems;

                    onNonEventChange('equipmentTypes', userValues.equipmentTypes.concat({
                        equipmentTypeId: firstEquipmentType.id,
                        membershipId: user?.membershipId || membership.membershipId,
                        name: firstEquipmentType.title
                    }));
                }}
                onDelete={(event, index) => {
                    event.preventDefault();

                    const filteredEquipmentTypes = userValues.equipmentTypes.filter((equipmentType, i) => i !== index);

                    onNonEventChange('equipmentTypes', filteredEquipmentTypes);
                }}
            >
                {
                    userValues.equipmentTypes.map((equipmentType, index) => (
                        <EquipmentTypeInput
                            equipmentAreaItems={equipmentAreaItems}
                            equipmentTypeItems={equipmentTypeItems}
                            equipmentTypeName={`equipmentType${index}`}
                            equipmentTypes={userValues.equipmentTypes}
                            index={index}
                            key={`${equipmentType.equipmentTypeId}${equipmentType.equipmentAreaId}${index}`}
                            onChange={(equipmentTypes) => onNonEventChange('equipmentTypes', equipmentTypes)}
                            setValid={setValid}
                            translations={translations}
                        />
                    ))
                }
            </InputList>
            {
                (isMigrated || hasPermissions(credentials, [permissions.VIEW_HOURLY_RATES, permissions.VIEW_SALARIES])) &&
                <div className='settings-group mobile'>
                    <ValidationInput
                        {...sharedInputProps}
                        errors={{
                            rangeUnderflow: valueGreaterThanOrEqual
                        }}
                        label={translations.ONLINK_SALARY_WEEKLY}
                        min={0}
                        name='salaryWeekly'
                        setValid={setValid}
                        step='any'
                        type='number'
                        value={userValues.salaryWeekly}
                    />
                    <ValidationInput
                        {...sharedInputProps}
                        errors={{
                            rangeUnderflow: valueGreaterThanOrEqual
                        }}
                        label={translations.ONLINK_SALARY_MONTHLY}
                        min={0}
                        name='salaryMonthly'
                        setValid={setValid}
                        step='any'
                        type='number'
                        value={userValues.salaryMonthly}
                    />
                </div>
            }
            {
                (isMigrated || hasPermissions(credentials, permissions.VIEW_HOURLY_RATES)) &&
                <>
                    <div className='settings-group mobile'>
                        <ValidationInput
                            {...sharedInputProps}
                            errors={{
                                rangeUnderflow: valueGreaterThanOrEqual
                            }}
                            label={translations.ONLINK_HOURLY_RATE}
                            min={0}
                            name='hourlyRate'
                            setValid={setValid}
                            step='any'
                            type='number'
                            value={userValues.hourlyRate}
                        />
                        <ValidationInput
                            {...sharedInputProps}
                            errors={{
                                rangeUnderflow: valueGreaterThanOrEqual
                            }}
                            label={translations.ONLINK_OVERTIME_RATE}
                            min={0}
                            name='overtimeRate'
                            setValid={setValid}
                            step='any'
                            type='number'
                            value={userValues.overtimeRate}
                        />
                    </div>
                    <div className='settings-group mobile'>
                        <ValidationInput
                            {...sharedInputProps}
                            errors={{
                                rangeUnderflow: valueGreaterThanOrEqual
                            }}
                            label={translations.ONLINK_BENEFITS_PERCENTAGE}
                            min={0}
                            name='benefitsPercent'
                            setValid={setValid}
                            step='any'
                            type='number'
                            value={userValues.benefitsPercent}
                        />
                        <ValidationInput
                            {...sharedInputProps}
                            errors={{
                                rangeUnderflow: valueGreaterThanOrEqual
                            }}
                            label={translations.ONLINK_TAXES_PERCENTAGE}
                            min={0}
                            name='taxPercent'
                            setValid={setValid}
                            step='any'
                            type='number'
                            value={userValues.taxPercent}
                        />
                    </div>
                </>
            }
        </form>
    );
}

EditableUserSettingsInputs.propTypes = {
    activeAppUserId: PropTypes.string,
    credentials: PropTypes.shape({
        permissionMap: PropTypes.legacyPermissions,
        superUser: PropTypes.bool
    }),
    departmentItems: PropTypes.multiSelectList,
    equipmentAreaItems: PropTypes.multiSelectList,
    equipmentTypeItems: PropTypes.multiSelectList,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setCountryCode: PropTypes.func,
    setFiles: PropTypes.func,
    setUserValues: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    user: PropTypes.user,
    userRoleItems: PropTypes.multiSelectList,
    userValues: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership
    };
}

export default connect(mapStateToProps)(EditableUserSettingsInputs);
