// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {BODY_FONT_STYLE, LABEL_FONT_STYLE} from 'Ui/constants/read-only/font-style';

function ReadOnlyValue(props) {
    const {
        label,
        readOnlyLabelClassName,
        readOnlyLabelProps,
        readOnlyValueClassName,
        readOnlyValueProps,
        value,
        ...containerProps
    } = props;

    const labelProps = React.useMemo(() => ({
        className: readOnlyLabelClassName,
        ...readOnlyLabelProps
    }), [readOnlyLabelClassName, readOnlyLabelProps]);
    const valueProps = React.useMemo(() => ({
        className: readOnlyValueClassName,
        ...readOnlyValueProps
    }), [readOnlyValueClassName, readOnlyValueProps]);

    return (
        <Stack
            direction='column'
            {...containerProps}
        >
            {
                label &&
                <Typography
                    marginBottom='2px'
                    noWrap={true}
                    {...labelProps}
                    {...LABEL_FONT_STYLE}
                >
                    {label}
                </Typography>
            }
            <Typography
                {...valueProps}
                {...BODY_FONT_STYLE}
            >
                {value}
            </Typography>
        </Stack>
    );
}

ReadOnlyValue.propTypes = {
    label: PropTypes.string,
    readOnlyLabelClassName: PropTypes.string,
    readOnlyLabelProps: PropTypes.object,
    readOnlyValueClassName: PropTypes.string,
    readOnlyValueProps: PropTypes.object,
    value: PropTypes.stringOrNumber
};

export default ReadOnlyValue;
