// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import ValidationInput from 'Ui/components/common/form/validation-input';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {MultiSelect} from '@deere/form-controls';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {PPE_ICONS} from 'Ui/constants/labor-constants';
import {
    IconChaps,
    IconEyeProtection,
    IconGloves,
    IconHardHat,
    IconHearingProtection,
    IconProtectiveFootwear,
    IconRespirator,
    IconSafetySuit,
    IconSunHat,
    IconSunscreen,
    IconVest
} from '@deere/icons';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import PropTypes from 'Utils/prop-type-utils';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const ppeIconDropdownOptions = [
    {
        title: 'Chaps',
        id: PPE_ICONS.CHAPS,
        Icon: <IconChaps iconChaps={ICON_STYLE}/>
    },
    {
        title: 'Eye Protection',
        id: PPE_ICONS.EYE_PROTECTION,
        Icon: <IconEyeProtection iconEyeProtection={ICON_STYLE}/>
    },
    {
        title: 'Gloves',
        id: PPE_ICONS.GLOVES,
        Icon: <IconGloves iconGloves={ICON_STYLE}/>
    },
    {
        title: 'Hard Hat',
        id: PPE_ICONS.HARD_HAT,
        Icon: <IconHardHat iconHardHat={ICON_STYLE}/>
    },
    {
        title: 'Hearing Protection',
        id: PPE_ICONS.HEARING_PROTECTION,
        Icon: <IconHearingProtection iconHearingProtection={ICON_STYLE}/>
    },
    {
        title: 'Protective Footwear',
        id: PPE_ICONS.PROTECTIVE_FOOTWEAR,
        Icon: <IconProtectiveFootwear iconProtectiveFootwear={ICON_STYLE}/>
    },
    {
        title: 'Respirator',
        id: PPE_ICONS.RESPIRATOR,
        Icon: <IconRespirator iconRespirator={ICON_STYLE}/>
    },
    {
        title: 'Safety Suit',
        id: PPE_ICONS.SAFETY_SUIT,
        Icon: <IconSafetySuit iconSafetySuit={ICON_STYLE}/>
    },
    {
        title: 'Vest',
        id: PPE_ICONS.VEST,
        Icon: <IconVest iconVest={ICON_STYLE}/>
    },
    {
        title: 'Sun Hat',
        id: PPE_ICONS.SUN_HAT,
        Icon: <IconSunHat iconSunHat={ICON_STYLE}/>
    },
    {
        title: 'Sunscreen',
        id: PPE_ICONS.SUNSCREEN,
        Icon: <IconSunscreen iconSunscreen={ICON_STYLE}/>
    }
];
const SHARED_MULTISELECT_PROPS = {
    className: 'multiselect',
    tabIndex: 0
};

function EditableJobSettings(props) {
    const {
        allData,
        loading,
        setValid,
        setValues,
        translations,
        values
    } = props;

    function onChange(event) {
        const {
            name, value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onNonEventChange(name, value) {
        onChange({
            target: {
                name,
                value
            }
        });
    }

    return (
        <form className='job-dialog-form'>
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                <div className='settings-group mobile'>
                    <ValidationInput
                        autoFocus={true}
                        debounceTimeout={window.props.debounceTimeout}
                        errors={{
                            valueMissing: translations.REQUIRED_FIELD_TEXT
                        }}
                        label={translations.NAME}
                        name='title'
                        onChange={onChange}
                        required={true}
                        setValid={setValid}
                        tabIndex={0}
                        type='text'
                        value={values.title}
                    />
                    <ValidationInput
                        debounceTimeout={window.props.debounceTimeout}
                        label={translations.ONLINK_SECONDARY_NAME}
                        name='secondaryName'
                        onChange={onChange}
                        setValid={setValid}
                        tabIndex={0}
                        type='text'
                        value={values.secondaryName}
                    />
                </div>
                <div className='settings-group mobile'>
                    <ValidationMultiSelect
                        {...SHARED_MULTISELECT_PROPS}
                        defaultSelectLabel={translations.PRODUCTS_SELECT_CATEGORY}
                        error={translations.REQUIRED_FIELD_TEXT}
                        items={allData.jobCategories}
                        label={translations.PRODUCT_TYPE}
                        multiple={false}
                        name='jobCategory'
                        onChange={(selectedId) => onNonEventChange('jobCategory', selectedId[0])}
                        selectedIds={[values.jobCategory]}
                        setValid={setValid}
                    />
                    <MultiSelect
                        {...SHARED_MULTISELECT_PROPS}
                        defaultSelectLabel={capitalizeFirstLetter(translations.NONE)}
                        items={allData.equipmentAreas}
                        label={translations.AREA}
                        multiple={false}
                        name='equipmentAreaId'
                        onChange={(selectedId) => onNonEventChange('equipmentAreaId', selectedId[0])}
                        selectedIds={[values.equipmentAreaId]}
                    />
                </div>
                <div className='settings-group mobile'>
                    <ValidationInput
                        errors={{
                            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} 24`,
                            rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} 0`
                        }}
                        label={translations.ONLINK_ESTIMATED_DURATION_HOURS}
                        max={24}
                        min={0}
                        name='estDuration'
                        onChange={onChange}
                        setValid={setValid}
                        step='any'
                        tabIndex={0}
                        type='number'
                        value={values.estDuration}
                    />
                </div>
                <div className='settings-block-row'>
                    <MultiSelect
                        {...SHARED_MULTISELECT_PROPS}
                        allItemsLabel={translations.ONLINK_ALL_PPE}
                        className='multiselect hide-all-option'
                        containerHeight={300}
                        defaultSelectLabel={translations.ONLINK_SELECT_PPE}
                        items={ppeIconDropdownOptions}
                        label={translations.ONLINK_PERSONAL_PROTECTION}
                        multiple={true}
                        multipleItemsSelectedLabel={translations.ONLINK_PPE}
                        name='ppeList'
                        onChange={(selectedIds) => onNonEventChange('ppeList', selectedIds)}
                        selectedIds={values.ppeList}
                    />
                </div>
                <div className='settings-block-row'>
                    <MultiSelect
                        {...SHARED_MULTISELECT_PROPS}
                        allItemsLabel={translations.ONLINK_ALL_EQUIPMENT_TYPES}
                        className='multiselect hide-all-option'
                        containerHeight={300}
                        defaultSelectLabel={translations.ONLINK_SELECT_EQUIPMENT_TYPES}
                        items={allData.equipmentTypes}
                        label={translations.MACHINE_INSIGHTS_MACHINE_TYPES}
                        multiple={true}
                        multipleItemsSelectedLabel={translations.MACHINE_INSIGHTS_MACHINE_TYPES}
                        name='equipmentTypes'
                        onChange={(selectedIds) => onNonEventChange('equipmentTypes', selectedIds)}
                        selectedIds={values.equipmentTypes}
                    />
                </div>
                <div className='settings-group mobile'>
                    <SwitchInput
                        checked={values.inactive}
                        name='inactive'
                        onChange={onNonEventChange}
                        onLabel={translations.inactive}
                        translations={translations}
                    />
                </div>
            </LoadingWrapper>
        </form>
    );
}

EditableJobSettings.propTypes = {
    allData: PropTypes.object,
    loading: PropTypes.bool,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default EditableJobSettings;
