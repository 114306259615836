// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ForecastTable from 'Ui/components/graph/weather/forecast-table';
import {Line} from 'react-chartjs-2';
import {formatLocalizedTime} from 'Utils/time-utils';
import moment from 'moment';
import NoDataMessage from 'Ui/components/common/message/no-data-message';

function ForecastChart(props) {
    const {
        data,
        tableClassName,
        tableData,
        timeUnit,
        timeUnitOptions,
        translations
    } = props;

    return (
        <NoDataMessage
            hasData={data.length > 0}
            noDataMessage={translations.ONLINK_NO_DATA}
        >
            <div className='chart-container'>
                <Line
                    data={{
                        datasets: data
                    }}

                    options={{
                        elements: {
                            line: {
                                tension: 0.4
                            }
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    title(tooltipTitle) {
                                        const {raw} = tooltipTitle[0];

                                        return formatLocalizedTime(raw.x, {
                                            month: 'short',
                                            year: 'numeric',
                                            day: 'numeric',
                                            hour: 'numeric'
                                        });
                                    },
                                    label(tooltipItem) {
                                        const {
                                            dataset, raw
                                        } = tooltipItem;

                                        return `${dataset.label}: ${raw.y} ${dataset.unitConfig}`;
                                    }
                                }
                            }
                        },
                        scales: {
                            leftYAxis: {
                                axis: 'y',
                                id: 'leftYAxis',
                                position: 'left',
                                ticks: {
                                    callback: (label) => `${label} ${data[0].unitConfig}`
                                },
                                type: 'linear'
                            },
                            rightYAxis: {
                                axis: 'y',
                                id: 'rightYAxis',
                                position: 'right',
                                ticks: {
                                    callback: (label) => `${label}${data[2].unitConfig}`
                                },
                                type: 'linear'
                            },
                            timeAxis: {
                                axis: 'x',
                                position: 'bottom',
                                time: {
                                    unit: timeUnit
                                },
                                ticks: {
                                    callback: (label, index, ticks) => {
                                        return formatLocalizedTime(moment(ticks[index].value), timeUnitOptions);
                                    }
                                },
                                type: 'time'
                            }
                        }
                    }}
                    type='line'
                />
            </div>
            <div className='graph-table-container'>
                {
                    tableData &&
                    <ForecastTable
                        tableClassName={tableClassName}
                        tableData={tableData}
                    />
                }
            </div>
        </NoDataMessage>
    );
}

ForecastChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    tableClassName: PropTypes.string,
    tableData: PropTypes.object,
    timeUnit: PropTypes.string,
    timeUnitOptions: PropTypes.object,
    translations: PropTypes.translations
};

export default ForecastChart;
