// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

function isValueIncluded(value, search) {
    return !isNullOrUndefined(value) && value.toString().toLowerCase().includes(search);
}

function filterData(data, searchFields, search) {
    if (searchFields) {
        return data.filter((item) => Object.keys(item).some((key) =>
            searchFields.includes(key) &&
            isValueIncluded(item[key], search)
        ));
    }

    return data.filter((item) => Object.keys(item).some((key) => isValueIncluded(item[key], search)));
}

export default function SearchFilter(Component) {
    const searchFilter = (props) => {
        const [search, setSearch] = React.useState(() => '');

        function applySearch(data, searchFields) {
            const trimmedSearch = search.trim();

            if (trimmedSearch) {
                return filterData(data, searchFields, trimmedSearch.toLowerCase());
            }

            return data;
        }

        return (
            <Component
                {...props}
                applySearch={applySearch}
                onSearchChange={(event) => setSearch(event.target.value)}
                search={search}
            />
        );
    };

    searchFilter.SearchComponent = Component;

    return searchFilter;
}
