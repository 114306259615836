// Unpublished Work © 2021-2024 Deere & Company.
import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getWorkboard(workboardId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/workboards/${workboardId}`);

    return response.data;
}

async function getWorkboardPresentation(workboardId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/workboards/presentation/${workboardId}`);

    return response.data;
}

async function getUserOrder(workboardId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/workboards/${workboardId}/userOrder`);

    return response.data;
}

async function postWorkboard(workboardData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/workboards', workboardData);

    return response.data;
}

async function putWorkboard(workboardId, workboardData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/workboards/${workboardId}`, workboardData);

    return response.data;
}

async function deleteWorkboard(workboardId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/workboards/${workboardId}`);

    return response.data;
}

export {
    getWorkboard,
    getWorkboardPresentation,
    getUserOrder,
    postWorkboard,
    putWorkboard,
    deleteWorkboard
};
