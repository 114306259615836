// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Bar} from 'react-chartjs-2';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {DOWNTIME_HOURS, UPTIME_HOURS, UPTIME_VS_DOWNTIME} from 'Ui/components/graph/constants/graph-filters';

const DATA_FOR_SELECTORS = {
    [DOWNTIME_HOURS]: 'downtime',
    [UPTIME_HOURS]: 'uptime',
    [UPTIME_VS_DOWNTIME]: 'ratio'
};

function getChartData(reportData, tertiarySelector) {
    const {[tertiarySelector]: dataProperty} = DATA_FOR_SELECTORS;

    return reportData.rollupsForSelector.reduce((dataset, rollup, index) => {
        const {
            name: xValue,
            [dataProperty]: yValue
        } = rollup;

        const color = GRAPH_COLORS[index];

        dataset.backgroundColor.push(color);
        dataset.borderColor.push(color);
        dataset.data.push({
            x: xValue,
            y: yValue
        });

        return dataset;
    }, {
        backgroundColor: [],
        borderColor: [],
        data: [],
        fill: false
    });
}

function UptimeReportChart(props) {
    const {
        tertiarySelector,
        translations,
        uptimeReportData
    } = props;

    return (
        <Bar
            data={{
                datasets: [getChartData(uptimeReportData, tertiarySelector)]
            }}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label(tooltipItem) {
                                const {parsed} = tooltipItem;

                                const yFormatted = formatNumber(parsed.y, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                });

                                return tertiarySelector === UPTIME_VS_DOWNTIME ?
                                    `${yFormatted} ${UNITS.percent}` :
                                    `${yFormatted} ${translations.HOURS_UOM}`;
                            }
                        }
                    }
                },
                scales: {
                    yAxis: {
                        axis: 'y',
                        position: 'left',
                        ticks: {
                            callback(tickValue, index, ticks) {
                                const tickFormatted = formatNumber(ticks[index].value, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                });

                                return tertiarySelector === UPTIME_VS_DOWNTIME ?
                                    `${tickFormatted} ${UNITS.percent}` :
                                    `${tickFormatted} ${translations.HOURS_UOM}`;
                            }
                        },
                        type: 'linear'
                    }
                }
            }}
            type='bar'
        />
    );
}

UptimeReportChart.propTypes = {
    tertiarySelector: PropTypes.string,
    translations: PropTypes.translations,
    uptimeReportData: PropTypes.object
};

export default UptimeReportChart;
