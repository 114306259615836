// Unpublished Work © 2021-2024 Deere & Company.

export const machineState = {
    IDLE: 'idle',
    TRANSPORT: 'transport',
    WORKING: 'working'
};

export const machineRawStates = {
    '0': 'Idle',
    '1': 'Transport',
    '2': 'Working'
};
