// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconChevronLeft} from '@deere/icons';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import {connect} from 'react-redux';
import {updateModelFilterSelectedIDs, updateTypeFilterSelectedIDs} from 'Store/actions/map';

const ICON_CARET_WIDTH = '21px';
const ICON_CARET_HEIGHT = '21px';

const IconChevronLeftStyle = {
    style: {
        cursor: 'pointer',
        height: ICON_CARET_HEIGHT,
        width: ICON_CARET_WIDTH,
        alignSelf: 'center',
        filters: true
    }
};

function EquipmentFilters(props) {
    const {
        setIsFilterVisible,
        translations,
        modelsMultiSelectList,
        modelFilterSelectedIDs,
        modelsMultiSelectListAllIds,
        typesMultiSelectList,
        typeFilterSelectedIDs,
        typesMultiSelectListAllIds,
        updateModelFilterSelectedIDs,
        updateTypeFilterSelectedIDs
    } = props;

    const [
        modelSelectedIDs,
        setModelSelectedIDs
    ] = React.useState(() => modelFilterSelectedIDs);

    const [
        typeSelectedIDs,
        setTypeSelectedIDs
    ] = React.useState(() => typeFilterSelectedIDs);

    function updateFilters() {
        const updatedModelSelectedIDs = modelSelectedIDs.length ?
            modelSelectedIDs :
            modelsMultiSelectListAllIds;
        const updatedTypeSelectedIDs = typeSelectedIDs.length ?
            typeSelectedIDs :
            typesMultiSelectListAllIds;

        updateModelFilterSelectedIDs(updatedModelSelectedIDs);
        updateTypeFilterSelectedIDs(updatedTypeSelectedIDs);

        setIsFilterVisible();
    }

    const commonProps = {
        tabIndex: 0,
        dropUpThreshold: -1,
        multiple: true,
        hideFilter: false
    };

    const typesMultiSelect = Boolean(typesMultiSelectList.length) &&
        <SortedMultiSelect
            allItemsLabel={translations.ONLINK_ALL_TYPES}
            className='multiselect type-multiselect'
            defaultSelectLabel={translations.ONLINK_ALL_TYPES}
            items={typesMultiSelectList}
            label={translations.TYPE}
            multipleItemsSelectedLabel={translations.ONLINK_TYPES}
            onChange={setTypeSelectedIDs}
            selectedIds={typeSelectedIDs}
            {...commonProps}
        />;

    const modelMultiSelect = Boolean(modelsMultiSelectList.length) &&
        <SortedMultiSelect
            allItemsLabel={translations.ALL_MODELS}
            className='multiselect model-multiselect'
            defaultSelectLabel={translations.ALL_MODELS}
            items={modelsMultiSelectList}
            label={translations.MODEL}
            multipleItemsSelectedLabel={translations.MODELS}
            onChange={setModelSelectedIDs}
            selectedIds={modelSelectedIDs}
            {...commonProps}
        />;

    return (
        <>
            <div className={'filter-parent'}>
                <div onClick={setIsFilterVisible}>
                    <IconChevronLeft
                        iconChevronLeft={IconChevronLeftStyle}
                    />
                </div>
                <div className={'filter-title'}>
                    {translations.ONLINK_SELECT_FILTERS}
                </div>
            </div>
            <div className={'filters'}>
                {
                    typesMultiSelect
                }
                {
                    modelMultiSelect
                }
            </div>
            <div className={'footer-actions'}>
                <div
                    className={'onlink-btn cancel'}
                    onClick={setIsFilterVisible}
                >
                    {translations.CANCEL}
                </div>
                <div
                    className={'onlink-btn primary save'}
                    onClick={updateFilters}
                >
                    {translations.APPLY}
                </div>
            </div>
        </>
    );
}

EquipmentFilters.propTypes = {
    modelFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    modelsMultiSelectList: PropTypes.multiSelectList,
    modelsMultiSelectListAllIds: PropTypes.arrayOf(PropTypes.string),
    setIsFilterVisible: PropTypes.func,
    translations: PropTypes.translations,
    typeFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    typesMultiSelectList: PropTypes.multiSelectList,
    typesMultiSelectListAllIds: PropTypes.arrayOf(PropTypes.string),
    updateModelFilterSelectedIDs: PropTypes.func,
    updateTypeFilterSelectedIDs: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        modelFilterSelectedIDs: state.map.modelFilterSelectedIDs,
        typeFilterSelectedIDs: state.map.typeFilterSelectedIDs
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateModelFilterSelectedIDs(value) {
            dispatch(updateModelFilterSelectedIDs(value));
        },
        updateTypeFilterSelectedIDs(value) {
            dispatch(updateTypeFilterSelectedIDs(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentFilters);
