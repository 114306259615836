// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

function isPermitted(permissionMap, permissions) {
    if (Array.isArray(permissions)) {
        return permissions.every((permission) => permissionMap?.[permission]);
    }

    return permissionMap?.[permissions];
}

function hasAppAccess(appKeyMap, appAccess) {
    if (Array.isArray(appAccess)) {
        return appAccess.some((appKey) => appKeyMap?.[appKey]);
    }

    return Boolean(appKeyMap?.[appAccess]);
}

function hasPermissions(credentials, permissions) {
    return Boolean(credentials.superUser || isPermitted(credentials.permissionMap, permissions));
}

export {
    hasAppAccess,
    hasPermissions
};
