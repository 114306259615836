// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {sortBy} from 'lodash';
import {Doughnut} from 'react-chartjs-2';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';

const TOTAL_COST = 'Total Cost';
const CATEGORY_NAME = 'Category Name';

function sortAndFilterData(data, translations) {
    if (data && data.Categories) {
        const filteredCategories = data.Categories.filter((x) => x[TOTAL_COST] !== '0.00');

        return sortBy(filteredCategories, (datum) => translations[datum[CATEGORY_NAME]]);
    }

    return [];
}

function makeTable(sorted, translations, conversionConfig) {
    return sorted.map((lineData) => {
        const {
            [CATEGORY_NAME]: categoryName,
            [TOTAL_COST]: totalCost
        } = lineData;

        return (
            <div
                className='table-list-items'
                key={categoryName}
            >
                <div className='table-cell cell-left'>{translations[categoryName]}</div>
                <div className='table-cell cell-right'>{getFormattedCurrency(totalCost, conversionConfig)}</div>
            </div>
        );
    });
}

function getPieData(sorted) {
    return {
        datasets: [{
            backgroundColor: GRAPH_COLORS,
            data: sorted.map((datum) => parseFloat(datum[TOTAL_COST]))
        }],
        labels: sorted.map((datum) => datum[CATEGORY_NAME])
    };
}

function CostByCategory(props) {
    const {
        currencyPreference,
        data,
        featureToggles,
        translations
    } = props;

    const sorted = sortAndFilterData(data, translations);
    const hasData = sorted.length > 0;
    const conversionConfig = {
        currencyPreference,
        featureToggles
    };

    return (
        <div className='table-group-card inventory-card category-by-cost-container'>
            <NoDataMessage
                hasData={hasData}
                noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
            >
                {
                    hasData &&
                    <>
                        <Doughnut
                            data={getPieData(sorted)}
                            options={{
                                aspectRatio: 2
                            }}
                            type='doughnut'
                        />
                        <div className='inventory-total-cash'>
                            {
                                `${getFormattedCurrency(parseFloat(data['Monthly Cost']), conversionConfig)} `
                            }
                            <span className='inventory-total-cash-subtext'>{translations.ONLINK_TOTAL}</span>
                        </div>
                        <div className='card-header-labels'>
                            <div className='card-header-item cell-left'>{translations.PRODUCT_TYPE}</div>
                            <div className='card-header-item cell-right'>{translations.ONLINK_TOTAL_COST}</div>
                        </div>
                        <div className='table-list category-table'>
                            {makeTable(sorted, translations, conversionConfig)}
                        </div>
                    </>
                }
            </NoDataMessage>
        </div>
    );
}

CostByCategory.propTypes = {
    currencyPreference: PropTypes.string,
    data: PropTypes.object,
    featureToggles: PropTypes.featureToggles,
    translations: PropTypes.translations
};

export default CostByCategory;
