// Unpublished Work © 2022-2024 Deere & Company.

export const ENTRY_FORM_TITLES = {
    'Electrical Consumption': 'Daily Electrical Consumption',
    'Electrical Cost': 'Daily Electrical Cost',
    Fuel: 'Daily Fuel',
    Revenue: 'Daily Revenue',
    'Water Cost': 'Daily Water Cost',
    'Water Flow': 'Daily Flow'
};

export const ADD_ENTRY_FORM_TITLES = {
    'Firmness': 'ONLINK_ADD_FIRMNESS',
    'Green Speed': 'ONLINK_ADD_GREEN_SPEED',
    'Soil Moisture': 'ONLINK_ADD_SOIL_MOISTURE',
    'Clipping Yield': 'ONLINK_ADD_CLIPPING_YIELD',
    Fuel: 'ONLINK_ADD_FUEL',
    Revenue: 'ONLINK_ADD_REVENUE',
    'Electrical Consumption': 'ONLINK_ADD_ELECTRICAL_CONSUMPTION',
    'Electrical Cost': 'ONLINK_ADD_ELECTRICAL_COST',
    'Water Flow': 'ONLINK_ADD_WATER_FLOW',
    'Water Cost': 'ONLINK_ADD_WATER_COST'
};
