// Unpublished Work © 2020-2024 Deere & Company.

const categories = [
    {
        name: 'weather',
        tiles: ['54f263a8-806c-4e03-ac71-68c64712ddf4']
    },
    {
        name: 'equipment',
        tiles: [
            '9c6dbebe-496b-4b99-9d53-e8822225b0a5',
            '634ba290-df26-4703-8552-010475ccaab1'
        ]
    },
    {
        name: 'chemicals',
        tiles: ['ba85121f-7dbc-4f7f-99f0-5b5a6812978a']
    },
    {
        name: 'conditions',
        tiles: ['fb98b151-a2c4-4aa2-8441-afa2faa7d25e']
    },
    {
        name: 'labor',
        tiles: ['043709ce-2f17-43f3-9c1c-ecfadcab504b']
    },
    {
        name: 'water',
        tiles: ['14825350-ecc6-454a-aa6f-cce2ec175f3d']
    },
    {
        name: 'rounds',
        tiles: ['83603f70-f984-4cbd-aac1-e75a2ee45116']
    },
    {
        name: 'maintenance',
        tiles: ['fd3eea69-8c10-4a63-a2b3-5d5aba7c4db2']
    },
    {
        name: 'operator_notes',
        tiles: ['b15d540c-8157-40ae-b7c3-eab6b3db1aba']
    },
    {
        name: 'electric_mower_status',
        tiles: ['8187a281-884c-58c4-8368-6581b4dcf52d']
    },
    {
        name: 'low_inventory_parts',
        tiles: ['e04d05e8-6a0f-48af-833a-466358bb08af']
    }
];

export {
    categories
};
