// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import SearchFilter from 'Ui/components/higher-order-components/search-filter';
import {getMemoizedData} from 'Ui/components/map/elements/common/filter-helpers';
import EquipmentInfoBox from 'Ui/components/map/elements/equipment-info-box';
import EquipmentList from 'Ui/components/map/sidebar/equipment-list';
import EquipmentListItem from 'Ui/components/map/sidebar/equipment-list-item';
import EquipmentMarker from 'Ui/components/map/elements/equipment-marker';
import MapPage from 'Ui/components/map/map-page';
import MapWrapper from 'Ui/components/map/map-elements/map-wrapper';
import {getMyJDMachineMeasurements, getMyJDMachineBatteryMeasurements} from 'Services/my-jd-machine-service';
import {hasLatitudeAndLongitude} from 'Utils/map-utils';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {setActiveFocusInterval, useRefresh} from 'Utils/refresh-utils';
import {updateModelFilterSelectedIDs, updateTypeFilterSelectedIDs} from 'Store/actions/map';
import {MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND} from 'Ui/constants/time-constants';
import {ELECTRIFICATION} from 'Common/constants/feature-toggles';
import {ELECTRIC_MACHINE_VIN_PREFIXES, VIN_PREFIX_LENGTH} from 'Ui/constants/equipment-constants';

function getEquipmentListItems(fleet, selectedEquipment, onEquipmentSelect, translations) {
    return fleet.map((equipment) => (
        <EquipmentListItem
            active={selectedEquipment && selectedEquipment.equipmentId === equipment.equipmentId}
            equipment={equipment}
            key={equipment.serialNumber}
            onEquipmentSelect={onEquipmentSelect}
            translations={translations}
        />
    ));
}

function getEquipmentMarkers(fleet, onEquipmentSelect, machineMeasurements, setPosition, props) {
    const {
        featureToggles,
        selectedEquipment,
        setSelectedEquipment,
        translations
    } = props;

    return fleet.map((equipment) => {
        if (selectedEquipment && selectedEquipment.equipmentId === equipment.equipmentId) {
            const machineMeasurementsForEquipment = machineMeasurements[equipment.myJDId];

            return (
                <EquipmentInfoBox
                    equipment={equipment}
                    featureToggles={featureToggles}
                    key={equipment.serialNumber}
                    loading={!machineMeasurementsForEquipment}
                    machineMeasurements={machineMeasurementsForEquipment}
                    setPosition={setPosition}
                    setSelectedEquipment={setSelectedEquipment}
                    translations={translations}
                />
            );
        }

        return (
            <EquipmentMarker
                equipment={equipment}
                key={equipment.serialNumber}
                onEquipmentSelect={onEquipmentSelect}
            />
        );
    });
}

async function getMachineMeasurementsData(fleet, showBatteryLevel) {
    const machineMeasurementsData = await getMyJDMachineMeasurements(fleet);

    if (showBatteryLevel) {
        const electricMachines = fleet
            .filter((machine) => ELECTRIC_MACHINE_VIN_PREFIXES.includes(machine.serialNumber.slice(0, VIN_PREFIX_LENGTH)));

        const electricMachineAndOrgIds = electricMachines.map((machine) => ({
            machineId: machine.myJDId,
            orgId: machine.orgId
        }));
        const batteryLevelMeasurements = await getMyJDMachineBatteryMeasurements(electricMachineAndOrgIds);

        fleet.forEach(({myJDId}) => {
            const batteryLevelForMachine = batteryLevelMeasurements.find(({machineId}) => machineId === myJDId);

            machineMeasurementsData[myJDId].batteryLevel = batteryLevelForMachine?.value;
        });
    }

    return machineMeasurementsData;
}

function initializeUseEffects(fleetWithMembership, setMachineMeasurements, autoRefreshFunc, showBatteryLevel) {
    async function updateMachineMeasurements(updatedFleetWithMembership) {
        if (updatedFleetWithMembership.fleet) {
            const machineMeasurementsData = await getMachineMeasurementsData(updatedFleetWithMembership.fleet, showBatteryLevel);

            setMachineMeasurements(machineMeasurementsData);
        }
    }

    const [isRefreshing, refreshFunc] = useRefresh(() => autoRefreshFunc(updateMachineMeasurements));

    React.useEffect(() => {
        const intervalId = setActiveFocusInterval(refreshFunc, MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND);

        return () => clearInterval(intervalId);
    }, []);

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        if (!isRefreshing && fleetWithMembership.fleet) {
            const machineMeasurementsData = await getMachineMeasurementsData(fleetWithMembership.fleet, showBatteryLevel);

            if (isMounted()) {
                setMachineMeasurements(machineMeasurementsData);
            }
        }
    }), [fleetWithMembership.fleet]);
}

function initializeState() {
    const [machineMeasurements, setMachineMeasurements] = React.useState({});
    const [position, setPosition] = React.useState(null);

    return {
        machineMeasurements,
        setMachineMeasurements,
        position,
        setPosition
    };
}

function EquipmentPage(props) {
    const {
        applySearch,
        autoRefreshFunc,
        bottomSheetRef,
        closeBottomSheet,
        featureToggles,
        fleetWithMembership,
        loading,
        map,
        modelFilterSelectedIDs,
        onSearchChange,
        search,
        selectedEquipment,
        setMap,
        setSelectedEquipment,
        translations,
        typeFilterSelectedIDs,
        updateModelFilterSelectedIDs,
        updateTypeFilterSelectedIDs,
        isMigrated
    } = props;

    const {
        machineMeasurements,
        setMachineMeasurements,
        position,
        setPosition
    } = initializeState();

    initializeUseEffects(fleetWithMembership, setMachineMeasurements, autoRefreshFunc, featureToggles[ELECTRIFICATION]);

    function onEquipmentSelect(equipment) {
        setSelectedEquipment(equipment);

        closeBottomSheet();

        const equipmentPosition = {
            lat: equipment.lat,
            lng: equipment.lon
        };

        if (map && hasLatitudeAndLongitude(equipmentPosition.lat, equipmentPosition.lng)) {
            map.panTo(equipmentPosition);
        }

        setPosition(equipmentPosition);
    }

    const {
        equipmentTypes,
        filteredFleet,
        fleetWithLocations,
        fleetWithLocationsAndMembership,
        modelNames
    } = getMemoizedData(
        fleetWithMembership.fleet,
        search,
        modelFilterSelectedIDs,
        typeFilterSelectedIDs,
        {
            applySearch,
            updateTypeFilterSelectedIDs,
            updateModelFilterSelectedIDs
        },
        fleetWithMembership.membership
    );

    React.useEffect(() => {
        if (selectedEquipment && !filteredFleet.some((fleet) => fleet.equipmentId === selectedEquipment.equipmentId)) {
            setSelectedEquipment(null);
            setPosition(null);
        }
    }, [filteredFleet]);

    const equipmentList = (
        <EquipmentList
            equipmentTypes={equipmentTypes}
            loading={loading}
            modelNames={modelNames}
            onSearchChange={onSearchChange}
            search={search}
            showDateFilter={false}
            translations={translations}
        >
            {getEquipmentListItems(filteredFleet, selectedEquipment, onEquipmentSelect, translations)}
        </EquipmentList>
    );

    const mapWrapper = (
        <MapWrapper
            isMigrated={isMigrated}
            itemLocations={fleetWithLocationsAndMembership}
            loading={loading || Boolean(position)}
            map={map}
            setMap={setMap}
            translations={translations}
        >
            {
                getEquipmentMarkers(
                    fleetWithLocations,
                    onEquipmentSelect,
                    machineMeasurements,
                    setPosition,
                    props
                )
            }
        </MapWrapper>
    );

    return (
        <MapPage
            bottomSheetRef={bottomSheetRef}
            featureToggles={featureToggles}
            listComponent={equipmentList}
            mapComponent={mapWrapper}
        />
    );
}

EquipmentPage.propTypes = {
    applySearch: PropTypes.func,
    autoRefreshFunc: PropTypes.func,
    bottomSheetRef: PropTypes.reference,
    closeBottomSheet: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    fleetWithMembership: PropTypes.itemLocationsWithMembership,
    isMigrated: PropTypes.bool,
    loading: PropTypes.bool,
    map: PropTypes.map,
    modelFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    onSearchChange: PropTypes.func,
    search: PropTypes.string,
    selectedEquipment: PropTypes.equipment,
    setMap: PropTypes.func,
    setSelectedEquipment: PropTypes.func,
    translations: PropTypes.translations,
    typeFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    updateModelFilterSelectedIDs: PropTypes.func,
    updateTypeFilterSelectedIDs: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        modelFilterSelectedIDs: state.map.modelFilterSelectedIDs,
        typeFilterSelectedIDs: state.map.typeFilterSelectedIDs,
        isMigrated: state.membership.isMigrated
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateModelFilterSelectedIDs(value) {
            dispatch(updateModelFilterSelectedIDs(value));
        },
        updateTypeFilterSelectedIDs(value) {
            dispatch(updateTypeFilterSelectedIDs(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter(EquipmentPage));
