// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {IconOrgSelection} from '@deere/icons';
import {openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {clsx} from 'clsx';

const iconOrgSelection = {
    style: {
        fill: '#fff',
        width: '24px',
        height: '24px',
        minWidth: '24px'
    }
};

function Membership(props) {
    const {
        name,
        openDialogs,
        openMembershipSelectDialog
    } = props;

    const className = clsx('membership-btn onlink-btn-nav onlink-btn-left', {
        active: openDialogs[dialogTypes.MEMBERSHIP_SELECT_DIALOG]
    });
    const icon = (
        <IconOrgSelection iconOrgSelection={iconOrgSelection}/>
    );

    return (
        <OnlinkButton
            className={className}
            color='white'
            leftIcon={icon}
            onClick={openMembershipSelectDialog}
        >
            {name}
        </OnlinkButton>
    );
}

Membership.propTypes = {
    name: PropTypes.string,
    openDialogs: PropTypes.objectOf(PropTypes.object),
    openMembershipSelectDialog: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        openDialogs: state.dialogs.openDialogs,
        name: state.membership.name
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openMembershipSelectDialog() {
            dispatch(openDialog(dialogTypes.MEMBERSHIP_SELECT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
