// Unpublished Work © 2023-2024 Deere & Company.

export const BASKET = 'basket';
export const CM = 'cm';
export const COUNT = 'count';
export const DEGREES_C = 'degrees C';
export const DEGREES_F = 'degrees F';
export const DOLLARS = 'dollars';
export const DOLLARS_PER_KILOWATT_HOUR = 'dollars/kwh';
export const FEET = 'feet';
export const GALLONS = 'gallons';
export const HOURS = 'hours';
export const INCHES = 'inches';
export const INCHES_WEATHER = 'inches weather';
export const KILOWATT_HOURS = 'kwh';
export const MILES_PER_HOUR = 'mph';
export const KILOMETERS_PER_HOUR = 'kph';
export const KILOGRAM = 'kg';
export const KILOGRAMS = 'kilograms';
export const MM = 'mm';
export const METERS = 'meters';
export const MILLIMETERS = 'millimeters';
export const OUNCES = 'ounces';
export const OZS = 'ozs';
export const PERCENT = 'percent';
export const POUNDS = 'pounds';
export const QUARTS = 'quarts';
export const LITERS = 'liters';
export const SALINITY = 'dS/m';
export const THOUSAND_GALLONS = '1000 gallons';
export const F355_EQUIVALENTS = 'F355 Equivalents';
export const GRAVITIES = 'gravities';
export const FEET_AND_INCHES = 'feet and inches';
export const FEET_AND_INCHES_GRAPH = 'feet and inches graph';
export const DOLLARS_PER_THOUSAND_GALLONS = 'dollars/1M gal';
export const DOLLARS_PER_THOUSAND_LITERS = 'dollars/1K liters';
export const DOLLARS_PER_LITER = 'dollars/liter';
