// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WidgetDragHandle from 'OnLabor/workboard/widget/widget-drag-handle';
import {useDeepMemo} from 'Utils/react-utils';
import {clsx} from 'clsx';

function mapItemToTypographyElement(item, index) {
    const {
        text,
        ...styleProps
    } = item;

    return (
        <Typography
            {...styleProps}
            component='span'
            key={index}
            noWrap={true}
            overflow='hidden'
            variant='body1'
        >
            {text}
        </Typography>
    );
}

function WidgetItem(props) {
    const {
        draggable,
        id,
        items,
        screenTouchListener,
        screenTouchTimerRef,
        ...otherProps
    } = props;

    const ghostElementRef = React.useRef();
    const widgetItemClassName = React.useMemo(() => clsx('history-widget-item', {
        draggable
    }), [draggable]);
    const typographyElements = useDeepMemo(() => items.map(mapItemToTypographyElement), [items]);

    return (
        <Stack
            {...otherProps}
            className={widgetItemClassName}
            direction='row'
            id={id}
            padding='0 6px'
            ref={ghostElementRef}
            spacing={3}
        >
            {
                draggable &&
                <WidgetDragHandle
                    dragData={id}
                    ghostElementRef={ghostElementRef}
                    screenTouchListener={screenTouchListener}
                    screenTouchTimerRef={screenTouchTimerRef}
                />
            }
            <Stack
                direction='row'
                flex={1}
                justifyContent='space-between'
                overflow='auto'
                spacing={3}
            >
                {typographyElements}
            </Stack>
        </Stack>
    );
}

WidgetItem.propTypes = {
    draggable: PropTypes.bool,
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.stringOrNumber
    })),
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference
};

export default WidgetItem;
