// Unpublished Work © 2023-2024 Deere & Company.

import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import moment from 'moment';
import {useDeepMemo} from 'Utils/react-utils';

function BudgetSidebar(props) {
    const {
        budget,
        currencyPreference,
        featureToggles,
        translations
    } = props;

    const {
        inventoryBudget,
        partBinTotalCost
    } = useDeepMemo(() => {
        const {
            inventory,
            partBins
        } = budget;

        const {
            totalBudget,
            amountRecommended,
            amountRequested,
            amountOrdered,
            amountReceived
        } = inventory;

        const totalRemaining = totalBudget - (amountRecommended + amountRequested + amountOrdered + amountReceived);

        const inventoryBudget = {
            budget: getFormattedCurrency(totalBudget, {
                currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            }),
            requested: getFormattedCurrency(amountRecommended, {
                currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            }),
            pending: getFormattedCurrency(amountRequested, {
                currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            }),
            ordered: getFormattedCurrency(amountOrdered, {
                currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            }),
            received: getFormattedCurrency(amountReceived, {
                currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            }),
            remaining: getFormattedCurrency(totalRemaining, {
                currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            }),
            totalRemaining
        };

        const totalCost = partBins.reduce((total, partBin) => {
            return total + partBin.totalCost;
        }, 0);

        const partBinTotalCost = getFormattedCurrency(totalCost, {
            currencyPreference,
            featureToggles,
            maximumFractionDigits: 2
        });

        return {
            inventoryBudget,
            partBinTotalCost
        };
    },
    [budget]
    );

    return (
        <div className='budget-sidebar'>
            <div className={'section'}>
                <div className={'section-header'}>
                    {translations[`months_${moment().format('MMMM')}`]}
                </div>
                <div className={'section-content'}>
                    <div>
                        <span>{`${translations.ONLINK_BUDGET}: `}</span>
                        <span>{inventoryBudget.budget}</span>
                    </div>
                    <div className='panel-horizontal-divider'/>
                    <div>
                        <span>{`${translations.ONLINK_RECOMMENDED}: `}</span>
                        <span>{inventoryBudget.requested}</span>
                    </div>
                    <div>
                        <span>{`${translations.PENDING_TELEMATICS_STATE}: `}</span>
                        <span>{inventoryBudget.pending}</span>
                    </div>
                    <div>
                        <span>{`${translations.ONLINK_ORDERED}: `}</span>
                        <span>{inventoryBudget.ordered}</span>
                    </div>
                    <div>
                        <span>{`${translations.received}: `}</span>
                        <span>{inventoryBudget.received}</span>
                    </div>
                    <div className='panel-horizontal-divider'/>
                    <div className={inventoryBudget.totalRemaining < 0 ? 'red' : 'green'}>
                        <span>{`${translations.ONLINK_BALANCE}: `}</span>
                        <span>{inventoryBudget.remaining}</span>
                    </div>
                </div>
            </div>
            <div className={'section'}>
                <div className={'section-header'}>
                    {translations.ONLINK_INVENTORY}
                </div>
                <div className={'section-content'}>
                    <div>
                        <span>{`${translations.ONLINK_STOCK}: `}</span>
                        <span>{partBinTotalCost}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

BudgetSidebar.propTypes = {
    budget: PropTypes.object,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    translations: PropTypes.translations
};

export default BudgetSidebar;
