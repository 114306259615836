// Unpublished Work © 2021-2024 Deere & Company.

import serviceStatusConstants from 'Ui/constants/service-status-constants';

const {
    completed,
    declined,
    scheduled,
    in_process: inProcess,
    recommended,
    work_item: workItem
} = serviceStatusConstants;

export default {
    completed: [completed],
    declined: [declined, scheduled],
    'in_process': [inProcess, completed],
    recommended: [recommended, scheduled, inProcess, completed],
    scheduled: [scheduled, inProcess, completed],
    'work_item': [workItem, completed],
    defaults: [completed, declined, scheduled, inProcess, completed, recommended, workItem]
};
