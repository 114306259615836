// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {getPanelThresholds} from 'Services/panel-service';
import {fetchEffectData} from 'Utils/react-utils';
import {formatNumber, getConvertedDataUnitOrCurrencySymbol} from 'Utils/unit-conversion-utils';
import {convertThresholdToUoM} from 'Common/utils/threshold/uom-converter';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {PRICE_PER_ROUND} from 'Common/constants/data-group-constants';
import {cloneDeep} from 'lodash';

const EXCLUDED_DATA_GROUPS = [PRICE_PER_ROUND];

function convertThresholdsToUoM(thresholds, conversionConfig) {
    if (thresholds) {
        return {
            highNormal: convertThresholdToUoM(thresholds.highNormal.value, conversionConfig),
            highWarning: convertThresholdToUoM(thresholds.highWarning.value, conversionConfig),
            lowNormal: convertThresholdToUoM(thresholds.lowNormal.value, conversionConfig),
            lowWarning: convertThresholdToUoM(thresholds.lowWarning.value, conversionConfig)
        };
    }

    return {
        highNormal: null,
        highWarning: null,
        lowNormal: null,
        lowWarning: null
    };
}

function convertThreshold({
    conversionConfig,
    threshold,
    translations,
    typesFunction
}) {
    const conversionConfigForThreshold = {
        ...conversionConfig,
        dataType: threshold.dataType,
        dataUnit: threshold.dataUnits
    };

    const typesLabel = typesFunction(threshold);

    return {
        ...convertThresholdsToUoM(threshold.thresholds, conversionConfigForThreshold),
        threshold,
        types: translations[typesLabel] || typesLabel,
        units: getConvertedDataUnitOrCurrencySymbol(conversionConfigForThreshold)
    };
}

function thresholdCell(row) {
    const {value} = row;

    return formatNumber(value);
}

function getColumns(translations, openDialog, updateThreshold) {
    return [
        {
            Header: translations.ONLINK_TYPES,
            headerClassName: 'sticky',
            className: 'sticky',
            accessor: 'types',
            width: 220,
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => {
                            openDialog({
                                editHandler: updateThreshold,
                                original
                            });
                        }}
                    >
                        {value}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_UNITS,
            accessor: 'units'
        },
        {
            Header: translations.ONLINK_LOW_WARNING,
            className: 'extreme-left-threshold-border',
            accessor: 'lowWarning',
            Cell: thresholdCell
        },
        {
            Header: translations.ONLINK_LOW_NORMAL,
            className: 'warning-left-threshold-border normal-right-threshold-border',
            accessor: 'lowNormal',
            Cell: thresholdCell
        },
        {
            Header: translations.ONLINK_HIGH_NORMAL,
            className: 'warning-right-threshold-border',
            accessor: 'highNormal',
            Cell: thresholdCell
        },
        {
            Header: translations.ONLINK_HIGH_WARNING,
            className: 'extreme-right-threshold-border',
            accessor: 'highWarning',
            Cell: thresholdCell
        }
    ];
}

function thresholdLegend(translations) {
    return (
        <div className='threshold-legend bold-body-text-section-title'>
            <div>{`${translations.LEGEND}:`}</div>
            <div>
                <div className='extreme-threshold'/>
                {`< ${translations.notification_severity_low}`}
            </div>
            <div>
                <div className='warning-threshold'/>
                {`< ${translations.ONLINK_LOW_NORMAL}`}
            </div>
            <div>
                <div className='normal-threshold'/>
                {translations.ONLINK_NORMAL_THRESHOLD}
            </div>
            <div>
                <div className='warning-threshold'/>
                {`> ${translations.ONLINK_HIGH_NORMAL}`}
            </div>
            <div>
                <div className='extreme-threshold'/>
                {`> ${translations.notification_severity_high}`}
            </div>
        </div>
    );
}

function ThresholdSettings(props) {
    const {
        category,
        filterFunction,
        membership,
        openDialog,
        optionalComponent,
        translations,
        typesFunction
    } = props;

    const [thresholds, setThresholds] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const conversionConfig = {
        currencyPreference: membership.currencyPreference,
        translations,
        unitOfMeasure: membership.unitOfMeasure,
        unitOfMeasureOverrides: membership.unitOfMeasureOverrides
    };

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {thresholds: thresholdsData} = await getPanelThresholds();

        if (isMounted()) {
            const filteredThresholds = thresholdsData.filter((threshold) => {
                const isSameCategory = threshold.category === category;

                const isIncludedType = !EXCLUDED_DATA_GROUPS.includes(threshold.dataType);

                const inCategoryAndNotFilteredOut = filterFunction ?
                    isSameCategory && filterFunction(threshold) :
                    isSameCategory;

                return isIncludedType && inCategoryAndNotFilteredOut;
            });
            const formattedThresholds = filteredThresholds.map((threshold) => convertThreshold({
                conversionConfig,
                threshold,
                translations,
                typesFunction
            }));

            setThresholds(formattedThresholds);
            setLoading(false);
        }
    }), []);

    function updateThreshold(updatedThreshold) {
        const clonedThresholds = cloneDeep(thresholds);
        const updateIndex = clonedThresholds.findIndex(({threshold}) => threshold.dataType === updatedThreshold.dataType);

        clonedThresholds[updateIndex] = convertThreshold({
            conversionConfig,
            threshold: updatedThreshold,
            translations,
            typesFunction
        });

        setThresholds(clonedThresholds);
    }

    return (
        <div className='threshold-settings'>
            <DataTable
                columns={getColumns(translations, openDialog, updateThreshold)}
                defaultSorted={[{
                    desc: false,
                    id: 'types'
                }]}
                filterComponent={optionalComponent}
                loading={loading}
                rows={thresholds}
                searchable={true}
                translations={translations}
            />
            {!loading && thresholdLegend(translations)}
        </div>
    );
}

ThresholdSettings.propTypes = {
    category: PropTypes.string,
    filterFunction: PropTypes.func,
    membership: PropTypes.membership,
    openDialog: PropTypes.func,
    optionalComponent: PropTypes.element,
    translations: PropTypes.translations,
    typesFunction: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openDialog(props) {
            dispatch(openReduxDialog(dialogTypes.THRESHOLD_EDIT_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThresholdSettings);
