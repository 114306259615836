// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Datepicker, Input} from '@deere/form-controls';
import {useDeepEffect} from 'Utils/react-utils';
import moment from 'moment';

function isValidDate(date, required) {
    const selectedDate = moment(date);
    const isValidDate = Boolean(date) && selectedDate.isValid();

    return isValidDate || !required;
}

function isValidRange({
    from, to
}, required) {
    const isValidFrom = isValidDate(from, required);
    const isValidTo = isValidDate(to, required);

    return isValidFrom && isValidTo;
}

function ValidationDate(props) {
    const {
        component: Component,
        setValid,
        range,
        required,
        readOnly,
        ...inputProps
    } = props;

    const {
        name,
        selected,
        label,
        dateFormat
    } = inputProps;

    useDeepEffect(() => {
        const valid = range ? isValidRange(selected, required) : isValidDate(selected, required);

        setValid(name, valid);

        return () => {
            setValid(name, true);
        };
    }, [selected, required]);

    const component = (
        <Component
            label={label}
            {...inputProps}
        />
    );

    if (readOnly) {
        return (
            <Input
                disabled={readOnly}
                label={label}
                tabIndex={0}
                type='text'
                value={selected && moment(selected).format(dateFormat).toString()}
            />
        );
    }

    if (required) {
        return (
            <div className='required-input'>
                {component}
            </div>
        );
    }

    return component;
}

ValidationDate.defaultProps = {
    component: Datepicker
};

ValidationDate.propTypes = {
    component: PropTypes.elementType,
    name: PropTypes.string.isRequired,
    range: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    selected: PropTypes.object,
    setValid: PropTypes.func.isRequired
};

export default ValidationDate;
