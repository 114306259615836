// Unpublished Work © 2020-2024 Deere & Company.

import {v4} from 'uuid';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export function addToast(toast) {
    return {
        type: ADD_TOAST,
        toast: {
            ...toast,
            id: v4()
        }
    };
}

export function removeToast(id) {
    return {
        type: REMOVE_TOAST,
        id
    };
}
