// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {IconAdd, IconDelete} from '@deere/icons';
import {joinClassNames} from 'Utils/html-utils';

const ICON_ADD_STYLE = {
    style: {
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const ICON_DELETE_STYLE = {
    style: {
        width: '20px',
        height: '20px'
    }
};

function ListBox(props) {
    const {
        addButtonLabel,
        className,
        headerLabel,
        items,
        noDataText,
        onAdd,
        onDelete
    } = props;

    return (
        <div className={joinClassNames('list-box-container', className)}>
            <div className='list-box-header'>
                <h4 className='list-box-label'>{headerLabel}</h4>
                <OnlinkButton
                    className='primary'
                    leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
                    onClick={(event) => {
                        event.preventDefault();

                        onAdd(event);
                    }}
                >
                    {addButtonLabel}
                </OnlinkButton>
            </div>
            <div className='list-box'>
                <div className='list-box-content'>
                    {
                        items?.length > 0 ?
                            items.map((item, index) => (
                                <div
                                    className='list-box-row'
                                    key={`${item}${index}`}
                                >
                                    <span className='list-box-item'>{item}</span>
                                    <OnLinkIconButton
                                        onClick={(event) => {
                                            event.preventDefault();

                                            onDelete(item, index);
                                        }}
                                    >
                                        <IconDelete iconDelete={ICON_DELETE_STYLE}/>
                                    </OnLinkIconButton>
                                </div>
                            )) : (
                                <span className='note-message'>{noDataText}</span>
                            )
                    }
                </div>
            </div>
        </div>
    );
}

ListBox.propTypes = {
    addButtonLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    headerLabel: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.stringOrNumber).isRequired,
    noDataText: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default ListBox;
