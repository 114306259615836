// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import GridLayout from 'Ui/components/grid/grid-layout';
import SettingsCard from 'Ui/components/settings/settings-card';
import SettingsDialog from 'Ui/components/settings/settings-dialog';
import {getAvailableSettings} from 'Utils/settings-utils';
import getConstants from 'Ui/components/settings/constants/settings';
import {SETTINGS} from 'Common/constants/routes';
import {fetchEffectData} from 'Utils/react-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function createTiles(translations, settings) {
    const settingsWithContent = settings.filter((setting) => setting.dialogProps.content.length > 0);

    return settingsWithContent.map((tile) => (
        <SettingsCard
            icon={tile.icon}
            key={tile.tileId}
            tileId={tile.tileId}
            title={tile.name}
            translations={translations}
        />
    ));
}

function createTileDialogs(settings) {
    return settings.map((tile) => {
        const lowerCaseTileId = tile.tileId.toLowerCase();
        const basePath = `${SETTINGS}/${lowerCaseTileId}`;

        return (
            <Route
                key={tile.tileId}
                path={[`${basePath}/:tab`, basePath]}
            >
                <SettingsDialog
                    tileId={lowerCaseTileId}
                    {...tile.dialogProps}
                />
            </Route>
        );
    });
}

function SettingsDashboard(props) {
    const {
        appKeys,
        featureToggles,
        membership,
        myJdPermissions,
        permissions,
        translations
    } = props;

    const [availableSettings, setAvailableSettings] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const settings = await getAvailableSettings(translations, membership, appKeys, permissions, myJdPermissions, featureToggles);

        if (isMounted()) {
            setAvailableSettings(settings);
            setLoading(false);
        }
    }), [membership, appKeys]);

    return (
        <LoadingWrapper
            className='login-loading-icon'
            loading={loading}
            size='50px'
        >
            <GridLayout
                className='settings-grid'
                layout={getConstants(featureToggles)}
                measureBeforeMount={true}
            >
                {createTiles(translations, availableSettings)}
            </GridLayout>
            <Switch>
                {createTileDialogs(availableSettings)}
            </Switch>
        </LoadingWrapper>
    );
}

SettingsDashboard.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    permissions: PropTypes.legacyPermissions,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions
    };
}

export default connect(mapStateToProps)(SettingsDashboard);
