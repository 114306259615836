// Unpublished Work © 2021-2024 Deere & Company.

export default {
    '54f263a8-806c-4e03-ac71-68c64712ddf4': {
        tile: {
            id: '54f263a8-806c-4e03-ac71-68c64712ddf4',
            name: 'weather'
        },
        position: {
            w: 1,
            h: 2
        }
    },
    'fb98b151-a2c4-4aa2-8441-afa2faa7d25e': {
        tile: {
            id: 'fb98b151-a2c4-4aa2-8441-afa2faa7d25e',
            name: 'course'
        },
        position: {
            w: 1,
            h: 1
        }
    },
    '043709ce-2f17-43f3-9c1c-ecfadcab504b': {
        tile: {
            id: '043709ce-2f17-43f3-9c1c-ecfadcab504b',
            name: 'labor'
        },
        position: {
            w: 1,
            h: 2
        }
    },
    '9c6dbebe-496b-4b99-9d53-e8822225b0a5': {
        tile: {
            id: '9c6dbebe-496b-4b99-9d53-e8822225b0a5',
            name: 'equipment'
        },
        position: {
            w: 1,
            h: 1
        }
    },
    '14825350-ecc6-454a-aa6f-cce2ec175f3d': {
        tile: {
            id: '14825350-ecc6-454a-aa6f-cce2ec175f3d',
            name: 'water'
        },
        position: {
            w: 1,
            h: 1
        }
    },
    'ba85121f-7dbc-4f7f-99f0-5b5a6812978a': {
        tile: {
            id: 'ba85121f-7dbc-4f7f-99f0-5b5a6812978a',
            name: 'chemicals'
        },
        position: {
            w: 1,
            h: 1
        }
    },
    '83603f70-f984-4cbd-aac1-e75a2ee45116': {
        tile: {
            id: '83603f70-f984-4cbd-aac1-e75a2ee45116',
            name: 'rounds'
        },
        position: {
            w: 1,
            h: 1
        }
    },
    '634ba290-df26-4703-8552-010475ccaab1': {
        tile: {
            id: '634ba290-df26-4703-8552-010475ccaab1',
            name: 'hoc'
        },
        position: {
            w: 1,
            h: 2
        }
    },
    'fd3eea69-8c10-4a63-a2b3-5d5aba7c4db2': {
        tile: {
            id: 'fd3eea69-8c10-4a63-a2b3-5d5aba7c4db2',
            name: 'maintenance'
        },
        position: {
            w: 1,
            h: 2
        }
    },
    'b15d540c-8157-40ae-b7c3-eab6b3db1aba': {
        tile: {
            id: 'b15d540c-8157-40ae-b7c3-eab6b3db1aba',
            name: 'operator_notes'
        },
        position: {
            w: 1,
            h: 2
        }
    },
    '8187a281-884c-58c4-8368-6581b4dcf52d': {
        tile: {
            id: '8187a281-884c-58c4-8368-6581b4dcf52d',
            name: 'electric_mower_status'
        },
        position: {
            w: 1,
            h: 2
        }
    },
    'e04d05e8-6a0f-48af-833a-466358bb08af': {
        tile: {
            id: 'e04d05e8-6a0f-48af-833a-466358bb08af',
            name: 'low_inventory_parts'
        },
        position: {
            w: 1,
            h: 2
        }
    }
};
