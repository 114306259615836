// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconAdd} from '@deere/icons';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import OnlinkButton from 'Ui/components/common/onlink-button';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {formatPhoneNumber} from 'Utils/phone';
import {fetchEffectData, useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import {getMembershipUsers} from 'Services/membership-service';
import {deleteUserById} from 'Services/user-service';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import dialogTypes from 'Ui/components/common/dialog-types';
import {USER_SEARCH_FIELDS} from 'Ui/constants/search-constants';
import {TOAST_TYPE} from '@deere/toast';

const ICON_ADD_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

async function onUserClick(user, setData, setIsEditing) {
    setData('user', user);

    setIsEditing();
}

function getColumns(translations, setData, setIsEditing) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink onClick={() => onUserClick(original.user, setData, setIsEditing)}>
                        {value}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.TITLE,
            accessor: 'title'
        },
        {
            Header: translations['USER_MANAGEMENT.COLUMN_HEADER.ROLE'],
            accessor: 'role'
        },
        {
            Header: translations.my_team_phone,
            accessor: 'phone'
        },
        {
            Header: translations.my_team_email,
            accessor: 'email'
        },
        {
            Header: translations.STATUS,
            accessor: 'status'
        }
    ];
}

function getFilterComponent(translations, showInactive, setShowInactive, setData, setIsEditing) {
    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={showInactive}
                name='showInactive'
                offLabel={translations.ONLINK_SHOW_INACTIVE}
                onChange={() => setShowInactive(!showInactive)}
                onLabel={translations.ONLINK_HIDE_INACTIVE}
                translations={translations}
            />
            <OnlinkButton
                className='add-user-button primary'
                leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
                onClick={() => {
                    setData('user', null);
                    setIsEditing();
                }}
            >
                {translations.ONLINK_ADD_USER}
            </OnlinkButton>
        </div>
    );
}

function UserSettings(props) {
    const {
        addToast,
        appUserId,
        closeConfirmation,
        featureToggles,
        isEditing,
        openConfirmation,
        setData,
        setIsEditing,
        translations,
        userRoles
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [showInactive, setShowInactive] = React.useState(false);
    const [users, setUsers] = React.useState([]);

    async function fetchUserData(isMounted = () => true) {
        setLoading(true);

        const {users: usersData} = await getMembershipUsers();

        if (isMounted()) {
            const formattedUsers = usersData.map((user) => {
                const role = userRoles.find((role) => role.userRoleId === user.userRoleId);

                return {
                    appUserId: user.appUserId,
                    email: user.email || '',
                    inactive: user.inactive,
                    name: `${user.lastName}, ${user.firstName}`,
                    role: role ? role.title : '',
                    phone: formatPhoneNumber(user.phone, user.countryCallingCode, featureToggles),
                    status: user.inactive ? translations.inactive : translations['EDIT_PDA_PAGE.LABEL.ACTIVE'],
                    title: user.title || '',
                    user
                };
            });

            setUsers(formattedUsers);
            setLoading(false);
        }
    }

    function deleteUser(user) {
        openConfirmation({
            message: translations.ONLINK_USER_THIS_CANNOT_BE_UNDONE,
            title: translations.ONLINK_DELETE_USER,
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteUserById(user.appUserId);

                    await fetchUserData();
                } catch (e) {
                    addToast({
                        message: translations.ONLINK_CONFIRM_USER_DELETION_FAILED,
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    useDeepEffect(() => fetchEffectData(fetchUserData), [isEditing, userRoles]);

    const filteredUsers = useDeepMemo(
        () => showInactive ? users : users.filter((user) => !user.inactive),
        [showInactive, users]
    );

    return (
        <DataTable
            columns={getColumns(translations, setData, setIsEditing)}
            defaultSorted={[{
                desc: false,
                id: 'name'
            }]}
            deleteFilter={(user) => user.appUserId !== appUserId}
            deleteHandler={deleteUser}
            filterComponent={getFilterComponent(translations, showInactive, setShowInactive, setData, setIsEditing)}
            loading={loading}
            rows={filteredUsers}
            searchFields={USER_SEARCH_FIELDS}
            searchable={true}
            showDeleteColumn={true}
            translations={translations}
        />
    );
}

UserSettings.propTypes = {
    addToast: PropTypes.func,
    appUserId: PropTypes.string,
    closeConfirmation: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    isEditing: PropTypes.bool,
    openConfirmation: PropTypes.func,
    setData: PropTypes.func,
    setIsEditing: PropTypes.func,
    translations: PropTypes.translations,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        appUserId: state.account.appUserId,
        featureToggles: state.account.featureToggles,
        translations: state.translations,
        userRoles: state.role.userRoles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
