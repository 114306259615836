// Unpublished Work © 2021-2024 Deere & Company.

import {formatLocalizedTime} from 'Utils/time-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {compareMissingValues} from 'Utils/sort';
import {THRESHOLD_CLASSNAMES} from 'Ui/constants/threshold-constants';
import moment from 'moment';

function getPercent(numerator, denominator) {
    return Math.max(0, 1 - numerator / denominator);
}

function hoursUntilServiceDue(service) {
    return service.atHours - service.totalHours;
}

function untilServiceDue(service, unitOfTime) {
    return moment(service.atDateSelect).diff(new Date(), unitOfTime);
}

function getPercentHours(service) {
    if (!isNullOrUndefined(service.atHours) && service.everyHours) {
        const untilDue = hoursUntilServiceDue(service);

        return getPercent(untilDue, service.everyHours);
    }

    return 0;
}

function getTimeAddedPercentFromRange(atDateSelect, today, timeAdded) {
    const totalRange = atDateSelect.diff(timeAdded, 'days');
    const elapsedDays = today.diff(timeAdded, 'days');

    const elapsedDaysZeroOrPositive = elapsedDays > 0 ? elapsedDays : 0;

    return totalRange > 0 ? elapsedDaysZeroOrPositive / totalRange : 1;
}

function getPercentMonthsRangeCalculation(service) {
    const atDateSelect = moment(service.atDateSelect);

    if (service.atDateSelect && atDateSelect.isValid()) {
        const today = moment();

        if (service.timeAdded) {
            return getTimeAddedPercentFromRange(atDateSelect, today, moment(service.timeAdded));
        }

        if (service.everyMonths) {
            const startTime = atDateSelect.subtract(service.everyMonths, 'months');

            return getTimeAddedPercentFromRange(atDateSelect, today, startTime);
        }
    }

    return 0;
}

function getThreshold(untilDue, threshold) {
    if (untilDue <= 0) {
        return THRESHOLD_CLASSNAMES.extremeThreshold;
    }

    if (untilDue < threshold) {
        return THRESHOLD_CLASSNAMES.warningThreshold;
    }

    return THRESHOLD_CLASSNAMES.normalThreshold;
}

function getPercentClassName(service) {
    const percentHours = getPercentHours(service);

    const percentMonths = getPercentMonthsRangeCalculation(service);

    if (percentHours >= percentMonths) {
        const dueHours = hoursUntilServiceDue(service);

        return getThreshold(dueHours, service.hourThreshold);
    }

    const dueDays = untilServiceDue(service, 'days');

    return getThreshold(dueDays, service.dayThreshold);
}

function getPercentDue(service) {
    const percentHours = getPercentHours(service);
    const percentMonths = getPercentMonthsRangeCalculation(service);

    return Math.max(percentHours, percentMonths);
}

function numericCompare(a = 0, b = 0) {
    if (a > b) {
        return -1;
    } else if (a < b) {
        return 1;
    }

    return 0;
}

function dateCompare(a, b) {
    if (a && b) {
        return moment(a) - moment(b);
    }

    return compareMissingValues(b, a);
}

function alphaNumericCompare(a, b) {
    if (a && b) {
        return a.localeCompare(b, 'en', {
            numeric: true
        });
    }

    return compareMissingValues(a, b);
}

function genericServiceCompare(serviceA, serviceB, primaryCompareFunction, primarySortField) {
    const primaryCompareResult = primaryCompareFunction(serviceA[primarySortField], serviceB[primarySortField]);

    if (primaryCompareResult !== 0) {
        return primaryCompareResult;
    }

    return numericCompare(serviceA.percentDue, serviceB.percentDue);
}

function dateSelectAtHoursCompare(serviceA, serviceB) {
    if (serviceA.atDateSelect && serviceB.atDateSelect) {
        const atDateSelect = dateCompare(serviceA.atDateSelect, serviceB.atDateSelect);

        if (atDateSelect !== 0) {
            return atDateSelect;
        }
    }

    if (serviceA.atHours && serviceB.atHours) {
        const atHours = numericCompare(serviceA.atHours, serviceB.atHours);

        if (atHours !== 0) {
            return atHours;
        }
    }

    return 0;
}

function dueColumnCompare(serviceA, serviceB) {
    const percentDue = numericCompare(serviceA.percentDue, serviceB.percentDue);

    if (percentDue !== 0) {
        return percentDue;
    }

    const compareResult = dateSelectAtHoursCompare(serviceA, serviceB);

    if (compareResult !== 0) {
        return compareResult;
    }

    return alphaNumericCompare(serviceA.serviceTypeName, serviceB.serviceTypeName);
}

function getInterval(service, translation) {
    const {
        everyHours,
        everyMonths,
        initialHours,
        initialMonths,
        modelMaintenanceId
    } = service;

    if (!modelMaintenanceId) {
        return null;
    }

    const everyHoursValid = everyHours && everyHours > 0;
    const everyMonthsValid = everyMonths && everyMonths > 0;
    const initialHoursValid = initialHours && initialHours > 0;
    const initialMonthsValid = initialMonths && initialMonths > 0;

    if (everyHoursValid && everyMonthsValid) {
        return replaceTranslationNames(translation.ONLINK_HOURS_AND_MONTHS_INTERVAL, {
            '0': everyHours,
            '1': everyMonths
        });
    } else if (everyHoursValid) {
        return replaceTranslationNames(translation.ONLINK_HOURS_INTERVAL, {
            '0': everyHours
        });
    } else if (everyMonthsValid) {
        return replaceTranslationNames(translation.ONLINK_MONTHS_INTERVAL, {
            '0': everyMonths
        });
    } else if (initialHoursValid && initialMonthsValid) {
        return replaceTranslationNames(translation.ONLINK_FIRST_HOURS_AND_FIRST_MONTHS_INTERVAL, {
            '0': initialHours,
            '1': initialMonths
        });
    } else if (initialHoursValid) {
        return replaceTranslationNames(translation.ONLINK_FIRST_HOURS_INTERVAL, {
            '0': initialHours
        });
    } else if (initialMonthsValid) {
        return replaceTranslationNames(translation.ONLINK_FIRST_MONTHS_INTERVAL, {
            '0': initialMonths
        });
    }

    return null;
}

function formatAssignees(service) {
    const {users = [] } = service;
    const names = users.map((user) => `${user.firstName} ${user.lastName}`);

    return names.join(', ');
}

function formatDateSelect(service) {
    const date = moment(service.atDateSelect);

    return service.atDateSelect && date.isValid() && formatLocalizedTime(date.toDate(), {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
}

function formatEquipmentName(service) {
    return service.fleetId !== service.fleetInUseId && service.fleetName ?
        `${service.equipmentName} [${service.fleetName}]` :
        service.equipmentName;
}

function formatModelAndManufacturerName(service) {
    return `${service.manufacturerName} ${service.modelName}`;
}

export {
    getPercentClassName,
    getPercentDue,
    getPercentHours,
    getPercentMonthsRangeCalculation,
    dueColumnCompare,
    getInterval,
    genericServiceCompare,
    numericCompare,
    alphaNumericCompare,
    dateCompare,
    formatAssignees,
    formatDateSelect,
    formatEquipmentName,
    formatModelAndManufacturerName
};
