// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnLinkDialogNavContent from 'Ui/components/common/onlink-dialog/onlink-dialog-nav-content';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {IconCaretDirection} from 'Ui/components/main-navbar';

const ICON_CARET_STYLE = {
    height: '20px',
    marginLeft: '0px',
    width: '20px'
};

function OnLinkDialogNavMobile(props) {
    const {
        activeTab,
        content,
        navButton,
        onTabClick
    } = props;

    const [open, setOpen] = React.useState(() => false);

    const iconCaret = (
        <IconCaretDirection
            caretUp={open}
            color='#2677bb'
            style={ICON_CARET_STYLE}
        />
    );

    return (
        <div className='onlink-dialog-navigation-mobile'>
            <OnlinkButton
                leftIcon={iconCaret}
                onClick={() => setOpen(!open)}
            />
            {
                open &&
                <>
                    <div className='onlink-dialog-navigation-dropdown'>
                        <OnLinkDialogNavContent
                            activeTab={activeTab}
                            content={content}
                            onTabClick={(tabId) => {
                                setOpen(false);

                                onTabClick(tabId);
                            }}
                        />
                        {
                            React.Children.map(navButton, (child) => React.cloneElement(child, {
                                onClick(...args) {
                                    setOpen(false);

                                    child.props.onClick(...args);
                                }
                            }))
                        }
                    </div>
                    <div
                        className='overlay'
                        onClick={() => setOpen(false)}
                    />
                </>
            }
        </div>
    );
}

OnLinkDialogNavMobile.propTypes = {
    activeTab: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.dialogContent),
    navButton: PropTypes.element,
    onTabClick: PropTypes.func
};

export default OnLinkDialogNavMobile;
