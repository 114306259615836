// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {BasicDialog} from '@deere/basic-dialog';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import MembershipSelect from 'Ui/components/main-navbar/membership-select';
import {switchMembership, switchOrg} from 'Utils/membership/switch-membership';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function MembershipSelectDialog(props) {
    const {
        closeMembershipSelectDialog,
        membership,
        hasMyJDSession,
        translations
    } = props;

    const [loading, setLoading] = React.useState(false);

    const changeMembership = React.useCallback(async (membershipId, orgId, isMigrated) => {
        setLoading(true);

        if (hasMyJDSession && orgId && isMigrated) {
            await switchOrg(orgId, translations);
        } else {
            await switchMembership(membershipId, membership.id, hasMyJDSession, translations);
        }

        closeMembershipSelectDialog();
        setLoading(false);
    }, [closeMembershipSelectDialog]);

    return (
        <BasicDialog
            className='choose-membership-dialog'
            closeHandler={closeMembershipSelectDialog}
            dismissOnClickOutside={true}
            show={true}
            title={translations.SELECT_A_MEMBERSHIP}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <MembershipSelect
                    changeMembership={changeMembership}
                    isSelected={true}
                    memberships={[membership]}
                    showParents={true}
                />
            </LoadingWrapper>
        </BasicDialog>
    );
}

MembershipSelectDialog.propTypes = {
    closeMembershipSelectDialog: PropTypes.func,
    hasMyJDSession: PropTypes.bool,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        hasMyJDSession: state.account.hasMyJDSession,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeMembershipSelectDialog() {
            dispatch(closeReduxDialog(dialogTypes.MEMBERSHIP_SELECT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipSelectDialog);
