// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';

import {TOAST_TYPE} from '@deere/toast';
import {joinClassNames} from 'Utils/html-utils';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {Dialog} from '@deere/isg.component-library';

function FormDialog(props) {
    const {
        addToast,
        cancelLabel,
        className,
        closeHandler,
        disableSave,
        footerLoading,
        isMigrated,
        onSave,
        saveLabel,
        translations,
        ...otherProps
    } = props;

    const footerActions = [
        {
            onClick: closeHandler,
            variant: 'secondary',
            title: cancelLabel
        },
        {
            async onClick(event) {
                try {
                    await onSave(event);
                } catch (err) {
                    addToast({
                        message: translations.ERROR_OCCURRED_TITLE,
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            variant: 'primary',
            loading: footerLoading,
            disabled: disableSave,
            title: saveLabel
        }
    ];

    return (
        <Dialog
            {...otherProps}
            className={joinClassNames('mui-form-dialog', className, isMigrated ? 'jd-green' : '')}
            closeHandler={closeHandler}
            footerActions={footerActions}
            open={true}
        />
    );
}

FormDialog.propTypes = {
    addToast: PropTypes.func,
    cancelLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    closeHandler: PropTypes.func.isRequired,
    disableSave: PropTypes.bool,
    footerLoading: PropTypes.bool,
    isMigrated: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    saveLabel: PropTypes.string.isRequired,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        }
    };
}

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
