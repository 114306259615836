// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';
import {joinClassNames} from 'Utils/html-utils';
import {createIconFillStyle} from 'Utils/icon-utils';
import {IconCheckmark} from '@deere/icons';
import {connect} from 'react-redux';
import {Checkbox, FormControlLabel} from '@deere/isg.component-library';

function getCheckmarkStyle(size, checked) {
    return {
        style: {
            height: size,
            opacity: checked ? '1' : '0',
            width: size
        }
    };
}

function CheckboxInput(props) {
    const {
        checked,
        className,
        disabled,
        fill,
        isMigrated,
        label,
        name,
        onChange,
        size
    } = props;

    const baseClassName = clsx('checkbox-input', {
        checked,
        disabled
    });

    if (isMigrated) {
        return (
            <FormControlLabel
                className='checkbox-label'
                control={<Checkbox
                    checked={checked}
                    className='checkbox-input-box'
                    disabled={disabled}
                    onClick={disabled ? null : () => onChange(name, !checked)}
                />}
                label={label}
            />
        );
    }

    return (
        <div className={joinClassNames(baseClassName, className)}>
            <div
                className='checkbox-input-box'
                onClick={disabled ? null : () => onChange(name, !checked)}
            >
                <IconCheckmark
                    iconCheckmark={getCheckmarkStyle(size, checked)}
                    primary={createIconFillStyle(fill)}
                />
            </div>
            {
                label &&
                <label className='checkbox-label'>
                    {label}
                </label>
            }
        </div>
    );
}

CheckboxInput.defaultProps = {
    checked: false,
    fill: '#fff',
    size: '20px'
};

CheckboxInput.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fill: PropTypes.string,
    isMigrated: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(CheckboxInput);
