// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import Panel from 'Ui/components/panel/panel';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import OnLinkProgressBar from 'Ui/components/common/onlink-progress-bar';
import {useDeepMemo} from 'Utils/react-utils';
import {redirect} from 'Utils/redirect-utils';
import {dueColumnCompare, formatDateSelect, getPercentClassName, getPercentDue} from 'Ui/models/maintenance';

const NUM_ROWS = 5;
const DECIMAL_TO_PERCENT = 100;

function createRows(maintenanceDue, history) {
    const formattedMaintenanceDue = maintenanceDue.map((maintenance) => ({
        ...maintenance,
        percentDue: getPercentDue(maintenance),
        atDateSelect: formatDateSelect(maintenance)
    }));
    const sortedMaintenanceDue = formattedMaintenanceDue.sort(dueColumnCompare);
    const first5MaintenanceDue = sortedMaintenanceDue.slice(0, NUM_ROWS);

    return first5MaintenanceDue.map((maintenance) => {
        const progressClassName = getPercentClassName(maintenance);
        const progressBar = (
            <OnLinkProgressBar
                barClassName='maintenance-progress-bar'
                fillClassName={progressClassName}
                percentFilled={Math.min(1, maintenance.percentDue) * DECIMAL_TO_PERCENT}
            />
        );

        const serviceUrl = `/equipment/suggestedMaintenance/serviceForm?serviceId=${maintenance.serviceId}`;

        const equipmentName = (
            <Tooltip
                placement='bottom'
                title={maintenance.equipmentName}
            >
                <div className='basic-table-cell-block'>
                    {maintenance.equipmentName}
                </div>
            </Tooltip>
        );

        const serviceTypeName = (
            <EditLink onClick={() => redirect(serviceUrl, history)}>
                {maintenance.serviceTypeName}
            </EditLink>
        );

        return [
            equipmentName,
            maintenance.modelName,
            serviceTypeName,
            progressBar
        ];
    });
}

function MaintenanceDue(props) {
    const {
        history,
        tile: maintenanceDue,
        translations
    } = props;

    const headers = [
        translations.NAME,
        translations.MODEL,
        translations.ONLINK_SERVICE,
        ''
    ];

    const rows = useDeepMemo(() => createRows(maintenanceDue, history), [maintenanceDue]);

    return (
        <Panel
            title={translations.ONLINK_MAINTENANCE_DUE}
            translations={translations}
        >
            {
                rows.length > 0 ? (
                    <BasicTable
                        className='maintenance-due-values'
                        headers={headers}
                        rows={rows}
                        vertical={true}
                    />
                ) : <NoDataMessage translations={translations}/>
            }
        </Panel>
    );
}

MaintenanceDue.propTypes = {
    history: PropTypes.history,
    tile: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export default withRouter(MaintenanceDue);
