// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MediaQuery from 'Ui/components/higher-order-components/media-query';
import OperatorItem from 'Ui/features/workboard-wizard/assignments/operator-item';
import {useDeepMemo, useLazyRef} from 'Utils/react-utils';
import {ASSIGNMENT_MOBILE_MEDIA_QUERY} from 'Ui/features/workboard-wizard/constants/assignment-media-query';
import {changeOperatorLayout, getByOperatorLayout} from 'Ui/features/workboard-wizard/assignments/utils/job-grid-layouts';
import {WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {onDragStart, onDragStop} from 'Utils/scroll-utils';
import GridLayoutWithWidth from 'Ui/components/common/grid-layout-with-width';
import {sortBy} from 'lodash';

const GRID_MARGIN = 10;

function OperatorAssignmentsForm(props) {
    const {
        collapseStatus,
        equipmentByType,
        featureToggles,
        fleetEquipment,
        isAssignmentMobile,
        isMultiline,
        membership,
        newestWorkItemIdRef,
        noteHeight,
        operators,
        screenTouchListener,
        screenTouchTimerRef,
        selectedIds: selectedOperatorIds,
        setCollapseStatus,
        setOperators,
        setValid,
        setValues,
        templates,
        templatesById,
        translations,
        values,
        readOnly
    } = props;

    const orderRef = useLazyRef(() => sortBy(values.userOrder, 'seq').map((user) => user.userId));

    const templateItems = useDeepMemo(() => templates.map((template) => ({
        id: template.jobTemplateId,
        title: `${template.jobCategory} - ${template.title}`
    })), [templates]);

    const equipmentInUseMap = useLazyRef(() => selectedOperatorIds.reduce((equipmentOperatorMap, appUserId) => {
        return values.workItemsByOperator[appUserId].reduce((equipmentWorkItemMap, workItem) => {
            workItem.equipment?.forEach((equipment) => {
                if (equipment.equipmentId) {
                    equipmentWorkItemMap.set(equipment.equipmentId, workItem.workItemId);
                }
            });

            return equipmentWorkItemMap;
        }, equipmentOperatorMap);
    }, new Map()));

    if (selectedOperatorIds.length === 0) {
        return null;
    }

    return (
        <GridLayoutWithWidth
            cols={1}
            draggableHandle='.icon-job-orderable'
            isDraggable={!readOnly}
            isResizable={false}
            layout={getByOperatorLayout(
                values.workItemsByOperator,
                values.userOrder,
                collapseStatus,
                isAssignmentMobile,
                featureToggles[WORKBOARD_REFACTOR],
                readOnly,
                noteHeight
            )}
            margin={[0, GRID_MARGIN]}
            onDragStart={(layout, oldItem) => onDragStart(oldItem.i, screenTouchListener)}
            onDragStop={(layout, oldItem) => onDragStop(oldItem.i, screenTouchListener, screenTouchTimerRef)}
            onLayoutChange={(currentLayout) => changeOperatorLayout(currentLayout, orderRef, setValues)}
            rowHeight={115}
        >
            {
                selectedOperatorIds.map((appUserId) => {
                    const activeOperatorIndex = operators.operatorsMasterList.findIndex((operator) => operator.appUserId === appUserId);
                    const allOperatorIndex = operators.allOperators.findIndex((operator) => operator.appUserId === appUserId);

                    return (
                        <div
                            className='assignment-container end-margin'
                            key={appUserId}
                        >
                            <OperatorItem
                                activeOperatorIndex={activeOperatorIndex}
                                allOperatorIndex={allOperatorIndex}
                                allWorkItemsByOperator={values.workItemsByOperator}
                                appUserId={appUserId}
                                collapseStatus={collapseStatus}
                                equipmentByType={equipmentByType}
                                equipmentInUseMap={equipmentInUseMap}
                                featureToggles={featureToggles}
                                fleetEquipment={fleetEquipment}
                                isAssignmentMobile={isAssignmentMobile}
                                isMultiline={isMultiline}
                                membership={membership}
                                newestWorkItemIdRef={newestWorkItemIdRef}
                                noteHeight={noteHeight}
                                operator={operators.operatorsMasterList[activeOperatorIndex]}
                                readOnly={readOnly}
                                screenTouchListener={screenTouchListener}
                                screenTouchTimerRef={screenTouchTimerRef}
                                setCollapseStatus={setCollapseStatus}
                                setOperators={setOperators}
                                setValid={setValid}
                                setValues={setValues}
                                templateItems={templateItems}
                                templatesById={templatesById}
                                translations={translations}
                                values={values}
                                workItemsForOperator={values.workItemsByOperator[appUserId]}
                            />
                        </div>
                    );
                })
            }
        </GridLayoutWithWidth>
    );
}

OperatorAssignmentsForm.propTypes = {
    collapseStatus: PropTypes.instanceOf(Map),
    equipmentByType: PropTypes.instanceOf(Map),
    featureToggles: PropTypes.featureToggles,
    fleetEquipment: PropTypes.arrayOf(PropTypes.equipment),
    isAssignmentMobile: PropTypes.bool,
    isMultiline: PropTypes.bool,
    membership: PropTypes.membership,
    newestWorkItemIdRef: PropTypes.reference,
    noteHeight: PropTypes.number,
    operators: PropTypes.object,
    readOnly: PropTypes.bool,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    setCollapseStatus: PropTypes.func,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    templates: PropTypes.arrayOf(PropTypes.object),
    templatesById: PropTypes.instanceOf(Map),
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default MediaQuery(ASSIGNMENT_MOBILE_MEDIA_QUERY)(OperatorAssignmentsForm);
