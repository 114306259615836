// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import GraphDateFilterWrapper from 'Ui/components/graph/graph-date-filter-wrapper';
import GraphFilter from 'Ui/components/graph/common/graph-filter';
import {getMemberships} from 'Utils/graph-utils';
import {BY_LOCATION, BY_TYPE} from 'Ui/components/graph/constants/graph-filters';
import {sortBy} from 'lodash';

function buildDropDownOptionsList(membership, dataTypes, primarySelector, translations) {
    const memberships = getMemberships(membership);
    const sortedDataTypes = sortBy(dataTypes, 'title');

    if (primarySelector === BY_LOCATION) {
        return {
            secondaryFilterData: memberships,
            tertiaryAllItemsLabel: translations.ONLINK_ALL_TYPES,
            tertiaryFilterData: sortedDataTypes,
            tertiaryMultipleItemsSelectedLabel: translations.ONLINK_TYPES
        };
    }

    return {
        secondaryFilterData: sortedDataTypes,
        tertiaryAllItemsLabel: translations.ONLINK_ALL_LOCATIONS,
        tertiaryFilterData: memberships,
        tertiaryMultipleItemsSelectedLabel: translations.ONLINK_LOCATIONS
    };
}

function GraphFilters(props) {
    const {
        dataTypes,
        membership,
        primarySelector,
        secondarySelector,
        tertiarySelectors,
        tileType,
        timeScale,
        translations,
        updateDateFilter,
        updatePrimarySelector,
        updateSecondarySelector,
        updateTertiarySelector
    } = props;

    const primaryFilterData = [
        {
            id: BY_LOCATION,
            title: translations.ONLINK_FILTER_LOCATION
        }, {
            id: BY_TYPE,
            title: translations.ONLINK_FILTER_TYPE
        }
    ];

    const {
        secondaryFilterData,
        tertiaryAllItemsLabel,
        tertiaryFilterData,
        tertiaryMultipleItemsSelectedLabel
    } = buildDropDownOptionsList(membership, dataTypes, primarySelector, translations);

    return (
        <Stack
            className='graph-filters'
            direction='row'
        >
            <GraphDateFilterWrapper
                handler={updateDateFilter}
                tileType={tileType}
                timeScale={timeScale}
                translations={translations}
            />
            <GraphFilter
                handler={updatePrimarySelector}
                items={primaryFilterData}
                noSearch={true}
                selectedIds={[primarySelector]}
                single={true}
            />
            <GraphFilter
                handler={updateSecondarySelector}
                items={secondaryFilterData}
                noSearch={false}
                selectedIds={[secondarySelector]}
                single={true}
            />
            <GraphFilter
                handler={updateTertiarySelector}
                items={tertiaryFilterData}
                labels={{
                    allSelect: tertiaryAllItemsLabel,
                    multiSelect: tertiaryMultipleItemsSelectedLabel,
                    selectAllItems: tertiaryAllItemsLabel
                }}
                noSearch={false}
                selectedIds={tertiarySelectors}
                single={false}
            />
        </Stack>
    );
}

GraphFilters.propTypes = {
    dataTypes: PropTypes.arrayOf(PropTypes.object),
    membership: PropTypes.membership,
    primarySelector: PropTypes.string,
    secondarySelector: PropTypes.string,
    tertiarySelectors: PropTypes.arrayOf(PropTypes.string),
    tileType: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations,
    updateDateFilter: PropTypes.func,
    updatePrimarySelector: PropTypes.func,
    updateSecondarySelector: PropTypes.func,
    updateTertiarySelector: PropTypes.func
};

export default GraphFilters;
