// Unpublished Work © 2020-2024 Deere & Company.

import appAccess from 'Common/constants/app-access';
import {VIEW_COURSE_MEASUREMENT_DATA, VIEW_FINANCIAL_DATA} from 'Common/constants/business-activities';

const FERT_CHEM_0_ID = '2b9b71f3-a733-4c15-811b-d81f3f96c678';
const FERT_CHEM_1_ID = 'd7f5990b-2677-4503-91ee-e5032cbb0da3';
const FERT_CHEM_2_ID = '38eca2ec-d143-4b05-8beb-23ab729f9e85';

const FERT_CHEM_URL = 'panelTypes/fert_chem';
const FERT_CHEM_TITLE = 'Fertilizer & Chemical';

import {graphRedirect, playBooksRedirect} from 'Utils/redirect-utils';

export default {
    [FERT_CHEM_0_ID]: {
        data: [],
        integration: 'PlayBooks',
        appAccess: appAccess.ONAPPLY,
        name: 'fert_chem_0',
        onClick: playBooksRedirect,
        tileId: FERT_CHEM_0_ID,
        url: FERT_CHEM_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    [FERT_CHEM_1_ID]: {
        data: [
            'fert_cost',
            'chem_cost'
        ],
        dateSelect: 'year',
        dateSelectionTypes: ['year'],
        graphTitle: FERT_CHEM_TITLE,
        name: 'fert_chem_1',
        onClick: graphRedirect,
        tileId: FERT_CHEM_1_ID,
        url: FERT_CHEM_URL,
        myJdPermissions: VIEW_FINANCIAL_DATA
    },
    [FERT_CHEM_2_ID]: {
        data: [
            'fert_cost',
            'chem_cost'
        ],
        dateSelect: 'year',
        dateSelectionTypes: ['year'],
        graphTitle: FERT_CHEM_TITLE,
        name: 'fert_chem_2',
        onClick: graphRedirect,
        tileId: FERT_CHEM_2_ID,
        url: FERT_CHEM_URL,
        myJdPermissions: VIEW_FINANCIAL_DATA
    }
};
