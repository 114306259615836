// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';

export function addDividers(children, dividerClassName) {
    const childrenOrDefault = children || [];

    return childrenOrDefault.reduce((childrenWithDividers, currentChild, index) => {
        if (childrenWithDividers.length > 0) {
            return [
                ...childrenWithDividers,
                <span
                    className={dividerClassName}
                    key={`divider${index}`}
                />,
                currentChild
            ];
        }

        return [currentChild];
    }, []);
}
