// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {useDeepEffect} from 'Utils/react-utils';

function GraphFilter(props) {
    const {
        handler,
        items,
        labels,
        noSearch,
        selectedIds,
        single,
        width
    } = props;

    const isNothingSelected = selectedIds.length === 0 ||
        selectedIds.some((selectedId) => items.every(({id}) => selectedId !== id));

    useDeepEffect(() => {
        if (isNothingSelected && items.length > 0) {
            const [{id}] = items;
            const initialSelectedId = single ? id : [id];

            handler(initialSelectedId);
        }
    }, [isNothingSelected, items]);

    const onChangeCallback = React.useCallback((ids) => {
        if (!single) {
            handler(ids);
        } else if (ids.length > 0) {
            handler(ids[0]);
        }
    }, [handler, single]);

    if (isNothingSelected) {
        return null;
    }

    return (
        <MultiSelect
            disableSorting={true}
            items={items}
            labels={{
                ...labels,
                placeholder: items[0].title
            }}
            noSearch={noSearch}
            onChange={onChangeCallback}
            selectedIds={selectedIds}
            single={single}
            width={width}
        />
    );
}

GraphFilter.defaultProps = {
    items: [],
    labels: {},
    width: 200
};

GraphFilter.propTypes = {
    handler: PropTypes.func,
    items: PropTypes.multiSelectList,
    labels: PropTypes.object,
    noSearch: PropTypes.bool,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    single: PropTypes.bool,
    width: PropTypes.number
};

export default GraphFilter;
