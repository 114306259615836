// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import LoadingIcon from 'Ui/components/common/loading-icon';

function LoadingWrapper(props) {
    const {
        alwaysRenderChildren,
        children,
        className,
        fill,
        loading,
        size
    } = props;

    if (loading) {
        const loadingIcon = (
            <LoadingIcon
                className={className}
                fill={fill}
                size={size}
            />
        );

        if (alwaysRenderChildren) {
            return (
                <>
                    {children}
                    {loadingIcon}
                </>
            );
        }

        return loadingIcon;
    }

    return children;
}

LoadingWrapper.defaultProps = {
    alwaysRenderChildren: false
};

LoadingWrapper.propTypes = {
    alwaysRenderChildren: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    fill: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired
};

export default LoadingWrapper;
