// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import {joinClassNames} from 'Utils/html-utils';

function InfoBoxText(props) {
    const {
        children,
        className,
        label,
        text
    } = props;

    return (
        <Stack direction='row'>
            <div className='info-box-text-container'>
                <span className='bold-body-text-section-title'>
                    {label}
                </span>
                <span className={joinClassNames('info-box-text', className)}>
                    {text}
                </span>
            </div>
            {children}
        </Stack>
    );
}

InfoBoxText.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    text: PropTypes.stringOrNumber.isRequired
};

export default InfoBoxText;
