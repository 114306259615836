// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {getMembershipById} from 'Services/membership-service';
import WorkboardPresentation from 'Ui/pages/onlabor/workboards/presentation/presentation';
import {VIEW_WORKBOARDS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import ErrorView from 'Ui/components/dashboard/error-view';
import {fetchEffectData} from 'Utils/react-utils';
import {refreshMyJdPermissions} from 'Services/session-service';

function PresentationWrapper(props) {
    const {
        translations,
        match,
        wrapperRef
    } = props;

    const [hasPermissionToViewPresentation, setHasPermissionToViewPresentation] = React.useState(false);
    const [workboardMembership, setWorkboardMembership] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const {membershipId} = match.params;

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {membership} = await getMembershipById(membershipId);

        let myJdPermissions = {};

        if (membership.isMigrated) {
            myJdPermissions = await refreshMyJdPermissions();
        }

        const userAuth = {
            isMigrated: membership.isMigrated,
            myJdPermissions
        };

        const canViewPresentationPage = isAuthorized({
            myJdPermissions: VIEW_WORKBOARDS
        }, userAuth);

        if (isMounted()) {
            setWorkboardMembership(membership);
            setHasPermissionToViewPresentation(canViewPresentationPage);
            setLoading(false);
        }
    }), [membershipId]);

    if (hasPermissionToViewPresentation) {
        return (
            <WorkboardPresentation
                translations={translations}
                workboardMembership={workboardMembership}
                wrapperRef={wrapperRef}
            />
        );
    }

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            <ErrorView translations={translations}/>
        </LoadingWrapper>
    );
}

PresentationWrapper.propTypes = {
    match: PropTypes.object,
    translations: PropTypes.translations,
    wrapperRef: PropTypes.reference
};

export default withRouter(PresentationWrapper);
