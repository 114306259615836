// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import EditLink from 'Ui/components/common/data-table/edit-link';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {IconRemove} from '@deere/icons';
import {joinClassNames} from 'Utils/html-utils';

const ICON_REMOVE_STYLE = {
    style: {
        width: '20px',
        height: '20px'
    }
};

function InputList(props) {
    const {
        addItemLabel,
        children,
        headerClassName,
        headerLabel,
        listClassName,
        onAdd,
        onDelete
    } = props;

    return (
        <>
            {
                headerLabel &&
                <h4 className={joinClassNames('input-list-header', headerClassName)}>
                    {headerLabel}
                </h4>
            }
            <div className={joinClassNames('input-list', listClassName)}>
                {
                    React.Children.map(children, (child, index) => (
                        <div className='input-list-block'>
                            <div className='input-list-items'>
                                {child}
                            </div>
                            <OnLinkIconButton onClick={(event) => onDelete(event, index)}>
                                <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
                            </OnLinkIconButton>
                        </div>
                    ))
                }
                <div className='input-list-add'>
                    <EditLink onClick={onAdd}>
                        {`${addItemLabel}...`}
                    </EditLink>
                </div>
            </div>
        </>
    );
}

InputList.propTypes = {
    addItemLabel: PropTypes.string,
    children: PropTypes.node,
    headerClassName: PropTypes.string,
    headerLabel: PropTypes.string,
    listClassName: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default InputList;
