// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconDelete} from '@deere/icons';
import ReactTable from 'react-table';
import PageJump from 'Ui/components/common/data-table/page-jump';
import SearchFilter from 'Ui/components/higher-order-components/search-filter';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import Search from 'Ui/components/search/search';
import {clsx} from 'clsx';
import 'react-table/react-table.css';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {connect} from 'react-redux';

const DEFAULT_PAGINATION_THRESHOLD = 100;

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function getColumns(columns, showDeleteColumn, deleteFilter, deleteHandler, myJdPermissions, isMigrated) {
    const filteredColumns = columns.filter((item) => isAuthorized({
        myJdPermissions: item.requiredMyJdPermissions
    }, {
        myJdPermissions,
        isMigrated
    }));

    if (showDeleteColumn) {
        return [
            ...filteredColumns,
            {
                accessor: 'delete',
                className: 'delete-col',
                headerClassName: 'delete-col',
                resizable: false,
                sortable: false,
                Cell(row) {
                    const rowData = row.original;
                    const rowIndex = row.index;
                    const showDeleteIcon = deleteFilter ? deleteFilter(rowData, rowIndex) : true;

                    return (
                        showDeleteIcon &&
                        <OnLinkIconButton
                            onClick={() => deleteHandler(rowData, rowIndex)}
                        >
                            <IconDelete iconDelete={ICON_STYLE}/>
                        </OnLinkIconButton>
                    );
                }
            }
        ];
    }

    return filteredColumns;
}

function getTable(tableProps, translations, paginationThreshold) {
    if (tableProps.data.length === 0) {
        return (
            <h1 className='table-no-data'>
                {tableProps.noDataAvailableMessage || translations.NO_LOADED_DATA_AVAILABLE}
            </h1>
        );
    }

    return (
        <div className='react-table'>
            <ReactTable
                className='-striped -highlight'
                defaultPageSize={paginationThreshold}
                getPaginationProps={(state) => ({
                    nextText: `${translations.NEXT} >`,
                    previousText: `< ${translations.PREVIOUS}`,
                    style: {
                        minWidth: state.rowMinWidth
                    }
                })}
                minRows={0}
                renderPageJump={(pageJumpProps) => (
                    <PageJump {...pageJumpProps}/>
                )}
                {...tableProps}
            />
        </div>
    );
}

function DataTable(props) {
    const {
        additionalTableOptionRow,
        applySearch,
        className,
        columns,
        defaultSorted,
        deleteFilter,
        deleteHandler,
        filterComponent,
        headerComponent,
        legendComponent,
        loading,
        noDataAvailableMessage,
        onSearchChange,
        pivotBy,
        rows,
        search,
        searchable,
        searchFields,
        showDeleteColumn,
        showPagination,
        sideComponent,
        translations,
        myJdPermissions,
        isMigrated
    } = props;

    const paginationThreshold = props.paginationThreshold || DEFAULT_PAGINATION_THRESHOLD;

    const [pageSize, setPageSize] = React.useState(paginationThreshold);

    const tableProps = {
        columns: getColumns(columns, showDeleteColumn, deleteFilter, deleteHandler, myJdPermissions, isMigrated),
        data: applySearch(rows, searchFields),
        defaultSorted,
        noDataAvailableMessage,
        onPageSizeChange: setPageSize,
        pivotBy
    };

    tableProps.showPagination = tableProps.data.length > paginationThreshold && showPagination;

    if (tableProps.showPagination) {
        tableProps.pageSize = pageSize;
    } else {
        tableProps.pageSize = tableProps.data.length;
    }

    const containerClassName = clsx('table-container', className, {
        hidden: loading
    });

    const componentClassName = clsx('table-scroll', className);

    return (
        <div className={componentClassName}>
            {
                headerComponent &&
                <div className='table-header-container'>
                    {headerComponent}
                </div>
            }
            <div className='table-options'>
                {
                    searchable &&
                    <Search
                        handler={onSearchChange}
                        search={search}
                        translations={translations}
                    />
                }
                {filterComponent}
            </div>
            {additionalTableOptionRow}
            <LoadingWrapper
                alwaysRenderChildren={true}
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                <div className={containerClassName}>
                    {sideComponent}
                    {getTable(tableProps, translations, paginationThreshold)}
                    {legendComponent}
                </div>
            </LoadingWrapper>
        </div>
    );
}

DataTable.defaultProps = {
    columns: [],
    defaultSorted: [],
    loading: false,
    pivotBy: [],
    rows: [],
    showPagination: false
};

DataTable.propTypes = {
    additionalTableOptionRow: PropTypes.element,
    applySearch: PropTypes.func,
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    defaultSorted: PropTypes.arrayOf(PropTypes.object),
    deleteFilter: PropTypes.func,
    deleteHandler: PropTypes.func,
    filterComponent: PropTypes.element,
    headerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isMigrated: PropTypes.bool,
    legendComponent: PropTypes.element,
    loading: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    noDataAvailableMessage: PropTypes.string,
    onSearchChange: PropTypes.func,
    paginationThreshold: PropTypes.number,
    pivotBy: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.string,
    searchable: PropTypes.bool,
    searchFields: PropTypes.arrayOf(PropTypes.string),
    showDeleteColumn: PropTypes.bool,
    showPagination: PropTypes.bool,
    sideComponent: PropTypes.element,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(SearchFilter(DataTable));
