// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Iframe from 'react-iframe';
import {BasicDialog} from '@deere/basic-dialog';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function WorkboardPreviewDialog(props) {
    const {
        closeWorkboardPreviewDialog,
        previewUrl,
        translations
    } = props;

    return (
        <BasicDialog
            className='workboard-preview-dialog'
            closeHandler={closeWorkboardPreviewDialog}
            show={true}
            title={translations.ONLINK_WORKBOARD_PREVIEW}
        >
            <Iframe
                className='workboard-preview-iframe'
                url={previewUrl}
            />
        </BasicDialog>
    );
}

WorkboardPreviewDialog.propTypes = {
    closeWorkboardPreviewDialog: PropTypes.func,
    previewUrl: PropTypes.string,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeWorkboardPreviewDialog() {
            dispatch(closeDialog(dialogTypes.WORKBOARD_PREVIEW_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(WorkboardPreviewDialog);
