// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {alphaNumericCompare, dateCompare, numericCompare} from 'Ui/models/maintenance';
import {fetchEffectData} from 'Utils/react-utils';
import {getInventories, getMaintenanceDue} from 'Services/membership-service';
import {getPurchaseOrders} from 'Services/inventory-service';
import {COMPLETED} from 'Common/constants/service-status';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import ComponentLibraryDataTableWrapper from 'Ui/components/common/data-table/component-library-datatable-wrapper';
import {DateRangeChip, FilterChipList, MultiselectChip} from '@deere/isg.component-library';
import {uniqBy} from 'lodash';
import {formatDateOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {VIEW_PARTS} from 'Common/constants/business-activities';
import {IconDownload} from '@deere/icons';
import {exportPartsHistoryReport} from 'Services/excel-service';

function getFilterChipData(data, titleProperty) {
    return uniqBy(data.map((dataEntry) => ({
        id: dataEntry[titleProperty],
        title: dataEntry[titleProperty]
    })), 'id').filter((entry) => entry.id);
}

async function fetchPartsHistoryData(isMounted, setLoading, setPartHistoryData, setFilterOptions, translations) {
    setLoading(true);

    const {inventories} = await getInventories();

    const [
        {purchaseOrders},
        services
    ] = await Promise.all([
        getPurchaseOrders(inventories[0].inventoryId),
        getMaintenanceDue(COMPLETED)
    ]);

    const partsHistoryFromPurchaseOrders = purchaseOrders
        .filter((purchaseOrder) => purchaseOrder.status === 'completed')
        .flatMap((purchaseOrder) => {
            if (purchaseOrder.partsMap) {
                return Object.values(purchaseOrder.partsMap).map((part) => ({
                    adjustment: part.quantityReceived,
                    date: formatDateOrDefault(purchaseOrder.dateSelect),
                    detailsType: translations.ONLINK_PURCHASE_ORDER,
                    detailsName: purchaseOrder.referenceNumber,
                    manufacturer: part.manufacturerName,
                    name: part.name,
                    partNumber: part.partNumber,
                    partType: part.partType
                }));
            }

            return [];
        });

    const partsHistoryServiceTickets = services
        .flatMap((serviceTicket) => {
            return serviceTicket.partsList?.map((part) => ({
                adjustment: `-${part.quantity}`,
                date: formatDateOrDefault(serviceTicket.timeCompleted) || formatDateOrDefault(serviceTicket.dateScheduled),
                detailsType: translations.ONLINK_SERVICE_TICKET,
                detailsName: serviceTicket.serviceTypeName,
                manufacturer: part.manufacturerName,
                name: part.name,
                partNumber: part.partNumber,
                partType: part.partType
            })) || [];
        });

    const partsHistoryData = [...partsHistoryFromPurchaseOrders, ...partsHistoryServiceTickets];

    const manufacturerFiltersOptions = getFilterChipData(partsHistoryData, 'manufacturer');
    const nameFiltersOptions = getFilterChipData(partsHistoryData, 'name');
    const partNumberFiltersOptions = getFilterChipData(partsHistoryData, 'partNumber');
    const partTypeFiltersOptions = getFilterChipData(partsHistoryData, 'partType');

    if (isMounted) {
        setPartHistoryData(partsHistoryData);
        setFilterOptions({
            manufacturerFiltersOptions,
            nameFiltersOptions,
            partNumberFiltersOptions,
            partTypeFiltersOptions
        });
        setLoading(false);
    }
}

function getFilters(appliedFilters, setAppliedFilters, filterOptions, translations) {
    return (
        <FilterChipList>
            <DateRangeChip
                onChange={(dateRange) => setAppliedFilters((prevFilters) => ({
                    ...prevFilters,
                    date: dateRange
                }))}
                title={translations.DATE_RANGE}
                value={appliedFilters.date}
            />
            <MultiselectChip
                autoFocus={true}
                enableVirtualization={true}
                items={filterOptions.nameFiltersOptions}
                onChange={(selectedIds) => setAppliedFilters((prevFilters) => ({
                    ...prevFilters,
                    name: selectedIds
                }))}
                pluralTitle={translations.ONLINK_NAMES}
                selectedIds={appliedFilters.name}
                title={translations.NAME}
            />
            <MultiselectChip
                autoFocus={true}
                enableVirtualization={true}
                items={filterOptions.partNumberFiltersOptions}
                onChange={(selectedIds) => setAppliedFilters((prevFilters) => ({
                    ...prevFilters,
                    partNumber: selectedIds
                }))}
                pluralTitle={translations.ONLINK_PART_NUMBERS}
                selectedIds={appliedFilters.partNumber}
                title={capitalizeFirstLetter(translations.PART_NUMBER)}
            />
            <MultiselectChip
                autoFocus={true}
                enableVirtualization={true}
                items={filterOptions.partTypeFiltersOptions}
                onChange={(selectedIds) => setAppliedFilters((prevFilters) => ({
                    ...prevFilters,
                    partType: selectedIds
                }))}
                pluralTitle={translations.ONLINK_PART_TYPES}
                selectedIds={appliedFilters.partType}
                title={translations.ONLINK_PART_TYPE}
            />
            <MultiselectChip
                autoFocus={true}
                enableVirtualization={true}
                items={filterOptions.manufacturerFiltersOptions}
                onChange={(selectedIds) => setAppliedFilters((prevFilters) => ({
                    ...prevFilters,
                    manufacturer: selectedIds
                }))}
                pluralTitle={translations.ONLINK_MANUFACTURERS}
                selectedIds={appliedFilters.manufacturer}
                title={translations.MANUFACTURER}
            />
        </FilterChipList>
    );
}

function getColumns(translations) {
    return [
        {
            Header: translations.DATE,
            accessor: 'date',
            width: '110px',
            sortType(a, b) {
                return dateCompare(a.values.date, b.values.date);
            }
        },
        {
            Header: translations.NAME,
            accessor: 'name',
            width: '150px'
        },
        {
            Header: capitalizeFirstLetter(translations.PART_NUMBER),
            accessor: 'partNumber',
            width: '120px'
        },
        {
            Header: translations.DETAILS,
            accessor: 'details',
            Cell: (row) => {
                return (
                    <Box>
                        <Typography>
                            {row.row.original.detailsType}
                        </Typography>
                        <Typography>
                            {row.row.original.detailsName}
                        </Typography>
                    </Box>
                );
            },
            sortType(a, b) {
                if (a.original.detailsType !== b.original.detailsType) {
                    return alphaNumericCompare(a.original.detailsType, b.original.detailsType);
                }

                return alphaNumericCompare(a.original.detailsName, b.original.detailsName);
            },
            width: '190px'
        },
        {
            Header: translations.ONLINK_PART_TYPE,
            accessor: 'partType',
            width: '180px'
        },
        {
            Header: translations.MANUFACTURER,
            accessor: 'manufacturer',
            width: '150px'
        },
        {
            Header: translations.ONLINK_ADJUSTMENT,
            accessor: 'adjustment',
            sortType(a, b) {
                return numericCompare(parseInt(a.values.adjustment, 10), parseInt(b.values.adjustment, 10));
            },
            width: '110px'
        }
    ];
}

function History(props) {
    const {
        membership,
        translations
    } = props;

    const [partHistoryData, setPartHistoryData] = React.useState([]);
    const filteredDataRef = React.useRef();
    const [appliedFilters, setAppliedFilters] = React.useState({
        date: [],
        manufacturer: [],
        name: [],
        partNumber: [],
        partType: []
    });
    const [filterOptions, setFilterOptions] = React.useState({
        manufacturerFiltersOptions: [],
        nameFiltersOptions: [],
        partNumberFiltersOptions: [],
        partTypeFiltersOptions: []
    });
    const [loading, setLoading] = React.useState(true);

    const columns = getColumns(translations);
    const filters = getFilters(appliedFilters, setAppliedFilters, filterOptions, translations);

    React.useEffect(() => fetchEffectData(async (isMounted) =>
        fetchPartsHistoryData(isMounted(), setLoading, setPartHistoryData, setFilterOptions, translations)),
    [membership.membershipId]
    );

    useNavBarActions([{
        disabled: loading,
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportPartsHistoryReport(filteredDataRef.current, translations),
        requiredMyJdPermissions: VIEW_PARTS,
        title: 'EXPORT',
        variant: 'primary'
    }]);

    return (
        <ComponentLibraryDataTableWrapper
            appliedFilters={appliedFilters}
            columns={columns}
            filters={filters}
            initialState={{
                sortBy: [
                    {
                        id: 'date',
                        desc: true
                    },
                    {
                        id: 'name',
                        desc: false
                    }
                ]
            }}
            loading={loading}
            rows={partHistoryData}
            setFilteredData={(filteredData) => {
                filteredDataRef.current = filteredData;
            }}
            translations={translations}
        />
    );
}

History.propTypes = {
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(History);
