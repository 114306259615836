// Unpublished Work © 2022-2024 Deere & Company.

import SwitchInput from 'Ui/components/common/form/switch-input';
import React from 'react';
import {exportFleetReport} from 'Services/excel-service';
import {FLEET} from 'Common/constants/routes';
import AdditionalActionButton from 'OnEquip/equipment/common/additional-action-button';
import {IconAdd, IconDownload, IconMachineHours, IconTerminal, IconWrench} from '@deere/icons';
import {createIconFillStyle} from 'Utils/icon-utils';
import {DropdownMenu} from '@deere/isg.component-library/lib/DropdownMenu/DropdownMenu';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';

const ADD_ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px',
        fill: '#fff'
    }
};

const FILTER_BUTTONS_ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const ICON_STYLE = {
    style: {
        height: '15px',
        width: '15px'
    }
};

function getAdditionalActionButtons(
    fleet,
    loading,
    translations,
    openBulkEngineHoursDialog,
    onAddEquipmentClick,
    history,
    isMobile
) {
    if (isMobile) {
        const items = [
            {
                title: translations.EXPORT,
                onClick: () => exportFleetReport(fleet, translations)
            },
            {
                title: translations.ONLINK_SERVICE,
                onClick: () => history.push(`${FLEET}/serviceForm`)
            },
            {
                title: translations.ONLINK_UPDATE_HOURS,
                onClick: openBulkEngineHoursDialog
            },
            {
                title: translations.equipment_add_equipment,
                onClick: onAddEquipmentClick
            }
        ];

        return (
            <DropdownMenu
                buttonTitle={translations.ACTIONS}
                className='onlink-action-dropdown onequip-fleet-action-dropdown'
                items={items}
            />
        );
    }

    return (
        <div className='table-buttons'>
            <AdditionalActionButton
                className='export-report'
                disabled={loading}
                label={translations.EXPORT}
                onClick={() => exportFleetReport(fleet, translations)}
            >
                <IconDownload iconDownload={FILTER_BUTTONS_ICON_STYLE}/>
            </AdditionalActionButton>
            <AdditionalActionButton
                className='create-service'
                label={translations.ONLINK_SERVICE}
                onClick={() => history.push(`${FLEET}/serviceForm`)}
            >
                <IconWrench
                    iconWrench={FILTER_BUTTONS_ICON_STYLE}
                    primary={createIconFillStyle('#f6a623')}
                />
            </AdditionalActionButton>
            <AdditionalActionButton
                className='update-hour'
                label={translations.ONLINK_UPDATE_HOURS}
                onClick={openBulkEngineHoursDialog}
            >
                <IconMachineHours iconMachineHours={FILTER_BUTTONS_ICON_STYLE}/>
            </AdditionalActionButton>
        </div>
    );
}

function getFilterComponent(
    fleet,
    loading,
    showArchived,
    setShowArchived,
    translations,
    openBulkEngineHoursDialog,
    onAddEquipmentClick,
    history,
    isMobile,
    featureToggles
) {
    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={showArchived}
                name='showArchived'
                onChange={() => setShowArchived(!showArchived)}
                onLabel={translations.ONLINK_SHOW_ARCHIVED}
                translations={translations}
            />
            {
                !featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
                getAdditionalActionButtons(fleet, loading, translations, openBulkEngineHoursDialog,
                    onAddEquipmentClick, history, isMobile)
            }
        </div>
    );
}

function getAdditionalTableOptionRow(translations, onAddEquipmentClick, isMobile) {
    if (!isMobile) {
        return (
            <OnlinkButton
                className='primary add-equipment-button'
                leftIcon={<IconAdd iconAdd={ADD_ICON_STYLE}/>}
                onClick={onAddEquipmentClick}
            >
                {translations.equipment_add_equipment}
            </OnlinkButton>
        );
    }

    return null;
}

function getFleetLegend(translations) {
    return (
        <div className='onequip-legend'>
            <IconTerminal iconTerminal={ICON_STYLE}/>
            <div className='telematics-enabled-text'>
                {translations.ONLINK_TELEMATICALLY_ENABLED}
            </div>
        </div>
    );
}

export {
    getFilterComponent,
    getAdditionalTableOptionRow,
    getFleetLegend
};
