// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import ServiceFormAuthWrapper from 'OnEquip/equipment/service-form/service-form-auth-wrapper';
import {getSearchParams} from 'OnEquip/equipment/utils/url-parser';
import {FLEET, SERVICE_HISTORY, SHOP, SUGGESTED_MAINTENANCE} from 'Common/constants/routes';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {EXPENSES, HISTORY, JOBS, MAINTENANCE, PARTS} from 'OnEquip/equipment/constants/equipment-details-tabs';

function getRedirectLinks(paramType) {
    const basePathLinks = {
        fleet: {
            path: FLEET
        },
        suggestedMaintenance: {
            path: SUGGESTED_MAINTENANCE
        },
        serviceHistory: {
            path: SERVICE_HISTORY
        },
        shop: {
            path: SHOP
        }
    };

    const equipmentDetailsPath = `${basePathLinks[paramType].path}/equipmentDetails/{tab}?equipmentId={equipmentId}`;

    return {
        fleet: {
            path: FLEET,
            reactRedirect: true
        },
        suggestedMaintenance: {
            path: SUGGESTED_MAINTENANCE,
            reactRedirect: true
        },
        serviceHistory: {
            path: SERVICE_HISTORY,
            reactRedirect: true
        },
        shop: {
            path: SHOP,
            reactRedirect: true
        },
        legacyEquipmentDetails: {
            path: `${window.props.onLinkLegacyUri}/onequip/#m/{membershipId}/fleet/{fleetId}/equipment/{equipmentId}`,
            reactRedirect: false
        },
        legacyShopPage: {
            path: `${window.props.onLinkLegacyUri}/onequip/#m/{membershipId}/shop`,
            reactRedirect: false
        },
        [MAINTENANCE]: {
            path: equipmentDetailsPath.replace('{tab}', MAINTENANCE),
            reactRedirect: true
        },
        [HISTORY]: {
            path: equipmentDetailsPath.replace('{tab}', HISTORY),
            reactRedirect: true
        },
        [JOBS]: {
            path: equipmentDetailsPath.replace('{tab}', JOBS),
            reactRedirect: true
        },
        [PARTS]: {
            path: equipmentDetailsPath.replace('{tab}', PARTS),
            reactRedirect: true
        },
        [EXPENSES]: {
            path: equipmentDetailsPath.replace('{tab}', EXPENSES),
            reactRedirect: true
        }
    };
}

function ServiceFormWrapper(props) {
    const {
        location,
        match,
        translations
    } = props;

    const {
        equipmentId,
        redirect,
        serviceId
    } = getSearchParams(location.search);

    const equipmentContext = useEquipmentContext();

    const redirectLinks = getRedirectLinks(match.params.type);
    const redirectLink = redirectLinks[redirect || match.params.type];

    return (
        <ServiceFormAuthWrapper
            equipmentId={equipmentId}
            onSaveHandler={equipmentContext.current}
            redirect={redirectLink}
            serviceId={serviceId}
            translations={translations}
        />
    );
}

ServiceFormWrapper.propTypes = {
    location: PropTypes.location,
    match: PropTypes.match,
    translations: PropTypes.translations
};

export default withRouter(ServiceFormWrapper);
