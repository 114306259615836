// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Input} from '@deere/form-controls';
import {IconSearch} from '@deere/icons';

const ICON_STYLE = {
    style: {
        height: '20px',
        left: '7px',
        position: 'absolute',
        top: '7px',
        width: '20px',
        zIndex: 1
    }
};

export default function Search(props) {
    const {
        handler,
        search,
        translations
    } = props;

    return (
        <div className='search-bar-div'>
            <IconSearch iconSearch={ICON_STYLE}/>
            <Input
                onChange={handler}
                onClear={(event) => {
                    event.target.value = '';

                    handler(event);
                }}
                placeholder={translations.SEARCH}
                tabIndex={0}
                value={search}
            />
        </div>
    );
}

Search.propTypes = {
    handler: PropTypes.func,
    search: PropTypes.string,
    translations: PropTypes.translations
};
