// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnlinkButton from 'Ui/components/common/onlink-button';
import OnLinkDialog from 'Ui/components/common/onlink-dialog/onlink-dialog';
import EditorContent from 'Ui/components/layout-editor/editor-content';
import {
    createLayout as createUserLayout,
    deleteLayout as deleteUserLayout,
    getUserLayouts,
    updateLayout as updateUserLayout
} from 'Services/user-view-service';
import {useDeepMemo, useLazyRef} from 'Utils/react-utils';
import {isTileVisible, mapTilePositionForSave} from 'Utils/tile-utils';
import {getLayoutById} from 'Utils/layout-utils';
import layoutGuids from 'Common/constants/layout-guids';
import {setActiveLayout as setActiveLayoutAction, setAvailableLayouts as setAvailableLayoutsAction} from 'Store/actions/account';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast} from 'Store/actions/toasts';
import dialogTypes from 'Ui/components/common/dialog-types';
import {isEqual, sortBy} from 'lodash';
import {v4} from 'uuid';
import {IconAdd} from '@deere/icons';
import {TOAST_TYPE} from '@deere/toast';

const ICON_ADD_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

async function toastOnError(request, addToast, setIsSaving, translations) {
    setIsSaving(true);

    try {
        await request();
    } catch (err) {
        setIsSaving(false);

        addToast({
            message: translations.ERROR_OCCURRED_TITLE,
            type: TOAST_TYPE.ERROR
        });
    }
}

function getCopyName(currentLayout, layouts, translations) {
    const baseName = `${currentLayout.name} - ${translations.COPY}`;

    for (let copyName = baseName, copyNum = 2; ; copyNum += 1) {
        if (layouts.every(({name}) => name !== copyName)) {
            return copyName;
        }

        copyName = `${baseName} ${copyNum}`;
    }
}

async function saveLayout(activeLayout, currentLayout, translations, setAvailableLayouts, setActiveLayout, closeDialog) {
    const layoutToSave = {
        name: currentLayout.name,
        tilePositions: currentLayout.tilePositions
    };

    currentLayout.isNew ?
        await createUserLayout(layoutToSave) :
        await updateUserLayout({
            ...layoutToSave,
            id: currentLayout.id
        });

    const {availableLayouts} = await getUserLayouts(translations);

    setAvailableLayouts(availableLayouts);

    if (currentLayout.id === activeLayout.id) {
        setActiveLayout(getLayoutById(availableLayouts, activeLayout.id));
    }

    closeDialog();
}

function createEditableLayout(layout) {
    return {
        ...layout,
        tilePositions: layout.tilePositions.map(mapTilePositionForSave)
    };
}

function createContent(availableLayouts, translations, editorContentProps) {
    const layoutsByType = availableLayouts.reduce((layoutMap, layout) => {
        const layoutItem = {
            element: (
                <EditorContent
                    {...editorContentProps}
                    currentLayout={layout}
                    translations={translations}
                />
            ),
            id: layout.id,
            label: layout.name
        };

        if (layout.onlinkUserId) {
            if (!layoutMap.has('custom')) {
                layoutMap.set('custom', []);
            }

            layoutMap.get('custom').push(layoutItem);
        } else {
            if (!layoutMap.has('default')) {
                layoutMap.set('default', []);
            }

            layoutMap.get('default').push(layoutItem);
        }

        return layoutMap;
    }, new Map());

    const content = [
        {
            subHeader: translations.DEFAULT
        },
        ...layoutsByType.get('default')
    ];

    if (layoutsByType.has('custom')) {
        return [
            ...content,
            {
                subHeader: translations.CUSTOM
            },
            ...layoutsByType.get('custom')
        ];
    }

    return content;
}

function initializeStateAndRefs(activeLayout, availableLayouts, userAuth) {
    const [currentAvailableLayouts, setCurrentAvailableLayouts] = React.useState(availableLayouts);
    const [currentLayoutId, setCurrentLayoutId] = React.useState(activeLayout.id);
    const [isSaving, setIsSaving] = React.useState(false);

    const isDirty = React.useRef(false);
    const currentLayout = useLazyRef(() => {
        activeLayout.tilePositions = activeLayout.tilePositions
            .filter(({tile: {id}}) => isTileVisible(id, userAuth));

        return createEditableLayout(activeLayout);
    });

    return {
        currentAvailableLayouts,
        currentLayout,
        currentLayoutId,
        isDirty,
        isSaving,
        setCurrentAvailableLayouts,
        setCurrentLayoutId,
        setIsSaving
    };
}

function LayoutEditDialog(props) {
    const {
        activeLayout,
        addToast,
        availableLayouts,
        closeConfirmation,
        closeDialog,
        isMigrated,
        myJdPermissions,
        featureToggles,
        openConfirmation,
        openTileEdit,
        setActiveLayout,
        setAvailableLayouts,
        translations
    } = props;

    const userAuth = React.useMemo(() => ({
        featureToggles,
        isMigrated,
        myJdPermissions
    }), [featureToggles, isMigrated, myJdPermissions]);

    const {
        currentAvailableLayouts,
        currentLayout,
        currentLayoutId,
        isDirty,
        isSaving,
        setCurrentAvailableLayouts,
        setCurrentLayoutId,
        setIsSaving
    } = initializeStateAndRefs(activeLayout, availableLayouts, userAuth);

    function createLayoutEditorCallbacks() {
        function openConfirmationDialog({
            message, title, onContinue
        }) {
            openConfirmation({
                message,
                title,
                async onContinue() {
                    await onContinue();
                    closeConfirmation();
                },
                onCancel: closeConfirmation
            });
        }

        function createLayout(newAvailableLayouts, tilePositions) {
            const createdLayout = {
                id: v4(),
                isNew: true,
                name: '',
                onlinkUserId: 'currentUser',
                tilePositions: tilePositions.filter(({tile: {id}}) => isTileVisible(id, userAuth))
            };

            isDirty.current = true;
            currentLayout.current = createEditableLayout(createdLayout);

            setCurrentAvailableLayouts([
                ...newAvailableLayouts,
                {
                    ...createdLayout,
                    name: translations.NEW_DASHBOARD
                }
            ]);
            setCurrentLayoutId(createdLayout.id);
        }

        async function deleteLayout() {
            const currentIsActive = currentLayout.current.id === activeLayout.id;

            await deleteUserLayout(currentLayout.current.id);

            const {availableLayouts} = await getUserLayouts(translations);

            setAvailableLayouts(availableLayouts);
            if (currentIsActive) {
                setActiveLayout(getLayoutById(availableLayouts, layoutGuids.GENERAL_ID));
            }
            closeDialog();
        }

        function updateCurrentLayout(layoutId) {
            const newActiveLayout = getLayoutById(currentAvailableLayouts, layoutId);

            newActiveLayout.tilePositions = newActiveLayout.tilePositions
                .filter(({tile: {id}}) => isTileVisible(id, userAuth));

            isDirty.current = false;
            currentLayout.current = createEditableLayout(newActiveLayout);

            setCurrentLayoutId(layoutId);
        }

        return {
            onCancel() {
                if (isDirty.current) {
                    openConfirmationDialog({
                        message: translations.UNSAVED_CHANGES_MESSAGE,
                        title: translations.UNSAVED_CHANGES,
                        onContinue: closeDialog
                    });
                } else {
                    closeConfirmation();
                    closeDialog();
                }
            },
            onCopy() {
                activeLayout.tilePositions = activeLayout.tilePositions
                    .filter(({tile: {id}}) => isTileVisible(id, userAuth));

                const copiedLayout = {
                    ...activeLayout,
                    id: v4(),
                    isNew: true,
                    name: getCopyName(activeLayout, currentAvailableLayouts, translations),
                    onlinkUserId: 'currentUser'
                };

                isDirty.current = true;
                currentLayout.current = createEditableLayout(copiedLayout);

                setCurrentAvailableLayouts([...currentAvailableLayouts, copiedLayout]);
                setCurrentLayoutId(copiedLayout.id);
            },
            onCreate() {
                if (isDirty.current) {
                    openConfirmationDialog({
                        message: translations.UNSAVED_CHANGES_MESSAGE,
                        title: translations.UNSAVED_CHANGES,
                        onContinue() {
                            openTileEdit({
                                onSave(tilePositions) {
                                    let newAvailableLayouts = currentAvailableLayouts;

                                    if (currentLayout.current.isNew) {
                                        newAvailableLayouts = newAvailableLayouts.filter(({isNew}) => !isNew);

                                        setCurrentAvailableLayouts(newAvailableLayouts);
                                    }

                                    createLayout(newAvailableLayouts, tilePositions);
                                },
                                tilePositions: []
                            });
                        }
                    });
                } else {
                    openTileEdit({
                        onSave(tilePositions) {
                            createLayout(currentAvailableLayouts, tilePositions);
                        },
                        tilePositions: []
                    });
                }
            },
            onDelete() {
                openConfirmationDialog({
                    message: translations.DELETE_DASHBOARD_MESSAGE,
                    title: translations.DELETE_DASHBOARD,
                    async onContinue() {
                        await toastOnError(deleteLayout, addToast, setIsSaving, translations);
                    }
                });
            },
            onLayoutChange(changedLayout) {
                const normalizedUpdatedPositions = changedLayout.tilePositions
                    .filter(({tileId}) => isTileVisible(tileId, userAuth));

                if (!isDirty.current) {
                    const normalizedPositions = currentLayout.current.tilePositions;

                    const didPositionsChange = !isEqual(
                        sortBy(normalizedUpdatedPositions, ['columnCount', 'tileId']),
                        sortBy(normalizedPositions, ['columnCount', 'tileId'])
                    );
                    const didNameChange = changedLayout.name !== currentLayout.current.name;

                    isDirty.current = didPositionsChange || didNameChange;
                }

                currentLayout.current = {
                    ...currentLayout.current,
                    ...changedLayout
                };
            },
            async onSave() {
                if (!currentLayout.current.name) {
                    addToast({
                        message: translations.DASHBOARD_NAME_REQUIRED,
                        type: TOAST_TYPE.ERROR
                    });
                } else {
                    await toastOnError(
                        () => saveLayout(activeLayout, currentLayout.current, translations, setAvailableLayouts, setActiveLayout, closeDialog),
                        addToast,
                        setIsSaving,
                        translations
                    );
                }
            },
            updateLayoutSelection(layoutId) {
                if (isDirty.current) {
                    openConfirmationDialog({
                        message: translations.UNSAVED_CHANGES_MESSAGE,
                        title: translations.UNSAVED_CHANGES,
                        onContinue() {
                            if (currentLayout.current.isNew) {
                                const filteredAvailableLayouts = currentAvailableLayouts.filter(({isNew}) => !isNew);

                                setCurrentAvailableLayouts(filteredAvailableLayouts);
                            }

                            updateCurrentLayout(layoutId);
                        }
                    });
                } else {
                    updateCurrentLayout(layoutId);
                }
            }
        };
    }

    const {
        onCreate,
        updateLayoutSelection,
        ...editorContentCallbacks
    } = createLayoutEditorCallbacks();

    const content = useDeepMemo(() => {
        const editorContentProps = {
            ...editorContentCallbacks,
            isSaving,
            openTileEdit
        };

        return createContent(currentAvailableLayouts, translations, editorContentProps);
    }, [currentAvailableLayouts, isSaving, translations]);

    return (
        <OnLinkDialog
            activeTab={currentLayoutId}
            className='layout-editor-dialog'
            closeHandler={closeDialog}
            content={content}
            large={true}
            navButton={(
                <OnlinkButton
                    className='add primary'
                    leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
                    onClick={onCreate}
                >
                    {translations.NEW_DASHBOARD}
                </OnlinkButton>
            )}
            onTabClick={updateLayoutSelection}
            title={translations.MANAGE_DASHBOARDS}
        />
    );
}

LayoutEditDialog.propTypes = {
    activeLayout: PropTypes.layout,
    addToast: PropTypes.func,
    availableLayouts: PropTypes.arrayOf(PropTypes.layout),
    closeConfirmation: PropTypes.func,
    closeDialog: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openTileEdit: PropTypes.func,
    setActiveLayout: PropTypes.func,
    setAvailableLayouts: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        activeLayout: state.account.activeLayout,
        availableLayouts: state.account.availableLayouts,
        featureToggles: state.account.featureToggles,
        translations: state.translations,
        myJdPermissions: state.account.myJdPermissions,
        isMigrated: state.membership.isMigrated
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        closeDialog() {
            dispatch(closeDialog(dialogTypes.LAYOUT_EDIT_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openTileEdit(props) {
            dispatch(openDialog(dialogTypes.TILE_EDIT_DIALOG, props));
        },
        setActiveLayout(value) {
            dispatch(setActiveLayoutAction(value));
        },
        setAvailableLayouts(value) {
            dispatch(setAvailableLayoutsAction(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutEditDialog);
