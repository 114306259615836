// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import defaultImage from 'Ui/images/bg/default_membership_image.jpg';
import {DEFAULT_IMAGE_URL} from 'Ui/constants/membership-properties';

function getStyle(hasWorkboard, isPreview, backgroundImage) {
    return isPreview || hasWorkboard ?
        {
            backgroundImage: `url(${backgroundImage})`
        } :
        {
            backgroundColor: '#f9f9f9'
        };
}

function StandaloneBackground(props) {
    const {
        workboard,
        membership,
        isPreview,
        dataUrl
    } = props;

    const [style, setStyle] = React.useState({});

    const logoDataUrl = dataUrl || defaultImage;
    const backgroundImage = membership.logoUrl === DEFAULT_IMAGE_URL ? defaultImage : logoDataUrl;

    React.useEffect(() => {
        const pageStyle = getStyle(Boolean(workboard), isPreview, backgroundImage);

        setStyle(pageStyle);
    }, [workboard, membership, isPreview]);

    return (
        <div
            className='presentation-background'
            style={style}
        />
    );
}

StandaloneBackground.propTypes = {
    dataUrl: PropTypes.string,
    isPreview: PropTypes.bool,
    membership: PropTypes.object,
    workboard: PropTypes.object
};

export default StandaloneBackground;
