// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconCircleAdd, IconCircleMinus, IconDownload, IconSwap} from '@deere/icons';
import AdditionalActionButton from 'OnEquip/equipment/common/additional-action-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {exportInventoryReport} from 'Services/excel-service';
import {DropdownMenu} from '@deere/isg.component-library';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {PARTS_CATALOG_URL} from 'Ui/components/secondary-navbar/utils/dropdown-navbar-helper';
import {INVENTORY} from 'Common/constants/routes';
import {EDIT_PARTS, MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';
import {redirect} from 'Utils/redirect-utils';
import {withRouter} from 'react-router-dom';

const ICON_STYLE = {
    style: {
        height: '15px',
        width: '15px',
        marginLeft: '5px'
    }
};

const EXPORT_ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function getNavbarActions(loading, history, featureToggles, isMigrated, callStockDialog, callPullDialog,
    callExportReport, callTransferDialog, canTransfer) {
    const navbarActions = [];

    if (!featureToggles[ONLINK_NAVIGATION_REDESIGN]) {
        return navbarActions;
    }

    navbarActions.push(
        {
            onClick: callStockDialog,
            title: 'ONLINK_STOCK',
            Icon: 'icon-add',
            variant: 'primary',
            disabled: loading,
            requiredMyJdPermissions: EDIT_PARTS
        },
        {
            onClick: callPullDialog,
            title: 'ONLINK_PULL',
            Icon: 'icon-circle-minus',
            disabled: loading,
            requiredMyJdPermissions: EDIT_PARTS
        },
        {
            onClick: callExportReport,
            title: 'EXPORT',
            Icon: 'icon-download',
            disabled: loading,
            requiredMyJdPermissions: EDIT_PARTS
        },
        {
            hidden: !canTransfer,
            disabled: loading,
            onClick: callTransferDialog,
            title: 'TRANSFER',
            Icon: 'icon-swap',
            requiredMyJdPermissions: EDIT_PARTS
        },
        {
            onClick: () => window.open(PARTS_CATALOG_URL, '_blank'),
            title: 'ONLINK_PARTS_CATALOG',
            Icon: 'icon-new-window'
        }
    );

    if (isMigrated) {
        navbarActions.push({
            onClick: () => redirect(`${INVENTORY}/settings/fleet`, history),
            title: 'SETTINGS',
            Icon: 'icon-gear',
            requiredMyJdPermissions: MANAGE_EQUIPMENT_SETTINGS
        });
    }

    return navbarActions;
}

function FilterComponent(props) {
    const {
        dialogOnClose,
        loading,
        membershipHierarchy,
        openTransferInventoryDialog,
        openPullInventoryDialog,
        openStockInventoryDialog,
        partsData,
        translations,
        isMobile,
        currencySymbol,
        featureToggles,
        history,
        isMigrated
    } = props;

    const canTransfer = membershipHierarchy.children?.length > 0;

    async function callExportReport() {
        await exportInventoryReport(
            partsData.formattedPartsFromInventoryBins,
            translations,
            currencySymbol
        );
    }

    function callTransferDialog() {
        openTransferInventoryDialog({
            availableParts: partsData.formattedPartsFromInventoryBins,
            inventories: partsData.inventories,
            membershipHierarchy,
            onClose: dialogOnClose
        });
    }

    function callPullDialog() {
        openPullInventoryDialog({
            availableParts: partsData.formattedPartsFromInventoryBins,
            inventories: partsData.inventories,
            onClose: dialogOnClose
        });
    }

    function callStockDialog() {
        openStockInventoryDialog({
            availableParts: partsData.parts,
            inventories: partsData.inventories,
            onClose: dialogOnClose
        });
    }

    const navBarActions = getNavbarActions(loading, history, featureToggles, isMigrated, callStockDialog, callPullDialog,
        callExportReport, callTransferDialog, canTransfer);

    useNavBarActions(navBarActions);

    if (isMobile) {
        const items = [
            {
                title: translations.EXPORT,
                onClick: callExportReport
            },
            {
                title: translations.ONLINK_PULL,
                onClick: callPullDialog
            },
            {
                title: translations.ONLINK_STOCK,
                onClick: callStockDialog
            }
        ];

        if (canTransfer) {
            items.splice(1, 0, {
                title: translations.TRANSFER,
                onClick: callTransferDialog
            });
        }

        return (
            !featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
            <DropdownMenu
                buttonTitle={translations.ACTIONS}
                className='onlink-action-dropdown onequip-fleet-action-dropdown'
                items={items}
            />
        );
    }

    return (
        !loading && !featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
        <div className='extra-table-options right-aligned table-buttons'>
            <AdditionalActionButton
                className='export-report'
                disabled={loading}
                label={translations.EXPORT}
                onClick={callExportReport}
            >
                <IconDownload iconDownload={EXPORT_ICON_STYLE}/>
            </AdditionalActionButton>
            {
                canTransfer &&
                <AdditionalActionButton
                    className='transfer-part'
                    label={translations.TRANSFER}
                    onClick={callTransferDialog}
                >
                    <IconSwap
                        iconSwap={ICON_STYLE}
                        primary={createIconFillStyle('#357edd')}
                    />
                </AdditionalActionButton>
            }
            <AdditionalActionButton
                className='pull-part'
                label={translations.ONLINK_PULL}
                onClick={callPullDialog}
            >
                <IconCircleMinus
                    iconCircleMinus={ICON_STYLE}
                    primary={createIconFillStyle('#ff4136')}
                />

            </AdditionalActionButton>
            <AdditionalActionButton
                className='stock-part'
                label={translations.ONLINK_STOCK}
                onClick={callStockDialog}
            >
                <IconCircleAdd
                    iconCircleAdd={ICON_STYLE}
                    primary={createIconFillStyle('#19a974')}
                />
            </AdditionalActionButton>
        </div>
    );
}

FilterComponent.propTypes = {
    currencySymbol: PropTypes.string,
    dialogOnClose: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    loading: PropTypes.bool,
    membershipHierarchy: PropTypes.object,
    openPullInventoryDialog: PropTypes.func,
    openStockInventoryDialog: PropTypes.func,
    openTransferInventoryDialog: PropTypes.func,
    partsData: PropTypes.object,
    translations: PropTypes.translations
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(withRouter(FilterComponent));
