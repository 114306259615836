// Unpublished Work © 2021-2024 Deere & Company.

import * as mapActions from 'Store/actions/map';

const DEFAULT_STATE = {
    modelFilterSelectedIDs: [],
    typeFilterSelectedIDs: []
};

const map = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case mapActions.UPDATE_MODEL_FILTER_SELECTED:
            return Object.assign({}, state, {
                modelFilterSelectedIDs: action.modelFilterSelectedIDs
            });
        case mapActions.UPDATE_TYPE_FILTER_SELECTED:
            return Object.assign({}, state, {
                typeFilterSelectedIDs: action.typeFilterSelectedIDs
            });
        default:
            return state;
    }
};

export default map;
