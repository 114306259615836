// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ItemContent from 'Ui/components/main-navbar/persistent-header/common/item-content';
import ItemLink from 'Ui/components/main-navbar/persistent-header/common/item-link';
import {joinClassNames} from 'Utils/html-utils';
import 'Sass/persistent-header/ph-nav-item.scss';

const NavItem = React.forwardRef(
    function NavItemComponent(props, ref) {
        const {
            basePath,
            children,
            className,
            clearStorage,
            description,
            iconName,
            label,
            leftIcon,
            onClick,
            path,
            prioritizeList,
            reactRouter,
            rightIcon,
            title,
            type
        } = props;

        const itemContent = (
            <ItemContent
                description={description}
                iconName={iconName}
                label={label}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
            />
        );

        const linkProps = {
            className: 'nav-item-content',
            ref,
            title
        };

        return (
            <div className={joinClassNames(className, 'nav-item')}>
                {
                    path ? (
                        <ItemLink
                            {...linkProps}
                            basePath={basePath}
                            clearStorage={clearStorage}
                            path={path}
                            prioritizeList={prioritizeList}
                            reactRouter={reactRouter}
                            type={type}
                        >
                            {itemContent}
                        </ItemLink>
                    ) : (
                        <div
                            {...linkProps}
                            onClick={onClick}
                            role='button'
                        >
                            {itemContent}
                        </div>
                    )
                }
                {children}
            </div>
        );
    }
);

NavItem.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    clearStorage: PropTypes.bool,
    description: PropTypes.node,
    iconName: PropTypes.string,
    label: PropTypes.node,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    path: PropTypes.string,
    prioritizeList: PropTypes.arrayOf(PropTypes.string),
    reactRouter: PropTypes.bool,
    rightIcon: PropTypes.node,
    title: PropTypes.string,
    type: PropTypes.string
};

export default NavItem;
