// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import * as turf from '@turf/turf';
import IdleMarker from 'Ui/components/map/location-history/idle-marker';
import moment from 'moment';

const MILLI_IN_SEC = 1000;
const CLUSTERING_DISTANCE_IN_KILOMETERS = 0.01;

function calculateClusterTime(cluster) {
    const firstPoint = cluster[0];
    const lastPoint = cluster[cluster.length - 1];

    const beginningTime = moment(firstPoint.meta.eventTimestamp);
    const endingTime = moment(lastPoint.meta.eventTimestamp);
    const timeElapsedSec = (endingTime - beginningTime) / MILLI_IN_SEC;

    return timeElapsedSec;
}

function calculateCenter(points) {
    const convertedPoints = points.map((point) => {
        return [point.lat, point.lng];
    });

    return turf.center(turf.points(convertedPoints));
}

function addNewCluster(clusters, currentClusterPoints) {
    const elapsedTime = calculateClusterTime(currentClusterPoints);
    const center = calculateCenter(currentClusterPoints.slice(0, -1));

    const newCluster = {
        center: {
            lat: center.geometry.coordinates[0],
            lng: center.geometry.coordinates[1]
        },
        elapsedTime
    };

    return [...clusters, newCluster];
}

function createPolylineClusters(polyline) {
    let clusters = [],
        currentCluster = [];

    polyline.path.forEach((point) => {
        const potentialNewCenter = calculateCenter([...currentCluster, point]);

        const currentTurfPoint = turf.point([point.lat, point.lng]);
        const distanceToNewCenter = turf.distance(potentialNewCenter, currentTurfPoint);

        const isWithinClusterRange = distanceToNewCenter < CLUSTERING_DISTANCE_IN_KILOMETERS;

        if (isWithinClusterRange) {
            currentCluster.push(point);
        } else {
            clusters = addNewCluster(clusters, [...currentCluster, point]);
            currentCluster = [point];
        }
    });

    if (polyline.path.length) {
        const lastPoint = polyline.path[polyline.path.length - 1];

        currentCluster.push(lastPoint);
        clusters = addNewCluster(clusters, currentCluster);
    }

    return clusters;
}

function createIdleMarkers(polylines) {
    return polylines.reduce((clusters, polyline) => {
        return [
            ...clusters,
            ...createPolylineClusters(polyline)
        ];
    }, []);
}

function IdleMarkers({
    polylines, zIndex
}) {
    const markers = createIdleMarkers(polylines);

    return markers.map((marker, i) =>
        (<IdleMarker
            center={marker.center}
            elapsedTime={marker.elapsedTime}
            key={i}
            zIndex={zIndex}
        />)
    );
}

IdleMarkers.propTypes = {
    polylines: PropTypes.arrayOf(PropTypes.shape()),
    zIndex: PropTypes.number
};

export default IdleMarkers;
