// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {getFeatureToggles} from 'Services/feature-toggle-service';
import {getUser} from 'Services/user-service';
import {setFeatureToggles, setPermissions, updateUser} from 'Store/actions/account';
import {updateHashedUserValues} from 'Store/actions/analytics';

async function getAppUserActions() {
    const {
        appKeyMap,
        appUser,
        hashedAppUserId,
        hashedMembershipId,
        permissionMap
    } = await getUser();

    const setPermissionsAction = setPermissions(permissionMap);
    const updateUserAction = updateUser({
        ...appUser,
        appKeys: appKeyMap
    });
    const updateHashedUserValuesAction = updateHashedUserValues({
        hashedAppUserId,
        hashedMembershipId
    });

    return [
        setPermissionsAction,
        updateUserAction,
        updateHashedUserValuesAction
    ];
}

async function getFeatureTogglesAction() {
    const featureToggles = await getFeatureToggles();

    return setFeatureToggles(featureToggles);
}

async function getSwitchUserActions() {
    return Promise.all([
        getAppUserActions(),
        getFeatureTogglesAction()
    ]);
}

export {
    getSwitchUserActions
};
