// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {asNumber} from 'Utils/conversion-utils';

function EditableThresholdSettings(props) {
    const {
        lowWarning,
        lowNormal,
        highNormal,
        highWarning,
        translations,
        onChange,
        setValid
    } = props;

    return (
        <form className={'settings-body editable-threshold-settings'}>
            <div className={'settings-group mobile'}>
                <ValidationInput
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} '${translations.ONLINK_LOW_NORMAL}'`
                    }}
                    label={translations.ONLINK_LOW_WARNING}
                    max={asNumber(lowNormal)}
                    name={'lowWarning'}
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type={'number'}
                    value={lowWarning}
                />
                <ValidationInput
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} '${translations.ONLINK_HIGH_NORMAL}'`,
                        rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} '${translations.ONLINK_LOW_WARNING}'`
                    }}
                    label={translations.ONLINK_LOW_NORMAL}
                    max={asNumber(highNormal)}
                    min={asNumber(lowWarning)}
                    name={'lowNormal'}
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type={'number'}
                    value={lowNormal}
                />
            </div>
            <div className={'settings-group mobile'}>
                <ValidationInput
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} '${translations.ONLINK_HIGH_WARNING}'`,
                        rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} '${translations.ONLINK_LOW_NORMAL}'`
                    }}
                    label={translations.ONLINK_HIGH_NORMAL}
                    max={asNumber(highWarning)}
                    min={asNumber(lowNormal)}
                    name={'highNormal'}
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type={'number'}
                    value={highNormal}
                />
                <ValidationInput
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} '${translations.ONLINK_HIGH_NORMAL}'`
                    }}
                    label={translations.ONLINK_HIGH_WARNING}
                    min={asNumber(highNormal)}
                    name={'highWarning'}
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type={'number'}
                    value={highWarning}
                />
            </div>
        </form>
    );
}

EditableThresholdSettings.propTypes = {
    highNormal: PropTypes.stringOrNumber,
    highWarning: PropTypes.stringOrNumber,
    lowNormal: PropTypes.stringOrNumber,
    lowWarning: PropTypes.stringOrNumber,
    onChange: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default EditableThresholdSettings;
