// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PanelData from 'Ui/components/panel/panel-data';
import PanelDateLabel from 'Ui/components/panel/panel-date-label';
import {IconRain} from '@deere/icons';
import PropTypes from 'Utils/prop-type-utils';
import {getValueWithThreshold} from 'Utils/threshold-utils';
import {getMinuteOrHourMessage} from 'Utils/time-utils';
import {convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {INCHES} from 'Common/constants/data-unit-constants';

const iconRain = {
    style: {
        width: '50px',
        height: '50px'
    }
};

function Precipitation(props) {
    const {
        featureToggles,
        obsTimeLocal,
        precip1hour,
        precip24hour,
        thresholds,
        translations,
        unitOfMeasure
    } = props;

    const precip24hourThreshold = thresholds.find((threshold) => threshold.dataType === 'precip_24hour');

    const conversionConfig = {
        dataUnit: INCHES,
        featureToggles,
        translations,
        unitOfMeasure
    };

    const precipitation1hour = convertToFormattedUoM(precip1hour, conversionConfig);
    const precipitation24hour = convertToFormattedUoM(precip24hour, conversionConfig);

    const precip24hourSpan = getValueWithThreshold(precip24hourThreshold, precip24hour, precipitation24hour);

    const date = getMinuteOrHourMessage(translations, obsTimeLocal);

    return (
        <div className='precipitation internal-panel'>
            <PanelData
                className='panel-row title-layer'
                label={translations.weather_PRECIPITATION}
                translations={translations}
                value={<IconRain iconRain={iconRain}/>}
            />
            <div className='panel-row'>
                <PanelData
                    className='panel-section'
                    label={translations.LAST_HOUR}
                    translations={translations}
                    value={precipitation1hour}
                />
                <PanelData
                    className='panel-section'
                    label={translations.LAST_24_HOURS}
                    translations={translations}
                    value={precip24hourSpan}
                />
            </div>
            <PanelDateLabel
                className='panel-row'
                date={date}
            />
        </div>
    );
}

Precipitation.propTypes = {
    featureToggles: PropTypes.featureToggles,
    obsTimeLocal: PropTypes.string,
    precip1hour: PropTypes.number,
    precip24hour: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string
};

export default Precipitation;
