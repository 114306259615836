// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Bar} from 'react-chartjs-2';
import {getGraphTimeScaleUnit} from 'Utils/graph-utils';
import {formatLocalizedTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import moment from 'moment';

const PURCHASE_INDEX = 0;
const SALES_TAX_INDEX = 1;
const PROPERTY_TAX_INDEX = 2;
const PARTS_INDEX = 3;
const SERVICE_INDEX = 4;
const TOTAL_COST_INDEX = 5;

const DATA_SETS_PROPERTIES = [
    'purchase',
    'salesTax',
    'propertyTax',
    'partsCost',
    'serviceCost',
    'totalCost'
];

function createDataset(color, label) {
    return {
        backgroundColor: color,
        borderColor: color,
        data: [],
        fill: false,
        label
    };
}

function getChartData(reportData, translations) {
    if (Object.keys(reportData).length === 0) {
        return [];
    }

    const datasetsForChart = [
        createDataset(GRAPH_COLORS[PURCHASE_INDEX], capitalizeFirstLetter(translations.SALES_SYSTEM_TRANSACTION_PURCHASE)),
        createDataset(GRAPH_COLORS[SALES_TAX_INDEX], translations.ONLINK_SALES_TAX),
        createDataset(GRAPH_COLORS[PROPERTY_TAX_INDEX], translations.ONLINK_PROPERTY_TAX),
        createDataset(GRAPH_COLORS[PARTS_INDEX], translations.PARTS),
        createDataset(GRAPH_COLORS[SERVICE_INDEX], translations.ONLINK_SERVICE),
        {
            ...createDataset(GRAPH_COLORS[TOTAL_COST_INDEX], translations.ONLINK_TOTAL_COST),
            backgroundColor: GRAPH_COLORS[TOTAL_COST_INDEX].replace('1.0', '0.1'),
            fill: true,
            type: 'line'
        }
    ];

    return reportData.dateChildren?.reduce((datasets, rollupData) => {
        return datasets.reduce((datasetsForDateSelect, dataset, index) => {
            const yValue = rollupData[DATA_SETS_PROPERTIES[index]];

            datasets[index].data.push({
                x: rollupData.dateSelect,
                y: yValue
            });

            return datasetsForDateSelect;
        }, datasets);
    }, datasetsForChart);
}

function getLabels(datasets) {
    const labels = datasets.reduce((allLabels, dataset) => dataset.data.reduce((labelsForDataset, data) => {
        labelsForDataset.add(data.x);

        return labelsForDataset;
    }, allLabels), new Set());

    return Array.from(labels);
}

function CostReportChart(props) {
    const {
        costReportData,
        featureToggles,
        membership,
        translations
    } = props;

    const equipmentCostRollupDataSets = getChartData(costReportData, translations);
    const timeScaleUnit = getGraphTimeScaleUnit(costReportData?.dateScale);

    return (
        <Bar
            data={{
                datasets: equipmentCostRollupDataSets,
                labels: getLabels(equipmentCostRollupDataSets)
            }}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(tooltipItems) {
                                const [{
                                    dataset,
                                    parsed
                                }] = tooltipItems;

                                const formattedDate = formatLocalizedTime(moment(parsed.x), TIME_UNIT_OPTIONS[timeScaleUnit]);

                                return `${formattedDate} ${dataset.label}`;
                            },
                            label(tooltipItem) {
                                const {parsed} = tooltipItem;

                                return getFormattedCurrency(parsed.y, {
                                    currencyPreference: membership.currencyPreference,
                                    featureToggles
                                });
                            }
                        }
                    }
                },
                scales: {
                    yAxis: {
                        axis: 'y',
                        position: 'left',
                        stacked: true,
                        ticks: {
                            callback(tickValue) {
                                return getFormattedCurrency(tickValue, {
                                    currencyPreference: membership.currencyPreference,
                                    featureToggles
                                });
                            }
                        },
                        type: 'linear'
                    },
                    xAxis: {
                        axis: 'x',
                        position: 'bottom',
                        stacked: true,
                        ticks: {
                            callback(tickValue, index, ticks) {
                                return formatLocalizedTime(moment(ticks[index].value), TIME_UNIT_OPTIONS[timeScaleUnit]);
                            },
                            source: 'data'
                        },
                        time: {
                            unit: timeScaleUnit
                        },
                        type: 'timeseries'
                    }
                }
            }}
            type={'bar'}
        />
    );
}

CostReportChart.propTypes = {
    costReportData: PropTypes.object,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export default CostReportChart;
