// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {TRUE} from 'Ui/constants/membership-properties';
import {MOWING_DIRECTION_ROW} from 'Common/constants/feature-toggles';
import JobsContainer from 'Src/ui-core/features/onlabor/workboards/presentation/jobs-container';
import PresentationNotes from 'Src/ui-core/features/onlabor/workboards/presentation/presentation-notes';
import MowingDirections from 'Src/ui-core/features/onlabor/workboards/presentation/mowing-directions';
import {Stack} from '@mui/material';
import PinLocation from './pin-location';
import {isEmptyString} from 'Common/utils/validation-utils';

function unMigratedPresentationView(viewArgs) {
    const {
        workboard,
        workItems,
        mowingPatternCompositeImages,
        presentationSettings,
        membership,
        translations,
        featureToggles,
        tileOpacityOn,
        fontSizeNotes,
        membershipNote
    } = viewArgs;

    return (<>
        <div
            className={'mowing-direction-sidebar'}
        >
            <PinLocation
                fontSize={fontSizeNotes}
                pinLocation={workboard.pinLocation}
                tileOpacityOn={tileOpacityOn}
                translations={translations}
            />
            <PresentationNotes
                fontSizeNotes={fontSizeNotes}
                membershipNote={membershipNote}
                note={workboard.note}
                tileOpacityOn={tileOpacityOn}
            />
            <MowingDirections
                mowingPatternCompositeImages={mowingPatternCompositeImages}
                tileOpacityOn={tileOpacityOn}
                translations={translations}
            />
        </div>
        <JobsContainer
            featureToggles={featureToggles}
            membership={membership}
            presentationSettings={presentationSettings}
            translations={translations}
            workItems={workItems}
            workboard={workboard}
        />
    </>);
}

function migratedPresentationView(viewArgs) {
    const {
        workboard,
        workItems,
        mowingPatternCompositeImages,
        presentationSettings,
        membership,
        translations,
        featureToggles,
        tileOpacityOn,
        fontSizeNotes,
        membershipNote
    } = viewArgs;

    const {
        note, pinLocation
    } = workboard;
    const {
        shouldRenderSideBar,
        pinLocationComponent
    } = React.useMemo(() => ({
        shouldRenderSideBar: !isEmptyString(note) || !isEmptyString(membershipNote),
        pinLocationComponent: (
            <PinLocation
                fontSize={fontSizeNotes}
                pinLocation={pinLocation}
                tileOpacityOn={tileOpacityOn}
                translations={translations}
            />
        )
    }), [fontSizeNotes, tileOpacityOn, translations, pinLocation, note, membershipNote]);

    return (
        <Stack
            direction='row'
            flexGrow={1}
        >
            {shouldRenderSideBar && <div className='presentation-sidebar'>
                {pinLocationComponent}
                <PresentationNotes
                    fontSizeNotes={fontSizeNotes}
                    membershipNote={membershipNote}
                    note={note}
                    tileOpacityOn={tileOpacityOn}
                />
            </div>}
            <Stack
                direction='column'
                flexGrow={1}
                flexWrap='wrap'
                sx={{
                    padding: '4px 0 0 2px',
                    color: 'white'
                }}
            >
                <Stack direction='row'>
                    {!shouldRenderSideBar && <div
                        className='presentation-sidebar'
                        style={{
                            padding: '12px 8px 48px'
                        }}
                    >{pinLocationComponent}</div>}
                    <Stack
                        direction='row'
                        flexWrap='wrap'
                    >
                        <MowingDirections
                            mowingPatternCompositeImages={mowingPatternCompositeImages}
                            tileOpacityOn={tileOpacityOn}
                            translations={translations}
                        />
                    </Stack>
                </Stack>
                <JobsContainer
                    featureToggles={featureToggles}
                    membership={membership}
                    presentationSettings={presentationSettings}
                    translations={translations}
                    workItems={workItems}
                    workboard={workboard}
                />
            </Stack>
        </Stack>
    );
}

function PresentationContentWrapper(props) {
    const {
        featureToggles,
        membership,
        membershipProperties,
        translations,
        workItems,
        workboard,
        mowingPatternCompositeImages,
        presentationSettings
    } = props;

    const migratedStyling = membership?.isMigrated && featureToggles[MOWING_DIRECTION_ROW];

    const viewArgs = {
        workboard,
        workItems,
        mowingPatternCompositeImages,
        presentationSettings,
        membership,
        translations,
        featureToggles,
        tileOpacityOn: membershipProperties?.workboard_tile_opacity === TRUE,
        fontSizeNotes: membershipProperties?.workboard_presentation_font_size_notes || '100%',
        membershipNote: membershipProperties?.workboard_presentation_note
    };

    return migratedStyling ? migratedPresentationView(viewArgs) : unMigratedPresentationView(viewArgs);
}

PresentationContentWrapper.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.object,
    membershipProperties: PropTypes.object,
    translations: PropTypes.translations,
    workboard: PropTypes.object
};

export default PresentationContentWrapper;
