// Unpublished Work © 2020-2024 Deere & Company.

import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import account from 'Store/reducers/account';
import dialogs from 'Store/reducers/dialogs';
import equipment from 'Store/reducers/equipment';
import membership from 'Store/reducers/membership';
import tertiaryNavBar from 'Store/reducers/tertiary-navbar';
import toasts from 'Store/reducers/toasts';
import translations from 'Store/reducers/translations';
import role from 'Store/reducers/role';
import map from 'Store/reducers/map';
import thunk from 'redux-thunk';
import analytics from 'Store/reducers/analytics';
import workboard from 'Store/reducers/workboard';

const middleware = applyMiddleware(thunk);
const enhancer = compose(middleware);

const combinedReducers = combineReducers({
    account,
    analytics,
    dialogs,
    equipment,
    membership,
    tertiaryNavBar,
    toasts,
    translations,
    role,
    map,
    workboard
});

export default createStore(combinedReducers, enhancer);
