// Unpublished Work © 2020-2024 Deere & Company.

import * as icons from 'Ui/images/icons-weather';

const LAST_WEATHER_ICON_CODE = 47;

function getIconNumber(iconCode) {
    if (iconCode <= LAST_WEATHER_ICON_CODE) {
        return iconCode;
    }

    return 'na';
}

function getWeatherIcon(iconCode) {
    const iconNumber = getIconNumber(iconCode);

    return icons[`icon${iconNumber}`];
}

export {
    getWeatherIcon
};
