// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import ListBox from 'Ui/components/common/list-box/list-box';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {deleteSensor, getSensorIds} from 'Services/vendor-service';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {fetchEffectData} from 'Utils/react-utils';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';

const SENSOR = 'Sensor';

function PogoSensorSettings(props) {
    const {
        closeConfirmation,
        openConfirmation,
        openSensorCreateDialog,
        translations
    } = props;

    const [sensorIds, setSensorIds] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {sensorIds: sensorIdsData} = await getSensorIds();

        if (isMounted()) {
            setSensorIds(sensorIdsData.map(({sensorId}) => sensorId).sort());
            setLoading(false);
        }
    }), []);

    return (
        <div className='settings-container'>
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <ListBox
                    addButtonLabel={replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                        '0': SENSOR
                    })}
                    className='settings-body'
                    headerLabel={'Sensors'}
                    items={sensorIds}
                    noDataText={replaceTranslationNames(translations.ONLINK_NO_ITEM_ASSIGNED, {
                        '0': SENSOR
                    })}
                    onAdd={() => openSensorCreateDialog({
                        sensorIds,
                        updateSensorIds(sensorId) {
                            setSensorIds((prevValues) => [
                                ...prevValues,
                                sensorId
                            ].sort());
                        },
                        translations
                    })}
                    onDelete={(removeSensorId) => openConfirmation({
                        message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                            '0': SENSOR
                        }),
                        title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                            '0': SENSOR
                        }),
                        async onContinue() {
                            await deleteSensor(removeSensorId);

                            const filteredSensorIds = sensorIds.filter((sensorId) => sensorId !== removeSensorId);

                            setSensorIds(filteredSensorIds);

                            closeConfirmation();
                        },
                        onCancel: closeConfirmation
                    })}
                />
            </LoadingWrapper>
        </div>
    );
}

PogoSensorSettings.propTypes = {
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openSensorCreateDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openSensorCreateDialog(props) {
            dispatch(openDialog(dialogTypes.SENSOR_CREATE_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PogoSensorSettings);
