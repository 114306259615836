// Unpublished Work © 2023-2024 Deere & Company.

import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {connect} from 'react-redux';
import React from 'react';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {useSave} from 'Ui/react-hooks/use-save';
import {savePurchaseOrder} from 'Ui/features/dialogs/add-edit-purchase-order-dialog/purchase-order-utils';
import PropTypes from 'Utils/prop-type-utils';
import {STATUS} from 'Utils/parts-utils';
import {asNumber} from 'Utils/conversion-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import SelectedPartQuantityWithCostWithSubRows
    from 'Ui/components/common/parts-selectors/selected-part-quantity-with-cost-with-subrows';

function ReceiveOrderDialog(props) {
    const {
        closeDialog,
        currencyPreference,
        featureToggles,
        invalidInputs,
        receiveOrderOnClose,
        receivableParts,
        setValid,
        translations,
        values,
        partRequestsToRemove,
        purchaseOrder
    } = props;

    const [selectedParts, setSelectedParts] = React.useState(() => {
        return receivableParts.map((part) => {
            const quantityMax = asNumber(part.quantity) - asNumber(part.quantityReceived);

            return {
                ...part,
                quantity: '',
                quantityMax
            };
        });
    });
    const [loading, setLoading] = React.useState(false);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        setLoading(true);

        const partsUpdatedForReceiveSave = receivableParts.map((part) => ({
            name: part.name,
            partId: part.partId,
            quantity: asNumber(part.quantity),
            totalCost: asNumber(part.totalCost),
            unitCost: asNumber(part.unitCost),
            quantityReceived: asNumber(part.quantityReceived)
        }));

        const savedValues = {
            ...values,
            partsUpdatedForReceiveSave,
            status: STATUS.RECEIVED
        };

        await savePurchaseOrder(savedValues, selectedParts, purchaseOrder, receiveOrderOnClose, closeDialog, false, partRequestsToRemove);
    }, {
        disabled: loading,
        invalidInputs
    });

    function onPartChange(name, value, partToChange) {
        partToChange[name] = value;

        setSelectedParts([...selectedParts]);
    }

    const noQuantitiesSelected = React.useMemo(() => {
        const partsWithQuantities = selectedParts.filter((part) => asNumber(part.quantity) > 0);

        return partsWithQuantities.length === 0;
    }, [selectedParts]);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='receive-order-dialog form-dialog'
            closeHandler={closeDialog}
            disableSave={disableSave || noQuantitiesSelected}
            onSave={saveFunc}
            saveLabel={translations.ONLINK_SAVE_AND_STOCK_PARTS}
            title={`${translations.ONLINK_RECEIVE_ORDER} ${values.referenceNumber}`}
            translations={translations}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading || isSaving}
                size='50px'
            >
                <div className='parts-multi-select'>
                    <div className='part-area-label'>
                        {translations.ONLINK_PART}
                    </div>
                    {
                        selectedParts.map((part, index) => (
                            <SelectedPartQuantityWithCostWithSubRows
                                currencyPreference={currencyPreference}
                                disabled={false}
                                featureToggles={featureToggles}
                                isReceiving={true}
                                key={index}
                                onChange={onPartChange}
                                part={part}
                                quantityPlaceholder={asNumber(part.quantity)}
                                quantityRequired={false}
                                setValid={setValid}
                                status={values.startingStatus}
                                translations={translations}
                            />
                        ))
                    }
                </div>
            </LoadingWrapper>
        </FormDialog>
    );
}

ReceiveOrderDialog.propTypes = {
    closeDialog: PropTypes.func,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    partRequestsToRemove: PropTypes.instanceOf(Set),
    purchaseOrder: PropTypes.object,
    receivableParts: PropTypes.arrayOf(PropTypes.object),
    receiveOrderOnClose: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        currencyPreference: state.membership.currencyPreference,
        featureToggles: state.account.featureToggles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeDialog(dialogTypes.RECEIVE_ORDER_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(ReceiveOrderDialog));
