// Unpublished Work © 2023-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import React from 'react';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {IconRemove} from '@deere/icons';
import {cloneDeep} from 'lodash';

const ICON_REMOVE_STYLE = {
    style: {
        height: '15px',
        width: '18px'
    }
};

function PartInfoSubRows({
    currencyPreference,
    featureToggles,
    isMobile,
    rows,
    disabled,
    onClear,
    setPartRequestsToRemove,
    readOnly,
    hideFinancialData
}) {
    const [partRequests, setPartRequests] = React.useState(rows);

    React.useEffect(() => {
        setPartRequests(rows);
    }, [rows]);

    async function deletePartRequest(row, index) {
        setPartRequestsToRemove((prevPartRequests) => {
            const nextPartRequests = cloneDeep(prevPartRequests);

            nextPartRequests.add(row.partRequestId);

            return nextPartRequests;
        });

        const clonedPartRequests = [...partRequests];

        clonedPartRequests.splice(index, 1);

        setPartRequests(clonedPartRequests);
    }

    return (
        <div className='part-info-sub-rows'>
            {
                !isMobile &&
                partRequests.map((row, index) => {
                    const formattedUnitCost = getFormattedCurrency(row.unitCost, {
                        featureToggles,
                        currencyPreference
                    });

                    const formattedTotalCost = getFormattedCurrency(row.totalCost, {
                        featureToggles,
                        currencyPreference
                    });

                    return (
                        <div
                            className='part-info-sub-row'
                            key={index}
                        >
                            <div className='part-label'>
                                {row.title}
                            </div>
                            <div className='quantity-value'>
                                {row.quantity}
                            </div>
                            {
                                !hideFinancialData &&
                                <>
                                    <div className='unit-cost-value'>
                                        {formattedUnitCost}
                                    </div>
                                    <div className='total-cost-value'>
                                        {formattedTotalCost}
                                    </div>
                                </>
                            }
                            {
                                !disabled && !isNullOrUndefined(onClear) && !readOnly &&
                                <button
                                    className='close shows-x'
                                    onClick={() => deletePartRequest(row, index)}
                                >
                                    <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
                                </button>
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}

PartInfoSubRows.propTypes = {
    currencyPreference: PropTypes.string,
    disabled: PropTypes.bool,
    featureToggles: PropTypes.featureToggles,
    hideFinancialData: PropTypes.bool,
    isMobile: PropTypes.bool,
    onClear: PropTypes.func,
    readOnly: PropTypes.bool,
    rows: PropTypes.arrayOf(PropTypes.object),
    setPartRequestsToRemove: PropTypes.func,
    showExtraSpace: PropTypes.bool
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(PartInfoSubRows);
