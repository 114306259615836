// Unpublished Work © 2021-2024 Deere & Company.

import {isNullOrUndefined} from 'Common/utils/validation-utils';

function hasLatitudeAndLongitude(latitude, longitude) {
    return !isNullOrUndefined(latitude) && !isNullOrUndefined(longitude);
}

export {
    hasLatitudeAndLongitude
};
