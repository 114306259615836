// Unpublished Work © 2022-2024 Deere & Company.

import {replaceTranslationNames} from 'Utils/translation-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {IconRemove} from '@deere/icons';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';
import {clsx} from 'clsx';

const ICON_REMOVE_STYLE = {
    style: {
        height: '28px',
        width: '34px'
    }
};

function SelectedPartQuantityWithCost({
    currencyPreference,
    featureToggles,
    isReceiving,
    onChange,
    onClear,
    part,
    quantityRequired,
    setValid,
    translations,
    disabled,
    readOnly,
    hideFinancialData
}) {
    const quantityLabel = React.useMemo(() => {
        if (isReceiving) {
            return translations.ONLINK_RECEIVED_QUANTITY;
        } else if (!isNullOrUndefined(part.quantityReceived)) {
            return translations.ONLINK_ORDERED_QUANTITY;
        }

        return translations['Observation.QUANTITY'];
    }, [part]);

    return (
        <div
            className='parts-selected-row'
            key={part.id}
        >
            <div className='part-label'>
                <div className='title'>
                    {part.title}
                </div>
                <div className='sub-title'>
                    {part.subtitle}
                </div>
            </div>
            <ReadOnlyWrapperComponent
                label={quantityLabel}
                readOnly={readOnly}
                readOnlyProps={{
                    readOnlyValueClassName: 'parts-orders-cost'
                }}
                value={part.quantity}
                wrappedComponent={ValidationInput}
                wrappedProps={{
                    disabled,
                    errors: {
                        rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
                            '0': 0
                        }),
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${part.quantityMax}`
                    },
                    max: part.quantityMax,
                    min: 0,
                    name: `${part.id}quantity`,
                    onChange: (event) => onChange('quantity', event.target.value, part),
                    placeholder: part.quantityMax,
                    required: quantityRequired,
                    setValid,
                    step: 'any',
                    tabIndex: 0,
                    type: 'number'
                }}
            />
            {
                !hideFinancialData &&
                <ReadOnlyWrapperComponent
                    label={translations.ONLINK_UNIT_COST}
                    readOnly={readOnly}
                    readOnlyProps={{
                        readOnlyValueClassName: 'parts-orders-cost'
                    }}
                    value={part.unitCost}
                    wrappedComponent={ValidationInput}
                    wrappedProps={{
                        disabled,
                        min: 0,
                        name: `${part.id}unitCost`,
                        onChange: (event) => onChange('unitCost', event.target.value, part),
                        required: true,
                        setValid,
                        step: 'any',
                        tabIndex: 0,
                        type: 'number'
                    }}
                />
            }
            {
                !hideFinancialData && <div className='quantity-with-cost-end-column'>
                    <div className='part-selected-cost-column'>
                        <div
                            className={clsx('parts-selected-cost-label', {
                                'readonly-cost-label': readOnly
                            })}
                        >{translations.ONLINK_TOTAL_COST}</div>
                        {getFormattedCurrency(part.quantity * part.unitCost, {
                            currencyPreference,
                            featureToggles
                        })}
                    </div>
                    {
                        !disabled && !isNullOrUndefined(onClear) && !readOnly &&
                        <button
                            className='close'
                            onClick={() => onClear(part)}
                        >
                            <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
                        </button>
                    }
                </div>
            }
        </div>
    );
}

SelectedPartQuantityWithCost.defaultProps = {
    quantityRequired: true
};

SelectedPartQuantityWithCost.propTypes = {
    currencyPreference: PropTypes.string,
    disabled: PropTypes.bool,
    featureToggles: PropTypes.featureToggles,
    hideFinancialData: PropTypes.bool,
    isMobile: PropTypes.bool,
    isReceiving: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    part: PropTypes.object,
    quantityRequired: PropTypes.bool,
    readOnly: PropTypes.bool,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default SelectedPartQuantityWithCost;
