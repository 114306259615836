// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

const EquipmentContext = React.createContext(null);

function useEquipmentContext() {
    return React.useContext(EquipmentContext);
}

function EquipmentContextProvider(props) {
    const {children} = props;
    const equipmentContext = React.useRef(() => {});

    return (
        <EquipmentContext.Provider value={equipmentContext}>
            {children}
        </EquipmentContext.Provider>
    );
}

EquipmentContextProvider.propTypes = {
    children: PropTypes.node
};

export {
    useEquipmentContext,
    EquipmentContextProvider
};
