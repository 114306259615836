// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Stack from '@mui/material/Stack';
import PrimaryNavBar from 'Ui/components/main-navbar/persistent-header/primary-navbar/primary-navbar';
import SecondaryNavBar from 'Ui/components/main-navbar/persistent-header/secondary-navbar/secondary-navbar';
import {createLink} from 'Utils/navigation-utils';
import {isAuthorized} from 'Common/utils/authorization-handler';
import 'Sass/persistent-header/ph-persistent-navbar.scss';
import {setInArray} from 'Common/utils/array-handlers';
import {cloneDeep} from 'lodash';

function createNavbarLinks(userAuth, items) {
    return items.reduce((navbarLinks, navbarItem) => {
        const navbarLink = {
            ...navbarItem
        };

        if (isAuthorized(navbarLink, userAuth)) {
            if (navbarLink.dropdownItems) {
                navbarLink.dropdownItems = navbarLink.dropdownItems.filter((dropdownItem) => {
                    const mainAuth = isAuthorized(dropdownItem, userAuth);
                    const displayWhenMigrated = userAuth.isMigrated && dropdownItem.hasOwnProperty('displayWhenMigrated') ?
                        dropdownItem.displayWhenMigrated : !dropdownItem.displayWhenMigrated;

                    if (!mainAuth && dropdownItem.alternativePath) {
                        const authorizedAlternatePath = setInArray(dropdownItem.alternativePath).find((path) => {
                            return isAuthorized(path, userAuth) && displayWhenMigrated;
                        });

                        if (authorizedAlternatePath) {
                            dropdownItem.path = authorizedAlternatePath?.path;
                            dropdownItem.basePath = authorizedAlternatePath?.basePath;
                            dropdownItem.description = authorizedAlternatePath?.description;
                        }

                        return authorizedAlternatePath && displayWhenMigrated;
                    }

                    return mainAuth && displayWhenMigrated;
                });
            }

            navbarLinks.push(navbarLink);
        }

        return navbarLinks;
    }, []);
}

function PersistentHeaderWrapper(props) {
    const {
        navbarItems, accountLinks
    } = window.props.links.persistentHeader;

    const navbarItemsCopy = cloneDeep(navbarItems);

    const links = {
        accountLinks: createNavbarLinks(props, accountLinks).map((link) => createLink(link.path, link.name, link.reactRouter, link.clearStorage)),
        navbarLinks: createNavbarLinks(props, navbarItemsCopy)
    };

    return (
        <Stack direction='column'>
            <PrimaryNavBar
                {...props}
                links={links}
            />
            <SecondaryNavBar
                {...props}
                links={links}
            />
        </Stack>
    );
}

PersistentHeaderWrapper.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    hasMyJDSession: PropTypes.bool,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    permissions: PropTypes.legacyPermissions,
    superUser: PropTypes.bool
};

export function mapStateToProps(state) {
    return {
        hasMyJDSession: state.account.hasMyJDSession,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions,
        superUser: state.account.superUser
    };
}

export default connect(mapStateToProps)(PersistentHeaderWrapper);
