// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import React from 'react';
import {deleteJobTemplateById, getJobTemplates} from 'Services/job-service';
import DataTable from 'Ui/components/common/data-table/data-table';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {fetchEffectData, useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import {formatMinutesToHours} from 'Utils/time-utils';
import {alphaNumericCompare} from 'Ui/models/maintenance';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';
import AddButton from 'Ui/components/settings/common/add-button';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {createPpeIconMap} from 'Utils/icon-utils';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px',
        marginRight: '5px'
    }
};

const PPE_ICON_MAP = createPpeIconMap(ICON_STYLE);

function getColumns(translations, openJobDialog, fetchJobTemplates) {
    return [
        {
            Header: translations.NAME,
            accessor: 'title',
            className: 'sticky',
            headerClassName: 'sticky',
            width: 175,
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openJobDialog({
                            job: original,
                            getUpdatedJobTemplates: fetchJobTemplates
                        })}
                    >
                        {value}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_SECONDARY_NAME,
            accessor: 'secondaryName',
            sortMethod: alphaNumericCompare,
            width: 175
        },
        {
            Header: translations.PRODUCT_TYPE,
            accessor: 'jobCategory',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.AREA,
            accessor: 'equipmentArea',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.ONLINK_PPE,
            accessor: 'ppeListIcons',
            sortable: false,
            width: 175
        },
        {
            Header: translations.ONLINK_EST_HRS,
            accessor: 'estDuration',
            sortMethod: alphaNumericCompare,
            width: 95
        },
        {
            Header: translations.STATUS,
            accessor: 'status',
            width: 95
        }
    ];
}

function getFilterComponent(translations, showInactive, setShowInactive, openJobDialog, fetchJobTemplates) {
    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={showInactive}
                name='showInactive'
                offLabel={translations.ONLINK_SHOW_INACTIVE}
                onChange={() => setShowInactive(!showInactive)}
                onLabel={translations.ONLINK_HIDE_INACTIVE}
                translations={translations}
            />
            <AddButton
                className='add-job-button'
                label={translations.ONLINK_ADD_JOB}
                onClick={() => {
                    openJobDialog({
                        getUpdatedJobTemplates: fetchJobTemplates
                    });
                }}
            />
        </div>
    );
}

function JobsSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        openJobDialog,
        translations
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [showInactive, setShowInactive] = React.useState(false);
    const [jobs, setJobs] = React.useState([]);

    async function fetchJobTemplates(isMounted = () => true) {
        setLoading(true);

        const {templates} = await getJobTemplates();

        if (isMounted()) {
            const formattedJobs = templates.map((jobTemplate) => {
                return {
                    ...jobTemplate,
                    estDuration: jobTemplate.estDuration && formatMinutesToHours(jobTemplate.estDuration) || '',
                    ppeListIcons: jobTemplate.ppeList?.map((ppeName) => PPE_ICON_MAP[ppeName]),
                    status: jobTemplate.inactive ? translations.inactive : translations['EDIT_PDA_PAGE.LABEL.ACTIVE']
                };
            });

            setJobs(formattedJobs);
            setLoading(false);
        }
    }

    function deleteJob(jobTemplate) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.jobs_job.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.jobs_job
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteJobTemplateById(jobTemplate.jobTemplateId);

                    await fetchJobTemplates();
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.jobs_job.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    useDeepEffect(() => fetchEffectData(fetchJobTemplates), []);

    const filteredJobsByStatus = useDeepMemo(
        () => showInactive ? jobs : jobs.filter((job) => !job.inactive),
        [showInactive, jobs]
    );

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, openJobDialog, fetchJobTemplates)}
                defaultSorted={[{
                    desc: false,
                    id: 'title'
                }]}
                deleteHandler={deleteJob}
                filterComponent={getFilterComponent(translations, showInactive, setShowInactive, openJobDialog, fetchJobTemplates)}
                loading={loading}
                rows={filteredJobsByStatus}
                searchable={true}
                showDeleteColumn={true}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

JobsSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openJobDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openJobDialog(props) {
            dispatch(openDialog(dialogTypes.JOB_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsSettings);
