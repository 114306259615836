// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {IconShown} from '@deere/icons';
import Stack from '@mui/material/Stack';
import Calendar from 'OnLabor/workboard/calendar/calendar';
import CalendarMobile from 'OnLabor/workboard/calendar/calendar-mobile';
import {getEmployeeStatusOptions, getTimeOff, getTodoTemplates, getWorkboards} from 'Services/membership-service';
import {getConvertedWeatherData} from 'Services/panel-service';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {useToggledMemo} from 'Ui/react-hooks/use-toggled-memo';
import {openDialog} from 'Store/actions/dialogs';
import {fetchEffectData} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {combineConsecutiveEvents} from 'OnLabor/workboard/utils/time-off-utils';
import {getPresentationUrl} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {AVAILABILITY, WEATHER, WORKBOARD} from 'OnLabor/workboard/constants/event-types';
import {STATUS_COLOR_MAPPING} from 'Common/constants/valid-hex-color';
import moment from 'moment';
import dialogTypes from 'Ui/components/common/dialog-types';
import {DEGREES_F} from 'Common/constants/data-unit-constants';
import {MANAGE_TIMEOFF, MANAGE_WORKBOARDS, VIEW_WORKBOARDS} from 'Common/constants/business-activities';

function formatAvailability(timeOffData, translations, employeeStatusOptions) {
    const restructuredEvents = combineConsecutiveEvents(timeOffData, true);
    const employeeStatusOptionsMap = employeeStatusOptions.reduce((statuses, employeeStatus) => ({
        ...statuses,
        [employeeStatus.employeeStatusOptionId]: employeeStatus
    }), {});

    return restructuredEvents?.map((timeOffEvent) => {
        const statusOption = translations[timeOffEvent.employeeStatusOption] || timeOffEvent.employeeStatusOption;

        const colorToMap = employeeStatusOptionsMap[timeOffEvent.employeeStatusOptionId]?.color;

        return {
            ...timeOffEvent,
            ...STATUS_COLOR_MAPPING[colorToMap],
            id: `${timeOffEvent.appUserId} - ${timeOffEvent.dateSelect}`,
            sortIndex: 2,
            title: `${timeOffEvent.firstName} ${timeOffEvent.lastName} - ${statusOption}`,
            type: AVAILABILITY
        };
    });
}

function formatWorkboards(workboardData) {
    const sortedWorkboards = workboardData?.sort((workboard1, workboard2) => {
        if (workboard1.startTime && workboard2.startTime) {
            return moment(workboard1.startTime).diff(workboard2.startTime);
        }

        return moment(workboard1.endTime).diff(workboard2.endTime);
    });

    return sortedWorkboards?.map((workboard) => ({
        ...workboard,
        end: workboard.endTime,
        id: workboard.workboardId,
        sortIndex: 1,
        start: workboard.startTime,
        title: workboard.name,
        type: WORKBOARD
    }));
}

function formatWeather(dailyForecast) {
    return dailyForecast?.map((weatherData) => {
        const {
            day, convertedTemp
        } = weatherData;

        if (!day) {
            return {};
        }

        let title = `${convertedTemp}°`;

        if (day.pop > 0) {
            title += ` (${day.pop}%)`;
        }

        return {
            allDay: true,
            forecast: day,
            id: day.fcst_valid,
            sortIndex: 0,
            start: new Date(day.fcst_valid_local),
            title,
            type: WEATHER
        };
    });
}

function getNavbarActions(
    workboards, openAddWorkboardDialog, openAddTimeOffDialog, fetchWorkboards,
    fetchAvailabilityByDate, history, loading, membershipId, isMigrated, myJdPermissions
) {
    const navbarActions = [];

    if (!isMigrated || myJdPermissions[MANAGE_WORKBOARDS]) {
        navbarActions.push({
            Icon: 'icon-add',
            disabled: loading.workboards,
            onClick: () => {
                openAddWorkboardDialog({
                    onClose: fetchWorkboards,
                    workboards
                });
            },
            title: 'ONLINK_WORKBOARD',
            variant: 'primary'
        });
    }

    if (!isMigrated || myJdPermissions[MANAGE_TIMEOFF]) {
        navbarActions.push({
            Icon: 'icon-add',
            onClick: () => {
                openAddTimeOffDialog({
                    onClose: fetchAvailabilityByDate
                });
            },
            title: 'ONLINK_TIME_OFF',
            variant: 'secondary'
        });
    }

    if (!isMigrated || myJdPermissions[VIEW_WORKBOARDS]) {
        navbarActions.push({
            Icon: (
                <IconShown
                    iconShown={{
                        style: {
                            height: '14px',
                            width: '14px'
                        },
                        viewBox: '4 4 22 22'
                    }}
                />
            ),
            onClick: () => window.open(getPresentationUrl(membershipId), '_blank'),
            title: 'ONLINK_VIEW_WORKBOARD',
            variant: 'secondary'
        });
    }

    if (!isMigrated || myJdPermissions[MANAGE_WORKBOARDS]) {
        const redirectPath = !isMigrated ? 'workboard' : 'jobs';

        navbarActions.push({
            Icon: 'icon-gear',
            onClick: () => history.push(`/labor/workboard/settings/labor/${redirectPath}`),
            title: 'SETTINGS',
            variant: 'secondary'
        });
    }

    return navbarActions;
}

function initializeState() {
    const [availabilityByDate, setAvailabilityByDate] = React.useState([]);
    const [loading, setLoading] = React.useState({
        availability: false,
        todoList: true,
        workboards: true
    });
    const [todoTemplates, setTodoTemplates] = React.useState([]);
    const [weather, setWeather] = React.useState([]);
    const [workboards, setWorkboards] = React.useState([]);
    const [dateValue, setDateValue] = React.useState(() => new Date());

    const [startAndEndTime, setStartAndEndTime] = React.useState(() => ({
        start: moment().startOf('month').subtract(1, 'weeks').format(DATE_FORMATS.day),
        end: moment().endOf('month').add(2, 'weeks').format(DATE_FORMATS.day)
    }));

    return {
        availabilityByDate,
        calendarRef: React.useRef(),
        dateValue,
        loading,
        setAvailabilityByDate,
        setDateValue,
        setLoading,
        setStartAndEndTime,
        setTodoTemplates,
        setWeather,
        setWorkboards,
        startAndEndTime,
        todoTemplates,
        weather,
        weatherRef: React.useRef(),
        workboards
    };
}

function initializeCallbacks({
    layers,
    setAvailabilityByDate,
    setLoading,
    setTodoTemplates,
    setWeather,
    setWorkboards,
    startAndEndTime,
    translations,
    weatherRef,
    conversionConfig,
    unitOfMeasure
}) {
    return {
        fetchTodoTemplates: React.useCallback(async (isMounted) => {
            setLoading((prevValues) => ({
                ...prevValues,
                todoList: true
            }));
            const {todoTemplates} = await getTodoTemplates();

            if (isMounted()) {
                const formattedTodoTemplates = todoTemplates?.map((todoTemplate) => ({
                    ...todoTemplate,
                    id: todoTemplate.todoTemplateId
                }));

                setTodoTemplates(formattedTodoTemplates);
                setLoading((prevValues) => ({
                    ...prevValues,
                    todoList: false
                }));
            }
        }, []),
        fetchWorkboards: React.useCallback(async (isMounted = () => true) => {
            setLoading((prevValues) => ({
                ...prevValues,
                workboards: true
            }));
            const {workboards: workboardsData} = await getWorkboards();
            const dateSelect = formatTime(new Date(), DATE_FORMATS.day);
            const weatherData = await getConvertedWeatherData('TWC', dateSelect, conversionConfig);
            const {dailyForecast} = weatherData;

            if (isMounted()) {
                weatherRef.current = weatherData;
                setWorkboards(formatWorkboards(workboardsData));
                setWeather(formatWeather(dailyForecast));
                setLoading((prevValues) => ({
                    ...prevValues,
                    workboards: false
                }));
            }
        }, [unitOfMeasure]),
        async fetchAvailabilityByDate(isMounted = () => true) {
            if (layers.showTimeOff) {
                setLoading((prevValues) => ({
                    ...prevValues,
                    availability: true
                }));

                const {employeeStatus} = await getTimeOff({
                    startDate: startAndEndTime.start,
                    endDate: startAndEndTime.end
                });

                const {employeeStatusOptions} = await getEmployeeStatusOptions();

                if (isMounted()) {
                    setAvailabilityByDate(formatAvailability(employeeStatus, translations, employeeStatusOptions));
                    setLoading((prevValues) => ({
                        ...prevValues,
                        availability: false
                    }));
                }
            }
        }
    };
}

function Workboard(props) {
    const {
        featureToggles,
        history,
        isMigrated,
        isMobile,
        layers,
        membershipId,
        myJdPermissions,
        openAddWorkboardDialog,
        openAddTimeOffDialog,
        translations,
        unitOfMeasure
    } = props;

    const conversionConfig = {
        dataUnit: DEGREES_F,
        featureToggles,
        translations,
        unitOfMeasure
    };

    const {
        availabilityByDate,
        dateValue,
        loading,
        setAvailabilityByDate,
        setDateValue,
        setLoading,
        setStartAndEndTime,
        setTodoTemplates,
        setWeather,
        setWorkboards,
        startAndEndTime,
        todoTemplates,
        weather,
        weatherRef,
        workboards
    } = initializeState();

    const {
        fetchTodoTemplates,
        fetchWorkboards,
        fetchAvailabilityByDate
    } = initializeCallbacks({
        layers,
        setAvailabilityByDate,
        setLoading,
        setTodoTemplates,
        setWeather,
        setWorkboards,
        startAndEndTime,
        translations,
        weatherRef,
        conversionConfig,
        unitOfMeasure
    });

    React.useEffect(() => fetchEffectData((isMounted) => Promise.all([
        fetchWorkboards(isMounted),
        fetchTodoTemplates(isMounted)
    ])), [membershipId]);

    React.useEffect(
        () => fetchEffectData(fetchAvailabilityByDate),
        [membershipId, startAndEndTime.end, startAndEndTime.start, layers.showTimeOff]
    );

    const CalendarComponent = React.useMemo(() => isMobile ? CalendarMobile : Calendar, [isMobile]);

    const navBarActions = getNavbarActions(workboards, openAddWorkboardDialog, openAddTimeOffDialog,
        fetchWorkboards, fetchAvailabilityByDate, history, loading, membershipId, isMigrated, myJdPermissions);

    useNavBarActions(navBarActions);

    const tileProps = useToggledMemo({
        disabledCallback: () => ({
            bgcolor: '#fff',
            borderRadius: '5px',
            boxShadow: '2px 2px 3px 0 rgba(0, 0, 0, .1)'
        }),
        enabledCallback: () => ({}),
        toggle: ONLINK_NAVIGATION_REDESIGN
    });

    return (
        <Stack
            {...tileProps}
            direction='row'
            flex={1}
            margin='15px'
            overflow='hidden'
        >
            <CalendarComponent
                availabilityByDate={availabilityByDate}
                dateValue={dateValue}
                featureToggles={featureToggles}
                fetchAvailabilityByDate={() => fetchAvailabilityByDate()}
                fetchWorkboards={() => fetchWorkboards()}
                layers={layers}
                loading={loading}
                membershipId={membershipId}
                openAddTimeOffDialog={openAddTimeOffDialog}
                openAddWorkboardDialog={openAddWorkboardDialog}
                setDateValue={setDateValue}
                setStartAndEndTime={setStartAndEndTime}
                todoTemplates={todoTemplates}
                translations={translations}
                weather={weather}
                weatherRef={weatherRef}
                workboards={workboards}
            />
        </Stack>
    );
}

Workboard.propTypes = {
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    layers: PropTypes.object,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openAddTimeOffDialog: PropTypes.func,
    openAddWorkboardDialog: PropTypes.func,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string
};

export function mapDispatchToProps(dispatch) {
    return {
        openAddTimeOffDialog(props) {
            dispatch(openDialog(dialogTypes.ADD_TIME_OFF_DIALOG, props));
        },
        openAddWorkboardDialog(props) {
            dispatch(openDialog(dialogTypes.ADD_WORKBOARD_DIALOG, props));
        }
    };
}

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        layers: state.workboard,
        membershipId: state.membership.membershipId,
        myJdPermissions: state.account.myJdPermissions,
        unitOfMeasure: state.membership.unitOfMeasure
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(withRouter(Workboard)));
