// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DataTable from 'Ui/components/common/data-table/data-table';
import EquipmentName from 'OnEquip/equipment/common/data-table/equipment-name';
import {fetchEffectData} from 'Utils/react-utils';
import {alphaNumericCompare} from 'Ui/models/maintenance';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {MAINTENANCE} from 'OnEquip/equipment/constants/equipment-details-tabs';
import {ASSIGNMENTS} from 'Common/constants/routes';
import {getDeploymentsInUse} from 'Services/fleet-service';
import moment from 'moment';
import {Datepicker} from '@deere/form-controls';
import {getHoc} from 'Services/hoc-service';
import {formatArrayToStringOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {getHocValue, getLanguagePreference} from 'Utils/unit-conversion-utils';
import {VIEW_WORKBOARDS} from 'Common/constants/business-activities';

function getColumns(translations) {
    return [
        {
            Header: translations.EQUIPMENT_LIST,
            accessor: 'equipmentName',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                return (
                    <EquipmentName
                        equipment={row.original}
                        tab={MAINTENANCE}
                        type={ASSIGNMENTS}
                    />
                );
            }
        },
        {
            Header: translations.MODEL,
            accessor: 'modelAndManufacturerName',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.OPERATOR,
            accessor: 'operator',
            sortMethod: alphaNumericCompare,
            requiredMyJdPermissions: VIEW_WORKBOARDS
        },
        {
            Header: translations.jobs_job,
            accessor: 'job',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.AREA,
            className: 'equipment-area',
            accessor: 'area',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.ONLINK_HOC,
            className: 'hoc',
            accessor: 'hoc',
            sortMethod: alphaNumericCompare
        }
    ];
}

function getFilterComponent(date, setDate) {
    return (
        <div className='extra-table-options'>
            <Datepicker
                containerClassName='assignments-datepicker'
                dateFormat='LL'
                large={true}
                locale={getLanguagePreference()}
                onChange={setDate}
                selected={date}
            />
        </div>
    );
}

function initializeState() {
    const [assignments, setAssignments] = React.useState(() => []);
    const [date, setDate] = React.useState(() => moment());
    const [loading, setLoading] = React.useState(() => true);

    return {
        assignments,
        setAssignments,
        date,
        setDate,
        loading,
        setLoading
    };
}

function Assignments(props) {
    const {
        fleetId,
        translations
    } = props;

    const {
        assignments,
        setAssignments,
        date,
        setDate,
        loading,
        setLoading
    } = initializeState();

    const equipmentContext = useEquipmentContext();

    equipmentContext.current = async (isMounted = () => true) => {
        setLoading(true);

        let formattedAssignments = [];

        if (date?.isValid()) {
            const formattedDate = date.format('YYYYMMDD');

            const [
                {deployments},
                {equipmentAreas}
            ] = await Promise.all([
                getDeploymentsInUse(fleetId, formattedDate),
                getHoc(formattedDate)
            ]);

            formattedAssignments = deployments.map((deployment) => {
                const {
                    equipment,
                    firstName,
                    lastName,
                    jobName,
                    equipmentAreaId
                } = deployment;

                const {
                    equipmentName,
                    equipmentId,
                    manufacturerName,
                    modelName
                } = equipment || {};

                const hocEquipmentArea = equipmentAreas.find((equipArea) => equipArea.equipmentAreaId === equipmentAreaId);

                return {
                    equipmentId: equipmentId || '',
                    equipmentName: equipmentName || '',
                    modelAndManufacturerName: formatArrayToStringOrDefault([manufacturerName, modelName]),
                    operator: formatArrayToStringOrDefault([firstName, lastName]),
                    job: jobName || '',
                    area: hocEquipmentArea?.name || '',
                    hoc: getHocValue(hocEquipmentArea, translations)
                };
            });
        }

        if (isMounted()) {
            setAssignments(formattedAssignments);
            setLoading(false);
        }
    };

    React.useEffect(() => fetchEffectData(equipmentContext.current), [fleetId, date]);

    return (
        <DataTable
            columns={getColumns(translations)}
            defaultSorted={[{
                desc: false,
                id: 'equipmentName'
            }]}
            filterComponent={getFilterComponent(date, setDate)}
            loading={loading}
            noDataAvailableMessage={translations.ONLINK_NO_ASSIGNMENTS_FOR_DATE_SELECTED}
            rows={assignments}
            searchable={true}
            translations={translations}
        />
    );
}

Assignments.propTypes = {
    addToast: PropTypes.func,
    fleetId: PropTypes.string,
    location: PropTypes.location,
    openConfirmation: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        fleetId: state.membership.fleetId,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(withRouter(Assignments));
