// Unpublished Work © 2020-2024 Deere & Company.

const FERT_CHEM_0_ID = '2b9b71f3-a733-4c15-811b-d81f3f96c678';
const FERT_CHEM_1_ID = 'd7f5990b-2677-4503-91ee-e5032cbb0da3';
const FERT_CHEM_2_ID = '38eca2ec-d143-4b05-8beb-23ab729f9e85';

const FERT_CHEM_0_NAME = 'fert_chem_0';
const FERT_CHEM_1_NAME = 'fert_chem_1';
const FERT_CHEM_2_NAME = 'fert_chem_2';

export default {
    tilePositions: [
        {
            tile: {
                id: FERT_CHEM_0_ID,
                name: FERT_CHEM_0_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                i: FERT_CHEM_0_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_1_ID,
                name: FERT_CHEM_1_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: FERT_CHEM_1_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_2_ID,
                name: FERT_CHEM_2_NAME
            },
            columnCount: 3,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: FERT_CHEM_2_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_0_ID,
                name: FERT_CHEM_0_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 1,
                i: FERT_CHEM_0_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_1_ID,
                name: FERT_CHEM_1_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: FERT_CHEM_1_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_2_ID,
                name: FERT_CHEM_2_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: FERT_CHEM_2_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_0_ID,
                name: FERT_CHEM_0_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 4,
                w: 1,
                h: 1,
                i: FERT_CHEM_0_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_1_ID,
                name: FERT_CHEM_1_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: FERT_CHEM_1_NAME
            }
        },
        {
            tile: {
                id: FERT_CHEM_2_ID,
                name: FERT_CHEM_2_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: FERT_CHEM_2_NAME
            }
        }
    ]
};
