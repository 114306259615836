// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnLinkDialogContentBar from 'Ui/components/common/onlink-dialog/onlink-dialog-content-bar';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {IconEdit} from '@deere/icons';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#2677bb');

function EditorContentActions(props) {
    const iconEdit = (
        <IconEdit
            iconEdit={ICON_EDIT_STYLE}
            primary={PRIMARY_STYLE}
        />
    );

    const editTilesButton = !props.isDefaultLayout && (
        <OnlinkButton
            className='edit'
            leftIcon={iconEdit}
            onClick={props.openTileEdit}
        >
            {props.translations.TILES}
        </OnlinkButton>
    );

    const saveButton = !props.isDefaultLayout && (
        <OnlinkButton
            className='primary'
            disabled={props.isSaving}
            onClick={props.onSave}
        >
            {props.translations.save}
        </OnlinkButton>
    );

    return (
        <OnLinkDialogContentBar className='editor-content-actions'>
            {editTilesButton}
            <OnlinkButton
                className='cancel'
                onClick={props.onCancel}
            >
                {props.translations.CANCEL}
            </OnlinkButton>
            {saveButton}
        </OnLinkDialogContentBar>
    );
}

EditorContentActions.propTypes = {
    isDefaultLayout: PropTypes.bool,
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    openTileEdit: PropTypes.func,
    translations: PropTypes.translations
};

export default EditorContentActions;
