// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ReactPhoneInput from 'react-phone-input-2';
import {clsx} from 'clsx';

function getCountryCodeOrDefault(dialCodeLength, countryCode) {
    return dialCodeLength === 0 ? '' : countryCode?.toUpperCase();
}

function getPhoneInputValues(phoneNumber, data, disableCountryCode) {
    if (disableCountryCode) {
        const callingCode = phoneNumber.length === 0 ? data.dialCode : '';

        return {
            callingCode,
            countryCode: getCountryCodeOrDefault(callingCode.length, data.countryCode),
            nationalPhoneNumber: phoneNumber
        };
    }

    const dialCodeLength = data.dialCode?.length || 0;
    const callingCode = phoneNumber.substring(0, dialCodeLength);

    return {
        callingCode,
        countryCode: getCountryCodeOrDefault(callingCode.length, data.countryCode),
        nationalPhoneNumber: phoneNumber.substring(dialCodeLength)
    };
}

function getPhoneNumber(phoneNumber, countryCallingCode, disableCountryCode) {
    if (phoneNumber || countryCallingCode) {
        const callingCode = disableCountryCode ? '' : countryCallingCode;

        return `${callingCode}${phoneNumber}`;
    }

    return null;
}

function PhoneInput(props) {
    const {
        countryCallingCode,
        defaultCountry,
        disableCountryCode,
        error,
        label,
        onChange,
        value,
        ...otherProps
    } = props;

    function onChangePhoneNumber(phoneNumber, data) {
        const {
            callingCode,
            countryCode,
            nationalPhoneNumber
        } = getPhoneInputValues(phoneNumber, data, disableCountryCode);

        onChange(nationalPhoneNumber, callingCode, countryCode);
    }

    const phoneNumberWithCallingCode = getPhoneNumber(value, countryCallingCode, disableCountryCode);
    const className = clsx('input phone-input form-group', {
        invalid: error
    });

    return (
        <div className={className}>
            {
                label &&
                <label
                    className='control-label'
                    title={label}
                >
                    {label}
                </label>
            }
            <ReactPhoneInput
                {...otherProps}
                country={defaultCountry?.toLowerCase()}
                disableCountryCode={disableCountryCode}
                disableSearchIcon={true}
                onChange={onChangePhoneNumber}
                onMount={onChangePhoneNumber}
                value={phoneNumberWithCallingCode}
            />
            {
                error &&
                <label
                    className='validation-message'
                    title={error}
                >
                    {error}
                </label>
            }
        </div>
    );
}

PhoneInput.propTypes = {
    countryCallingCode: PropTypes.string,
    defaultCountry: PropTypes.string,
    disableCountryCode: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.stringOrNumber.isRequired
};

export default PhoneInput;
