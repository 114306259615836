// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DashboardHeaderWrapper from 'Ui/components/main-navbar/dashboard-header/dashboard-header-wrapper';
import PersistentHeaderWrapper from 'Ui/components/main-navbar/persistent-header/persistent-header-wrapper';
import {ONLINK_NAVIGATION_HEADER} from 'Common/constants/feature-toggles';

function MainNavBarWrapper(props) {
    const {
        appKeys,
        featureToggles,
        isMigrated
    } = props;

    const hasSomeAppAccess = isMigrated || appKeys?.onequip || appKeys?.ongolf || appKeys?.onlabor || appKeys?.onperform;
    const s3Path = hasSomeAppAccess ? `/s3/${window.props.newFeaturesBucket}/newFeatures.pdf` : '';
    const OnLinkHeaderWrapper = featureToggles[ONLINK_NAVIGATION_HEADER] ?
        PersistentHeaderWrapper :
        DashboardHeaderWrapper;

    return (
        <OnLinkHeaderWrapper
            {...props}
            hasSomeAppAccess={hasSomeAppAccess}
            s3Path={s3Path}
        />
    );
}

MainNavBarWrapper.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    user: PropTypes.user
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        user: {
            email: state.account.email,
            firstName: state.account.firstName,
            lastName: state.account.lastName
        }
    };
}

export default connect(mapStateToProps)(withRouter(MainNavBarWrapper));
