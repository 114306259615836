// Unpublished Work © 2023-2024 Deere & Company.

import {replaceTranslationNames} from 'Utils/translation-utils';
import {deletePurchaseOrder, getPurchaseOrder, postInventoryTransfers} from 'Services/inventory-service';
import {TOAST_TYPE} from '@deere/toast';
import {deletePartRequest} from 'Services/parts-service';
import {STATUS} from 'Utils/parts-utils';

async function handlePartRequestRevert({
    inventoryId, partsList
}) {
    const partRequestsToDelete = [];
    const pullPartBins = {
        isRevert: true,
        sourceInventoryId: inventoryId,
        partTransfers: []
    };

    partsList.forEach((part) => {
        part.partRequests?.forEach((partRequest) => {
            const {
                partRequestId,
                partId,
                quantity,
                totalCost,
                status
            } = partRequest;

            partRequestsToDelete.push(deletePartRequest(partRequestId));

            if (status === STATUS.RECEIVED) {
                const partIdAlreadyExists = pullPartBins.partTransfers.findIndex((partTransfer) => partTransfer.partId === partId);

                if (partIdAlreadyExists > -1) {
                    pullPartBins.partTransfers[partIdAlreadyExists].totalCost += totalCost;
                    pullPartBins.partTransfers[partIdAlreadyExists].quantity += quantity;
                } else {
                    pullPartBins.partTransfers.push({
                        partId,
                        quantity,
                        totalCost
                    });
                }
            }
        });
    });

    await Promise.all(partRequestsToDelete);
    await postInventoryTransfers(pullPartBins);
}

function handleDeletePurchaseOrder(openConfirmation, closeConfirmation, purchaseOrder, callGetPurchaseOrders, translations, addToast) {
    openConfirmation({
        message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
            '0': translations.ONLINK_PURCHASE_ORDER.toLowerCase()
        }),
        title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
            '0': translations.ONLINK_PURCHASE_ORDER
        }),
        async onContinue() {
            try {
                const {purchaseOrderId} = purchaseOrder;

                const {purchaseOrder: detailedPurchaseOrder} = await getPurchaseOrder(purchaseOrderId);

                await handlePartRequestRevert(detailedPurchaseOrder);
                await deletePurchaseOrder(purchaseOrderId);
            } catch (e) {
                addToast({
                    message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                        '0': translations.ONLINK_PURCHASE_ORDER.toLowerCase()
                    }),
                    type: TOAST_TYPE.ERROR
                });
            }

            closeConfirmation();
            await callGetPurchaseOrders();
        },
        onCancel: closeConfirmation
    });
}

export {
    handleDeletePurchaseOrder
};
