// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {GRAPH_THRESHOLD_CONSTANTS} from 'Ui/constants/graph-threshold-constants';
import {formatNumber, convertToUoM} from 'Utils/unit-conversion-utils';

const BASE_PATH = '/memberships/currentMembership';

async function getPanelDetails(panelType, dateSelect, timeScale) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`${BASE_PATH}/panelDetails/${panelType}`, {
        params: {
            date: dateSelect,
            timeScale
        }
    });

    return response.data;
}

async function getPanelThresholds() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`${BASE_PATH}/thresholds`);

    response.data.thresholds = [...response.data.thresholds, ...GRAPH_THRESHOLD_CONSTANTS];

    return response.data;
}

async function getTileData(path, queryParams) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`${BASE_PATH}/${path}`, {
        params: queryParams
    });

    return response.data;
}

async function getMyJDTileData(path, queryParams) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(path, {
        params: queryParams
    });

    return response.data;
}

async function getDataSource(dataSource, dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`${BASE_PATH}/dataSources/${dataSource}`, {
        params: {
            date: dateSelect
        }
    });

    return response.data;
}

async function getDataSourceForMembership(dataSource, dateSelect, membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/memberships/${membershipId}/dataSources/${dataSource}`, {
        params: {
            date: dateSelect
        }
    });

    return response.data;
}

async function getConvertedWeatherData(dataSource, dateSelect, conversionConfig) {
    const response = await getDataSource(dataSource, dateSelect);
    const {dailyForecast} = response;

    const formattedData = dailyForecast?.map((weatherData) => {
        const {
            day, night
        } = weatherData;
        const temp = day && day.temp || night && night.temp;

        return {
            convertedTemp: temp && formatNumber(convertToUoM(temp, conversionConfig), {
                maximumFractionDigits: 0
            }),
            ...weatherData
        };
    });

    return {
        ...response,
        dailyForecast: formattedData
    };
}

export {
    getPanelDetails,
    getPanelThresholds,
    getTileData,
    getDataSource,
    getDataSourceForMembership,
    getMyJDTileData,
    getConvertedWeatherData
};
