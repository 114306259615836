// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnlinkButton from 'Ui/components/common/onlink-button';
import SidebarHeader from 'OnLabor/workboard/calendar/sidebar-header';
import SwitchInput from 'Ui/components/common/form/switch-input';
import TodoList from 'OnLabor/workboard/common/todo-list';
import {updateWorkboardLayers} from 'Store/actions/workboard';
import {DATE_FORMATS} from 'Utils/time-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {getPresentationUrl} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import {useToggledMemo} from 'Ui/react-hooks/use-toggled-memo';
import {AVAILABILITY, WORKBOARD} from 'OnLabor/workboard/constants/event-types';
import {ONLINK_BLUE, ONLINK_WHITE, JD_GREEN_THEME} from 'Ui/constants/theme';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import moment from 'moment';
import {MANAGE_TIMEOFF} from 'Common/constants/business-activities';

const SHARED_WIDGET_PROPS = {
    bgcolor: ONLINK_WHITE,
    border: '1px solid',
    borderColor: 'grey.200',
    borderRadius: '5px'
};

function renderEvent(eventInfo) {
    const {
        extendedProps,
        start,
        title
    } = eventInfo.event;

    if (extendedProps.forecast) {
        return (
            <>
                <img
                    alt={extendedProps.forecast.icon_code.toString()}
                    className='workboard-weather-icon'
                    src={getWeatherIcon(extendedProps.forecast.icon_code)}
                />
                <i>{title}</i>
            </>
        );
    }

    if (extendedProps.employeeStatusOption) {
        return (
            <div className='centered-item'>
                {title}
            </div>
        );
    }

    return (
        <>
            <b>{moment(start).format('h:mma')}</b>
            {` ${title}`}
        </>
    );
}

function onLayerChange(name, value, updateLayersState, layers) {
    updateLayersState({
        ...layers,
        [name]: value
    });
}

function Calendar(props) {
    const {
        appUserId,
        availabilityByDate,
        featureToggles,
        fetchAvailabilityByDate,
        fetchWorkboards,
        isMigrated,
        layers,
        loading,
        membershipId,
        myJdPermissions,
        openAddTimeOffDialog,
        openAddWorkboardDialog,
        setStartAndEndTime,
        todoTemplates,
        translations,
        updateLayersState,
        weather,
        workboards
    } = props;

    const calendarRef = React.useRef();

    const loadingApplication = loading.workboards || loading.todoList || loading.availability;

    const {
        calendarProps = {},
        layersHeader,
        sidebarComponent: SidebarComponent,
        sidebarComponentProps = {},
        sidebarStackProps = {}
    } = useToggledMemo({
        disabledCallback: () => ({
            layersHeader: translations.ONLINK_LAYERS,
            sidebarComponent: React.Fragment
        }),
        enabledCallback: () => ({
            calendarProps: SHARED_WIDGET_PROPS,
            layersHeader: translations.ONLINK_CALENDAR_LAYERS,
            sidebarComponent: Box,
            sidebarComponentProps: {
                ...SHARED_WIDGET_PROPS,
                className: 'sidebar-group-box'
            },
            sidebarStackProps: {
                padding: '4px 12px 12px'
            }
        }),
        toggle: ONLINK_NAVIGATION_REDESIGN
    }, [translations]);

    return (
        <Stack
            className='workboard-container'
            direction='row'
            flex={1}
            overflow='hidden'
        >
            <LoadingWrapper
                alwaysRenderChildren={true}
                className='dashboard-loading-icon'
                loading={loadingApplication}
                size='50px'
            >
                <Box
                    {...calendarProps}
                    className='workboard-calendar'
                    padding='12px'
                    visibility={loadingApplication ? 'hidden' : 'visible'}
                    width='80%'
                >
                    <FullCalendar
                        contentHeight='100%'
                        customButtons={{
                            prev: {
                                click() {
                                    const calendarInstance = calendarRef.current.getApi();

                                    calendarInstance.prev();

                                    setStartAndEndTime({
                                        start: moment(calendarInstance.view.activeStart).format(DATE_FORMATS.day),
                                        end: moment(calendarInstance.view.activeEnd).format(DATE_FORMATS.day)
                                    });
                                }
                            },
                            next: {
                                click() {
                                    const calendarInstance = calendarRef.current.getApi();

                                    calendarInstance.next();

                                    setStartAndEndTime({
                                        start: moment(calendarInstance.view.activeStart).format(DATE_FORMATS.day),
                                        end: moment(calendarInstance.view.activeEnd).format(DATE_FORMATS.day)
                                    });
                                }
                            }
                        }}
                        eventClick={({event}) => {
                            if (event.extendedProps.type === WORKBOARD) {
                                openAddWorkboardDialog({
                                    onClose: fetchWorkboards,
                                    workboardId: event.id,
                                    workboards
                                });
                            } else if (event.extendedProps.type === AVAILABILITY) {
                                openAddTimeOffDialog({
                                    onClose: fetchAvailabilityByDate,
                                    timeOffValues: {
                                        ...event.extendedProps,
                                        toDate: moment(event.endStr).subtract(1, 'day').format(DATE_FORMATS.day)
                                    }
                                });
                            }
                        }}
                        eventContent={renderEvent}
                        eventOrder='sortIndex'
                        eventSources={[
                            {
                                color: !isMigrated ? ONLINK_BLUE : JD_GREEN_THEME,
                                display: layers.showWorkboards ? 'block' : 'none',
                                events: workboards,
                                textColor: ONLINK_WHITE
                            },
                            {
                                display: layers.showTimeOff ? 'block' : 'none',
                                events: availabilityByDate,
                                textColor: ONLINK_WHITE
                            },
                            {
                                display: 'list-item',
                                events: weather,
                                textColor: '#000'
                            }
                        ]}
                        eventTextColor={ONLINK_WHITE}
                        headerToolbar={{
                            left: 'prev,title,next',
                            right: 'dayGridMonth,dayGridWeek'
                        }}
                        height='100%'
                        locale={getLanguagePreference()}
                        locales={allLocales}
                        nextDayThreshold='12:00:00'
                        plugins={[dayGridPlugin, listPlugin]}
                        ref={calendarRef}
                    />
                </Box>
            </LoadingWrapper>
            <Stack
                className='workboard-sidebar'
                direction='column'
                overflow='auto'
            >
                {
                    !featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
                    <SidebarComponent {...sidebarComponentProps}>
                        <SidebarHeader title={translations.ONLINK_CALENDAR_ACTIONS}/>
                        <Stack
                            {...sidebarStackProps}
                            direction='column'
                            spacing='10px'
                        >
                            <a
                                className='onlink-btn onlink-btn-blue view-workboard-button'
                                href={getPresentationUrl(membershipId, featureToggles)}
                                rel='noreferrer'
                                target='_blank'
                            >
                                <div className='onlink-btn-text'>
                                    {translations.ONLINK_VIEW_WORKBOARD}
                                </div>
                            </a>
                            <OnlinkButton
                                className='workboard-button'
                                color='blue'
                                disabled={loading.workboards}
                                onClick={() => openAddWorkboardDialog({
                                    onClose: fetchWorkboards,
                                    workboards
                                })}
                            >
                                {translations.ONLINK_ADD_WORKBOARD}
                            </OnlinkButton>
                            <OnlinkButton
                                className='workboard-button'
                                color='blue'
                                onClick={() => openAddTimeOffDialog({
                                    onClose: fetchAvailabilityByDate
                                })}
                            >
                                {translations.ONLINK_ADD_TIME_OFF}
                            </OnlinkButton>
                        </Stack>
                    </SidebarComponent>
                }
                <SidebarComponent {...sidebarComponentProps}>
                    <SidebarHeader title={layersHeader}/>
                    <Stack
                        {...sidebarStackProps}
                        direction='column'
                        spacing='20px'
                    >
                        <SwitchInput
                            checked={layers.showWorkboards}
                            className='layer-input'
                            name='showWorkboards'
                            onChange={(name, checked) => onLayerChange(name, checked, updateLayersState, layers)}
                            onLabel={translations.ONLINK_WORKBOARDS}
                            translations={translations}
                        />
                        {
                            (!isMigrated || myJdPermissions[MANAGE_TIMEOFF]) &&
                            <SwitchInput
                                checked={layers.showTimeOff}
                                className='layer-input'
                                disabled={loading.availability}
                                name='showTimeOff'
                                onChange={(name, checked) => onLayerChange(name, checked, updateLayersState, layers)}
                                onLabel={translations.ONLINK_TIME_OFF}
                                translations={translations}
                            />
                        }
                    </Stack>
                </SidebarComponent>
                <SidebarComponent {...sidebarComponentProps}>
                    <TodoList
                        appUserId={appUserId}
                        featureToggles={featureToggles}
                        membershipId={membershipId}
                        todoTemplates={todoTemplates}
                        translations={translations}
                    />
                </SidebarComponent>
            </Stack>
        </Stack>
    );
}

Calendar.propTypes = {
    appUserId: PropTypes.string,
    availabilityByDate: PropTypes.arrayOf(PropTypes.object),
    featureToggles: PropTypes.featureToggles,
    fetchAvailabilityByDate: PropTypes.func,
    fetchWorkboards: PropTypes.func,
    isMigrated: PropTypes.bool,
    layers: PropTypes.object,
    loading: PropTypes.object,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openAddTimeOffDialog: PropTypes.func,
    openAddWorkboardDialog: PropTypes.func,
    setStartAndEndTime: PropTypes.func,
    todoTemplates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    updateLayersState: PropTypes.func,
    weather: PropTypes.arrayOf(PropTypes.object),
    workboards: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        appUserId: state.account.appUserId,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateLayersState(props) {
            dispatch(updateWorkboardLayers(props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
