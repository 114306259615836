// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import Membership from 'Ui/components/main-navbar/persistent-header/shared-navbar/membership';
import MobileDropdown from 'Ui/components/main-navbar/persistent-header/secondary-navbar/mobile-dropdown';
import NavBarLinks from 'Ui/components/main-navbar/persistent-header/secondary-navbar/navbar-links';
import WhatsNew from 'Ui/components/main-navbar/persistent-header/secondary-navbar/whats-new';
import 'Sass/persistent-header/ph-secondary-navbar.scss';

function SecondaryNavBar(props) {
    const {
        hasSomeAppAccess,
        isMobile,
        s3Path,
        translations
    } = props;

    return (
        <div className='secondary-navbar ph-navbar'>
            {
                isMobile ? (
                    <>
                        <MobileDropdown {...props}/>
                        <Membership/>
                    </>
                ) : (
                    <>
                        <NavBarLinks {...props}/>
                        {
                            hasSomeAppAccess &&
                            <WhatsNew
                                s3Path={s3Path}
                                translations={translations}
                            />
                        }
                    </>
                )
            }
        </div>
    );
}

SecondaryNavBar.propTypes = {
    hasSomeAppAccess: PropTypes.bool,
    isMobile: PropTypes.bool,
    links: PropTypes.object,
    s3Path: PropTypes.string,
    translations: PropTypes.translations,
    user: PropTypes.user
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(SecondaryNavBar);
