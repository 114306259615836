// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';

function PillButton(props) {
    const {
        children, icon, callBack
    } = props;

    return (
        <div
            className='pill-button'
        >
            <div className='pill-button-text'>
                {children}
            </div>
            <OnLinkIconButton
                onClick={callBack}
            >
                {icon}
            </OnLinkIconButton>
        </div>
    );
}

PillButton.propTypes = {
    callBack: PropTypes.func,
    children: PropTypes.node,
    icon: PropTypes.node
};

export default PillButton;
