// Unpublished Work © 2020-2024 Deere & Company.

function hasNoFileExtension(fileNameArgs) {
    return fileNameArgs.length === 1 || fileNameArgs[0] === '' && fileNameArgs.length === 2;
}

function getFileExtension(fileName) {
    const fileNameArgs = fileName.split('.');

    return hasNoFileExtension(fileNameArgs) ?
        null :
        fileNameArgs[fileNameArgs.length - 1].toLowerCase();
}

function isValidFileExtension(fileName, validFileExtensions) {
    const fileExtension = getFileExtension(fileName);

    return validFileExtensions.some((validFileExtension) => validFileExtension.toLowerCase() === fileExtension);
}

function validateFile(file, extensions, maxFileSizeError, extensionError) {
    if (file.size > window.props.maxFileSize) {
        throw new Error(maxFileSizeError);
    }

    if (!isValidFileExtension(file.name, extensions)) {
        throw new Error(extensionError);
    }
}

export {
    validateFile
};
