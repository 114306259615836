// Unpublished Work © 2022-2024 Deere & Company.

import {getJobTemplates} from 'Services/job-service';

async function fetchJobTemplates({
    deletedJobs = [],
    isMounted,
    setTemplates,
    translations
}) {
    const {templates: templatesData} = await getJobTemplates();

    const templatesWithDeletedJobs = [
        ...templatesData,
        ...deletedJobs.map((job) => ({
            jobTemplateId: job.jobTemplateId,
            jobCategory: translations.ONLINK_DELETED_JOBS,
            title: job.title,
            estDuration: job.estDuration
        }))
    ];

    if (isMounted()) {
        setTemplates(templatesWithDeletedJobs);
    }
}

export {
    fetchJobTemplates
};
