// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import {SpinnerControls} from 'Ui/components/spinner-controls';
import CurrentWorkboardItems from 'Ui/features/workboard-wizard/workboard-history/current-workboard-items';
import HistoryItems from 'Ui/features/workboard-wizard/workboard-history/history-items';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import WidgetBox from 'OnLabor/workboard/widget/widget-box';
import {useDeepMemo} from 'Utils/react-utils';
import {formatLocalizedTime} from 'Utils/time-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {ONLABOR_CURRENT_WORKBOARD_WIDGET} from 'Common/constants/feature-toggles';
import moment from 'moment';
import {sortBy} from 'lodash';
import {clsx} from 'clsx';

function sortByOperator(workItem) {
    const appUser = workItem.appUser;

    if (!appUser) {
        return '';
    }

    return `${appUser.firstName?.toLowerCase()} ${appUser.lastName?.toLowerCase()}`;
}

function sortByJob(workItem) {
    const jobTemplate = workItem.jobTemplate;

    if (!jobTemplate || !jobTemplate.title) {
        return '';
    }

    return jobTemplate.title.toLowerCase();
}

function WorkboardHistoryWidget(props) {
    const {
        featureToggles,
        operatorsMasterList,
        screenTouchListener,
        screenTouchTimerRef,
        setWorkboardIndex,
        translations,
        workboardId,
        workboardIndex,
        workboards,
        workItemsForWorkboard,
        templates,
        workboardCreationFlow,
        readOnly
    } = props;

    const sortedWorkItems = useDeepMemo(() => {
        const workItemsOrDefault = workItemsForWorkboard || [];

        return sortBy(workItemsOrDefault, sortByOperator, sortByJob);
    }, [workItemsForWorkboard]);

    const selectedWorkboard = workboards[workboardIndex];
    const hasWorkItems = !workItemsForWorkboard || workItemsForWorkboard.length > 0;
    const hasCurrentWorkboardWidgetToggle = featureToggles[ONLABOR_CURRENT_WORKBOARD_WIDGET];

    const title = hasCurrentWorkboardWidgetToggle ? '' : translations.ONLINK_WORKBOARD_HISTORY;

    return (
        <WidgetBox
            className={clsx('history-widget', {
                'history-widget-redesign': hasCurrentWorkboardWidgetToggle
            })}
            title={title}
        >
            {
                selectedWorkboard &&
                <>
                    <SpinnerControls
                        className='start-margin end-margin title-section'
                        hasCurrentWorkboardWidgetToggle={hasCurrentWorkboardWidgetToggle}
                        index={workboardIndex}
                        maxIndex={workboards.length - 1}
                        minIndex={0}
                        setIndex={setWorkboardIndex}
                        text={
                            selectedWorkboard.workboardId === workboardId ?
                                translations.ONLINK_CURRENT_WORKBOARD :
                                `${formatLocalizedTime(moment(selectedWorkboard.dateSelect))} ${selectedWorkboard.name}`
                        }
                    />
                    <Stack
                        direction='column'
                        overflow='auto'
                        spacing={2}
                    >
                        <LoadingWrapper
                            className='onlink-loading-icon'
                            loading={!workItemsForWorkboard}
                            size='50px'
                        >
                            {
                                selectedWorkboard.workboardId === workboardId ?
                                    <CurrentWorkboardItems
                                        operatorsMasterList={operatorsMasterList}
                                        readOnly={readOnly}
                                        screenTouchListener={screenTouchListener}
                                        screenTouchTimerRef={screenTouchTimerRef}
                                        sortedWorkItems={sortedWorkItems}
                                        templates={templates}
                                        translations={translations}
                                        workboardCreationFlow={workboardCreationFlow}
                                    /> :
                                    <NoDataMessage
                                        hasData={hasWorkItems}
                                        noDataMessage={replaceTranslationNames(translations.ONLINK_NO_ITEM_ASSIGNED, {
                                            '0': translations.JOBS
                                        })}
                                    >
                                        <HistoryItems
                                            sortedWorkItems={sortedWorkItems}
                                            translations={translations}
                                            workboardCreationFlow={workboardCreationFlow}
                                        />
                                    </NoDataMessage>
                            }
                        </LoadingWrapper>
                    </Stack>
                </>
            }
        </WidgetBox>
    );
}

WorkboardHistoryWidget.propTypes = {
    featureToggles: PropTypes.featureToggles,
    operatorsMasterList: PropTypes.arrayOf(PropTypes.user),
    readOnly: PropTypes.bool,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    setWorkboardIndex: PropTypes.func,
    templates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    workboardCreationFlow: PropTypes.string,
    workboardId: PropTypes.string,
    workboardIndex: PropTypes.number,
    workboards: PropTypes.arrayOf(PropTypes.object),
    workItemsForWorkboard: PropTypes.arrayOf(PropTypes.object)
};

export default WorkboardHistoryWidget;
