// Unpublished Work © 2020-2024 Deere & Company.

import {
    SET_USER_VARIABLES,
    SET_FEATURE_TOGGLES,
    SET_HAS_MY_JD_SESSION,
    SET_MY_JD_ID,
    SET_PERMISSIONS,
    SET_AVAILABLE_LAYOUTS,
    SET_ACTIVE_LAYOUT,
    SET_MYJD_PERMISSIONS,
    SET_MYJD_USER_ORGS
} from 'Store/actions/account';

const DEFAULT_STATE = {
    activeLayout: {},
    appKeys: {},
    appUserId: null,
    availableLayouts: [],
    email: null,
    featureToggles: {},
    firstName: null,
    hasMyJDSession: false,
    myJdId: null,
    lastName: null,
    permissions: {},
    superUser: false,
    membershipId: null,
    myJdPermissions: {}
};

const account = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_USER_VARIABLES:
            return Object.assign({}, state, {
                appKeys: action.user.appKeys,
                appUserId: action.user.appUserId,
                email: action.user.email,
                firstName: action.user.firstName,
                lastName: action.user.lastName,
                superUser: action.user.superUser,
                membershipId: action.user.membershipId
            });
        case SET_FEATURE_TOGGLES:
            return Object.assign({}, state, {
                featureToggles: action.featureToggles
            });
        case SET_HAS_MY_JD_SESSION:
            return Object.assign({}, state, {
                hasMyJDSession: action.hasMyJDSession
            });
        case SET_MY_JD_ID:
            return Object.assign({}, state, {
                myJdId: action.myJdId
            });
        case SET_PERMISSIONS:
            return Object.assign({}, state, {
                permissions: action.permissions
            });
        case SET_AVAILABLE_LAYOUTS:
            return Object.assign({}, state, {
                availableLayouts: action.availableLayouts
            });
        case SET_ACTIVE_LAYOUT:
            return Object.assign({}, state, {
                activeLayout: action.activeLayout
            });
        case SET_MYJD_PERMISSIONS:
            return Object.assign({}, state, {
                myJdPermissions: action.myJdPermissions
            });
        case SET_MYJD_USER_ORGS:
            return Object.assign({}, state, {
                myJdUserOrgs: action.myJdUserOrgs
            });
        default:
            return state;
    }
};

export default account;
