// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {BODY_FONT_STYLE, LABEL_FONT_STYLE} from 'Ui/constants/read-only/font-style';

function ReadOnlyList(props) {
    const {
        items,
        label,
        noItemsSelectedLabel,
        selectedIds,
        ...containerProps
    } = props;

    const selectedItems = React.useMemo(() => items.filter(({id}) => selectedIds.includes(id)), [items, selectedIds]);

    if (selectedItems.length === 0) {
        return (
            <Typography
                {...LABEL_FONT_STYLE}
                {...containerProps}
            >
                {noItemsSelectedLabel}
            </Typography>
        );
    }

    return (
        <Stack
            direction='column'
            {...containerProps}
        >
            {
                label &&
                <Typography
                    {...LABEL_FONT_STYLE}
                    marginBottom='2px'
                >
                    {label}
                </Typography>
            }
            <Stack direction='column'>
                {
                    selectedItems.map((item) => (
                        <Box
                            key={item.id}
                            marginBottom='5px'
                        >
                            <Typography {...BODY_FONT_STYLE}>{item.title}</Typography>
                            <Typography
                                {...BODY_FONT_STYLE}
                                color='grey.500'
                            >
                                {item.subtitle}
                            </Typography>
                        </Box>
                    ))
                }
            </Stack>
        </Stack>
    );
}

ReadOnlyList.propTypes = {
    items: PropTypes.multiSelectList,
    label: PropTypes.string,
    noItemsSelectedLabel: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.string)
};

export default ReadOnlyList;
