// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {joinClassNames} from 'Utils/html-utils';
import PropTypes from 'Utils/prop-type-utils';
import PanelDateLabel from 'Ui/components/panel/panel-date-label';

function PanelData(props) {
    const {
        value, smallSuffix, label, date, className, translations
    } = props;

    return (
        <div className={joinClassNames('dashboard-panel-data', className)}>
            <div className='panel-data-value-container'>
                <div className='panel-data-value'>
                    {value}
                </div>
                <div className='panel-data-small-suffix'>
                    {translations[smallSuffix] || smallSuffix}
                </div>
            </div>
            <div className='panel-data-label'>
                {translations[label] || label}
            </div>
            {!isNullOrUndefined(date) && <PanelDateLabel date={date}/>}
        </div>
    );
}

PanelData.defaultProps = {
    smallSuffix: ''
};

PanelData.propTypes = {
    className: PropTypes.string,
    date: PropTypes.string,
    label: PropTypes.stringOrNumberOrObject.isRequired,
    smallSuffix: PropTypes.string,
    translations: PropTypes.translations,
    value: PropTypes.stringOrNumberOrObject
};

export default PanelData;
