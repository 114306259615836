// Unpublished Work © 2022-2024 Deere & Company.

import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import React from 'react';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import EditableJobSettings from 'Ui/components/settings/labor/editable-job-settings';
import {fetchEffectData} from 'Utils/react-utils';
import {getEquipmentAreas, getEquipmentTypes} from 'Services/membership-service';
import {createJobTemplate, getJobCategories, updateJobTemplate} from 'Services/job-service';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import * as PropTypes from 'prop-types';
import {sortBy} from 'lodash';

const HOUR = 60;

function JobDialog(props) {
    const {
        closeDialog,
        getUpdatedJobTemplates,
        invalidInputs,
        membershipId,
        job,
        setValid,
        translations
    } = props;

    const [loading, setLoading] = React.useState(() => true);
    const [allData, setAllData] = React.useState(() => ({
        equipmentAreas: [],
        equipmentTypes: [],
        equipmentTypesData: [],
        jobCategories: []
    }));
    const [values, setValues] = React.useState(() => ({
        equipmentAreaId: job?.equipmentAreaId || null,
        equipmentTypes: job?.equipmentTypes?.map((equipmentType) => equipmentType.equipmentTypeId) || [],
        estDuration: job?.estDuration || '',
        inactive: job?.inactive || false,
        jobCategory: job?.jobCategory || null,
        membershipId,
        seq: job?.seq || 0,
        secondaryName: job?.secondaryName || '',
        title: job?.title || '',
        ppeList: job?.ppeList || []
    }));

    async function onSave() {
        setLoading(true);

        const equipmentTypesToSave = allData.equipmentTypesData.filter((equipmentType) => {
            return values.equipmentTypes.includes(equipmentType.equipmentTypeId);
        });

        const jobToSave = {
            ...values,
            estDuration: parseInt(values.estDuration * HOUR, 10),
            equipmentTypes: equipmentTypesToSave
        };

        if (job) {
            await updateJobTemplate(job.jobTemplateId, jobToSave);
        } else {
            await createJobTemplate(jobToSave);
        }

        await getUpdatedJobTemplates();

        setLoading(false);

        closeDialog();
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);
        const [{equipmentAreas}, {equipmentTypes}, {jobCategories}] = await Promise.all([
            getEquipmentAreas(translations),
            getEquipmentTypes(),
            getJobCategories()
        ]);

        const equipmentAreasOptions = [
            {
                id: undefined,
                title: capitalizeFirstLetter(translations.NONE)
            },
            ...equipmentAreas.map((equipmentArea) => ({
                id: equipmentArea.equipmentAreaId,
                title: equipmentArea.name
            }))
        ];

        const equipmentTypesOptions = equipmentTypes.map((equipmentType) => ({
            id: equipmentType.equipmentTypeId,
            title: equipmentType.name
        }));

        const jobCategoriesOptions = jobCategories.map((jobCategory) => ({
            id: jobCategory.title,
            title: jobCategory.title
        }));

        if (isMounted()) {
            setAllData({
                equipmentAreas: sortBy(equipmentAreasOptions, 'title'),
                equipmentTypes: sortBy(equipmentTypesOptions, 'title'),
                equipmentTypesData: equipmentTypes,
                jobCategories: sortBy(jobCategoriesOptions, 'title')
            });

            setLoading(false);
        }
    }), []);

    const title = job ? translations.EDIT_JOB : translations.ONLINK_ADD_JOB;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='job-dialog dropdown-overflow-dialog'
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0}
            onSave={onSave}
            saveLabel={translations.save}
            title={title}
            translations={translations}
        >
            <EditableJobSettings
                allData={allData}
                loading={loading}
                setValid={setValid}
                setValues={setValues}
                translations={translations}
                values={values}
            />
        </FormDialog>
    );
}

JobDialog.propTypes = {
    closeDialog: PropTypes.func,
    getUpdatedJobTemplates: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    job: PropTypes.object,
    membershipId: PropTypes.string,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.JOB_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(JobDialog));
