// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getSessionStrategy() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/session/strategy');

    return response.data;
}

async function refreshMyJdPermissions() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put('/api/session/myJdPermissions');

    return response.data;
}

async function updateSessionMembership(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put('/api/session/membership', {
        membershipId
    });

    return response.data;
}

async function updateUserSessionForOrg(orgId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put('/api/session/org', {
        orgId
    });

    return response.data;
}

export {
    getSessionStrategy,
    refreshMyJdPermissions,
    updateSessionMembership,
    updateUserSessionForOrg
};
