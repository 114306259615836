// Unpublished Work © 2020-2024 Deere & Company.

import PropTypes from 'prop-types';

const stringLiteralOrArray = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);
const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
const stringOrNumberOrElement = PropTypes.oneOfType([stringOrNumber, PropTypes.element]);
const stringOrNumberOrObject = PropTypes.oneOfType([stringOrNumber, PropTypes.object]);
const arrayOfElementsOrObject = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]);
const objectOfBool = PropTypes.objectOf(PropTypes.bool);

const errors = PropTypes.shape({
    customError: PropTypes.string,
    patternMismatch: PropTypes.string,
    rangeOverflow: PropTypes.string,
    rangeUnderflow: PropTypes.string,
    stepMismatch: PropTypes.string,
    tooLong: PropTypes.string,
    tooShort: PropTypes.string,
    typeMismatch: PropTypes.string,
    valueMissing: PropTypes.string
});

const files = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
]);

const map = PropTypes.shape({
    fitBounds: PropTypes.func,
    panTo: PropTypes.func
});

const xyCoordinates = PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
});

const mapPosition = PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
});

const equipment = PropTypes.shape({
    engineHours: PropTypes.number,
    equipmentName: PropTypes.string,
    equipmentType: PropTypes.string,
    icon: PropTypes.shape({
        name: PropTypes.string
    }),
    lat: PropTypes.number,
    lon: PropTypes.number,
    modelName: PropTypes.string,
    reportTime: PropTypes.string,
    serialNumber: PropTypes.string
});

const tile = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
});

const multiSelectItem = PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
});

const multiSelectList = PropTypes.arrayOf(multiSelectItem);

const checkListItem = PropTypes.shape({
    id: stringOrNumber,
    label: PropTypes.string
});

const checkListItemsList = PropTypes.arrayOf(checkListItem);

const position = PropTypes.shape({
    i: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    w: PropTypes.number,
    h: PropTypes.number
});

const tilePosition = PropTypes.shape({
    tile,
    columnCount: PropTypes.number,
    position
});

const layout = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    onlinkUserId: PropTypes.string,
    tilePositions: PropTypes.arrayOf(tilePosition)
});

const link = PropTypes.shape({
    path: PropTypes.string,
    basePath: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    reactRouter: PropTypes.bool
});

const links = PropTypes.shape({
    account: PropTypes.shape({
        integrationLinks: PropTypes.arrayOf(link),
        userLinks: PropTypes.arrayOf(link),
        otherLinks: PropTypes.arrayOf(link)
    }),
    mainHeaderLinks: PropTypes.arrayOf(link)
});

const location = PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
});

const history = PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location,
    push: PropTypes.func,
    replace: PropTypes.func
});

const machineStateMeasurements = PropTypes.shape({
    avgGroundSpeed: PropTypes.number,
    utilization: PropTypes.number
});

const machineMeasurements = PropTypes.shape({
    idle: machineStateMeasurements,
    transport: machineStateMeasurements,
    working: machineStateMeasurements,
    batteryLevel: PropTypes.number
});

const match = PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.objectOf(PropTypes.string),
    path: PropTypes.string,
    url: PropTypes.string
});

const navItem = PropTypes.shape({
    active: PropTypes.bool,
    dropdown: PropTypes.func,
    isDropdown: PropTypes.bool,
    link,
    links: PropTypes.arrayOf(link),
    name: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string
});

const threshold = PropTypes.shape({
    defaultValue: PropTypes.number,
    value: PropTypes.number
});

const thresholds = PropTypes.arrayOf(
    PropTypes.shape({
        dataLabel: PropTypes.string,
        dataType: PropTypes.string,
        dataUnits: PropTypes.string,
        membershipId: PropTypes.string,
        thresholds: PropTypes.shape({
            highNormal: threshold,
            highWarning: threshold,
            lowNormal: threshold,
            lowWarning: threshold
        })
    })
);

const panel = PropTypes.shape({
    panelTitle: PropTypes.string,
    dataRollups: PropTypes.arrayOf(
        PropTypes.shape({
            dataType: PropTypes.string,
            config: PropTypes.shape({
                dataType: PropTypes.string
            })
        })
    ),
    thresholds,
    conditions: PropTypes.object
});

const tileData = PropTypes.oneOfType([
    panel,
    PropTypes.arrayOf(panel)
]);

const tileInfo = PropTypes.shape({
    component: PropTypes.elementType,
    name: PropTypes.string,
    onClick: PropTypes.func,
    tileId: PropTypes.string,
    url: PropTypes.string
});

const dialogContent = PropTypes.oneOfType([
    PropTypes.shape({
        element: stringOrNumberOrElement,
        id: PropTypes.string,
        label: PropTypes.string
    }),
    PropTypes.shape({
        subHeader: PropTypes.string
    })
]);

const row = PropTypes.oneOfType([
    PropTypes.arrayOf(stringOrNumberOrElement),
    PropTypes.objectOf(stringOrNumberOrElement)
]);

const appKeys = objectOfBool;

const imperial = PropTypes.objectOf(PropTypes.string);

const translations = PropTypes.objectOf(PropTypes.string);

const compensation = PropTypes.shape({
    benefitsPercent: PropTypes.number,
    hourlyRate: PropTypes.number,
    overtimeRate: PropTypes.number,
    salaryMonthly: PropTypes.number,
    salaryWeekly: PropTypes.number,
    taxPercent: PropTypes.number
});

const equipmentType = PropTypes.shape({
    equipmentArea: PropTypes.string,
    equipmentAreaId: PropTypes.string,
    equipmentTypeId: PropTypes.string,
    membershipId: PropTypes.string,
    name: PropTypes.string
});

const user = PropTypes.shape({
    appKeys: PropTypes.arrayOf(PropTypes.string),
    appUserId: PropTypes.string,
    checkAlarm: PropTypes.bool,
    compensation,
    departmentIds: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    equipmentTypes: PropTypes.arrayOf(equipmentType),
    firstName: PropTypes.string,
    inactive: PropTypes.bool,
    lastName: PropTypes.string,
    membershipId: PropTypes.string,
    phone: PropTypes.string,
    superUser: PropTypes.bool,
    title: PropTypes.string,
    userRoleId: PropTypes.string
});

const userAcknowledgment = PropTypes.shape({
    appUserId: PropTypes.string,
    acknowledgmentId: PropTypes.string,
    accepted: PropTypes.bool
});

const membership = PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    membershipId: PropTypes.string,
    membershipType: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
});

const editableThreshold = PropTypes.shape({
    highNormal: stringOrNumber,
    highWarning: stringOrNumber,
    lowNormal: stringOrNumber,
    lowWarning: stringOrNumber,
    types: PropTypes.string,
    units: PropTypes.string
});

const featureToggles = objectOfBool;

const appliedFilters = PropTypes.arrayOf(
    PropTypes.shape({
        name: PropTypes.string,
        clearCallBack: PropTypes.func
    })
);

const reference = PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
});

const itemLocationsWithMembership = PropTypes.shape({
    itemLocations: PropTypes.arrayOf(equipment),
    membership: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
    })
});

const imageSource = PropTypes.oneOfType([
    PropTypes.shape({
        uri: PropTypes.string,
        headers: PropTypes.objectOf(PropTypes.string)
    }),
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(
        PropTypes.shape({
            uri: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
            headers: PropTypes.objectOf(PropTypes.string)
        })
    )
]);

const legacyPermissions = objectOfBool;
const myJdPermissions = objectOfBool;

export default Object.assign(PropTypes, {
    arrayOfElementsOrObject,
    appKeys,
    appliedFilters,
    checkListItemsList,
    compensation,
    dialogContent,
    editableThreshold,
    equipment,
    equipmentType,
    errors,
    featureToggles,
    files,
    history,
    imageSource,
    imperial,
    itemLocationsWithMembership,
    layout,
    legacyPermissions,
    link,
    links,
    location,
    machineMeasurements,
    map,
    mapPosition,
    match,
    membership,
    multiSelectList,
    myJdPermissions,
    navItem,
    objectOfBool,
    panel,
    position,
    reference,
    row,
    stringLiteralOrArray,
    stringOrNumber,
    stringOrNumberOrElement,
    stringOrNumberOrObject,
    thresholds,
    tile,
    tileData,
    tileInfo,
    tilePosition,
    translations,
    user,
    userAcknowledgment,
    xyCoordinates
});
