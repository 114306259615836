// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import {Radio, FormControlLabel} from '@deere/isg.component-library';
import {connect} from 'react-redux';

function RadioButton(props) {
    const {
        className,
        id,
        isMigrated,
        isSelected,
        label,
        onChange,
        value
    } = props;

    if (isMigrated) {
        return (
            <FormControlLabel
                className='radio-button-label'
                control={<Radio
                    checked={isSelected}
                    className='radio-button-input'
                    id={id}
                    onChange={onChange}
                    value={value}
                />}
                label={label}
            />
        );
    }

    return (
        <div className={joinClassNames('radio-button', className)}>
            <input
                checked={isSelected}
                className='radio-button-input'
                id={id}
                onChange={onChange}
                type='radio'
                value={value}
            />
            <label
                className='radio-button-label'
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    );
}

RadioButton.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    isMigrated: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(RadioButton);
