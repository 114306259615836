// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DrillInLinks from 'Ui/components/secondary-navbar/drill-in-links';
import Membership from 'Ui/components/secondary-navbar/membership';
import MobileNavWrapper from 'Ui/components/secondary-navbar/mobile-nav-wrapper';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {isActive} from 'Ui/components/secondary-navbar/utils/nav-helpers';

function getLinksWithMembership(navLinks, membershipId) {
    return navLinks.map((navLink) => {
        if (navLink.links) {
            navLink.links = getLinksWithMembership(navLink.links, membershipId);
            return navLink;
        }

        if (navLink.reactRouter) {
            return navLink;
        }

        return {
            ...navLink,
            basePath: navLink.basePath.replace('{0}', membershipId),
            path: navLink.path.replace('{0}', membershipId)
        };
    });
}

function getNavItems(navLinks, location, translations) {
    let activeNavItem = null;

    const navItems = navLinks.map((navLink) => {
        const active = isActive(navLink, location);

        if (active) {
            activeNavItem = navLink.name;
        }

        if (navLink.isDropdown) {
            return {
                active,
                ...navLink
            };
        }

        return {
            active,
            isDropdown: false,
            link: navLink,
            name: translations[navLink.name] || navLink.name,
            type: navLink.path.substring(navLink.path.lastIndexOf('/') + 1)
        };
    });

    return {
        activeNavItem,
        navItems
    };
}

function addNavbarComponents(props, navbarItems, dropdownOpen, toggleDropdown) {
    const {
        appKeys,
        barComponent: BarComponent,
        featureToggles,
        history,
        location,
        match,
        membershipId,
        mobileNavComponent: MobileNavComponent,
        navLinks,
        superUser,
        translations,
        userPermissions: permissions
    } = props;

    const userAuth = {
        appKeys,
        featureToggles,
        superUser,
        permissions
    };

    const filteredNavLinks = navLinks.filter((navLink) => isAuthorized(navLink, userAuth));
    const transformedNavLinks = getLinksWithMembership(filteredNavLinks, membershipId);

    const {
        activeNavItem,
        navItems
    } = getNavItems(transformedNavLinks, location, translations);

    navbarItems.push(
        <DrillInLinks
            history={history}
            key='drillInLinks'
            navItems={navItems}
            translations={translations}
        />,
        <MobileNavComponent
            activeNavItem={activeNavItem}
            dropdownOpen={dropdownOpen}
            history={history}
            key='mobileNavComponent'
            location={location}
            match={match}
            navItems={navItems}
            toggleDropdown={toggleDropdown}
            translations={translations}
        />
    );

    if (BarComponent) {
        navbarItems.push(
            <div
                className='mobile-layout'
                key='barComponent'
            >
                <BarComponent
                    activeNavItem={activeNavItem}
                    dropdownOpen={dropdownOpen}
                    history={history}
                    location={location}
                    match={match}
                    navItems={navItems}
                    toggleDropdown={toggleDropdown}
                    translations={translations}
                />
            </div>
        );
    }
}

function createNavbarItems(props, dropdownOpen, toggleDropdown) {
    const {
        appAccess,
        appKeys,
        featureToggles,
        navLinks,
        permission,
        superUser,
        toggle,
        userPermissions: permissions
    } = props;

    const requiredAuth = {
        appAccess,
        permission,
        toggle
    };

    const userAuth = {
        appKeys,
        featureToggles,
        permissions,
        superUser
    };

    const navbarItems = [<Membership key='membership'/>];

    if (navLinks && isAuthorized(requiredAuth, userAuth)) {
        addNavbarComponents(props, navbarItems, dropdownOpen, toggleDropdown);
    }

    return navbarItems;
}

function SecondaryNavbar(props) {
    const [dropdownOpen, setDropdownOpen] = React.useState(() => false);

    function toggleDropdown() {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <div className='secondary-navbar'>
            {createNavbarItems(props, dropdownOpen, toggleDropdown)}
        </div>
    );
}

SecondaryNavbar.defaultProps = {
    mobileNavComponent: MobileNavWrapper
};

SecondaryNavbar.propTypes = {
    appAccess: PropTypes.string,
    appKeys: PropTypes.appKeys,
    barComponent: PropTypes.elementType,
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    location: PropTypes.location,
    match: PropTypes.match,
    membershipId: PropTypes.string,
    mobileNavComponent: PropTypes.elementType,
    navLinks: PropTypes.arrayOf(PropTypes.link),
    permission: PropTypes.string,
    superUser: PropTypes.bool,
    toggle: PropTypes.string,
    translations: PropTypes.translations,
    userPermissions: PropTypes.legacyPermissions
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        membershipId: state.membership.membershipId,
        superUser: state.account.superUser,
        translations: state.translations,
        userPermissions: state.account.permissions
    };
}

export default connect(mapStateToProps)(withRouter(SecondaryNavbar));
