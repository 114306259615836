// Unpublished Work © 2023-2024 Deere & Company.

import SelectedPartQuantityWithCost from 'Ui/components/common/parts-selectors/selected-part-quantity-with-cost';
import React from 'react';
import PartInfoSubRows from 'Ui/components/common/parts-selectors/part-info-sub-rows';
import {STATUS} from 'Utils/parts-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatDateOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import PropTypes from 'Utils/prop-type-utils';
import {asNumber} from 'Utils/conversion-utils';

function SelectedPartQuantityWithCostWithSubRows({
    currencyPreference,
    disabled,
    featureToggles,
    isReceiving,
    onChange,
    onClear,
    part,
    quantityRequired,
    setPartRequestsToRemove,
    setValid,
    translations,
    readOnly,
    hideFinancialData
}) {
    const rows = React.useMemo(() => {
        if (isReceiving) {
            const quantityReceived = asNumber(part.quantityReceived);
            const orderedQuantity = asNumber(part.quantityMax) + quantityReceived;
            const unitCost = asNumber(part.unitCost);

            const receivingPartSubRows = [{
                quantity: orderedQuantity,
                title: translations.ONLINK_ORDERED,
                totalCost: orderedQuantity * unitCost,
                unitCost
            }];

            if (quantityReceived > 0) {
                receivingPartSubRows.push({
                    quantity: quantityReceived,
                    title: translations.ONLINK_PREVIOUSLY_RECEIVED,
                    totalCost: asNumber(part.totalCost),
                    unitCost
                });
            }

            return receivingPartSubRows;
        }

        return part.partRequests?.filter((partRequest) => partRequest.quantity > 0).map((partRequest) => {
            const title = partRequest.status === STATUS.RECEIVED ? translations.my_team_received :
                replaceTranslationNames(translations.ONLINK_RECOMMENDED_FOR, {
                    '0': formatDateOrDefault(partRequest.dateSelect)
                });

            return {
                partRequestId: partRequest.partRequestId,
                quantity: partRequest.quantity,
                title,
                totalCost: partRequest.totalCost,
                unitCost: partRequest.unitCost
            };
        }) || [];
    }, [disabled, isReceiving, onClear, part]);

    function onClearWithRequestRemoval(part) {
        onClear(part);

        setPartRequestsToRemove((prevPartRequests) => {
            const partRequestIds = rows.map((row) => {
                return row.partRequestId;
            });

            return new Set([...prevPartRequests, ...partRequestIds]);
        });
    }

    return (
        <div className='parts-selected-row-with-sub-rows'>
            <SelectedPartQuantityWithCost
                currencyPreference={currencyPreference}
                disabled={disabled}
                featureToggles={featureToggles}
                hideFinancialData={hideFinancialData}
                isReceiving={isReceiving}
                onChange={onChange}
                onClear={onClear ? onClearWithRequestRemoval : null}
                part={part}
                quantityRequired={quantityRequired}
                readOnly={readOnly}
                setValid={setValid}
                translations={translations}
            />
            <PartInfoSubRows
                currencyPreference={currencyPreference}
                disabled={disabled}
                featureToggles={featureToggles}
                hideFinancialData={hideFinancialData}
                onClear={onClear}
                readOnly={readOnly}
                rows={rows}
                setPartRequestsToRemove={setPartRequestsToRemove}
            />
        </div>
    );
}

SelectedPartQuantityWithCostWithSubRows.propTypes = {
    currencyPreference: PropTypes.string,
    disabled: PropTypes.bool,
    featureToggles: PropTypes.featureToggles,
    hideFinancialData: PropTypes.bool,
    isReceiving: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    part: PropTypes.object,
    quantityRequired: PropTypes.bool,
    readOnly: PropTypes.bool,
    setPartRequestsToRemove: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default SelectedPartQuantityWithCostWithSubRows;
