// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import GridLayout from 'Ui/components/grid/grid-layout';
import OnlinkButton from 'Ui/components/common/onlink-button';
import Tile from 'Ui/components/dashboard/tile';
import {createTileFromLayout, createTileMap} from 'Utils/dashboard-utils';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import tileInfo from 'Ui/components/common/tile-constants/tile-info';
import {ONLINK_NAVIGATION_HEADER} from 'Common/constants/feature-toggles';
import {COURSE} from 'Common/constants/membership-type';
import dialogTypes from 'Ui/components/common/dialog-types';

function createTileDataMap(tileData) {
    if (tileData.data) {
        const data = Array.isArray(tileData.data) ?
            tileData.data.find(({panels}) => panels) :
            tileData.data;

        if (data && data.panels) {
            return data.panels.reduce(createTileMap, {});
        }
    }

    return {};
}

function getTileData(data, tileData) {
    const isEmpty = Object.keys(tileData).length;

    if (Array.isArray(data)) {
        return isEmpty ? [tileData].concat(data) : data;
    }

    return isEmpty ? tileData : data;
}

function createTileComponents({
    appKeys,
    featureToggles,
    fetchDate,
    history,
    integrations,
    isMigrated,
    membershipId,
    myJdPermissions,
    thresholds,
    tileData,
    translations
}) {
    const tileMap = createTileDataMap(tileData);

    return tileData.tileIds.reduce((tileComponents, tileId) => {
        const newTileInfo = tileInfo[tileId];
        const tileMapData = tileMap[newTileInfo.name] || {};
        const newTileData = getTileData(tileData.data, tileMapData);

        const tileLayout = createTileFromLayout(newTileData, newTileInfo, integrations, {
            appKeys,
            featureToggles,
            isMigrated,
            myJdPermissions
        });

        if (tileLayout) {
            const onClickHandler = newTileInfo.onClick && (
                () => newTileInfo.onClick(
                    membershipId,
                    tileMapData.childCategory,
                    history,
                    newTileData.panelType,
                    newTileData.categoryType
                )
            );

            return tileComponents.concat(
                <Tile
                    columnCount={newTileInfo.columnCount}
                    component={newTileInfo.component}
                    featureToggles={featureToggles}
                    fetchDate={fetchDate}
                    key={tileId}
                    onClick={onClickHandler}
                    thresholds={thresholds}
                    tileData={tileLayout}
                    translations={translations}
                />
            );
        }

        return tileComponents;
    }, []);
}

function TileLayout(props) {
    const {
        featureToggles,
        layout,
        membershipType,
        onCloseEntryDialog,
        openManualDataDialog,
        tileData,
        translations
    } = props;

    const showManualDataButton = !featureToggles[ONLINK_NAVIGATION_HEADER] && membershipType === COURSE;

    return (
        <>
            <GridLayout
                layout={layout}
                measureBeforeMount={true}
            >
                {
                    tileData.reduce((tiles, tileDataItem) => {
                        const newTiles = createTileComponents({
                            ...props,
                            tileData: tileDataItem
                        });

                        return tiles.concat(newTiles);
                    }, [])
                }
            </GridLayout>
            {
                showManualDataButton &&
                <OnlinkButton
                    className='onlink-btn-fixed'
                    color='blue'
                    onClick={() => openManualDataDialog({
                        onCloseEntryDialog,
                        translations
                    })}
                >
                    {translations.ONLINK_MANUAL_DATA}
                </OnlinkButton>
            }
        </>
    );
}

TileLayout.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    fetchDate: PropTypes.string,
    history: PropTypes.history,
    integrations: PropTypes.arrayOf(PropTypes.object),
    isMigrated: PropTypes.bool,
    layout: PropTypes.layout,
    membershipId: PropTypes.string,
    membershipType: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    onCloseEntryDialog: PropTypes.func,
    openManualDataDialog: PropTypes.func,
    thresholds: PropTypes.thresholds,
    tileData: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.tileData,
        tileIds: PropTypes.arrayOf(PropTypes.string)
    })),
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        membershipType: state.membership.membershipType,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openManualDataDialog(props) {
            dispatch(openReduxDialog(dialogTypes.MANUAL_DATA_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TileLayout));
