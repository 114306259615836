// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import {Bar} from 'react-chartjs-2';
import {INVENTORIES} from 'Ui/components/graph/constants/graph-filters';
import {formatNumber, getFormattedCurrency} from 'Utils/unit-conversion-utils';
import React from 'react';

function getYAxisScales(
    primarySelector,
    translations,
    {
        currencyPreference,
        featureToggles
    }) {
    const yAxis = {
        cost: {
            type: 'linear',
            stacked: true,
            position: 'left',
            ticks: {
                beginAtZero: true,
                callback(value) {
                    return getFormattedCurrency(value, {
                        currencyPreference,
                        featureToggles
                    });
                }
            }
        }
    };

    if (primarySelector !== INVENTORIES) {
        yAxis.hours = {
            type: 'linear',
            position: 'right',
            ticks: {
                callback(value) {
                    return `${formatNumber(value)} ${translations.HOURS_UOM}`;
                }
            }
        };
    }

    return yAxis;
}

function LifetimeReportChart(props) {
    const {
        chartData,
        primarySelector,
        translations,
        featureToggles,
        currencyPreference
    } = props;

    return (
        <Bar
            data={chartData}
            options={{
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top'
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(tooltipItem) {
                                const {
                                    dataset,
                                    parsed
                                } = tooltipItem;

                                if (dataset.stack === 'cost') {
                                    return getFormattedCurrency(parsed.y, {
                                        currencyPreference,
                                        featureToggles
                                    });
                                }

                                return `${formatNumber(parsed.y)} ${translations.HOURS_UOM}`;
                            }
                        }
                    }
                },
                scales: getYAxisScales(primarySelector,
                    translations,
                    {
                        currencyPreference,
                        featureToggles
                    })
            }}
            type='bar'
        />
    );
}

LifetimeReportChart.propTypes = {
    chartData: PropTypes.object,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    primarySelector: PropTypes.string,
    translations: PropTypes.translations
};

export default LifetimeReportChart;
