// Unpublished Work © 2022-2024 Deere & Company.

export const UPDATE_WORKBOARD_LAYERS = 'UPDATE_WORKBOARD_LAYERS';

export function updateWorkboardLayers(workboardLayers) {
    return {
        type: UPDATE_WORKBOARD_LAYERS,
        workboardLayers
    };
}
