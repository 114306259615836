// Unpublished Work © 2022-2024 Deere & Company.

import {replaceTranslationNames} from 'Utils/translation-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

const SERIAL_NUMBER_LENGTH = 6;

function formatModelName(model) {
    return model.subTier ?
        `${model.modelName} ${model.subTier}` :
        model.modelName;
}

function stringifyAndPadSerialNumber(serialNumber) {
    if (isNullOrUndefined(serialNumber)) {
        return serialNumber;
    }

    const stringifiedSerialNumber = serialNumber.toString();

    return stringifiedSerialNumber.length < SERIAL_NUMBER_LENGTH ?
        stringifiedSerialNumber.padStart(SERIAL_NUMBER_LENGTH, '0') :
        stringifiedSerialNumber;
}

function getSerialNumberDescription(model, translations) {
    const serialStart = stringifyAndPadSerialNumber(model.serialStart);
    const serialEnd = stringifyAndPadSerialNumber(model.serialEnd);

    if (serialStart && serialEnd) {
        return serialStart === serialEnd ?
            `${translations.SERIAL} ${serialStart}` :
            replaceTranslationNames(translations.ONLINK_SERIAL_RANGE, {
                '0': serialStart,
                '1': serialEnd
            });
    } else if (serialStart) {
        return replaceTranslationNames(translations.ONLINK_SERIAL_ABOVE, {
            '0': serialStart
        });
    } else if (serialEnd) {
        return replaceTranslationNames(translations.ONLINK_SERIAL_BELOW, {
            '0': serialEnd
        });
    }

    return '';
}

function getModelDescription(model, translations) {
    const modelDescriptionItems = [];

    if (model.description) {
        modelDescriptionItems.push(model.description);
    }

    const serialNumberDescription = getSerialNumberDescription(model, translations);

    if (serialNumberDescription) {
        modelDescriptionItems.push(`(${serialNumberDescription})`);
    }

    return modelDescriptionItems.join(', ');
}

export {
    formatModelName,
    getModelDescription
};
