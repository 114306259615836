// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MyJohnDeereDataTable from 'Ui/components/settings/myJohnDeere/myJohnDeere-data-table';
import {getMyJDUserId, getMyJDUserOrganizations} from 'Services/my-jd-user-service';
import {fetchEffectData} from 'Utils/react-utils';

function getColumns(translations) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky'
        },
        {
            Header: translations.ID,
            accessor: 'id'
        },
        {
            Header: translations.TYPE,
            accessor: 'type'
        }
    ];
}

function MyJohnDeereOrganizationsSettings(props) {
    const {
        hasMyJDSession,
        translations
    } = props;

    const [hasMyJdUser, setHasMyJdUser] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [organizations, setOrganizations] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const dataCalls = [getMyJDUserId()];

        if (hasMyJDSession) {
            dataCalls.push(getMyJDUserOrganizations());
        }

        const [myJDUser, organizationsData = []] = await Promise.all(dataCalls);

        if (isMounted()) {
            const formatted = organizationsData.map((org) => ({
                id: org.id,
                name: org.name,
                type: org.type
            }));

            setOrganizations(formatted);
            setHasMyJdUser(Boolean(myJDUser));
            setLoading(false);
        }
    }), []);

    return (
        <MyJohnDeereDataTable
            columns={getColumns(translations)}
            defaultSorted={[{
                desc: false,
                id: 'name'
            }]}
            hasMyJDSession={hasMyJDSession}
            hasMyJDUser={hasMyJdUser}
            loading={loading}
            rows={organizations}
            searchable={true}
            translations={translations}
        />
    );
}

MyJohnDeereOrganizationsSettings.propTypes = {
    hasMyJDSession: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        hasMyJDSession: state.account.hasMyJDSession,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(MyJohnDeereOrganizationsSettings);
