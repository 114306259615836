// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import Helmet from 'react-helmet';
import EntryItem from 'Ui/components/entry/entry-item';
import Equipment from 'OnEquip/equipment/equipment';
import EquipmentReport from 'Ui/pages/onequip/reports/equipment-report';
import Footer from 'Ui/components/footer/footer';
import Labor from 'OnLabor/labor';
import LayoutDropdown from 'Ui/components/secondary-navbar/layout-dropdown';
import MobileNavDashboardWrapper from 'Ui/components/secondary-navbar/mobile-nav-dashboard-wrapper';
import OnlinkMapWrapper from 'Ui/components/map/onlink-map-wrapper';
import Parts from 'Ui/pages/onequip/parts/parts';
import SecondaryNavBar from 'Ui/components/secondary-navbar/secondary-navbar';
import {TertiaryNavBarWrapper} from 'Ui/components/tertiary-navbar';
import WorkboardPresentationWrapper from 'Ui/pages/onlabor/workboards/presentation/presentation-wrapper';
import {AuthContextProvider} from 'Ui/context/auth-context';
import {getOnEquipNavLinks, getOnLaborNavLinks} from 'Ui/components/secondary-navbar/utils/dropdown-navbar-helper';
import {
    dashboardNavLinks,
    mapNavLinks,
    onEquipNavLinks,
    onLaborNavLinks
} from 'Ui/constants/secondary-navbar-constants';
import {
    ANALYZE_FLEET_TITLE,
    ANALYZE_OPERATIONS_TITLE,
    FLEET_MAINTENANCE_TITLE,
    HOME_TITLE,
    LABOR_TITLE,
    MAP_TITLE,
    ONEQUIP,
    ONLABOR,
    ONLINK_DASHBOARD,
    ONLINK_MAP,
    ONLINK_SETTINGS,
    PARTS_TITLE,
    SETTINGS_TITLE,
    WORKBOARD
} from 'Ui/constants/tab-header-constants';
import appAccess from 'Common/constants/app-access';
import {
    ONLINK_NAVIGATION_HEADER,
    ONLINK_NAVIGATION_REDESIGN,
    PARTS_HISTORY_PAGE
} from 'Common/constants/feature-toggles';
import {
    ANALYZE_COURSE_OPERATIONS,
    DRILL_IN,
    DRILL_IN_CHART,
    EQUIPMENT_REPORT,
    FORETEES,
    HOME,
    MY_WORK,
    PLAYBOOKS,
    SETTINGS,
    TWC,
    WORKBOARD_PRESENTATION
} from 'Common/constants/routes';
import {BASE_ROUTES, getBaseRouteConfig} from 'Ui/constants/routes/base-route-config';
import {clsx} from 'clsx';
import {useDeepMemo} from 'Utils/react-utils';
import {
    VIEW_EQUIPMENT_LIST_API,
    VIEW_EQUIPMENT_DETAILS,
    VIEW_PARTS,
    VIEW_FINANCIAL_DATA
} from 'Common/constants/business-activities';

const dashboardSecondaryNav = {
    appAccess: appAccess.ONGOLF,
    barComponent: LayoutDropdown,
    mobileNavComponent: MobileNavDashboardWrapper,
    navLinks: dashboardNavLinks
};

function getRoutes(featureToggles, translations, isMigrated) {
    const onLaborSecondaryNav = {
        navLinks: getOnLaborNavLinks(onLaborNavLinks, featureToggles, translations)
    };
    const onEquipNavElements = getOnEquipNavLinks(onEquipNavLinks, featureToggles, translations);
    const analyzeTabHeader = isMigrated ? translations[ANALYZE_OPERATIONS_TITLE] : translations[ANALYZE_FLEET_TITLE];

    return [
        {
            ...getBaseRouteConfig(ANALYZE_COURSE_OPERATIONS, isMigrated),
            tabHeader: translations[ANALYZE_OPERATIONS_TITLE]
        },
        {
            ...BASE_ROUTES[HOME],
            secondaryNavBar: dashboardSecondaryNav,
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[HOME_TITLE] : null
        },
        {
            ...BASE_ROUTES[SETTINGS],
            secondaryNavBar: dashboardSecondaryNav,
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[SETTINGS_TITLE] : ONLINK_SETTINGS
        },
        {
            ...BASE_ROUTES[DRILL_IN],
            secondaryNavBar: dashboardSecondaryNav,
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[HOME_TITLE] : null
        },
        {
            ...BASE_ROUTES[PLAYBOOKS],
            secondaryNavBar: dashboardSecondaryNav
        },
        {
            ...BASE_ROUTES[TWC],
            secondaryNavBar: dashboardSecondaryNav
        },
        {
            ...BASE_ROUTES[FORETEES],
            secondaryNavBar: dashboardSecondaryNav
        },
        {
            ...BASE_ROUTES[DRILL_IN_CHART],
            secondaryNavBar: dashboardSecondaryNav
        },
        {
            className: 'map-container',
            component: OnlinkMapWrapper,
            exact: true,
            hideFooter: true,
            path: '/map/:type',
            secondaryNavBar: {
                navLinks: mapNavLinks
            },
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[MAP_TITLE] : ONLINK_MAP
        },
        {
            appAccess: appAccess.ONEQUIP,
            component: EquipmentReport,
            path: EQUIPMENT_REPORT,
            secondaryNavBar: {
                navLinks: onEquipNavElements
            },
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? analyzeTabHeader : ONEQUIP,
            myJdPermissions: [VIEW_EQUIPMENT_DETAILS, VIEW_FINANCIAL_DATA]
        },
        {
            appAccess: appAccess.ONEQUIP,
            className: isMigrated ? 'migrated-parts-container' : 'parts-container',
            component: Parts,
            path: '/equipment/parts/:type',
            secondaryNavBar: {
                navLinks: onEquipNavElements
            },
            tabHeader: isMigrated ? translations[PARTS_TITLE] : translations[FLEET_MAINTENANCE_TITLE],
            paramsAuth: {
                type: {
                    history: {
                        migratedMembershipAccessOnly: true,
                        myJdPermissions: [VIEW_PARTS],
                        toggle: PARTS_HISTORY_PAGE
                    },
                    inventory: {
                        myJdPermissions: [VIEW_PARTS]
                    },
                    orders: {
                        myJdPermissions: [VIEW_PARTS]
                    },
                    recommended: {
                        myJdPermissions: [VIEW_PARTS]
                    }
                }
            }
        },
        {
            appAccess: appAccess.ONEQUIP,
            className: 'onequip-container',
            component: Equipment,
            path: '/equipment/:type',
            secondaryNavBar: {
                navLinks: onEquipNavElements
            },
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[FLEET_MAINTENANCE_TITLE] : ONEQUIP,
            paramsAuth: {
                type: {
                    fleet: {
                        myJdPermissions: [VIEW_EQUIPMENT_LIST_API]
                    },
                    hoc: {
                        myJdPermissions: [VIEW_EQUIPMENT_LIST_API]
                    },
                    suggestedMaintenance: {
                        myJdPermissions: [VIEW_EQUIPMENT_LIST_API]
                    },
                    shop: {
                        myJdPermissions: [VIEW_EQUIPMENT_DETAILS]
                    },
                    assignments: {
                        myJdPermissions: [VIEW_EQUIPMENT_DETAILS]
                    },
                    serviceHistory: {
                        myJdPermissions: [VIEW_EQUIPMENT_LIST_API]
                    }
                }
            }
        },
        {
            ...BASE_ROUTES[MY_WORK],
            className: 'my-work-container',
            exact: true,
            secondaryNavBar: onLaborSecondaryNav,
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[LABOR_TITLE] : ONLABOR
        },
        {
            appAccess: appAccess.ONLABOR,
            component: WorkboardPresentationWrapper,
            exact: true,
            hideFooter: true,
            isStandalonePage: true,
            path: WORKBOARD_PRESENTATION,
            tabHeader: isMigrated ? WORKBOARD : ONLABOR
        },
        {
            appAccess: appAccess.ONLABOR,
            className: 'onlabor-container',
            component: Labor,
            path: '/labor',
            secondaryNavBar: onLaborSecondaryNav,
            tabHeader: featureToggles[ONLINK_NAVIGATION_REDESIGN] ? translations[LABOR_TITLE] : ONLABOR
        }
    ];
}

function createRoutes({
    featureToggles,
    footer,
    hasSubscription,
    loadPersistentHeader,
    translations,
    isMigrated
}) {
    const routes = getRoutes(featureToggles, translations, isMigrated);

    return routes.map(({
        appAccess,
        className,
        component,
        hideFooter,
        isStandalonePage,
        myJdPermissions,
        paramsAuth,
        permission,
        secondaryNavBar,
        toggle,
        ...routeProps
    }) => (
        <Route
            {...routeProps}
            key={routeProps.path}
        >
            <Helmet>
                <title>{routeProps.tabHeader || ONLINK_DASHBOARD}</title>
            </Helmet>
            {
                !isStandalonePage && !featureToggles[ONLINK_NAVIGATION_HEADER] &&
                <SecondaryNavBar {...secondaryNavBar}/>
            }
            <EntryItem
                className={className}
                component={component}
                featureToggles={featureToggles}
                footer={!hideFooter && footer}
                hasSubscription={hasSubscription}
                loadPersistentHeader={loadPersistentHeader}
                paramsAuth={paramsAuth}
                requiredAuth={{
                    appAccess,
                    myJdPermissions,
                    permission,
                    toggle
                }}
                translations={translations}
            />
        </Route>
    ));
}

function Entry(props) {
    const {
        featureToggles,
        hasSubscription,
        isMigrated,
        isStandalonePage,
        loadPersistentHeader,
        translations
    } = props;

    const mainContentClassName = React.useMemo(() => clsx('main-content', {
        'ph-redesign-content': featureToggles[ONLINK_NAVIGATION_HEADER] && !loadPersistentHeader,
        'ph-integration-content': loadPersistentHeader,
        'jd-green': isMigrated
    }), [featureToggles[ONLINK_NAVIGATION_HEADER], loadPersistentHeader]);
    const footer = useDeepMemo(() => <Footer translations={translations}/>, [translations]);

    return (
        <AuthContextProvider>
            <div className={isStandalonePage ? 'standalone-content' : mainContentClassName}>
                {
                    !isStandalonePage && featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
                    <TertiaryNavBarWrapper
                        hasSubscription={hasSubscription}
                        isMigrated={isMigrated}
                    />
                }
                <Switch>
                    <Redirect
                        exact={true}
                        from='/'
                        to='/home'
                    />
                    <Redirect
                        exact={true}
                        from='/map'
                        to='/map/equipment'
                    />
                    <Redirect
                        exact={true}
                        from='/equipment'
                        to='/equipment/fleet'
                    />
                    <Redirect
                        exact={true}
                        from='/equipment/parts'
                        to='/equipment/parts/inventory'
                    />
                    <Redirect
                        exact={true}
                        from='/labor'
                        to='/labor/workboard'
                    />
                    {
                        createRoutes({
                            featureToggles,
                            footer,
                            hasSubscription,
                            loadPersistentHeader,
                            translations,
                            isMigrated
                        })
                    }
                </Switch>
            </div>
        </AuthContextProvider>
    );
}

Entry.propTypes = {
    featureToggles: PropTypes.featureToggles,
    hasSubscription: PropTypes.bool,
    isMigrated: PropTypes.bool,
    isStandalonePage: PropTypes.bool,
    loadPersistentHeader: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated
    };
}

export default connect(mapStateToProps)(Entry);
