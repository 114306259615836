// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MediaQuery from 'Ui/components/higher-order-components/media-query';
import GridLayoutWithWidth from 'Ui/components/common/grid-layout-with-width';
import JobItem from 'Ui/features/workboard-wizard/assignments/job-item';
import {
    changeJobLayout,
    getJobLayout,
    sortBySeq
} from 'Ui/features/workboard-wizard/assignments/utils/job-grid-layouts';
import {getOvertimeThresholds} from 'OnLabor/workboard/utils/overtime-status';
import {createRoleNameMap, getFormattedOperators} from 'OnLabor/workboard/utils/operator-utils';
import {useDeepMemo, useLazyRef} from 'Utils/react-utils';
import {onDragStart, onDragStop} from 'Utils/scroll-utils';
import {WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {ASSIGNMENT_MOBILE_MEDIA_QUERY} from 'Ui/features/workboard-wizard/constants/assignment-media-query';

const GRID_MARGIN = 10;

function JobAssignmentsForm(props) {
    const {
        collapseStatus,
        equipmentByType,
        featureToggles,
        flattenedJobs,
        fleetEquipment,
        isAssignmentMobile,
        isMultiline,
        membership,
        newestWorkItemIdRef,
        noteHeight,
        operators,
        screenTouchListener,
        screenTouchTimerRef,
        setCollapseStatus,
        setOperators,
        setValid,
        setValues,
        templatesById,
        translations,
        userRoles,
        readOnly
    } = props;

    const orderRef = useLazyRef(() => sortBySeq(flattenedJobs, 'jobTemplateId'));

    const {
        overtimeReachedThreshold,
        overtimeWarningThreshold
    } = getOvertimeThresholds(
        membership.properties.workboard_weekly_work_hours,
        membership.properties.workboard_employee_daily_hours_limit
    );

    const equipmentInUseMap = useLazyRef(() => flattenedJobs.reduce((equipmentJobMap, job) => {
        return job.workItems.reduce((equipmentWorkItemMap, workItem) => {
            workItem.equipment?.forEach((equipment) => {
                if (equipment.equipmentId) {
                    equipmentWorkItemMap.set(equipment.equipmentId, workItem.workItemId);
                }
            });

            return equipmentWorkItemMap;
        }, equipmentJobMap);
    }, new Map()));

    const roleNameMap = useDeepMemo(() => createRoleNameMap(userRoles), [userRoles]);

    const operatorItems = {
        activeOperatorItems: useDeepMemo(() => getFormattedOperators({
            operators: operators.operatorsMasterList,
            overtimeReachedThreshold,
            overtimeWarningThreshold,
            roleNameMap,
            translations
        }), [operators.operatorsMasterList, overtimeReachedThreshold, overtimeWarningThreshold, roleNameMap]),
        inactiveOperatorItems: useDeepMemo(() => getFormattedOperators({
            operators: operators.allOperators,
            overtimeReachedThreshold,
            overtimeWarningThreshold,
            roleNameMap,
            translations
        }), [operators.allOperators, overtimeReachedThreshold, overtimeWarningThreshold, roleNameMap])
    };

    if (flattenedJobs.length === 0) {
        return null;
    }

    return (
        <GridLayoutWithWidth
            cols={1}
            draggableHandle='.icon-job-orderable'
            isDraggable={!readOnly}
            isResizable={false}
            layout={getJobLayout(flattenedJobs, collapseStatus, isAssignmentMobile, featureToggles[WORKBOARD_REFACTOR], readOnly, noteHeight)}
            margin={[0, GRID_MARGIN]}
            onDragStart={(layout, oldItem) => onDragStart(oldItem.i, screenTouchListener)}
            onDragStop={(layout, oldItem) => onDragStop(oldItem.i, screenTouchListener, screenTouchTimerRef)}
            onLayoutChange={(currentLayout) => changeJobLayout(currentLayout, orderRef, setValues, templatesById)}
            rowHeight={115}
        >
            {
                flattenedJobs.map((job) => (
                    <div
                        className='assignment-container end-margin'
                        key={`${job.jobId || ''} ${job.jobTemplateId}`}
                    >
                        <JobItem
                            collapseStatus={collapseStatus}
                            equipmentByType={equipmentByType}
                            equipmentInUseMap={equipmentInUseMap}
                            featureToggles={featureToggles}
                            fleetEquipment={fleetEquipment}
                            isAssignmentMobile={isAssignmentMobile}
                            isMultiline={isMultiline}
                            job={job}
                            jobIndex={job.jobIndex}
                            membership={membership}
                            newestWorkItemIdRef={newestWorkItemIdRef}
                            noteHeight={noteHeight}
                            operatorItems={operatorItems}
                            operators={operators}
                            readOnly={readOnly}
                            screenTouchListener={screenTouchListener}
                            screenTouchTimerRef={screenTouchTimerRef}
                            setCollapseStatus={setCollapseStatus}
                            setOperators={setOperators}
                            setValid={setValid}
                            setValues={setValues}
                            translations={translations}
                        />
                    </div>
                ))
            }
        </GridLayoutWithWidth>
    );
}

JobAssignmentsForm.propTypes = {
    collapseStatus: PropTypes.object,
    equipmentByType: PropTypes.instanceOf(Map),
    featureToggles: PropTypes.featureToggles,
    flattenedJobs: PropTypes.arrayOf(PropTypes.object),
    fleetEquipment: PropTypes.arrayOf(PropTypes.equipment),
    isAssignmentMobile: PropTypes.bool,
    isMultiline: PropTypes.bool,
    membership: PropTypes.membership,
    newestWorkItemIdRef: PropTypes.reference,
    noteHeight: PropTypes.number,
    operators: PropTypes.object,
    readOnly: PropTypes.bool,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    setCollapseStatus: PropTypes.func,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    templatesById: PropTypes.instanceOf(Map),
    translations: PropTypes.translations,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        userRoles: state.role.userRoles
    };
}

export default connect(mapStateToProps)(MediaQuery(ASSIGNMENT_MOBILE_MEDIA_QUERY)(JobAssignmentsForm));
