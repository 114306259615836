// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Accordion} from '@deere/isg.component-library/lib/Accordion/Accordion';
import CutDirectionInput from 'OnLabor/workboard/common/cut-direction-input';
import {MOWING_PATTERNS} from 'OnLabor/workboard/constants/mowing-patterns';
import {formatTime} from 'Utils/time-utils';
import {cloneDeep} from 'lodash';

function getDirections(direction, direction2) {
    if (direction && direction2) {
        return `${direction} & ${direction2}`;
    }

    return direction || direction2 || '';
}

function createCutHistoryList(equipmentAreaId, cutHistory, translations) {
    if (cutHistory && cutHistory[equipmentAreaId]?.length > 0) {
        return cutHistory[equipmentAreaId].map((entry, i) => {
            const {
                cleanupPass,
                cuttingStyle,
                dateSelect,
                direction,
                direction2
            } = entry;

            const directions = getDirections(direction, direction2);
            const formattedCuttingStyle = cuttingStyle && !['Single', 'Double'].includes(cuttingStyle) ? ` ${cuttingStyle}` : '';
            const formattedCleanupPass = cleanupPass ? ` ${cleanupPass}` : '';
            const separator = formattedCuttingStyle || formattedCleanupPass ? ',' : '';

            return (
                <div
                    className='cut-history-entry'
                    key={`${equipmentAreaId}${i}`}
                >
                    <div>{formatTime(dateSelect, 'MM/DD')}</div>
                    <div>{`${directions}${separator}${formattedCuttingStyle}${formattedCleanupPass}`}</div>
                </div>
            );
        });
    }

    return (
        <div className='cut-history-entry'>
            {translations.ONLINK_NO_CUT_HISTORY ?? translations.ONLINK_NO_HISTORY_30_DAYS}
        </div>
    );
}

function CutsAndDirectionsForm(props) {
    const {
        setValues,
        translations,
        cutHistory,
        values,
        readOnly
    } = props;

    const [activeCutHistory, setActiveCutHistory] = React.useState(null);

    function handleAccordionChange(cutHistory, isExpanded) {
        setActiveCutHistory(isExpanded ? cutHistory : null);
    }

    function checkIfClickedAway(label) {
        if (label !== activeCutHistory && activeCutHistory) {
            setActiveCutHistory(null);
        }
    }

    return (
        <div className='add-workboard-form cuts-and-directions-form'>
            {
                MOWING_PATTERNS.map((mowingPattern, index) => {
                    const mowingPatternValue = values.mowingPatterns[index];
                    const cutHistoryList = createCutHistoryList(mowingPattern.equipmentAreaId, cutHistory, translations);

                    return (
                        <div
                            className={'cuts-box'}
                            key={`${mowingPattern.label}${index}`}
                        >
                            <div onClick={() => checkIfClickedAway(mowingPattern.label)}>
                                <CutDirectionInput
                                    key={mowingPattern.equipmentAreaId}
                                    label={translations[mowingPattern.label]}
                                    mowingPattern={mowingPatternValue}
                                    onChange={(name, value) => {
                                        setValues((prevValues) => {
                                            const clonedValues = cloneDeep(prevValues);

                                            clonedValues.mowingPatterns[index][name] = value;

                                            return clonedValues;
                                        });
                                    }}
                                    readOnly={readOnly}
                                    translations={translations}
                                />
                            </div>
                            <Accordion
                                className='cut-history-accordion'
                                classes={{
                                    root: 'accordion-root'
                                }}
                                elevation={0}
                                expanded={activeCutHistory === mowingPattern.label}
                                headerTitle={translations.ONLINK_CUT_HISTORY}
                                onChange={(event, isExpanded) => handleAccordionChange(mowingPattern.label, isExpanded)}
                            >
                                <div className='accordion-child'>
                                    {cutHistoryList}
                                </div>
                            </Accordion>
                        </div>
                    );
                })
            }
        </div>
    );
}

CutsAndDirectionsForm.propTypes = {
    cutHistory: PropTypes.object,
    readOnly: PropTypes.bool,
    setCutHistory: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default CutsAndDirectionsForm;
