// Unpublished Work © 2024 Deere & Company.

/* eslint-disable max-statements */
import {isEmptyString} from 'Common/utils/validation-utils';
import {jsPDF} from 'jspdf';
import moment from 'moment';
import {
    getServiceGroup,
    getStatusTitle,
    getUserData
} from 'Ui/features/onequip/equipment/service-form/service-form-helper';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';

const PAGE_MARGIN = 10;

const TITLE_FONT_SIZE = 16;
const FONT_SIZE = 10;
const FONT_HEIGHT = 3;

const EMPTY_FIELD = {
    label: '',
    value: ''
};
const COLUMN_SPACING = 8;

const CHECK_BOX_WIDTH = 6;
const CHECK_BOX_HEIGHT = 6;

/* eslint-disable-next-line no-magic-numbers */
const CHECKMARK_VECTORS = [[0.5, 1], [2, -2.5]];

const NOTE_RECT_MARGIN = 2;

const FOUR = 4;
const SIX = 6;
const THREE = 3;

function getWhole(pdf) {
    return pdf.internal.pageSize.getWidth() - PAGE_MARGIN * 2;
}

function getHalf(pdf) {
    return getWhole(pdf) / 2;
}

function getThird(pdf) {
    return getWhole(pdf) / THREE;
}

function getFourth(pdf) {
    return getWhole(pdf) / FOUR;
}

function getSixth(pdf) {
    return getWhole(pdf) / SIX;
}

function setFontBold(pdf) {
    const {fontName} = pdf.getFont();

    pdf.setFont(fontName, 'bold');
}

function setFontNormal(pdf) {
    const {fontName} = pdf.getFont();

    pdf.setFont(fontName, 'normal');
}

function splitTrimFilter(pdf, text, width) {
    const trimedText = text?.trim();

    return isEmptyString(trimedText) ? [''] : pdf.splitTextToSize(trimedText, width).map((s) => s.trim()).filter((s) => !isEmptyString(s));
}

function calculateNewPage(pdf, currentPositionY, heightRequired) {
    const pageHeight = pdf.internal.pageSize.getHeight();
    const shouldAddNewPage = currentPositionY + heightRequired > pageHeight - PAGE_MARGIN;

    if (shouldAddNewPage) {
        pdf.addPage();
        return PAGE_MARGIN;
    }

    return currentPositionY;
}

function renderDataFields(pdf, fields, currentPosition) {
    pdf.setFontSize(FONT_SIZE);

    const labelWidth = getSixth(pdf) - COLUMN_SPACING;
    const valueWidth = getThird(pdf) - COLUMN_SPACING;

    const leftLabelX = PAGE_MARGIN;
    const rightLabelX = PAGE_MARGIN + getHalf(pdf);

    const leftValueX = PAGE_MARGIN + getSixth(pdf);
    const rightValueX = PAGE_MARGIN + getSixth(pdf) * FOUR;

    const nFields = fields.length;
    const nRows = Math.ceil(nFields / 2);

    for (let i = 0; i < nRows; i++) {
        const {
            label: leftLabel,
            value: leftValue
        } = fields[i * 2];
        const {
            label: rightLabel,
            value: rightValue
        } = fields[i * 2 + 1];

        const leftLabelText = splitTrimFilter(pdf, leftLabel, labelWidth);
        const rightLabelText = splitTrimFilter(pdf, rightLabel, labelWidth);

        const leftValueText = splitTrimFilter(pdf, leftValue, valueWidth);
        const rightValueText = splitTrimFilter(pdf, rightValue, valueWidth);

        const maxLines = Math.max(leftLabelText.length, rightLabelText.length, leftValueText.length, rightValueText.length);
        const heightRequired = (maxLines + 2) * (FONT_HEIGHT + pdf.getLineHeightFactor());

        currentPosition.y = calculateNewPage(pdf, currentPosition.y, heightRequired);

        setFontBold(pdf);
        pdf.text(leftLabelText, leftLabelX, currentPosition.y, {
            maxWidth: labelWidth
        });
        pdf.text(rightLabelText, rightLabelX, currentPosition.y, {
            maxWidth: labelWidth
        });

        setFontNormal(pdf);

        pdf.text(leftValueText, leftValueX, currentPosition.y, {
            maxWidth: valueWidth
        });
        pdf.text(rightValueText, rightValueX, currentPosition.y, {
            maxWidth: valueWidth
        });

        currentPosition.y += heightRequired;
    }
}

function renderAssignedTo(pdf, assignedUsers, currentPosition) {
    const nameWidth = getHalf(pdf);
    const assignedToX = PAGE_MARGIN + getHalf(pdf);

    for (const {
        firstName, lastName
    } of assignedUsers) {
        const nameText = splitTrimFilter(pdf, `${firstName} ${lastName}`, nameWidth);
        const heightRequired = (nameText.length + 1) * (FONT_HEIGHT + pdf.getLineHeightFactor());

        currentPosition.y = calculateNewPage(pdf, currentPosition.y, heightRequired);
        pdf.text(nameText, assignedToX, currentPosition.y, {
            maxWidth: nameWidth
        });
        currentPosition.y += heightRequired;
    }
}

function drawCheckMark(pdf, x, y) {
    pdf.lines(CHECKMARK_VECTORS, x + 2, y + THREE);
}

function renderServiceTasks(pdf, serviceTasks, currentPosition) {
    setFontNormal(pdf);

    const taskWidth = getHalf(pdf) - 2 - CHECK_BOX_WIDTH - COLUMN_SPACING;

    for (const {
        serviceTask, isUsed
    } of serviceTasks) {
        const taskX = PAGE_MARGIN;
        const checkboxX = taskX + taskWidth;

        const serviceTaskText = splitTrimFilter(pdf, serviceTask, taskWidth);
        const heightRequired = (serviceTaskText.length + 1) * (FONT_HEIGHT + pdf.getLineHeightFactor());

        currentPosition.y = calculateNewPage(pdf, currentPosition.y, heightRequired);
        pdf.text(serviceTaskText, taskX, currentPosition.y, {
            maxWidth: taskWidth
        });
        const checkboxY = currentPosition.y - CHECK_BOX_HEIGHT + 2;

        pdf.rect(checkboxX, checkboxY, CHECK_BOX_WIDTH, CHECK_BOX_HEIGHT);
        if (isUsed) {
            drawCheckMark(pdf, checkboxX, checkboxY);
        }
        currentPosition.y += heightRequired;
    }
}

function renderTitle(pdf, title, currentPosition) {
    pdf.setFontSize(TITLE_FONT_SIZE);
    setFontBold(pdf);
    pdf.text(title, currentPosition.x, currentPosition.y);

    currentPosition.y += pdf.getLineHeight();
}

function getServiceTasks(serviceTaskOptions, serviceTasksCheckedIds, serviceTasksSelectedIds) {
    const serviceTaskIdToTitle = new Map(serviceTaskOptions.map((serviceTask) => {
        return [
            serviceTask.serviceTaskTypeId,
            serviceTask.title || serviceTask.serviceTask
        ];
    }));

    const checkedSet = new Set(serviceTasksCheckedIds);

    return serviceTasksSelectedIds.map((id) => ({
        serviceTask: serviceTaskIdToTitle.get(id),
        isUsed: checkedSet.has(id)
    }));
}

function renderServiceTasksAssignedTo(pdf, translations, currentPosition, serviceTasks, assignedUsers) {
    const serviceTasksX = PAGE_MARGIN;
    const assignedToX = PAGE_MARGIN + getHalf(pdf);

    const serviceTasksAssignedToHeightRequired = FONT_HEIGHT + pdf.getLineHeight();

    currentPosition.y = calculateNewPage(pdf, currentPosition.y, serviceTasksAssignedToHeightRequired);

    const serviceTasksAssignedToY = currentPosition.y + pdf.getLineHeight() / 2;

    setFontBold(pdf);
    pdf.text(translations.ONLINK_SERVICE_TASKS, serviceTasksX, serviceTasksAssignedToY);
    pdf.text(translations.ONLINK_ASSIGNED_TO, assignedToX, serviceTasksAssignedToY);

    const lineY = serviceTasksAssignedToY + 1;

    pdf.line(currentPosition.x, lineY, currentPosition.x + getHalf(pdf) - COLUMN_SPACING, lineY);
    pdf.line(currentPosition.x + getHalf(pdf), lineY, currentPosition.x + getWhole(pdf), lineY);

    currentPosition.y += serviceTasksAssignedToHeightRequired;

    const serviceTasksAssignedToListStartY = currentPosition.y;
    const serviceTasksAssignedToStartPage = pdf.internal.getCurrentPageInfo().pageNumber;

    renderServiceTasks(pdf, serviceTasks, currentPosition, getHalf(pdf));

    const serviceTasksEndPage = pdf.internal.getCurrentPageInfo().pageNumber;
    const serviceTasksEndHeight = currentPosition.y;

    pdf.setPage(serviceTasksAssignedToStartPage);
    currentPosition.y = serviceTasksAssignedToListStartY;

    renderAssignedTo(pdf, assignedUsers, currentPosition, getHalf(pdf));

    const assignedToEndPage = pdf.internal.getCurrentPageInfo().pageNumber;
    const assignedToEndHeight = currentPosition.y;

    if (assignedToEndPage < serviceTasksEndPage) {
        currentPosition.y = serviceTasksEndHeight;
        pdf.setPage(serviceTasksEndPage);
    } else if (assignedToEndPage === serviceTasksEndPage) {
        currentPosition.y = Math.max(assignedToEndHeight, serviceTasksEndHeight);
    }
}

function renderPartsList(pdf, partsList, currentPosition, partX, descriptionX, quantityX) {
    setFontNormal(pdf);

    const nameWidth = getFourth(pdf) - COLUMN_SPACING;
    const descriptionWidth = getHalf(pdf) - COLUMN_SPACING;
    const quantityWidth = getFourth(pdf);

    for (const {
        name,
        subtitle: description,
        quantity
    } of partsList) {
        const nameText = splitTrimFilter(pdf, name, nameWidth);
        const descriptionText = splitTrimFilter(pdf, description, descriptionWidth);
        const quantityText = splitTrimFilter(pdf, `${quantity ?? 0}`, quantityWidth);

        const maxLines = Math.max(nameText.length, descriptionText.length, quantityText.length);
        const heightRequired = (maxLines + 1) * (FONT_HEIGHT + pdf.getLineHeightFactor());

        currentPosition.y = calculateNewPage(pdf, currentPosition.y, heightRequired);

        pdf.text(nameText, partX, currentPosition.y, {
            maxWidth: nameWidth
        });
        pdf.text(descriptionText, descriptionX, currentPosition.y, {
            maxWidth: descriptionWidth
        });
        pdf.text(quantityText, quantityX, currentPosition.y, {
            maxWidth: quantityWidth
        });

        currentPosition.y += heightRequired;
    }
}

function renderPartsDescriptionQuantity(pdf, translations, currentPosition, partsList) {
    setFontBold(pdf);
    const partsX = PAGE_MARGIN;
    const descriptionX = PAGE_MARGIN + getFourth(pdf);
    const quantityX = PAGE_MARGIN + getHalf(pdf) + getFourth(pdf);

    const partsDescriptionQuantityHeightRequired = FONT_HEIGHT + pdf.getLineHeight();

    currentPosition.y = calculateNewPage(pdf, currentPosition.y, partsDescriptionQuantityHeightRequired);

    const partsDescriptionQuantityY = currentPosition.y + pdf.getLineHeight() / 2;
    const partsDescriptionQuantityLineY = partsDescriptionQuantityY + 1;

    pdf.text(translations.PARTS, partsX, partsDescriptionQuantityY);
    pdf.text(translations.DESCRIPTION, descriptionX, partsDescriptionQuantityY);
    pdf.text(translations['Observation.QUANTITY'], quantityX, partsDescriptionQuantityY);
    pdf.line(PAGE_MARGIN, partsDescriptionQuantityLineY, PAGE_MARGIN + getWhole(pdf), partsDescriptionQuantityLineY);

    currentPosition.y += partsDescriptionQuantityHeightRequired;

    renderPartsList(pdf, partsList, currentPosition, partsX, descriptionX, quantityX);
}

function renderNotes(pdf, notesTranslation, notes, currentPosition) {
    const topMargin = pdf.getLineHeight() / 2;
    const notesTitleX = PAGE_MARGIN;

    const notesX = PAGE_MARGIN + NOTE_RECT_MARGIN;
    const notesWidth = getWhole(pdf) - NOTE_RECT_MARGIN * 2;
    const noteTexts = notes.map(({note}) => splitTrimFilter(pdf, note, notesWidth));
    const noteHeightRequired = noteTexts.reduce((sum, noteText) => sum + (noteText.length + 1) * (FONT_HEIGHT + pdf.getLineHeightFactor()), 0) + NOTE_RECT_MARGIN * 2 + topMargin;

    currentPosition.y = calculateNewPage(pdf, currentPosition.y, noteHeightRequired);

    const noteTitleY = currentPosition.y + topMargin;

    pdf.text(`${notesTranslation}:`, notesTitleX, noteTitleY);

    currentPosition.y += topMargin + FONT_HEIGHT + 1;
    pdf.rect(PAGE_MARGIN, currentPosition.y - FONT_HEIGHT, getWhole(pdf), noteHeightRequired);

    currentPosition.y += NOTE_RECT_MARGIN;
    for (const noteText of noteTexts) {
        pdf.text(noteText, notesX, currentPosition.y, {
            maxWidth: notesWidth
        });
        currentPosition.y += (noteText.length + 1) * (FONT_HEIGHT + pdf.getLineHeightFactor());
    }
}

/*
    The rendering function work by predermining the X coordinate of pdf elements like text, lines, and rectangles.
    Then it calculates the heightRequired for the element to be rendered and adds a page if needed.
    Then it renders the element and updates the currentPositionY as necessary.
    In general, pre-compute x and y coordinates, calculate heightRequired, add page if necessary, render element, update currentPositionY.
*/
export function openServiceFormPdf(values, allData, currentEquipment, translations) {
    const pdf = new jsPDF();

    pdf.setProperties({
        title: translations.ONLINK_SERVICE_TICKET
    });

    const currentPosition = {
        x: PAGE_MARGIN,
        y: PAGE_MARGIN
    };

    const languagePreference = getLanguagePreference();

    renderTitle(pdf, translations.ONLINK_SERVICE_TICKET, currentPosition);

    const {
        dueAtDate,
        serviceDate,
        atHours,
        totalHours,
        status,
        serviceTypeName,
        serviceGroup,
        estDuration,
        actualDuration,
        serviceTasksCheckedIds,
        serviceTasksSelectedIds,
        partsList,
        notes,
        serviceTasks
    } = values;

    const fields = [
        {
            label: translations.IWP_EQUIPMENT_LABEL, // why is there so much space you ask? well, to prevent serial number from ever being on the same line as equipment name!
            value: `${currentEquipment.equipmentName ?? ''}                                                                                   ${currentEquipment.serialNumber ?? ''}`
        },
        EMPTY_FIELD,
        {
            label: translations.ONLINK_DUE_AT_DATE,
            value: dueAtDate ? moment(dueAtDate).locale(languagePreference)?.format('L') : ''
        },
        {
            label: translations.ONLINK_SERVICE_DATE,
            value: serviceDate ? moment(serviceDate).locale(languagePreference)?.format('L') : ''
        },
        {
            label: translations.ONLINK_DUE_AT_HOURS,
            value: `${atHours ?? ''}`
        },
        {
            label: translations.ONLINK_ACTUAL_ENGINE_HOURS,
            value: `${totalHours ?? ''}`
        },
        {
            label: translations.STATUS,
            value: getStatusTitle(status, translations)
        },
        EMPTY_FIELD,
        {
            label: translations.ONLINK_SERVICE_TYPE,
            value: serviceTypeName
        },
        {
            label: translations.ONLINK_SERVICE_GROUP,
            value: getServiceGroup(serviceGroup, translations)
        },
        {
            label: translations.ONLINK_ESTIMATED_DURATION_MINUTES,
            value: !isEmptyString(estDuration) ? `${estDuration}` : ''
        },
        {
            label: translations.ONLINK_ACTUAL_DURATION_MINUTES,
            value: !isEmptyString(actualDuration) ? `${actualDuration}` : ''
        }
    ];

    renderDataFields(pdf, fields, currentPosition);

    renderServiceTasksAssignedTo(
        pdf,
        translations,
        currentPosition,
        getServiceTasks([...allData.serviceTasksOptions, ...serviceTasks], serviceTasksCheckedIds, serviceTasksSelectedIds),
        getUserData(values, allData.serviceAssignableUsers)
    );

    renderPartsDescriptionQuantity(pdf, translations, currentPosition, partsList);

    renderNotes(pdf, translations.NOTES, notes, currentPosition);

    pdf.autoPrint({
        variant: 'non-conform'
    });
    window.open(pdf.output('bloburl'), '_blank');
}
