// Unpublished Work © 2023-2024 Deere & Company.

import {cloneDeep, sortBy} from 'lodash';

function sortByJobsWorkItemsByUserOrder(workItems, userOrder) {
    const sortedByJobsWorkItems = [];

    sortBy(userOrder, 'seq').forEach((userSeq) => {
        sortBy(userSeq.workItems, 'seq').forEach((workItemSeq) => {
            const workItem = workItems.find((workItem) => workItem.workItemId === workItemSeq.workItemId);

            if (!workItem) {
                return;
            }

            const existingJobIndex = sortedByJobsWorkItems.findIndex((workItemsArray) => workItemsArray[0].jobTemplateId === workItem.jobTemplateId);

            if (existingJobIndex < 0) {
                sortedByJobsWorkItems.push([workItem]);
            } else {
                sortedByJobsWorkItems[existingJobIndex].push(workItem);
            }
        });
    });

    return sortedByJobsWorkItems;
}

function sortByEmployeeWorkItemsBySeq(workItemsByUser, userOrder) {
    const sortedWorkItemsByUser = cloneDeep(workItemsByUser);

    sortedWorkItemsByUser.sort((userAWorkItems, userBWorkItems) => {
        const userASeq = userOrder?.find((user) => user.userId === userAWorkItems[0]?.appUserId);
        const userBSeq = userOrder?.find((user) => user.userId === userBWorkItems[0]?.appUserId);

        return userASeq?.seq - userBSeq?.seq;
    });

    sortedWorkItemsByUser.forEach((userWorkItems) => {
        const userSeq = userOrder?.find((user) => user.userId === userWorkItems[0]?.appUserId);

        if (userSeq) {
            userWorkItems.sort((userAWorkItems, userBWorkItems) => {
                const workItemASeq = userSeq.workItems.find((workItem) => workItem.workItemId === userAWorkItems.workItemId);
                const workItemBSeq = userSeq.workItems.find((workItem) => workItem.workItemId === userBWorkItems.workItemId);

                return workItemASeq?.seq - workItemBSeq?.seq;
            });
        }
    });

    return sortedWorkItemsByUser;
}

function sortWorkItemsByUserOrder(workItems, userOrder) {
    const sortedWorkItems = [];

    sortBy(userOrder, 'seq').forEach((userSeq) => {
        sortBy(userSeq.workItems, 'seq').forEach((workItemSeq) => {
            const workItem = workItems.find((workItem) => workItem.workItemId === workItemSeq.workItemId);

            if (workItem) {
                sortedWorkItems.push(workItem);
            }
        });
    });

    return sortedWorkItems;
}

export {
    sortByJobsWorkItemsByUserOrder,
    sortByEmployeeWorkItemsBySeq,
    sortWorkItemsByUserOrder
};
