// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import WidgetItem from 'OnLabor/workboard/widget/widget-item';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {sortBy} from 'lodash';

function getTitle(translations, workItems, workboardCreationFlow) {
    const unassignedTitle = {
        color: 'warning.main',
        text: translations.ONLINK_UNASSIGNED
    };

    const emptyTitle = {
        text: ''
    };

    switch (workItems.length) {
        case 0:
            return workboardCreationFlow === JOB ? unassignedTitle : emptyTitle;
        case 1: {
            if (workboardCreationFlow === JOB) {
                const jobTemplate = workItems[0].jobTemplate;

                return jobTemplate ? {
                    text: jobTemplate.title
                } : unassignedTitle;
            }

            const user = workItems[0].appUser;

            return user ? {
                text: `${user.firstName} ${user.lastName}`
            } : emptyTitle;
        }
        default:
            return workboardCreationFlow === JOB ? {
                text: `${workItems.length} ${translations.JOBS}`
            } : {
                text: `${workItems.length} ${translations.OPERATORS}`
            };
    }
}

function getOperatorAssignments(templates, sortedWorkItems, workboardCreationFlow, translations) {
    return templates.map((jobTemplate) => {
        const name = {
            text: jobTemplate.title || ''
        };

        const workItemsWithCurrentJobAssigned = sortedWorkItems.filter(({jobTemplateId}) => jobTemplateId === jobTemplate.jobTemplateId);

        return {
            jobTemplateId: jobTemplate.jobTemplateId,
            quantity: workItemsWithCurrentJobAssigned.length,
            operatorTitle: getTitle(translations, workItemsWithCurrentJobAssigned, workboardCreationFlow),
            name
        };
    });
}

function getJobAssignments(operatorsMasterList, sortedWorkItems, workboardCreationFlow, translations) {
    return operatorsMasterList.map((operator) => {
        const name = {
            text: `${operator.firstName} ${operator.lastName}`
        };

        const workItemsWithCurrentUserAssigned = sortedWorkItems.filter(({appUserId}) => appUserId === operator.appUserId);

        return {
            appUserId: operator.appUserId,
            quantity: workItemsWithCurrentUserAssigned.length,
            jobTitle: getTitle(translations, workItemsWithCurrentUserAssigned, workboardCreationFlow),
            name
        };
    });
}

function CurrentWorkboardItems(props) {
    const {
        operatorsMasterList,
        screenTouchListener,
        screenTouchTimerRef,
        sortedWorkItems,
        translations,
        templates,
        workboardCreationFlow,
        readOnly
    } = props;

    const sortedAssignmentsList = React.useMemo(() => {
        const assignments = workboardCreationFlow === JOB ?
            getJobAssignments(operatorsMasterList, sortedWorkItems, workboardCreationFlow, translations) :
            getOperatorAssignments(templates, sortedWorkItems, workboardCreationFlow, translations);

        return workboardCreationFlow === JOB ? assignments.sort((a, b) => {
            const countComparison = a.quantity - b.quantity;
            const oneItemIsUnassigned = (a.quantity === 0 || b.quantity === 0) && countComparison !== 0;

            return oneItemIsUnassigned ?
                countComparison :
                a.name.text.toLowerCase().localeCompare(b.name.text.toLowerCase());
        }) : sortBy(assignments, [(assignment) => assignment.name.text.toLowerCase()]);
    }, [operatorsMasterList, templates, sortedWorkItems]);

    return workboardCreationFlow === JOB ? sortedAssignmentsList.map((row) => (
        <WidgetItem
            draggable={!readOnly}
            id={row.appUserId}
            items={[row.name, row.jobTitle]}
            key={`${row.appUserId}${row.name.text}`}
            screenTouchListener={screenTouchListener}
            screenTouchTimerRef={screenTouchTimerRef}
        />
    )) : sortedAssignmentsList.map((row) => (
        <WidgetItem
            draggable={!readOnly}
            id={row.jobTemplateId}
            items={[row.name, row.operatorTitle]}
            key={`${row.jobTemplateId}${row.name.text}`}
            screenTouchListener={screenTouchListener}
            screenTouchTimerRef={screenTouchTimerRef}
        />
    ));
}

CurrentWorkboardItems.propTypes = {
    operatorsMasterList: PropTypes.arrayOf(PropTypes.user),
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    sortedWorkItems: PropTypes.arrayOf(PropTypes.object),
    templates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    workboardCreationFlow: PropTypes.string
};

export default CurrentWorkboardItems;
