// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import DropdownNavItem from 'Ui/components/main-navbar/persistent-header/navigation/dropdown-nav-item';
import NavBarItem from 'Ui/components/main-navbar/persistent-header/common/navbar-item';
import {useDeepMemo} from 'Utils/react-utils';

function NavBarLinks(props) {
    const {
        links: {navbarLinks = [] },
        translations
    } = props;

    const navbarItems = useDeepMemo(() => navbarLinks.map((navbarLink) => (
        <NavBarItem
            key={`${navbarLink.name} ${navbarLink.path}`}
            navItemGroupComponent={DropdownNavItem}
            navbarLink={navbarLink}
            translations={translations}
        />)), [navbarLinks, translations]);

    return (
        <Stack direction='row'>
            {navbarItems}
        </Stack>
    );
}

NavBarLinks.propTypes = {
    links: PropTypes.object,
    translations: PropTypes.translations
};

export default NavBarLinks;
