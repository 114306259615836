// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconEdit} from '@deere/icons';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';
import {COURSE, FACILITY} from 'Common/constants/membership-type';
import moment from 'moment';

const ICON_EDIT_STYLE = {
    style: {
        width: '24px',
        height: '24px'
    }
};

function HocEditIcon(props) {
    const {
        childLevels,
        hocData,
        isMigrated,
        membershipType: type,
        myJdPermissions,
        openDialog,
        translations,
        updateCourseMembership
    } = props;

    const hasPermissions = isAuthorized({
        myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
    }, {
        isMigrated,
        myJdPermissions
    });

    const isEditable = React.useMemo(() => hasPermissions && (
        type === COURSE || type === FACILITY && childLevels.some((x) => x.membershipType === COURSE)
    ), [childLevels, hasPermissions, type]);

    return (
        isEditable &&
        <OnLinkIconButton
            className='hoc-edit-icon'
            onClick={() => {
                openDialog({
                    initialDate: moment(),
                    membership: hocData,
                    translations,
                    updateCourseMembership
                });
            }}
        >
            <IconEdit iconEdit={ICON_EDIT_STYLE}/>
        </OnLinkIconButton>
    );
}

HocEditIcon.propTypes = {
    childLevels: PropTypes.arrayOf(PropTypes.object),
    hocData: PropTypes.object,
    isMigrated: PropTypes.bool,
    membershipType: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openDialog: PropTypes.func,
    translations: PropTypes.translations,
    updateCourseMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        childLevels: state.membership.childLevels,
        isMigrated: state.membership.isMigrated,
        membershipType: state.membership.membershipType,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openDialog(props) {
            dispatch(openReduxDialog(dialogTypes.HOC_EDIT_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HocEditIcon);
