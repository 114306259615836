// Unpublished Work © 2023-2024 Deere & Company.

import {
    CLIPPING_YIELD_TYPES,
    FIRMNESS_TYPES,
    NONE
} from 'Common/constants/preference-constants';
import {getUOMOverrideUnits} from 'Common/constants/uom-override-units';
import {GREEN_SPEED, CLIPPING_YIELD, FIRMNESS, WSPD} from 'Common/constants/data-group-constants';

const UOM_OVERRIDE_DATATYPES = [GREEN_SPEED, CLIPPING_YIELD, WSPD, FIRMNESS];

function convertToCorrectDataType(measurement, options) {
    const correctDataType = options.find((option) => option.measurements.includes(measurement));

    return correctDataType?.dataType;
}

function getDataType(valueKey, measurement) {
    switch (valueKey) {
        case CLIPPING_YIELD:
            return convertToCorrectDataType(measurement, CLIPPING_YIELD_TYPES);
        case FIRMNESS:
            return convertToCorrectDataType(measurement, FIRMNESS_TYPES);
        default:
            return valueKey;
    }
}

function getUomOverrides(unitOfMeasureOverrides) {
    const overrides = [];
    const UNITS = getUOMOverrideUnits({});

    Object.keys(unitOfMeasureOverrides).forEach((valueKey) => {
        const measurement = unitOfMeasureOverrides[valueKey];

        if (measurement && measurement !== NONE) {
            const dataType = getDataType(valueKey, measurement);

            overrides.push({
                dataType,
                unitOfMeasure: UNITS[measurement]?.unitOfMeasure || null,
                measurement
            });
        }
    });

    return overrides;
}

function sanitizeUomOverrides(unitOfMeasureOverrides, preferredDataTypes) {
    return UOM_OVERRIDE_DATATYPES.reduce((uomOverrides, dataType) => {
        const uom = unitOfMeasureOverrides?.find((measurement) => {
            return measurement.dataType.includes(dataType);
        });
        const preferredType = preferredDataTypes?.find((preferredDataType) => {
            return preferredDataType.dataType.includes(dataType);
        }) || {
            measurement: NONE
        };

        uomOverrides[dataType] = {
            ...uom,
            ...preferredType

        };

        return uomOverrides;
    }, {});
}

export {
    convertToCorrectDataType,
    getDataType,
    getUomOverrides,
    sanitizeUomOverrides
};
