// Unpublished Work © 2020-2024 Deere & Company.

import {NOT_AVAILABLE} from 'Ui/constants/common-constants';
import {formatLocalizedTime} from 'Utils/time-utils';
import {HOURS, getWorkboardProperties} from 'Common/utils/workboard-settings-utils';

function getProperties(translations) {
    const workBoardProperties = getWorkboardProperties(translations);
    const options = {
        hour: 'numeric',
        minute: 'numeric'
    };

    return {
        'workboard_default_start_time': {
            label: translations.ONLINK_WORKBOARD_START_TIME,
            options: [
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.TWELVE_AM, 0), options),
                    value: '0'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.ONE_AM, 0), options),
                    value: '1'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.TWO_AM, 0), options),
                    value: '2'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.THREE_AM, 0), options),
                    value: '3'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.FOUR_AM, 0), options),
                    value: '4'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.FIVE_AM, 0), options),
                    value: '5'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.SIX_AM, 0), options),
                    value: '6'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.SEVEN_AM, 0), options),
                    value: '7'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.EIGHT_AM, 0), options),
                    value: '8'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.NINE_AM, 0), options),
                    value: '9'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.TEN_AM, 0), options),
                    value: '10'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.ELEVEN_AM, 0), options),
                    value: '11'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.TWELVE_PM, 0), options),
                    value: '12'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.ONE_PM, 0), options),
                    value: '13'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.TWO_PM, 0), options),
                    value: '14'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.THREE_PM, 0), options),
                    value: '15'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.FOUR_PM, 0), options),
                    value: '16'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.FIVE_PM, 0), options),
                    value: '17'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.SIX_PM, 0), options),
                    value: '18'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.SEVEN_PM, 0), options),
                    value: '19'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.EIGHT_PM, 0), options),
                    value: '20'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.NINE_PM, 0), options),
                    value: '21'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.TEN_PM, 0), options),
                    value: '22'
                },
                {
                    label: formatLocalizedTime(new Date().setHours(HOURS.ELEVEN_PM, 0), options),
                    value: '23'
                }
            ],
            default: '6'
        },
        ...workBoardProperties
    };
}

function getDefault(property, translations) {
    return getProperties(translations)[property].default;
}

function getLabel(property, value, translations) {
    const options = getProperties(translations)[property].options;
    const option = options.find((option) => option.value === value);

    return option && option.label || NOT_AVAILABLE;
}

function mapLabels(property, translations) {
    const options = getProperties(translations)[property].options;

    return options.map((option) => ({
        id: option.value,
        title: option.label
    }));
}

export {
    getDefault,
    getLabel,
    mapLabels
};
