// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {closeDialog} from 'Store/actions/dialogs';
import {connect} from 'react-redux';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {useSave} from 'Ui/react-hooks/use-save';
import {createPartType, updatePartType} from 'Services/inventory-service';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {useDeepMemo} from 'Utils/react-utils';

function isDuplicatePartTypeTitle(partType, partTypeIdsByName, partTypeTitle) {
    const partTypeIdForName = partTypeIdsByName.get(partTypeTitle.toLowerCase());

    return partTypeIdForName && partTypeIdForName !== partType?.partTypeId;
}

function PartTypeDialog(props) {
    const {
        closePartTypeDialog,
        fetchPartTypes,
        invalidInputs,
        inventoryPartTypes,
        inventoryId,
        membershipId,
        partType,
        setValid,
        translations
    } = props;

    const [partTypeTitle, setPartTypeTitle] = React.useState(() => partType?.title || '');
    const title = partType ? translations.EDIT_TYPE : translations.ONLINK_ADD_ITEM;

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const payload = {
            inventoryId,
            membershipId,
            title: partTypeTitle
        };

        partType ?
            await updatePartType(inventoryId, partType.partTypeId, payload) :
            await createPartType(inventoryId, payload);

        await fetchPartTypes();

        closePartTypeDialog();
    }, {
        invalidInputs
    });

    const partTypeIdsByName = useDeepMemo(() => inventoryPartTypes.reduce((partTypesMap, partTypeInInventory) => {
        const {
            partTypeId,
            title
        } = partTypeInInventory;

        if (title?.trim()?.length) {
            partTypesMap.set(title.toLowerCase(), partTypeId);
        }

        return partTypesMap;
    }, new Map()), [inventoryPartTypes]);

    const duplicateTitle = isDuplicatePartTypeTitle(partType, partTypeIdsByName, partTypeTitle);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closePartTypeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={replaceTranslationNames(title, {
                '0': translations.ONLINK_PART_TYPE
            })}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    debounceTimeout={window.props.debounceTimeout}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        customError: duplicateTitle ?
                            translations.ONLINK_PART_TYPE_NAME_EXISTS : translations.REQUIRED_FIELD_TEXT
                    }}
                    invalidCustomError={partTypeTitle.trim().length === 0 ||
                        duplicateTitle
                    }
                    label={translations.ONLINK_PART_TYPE}
                    name='partTypeTitle'
                    onChange={(event) => setPartTypeTitle(event.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={partTypeTitle}
                />
            </form>
        </FormDialog>
    );
}

PartTypeDialog.propTypes = {
    closePartTypeDialog: PropTypes.func,
    fetchPartTypes: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    inventoryId: PropTypes.string,
    inventoryPartTypes: PropTypes.arrayOf(PropTypes.object),
    membershipId: PropTypes.string,
    partType: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closePartTypeDialog() {
            dispatch(closeDialog(dialogTypes.PART_TYPE_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(PartTypeDialog));
