// Unpublished Work © 2022-2024 Deere & Company.

const DETAILS_PATH = 'home : details';
const SETTINGS_PATH = `${DETAILS_PATH} : settings`;
const ONEQUIP_PATH = 'on-equip';

const ONEQUIP_MAP = {
    '/equipment/assignments': `${ONEQUIP_PATH} : assignments`,
    '/equipment/fleet': `${ONEQUIP_PATH} : fleet`,
    '/equipment/serviceHistory': `${ONEQUIP_PATH} : service history`,
    '/equipment/shop': `${ONEQUIP_PATH} : shop`,
    '/equipment/suggestedMaintenance': `${ONEQUIP_PATH} : maintenance`
};

export const PAGE_MAP = {
    '/equipment/hoc': `${ONEQUIP_PATH} : heights of cut`,
    '/home': 'home : homepage',
    '/home/details/equipment/equipment_1': `${DETAILS_PATH} : equipment : height of cut history`,
    '/home/details/equipment/equipment_2': `${DETAILS_PATH} : equipment : monthly`,
    '/home/details/equipment/equipment_3': `${DETAILS_PATH} : equipment : yearly`,
    '/home/details/equipment/equipment_4': `${DETAILS_PATH} : equipment : fuel & gas`,
    '/home/details/fert_chem': `${DETAILS_PATH} : fert & chem`,
    '/home/details/fert_chem/PlayBooks': `${DETAILS_PATH} : fert & chem : playbooks`,
    '/home/details/fert_chem/fert_chem_1': `${DETAILS_PATH} : fert & chem : monthly`,
    '/home/details/fert_chem/fert_chem_2': `${DETAILS_PATH} : fert & chem : yearly`,
    '/home/details/fert_chem/fert_chem_3': `${DETAILS_PATH} : fert & chem : nitrogen, tgr & pgr`,
    '/home/details/labor/labor_1': `${DETAILS_PATH} : labor : monthly`,
    '/home/details/labor/labor_2': `${DETAILS_PATH} : labor : yearly`,
    '/home/details/labor/labor_3': `${DETAILS_PATH} : labor : performance monthly`,
    '/home/details/labor/labor_4': `${DETAILS_PATH} : labor : performance yearly`,
    '/home/details/rounds/ForeTees': `${DETAILS_PATH} : rounds : foretees`,
    '/home/details/rounds/rounds_1': `${DETAILS_PATH} : rounds : monthly`,
    '/home/details/rounds/rounds_2': `${DETAILS_PATH} : rounds : yearly`,
    '/home/details/settings/labor/mywork': `${SETTINGS_PATH} : labor : my work`,
    '/home/details/settings/my_john_deere/connections': `${SETTINGS_PATH} : myjohndeere : connections`,
    '/home/details/settings/my_john_deere/equipment': `${SETTINGS_PATH} : myjohndeere : equipment`,
    '/home/details/settings/my_john_deere/linked_account': `${SETTINGS_PATH} : myjohndeere : linked account`,
    '/home/details/settings/my_john_deere/organizations': `${SETTINGS_PATH} : myjohndeere : organizations`,
    '/home/details/settings/partners/pogocoursename': `${SETTINGS_PATH} : partners : pogo course names`,
    '/home/details/water_energy': `${DETAILS_PATH} : water`,
    '/home/details/water_energy/water_energy_2': `${DETAILS_PATH} : water : energy use & cost`,
    '/home/details/water_energy/water_energy_5': `${DETAILS_PATH} : water : monthly`,
    '/home/details/water_energy/water_energy_6': `${DETAILS_PATH} : water : yearly`,
    '/home/details/weather_course': `${DETAILS_PATH} : conditions`,
    '/home/details/weather_course/TWC': `${DETAILS_PATH} : conditions : forecast conditions`,
    '/home/details/weather_course/weather_3': `${DETAILS_PATH} : conditions : soil conditions`,
    '/home/details/weather_course/weather_6': `${DETAILS_PATH} : conditions : performance & elements`,
    '/labor/myWork': 'labor : my work',
    '/map/location_history': 'map : location history',
    ...Object.keys(ONEQUIP_MAP).reduce((paths, equipmentPath) => {
        const basePath = ONEQUIP_MAP[equipmentPath];

        paths[equipmentPath] = basePath;
        paths[`${equipmentPath}/equipmentDetails/expenses`] = `${basePath} : expenses`;
        paths[`${equipmentPath}/equipmentDetails/history`] = `${basePath} : history`;
        paths[`${equipmentPath}/equipmentDetails/jobs`] = `${basePath} : jobs`;
        paths[`${equipmentPath}/equipmentDetails/maintenance`] = `${basePath} : maintenance`;
        paths[`${equipmentPath}/equipmentDetails/parts`] = `${basePath} : parts`;
        paths[`${equipmentPath}/serviceForm`] = `${basePath} : service form`;

        return paths;
    }, {})
};
