// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';

export const STATUS_COLOR_MAPPING = {
    gray: {
        color: '#bfabcb',
        textColor: '#000'
    },
    mauve: {
        color: '#bfabcb',
        textColor: '#000'
    },
    black: {
        color: '#993399',
        textColor: '#fff'
    },
    magenta: {
        color: '#993399',
        textColor: '#fff'
    },
    'dark-red': {
        color: '#8a0000',
        textColor: '#fff'
    },
    red: {
        color: '#8a0000',
        textColor: '#fff'
    },
    brown: {
        color: '#99681e',
        textColor: '#fff'
    },
    orange: {
        color: '#f2a33c',
        textColor: '#000'
    },
    green: {
        color: '#47c2b7',
        textColor: '#000'
    },
    teal: {
        color: '#47c2b7',
        textColor: '#000'
    },
    blue: {
        color: '#0066ad',
        textColor: '#fff'
    },
    purple: {
        color: '#5f0066',
        textColor: '#fff'
    }
};

export const REMAP_STATUS_COLORS = {
    black: 'magenta',
    gray: 'mauve',
    red: 'dark-red',
    green: 'teal'
};

export const EMPLOYEE_STATUS_COLORS = ['mauve', 'magenta', 'brown', 'dark-red', 'orange', 'teal', 'blue', 'purple'];

export const VALID_HEX_COLORS = [
    {
        id: '#7b9cff',
        Icon: <span className='color-item periwinkle'/>,
        translationKeyWord: 'COLOR_PERIWINKLE'
    },
    {
        id: '#05a1c9',
        Icon: <span className='color-item light-blue'/>,
        translationKeyWord: 'COLOR_LIGHT_BLUE'
    },
    {
        id: '#0066ad',
        Icon: <span className='color-item blue'/>,
        translationKeyWord: 'COLOR_BLUE'
    },
    {
        id: '#ffff82',
        Icon: <span className='color-item light-yellow'/>,
        translationKeyWord: 'COLOR_LIGHT_YELLOW'
    },
    {
        id: '#16c06b',
        Icon: <span className='color-item green'/>,
        translationKeyWord: 'COLOR_GREEN'
    },
    {
        id: '#47c2b7',
        Icon: <span className='color-item teal'/>,
        translationKeyWord: 'COLOR_TEAL'
    },
    {
        id: '#f2a33c',
        Icon: <span className='color-item orange'/>,
        translationKeyWord: 'COLOR_ORANGE'
    },
    {
        id: '#d2ad79',
        Icon: <span className='color-item light-brown'/>,
        translationKeyWord: 'COLOR_LIGHT_BROWN'
    },
    {
        id: '#8a0000',
        Icon: <span className='color-item dark-red'/>,
        translationKeyWord: 'COLOR_DARK_RED'
    },
    {
        id: '#bfabcb',
        Icon: <span className='color-item mauve'/>,
        translationKeyWord: 'COLOR_MAUVE'
    },
    {
        id: '#5f0066',
        Icon: <span className='color-item purple'/>,
        translationKeyWord: 'COLOR_PURPLE'
    },
    {
        id: '#7464D0',
        Icon: <span className='color-item violet'/>,
        translationKeyWord: 'COLOR_VIOLET'
    },
    {
        id: '#00ffc3',
        Icon: <span className='color-item aquamarine'/>,
        translationKeyWord: 'COLOR_AQUAMARINE'
    },
    {
        id: '#993399',
        Icon: <span className='color-item magenta'/>,
        translationKeyWord: 'COLOR_MAGENTA'
    },
    {
        id: '#99681e',
        Icon: <span className='color-item brown'/>,
        translationKeyWord: 'COLOR_BROWN'
    }
];

export const VALID_COLOR_IDS = new Set(VALID_HEX_COLORS.map(({id}) => id));
