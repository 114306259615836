// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import BasicTableCell from 'Ui/components/common/basic-table/basic-table-cell';

function BasicTableRow(props) {
    return (
        <div className='basic-table-row'>
            {
                Object.keys(props.cells).map((key) => (
                    <BasicTableCell key={`table-cell ${key}`}>
                        {props.cells[key]}
                    </BasicTableCell>
                ))
            }
        </div>
    );
}

BasicTableRow.propTypes = {
    cells: PropTypes.row
};

export default BasicTableRow;
