// Unpublished Work © 2023-2024 Deere & Company.

import {getAddressFieldsByCountry} from 'Services/membership-service';
import {camelCase, sortBy} from 'lodash';

async function getAddressFormatElements(country) {
    const {addressFormatElements} = await getAddressFieldsByCountry(country);

    return sortBy(addressFormatElements, 'row', 'column').map((addressFormatElement) => ({
        ...addressFormatElement,
        addressFormatElementClass: camelCase(addressFormatElement.addressFormatElementClass)
    }));
}

function mapAddressElementsByClass(addressElements = []) {
    return addressElements.reduce((addressElementObject, addressElement) => ({
        ...addressElementObject,
        [addressElement.elementClass]: addressElement.elementValue
    }), {});
}

export {
    getAddressFormatElements,
    mapAddressElementsByClass
};
