// Unpublished Work © 2023-2024 Deere & Company.

const WORKBOARD_START_TIME = 'workboard_default_start_time';

const HOURS = {
    TWELVE_AM: 0,
    ONE_AM: 1,
    TWO_AM: 2,
    THREE_AM: 3,
    FOUR_AM: 4,
    FIVE_AM: 5,
    SIX_AM: 6,
    SEVEN_AM: 7,
    EIGHT_AM: 8,
    NINE_AM: 9,
    TEN_AM: 10,
    ELEVEN_AM: 11,
    TWELVE_PM: 12,
    ONE_PM: 13,
    TWO_PM: 14,
    THREE_PM: 15,
    FOUR_PM: 16,
    FIVE_PM: 17,
    SIX_PM: 18,
    SEVEN_PM: 19,
    EIGHT_PM: 20,
    NINE_PM: 21,
    TEN_PM: 22,
    ELEVEN_PM: 23

};

function getWorkboardProperties(translations) {
    const offOnOptions = [
        {
            label: translations.OFF,
            value: 'false'
        },
        {
            label: translations.ON,
            value: 'true'
        }
    ];
    const onOffOptions = [
        {
            label: translations.ON,
            value: 'true'
        },
        {
            label: translations.OFF,
            value: 'false'
        }
    ];
    const fontOptions = [
        {
            label: translations.ONLINK_EXTRA_SMALL_FONT,
            value: '70%'
        },
        {
            label: translations.SMALL,
            value: '80%'
        },
        {
            label: translations.UOM_MEDIUM,
            value: '100%'
        },
        {
            label: translations.LARGE,
            value: '120%'
        },
        {
            label: translations.ONLINK_EXTRA_LARGE_FONT,
            value: '140%'
        }
    ];

    return {
        'workboard_week_start_day': {
            label: translations.ONLINK_WEEK_START_DAY,
            options: [
                {
                    label: translations.days_Monday,
                    value: '1'
                },
                {
                    label: translations.days_Tuesday,
                    value: '2'
                },
                {
                    label: translations.days_Wednesday,
                    value: '3'
                },
                {
                    label: translations.days_Thursday,
                    value: '4'
                },
                {
                    label: translations.days_Friday,
                    value: '5'
                },
                {
                    label: translations.days_Saturday,
                    value: '6'
                },
                {
                    label: translations.days_Sunday,
                    value: '7'
                }
            ],
            default: '1'
        },
        'workboard_weekly_work_hours': {
            label: translations.ONLINK_WEEKLY_WORK_HOURS,
            default: '40'
        },
        'workboard_copy_estimated_to_actual': {
            label: translations.ONLINK_COPY_ESTIMATED_HOURS,
            options: offOnOptions,
            default: 'false'
        },
        'workboard_presentation_groups': {
            label: translations.ONLINK_WORKBOARD_DISPLAY_GROUPING,
            options: [
                {
                    label: translations.ONLINK_GROUP_BY_JOBS,
                    value: 'jobs'
                },
                {
                    label: translations.ONLINK_GROUP_BY_EMPLOYEES,
                    value: 'users'
                }
            ],
            default: 'jobs'
        },
        'workboard_creation_flow': {
            label: translations.ONLINK_WORKBOARD_CREATION,
            options: [
                {
                    label: translations.ONLINK_WORKBOARD_CREATION_BY_EMPLOYEE,
                    value: 'user'
                },
                {
                    label: translations.ONLINK_WORKBOARD_CREATION_BY_JOB,
                    value: 'job'
                }
            ],
            default: 'job'
        },
        'workboard_presentation_autoscroll_speed': {
            label: translations.ONLINK_WORKBOARD_DISPLAY_AUTO_SCROLL,
            options: [
                {
                    label: translations.ONLINK_DISABLED,
                    value: '0'
                },
                {
                    label: translations.ONLINK_FAST,
                    value: '25'
                },
                {
                    label: translations.ONLINK_NORMAL,
                    value: '50'
                },
                {
                    label: translations.ONLINK_SLOW,
                    value: '75'
                },
                {
                    label: translations.ONLINK_VERY_SLOW,
                    value: '100'
                }
            ],
            default: '0'
        },
        'workboard_presentation_show_weather': {
            label: translations.ONLINK_SHOW_WEATHER,
            options: onOffOptions,
            default: 'true'
        },
        'workboard_presentation_show_estimated_hours': {
            label: translations.ONLINK_SHOW_ESTIMATED_HOURS,
            options: onOffOptions,
            default: 'true'
        },
        'workboard_presentation_font_size': {
            label: translations.ONLINK_WORKBOARD_DISPLAY_FONT,
            options: fontOptions,
            default: '100%'
        },
        'workboard_presentation_font_size_notes': {
            label: translations.ONLINK_NOTE_DISPLAY_FONT,
            options: fontOptions,
            default: '100%'
        },
        'workboard_presentation_note': {
            label: translations.ONLINK_NOTES,
            default: ''
        },
        'workboard_employee_daily_hours_limit': {
            default: '8'
        },
        'workboard_presentation_column_count': {
            default: '1',
            options: [
                {
                    value: '1'
                },
                {
                    value: '2'
                },
                {
                    value: '3'
                },
                {
                    value: '4'
                }
            ]
        },
        'mywork_jobs_displayed': {
            label: translations.ONLINK_JOBS_DISPLAYED,
            options: [
                {
                    label: translations.ONLINK_CURRENT_WORKBOARD_ONLY,
                    value: 'current_workboard'
                },
                {
                    label: translations.days_Today,
                    value: 'today'
                },
                {
                    label: translations.ALL,
                    value: 'all'
                }
            ],
            default: 'current_workboard'
        },
        'mywork_workboard_view': {
            label: translations.ONLINK_WORKBOARD_VIEW,
            options: [
                {
                    label: translations.OFF,
                    value: 'off'
                },
                {
                    label: translations.ONLINK_CURRENT_ONLY,
                    value: 'current'
                },
                {
                    label: translations.ONLINK_CURRENT_AND_FUTURE,
                    value: 'current_and_future'
                }
            ],
            default: 'off'
        },
        'mywork_show_route': {
            label: translations.ONLINK_SHOW_ROUTE,
            options: offOnOptions,
            default: 'false'
        },
        'mywork_show_estimated_hours': {
            label: translations.ONLINK_SHOW_ESTIMATED_HOURS,
            options: offOnOptions,
            default: 'false'
        },
        'mywork_show_operator_note': {
            label: translations.ONLINK_SHOW_OPERATOR_NOTE,
            options: offOnOptions,
            default: 'false'
        },
        'mywork_show_pre_start_check': {
            label: translations.ONLINK_SHOW_PRE_START_CHECK,
            options: offOnOptions,
            default: 'false'
        },
        'mywork_show_protection_icons': {
            label: translations.ONLINK_SHOW_PROTECTION_ICONS,
            options: offOnOptions,
            default: 'false'
        },
        'workboard_show_employee_image': {
            label: translations.ONLINK_SHOW_EMPLOYEE_IMAGE,
            options: offOnOptions,
            default: 'false'
        },
        'workboard_show_employee_group_color': {
            label: translations.ONLINK_SHOW_EMPLOYEE_GROUP_COLOR,
            options: offOnOptions,
            default: 'false'
        },
        'workboard_show_route': {
            label: translations.ONLINK_SHOW_ROUTE,
            options: offOnOptions,
            default: 'false'
        },
        'workboard_show_protection_icons': {
            label: translations.ONLINK_SHOW_PROTECTION_ICONS,
            options: offOnOptions,
            default: 'false'
        },
        'workboard_tile_opacity': {
            label: translations.ONLINK_TILE_OPACITY,
            options: offOnOptions,
            default: 'true'
        }
    };
}

function getWorkboardPropertyOptions() {
    const workboardOptions = getWorkboardProperties({});

    const filteredOptions = Object.keys(workboardOptions).reduce((accumulator, key) => {
        const {
            label, options, ...remaining
        } = workboardOptions[key];

        accumulator[key] = {
            ...remaining
        };

        if (options) {
            accumulator[key].options = options.map(({value}) => value);
        }

        return accumulator;
    }, {});

    filteredOptions[WORKBOARD_START_TIME] = {
        options: Object.values(HOURS).map(String),
        default: '6'
    };

    return filteredOptions;
}

function getPropertyValues(property) {
    const options = getWorkboardPropertyOptions();

    return options[property]?.options;
}

export {
    HOURS,
    getWorkboardProperties,
    getPropertyValues,
    getWorkboardPropertyOptions
};
