// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Bar} from 'react-chartjs-2';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';

function ingredientsCheckForYearly({Ingredients}) {
    return Object.entries(Ingredients).some(([ingredient, {YTD}]) => ingredient !== 'CostTotal' && YTD > 0);
}

function makeTableRow({
    areaName,
    conversionConfig,
    key,
    monthlyTotal,
    ytd
}) {
    return (
        <div
            className='table-list-items'
            key={key}
        >
            <div className='table-cell cell-left'>
                {areaName}
            </div>
            <div className='table-cell not-name'>
                {getFormattedCurrency(monthlyTotal, conversionConfig)}
            </div>
            <div className='table-cell cell-right'>
                {getFormattedCurrency(ytd, conversionConfig)}
            </div>
        </div>
    );
}

function getBarData(monthlyData, translations) {
    const mappedMonthlyData = monthlyData.map((lineData, i) => {
        const {
            AreaName,
            Ingredients: {
                B, Ca, Cu, Fe, K, Mg, Mn, Mo, N, P, S, Si, Zn
            }
        } = lineData;
        const color = GRAPH_COLORS[i];

        return {
            backgroundColor: color,
            borderColor: color,
            data: [B.YTD, Ca.YTD, Cu.YTD, Fe.YTD, K.YTD, Mg.YTD, Mn.YTD, Mo.YTD, N.YTD, P.YTD, S.YTD, Si.YTD, Zn.YTD],
            label: translations[AreaName] || AreaName
        };
    });

    return {
        datasets: mappedMonthlyData,
        labels: ['B', 'Ca', 'Cu', 'Fe', 'K', 'Mg', 'Mn', 'Mo', 'N', 'P', 'S', 'Si', 'Zn']
    };
}

function Nutrients(props) {
    const {
        currencyPreference,
        data,
        featureToggles,
        hasFinanceAuth,
        translations
    } = props;

    const hasData = !isNullOrUndefined(data);
    const filteredMonthlyData = hasData ? data.MonthlyData.filter(ingredientsCheckForYearly) : [];
    const conversionConfig = {
        currencyPreference,
        featureToggles
    };

    return (
        <div className='table-group-card inventory-card'>
            <NoDataMessage
                hasData={hasData}
                noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
            >
                <>
                    <div className='bar-chart'>
                        <NoDataMessage
                            hasData={filteredMonthlyData.length > 0}
                            noDataMessage={translations.ONLINK_NO_MONTHLY_DATA}
                        >
                            <Bar
                                data={getBarData(filteredMonthlyData, translations)}
                                options={{
                                    scales: {
                                        ingredients: {
                                            axis: 'x',
                                            stacked: true
                                        },
                                        weight: {
                                            axis: 'y',
                                            stacked: true,
                                            ticks: {
                                                callback: (label) => `${label} ${translations.lb}`
                                            }
                                        }
                                    }
                                }}
                                type='bar'
                            />
                        </NoDataMessage>
                    </div>
                    {
                        hasFinanceAuth &&
                        <>
                            <div className='card-header-labels'>
                                <div className='card-header-item cell-left'>
                                    {translations.AREA}
                                </div>
                                <div className='card-header-item not-name'>
                                    {translations.ONLINK_MONTHLY}
                                </div>
                                <div className='card-header-item cell-right'>
                                    {translations.ONLINK_YTD}
                                </div>
                            </div>
                            <div className='table-list'>
                                {
                                    filteredMonthlyData.map(({
                                        AreaName,
                                        Ingredients: {CostTotal}
                                    }) => makeTableRow({
                                        areaName: translations[AreaName] || AreaName,
                                        conversionConfig,
                                        key: AreaName,
                                        monthlyTotal: parseFloat(CostTotal?.MonthlyTotal),
                                        ytd: parseFloat(CostTotal?.YTD)
                                    }))
                                }
                                {
                                    makeTableRow({
                                        areaName: translations.ONLINK_TOTAL,
                                        conversionConfig,
                                        key: 'total',
                                        monthlyTotal: data?.ApplicationTotalCost?.MonthlyTotal,
                                        ytd: data?.ApplicationTotalCost?.YTD
                                    })
                                }
                            </div>
                        </>
                    }
                </>
            </NoDataMessage>
        </div>
    );
}

Nutrients.propTypes = {
    currencyPreference: PropTypes.string,
    data: PropTypes.object,
    featureToggles: PropTypes.featureToggles,
    hasFinanceAuth: PropTypes.bool,
    translations: PropTypes.translations
};

export default Nutrients;
