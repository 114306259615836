// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import EquipmentTransferForm from 'OnEquip/equipment/common/equipment-transfer-form';
import {getFleetByMembershipId} from 'Services/membership-service';
import {transferEquipment} from 'Services/equipment-service';
import PropTypes from 'Utils/prop-type-utils';
import {addHierarchyOfMembershipsToList} from 'OnEquip/equipment/utils/membership-hierarchy-utils';

function EquipmentTransferDialog(props) {
    const {
        closeDialog,
        equipment,
        invalidInputs,
        membershipHierarchy,
        setValid,
        translations,
        updateFleetEquipment
    } = props;

    const [selectedMembershipId, setSelectedMembershipId] = React.useState(() => null);
    const [loading, setLoading] = React.useState(false);

    async function onSave() {
        setLoading(true);

        const {fleets} = await getFleetByMembershipId(selectedMembershipId);
        const destinationFleetId = fleets[0].fleetId;

        const transferData = {
            destinationFleetId,
            equipment,
            equipmentId: equipment.equipmentId,
            sourceFleetId: equipment.fleetId
        };

        await transferEquipment(transferData);

        setLoading(false);

        updateFleetEquipment();
        closeDialog();
    }

    const allSelectableMemberships = React.useMemo(
        () => addHierarchyOfMembershipsToList(null, membershipHierarchy, equipment.membership.membershipId),
        [membershipHierarchy]
    );

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='equipment-transfer-dialog dropdown-overflow-dialog'
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0}
            footerLoading={loading}
            onSave={onSave}
            saveLabel={translations.TRANSFER}
            title={translations.TRANSFER}
            translations={translations}
        >
            <EquipmentTransferForm
                equipment={equipment}
                membershipSelection={allSelectableMemberships}
                membershipSourceName={equipment.fleetInUseName}
                selectedMembershipId={selectedMembershipId}
                setSelectedMembershipId={setSelectedMembershipId}
                setValid={setValid}
                translations={translations}
            />
        </FormDialog>
    );
}

EquipmentTransferDialog.propTypes = {
    closeDialog: PropTypes.func,
    equipment: PropTypes.equipment,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipHierarchy: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.EQUIPMENT_TRANSFER_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EquipmentTransferDialog));
