// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {getHoleOptions, onManualDataChange, validateDate, getLocalTimeFormats} from 'Ui/components/manual-data/manual-data-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {
    convertFeetAndInchesToInches,
    convertToMultipleDataUnitValues,
    determineConversionConfig,
    formatNumber,
    getLanguagePreference,
    getPreferredDataTypeForm
} from 'Utils/unit-conversion-utils';
import {GREEN_SPEED} from 'Common/constants/data-group-constants';
import {FEET, INCHES, METERS} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import moment from 'moment';

const MAX_METERS = 30;
const MAX_FEET = 100;
const MAX_INCHES = 11;

function getDisplayValueEnglish(values, unitConversionConfig, uom) {
    const [feet, inches] = convertToMultipleDataUnitValues(values.manualData[0].value, uom, unitConversionConfig);

    return [feet, formatNumber(inches, {
        maximumFractionDigits: 0
    })];
}

function init() {
    const [validatedDate, setValidatedDate] = React.useState();
    const feetValue = React.useRef();
    const inchesValue = React.useRef();

    return {
        validatedDate,
        setValidatedDate,
        feetValue,
        inchesValue
    };
}

function GreenSpeedEntryForm(props) {
    const {
        featureToggles,
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    const {
        validatedDate,
        setValidatedDate,
        feetValue,
        inchesValue
    } = init();

    const {
        greenSpeedUOM,
        holesOptions,
        minimumValueMessage,
        unitConversionConfig
    } = React.useMemo(() => {
        const holesOptions = getHoleOptions(translations);

        const greenSpeedUOM = values.manualData ? values.manualData[0].data_unit :
            getPreferredDataTypeForm(membership.preferredDataTypes, membership.unitOfMeasure, [GREEN_SPEED]);

        const unitConversionConfig = determineConversionConfig({
            dataType: GREEN_SPEED,
            dataUnit: null,
            featureToggles,
            isConfigForGraph: false
        })[UNIT_OF_MEASURE.ENGLISH].formatting;

        const [feetValueToAdd, inchesValueToAdd] = [INCHES, FEET].includes(greenSpeedUOM) ? getDisplayValueEnglish(values, unitConversionConfig, greenSpeedUOM) : ['', ''];

        feetValue.current = feetValueToAdd;
        inchesValue.current = inchesValueToAdd;

        const minimumValueMessage = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
            '0': MIN_NUMERIC_VALUE
        });

        return {
            greenSpeedUOM,
            holesOptions,
            minimumValueMessage,
            unitConversionConfig
        };
    }, [membership]);

    React.useEffect(() => {
        setValidatedDate(validateDate(values.timeSample, setValid));
    }, [values.timeSample]);

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onFeetChange(value) {
        setValues((prevValues) => {
            const newFeetValue = value === '' ? undefined : value;

            const previousInchesValue = prevValues.manualData ? getDisplayValueEnglish(prevValues, unitConversionConfig, INCHES)[1] : undefined;
            const totalInInches = convertFeetAndInchesToInches(newFeetValue, previousInchesValue);

            feetValue.current = value;

            return {
                ...prevValues,
                manualData: [{
                    'data_type': values.dataGroup,
                    'data_unit': INCHES,
                    value: totalInInches
                }]
            };
        });
    }

    function onInchesChange(value) {
        setValues((prevValues) => {
            const newInchesValue = value === '' ? undefined : value;
            const previousFeetValue = prevValues.manualData ? getDisplayValueEnglish(prevValues, unitConversionConfig, INCHES)[0] : undefined;
            const totalInInches = convertFeetAndInchesToInches(previousFeetValue, newInchesValue);

            inchesValue.current = value;

            return {
                ...prevValues,
                manualData: [{
                    'data_type': values.dataGroup,
                    'data_unit': INCHES,
                    value: totalInInches
                }]
            };
        });
    }

    function getUOMFields(UOM) {
        if (UOM === METERS) {
            return (
                <ValidationInput
                    errors={{
                        rangeUnderflow: minimumValueMessage,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_METERS}`
                    }}
                    label={capitalizeFirstLetter(translations.meters)}
                    max={MAX_METERS}
                    min={MIN_NUMERIC_VALUE}
                    name='meters'
                    onChange={(event) => onManualDataChange({
                        dataUnit: METERS,
                        name: values.dataGroup,
                        setValues,
                        value: event.target.value
                    })}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='number'
                    value={values.manualData ? values.manualData[0].value : ''}
                />
            );
        }

        return (
            <div className='settings-group mobile'>
                <ValidationInput
                    errors={{
                        rangeUnderflow: minimumValueMessage,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_FEET}`,
                        patternMismatch: translations.ONLINK_INTEGER_VALIDATE
                    }}
                    label={translations.STANDARD_UOM_FOOT}
                    max={MAX_FEET}
                    min={MIN_NUMERIC_VALUE}
                    name='feet'
                    onChange={(event) => onFeetChange(event.target.value)}
                    pattern='^\d+$'
                    required={true}
                    setValid={setValid}
                    step={1}
                    tabIndex={0}
                    type='number'
                    value={feetValue.current}
                />
                <ValidationInput
                    errors={{
                        rangeUnderflow: minimumValueMessage,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_INCHES}`,
                        patternMismatch: translations.ONLINK_INTEGER_VALIDATE
                    }}
                    label={capitalizeFirstLetter(translations.INCHES)}
                    max={MAX_INCHES}
                    min={MIN_NUMERIC_VALUE}
                    name='inches'
                    onChange={(event) => onInchesChange(event.target.value)}
                    pattern='^\d+$'
                    required={true}
                    setValid={setValid}
                    step={1}
                    tabIndex={0}
                    type='number'
                    value={inchesValue.current}
                />
            </div>
        );
    }

    const UOMFields = getUOMFields(greenSpeedUOM);

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LLL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE_AND_TIME}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
                showTimeSelect={true}
                timeFormat={getLocalTimeFormats()}
                timeIntervals={1}
            />
            <ValidationMultiSelect
                className='mobile-required-multiselect'
                disabled={false}
                error={translations.REQUIRED_FIELD_TEXT}
                hideCheckboxes={true}
                items={holesOptions}
                label={translations.ONLINK_HOLES}
                multiple={false}
                name='hole'
                onChange={(event) => onValuesChange('hole', event[0])}
                required={true}
                selectedIds={[values.hole]}
                setValid={setValid}
                tabIndex={0}
            />
            {UOMFields}
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

GreenSpeedEntryForm.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(GreenSpeedEntryForm);
