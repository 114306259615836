// Unpublished Work © 2021-2024 Deere & Company.

export default {
    ACCESS_MULTI_USER_MODE: 'Can Access Multi-User Mode',
    ACCESS_SUPERINTENDENT_MODE: 'Can Access Superintendent Mode',
    ASSIGNED_TO_SERVICE_TICKETS: 'Can be Assigned to Service Tickets',
    DELETE_SERVICE_TICKETS: 'Can Delete Service Tickets',
    EDIT_APP_ACCESS: 'Can Edit App Access',
    EDIT_ROLES: 'Can Edit Roles',
    RECEIVES_ALERTS: 'Receives Alerts',
    VIEW_HOURLY_RATES: 'Can View Hourly Rates',
    VIEW_SALARIES: 'Can View Salaries',
    MANAGE_MYJD_CONFIGURATION: 'Manage MyJohnDeere Configuration',
    CAN_ACCESS_MY_WORK: 'Can Access My Work'
};
