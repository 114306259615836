// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import {Button} from '@deere/isg.component-library/lib/Button';
import {connect} from 'react-redux';

function buttonContents(props) {
    return (
        <>
            {props.leftIcon}
            <div className='onlink-btn-text'>
                {props.children}
            </div>
            {props.rightIcon}
        </>
    );
}

function OnlinkButton(props) {
    const {isMigrated} = props;
    const color = props.color ? `onlink-btn-${props.color}` : null;
    const active = props.active ? 'onlink-btn-active' : null;
    const borderless = props.borderless ? 'onlink-btn-borderless' : null;

    const classNames = joinClassNames(
        'onlink-btn',
        color,
        active,
        borderless,
        props.className
    );

    if (isMigrated) {
        return (
            <Button
                className={classNames}
                disabled={props.disabled}
                onClick={props.onClick}
                type={props.type}
            >
                {buttonContents(props)}
            </Button>
        );
    }

    return (
        <button
            className={classNames}
            disabled={props.disabled}
            onClick={props.onClick}
            type={props.type}
        >
            {buttonContents(props)}
        </button>
    );
}

OnlinkButton.propTypes = {
    active: PropTypes.bool,
    borderless: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    isMigrated: PropTypes.bool,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    rightIcon: PropTypes.node,
    type: PropTypes.string
};
export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(OnlinkButton);
