// Unpublished Work © 2022-2024 Deere & Company.

export const BY_JOB_CATEGORY = 'byCategory';
export const BY_JOB_TEMPLATE = 'byJob';
export const BY_STATUS = 'byStatus';
export const BY_TIME = 'byTime';
export const BY_USER = 'byUser';
export const BY_USER_STATUS = 'byUserStatus';
export const JOB_CATEGORIES_BY_TIME = 'jobCategoriesbyTime';
export const JOB_TEMPLATES_BY_TIME = 'jobTemplatesbyTime';
export const USERS_BY_TIME = 'usersbyTime';

export const ACTUAL_COST = 'laborCost';
export const ACTUAL_DURATION = 'actualDuration';
export const ESTIMATED_COST = 'estLaborCost';
export const ESTIMATED_DURATION = 'estDuration';
export const OVERTIME_COST = 'overtimeCost';
export const OVERTIME_DURATION = 'overtimeDuration';

export const ID_TO_PROPERTY_MAP = {
    [BY_JOB_CATEGORY]: 'jobCategories',
    [JOB_CATEGORIES_BY_TIME]: 'jobCategories',
    [BY_JOB_TEMPLATE]: 'jobTemplates',
    [JOB_TEMPLATES_BY_TIME]: 'jobTemplates',
    [BY_STATUS]: 'users',
    [BY_USER]: 'users',
    [USERS_BY_TIME]: 'users',
    [BY_TIME]: 'users'
};
