// Unpublished Work © 2023-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function sendFeedback(feedback) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/feedback', feedback);

    return response.data;
}

export {
    sendFeedback
};
