// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import DropdownNavItem from 'Ui/components/main-navbar/persistent-header/navigation/dropdown-nav-item';
import NavList from 'Ui/components/main-navbar/persistent-header/common/nav-list';
import UserDetails from 'Ui/components/main-navbar/persistent-header/shared-navbar/user-details';
import {useDeepMemo} from 'Utils/react-utils';

function Account(props) {
    const {
        links: {accountLinks = [] },
        translations,
        user
    } = props;

    const accountItems = useDeepMemo(() => (
        <NavList
            additionalNavItemProps={{
                className: 'account-item'
            }}
            navbarLinks={accountLinks}
            translations={translations}
        />
    ), [accountLinks, translations]);

    return (
        <DropdownNavItem
            iconName='icon-person'
            shouldRenderChevron={false}
        >
            <UserDetails user={user}/>
            {accountItems}
        </DropdownNavItem>
    );
}

Account.propTypes = {
    links: PropTypes.links,
    translations: PropTypes.translations,
    user: PropTypes.object
};

export default Account;
