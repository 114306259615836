// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddressFieldInputs from 'Ui/components/settings/common/form/address-field-inputs';
import ValidationInput from 'Ui/components/common/form/validation-input';
import OnlinkButton from 'Ui/components/common/onlink-button';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import {useSave} from 'Ui/react-hooks/use-save';
import {getAddressFormatElements, mapAddressElementsByClass, resetAddressElementInputs} from 'Utils/address-format';
import {asNumber} from 'Utils/conversion-utils';
import {calculateLatLong} from 'Utils/geocoder-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {updateMembershipData} from 'Services/membership-service';
import {updateMembership} from 'Store/actions/membership';

const MAX_LATITUDE = 90;
const MAX_LONGITUDE = 180;
const MIN_LATITUDE = -90;
const MIN_LONGITUDE = -180;

function initializeState(membership) {
    const [values, setValues] = React.useState(() => {
        const membershipOrDefault = membership || {};

        return {
            address1: membershipOrDefault.address1 || '',
            address2: membershipOrDefault.address2 || '',
            addressElements: mapAddressElementsByClass(membershipOrDefault.addressElements),
            city: membershipOrDefault.city || '',
            country: membershipOrDefault.country || 'US',
            latitude: membershipOrDefault.latitude || 0,
            longitude: membershipOrDefault.longitude || 0,
            name: membershipOrDefault.name || '',
            state: membershipOrDefault.state,
            zip: membershipOrDefault.zip || ''
        };
    });
    const [loading, setLoading] = React.useState(true);
    const [addressFields, setAddressFields] = React.useState([]);

    return {
        addressFields,
        loading,
        setAddressFields,
        setLoading,
        setValues,
        values
    };
}

function getValidAddressFieldsByCountry(addressFields) {
    return addressFields.map(({addressFormatElementClass}) => addressFormatElementClass);
}

async function calculateLatitudeLongitude(addressFields, event, featureToggles, values, setValues) {
    event.preventDefault();

    const validAddressFields = getValidAddressFieldsByCountry(addressFields);
    const dynamicAddressFields = Object.keys(values.addressElements)
        .filter((key) => validAddressFields?.includes(key))
        .map((key) => values.addressElements[key]);

    const {
        latitude,
        longitude
    } = await calculateLatLong(dynamicAddressFields);

    setValues((prevValues) => ({
        ...prevValues,
        latitude,
        longitude
    }));
}

function EditableAccountSettings(props) {
    const {
        featureToggles,
        invalidInputs,
        membership,
        setIsEditing,
        setValid,
        translations,
        updateMembership
    } = props;

    const {
        addressFields,
        loading,
        setAddressFields,
        setLoading,
        setValues,
        values
    } = initializeState(membership);

    const [saveFunc, disableSave] = useSave(async () => {
        const validAddressFields = getValidAddressFieldsByCountry(addressFields);

        const addressElements = Object.keys(values.addressElements).filter((key) => validAddressFields?.includes(key)).map((key) => ({
            elementClass: key,
            elementValue: values.addressElements[key]
        }));

        const membershipData = {
            ...values,
            addressElements,
            latitude: asNumber(values.latitude),
            longitude: asNumber(values.longitude)
        };

        await updateMembershipData(membershipData, membership.membershipId);

        updateMembership({
            ...membership,
            ...membershipData
        });

        setIsEditing();
    }, {
        disabled: loading,
        invalidInputs
    });

    function onChange(event) {
        const {
            name, value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onAddressElementChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            addressElements: {
                ...prevValues.addressElements,
                [name]: value
            }
        }));
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const addressFormatElements = await getAddressFormatElements(values.country);

        if (isMounted()) {
            setAddressFields(addressFormatElements);

            resetAddressElementInputs(addressFormatElements, values.addressElements, onAddressElementChange);

            setLoading(false);
        }
    }), [values.country]);

    const latitudeValidationMessage = replaceTranslationNames(translations.VALUE_BETWEEN, {
        '0': MIN_LATITUDE.toString(),
        '1': MAX_LATITUDE.toString()
    });

    const longitudeValidationMessage = replaceTranslationNames(translations.VALUE_BETWEEN, {
        '0': MIN_LONGITUDE.toString(),
        '1': MAX_LONGITUDE.toString()
    });

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        onChange,
        required: true,
        setValid,
        tabIndex: 0
    };

    return (
        <>
            <form
                className='settings-body editable-account-settings'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    {...sharedInputProps}
                    autoComplete='organization'
                    autoFocus={true}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.NAME}
                    name='name'
                    type='text'
                    value={values.name}
                />
                <AddressFieldInputs
                    {...values}
                    addressFields={addressFields}
                    isRequired={true}
                    loading={loading}
                    onAddressElementChange={onAddressElementChange}
                    onInputChange={onChange}
                    setValid={setValid}
                    translations={translations}
                />
                <ValidationInput
                    {...sharedInputProps}
                    errors={{
                        rangeOverflow: latitudeValidationMessage,
                        rangeUnderflow: latitudeValidationMessage,
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.latitude}
                    max={MAX_LATITUDE}
                    min={MIN_LATITUDE}
                    name='latitude'
                    step='any'
                    type='number'
                    value={values.latitude}
                />
                <ValidationInput
                    {...sharedInputProps}
                    errors={{
                        rangeOverflow: longitudeValidationMessage,
                        rangeUnderflow: longitudeValidationMessage,
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.longitude}
                    max={MAX_LONGITUDE}
                    min={MIN_LONGITUDE}
                    name='longitude'
                    step='any'
                    type='number'
                    value={values.longitude}
                />
                <OnlinkButton onClick={(event) => calculateLatitudeLongitude(addressFields, event, featureToggles, values, setValues)}>
                    {translations.ONLINK_CALCULATE_LAT_LONG}
                </OnlinkButton>
            </form>

            <SaveContentBar
                disabled={disableSave}
                onCancelClick={setIsEditing}
                onSaveClick={saveFunc}
                translations={translations}
            />
        </>
    );
}

EditableAccountSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateMembership(value) {
            dispatch(updateMembership(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EditableAccountSettings));
