// Unpublished Work © 2021-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import {cloneDeep, groupBy} from 'lodash';
import React from 'react';
import WorkBoardJobDetailsCard from 'OnLabor/my-work/work-board-dialog/work-board-dialog-jobs/work-board-job-details-card';
import {TITLE, USER} from 'Ui/constants/workboard-constants';
import {WORKBOARD_PRESENTATION_GROUPS} from 'Ui/constants/membership-properties';
import {ONLINK_CREATION_FLOW} from 'Common/constants/feature-toggles';
import {
    sortByEmployeeWorkItemsBySeq,
    sortByJobsWorkItemsByUserOrder
} from 'OnLabor/workboard/utils/workboard-sort';

function breakIntoColumns(groupedArray, translations, properties, secondaryJobs) {
    function getTotalRank(group) {
        return group.reduce((workingArray, workItem, index) => {
            const additionalJobs = workItem.reduce((total, item) => {
                const additionalJobValue = secondaryJobs ? secondaryJobs[item.user].length : 0;

                return total + additionalJobValue;
            }, 0);

            const element = (<WorkBoardJobDetailsCard
                items={workItem}
                key={index}
                properties={properties}
                secondaryJobs={secondaryJobs}
                translations={translations}
            />);

            workingArray.total += workItem.length + additionalJobs;
            workingArray.items = workingArray.items.concat(element);

            return workingArray;
        }, {
            items: [],
            total: 0
        });
    }

    const jobsArray = Object.values(groupedArray);
    const middleIndex = Math.ceil(jobsArray.length / 2);

    const group0 = getTotalRank(jobsArray.slice(0, middleIndex));
    const group1 = getTotalRank(jobsArray.slice(middleIndex));

    if (jobsArray.length > 2 && group0.total - group1.total > 2) {
        const job = group0.items.pop();

        group1.items.unshift(job);
    }

    return (
        <>
            <div className='job-card-column'>
                {group0.items}
            </div>
            <div className='job-card-column'>
                {group1.items}
            </div>
        </>
    );
}

function getSecondaryJob(byUser, workItems) {
    let primaryJobs = cloneDeep(workItems);

    const secondaryJobs = byUser.reduce((userObject, user) => {
        userObject[user[0].user] = user.slice(1);
        userObject[user[0].user].map((job) => {
            primaryJobs = primaryJobs.filter((item) => {
                const filterOutJobsWithSameUser = item.user !== user[0].user;
                const filterIfUsedInAdditionalJob = item.title !== job.title;

                return filterOutJobsWithSameUser || filterIfUsedInAdditionalJob;
            });
        });

        return userObject;
    }, {});

    return {
        primaryJobs,
        secondaryJobs
    };
}

function workItemsByJobs(byUser, workItems, translations, properties) {
    const {
        primaryJobs,
        secondaryJobs
    } = getSecondaryJob(byUser, workItems);

    const byTitle = groupBy(primaryJobs, TITLE);

    return breakIntoColumns(byTitle, translations, properties, secondaryJobs);
}

function WorkBoardJobDetails(props) {
    const {
        featureToggles,
        properties,
        translations,
        workboard,
        workItems
    } = props;

    const byJob = properties.workboard_presentation_groups === WORKBOARD_PRESENTATION_GROUPS.JOBS;

    const byUser = Object.values(groupBy(workItems, USER));

    if (featureToggles[ONLINK_CREATION_FLOW] && workboard.creationFlow === USER) {
        if (byJob) {
            const sortedWorkItemsByUser = sortByEmployeeWorkItemsBySeq(byUser, workboard.userOrder);

            const {
                primaryJobs,
                secondaryJobs
            } = getSecondaryJob(sortedWorkItemsByUser, workItems);

            const groupedAndSortedItems = sortByJobsWorkItemsByUserOrder(primaryJobs, workboard.userOrder);

            return breakIntoColumns(groupedAndSortedItems, translations, properties, secondaryJobs);
        }

        const sortedWorkItemsByUser = sortByEmployeeWorkItemsBySeq(byUser, workboard.userOrder);

        return breakIntoColumns(sortedWorkItemsByUser, translations, properties, null);
    }

    return byJob ? workItemsByJobs(byUser, workItems, translations, properties) : breakIntoColumns(byUser, translations, properties, null);
}

WorkBoardJobDetails.propTypes = {
    properties: PropTypes.object,
    translations: PropTypes.translations,
    workItems: PropTypes.arrayOf(PropTypes.object)
};

export default WorkBoardJobDetails;
