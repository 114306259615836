// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LineChart from 'Ui/components/graph/line-chart';
import LoadingIcon from 'Ui/components/common/loading-icon';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import Panel from 'Ui/components/panel/panel';
import GraphFilters from 'Ui/components/graph/graph-filters';
import GraphDataTable from 'Ui/components/graph/graph-data-table';
import {createDataTypeMap} from 'Utils/data-utils';
import {getMemberships, transformData} from 'Utils/graph-utils';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {convertToUoM} from 'Utils/unit-conversion-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {getPanelDetails, getPanelThresholds} from 'Ui/services/panel-service';
import {BY_LOCATION, BY_TYPE} from 'Ui/components/graph/constants/graph-filters';
import {clsx} from 'clsx';
import {
    DATA_TYPE_OVERRIDES,
    GROUPED_DATA_TYPE_OVERRIDES,
    GROUPED_DATA_TYPES
} from 'Ui/components/common/data-overrides';
import tileInfo from 'Ui/components/common/tile-constants/tile-info';
import NoDataMessage from 'Ui/components/common/message/no-data-message';

const DAY_AND_TIME = 'day & time';
const REQUEST_TIME_SCALES = {
    [DAY_AND_TIME]: 'daily',
    day: 'daily',
    month: 'monthly',
    year: 'yearly'
};

const EQUIPMENT_1 = 'equipment_1';

const DATA_TYPES = ['chart', 'dataTable'];

const LOADING_ICON_PROPS = {
    className: 'dashboard-loading-icon',
    size: '50px'
};

function filterDataByMembership(data, membershipId) {
    return data.filter((item) => item.membershipId === membershipId);
}

function valueOrDefault(value, conversionConfigWithUnit) {
    return isNullOrUndefined(value) ? null : convertToUoM(value, conversionConfigWithUnit);
}

function convertDataToUoM(data, conversionConfig) {
    const conversionConfigWithTypeAndUnit = {
        ...conversionConfig,
        dataType: data.dataType,
        dataUnit: data.dataUnits || data.dataUnit
    };

    const convertedData = {
        avgValue: valueOrDefault(data.avgValue, conversionConfigWithTypeAndUnit),
        dataValue: valueOrDefault(data.dataValue, conversionConfigWithTypeAndUnit),
        maxValue: valueOrDefault(data.maxValue, conversionConfigWithTypeAndUnit),
        minValue: valueOrDefault(data.minValue, conversionConfigWithTypeAndUnit),
        totalValue: valueOrDefault(data.totalValue, conversionConfigWithTypeAndUnit)
    };

    return {
        ...data,
        ...convertedData
    };
}

function buildDataSet(value, membership, featureToggles, membershipId, tileType) {
    const {
        daily,
        monthly,
        yearly
    } = value;

    const conversionConfig = {
        featureToggles,
        unitOfMeasure: membership.unitOfMeasure,
        unitOfMeasureOverrides: membership.unitOfMeasureOverrides
    };

    if (yearly && yearly.length) {
        return filterDataByMembership(yearly, membershipId)
            .map((data) => convertDataToUoM(data, conversionConfig));
    } else if (monthly && monthly.length) {
        return filterDataByMembership(monthly, membershipId)
            .map((data) => convertDataToUoM(data, conversionConfig));
    } else if (daily && daily.length) {
        return filterDataByMembership(daily, membershipId)
            .map((data) => {
                const dailyValues = tileType === EQUIPMENT_1 ? data : convertDataToUoM(data,
                    {
                        ...conversionConfig,
                        useNonConverted: true
                    });

                return {
                    ...dailyValues,
                    timeScale: DAY_AND_TIME
                };
            });
    }

    return [];
}

const DATASET_FILTER_CALLBACKS = {
    [BY_LOCATION]: (panelDetails, membership, featureToggles, selectedMembershipIds) => {
        const {
            byDataType,
            panelType
        } = panelDetails;

        return Object.keys(byDataType).reduce((graphData, dataType) => {
            const dataByType = byDataType[dataType];

            graphData[dataType] = selectedMembershipIds.reduce((dataSets, selectedMembershipId) => {
                const dataSet = buildDataSet(dataByType, membership, featureToggles, selectedMembershipId, panelType);

                return dataSets.concat(dataSet);
            }, []);

            return graphData;
        }, {});
    },
    [BY_TYPE]: (panelDetails, membership, featureToggles, dataTypes, selectedMembershipIds) => {
        const childMemberships = getMemberships(membership).filter(({id}) => selectedMembershipIds.includes(id));
        const {
            byDataType,
            panelType
        } = panelDetails;

        return childMemberships.reduce((graphData, childMembership) => {
            const {
                id,
                title
            } = childMembership;

            graphData[title] = dataTypes.reduce((dataSets, dataType) => {
                const dataByType = byDataType[dataType] || {};
                const dataSet = buildDataSet(dataByType, membership, featureToggles, id, panelType);

                return dataSets.concat(dataSet);
            }, []);

            return graphData;
        }, {});
    }
};

function getTileInfo(tileType) {
    const tileId = Object.keys(tileInfo).find((id) => tileInfo[id].name === tileType);

    return tileInfo[tileId];
}

function getPanelDateSelector(tileType) {
    const graphTileInfo = getTileInfo(tileType);
    const timeScale = graphTileInfo.dateSelect || 'month';

    return {
        dateSelect: formatTime(new Date(), DATE_FORMATS[timeScale]),
        timeScale
    };
}

async function fetchGraphData(dateSelect, timeScale, tileType) {
    const {
        dateSelect: dateSelectData,
        timeScale: timeScaleData
    } = getPanelDateSelector(tileType);

    const date = dateSelect || dateSelectData;

    const [panelDetails, {thresholds}] = await Promise.all([
        getPanelDetails(tileType, date, REQUEST_TIME_SCALES[timeScale || timeScaleData]),
        getPanelThresholds()
    ]);

    return {
        dateSelect: date,
        panelDetails,
        thresholds
    };
}

function getDataTypesForFilter(panelDetails, thresholds, translations) {
    if (thresholds) {
        const thresholdMap = thresholds.reduce(createDataTypeMap, new Map());

        const panelDataTypes = Object.keys(panelDetails.byDataType);
        const dataTypesForFilter = panelDataTypes.length === 0 ?
            panelDetails.categoryPanel.panelData.map((panelData) => panelData.dataType) :
            panelDataTypes;

        const dataTypeSet = new Set();

        return dataTypesForFilter.reduce((dataTypeItems, dataType) => {
            const groupedDataType = GROUPED_DATA_TYPE_OVERRIDES[dataType] || dataType;
            const thresholdKey = DATA_TYPE_OVERRIDES[dataType] || groupedDataType;

            if (!dataTypeSet.has(thresholdKey) && thresholdMap.has(thresholdKey)) {
                dataTypeSet.add(thresholdKey);

                const {dataLabel} = thresholdMap.get(thresholdKey);

                dataTypeItems.push({
                    id: groupedDataType,
                    title: translations[dataLabel]
                });
            }

            return dataTypeItems;
        }, []);
    }

    return [];
}

function buildData(timeScale, panelDetails, primarySelector, membership, featureToggles, ...args) {
    const graphDataCallback = DATASET_FILTER_CALLBACKS[primarySelector];

    return {
        categoryPanel: panelDetails.categoryPanel,
        graphData: graphDataCallback(panelDetails, membership, featureToggles, ...args),
        timeScale: timeScale || getPanelDateSelector(panelDetails.panelType).timeScale
    };
}

function getMemoizedData({
    featureToggles,
    membership,
    panelDetails,
    primarySelector,
    secondarySelector,
    tertiarySelectors,
    timeScale
}) {
    const groupedTertiarySelectors = useDeepMemo(() => tertiarySelectors.reduce((allTertiarySelectors, tertiarySelector) => {
        const groupedTertiarySelector = GROUPED_DATA_TYPES[tertiarySelector] || tertiarySelector;

        return allTertiarySelectors.concat(groupedTertiarySelector);
    }, []), [tertiarySelectors]);

    const data = useDeepMemo(() => {
        if (panelDetails.byDataType && panelDetails.categoryPanel) {
            const groupedSecondarySelectors = GROUPED_DATA_TYPES[secondarySelector] || [secondarySelector];

            return buildData(timeScale, panelDetails, primarySelector, membership, featureToggles,
                groupedSecondarySelectors, groupedTertiarySelectors);
        }

        return {};
    }, [panelDetails, primarySelector, secondarySelector, groupedTertiarySelectors]);

    return {
        data,
        groupedTertiarySelectors
    };
}

function transformAndFilterData({
    data,
    primarySelector,
    tertiarySelectors,
    thresholds,
    type
}) {
    const transformedData = transformData(data.graphData, data.categoryPanel.panelData, thresholds, type);
    const transformedDataKeys = Object.keys(transformedData);

    const filteredData = primarySelector === BY_LOCATION ?
        transformedDataKeys.filter((key) => tertiarySelectors.includes(key)) :
        transformedDataKeys.filter((key) => tertiarySelectors.includes(transformedData[key].membershipId));

    return filteredData.reduce((reducedData, key) => {
        reducedData[key] = transformedData[key];

        return reducedData;
    }, {});
}

function getGraphTitle(tileType, title) {
    const {graphTitle} = getTileInfo(tileType);

    return graphTitle || title;
}

function hasData(graphData) {
    return Object.keys(graphData).some((key) => graphData[key].data.length > 0);
}

function initializeStateAndRefs() {
    const [allData, setAllData] = React.useState(() => ({
        dataTypes: [],
        dateSelect: undefined,
        panelDetails: {},
        thresholds: []
    }));
    const [loading, setLoading] = React.useState(true);
    const [primarySelector, setPrimarySelector] = React.useState(BY_LOCATION);
    const [secondarySelector, setSecondarySelector] = React.useState(null);
    const [tertiarySelectors, setTertiarySelectors] = React.useState([]);

    return {
        allData,
        loading,
        primarySelector,
        secondarySelector,
        setAllData,
        setLoading,
        setPrimarySelector,
        setSecondarySelector,
        setTertiarySelectors,
        tertiarySelectors,
        timeScale: React.useRef()
    };
}

function GraphPanel(props) {
    const {
        featureToggles,
        match: {params: {tileType}},
        membership,
        translations
    } = props;

    const {
        allData,
        loading,
        primarySelector,
        secondarySelector,
        setAllData,
        setLoading,
        setPrimarySelector,
        setSecondarySelector,
        setTertiarySelectors,
        tertiarySelectors,
        timeScale
    } = initializeStateAndRefs();

    const updateDateFilter = React.useCallback(async (selectedDateSelect, selectedTimeScale, isMounted = () => true) => {
        setLoading(true);

        const {
            dateSelect: dateSelectData,
            panelDetails: panelDetailsData,
            thresholds: thresholdsData
        } = await fetchGraphData(selectedDateSelect, selectedTimeScale, tileType);

        if (isMounted()) {
            timeScale.current = selectedTimeScale;

            const dataTypesForFilter = getDataTypesForFilter(panelDetailsData, thresholdsData, translations);

            setAllData({
                dataTypes: dataTypesForFilter,
                dateSelect: dateSelectData,
                panelDetails: panelDetailsData,
                thresholds: thresholdsData
            });
            if (selectedDateSelect) {
                setLoading(false);
            }
        }
    }, [tileType, translations]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        await updateDateFilter(allData.dateSelect, timeScale.current, isMounted);
    }), [membership.membershipId]);

    const {
        data,
        groupedTertiarySelectors
    } = getMemoizedData({
        featureToggles,
        membership,
        panelDetails: allData.panelDetails,
        primarySelector,
        secondarySelector,
        tertiarySelectors,
        timeScale: timeScale.current
    });

    if (!data.categoryPanel) {
        return (
            <LoadingIcon {...LOADING_ICON_PROPS}/>
        );
    }

    const [chartData, dataTableData] = DATA_TYPES
        .map((dataType) => transformAndFilterData({
            data,
            primarySelector,
            tertiarySelectors: groupedTertiarySelectors,
            thresholds: allData.thresholds,
            type: dataType
        }));

    const className = clsx('graph-panel-wrapper', {
        hidden: loading
    });

    return (
        <LoadingWrapper
            {...LOADING_ICON_PROPS}
            alwaysRenderChildren={true}
            loading={loading}
        >
            <Panel
                className={className}
                title={getGraphTitle(tileType, data.categoryPanel.panelTitle)}
                translations={translations}
            >
                <GraphFilters
                    dataTypes={allData.dataTypes}
                    membership={membership}
                    primarySelector={primarySelector}
                    secondarySelector={secondarySelector}
                    tertiarySelectors={tertiarySelectors}
                    tileType={tileType}
                    timeScale={data.timeScale}
                    translations={translations}
                    updateDateFilter={updateDateFilter}
                    updatePrimarySelector={setPrimarySelector}
                    updateSecondarySelector={setSecondarySelector}
                    updateTertiarySelector={setTertiarySelectors}
                />
                <NoDataMessage
                    hasData={hasData(chartData) && hasData(dataTableData)}
                    noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
                >
                    <div className='chart-container'>
                        <LineChart
                            chartData={chartData}
                            featureToggles={featureToggles}
                            membership={membership}
                            timeScale={data.timeScale}
                            translations={translations}
                        />
                    </div>
                    <GraphDataTable
                        dateSelect={allData.dateSelect}
                        featureToggles={featureToggles}
                        membership={membership}
                        onManualDataUpdate={() => updateDateFilter(allData.dateSelect, timeScale.current)}
                        panelType={data.categoryPanel.panelType}
                        primarySelector={primarySelector}
                        tableData={dataTableData}
                        timeScale={data.timeScale}
                        translations={translations}
                    />
                </NoDataMessage>
            </Panel>
        </LoadingWrapper>
    );
}

GraphPanel.propTypes = {
    featureToggles: PropTypes.featureToggles,
    match: PropTypes.match,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership
    };
}

export default connect(mapStateToProps)(withRouter(GraphPanel));
