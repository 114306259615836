// Unpublished Work © 2020-2024 Deere & Company.

import equipmentTiles from 'Ui/components/common/tile-constants/tiles/equipment';
import fertChemTiles from 'Ui/components/common/tile-constants/tiles/fert-chem';
import homeTiles from 'Ui/components/common/tile-constants/tiles/home';
import laborTiles from 'Ui/components/common/tile-constants/tiles/labor';
import roundsTiles from 'Ui/components/common/tile-constants/tiles/rounds';
import waterEnergyTiles from 'Ui/components/common/tile-constants/tiles/water-energy';
import weatherCourseTiles from 'Ui/components/common/tile-constants/tiles/weather-course';

export default Object.assign({},
    equipmentTiles,
    fertChemTiles,
    homeTiles,
    laborTiles,
    roundsTiles,
    waterEnergyTiles,
    weatherCourseTiles
);
