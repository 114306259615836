// Unpublished Work © 2022-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function deleteTodoTemplate(todoTemplateId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete('/api/todoTemplates', {
        params: {
            todoTemplateId
        }
    });

    return response.data;
}

async function createTodoTemplate(todoTemplateData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/todoTemplates', todoTemplateData);

    return response.data;
}

async function updateTodoTemplate(todoTemplate, todoTemplateId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/todoTemplates/${todoTemplateId}`, todoTemplate);

    return response.data;
}

async function createTodo(todoData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/todos', todoData);

    return response.data;
}

async function updateTodo(todoData, todoId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/todos/${todoId}`, todoData);

    return response.data;
}

async function deleteTodo(todoId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/todos/${todoId}`);

    return response.data;
}

export {
    deleteTodoTemplate,
    createTodoTemplate,
    updateTodoTemplate,
    createTodo,
    updateTodo,
    deleteTodo
};
