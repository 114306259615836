// Unpublished Work © 2022-2024 Deere & Company.

const MEMBERSHIP_SETTINGS = 'Membership';
const USER_SETTINGS = 'Users';
const NOTIFICATION_SETTINGS = 'Notifications';
const LABOR_SETTINGS = 'Labor';
const PARTNER_SETTINGS = 'Partners';
const MYJOHNDEERE_SETTINGS = 'my_john_deere';
const FLEET_SETTINGS = 'Fleet';

export default function getConstants() {
    /* eslint-enable no-magic-numbers */
    return {
        tilePositions: [
            {
                tile: {
                    id: MEMBERSHIP_SETTINGS,
                    name: MEMBERSHIP_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 0,
                    y: 0,
                    w: 1,
                    h: 1,
                    i: MEMBERSHIP_SETTINGS
                }
            },
            {
                tile: {
                    id: LABOR_SETTINGS,
                    name: LABOR_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 0,
                    y: 1,
                    w: 1,
                    h: 1,
                    i: LABOR_SETTINGS
                }
            },
            {
                tile: {
                    id: MYJOHNDEERE_SETTINGS,
                    name: MYJOHNDEERE_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 0,
                    y: 2,
                    w: 1,
                    h: 1,
                    i: MYJOHNDEERE_SETTINGS
                }
            },
            {
                tile: {
                    id: USER_SETTINGS,
                    name: USER_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 1,
                    y: 0,
                    w: 1,
                    h: 1,
                    i: USER_SETTINGS
                }
            },
            {
                tile: {
                    id: FLEET_SETTINGS,
                    name: FLEET_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 1,
                    y: 1,
                    w: 1,
                    h: 1,
                    i: FLEET_SETTINGS
                }
            },
            {
                tile: {
                    id: NOTIFICATION_SETTINGS,
                    name: NOTIFICATION_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 2,
                    y: 0,
                    w: 1,
                    h: 1,
                    i: NOTIFICATION_SETTINGS
                }
            },
            {
                tile: {
                    id: PARTNER_SETTINGS,
                    name: PARTNER_SETTINGS
                },
                columnCount: 3,
                position: {
                    x: 2,
                    y: 1,
                    w: 1,
                    h: 1,
                    i: PARTNER_SETTINGS
                }
            },
            {
                tile: {
                    id: MEMBERSHIP_SETTINGS,
                    name: MEMBERSHIP_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 0,
                    y: 0,
                    w: 1,
                    h: 1,
                    i: MEMBERSHIP_SETTINGS
                }
            },
            {
                tile: {
                    id: NOTIFICATION_SETTINGS,
                    name: NOTIFICATION_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 0,
                    y: 1,
                    w: 1,
                    h: 1,
                    i: NOTIFICATION_SETTINGS
                }
            },
            {
                tile: {
                    id: FLEET_SETTINGS,
                    name: FLEET_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 0,
                    y: 2,
                    w: 1,
                    h: 1,
                    i: FLEET_SETTINGS
                }
            },
            {
                tile: {
                    id: MYJOHNDEERE_SETTINGS,
                    name: MYJOHNDEERE_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 0,
                    y: 3,
                    w: 1,
                    h: 1,
                    i: MYJOHNDEERE_SETTINGS
                }
            },
            {
                tile: {
                    id: USER_SETTINGS,
                    name: USER_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 1,
                    y: 0,
                    w: 1,
                    h: 1,
                    i: USER_SETTINGS
                }
            },
            {
                tile: {
                    id: LABOR_SETTINGS,
                    name: LABOR_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 1,
                    y: 1,
                    w: 1,
                    h: 1,
                    i: LABOR_SETTINGS
                }
            },
            {
                tile: {
                    id: PARTNER_SETTINGS,
                    name: PARTNER_SETTINGS
                },
                columnCount: 2,
                position: {
                    x: 1,
                    y: 2,
                    w: 1,
                    h: 1,
                    i: PARTNER_SETTINGS
                }
            },
            {
                tile: {
                    id: MEMBERSHIP_SETTINGS,
                    name: MEMBERSHIP_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 0,
                    w: 1,
                    h: 1,
                    i: MEMBERSHIP_SETTINGS
                }
            },
            {
                tile: {
                    id: USER_SETTINGS,
                    name: USER_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 1,
                    w: 1,
                    h: 1,
                    i: USER_SETTINGS
                }
            },
            {
                tile: {
                    id: NOTIFICATION_SETTINGS,
                    name: NOTIFICATION_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 2,
                    w: 1,
                    h: 1,
                    i: NOTIFICATION_SETTINGS
                }
            },
            {
                tile: {
                    id: LABOR_SETTINGS,
                    name: LABOR_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 3,
                    w: 1,
                    h: 1,
                    i: LABOR_SETTINGS
                }
            },
            {
                tile: {
                    id: FLEET_SETTINGS,
                    name: FLEET_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 4,
                    w: 1,
                    h: 1,
                    i: FLEET_SETTINGS
                }
            },
            {
                tile: {
                    id: PARTNER_SETTINGS,
                    name: PARTNER_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 5,
                    w: 1,
                    h: 1,
                    i: PARTNER_SETTINGS
                }
            },
            {
                tile: {
                    id: MYJOHNDEERE_SETTINGS,
                    name: MYJOHNDEERE_SETTINGS
                },
                columnCount: 1,
                position: {
                    x: 0,
                    y: 6,
                    w: 1,
                    h: 1,
                    i: MYJOHNDEERE_SETTINGS
                }
            }
        ]
    };
}
