// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import DataTable from 'Ui/components/common/data-table/data-table';
import LoadingIcon from 'Ui/components/common/loading-icon';
import MyJohnDeereLogin from 'Ui/components/settings/myJohnDeere/myJohnDeere-login';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {MY_JOHN_DEERE} from 'Ui/constants/replace-constants';
import Box from '@mui/material/Box';

function MyJohnDeereDataTable(props) {
    const {
        hasMyJDSession,
        hasMyJDUser,
        loading,
        translations,
        ...tableProps
    } = props;

    if (loading) {
        return (
            <div className='table-scroll'>
                <LoadingIcon
                    className='table-loading-icon center-loading-icon'
                    size='50px'
                />
            </div>
        );
    }

    if (hasMyJDUser && !hasMyJDSession) {
        return (
            <div className='linked-account-body'>
                <MyJohnDeereLogin translations={translations}/>
            </div>
        );
    }

    if (!hasMyJDUser) {
        const noDataMessage = replaceTranslationNames(translations.ONLINK_USER_NOT_LINKED_TO_MYJOHNDEERE, {
            '0': MY_JOHN_DEERE
        });

        return (
            <Box width='100%'>
                <NoDataMessage noDataMessage={noDataMessage}/>
            </Box>
        );
    }

    return (
        <DataTable
            {...tableProps}
            translations={translations}
        />
    );
}

MyJohnDeereDataTable.propTypes = {
    hasMyJDSession: PropTypes.bool,
    hasMyJDUser: PropTypes.bool,
    loading: PropTypes.bool,
    translations: PropTypes.translations
};
export default MyJohnDeereDataTable;
