// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {DatePicker, MultiSelect} from '@deere/isg.component-library';
import {useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import moment from 'moment';

const MONTH_AND_YEAR = ['month', 'year'];

function getYearlyItems(numYears) {
    return Array.from({
        length: numYears
    }, (x, yearIndex) => {
        const year = moment().subtract(yearIndex, 'years').format(DATE_FORMATS.year);

        return {
            id: year,
            title: year
        };
    });
}

function getMonthlyItems(numMonths, translations) {
    return Array.from({
        length: numMonths
    }, (x, monthIndex) => {
        const date = moment().subtract(monthIndex, 'months');
        const month = date.format('MMMM');
        const year = date.format(DATE_FORMATS.year);

        return {
            id: date.format(DATE_FORMATS.month),
            title: `${translations[`months_${month}`]} ${year}`
        };
    });
}

function getDateItems(timeScale, numMonths, numYears, translations) {
    switch (timeScale) {
        case 'month':
            return getMonthlyItems(numMonths, translations);
        case 'year':
            return getYearlyItems(numYears);
        default:
            return [];
    }
}

function GraphDateFilter(props) {
    const {
        handler,
        numMonths,
        numYears,
        setTitleDate,
        timeScale,
        timeScaleItems,
        translations
    } = props;

    const [selectedTimeScale, setSelectedTimeScale] = React.useState(timeScale);

    const dateItems = useDeepMemo(
        () => getDateItems(selectedTimeScale, numMonths, numYears, translations),
        [numMonths, numYears, selectedTimeScale, translations]
    );

    const [datePickerDay, setDatePickerDay] = React.useState(() => {
        const now = new Date();

        now.setHours(0, 0, 0, 0);

        return now;
    });
    const [selectedDateId, setSelectedDateId] = React.useState(() => {
        const firstDateItem = dateItems[0];

        return firstDateItem ? firstDateItem.id : '';
    });

    const isDailyTimeScale = selectedTimeScale === 'day';

    useDeepEffect(() => {
        const dateFormat = DATE_FORMATS[selectedTimeScale];
        const isValidDateForTimeScale = dateFormat && (isDailyTimeScale || selectedDateId.length === dateFormat.length);

        if (isValidDateForTimeScale) {
            const date = isDailyTimeScale ? datePickerDay : selectedDateId;

            if (setTitleDate) {
                const newTitleDate = isDailyTimeScale ?
                    datePickerDay.toDateString() :
                    dateItems.find(({id}) => id === selectedDateId)?.title;

                setTitleDate(newTitleDate);
            }

            const dateSelect = formatTime(date, dateFormat);

            handler(dateSelect, selectedTimeScale);
        } else if (!dateFormat) {
            handler('', selectedTimeScale);
        }
    }, [datePickerDay, selectedDateId, selectedTimeScale]);

    const handleTimeScaleChange = React.useCallback(([newTimeScale]) => {
        const now = moment();

        if (!now.isSame(datePickerDay, 'day')) {
            setDatePickerDay(now.toDate());
        }

        if (MONTH_AND_YEAR.includes(newTimeScale)) {
            const newSelectedDate = now.format(DATE_FORMATS[newTimeScale]);

            setSelectedDateId(newSelectedDate);
        }

        setSelectedTimeScale(newTimeScale);
    }, [datePickerDay]);

    const onDayChange = React.useCallback(([newDate]) => {
        if (newDate.getTime() !== datePickerDay.getTime()) {
            setDatePickerDay(newDate);
        }
    }, [datePickerDay]);

    return (
        <>
            <MultiSelect
                disableSorting={true}
                items={timeScaleItems}
                labels={{}}
                noSearch={true}
                onChange={handleTimeScaleChange}
                selectedIds={[selectedTimeScale]}
                single={true}
                width={200}
            />
            {
                MONTH_AND_YEAR.includes(selectedTimeScale) &&
                <MultiSelect
                    disableSorting={true}
                    items={dateItems}
                    labels={{}}
                    noSearch={true}
                    onChange={([newSelectedDateId]) => setSelectedDateId(newSelectedDateId)}
                    selectedIds={[selectedDateId]}
                    single={true}
                    width={200}
                />
            }
            {
                isDailyTimeScale &&
                <DatePicker
                    InputProps={{
                        label: translations.DATE,
                        labelVisuallyHidden: true
                    }}
                    maxDate={new Date()}
                    onChange={onDayChange}
                    value={datePickerDay}
                />
            }
        </>
    );
}

GraphDateFilter.propTypes = {
    handler: PropTypes.func,
    numMonths: PropTypes.number,
    numYears: PropTypes.number,
    setTitleDate: PropTypes.func,
    timeScale: PropTypes.string,
    timeScaleItems: PropTypes.multiSelectList,
    translations: PropTypes.translations
};

export default GraphDateFilter;
