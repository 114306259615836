// Unpublished Work © 2021-2024 Deere & Company.

export default {
    completed: {
        id: 'completed',
        title: 'Completed'
    },
    declined: {
        color: 'orange',
        id: 'declined',
        title: 'Declined'
    },
    'in_process': {
        color: 'red',
        id: 'in_process',
        title: 'In Process'
    },
    pending: {
        id: 'pending',
        title: 'Pending'
    },
    recommended: {
        color: 'orange',
        id: 'recommended',
        title: 'Recommended'
    },
    scheduled: {
        color: 'green',
        id: 'scheduled',
        title: 'Scheduled'
    },
    skipped: {
        id: 'skipped',
        title: 'Skipped'
    },
    'work_item': {
        color: 'green',
        id: 'work_item',
        title: 'Each Use'
    }
};
