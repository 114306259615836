// Unpublished Work © 2023-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createManufacturer(manufacturerData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/manufacturers', manufacturerData);

    return response.data;
}

async function deleteManufacturer(manufacturerId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/manufacturers/${manufacturerId}`);

    return response.data;
}

async function updateManufacturer(manufacturerId, manufacturerData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/manufacturers/${manufacturerId}`, manufacturerData);

    return response.data;
}

export {
    createManufacturer,
    deleteManufacturer,
    updateManufacturer
};
