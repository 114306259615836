// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function GridColumns(props) {
    const {
        columnCount,
        columns,
        tiles
    } = props;

    return Array.from({
        length: columnCount
    }, (x, i) => {
        const column = columns[i] || [];

        return (
            <div
                className='grid-column'
                key={i}
            >
                {column.map((row) => tiles[row.tileId] || tiles[row.tileType])}
            </div>
        );
    });
}

GridColumns.propTypes = {
    columnCount: PropTypes.number,
    columns: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.position)),
    tiles: PropTypes.objectOf(PropTypes.element)
};

export default GridColumns;
