// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnLinkDialogContentBar from 'Ui/components/common/onlink-dialog/onlink-dialog-content-bar';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {Input} from '@deere/form-controls';
import {IconDelete} from '@deere/icons';
import {ReactComponent as DuplicateIcon} from '@deere/ux.brand-foundations/icons/duplicate.svg';

const ICON_DELETE_STYLE = {
    style: {
        display: 'inline',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#2677bb');

function EditorContentHeader(props) {
    const iconDelete = (
        <IconDelete
            iconDelete={ICON_DELETE_STYLE}
            primary={PRIMARY_STYLE}
        />
    );

    const deleteButton = !props.isDefaultLayout && !props.isNew && (
        <OnlinkButton
            className='delete'
            leftIcon={iconDelete}
            onClick={props.onDelete}
        >
            {props.translations.DELETE}
        </OnlinkButton>
    );

    const copyButton = !props.isNew && (
        <OnlinkButton
            className='copy'
            leftIcon={<DuplicateIcon className='duplicate-icon'/>}
            onClick={props.onCopy}
        >
            {props.translations.CREATE_COPY}
        </OnlinkButton>
    );

    return (
        <OnLinkDialogContentBar className='editor-content-header'>
            <div className='input-container'>
                <Input
                    disabled={props.isDefaultLayout}
                    name='dashboard-name'
                    onChange={props.onLayoutNameChange}
                    placeholder={props.translations.DASHBOARD_NAME}
                    tabIndex={0}
                    type='text'
                    value={props.name}
                />
                {copyButton}
            </div>
            {deleteButton}
        </OnLinkDialogContentBar>
    );
}

EditorContentHeader.propTypes = {
    isDefaultLayout: PropTypes.bool,
    isNew: PropTypes.bool,
    name: PropTypes.string,
    onCopy: PropTypes.func,
    onDelete: PropTypes.func,
    onLayoutNameChange: PropTypes.func,
    translations: PropTypes.translations
};

export default EditorContentHeader;
