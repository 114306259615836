// Unpublished Work © 2022-2024 Deere & Company.

import {createPpeIconMap} from 'Utils/icon-utils';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {PRESENTATION_PROPERTIES} from 'Ui/constants/workboard-constants';
import {getProfilePic} from 'Services/user-service';
import {getBase64DataURL} from 'Utils/html-utils';
import React from 'react';
import DetailsBlock from 'OnLabor/my-work/my-work-tile/details-block';

const ICON_STYLE = {
    style: {
        height: '32px',
        width: '32px',
        marginRight: '5px',
        fill: '#ffffff'
    }
};

const PRIMARY_ICON_STYLE = {
    style: {
        fill: '#ffffff'
    }
};

const MINUTES_PER_HOUR_FLOAT = 60.0;

const PPE_ICON_MAP = createPpeIconMap(ICON_STYLE, PRIMARY_ICON_STYLE);

function getWorkItemsPerColumn(workItems, columnCount) {
    const itemsPerRow = Object.keys(workItems).length / columnCount;

    return Object.keys(workItems).reduce((workItemsPerColumn, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerRow);

        if (!workItemsPerColumn[chunkIndex]) {
            workItemsPerColumn[chunkIndex] = [];
        }

        workItemsPerColumn[chunkIndex].push(item);

        return workItemsPerColumn;
    }, []);
}

function getElementScrollY(elem) {
    return elem.scrollTop;
}

async function getFormattedProfilePic(profilePicUrl) {
    const {
        imageData, extension
    } = await getProfilePic(profilePicUrl);

    return imageData ? getBase64DataURL(`image/${extension}`, imageData) : null;
}

async function getAllWorkItems(jobs, translations, properties) {
    const {
        showRoute,
        showPPEIcon,
        showEstimatedHours,
        showEmployeeImage
    } = properties;

    const workItems = [];

    for (const job of jobs) {
        for (const workItem of job.workItems) {
            /* eslint-disable max-depth */
            if (workItem.appUser) {
                let route = null,
                    ppeList = [];

                if (showRoute && workItem.route) {
                    route = workItem.routeCustomDefn?.length > 0 ?
                        `${translations.ONLINK_ROUTE}: ${workItem.route} - ${translations.ONLINK_HOLES} ${workItem.routeCustomDefn.join(', ')}` :
                        `${translations.ONLINK_ROUTE}: ${workItem.route}`;
                }

                if (showPPEIcon) {
                    ppeList = job.jobTemplate?.ppeList;
                }

                const formattedEquipmentNames =
                    workItem.equipment?.filter(({equipmentName}) => equipmentName).map((equipment) =>
                        `${equipment.equipmentName} (${equipment.manufacturerName} ${equipment.modelName})`
                    );

                workItems.push({
                    ...workItem,
                    appUserName: `${workItem.appUser.firstName} ${workItem.appUser.lastName}`,
                    appUserId: workItem.appUser.appUserId,
                    color: workItem.appUser.color,
                    estHours: showEstimatedHours ? formatNumber(workItem.estDuration / MINUTES_PER_HOUR_FLOAT, {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1
                    }) : null,
                    equipment: workItem.equipment,
                    formattedEquipmentNames,
                    jobId: job.jobId,
                    jobTemplateId: job.jobTemplate?.jobTemplateId,
                    note: workItem.note,
                    ppeList,
                    profilePicUrl: showEmployeeImage ?
                        getFormattedProfilePic(workItem.appUser.profilePicUrl) :
                        null,
                    route,
                    secondaryName: job.jobTemplate?.secondaryName,
                    title: job.title,
                    workItemId: workItem.workItemId
                });
            }
            /* eslint-enable max-depth */
        }
    }

    return showEmployeeImage ?
        await Promise.all(workItems.map(
            (workItem) => workItem.profilePicUrl.then((profilePicUrl) => ({
                ...workItem,
                profilePicUrl
            })))
        ) : workItems;
}

function getPresentationSettings(membership) {
    const grouping = membership.properties[PRESENTATION_PROPERTIES.grouping];
    const columnCount = membership.properties[PRESENTATION_PROPERTIES.columnCount];
    const fontSize = membership.properties[PRESENTATION_PROPERTIES.fontSize];
    const showEstimatedHours = membership.properties[PRESENTATION_PROPERTIES.showEstimatedHours] === 'true';
    const showPPE = membership.properties[PRESENTATION_PROPERTIES.showPPE] === 'true';
    const tileOpacity = membership.properties[PRESENTATION_PROPERTIES.tileOpacity] === 'true' ? 'opacity-on' : 'opacity-off';
    const showRoute = membership.properties[PRESENTATION_PROPERTIES.showRoute] === 'true';
    const showEmployeeGroupColor = membership.properties[PRESENTATION_PROPERTIES.showEmployeeGroupColor] === 'true';
    const showEmployeeImage = membership.properties[PRESENTATION_PROPERTIES.showEmployeeImage] === 'true';

    return {
        grouping,
        columnCount,
        fontSize,
        showEstimatedHours,
        showPPE,
        tileOpacity,
        showRoute,
        showEmployeeGroupColor,
        showEmployeeImage
    };
}

function getPresentationUrl(membershipId) {
    const {onLinkLegacyUri: baseUri} = window.props;

    return `${baseUri}/labor/workboard/presentation/${membershipId}`;
}

function getWorkItemEquipmentDetails(equipment) {
    return equipment?.map((equipment, index) => (
        <div
            className='workitem-equipment-details'
            key={index}
        >
            {`${equipment?.equipmentName} (${equipment?.manufacturerName} ${equipment?.modelName})`}
        </div>
    ));
}

function getSharedWorkItemDetails(workItem, showPPE, showSecondaryName) {
    const equipmentList = getWorkItemEquipmentDetails(workItem.equipment);
    const hasSecondaryName = Boolean(workItem.secondaryName);

    return (
        <>
            {
                hasSecondaryName && showSecondaryName &&
                <div>{`(${workItem.secondaryName})`}</div>
            }
            {equipmentList}
            {workItem.route && <div>{workItem.route}</div>}
            {workItem.note && <div
                style={{
                    whiteSpace: 'pre-line'
                }}
            >{workItem.note}</div>}
            {
                showPPE && workItem.ppeList?.length > 0 &&
                <DetailsBlock>
                    {
                        workItem.ppeList?.map((ppeName) => PPE_ICON_MAP[ppeName])
                    }
                </DetailsBlock>
            }
        </>
    );
}

export {
    getElementScrollY,
    getWorkItemsPerColumn,
    getAllWorkItems,
    getPresentationSettings,
    getPresentationUrl,
    getSharedWorkItemDetails
};
