// Unpublished Work © 2023-2024 Deere & Company.

export const UNITS = {
    basket: 'B',
    celsius: '°C',
    cm: 'cm',
    dollars: '$',
    ec: 'dS/m',
    fahrenheit: '°F',
    feet: '\'',
    gallons: 'gal',
    hours: 'hrs',
    inches: '"',
    kilowattHour: 'kWh',
    liters: 'l',
    mm: 'mm',
    oneKGallons: 'kGal',
    ounces: 'oz',
    percent: '%',
    pounds: 'lb',
    quarts: 'qt',
    meters: 'm',
    gravities: 'Gravities',
    f355: 'F355'
};

export const UNIT_CONFIG = {
    METERS: {
        decimalPlaces: 2,
        suffix: UNITS.meters,
        includeSpace: true
    },
    FEET_AND_INCHES: {
        dataUnits: [{
            decimalPlaces: 0,
            suffix: UNITS.feet
        }, {
            decimalPlaces: 0,
            suffix: UNITS.inches
        }],
        includeSpaceBetweenDataUnits: true,
        conversionFactors: {
            feet: 12,
            inches: 1
        }
    },
    FEET_AND_INCHES_GRAPH: {
        decimalPlaces: 2,
        suffix: UNITS.feet
    },
    GRAVITIES: {
        decimalPlaces: 2,
        suffix: UNITS.gravities,
        includeSpace: true
    },
    F355: {
        decimalPlaces: 2,
        suffix: UNITS.f355,
        includeSpace: true
    }
};
