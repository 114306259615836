// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect} from '@deere/isg.component-library';

function PartsMultiSelect(props) {
    const {
        currencyPreference,
        disabled,
        featureToggles,
        filteredParts,
        footerAction,
        hideFinancialData,
        onPartSelect,
        partsInputComponent: PartsInputComponent,
        readOnly,
        selectedParts,
        setPartRequestsToRemove,
        setSelectedParts,
        setValid,
        translations,
        selectedPartsClassName
    } = props;

    function onChange(name, value, partToChange) {
        partToChange[name] = value;

        setSelectedParts([...selectedParts]);
    }

    function onClear(part) {
        const filteredSelectedParts = selectedParts.filter(({id}) => id !== part.id);

        setSelectedParts(filteredSelectedParts);
    }

    const selectedPartComponents = React.useMemo(() => selectedParts.map((part) => (
        <PartsInputComponent
            currencyPreference={currencyPreference}
            disabled={disabled}
            featureToggles={featureToggles}
            hideFinancialData={hideFinancialData}
            key={part.id}
            onChange={onChange}
            onClear={onClear}
            part={part}
            readOnly={readOnly}
            setPartRequestsToRemove={setPartRequestsToRemove}
            setValid={setValid}
            translations={translations}
        />
    )), [selectedParts]);

    const partLabel = React.useMemo(() => selectedParts.length > 0 ? (
        <div className='part-area-label'>
            {translations.ONLINK_PART}
        </div>
    ) : null, [selectedParts]);

    return (
        <div className='parts-multi-select'>
            {partLabel}
            <div className={selectedPartsClassName}>
                {selectedPartComponents}
            </div>
            {
                !readOnly &&
                <div className='settings-group mobile'>
                    <MultiSelect
                        autoFocus={true}
                        disabled={disabled}
                        enableVirtualization
                        footerAction={footerAction}
                        items={filteredParts}
                        label={translations.ONLINK_ADD_PART}
                        labels={{
                            emptyList: translations.ONLINK_NO_PARTS,
                            placeholder: translations.ONLINK_PARTS_SEARCH
                        }}
                        onChange={onPartSelect}
                        selectedIds={[]}
                        single={true}
                    />
                </div>
            }
        </div>
    );
}

PartsMultiSelect.propTypes = {
    currencyPreference: PropTypes.string,
    disabled: PropTypes.bool,
    featureToggles: PropTypes.featureToggles,
    filteredParts: PropTypes.arrayOf(PropTypes.object),
    footerAction: PropTypes.object,
    hideFinancialData: PropTypes.bool,
    onPartSelect: PropTypes.func,
    partsInputComponent: PropTypes.elementType,
    readOnly: PropTypes.bool,
    selectedParts: PropTypes.arrayOf(PropTypes.object),
    selectedPartsClassName: PropTypes.string,
    setPartRequestsToRemove: PropTypes.func,
    setSelectedParts: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default PartsMultiSelect;
