// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';

function UpsertEquipmentForm(props) {
    const {
        equipmentName,
        modelItems,
        shouldDisplayModelSelector,
        selectedModel,
        serialNumber,
        setEquipmentName,
        setSerialNumber,
        setSelectedModel,
        setValid,
        translations
    } = props;

    const requiredInputError = {
        valueMissing: translations.REQUIRED_FIELD_TEXT
    };

    return (
        <form className='equipment-dialog-form'>
            {
                shouldDisplayModelSelector && <div className='settings-block-row'>
                    <ValidationMultiSelect
                        className='multiselect'
                        component={SortedMultiSelect}
                        containerHeight={300}
                        defaultSelectLabel={translations.ONLINK_SELECT_MODEL}
                        dropUpThreshold={350}
                        error={translations.REQUIRED_FIELD_TEXT}
                        items={modelItems}
                        label={translations.MODEL}
                        multiple={false}
                        name='selectedModel'
                        onChange={(selectedIds) => setSelectedModel(selectedIds[0])}
                        selectedIds={[selectedModel]}
                        setValid={setValid}
                        tabIndex={0}
                    />
                </div>
            }
            <div className='settings-group mobile'>
                <ValidationInput
                    errors={requiredInputError}
                    label={translations.NAME}
                    name='equipmentName'
                    onChange={(e) => setEquipmentName(e.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    value={equipmentName}
                />
                <ValidationInput
                    errors={requiredInputError}
                    label={translations.ONLINK_SERIAL_NUMBER}
                    name='serialNumber'
                    onChange={(e) => setSerialNumber(e.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    value={serialNumber}
                />
            </div>
        </form>
    );
}

UpsertEquipmentForm.propTypes = {
    equipmentName: PropTypes.string,
    modelItems: PropTypes.array,
    selectedModel: PropTypes.object,
    serialNumber: PropTypes.string,
    setEquipmentName: PropTypes.func,
    setSelectedModel: PropTypes.func,
    setSerialNumber: PropTypes.func,
    setValid: PropTypes.func,
    shouldDisplayModelSelector: PropTypes.bool,
    translations: PropTypes.translations
};

export default UpsertEquipmentForm;
