// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DashboardLayout from 'Ui/components/dashboard/dashboard-layout';
import drillInPositions from 'Ui/components/common/tile-constants/drill-in-positions';
import {
    setActiveLayout as setActiveLayoutAction,
    setAvailableLayouts as setAvailableLayoutsAction
} from 'Store/actions/account';
import {getUserLayouts} from 'Services/user-view-service';
import {fetchEffectData} from 'Ui/utils/react-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function Dashboard(props) {
    const {
        activeLayout,
        appUserId,
        match: {params: {type}},
        setActiveLayout,
        setAvailableLayouts,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (!type) {
            setLoading(true);

            const {
                availableLayouts,
                activeLayout
            } = await getUserLayouts(translations);

            setAvailableLayouts(availableLayouts);
            setActiveLayout(activeLayout);
        }

        if (isMounted()) {
            setLoading(false);
        }
    }), [appUserId]);

    const layout = React.useMemo(
        () => drillInPositions[type] || activeLayout,
        [activeLayout, type]
    );

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            <DashboardLayout
                layout={layout}
                translations={translations}
            />
        </LoadingWrapper>
    );
}

Dashboard.propTypes = {
    activeLayout: PropTypes.layout,
    appUserId: PropTypes.string,
    availableLayouts: PropTypes.arrayOf(PropTypes.layout),
    match: PropTypes.match,
    setActiveLayout: PropTypes.func,
    setAvailableLayouts: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        activeLayout: state.account.activeLayout,
        appUserId: state.account.appUserId,
        availableLayouts: state.account.availableLayouts
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setActiveLayout(layout) {
            dispatch(setActiveLayoutAction(layout));
        },
        setAvailableLayouts(layouts) {
            dispatch(setAvailableLayoutsAction(layouts));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
