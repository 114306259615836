// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getMyJDConnections() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/myJohnDeere/connections');

    return response.data;
}

export {
    getMyJDConnections
};
