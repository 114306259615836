// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {BasicDialog} from '@deere/basic-dialog';
import Iframe from 'react-iframe';
import StepInstructionsForm from 'Ui/components/common/form/step-instructions-form';
import UserMigrationTable from 'Ui/features/myJohnDeere-migration/user-migration-table';
import {useEventListener} from 'Ui/react-hooks/use-event-listener';
import {useSave} from 'Ui/react-hooks/use-save';
import {batchUpsertInviteStatuses} from 'Services/invite-status-service';
import {INVITE_SENT} from 'Common/constants/invite-statuses';
import {loadMigrationDataTable} from 'Utils/myJohnDeereMigration/user-migration-table-data';

function MyJohnDeereStaffMigration(props) {
    const {
        invalidInputs,
        membership,
        translations,
        setValid,
        userRoles
    } = props;

    const [selectedStaff, setSelectedStaff] = React.useState(() => new Set());
    const [loading, setLoading] = React.useState(true);
    const [invites, setInvites] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    const onMigrate = React.useCallback(() => setOpenDialog(true), []);

    const emailQueryParamValue = React.useMemo(() => {
        const emails = [...selectedStaff].map((appUserId) => invites[appUserId].email);

        return encodeURIComponent(emails.join(','));
    }, [selectedStaff, invites]);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const inviteStatusesData = [...selectedStaff].map((appUserId) => ({
            ...invites[appUserId],
            appUserId,
            inviteStatus: INVITE_SENT
        }));

        await batchUpsertInviteStatuses(inviteStatusesData);

        await loadMigrationDataTable({
            filterInvitedUsers: false,
            isMounted: () => true,
            setLoading,
            setRows,
            setInvites,
            setSelected: setSelectedStaff,
            translations,
            userRoles
        });
    }, {
        disabled: loading,
        invalidInputs
    });

    useEventListener(window, {
        listener(event) {
            if (event.data.messageType === 'add-staff-status' && event.data.message === 'success') {
                saveFunc();
            }

            if (event.data.messageType === 'add-staff-closed' && event.data.message === true) {
                setOpenDialog(false);
            }
        },
        options: false,
        type: 'message'
    }, [selectedStaff, invites]);

    return (
        <>
            <StepInstructionsForm
                steps={[
                    {
                        title: translations.ONLINK_STAFF_MIGRATION_STEP1_TITLE,
                        body: [translations.ONLINK_STAFF_MIGRATION_STEP1_BODY]
                    },
                    {
                        title: translations.ONLINK_STAFF_MIGRATION_STEP2_TITLE,
                        body: [translations.ONLINK_STAFF_MIGRATION_STEP2_BODY, translations.ONLINK_STAFF_MIGRATION_STEP2_BODY_PART_2]
                    },
                    {
                        title: translations.ONLINK_STAFF_MIGRATION_STEP3_TITLE,
                        body: [translations.ONLINK_STAFF_MIGRATION_STEP3_BODY],
                        additionalComponent: (
                            <UserMigrationTable
                                disableDuplicateUsers={false}
                                disableMigrate={disableSave || selectedStaff.size === 0 || isSaving}
                                editableEmailColumn={true}
                                filterInvitedUsers={false}
                                loading={loading || isSaving}
                                onMigrate={onMigrate}
                                reloadTableFromDialog={false}
                                rows={rows}
                                selected={selectedStaff}
                                setInvites={setInvites}
                                setLoading={setLoading}
                                setRows={setRows}
                                setSelected={setSelectedStaff}
                                setValid={setValid}
                                translations={translations}
                                userRoles={userRoles}
                            />
                        )
                    }
                ]}
                subtitles={[translations.ONLINK_STAFF_MEMBER_MIGRATION_SUBTITLE, translations.ONLINK_STAFF_MEMBER_MIGRATION_SUBTITLE_PART_2]}
                title={translations.ONLINK_STAFF_MEMBER_MIGRATION_TITLE}
            />
            {
                openDialog &&
                <BasicDialog
                    className='add-staff-dialog'
                    show={true}
                >
                    <Iframe
                        className='add-staff-iframe'
                        url={
                            `${window.props.teamManagerLink}/organizations/${membership.orgId}/staff/new?fullSize=true&emails=${emailQueryParamValue}`
                        }
                    />
                </BasicDialog>
            }
        </>
    );
}

MyJohnDeereStaffMigration.propTypes = {
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        translations: state.translations,
        userRoles: state.role.userRoles
    };
}

export default connect(mapStateToProps)(FormValidator(MyJohnDeereStaffMigration));
