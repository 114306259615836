// Unpublished Work © 2022-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getModels() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/fleets/models');

    return response.data;
}

async function getDeploymentsInUse(fleetId, dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/fleets/${fleetId}/deploymentsInUse/${dateSelect}`);

    return response.data;
}

async function getEquipmentRollupsForFleet(fleetId, dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/fleets/${fleetId}/equipmentRollups/${dateSelect}`);

    return response.data;
}

async function getModelMaintenances(fleetId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/fleets/${fleetId}/modelMaintenances`);

    return response.data;
}

async function getFleetEquipmentById(fleetId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/fleets/${fleetId}/equipment`);

    return response.data;
}

export {
    getModels,
    getDeploymentsInUse,
    getEquipmentRollupsForFleet,
    getModelMaintenances,
    getFleetEquipmentById
};
