// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {TextArea} from '@deere/form-controls';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';
import moment from 'moment';
import {clsx} from 'clsx';

function Notes(props) {
    const {
        editableNote,
        existingNotes,
        onChange,
        readOnly,
        translations
    } = props;

    const existingNotesComponents = React.useMemo(() => {
        if (existingNotes?.length > 0) {
            const rows = existingNotes.map((note) => [
                note.note,
                moment(note.noteTime).fromNow()
            ]);

            const tableClassName = clsx('notes-table', {
                'read-only': readOnly
            });

            return (
                <div className='settings-group mobile'>
                    <BasicTable
                        className={tableClassName}
                        headers={[translations.NOTE, translations.DATE]}
                        rows={rows}
                        vertical={true}
                    />
                </div>
            );
        }

        return null;
    }, [existingNotes, readOnly]);

    return (
        <>
            <div className='settings-group mobile'>
                <ReadOnlyWrapperComponent
                    readOnly={readOnly}
                    readOnlyProps={{
                        marginBottom: '10px'
                    }}
                    value={editableNote}
                    wrappedComponent={TextArea}
                    wrappedProps={{
                        allowResize: false,
                        className: 'notes-text-area',
                        label: translations.NOTES,
                        name: 'editableNote',
                        onChange,
                        tabIndex: 0
                    }}
                />
            </div>
            {existingNotesComponents}
        </>
    );
}

Notes.propTypes = {
    editableNote: PropTypes.string,
    existingNotes: PropTypes.arrayOf(
        PropTypes.shape({
            note: PropTypes.string,
            noteTime: PropTypes.number
        })
    ),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    translations: PropTypes.translations
};

export default Notes;
