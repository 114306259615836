// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Input} from '@deere/form-controls';
import {MINUTES_PER_HOUR} from 'Ui/constants/time-constants';
import {MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {isEmptyString} from 'Common/utils/validation-utils';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

function convertValueToHours(value) {
    return isEmptyString(value) ? null : Number(value) / MINUTES_PER_HOUR;
}

function convertValueToMinutes(value) {
    return isEmptyString(value) ? null : Math.max(value, 0) * MINUTES_PER_HOUR;
}

function DurationInput(props) {
    const {
        label,
        onChange,
        readOnly,
        value,
        ...otherProps
    } = props;

    const onChangeWithConversion = React.useCallback((event) => {
        const valueInMinutes = convertValueToMinutes(event.target.value);

        onChange(valueInMinutes);
    }, [onChange]);
    const valueInHours = React.useMemo(() => convertValueToHours(value), [value]);

    return (
        <ReadOnlyWrapperComponent
            label={label}
            readOnly={readOnly}
            readOnlyProps={{
                readOnlyLabelClassName: 'title-1'
            }}
            value={valueInHours}
            wrappedComponent={Input}
            wrappedProps={{
                ...otherProps,
                debounceTimeout: window.props.debounceTimeout,
                min: MIN_NUMERIC_VALUE,
                onChange: onChangeWithConversion,
                type: 'number'
            }}
        />
    );
}

DurationInput.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    value: PropTypes.stringOrNumber
};

export default DurationInput;
