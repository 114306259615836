// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import React from 'react';
import {getEmployeeStatusOptions, deleteEmployeeStatusOption} from 'Services/membership-service';
import DataTable from 'Ui/components/common/data-table/data-table';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';
import EditLink from 'Ui/components/common/data-table/edit-link';
import AddButton from 'Ui/components/settings/common/add-button';
import {REMAP_STATUS_COLORS} from 'Common/constants/valid-hex-color';

function getColumns(translations, openEmployeeStatusDialog, fetchStatusOptions) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openEmployeeStatusDialog({
                            status: original,
                            translations,
                            getUpdatedStatusOptions: fetchStatusOptions
                        })}
                    >
                        {value}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_COLOR,
            accessor: 'color',
            Cell(row) {
                const color = REMAP_STATUS_COLORS[row.value] || row.value;

                return (
                    <div
                        className={`color-item status-color-item ${color}`}
                    />
                );
            }
        },
        {
            Header: translations.ONLINK_AVAILABLE_FOR_WORK,
            accessor: 'isAvailable',
            Cell(row) {
                return row.value ? translations.yes : translations.no;
            }
        }
    ];
}

function getFilterComponent(translations, openEmployeeStatusDialog, fetchStatusOptions) {
    return (
        <div className='extra-table-options'>
            <AddButton
                className='add-status-button'
                label={translations.ONLINK_ADD_STATUS}
                onClick={() => {
                    openEmployeeStatusDialog({
                        translations,
                        getUpdatedStatusOptions: fetchStatusOptions
                    });
                }}
            />
        </div>
    );
}

function EmployeeStatusesSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        translations,
        openEmployeeStatusDialog
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [statusOptions, setStatusOptions] = React.useState([]);

    async function fetchStatusOptions(isMounted = () => true) {
        setLoading(true);

        const {employeeStatusOptions} = await getEmployeeStatusOptions();

        if (isMounted()) {
            setStatusOptions(employeeStatusOptions);
            setLoading(false);
        }
    }

    function deleteEmployeeStatus(employeeStatus) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.STATUS.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.STATUS
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteEmployeeStatusOption(employeeStatus.employeeStatusOptionId);

                    await fetchStatusOptions();
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.STATUS.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    useDeepEffect(() => fetchEffectData(fetchStatusOptions), []);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, openEmployeeStatusDialog, fetchStatusOptions)}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                deleteHandler={deleteEmployeeStatus}
                filterComponent={getFilterComponent(translations, openEmployeeStatusDialog, fetchStatusOptions)}
                loading={loading}
                rows={statusOptions}
                searchable={true}
                showDeleteColumn={true}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

EmployeeStatusesSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openEmployeeStatusDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openEmployeeStatusDialog(props) {
            dispatch(openDialog(dialogTypes.EMPLOYEE_STATUS_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeStatusesSettings);
