// Unpublished Work © 2020-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

export default {
    ADD_EDIT_EXPENSE_DIALOG: 'ADD_EDIT_EXPENSE_DIALOG',
    BULK_ENGINE_HOURS_DIALOG: 'BULK_ENGINE_HOURS_DIALOG',
    CHECK_EQUIPMENT_DIALOG: 'CHECK_EQUIPMENT_DIALOG',
    CONFIRMATION_DIALOG: 'CONFIRMATION_DIALOG',
    CONTACT_US_DIALOG: 'CONTACT_US_DIALOG',
    COURSE_NAME_DIALOG: 'COURSE_NAME_DIALOG',
    DEALERSHIP_DIALOG: 'DEALERSHIP_DIALOG',
    DEPARTMENT_EDIT_DIALOG: 'DEPARTMENT_EDIT_DIALOG',
    EQUIPMENT_CREATE_DIALOG: 'EQUIPMENT_CREATE_DIALOG',
    EQUIPMENT_EDIT_DIALOG: 'EQUIPMENT_EDIT_DIALOG',
    EQUIPMENT_TRANSFER_DIALOG: 'EQUIPMENT_TRANSFER_DIALOG',
    HOC_EDIT_DIALOG: 'HOC_EDIT_DIALOG',
    LAYOUT_EDIT_DIALOG: 'LAYOUT_EDIT_DIALOG',
    MANUAL_DATA_DIALOG: 'MANUAL_DATA_DIALOG',
    MANUAL_DATA_ENTRY_DIALOG: 'MANUAL_DATA_ENTRY_DIALOG',
    MEMBERSHIP_SELECT_DIALOG: 'MEMBERSHIP_SELECT_DIALOG',
    PART_DIALOG: 'PART_DIALOG',
    ROLE_EDIT_DIALOG: 'ROLE_EDIT_DIALOG',
    SERVICE_FORM_DIALOG: 'SERVICE_FORM_DIALOG',
    SETTINGS_DIALOG: 'SETTINGS_DIALOG',
    TERMS_AND_CONDITIONS_DIALOG: 'TERMS_AND_CONDITIONS_DIALOG',
    THRESHOLD_EDIT_DIALOG: 'THRESHOLD_EDIT_DIALOG',
    TILE_EDIT_DIALOG: 'TILE_EDIT_DIALOG',
    SENSOR_CREATE_DIALOG: 'SENSOR_CREATE_DIALOG',
    ADD_WORKBOARD_DIALOG: 'ADD_WORKBOARD_DIALOG',
    JOB_CATEGORY_DIALOG: 'JOB_CATEGORY_DIALOG',
    JOB_DIALOG: 'JOB_DIALOG',
    EMPLOYEE_STATUS_DIALOG: 'EMPLOYEE_STATUS_DIALOG',
    QUICK_TODO_DIALOG: 'QUICK_TODO_DIALOG',
    MANAGE_TODO_DIALOG: 'MANAGE_TODO_DIALOG',
    ADD_TIME_OFF_DIALOG: 'ADD_TIME_OFF_DIALOG',
    ADD_TODO_DIALOG: 'ADD_TODO_DIALOG',
    EDIT_MOWING_DIRECTION_DIALOG: 'EDIT_MOWING_DIRECTION_DIALOG',
    WORKBOARD_PREVIEW_DIALOG: 'WORKBOARD_PREVIEW_DIALOG',
    PART_BIN_DIALOG: 'PART_BIN_DIALOG',
    TRANSFER_INVENTORY_DIALOG: 'TRANSFER_INVENTORY_DIALOG',
    PULL_INVENTORY_DIALOG: 'PULL_INVENTORY_DIALOG',
    STOCK_INVENTORY_DIALOG: 'STOCK_INVENTORY_DIALOG',
    ADD_EDIT_PURCHASE_ORDER_DIALOG: 'ADD_EDIT_PURCHASE_ORDER_DIALOG',
    PURCHASE_ORDER_DIALOG: 'PURCHASE_ORDER_DIALOG',
    BIN_LOCATION_DIALOG: 'BIN_LOCATION_DIALOG',
    MANUFACTURER_DIALOG: 'MANUFACTURER_DIALOG',
    RECEIVE_ORDER_DIALOG: 'RECEIVE_ORDER_DIALOG',
    PART_TYPE_DIALOG: 'PART_TYPE_DIALOG',
    ADD_EDIT_MAINTENANCE_DIALOG: 'ADD_EDIT_MAINTENANE_DIALOG',
    ERROR_DIALOG: 'ERROR_DIALOG',
    FEEDBACK_DIALOG: 'FEEDBACK_DIALOG',
    MIGRATION_CONFIRMATION_DIALOG: 'MIGRATION_CONFIRMATION_DIALOG',
    WORK_ITEM_DETAILS_DIALOG: 'WORK_ITEM_DETAILS_DIALOG'
};
