// Unpublished Work © 2023-2024 Deere & Company.

import * as tertiaryNavBarActions from 'Store/actions/tertiary-navbar';

const DEFAULT_STATE = {
    actions: []
};

const tertiaryNavBar = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case tertiaryNavBarActions.SET_TERTIARY_NAVBAR_ACTIONS:
            return {
                ...state,
                actions: action.navbarActions || []
            };
        default:
            return state;
    }
};

export default tertiaryNavBar;
