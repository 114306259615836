// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import equipmentStatusConstants from 'Ui/constants/equipment-status-constants';
import purchaseOrderStatusConstants from 'Common/constants/purchase-order-status-constants';

function Status(props) {
    const {
        formattedStatus,
        status,
        className
    } = props;

    const statusConstant = equipmentStatusConstants[status.toLowerCase()] ||
        purchaseOrderStatusConstants[status.toLowerCase()] ||
        {};

    return (
        <span className={joinClassNames('equipment-status', className, statusConstant.color)}>
            {formattedStatus}
        </span>
    );
}

Status.propTypes = {
    className: PropTypes.string,
    formattedStatus: PropTypes.string,
    status: PropTypes.string
};

export default Status;
