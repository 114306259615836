// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import {Themes, LocalizationProvider, ThemeProvider} from '@deere/isg.component-library';
import {createTheme} from '@mui/material/styles';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
const {JDGreenBootstrap} = Themes;

import Favicon from 'react-favicon';

const ONLINK_BLUE_50 = '#e4f3fb';
const ONLINK_BLUE_100 = '#bce0f7';
const ONLINK_BLUE_200 = '#93cdf2';
const ONLINK_BLUE_300 = '#6bb9ed';
const ONLINK_BLUE_400 = '#4ca9ea';
const ONLINK_BLUE_500 = '#2f9be7';
const ONLINK_BLUE_600 = '#278dda';
const ONLINK_BLUE_700 = '#1d7cc8';
const ONLINK_BLUE_800 = '#156cb6';
const ONLINK_BLUE_900 = '#054e97';
const ONLINK_BLUE_1000 = '#03388d';
const ONLINK_ORANGE = '#F99700';
const ONLINK_WHITE = '#fff';

const ONLINK_BLACK_OVERRIDE = {
    color: JDGreenBootstrap.tokens.FuelColorGray900
};
const ONLINK_BLUE_OVERRIDE = {
    color: ONLINK_BLUE_800
};

const OnLinkTheme = createTheme({
    ...JDGreenBootstrap,
    components: {
        ...JDGreenBootstrap.components,
        MuiFormLabel: {
            ...JDGreenBootstrap.components.MuiFormLabel,
            styleOverrides: {
                root: ONLINK_BLACK_OVERRIDE
            }
        },
        MuiStepLabel: {
            ...JDGreenBootstrap.components.MuiStepLabel,
            styleOverrides: {
                labelContainer: ONLINK_BLACK_OVERRIDE
            }
        },
        MuiTab: {
            ...JDGreenBootstrap.components.MuiTab,
            styleOverrides: {
                root: {
                    color: JDGreenBootstrap.tokens.FuelColorGray600,
                    fontWeight: 600,
                    minHeight: 'auto',
                    padding: '4px 16px 8px',
                    ':hover': {
                        textDecoration: 'none'
                    }
                },
                textColorPrimary: {
                    ':focus': ONLINK_BLUE_OVERRIDE,
                    ':hover': ONLINK_BLUE_OVERRIDE
                }
            }
        },
        MuiTabs: {
            ...JDGreenBootstrap.components.MuiTabs,
            styleOverrides: {
                root: {
                    minHeight: 'auto'
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            },
            styleOverrides: {
                root: {
                    // This is to workaround jd's style-baseline.css
                    ':link': ONLINK_BLUE_OVERRIDE,
                    ':visited': ONLINK_BLUE_OVERRIDE,
                    // this must be last for some reason
                    ':hover': {
                        color: ONLINK_BLUE_600,
                        cursor: 'pointer'
                    }
                }
            }
        }
    },
    palette: {
        ...JDGreenBootstrap.palette,
        button: {
            activeBackground: '#c7dcee',
            borderColor: ONLINK_BLUE_800,
            focusBackground: '#d5e4f2',
            hoverBackground: '#edf3f9',
            selectedBackground: ONLINK_BLUE_800
        },
        chip: {
            selectedBackground: ONLINK_BLUE_800,
            unselectedText: ONLINK_BLUE_800
        },
        highlight: {
            backgroundColor: ONLINK_BLUE_800
        },
        input: {
            focusColor: ONLINK_BLUE_800
        },
        interactive: {
            activeBackground: ONLINK_BLUE_1000,
            activeBorder: ONLINK_BLUE_800,
            selectedBackground: ONLINK_BLUE_1000
        },
        panel: {
            chevronColor: ONLINK_BLUE_800,
            collapseIconFillPrimary: ONLINK_BLUE_800
        },
        pill: {
            defaultText: JDGreenBootstrap.tokens.FuelColorGray900,
            hoverBackground: ONLINK_BLUE_800
        },
        primary: {
            contrastText: ONLINK_WHITE,
            main: ONLINK_BLUE_800,
            light: ONLINK_BLUE_600
        },
        secondary: {
            main: ONLINK_WHITE
        },
        selectionControls: {
            selectedBackground: ONLINK_BLUE_800
        },
        table: {
            chevronColor: ONLINK_BLUE_800,
            sortedColor: ONLINK_BLUE_800
        },
        tabs: {
            activeColor: ONLINK_BLUE_800,
            indicatorBackground: ONLINK_BLUE_800
        },
        text: {
            primary: JDGreenBootstrap.tokens.FuelColorGray900,
            secondary: ONLINK_BLUE_800
        },
        warning: {
            main: ONLINK_ORANGE
        }
    },
    tokens: {
        ...JDGreenBootstrap.tokens,
        FuelColorBrandPrimary: ONLINK_BLUE_800,
        FuelColorBrandSecondary: ONLINK_BLUE_800,
        FuelColorBackgroundActionPrimaryBase: ONLINK_BLUE_800,
        FuelColorBackgroundActionPrimaryHovered: '#1361a4',
        FuelColorBackgroundActionPrimaryPressed: '#115692',
        FuelColorBackgroundActionTertiaryHovered: ONLINK_BLUE_50,
        FuelColorBackgroundActionTertiaryPressed: ONLINK_BLUE_200,
        FuelColorBackgroundInteractiveDefaultBase: ONLINK_BLUE_800,
        FuelColorBackgroundBrand: ONLINK_BLUE_800,
        FuelColorTextLink: ONLINK_BLUE_800,
        FuelColorTextActionPrimary: ONLINK_WHITE,
        FuelColorTextActionTertiary: ONLINK_BLUE_800,
        FuelColorTextBrandPrimary: ONLINK_BLUE_800,
        FuelColorYellow50: ONLINK_BLUE_50,
        FuelColorYellow100: ONLINK_BLUE_100,
        FuelColorYellow200: ONLINK_BLUE_200,
        FuelColorYellow300: ONLINK_BLUE_300,
        FuelColorYellow400: ONLINK_BLUE_400,
        FuelColorYellow500: ONLINK_BLUE_500,
        FuelColorYellow600: ONLINK_BLUE_600,
        FuelColorYellow700: ONLINK_BLUE_700,
        FuelColorYellow800: ONLINK_BLUE_800,
        FuelColorYellow900: ONLINK_BLUE_900,
        FuelColorYellow1000: ONLINK_BLUE_1000,
        FuelColorGreen50: ONLINK_BLUE_50,
        FuelColorGreen100: ONLINK_BLUE_100,
        FuelColorGreen200: ONLINK_BLUE_200,
        FuelColorGreen300: ONLINK_BLUE_300,
        FuelColorGreen400: ONLINK_BLUE_400,
        FuelColorGreen500: ONLINK_BLUE_500,
        FuelColorGreen600: ONLINK_BLUE_600,
        FuelColorGreen700: ONLINK_BLUE_700,
        FuelColorGreen800: ONLINK_BLUE_800,
        FuelColorGreen900: ONLINK_BLUE_900,
        FuelColorGreen1000: ONLINK_BLUE_1000,
        FuelColorJdGreen: ONLINK_BLUE_800,
        FuelColorJdYellow: ONLINK_BLUE_800,
        FuelColorJdConstructionYellow: ONLINK_BLUE_800
    }
});

function OnlinkThemeProvider(props) {
    const {
        translations,
        isMigrated,
        children
    } = props;

    return (
        <ThemeProvider theme={isMigrated ? JDGreenBootstrap : OnLinkTheme}>
            {
                isMigrated &&
                <Favicon url={`${window.props.persistentHeaderUrl}/images/favicon-map-ag.ico`}/>
            }
            <LocalizationProvider translations={translations}>
                {children}
            </LocalizationProvider>
        </ThemeProvider>
    );
}

OnlinkThemeProvider.propTypes = {
    children: PropTypes.node,
    isMigrated: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(OnlinkThemeProvider);
