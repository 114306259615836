// Unpublished Work © 2022-2024 Deere & Company.

import moment from 'moment';
import {TOAST_TYPE} from '@deere/toast';
import {deleteEmployeeStatusByDateSelect, updateEmployeeStatusByDateSelect} from 'Services/user-service';
import {CONFLICT} from 'Common/constants/errors';

function getPreviousDayEvent(newEvent, events) {
    const previousDayDate = moment(newEvent.end).subtract(1, 'days').format('YYYYMMDD');

    return events.filter((e) => {
        return e.appUserId === newEvent.appUserId && e.dateSelect === previousDayDate &&
            newEvent.employeeStatusOptionId === e.employeeStatusOptionId;
    });
}

function getNextDayEvent(newEvent, events) {
    const nextDayDate = moment(newEvent.end).add(1, 'days').format('YYYYMMDD');

    const nextDayEvent = events.filter((e) => {
        return e.appUserId === newEvent.appUserId && e.dateSelect === nextDayDate &&
            newEvent.employeeStatusOptionId === e.employeeStatusOptionId;
    });

    if (nextDayEvent.length > 0) {
        newEvent.end = nextDayDate;
    }

    return nextDayEvent;
}

function addNewEventToResults(newEvent, restructuredEvents, isFullCalendarFormat) {
    if (isFullCalendarFormat) {
        newEvent.end = moment(newEvent.end).add(1, 'days').format('YYYYMMDD');
    }

    restructuredEvents.push(newEvent);
}

function combineConsecutiveEvents(events, isFullCalendarFormat) {
    const restructuredEvents = [];

    events?.forEach((event) => {
        let nextDayEvent;

        const newEvent = {
            ...event,
            start: event.dateSelect,
            end: event.dateSelect
        };

        const previousDayEvent = getPreviousDayEvent(newEvent, events);

        if (previousDayEvent.length > 0) {
            return;
        }

        do {
            nextDayEvent = getNextDayEvent(newEvent, events);
        } while (nextDayEvent.length > 0);

        addNewEventToResults(newEvent, restructuredEvents, isFullCalendarFormat);
    });

    return restructuredEvents;
}

async function changeEmployeeStatus(payload, values) {
    while (values.fromDate.isBefore(values.toDate) || values.fromDate.isSame(values.toDate)) {
        payload.dateSelect = values.fromDate.format('YYYYMMDD');

        try {
            await updateEmployeeStatusByDateSelect(payload);
        } catch (e) {
            return e.response.status;
        }
        values.fromDate.add(1, 'days');
    }
    return null;
}

async function deleteEmployeeStatus(payload, values) {
    values.fromDate = moment(values.dateSelect);

    while (values.fromDate.isBefore(values.toDate) || values.fromDate.isSame(values.toDate)) {
        payload.dateSelect = values.fromDate.format('YYYYMMDD');

        try {
            await deleteEmployeeStatusByDateSelect(payload);
        } catch (e) {
            return e.response.status;
        }
        values.fromDate.add(1, 'days');
    }
    return null;
}

function getAddEditFooterActions(isEdit, translations, closeHandler, saveHandler, addToast, disableSave, deleteHandler) {
    const hasDelete = isEdit ? [{
        callback: deleteHandler,
        cssClasses: 'onlink-btn',
        title: translations.DELETE,
        disabled: disableSave
    }] : [];

    return [
        ...hasDelete,
        {
            callback: closeHandler,
            cssClasses: 'onlink-btn',
            title: translations.CANCEL
        },
        {
            async callback(event) {
                try {
                    await saveHandler(event);
                } catch (err) {
                    if (err.response.status !== CONFLICT) {
                        addToast({
                            message: translations.ERROR_OCCURRED_TITLE,
                            type: TOAST_TYPE.ERROR
                        });
                    }
                }
            },
            cssClasses: 'onlink-btn primary',
            disabled: disableSave,
            title: translations.save
        }
    ];
}

export {
    combineConsecutiveEvents,
    getAddEditFooterActions,
    changeEmployeeStatus,
    deleteEmployeeStatus
};
