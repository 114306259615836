// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import {connect} from 'react-redux';
import {Button} from '@deere/isg.component-library/lib/Button';

function buttonContents(props) {
    return (
        <>
            {
                props.leftText &&
                <div className='onlink-icon-btn-text'>
                    {props.leftText}
                </div>
            }
            {props.children}
            {
                props.rightText &&
                <div className='onlink-icon-btn-text'>
                    {props.rightText}
                </div>
            }
        </>
    );
}

function OnLinkIconButton(props) {
    const {isMigrated} = props;

    if (isMigrated) {
        return (
            <Button
                className={joinClassNames('onlink-icon-btn', props.className)}
                disabled={props.disabled}
                onClick={props.onClick}
            >
                {buttonContents(props)}
            </Button>
        );
    }

    return (
        <button
            className={joinClassNames('onlink-icon-btn', props.className)}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {buttonContents(props)}
        </button>
    );
}

OnLinkIconButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isMigrated: PropTypes.bool,
    leftText: PropTypes.node,
    onClick: PropTypes.func,
    rightText: PropTypes.node
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(OnLinkIconButton);
