// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddButton from 'Ui/components/settings/common/add-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {deleteRoleById} from 'Services/role-service';
import {closeDialog, openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {updateUserRoles as updateUserRolesRedux} from 'Store/actions/role';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {useDeepMemo} from 'Utils/react-utils';
import dialogTypes from 'Ui/components/common/dialog-types';
import {TOAST_TYPE} from '@deere/toast';

function getColumns(translations, openDialog) {
    return [{
        Header: translations['USER_MANAGEMENT.COLUMN_HEADER.ROLE'],
        accessor: 'title',
        Cell(row) {
            const {
                original,
                value
            } = row;

            return (
                <EditLink
                    onClick={() => {
                        openDialog({
                            role: original.role
                        });
                    }}
                >
                    {value}
                </EditLink>
            );
        }
    }];
}

function getFilterComponent(translations, openDialog) {
    return (
        <AddButton
            className='add-role-button'
            label={translations.ONLINK_ADD_ROLE}
            onClick={() => {
                openDialog({
                    role: null
                });
            }}
        />
    );
}

function RoleSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        openDialog,
        translations,
        updateUserRoles,
        userRoles
    } = props;

    const formattedRoles = useDeepMemo(() => userRoles.map((role) => ({
        role,
        title: role.title
    })), [userRoles]);

    function deleteRole(data) {
        const {userRoleId: roleId} = data.role;

        openConfirmation({
            message: translations.ONLINK_ROLE_THIS_CANNOT_BE_UNDONE,
            title: translations.ONLINK_DELETE_ROLE,
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteRoleById(roleId);

                    const roles = userRoles.filter((role) => role.userRoleId !== roleId);

                    updateUserRoles(roles);
                } catch (e) {
                    const message = translations[e.response.data.errorTranslation];

                    addToast({
                        message,
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    return (
        <DataTable
            columns={getColumns(translations, openDialog)}
            defaultSorted={[{
                desc: false,
                id: 'title'
            }]}
            deleteHandler={deleteRole}
            filterComponent={getFilterComponent(translations, openDialog)}
            rows={formattedRoles}
            searchable={true}
            showDeleteColumn={true}
            translations={translations}
        />
    );
}

RoleSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openDialog: PropTypes.func,
    translations: PropTypes.translations,
    updateUserRoles: PropTypes.func,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        userRoles: state.role.userRoles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openReduxDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openDialog(props) {
            dispatch(openReduxDialog(dialogTypes.ROLE_EDIT_DIALOG, props));
        },
        updateUserRoles(value) {
            dispatch(updateUserRolesRedux(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleSettings);
