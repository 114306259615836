// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';
import {Stack, Typography} from '@mui/material';

const PIN_LOCATION_MULTIPLIER = 1.5;

function PinLocation(props) {
    const {
        tileOpacityOn,
        pinLocation,
        fontSize,
        translations
    } = props;

    const {
        noteClassName,
        pinLocationFontSize
    } = React.useMemo(() => ({
        noteClassName: clsx('note', {
            'opacity-on': tileOpacityOn,
            'opacity-off': !tileOpacityOn
        }),
        pinLocationFontSize: `${fontSize.replaceAll('%', '') * PIN_LOCATION_MULTIPLIER}%`
    }), [tileOpacityOn, fontSize]);

    return (
        pinLocation && <Stack
            className={noteClassName}
            sx={{
                justifyContent: 'space-between',
                flexDirection: 'row'
            }}
        >
            <Typography
                sx={{
                    width: '64%',
                    fontSize,
                    alignContent: 'center'
                }}
            >{`${translations.PRO_GOLF_PIN_LOCATION}:`}</Typography>
            <Typography
                sx={{
                    width: '36%',
                    fontSize: pinLocationFontSize,
                    textAlign: 'center',
                    alignContent: 'center',
                    fontWeight: 'bold'
                }}
            >{pinLocation}</Typography>
        </Stack>
    );
}

PinLocation.propTypes = {
    fontSize: PropTypes.string,
    pinLocation: PropTypes.string,
    tileOpacityOn: PropTypes.bool,
    translations: PropTypes.translations
};

export default PinLocation;
