// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import DrillInNavItem from 'Ui/components/main-navbar/persistent-header/navigation/drill-in-nav-item';
import DropdownNavItem from 'Ui/components/main-navbar/persistent-header/navigation/dropdown-nav-item';
import NavBarItem from 'Ui/components/main-navbar/persistent-header/common/navbar-item';
import NavList from 'Ui/components/main-navbar/persistent-header/common/nav-list';
import UserDetails from 'Ui/components/main-navbar/persistent-header/shared-navbar/user-details';
import WhatsNew from 'Ui/components/main-navbar/persistent-header/secondary-navbar/whats-new';
import {useDeepMemo} from 'Utils/react-utils';

function MobileDropdown(props) {
    const {
        links: {
            accountLinks = [],
            navbarLinks = []
        },
        s3Path,
        translations,
        user,
        hasSomeAppAccess
    } = props;

    const [selectedNavItem, setSelectedNavItem] = React.useState(null);
    const deselectNavItem = React.useCallback(() => setSelectedNavItem(null), []);

    const dropdownNavbarItems = useDeepMemo(() => navbarLinks.map((navbarLink) => (
        <NavBarItem
            additionalNavItemGroupProps={{
                isSelected: false,
                setIsSelected() {
                    setSelectedNavItem(navbarLink.name);
                }
            }}
            key={`${navbarLink.name} ${navbarLink.path}`}
            navItemGroupComponent={DrillInNavItem}
            navbarLink={navbarLink}
            translations={translations}
        />
    )), [navbarLinks, translations]);
    const dropdownAccountItems = useDeepMemo(() => (
        <NavList
            additionalNavItemProps={{
                className: 'account-item'
            }}
            navbarLinks={accountLinks}
            translations={translations}
        />
    ), [accountLinks, translations]);

    return (
        <DropdownNavItem
            dropdownMenuClassName='mobile-dropdown-menu'
            iconName='icon-list-view'
            onClose={deselectNavItem}
            shouldRenderChevron={false}
        >
            {
                selectedNavItem ? (
                    <NavBarItem
                        additionalNavItemGroupProps={{
                            isSelected: true,
                            setIsSelected: deselectNavItem
                        }}
                        navItemGroupComponent={DrillInNavItem}
                        navbarLink={navbarLinks.find((navbarLink) => navbarLink.name === selectedNavItem)}
                        translations={translations}
                    />
                ) : (
                    <>
                        <div className='nav-items'>
                            {dropdownNavbarItems}
                        </div>
                        {
                            hasSomeAppAccess &&
                            <WhatsNew
                                s3Path={s3Path}
                                translations={translations}
                            />
                        }
                        <UserDetails user={user}/>
                        <div className='nav-items'>
                            {dropdownAccountItems}
                        </div>
                    </>
                )
            }
        </DropdownNavItem>
    );
}

MobileDropdown.propTypes = {
    hasSomeAppAccess: PropTypes.bool,
    links: PropTypes.object,
    s3Path: PropTypes.string,
    translations: PropTypes.translations,
    user: PropTypes.user
};

export default MobileDropdown;
