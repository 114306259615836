// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import SwitchInput from 'Ui/components/common/form/switch-input';
import ValidationInput from 'Ui/components/common/form/validation-input';
import permissionConstants from 'Ui/constants/permission-constants';

const PERMISSIONS = {
    ONLINK_EDIT_APPLICATION_ACCESS: {
        permission: permissionConstants.EDIT_APP_ACCESS
    },
    'pages/staff/editRoles': {
        permission: permissionConstants.EDIT_ROLES
    },
    ONLINK_VIEW_SALARIES: {
        permission: permissionConstants.VIEW_SALARIES
    },
    ONLINK_VIEW_HOURLY_RATE: {
        permission: permissionConstants.VIEW_HOURLY_RATES
    },
    ONLINK_ASSIGN_SERVICE_TICKET: {
        permission: permissionConstants.ASSIGNED_TO_SERVICE_TICKETS
    },
    ONLINK_SUPERINTENDENT_MODE: {
        permission: permissionConstants.ACCESS_SUPERINTENDENT_MODE
    },
    ONLINK_MULTIUSER_MODE: {
        permission: permissionConstants.ACCESS_MULTI_USER_MODE
    },
    ONLINK_DELETE_SERVICE_TICKET: {
        permission: permissionConstants.DELETE_SERVICE_TICKETS
    },
    ONLINK_MANAGE_MYJD_CONFIGURATION: {
        permission: permissionConstants.MANAGE_MYJD_CONFIGURATION
    },
    ONLINK_ACCESS_MY_WORK: {
        permission: permissionConstants.CAN_ACCESS_MY_WORK
    }
};

function createPermissionItems({
    permissions,
    togglePermission,
    translations
}) {
    return Object.keys(PERMISSIONS).map((permission, index) => {
        const permissionWithToggle = PERMISSIONS[permission];
        const switchInput = (
            <SwitchInput
                checked={permissions.has(permissionWithToggle.permission)}
                className='role-permission'
                key={`${permission}${index}`}
                name={permissionWithToggle.permission}
                onChange={togglePermission}
                onLabel={translations[permission]}
                translations={translations}
            />
        );

        return switchInput;
    });
}

function EditableRoleSettings(props) {
    const {
        featureToggles,
        name,
        onChange,
        onSave,
        permissions,
        setValid,
        togglePermission,
        translations
    } = props;

    return (
        <form
            className='settings-body editable-role-settings'
            onSubmit={onSave}
        >
            <ValidationInput
                autoFocus={true}
                debounceTimeout={window.props.debounceTimeout}
                errors={{
                    valueMissing: translations.REQUIRED_FIELD_TEXT
                }}
                label={translations.NAME}
                name='name'
                onChange={onChange}
                required={true}
                setValid={setValid}
                tabIndex={0}
                type='text'
                value={name}
            />
            <div className='settings-group'>
                {translations.ONLINK_ACCESS}
                {
                    createPermissionItems({
                        featureToggles,
                        permissions,
                        togglePermission,
                        translations
                    })
                }
            </div>
        </form>
    );
}

EditableRoleSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    permissions: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    togglePermission: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(EditableRoleSettings);
