// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {Typography} from '@mui/material';
import {isEmptyString} from 'Common/utils/validation-utils';

function WorkBoardDialogContent(props) {
    const {
        children,
        workboard,
        loading,
        translations
    } = props;

    const hasNote = !isEmptyString(workboard?.note?.length);
    const hasPinLocation = !isEmptyString(workboard?.pinLocation);

    return (
        <div className='work-board-dialog-content'>
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                {
                    (hasNote || hasPinLocation) &&
                    <div className='work-board-dialog-note large-section-title'>
                        {hasNote && <p>{workboard.note}</p>}
                        {hasPinLocation && <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: 'bold'
                            }}
                        >{`${translations.PRO_GOLF_PIN_LOCATION}: ${workboard.pinLocation}`}</Typography>}
                    </div>
                }
                {children}
            </LoadingWrapper>
        </div>
    );
}

WorkBoardDialogContent.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    translations: PropTypes.translations,
    workboard: PropTypes.object
};

export default WorkBoardDialogContent;
