// Unpublished Work © 2020-2024 Deere & Company.

export const tileTitleTranslationKeys = {
    weather: 'CURRENT_CONDITIONS',
    labor: 'Labor',
    equipment: 'Equipment',
    course: 'ONLINK_PLAYING_CONDITIONS',
    water: 'Water Management',
    chemicals: 'Fertilizer & Chemical',
    rounds: 'ONLINK_ROUNDS_PLAYED',
    hoc: 'HEIGHTS_OF_CUT',
    maintenance: 'ONLINK_MAINTENANCE_DUE',
    'operator_notes': 'ONLINK_TODAYS_OPERATOR_NOTES',
    'electric_mower_status': 'ONLINK_ELECTRIC_MOWER_STATUS',
    'low_inventory_parts': 'ONLINK_LOW_INVENTORY_PARTS'
};
