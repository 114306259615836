// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import {Marker, OverlayView} from '@react-google-maps/api';

function getPixelPositionOffset(width, height, labelAnchor) {
    return {
        x: labelAnchor.x - width / 2,
        y: labelAnchor.y - height / 2
    };
}

function MarkerWithLabel(props) {
    const {
        labelAnchor,
        labelClass,
        labelContent,
        onClick,
        onLoad,
        position,
        ...markerProps
    } = props;

    return (
        <>
            <Marker
                position={position}
                visible={false}
                {...markerProps}
            />
            <OverlayView
                getPixelPositionOffset={(width, height) => getPixelPositionOffset(width, height, labelAnchor)}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                onLoad={onLoad}
                position={position}
            >
                <div
                    className={joinClassNames('marker-label', labelClass)}
                    onClick={onClick}
                >
                    {labelContent}
                </div>
            </OverlayView>
        </>
    );
}

MarkerWithLabel.defaultProps = {
    labelAnchor: {
        x: 0,
        y: 0
    }
};

MarkerWithLabel.propTypes = {
    labelAnchor: PropTypes.xyCoordinates,
    labelClass: PropTypes.string,
    labelContent: PropTypes.node,
    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    position: PropTypes.mapPosition.isRequired
};

export default MarkerWithLabel;
