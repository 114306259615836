// Unpublished Work © 2020-2024 Deere & Company.

import appAccess from 'Common/constants/app-access';

export default [
    {
        id: appAccess.ONGOLF,
        title: 'OnGolf'
    },
    {
        id: appAccess.ONEQUIP,
        title: 'OnEquip'
    },
    {
        id: appAccess.ONLABOR,
        title: 'OnLabor'
    },
    {
        id: appAccess.ONAPPLY,
        title: 'OnApply'
    }
];
