// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {NavLink} from 'react-router-dom';
import {isCurrentPath} from 'Utils/route';

const ItemLink = React.forwardRef(
    function ItemLinkComponent(props, ref) {
        const {
            basePath,
            children,
            className,
            clearStorage,
            path,
            prioritizeList,
            reactRouter,
            title,
            type
        } = props;

        const isActive = React.useCallback(
            (match, {pathname}) => isCurrentPath(pathname, {
                basePath,
                prioritizeList
            }),
            [basePath, prioritizeList]
        );

        const navLinkProps = {
            children,
            className,
            ref,
            title,
            onClick: clearStorage ? () => localStorage.removeItem('UserSession') : undefined
        };

        return reactRouter ? (
            <NavLink
                {...navLinkProps}
                isActive={isActive}
                to={path}
            />
        ) : (
            <a
                {...navLinkProps}
                href={path}
                target={type}
            />
        );
    }
);

ItemLink.defaultProps = {
    prioritizeList: []
};

ItemLink.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    clearStorage: PropTypes.bool,
    path: PropTypes.string,
    prioritizeList: PropTypes.arrayOf(PropTypes.string),
    reactRouter: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string
};

export default ItemLink;
