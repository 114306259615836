// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function ListSubheader(props) {
    return (
        <div
            className='list-subheader'
        >
            {props.text}
            <hr className='list-subheader-divider'/>
        </div>
    );
}

ListSubheader.propTypes = {
    text: PropTypes.string
};

export default ListSubheader;
