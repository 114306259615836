// Unpublished Work © 2021-2024 Deere & Company.

export default {
    archived: {
        label: 'Archived - Not in inventory'
    },
    available: {
        color: 'green',
        label: 'Available'
    },
    decommissioned: {
        label: 'Decommissioned'
    },
    'in_shop': {
        color: 'red',
        label: 'In Shop'
    },
    'in_use': {
        label: 'In Use'
    },
    'out_of_service': {
        color: 'red',
        label: 'Out of Service'
    }
};
