// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';

function SidebarListItem(props) {
    const {
        active,
        children,
        onClick
    } = props;

    const className = clsx('map-sidebar-list-item', {
        active,
        clickable: onClick
    });

    return (
        <div
            className={className}
            onClick={onClick}
        >
            {children}
        </div>
    );
}

SidebarListItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func
};

export default SidebarListItem;
