// Unpublished Work © 2022-2024 Deere & Company.

import {convert} from '@deere/unit-system';
import {setInArray} from 'Common/utils/array-handlers';
import {configForUnit} from 'Common/utils/units/config-for-unit';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {
    convertToMultipleDataUnitValues as commonConvertToMultipleDataUnitValues,
    convertToUoM as commonConvertToUoM,
    determineConversionConfig as commonDetermineConversionConfig,
    getCurrencySymbol as getCurrencySymbolForLocale,
    getDataUnitAndCurrencyPreferenceParts,
    getUoMConfig as commonGetUoMConfig
} from 'Common/utils/units/unit-converter';
import {INCHES} from 'Common/constants/data-unit-constants';
import {CONVERSION_UNIT, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {DEFAULT_UOM_FOR_DATATYPE} from 'Ui/constants/manual-data-constants';

const CURRENCY_VALUE_REGEX = /(?:[,.]?\d+[,.]?)+/g;
const WHITESPACE_REGEX = /\s/;
const MAX_DECIMAL_PLACES = 20;

const determineConversionConfig = commonDetermineConversionConfig;
const convertToMultipleDataUnitValues = commonConvertToMultipleDataUnitValues;
const getUoMConfig = commonGetUoMConfig;
const convertToUoM = commonConvertToUoM;

function getTranslatedLabel(label, translations) {
    return translations[label] || label;
}

function getLanguagePreference() {
    return navigator.languages?.[0] || navigator.language;
}

function formatNumber(value, formatOptions) {
    const languagePreference = getLanguagePreference();
    const numberFormatter = new Intl.NumberFormat(languagePreference, formatOptions);

    return numberFormatter.format(value);
}

function formatUoMValue(value, {
    formatting = {},
    translations
}) {
    const {
        dataUnits,
        includeSpaceBetweenDataUnits = false,
        noDecimalFormatting
    } = formatting;

    const values = setInArray(value);

    const paddingBetweenDataUnits = includeSpaceBetweenDataUnits ? ' ' : '';

    return dataUnits?.reduce((formattedValue, dataUnit, index) => {
        const {
            decimalPlaces = 0,
            includeSpace = false,
            prefix,
            suffix
        } = dataUnit;

        const padding = includeSpace ? ' ' : '';
        const formattedPrefix = prefix ? `${getTranslatedLabel(prefix, translations)}${padding}` : '';
        const formattedDataValue = formatNumber(values[index], {
            maximumFractionDigits: noDecimalFormatting ? MAX_DECIMAL_PLACES : decimalPlaces
        });
        const formattedSuffix = suffix ? `${padding}${getTranslatedLabel(suffix, translations)}` : '';

        return `${formattedValue}${formattedPrefix}${formattedDataValue}${formattedSuffix}${paddingBetweenDataUnits}`;
    }, '');
}

function hasWhiteSpace(str) {
    return str && WHITESPACE_REGEX.test(str);
}

function getCurrencyConfig(formattedCurrency) {
    const formattedCurrencyParts = formattedCurrency.split(CURRENCY_VALUE_REGEX);

    return {
        includeSpace: formattedCurrencyParts.some(hasWhiteSpace),
        prefix: formattedCurrencyParts[0].trim(),
        suffix: formattedCurrencyParts[formattedCurrencyParts.length - 1].trim()
    };
}

function getCurrencySymbol({currencyPreference}) {
    return getCurrencySymbolForLocale({
        currencyPreference,
        locale: getLanguagePreference()
    });
}

function getFormattedCurrency(value, {
    currencyPreference,
    maximumFractionDigits
}) {
    if (isNullOrUndefined(value)) {
        return '';
    }

    return formatNumber(value, {
        currency: currencyPreference,
        currencyDisplay: 'narrowSymbol',
        style: 'currency',
        maximumFractionDigits
    });
}

function getFormatting(formatting) {
    return Array.isArray(formatting?.dataUnits) ? formatting : {
        dataUnits: [formatting]
    };
}

function getHocValue(hoc, translations) {
    const hocNumber = hoc && (hoc.manualDataHocValue || hoc.hocValue);

    return hocNumber ? formatUoMValue(hocNumber, {
        formatting: getFormatting(configForUnit(hoc.unitOfMeasure || INCHES)),
        translations
    }) : '';
}

function convertToFormattedUoM(value, {
    dataType,
    dataUnit,
    translations,
    unitOfMeasure = UNIT_OF_MEASURE.ENGLISH,
    unitOfMeasureOverrides
}) {
    const convertedValue = commonConvertToUoM(value, {
        dataType,
        dataUnit,
        unitOfMeasure,
        unitOfMeasureOverrides
    });

    if (isNullOrUndefined(convertedValue)) {
        return '';
    }

    const {formatting} = commonGetUoMConfig({
        dataType,
        dataUnit,
        unitOfMeasure,
        unitOfMeasureOverrides
    });

    return formatUoMValue(convertedValue, {
        formatting: getFormatting(formatting),
        translations
    });
}

function getPreferredDataType(preferredDataTypes, unitOfMeasure, overrideType) {
    const preferredDataType = preferredDataTypes.find(({dataType}) => dataType === overrideType);
    const defaultUomForDataType = DEFAULT_UOM_FOR_DATATYPE[overrideType];

    if (!preferredDataType && !defaultUomForDataType) {
        return null;
    }

    return preferredDataType?.measurement || defaultUomForDataType[unitOfMeasure];
}

function getPreferredDataTypeForm(preferredDataTypes, defaultUnitOfMeasure, overrideType) {
    const preferredDataType = preferredDataTypes.find(({dataType}) => overrideType.includes(dataType));

    return preferredDataType?.measurement || DEFAULT_UOM_FOR_DATATYPE[overrideType[0]][defaultUnitOfMeasure];
}

function convertFeetAndInchesToInches(feet = '0', inches = '0') {
    return convert(parseInt(feet, 10), CONVERSION_UNIT.FEET, CONVERSION_UNIT.INCHES) + parseFloat(inches);
}

function getConvertedDataUnitOrCurrencySymbol({
    currencyPreference,
    dataType,
    dataUnit,
    translations,
    unitOfMeasure = UNIT_OF_MEASURE.ENGLISH,
    unitOfMeasureOverrides
}) {
    const {
        convertedUnit,
        currencySymbol,
        translationKey
    } = getDataUnitAndCurrencyPreferenceParts({
        currencyPreference,
        dataType,
        dataUnit,
        locale: getLanguagePreference(),
        unitOfMeasure,
        unitOfMeasureOverrides
    });

    const translationForKey = translations[translationKey];

    if (convertedUnit || translationForKey) {
        const translatedConvertedUnit = translationForKey || getTranslatedLabel(convertedUnit, translations);

        return currencySymbol ? `${currencySymbol}/${translatedConvertedUnit}` : translatedConvertedUnit;
    }

    return currencySymbol || '';
}

export {
    convertToMultipleDataUnitValues,
    convertFeetAndInchesToInches,
    convertToFormattedUoM,
    convertToUoM,
    determineConversionConfig,
    getPreferredDataTypeForm,
    formatNumber,
    formatUoMValue,
    getCurrencyConfig,
    getCurrencySymbol,
    getFormattedCurrency,
    getFormatting,
    getHocValue,
    getLanguagePreference,
    getPreferredDataType,
    getUoMConfig,
    getConvertedDataUnitOrCurrencySymbol
};
