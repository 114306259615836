// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import NavItem from 'Ui/components/main-navbar/persistent-header/navigation/nav-item';
import NavList from 'Ui/components/main-navbar/persistent-header/common/nav-list';
import {isCurrentPath} from 'Utils/route';
import {clsx} from 'clsx';

function isLinkActive(pathname, dropdownItems, linkProps) {
    const active = isCurrentPath(pathname, linkProps);

    return dropdownItems && !active ?
        dropdownItems.some((dropdownItem) => isCurrentPath(pathname, dropdownItem)) :
        active;
}

function NavBarItem(props) {
    const {
        additionalNavItemGroupProps,
        additionalNavItemProps,
        location: {pathname},
        navbarLink,
        navItemGroupComponent: NavItemGroupComponent,
        translations
    } = props;

    const {
        dropdownItems,
        icon: navItemIcon,
        name: navItemName,
        ...navItemProps
    } = navbarLink;

    const {[navItemName]: translatedNavItemLabel} = translations;

    const navItemClassName = clsx({
        active: isLinkActive(pathname, dropdownItems, navItemProps)
    });

    if (dropdownItems) {
        return (
            <NavItemGroupComponent
                {...navItemProps}
                {...additionalNavItemGroupProps}
                groupNavItemClassName={navItemClassName}
                iconName={navItemIcon}
                label={translatedNavItemLabel}
                title={translatedNavItemLabel}
            >
                <NavList
                    additionalNavItemProps={additionalNavItemProps}
                    navbarLinks={dropdownItems}
                    translations={translations}
                />
            </NavItemGroupComponent>
        );
    }

    return (
        <NavItem
            {...navItemProps}
            {...additionalNavItemProps}
            className={navItemClassName}
            iconName={navItemIcon}
            label={translatedNavItemLabel}
            title={translatedNavItemLabel}
        />
    );
}

NavBarItem.defaultProps = {
    additionalNavItemGroupProps: {},
    additionalNavItemProps: {}
};

NavBarItem.propTypes = {
    additionalNavItemGroupProps: PropTypes.object,
    additionalNavItemProps: PropTypes.object,
    location: PropTypes.location,
    navbarLink: PropTypes.object,
    navItemGroupComponent: PropTypes.elementType.isRequired,
    translations: PropTypes.translations
};

export default withRouter(NavBarItem);
