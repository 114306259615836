// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import moment from 'moment';
import {Datepicker} from '@deere/form-controls';
import SidebarSearchbar from 'Ui/components/map/sidebar/common/sidebar-searchbar';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';

function SidebarHeader(props) {
    const {
        appliedFilters,
        dateSelected,
        onFilterClick,
        onSearchChange,
        search,
        setDate,
        showDateFilter,
        showFilter,
        translations
    } = props;

    return (
        <div className='sidebar-filters'>
            {
                showDateFilter &&
                <Datepicker
                    className='map-datepicker'
                    dateFormat='LL'
                    label={translations.DATE}
                    large={true}
                    locale={getLanguagePreference()}
                    maxDate={moment()}
                    minDate={moment().subtract(2, 'years')}
                    onChange={setDate}
                    popperModifiers={{
                        flip: {
                            enabled: false
                        },
                        preventOverflow: {
                            enabled: false
                        },
                        hide: {
                            enabled: false
                        }
                    }}
                    selected={dateSelected}
                />
            }
            {
                showFilter &&
                <SidebarSearchbar
                    appliedFilters={appliedFilters}
                    onFilterClick={onFilterClick}
                    onSearchChange={onSearchChange}
                    search={search}
                    translations={translations}
                />
            }
        </div>
    );
}

SidebarHeader.propTypes = {
    appliedFilters: PropTypes.appliedFilters,
    dateSelected: PropTypes.instanceOf(moment),
    onFilterClick: PropTypes.func,
    onSearchChange: PropTypes.func,
    search: PropTypes.string,
    setDate: PropTypes.func,
    showDateFilter: PropTypes.bool,
    showFilter: PropTypes.bool,
    translations: PropTypes.translations
};

export default SidebarHeader;
