// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import backgroundImage from 'Ui/images/background.jpg';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';

const BACKGROUND_IMAGE_STYLE = {
    style: {
        backgroundImage: `url(${backgroundImage})`
    }
};

const BACKGROUND_COLOR_STYLE = {
    style: {
        backgroundColor: '#E5E5E5'
    }
};

function MainContentBackground(props) {
    const {featureToggles} = props;

    const backgroundImageProps = React.useMemo(
        () => featureToggles[ONLINK_NAVIGATION_REDESIGN] ? BACKGROUND_COLOR_STYLE : BACKGROUND_IMAGE_STYLE,
        [featureToggles[ONLINK_NAVIGATION_REDESIGN]]
    );

    return (
        <div
            {...backgroundImageProps}
            className='main-content-background'
        />
    );
}

MainContentBackground.propTypes = {
    featureToggles: PropTypes.featureToggles
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(MainContentBackground);
