// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LinkProfileCard from 'Ui/components/login/link-profile-card';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {fetchEffectData} from 'Utils/react-utils';
import {redirect} from 'Utils/redirect-utils';
import {getMyJDProfile} from 'Services/my-jd-user-service';
import {MY_JOHN_DEERE} from 'Ui/constants/replace-constants';
import onlinkImage from 'Ui/images/onlink.png';

function LinkProfileConfirm(props) {
    const {
        onLinkEmail,
        onLinkMembership,
        redirectParam,
        translations
    } = props;

    const [myJDName, setMyJDName] = React.useState('');
    const [myJDOrganizationName, setMyJDOrganizationName] = React.useState('');

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const {
            user, organization
        } = await getMyJDProfile();

        if (isMounted()) {
            const organizationName = organization && organization.name || '';

            setMyJDName(user.accountName);
            setMyJDOrganizationName(organizationName);
        }
    }), []);

    const onlinkMembershipCard = React.useMemo(() => ({
        label: translations.ONLINK_MEMBERSHIP,
        name: onLinkMembership
    }), [onLinkMembership]);
    const myJDOrganizationCard = React.useMemo(() => ({
        label: translations.contact_organization,
        name: myJDOrganizationName
    }), [myJDOrganizationName]);

    return (
        <div className='link-profile-prompt'>
            <h1 className='link-header'>
                {translations.ONLINK_ABOUT_TO_LINK_THESE_ACCOUNTS}
            </h1>
            <LinkProfileCard
                organization={onlinkMembershipCard}
                translations={translations}
                username={onLinkEmail}
            >
                <img
                    alt='OnLink Logo'
                    className='profile-image'
                    src={onlinkImage}
                />
            </LinkProfileCard>
            <LinkProfileCard
                organization={myJDOrganizationCard}
                translations={translations}
                username={myJDName}
            >
                <div className='jdSans'>
                    {MY_JOHN_DEERE}
                </div>
            </LinkProfileCard>
            <div className='prompt-actions'>
                <OnlinkButton onClick={() => redirect(`/login-redirect${redirectParam}`)}>
                    {translations.CANCEL}
                </OnlinkButton>
                <form
                    action={`/myjd-link-confirmation${redirectParam}`}
                    className='prompt-actions'
                    method='post'
                >
                    <OnlinkButton
                        className='primary'
                        type='submit'
                    >
                        {translations.confirm}
                    </OnlinkButton>
                </form>
            </div>
        </div>
    );
}

LinkProfileConfirm.propTypes = {
    onLinkEmail: PropTypes.string,
    onLinkMembership: PropTypes.string,
    redirectParam: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        onLinkEmail: state.account.email,
        onLinkMembership: state.membership.name
    };
}

export default connect(mapStateToProps)(LinkProfileConfirm);
