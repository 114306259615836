// Unpublished Work © 2022-2024 Deere & Company.

import {
    CLOCKWISE_CIRCLE,
    CONTOUR,
    COUNTER_CLOCKWISE_CIRCLE,
    EIGHT_TO_TWO,
    ELEVEN_TO_FIVE,
    FIVE_TO_ELEVEN,
    FOUR_TO_TEN,
    NINE_TO_THREE,
    ONE_TO_SEVEN,
    SEVEN_TO_ONE,
    SINGLE_GREEN_TO_TEE,
    SINGLE_TEE_TO_GREEN,
    SIX_TO_TWELVE,
    TEN_TO_FOUR,
    THREE_TO_NINE,
    TWELVE_TO_SIX,
    TWO_COLORS_AWAY_LEFT,
    TWO_COLORS_AWAY_RIGHT,
    TWO_TO_EIGHT
} from 'OnLabor/workboard/constants/mowing-directions';

export const MOWING_DIRECTIONS_ORDER = {
    [ONE_TO_SEVEN]: 0,
    [TWO_TO_EIGHT]: 1,
    [THREE_TO_NINE]: 2,
    [FOUR_TO_TEN]: 3,
    [FIVE_TO_ELEVEN]: 4,
    [SIX_TO_TWELVE]: 5,
    [SEVEN_TO_ONE]: 6,
    [EIGHT_TO_TWO]: 7,
    [NINE_TO_THREE]: 8,
    [TEN_TO_FOUR]: 9,
    [ELEVEN_TO_FIVE]: 10,
    [TWELVE_TO_SIX]: 11,
    [CLOCKWISE_CIRCLE]: 12,
    [COUNTER_CLOCKWISE_CIRCLE]: 13,
    [CONTOUR]: 14,
    [SINGLE_GREEN_TO_TEE]: 15,
    [SINGLE_TEE_TO_GREEN]: 16,
    [TWO_COLORS_AWAY_LEFT]: 17,
    [TWO_COLORS_AWAY_RIGHT]: 18
};
