// Unpublished Work © 2021-2024 Deere & Company.

import {saveAs} from 'file-saver';

const DEFAULT_COLUMN_WIDTH = 10;
const EXTRA_CELL_PADDING = 2;

function autofitWorksheet(worksheet, {
    defaultColumnWidth = DEFAULT_COLUMN_WIDTH,
    extraCellPadding = EXTRA_CELL_PADDING
} = {}) {
    worksheet.columns.forEach((column) => {
        let maxColumnWidth = defaultColumnWidth;

        column.eachCell((cell) => {
            const columnWidth = cell.value ?
                cell.value.toString().length + extraCellPadding :
                defaultColumnWidth;

            if (columnWidth > maxColumnWidth) {
                maxColumnWidth = columnWidth;
            }
        });

        column.width = maxColumnWidth;
    });
}

async function downloadExcelFile(workbook, fileName) {
    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buffer]), fileName);
}

export {
    autofitWorksheet,
    downloadExcelFile
};
