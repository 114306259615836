// Unpublished Work © 2021-2024 Deere & Company.

export default {
    none: 'none',
    available: 'available',
    inUse: 'in_use',
    inShop: 'in_shop',
    outOfService: 'out_of_service',
    decommissioned: 'decommissioned',
    ordered: 'ordered',
    archived: 'archived'
};
