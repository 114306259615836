// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {IconAlertTriangle} from '@deere/icons';
import StatusText from 'OnLabor/workboard/common/status-text';
import {useDeepMemo} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {formatStatus} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import equipmentStatus from 'Common/constants/equipment-status';
import equipmentStatusConstants from 'Ui/constants/equipment-status-constants';

const ICON_STYLE = {
    style: {
        height: '14px',
        width: '14px'
    }
};

const DISABLED_EQUIPMENT_STATUSES = [equipmentStatus.inShop, equipmentStatus.inUse];

function isSameEquipmentArea(equipmentAreaId, equipment) {
    return !equipmentAreaId || !equipment.equipmentAreaId || equipment.equipmentAreaId === equipmentAreaId;
}

function createEquipmentItem(equipmentWithStatus, translations, hoursLabel) {
    const lastMeterHours = isNullOrUndefined(equipmentWithStatus.lastMeterHours) ?
        '' :
        formatNumber(equipmentWithStatus.lastMeterHours, {
            maximumFractionDigits: 0
        });

    const formattedStatus = formatStatus(equipmentWithStatus, translations);

    return {
        disabled: DISABLED_EQUIPMENT_STATUSES.includes(equipmentWithStatus.status),
        extraContent: (
            <StatusText
                dangerColor='#e7040f'
                okColor='#19a974'
                status={equipmentStatusConstants[equipmentWithStatus.status]?.color}
            >
                {`${lastMeterHours} ${hoursLabel} (${formattedStatus})`}
            </StatusText>
        ),
        id: equipmentWithStatus.equipmentId,
        subtitle: equipmentWithStatus.modelName,
        title: equipmentWithStatus.equipmentName
    };
}

function EquipmentMultiSelect(props) {
    const {
        appUserId,
        columnCount,
        eligibleEquipmentTypes,
        equipmentAreaId,
        equipmentByType,
        equipmentId,
        equipmentInUseMap,
        equipmentType,
        equipmentTypeId,
        equipmentTypeIndex,
        hoursLabel,
        onChange,
        translations,
        useColumnLayout,
        workItemId
    } = props;

    const filteredEquipmentForType = useDeepMemo(() => {
        const equipmentForType = equipmentByType.get(equipmentTypeId) || [];

        return equipmentForType.filter((equipment) => isSameEquipmentArea(equipmentAreaId, equipment));
    }, [equipmentAreaId, equipmentByType, equipmentTypeId]);

    const multiSelectLabel = React.useMemo(() => replaceTranslationNames(translations.ONLINK_ASSIGN_EQUIPMENT_TYPE, {
        '0': equipmentType
    }), [equipmentType, translations.ONLINK_ASSIGN_EQUIPMENT_TYPE]);

    const onEquipmentChange = React.useCallback(([id]) => {
        const selectedEquipmentId = equipmentId === id ? undefined : id;

        onChange(selectedEquipmentId, equipmentTypeIndex);
    }, [equipmentId, equipmentTypeIndex, onChange]);

    const textColor = React.useMemo(
        () => isNullOrUndefined(equipmentId) ? 'warning.main' : 'text.primary',
        [equipmentId]
    );

    const userHasEligibility = useDeepMemo(() => {
        const userHasEligibilityForType = eligibleEquipmentTypes?.find((type) => type.equipmentTypeId === equipmentTypeId);

        return !appUserId || userHasEligibilityForType && isSameEquipmentArea(equipmentAreaId, userHasEligibilityForType);
    }, [appUserId, equipmentAreaId, eligibleEquipmentTypes, equipmentTypeId]);

    const textWithRightIcon = React.useMemo(() => (
        <Stack direction='row'>
            <Typography
                color={textColor}
                fontWeight='600'
                marginLeft='5px'
                noWrap={true}
                overflow='hidden'
            >
                {multiSelectLabel}
            </Typography>
            {
                !userHasEligibility &&
                <Tooltip
                    placement='bottom'
                    title={translations.ONLINK_USER_EQUIPMENT_ELIGIBILITY}
                >
                    <Stack
                        alignItems='center'
                        direction='row'
                        marginLeft='5px'
                    >
                        <IconAlertTriangle iconAlertTriangle={ICON_STYLE}/>
                    </Stack>
                </Tooltip>
            }
        </Stack>
    ), [multiSelectLabel, textColor, translations.ONLINK_USER_EQUIPMENT_ELIGIBILITY, userHasEligibility]);

    const equipmentMultiSelectProps = React.useMemo(() => useColumnLayout ? {} : {
        width: 350
    }, [useColumnLayout]);

    const equipmentItems = filteredEquipmentForType.map((equipment) => {
        const workItemIdForEquipment = equipmentInUseMap.current?.get(equipment.equipmentId);
        const equipmentWithStatus = {
            ...equipment,
            status: workItemIdForEquipment && workItemIdForEquipment !== workItemId ?
                equipmentStatus.inUse :
                equipment.status
        };

        return createEquipmentItem(equipmentWithStatus, translations, hoursLabel);
    });

    return (
        <Grid
            item={true}
            xs={columnCount}
        >
            <MultiSelect
                {...equipmentMultiSelectProps}
                autoFocus={true}
                items={equipmentItems}
                label={textWithRightIcon}
                labels={{
                    emptyList: translations.NO_EQUIPMENT_FOUND,
                    placeholder: translations.FILES_SELECT_EQUIPMENT
                }}
                onChange={onEquipmentChange}
                selectedIds={[equipmentId]}
                single={true}
            />
        </Grid>
    );
}

EquipmentMultiSelect.propTypes = {
    appUserId: PropTypes.string,
    columnCount: PropTypes.number,
    eligibleEquipmentTypes: PropTypes.arrayOf(PropTypes.object),
    equipmentAreaId: PropTypes.string,
    equipmentByType: PropTypes.instanceOf(Map),
    equipmentId: PropTypes.string,
    equipmentInUseMap: PropTypes.reference,
    equipmentType: PropTypes.string,
    equipmentTypeId: PropTypes.string,
    equipmentTypeIndex: PropTypes.number,
    hoursLabel: PropTypes.string,
    onChange: PropTypes.func,
    translations: PropTypes.translations,
    useColumnLayout: PropTypes.bool,
    workItemId: PropTypes.string
};

export default EquipmentMultiSelect;
