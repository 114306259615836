// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';

const MAX_PERCENT = 100;

function getPercentWidth(percentFilled) {
    return Math.min(Math.max(percentFilled, 0), MAX_PERCENT);
}

function OnLinkProgressBar(props) {
    const {
        barClassName,
        children,
        fillClassName,
        percentFilled
    } = props;

    return (
        <div className={joinClassNames('onlink-progress-bar', barClassName)}>
            <div
                className={joinClassNames('onlink-progress-bar-fill', fillClassName)}
                style={{
                    width: `${getPercentWidth(percentFilled)}%`
                }}
            />
            {children}
        </div>
    );
}

OnLinkProgressBar.defaultProps = {
    percentFilled: 0
};

OnLinkProgressBar.propTypes = {
    barClassName: PropTypes.string,
    children: PropTypes.element,
    fillClassName: PropTypes.string,
    percentFilled: PropTypes.number
};

export default OnLinkProgressBar;
