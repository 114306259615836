// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {fetchEffectData} from 'Utils/react-utils';
import DataTable from 'Ui/components/common/data-table/data-table';
import PropTypes from 'Utils/prop-type-utils';

function EquipmentTab(props) {
    const {tableProps} = props;

    const [data, setData] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);
        const data = await tableProps.rowsData();

        if (isMounted()) {
            setData(data);
            setLoading(false);
        }
    }), [tableProps.equipment]);

    const filteredData = React.useMemo(() => tableProps.filterList ? tableProps.filterData(data) : data, [data, tableProps.filterList]);

    return (
        <DataTable
            {...tableProps}
            loading={tableProps.loading || loading}
            rows={filteredData}
        />
    );
}

EquipmentTab.propTypes = {
    tableProps: PropTypes.object
};

export default EquipmentTab;
