// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Panel from 'Ui/components/panel/panel';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import {useDeepMemo} from 'Utils/react-utils';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import {orderBy} from 'lodash';

function getToolTip(toolTipContent) {
    return (
        <Tooltip
            placement='bottom'
            title={toolTipContent}
        >
            <div className='basic-table-cell-block'>
                {toolTipContent}
            </div>
        </Tooltip>
    );
}

function createOperatorNotesRows(workboards) {
    const operatorNotes = [];

    workboards.forEach((workboard) => {
        workboard.jobs.forEach((job) => {
            job.workItems.forEach((workItem) => {
                const {
                    appUser,
                    equipment,
                    operatorNote,
                    operatorNoteTimeStamp
                } = workItem;

                if (operatorNote?.length) {
                    const operatorNameWithToolTip = getToolTip(appUser ? `${appUser.firstName} ${appUser.lastName}` : '');

                    const equipmentNames = equipment?.map((equipment) => equipment.equipmentName);

                    const equipmentName = equipmentNames && equipmentNames.length ? getToolTip(equipmentNames.join(', ')) : '';

                    const operatorNote = getToolTip(workItem.operatorNote);

                    operatorNotes.push({
                        operatorNameWithToolTip,
                        equipmentName,
                        operatorNote,
                        operatorNoteTimeStamp
                    });
                }
            });
        });
    });

    return orderBy(operatorNotes, 'operatorNoteTimeStamp', 'desc')
        .map(({
            operatorNameWithToolTip, equipmentName, operatorNote
        }) => {
            return {
                operatorNameWithToolTip,
                equipmentName,
                operatorNote
            };
        });
}

function OperatorNotes(props) {
    const {
        tile: {workboards},
        translations
    } = props;

    const headers = [
        translations.OPERATOR,
        translations.IWP_EQUIPMENT_LABEL,
        translations.NOTE
    ];

    const operatorNotes = useDeepMemo(() => createOperatorNotesRows(workboards), [workboards]);

    return (
        <Panel
            title={translations.ONLINK_TODAYS_OPERATOR_NOTES}
            translations={translations}
        >
            {
                operatorNotes.length ? (
                    <BasicTable
                        className='operator-notes'
                        headers={headers}
                        rows={operatorNotes}
                        vertical={true}
                    />
                ) : <NoDataMessage translations={translations}/>
            }
        </Panel>
    );
}

OperatorNotes.propTypes = {
    tile: PropTypes.object,
    translations: PropTypes.translations
};

export default OperatorNotes;
