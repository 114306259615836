// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {MINUTES_PER_HOUR} from 'Ui/constants/time-constants';
import {useDeepMemo} from 'Utils/react-utils';
import {formatLocalizedTime, WEEKDAY_AND_NUMERIC_DATE_OPTIONS} from 'Utils/time-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import moment from 'moment';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {WORKBOARD_STEP} from 'OnLabor/workboard/constants/workboard-steps';

function getReviewLinks(setActiveStep, translations, workboardCreationFlow) {
    const assignmentsStep = workboardCreationFlow === JOB ? WORKBOARD_STEP.three : WORKBOARD_STEP.two;
    const jobStep = workboardCreationFlow === JOB ? WORKBOARD_STEP.two : WORKBOARD_STEP.three;

    const mowingDirectionsStepLink = (
        <EditLink onClick={() => setActiveStep(WORKBOARD_STEP.one)}>
            {translations.REVIEW}
        </EditLink>
    );

    const jobsStepLink = (
        <EditLink onClick={() => setActiveStep(jobStep)}>
            {translations.REVIEW}
        </EditLink>
    );

    const equipmentStepLink = (
        <EditLink onClick={() => setActiveStep(WORKBOARD_STEP.three)}>
            {translations.REVIEW}
        </EditLink>
    );

    const assignmentsStepLink = (
        <EditLink onClick={() => setActiveStep(assignmentsStep)}>
            {translations.REVIEW}
        </EditLink>
    );

    return {
        assignmentsStepLink,
        equipmentStepLink,
        jobsStepLink,
        mowingDirectionsStepLink
    };
}

function incrementCountsForWorkItem(counts, workItem) {
    const {
        equipment = [],
        estDuration = 0
    } = workItem;

    counts.equipmentSelectedAmount += equipment.filter(({equipmentId}) => !isNullOrUndefined(equipmentId)).length;
    counts.totalHoursSelectedAmount += estDuration / MINUTES_PER_HOUR;
}

function AddWorkboardSummary(props) {
    const {
        setActiveStep,
        translations,
        values,
        workboardCreationFlow
    } = props;

    const {
        assignmentsStepLink,
        equipmentStepLink,
        jobsStepLink,
        mowingDirectionsStepLink
    } = getReviewLinks(setActiveStep, translations, workboardCreationFlow);

    const areasSelectedAmount = useDeepMemo(() => {
        return values.mowingPatterns.filter((mowingPattern) => !isNullOrUndefined(mowingPattern.cuttingStyle)).length;
    }, [values.mowingPatterns]);

    const {
        equipmentSelectedAmount,
        jobsSelectedAmount,
        operatorsSelectedAmount,
        totalHoursSelectedAmount
    } = useDeepMemo(() => {
        const counts = {
            equipmentSelectedAmount: 0,
            totalHoursSelectedAmount: 0
        };
        const uniqueIds = new Set();

        if (workboardCreationFlow === JOB) {
            const jobsSelectedAmount = Object.keys(values.jobs).reduce((numJobsSelected, area) => {
                const {[area]: jobsForArea} = values.jobs;

                jobsForArea.forEach((job) => {
                    job.workItems?.forEach((workItem) => {
                        const {appUserId} = workItem;

                        incrementCountsForWorkItem(counts, workItem);

                        if (appUserId) {
                            uniqueIds.add(appUserId);
                        }
                    });
                });

                return numJobsSelected + jobsForArea.length;
            }, 0);

            return {
                ...counts,
                jobsSelectedAmount,
                operatorsSelectedAmount: uniqueIds.size
            };
        }

        const selectedOperatorIds = Object.keys(values.workItemsByOperator);

        selectedOperatorIds.forEach((appUserId) => {
            values.workItemsByOperator[appUserId].forEach((workItem) => {
                const {jobTemplateId} = workItem;

                incrementCountsForWorkItem(counts, workItem);

                if (jobTemplateId) {
                    uniqueIds.add(jobTemplateId);
                }
            });
        });

        return {
            ...counts,
            jobsSelectedAmount: uniqueIds.size,
            operatorsSelectedAmount: selectedOperatorIds.length
        };
    }, [values.jobs, values.workItemsByOperator]);

    return (
        <div className='add-workboard-form'>
            <div className='summary-form'>
                <div className='bold-label'>
                    {`${translations.ONLINK_WORKBOARD}: ${formatLocalizedTime(moment(values.date), WEEKDAY_AND_NUMERIC_DATE_OPTIONS)} - ${values.name}`}
                </div>
                <div className='bold-label'>
                    {translations.SUMMARY}
                </div>
                <div className='summary-table'>
                    <div className='summary-table-row'>
                        {`${areasSelectedAmount} ${translations.ONLINK_AREAS_SELECTED}`}
                        {mowingDirectionsStepLink}
                    </div>
                    <div className='summary-table-row'>
                        {`${jobsSelectedAmount} ${translations.ONLINK_JOBS_SELECTED}`}
                        {jobsStepLink}
                    </div>
                    <div className='summary-table-row'>
                        {`${operatorsSelectedAmount} ${translations.ONLINK_OPERATORS_SELECTED}`}
                        {assignmentsStepLink}
                    </div>
                    <div className='summary-table-row'>
                        {`${equipmentSelectedAmount} ${translations.ONLINK_EQUIPMENT_SELECTED}`}
                        {equipmentStepLink}
                    </div>
                    <div className='summary-table-row'>
                        {`${totalHoursSelectedAmount} ${translations.UTILIZATION_REPORT_TOTAL_HOURS}`}
                        {assignmentsStepLink}
                    </div>
                </div>
            </div>
        </div>
    );
}

AddWorkboardSummary.propTypes = {
    setActiveStep: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object,
    workboardCreationFlow: PropTypes.string
};

export default AddWorkboardSummary;
