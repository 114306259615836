// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import JobDetails from 'OnLabor/my-work/my-work-tile/job-details';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnlinkButton from 'Ui/components/common/onlink-button';
import Panel from 'Ui/components/panel/panel';
import {getEquipment} from 'Services/equipment-service';
import {getWorkItemsForUser} from 'Services/user-service';
import {getWorkItemLogs} from 'Services/work-item-service';
import {joinClassNames} from 'Utils/html-utils';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {MYWORK_JOBS_DISPLAYED, TRUE} from 'Ui/constants/membership-properties';
import logStateConstants from 'OnEquip/equipment/service-form/utils/log-state-constants';
import {findLast, sortBy} from 'lodash';
import moment from 'moment';
import {VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';
import {openDialog, closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function getWorkItems(workItems, translations, setShowWorkItemDetails, properties, openWorkDetailsDialog, closeWorkDetailsDialog) {
    if (workItems.length === 0) {
        return (
            <h1 className='no-data-message'>
                {translations.ONLINK_NO_WORK_ASSIGNED}
            </h1>
        );
    }

    const withPpeList = properties.mywork_show_protection_icons === TRUE;

    return workItems.map((workItem, index) => (
        <React.Fragment key={workItem.workItemId}>
            {
                index > 0 &&
                <span className='panel-horizontal-divider'/>
            }
            <div className='work-item'>
                <div className={joinClassNames('work-item-info', withPpeList ? 'with-ppe-list' : '')}>
                    <div className='bold-body-text-section-title'>
                        {workItem.job.title}
                    </div>
                    {
                        workItem.job?.jobTemplate?.secondaryName &&
                        <div className='secondary-name'>
                            {`(${workItem.job.jobTemplate.secondaryName})`}
                        </div>
                    }
                    <JobDetails
                        equipment={workItem.equipment}
                        note={workItem.note}
                        ppeList={withPpeList ? workItem.job?.jobTemplate?.ppeList : null}
                        route={properties.mywork_show_route === TRUE ? workItem.route : null}
                        routeCustomDefn={workItem.routeCustomDefn}
                        translations={translations}
                    />
                </div>
                <OnlinkButton
                    className='primary'
                    onClick={() => {
                        setShowWorkItemDetails(workItem);
                        openWorkDetailsDialog({
                            closeDialog: () => {
                                closeWorkDetailsDialog();
                                setShowWorkItemDetails(null);
                            },
                            properties,
                            translations,
                            workItem
                        });
                    }}
                >
                    {translations.VIEW}
                </OnlinkButton>
            </div>
        </React.Fragment>
    ));
}

function setIfMounted(setWorkItems, setLoading, isMounted, sortedWorkItems) {
    if (isMounted()) {
        setWorkItems(sortedWorkItems);
        setLoading(false);
    }
}

function isAfterStartTime(workboard, now) {
    return workboard.startTime ?
        moment(workboard.startTime).isSameOrBefore(now, 'minutes') :
        true;
}

const JOBS_DISPLAYED_FILTER_CALLBACKS = {
    [MYWORK_JOBS_DISPLAYED.ALL]: (workboards) => workboards,
    [MYWORK_JOBS_DISPLAYED.CURRENT_WORKBOARD]: (workboards) => {
        const now = new Date();
        const dateSelect = formatTime(now, DATE_FORMATS.day);

        const currentWorkboard = findLast(workboards, (workboard) => workboard.dateSelect === dateSelect &&
            isAfterStartTime(workboard, now));

        return currentWorkboard ? [currentWorkboard] : [];
    },
    [MYWORK_JOBS_DISPLAYED.TODAY]: (workboards) => {
        const now = new Date();
        const dateSelect = formatTime(now, DATE_FORMATS.day);

        const hasFirstWorkboardStarted = workboards.some((workboard) => workboard.dateSelect === dateSelect &&
            isAfterStartTime(workboard, now));

        return workboards.filter((workboard) => hasFirstWorkboardStarted && workboard.dateSelect === dateSelect);
    }
};

function getWorkboardMap(workboards, jobsDisplayed = MYWORK_JOBS_DISPLAYED.CURRENT_WORKBOARD) {
    const filteredWorkboards = JOBS_DISPLAYED_FILTER_CALLBACKS[jobsDisplayed](workboards);

    return filteredWorkboards.reduce((allWorkboardsMap, workboard) => {
        allWorkboardsMap.set(workboard.workboardId, workboard);

        return allWorkboardsMap;
    }, new Map());
}

async function getWorkItemEquipment(workItemEquipment) {
    const {equipment} = await getEquipment(workItemEquipment.equipmentId);

    return {
        ...workItemEquipment,
        lastReportedMtgId: equipment?.lastReportedMtgId,
        equipmentTotalHours: equipment?.totalHours ? equipment.totalHours.toFixed(1) : ''
    };
}

async function getWorkItemsWithTimeLogs(workItemsForUser, membershipId, workboardMap, {
    myJdPermissions,
    isMigrated
}) {
    const workItemLogPromise = workItemsForUser.map((workItemIteration) => {
        return getWorkItemLogs(workItemIteration.workItemId);
    });

    const workItemLogArray = await Promise.all(workItemLogPromise);

    const workItemPromises = workItemLogArray.map(async ({workItemLogs}, index) => {
        const workItem = workItemsForUser[index];
        const hasStoppedLogs = workItemLogs?.some((log) => log.logType === logStateConstants.stop);

        if (!hasStoppedLogs && workItem.membershipId === membershipId) {
            let equipmentPromises;

            if (isMigrated) {
                equipmentPromises = myJdPermissions[VIEW_EQUIPMENT_DETAILS] ?
                    workItem.equipment?.map(getWorkItemEquipment) :
                    null;
            } else {
                equipmentPromises = workItem.equipment?.map(getWorkItemEquipment);
            }

            const jobsWorkBoard = workboardMap.get(workItem.job.workboardId);

            return {
                ...workItem,
                workItemLogs,
                equipment: equipmentPromises ? await Promise.all(equipmentPromises) : null,
                jobSeq: workItem.job.seq,
                workboardStartTime: jobsWorkBoard?.startTime ? moment(jobsWorkBoard.startTime) : null
            };
        }

        return null;
    });

    const resolvedWorkItems = await Promise.all(workItemPromises);

    return resolvedWorkItems.filter((workItem) => workItem);
}

function MyWorkTile(props) {
    const {
        membershipId,
        properties,
        translations,
        workboards,
        hasPermission,
        myJdPermissions,
        isMigrated,
        openWorkDetailsDialog,
        closeWorkDetailsDialog
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [workItems, setWorkItems] = React.useState([]);
    const [showWorkItemDetails, setShowWorkItemDetails] = React.useState(null);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (!showWorkItemDetails) {
            setLoading(true);

            const workboardMap = getWorkboardMap(workboards, properties.mywork_jobs_displayed);
            const canSeeAllJobs = properties.mywork_jobs_displayed === MYWORK_JOBS_DISPLAYED.ALL && hasPermission;
            const dateSelect = canSeeAllJobs ?
                null :
                formatTime(new Date(), DATE_FORMATS.day);

            const {workItems: workItemsForUser} = await getWorkItemsForUser(dateSelect);
            const filteredWorkItems = workItemsForUser.filter((workItem) => workboardMap.has(workItem?.job?.workboardId));

            const workItemsWithTimeLogs = await getWorkItemsWithTimeLogs(filteredWorkItems, membershipId, workboardMap, {
                myJdPermissions,
                isMigrated
            });

            setIfMounted(setWorkItems, setLoading, isMounted, workItemsWithTimeLogs);
        }
    }), [showWorkItemDetails, properties.mywork_jobs_displayed, properties.mywork_show_protection_icons]);

    const sortedWorkItems = useDeepMemo(
        () => sortBy(workItems, 'workboardStartTime', 'jobSeq'),
        [workItems]
    );

    return (
        <Panel
            className='my-work-tile'
            title='ONLINK_MY_WORK'
            translations={translations}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                {getWorkItems(sortedWorkItems, translations, setShowWorkItemDetails, properties, openWorkDetailsDialog, closeWorkDetailsDialog)}
            </LoadingWrapper>
        </Panel>
    );
}

MyWorkTile.propTypes = {
    closeWorkDetailsDialog: PropTypes.func,
    hasPermission: PropTypes.bool,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openWorkDetailsDialog: PropTypes.func,
    properties: PropTypes.object,
    translations: PropTypes.translations,
    workboards: PropTypes.arrayOf(PropTypes.object)
};

export function mapDispatchToProps(dispatch) {
    return {
        openWorkDetailsDialog(props) {
            dispatch(openDialog(dialogTypes.WORK_ITEM_DETAILS_DIALOG, props));
        },
        closeWorkDetailsDialog() {
            dispatch(closeDialog(dialogTypes.WORK_ITEM_DETAILS_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(MyWorkTile);
