// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {IconEdit} from '@deere/icons';
import {NONE, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {getUOMOverrideUnits} from 'Common/constants/uom-override-units';
import {
    CLIPPING_YIELD,
    CLIPPING_YIELD_VOLUME,
    CLIPPING_YIELD_WEIGHT,
    FIRMNESS,
    FIRMNESS_F355_EQUIVALENTS,
    FIRMNESS_GRAVITIES,
    GREEN_SPEED,
    WSPD
} from 'Common/constants/data-group-constants';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#fff');

function getUnitOfMeasure(translations) {
    return {
        [UNIT_OF_MEASURE.METRIC]: {
            label: translations.METRIC
        },
        [UNIT_OF_MEASURE.ENGLISH]: {
            label: translations.UNIT_OF_MEASURE0
        }
    };
}

function PreferenceSettings(props) {
    const {
        membership,
        setIsEditing,
        translations
    } = props;

    const UNITS = getUOMOverrideUnits(translations);

    const preferredOverrides = React.useMemo(
        () => membership.preferredDataTypes.reduce(
            (overridesObject, currentOverride) => ({
                ...overridesObject,
                [currentOverride.dataType]: currentOverride.measurement
            }),
            {}),
        [membership.preferredDataTypes]
    );

    const unitOfMeasure = membership.unitOfMeasure || UNIT_OF_MEASURE.ENGLISH;
    const unitOfMeasureLabel = getUnitOfMeasure(translations)[unitOfMeasure].label;

    const preferredOverridesClippingYield = preferredOverrides[CLIPPING_YIELD] || preferredOverrides[CLIPPING_YIELD_VOLUME] || preferredOverrides[CLIPPING_YIELD_WEIGHT];
    const preferredOverridesFirmness = preferredOverrides[FIRMNESS] || preferredOverrides[FIRMNESS_F355_EQUIVALENTS] || preferredOverrides[FIRMNESS_GRAVITIES];

    return (
        <div className='settings-body preference-settings'>
            <div className='settings-group horizontal-reverse'>
                <div className='edit-button'>
                    <OnlinkButton
                        className='primary'
                        leftIcon={
                            <IconEdit
                                iconEdit={ICON_EDIT_STYLE}
                                primary={PRIMARY_STYLE}
                            />
                        }
                        onClick={setIsEditing}
                    >
                        {translations.EDIT}
                    </OnlinkButton>
                </div>
                <div>
                    <div className='unit-of-measure'>
                        <div className='settings-block-row'>
                            {translations.UNIT_OF_MEASURE}
                        </div>
                        <div className='settings-block-row'>
                            {unitOfMeasureLabel}
                        </div>
                    </div>
                    {
                        <div className='overrides'>
                            <div className='settings-block-row'>
                                {translations.ONLINK_DEFAULTS}
                            </div>
                            <div className='settings-block-row'>
                                {`${translations.WIND_SPEED}: ${preferredOverrides[WSPD] ?
                                    UNITS[preferredOverrides[WSPD]].title : UNITS[NONE].title}`}
                            </div>
                            <div className='settings-block-row'>
                                {`${translations.ONLINK_GREEN_SPEED}: ${preferredOverrides[GREEN_SPEED] ?
                                    UNITS[preferredOverrides[GREEN_SPEED]].title : UNITS[NONE].title}`}
                            </div>
                            <div className='settings-block-row'>
                                {`${translations.CLIPPING_YIELD}: ${ preferredOverridesClippingYield ?
                                    UNITS[preferredOverridesClippingYield].title : UNITS[NONE].title}`}
                            </div>
                            <div className='settings-block-row'>
                                {`${translations.Firmness}: ${ preferredOverridesFirmness ?
                                    UNITS[preferredOverridesFirmness].title : UNITS[NONE].title}`}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

PreferenceSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(PreferenceSettings);
