// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';

function PanelErrorMessage() {
    return (
        <span>
            {'An error occurred while loading data for this panel'}
        </span>
    );
}

PanelErrorMessage.propTypes = {
};

export default PanelErrorMessage;
