// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {OverlayView} from '@react-google-maps/api';
import {IconRemove} from '@deere/icons';

const ICON_REMOVE_STYLE = {
    style: {
        width: '24px',
        height: '24px'
    }
};

function getPixelPositionOffset(width, height, anchor) {
    return {
        x: anchor.x - width / 2,
        y: anchor.y - height
    };
}

function blockMapEvents(ref) {
    if (ref) {
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref);
    }
}

function OnLinkInfoBox(props) {
    const {
        anchor,
        children,
        className,
        closeHandler,
        onLoad,
        position
    } = props;

    return (
        <OverlayView
            getPixelPositionOffset={(width, height) => getPixelPositionOffset(width, height, anchor)}
            mapPaneName={OverlayView.FLOAT_PANE}
            onLoad={onLoad}
            position={position}
        >
            <div
                className={joinClassNames('onlink-info-box', className)}
                ref={blockMapEvents}
            >
                {
                    closeHandler &&
                    <OnLinkIconButton
                        className='onlink-info-box-close'
                        onClick={closeHandler}
                    >
                        <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
                    </OnLinkIconButton>
                }
                <div className='onlink-info-box-content'>
                    {children}
                </div>
                <div className='onlink-info-box-arrow'/>
            </div>
        </OverlayView>
    );
}

OnLinkInfoBox.defaultProps = {
    anchor: {
        x: 0,
        y: 0
    }
};

OnLinkInfoBox.propTypes = {
    anchor: PropTypes.xyCoordinates,
    children: PropTypes.node,
    className: PropTypes.string,
    closeHandler: PropTypes.func,
    onLoad: PropTypes.func,
    position: PropTypes.mapPosition.isRequired
};

export default OnLinkInfoBox;
