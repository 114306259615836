// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {noop} from 'lodash';

const AuthContext = React.createContext({
    setAuthorized: (authorized) => noop(authorized)
});

function useAuthContext() {
    return React.useContext(AuthContext);
}

function AuthContextProvider(props) {
    const {children} = props;

    const [authorized, setAuthorized] = React.useState();
    const setAuthorizedCallback = React.useCallback((value) => setAuthorized(Boolean(value)), []);

    return (
        <AuthContext.Provider
            value={{
                authorized,
                setAuthorized: setAuthorizedCallback
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

AuthContextProvider.propTypes = {
    children: PropTypes.node
};

export {
    useAuthContext,
    AuthContextProvider
};
