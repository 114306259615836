// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import PanelData from 'Ui/components/panel/panel-data';
import PanelDateLabel from 'Ui/components/panel/panel-date-label';
import {convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {getValueWithThreshold} from 'Utils/threshold-utils';
import {getMinuteOrHourMessage} from 'Utils/time-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {DEGREES_F} from 'Common/constants/data-unit-constants';

function Temperature(props) {
    const {
        featureToggles,
        iconCode,
        obsTimeLocal,
        phrase22char,
        temp,
        tempMax24hour,
        tempMin24hour,
        thresholds,
        translations,
        unitOfMeasure
    } = props;

    const conversionConfig = {
        dataUnit: DEGREES_F,
        featureToggles,
        translations,
        unitOfMeasure
    };

    const airTempThreshold = thresholds.find((threshold) => threshold.dataType === 'air_temp');

    const temperature = convertToFormattedUoM(temp, conversionConfig);
    const temperatureMax24hour = convertToFormattedUoM(tempMax24hour, conversionConfig);
    const temperatureMin24hour = convertToFormattedUoM(tempMin24hour, conversionConfig);

    const tempSpan = getValueWithThreshold(airTempThreshold, temp, temperature);

    return (
        <div className='temperature internal-panel'>
            <PanelData
                className='panel-row title-layer'
                label={tempSpan}
                translations={translations}
                value={(
                    <img
                        alt={phrase22char}
                        className='dashboard-panel-icon'
                        src={getWeatherIcon(iconCode)}
                    />
                )}
            />
            <span className='panel-row'>{phrase22char}</span>
            <div className='panel-row'>
                <div className='panel-section'>
                    <span className='title'>{translations.WEATHER_HIGH}</span>
                    <div className='degree'>
                        {temperatureMax24hour}
                    </div>
                </div>
                <div className='panel-section'>
                    <span className='title'>{translations.WEATHER_LOW}</span>
                    <div className='degree'>
                        {temperatureMin24hour}
                    </div>
                </div>
            </div>
            <PanelDateLabel
                className='panel-row'
                date={getMinuteOrHourMessage(translations, obsTimeLocal)}
            />
        </div>
    );
}

Temperature.propTypes = {
    featureToggles: PropTypes.featureToggles,
    iconCode: PropTypes.stringOrNumber,
    obsTimeLocal: PropTypes.string,
    phrase22char: PropTypes.string,
    temp: PropTypes.number,
    tempMax24hour: PropTypes.number,
    tempMin24hour: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string
};

export default Temperature;
