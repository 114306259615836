// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Inventory from 'Ui/pages/onequip/parts/inventory';
import History from 'Ui/pages/onequip/parts/history';
import Recommended from 'Ui/pages/onequip/parts/recommended';
import Orders from 'Ui/pages/onequip/parts/orders';
import {getAvailableSettings} from 'Utils/settings-utils';
import {Route, Switch, withRouter} from 'react-router-dom';
import {fetchEffectData} from 'Utils/react-utils';
import {INVENTORY} from 'Common/constants/routes';
import SettingsDialog from 'Ui/components/settings/settings-dialog';
import {clsx} from 'clsx';

const partTabs = {
    history: History,
    inventory: Inventory,
    orders: Orders,
    recommended: Recommended
};

function createFleetSettingsDialogRoute(settings) {
    const fleetTile = settings.find((tile) => tile.tileId === 'Fleet');

    if (!fleetTile) {
        return null;
    }

    const lowerCaseTileId = fleetTile.tileId.toLowerCase();
    const basePath = `${INVENTORY}/settings/${lowerCaseTileId}`;

    return (
        <Route
            key={fleetTile.tileId}
            path={[`${basePath}/:tab`, basePath]}
        >
            <SettingsDialog
                basePath={INVENTORY}
                tileId={`settings/${lowerCaseTileId}`}
                {...fleetTile.dialogProps}
            />
        </Route>
    );
}

function Parts(props) {
    const {
        appKeys,
        featureToggles,
        match,
        membership,
        myJdPermissions,
        permissions,
        translations
    } = props;

    const [availableSettings, setAvailableSettings] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const settings = await getAvailableSettings(translations, membership, appKeys, permissions, myJdPermissions, featureToggles);

        if (isMounted()) {
            setAvailableSettings(settings);
        }
    }), [membership, appKeys]);

    const CurrentPageComponent = partTabs[match.params.type];

    const classNames = clsx('parts-onlink-tile', {
        [match.params.type]: true
    });

    return (
        <div className={match.params.type === 'history' ? 'ops-center-table-container' : classNames}>
            <CurrentPageComponent
                featureToggles={featureToggles}
                membership={membership}
                myJdPermissions={myJdPermissions}
                translations={translations}
            />
            <Switch>
                {
                    createFleetSettingsDialogRoute(availableSettings)
                }
            </Switch>
        </div>
    );
}

Parts.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    match: PropTypes.match,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    permissions: PropTypes.legacyPermissions,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions
    };
}

export default connect(mapStateToProps)(withRouter(Parts));
