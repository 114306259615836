// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';

function PresentationNotes(props) {
    const {
        membershipNote,
        tileOpacityOn,
        note,
        fontSizeNotes
    } = props;

    const noteClassName = clsx('note', {
        'opacity-on': tileOpacityOn,
        'opacity-off': !tileOpacityOn
    });

    return (
        <>
            {
                membershipNote && <div
                    className={noteClassName}
                    style={{
                        fontSize: fontSizeNotes
                    }}
                >
                    {membershipNote}
                </div>
            }
            {
                note && <div
                    className={noteClassName}
                    style={{
                        fontSize: fontSizeNotes
                    }}
                >
                    {note}
                </div>
            }
        </>
    );
}

PresentationNotes.propTypes = {
    fontSizeNotes: PropTypes.string,
    membershipNote: PropTypes.string,
    note: PropTypes.string,
    tileOpacityOn: PropTypes.bool
};

export default PresentationNotes;
