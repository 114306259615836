// Unpublished Work © 2022-2024 Deere & Company.

import {replaceTranslationNames} from 'Utils/translation-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {IconRemove} from '@deere/icons';

const ICON_REMOVE_STYLE = {
    style: {
        height: '28px',
        width: '34px'
    }
};

function SelectedPartQuantityFromStock({
    currencyPreference,
    featureToggles,
    part,
    onChange,
    onClear,
    setValid,
    translations
}) {
    const valueGreaterThanOrEqual = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    });

    const valueLessThanOrEqual = `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${part.stock}`;

    return (
        <div
            className='parts-selected-row'
            key={part.id}
        >
            <div className='part-label'>
                <div className='title'>
                    {part.title}
                </div>
                <div className='sub-title'>
                    {part.subtitle}
                </div>
            </div>
            <ValidationInput
                disabled={part.stock === 0}
                errors={{
                    rangeUnderflow: valueGreaterThanOrEqual,
                    rangeOverflow: valueLessThanOrEqual
                }}
                label={translations['Observation.QUANTITY']}
                max={part.stock}
                min={0}
                name={`${part.id}quantity`}
                onChange={(event) => onChange('quantity', event.target.value, part)}
                required={true}
                setValid={setValid}
                step='any'
                tabIndex={0}
                type='number'
                value={part.quantity}
            />
            <div className='quantity-from-stock-end-column'>
                <div className='part-selected-cost-column'>
                    <div className='parts-selected-cost-label'>{translations.ONLINK_STOCK}</div>
                    {part.stock}
                </div>
                <div className='part-selected-cost-column'>
                    <div className='parts-selected-cost-label'>{translations.ONLINK_UNIT_COST}</div>
                    {getFormattedCurrency(part.unitCost, {
                        currencyPreference,
                        featureToggles
                    })}
                </div>
                <div className='part-selected-cost-column'>
                    <div className='parts-selected-cost-label'>{translations.ONLINK_TOTAL_COST}</div>
                    {getFormattedCurrency(part.quantity * part.unitCost, {
                        currencyPreference,
                        featureToggles
                    })}
                </div>
                <button
                    className='close'
                    onClick={() => onClear(part)}
                >
                    <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
                </button>
            </div>
        </div>
    );
}

SelectedPartQuantityFromStock.propTypes = {
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    part: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default SelectedPartQuantityFromStock;
