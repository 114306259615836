// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import WaterFlowInputList from 'Ui/components/manual-data/form-components/water-flow-input-list';
import {onManualDataChange, validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {isEmptyString, isNullOrUndefined} from 'Common/utils/validation-utils';
import {MAX_NUMERIC_VALUE, MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {GALLONS, LITERS} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import moment from 'moment';
import {cloneDeep} from 'lodash';

function getManualDataUnit(manualDataUnit, unitOfMeasure) {
    return manualDataUnit || (unitOfMeasure === UNIT_OF_MEASURE.METRIC ? LITERS : GALLONS);
}

function valueOrEmptyString(value) {
    return isNullOrUndefined(value) ? '' : value;
}

function hasAllEmptyValues(values) {
    return values.every((value) => Object.keys(value).every((key) => isEmptyString(value[key])));
}

function getMemoizedData(unitOfMeasure, translations, values) {
    const manualDataMap = useDeepMemo(() => {
        const {manualData = [] } = values;

        return manualData.reduce((manualDataByType, manualData) => {
            manualDataByType.set(manualData.data_type, manualData);

            return manualDataByType;
        }, new Map());
    }, [values.manualData]);

    const irrigation24Hour = manualDataMap.get('water_flow') || {};
    const irrigationDryRun = manualDataMap.get('water_flow_night') || {};
    const transfer24Hour = manualDataMap.get('water_transfer') || {};
    const transferDryRun = manualDataMap.get('water_transfer_night') || {};

    const irrigation24HourValues = irrigation24Hour.data_values || [];
    const transfer24HourValues = transfer24Hour.data_values || [];

    return {
        irrigation24HourUnit: getManualDataUnit(irrigation24Hour.data_unit, unitOfMeasure),
        irrigation24HourValues,
        irrigationDryRunUnit: getManualDataUnit(irrigationDryRun.data_unit, unitOfMeasure),
        irrigationDryRunValue: valueOrEmptyString(irrigationDryRun.value),
        isInvalid: isEmptyString(irrigationDryRun.value) && isEmptyString(transferDryRun.value) &&
            hasAllEmptyValues(irrigation24HourValues) && hasAllEmptyValues(transfer24HourValues),
        transfer24HourUnit: getManualDataUnit(transfer24Hour.data_unit, unitOfMeasure),
        transfer24HourValues,
        transferDryRunUnit: getManualDataUnit(transferDryRun.data_unit, unitOfMeasure),
        transferDryRunValue: valueOrEmptyString(transferDryRun.value)
    };
}

function WaterFlowEntryForm(props) {
    const {
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const [validatedDate, setValidatedDate] = React.useState();
    const {
        irrigation24HourUnit,
        irrigation24HourValues,
        irrigationDryRunUnit,
        irrigationDryRunValue,
        isInvalid,
        transfer24HourUnit,
        transfer24HourValues,
        transferDryRunUnit,
        transferDryRunValue
    } = getMemoizedData(membership.unitOfMeasure, translations, values);

    React.useEffect(() => {
        setValidatedDate(validateDate(values.timeSample, setValid));
    }, [values.timeSample]);

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        errors: {
            customError: translations.REQUIRED_FIELD_TEXT,
            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`,
            rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${MIN_NUMERIC_VALUE}`
        },
        invalidCustomError: isInvalid,
        max: MAX_NUMERIC_VALUE,
        min: MIN_NUMERIC_VALUE,
        setValid,
        step: 'any',
        tabIndex: 0,
        type: 'number'
    };

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
            />
            <WaterFlowInputList
                addItemLabel={translations.ONLINK_ADD_LOCATION}
                dataUnit={irrigation24HourUnit}
                headerLabel={translations.ONLINK_24_HOUR_IRRIGATION}
                isInvalid={isInvalid}
                name='water_flow'
                onChange={(value, name, index) => {
                    const clonedIrrigation24HourValues = cloneDeep(irrigation24HourValues);

                    clonedIrrigation24HourValues[index][name] = value;

                    onManualDataChange({
                        dataUnit: irrigation24HourUnit,
                        name: 'water_flow',
                        setValues,
                        value: clonedIrrigation24HourValues
                    });
                }}
                setValid={setValid}
                setValues={setValues}
                translations={translations}
                values={irrigation24HourValues}
            />
            <ValidationInput
                {...sharedInputProps}
                label={`${translations.ONLINK_IRRIGATION_DRY_RUN} (${translations[irrigationDryRunUnit]})`}
                name='water_flow_night'
                onChange={(event) => onManualDataChange({
                    dataUnit: irrigationDryRunUnit,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                value={irrigationDryRunValue}
            />
            <WaterFlowInputList
                addItemLabel={translations.ONLINK_ADD_LOCATION}
                dataUnit={transfer24HourUnit}
                headerLabel={translations.ONLINK_24_HOUR_TRANSFER}
                isInvalid={isInvalid}
                name='water_transfer'
                onChange={(value, name, index) => {
                    const clonedTransfer24HourValues = cloneDeep(transfer24HourValues);

                    clonedTransfer24HourValues[index][name] = value;

                    onManualDataChange({
                        dataUnit: transfer24HourUnit,
                        name: 'water_transfer',
                        setValues,
                        value: clonedTransfer24HourValues
                    });
                }}
                setValid={setValid}
                setValues={setValues}
                translations={translations}
                values={transfer24HourValues}
            />
            <ValidationInput
                {...sharedInputProps}
                label={`${translations.ONLINK_TRANSFER_DRY_RUN} (${translations[transferDryRunUnit]})`}
                name='water_transfer_night'
                onChange={(event) => onManualDataChange({
                    dataUnit: transferDryRunUnit,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                value={transferDryRunValue}
            />
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

WaterFlowEntryForm.propTypes = {
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default WaterFlowEntryForm;
