// Unpublished Work © 2022-2024 Deere & Company.

export const ONE_TO_SEVEN = '1 - 7';
export const TWO_TO_EIGHT = '2 - 8';
export const THREE_TO_NINE = '3 - 9';
export const FOUR_TO_TEN = '4 - 10';
export const FIVE_TO_ELEVEN = '5 - 11';
export const SIX_TO_TWELVE = '6 - 12';
export const SEVEN_TO_ONE = '7 - 1';
export const EIGHT_TO_TWO = '8 - 2';
export const NINE_TO_THREE = '9 - 3';
export const TEN_TO_FOUR = '10 - 4';
export const ELEVEN_TO_FIVE = '11 - 5';
export const TWELVE_TO_SIX = '12 - 6';
export const CLOCKWISE_CIRCLE = 'Circle - Clockwise';
export const COUNTER_CLOCKWISE_CIRCLE = 'Circle - Counter Clockwise';
export const CONTOUR = 'Contour';
export const SINGLE_GREEN_TO_TEE = 'Single - Green to Tee';
export const SINGLE_TEE_TO_GREEN = 'Single - Tee to Green';
export const TWO_COLORS_AWAY_LEFT = '50/50 - Away Left';
export const TWO_COLORS_AWAY_RIGHT = '50/50 - Away Right';
