// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconCheckmark} from '@deere/icons';
import {Tooltip} from '@deere/isg.component-library';
import {clsx} from 'clsx';
import {createIconFillStyle} from 'Utils/icon-utils';

const PRIMARY_STYLE = createIconFillStyle('#fff');

function getTileCheckmarkStyle(checked) {
    return {
        style: {
            height: '24px',
            marginLeft: '2px',
            marginTop: '3px',
            opacity: checked ? '1' : '0',
            width: '24px'
        }
    };
}

function TileCheckbox(props) {
    const {
        checked,
        disabled,
        id,
        name,
        onChange
    } = props;

    const falsyCheckedValue = React.useMemo(() => Boolean(checked), [checked]);
    const className = React.useMemo(() => clsx('tile-checkbox', {
        checked: falsyCheckedValue,
        disabled
    }), [disabled, falsyCheckedValue]);

    return (
        <label
            className={className}
            htmlFor={id}
        >
            <Tooltip
                placement='bottom'
                title={name}
            >
                <div className='tile-checkbox-name'>
                    {name}
                </div>
            </Tooltip>
            <div className='circle'>
                <IconCheckmark
                    iconCheckmark={getTileCheckmarkStyle(falsyCheckedValue)}
                    primary={PRIMARY_STYLE}
                />
            </div>
            <input
                checked={falsyCheckedValue}
                className='tile-checkbox-input'
                disabled={disabled}
                id={id}
                onChange={onChange}
                type='checkbox'
            />
        </label>
    );
}

TileCheckbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func
};

export default TileCheckbox;
