// Unpublished Work © 2022-2024 Deere & Company.

import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import React from 'react';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import PropTypes from 'Utils/prop-type-utils';
import moment from 'moment';
import ExpenseDialogForm from 'OnEquip/equipment/common/expense-dialog-form';
import {FORM_EXPENSE_TYPES as expenseTypeConstants} from 'Ui/constants/equipment-expense-types-constants';
import {createEquipmentExpense, getEquipmentExpense, updateEquipmentExpense} from 'Services/equipment-service';
import {formatTime} from 'Utils/time-utils';
import {asNumber} from 'Utils/conversion-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {fetchEffectData} from 'Utils/react-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function setAmountOnPayload(amount, expensesPayload) {
    const parsedAmount = asNumber(amount);

    if (!isNullOrUndefined(parsedAmount)) {
        expensesPayload.amount = parsedAmount;
    }
}

function initializeState() {
    const [amount, setAmount] = React.useState(() => null);
    const [dateSelect, setDateSelect] = React.useState(() => moment());
    const [expenseType, setExpenseType] = React.useState(() => expenseTypeConstants[0].id);
    const [loading, setLoading] = React.useState(false);

    return {
        amount,
        dateSelect,
        expenseType,
        loading,
        setAmount,
        setDateSelect,
        setExpenseType,
        setLoading
    };
}

function AddEditExpenseDialog(props) {
    const {
        closeDialog,
        equipment,
        invalidInputs,
        setValid,
        translations,
        updateFleetEquipment,
        equipmentExpenseId
    } = props;

    const isEdit = Boolean(equipmentExpenseId);

    const {
        amount,
        dateSelect,
        expenseType,
        loading,
        setAmount,
        setDateSelect,
        setExpenseType,
        setLoading
    } = initializeState();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (isEdit) {
            setLoading(true);
            const {equipmentExpense} = await getEquipmentExpense(equipmentExpenseId);

            if (isMounted()) {
                setAmount(equipmentExpense.amount);
                setDateSelect(moment(equipmentExpense.dateSelect));
                setExpenseType(equipmentExpense.expenseType);

                setLoading(false);
            }
        }
    }), []);

    async function onSave() {
        const expensesPayload = {
            dateSelect: formatTime(dateSelect, 'YYYYMMDD'),
            equipmentId: equipment.equipmentId,
            expenseType,
            equipmentExpenseId
        };

        setAmountOnPayload(amount, expensesPayload);

        setLoading(true);

        try {
            if (isEdit) {
                await updateEquipmentExpense(expensesPayload);
            } else {
                await createEquipmentExpense(expensesPayload);
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }

        updateFleetEquipment();
        closeDialog();
    }

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className={'add-expense-dialog dropdown-overflow-dialog'}
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0 || loading}
            footerLoading={loading}
            onSave={onSave}
            saveLabel={translations.save}
            title={
                isEdit ?
                    translations.ONLINK_EDIT_EQUIPMENT_EXPENSE :
                    translations.ONLINK_ADD_EQUIPMENT_EXPENSE
            }
            translations={translations}
        >
            <LoadingWrapper
                className={'onlink-loading-icon'}
                loading={loading}
                size={'50px'}
            >
                <ExpenseDialogForm
                    amount={amount}
                    dateSelect={dateSelect}
                    expenseType={expenseType}
                    setAmount={setAmount}
                    setDateSelect={setDateSelect}
                    setExpenseType={setExpenseType}
                    setValid={setValid}
                    translations={translations}
                />
            </LoadingWrapper>
        </FormDialog>
    );
}

AddEditExpenseDialog.propTypes = {
    closeDialog: PropTypes.func,
    equipment: PropTypes.equipment,
    equipmentExpenseId: PropTypes.string,
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.ADD_EDIT_EXPENSE_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(AddEditExpenseDialog));
