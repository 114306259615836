// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import Box from '@mui/material/Box';
import React from 'react';
import DataTable from 'Ui/components/common/data-table/data-table';
import {dataTableFormatTime} from 'Utils/time-utils';
import {numericCompare} from 'Ui/models/maintenance';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {BY_EQUIPMENT_TYPE, BY_MODEL} from 'Ui/components/graph/constants/graph-filters';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

const NAME_COLUMN_INDEX = 0;
const UPTIME_COLUMN_INDEX = 1;
const DOWNTIME_COLUMN_INDEX = 2;
const PERCENT_COLUMN_INDEX = 3;

function getFormattedRowValue(value) {
    return formatNumber(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export function getHeaderColumnName(secondarySelector, translations) {
    switch (secondarySelector) {
        case BY_EQUIPMENT_TYPE:
            return translations.ONLINK_EQUIPMENT_TYPE;
        case BY_MODEL:
            return translations.MODEL;
        default:
            return translations.IWP_EQUIPMENT_LABEL;
    }
}

function getColumns(secondarySelector, translations, uptimeReportData) {
    const columns = [
        {
            Header: getHeaderColumnName(secondarySelector, translations),
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky'
        },
        {
            Header: translations.ONLINK_UPTIME,
            accessor: 'uptime',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod: numericCompare,
            Cell: (row) => getFormattedRowValue(row.value)
        },
        {
            Header: translations.ONLINK_DOWNTIME,
            accessor: 'downtime',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod: numericCompare,
            Cell: (row) => getFormattedRowValue(row.value)
        },
        {
            Header: translations.ONLINK_PERCENT,
            accessor: 'ratio',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod: numericCompare,
            Cell: (row) => `${getFormattedRowValue(row.value)} ${UNITS.percent}`
        }
    ];

    if (!isNullOrUndefined(uptimeReportData.totals)) {
        const {
            dateScale,
            dateSelect,
            downtime,
            ratio,
            uptime
        } = uptimeReportData.totals;

        const formattedDate = dataTableFormatTime(dateSelect, dateScale);

        return [
            {
                ...columns[NAME_COLUMN_INDEX],
                Footer: (
                    <span className='bold-text'>{`${formattedDate} ${translations.ONLINK_TOTAL}`}</span>
                )
            },
            {
                ...columns[UPTIME_COLUMN_INDEX],
                Footer: (
                    <span className='bold-text'>{`${getFormattedRowValue(uptime)}`}</span>
                )
            },
            {
                ...columns[DOWNTIME_COLUMN_INDEX],
                Footer: (
                    <span className='bold-text'>{`${getFormattedRowValue(downtime)}`}</span>
                )
            },
            {
                ...columns[PERCENT_COLUMN_INDEX],
                Footer: (
                    <span className='bold-text'>{`${getFormattedRowValue(ratio)} ${UNITS.percent}`}</span>
                )
            }
        ];
    }

    return columns;
}

function UptimeReportDataTable(props) {
    const {
        secondarySelector,
        translations,
        uptimeReportData
    } = props;

    return (
        <Box
            className='graph-table-container'
            position='relative'
        >
            <DataTable
                columns={getColumns(secondarySelector, translations, uptimeReportData)}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                rows={uptimeReportData.rollupsForSelector}
                translations={translations}
            />
        </Box>
    );
}

UptimeReportDataTable.propTypes = {
    secondarySelector: PropTypes.string,
    translations: PropTypes.translations,
    uptimeReportData: PropTypes.object
};

export default UptimeReportDataTable;
