// Unpublished Work © 2022-2024 Deere & Company.

export const BY_LOCATION = 'byLocation';
export const BY_TYPE = 'byType';
export const SCHEDULE_STATUS = 'employeeStatusReport';
export const WORKBOARDS = 'labor';
export const COST_REPORT = 'costReport';
export const INVENTORIES = 'inventories';
export const EQUIPMENT_COST = 'equipmentCost';
export const EQUIPMENT_UPTIME = 'equipmentUptime';
export const BY_EQUIPMENT = 'equipment';
export const BY_EQUIPMENT_TYPE = 'equipmentType';
export const BY_MODEL = 'model';
export const COST_BY_EQUIPMENT_AREA = 'costByEquipmentArea';
export const COST_BY_EQUIPMENT_MODEL = 'costByEquipmentModel';
export const COST_BY_EQUIPMENT_TYPE = 'costByEquipmentType';

export const LIFETIME_REPORTS = [
    COST_BY_EQUIPMENT_AREA,
    COST_BY_EQUIPMENT_MODEL,
    COST_BY_EQUIPMENT_TYPE,
    INVENTORIES
];
export const ALL = 'all';
export const LIFETIME = 'lifetime';
export const UPTIME_VS_DOWNTIME = 'ratio';
export const UPTIME_HOURS = 'uptime';
export const DOWNTIME_HOURS = 'downtime';
