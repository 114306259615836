// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function ConfirmationMessage(props) {
    const {
        message,
        translations
    } = props;

    return (
        <>
            <div className='confirmation-message'>
                <span className='warning-message'>
                    {translations.ONLINK_WARNING}
                </span>
                {message}
            </div>
            {translations.ONLINK_CONTINUE_CONFIRMATION_MESSAGE}
        </>
    );
}

ConfirmationMessage.propTypes = {
    message: PropTypes.string,
    translations: PropTypes.translations
};

export default ConfirmationMessage;
