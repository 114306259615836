// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {IconArrowRight} from '@deere/icons';
import moment from 'moment';

const ICON_STYLE = {
    style: {
        width: '20px',
        height: '20px'
    }
};

const THREE = 3;

function findForecastByDate(weather, date) {
    return weather?.dailyForecast?.find((forecast) => moment(forecast.fcst_valid_local).isSame(date, 'day'));
}

function getTemperaturePopSection(forecast, temp) {
    return (<div className={'temp-section'}>
        {`${temp}° (${forecast.pop}%)`}
    </div>);
}

function getHourlyWeather(weather, date) {
    if (weather?.hourlyForecast?.length > 0) {
        const nextThreeHours = weather.hourlyForecast.slice(0, THREE);
        const dailyForecast = findForecastByDate(weather, date);

        const forecast = dailyForecast?.day || dailyForecast?.night;

        return (<div className={'work-board-weather'}>
            <div className={'weather-strip'}>
                {nextThreeHours.map((hourWeather, index) => (
                    <div
                        className={'flex-display'}
                        key={index}
                    >
                        <img
                            className='work-board-weather-icon'
                            src={getWeatherIcon(hourWeather.icon_code)}
                        />
                        {index !== nextThreeHours.length - 1 &&
                        <IconArrowRight
                            iconArrowRight={ICON_STYLE}
                        />
                        }
                    </div>
                ))}
            </div>
            {forecast && getTemperaturePopSection(forecast, dailyForecast.convertedTemp)}
        </div>);
    }
    return null;
}

function getDailyForecast(weather, date) {
    if (weather?.dailyForecast?.length > 0) {
        const dailyForecast = findForecastByDate(weather, date);

        if (dailyForecast?.day) {
            return (
                <div className={'work-board-weather'}>
                    <img
                        className='work-board-weather-icon'
                        src={getWeatherIcon(dailyForecast.day.icon_code)}
                    />
                    {getTemperaturePopSection(dailyForecast.day, dailyForecast.convertedTemp)}
                </div>
            );
        }
    }

    return null;
}

function WorkBoardWeather(props) {
    const {
        date,
        weather
    } = props;

    const showHourly = moment(date).isSame(new Date(), 'day');

    return showHourly ? getHourlyWeather(weather, date) : getDailyForecast(weather, date);
}

WorkBoardWeather.propTypes = {
    date: PropTypes.string,
    weather: PropTypes.object
};

export default WorkBoardWeather;
