// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Button, Dialog, Typography} from '@deere/isg.component-library';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function ErrorDialog(props) {
    const {
        messageText,
        buttonText,
        onClickAction,
        closeErrorDialog
    } = props;

    return (
        <Dialog
            className='error-dialog'
            closeHandler={null}
            dismissOnClickOutside={false}
            notDismissible={false}
            open={true}
            title={null}
            width={'sm'}
        >
            <Typography
                marginBottom='20px'
                textAlign='center'
                variant='h3'
            >
                {messageText}
            </Typography>
            <Button
                onClick={() => {
                    onClickAction();
                    closeErrorDialog();
                }}
                variant='primary'
            >
                {buttonText}
            </Button>
        </Dialog>
    );
}

ErrorDialog.propTypes = {
    buttonText: PropTypes.string,
    closeErrorDialog: PropTypes.func,
    messageText: PropTypes.string,
    onClickAction: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        closeErrorDialog() {
            dispatch(closeDialog(dialogTypes.ERROR_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(ErrorDialog);
