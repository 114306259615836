// Unpublished Work © 2022-2024 Deere & Company.

import {
    ALL,
    COST_BY_EQUIPMENT_AREA,
    COST_BY_EQUIPMENT_MODEL,
    COST_BY_EQUIPMENT_TYPE
} from 'Ui/components/graph/constants/graph-filters';
import {
    getLifetimeEquipmentCostByArea,
    getLifetimeEquipmentCostByModel,
    getLifetimeEquipmentCostByType
} from 'Services/equipment-report-service';
import {formatNumber, getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {getInventories} from 'Services/membership-service';
import {getPartBins} from 'Services/inventory-service';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {sortBy} from 'lodash';

const CURRENCY = 'CURRENCY';
const TWO = 2;
const THREE = 3;
const FOUR = 4;
const FIVE = 5;

function formatGraphqlCallTable(equipmentCall, formattingPreference, translations) {
    return equipmentCall.map((entry) => {
        return Object.keys(entry).reduce((formattedObject, entryKey) => {
            const {
                value,
                format
            } = entry[entryKey];

            if (format) {
                formattedObject[`${entryKey}Formatted`] =
                    format.style === CURRENCY ? getFormattedCurrency(value, formattingPreference) :
                        formatNumber(value, formattingPreference);
            }
            formattedObject[entryKey] = translations[value] || value;

            return formattedObject;
        }, {});
    });
}

function formatGraphqlCallChart(equipmentCall, secondarySelector, translations) {
    const selectedAll = secondarySelector === ALL;

    const datasets = !selectedAll ? [
        {
            label: translations.ENGINE_HOURS,
            yAxisID: 'hours',
            stack: 'hours',
            dataLabel: 'engineHours',
            data: [],
            backgroundColor: GRAPH_COLORS[FIVE]
        },
        {
            label: translations.PARTS,
            yAxisID: 'cost',
            stack: 'cost',
            dataLabel: 'parts',
            data: [],
            backgroundColor: GRAPH_COLORS[THREE]
        },
        {
            label: translations.ONLINK_PURCHASE,
            yAxisID: 'cost',
            stack: 'cost',
            dataLabel: 'purchaseCost',
            data: [],
            backgroundColor: GRAPH_COLORS[1]
        },
        {
            label: translations.ONLINK_SERVICE,
            yAxisID: 'cost',
            stack: 'cost',
            dataLabel: 'service',
            data: [],
            backgroundColor: GRAPH_COLORS[FOUR]
        },
        {
            label: translations.ONLINK_TAX,
            yAxisID: 'cost',
            stack: 'cost',
            dataLabel: 'tax',
            data: [],
            backgroundColor: GRAPH_COLORS[TWO]
        }
    ] : [
        {
            label: translations.ENGINE_HOURS,
            yAxisID: 'hours',
            stack: 'hours',
            dataLabel: 'engineHours',
            data: [],
            backgroundColor: GRAPH_COLORS[1]
        },
        {
            label: translations.ONLINK_TOTAL_COST,
            yAxisID: 'cost',
            stack: 'cost',
            dataLabel: 'total',
            data: [],
            backgroundColor: GRAPH_COLORS[0]
        }
    ];

    const chartData = equipmentCall.reduce((chartData, equipment) => {
        chartData.labels.push(translations[equipment.name.value] || equipment.name.value);

        chartData.datasets.forEach((dataSet, index) => {
            chartData.datasets[index].data.push({
                y: equipment[dataSet.dataLabel]?.value,
                x: translations[equipment.name.value] || equipment.name.value
            });
        });

        return chartData;
    }, {
        labels: [],
        datasets
    });

    chartData.labels = chartData.labels.sort();
    return chartData;
}

async function getFormattedInventoriesPartsBins(formattingPreference, translations) {
    const {inventories} = await getInventories();
    const {partBins} = await getPartBins(inventories[0].inventoryId);

    const tableData = sortBy(partBins.reduce((partsBinArray, partBin) => {
        const partsBinIndex = partsBinArray.findIndex((entry) => entry.partType === partBin.partType);

        if (partsBinIndex === -1) {
            partsBinArray.push(
                {
                    partType: partBin.partType,
                    totalCost: partBin.totalCost,
                    formattedTotalCost: getFormattedCurrency(partBin.totalCost, formattingPreference)
                });
        } else {
            partsBinArray[partsBinIndex].totalCost += partBin.totalCost;
            partsBinArray[partsBinIndex].formattedTotalCost = getFormattedCurrency(partsBinArray[partsBinIndex].totalCost, formattingPreference);
        }

        return partsBinArray;
    }, []), 'partType');

    const chartData = tableData.reduce((chartObject, tableEntry, index) => {
        chartObject.labels.push(tableEntry.partType);

        chartObject.datasets[0].data.push({
            y: tableEntry.totalCost,
            x: tableEntry.partType
        });
        chartObject.datasets[0].backgroundColor.push(GRAPH_COLORS[index]);
        chartObject.datasets[0].hoverBackgroundColor.push(GRAPH_COLORS[index]);

        return chartObject;
    }, {
        labels: [],
        datasets: [{
            yAxisID: 'cost',
            label: translations.ONLINK_TOTALS_BY_PART_TYPE,
            stack: 'cost',
            dataLabel: 'totalCost',
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: []
        }]
    });

    return [tableData, chartData];
}

function reCalculateTotalCost(lifeTimeEquipmentCosts) {
    return lifeTimeEquipmentCosts?.map((equipmentCost) => {
        equipmentCost.total.value = equipmentCost.total.value - equipmentCost.leaseCost.value;
        equipmentCost.costPerEngineHour.value = equipmentCost.engineHours.value > 0 ?
            equipmentCost.total.value / equipmentCost.engineHours.value : 0;
        return equipmentCost;
    });
}

function getEquipmentCosts(call, formattingPreference, secondarySelector, translations) {
    const equipmentCosts = reCalculateTotalCost(call);

    return [
        formatGraphqlCallTable(equipmentCosts, formattingPreference, translations),
        formatGraphqlCallChart(equipmentCosts, secondarySelector, translations)
    ];
}

async function getTableData(primarySelector, secondarySelector, fleetId, formattingPreference, translations) {
    switch (primarySelector) {
        case COST_BY_EQUIPMENT_AREA: {
            const areaCall = await getLifetimeEquipmentCostByArea(fleetId, secondarySelector);

            return getEquipmentCosts(areaCall, formattingPreference, secondarySelector, translations);
        }
        case COST_BY_EQUIPMENT_MODEL: {
            const modelCall = await getLifetimeEquipmentCostByModel(fleetId, secondarySelector);

            return getEquipmentCosts(modelCall, formattingPreference, secondarySelector, translations);
        }
        case COST_BY_EQUIPMENT_TYPE: {
            const typeCall = await getLifetimeEquipmentCostByType(fleetId, secondarySelector);

            return getEquipmentCosts(typeCall, formattingPreference, secondarySelector, translations);
        }
        default:
            return getFormattedInventoriesPartsBins(formattingPreference, translations);
    }
}

export {
    getTableData
};
