// Unpublished Work © 2023-2024 Deere & Company.

import {NEW_WORKBOARD_ID} from 'Ui/features/workboard-wizard/constants/workboard-history';
import moment from 'moment';
import {cloneDeep, findLastIndex} from 'lodash';

const WORKBOARD_FETCH_COUNT = 7;

function getCurrentWorkboardIndex(workboards, currentWorkboardId) {
    return findLastIndex(workboards, ({workboardId}) => workboardId === currentWorkboardId);
}

function getMostRecentWorkboardIndex(workboards, date) {
    return findLastIndex(workboards, ({startTime}) => moment(startTime).isSameOrBefore(date, 'minutes'));
}

function getListWithCurrentWorkboard({
    currentWorkboardId,
    values,
    workboards,
    workItemsForCurrentWorkboard
}) {
    if (currentWorkboardId === NEW_WORKBOARD_ID) {
        const mostRecentWorkboardIndex = getMostRecentWorkboardIndex(workboards, values.date) + 1;

        return [
            ...workboards.slice(0, mostRecentWorkboardIndex),
            {
                id: currentWorkboardId,
                name: values.name,
                startTime: values.date,
                workboardId: currentWorkboardId,
                workItems: workItemsForCurrentWorkboard
            },
            ...workboards.slice(mostRecentWorkboardIndex)
        ];
    }

    const currentWorkboardIndex = getCurrentWorkboardIndex(workboards, currentWorkboardId);

    return [
        ...workboards.slice(0, currentWorkboardIndex),
        {
            ...workboards[currentWorkboardIndex],
            startTime: values.date,
            workItems: workItemsForCurrentWorkboard
        },
        ...workboards.slice(currentWorkboardIndex + 1)
    ];
}

function getWorkItemsWithJobTemplate(jobs) {
    return jobs.reduce((workItemsForJobs, job) => {
        const workItemsWithJobTemplate = job.workItems.map((workItem) => ({
            ...workItem,
            jobTemplate: job.jobTemplate,
            jobTemplateId: job.jobTemplateId
        }));

        return workItemsForJobs.concat(workItemsWithJobTemplate);
    }, []);
}

function getCurrentWorkboardItems({
    jobs,
    selectedIds,
    templatesById,
    workItemsByOperator
}) {
    if (jobs) {
        return getWorkItemsWithJobTemplate(jobs);
    }

    return selectedIds.reduce((allWorkItems, appUserId) => {
        const workItemsForOperator = workItemsByOperator[appUserId].map((workItem) => ({
            ...workItem,
            jobTemplate: templatesById.get(workItem.jobTemplateId)
        }));

        return allWorkItems.concat(workItemsForOperator);
    }, []);
}

function getWorkboardDetails({
    workboardIndex,
    workboards,
    workItemsByWorkboard
}) {
    const workboardForIndex = workboards[workboardIndex];
    const startIndex = Math.max(workboardIndex - WORKBOARD_FETCH_COUNT, 0);

    return {
        pastAndFutureWorkboardIds: workboards
            .slice(startIndex, workboardIndex + WORKBOARD_FETCH_COUNT)
            .filter(({workboardId}) => workboardId !== NEW_WORKBOARD_ID && !workItemsByWorkboard.has(workboardId))
            .map(({workboardId}) => workboardId),
        selectedWorkboard: workboardForIndex,
        workItemsForSelectedWorkboard: workItemsByWorkboard.get(workboardForIndex?.workboardId)
    };
}

function transformJobsToWorkItems({
    jobs,
    pastAndFutureWorkboardIds,
    workItemsByWorkboard
}) {
    const clonedWorkItemsByWorkboard = cloneDeep(workItemsByWorkboard);

    pastAndFutureWorkboardIds.forEach((workboardId) => {
        const jobsForWorkboard = jobs[workboardId] || [];
        const workItemsForWorkboard = getWorkItemsWithJobTemplate(jobsForWorkboard);

        clonedWorkItemsByWorkboard.set(workboardId, workItemsForWorkboard);
    });

    return clonedWorkItemsByWorkboard;
}

export {
    getCurrentWorkboardIndex,
    getCurrentWorkboardItems,
    getListWithCurrentWorkboard,
    getMostRecentWorkboardIndex,
    getWorkboardDetails,
    transformJobsToWorkItems
};
