// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ListItem from 'Ui/components/common/list/list-item';
import SecondaryMobileDropdown from 'Ui/components/secondary-navbar/secondary-mobile-dropdown';

function MobileNavList(props) {
    const {
        navItems,
        onClick
    } = props;

    const mobileItemList = navItems.map((navItem) => {
        if (navItem.isDropdown) {
            return (
                <SecondaryMobileDropdown
                    active={navItem.active}
                    key={navItem.name}
                    links={navItem.links}
                    name={navItem.name}
                    onClick={onClick}
                />
            );
        }

        return (
            <ListItem
                active={navItem.active}
                key={navItem.name}
                onClick={() => onClick(navItem)}
                text={navItem.name}
            />
        );
    });

    return (
        <div className='mobile-nav-list-container'>
            <div className='mobile-nav-list'>
                {mobileItemList}
            </div>
        </div>
    );
}

MobileNavList.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.navItem),
    onClick: PropTypes.func
};

export default MobileNavList;
