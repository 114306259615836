// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import PropTypes from 'Utils/prop-type-utils';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {fetchEffectData} from 'Utils/react-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {getBinLocations, updatePartBin} from 'Services/inventory-service';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {alphaNumericCompare} from 'Ui/models/maintenance';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';

function inititializeState(part) {
    const [loading, setLoading] = React.useState({
        loadingData: true,
        saving: false
    });
    const [binLocations, setBinLocations] = React.useState([]);
    const [partBin, setPartBin] = React.useState({
        manufactererId: part.manufacturerId,
        binLocationId: part.binLocationId,
        partId: part.partId,
        restockTarget: part.restockTarget,
        thresholdLevel: part.thresholdLevel,
        partBinId: part.partBinId
    });

    return {
        loading,
        setLoading,
        binLocations,
        setBinLocations,
        partBin,
        setPartBin
    };
}

function PartBinDialog(props) {
    const {
        invalidInputs,
        closePartBinDialog,
        setValid,
        translations,
        part,
        updateInventoryData
    } = props;

    const {
        loading,
        setLoading,
        binLocations,
        setBinLocations,
        partBin,
        setPartBin
    } = inititializeState(part);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading((prevValues) => ({
            ...prevValues,
            loadingData: true
        }));

        const {binLocations} = await getBinLocations(part.inventoryId);

        const remappedBinLocations = binLocations.map((binLocation) => {
            return {
                id: binLocation.binLocationId,
                title: binLocation.name
            };
        }).sort((a, b) => alphaNumericCompare(a.title, b.title));

        if (isMounted()) {
            setBinLocations(remappedBinLocations);

            if (!partBin.binLocationId && remappedBinLocations.length) {
                setPartBin((prevValues) => ({
                    ...prevValues,
                    binLocationId: remappedBinLocations[0].id
                }));
            }

            setLoading((prevValues) => ({
                ...prevValues,
                loadingData: false
            }));
        }
    }), []);

    async function onSave() {
        const partBinPayload = {
            binLocationId: partBin.binLocationId,
            partId: partBin.partId,
            restockTarget: partBin.restockTarget === '' ? 0 : parseInt(partBin.restockTarget, 10),
            thresholdLevel: partBin.thresholdLevel === '' ? 0 : parseInt(partBin.thresholdLevel, 10)
        };

        setLoading((prevValues) => ({
            ...prevValues,
            saving: true
        }));

        try {
            await updatePartBin(partBin.partBinId, partBinPayload);
        } catch (error) {
            setLoading((prevValues) => ({
                ...prevValues,
                saving: false
            }));

            throw error;
        }

        updateInventoryData();
        closePartBinDialog();
    }

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closePartBinDialog}
            disableSave={invalidInputs.size > 0 || loading.saving}
            footerLoading={loading.saving}
            onSave={onSave}
            saveLabel={translations.save}
            title={translations.ONLINK_EDIT_PART_BIN}
            translations={translations}
        >
            <form className='settings-body'>
                <LoadingWrapper
                    className='onlink-loading-icon'
                    loading={loading.loadingData}
                    size='50px'
                >
                    <MultiSelect
                        disabled
                        items={[{
                            id: part.manufacturerId,
                            title: part.manufacturerName
                        }]}
                        label={translations.MANUFACTURER}
                        labels={{}}
                        onChange={(manufacturerIds) => {
                            setPartBin((prevValues) => ({
                                ...prevValues,
                                manufactererId: manufacturerIds[0]
                            }));
                        }}
                        selectedIds={[partBin.manufactererId]}
                        single
                    />
                    <MultiSelect
                        disabled
                        items={[{
                            id: part.partBinId,
                            title: part.name
                        }]}
                        label={translations.ONLINK_PART}
                        labels={{}}
                        onChange={(partBinIds) => {
                            setPartBin((prevValues) => ({
                                ...prevValues,
                                partBinId: partBinIds[0]
                            }));
                        }}
                        selectedIds={[partBin.partBinId]}
                        single
                    />
                    <MultiSelect
                        items={binLocations}
                        label={translations.ONLINK_BIN_LOCATION}
                        labels={{}}
                        onChange={(binLocations) => {
                            setPartBin((prevValues) => ({
                                ...prevValues,
                                binLocationId: binLocations[0]
                            }));
                        }}
                        selectedIds={[partBin.binLocationId]}
                        single
                    />
                    <ValidationInput
                        errors={{
                            rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
                                '0': 0
                            })
                        }}
                        label={translations.ONLINK_THRESHOLD}
                        min={0}
                        name='threshold'
                        onChange={(event) => {
                            setPartBin((prevValues) => ({
                                ...prevValues,
                                thresholdLevel: event.target.value
                            }));
                        }}
                        setValid={setValid}
                        tabIndex={0}
                        type='number'
                        value={partBin.thresholdLevel}
                    />
                    <ValidationInput
                        errors={{
                            rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
                                '0': 0
                            })
                        }}
                        label={translations.ONLINK_RESTOCK_TARGET}
                        min={0}
                        name='restock-target'
                        onChange={(event) => {
                            setPartBin((prevValues) => ({
                                ...prevValues,
                                restockTarget: event.target.value
                            }));
                        }}
                        setValid={setValid}
                        tabIndex={0}
                        type='number'
                        value={partBin.restockTarget}
                    />
                </LoadingWrapper>
            </form>
        </FormDialog>
    );
}

PartBinDialog.propTypes = {
    closePartBinDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    openConfirmation: PropTypes.func,
    part: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.object,
    updateInventoryData: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        closePartBinDialog() {
            dispatch(closeDialog(dialogTypes.PART_BIN_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(PartBinDialog));
