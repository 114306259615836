// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import CheckboxInput from 'Ui/components/common/form/checkbox-input';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {IconDelete} from '@deere/icons';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function getSelectedIds(selectedIds, checkboxName, isChecked) {
    if (isChecked) {
        return selectedIds.concat(checkboxName);
    }

    return selectedIds.filter((selectedId) => selectedId !== checkboxName);
}

function createCheckListItems(props, onCheckboxChange) {
    const {
        disabled,
        items,
        onDelete,
        selectedIds,
        showDelete
    } = props;

    return items.map((item) => {
        const itemChecked = selectedIds.some((selectedId) => selectedId === item.id);
        const deleteIcon = showDelete && showDelete(item.id) && (
            <OnLinkIconButton
                onClick={(e) => {
                    e.preventDefault();

                    onDelete(item);
                }}
            >
                <IconDelete iconDelete={ICON_STYLE}/>
            </OnLinkIconButton>
        );

        return (
            <div
                className='check-list-item'
                key={item.id}
            >
                <CheckboxInput
                    checked={itemChecked}
                    className='form-checkbox'
                    disabled={disabled}
                    label={item.label}
                    name={item.id}
                    onChange={onCheckboxChange}
                />
                {deleteIcon}
            </div>
        );
    });
}

function CheckList(props) {
    const {
        items,
        label,
        name,
        onChange,
        selectedIds
    } = props;

    function onCheckboxChange(checkboxName, isChecked) {
        const newSelectedIds = getSelectedIds(selectedIds, checkboxName, isChecked);

        onChange(name, newSelectedIds);
    }

    const formattedItems = React.useMemo(() => createCheckListItems(props, onCheckboxChange), [items, selectedIds]);

    return (
        <div>
            {
                label &&
                <label className='multiselect-checklist-label'>
                    {label}
                </label>
            }
            <div
                className={'checklist'}
            >
                {formattedItems}
            </div>
        </div>
    );
}

CheckList.propTypes = {
    disabled: PropTypes.bool,
    items: PropTypes.checkListItemsList,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.stringOrNumber),
    showDelete: PropTypes.func
};

export default CheckList;
