// Unpublished Work © 2021-2024 Deere & Company.

import appAccess from 'Common/constants/app-access';
import permissions from 'Common/constants/permissions';
import {createLink} from 'Utils/navigation-utils';
import {
    VIEW_COURSE_MEASUREMENT_DATA,
    VIEW_EQUIPMENT_LIST_API,
    VIEW_WORKBOARD_SUMMARY_DATA,
    VIEW_EQUIPMENT_DETAILS,
    VIEW_PARTS,
    VIEW_FINANCIAL_DATA
} from 'Common/constants/business-activities';
import {LABOR_REPORT, WORKBOARD_REPORT, EQUIPMENT_REPORT} from 'Common/constants/routes';
import {PARTS_HISTORY_PAGE} from 'Common/constants/feature-toggles';

const dashboardBasePath = '/home/details';
const mapBasePath = '/map';

export const onEquipBasePath = '/equipment';

export const onLaborBasePath = '/labor';

export const onEquipPartsBasePath = '/equipment/parts';
export const onEquipReportBasePath = '/equipment/report';

export const legacyOnEquipBasePath = '/onequip/#m/{0}';

export const legacyOnLaborBasePath = '/onlabor/#m/{0}';

export const dashboardNavLinks = [
    createLink(`${dashboardBasePath}/weather_course`, 'ONLINK_CONDITIONS', true),
    {
        ...createLink(`${dashboardBasePath}/equipment`, 'Equipment', true),
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    },
    {
        ...createLink(`${dashboardBasePath}/labor`, 'Labor', true),
        myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA
    },
    {
        ...createLink(`${dashboardBasePath}/water_energy`, 'WATER', true),
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    {
        ...createLink(`${dashboardBasePath}/fert_chem`, 'ONLINK_FERT_AND_CHEM', true),
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    {
        ...createLink(`${dashboardBasePath}/rounds`, 'Rounds', true),
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    }
];

export const mapNavLinks = [
    createLink(`${mapBasePath}/equipment`, 'Equipment', true),
    createLink(`${mapBasePath}/location_history`, 'location_history', true)
];

export const onEquipNavLinks = [
    {
        ...createLink(`${onEquipBasePath}/fleet`, 'Equipment', true),
        basePath: onEquipBasePath
    },
    createLink(`${legacyOnEquipBasePath}/partsLayout`, 'PARTS', false),
    createLink(`${legacyOnEquipBasePath}/reports`, 'reports_my_reports', false)
];

export const onLaborNavLinks = [
    {
        ...createLink(`${legacyOnLaborBasePath}/labor`, 'ONLINK_WORKBOARDS', false),
        appAccess: appAccess.ONLABOR
    },
    {
        ...createLink(`${legacyOnLaborBasePath}/schedule`, 'SCHEDULE', false),
        appAccess: appAccess.ONLABOR
    },
    {
        ...createLink(`${onLaborBasePath}/myWork`, 'ONLINK_MY_WORK', true),
        permission: permissions.CAN_ACCESS_MY_WORK
    },
    {
        ...createLink(`${legacyOnLaborBasePath}/reports`, 'reports_my_reports', false),
        appAccess: appAccess.ONLABOR
    }
];

export const courseOperationsNavLinks = [
    {
        ...createLink(WORKBOARD_REPORT, 'ONLINK_WORKBOARDS', true),
        alias: '/labor/report',
        appAccess: permissions.ONLABOR,
        myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA
    },
    {
        ...createLink(LABOR_REPORT, 'ONLINK_LABOR_COSTS', true),
        appAccess: permissions.ONLABOR,
        myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA
    }
];

export const fleetMaintenanceLinks = [
    {
        ...createLink(`${onEquipBasePath}/suggestedMaintenance`, 'ONLINK_MAINTENANCE', false),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    }, {
        ...createLink(`${onEquipBasePath}/shop`, 'ONLINK_SHOP', false),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_EQUIPMENT_DETAILS
    }, {
        ...createLink(`${onEquipBasePath}/assignments`, 'ASSIGNMENTS', false),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_EQUIPMENT_DETAILS
    }
];

export const partsLinks = [
    {
        ...createLink(`${onEquipPartsBasePath}/inventory`, 'ONLINK_INVENTORY', true),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_PARTS
    },
    {
        ...createLink(`${onEquipPartsBasePath}/history`, 'HISTORY', true),
        appAccess: appAccess.ONEQUIP,
        migratedMembershipAccessOnly: true,
        myJdPermissions: VIEW_PARTS,
        toggle: PARTS_HISTORY_PAGE
    },
    {
        ...createLink(`${onEquipPartsBasePath}/recommended`, 'ONLINK_RECOMMENDED', true),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_PARTS
    },
    {
        ...createLink(`${onEquipPartsBasePath}/orders`, 'ORDERS', true),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_PARTS
    }
];

export const fleetSetupNavLinks = [
    {
        ...createLink(`${onEquipBasePath}/fleet`, 'ONLINK_FLEET', true),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    },
    {
        ...createLink(`${onEquipBasePath}/hoc`, 'ONLINK_HEIGHT_OF_CUT', true),
        appAccess: appAccess.ONEQUIP,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    }
];

export const fleetAnalyzeNavLinks = [
    {
        ...createLink(EQUIPMENT_REPORT, 'ONLINK_EQUIPMENT_COSTS', true),
        appAccess: permissions.ONEQUIP,
        myJdPermissions: [VIEW_EQUIPMENT_DETAILS, VIEW_FINANCIAL_DATA]
    },
    {
        ...createLink(`${onEquipBasePath}/serviceHistory`, 'ONLINK_SERVICE_HISTORY', true),
        appAccess: permissions.ONEQUIP,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    }
];
