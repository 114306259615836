// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';
import {withRouter} from 'react-router-dom';
import {SETTINGS} from 'Common/constants/routes';
import 'Sass/settings/settings.scss';

function SettingsCard(props) {
    const {
        history,
        icon,
        match,
        tileId,
        title,
        translations
    } = props;

    const className = clsx('settings-card settings-grid-card', {
        clickable: match.path === SETTINGS
    });

    return (
        <div
            className={className}
            onClick={() => history.push(`${SETTINGS}/${tileId.toLowerCase()}`)}
        >
            <div className='settings-card-title'>
                {translations[title]}
            </div>
            <div className='settings-card-icon'>
                {icon}
            </div>
        </div>
    );
}

SettingsCard.propTypes = {
    history: PropTypes.history,
    icon: PropTypes.node,
    match: PropTypes.match,
    tileId: PropTypes.string,
    title: PropTypes.stringOrNumberOrElement.isRequired,
    translations: PropTypes.translations
};

export default withRouter(SettingsCard);
