// Unpublished Work © 2022-2024 Deere & Company.

import {alphaNumericCompare} from 'Ui/models/maintenance';
import {FUEL_COST, GAS_COST, GREEN_SPEED, WATER_COST} from 'Common/constants/data-group-constants';
import {
    DOLLARS_PER_LITER,
    DOLLARS_PER_THOUSAND_LITERS,
    FEET,
    FEET_AND_INCHES,
    INCHES
} from 'Common/constants/data-unit-constants';
import {CURRENCY_PREFERENCE} from 'Common/constants/preference-constants';
import {getCurrencySymbol} from 'Utils/unit-conversion-utils';

function sortManualDataRows(rowA, rowB, sortOrder, primarySortField, secondarySortField) {
    const compareResult = alphaNumericCompare(rowA[primarySortField], rowB[primarySortField]);

    if (compareResult !== 0) {
        return compareResult;
    }

    if (sortOrder) {
        return alphaNumericCompare(rowB[secondarySortField], rowA[secondarySortField]);
    }

    return alphaNumericCompare(rowA[secondarySortField], rowB[secondarySortField]);
}

function getDataUnit({
    currencyPreference = CURRENCY_PREFERENCE.USD,
    dataType,
    dataUnit,
    translations
}) {
    const currencySymbol = getCurrencySymbol({
        currencyPreference
    });

    switch (dataType) {
        case GREEN_SPEED:
            return dataUnit === INCHES || dataUnit === FEET ? FEET_AND_INCHES : dataUnit;
        case WATER_COST: {
            return dataUnit === DOLLARS_PER_THOUSAND_LITERS ?
                `${currencySymbol}/1000 ${translations.STANDARD_UOM_LITER}` :
                `${currencySymbol}/1000 ${translations.GALLONS}`;
        }
        case GAS_COST:
        case FUEL_COST: {
            return dataUnit === DOLLARS_PER_LITER ?
                `${currencySymbol}/${translations.ONLINK_LITER}` :
                `${currencySymbol}/${translations.ONLINK_GALLON}`;
        }
        default:
            return dataUnit;
    }
}

export {
    getDataUnit,
    sortManualDataRows
};
