// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import CustomDateRange from 'Ui/components/common/date-range-picker/custom-date-range';
import PropTypes from 'Utils/prop-type-utils';

const CUSTOM_ID = 1;

function DateRangePicker(props) {
    const {
        setDateRange,
        translations,
        dateRangeOptions,
        defaultSelectionId,
        showLabel
    } = props;

    const dateRangeOptionsWithCustom = [
        {
            id: CUSTOM_ID,
            title: translations.CUSTOM
        },
        ...dateRangeOptions
    ];

    const [selectedIds, setSelectedIds] = React.useState([defaultSelectionId]);

    function setSelection(selectedIds) {
        setSelectedIds(selectedIds);

        const {
            startDate,
            endDate
        } = dateRangeOptionsWithCustom.find((dateRangeOption) => dateRangeOption.id === selectedIds[0]);

        if (startDate && endDate) {
            setDateRange({
                startDate,
                endDate
            });
        }
    }

    return (
        <>
            <MultiSelect
                disableSorting
                items={dateRangeOptionsWithCustom}
                label={showLabel ? `${translations.DATE_RANGE} :` : null}
                labels={{
                    placeholder: translations.SELECT_DATE_RANGE
                }}
                onChange={setSelection}
                selectedIds={selectedIds}
                single
            />
            {
                selectedIds[0] === CUSTOM_ID &&
                <CustomDateRange
                    setDateRange={setDateRange}
                    translations={translations}
                />
            }
        </>
    );
}

DateRangePicker.defaultProps = {
    showLabel: true
};

DateRangePicker.propTypes = {
    dateRangeOptions: PropTypes.arrayOf(PropTypes.object),
    defaultSelectionId: PropTypes.number,
    setDateRange: PropTypes.func,
    showLabel: PropTypes.bool,
    translations: PropTypes.translations
};

export default DateRangePicker;
