// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import GridLayout, {WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const Layout = WidthProvider(GridLayout);

function triggerResize() {
    window.dispatchEvent(new Event('resize'));
}

function GridLayoutWithWidth(props) {
    const {
        children,
        ...otherProps
    } = props;
    const [key, setKey] = React.useState(() => new Date().toISOString());
    const windowWidth = React.useRef(window.innerWidth);

    const updateGrid = React.useCallback(() => {
        if (windowWidth.current !== window.innerWidth) {
            windowWidth.current = window.innerWidth;
            setKey(new Date().toISOString());
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener('resize', updateGrid);
        window.addEventListener('orientationchange', triggerResize);
        window.addEventListener('visibilitychange', triggerResize);

        return () => {
            window.removeEventListener('resize', updateGrid);
            window.removeEventListener('orientationchange', triggerResize);
            window.removeEventListener('visibilitychange', triggerResize);
        };
    }, []);

    return (
        <Layout
            key={key}
            {...otherProps}
        >
            {children}
        </Layout>
    );
}

GridLayoutWithWidth.propTypes = {
    children: PropTypes.node.isRequired
};

export default GridLayoutWithWidth;
