// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

export default function ForecastTable(props) {
    const {tableData} = props;

    return (
        <div className={`table-group-card white graph-table-overflow ${props.tableClassName}`}>
            <div className='card-header-labels to-upper'>
                {
                    tableData.header.map((header, i) => (
                        <div
                            className='card-header-item margin cell-left bold forecast-row'
                            key={`header ${i}`}
                        >
                            {header}
                        </div>
                    ))
                }
            </div>
            <div className='table-list'>
                {
                    tableData.data.map((data, i) => (
                        <div
                            className='table-list-items child'
                            key={i}
                        >
                            {
                                data.map((item, j) => (
                                    <div
                                        className='table-cell cell-left margin forecast-row'
                                        key={`item ${j}${i}`}
                                    >
                                        {item}
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

ForecastTable.propTypes = {
    tableClassName: PropTypes.string,
    tableData: PropTypes.shape()

};
