// Unpublished Work © 2022-2024 Deere & Company.

import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import React from 'react';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import PropTypes from 'Utils/prop-type-utils';
import EditableEmployeeStatusSettings from 'Ui/components/settings/labor/editable-employee-status-settings';
import {createEmployeeStatusOption, updateEmployeeStatusOption} from 'Services/membership-service';
import {useSave} from 'Ui/react-hooks/use-save';
import {REMAP_STATUS_COLORS} from 'Common/constants/valid-hex-color';

function initializeState(status) {
    const initialColor = REMAP_STATUS_COLORS[status?.color] || status?.color || 'mauve';
    const [statusName, setStatusName] = React.useState(() => status && status.name || '');
    const [color, setColor] = React.useState(() => initialColor);
    const [isAvailable, setIsAvailable] = React.useState(() => status && status.isAvailable || false);

    return {
        statusName,
        setStatusName,
        color,
        setColor,
        isAvailable,
        setIsAvailable
    };
}

function EmployeeStatusDialog(props) {
    const {
        closeDialog,
        status,
        invalidInputs,
        setValid,
        translations,
        getUpdatedStatusOptions,
        membershipId
    } = props;

    const {
        statusName,
        setStatusName,
        color,
        setColor,
        isAvailable,
        setIsAvailable
    } = initializeState(status);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const statusPayload = {
            membershipId,
            ...status,
            color,
            isAvailable,
            name: statusName
        };

        if (status) {
            await updateEmployeeStatusOption(status.employeeStatusOptionId, statusPayload);
        } else {
            await createEmployeeStatusOption(statusPayload);
        }

        await getUpdatedStatusOptions();
        closeDialog();
    }, {
        invalidInputs
    });

    const title = status ?
        translations.ONLINK_EDIT_STATUS :
        translations.ONLINK_ADD_STATUS;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className={'employee-status-dialog'}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={title}
            translations={translations}
        >
            <EditableEmployeeStatusSettings
                color={color}
                isAvailable={isAvailable}
                setColor={setColor}
                setIsAvailable={setIsAvailable}
                setStatus={setStatusName}
                setValid={setValid}
                status={statusName}
                translations={translations}
            />
        </FormDialog>
    );
}

EmployeeStatusDialog.propTypes = {
    closeDialog: PropTypes.func,
    getUpdatedStatusOptions: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipId: PropTypes.string,
    setValid: PropTypes.func,
    status: PropTypes.object,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.EMPLOYEE_STATUS_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EmployeeStatusDialog));
