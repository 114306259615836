// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {replaceTranslationNames} from 'Utils/translation-utils';

const MAX_QUANTITY = 2000000;

function SelectedPartQuantity({
    disabled,
    onChange,
    onClear,
    part,
    readOnly,
    setValid,
    translations
}) {
    const valueGreaterThanOrEqual = React.useMemo(() => replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    }), [translations.VALUE_GREATER_THAN_OR_EQUAL]);
    const valueLessThanOrEqual = React.useMemo(
        () => `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_QUANTITY}`,
        [translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL]
    );

    return (
        <div
            className='settings-group mobile part'
            key={part.id}
        >
            <div className='part-label'>
                <div className='title'>
                    {part.title}
                </div>
                <div className='sub-title'>
                    {part.subtitle}
                </div>
            </div>
            <ReadOnlyWrapperComponent
                label={translations['Observation.QUANTITY']}
                readOnly={readOnly}
                readOnlyProps={{
                    marginBottom: '10px'
                }}
                value={part.quantity}
                wrappedComponent={ValidationInput}
                wrappedProps={{
                    disabled,
                    errors: {
                        rangeUnderflow: valueGreaterThanOrEqual,
                        rangeOverflow: valueLessThanOrEqual
                    },
                    max: MAX_QUANTITY,
                    min: 0,
                    name: `${part.id}quantity`,
                    onChange: (event) => onChange('quantity', event.target.value, part),
                    onClear: () => onClear(part),
                    required: true,
                    setValid,
                    tabIndex: 0,
                    type: 'number'
                }}
            />
        </div>
    );
}

SelectedPartQuantity.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    part: PropTypes.object,
    readOnly: PropTypes.bool,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default SelectedPartQuantity;
