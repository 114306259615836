// Unpublished Work © 2022-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import WorkItemTimer from 'OnLabor/my-work/my-work-dialog/work-item-timer';
import CheckboxInput from 'Ui/components/common/form/checkbox-input';
import {TextArea} from '@deere/form-controls';
import {TRUE} from 'Ui/constants/membership-properties';

function WorkItemDetailsDialogInputs(props) {
    const {
        showEngineHoursOrCloseDialog,
        setTotalTime,
        setShowConfirmationDialog,
        translations,
        workItem,
        workItemInputs,
        setWorkItemInputs,
        updateWorkItem,
        properties
    } = props;

    function onChange(name, value) {
        setWorkItemInputs((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    return (
        <div className='work-item-inputs'>
            {
                properties.mywork_show_pre_start_check === TRUE &&
                <CheckboxInput
                    checked={workItemInputs.preStartCheck}
                    className='form-checkbox'
                    label={translations.ONLINK_CERTIFY_PRE_START_CHECK}
                    name='preStartCheck'
                    onChange={onChange}
                />
            }
            <WorkItemTimer
                closeDialog={showEngineHoursOrCloseDialog}
                onTimeChange={setTotalTime}
                showConfirmationDialog={setShowConfirmationDialog}
                translations={translations}
                updateWorkItem={updateWorkItem}
                workItem={workItem}
            />
            {
                properties.mywork_show_operator_note === TRUE &&
                <TextArea
                    allowResize={false}
                    className={'notes-text-area'}
                    label={`${translations.ONLINK_OPERATOR_NOTE}:`}
                    name='operatorNote'
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    placeholder={translations.ONLINK_OPERATOR_NOTE_PLACEHOLDER_TEXT}
                    tabIndex={0}
                    value={workItemInputs.operatorNote}
                />
            }
        </div>
    );
}

WorkItemDetailsDialogInputs.propTypes = {
    properties: PropTypes.object,
    setShowConfirmationDialog: PropTypes.func,
    setTotalTime: PropTypes.func,
    setWorkItemInputs: PropTypes.func,
    showEngineHoursOrCloseDialog: PropTypes.func,
    translations: PropTypes.translations,
    updateWorkItem: PropTypes.func,
    workItem: PropTypes.object,
    workItemInputs: PropTypes.object
};

export default WorkItemDetailsDialogInputs;
