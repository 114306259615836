// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {IconCaretDirection} from 'Ui/components/main-navbar';
import LayoutDropdownContent from 'Ui/components/secondary-navbar/layout-dropdown-content';
import {setActiveLayout as setActiveLayoutService} from 'Services/user-view-service';
import {setActiveLayout as setActiveLayoutRedux} from 'Store/actions/account';
import {clsx} from 'clsx';
import {getLayoutById} from 'Src/ui-core/utils/layout-utils';

function LayoutDropdown(props) {
    const {
        activeLayoutId,
        availableLayouts,
        history,
        setActiveLayout,
        translations,
        appUserId
    } = props;

    const [dropdownOpen, setDropdownOpen] = React.useState(() => false);

    function toggleDropdown() {
        setDropdownOpen(!dropdownOpen);
    }

    const className = clsx('onlink-btn-nav onlink-btn-right', {
        active: dropdownOpen
    });

    const iconCaret = (
        <IconCaretDirection
            caretUp={dropdownOpen}
            color='#fff'
        />
    );

    return (
        <>
            <OnlinkButton
                className={className}
                color='white'
                onClick={toggleDropdown}
                rightIcon={iconCaret}
            >
                {translations.DASHBOARD}
            </OnlinkButton>
            {
                dropdownOpen && (
                    <>
                        <LayoutDropdownContent
                            activeLayoutId={activeLayoutId}
                            availableLayouts={availableLayouts}
                            onClick={(id) => {
                                setActiveLayoutService(id, appUserId);
                                setActiveLayout(getLayoutById(availableLayouts, id));

                                history.push('/home');
                            }}
                            toggleDropdown={toggleDropdown}
                            translations={translations}
                        />
                        <div
                            className='overlay'
                            onClick={toggleDropdown}
                        />
                    </>
                )
            }
        </>
    );
}

LayoutDropdown.propTypes = {
    activeLayoutId: PropTypes.string,
    appUserId: PropTypes.string,
    availableLayouts: PropTypes.arrayOf(PropTypes.layout),
    history: PropTypes.history,
    setActiveLayout: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        activeLayoutId: state.account.activeLayout.id,
        availableLayouts: state.account.availableLayouts,
        appUserId: state.account.appUserId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setActiveLayout(value) {
            dispatch(setActiveLayoutRedux(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDropdown);
