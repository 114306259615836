// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconEdit} from '@deere/icons';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {getLabel} from 'Utils/workboard-settings-utils';
import {NOT_AVAILABLE} from 'Ui/constants/common-constants';
import defaultImage from 'Ui/images/bg/default_membership_image.jpg';
import {useGetMembershipLogo} from 'Ui/react-hooks/use-get-membership-logo';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#fff');

function getWorkboardProperty(propertyName, properties, translations) {
    return getLabel(propertyName, properties[propertyName], translations);
}

function WorkboardSettings(props) {
    const {
        membership,
        setIsEditing,
        translations
    } = props;

    const properties = membership.properties || {};
    const isDefaultImage = membership.logoUrl === 'images/bg/default_membership_image.jpg';

    const onLaborUpdatesSettings = (
        <>
            <div className='settings-group horizontal'>
                <div className='settings-block-row'>{translations.ONLINK_SHOW_EMPLOYEE_IMAGE}</div>
                <div className='settings-block-row'>
                    {getWorkboardProperty('workboard_show_employee_image', properties, translations)}
                </div>
            </div>
            <div className='settings-group horizontal'>
                <div className='settings-block-row'>{translations.ONLINK_SHOW_EMPLOYEE_GROUP_COLOR}</div>
                <div className='settings-block-row'>
                    {getWorkboardProperty('workboard_show_employee_group_color', properties, translations)}
                </div>
            </div>
            <div className='settings-group horizontal'>
                <div className='settings-block-row'>{translations.ONLINK_SHOW_ROUTE}</div>
                <div className='settings-block-row'>
                    {getWorkboardProperty('workboard_show_route', properties, translations)}
                </div>
            </div>
        </>
    );

    const onLaborRefactorSettings = (
        <div className='settings-group mobile'>
            <div className='settings-block'>
                <div className='settings-block-row'>{translations.ONLINK_SHOW_PROTECTION_ICONS}</div>
                <div className='settings-block-row'>
                    {getWorkboardProperty('workboard_show_protection_icons', properties, translations)}
                </div>
            </div>
            <div className='settings-block'>
                <div className='settings-block-row'>{translations.ONLINK_TILE_OPACITY}</div>
                <div className='settings-block-row'>
                    {getWorkboardProperty('workboard_tile_opacity', properties, translations)}
                </div>
            </div>
        </div>
    );

    const workboardCreationSetting = (
        <div className='settings-block workboard-toggle-group'>
            <div className='settings-block-row'>{translations.ONLINK_WORKBOARD_CREATION}</div>
            <div className='settings-block-row'>
                {getWorkboardProperty('workboard_creation_flow', properties, translations)}
            </div>
        </div>
    );

    const {
        logoDataUrl, logoLoading
    } = useGetMembershipLogo(membership.membershipId);

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={logoLoading}
            size='50px'
        >
            <div className='settings-body workboard-settings'>
                <div className='settings-group horizontal-reverse'>
                    <div className='edit-button'>
                        <OnlinkButton
                            className='primary'
                            leftIcon={
                                <IconEdit
                                    iconEdit={ICON_EDIT_STYLE}
                                    primary={PRIMARY_STYLE}
                                />
                            }
                            onClick={setIsEditing}
                        >
                            {translations.EDIT}
                        </OnlinkButton>
                    </div>
                    <div className='workboard-settings-block'>
                        <div className='settings-group mobile'>
                            <div className='settings-block'>
                                <div className='settings-block-row'>{translations.ONLINK_WEEK_START_DAY}</div>
                                <div className='settings-block-row'>
                                    {getWorkboardProperty('workboard_week_start_day', properties, translations)}
                                </div>
                            </div>
                            <div className='settings-block'>
                                <div className='settings-block-row'>{translations.ONLINK_WORKBOARD_START_TIME}</div>
                                <div className='settings-block-row'>
                                    {getWorkboardProperty('workboard_default_start_time', properties, translations)}
                                </div>
                            </div>
                        </div>
                        <div className='settings-group mobile'>
                            <div className='settings-block'>
                                <div className='settings-block-row'>{translations.ONLINK_WEEKLY_WORK_HOURS}</div>
                                <div className='settings-block-row'>
                                    {properties.workboard_weekly_work_hours || NOT_AVAILABLE}
                                </div>
                            </div>
                            <div className='settings-block'>
                                <div className='settings-block-row'>{translations.ONLINK_DAILY_WORK_HOURS}</div>
                                <div className='settings-block-row'>
                                    {properties.workboard_employee_daily_hours_limit || NOT_AVAILABLE}
                                </div>
                            </div>
                        </div>
                        <div className='settings-group mobile'>
                            {workboardCreationSetting}
                        </div>
                        <fieldset className='workboard-block'>
                            <legend
                                className='workboard-settings-title'
                            >{translations.ONLINK_WORKBOARD_DISPLAY_SETTINGS}</legend>
                            <div className='settings-group mobile'>
                                <div className='settings-block workboard-toggle-group'>
                                    <div
                                        className='settings-block-row'
                                    >{translations.ONLINK_WORKBOARD_DISPLAY_GROUPING}</div>
                                    <div className='settings-block-row'>
                                        {getWorkboardProperty('workboard_presentation_groups', properties, translations)}
                                    </div>
                                </div>
                                <div className='settings-block'>
                                    <div
                                        className='settings-block-row'
                                    >{translations.ONLINK_WORKBOARD_DISPLAY_FONT}</div>
                                    <div className='settings-block-row'>
                                        {getWorkboardProperty('workboard_presentation_font_size', properties, translations)}
                                    </div>
                                </div>
                            </div>
                            <div className='settings-group mobile'>
                                <div className='settings-block'>
                                    <div className='settings-block-row'>{translations.ONLINK_WORKBOARD_COLUMNS}</div>
                                    <div className='settings-block-row'>
                                        {properties.workboard_presentation_column_count || NOT_AVAILABLE}
                                    </div>
                                </div>
                                <div className='settings-block'>
                                    <div
                                        className='settings-block-row'
                                    >{translations.ONLINK_WORKBOARD_DISPLAY_AUTO_SCROLL}</div>
                                    <div className='settings-block-row'>
                                        {getWorkboardProperty('workboard_presentation_autoscroll_speed', properties, translations)}
                                    </div>
                                </div>
                            </div>
                            <div className='settings-group mobile'>
                                <div className='settings-block'>
                                    <div className='settings-block-row'>{translations.ONLINK_NOTE_DISPLAY_FONT}</div>
                                    <div className='settings-block-row'>
                                        {getWorkboardProperty('workboard_presentation_font_size_notes', properties, translations)}
                                    </div>
                                </div>
                                <div className='settings-block'>
                                    <div className='settings-block-row'>{translations.NOTES}</div>
                                    <div className='settings-block-row multi-line'>
                                        {properties.workboard_presentation_note || NOT_AVAILABLE}
                                    </div>
                                </div>
                            </div>
                            {onLaborRefactorSettings}
                        </fieldset>

                        <div className='settings-group horizontal'>
                            <div className='settings-block-row'>{translations.ONLINK_SHOW_WEATHER}</div>
                            <div className='settings-block-row'>
                                {getWorkboardProperty('workboard_presentation_show_weather', properties, translations)}
                            </div>
                        </div>
                        <div className='settings-group horizontal'>
                            <div className='settings-block-row'>{translations.ONLINK_SHOW_ESTIMATED_HOURS}</div>
                            <div className='settings-block-row'>
                                {getWorkboardProperty('workboard_presentation_show_estimated_hours', properties, translations)}
                            </div>
                        </div>
                        <div className='settings-group horizontal'>
                            <div className='settings-block-row'>{translations.ONLINK_COPY_ESTIMATED_HOURS}</div>
                            <div className='settings-block-row'>
                                {getWorkboardProperty('workboard_copy_estimated_to_actual', properties, translations)}
                            </div>
                        </div>
                        {onLaborUpdatesSettings}
                        <div className='settings-block'>
                            <div className='settings-block-row'>{translations.ONLINK_COVER_PHOTO}</div>
                            <img
                                alt='Cover Photo'
                                className='cover-photo'
                                src={isDefaultImage ? defaultImage : logoDataUrl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </LoadingWrapper>
    );
}

WorkboardSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(WorkboardSettings);
