// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Link, matchPath, withRouter} from 'react-router-dom';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {isCurrentPath} from 'Utils/route';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import 'Sass/persistent-header/ph-tabs.scss';

function Tabs(props) {
    const {
        location: {pathname},
        tabs: tabItems,
        translations,
        ...userAuth
    } = props;

    const filteredTabItems = React.useMemo(
        () => tabItems.filter((tabItem) =>
            !tabItem.hidden &&
            isAuthorized(tabItem, userAuth) &&
            (!tabItem.migratedMembershipAccessOnly || props.isMigrated)
        ),
        [tabItems, userAuth]
    );

    const {
        selectedTab,
        tabs
    } = React.useMemo(() => {
        let selectedTab = false;

        const tabs = filteredTabItems.map((tabItem) => {
            const label = tabItem.label || tabItem.name;
            const path = tabItem.value || tabItem.path;

            const shouldMatchPath = !selectedTab && isCurrentPath(pathname, {
                basePath: pathname,
                prioritizeList: tabItem.prioritizeList
            });

            if (shouldMatchPath) {
                const match = matchPath(pathname, {
                    exact: false,
                    path,
                    strict: false
                });

                const aliasMatch = tabItem.alias && matchPath(pathname, {
                    exact: true,
                    path: tabItem.alias,
                    strict: true
                });

                if (aliasMatch?.path || match?.path) {
                    selectedTab = path;
                }
            }

            const translatedLabel = capitalizeFirstLetter(translations[label]);

            return (
                <MuiTab
                    component={Link}
                    key={path}
                    label={translatedLabel}
                    to={path}
                    value={path}
                />
            );
        });

        return {
            selectedTab,
            tabs
        };
    }, [pathname, filteredTabItems]);

    const displayScrollButtons = React.useMemo(() => tabs.length > 2 ? 'auto' : false, [tabs.length]);

    return (
        <MuiTabs
            orientation='horizontal'
            scrollButtons={displayScrollButtons}
            textColor='primary'
            value={selectedTab}
            variant='scrollable'
        >
            {tabs}
        </MuiTabs>
    );
}

Tabs.defaultProps = {
    tabs: []
};

Tabs.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    hasMyJDSession: PropTypes.bool,
    isMigrated: PropTypes.bool,
    location: PropTypes.location,
    myJdPermissions: PropTypes.myJdPermissions,
    permissions: PropTypes.legacyPermissions,
    superUser: PropTypes.bool,
    tabs: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        hasMyJDSession: state.account.hasMyJDSession,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions,
        superUser: state.account.superUser
    };
}

export default connect(mapStateToProps)(withRouter(Tabs));
