// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {PersistentHeader} from '@deere/persistent-header';
import Entry from 'Ui/components/entry/entry';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {MainNavBarWrapper} from 'Ui/components/main-navbar';
import MainContentBackground from 'Ui/components/main-content-background';
import PersistentHeaderChildWrapper from 'Ui/components/entry/persistent-header-child-wrapper';
import {getSubscriptionForMembership} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {INTERNAL_PH_TOGGLE} from 'Common/constants/feature-toggles';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {MY_JOHN_DEERE} from 'Ui/constants/replace-constants';
import {openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {redirect} from 'Utils/redirect-utils';
import {
    PH_BASE_HOME_ROUTES,
    PH_BASE_FLEET_ROUTES,
    PH_BASE_LABOR_ROUTES,
    PH_BASE_FLEET_MAINTENANCE_ROUTES,
    PH_BASE_PART_ROUTES,
    CONTRIBUTION_PRODUCT_IDS
} from 'Ui/constants/routes/base-route-config';

function callOpenErrorDialog(openErrorDialog, translations, location, setLoading, setSetShowErrorDialog) {
    openErrorDialog({
        messageText: replaceTranslationNames(translations.ONLINK_USER_CREDIENTIALS_NO_LONGER_VALID, {
            '0': MY_JOHN_DEERE
        }),
        buttonText: replaceTranslationNames(translations.ONLINK_MYJOHNDEERE_SIGNIN, {
            '0': MY_JOHN_DEERE
        }),
        onClickAction: () => {
            setLoading(true);
            setSetShowErrorDialog(false);
            redirect(`/api/ops-center-redirect?redirect=${location.pathname}`);
        }
    });
}

function arrayContainsPrefix(stringToCheck, arrayOfStrings) {
    return arrayOfStrings.some((string) => stringToCheck?.startsWith(string));
}

function getContributionProductId(pathName) {
    if (arrayContainsPrefix(pathName, PH_BASE_HOME_ROUTES)) {
        return CONTRIBUTION_PRODUCT_IDS.HOME;
    } else if (arrayContainsPrefix(pathName, PH_BASE_FLEET_ROUTES)) {
        return CONTRIBUTION_PRODUCT_IDS.FLEET;
    } else if (arrayContainsPrefix(pathName, PH_BASE_LABOR_ROUTES)) {
        return CONTRIBUTION_PRODUCT_IDS.LABOR;
    } else if (arrayContainsPrefix(pathName, PH_BASE_FLEET_MAINTENANCE_ROUTES)) {
        return CONTRIBUTION_PRODUCT_IDS.FLEET_MAINTENANCE;
    } else if (arrayContainsPrefix(pathName, PH_BASE_PART_ROUTES)) {
        return CONTRIBUTION_PRODUCT_IDS.PART;
    }
    return CONTRIBUTION_PRODUCT_IDS.COURSE_OPERATIONS;
}

function EntryComponent(props) {
    const {
        hasMyJDSession,
        featureToggles,
        isStandalonePage,
        location,
        mainNavBarProps,
        membership,
        openErrorDialog,
        translations,
        myJdId
    } = props;

    const contributionProductId = React.useMemo(
        () => getContributionProductId(location.pathname),
        [location.pathname]
    );
    const [loading, setLoading] = React.useState(true);
    const [hasSubscription, setHasSubscription] = React.useState(false);
    const [showPersistentHeader, setShowPersistentHeader] = React.useState(hasMyJDSession && membership.isMigrated && !isStandalonePage);
    const [showErrorDialog, setSetShowErrorDialog] = React.useState(false);

    const entryComponent = (
        <Entry
            hasSubscription={hasSubscription}
            isStandalonePage={isStandalonePage}
            loadPersistentHeader={showPersistentHeader}
            translations={translations}
        />
    );

    const page = showPersistentHeader ? (
        <PersistentHeader
            contributionProductId={contributionProductId}
            logoutRedirectUri={`${window.props.myjdRedirectLocation}/ph-logout`}
            render={(renderProps) => (
                <PersistentHeaderChildWrapper
                    {...renderProps}
                    membershipOrgId={membership.orgId}
                    translations={translations}
                >
                    {entryComponent}
                </PersistentHeaderChildWrapper>
            )}
            sourceAppUri={`${window.location.href}?source=opscenter`}
            url={window.props.persistentHeaderUrl}
            userId={myJdId}
        />
    ) : (
        <>
            {
                !isStandalonePage &&
                <>
                    <MainNavBarWrapper {...mainNavBarProps}/>
                    <MainContentBackground/>
                </>
            }
            {
                showErrorDialog ?
                    callOpenErrorDialog(
                        openErrorDialog,
                        translations,
                        location,
                        setLoading,
                        setSetShowErrorDialog
                    ) : entryComponent
            }
        </>
    );

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const subscription = await getSubscriptionForMembership();

        if (isMounted()) {
            setLoading(false);
            setHasSubscription(subscription?.hasSubscription);
            setShowPersistentHeader(hasMyJDSession && membership.isMigrated && !isStandalonePage);
            setSetShowErrorDialog(!hasMyJDSession && membership.isMigrated);
        }
    }), [membership.membershipId]);

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            {
                featureToggles[INTERNAL_PH_TOGGLE] &&
                <SwitchInput
                    checked={showPersistentHeader}
                    className={'ph-toggle'}
                    name='showPHToggle'
                    offLabel={'Show PH'}
                    onChange={() => setShowPersistentHeader(!showPersistentHeader)}
                    onLabel={'Hide PH'}
                    translations={translations}
                />
            }
            {page}
        </LoadingWrapper>
    );
}

EntryComponent.propTypes = {
    featureToggles: PropTypes.featureToggles,
    hasMyJDSession: PropTypes.bool,
    isStandalonePage: PropTypes.bool,
    location: PropTypes.location,
    mainNavBarProps: PropTypes.object,
    membership: PropTypes.membership,
    myJdId: PropTypes.string,
    openErrorDialog: PropTypes.func,
    setMyJdPermissions: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        hasMyJDSession: state.account.hasMyJDSession,
        myJdId: state.account.myJdId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openErrorDialog(props) {
            dispatch(openDialog(dialogTypes.ERROR_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryComponent);
