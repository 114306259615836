// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnLinkDialogNavContent from 'Ui/components/common/onlink-dialog/onlink-dialog-nav-content';
import OnLinkDialogNavMobile from 'Ui/components/common/onlink-dialog/onlink-dialog-nav-mobile';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';

function OnLinkDialogNav(props) {
    const {
        activeTab,
        content,
        isMobile,
        navButton,
        onTabClick
    } = props;

    if (isMobile) {
        return (
            <OnLinkDialogNavMobile
                activeTab={activeTab}
                content={content}
                navButton={navButton}
                onTabClick={onTabClick}
            />
        );
    }

    return (
        <div className='onlink-dialog-navigation'>
            <OnLinkDialogNavContent
                activeTab={activeTab}
                content={content}
                onTabClick={onTabClick}
            />
            {navButton}
        </div>
    );
}

OnLinkDialogNav.propTypes = {
    activeTab: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.dialogContent),
    isMobile: PropTypes.bool,
    navButton: PropTypes.element,
    onTabClick: PropTypes.func
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(OnLinkDialogNav);
