// Unpublished Work © 2022-2024 Deere & Company.

import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import React from 'react';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatModelName} from 'Ui/models/equipment-model';
import PropTypes from 'Utils/prop-type-utils';

function getAdditionalEquipmentInfoString(equipment) {
    return `${equipment.manufacturerName} ${formatModelName(equipment)}${equipment.equipmentType ? ` ${equipment.equipmentType}` : ''}`;
}

function EquipmentTransferForm(props) {
    const {
        equipment,
        membershipSelection,
        membershipSourceName,
        selectedMembershipId,
        setSelectedMembershipId,
        setValid,
        translations
    } = props;

    return (
        <form className='equipment-transfer-dialog-form'>
            <div className='header'>
                <div className='equipment-name'>
                    {equipment.equipmentName}
                </div>
                <div className='equipment-info'>
                    {getAdditionalEquipmentInfoString(equipment)}
                </div>
            </div>
            <ValidationMultiSelect
                className='multiselect'
                component={SortedMultiSelect}
                defaultSelectLabel={translations.ONLINK_SELECT_MEMBERSHIP}
                items={membershipSelection}
                label={`${replaceTranslationNames(translations.ONLINK_TRANSFER_FROM_TO, {
                    '0': membershipSourceName
                })}:`}
                name='membershipSelect'
                onChange={(selectedIds) => setSelectedMembershipId(selectedIds[0])}
                selectedIds={[selectedMembershipId]}
                setValid={setValid}
                tabIndex={0}
            />
        </form>
    );
}

EquipmentTransferForm.propTypes = {
    equipment: PropTypes.equipment,
    membershipSelection: PropTypes.multiSelectList,
    membershipSourceName: PropTypes.string,
    selectedMembershipId: PropTypes.string,
    setSelectedMembershipId: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default EquipmentTransferForm;
