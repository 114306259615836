// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {openDialog} from 'Store/actions/dialogs';
import {useDeepMemo} from 'Utils/react-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {alphaNumericCompare} from 'Ui/models/maintenance';
import {MOWING_DIRECTIONS_ORDER} from 'Ui/components/settings/constants/mowing-directions';
import dialogTypes from 'Ui/components/common/dialog-types';

function getColumns(openMowingDirectionDialog, translations) {
    return [
        {
            Header: translations.ONLINK_MOWING_DIRECTION,
            accessor: 'direction',
            sortMethod(direction1, direction2) {
                const directionOrder1 = MOWING_DIRECTIONS_ORDER[direction1];
                const directionOrder2 = MOWING_DIRECTIONS_ORDER[direction2];

                if (!isNullOrUndefined(directionOrder1) && !isNullOrUndefined(directionOrder2)) {
                    return directionOrder1 - directionOrder2;
                }

                return alphaNumericCompare(direction1, direction2);
            },
            Cell(row) {
                const {
                    index,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openMowingDirectionDialog({
                            mowingDirectionIndex: index
                        })}
                    >
                        {translations[value] || value}
                    </EditLink>
                );
            },
            width: 230
        },
        {
            Header: translations.ONLINK_APPLIES_TO,
            accessor: 'areasApplied'
        },
        {
            Header: translations.STATUS,
            accessor: 'inactive',
            Cell(row) {
                const {value} = row;

                return value ?
                    translations.inactive :
                    translations['EDIT_PDA_PAGE.LABEL.ACTIVE'];
            },
            width: 100
        }
    ];
}

function MowingDirectionsSettings(props) {
    const {
        membership,
        openMowingDirectionDialog,
        translations
    } = props;

    const mowingDirections = useDeepMemo(() => membership.mowingDirections.map((mowingDirection) => ({
        ...mowingDirection,
        areasApplied: mowingDirection.areasApplied.join(', ')
    })), [membership.mowingDirections]);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(openMowingDirectionDialog, translations)}
                defaultSorted={[{
                    desc: false,
                    id: 'direction'
                }]}
                rows={mowingDirections}
                searchable={true}
                translations={translations}
            />
        </div>
    );
}

MowingDirectionsSettings.propTypes = {
    membership: PropTypes.membership,
    openMowingDirectionDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openMowingDirectionDialog(props) {
            dispatch(openDialog(dialogTypes.EDIT_MOWING_DIRECTION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MowingDirectionsSettings);
