// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {sortBy} from 'lodash';

function getMembershipChildLevels(memberships, showMigrated) {
    return memberships.reduce((membershipMap, membership) => {
        const childLevels = membership.childLevels || [];
        const childLevelsToShow = showMigrated ? childLevels : childLevels.filter((child) => !child.isMigrated);

        membershipMap.set(membership.membershipId, sortBy(childLevelsToShow, 'name'));

        return membershipMap;
    }, new Map());
}

function getMembershipSiblings(memberships, isSelected, showMigrated) {
    return memberships.reduce((membershipMap, membership) => {
        const currentMembership = {
            isCurrent: isSelected,
            membershipId: membership.membershipId,
            name: membership.name
        };

        const siblings = [currentMembership];

        if (membership.siblings) {
            if (showMigrated) {
                siblings.push(...membership.siblings);
            } else {
                siblings.push(...membership.siblings.filter((sibling) => !sibling.isMigrated));
            }
        }

        membershipMap.set(membership.membershipId, sortBy(siblings, 'name'));

        return membershipMap;
    }, new Map());
}

function getParentBreadcrumbs(membership, changeMembership) {
    const parentBreadcrumbs = [];

    for (let {parent} = membership; parent; parent = parent.parent) {
        parentBreadcrumbs.push(
            <span
                className='selectable-membership'
                key={parent.membershipId}
                onClick={() => changeMembership(parent.membershipId, parent.orgId, parent.isMigrated)}
            >
                {parent.name}
            </span>
        );
    }

    return parentBreadcrumbs.reverse();
}

function renderSiblings(siblings, membership, children, changeMembership) {
    return siblings.get(membership.membershipId).map((sibling) => {
        if (sibling.isCurrent) {
            return (
                <li
                    className='current-membership'
                    key={sibling.membershipId}
                >
                    <b>{sibling.name}</b>
                    <ul className='children'>
                        {children}
                    </ul>
                </li>
            );
        }

        return (
            <li
                className='sibling-membership'
                key={sibling.membershipId}
            >
                <span
                    className='selectable-membership'
                    onClick={() => changeMembership(sibling.membershipId, sibling.orgId, sibling.isMigrated)}
                >
                    {sibling.name}
                </span>
            </li>
        );
    });
}

function MembershipSelect(props) {
    const {
        memberships,
        showParents,
        isSelected,
        changeMembership,
        showMigrated = false
    } = props;

    const [childLevels, siblings, legacyMemberships] = useDeepMemo(() => {
        const membershipsToShow = showMigrated ? memberships : memberships.filter((membership) => !membership.isMigrated);

        return [
            getMembershipChildLevels(membershipsToShow, showMigrated),
            getMembershipSiblings(membershipsToShow, isSelected, showMigrated),
            membershipsToShow
        ];
    }, [memberships, isSelected]);

    return legacyMemberships.map((membership) => {
        const children = childLevels.get(membership.membershipId).map((child) => (
            <li key={child.membershipId}>
                <span
                    className='selectable-membership'
                    onClick={() => changeMembership(child.membershipId, child.orgId, child.isMigrated)}
                >
                    {child.name}
                </span>
            </li>
        ));

        return (
            <ul key={membership.membershipId}>
                {
                    showParents &&
                    <li className='breadcrumbs'>
                        {getParentBreadcrumbs(membership, changeMembership)}
                    </li>
                }
                <ul className='siblings'>
                    {renderSiblings(siblings, membership, children, changeMembership)}
                </ul>
            </ul>
        );
    });
}

MembershipSelect.propTypes = {
    changeMembership: PropTypes.func,
    isSelected: PropTypes.bool,
    memberships: PropTypes.array,
    showMigrated: PropTypes.bool,
    showParents: PropTypes.bool
};

export default MembershipSelect;
