// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function NoDataMessage(props) {
    return (
        <span>
            {props.translations.ONLINK_NO_DATA}
        </span>
    );
}

NoDataMessage.propTypes = {
    translations: PropTypes.translations
};

export default NoDataMessage;
