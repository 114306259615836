// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ListItem from 'Ui/components/common/list/list-item';
import ListSubheader from 'Ui/components/common/list/list-subheader';
import {connect} from 'react-redux';
import {GroupedPanelList} from '@deere/isg.component-library';

function createGroupPanelList(content, activeTab, onTabClick) {
    const groups = [];

    if (content[0].subHeader === undefined) {
        groups.push({
            items: []
        });
    }

    for (let i = 0; i < content.length; i++) {
        if (content[i].subHeader) {
            groups.push({
                title: content[i].subHeader.charAt(0) + content[i].subHeader.slice(1).toLowerCase(),
                items: []
            });
        } else {
            groups[groups.length - 1].items.push({
                ...content[i],
                active: activeTab === content[i].id,
                key: content[i].id,
                onClick: () => onTabClick(content[i].id),
                title: content[i].label,
                divider: true,
                className: activeTab === content[i].id ? 'group-panel-list-active' : ''
            });
        }
    }

    return (
        <GroupedPanelList
            disableSorting={true}
            groups={groups}
        />
    );
}

function getNavItems(props) {
    const {
        activeTab,
        content,
        isMigrated,
        onTabClick
    } = props;

    if (isMigrated) {
        return createGroupPanelList(content, activeTab, onTabClick);
    }

    // when the code below can be removed, so can the file list-subheader.js
    return content.map((navItem, index) => {
        if (navItem.label) {
            return (
                <ListItem
                    active={activeTab === navItem.id}
                    key={navItem.id}
                    onClick={() => onTabClick(navItem.id)}
                    text={navItem.label}
                />
            );
        }

        return (
            <ListSubheader
                key={`${navItem.subHeader}${index}`}
                text={navItem.subHeader}
            />
        );
    });
}

function OnLinkDialogNavContent(props) {
    return (
        <div className='onlink-dialog-navigation-content'>
            {getNavItems(props)}
        </div>
    );
}

OnLinkDialogNavContent.propTypes = {
    activeTab: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.dialogContent),
    isMigrated: PropTypes.bool,
    onTabClick: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(OnLinkDialogNavContent);
