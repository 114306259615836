// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {BasicDialog} from '@deere/basic-dialog';
import moment from 'moment';
import {updateService} from 'Services/maintenance-service';
import serviceStatusConstants from 'Ui/constants/service-status-constants';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import ValidationDate from 'Ui/components/common/form/validation-date';

function initializeState() {
    const [rescheduleDate, setRescheduleDate] = React.useState(() => moment());

    return {
        rescheduleDate,
        setRescheduleDate
    };
}

function footerActions(onContinue, onCancel, translations) {
    const continueButton = {
        callback: onContinue,
        cssClasses: 'onlink-btn primary',
        title: translations.ONLINK_RESCHEDULE
    };
    const cancelButton = {
        callback: onCancel,
        cssClasses: 'incomplete-service-tasks-dialog-decline onlink-btn primary',
        title: translations.DECLINE
    };

    return [cancelButton, continueButton];
}

function ServiceIncompleteTasksDialog(props) {
    const {
        serviceTasksData,
        checkListSelectedIds,
        multiSelectSelectedIds,
        redirect,
        setValid,
        skippedService,
        translations
    } = props;

    const {
        rescheduleDate,
        setRescheduleDate
    } = initializeState();

    function onChangeDate(date) {
        setRescheduleDate(date);
    }

    async function save(date) {
        skippedService.dateScheduled = moment(date).format('YYYYMMDD').toString();
        skippedService.status = serviceStatusConstants.scheduled.id;

        await updateService(skippedService, skippedService.serviceId);

        redirect();
    }

    const incompleteServiceTasks = serviceTasksData.filter((serviceTask) => {
        return !checkListSelectedIds.includes(serviceTask.id) && multiSelectSelectedIds.includes(serviceTask.id);
    });

    const body = (<div className='incomplete-service-tasks-dialog-body'>
        <div className='incomplete-service-tasks-dialog-title'>
            {`${translations.ONLINK_INCOMPLETE_TASKS_MESSAGE}:`}
        </div>
        {
            incompleteServiceTasks.map((task) => {
                return (<div
                    className='incomplete-service-tasks-dialog-task'
                    key={task.id}
                >
                    {task.label}
                </div>);
            })
        }
        <div className='incomplete-service-tasks-dialog-input'>
            <ValidationDate
                dateFormat='L'
                label={translations.ONLINK_RESCHEDULE_SERVICE_ON}
                large={true}
                locale={getLanguagePreference()}
                name='rescheduleServiceTask'
                onChange={onChangeDate}
                required={false}
                selected={rescheduleDate}
                setValid={setValid}
                tabIndex={0}
            />
        </div>
    </div>);

    return (
        <BasicDialog
            className='incomplete-service-tasks-dialog'
            closeHandler={redirect}
            footerActions={footerActions(save, redirect, translations)}
            show={true}
            title={translations.ONLINK_INCOMPLETE_SERVICE_TASKS}
        >
            {body}
        </BasicDialog>
    );
}

ServiceIncompleteTasksDialog.propTypes = {
    checkListSelectedIds: PropTypes.arrayOf(PropTypes.string),
    multiSelectSelectedIds: PropTypes.arrayOf(PropTypes.string),
    redirect: PropTypes.func,
    serviceTasksData: PropTypes.arrayOf(PropTypes.object),
    setValid: PropTypes.func,
    skippedService: PropTypes.object,
    translations: PropTypes.translations
};

export default ServiceIncompleteTasksDialog;
