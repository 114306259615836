// Unpublished Work © 2021-2024 Deere & Company.

import {updateEquipment} from 'Services/equipment-service';
import {REJECTED} from 'Common/constants/response-status';

async function bulkUpdateEquipment(updateEquipmentEngineHours) {
    const filteredEquipmentToUpdateEngineHours = updateEquipmentEngineHours.filter((equipment) => equipment.totalHours !== '');
    const toBeUpdated = filteredEquipmentToUpdateEngineHours.map((equipmentData) => updateEquipment(equipmentData, equipmentData.equipmentId));

    const results = await Promise.allSettled(toBeUpdated);

    return results.reduce((names, result, index) => {
        if (result.status === REJECTED) {
            const {equipmentName} = filteredEquipmentToUpdateEngineHours[index];

            return names.concat(equipmentName);
        }

        return names;
    }, []);
}

export {
    bulkUpdateEquipment
};
