// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import ForecastChart from 'Ui/components/graph/weather/forecast-chart';
import Panel from 'Ui/components/panel/panel';
import TabLayout from 'Ui/components/tab/tab-layout';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {fetchEffectData} from 'Utils/react-utils';
import {DATE_FORMATS, formatLocalizedTime, formatTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {getDataSource} from 'Ui/services/panel-service';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import moment from 'moment';
import {convertToFormattedUoM, convertToUoM, getUoMConfig} from 'Utils/unit-conversion-utils';
import {getUnitOfMeasureOverride} from 'Common/utils/units/unit-converter';
import {DEGREES_F, INCHES_WEATHER, MILES_PER_HOUR} from 'Common/constants/data-unit-constants';
import {round} from 'lodash';
import {WSPD} from 'Common/constants/data-group-constants';

const LEFT_Y_AXIS = 'leftYAxis';
const RIGHT_Y_AXIS = 'rightYAxis';
const TEMP = 'temp';
const MAX_TEMP = 'max_temp';
const MIN_TEMP = 'min_temp';
const QPF = 'qpf';

function getConvertedDataPoints(hourlyForecast, variables) {
    return hourlyForecast.map((forecast) => {
        const forecastArr = {
            ...forecast
        };

        return variables.reduce((forecastCollection, variable) => {
            forecastCollection[variable.name] = round(convertToUoM(forecast[variable.name], variable.conversionConfig), variable.decimalPlaces);

            return forecastCollection;
        }, forecastArr);
    });
}

function getDataPoints(data, value, format) {
    return data.map((item) => {
        const time = moment(item.fcst_valid_local);

        return {
            x: format ? time.startOf('day') : time,
            y: item[value]
        };
    });
}

function getHourlyData(data, translations, tempConversionConfig) {
    if (data && data.hourlyForecast && data.hourlyForecast.length) {
        const {hourlyForecast} = data;

        const conversionVariables = [
            {
                name: TEMP,
                conversionConfig: tempConversionConfig,
                decimalPlaces: 0
            }
        ];

        const convertedForecast = getConvertedDataPoints(hourlyForecast, conversionVariables);

        const tempData = getDataPoints(convertedForecast, TEMP, false);
        const humidity = getDataPoints(hourlyForecast, 'rh', false);
        const precip = getDataPoints(hourlyForecast, 'pop', false);

        const {formatting} = getUoMConfig(tempConversionConfig);

        return [
            {
                backgroundColor: GRAPH_COLORS[0],
                borderColor: GRAPH_COLORS[0],
                data: tempData,
                fill: false,
                label: `${translations.ONLINK_TEMP} (${formatting.suffix})`,
                unitConfig: formatting.suffix,
                yAxisID: LEFT_Y_AXIS
            },
            {
                backgroundColor: GRAPH_COLORS[1],
                borderColor: GRAPH_COLORS[1],
                data: humidity,
                fill: false,
                label: `${translations['Observation.HUMIDITY']} (${UNITS.percent})`,
                unitConfig: UNITS.percent,
                yAxisID: RIGHT_Y_AXIS
            },
            {
                backgroundColor: GRAPH_COLORS[2].replace('1.0', '0.2'),
                borderColor: GRAPH_COLORS[2],
                data: precip,
                fill: true,
                label: `${translations.ONLINK_CHANCE_PRECIP} (${UNITS.percent})`,
                unitConfig: UNITS.percent,
                yAxisID: RIGHT_Y_AXIS
            }
        ];
    }

    return [];
}

function getHourlyTableData(data, translations, tempConversionConfig, inchConversionConfig, mphConversionConfig) {
    if (data && data.hourlyForecast && data.hourlyForecast.length) {
        const {hourlyForecast} = data;
        const hourlyHeaders = [
            translations.TIME,
            translations.ONLINK_CONDITIONS,
            translations.ONLINK_TEMP,
            translations.ONLINK_PRECIP,
            translations.ONLINK_TOTAL,
            translations.ONLINK_HUMID,
            translations.ONLINK_DEW,
            translations.weather_WIND
        ];

        return {
            header: hourlyHeaders,
            data: hourlyForecast.map((forecast) => [
                formatLocalizedTime(moment(forecast.fcst_valid_local), {
                    hour: 'numeric'
                }),
                <>
                    <img
                        alt={forecast.icon_code.toString()}
                        className='weather-graph-icon'
                        src={getWeatherIcon(forecast.icon_code)}
                    />
                    {forecast.phrase_22char}
                </>,
                convertToFormattedUoM(forecast.temp, tempConversionConfig),
                `${forecast.pop}${UNITS.percent}`,
                convertToFormattedUoM(forecast.qpf, inchConversionConfig),
                `${forecast.rh}${UNITS.percent}`,
                convertToFormattedUoM(forecast.dewpt, tempConversionConfig),
                `${forecast.wdir_cardinal} ${Math.round(convertToUoM(forecast.wspd, mphConversionConfig))}`
            ])
        };
    }

    return null;
}

function getExtendedData(data, translations, tempConversionConfig, inchConversionConfig) {
    if (data && data.dailyForecast && data.dailyForecast.length) {
        const {formatting: tempFormatting} = getUoMConfig(tempConversionConfig);
        const {formatting: inchFormatting} = getUoMConfig(inchConversionConfig);

        const conversionVariables = [
            {
                name: MAX_TEMP,
                conversionConfig: tempConversionConfig,
                decimalPlaces: 0
            },
            {
                name: MIN_TEMP,
                conversionConfig: tempConversionConfig,
                decimalPlaces: 0
            },
            {
                name: QPF,
                conversionConfig: inchConversionConfig,
                decimalPlaces: inchFormatting.decimalPlaces
            }
        ];

        const convertedForecast = getConvertedDataPoints(data.dailyForecast, conversionVariables);

        const maxTempDataPoints = getDataPoints(convertedForecast, MAX_TEMP, true);
        const minTempDataPoints = getDataPoints(convertedForecast, MIN_TEMP, true);
        const totalPrecip = getDataPoints(convertedForecast, QPF, true);

        return [
            {
                backgroundColor: GRAPH_COLORS[0],
                borderColor: GRAPH_COLORS[0],
                data: maxTempDataPoints,
                fill: false,
                label: `${translations.ONLINK_MAX_TEMPERATURE} (${tempFormatting.suffix})`,
                unitConfig: tempFormatting.suffix,
                yAxisID: LEFT_Y_AXIS
            },
            {
                backgroundColor: GRAPH_COLORS[1],
                borderColor: GRAPH_COLORS[1],
                data: minTempDataPoints,
                fill: false,
                label: `${translations.ONLINK_MIN_TEMPERATURE} (${tempFormatting.suffix})`,
                unitConfig: tempFormatting.suffix,
                yAxisID: LEFT_Y_AXIS
            },
            {
                backgroundColor: GRAPH_COLORS[2].replace('1.0', '0.2'),
                borderColor: GRAPH_COLORS[2],
                data: totalPrecip,
                fill: true,
                label: `${translations.ONLINK_TOTAL_PRECIP} (${inchFormatting.suffix})`,
                unitConfig: inchFormatting.suffix,
                yAxisID: RIGHT_Y_AXIS
            }
        ];
    }

    return [];
}

function getExtendedTableData(data, translations, tempConversionConfig, mphConversionConfig) {
    if (data && data.dailyForecast && data.dailyForecast.length) {
        const {dailyForecast} = data;
        const extendedHeaders = [
            translations.DATE,
            translations.ONLINK_DAY,
            translations.ONLINK_TEMP,
            translations.ONLINK_HUMID,
            translations.ONLINK_PRECIP,
            translations.weather_WIND,
            translations.ONLINK_NIGHT,
            translations.ONLINK_TEMP,
            translations.ONLINK_HUMID,
            translations.ONLINK_PRECIP,
            translations.weather_WIND
        ];

        return {
            header: extendedHeaders,
            data: dailyForecast.map((forecast) => [
                formatLocalizedTime(moment(forecast.fcst_valid_local), TIME_UNIT_OPTIONS.day),
                forecast.day ? (
                    <>
                        <img
                            alt={forecast.day.icon_code.toString()}
                            className='weather-graph-icon'
                            src={getWeatherIcon(forecast.day.icon_code)}
                        />
                        {forecast.day.phrase_22char}
                    </>
                ) : '',
                forecast.day ? convertToFormattedUoM(forecast.day.temp, tempConversionConfig) : '',
                forecast.day ? `${forecast.day.rh}${UNITS.percent}` : '',
                forecast.day ? `${forecast.day.pop}${UNITS.percent}` : '',
                forecast.day ? `${forecast.day.wdir_cardinal} ${Math.round(convertToUoM(forecast.day.wspd, mphConversionConfig))}` : '',
                forecast.night ? (
                    <>
                        <img
                            alt={forecast.night.icon_code.toString()}
                            className='weather-graph-icon'
                            src={getWeatherIcon(forecast.night.icon_code)}
                        />
                        {forecast.night.phrase_22char}
                    </>
                ) : '',
                forecast.night ? convertToFormattedUoM(forecast.night.temp, tempConversionConfig) : '',
                forecast.night ? `${forecast.night.rh}${UNITS.percent}` : '',
                forecast.night ? `${forecast.night.pop}${UNITS.percent}` : '',
                forecast.night ? `${forecast.night.wdir_cardinal} ${Math.round(convertToUoM(forecast.night.wspd, mphConversionConfig))}` : ''
            ])
        };
    }

    return null;
}

function initializeState() {
    const [data, setData] = React.useState(() => null);
    const [loading, setLoading] = React.useState(() => true);

    return {
        data,
        setData,
        loading,
        setLoading
    };
}

function WeatherGraph(props) {
    const {
        membershipId,
        translations,
        unitOfMeasure,
        featureToggles,
        unitOfMeasureOverrides
    } = props;

    const {
        data,
        setData,
        loading,
        setLoading
    } = initializeState();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const dateSelect = formatTime(new Date(), DATE_FORMATS.day);
        const twcData = await getDataSource('TWC', dateSelect);

        if (isMounted()) {
            setData(twcData);
            setLoading(false);
        }
    }), [membershipId]);

    const tabs = [translations.HOURLY.toUpperCase(), translations.ONLINK_EXTENDED.toUpperCase()];

    const conversionConfig = {
        featureToggles,
        translations,
        unitOfMeasure
    };

    const tempConversionConfig = {
        ...conversionConfig,
        dataUnit: DEGREES_F
    };

    const inchConversionConfig = {
        ...conversionConfig,
        dataUnit: INCHES_WEATHER
    };

    const windSpeedUnitOfMeasure = React.useMemo(() =>
        getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, WSPD)
    , [unitOfMeasureOverrides, unitOfMeasure]);

    const mphConversionConfig = {
        ...conversionConfig,
        dataUnit: MILES_PER_HOUR,
        unitOfMeasure: windSpeedUnitOfMeasure
    };

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={!data && loading}
            size='50px'
        >
            <Panel
                className='integrations'
                title={translations.forecast.toUpperCase()}
                translations={translations}
            >
                <TabLayout tabs={tabs}>
                    <ForecastChart
                        data={getHourlyData(data, translations, tempConversionConfig)}
                        key='hourly'
                        tableClassName='hourly-table'
                        tableData={getHourlyTableData(data, translations, tempConversionConfig, inchConversionConfig, mphConversionConfig)}
                        timeUnit='hour'
                        timeUnitOptions={{
                            hour: 'numeric'
                        }}
                        translations={translations}
                    />
                    <ForecastChart
                        data={getExtendedData(data, translations, tempConversionConfig, inchConversionConfig)}
                        key='extended'
                        tableClassName='extended-table'
                        tableData={getExtendedTableData(data, translations, tempConversionConfig, mphConversionConfig)}
                        timeUnit='day'
                        timeUnitOptions={{
                            month: 'short',
                            day: 'numeric'
                        }}
                        translations={translations}
                    />
                </TabLayout>
            </Panel>
        </LoadingWrapper>
    );
}

WeatherGraph.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membershipId: PropTypes.string,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.array
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membershipId: state.membership.membershipId,
        unitOfMeasure: state.membership.unitOfMeasure,
        unitOfMeasureOverrides: state.membership.unitOfMeasureOverrides
    };
}

export default connect(mapStateToProps)(WeatherGraph);
