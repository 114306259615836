// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {
    getAreaOptions,
    getHoleOptions,
    onManualDataChange,
    validateDate,
    getLocalTimeFormats
} from 'Ui/components/manual-data/manual-data-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {isEmptyString, isNullOrUndefined} from 'Common/utils/validation-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {DEGREES_C, DEGREES_F, PERCENT} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import moment from 'moment';

function getTemperatureUnitAndLabel(manualDataUnit, unitOfMeasure, translations) {
    const tempUnit = manualDataUnit || (unitOfMeasure === UNIT_OF_MEASURE.METRIC ? DEGREES_C : DEGREES_F);

    return {
        tempLabel: tempUnit === DEGREES_C ?
            translations.ONLINK_DEGREES_CELSIUS :
            translations.ONLINK_DEGREES_FAHRENHEIT,
        tempUnit
    };
}

function valueOrEmptyString(value) {
    return isNullOrUndefined(value) ? '' : value;
}

function getMemoizedData(unitOfMeasure, translations, values) {
    const [areaOptions, holeOptions] = useDeepMemo(() => [
        getAreaOptions(translations),
        getHoleOptions(translations)
    ], [translations]);
    const manualDataMap = useDeepMemo(() => {
        const {manualData = [] } = values;

        return manualData.reduce((manualDataByType, manualData) => {
            manualDataByType.set(manualData.data_type, manualData);

            return manualDataByType;
        }, new Map());
    }, [values.manualData]);

    const moisture = manualDataMap.get('moisture') || {};
    const temp = manualDataMap.get('temp') || {};

    const {
        tempLabel,
        tempUnit
    } = getTemperatureUnitAndLabel(temp.data_unit, unitOfMeasure, translations);

    return {
        areaOptions,
        holeOptions,
        isInvalid: isEmptyString(moisture.value) && isEmptyString(temp.value),
        moisture: valueOrEmptyString(moisture.value),
        temp: valueOrEmptyString(temp.value),
        tempLabel,
        tempUnit
    };
}

function getSharedInputProps(translations, setValid) {
    return {
        sharedInputProps: {
            debounceTimeout: window.props.debounceTimeout,
            max: 300,
            setValid,
            step: 'any',
            tabIndex: 0,
            type: 'number'
        },
        sharedMultiSelectProps: {
            className: 'mobile-required-multiselect',
            error: translations.REQUIRED_FIELD_TEXT,
            hideCheckboxes: true,
            multiple: false,
            required: true,
            setValid,
            tabIndex: 0
        }
    };
}

function SoilMoistureEntryForm(props) {
    const {
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    const [validatedDate, setValidatedDate] = React.useState();
    const {
        areaOptions,
        holeOptions,
        isInvalid,
        moisture,
        temp,
        tempLabel,
        tempUnit
    } = getMemoizedData(membership.unitOfMeasure, translations, values);

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    React.useEffect(() => onValuesChange('holeLocation', areaOptions[0].id), []);

    React.useEffect(() => {
        const validatedDateFromTime = validateDate(values.timeSample, setValid);

        setValidatedDate(validatedDateFromTime);
    }, [values.timeSample]);

    const {
        sharedInputProps,
        sharedMultiSelectProps
    } = getSharedInputProps(translations, setValid);

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LLL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE_AND_TIME}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
                showTimeSelect={true}
                timeFormat={getLocalTimeFormats()}
                timeIntervals={1}
            />
            <div className='settings-group mobile'>
                <ValidationMultiSelect
                    {...sharedMultiSelectProps}
                    items={holeOptions}
                    label={translations.ONLINK_HOLES}
                    name='hole'
                    onChange={(selectedIds) => onValuesChange('hole', selectedIds[0])}
                    selectedIds={[values.hole]}
                />
                <ValidationMultiSelect
                    {...sharedMultiSelectProps}
                    items={areaOptions}
                    label={translations.AREA}
                    name='holeLocation'
                    onChange={(selectedIds) => onValuesChange('holeLocation', selectedIds[0])}
                    selectedIds={[values.holeLocation]}
                />
            </div>
            <div className='settings-group mobile'>
                <ValidationInput
                    {...sharedInputProps}
                    errors={{
                        customError: translations.REQUIRED_FIELD_TEXT,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} 300`,
                        rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${MIN_NUMERIC_VALUE}`
                    }}
                    invalidCustomError={isInvalid}
                    label={`${translations.Moisture} %`}
                    min={MIN_NUMERIC_VALUE}
                    name='moisture'
                    onChange={(event) => onManualDataChange({
                        dataUnit: PERCENT,
                        name: event.target.name,
                        setValues,
                        value: event.target.value
                    })}
                    setValid={setValid}
                    value={moisture}
                />
                <ValidationInput
                    {...sharedInputProps}
                    errors={{
                        customError: translations.REQUIRED_FIELD_TEXT,
                        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} 300`,
                        rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} -150`
                    }}
                    invalidCustomError={isInvalid}
                    label={`${translations.ONLINK_SOIL_TEMP} (${tempLabel})`}
                    min={-150}
                    name='temp'
                    onChange={(event) => onManualDataChange({
                        dataUnit: tempUnit,
                        name: event.target.name,
                        setValues,
                        value: event.target.value
                    })}
                    setValid={setValid}
                    value={temp}
                />
            </div>
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

SoilMoistureEntryForm.propTypes = {
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default SoilMoistureEntryForm;
