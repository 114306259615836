// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function batchUpsertInviteStatuses(inviteStatusesData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/inviteStatus', inviteStatusesData);

    return response.data;
}

async function getInviteStatus(appUserId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/inviteStatus/${appUserId}`);

    return response.data;
}

async function getCurrentMembershipInviteStatuses() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/inviteStatus/currentMembership');

    return response.data;
}

export {
    batchUpsertInviteStatuses,
    getInviteStatus,
    getCurrentMembershipInviteStatuses
};
