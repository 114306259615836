// Unpublished Work © 2020-2024 Deere & Company.

export const UPDATE_EQUIPMENT_AREAS = 'UPDATE_EQUIPMENT_AREAS';
export const UPDATE_EQUIPMENT_TYPES = 'UPDATE_EQUIPMENT_TYPES';

export function updateEquipmentAreas(equipmentAreas) {
    return {
        type: UPDATE_EQUIPMENT_AREAS,
        equipmentAreas
    };
}

export function updateEquipmentTypes(equipmentTypes) {
    return {
        type: UPDATE_EQUIPMENT_TYPES,
        equipmentTypes
    };
}
