// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function UserDetails(props) {
    const {user} = props;

    return (
        <div className='account-item user-details'>
            <div className='user-name'>
                {`${user.firstName} ${user.lastName}`}
            </div>
            <div className='user-email'>
                {user.email}
            </div>
        </div>
    );
}

UserDetails.propTypes = {
    user: PropTypes.object
};

export default UserDetails;
