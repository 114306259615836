// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {reduceFeatureToggles} from 'Common/utils/feature-toggles';

async function getFeatureToggles() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/feature-toggles');

    return response.data.reduce(reduceFeatureToggles, {});
}

export {
    getFeatureToggles
};
