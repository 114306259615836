// Unpublished Work © 2020-2024 Deere & Company.

const WATER_ENERGY_2_ID = '322740a9-fab4-4cd6-806d-51ae0cfc3258';
const WATER_ENERGY_3_ID = '464419ed-2dad-451b-9894-fbc2c1ccf2b5';
const WATER_ENERGY_4_ID = '61779f7e-abab-4840-8c6c-dca80aeac47b';

const WATER_ENERGY_2_NAME = 'water_energy_2';
const WATER_ENERGY_3_NAME = 'water_energy_3';
const WATER_ENERGY_4_NAME = 'water_energy_4';

export default {
    tilePositions: [
        {
            tile: {
                id: WATER_ENERGY_2_ID,
                name: WATER_ENERGY_2_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 0,
                w: 1,
                h: 3,
                i: WATER_ENERGY_2_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_3_ID,
                name: WATER_ENERGY_3_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: WATER_ENERGY_3_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_4_ID,
                name: WATER_ENERGY_4_NAME
            },
            columnCount: 3,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: WATER_ENERGY_4_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_2_ID,
                name: WATER_ENERGY_2_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 3,
                i: WATER_ENERGY_2_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_3_ID,
                name: WATER_ENERGY_3_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: WATER_ENERGY_3_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_4_ID,
                name: WATER_ENERGY_4_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: WATER_ENERGY_4_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_2_ID,
                name: WATER_ENERGY_2_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 5,
                w: 1,
                h: 3,
                i: WATER_ENERGY_2_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_3_ID,
                name: WATER_ENERGY_3_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: WATER_ENERGY_3_NAME
            }
        },
        {
            tile: {
                id: WATER_ENERGY_4_ID,
                name: WATER_ENERGY_4_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: WATER_ENERGY_4_NAME
            }
        }
    ]
};
