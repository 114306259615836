// Unpublished Work © 2023-2024 Deere & Company.

const PIXEL_SCROLL_DOWN_AMOUNT = 3;
const PIXEL_SCROLL_UP_AMOUNT = -3;

function clearTouchInterval(touch, dragContainerPosition, screenTouchTimerRef) {
    const screenTouchedInsideContainer = touch.pageY >= dragContainerPosition.top &&
        touch.pageY <= dragContainerPosition.bottom;

    if (screenTouchedInsideContainer) {
        clearInterval(screenTouchTimerRef.current);

        screenTouchTimerRef.current = null;
    }
}

function moveHandler(position, screenTouchTimerRef, containerId) {
    const dragContainer = document.getElementById(containerId);
    const dragContainerPosition = dragContainer.getBoundingClientRect();

    if (!screenTouchTimerRef.current) {
        if (position.pageY < dragContainerPosition.top) {
            screenTouchTimerRef.current = setInterval(() => dragContainer.scrollBy(0, PIXEL_SCROLL_UP_AMOUNT), 1);
        }

        if (position.pageY > dragContainerPosition.bottom) {
            screenTouchTimerRef.current = setInterval(() => dragContainer.scrollBy(0, PIXEL_SCROLL_DOWN_AMOUNT), 1);
        }
    } else {
        clearTouchInterval(position, dragContainerPosition, screenTouchTimerRef);
    }
}

function onDragStart(elementId, screenTouchListener) {
    const draggableElement = document.getElementById(elementId);

    draggableElement.addEventListener('touchmove', screenTouchListener);
}

function onDragStop(elementId, screenTouchListener, screenTouchTimerRef) {
    const draggableElement = document.getElementById(elementId);

    draggableElement.removeEventListener('touchmove', screenTouchListener);

    clearInterval(screenTouchTimerRef.current);
    screenTouchTimerRef.current = null;
}

function scrollToNewestItem(ref, isNew) {
    if (ref && isNew) {
        setTimeout(() => {
            ref.scrollIntoView({
                behavior: 'smooth'
            });
        }, 0);
    }
}

export {
    moveHandler,
    onDragStart,
    onDragStop,
    scrollToNewestItem
};
