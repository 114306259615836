// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Bar} from 'react-chartjs-2';
import {getGraphTimeScaleUnit} from 'Utils/graph-utils';
import {formatLocalizedTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {formatNumber, getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {COURSE} from 'Common/constants/membership-type';
import {SCHEDULE_STATUS} from 'Ui/components/graph/constants/graph-filters';
import {GRAPH_COLORS} from 'Ui/constants/graph-constants';
import {MINUTES_PER_HOUR} from 'Ui/constants/time-constants';
import {groupBy} from 'lodash';
import moment from 'moment';

function transformData(data, {
    primarySelector,
    tertiarySelector
}) {
    if (primarySelector === SCHEDULE_STATUS) {
        return data.optionMap?.[tertiarySelector];
    }

    const {[tertiarySelector]: dataValue} = data;

    return tertiarySelector.includes('Cost') ?
        dataValue :
        dataValue / MINUTES_PER_HOUR;
}

function formatValue(value, {
    featureToggles,
    membership,
    primarySelector,
    tertiarySelector,
    translations
}) {
    if (primarySelector === SCHEDULE_STATUS) {
        return `${formatNumber(value)} ${translations.DAYS}`;
    }

    if (tertiarySelector.includes('Cost')) {
        return getFormattedCurrency(value, {
            currencyPreference: membership.currencyPreference,
            featureToggles
        });
    }

    return `${formatNumber(value)} ${translations.HOURS_UOM}`;
}

function getXAxis(isTimeAxis, isCourseOrStatus, timeScaleUnit, translations) {
    if (isTimeAxis) {
        return {
            ticks: {
                callback(tickValue, index, ticks) {
                    return isCourseOrStatus ?
                        formatLocalizedTime(moment(ticks[index].value), TIME_UNIT_OPTIONS[timeScaleUnit]) :
                        translations.ONLINK_TOTAL;
                }
            },
            time: {
                unit: timeScaleUnit
            },
            type: 'time'
        };
    }

    const categoryAxis = {
        type: 'category'
    };

    return isCourseOrStatus ? categoryAxis : {
        ...categoryAxis,
        ticks: {
            callback() {
                return translations.ONLINK_TOTAL;
            }
        }
    };
}

function getDatasets(chartDataForSelector, groupKey, primarySelector, tertiarySelector) {
    const groupedData = groupBy(chartDataForSelector, 'name');

    let colorIndex = 0;

    return Object.keys(groupedData).reduce((datasets, group) => {
        const color = GRAPH_COLORS[colorIndex];
        const groupValueToIndexMap = new Map();

        const dataset = groupedData[group].reduce((datasetForName, data) => {
            const {[groupKey]: xValue} = data;

            const yValue = transformData(data, {
                primarySelector,
                tertiarySelector
            });

            if (yValue) {
                if (!groupValueToIndexMap.has(xValue)) {
                    groupValueToIndexMap.set(xValue, datasetForName.data.length);

                    datasetForName.data.push({
                        x: xValue,
                        y: yValue
                    });
                } else {
                    const groupValueIndex = groupValueToIndexMap.get(xValue);

                    datasetForName.data[groupValueIndex].y += yValue;
                }
            }

            return datasetForName;
        }, {
            backgroundColor: color,
            borderColor: color,
            data: [],
            fill: true,
            label: group
        });

        if (dataset.data.length === 0) {
            return datasets;
        }

        colorIndex += 1;

        return datasets.concat(dataset);
    }, []);
}

function getLabels(datasets) {
    const labels = datasets.reduce((allLabels, dataset) => dataset.data.reduce((labelsForDataset, data) => {
        labelsForDataset.add(data.x);

        return labelsForDataset;
    }, allLabels), new Set());

    return Array.from(labels);
}

function WorkboardBarChart(props) {
    const {
        chartDataForSelector,
        featureToggles,
        isTimeAxis,
        membership,
        primarySelector,
        tertiarySelector,
        timeScale,
        translations
    } = props;

    const isCourseOrStatus = membership.membershipType === COURSE || primarySelector === SCHEDULE_STATUS;

    const groupKey = isTimeAxis ? 'dateSelect' : 'name';
    const timeScaleUnit = getGraphTimeScaleUnit(timeScale);

    const datasets = getDatasets(chartDataForSelector, groupKey, primarySelector, tertiarySelector);

    return (
        <Bar
            data={{
                datasets,
                labels: getLabels(datasets)
            }}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: isTimeAxis
                    },
                    tooltip: {
                        callbacks: {
                            title(tooltipItems) {
                                const [{
                                    label,
                                    parsed
                                }] = tooltipItems;

                                if (isCourseOrStatus) {
                                    return isTimeAxis ?
                                        formatLocalizedTime(parsed.x, TIME_UNIT_OPTIONS[timeScaleUnit]) :
                                        label;
                                }

                                return translations.ONLINK_TOTAL;
                            },
                            label(tooltipItem) {
                                const {
                                    dataset,
                                    parsed
                                } = tooltipItem;

                                const formattedValue = formatValue(parsed.y, {
                                    featureToggles,
                                    membership,
                                    primarySelector,
                                    tertiarySelector,
                                    translations
                                });

                                return isTimeAxis ?
                                    `${dataset.label}: ${formattedValue}` :
                                    formattedValue;
                            }
                        }
                    }
                },
                scales: {
                    yAxis: {
                        axis: 'y',
                        position: 'left',
                        stacked: true,
                        ticks: {
                            callback(tickValue) {
                                return formatValue(tickValue, {
                                    featureToggles,
                                    membership,
                                    primarySelector,
                                    tertiarySelector,
                                    translations
                                });
                            }
                        },
                        type: 'linear'
                    },
                    xAxis: {
                        axis: 'x',
                        position: 'bottom',
                        stacked: true,
                        ...getXAxis(isTimeAxis, isCourseOrStatus, timeScaleUnit, translations)
                    }
                }
            }}
            type='bar'
        />
    );
}

WorkboardBarChart.propTypes = {
    chartDataForSelector: PropTypes.arrayOf(PropTypes.object),
    featureToggles: PropTypes.featureToggles,
    isTimeAxis: PropTypes.bool,
    membership: PropTypes.membership,
    primarySelector: PropTypes.string,
    tertiarySelector: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export default WorkboardBarChart;
