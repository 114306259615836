// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';

async function invokeSave(onSave, setIsSaving, isMounted, ...args) {
    setIsSaving(true);

    try {
        return await onSave(...args);
    } finally {
        if (isMounted.current) {
            setIsSaving(false);
        }
    }
}

function useSave(onSave, {
    disabled = false,
    invalidInputs = new Set()
} = {}) {
    const isMounted = React.useRef(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const disableSave = isSaving || disabled || invalidInputs.size > 0;

    React.useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const saveFunc = React.useCallback(async (event, ...args) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        return !disableSave ?
            await invokeSave(onSave, setIsSaving, isMounted, event, ...args) :
            undefined;
    }, [disableSave, onSave]);

    return [saveFunc, disableSave, isSaving];
}

export {
    useSave
};
