// Unpublished Work © 2020-2024 Deere & Company.

import * as translationsActions from 'Store/actions/translations';

const DEFAULT_STATE = {};

const translations = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case translationsActions.SET_TRANSLATIONS:
            return Object.assign({}, state, {
                ...action.translations
            });
        default:
            return state;
    }
};

export default translations;
