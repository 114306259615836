// Unpublished Work © 2020-2024 Deere & Company.

import moment from 'moment';
import logStateConstants from 'OnEquip/equipment/service-form/utils/log-state-constants';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';

const DATE_FORMATS = {
    year: 'YYYY',
    month: 'YYYYMM',
    day: 'YYYYMMDD',
    hour: 'YYYYMMDDHH'
};
const DISPLAY_FORMATS = {
    minute: 'HH:mm',
    hour: 'MM/DD HH:mm',
    day: 'MM/DD',
    month: 'MMM'
};

const TIME_UNIT_OPTIONS = {
    minute: {
        hour: 'numeric',
        minute: 'numeric'
    },
    hour: {
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
    },
    day: {
        month: '2-digit',
        day: '2-digit'
    },
    month: {
        month: 'short'
    }
};
const MANUAL_DATA_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const MILLISECONDS = 'milliseconds';
const MINUTES = 'minutes';

const MONTH_LENGTH = 6;
const DAY_LENGTH = 8;
const HOUR_LENGTH = 10;

const HOUR = 60;
const DAY = 1440;

const DATE_FORMAT_OPTIONS = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
};

const DATE_TIME_FORMAT_OPTIONS = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};

const YEAR_FORMAT_OPTIONS = {
    month: 'short',
    year: 'numeric'
};

const YEAR_NO_MONTH_OPTIONS = {
    year: 'numeric'
};

const WEEKDAY_AND_NUMERIC_DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
};

function formatTime(time, format) {
    return moment(time).format(format);
}

function formatLocalizedTime(time, format) {
    return moment(time).isValid() && new Intl.DateTimeFormat(getLanguagePreference(), format).format(time);
}

function parseTime(time, format) {
    return moment(time, format);
}

function forceLocalTimeZone(time) {
    return moment(moment.utc(time).format('YYYY-MM-DDTHH:mm:ss'));
}

function formatTimeOrDefaultWithTimeZone(time, format = 'HH:mm') {
    return time && formatTime(forceLocalTimeZone(time), format) || '';
}

function formatRelativeTime(time) {
    return moment(time).fromNow();
}

function differenceInMinutes(time) {
    const duration = moment.duration(moment(time).diff(Date.now()));

    return Math.abs(duration.asMinutes());
}

function getMinuteOrHourMessage(translations, time) {
    const difference = Math.round(differenceInMinutes(time));

    if (difference < 2) {
        return translations.ONE_MINUTE_AGO;
    } else if (difference < HOUR) {
        return translations.MINUTES_AGO_VALUE.replace('{value}', difference);
    } else if (difference < HOUR * 2) {
        return translations.ONE_HOUR_AGO;
    } else if (difference < DAY) {
        return translations.HOURS_AGO_VALUE.replace('{value}', Math.round(difference / HOUR));
    }

    return translations.AS_OF_DATE.replace('{date}', formatLocalizedTime(moment.utc(time), DATE_FORMAT_OPTIONS));
}

function dateFilterFrom(dateFilter) {
    switch (dateFilter.length) {
        case MONTH_LENGTH:
            return parseTime(dateFilter, DATE_FORMATS.month);

        case DAY_LENGTH:
            return parseTime(dateFilter, DATE_FORMATS.day);

        case HOUR_LENGTH:
            return parseTime(dateFilter, DATE_FORMATS.hour);

        default:
            return parseTime(dateFilter, DATE_FORMATS.year);
    }
}

function dataTableFormatTime(time, timeScale) {
    const momentTime = moment(time);

    switch (timeScale) {
        case 'daily':
        case 'day':
            return formatLocalizedTime(momentTime, DATE_FORMAT_OPTIONS);
        case 'day & time':
            return formatLocalizedTime(momentTime, DATE_TIME_FORMAT_OPTIONS);
        case 'monthly':
        case 'month':
            return formatLocalizedTime(momentTime, YEAR_FORMAT_OPTIONS);
        default:
            return formatLocalizedTime(momentTime, YEAR_NO_MONTH_OPTIONS);
    }
}

function getDateRange(date, startTime, endTime) {
    return {
        startDate: moment(date).startOf(startTime).toISOString(),
        endDate: moment(date).endOf(endTime).toISOString()
    };
}

function getLogsTotalTime(logs) {
    const totalTime = moment.duration(0);

    let lastType = null,
        lastTime = null;

    logs.forEach((log, index) => {
        const type = log.logType;
        const timeLog = log.timeLog;

        if (index === 0) {
            if (type === logStateConstants.start || type === logStateConstants.unpause) {
                const dateNow = Date.now() - timeLog;

                totalTime.add(dateNow, MILLISECONDS);
            }
        } else if (lastType === logStateConstants.pause || lastType === logStateConstants.stop) {
            const diffTime = lastTime - timeLog;

            totalTime.add(diffTime, MILLISECONDS);
        }

        lastType = type;
        lastTime = timeLog;
    });

    return totalTime;
}

function formatMinutesToHours(minutes) {
    return (minutes / HOUR)?.toFixed(2);
}

export {
    DATE_FORMATS,
    DISPLAY_FORMATS,
    TIME_UNIT_OPTIONS,
    DATE_FORMAT_OPTIONS,
    DATE_TIME_FORMAT_OPTIONS,
    formatTime,
    forceLocalTimeZone,
    dataTableFormatTime,
    differenceInMinutes,
    dateFilterFrom,
    formatRelativeTime,
    getDateRange,
    getMinuteOrHourMessage,
    parseTime,
    getLogsTotalTime,
    formatTimeOrDefaultWithTimeZone,
    MANUAL_DATA_TIME_FORMAT,
    MINUTES,
    formatLocalizedTime,
    formatMinutesToHours,
    WEEKDAY_AND_NUMERIC_DATE_OPTIONS
};
