// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconRemove} from '@deere/icons';

const ICON_REMOVE_STYLE = {
    style: {
        height: '28px',
        width: '34px'
    }
};

function Tile(props) {
    const closeButton = props.removable && (
        <button
            className='close'
            onClick={() => props.onRemove(props.id)}
        >
            <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
        </button>
    );

    return (
        <div className='editor-tile'>
            {props.title}
            {closeButton}
        </div>
    );
}

Tile.propTypes = {
    id: PropTypes.string,
    onRemove: PropTypes.func,
    removable: PropTypes.bool,
    title: PropTypes.string
};

export default Tile;
