// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {IconHandleChevronDown, IconHandleChevronUp} from '@deere/icons';
import JobAssignmentsForm from 'Ui/features/workboard-wizard/assignments/job-assignments-form';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {Button} from '@deere/isg.component-library';
import OperatorAssignmentsForm from 'Ui/features/workboard-wizard/assignments/operator-assignments-form';
import SwitchInput from 'Ui/components/common/form/switch-input';
import WorkboardHistoryWidgetWrapper from 'Ui/features/workboard-wizard/workboard-history/workboard-history-widget-wrapper';
import {getFleetEquipment} from 'Services/membership-service';
import {createIconFillStyle} from 'Utils/icon-utils';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {moveHandler} from 'Utils/scroll-utils';
import {isEmptyArray} from 'Common/utils/validation-utils';
import equipmentStatus from 'Common/constants/equipment-status';
import {WORKBOARD_JOB_MULTILINE, WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {JD_GREEN_THEME, ONLINK_BLUE} from 'Ui/constants/theme';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {sortBy} from 'lodash';

const ICON_COLLAPSE_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const ASSIGNMENTS_WIDTH = 6;

const NOTE_LINE_HEIGHT = 16;
const READONLY_NOTE_LINE_HEIGHT = 20.02;
const NOTE_LABEL_HEIGHT = 20.02;
const READONLY_NOTE_LABEL_MARGIN = 2;

const BASE_NOTE_HEIGHT = 19.6;
const READONLY_BASE_NOTE_HEIGHT = NOTE_LABEL_HEIGHT + READONLY_NOTE_LABEL_MARGIN;

const NUM_NOTE_LINES = 3;

function isEquipmentAvailable(equipment, fleetId) {
    return equipment.status !== equipmentStatus.archived && fleetId === equipment.fleetInUseId;
}

function setStatusForAllTiles(setCollapseStatus, status) {
    setCollapseStatus((prevCollapseStatus) => {
        const newStatusesForJobs = new Map();

        prevCollapseStatus.forEach((jobValue, jobId) => {
            const newStatusesForWorkItems = new Map();

            jobValue.forEach((workItemValue, workItemId) => {
                newStatusesForWorkItems.set(workItemId, status);
            });

            newStatusesForJobs.set(jobId, newStatusesForWorkItems);
        });

        return newStatusesForJobs;
    });
}

function checkIfAtLeastOneIsNotCollapsed(collapseStatus) {
    let size = 0;

    for (const [, sectionValue] of collapseStatus) {
        const collapsed = [...sectionValue.values()].includes(false);

        size += sectionValue.size;

        if (collapsed) {
            return collapsed;
        }
    }

    return size === 0;
}

function getFlattenedJobs(jobCategories, jobs) {
    const allSelectedJobs = jobCategories.reduce((allJobs, jobCategory) => {
        const jobsForCategoryWithIndex = jobs[jobCategory].map((job, jobIndex) => ({
            ...job,
            jobIndex
        }));

        return allJobs.concat(jobsForCategoryWithIndex);
    }, []);

    return sortBy(allSelectedJobs, 'seq');
}

function setByJobCollapseStatus(flattenedJobs, setCollapseStatus) {
    setCollapseStatus((prevCollapseStatus) => {
        const newCollapseStatusMap = new Map();

        flattenedJobs.forEach((job) => {
            const existingByJobMap = prevCollapseStatus.get(`${job.jobId || ''} ${job.jobTemplateId}`);
            const newWorkItemsMap = new Map();

            job?.workItems.forEach((workItem) => {
                const existingWorkItem = existingByJobMap?.get(workItem.workItemId);

                newWorkItemsMap.set(workItem.workItemId, Boolean(existingWorkItem));
            });

            newCollapseStatusMap.set(`${job.jobId || ''} ${job.jobTemplateId}`, newWorkItemsMap);
        });

        return newCollapseStatusMap;
    });
}

function setByOperatorCollapseStatus(operatorKeys, operators, setCollapseStatus) {
    setCollapseStatus((prevCollapseStatus) => {
        const newCollapseStatusMap = new Map();

        operatorKeys.forEach((operatorId) => {
            const existingByJobMap = prevCollapseStatus.get(operatorId);
            const newWorkItemsMap = new Map();

            operators[operatorId]?.forEach((workItem) => {
                const existingWorkItem = existingByJobMap?.get(workItem.workItemId);

                newWorkItemsMap.set(workItem.workItemId, Boolean(existingWorkItem));
            });

            newCollapseStatusMap.set(operatorId, newWorkItemsMap);
        });

        return newCollapseStatusMap;
    });
}

function getMemoizedData({
    collapseStatus,
    featureToggles,
    fleetEquipment,
    fleetId,
    isMigrated,
    setCollapseStatus,
    readOnly,
    templates,
    translations,
    values,
    workboardCreationFlow
}) {
    const isByJob = workboardCreationFlow === JOB;
    const atLeastOneNotCollapsed = useDeepMemo(() => checkIfAtLeastOneIsNotCollapsed(collapseStatus), [collapseStatus]);

    const equipmentByType = useDeepMemo(() => fleetEquipment
        .filter((individualEquipment) => isEquipmentAvailable(individualEquipment, fleetId))
        .reduce((equipmentMap, equipment) => {
            const {equipmentTypeId} = equipment;

            if (!equipmentMap.has(equipmentTypeId)) {
                equipmentMap.set(equipmentTypeId, []);
            }

            equipmentMap.get(equipmentTypeId).push(equipment);

            return equipmentMap;
        }, new Map()), [fleetEquipment]);

    const templatesById = useDeepMemo(() => templates.reduce((templateMap, template) => {
        templateMap.set(template.jobTemplateId, template);

        return templateMap;
    }, new Map()), [templates]);

    const {
        AssignmentForm,
        flattenedJobs,
        noDataMessage,
        selectedIds,
        workItemsByOperator
    } = useDeepMemo(() => {
        if (isByJob) {
            const jobCategories = Object.keys(values.jobs);
            const flattenedJobs = getFlattenedJobs(jobCategories, values.jobs);

            return {
                AssignmentForm: JobAssignmentsForm,
                flattenedJobs,
                noDataMessage: translations.ONLINK_NO_JOBS_SELECTED,
                selectedIds: jobCategories
            };
        }

        const operatorKeys = Object.keys(values.workItemsByOperator);

        return {
            AssignmentForm: OperatorAssignmentsForm,
            noDataMessage: translations.ONLINK_NO_OPERATORS_SELECTED,
            selectedIds: operatorKeys,
            workItemsByOperator: values.workItemsByOperator
        };
    }, [translations, JSON.stringify(values.jobs), values.workItemsByOperator, isByJob]);

    React.useEffect(() => {
        if (isByJob) {
            setByJobCollapseStatus(flattenedJobs, setCollapseStatus);
        } else {
            setByOperatorCollapseStatus(selectedIds, workItemsByOperator, setCollapseStatus);
        }
    }, [
        flattenedJobs,
        selectedIds,
        workItemsByOperator,
        setCollapseStatus,
        setByOperatorCollapseStatus,
        setByJobCollapseStatus
    ]);

    const collapseIcon = React.useMemo(() => {
        const themeColor = isMigrated ? JD_GREEN_THEME : ONLINK_BLUE;
        const primaryChevronStyle = createIconFillStyle(themeColor);

        return atLeastOneNotCollapsed ? (
            <IconHandleChevronUp
                iconHandleChevronUp={ICON_COLLAPSE_STYLE}
                primary={primaryChevronStyle}
            />
        ) : (
            <IconHandleChevronDown
                iconHandleChevronDown={ICON_COLLAPSE_STYLE}
                primary={primaryChevronStyle}
            />
        );
    }, [atLeastOneNotCollapsed, isMigrated]);

    const isMultiline = featureToggles[WORKBOARD_JOB_MULTILINE];
    const noteHeight = React.useMemo(() => {
        if (readOnly) {
            return isMultiline ?
                READONLY_BASE_NOTE_HEIGHT + NUM_NOTE_LINES * READONLY_NOTE_LINE_HEIGHT :
                READONLY_BASE_NOTE_HEIGHT + READONLY_NOTE_LINE_HEIGHT;
        }

        return isMultiline ?
            BASE_NOTE_HEIGHT + NUM_NOTE_LINES * NOTE_LINE_HEIGHT :
            BASE_NOTE_HEIGHT + NOTE_LINE_HEIGHT;
    }, [readOnly, isMultiline]);

    return {
        AssignmentForm,
        atLeastOneNotCollapsed,
        collapseIcon,
        equipmentByType,
        isMultiline,
        flattenedJobs,
        noDataMessage,
        noteHeight,
        selectedIds,
        templatesById
    };
}

function AssignmentsFormWrapper(props) {
    const {
        collapseStatus,
        featureToggles,
        fleetEquipment,
        fleetId,
        loading,
        membership,
        operators,
        readOnly,
        setCollapseStatus,
        setFleetEquipment,
        setLoading,
        setShowHistoryWidget,
        setValues,
        setWorkItemsByWorkboardMap,
        showHistoryWidget,
        templates,
        translations,
        values,
        workboardCreationFlow,
        workboardId,
        workboards,
        workItemsByWorkboardMap
    } = props;

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (isEmptyArray(fleetEquipment)) {
            setLoading((prevLoading) => ({
                ...prevLoading,
                fleetEquipment: true
            }));

            const fleetAndEquipment = await getFleetEquipment();

            if (isMounted()) {
                setFleetEquipment({
                    equipment: fleetAndEquipment.equipment,
                    fleetId: fleetAndEquipment.fleetId
                });
                setLoading((prevLoading) => ({
                    ...prevLoading,
                    fleetEquipment: false
                }));
            }
        }
    }), []);

    const {
        AssignmentForm,
        atLeastOneNotCollapsed,
        collapseIcon,
        equipmentByType,
        isMultiline,
        flattenedJobs,
        noDataMessage,
        noteHeight,
        selectedIds,
        templatesById
    } = getMemoizedData({
        collapseStatus,
        featureToggles,
        fleetEquipment,
        fleetId,
        isMigrated: membership.isMigrated,
        setCollapseStatus,
        readOnly,
        templates,
        translations,
        values,
        workboardCreationFlow
    });

    const newestWorkItemIdRef = React.useRef(null);
    const screenTouchTimerRef = React.useRef(null);
    const screenTouchListener = React.useCallback(
        (e) => moveHandler(e.touches[0], screenTouchTimerRef, 'job-grid-container'),
        [screenTouchTimerRef.current]
    );

    const hasData = selectedIds.length > 0;

    const assignmentInputs = (
        <>
            <Stack
                direction='row'
                justifyContent='space-between'
                paddingBottom={2}
            >
                {
                    featureToggles[WORKBOARD_REFACTOR] &&
                    <SwitchInput
                        checked={showHistoryWidget}
                        name='showWorkboardHistoryToggle'
                        offLabel={translations.ONLINK_SHOW_HISTORY}
                        onChange={() => setShowHistoryWidget(!showHistoryWidget)}
                        onLabel={translations.ONLINK_SHOW_HISTORY}
                        translations={translations}
                    />
                }
                <Button
                    disabled={!hasData}
                    onClick={() => setStatusForAllTiles(setCollapseStatus, atLeastOneNotCollapsed)}
                    startIcon={collapseIcon}
                    variant='tertiary'
                >
                    {atLeastOneNotCollapsed ? translations.COLLAPSE_ALL : translations.EXPAND_ALL}
                </Button>
            </Stack>
            <NoDataMessage
                hasData={hasData}
                noDataMessage={noDataMessage}
            >
                <AssignmentForm
                    {...props}
                    collapseStatus={collapseStatus}
                    equipmentByType={equipmentByType}
                    flattenedJobs={flattenedJobs}
                    isMultiline={isMultiline}
                    newestWorkItemIdRef={newestWorkItemIdRef}
                    noteHeight={noteHeight}
                    readOnly={readOnly}
                    screenTouchListener={screenTouchListener}
                    screenTouchTimerRef={screenTouchTimerRef}
                    selectedIds={selectedIds}
                    setCollapseStatus={setCollapseStatus}
                    setValues={setValues}
                    templates={templates}
                    templatesById={templatesById}
                    values={values}
                />
            </NoDataMessage>
        </>
    );

    return (
        <LoadingWrapper
            className='onlink-loading-icon'
            loading={loading.templates || loading.fleetEquipment || loading.operators}
            size='50px'
        >
            {
                showHistoryWidget ?
                    <Grid
                        alignItems='flex-start'
                        className='add-workboard-form assignments-form'
                        container={true}
                        direction='row'
                        id='job-grid-container'
                        justifyContent='space-evenly'
                        spacing={2}
                    >
                        <Grid
                            item={true}
                            md={featureToggles[WORKBOARD_REFACTOR] ? true : ASSIGNMENTS_WIDTH}
                            xs={true}
                        >
                            {assignmentInputs}
                        </Grid>
                        <Grid
                            className='history-widget-wrapper'
                            item={true}
                            xs={4}
                        >
                            <WorkboardHistoryWidgetWrapper
                                flattenedJobs={flattenedJobs}
                                operatorsMasterList={operators.operatorsMasterList}
                                readOnly={readOnly}
                                screenTouchListener={screenTouchListener}
                                screenTouchTimerRef={screenTouchTimerRef}
                                selectedIds={selectedIds}
                                setWorkItemsByWorkboardMap={setWorkItemsByWorkboardMap}
                                templates={templates}
                                templatesById={templatesById}
                                translations={translations}
                                values={values}
                                workItemsByWorkboardMap={workItemsByWorkboardMap}
                                workboardCreationFlow={workboardCreationFlow}
                                workboardId={workboardId}
                                workboards={workboards}
                            />
                        </Grid>
                    </Grid> :
                    <div
                        className='add-workboard-form-full-width assignments-form'
                    >
                        {assignmentInputs}
                    </div>
            }
        </LoadingWrapper>
    );
}

AssignmentsFormWrapper.propTypes = {
    collapseStatus: PropTypes.instanceOf(Map),
    featureToggles: PropTypes.featureToggles,
    fleetEquipment: PropTypes.arrayOf(PropTypes.equipment),
    fleetId: PropTypes.string,
    loading: PropTypes.object,
    membership: PropTypes.membership,
    operators: PropTypes.object,
    readOnly: PropTypes.bool,
    setCollapseStatus: PropTypes.func,
    setFleetEquipment: PropTypes.func,
    setLoading: PropTypes.func,
    setShowHistoryWidget: PropTypes.func,
    setValues: PropTypes.func,
    setWorkItemsByWorkboardMap: PropTypes.func,
    showHistoryWidget: PropTypes.bool,
    templates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    values: PropTypes.object,
    workboardCreationFlow: PropTypes.string,
    workboardId: PropTypes.string,
    workboards: PropTypes.arrayOf(PropTypes.object),
    workItemsByWorkboardMap: PropTypes.instanceOf(Map)
};

export default AssignmentsFormWrapper;
