// Unpublished Work © 2021-2024 Deere & Company.

function createLink(path, name, reactRouter = false, clearStorage = false) {
    return {
        path,
        basePath: path,
        type: '_self',
        name,
        reactRouter,
        clearStorage
    };
}

export {
    createLink
};
