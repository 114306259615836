// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {connect} from 'react-redux';
import {clsx} from 'clsx';

function Container(props) {
    const {
        children,
        featureToggles
    } = props;

    const classNames = clsx('onlink-tile onequip-tile', {
        'onequip-tile-new-nav': featureToggles[ONLINK_NAVIGATION_REDESIGN]
    });

    return (
        <>
            <div className={classNames}>
                {children}
            </div>
        </>
    );
}

Container.propTypes = {
    children: PropTypes.node,
    featureToggles: PropTypes.featureToggles
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(Container);
