// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {canAccessData} from 'Common/utils/jd-permission-handler';
import {connect} from 'react-redux';
import Link from '@mui/material/Link';
import {Typography} from '@mui/material';

const EditLink = React.forwardRef(
    function EditLink(props, ref) {
        const {
            className,
            children,
            isMigrated,
            href,
            myJdPermissions,
            onClick,
            rel,
            requiredMyJdPermissions = [],
            sx,
            target,
            id
        } = props;

        const canEdit = !isMigrated || canAccessData(requiredMyJdPermissions, myJdPermissions);

        return (
            canEdit ?
                <Link
                    className={className}
                    href={href}
                    id={id}
                    onClick={onClick}
                    ref={ref}
                    rel={rel}
                    sx={sx}
                    target={target}
                    underline={'hover'}
                >
                    {children}
                </Link> :
                <Typography>{children}</Typography>
        );
    }
);

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

EditLink.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string,
    id: PropTypes.string,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    onClick: PropTypes.func,
    rel: PropTypes.string,
    requiredMyJdPermissions: PropTypes.arrayOf(PropTypes.string),
    sx: PropTypes.object,
    target: PropTypes.string
};

export default connect(mapStateToProps)(EditLink);
