// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import {IconChevronDown, IconChevronUp} from '@deere/icons';
import DropdownMenu from 'Ui/components/main-navbar/persistent-header/common/dropdown-menu';
import NavItem from 'Ui/components/main-navbar/persistent-header/navigation/nav-item';
import {clsx} from 'clsx';
import {noop} from 'lodash';

const ICON_CHEVRON_STYLE = {
    style: {
        height: '12px',
        width: '12px'
    }
};

function DropdownNavItem(props) {
    const {
        children,
        dropdownMenuClassName,
        groupNavItemClassName,
        onClose,
        shouldRenderChevron,
        ...navItemProps
    } = props;

    const anchorRef = React.useRef();
    const [isOpen, setIsOpen] = React.useState(false);
    const closeDropdown = React.useCallback(() => {
        onClose();
        setIsOpen(false);
    }, [onClose]);
    const toggleDropdown = React.useCallback(() => {
        onClose();
        setIsOpen(!isOpen);
    }, [isOpen, onClose]);

    const chevronContainer = React.useMemo(() => shouldRenderChevron ? (
        <span className='chevron-container opener'>
            {
                isOpen ?
                    <IconChevronUp iconChevronUp={ICON_CHEVRON_STYLE}/> :
                    <IconChevronDown iconChevronDown={ICON_CHEVRON_STYLE}/>
            }
        </span>
    ) : null, [isOpen, shouldRenderChevron]);

    return (
        <Stack direction='row'>
            <NavItem
                {...navItemProps}
                className={clsx('dropdown', groupNavItemClassName, {
                    opened: isOpen
                })}
                onClick={toggleDropdown}
                ref={anchorRef}
                rightIcon={chevronContainer}
            />
            <DropdownMenu
                anchorRef={anchorRef}
                className={dropdownMenuClassName}
                closeDropdown={closeDropdown}
                isOpen={isOpen}
            >
                {children}
            </DropdownMenu>
        </Stack>
    );
}

DropdownNavItem.defaultProps = {
    onClose: noop,
    shouldRenderChevron: true
};

DropdownNavItem.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    dropdownMenuClassName: PropTypes.string,
    groupNavItemClassName: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.node,
    onClose: PropTypes.func,
    path: PropTypes.string,
    prioritizeList: PropTypes.arrayOf(PropTypes.string),
    reactRouter: PropTypes.bool,
    shouldRenderChevron: PropTypes.bool,
    title: PropTypes.string
};

export default DropdownNavItem;
