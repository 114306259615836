// Unpublished Work © 2022-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {ALL} from 'Ui/components/graph/constants/graph-filters';

async function getLifetimeEquipmentCostByArea(fleetId, equipmentAreaId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/equipment/reports/lifetime/area', {
        params: {
            fleetId,
            equipmentAreaId
        }
    });

    const {forFleet} = response.data?.equipmentTCO;

    return equipmentAreaId === ALL ? forFleet.byEachEquipmentArea.forever.data : forFleet.byEquipmentArea.forever.data;
}

async function getLifetimeEquipmentCostByModel(fleetId, equipmentModelId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/equipment/reports/lifetime/model', {
        params: {
            fleetId,
            equipmentModelId
        }
    });
    const {forFleet} = response.data?.equipmentTCO;

    return equipmentModelId === ALL ? forFleet.byEachEquipmentModel.forever.data : forFleet.byEquipmentModel.forever.data;
}

async function getLifetimeEquipmentCostByType(fleetId, equipmentTypeId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/equipment/reports/lifetime/type', {
        params: {
            fleetId,
            equipmentTypeId
        }
    });
    const {forFleet} = response.data?.equipmentTCO;

    return equipmentTypeId === ALL ? forFleet.byEachEquipmentType.forever.data : forFleet.byEquipmentType.forever.data;
}

export {
    getLifetimeEquipmentCostByArea,
    getLifetimeEquipmentCostByModel,
    getLifetimeEquipmentCostByType
};
