// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MyJohnDeereDataTable from 'Ui/components/settings/myJohnDeere/myJohnDeere-data-table';
import {getMyJDUserId, getMyJDUserMachines} from 'Services/my-jd-user-service';
import {fetchEffectData} from 'Utils/react-utils';

function getColumns(translations) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky'
        },
        {
            Header: translations.ADD_EQUIPMENT_PIN,
            accessor: 'pin',
            width: 170
        },
        {
            Header: translations.BRAND,
            accessor: 'brand'
        },
        {
            Header: translations.MODEL,
            accessor: 'model'
        },
        {
            Header: translations.TYPE,
            accessor: 'type'
        },
        {
            Header: translations.organization,
            accessor: 'organization',
            width: 145
        }
    ];
}

function MyJohnDeereEquipmentSettings(props) {
    const {
        hasMyJDSession,
        translations
    } = props;

    const [equipment, setEquipment] = React.useState([]);
    const [hasMyJdUser, setHasMyJdUser] = React.useState(true);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const dataCalls = [getMyJDUserId()];

        if (hasMyJDSession) {
            dataCalls.push(getMyJDUserMachines());
        }

        const [myJDUser, machines = []] = await Promise.all(dataCalls);

        if (isMounted()) {
            const formatted = machines.map((machine) => ({
                brand: machine.make?.name || '',
                model: machine.model?.name || '',
                name: machine.name,
                organization: machine.orgName,
                pin: machine.serialNumber || '',
                type: machine.type?.name || ''
            }));

            setEquipment(formatted);
            setHasMyJdUser(Boolean(myJDUser));
            setLoading(false);
        }
    }), []);

    return (
        <MyJohnDeereDataTable
            columns={getColumns(translations)}
            defaultSorted={[{
                desc: false,
                id: 'name'
            }]}
            hasMyJDSession={hasMyJDSession}
            hasMyJDUser={hasMyJdUser}
            loading={loading}
            rows={equipment}
            searchable={true}
            translations={translations}
        />
    );
}

MyJohnDeereEquipmentSettings.propTypes = {
    hasMyJDSession: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        hasMyJDSession: state.account.hasMyJDSession,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(MyJohnDeereEquipmentSettings);
