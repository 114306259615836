// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {getPartsFromInventoryBins} from 'Services/inventory-service';
import {getInventories} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import PartSelector from 'Ui/components/common/parts-selectors/part-selector';
import SelectedPartQuantity from 'Ui/components/common/parts-selectors/selected-part-quantity';
import {formatParts} from 'Utils/multiselect/service-multiselect-mappers';

function ServiceFormParts(props) {
    const {
        disabled,
        membershipId,
        readOnly,
        selectedParts,
        setSelectedParts,
        setValid,
        translations
    } = props;

    const [parts, setParts] = React.useState([]);
    const [inventories, setInventories] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const {inventories} = await getInventories();
        const availableParts = await getPartsFromInventoryBins(inventories);

        const formattedParts = formatParts(availableParts, true, 1);

        if (isMounted()) {
            setParts(formattedParts);
            setInventories(inventories);
        }
    }), [membershipId]);

    return (
        <PartSelector
            additionalSubTitleSelector='name'
            availableParts={parts}
            disabled={disabled}
            inventories={inventories}
            newPartQuantity={1}
            partsInputComponent={SelectedPartQuantity}
            readOnly={readOnly}
            selectedParts={selectedParts}
            setSelectedParts={(value) => setSelectedParts({
                target: {
                    name: 'partsList',
                    value
                }
            })}
            setValid={setValid}
            showAddNewPartOption={true}
            subTitleSelector='manufacturerName'
            titleSelector='partNumber'
            translations={translations}
        />
    );
}

ServiceFormParts.propTypes = {
    disabled: PropTypes.bool,
    membershipId: PropTypes.string,
    readOnly: PropTypes.bool,
    selectedParts: PropTypes.arrayOf(PropTypes.object),
    setSelectedParts: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId
    };
}

export default connect(mapStateToProps)(ServiceFormParts);
