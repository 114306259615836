// Unpublished Work © 2021-2024 Deere & Company.

import {FULFILLED, REJECTED} from 'Common/constants/response-status';

function filterFailedResponse(response) {
    return response.status === REJECTED && Boolean(response.reason);
}

function filterSuccessfulResponse(response) {
    return response.status === FULFILLED && Boolean(response.value);
}

function reduceResponseData(allData, response) {
    const data = response.value || response.reason;

    if (data) {
        return allData.concat(data);
    }

    return allData;
}

export {
    filterFailedResponse,
    filterSuccessfulResponse,
    reduceResponseData
};
