// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import PanelData from 'Ui/components/panel/panel-data';
import {getValueWithThreshold} from 'Utils/threshold-utils';
import {dateFilterFrom, forceLocalTimeZone, formatTime, getMinuteOrHourMessage} from 'Utils/time-utils';
import {convertToUoM, formatUoMValue, getFormattedCurrency, getUoMConfig} from 'Utils/unit-conversion-utils';
import {DOLLARS, HOURS, INCHES} from 'Common/constants/data-unit-constants';
import {
    EC,
    FIRMNESS,
    FIRMNESS_F355_EQUIVALENTS,
    FIRMNESS_GRAVITIES,
    FUEL_COST,
    GAS_COST,
    GREEN_SPEED
} from 'Common/constants/data-group-constants';
function getFormattedTime(time, timeScale, translations) {
    switch (timeScale) {
        case 'year':
            return formatTime(forceLocalTimeZone(time), 'YYYY');

        case 'month':
            return translations[`months_${formatTime(forceLocalTimeZone(time), 'MMMM').toString()}`];

        default:
            return getMinuteOrHourMessage(translations, time);
    }
}

function formatTileDecimal(conversionConfig, dataUnitConfig) {
    const {
        dataUnit,
        dataType
    } = conversionConfig;

    if ([GREEN_SPEED, FIRMNESS, FIRMNESS_GRAVITIES, FIRMNESS_F355_EQUIVALENTS, EC].includes(dataType)) {
        return dataUnitConfig.decimalPlaces;
    }

    if (dataUnit === INCHES || dataUnit === HOURS) {
        return 1;
    }

    return 0;
}

function formatNonDollarValue(convertedValue, conversionConfig) {
    const {formatting} = getUoMConfig(conversionConfig);
    const hasDataUnitsArray = Array.isArray(formatting.dataUnits);

    const smallSuffixValue = hasDataUnitsArray ? formatting.dataUnits[0].smallSuffix : formatting.smallSuffix;
    const suffix = hasDataUnitsArray ? formatting.dataUnits[0].suffix : formatting.suffix;

    const smallSuffix = smallSuffixValue ? suffix : '';

    const dataUnits = hasDataUnitsArray && formatting.dataUnits.map((dataUnit) => ({
        ...dataUnit,
        decimalPlaces: 0,
        suffix: dataUnit.smallSuffix ? '' : dataUnit.suffix
    }));

    return {
        formattedValue: formatUoMValue(convertedValue, {
            formatting: {
                ...formatting,
                dataUnits: dataUnits || [{
                    ...formatting,
                    decimalPlaces: formatTileDecimal(conversionConfig, formatting),
                    suffix: formatting.smallSuffix ? '' : formatting.suffix
                }]
            },
            translations: conversionConfig.translations
        }),
        smallSuffix
    };
}

function getFormattedValue(value, conversionConfig) {
    const convertedValue = convertToUoM(value, conversionConfig);

    if (conversionConfig.dataUnit !== DOLLARS) {
        return formatNonDollarValue(convertedValue, conversionConfig);
    }

    if (![FUEL_COST, GAS_COST].includes(conversionConfig.dataType)) {
        conversionConfig.maximumFractionDigits = 0;
    }

    return {
        formattedValue: getFormattedCurrency(convertedValue, conversionConfig),
        smallSuffix: ''
    };
}

function templateContext({
    currencyPreference,
    featureToggles,
    rollupData,
    translations,
    unitOfMeasure,
    unitOfMeasureOverrides
}) {
    const {
        config,
        dataUnits: dataUnit,
        dataType,
        lastUpdate,
        timeScale,
        timeSelect,
        [rollupData.useField]: value
    } = rollupData;

    const time = lastUpdate || dateFilterFrom(timeSelect).toDate();
    const formattedTime = getFormattedTime(time, timeScale, translations);

    const conversionConfig = {
        currencyPreference,
        dataType,
        dataUnit,
        featureToggles,
        translations,
        unitOfMeasure,
        unitOfMeasureOverrides
    };

    const {
        formattedValue, smallSuffix
    } = getFormattedValue(value, conversionConfig);

    return {
        time: formattedTime,
        label: config.dataLabel,
        smallSuffix,
        value: getValueWithThreshold(config, value, formattedValue)
    };
}

function DataTypeView(props) {
    const {
        featureToggles,
        rollup,
        translations,
        unitOfMeasure,
        currencyPreference,
        unitOfMeasureOverrides
    } = props;

    const {
        label,
        smallSuffix,
        time,
        value
    } = templateContext({
        currencyPreference,
        featureToggles,
        rollupData: rollup,
        translations,
        unitOfMeasure,
        unitOfMeasureOverrides
    });

    return (
        <PanelData
            date={time}
            label={label}
            smallSuffix={smallSuffix}
            translations={translations}
            value={value}
        />
    );
}

DataTypeView.propTypes = {
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    rollup: PropTypes.shape({
        timeSelect: PropTypes.string,
        dataUnits: PropTypes.string,
        timeScale: PropTypes.string
    }),
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.arrayOf(PropTypes.object)
};

export default DataTypeView;
