// Unpublished Work © 2020-2024 Deere & Company.
import {createAxiosClient} from 'Common/utils/axios-client';
import {CONFLICT, UNAUTHORIZED} from 'Common/constants/errors';
import store from 'Store/store';
import {addToast} from 'Store/actions/toasts';
import {TOAST_TYPE} from '@deere/toast';

function toastIfForbidden() {
    const translations = store.getState().translations;

    store.dispatch(addToast({
        message: translations.ONLINK_WRONG_ORG_ERROR,
        type: TOAST_TYPE.ERROR,
        persist: true
    }));
}

function redirectIfUnauthorized(route) {
    if (typeof route === 'string') {
        window.location.replace(route);
    }
}

function interceptErrorResponse(error) {
    const response = error.response;

    switch (response?.status) {
        case UNAUTHORIZED:
            redirectIfUnauthorized(response.data);
            break;
        case CONFLICT:
            toastIfForbidden();
            break;
        default:
            break;
    }
    throw error;
}

function addInterceptor(axiosClient) {
    axiosClient.interceptors.response.use(null, interceptErrorResponse);
}

function createUiAxiosClient(timeout, headers) {
    return createAxiosClient('ui', {
        timeout,
        headers
    }, addInterceptor);
}

export {
    createUiAxiosClient,
    interceptErrorResponse
};
