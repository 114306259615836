// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import ValidationDate from 'Ui/components/common/form/validation-date';
import {DateRangePicker} from '@deere/form-controls';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import PropTypes from 'Utils/prop-type-utils';

function CustomDateRange(props) {
    const {
        translations,
        setValid,
        invalidInputs,
        setDateRange
    } = props;

    const [customDateRange, setCustomDateRange] = React.useState({
        from: null,
        to: null
    });

    return (
        <>
            <ValidationDate
                className={'date-range-input'}
                component={DateRangePicker}
                dateFormat='L'
                large
                locale={getLanguagePreference()}
                name='fromDate'
                onChange={setCustomDateRange}
                range
                required
                selected={customDateRange}
                setValid={setValid}
            />
            <OnlinkButton
                className='refresh-date-range primary'
                disabled={invalidInputs.size > 0}
                onClick={() => setDateRange({
                    startDate: customDateRange.from,
                    endDate: customDateRange.to
                })}
            >
                {translations.REFRESH_BUTTON}
            </OnlinkButton>
        </>
    );
}

CustomDateRange.propTypes = {
    invalidInputs: PropTypes.instanceOf(Set),
    setDateRange: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default FormValidator(CustomDateRange);
