// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Switch as OutdatedSwitch} from '@deere/ui-switch';
import {Switch, FormControlLabel} from '@deere/isg.component-library';
import {joinClassNames} from 'Utils/html-utils';
import {connect} from 'react-redux';

function getSwitchLabel(checked, onLabel, offLabel) {
    if (onLabel && !offLabel) {
        return onLabel;
    }

    if (!onLabel && offLabel) {
        return offLabel;
    }

    return checked ? onLabel : offLabel;
}

function SwitchInput(props) {
    const {
        checked,
        className,
        name,
        offLabel,
        onChange,
        onLabel,
        translations,
        disabled,
        isMigrated
    } = props;

    if (isMigrated) {
        return (
            <FormControlLabel
                className={joinClassNames('switch-label', className)}
                control={<Switch
                    checked={checked}
                    className='switch-input'
                    disabled={disabled}
                    onChange={() => onChange(name, !checked)}
                    translations={translations}
                />}
                label={getSwitchLabel(checked, onLabel, offLabel)}
            />
        );
    }

    return (
        <div className={joinClassNames('switch-input', className)}>
            <OutdatedSwitch
                checked={checked}
                disabled={disabled}
                onChange={() => onChange(name, !checked)}
                translations={translations}
            />
            <label className='switch-label'>
                {getSwitchLabel(checked, onLabel, offLabel)}
            </label>
        </div>
    );
}

SwitchInput.defaultProps = {
    checked: false,
    disabled: false
};

SwitchInput.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isMigrated: PropTypes.bool,
    name: PropTypes.string.isRequired,
    offLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onLabel: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership?.isMigrated
    };
}

export default connect(mapStateToProps)(SwitchInput);
