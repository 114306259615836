// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {connect} from 'react-redux';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {fetchEffectData} from 'Utils/react-utils';
import {
    getPartsFromInventoryBins,
    getPurchaseOrder
} from 'Services/inventory-service';
import PartSelector from 'Ui/components/common/parts-selectors/part-selector';
import ValidationDate from 'Ui/components/common/form/validation-date';
import {useSave} from 'Ui/react-hooks/use-save';
import React from 'react';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {Input, TextArea} from '@deere/form-controls';
import {replaceTranslationNames} from 'Utils/translation-utils';
import moment from 'moment';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import {
    formatAvailablePartsData,
    formatDistributors,
    getPurchaseOrderStatusItem,
    getSelectedParts,
    STATUS
} from 'Utils/parts-utils';
import {getMembershipHierarchyDealerships} from 'Services/membership-service';
import AddEditPurchaseOrderDialogActions
    from 'Ui/features/dialogs/add-edit-purchase-order-dialog/add-edit-purchase-order-dialog-actions';
import {BasicDialog} from '@deere/basic-dialog';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';
import {
    getStatusToSend,
    savePurchaseOrder
} from 'Ui/features/dialogs/add-edit-purchase-order-dialog/purchase-order-utils';
import SelectedPartQuantityWithCostWithSubRows
    from 'Ui/components/common/parts-selectors/selected-part-quantity-with-cost-with-subrows';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {
    VIEW_FINANCIAL_DATA,
    VIEW_EQUIPMENT_LIST_API,
    MODIFY_FINANCIAL_DATA
} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

const MAX_QUANTITY = 2000000;

function initializeState(purchaseOrder, inventories) {
    const [availableDistributors, setAvailableDistributors] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedParts, setSelectedParts] = React.useState([]);
    const [availableParts, setAvailableParts] = React.useState([]);
    const [partRequestsToRemove, setPartRequestsToRemove] = React.useState(new Set());
    const [values, setValues] = React.useState({
        dateSelect: purchaseOrder?.dateSelect ? moment(purchaseOrder?.dateSelect) : moment(),
        distributorId: purchaseOrder?.distributorId || null,
        inventoryId: purchaseOrder?.inventoryId || inventories[0].inventoryId,
        invoiceNumber: purchaseOrder?.invoiceNumber || '',
        notes: purchaseOrder?.notes || '',
        otherCosts: purchaseOrder?.otherCosts || {
            tax: '',
            shipping: '',
            handling: ''
        },
        purchaseOrderId: purchaseOrder?.purchaseOrderId || null,
        referenceNumber: purchaseOrder?.referenceNumber || null,
        startingStatus: purchaseOrder?.status || null,
        status: purchaseOrder?.status || STATUS.PENDING,
        distributorEmail: purchaseOrder?.distributor?.email || ''
    });

    return {
        availableDistributors,
        setAvailableDistributors,
        loading,
        setLoading,
        selectedParts,
        setSelectedParts,
        availableParts,
        setAvailableParts,
        values,
        setValues,
        partRequestsToRemove,
        setPartRequestsToRemove
    };
}

function AddEditPurchaseOrderDialog(props) {
    const {
        closeDialog,
        inventories,
        translations,
        setValid,
        invalidInputs,
        onClose,
        featureToggles,
        membership,
        purchaseOrder,
        isMigrated,
        myJdPermissions
    } = props;

    const {
        availableDistributors,
        setAvailableDistributors,
        loading,
        setLoading,
        selectedParts,
        setSelectedParts,
        availableParts,
        setAvailableParts,
        values,
        setValues,
        partRequestsToRemove,
        setPartRequestsToRemove
    } = initializeState(purchaseOrder, inventories);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const [{dealerships}, availablePartsData, {purchaseOrder: purchaseOrderCall}] = await Promise.all([
            getMembershipHierarchyDealerships(membership.membershipId),
            getPartsFromInventoryBins(inventories),
            purchaseOrder ? getPurchaseOrder(purchaseOrder.purchaseOrderId) : Promise.resolve({})
        ]);

        if (isMounted()) {
            setSelectedParts(getSelectedParts(purchaseOrderCall));
            setAvailableDistributors(formatDistributors(dealerships, purchaseOrderCall));
            setAvailableParts(formatAvailablePartsData(availablePartsData));
            setLoading(false);
        }
    }), []);

    const [saveFunc, disableSave, isSaving] = useSave(() => savePurchaseOrder(
        {
            ...values,
            status: getStatusToSend(values)
        }, selectedParts,
        purchaseOrder,
        onClose,
        closeDialog,
        values.status === STATUS.COMPLETED,
        partRequestsToRemove
    ), {
        disabled: loading,
        invalidInputs
    });

    function onChange(event) {
        const {
            name,
            value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onChangeOtherCost(event) {
        const {
            name,
            value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            otherCosts: {
                ...prevValues.otherCosts,
                [name]: value
            }
        }));
    }

    const {
        canEdit,
        purchaseOrderStatusItem,
        purchaseOrderTitle,
        valueGreaterThanOrEqual,
        valueLessThanOrEqual
    } = React.useMemo(() => {
        const valueGreaterThanOrEqual = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
            '0': 0
        });

        const valueLessThanOrEqual = `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_QUANTITY}`;

        const canEdit = !purchaseOrder || purchaseOrder.status === STATUS.PENDING;
        const purchaseOrderTitle = purchaseOrder ? `${translations.ONLINK_EDIT_PURCHASE_ORDER} ${purchaseOrder.referenceNumber}` : translations.ONLINK_ADD_PURCHASE_ORDER;

        const purchaseOrderStatusItem = getPurchaseOrderStatusItem(translations, purchaseOrder?.status);

        return {
            canEdit,
            purchaseOrderStatusItem,
            purchaseOrderTitle,
            valueGreaterThanOrEqual,
            valueLessThanOrEqual
        };
    }, [purchaseOrder, translations]);

    const userAuth = {
        isMigrated,
        myJdPermissions
    };

    const myJdPermissionMap = React.useMemo(() => {
        return {
            hasViewFinancialDataPermissions: isAuthorized({
                myJdPermissions: VIEW_FINANCIAL_DATA
            }, userAuth),
            hasEditFinancialDataPermissions: isAuthorized({
                myJdPermissions: MODIFY_FINANCIAL_DATA
            }, userAuth),
            hasViewDataPermissions: isAuthorized({
                myJdPermissions: VIEW_EQUIPMENT_LIST_API
            }, userAuth)
        };
    }, [isMigrated, myJdPermissions]);

    const readOnly = myJdPermissionMap.hasViewDataPermissions && !myJdPermissionMap.hasEditFinancialDataPermissions;

    return (
        <BasicDialog
            className='orders-dialog form-dialog'
            closeHandler={closeDialog}
            show={true}
            title={readOnly ? `${translations.ONLINK_PURCHASE_ORDER} ${purchaseOrder.referenceNumber}` : purchaseOrderTitle}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading || isSaving}
                size='50px'
            >
                <div className='orders-dialog-form'>
                    <div className='settings-group mobile'>
                        <ReadOnlyWrapperComponent
                            label={translations.ONLINK_INVOICE_NUMBER}
                            readOnly={readOnly}
                            readOnlyProps={{
                                readOnlyValueClassName: 'purchase-order-value'
                            }}
                            value={values.invoiceNumber}
                            wrappedComponent={Input}
                            wrappedProps={{
                                disabled: !canEdit,
                                name: 'invoiceNumber',
                                onChange,
                                tabIndex: 0
                            }}
                        />
                        <ReadOnlyWrapperComponent
                            label={translations.DATE}
                            readOnly={readOnly}
                            readOnlyProps={{
                                value: moment(values.dateSelect).format('L')
                            }}
                            wrappedComponent={ValidationDate}
                            wrappedProps={{
                                dateFormat: 'L',
                                large: true,
                                locale: getLanguagePreference(),
                                name: 'dateSelect',
                                onChange: (date) => {
                                    if (date) {
                                        onChange({
                                            target: {
                                                name: 'dateSelect',
                                                value: date
                                            }
                                        });
                                    }
                                },
                                readOnly: !canEdit,
                                required: true,
                                selected: values.dateSelect,
                                setValid,
                                tabIndex: 0
                            }}
                        />
                    </div>
                    <div className='settings-group mobile'>
                        <ReadOnlyWrapperComponent
                            label={translations.DEALER}
                            readOnly={readOnly}
                            readOnlyProps={{
                                readOnlyValueClassName: 'purchase-order-value',
                                value: availableDistributors.find(({id}) => id === values.distributorId)?.title
                            }}
                            wrappedComponent={ValidationMultiSelect}
                            wrappedProps={{
                                component: SortedMultiSelect,
                                defaultSelectLabel: translations.ONLINK_SELECT_DEALER,
                                disabled: Boolean(purchaseOrder),
                                error: translations.REQUIRED_FIELD_TEXT,
                                items: availableDistributors,
                                multiple: false,
                                name: 'distributorId',
                                onChange: ([selectedId]) => {
                                    onChange({
                                        target: {
                                            name: 'distributorId',
                                            value: selectedId
                                        }
                                    });
                                },
                                required: true,
                                selectedIds: [values.distributorId],
                                setValid,
                                tabIndex: 0
                            }}
                        />
                        <ReadOnlyWrapperComponent
                            label={translations.STATUS}
                            readOnly={readOnly}
                            readOnlyProps={{
                                value: values.status
                            }}
                            wrappedComponent={ValidationMultiSelect}
                            wrappedProps={{
                                component: SortedMultiSelect,
                                disabled: purchaseOrder?.status === STATUS.COMPLETED,
                                items: purchaseOrderStatusItem,
                                multiple: false,
                                name: 'status',
                                onChange: ([selectedId]) => {
                                    if (selectedId) {
                                        onChange({
                                            target: {
                                                name: 'status',
                                                value: selectedId
                                            }
                                        });
                                    }
                                },
                                selectedIds: [values.status],
                                setValid,
                                tabIndex: 0
                            }}
                        />
                    </div>
                    <PartSelector
                        additionalSubTitleSelector='partNumber'
                        availableParts={availableParts}
                        currencyPreference={membership.currencyPreference}
                        disabled={!canEdit}
                        featureToggles={featureToggles}
                        hideFinancialData={!myJdPermissionMap.hasViewFinancialDataPermissions}
                        inventories={inventories}
                        partsInputComponent={SelectedPartQuantityWithCostWithSubRows}
                        readOnly={readOnly}
                        selectedParts={selectedParts}
                        setAvailableParts={setAvailableParts}
                        setPartRequestsToRemove={setPartRequestsToRemove}
                        setSelectedParts={setSelectedParts}
                        setValid={setValid}
                        showAddNewPartOption={true}
                        subTitleSelector='manufacturerName'
                        titleSelector='name'
                        translations={translations}
                    />
                    {
                        myJdPermissionMap.hasViewFinancialDataPermissions &&
                        <>
                            <div className='settings-group mobile'>
                                <ReadOnlyWrapperComponent
                                    label={translations.ONLINK_EST_TAX}
                                    readOnly={readOnly}
                                    readOnlyProps={{
                                        readOnlyValueClassName: 'purchase-order-value'
                                    }}
                                    value={values.otherCosts.tax}
                                    wrappedComponent={ValidationInput}
                                    wrappedProps={{
                                        disabled: !canEdit,
                                        errors: {
                                            rangeUnderflow: valueGreaterThanOrEqual,
                                            rangeOverflow: valueLessThanOrEqual
                                        },
                                        max: MAX_QUANTITY,
                                        min: 0,
                                        name: 'tax',
                                        onChange: onChangeOtherCost,
                                        setValid,
                                        tabIndex: 0,
                                        type: 'number'
                                    }}
                                />
                            </div>
                            <div className='settings-group mobile'>
                                <ReadOnlyWrapperComponent
                                    label={translations.ONLINK_EST_SHIPPING}
                                    readOnly={readOnly}
                                    readOnlyProps={{
                                        readOnlyValueClassName: 'purchase-order-value'
                                    }}
                                    value={values.otherCosts.shipping}
                                    wrappedComponent={ValidationInput}
                                    wrappedProps={{
                                        disabled: !canEdit,
                                        errors: {
                                            rangeUnderflow: valueGreaterThanOrEqual,
                                            rangeOverflow: valueLessThanOrEqual
                                        },
                                        max: MAX_QUANTITY,
                                        min: 0,
                                        name: 'shipping',
                                        onChange: onChangeOtherCost,
                                        setValid,
                                        tabIndex: 0,
                                        type: 'number'
                                    }}
                                />
                                <ReadOnlyWrapperComponent
                                    label={translations.ONLINK_EST_HANDLING}
                                    readOnly={readOnly}
                                    readOnlyProps={{
                                        readOnlyValueClassName: 'purchase-order-value'
                                    }}
                                    value={values.otherCosts.handling}
                                    wrappedComponent={ValidationInput}
                                    wrappedProps={{
                                        disabled: !canEdit,
                                        errors: {
                                            rangeUnderflow: valueGreaterThanOrEqual,
                                            rangeOverflow: valueLessThanOrEqual
                                        },
                                        max: MAX_QUANTITY,
                                        min: 0,
                                        name: 'handling',
                                        onChange: onChangeOtherCost,
                                        setValid,
                                        tabIndex: 0,
                                        type: 'number'
                                    }}
                                />
                            </div>
                        </>
                    }
                    <div className='settings-group mobile'>
                        <ReadOnlyWrapperComponent
                            label={translations.NOTES}
                            readOnly={readOnly}
                            value={values.notes}
                            wrappedComponent={TextArea}
                            wrappedProps={{
                                disabled: purchaseOrder?.status === STATUS.COMPLETED,
                                name: 'notes',
                                onChange,
                                rows: 6,
                                tabIndex: 0
                            }}
                        />
                    </div>
                </div>
            </LoadingWrapper>
            <footer className='dialog-footer'>
                <AddEditPurchaseOrderDialogActions
                    closeDialog={closeDialog}
                    disableSave={disableSave}
                    hasEditFinancialDataPermissions={myJdPermissionMap.hasEditFinancialDataPermissions}
                    isEdit={Boolean(purchaseOrder)}
                    partRequestsToRemove={partRequestsToRemove}
                    purchaseOrder={purchaseOrder}
                    receiveAndCompleteFunc={() => savePurchaseOrder(
                        {
                            ...values,
                            status: STATUS.RECEIVED
                        },
                        selectedParts,
                        purchaseOrder,
                        onClose,
                        closeDialog,
                        true,
                        partRequestsToRemove
                    )}
                    receiveOrderOnClose={() => {
                        onClose();
                        closeDialog();
                    }}
                    saveFunc={saveFunc}
                    selectedParts={selectedParts}
                    translations={translations}
                    values={values}
                />
            </footer>
        </BasicDialog>
    );
}

AddEditPurchaseOrderDialog.propTypes = {
    addToast: PropTypes.func,
    closeDialog: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    inventories: PropTypes.arrayOf(PropTypes.object),
    isMigrated: PropTypes.bool,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    onClose: PropTypes.func,
    partRequestsToRemove: PropTypes.instanceOf(Set),
    purchaseOrder: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeDialog() {
            dispatch(closeDialog(dialogTypes.ADD_EDIT_PURCHASE_ORDER_DIALOG));
        }
    };
}

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(AddEditPurchaseOrderDialog));
