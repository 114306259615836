// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import CheckEquipmentDialogForm from 'OnEquip/equipment/common/check-equipment-dialog-form';
import {useSave} from 'Ui/react-hooks/use-save';
import {asNumber} from 'Utils/conversion-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {isEmptyArray, isNullOrUndefined} from 'Common/utils/validation-utils';
import {updateEquipment} from 'Services/equipment-service';
import {getEquipmentAreas} from 'Services/membership-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import {updateEquipmentAreas as updateReduxEquipmentAreas} from 'Store/actions/equipment';
import dialogTypes from 'Ui/components/common/dialog-types';

function getEquipmentData(equipment) {
    const {
        equipmentArea,
        equipmentAreaId,
        ...equipmentData
    } = equipment;

    return equipmentData;
}

function CheckEquipmentDialog(props) {
    const {
        closeDialog,
        equipment,
        equipmentAreas,
        invalidInputs,
        setValid,
        translations,
        updateEquipmentAreas,
        updateFleetEquipment
    } = props;

    const [equipmentAreaId, setEquipmentAreaId] = React.useState(equipment.equipmentAreaId);
    const [note, setNote] = React.useState('');
    const [status, setStatus] = React.useState(equipment.status);
    const [totalHours, setTotalHours] = React.useState(null);

    React.useEffect(() => fetchEffectData(async () => {
        if (isEmptyArray(equipmentAreas)) {
            const {equipmentAreas: equipmentAreasData} = await getEquipmentAreas(translations);

            updateEquipmentAreas(equipmentAreasData);
        }
    }), []);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const equipmentData = {
            ...getEquipmentData(equipment),
            clearArea: !equipmentAreaId,
            equipmentAreaId,
            status
        };

        if (note) {
            equipmentData.note = note;
        }

        const parsedTotalHours = asNumber(totalHours);

        if (!isNullOrUndefined(parsedTotalHours)) {
            equipmentData.totalHours = parsedTotalHours;
        }

        await updateEquipment(equipmentData, equipment.equipmentId);

        updateFleetEquipment();
        closeDialog();
    }, {
        invalidInputs
    });

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={equipment.equipmentName}
            translations={translations}
        >
            <CheckEquipmentDialogForm
                equipment={equipment}
                equipmentAreaId={equipmentAreaId}
                equipmentAreas={equipmentAreas}
                note={note}
                saveFunc={saveFunc}
                setEquipmentAreaId={setEquipmentAreaId}
                setNote={setNote}
                setStatus={setStatus}
                setTotalHours={setTotalHours}
                setValid={setValid}
                status={status}
                totalHours={totalHours}
                translations={translations}
            />
        </FormDialog>
    );
}

CheckEquipmentDialog.propTypes = {
    closeDialog: PropTypes.func,
    equipment: PropTypes.equipment,
    equipmentAreas: PropTypes.arrayOf(PropTypes.object),
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateEquipmentAreas: PropTypes.func,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        equipmentAreas: state.equipment.equipmentAreas,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.CHECK_EQUIPMENT_DIALOG));
        },
        updateEquipmentAreas(value) {
            dispatch(updateReduxEquipmentAreas(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(CheckEquipmentDialog));
