// Unpublished Work © 2021-2024 Deere & Company.

export const COMPLETED = 'completed';
export const DECLINED = 'declined';
export const IN_PROCESS = 'in_process';
export const PENDING = 'pending';
export const RECOMMENDED = 'recommended';
export const SCHEDULED = 'scheduled';
export const SKIPPED = 'skipped';
export const WORK_ITEM = 'work_item';
