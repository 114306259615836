// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconDownload} from '@deere/icons';
import AdditionalActionButton from 'OnEquip/equipment/common/additional-action-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import {COURSE} from 'Common/constants/membership-type';
import {MINUTES_PER_HOUR} from 'Ui/constants/time-constants';
import {
    BY_JOB_CATEGORY,
    BY_JOB_TEMPLATE,
    BY_TIME,
    BY_USER,
    JOB_CATEGORIES_BY_TIME,
    JOB_TEMPLATES_BY_TIME,
    USERS_BY_TIME
} from 'OnLabor/report/constants/labor-report';
import {exportLaborWorkboardsReport} from 'Services/excel-service';
import {formatNumber, getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {alphaNumericCompare, dateCompare} from 'Ui/models/maintenance';
import {groupBy, sortBy, sum} from 'lodash';
import {dataTableFormatTime} from 'Utils/time-utils';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {
    VIEW_FINANCIAL_DATA,
    VIEW_WORKBOARD_SUMMARY_DATA
} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {connect} from 'react-redux';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function getNameForHeader(secondarySelector, translations) {
    switch (secondarySelector) {
        case BY_JOB_CATEGORY:
        case JOB_CATEGORIES_BY_TIME:
            return translations.ONLINK_JOB_CATEGORY;
        case BY_JOB_TEMPLATE:
        case JOB_TEMPLATES_BY_TIME:
            return translations.jobs_job;
        case BY_USER:
        case USERS_BY_TIME:
            return translations.ONLINK_EMPLOYEE;
        default:
            return secondarySelector;
    }
}

function createColumnOrDefault(shouldCreate, column) {
    return shouldCreate ? [column] : [];
}

function getDateSelect({
    aggregatorRow,
    dateScale,
    dateSelect,
    membership,
    timeScale,
    translations
}) {
    if (membership.membershipType === COURSE && !aggregatorRow) {
        return dataTableFormatTime(dateSelect, dateScale);
    }

    const formattedDate = dataTableFormatTime(dateSelect, timeScale);

    switch (timeScale) {
        case 'year':
        case 'month':
            return `${formattedDate} ${translations.ONLINK_TOTAL}`;
        default:
            return formattedDate;
    }
}

function aggregateRow(value) {
    return (
        <span className='workboard-label'>
            {value}
        </span>
    );
}

function formatMinutesToHoursNumber(value) {
    return formatNumber(value / MINUTES_PER_HOUR, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

function hoursRow(row) {
    const hours = formatMinutesToHoursNumber(row.value);

    return aggregateRow(hours);
}

function currencyRow(row, currencyPreferenceByToggles) {
    const formattedCurrency = getFormattedCurrency(row.value, currencyPreferenceByToggles);

    return aggregateRow(formattedCurrency);
}

function getColumns({
    featureToggles,
    groupKey,
    membership,
    secondarySelector,
    timeScale,
    translations
}) {
    const currencyPreferenceByToggles = {
        currencyPreference: membership.currencyPreference,
        featureToggles
    };

    return [
        ...createColumnOrDefault(secondarySelector !== BY_TIME, {
            Header: getNameForHeader(secondarySelector, translations),
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            sortMethod: alphaNumericCompare,
            PivotValue: (row) => aggregateRow(row.value)
        }),
        ...createColumnOrDefault(groupKey === 'dateSelect', {
            Header: translations.DATE,
            accessor: 'dateSelect',
            sortMethod: dateCompare,
            Cell(row) {
                return getDateSelect({
                    aggregatorRow: row.aggregated,
                    dateScale: row.original?.dateScale,
                    dateSelect: row.value,
                    membership,
                    timeScale,
                    translations
                });
            },
            aggregate: (values) => values[0],
            Aggregated(row) {
                const content = getDateSelect({
                    aggregatorRow: row.aggregated,
                    dateSelect: row.value,
                    membership,
                    timeScale,
                    translations
                });

                return aggregateRow(content);
            }
        }),
        {
            Header: translations.ONLINK_EST_LABOR_HOURS,
            accessor: 'estDuration',
            Cell: (row) => formatMinutesToHoursNumber(row.value),
            aggregate: sum,
            Aggregated: hoursRow
        },
        {
            Header: translations.ONLINK_ESTIMATED_COST,
            accessor: 'estLaborCost',
            Cell: (row) => getFormattedCurrency(row.value, currencyPreferenceByToggles),
            aggregate: sum,
            Aggregated: (row) => currencyRow(row, currencyPreferenceByToggles),
            requiredMyJdPermissions: VIEW_FINANCIAL_DATA
        },
        {
            Header: translations.ONLINK_ACTUAL_HOURS,
            accessor: 'actualDuration',
            Cell: (row) => formatMinutesToHoursNumber(row.value),
            aggregate: sum,
            Aggregated: hoursRow
        },
        {
            Header: translations.ONLINK_ACTUAL_COST,
            accessor: 'laborCost',
            Cell: (row) => getFormattedCurrency(row.value, currencyPreferenceByToggles),
            aggregate: sum,
            Aggregated: (row) => currencyRow(row, currencyPreferenceByToggles),
            requiredMyJdPermissions: VIEW_FINANCIAL_DATA
        },
        {
            Header: translations.ONLINK_OVERTIME_HOURS,
            accessor: 'overtimeDuration',
            Cell: (row) => formatMinutesToHoursNumber(row.value),
            aggregate: sum,
            Aggregated: hoursRow
        },
        {
            Header: translations.ONLINK_OVERTIME_COST,
            accessor: 'overtimeCost',
            Cell: (row) => getFormattedCurrency(row.value, currencyPreferenceByToggles),
            aggregate: sum,
            Aggregated: (row) => currencyRow(row, currencyPreferenceByToggles),
            requiredMyJdPermissions: VIEW_FINANCIAL_DATA
        }
    ];
}

function getAggregatedValues(dataItems) {
    return dataItems.reduce((acc, item) => {
        const {
            actualDuration,
            estDuration,
            estLaborCost,
            laborCost,
            overtimeDuration,
            overtimeCost
        } = item;

        acc.actualDuration += actualDuration;
        acc.laborCost += laborCost;
        acc.estDuration += estDuration;
        acc.estLaborCost += estLaborCost;
        acc.overtimeDuration += overtimeDuration;
        acc.overtimeCost += overtimeCost;

        return acc;
    }, {
        actualDuration: 0,
        estDuration: 0,
        estLaborCost: 0,
        laborCost: 0,
        overtimeDuration: 0,
        overtimeCost: 0
    });
}

function getGroupedDataByNameAndDateSelect(groupedDataByName) {
    return Object.keys(groupedDataByName).reduce((groupedDataByNameAndDate, name) => {
        const items = groupedDataByName[name];

        groupedDataByNameAndDate[name] = groupBy(items, 'dateSelect');

        return groupedDataByNameAndDate;
    }, {});
}

function getGroupedData(chartDataForSelector, groupKey, secondarySelector) {
    if (secondarySelector !== BY_TIME) {
        const groupedDataByName = groupBy(chartDataForSelector, 'name');

        return groupKey === 'dateSelect' ?
            getGroupedDataByNameAndDateSelect(groupedDataByName) :
            groupedDataByName;
    }

    return groupBy(chartDataForSelector, 'dateSelect');
}

function getRowsData(chartDataForSelector, groupKey, secondarySelector) {
    const groupedData = getGroupedData(chartDataForSelector, groupKey, secondarySelector);

    return Object.keys(groupedData).reduce((rows, nameKey) => {
        const dataItems = groupedData[nameKey];

        if (Array.isArray(dataItems)) {
            const aggregatedValues = getAggregatedValues(dataItems);
            const [{
                dateScale,
                dateSelect,
                name
            }] = dataItems;

            rows.push({
                ...aggregatedValues,
                dateScale,
                dateSelect,
                name
            });
        } else {
            Object.keys(dataItems).forEach((dateSelectKey) => {
                const dataItemsByDateSelect = dataItems[dateSelectKey];
                const [{
                    actualDuration,
                    dateScale,
                    dateSelect,
                    estDuration,
                    estLaborCost,
                    laborCost,
                    name,
                    overtimeDuration,
                    overtimeCost
                }] = dataItemsByDateSelect;

                rows.push({
                    actualDuration,
                    dateScale,
                    dateSelect,
                    estDuration,
                    estLaborCost,
                    laborCost,
                    name,
                    overtimeDuration,
                    overtimeCost
                });
            });
        }

        return rows;
    }, []);
}

function getFormattedData(secondarySelector, rowsData, translations, membership, featureToggles) {
    const columnHeaderName = getNameForHeader(secondarySelector, translations);
    const title = secondarySelector === BY_JOB_CATEGORY ? 'Job Categories' : 'Jobs';
    const currencyPreferenceByToggles = {
        currencyPreference: membership.currencyPreference,
        featureToggles
    };

    const formattedData = rowsData.map((row) => ({
        ...row,
        actualDuration: formatMinutesToHoursNumber(row.actualDuration),
        estDuration: formatMinutesToHoursNumber(row.estDuration),
        estLaborCost: getFormattedCurrency(row.estLaborCost, currencyPreferenceByToggles),
        laborCost: getFormattedCurrency(row.laborCost, currencyPreferenceByToggles),
        overtimeDuration: formatMinutesToHoursNumber(row.overtimeDuration),
        overtimeCost: getFormattedCurrency(row.overtimeCost, currencyPreferenceByToggles)
    }));

    return {
        columnHeaderName,
        title,
        workboardsReport: sortBy(formattedData, 'name')
    };
}

function getAdditionalActionButtons(
    dateSelector,
    featureToggles,
    membership,
    rowsData,
    secondarySelector,
    timeScale,
    translations,
    myJdPermissionMap
) {
    if (!featureToggles[ONLINK_NAVIGATION_REDESIGN] && myJdPermissionMap.hasExportDataPermission &&
        (secondarySelector === BY_JOB_CATEGORY || secondarySelector === BY_JOB_TEMPLATE) && rowsData.length) {
        const {
            columnHeaderName,
            title,
            workboardsReport
        } = getFormattedData(secondarySelector, rowsData, translations, membership, featureToggles);

        return (
            <div className='export-button'>
                <AdditionalActionButton
                    className='export-report'
                    label={translations.EXPORT}
                    onClick={() => exportLaborWorkboardsReport({
                        columnHeaderName,
                        dateSelector,
                        timeScale,
                        title,
                        translations,
                        workboardsReport,
                        hasViewFinancialDataPermission: myJdPermissionMap.hasFinancialDataPermissions
                    })}
                >
                    <IconDownload iconDownload={ICON_STYLE}/>
                </AdditionalActionButton>
            </div>
        );
    }

    return (
        <></>
    );
}

function WorkboardDataTable(props) {
    const {
        laborReportData: chartDataForSelector,
        dateSelector,
        featureToggles,
        isTimeAxis,
        membership,
        secondarySelector,
        timeScale,
        translations,
        isMigrated,
        myJdPermissions
    } = props;

    const groupKey = isTimeAxis ? 'dateSelect' : 'name';
    const isCourse = membership.membershipType === COURSE;
    const rowsData = isCourse ? getRowsData(chartDataForSelector, groupKey, secondarySelector) : chartDataForSelector;
    const userAuth = {
        isMigrated,
        myJdPermissions
    };

    const myJdPermissionMap = React.useMemo(() => {
        return {
            hasFinancialDataPermissions: isAuthorized({
                myJdPermissions: VIEW_FINANCIAL_DATA
            }, userAuth),
            hasExportDataPermission: isAuthorized({
                myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA
            }, userAuth)
        };
    }, [isMigrated, myJdPermissions]);

    useNavBarActions(featureToggles[ONLINK_NAVIGATION_REDESIGN] && myJdPermissionMap.hasExportDataPermission ? [{
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportLaborWorkboardsReport({
            ...getFormattedData(secondarySelector, rowsData, translations, membership, featureToggles),
            dateSelector,
            timeScale,
            translations,
            hasViewFinancialDataPermission: myJdPermissionMap.hasFinancialDataPermissions
        }),
        title: 'EXPORT',
        variant: 'primary'
    }] : []);

    return (
        <div className='graph-table-container labor-report'>
            <DataTable
                columns={getColumns({
                    featureToggles,
                    groupKey,
                    membership,
                    secondarySelector,
                    timeScale,
                    translations
                })}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                filterComponent={getAdditionalActionButtons(
                    dateSelector,
                    featureToggles,
                    membership,
                    rowsData,
                    secondarySelector,
                    timeScale,
                    translations,
                    myJdPermissionMap
                )}
                pivotBy={timeScale === 'day' || secondarySelector === BY_TIME || !isTimeAxis || !isCourse ? [] : ['name']}
                rows={rowsData}
                translations={translations}
            />
        </div>
    );
}

WorkboardDataTable.propTypes = {
    dateSelector: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    isTimeAxis: PropTypes.bool,
    laborReportData: PropTypes.arrayOf(PropTypes.object),
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    secondarySelector: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(WorkboardDataTable);
