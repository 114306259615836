// Unpublished Work © 2020-2024 Deere & Company.

import {graphRedirect} from 'Utils/redirect-utils';

const LABOR_1_ID = 'f5d2c96f-ba76-4abe-bd99-21fc17d6fcd1';
const LABOR_2_ID = '6a8027c9-0d51-474b-9f5c-17ffdb21f714';
const LABOR_3_ID = '6d687d5b-1cf7-4014-af12-b5a2b650f42a';
const LABOR_4_ID = 'c39d5da5-785d-426d-b15b-2316c7be7a81';

const LABOR_URL = 'panelTypes/labor';

export default {
    [LABOR_1_ID]: {
        columnCount: 2,
        data: [
            'labor_hours',
            'total_labor_cost',
            'labor_regular_hours',
            'labor_regular_cost',
            'labor_overtime_hours',
            'labor_overtime_cost'
        ],
        dateSelect: 'month',
        dateSelectionTypes: ['month', 'year'],
        graphTitle: 'Labor',
        name: 'labor_1',
        onClick: graphRedirect,
        tileId: LABOR_1_ID,
        url: LABOR_URL
    },
    [LABOR_2_ID]: {
        columnCount: 2,
        data: [
            'labor_hours',
            'total_labor_cost',
            'labor_regular_hours',
            'labor_regular_cost',
            'labor_overtime_hours',
            'labor_overtime_cost'
        ],
        dateSelect: 'year',
        dateSelectionTypes: ['month', 'year'],
        graphTitle: 'Labor',
        name: 'labor_2',
        onClick: graphRedirect,
        tileId: LABOR_2_ID,
        url: LABOR_URL
    },
    [LABOR_3_ID]: {
        data: [
            'est_labor_hours',
            'labor_hours',
            'est_total_labor_cost',
            'total_labor_cost'
        ],
        dateSelect: 'month',
        dateSelectionTypes: ['month', 'year'],
        name: 'labor_3',
        onClick: graphRedirect,
        tileId: LABOR_3_ID,
        url: LABOR_URL
    },
    [LABOR_4_ID]: {
        data: [
            'est_labor_hours',
            'labor_hours',
            'est_total_labor_cost',
            'total_labor_cost'
        ],
        dateSelect: 'year',
        dateSelectionTypes: ['month', 'year'],
        name: 'labor_4',
        onClick: graphRedirect,
        tileId: LABOR_4_ID,
        url: LABOR_URL
    }
};
