// Unpublished Work © 2022-2024 Deere & Company.

export const SET_HASHED_USER_VALUES = 'SET_HASHED_USER_VALUES';

export function updateHashedUserValues(analytics) {
    return {
        type: SET_HASHED_USER_VALUES,
        analytics
    };
}
