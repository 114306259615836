// Unpublished Work © 2020-2024 Deere & Company.

import * as equipmentActions from 'Store/actions/equipment';

const DEFAULT_STATE = {
    equipmentAreas: [],
    equipmentTypes: []
};

const equipment = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case equipmentActions.UPDATE_EQUIPMENT_AREAS:
            return Object.assign({}, state, {
                equipmentAreas: action.equipmentAreas
            });
        case equipmentActions.UPDATE_EQUIPMENT_TYPES:
            return Object.assign({}, state, {
                equipmentTypes: action.equipmentTypes
            });
        default:
            return state;
    }
};

export default equipment;
