// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import NavItem from 'Ui/components/main-navbar/persistent-header/navigation/nav-item';
import {openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function Membership(props) {
    const {
        name,
        openMembershipSelectDialog
    } = props;

    return (
        <NavItem
            iconName='icon-org-selection'
            label={name}
            onClick={openMembershipSelectDialog}
            title={name}
        />
    );
}

Membership.propTypes = {
    name: PropTypes.string,
    openMembershipSelectDialog: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        name: state.membership.name
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openMembershipSelectDialog() {
            dispatch(openDialog(dialogTypes.MEMBERSHIP_SELECT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
