// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {findLast, sortBy} from 'lodash';
import {Typography} from '@mui/material';
import {isEmptyString} from 'Common/utils/validation-utils';

function getCurrentWorkboard(workboardsByDate) {
    const now = new Date();
    const currentTime = now.getTime();
    const formattedDate = formatTime(now, DATE_FORMATS.day);

    const currentDayWorkboards = workboardsByDate[formattedDate];

    const sortedWorkboards = sortBy(currentDayWorkboards, (workboard) => new Date(workboard.startTime).getTime());
    const currentWorkboard = findLast(sortedWorkboards, (workboard) => new Date(workboard.startTime).getTime() <= currentTime);

    return currentWorkboard || (sortedWorkboards.length > 0 ? sortedWorkboards[0] : null);
}

function WorkBoardNoteTile(props) {
    const {
        workboardsByDate,
        translations
    } = props;

    const currentWorkboard = React.useMemo(() => getCurrentWorkboard(workboardsByDate), [workboardsByDate]);

    const hasNote = !isEmptyString(currentWorkboard?.note);
    const hasPinLocation = !isEmptyString(currentWorkboard?.pinLocation);

    return (
        (hasNote || hasPinLocation) &&
        <div className='dashboard-panel medium-section-title work-board-note'>
            {hasNote && <p>{currentWorkboard.note}</p>}
            {hasPinLocation && <Typography
                sx={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}
            >{`${translations.PRO_GOLF_PIN_LOCATION}: ${currentWorkboard.pinLocation}`}</Typography>}
        </div>
    );
}

WorkBoardNoteTile.propTypes = {
    translations: PropTypes.translations,
    workboardsByDate: PropTypes.object
};

export default WorkBoardNoteTile;
