// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnLinkDialog from 'Ui/components/common/onlink-dialog/onlink-dialog';
import WorkBoardDialogJobs from 'OnLabor/my-work/work-board-dialog/work-board-dialog-jobs/work-board-dialog-jobs';
import WorkBoardDialogMowingDirections from 'OnLabor/my-work/work-board-dialog/work-board-dialog-mowing-directions';
import {getWorkboard} from 'Services/work-board-service';
import {fetchEffectData} from 'Utils/react-utils';
import {sortJobsBySeq} from 'Utils/workboard-utils';

const JOBS_ID = 'WorkBoardDialogJobs';
const MOWING_DIRECTIONS_ID = 'MowingDirections';

function WorkBoardDialog(props) {
    const {
        closeDialog,
        translations,
        workboardInDialog
    } = props;

    const [activeTab, setActiveTab] = React.useState(() => JOBS_ID);
    const [loading, setLoading] = React.useState(() => false);
    const [workboard, setWorkboard] = React.useState(() => null);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);
        const {
            workboard: workboardData, userOrder
        } = await getWorkboard(workboardInDialog.workboardId);

        const jobs = sortJobsBySeq(workboardData.jobs);

        if (isMounted()) {
            setWorkboard({
                ...workboardData,
                jobs,
                userOrder
            });
            setLoading(false);
        }
    }), [workboardInDialog]);

    const loadingWrapperProps = {
        className: 'onlink-loading-icon',
        loading,
        size: '50px'
    };

    const content = [
        {
            element: (
                <LoadingWrapper {...loadingWrapperProps}>
                    <WorkBoardDialogJobs
                        translations={translations}
                        workboard={workboard}
                    />
                </LoadingWrapper>
            ),
            id: JOBS_ID,
            label: translations.JOBS
        },
        {
            element: (
                <LoadingWrapper {...loadingWrapperProps}>
                    <WorkBoardDialogMowingDirections
                        translations={translations}
                        workboard={workboard}
                    />
                </LoadingWrapper>
            ),
            id: MOWING_DIRECTIONS_ID,
            label: translations.ONLINK_MOWING_DIRECTIONS
        }
    ];

    return (
        <OnLinkDialog
            activeTab={activeTab}
            className='work-board-dialog'
            closeHandler={closeDialog}
            content={content}
            large={true}
            onTabClick={setActiveTab}
            title={workboardInDialog.name}
        />
    );
}

WorkBoardDialog.propTypes = {
    closeDialog: PropTypes.func,
    translations: PropTypes.translations,
    workboardInDialog: PropTypes.object
};

export default WorkBoardDialog;
