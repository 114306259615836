// Unpublished Work © 2022-2024 Deere & Company.

export const GRAPH_THRESHOLD_CONSTANTS = [
    {
        dataType: 'npk',
        dataLabel: 'NPK'
    },
    {
        dataType: 'sulfur',
        dataLabel: 'Sulfur'
    },
    {
        dataType: 'mslp',
        dataLabel: 'MSLP'
    },
    {
        dataType: 'dialectric',
        dataLabel: 'Dia'
    },
    {
        dataType: 'hoc',
        dataLabel: 'Height of Cut'
    },
    {
        dataType: 'hoc_apps_spec',
        dataLabel: 'HOC Approaches Special'
    },
    {
        dataType: 'hoc_apps',
        dataLabel: 'HOC Apps'
    },
    {
        dataType: 'hoc_bunker_faces',
        dataLabel: 'HOC Bunker Faces'
    },
    {
        dataType: 'hoc_bunker_faces_spec',
        dataLabel: 'HOC Bunker Faces Special'
    },
    {
        dataType: 'hoc_bunker_surrounds',
        dataLabel: 'HOC Bunker Surrounds'
    },
    {
        dataType: 'hoc_bunker_surrounds_spec',
        dataLabel: 'HOC Bunker Surrounds Special'
    },
    {
        dataType: 'hoc_collars',
        dataLabel: 'HOC Collars'
    },
    {
        dataType: 'hoc_collars_spec',
        dataLabel: 'HOC Collars Special'
    },
    {
        dataType: 'hoc_fairways',
        dataLabel: 'HOC Fairways'
    },
    {
        dataType: 'hoc_fairways_spec',
        dataLabel: 'HOC Fairways Special'
    },
    {
        dataType: 'hoc_greens',
        dataLabel: 'HOC Greens'
    },
    {
        dataType: 'hoc_greens_spec',
        dataLabel: 'HOC Greens Special'
    },
    {
        dataType: 'hoc_int',
        dataLabel: 'HOC Intermediate'
    },
    {
        dataType: 'hoc_int_spec',
        dataLabel: 'HOC Intermediate Special'
    },
    {
        dataType: 'hoc_spec',
        dataLabel: 'HOC Other Special'
    },
    {
        dataType: 'hoc_rough',
        dataLabel: 'HOC Rough'
    },
    {
        dataType: 'hoc_rough_spec',
        dataLabel: 'HOC Rough Special'
    },
    {
        dataType: 'hoc_tees',
        dataLabel: 'HOC Tees'
    },
    {
        dataType: 'hoc_tees_spec',
        dataLabel: 'HOC Tees Special'
    },
    {
        dataType: 'percolation_rate',
        dataLabel: 'Percolation Rate'
    },
    {
        dataType: 'thatch',
        dataLabel: 'Thatch'
    },
    {
        dataType: 'water_cost_night',
        dataLabel: 'Water Cost Night'
    },
    {
        dataType: 'water_irrigation_day',
        dataLabel: 'Water Irrigation Day'
    },
    {
        dataType: 'electrical_cost_night',
        dataLabel: 'Electrical Cost Night'
    },
    {
        dataType: 'water_transfer_day',
        dataLabel: 'Water Usage Day'
    },
    {
        dataType: 'water_transfer_night',
        dataLabel: 'Water Usage Night'
    },
    {
        dataType: 'advance_lease_payments',
        dataLabel: 'Adv Lease Payments'
    },
    {
        dataType: 'final_lease_payments',
        dataLabel: 'Final Lease Payments'
    },
    {
        dataType: 'lease_annual_hours',
        dataLabel: 'Lease Annual Hours'
    },
    {
        dataType: 'lease_months_remaining',
        dataLabel: 'Lease Months Remaining'
    },
    {
        dataType: 'lease_payments',
        dataLabel: 'Lease Payments'
    },
    {
        dataType: 'lease_total_hours',
        dataLabel: 'Lease Total Hours'
    },
    {
        dataType: 'oil_pressure',
        dataLabel: 'Oil Pressure'
    },
    {
        dataType: 'equip_property_tax',
        dataLabel: 'Property Tax'
    },
    {
        dataType: 'purchase_payments',
        dataLabel: 'Purchase Payments'
    },
    {
        dataType: 'equip_sales_tax',
        dataLabel: 'Sales Tax'
    },
    {
        dataType: 'tire_pressure',
        dataLabel: 'Tire Pressure'
    },
    {
        dataType: 'water_cost',
        dataLabel: 'Water Cost'
    },
    {
        dataType: 'clipping_yield',
        dataLabel: 'Clipping Yield'
    },
    {
        dataType: 'clipping_yield_volume',
        dataLabel: 'Clipping Yield - Volume'
    },
    {
        dataType: 'clipping_yield_weight',
        dataLabel: 'Clipping Yield - Weight'
    }
];
