// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';

function Tab(props) {
    const {
        active,
        onTabClick,
        text
    } = props;

    const className = clsx('tab', {
        active
    });

    return (
        <button
            className={className}
            onClick={active ? null : onTabClick}
        >
            {text}
        </button>
    );
}

Tab.propTypes = {
    active: PropTypes.bool,
    onTabClick: PropTypes.func,
    text: PropTypes.string
};

export default Tab;
