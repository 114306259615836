// Unpublished Work © 2020-2024 Deere & Company.

import * as toastsActions from 'Store/actions/toasts';

const DEFAULT_STATE = {
    toasts: []
};

const toasts = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case toastsActions.ADD_TOAST:
            return Object.assign({}, state, {
                toasts: [
                    ...state.toasts,
                    action.toast
                ]
            });
        case toastsActions.REMOVE_TOAST:
            return Object.assign({}, state, {
                toasts: state.toasts.filter((toast) =>
                    toast.id !== action.id
                )
            });
        default:
            return state;
    }
};

export default toasts;
