// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {getWorkItemsPerColumn, getSharedWorkItemDetails} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';

function formatWorkItemsByEmployee(workItems, showEstimatedHours, showPPE) {
    return workItems.map((workItem) => {
        const workItemDetails = getSharedWorkItemDetails(workItem, showPPE, true);

        return (
            <div
                className={'workitems-by-employee'}
                key={`${workItem.appUserId}-${workItem.jobId}`}
            >
                <h3 className={'work-item-header'}>
                    {`${workItem.title}`}
                    <span className={'est-hours'}>{showEstimatedHours ? ` ${workItem.estHours}` : ''}</span>
                </h3>
                {workItemDetails}
            </div>
        );
    });
}

function getJobsByEmployeeHeader(workItem, showEmployeeGroupColor, showEmployeeImage) {
    const imgUrl = workItem.profilePicUrl;

    return (
        <div className={'employee-title-header'}>
            {
                showEmployeeGroupColor &&
                <div
                    className={'group-color'}
                    key={workItem.appUserId}
                    style={{
                        backgroundColor: workItem.color
                    }}
                />
            }
            {
                showEmployeeImage &&
                <img
                    className={`user-image${imgUrl ? '' : ' hide-image'}`}
                    src={imgUrl}
                />
            }
            <h3 className={'title'}>
                {`${workItem.appUserName}`}
            </h3>
        </div>
    );
}

function formatEmployeeView(workItemsByEmployee, properties) {
    const {
        showEstimatedHours, tileOpacity, showPPE, fontSize, columnCount, showEmployeeGroupColor, showEmployeeImage
    } = properties;

    const workItemsPerColumn = getWorkItemsPerColumn(workItemsByEmployee, columnCount).filter(Boolean);

    return Array.from({
        length: columnCount
    }, (x, i) => {
        return (
            <div
                className='grid-column'
                key={i}
            >
                {
                    workItemsPerColumn[i]?.map((key, index) => {
                        const workItems = workItemsByEmployee[key];

                        return (
                            <div
                                className={`presentation-job-item ${tileOpacity}`}
                                key={index}
                                style={{
                                    fontSize
                                }}
                            >
                                {getJobsByEmployeeHeader(workItems[0], showEmployeeGroupColor, showEmployeeImage)}
                                {formatWorkItemsByEmployee(workItems, showEstimatedHours, showPPE)}
                            </div>
                        );
                    })
                }
            </div>
        );
    });
}

function PresentationEmployeeView(props) {
    const {
        workItemsByEmployee,
        properties
    } = props;

    return (
        <div className={'jobs-container'}>
            {formatEmployeeView(workItemsByEmployee, properties)}
        </div>
    );
}

PresentationEmployeeView.propTypes = {
    properties: PropTypes.object,
    workItemsByEmployee: PropTypes.array
};

export default PresentationEmployeeView;
