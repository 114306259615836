// Unpublished Work © 2023-2024 Deere & Company.

import store from 'Store/store';
import {setTertiaryNavBarActions} from 'Store/actions/tertiary-navbar';
import {useDeepEffect} from 'Utils/react-utils';

function useNavBarActions(actions = []) {
    useDeepEffect(() => {
        store.dispatch(setTertiaryNavBarActions(actions));

        return () => {
            store.dispatch(setTertiaryNavBarActions([]));
        };
    }, [actions]);
}

export {
    useNavBarActions
};
