// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {EquipmentCostReport} from 'Ui/features/onequip/reports/cost-report';
import {EquipmentUptimeReport} from 'Ui/features/onequip/reports/uptime-report';
import {EquipmentLifetimeReport} from 'Ui/features/onequip/reports/lifetime-report';
import EquipmentReportFilters from 'Ui/features/onequip/reports/equipment-report-filters';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import Panel from 'Ui/components/panel/panel';
import {getFleetEquipment} from 'Services/membership-service';
import {
    COST_BY_EQUIPMENT_AREA,
    COST_BY_EQUIPMENT_MODEL,
    COST_BY_EQUIPMENT_TYPE,
    EQUIPMENT_COST,
    EQUIPMENT_UPTIME,
    INVENTORIES,
    LIFETIME_REPORTS
} from 'Ui/components/graph/constants/graph-filters';
import {fetchEffectData} from 'Utils/react-utils';
import {clsx} from 'clsx';

const LOADING_ICON_PROPS = {
    className: 'dashboard-loading-icon',
    size: '50px'
};

const EQUIPMENT_REPORT_VIEWS = {
    [EQUIPMENT_COST]: EquipmentCostReport,
    [EQUIPMENT_UPTIME]: EquipmentUptimeReport,
    [COST_BY_EQUIPMENT_AREA]: EquipmentLifetimeReport,
    [COST_BY_EQUIPMENT_MODEL]: EquipmentLifetimeReport,
    [COST_BY_EQUIPMENT_TYPE]: EquipmentLifetimeReport,
    [INVENTORIES]: EquipmentLifetimeReport
};

function initializeStateAndRefs() {
    const [allData, setAllData] = React.useState(() => ({
        dateSelect: null,
        equipment: []
    }));
    const [loading, setLoading] = React.useState({
        equipment: true,
        dateSelect: true,
        primarySelector: true,
        secondarySelector: false,
        tertiarySelector: false
    });
    const [primarySelector, setPrimarySelector] = React.useState(EQUIPMENT_COST);
    const [secondarySelector, setSecondarySelector] = React.useState(null);
    const [tertiarySelector, setTertiarySelector] = React.useState(null);
    const [timeScale, setTimeScale] = React.useState('month');

    return {
        allData,
        loading,
        primarySelector,
        setAllData,
        setLoading,
        setPrimarySelector,
        setSecondarySelector,
        setTertiarySelector,
        setTimeScale,
        secondarySelector,
        tertiarySelector,
        timeScale
    };
}

function EquipmentReport(props) {
    const {
        featureToggles,
        membership,
        translations
    } = props;

    const {
        allData,
        loading,
        primarySelector,
        setAllData,
        setLoading,
        setPrimarySelector,
        setSecondarySelector,
        setTertiarySelector,
        setTimeScale,
        secondarySelector,
        tertiarySelector,
        timeScale
    } = initializeStateAndRefs();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading((prevLoading) => ({
            ...prevLoading,
            equipment: true
        }));

        const {equipment: equipmentData} = await getFleetEquipment();

        if (isMounted()) {
            setAllData((prevAllData) => ({
                ...prevAllData,
                equipment: equipmentData
            }));
            setLoading((prevLoading) => ({
                ...prevLoading,
                equipment: false
            }));
        }
    }), [membership.membershipId]);

    const updateDateFilter = React.useCallback((selectedDateSelect, selectedTimeScale) => {
        setLoading((prevLoading) => ({
            ...prevLoading,
            dateSelect: true
        }));

        setTimeScale(selectedTimeScale);

        setAllData((prevAllData) => ({
            ...prevAllData,
            dateSelect: selectedDateSelect
        }));
    }, [primarySelector]);

    const className = clsx('graph-panel-wrapper', {
        hidden: loading.dateSelect || loading.equipment || loading.primarySelector || loading.secondarySelector || loading.tertiarySelector
    });

    const ReportComponent = EQUIPMENT_REPORT_VIEWS[primarySelector];

    return (
        <LoadingWrapper
            {...LOADING_ICON_PROPS}
            alwaysRenderChildren={true}
            loading={loading.dateSelect || loading.equipment || loading.primarySelector || loading.secondarySelector || loading.tertiarySelector}
        >
            <Panel
                className={className}
                title='ONLINK_EQUIPMENT_REPORT'
                translations={translations}
            >
                <EquipmentReportFilters
                    fleetEquipment={allData.equipment}
                    isMigrated={membership.isMigrated}
                    primarySelector={primarySelector}
                    secondarySelector={secondarySelector}
                    tertiarySelector={tertiarySelector}
                    timeScale={timeScale}
                    translations={translations}
                    updateDateFilter={updateDateFilter}
                    updatePrimarySelector={(selected) => {
                        if (selected !== primarySelector) {
                            setLoading((prevLoading) => ({
                                ...prevLoading,
                                primarySelector: true
                            }));
                        }

                        setPrimarySelector(selected);
                    }}
                    updateSecondarySelector={(selected) => {
                        if (selected !== secondarySelector &&
                            (LIFETIME_REPORTS.includes(primarySelector) || primarySelector === EQUIPMENT_COST)) {
                            setLoading((prevLoading) => ({
                                ...prevLoading,
                                secondarySelector: true
                            }));
                        }

                        setSecondarySelector(selected);
                    }}
                    updateTertiarySelector={(selected) => {
                        if (selected !== tertiarySelector && primarySelector === EQUIPMENT_COST) {
                            setLoading((prevLoading) => ({
                                ...prevLoading,
                                tertiarySelector: true
                            }));
                        }

                        setTertiarySelector(selected);
                    }}
                />
                {
                    ReportComponent && allData.equipment &&
                    <ReportComponent
                        dateSelect={allData.dateSelect}
                        featureToggles={featureToggles}
                        fleetEquipment={allData.equipment}
                        loading={loading}
                        membership={membership}
                        primarySelector={primarySelector}
                        secondarySelector={secondarySelector}
                        setLoading={setLoading}
                        tertiarySelector={tertiarySelector}
                        translations={translations}
                    />
                }
            </Panel>
        </LoadingWrapper>
    );
}

EquipmentReport.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership
    };
}

export default connect(mapStateToProps)(EquipmentReport);
