// Unpublished Work © 2021-2024 Deere & Company.

export const MYWORK_JOBS_DISPLAYED = {
    CURRENT_WORKBOARD: 'current_workboard',
    TODAY: 'today',
    ALL: 'all'
};

export const DEFAULT_IMAGE_URL = 'images/bg/default_membership_image.jpg';

export const MYWORK_WORKBOARD_VIEW = {
    OFF: 'off',
    CURRENT: 'current',
    CURRENT_AND_FUTURE: 'current_and_future'
};

export const WORKBOARD_PRESENTATION_GROUPS = {
    JOBS: 'jobs'
};

export const TRUE = 'true';

export const ONLINK_MEMBERSHIP_ID = '48073f1c-3b73-4036-b36e-c4025f0df5af';

const VAQUERO_GOLF_CLUB_FACILITY_ID = 'e2a2f8af-5d04-4e84-8cb2-d20aa543f284';
const BELLERIVE_COUNTRY_CLUB_FACILITY_ID = '98b826f8-d014-4b18-8572-deed4a05ec53';
const QUAIL_WEST_GOLF_AND_COUNTRY_CLUB__ID = 'd8812e38-8120-4582-85e7-39f6b1441d3e';

export const FORETEES_EXCLUDED_FACILITIES = [VAQUERO_GOLF_CLUB_FACILITY_ID, BELLERIVE_COUNTRY_CLUB_FACILITY_ID, QUAIL_WEST_GOLF_AND_COUNTRY_CLUB__ID];
