// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import SidebarList from 'Ui/components/map/sidebar/common/sidebar-list';
import {updateModelFilterSelectedIDs, updateTypeFilterSelectedIDs} from 'Store/actions/map';
import {connect} from 'react-redux';
import moment from 'moment';
import FilterView from 'Ui/components/map/sidebar/common/filter-view';
import EquipmentFilters from 'Ui/components/map/sidebar/equipment-filters';

function mapToMultiSelectItem(item) {
    return {
        id: item,
        title: item
    };
}

function getAppliedFilters(modelsMultiSelectListAllIds, typesMultiSelectListAllIds, props) {
    const {
        modelFilterSelectedIDs,
        translations,
        typeFilterSelectedIDs,
        updateModelFilterSelectedIDs,
        updateTypeFilterSelectedIDs
    } = props;

    const appliedFilters = [];

    if (modelFilterSelectedIDs.length < modelsMultiSelectListAllIds.length) {
        appliedFilters.push({
            name: translations.ONLINK_BY_MODEL,
            clearCallBack() {
                updateModelFilterSelectedIDs(modelsMultiSelectListAllIds);
            }
        });
    }

    if (typeFilterSelectedIDs.length < typesMultiSelectListAllIds.length) {
        appliedFilters.push({
            name: translations.ONLINK_BY_TYPE,
            clearCallBack() {
                updateTypeFilterSelectedIDs(typesMultiSelectListAllIds);
            }
        });
    }

    return appliedFilters;
}

function EquipmentList(props) {
    const {
        children,
        dateSelected,
        equipmentTypes,
        loading,
        modelNames,
        onSearchChange,
        search,
        setDate,
        showDateFilter,
        translations
    } = props;

    const modelsMultiSelectList = modelNames.map(mapToMultiSelectItem);
    const typesMultiSelectList = equipmentTypes.map(mapToMultiSelectItem);

    const listComponent = (
        <SidebarList
            appliedFilters={getAppliedFilters(modelNames, equipmentTypes, props)}
            dateSelected={dateSelected}
            emptyMessage={showDateFilter ? translations.ONLINK_NO_EQUIPMENT_FOR_THE_DATE_SELECTED : translations.ONLINK_NO_EQUIPMENT_FOR_MEMBERSHIP}
            loading={loading}
            onSearchChange={onSearchChange}
            search={search}
            setDate={setDate}
            showDateFilter={showDateFilter}
            showFilter={equipmentTypes.length > 0 || modelNames.length > 0}
            translations={translations}
        >
            {
                children
            }
        </SidebarList>
    );

    const filterComponent = (
        <EquipmentFilters
            modelsMultiSelectList={modelsMultiSelectList}
            modelsMultiSelectListAllIds={modelNames}
            translations={translations}
            typesMultiSelectList={typesMultiSelectList}
            typesMultiSelectListAllIds={equipmentTypes}
        />
    );

    return (
        <FilterView
            filterComponent={filterComponent}
            listComponent={listComponent}
        />
    );
}

EquipmentList.propTypes = {
    children: PropTypes.node,
    dateSelected: PropTypes.instanceOf(moment),
    equipmentTypes: PropTypes.arrayOf(PropTypes.string),
    loading: PropTypes.bool,
    modelFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    modelNames: PropTypes.arrayOf(PropTypes.string),
    onSearchChange: PropTypes.func,
    search: PropTypes.string,
    setDate: PropTypes.func,
    showDateFilter: PropTypes.bool,
    translations: PropTypes.translations,
    typeFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    updateModelFilterSelectedIDs: PropTypes.func,
    updateTypeFilterSelectedIDs: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        modelFilterSelectedIDs: state.map.modelFilterSelectedIDs,
        typeFilterSelectedIDs: state.map.typeFilterSelectedIDs
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateModelFilterSelectedIDs(value) {
            dispatch(updateModelFilterSelectedIDs(value));
        },
        updateTypeFilterSelectedIDs(value) {
            dispatch(updateTypeFilterSelectedIDs(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentList);
