// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTypeView from 'Ui/components/panel/data-type-view';
import Panel from 'Ui/components/panel/panel';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import PanelErrorMessage from 'Ui/components/panel/panel-error-message';
import {addDividers} from 'Ui/components/panel/utils/add-dividers';
import {mergeTileData} from 'Utils/data-utils';
import {isEmptyArray, isNullOrUndefined} from 'Common/utils/validation-utils';
import {chunk} from 'lodash';
import {IconChevronRight} from '@deere/icons';
import {GROUPED_DATA_TYPE_OVERRIDES, GROUPED_DATA_TYPES} from 'Ui/components/common/data-overrides';
import {FIRMNESS} from 'Common/constants/data-group-constants';

const DEFAULT_ROW_SIZE = 3;
const LAST_TWO = -2;
const MIN_ROW_SIZE = 1;
const REORGANIZED_ROW_SIZE = 2;
const PANEL_TYPE_COURSE = 'course';
const PANEL_TYPE_WEATHER_5 = 'weather_5';

function transformData(dataRollups, panelData, thresholds) {
    const mergedData = mergeTileData(dataRollups, panelData, thresholds);

    return mergedData.map((data) => ({
        ...data.dataRollup,
        config: data.threshold,
        timeScale: data.panelData.timeScale,
        useField: `${data.panelData.useField}Value`
    }));
}

function getPreferredDataType(featureToggles, dataType, unitOfMeasureOverrides) {
    return unitOfMeasureOverrides.reduce((selectedValue, unitOfMeasureOverride) => {
        if (GROUPED_DATA_TYPES[dataType].includes(unitOfMeasureOverride.dataType)) {
            return unitOfMeasureOverride.dataType;
        }

        return selectedValue;
    }, dataType);
}

function filterDataRollupsByDataType(dataRollups, dataType, preferredDataType) {
    return dataRollups.reduce((filteredDataRollups, dataRollUp) => {
        if (GROUPED_DATA_TYPES[dataType].includes(dataRollUp.dataType)) {
            if (dataRollUp.dataType === preferredDataType) {
                filteredDataRollups.push({
                    ...dataRollUp,
                    dataType: GROUPED_DATA_TYPE_OVERRIDES[dataRollUp.dataType]
                });
            }
        } else {
            filteredDataRollups.push(dataRollUp);
        }

        return filteredDataRollups;
    }, []);
}

function filterDataRollups(dataRollups, featureToggles, panel, unitOfMeasureOverrides) {
    if (panel.panelType === PANEL_TYPE_COURSE || panel.panelType === PANEL_TYPE_WEATHER_5) {
        const preferredDataType = getPreferredDataType(featureToggles, FIRMNESS, unitOfMeasureOverrides);

        return filterDataRollupsByDataType(dataRollups, FIRMNESS, preferredDataType);
    }

    return dataRollups;
}

function getChildComponents({
    currencyPreference,
    featureToggles,
    panel,
    thresholds,
    translations,
    unitOfMeasure,
    unitOfMeasureOverrides
}) {
    if (isEmptyArray(panel.panelData)) {
        return [];
    }

    const rollups = filterDataRollups(panel.dataRollups, featureToggles, panel, unitOfMeasureOverrides);

    if (isEmptyArray(rollups)) {
        return [
            <NoDataMessage
                key='noDataMessage'
                translations={translations}
            />
        ];
    }

    const rollupsWithConfig = transformData(rollups, panel.panelData, thresholds);

    return rollupsWithConfig.some(({config}) => !config) ?
        [<PanelErrorMessage key='panelErrorMessage'/>] :
        rollupsWithConfig.map((rollup) => (
            <DataTypeView
                currencyPreference={currencyPreference}
                featureToggles={featureToggles}
                key={rollup.dataType}
                rollup={rollup}
                translations={translations}
                unitOfMeasure={unitOfMeasure}
                unitOfMeasureOverrides={unitOfMeasureOverrides}
            />
        ));
}

function reOrganizeLastRows(rowsOfComponents, columnCount) {
    const lastRow = rowsOfComponents[rowsOfComponents.length - 1];
    const shouldReorganizeLastComponents = lastRow.length === MIN_ROW_SIZE;

    if (!shouldReorganizeLastComponents) {
        return rowsOfComponents;
    }

    const lastComponents = [
        ...rowsOfComponents[rowsOfComponents.length - 2],
        ...lastRow
    ];
    const reorganizedLastComponents = chunk(
        lastComponents,
        Math.max(columnCount - 1, REORGANIZED_ROW_SIZE)
    );

    return [
        ...rowsOfComponents.slice(0, LAST_TWO),
        ...reorganizedLastComponents
    ];
}

function organizeIntoRows(childComponents, columnCount) {
    const rowsOfComponents = chunk(childComponents, columnCount);
    const shouldReorganizeRows = rowsOfComponents.length > 1 && columnCount > 1;

    return shouldReorganizeRows ?
        reOrganizeLastRows(rowsOfComponents, columnCount) :
        rowsOfComponents;
}

function getRowComponents(childComponents, columnCount) {
    const rowsOfComponents = organizeIntoRows(childComponents, columnCount);

    return rowsOfComponents.map((row, i) => (
        <div
            className='panel-row'
            key={i}
        >
            {addDividers(row, 'panel-vertical-divider')}
        </div>
    ));
}

function GenericDataPanel(props) {
    const {
        columnCount,
        onClick,
        panel,
        translations,
        ...otherProps
    } = props;

    const childComponents = getChildComponents({
        panel,
        translations,
        ...otherProps
    });
    const iconChevronRight = React.useMemo(() => !panel.panelData && <IconChevronRight/>, [!panel.panelData]);
    const columnCountOrDefault = React.useMemo(
        () => isNullOrUndefined(columnCount) ? DEFAULT_ROW_SIZE : Math.max(columnCount, MIN_ROW_SIZE),
        [columnCount]
    );

    const childComponentRows = getRowComponents(childComponents, columnCountOrDefault);
    const childComponentsWithDividers = addDividers(childComponentRows, 'panel-horizontal-divider');

    return (
        <Panel
            className='generic-panel'
            icon={iconChevronRight}
            onClick={onClick}
            title={panel.panelTitle}
            translations={translations}
        >
            {childComponentsWithDividers}
        </Panel>
    );
}

GenericDataPanel.propTypes = {
    columnCount: PropTypes.number,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    onClick: PropTypes.func,
    panel: PropTypes.panel,
    thresholds: PropTypes.thresholds,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        currencyPreference: state.membership.currencyPreference,
        unitOfMeasure: state.membership.unitOfMeasure,
        unitOfMeasureOverrides: state.membership.unitOfMeasureOverrides
    };
}

export default connect(mapStateToProps)(GenericDataPanel);
