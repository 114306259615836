// Unpublished Work © 2021-2024 Deere & Company.

export const UPDATE_MODEL_FILTER_SELECTED = 'UPDATE_MODEL_FILTER_SELECTED_IDS';
export const UPDATE_TYPE_FILTER_SELECTED = 'UPDATE_TYPE_FILTER_SELECTED_IDS';

export function updateModelFilterSelectedIDs(modelFilterSelectedIDs) {
    return {
        type: UPDATE_MODEL_FILTER_SELECTED,
        modelFilterSelectedIDs
    };
}

export function updateTypeFilterSelectedIDs(typeFilterSelectedIDs) {
    return {
        type: UPDATE_TYPE_FILTER_SELECTED,
        typeFilterSelectedIDs
    };
}
