// Unpublished Work © 2022-2024 Deere & Company.

function formatRouteWithCustomHoles(route, routeCustomDefn, translations) {
    return routeCustomDefn && routeCustomDefn.length > 0 ?
        `${route} - ${translations.ONLINK_HOLES} ${routeCustomDefn.join(', ')}` :
        route;
}

export {
    formatRouteWithCustomHoles
};
