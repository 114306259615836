// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MobileNav from 'Ui/components/secondary-navbar/common/mobile-nav';
import MobileNavList from 'Ui/components/secondary-navbar/common/mobile-nav-list';
import {redirectAndToggleDropdown} from 'Ui/components/secondary-navbar/utils/nav-helpers';

function MobileNavWrapper(props) {
    const {
        activeNavItem,
        history,
        navItems,
        toggleDropdown,
        translations
    } = props;

    return (
        <MobileNav
            {...props}
            dropdownTitle={translations[activeNavItem] || activeNavItem}
        >
            <MobileNavList
                navItems={navItems}
                onClick={(navItem) => redirectAndToggleDropdown(navItem, history, toggleDropdown)}
            />
        </MobileNav>
    );
}

MobileNavWrapper.propTypes = {
    activeNavItem: PropTypes.string,
    history: PropTypes.history,
    navItems: PropTypes.arrayOf(PropTypes.navItem),
    toggleDropdown: PropTypes.func,
    translations: PropTypes.translations
};

export default MobileNavWrapper;
