// Unpublished Work © 2023-2024 Deere & Company.

import parsePhoneNumberFromString from 'libphonenumber-js/min';

function formatPhoneNumber(phone, countryCallingCode) {
    if (!phone) {
        return '';
    }

    const dialCode = countryCallingCode ? `+${countryCallingCode}` : '';
    const parsedPhoneNumber = parsePhoneNumberFromString(`${dialCode}${phone}`);

    if (parsedPhoneNumber) {
        return parsedPhoneNumber.formatInternational();
    }

    return phone;
}

export {
    formatPhoneNumber
};
