// Unpublished Work © 2020-2024 Deere & Company.

import {isNullOrUndefined} from 'Common/utils/validation-utils';

function filterClassName(className) {
    return !isNullOrUndefined(className) && className !== '';
}

function joinClassNames(...classNames) {
    const newClassNames = classNames.filter(filterClassName);

    return newClassNames.join(' ');
}

function getBase64DataURL(fileType, data) {
    return `data:${fileType};base64,${data}`;
}

export {
    joinClassNames,
    getBase64DataURL
};
