// Unpublished Work © 2020-2024 Deere & Company.
import axios from 'axios';
const axiosClients = new Map();

function createAxiosClient(url, options, callback) {
    const key = url + JSON.stringify(options.headers);

    if (!axiosClients.has(key)) {
        const axiosClient = axios.create(options);

        if (callback) {
            callback(axiosClient);
        }

        axiosClients.set(key, axiosClient);
    }

    return axiosClients.get(key);
}

export {createAxiosClient};
