// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import Stack from '@mui/material/Stack';
import {Button} from '@deere/isg.component-library/lib/Button';
import {DropdownMenu} from '@deere/isg.component-library/lib/DropdownMenu';
import {IconButton} from '@deere/isg.component-library/lib/IconButton';
import {isAuthorized} from 'Common/utils/authorization-handler';

function ActionButtons(props) {
    const {
        isMobile,
        items,
        isMigrated,
        myJdPermissions,
        translations,
        ...otherProps
    } = props;

    if (items.length === 0) {
        return null;
    }

    const filteredItems = items.filter((item) => !item.hidden && isAuthorized({
        myJdPermissions: item.requiredMyJdPermissions
    }, {
        myJdPermissions,
        isMigrated
    }));

    if (filteredItems.length === 0) {
        return null;
    }

    return isMobile && filteredItems.length > 1 ? (
        <DropdownMenu
            buttonIcon='icon-more'
            buttonTitle={translations.ACTIONS}
            items={filteredItems.map((item) => ({
                ...item,
                title: translations[item.title]
            }))}
            {...otherProps}
        />
    ) : (
        <Stack
            direction='row'
            spacing={2}
            {...otherProps}
        >
            {
                filteredItems.map((item) => {
                    const {
                        Icon,
                        title,
                        ...buttonProps
                    } = item;

                    const {[title]: translatedTitle} = translations;

                    return Icon && !translatedTitle ? (
                        <IconButton
                            {...buttonProps}
                            icon={Icon}
                            key={title}
                        />
                    ) : (
                        <Button
                            {...buttonProps}
                            key={title}
                            startIcon={Icon}
                        >
                            {translatedTitle}
                        </Button>
                    );
                })
            }
        </Stack>
    );
}

ActionButtons.propTypes = {
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    myJdPermissions: PropTypes.myJdPermissions,
    requiredMyJdPermissions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        items: state.tertiaryNavBar.actions,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...ownProps,
        ...stateProps
    };
}

export default connect(mapStateToProps, null, mergeProps)(MediaQuery(MOBILE_MEDIA_QUERY)(ActionButtons));
