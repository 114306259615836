// Unpublished Work © 2023-2024 Deere & Company.

import {COURSE, FACILITY, ORGANIZATION} from 'Common/constants/membership-type';

function getVendorSearchParams(vendor) {
    const vendorUrl = vendor?.url;

    if (vendorUrl) {
        const parsedVendorUrl = new URL(vendorUrl);

        return new URLSearchParams(parsedVendorUrl.search);
    }

    return new URLSearchParams();
}

function hasPogoSensors(membership, parentFacilityWithoutUsers, facilityWithoutUsers) {
    const noParentOrOrganization = membership.parent?.membershipType === ORGANIZATION || parentFacilityWithoutUsers;
    const membershipIsFacilityAndHasUsers = membership.membershipType === FACILITY && !facilityWithoutUsers;

    return membershipIsFacilityAndHasUsers || membership.membershipType === COURSE && noParentOrOrganization;
}

export {
    getVendorSearchParams,
    hasPogoSensors
};
