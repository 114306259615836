// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {openDialog} from 'Store/actions/dialogs';
import {useDeepMemo} from 'Utils/react-utils';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {MAX_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import dialogTypes from 'Ui/components/common/dialog-types';

function PartDialogForm(props) {
    const {
        allManufacturers,
        inventories,
        isEdit,
        manufacturerCollection,
        onSave,
        openManufacturerDialog,
        openPartTypeDialog,
        partTypeCollection,
        setValid,
        setValues,
        translations,
        updateManufacturers,
        updatePartTypes,
        values
    } = props;

    const inventoryId = inventories.length > 0 ? inventories[0].inventoryId : null;

    function onChange(event) {
        const {
            name,
            value
        } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    function onChangeArrayWrapper(name, value) {
        onChange({
            target: {
                name,
                value
            }
        });
    }

    const numberInputErrors = {
        rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`,
        rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
            '0': 0
        })
    };

    const existingManufacturers = useDeepMemo(() => allManufacturers.reduce((manufacturerNames, manufacturer) => {
        manufacturerNames.add(manufacturer.name);

        return manufacturerNames;
    }, new Set()), [allManufacturers]);

    return (
        <form
            className='settings-body'
            onSubmit={onSave}
        >
            <div className='settings-group mobile'>
                <ValidationMultiSelect
                    className='required-input mobile-required-multiselect'
                    component={SortedMultiSelect}
                    defaultSelectLabel={translations.ONLINK_SELECT_MANUFACTURER}
                    disabled={isEdit}
                    error={translations.REQUIRED_FIELD_TEXT}
                    footerActions={[{
                        onClick: () => openManufacturerDialog({
                            existingManufacturers,
                            inventoryIdRef: {
                                current: inventoryId
                            },
                            manufacturer: null,
                            onClose: updateManufacturers,
                            translations
                        }),
                        title: replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                            '0': translations.MANUFACTURER
                        })
                    }]}
                    items={manufacturerCollection}
                    label={translations.MANUFACTURER}
                    name='manufacturerId'
                    onChange={([selectedId]) => onChangeArrayWrapper('manufacturerId', selectedId)}
                    selectedIds={[values.manufacturerId]}
                    setValid={setValid}
                    tabIndex={0}
                />
            </div>
            <div className='settings-group mobile'>
                <SortedMultiSelect
                    className='multiselect'
                    footerActions={[{
                        onClick: () => openPartTypeDialog({
                            fetchPartTypes: updatePartTypes,
                            inventoryId,
                            inventoryPartTypes: partTypeCollection,
                            partType: null,
                            translations
                        }),
                        title: replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                            '0': translations.ONLINK_PART_TYPE
                        })
                    }]}
                    hideFilter={true}
                    items={partTypeCollection}
                    label={translations.ONLINK_PART_TYPE}
                    onChange={([selectedId]) => {
                        if (selectedId) {
                            onChangeArrayWrapper('partTypeId', selectedId);
                        }
                    }}
                    selectedIds={[values.partTypeId]}
                    tabIndex={0}
                />
            </div>
            <div className='settings-group mobile'>
                <ValidationInput
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={translations.NAME}
                    name='name'
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={values.name}
                />
            </div>
            <div className='settings-group mobile'>
                <ValidationInput
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT
                    }}
                    label={capitalizeFirstLetter(translations.PART_NUMBER)}
                    name='partNumber'
                    onChange={onChange}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={values.partNumber}
                />
            </div>
            <div className='settings-group mobile'>
                <ValidationInput
                    errors={numberInputErrors}
                    label={translations.ONLINK_DEFAULT_THRESHOLD}
                    max={MAX_NUMERIC_VALUE}
                    min={0}
                    name='thresholdLevel'
                    onChange={onChange}
                    setValid={setValid}
                    tabIndex={0}
                    type='number'
                    value={values.thresholdLevel}
                />
                <ValidationInput
                    errors={numberInputErrors}
                    label={translations.ONLINK_DEFAULT_RESTOCK_TARGET}
                    max={MAX_NUMERIC_VALUE}
                    min={0}
                    name='restockTarget'
                    onChange={onChange}
                    setValid={setValid}
                    tabIndex={0}
                    type='number'
                    value={values.restockTarget}
                />
            </div>
        </form>
    );
}

PartDialogForm.defaultProps = {
    inventories: []
};

PartDialogForm.propTypes = {
    allManufacturers: PropTypes.arrayOf(PropTypes.object),
    inventories: PropTypes.arrayOf(PropTypes.object),
    isEdit: PropTypes.bool,
    manufacturerCollection: PropTypes.arrayOf(PropTypes.object),
    onSave: PropTypes.func,
    openManufacturerDialog: PropTypes.func,
    openPartTypeDialog: PropTypes.func,
    partTypeCollection: PropTypes.arrayOf(PropTypes.object),
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    updateManufacturers: PropTypes.func,
    updatePartTypes: PropTypes.func,
    values: PropTypes.object
};

export function mapDispatchToProps(dispatch) {
    return {
        openManufacturerDialog(props) {
            dispatch(openDialog(dialogTypes.MANUFACTURER_DIALOG, props));
        },
        openPartTypeDialog(props) {
            dispatch(openDialog(dialogTypes.PART_TYPE_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(PartDialogForm);
