// Unpublished Work © 2022-2024 Deere & Company.

import {MINUTES_PER_HOUR} from 'Ui/constants/time-constants';

const DEFAULT_WEEKLY_WORK_HOURS = 40;
const OVERTIME_WARNING_MULTIPLIER = 0.8;

function getOvertimeStatus({minutesWorkedForWeek}, overtimeReachedThreshold, overtimeWarningThreshold) {
    if (!minutesWorkedForWeek) {
        return 'ok';
    }

    if (minutesWorkedForWeek > overtimeReachedThreshold) {
        return 'danger';
    }

    if (minutesWorkedForWeek > overtimeWarningThreshold) {
        return 'warning';
    }

    return 'ok';
}

function getOvertimeThresholds(weeklyWorkHours, dailyWorkHours) {
    if (weeklyWorkHours && dailyWorkHours) {
        const weeklyWorkHoursNum = Number(weeklyWorkHours);

        return {
            overtimeReachedThreshold: weeklyWorkHoursNum * MINUTES_PER_HOUR,
            overtimeWarningThreshold: (weeklyWorkHoursNum - Number(dailyWorkHours)) * MINUTES_PER_HOUR
        };
    }

    const overtimeReachedThreshold = DEFAULT_WEEKLY_WORK_HOURS * MINUTES_PER_HOUR;

    return {
        overtimeReachedThreshold,
        overtimeWarningThreshold: overtimeReachedThreshold * OVERTIME_WARNING_MULTIPLIER
    };
}

export {
    getOvertimeStatus,
    getOvertimeThresholds
};
