// Unpublished Work © 2022-2024 Deere & Company.

const ONLINK_WORKBOARD_DETAILS = 'ONLINK_WORKBOARD_DETAILS';
const ONLINK_CUTS_AND_DIRECTIONS = 'ONLINK_CUTS_AND_DIRECTIONS';
const ONLINK_SELECT_JOBS = 'ONLINK_SELECT_JOBS';
const ONLINK_SELECT_OPERATORS = 'ONLINK_SELECT_OPERATORS';
const ASSIGNMENTS = 'ASSIGNMENTS';
const SUMMARY = 'SUMMARY';

export const WORKBOARD_STEPS_BY_JOB = [
    ONLINK_WORKBOARD_DETAILS,
    ONLINK_CUTS_AND_DIRECTIONS,
    ONLINK_SELECT_JOBS,
    ASSIGNMENTS,
    SUMMARY
];

export const WORKBOARD_STEPS_BY_USER = [
    ONLINK_WORKBOARD_DETAILS,
    ONLINK_CUTS_AND_DIRECTIONS,
    ONLINK_SELECT_OPERATORS,
    ASSIGNMENTS,
    SUMMARY
];

export const WORKBOARD_STEP = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4
};
