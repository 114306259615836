// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import FilterComponent from 'Ui/features/onequip/parts/inventory/filter-component';
import PartsCatalogLink from 'Ui/components/common/data-table/parts-catalog-link';
import {getPartsFromInventoryBins, getParts, deletePartBin} from 'Services/inventory-service';
import {getInventories} from 'Services/membership-service';
import {closeDialog, openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {alphaNumericCompare, numericCompare} from 'Ui/models/maintenance';
import {getHierarchy} from 'OnEquip/equipment/utils/membership-hierarchy-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {getCurrencySymbol, formatNumber} from 'Utils/unit-conversion-utils';
import {CATEGORY, ACTIONS, LABELS} from 'Utils/analytics-utils';
import {getActionColumn} from 'Ui/features/onequip/parts/inventory/inventory-actions-column';
import dialogTypes from 'Ui/components/common/dialog-types';
import {THRESHOLD_CLASSNAMES} from 'Ui/constants/threshold-constants';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {
    VIEW_FINANCIAL_DATA,
    EDIT_PARTS,
    MANAGE_PARTS
} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

async function fetchInventoryParts(isMounted, setLoading, setPartsData, setMembershipHierarchy, superUser, appUserMembershipId) {
    setLoading(true);

    const [
        {inventories},
        hierarchy,
        {parts}
    ] = await Promise.all([
        getInventories(),
        getHierarchy(superUser, appUserMembershipId),
        getParts()
    ]);

    const availablePartsData = await getPartsFromInventoryBins(inventories);

    if (isMounted) {
        const formattedAvailableParts = availablePartsData.map((part) => {
            const formattedQuantityOrdered = part.quantityOrdered ? formatNumber(part.quantityOrdered) : '-';
            const formattedQuantityReserved = part.quantityReserved ? formatNumber(part.quantityReserved) : '-';

            return {
                ...part,
                formattedInOutValue: `${formattedQuantityOrdered}/${formattedQuantityReserved}`,
                formattedTotalCost: formatNumber(part.totalCost, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }),
                formattedStock: formatNumber(part.quantity),
                stock: part.quantity
            };
        });

        const filteredParts = parts.filter((part) => Boolean(part.name));

        setPartsData({
            inventories,
            parts: filteredParts,
            formattedPartsFromInventoryBins: formattedAvailableParts
        });
        setMembershipHierarchy(hierarchy);
        setLoading(false);
    }
}

function getColumns(
    translations,
    membership,
    featureToggles,
    membershipHierarchy,
    partsData,
    openTransferInventoryDialog,
    openPullInventoryDialog,
    openStockInventoryDialog,
    dialogOnClose,
    openPartBinDialog,
    currencySymbol
) {
    return [
        {
            Header: translations.TYPE,
            accessor: 'partType',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.NAME,
            accessor: 'name',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                return (
                    <EditLink
                        onClick={() => {
                            openPartBinDialog({
                                part: row.original,
                                translations,
                                updateInventoryData: dialogOnClose
                            });
                        }}
                    >
                        {row.original.name}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_LOCATION,
            accessor: 'binLocation',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.MANUFACTURER,
            accessor: 'manufacturerName',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.PART_NUMBER,
            accessor: 'partNumber',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                return (
                    <PartsCatalogLink
                        analyticsEventData={{
                            category: CATEGORY.PARTS_CATALOG,
                            label: LABELS.PART_NUMBER,
                            action: ACTIONS.PARTS_INVENTORY,
                            value: row.original.partNumber
                        }}
                        manufacturerId={row.original.manufacturerId}
                        url={`https://partscatalog.deere.com/jdrc/partdetails/partnum/${row.original.partNumber}`}
                        value={row.original.partNumber}
                    />
                );
            }
        },
        {
            Header: `${translations.VALUE} (${currencySymbol})`,
            accessor(row) {
                return row;
            },
            id: 'formattedTotalCost',
            className: 'right-aligned',
            width: 150,
            headerClassName: 'right-aligned',
            sortMethod(a, b) {
                return numericCompare(a.totalCost, b.totalCost);
            },
            Cell(row) {
                return row.original.formattedTotalCost;
            },
            requiredMyJdPermissions: VIEW_FINANCIAL_DATA
        },
        {
            Header: translations.ONLINK_STOCK,
            accessor(row) {
                return row;
            },
            id: 'formattedStock',
            width: 90,
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod(a, b) {
                return numericCompare(a.quantity, b.quantity);
            },
            Cell(row) {
                const part = row.original;

                let colorClass = '';

                if (part.thresholdLevel) {
                    if (part.quantity === 0) {
                        colorClass = THRESHOLD_CLASSNAMES.extremeThresholdText;
                    } else if (part.quantity < part.thresholdLevel) {
                        colorClass = THRESHOLD_CLASSNAMES.warningThresholdText;
                    } else {
                        colorClass = THRESHOLD_CLASSNAMES.normalThresholdText;
                    }
                }

                return (
                    <span className={colorClass}>{part.formattedStock}</span>
                );
            }
        },
        {
            Header: `${translations.ONLINK_IN}/${translations.ONLINK_OUT}`,
            accessor: 'formattedInOutValue',
            width: 100,
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortable: false
        },
        {
            accessor: 'actions',
            resizable: false,
            sortable: false,
            width: 78,
            Cell(row) {
                return getActionColumn(
                    row,
                    openTransferInventoryDialog,
                    openPullInventoryDialog,
                    openStockInventoryDialog,
                    membershipHierarchy,
                    partsData,
                    dialogOnClose
                );
            },
            requiredMyJdPermissions: EDIT_PARTS
        }
    ];
}

function Inventory(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        appUserMembershipId,
        featureToggles,
        membership,
        myJdPermissions,
        openPullInventoryDialog,
        openStockInventoryDialog,
        openTransferInventoryDialog,
        openPartBinDialog,
        superUser,
        translations
    } = props;

    const [partsData, setPartsData] = React.useState({
        inventories: [],
        parts: [],
        formattedPartsFromInventoryBins: []
    });
    const [loading, setLoading] = React.useState(true);
    const [membershipHierarchy, setMembershipHierarchy] = React.useState({});

    const currencySymbol = getCurrencySymbol({
        currencyPreference: membership.currencyPreference,
        featureToggles
    });

    React.useEffect(() => fetchEffectData(async (isMounted) =>
        fetchInventoryParts(
            isMounted(),
            setLoading,
            setPartsData,
            setMembershipHierarchy,
            superUser,
            appUserMembershipId
        )),
    [membership.membershipId]
    );

    const dialogOnClose = () => fetchInventoryParts(
        true,
        setLoading,
        setPartsData,
        setMembershipHierarchy,
        superUser,
        appUserMembershipId
    );

    function deletePartBinHandler(partBin) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_PART_BIN?.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_PART_BIN
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    await deletePartBin(partBin.partBinId);

                    await dialogOnClose();
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.ONLINK_PART_BIN?.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    const columns = getColumns(
        translations,
        membership,
        featureToggles,
        membershipHierarchy,
        partsData,
        openTransferInventoryDialog,
        openPullInventoryDialog,
        openStockInventoryDialog,
        dialogOnClose,
        openPartBinDialog,
        currencySymbol
    );

    const userAuth = {
        myJdPermissions,
        isMigrated: membership.isMigrated
    };

    return (
        <DataTable
            columns={columns}
            defaultSorted={[{
                desc: false,
                id: 'partType'
            }, {
                desc: false,
                id: 'name'
            }]}
            deleteFilter={(partBin) => partBin.quantity <= 0}
            deleteHandler={deletePartBinHandler}
            filterComponent={
                <FilterComponent
                    currencySymbol={currencySymbol}
                    dialogOnClose={dialogOnClose}
                    featureToggles={featureToggles}
                    isMigrated={membership.isMigrated}
                    loading={loading}
                    membershipHierarchy={membershipHierarchy}
                    openPullInventoryDialog={openPullInventoryDialog}
                    openStockInventoryDialog={openStockInventoryDialog}
                    openTransferInventoryDialog={openTransferInventoryDialog}
                    partsData={partsData}
                    translations={translations}
                />
            }
            loading={loading}
            rows={partsData.formattedPartsFromInventoryBins}
            searchable={true}
            showDeleteColumn={isAuthorized({
                myJdPermissions: MANAGE_PARTS
            }, userAuth)}
            showPagination={true}
            translations={translations}
        />
    );
}

Inventory.propTypes = {
    addToast: PropTypes.func,
    appUserMembershipId: PropTypes.string,
    closeConfirmation: PropTypes.func,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openPartBinDialog: PropTypes.func,
    openPullInventoryDialog: PropTypes.func,
    openStockInventoryDialog: PropTypes.func,
    openTransferInventoryDialog: PropTypes.func,
    superUser: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appUserMembershipId: state.account.membershipId,
        membership: state.membership,
        myJdPermissions: state.account.myJdPermissions,
        superUser: state.account.superUser,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openPartBinDialog(props) {
            dispatch(openReduxDialog(dialogTypes.PART_BIN_DIALOG, props));
        },
        openTransferInventoryDialog(props) {
            dispatch(openReduxDialog(dialogTypes.TRANSFER_INVENTORY_DIALOG, props));
        },
        openPullInventoryDialog(props) {
            dispatch(openReduxDialog(dialogTypes.PULL_INVENTORY_DIALOG, props));
        },
        openStockInventoryDialog(props) {
            dispatch(openReduxDialog(dialogTypes.STOCK_INVENTORY_DIALOG, props));
        },
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openReduxDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
