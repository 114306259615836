// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {getValueWithThreshold} from 'Utils/threshold-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {DEGREES_F} from 'Common/constants/data-unit-constants';

function CurrentTemperature(props) {
    const {
        featureToggles,
        iconCode,
        phrase22char,
        temp,
        thresholds,
        translations,
        unitOfMeasure
    } = props;

    const airTempThreshold = thresholds.find((threshold) => threshold.dataType === 'air_temp');

    const conversionConfig = {
        dataUnit: DEGREES_F,
        featureToggles,
        translations,
        unitOfMeasure
    };

    const convertedTempt = convertToFormattedUoM(temp, conversionConfig);

    const tempSpan = getValueWithThreshold(airTempThreshold, temp, convertedTempt);

    const weatherIcon = getWeatherIcon(iconCode);

    return (
        <div className='current-temperature'>
            <img
                alt={phrase22char}
                className='dashboard-panel-icon'
                src={weatherIcon}
            />
            <div className='dashboard-panel-data'>
                {tempSpan}
                <div className='current-description'>
                    {phrase22char}
                </div>
            </div>
        </div>
    );
}

CurrentTemperature.propTypes = {
    featureToggles: PropTypes.featureToggles,
    iconCode: PropTypes.stringOrNumber,
    phrase22char: PropTypes.string,
    temp: PropTypes.number,
    thresholds: PropTypes.thresholds,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string
};

export default CurrentTemperature;
