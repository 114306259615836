// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {useMediaQuery} from 'react-responsive';

export const MOBILE_MEDIA_QUERY = {
    prop: 'isMobile',
    query: '(max-width: 767px)'
};

export const TABLET_MEDIA_QUERY = {
    prop: 'isTablet',
    query: '(max-width: 1200px)'
};

function getMediaQueryProps(mediaQueryProps, mediaQuery) {
    const {
        device,
        onChange,
        prop,
        ...settings
    } = mediaQuery;

    mediaQueryProps[prop] = useMediaQuery(settings, device, onChange);

    return mediaQueryProps;
}

export default function MediaQuery(...mediaQueries) {
    return (Component) => {
        const mediaQuery = (props) => (
            <Component
                {...mediaQueries.reduce(getMediaQueryProps, {})}
                {...props}
            />
        );

        mediaQuery.MediaQueryComponent = Component;

        return mediaQuery;
    };
}
