// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {updatePartRequest} from 'Services/parts-service';
import {closeDialog} from 'Store/actions/dialogs';
import {useSave} from 'Ui/react-hooks/use-save';
import {useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS} from 'Utils/time-utils';
import dialogTypes from 'Ui/components/common/dialog-types';
import {sortBy} from 'lodash';
import {formatDistributorItem} from 'Utils/parts-utils';
import moment from 'moment';

function PurchaseOrderDialog(props) {
    const {
        availableDistributors,
        closePurchaseOrderDialog,
        invalidInputs,
        partRequest,
        setValid,
        translations,
        updatePartRequestData
    } = props;

    const distributorItems = useDeepMemo(() => {
        const mappedDistributorItems = availableDistributors.map((distributor) => ({
            id: distributor.membershipId,
            subtitle: formatDistributorItem(distributor),
            title: distributor.name
        }));

        return sortBy(mappedDistributorItems, 'title');
    }, [availableDistributors]);

    const [distributorId, setDistributorId] = React.useState(() => {
        const partRequestDistributor = partRequest.distributorId && distributorItems.find((distributor) => distributor.id === partRequest.distributorId);
        const distributor = partRequestDistributor ? partRequestDistributor : distributorItems[0];

        return distributor ? distributor.id : null;
    });

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const partRequestData = {
            dateSelect: moment().format(DATE_FORMATS.day),
            distributorId,
            status: 'purchaseOrder'
        };

        await updatePartRequest(partRequest.partRequestId, partRequestData);

        updatePartRequestData();
        closePurchaseOrderDialog();
    }, {
        invalidInputs
    });

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closePurchaseOrderDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={translations.ONLINK_ADD_TO_PURCHASE_ORDER}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationMultiSelect
                    autoFocus={true}
                    component={MultiSelect}
                    disableSorting={true}
                    items={distributorItems}
                    label={partRequest.name}
                    labels={{}}
                    name='distributorId'
                    onChange={(selectedIds) => setDistributorId(selectedIds[0])}
                    selectedIds={[distributorId]}
                    setValid={setValid}
                    single={true}
                />
            </form>
        </FormDialog>
    );
}

PurchaseOrderDialog.propTypes = {
    availableDistributors: PropTypes.arrayOf(PropTypes.object),
    closePurchaseOrderDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    partRequest: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.object,
    updatePartRequestData: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        closePurchaseOrderDialog() {
            dispatch(closeDialog(dialogTypes.PURCHASE_ORDER_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(PurchaseOrderDialog));
