// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';

function EditableSettings(props) {
    const {
        component: Component,
        editableComponent: EditableComponent,
        ...otherProps
    } = props;

    const [isEditing, setIsEditing] = React.useState(false);
    const [abstractState, setAbstractState] = React.useState({});

    function setData(name, data) {
        setAbstractState((prevState) => ({
            ...prevState,
            [name]: data
        }));
    }

    const componentClassName = clsx('settings-container', {
        'account-settings': otherProps.id === 'Account',
        hidden: isEditing
    });

    return (
        <>
            <div className={componentClassName}>
                <Component
                    {...otherProps}
                    {...abstractState}
                    isEditing={isEditing}
                    setData={setData}
                    setIsEditing={() => setIsEditing(true)}
                />
            </div>
            {
                isEditing &&
                <div className='settings-container'>
                    <EditableComponent
                        {...otherProps}
                        {...abstractState}
                        setIsEditing={() => setIsEditing(false)}
                    />
                </div>
            }
        </>
    );
}

EditableSettings.propTypes = {
    component: PropTypes.elementType,
    editableComponent: PropTypes.elementType
};

export default EditableSettings;
