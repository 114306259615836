// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {BasicDialog} from '@deere/basic-dialog';
import Stack from '@mui/material/Stack';

export function ContactUsDialog(props) {
    const {
        byPhoneUrl,
        closeDialog,
        contactUsUrl,
        translations
    } = props;

    return (
        <BasicDialog
            className='contact-us-dialog'
            closeHandler={closeDialog}
            show={true}
            title={translations.ONLINK_CONTACT_US_TODAY}
        >
            <Stack
                alignItems='center'
                className={'contact-us-dialog-body'}
                direction='column'
                fontSize={18}
                fontWeight='bold'
                height={224}
                justifyContent='space-around'
                textAlign='center'
            >
                <div>{translations.ONLINK_CONTACT_METHODS}</div>
                <a
                    href={byPhoneUrl}
                    rel={'noreferrer'}
                    target={'_blank'}
                >
                    {translations.ONLINK_BY_PHONE}
                </a>
                <div>{`--${translations.Or.toLowerCase()}--`}</div>
                <a
                    href={contactUsUrl}
                    rel={'noreferrer'}
                    target={'_blank'}
                >{translations.ONLINK_WEB_FORM}</a>
            </Stack>
        </BasicDialog>
    );
}

ContactUsDialog.propTypes = {
    byPhoneUrl: PropTypes.string,
    closeDialog: PropTypes.func,
    contactUsUrl: PropTypes.string,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => {
            dispatch(closeDialog(dialogTypes.CONTACT_US_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(ContactUsDialog);
