// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {joinClassNames} from 'Utils/html-utils';
import PropTypes from 'Utils/prop-type-utils';

function PanelDateLabel(props) {
    return (
        <div className={joinClassNames('panel-data-date', props.className)}>{props.date}</div>
    );
}

PanelDateLabel.propTypes = {
    className: PropTypes.string,
    date: PropTypes.string
};

export default PanelDateLabel;
