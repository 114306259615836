// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';

function createMowingPatterns(mowingPatternCompositeImages, translations, tileOpacityOn) {
    return mowingPatternCompositeImages.map((mowingPattern) => {
        const area = translations[mowingPattern.area] || mowingPattern.area;
        const cutStyle = translations[mowingPattern.cuttingStyle] || mowingPattern.cuttingStyle;
        const direction = translations[mowingPattern.direction] || mowingPattern.direction;
        const direction2 = mowingPattern.direction2 ? ` (${mowingPattern.direction2})` : '';

        return (
            <div
                className='mowing-pattern-section'
                key={mowingPattern.equipmentAreaId}
            >
                <div
                    className={clsx('mowing-pattern-text-section', {
                        'opacity-on': tileOpacityOn,
                        'opacity-off': !tileOpacityOn
                    })}
                >
                    <div className='work-item-details-text-line medium-section-title'>
                        {area}
                    </div>
                    <div className='work-item-details-text-line bold-body-text-section-title'>
                        {`${cutStyle} - ${direction}${direction2}`}
                    </div>
                </div>
                <img
                    className='mowing-pattern-image'
                    src={mowingPattern.imgSrc}
                />
            </div>
        );
    });
}

function MowingDirections(props) {
    const {
        mowingPatternCompositeImages,
        translations,
        tileOpacityOn
    } = props;

    return createMowingPatterns(mowingPatternCompositeImages, translations, tileOpacityOn);
}

MowingDirections.propTypes = {
    setLoading: PropTypes.func,
    tileOpacityOn: PropTypes.bool,
    translations: PropTypes.translations
};

export default MowingDirections;
