// Unpublished Work © 2021-2024 Deere & Company.

import {EMAIL_ALREADY_EXISTS, EMAIL_FORMAT_INCORRECT, REQUEST_LIMIT_EXCEEDED, COURSE_NAME_TO_ID_MAP_EXISTS, SERVICE_IN_PROCESS} from 'Common/constants/errors';

function translateErrorMessage(error, translations) {
    switch (error) {
        case EMAIL_ALREADY_EXISTS:
            return translations.ONLINK_EMAIL_UNIQUE;
        case EMAIL_FORMAT_INCORRECT:
            return translations.ONLINK_EMAIL_NOT_FORMATTED_CORRECTLY;
        case REQUEST_LIMIT_EXCEEDED:
            return translations.ONLINK_REQUEST_LIMIT_EXCEEDED;
        case COURSE_NAME_TO_ID_MAP_EXISTS:
            return translations.ONLINK_COURSE_NAME_TO_ID_MAP_EXISTS;
        case SERVICE_IN_PROCESS:
            return translations.ONLINK_SERVICE_IN_PROCESS_MESSAGE;
        default:
            return error;
    }
}

export {
    translateErrorMessage
};
