// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import DetailsBlock from 'OnLabor/my-work/my-work-tile/details-block';
import {useDeepMemo} from 'Utils/react-utils';
import {sortBy} from 'lodash';
import {formatRouteWithCustomHoles} from 'Ui/models/job-model';
import {createPpeIconMap} from 'Utils/icon-utils';

const ICON_STYLE = {
    style: {
        height: '32px',
        width: '32px',
        marginRight: '5px'
    }
};

const PPE_ICON_MAP = createPpeIconMap(ICON_STYLE);

function JobDetails(props) {
    const {
        equipment,
        note,
        route,
        routeCustomDefn,
        translations,
        ppeList
    } = props;

    const sortedEquipment = useDeepMemo(() => sortBy(equipment, 'equipmentName'), [equipment]);

    return (
        <div className='job-details'>
            {
                note &&
                <DetailsBlock label={translations.NOTE}>
                    {note}
                </DetailsBlock>
            }
            {
                route &&
                <DetailsBlock label={translations.ONLINK_ROUTE}>
                    {formatRouteWithCustomHoles(route, routeCustomDefn, translations)}
                </DetailsBlock>
            }
            {
                equipment &&
                <DetailsBlock label={translations.IWP_EQUIPMENT_LABEL}>
                    {
                        sortedEquipment.map((equipmentItem) => {
                            const description = `${equipmentItem.manufacturerName} ${equipmentItem.modelName}`;

                            return (
                                <div key={equipmentItem.equipmentId}>
                                    {`${equipmentItem.equipmentName} (${description})`}
                                </div>
                            );
                        })
                    }
                </DetailsBlock>
            }
            {
                ppeList &&
                <DetailsBlock>
                    {
                        ppeList?.map((ppeName) => PPE_ICON_MAP[ppeName])
                    }
                </DetailsBlock>
            }
        </div>
    );
}

JobDetails.propTypes = {
    equipment: PropTypes.arrayOf(PropTypes.object),
    note: PropTypes.string,
    ppeList: PropTypes.arrayOf(PropTypes.string),
    route: PropTypes.string,
    routeCustomDefn: PropTypes.arrayOf(PropTypes.string),
    translations: PropTypes.translations
};

export default JobDetails;
