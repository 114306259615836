// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Stack from '@mui/material/Stack';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {useDeepMemo} from 'Utils/react-utils';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import {
    BACKTRACK_CUT,
    DOUBLE_CUT,
    EXCLUDED_MOWING_DIRECTIONS_FOR_CUT,
    SINGLE_CUT
} from 'OnLabor/workboard/constants/cutting-styles';
import {MOWING_DIRECTION_REMAPPING} from 'OnLabor/workboard/constants/mowing-patterns';
import {TWELVE_TO_SIX} from 'OnLabor/workboard/constants/mowing-directions';
import Typography from '@mui/material/Typography';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

const CUTS_WITH_2_INPUTS = [BACKTRACK_CUT, DOUBLE_CUT];
const SHARED_MULTISELECT_PROPS = {
    disableSorting: true,
    noSearch: true,
    single: true
};

function getMowingDirection(mowingDirection) {
    return MOWING_DIRECTION_REMAPPING[mowingDirection] || mowingDirection;
}

function getExcludedMowingDirections(cuttingStyle) {
    return EXCLUDED_MOWING_DIRECTIONS_FOR_CUT[cuttingStyle] || [];
}

function resetSelections(cuttingStyle, noneItemId, onChange, firstDirectionId) {
    const noneItemCuttingStyle = cuttingStyle === noneItemId;

    if (!CUTS_WITH_2_INPUTS.includes(cuttingStyle) || noneItemCuttingStyle) {
        onChange('direction2', null);
    }

    if (noneItemCuttingStyle) {
        onChange('direction', null);
        onChange('cleanupPass', null);
    } else {
        onChange('direction', firstDirectionId);
    }
}

function CutDirectionInput(props) {
    const {
        label,
        membership,
        mowingPattern,
        onChange,
        translations,
        readOnly
    } = props;

    const direction1 = getMowingDirection(mowingPattern?.direction);
    const direction2 = getMowingDirection(mowingPattern?.direction2);
    const excludedMowingDirections = getExcludedMowingDirections(mowingPattern?.cuttingStyle);
    const capitalizedNone = React.useMemo(() => capitalizeFirstLetter(translations.NONE), [translations.NONE]);

    const noneItem = {
        id: undefined,
        title: capitalizedNone
    };

    const filteredMowingDirections = useDeepMemo(() => membership?.mowingDirections.filter((mowingDirection) => {
        const {
            areasApplied,
            direction,
            inactive
        } = mowingDirection;

        return direction === direction1 || direction === direction2 || !inactive && areasApplied.includes(mowingPattern?.area);
    }, []), [direction1, direction2, membership?.mowingDirections, mowingPattern?.area]);

    const directionItems = useDeepMemo(() => filteredMowingDirections.reduce((mowingDirectionItems, mowingDirection) => {
        const {direction} = mowingDirection;

        if (direction === direction1 || direction === direction2 || !excludedMowingDirections.includes(direction)) {
            return mowingDirectionItems.concat({
                id: direction,
                title: translations[direction] || direction
            });
        }

        return mowingDirectionItems;
    }, []), [direction1, direction2, excludedMowingDirections, filteredMowingDirections, translations]);
    const [cuttingStyleItems, cleanupPassItems] = useDeepMemo(() => {
        const cutItems = [
            noneItem,
            {
                id: SINGLE_CUT,
                title: translations.ONLINK_SINGLE_CUT_STYLE
            },
            {
                id: DOUBLE_CUT,
                title: translations.ONLINK_DOUBLE_CUT_STYLE
            },
            {
                id: BACKTRACK_CUT,
                title: translations.ONLINK_BACKTRACK_CUT_STYLE
            }
        ];

        if (!cutItems.some(({id}) => mowingPattern?.cuttingStyle === id)) {
            cutItems.push({
                id: mowingPattern?.cuttingStyle,
                title: translations[mowingPattern?.cuttingStyle] || mowingPattern?.cuttingStyle
            });
        }

        return [
            cutItems,
            [
                {
                    id: undefined,
                    title: translations.ONLINK_NO_CLEANUP_PASS
                },
                {
                    id: 'Tee to Green',
                    title: translations.ONLINK_TEE_TO_GREEN_CLEANUP_PASS
                },
                {
                    id: 'Green to Tee',
                    title: translations.ONLINK_GREEN_TO_TEE_CLEANUP_PASS
                },
                {
                    id: 'Contour',
                    title: translations.ONLINK_CONTOUR_CLEANUP_PASS
                },
                {
                    id: 'Clockwise',
                    title: translations.ONLINK_CLOCKWISE_CLEANUP_PASS
                },
                {
                    id: 'Counter Clockwise',
                    title: translations.ONLINK_COUNTER_CLOCKWISE_CLEANUP_PASS
                }
            ]
        ];
    }, [mowingPattern?.cuttingStyle, translations]);

    return (
        <Stack
            className={'cut-direction-stack'}
            direction='column'
            marginBottom={2}
            spacing={2}
        >
            <div className='title-1'>
                {label}
            </div>
            {
                readOnly && !mowingPattern?.cuttingStyle ?
                    <Typography>{capitalizeFirstLetter(props.translations.NONE)}</Typography> :
                    <>
                        <ReadOnlyWrapperComponent
                            readOnly={readOnly}
                            readOnlyProps={{
                                value: translations[mowingPattern?.cuttingStyle] || mowingPattern?.cuttingStyle
                            }}
                            wrappedComponent={MultiSelect}
                            wrappedProps={{
                                ...SHARED_MULTISELECT_PROPS,
                                items: cuttingStyleItems,
                                labels: {
                                    placeholder: capitalizedNone
                                },
                                onChange: ([cuttingStyle]) => {
                                    const excludedMowingDirectionsForCut = getExcludedMowingDirections(cuttingStyle);

                                    if (excludedMowingDirectionsForCut.includes(direction1)) {
                                        const resolvedMowingDirection = filteredMowingDirections
                                            ?.find(({direction}) => !excludedMowingDirectionsForCut.includes(direction));

                                        onChange('direction', resolvedMowingDirection?.direction);
                                    }

                                    const firstDirectionId = directionItems.some(({id}) => id === TWELVE_TO_SIX) ?
                                        TWELVE_TO_SIX :
                                        directionItems[0]?.id;

                                    resetSelections(cuttingStyle, noneItem.id, onChange, firstDirectionId);

                                    onChange('cuttingStyle', cuttingStyle);
                                },
                                selectedIds: [mowingPattern?.cuttingStyle]
                            }}
                        />
                        {
                            mowingPattern?.cuttingStyle &&
                            <>
                                {
                                    directionItems?.length > 0 &&
                                    <>
                                        <div>
                                            <ReadOnlyWrapperComponent
                                                readOnly={readOnly}
                                                readOnlyProps={{
                                                    value: translations[direction1] || direction1
                                                }}
                                                wrappedComponent={MultiSelect}
                                                wrappedProps={{
                                                    ...SHARED_MULTISELECT_PROPS,
                                                    items: directionItems,
                                                    labels: {
                                                        placeholder: capitalizedNone
                                                    },
                                                    onChange: ([id]) => onChange('direction', id),
                                                    selectedIds: [direction1]
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {
                                                CUTS_WITH_2_INPUTS.includes(mowingPattern?.cuttingStyle) &&
                                                <ReadOnlyWrapperComponent
                                                    readOnly={readOnly}
                                                    readOnlyProps={{
                                                        value: translations[direction2] || direction2
                                                    }}
                                                    wrappedComponent={MultiSelect}
                                                    wrappedProps={{
                                                        ...SHARED_MULTISELECT_PROPS,
                                                        items: [noneItem, ...directionItems],
                                                        labels: {
                                                            placeholder: capitalizedNone
                                                        },
                                                        onChange: ([id]) => onChange('direction2', id),
                                                        selectedIds: [direction2]
                                                    }}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                                <div>
                                    <ReadOnlyWrapperComponent
                                        readOnly={readOnly}
                                        readOnlyProps={{
                                            value: translations[mowingPattern?.cleanupPass] || mowingPattern?.cleanupPass
                                        }}
                                        wrappedComponent={MultiSelect}
                                        wrappedProps={{
                                            ...SHARED_MULTISELECT_PROPS,
                                            items: cleanupPassItems,
                                            labels: {
                                                placeholder: translations.ONLINK_NO_CLEANUP_PASS
                                            },
                                            onChange: ([id]) => onChange('cleanupPass', id),
                                            selectedIds: [mowingPattern?.cleanupPass]
                                        }}
                                    />
                                </div>
                            </>
                        }
                    </>
            }
        </Stack>
    );
}

CutDirectionInput.propTypes = {
    label: PropTypes.string,
    membership: PropTypes.membership,
    mowingPattern: PropTypes.object,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export default connect(mapStateToProps)(CutDirectionInput);
