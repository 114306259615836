// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function WidgetBox(props) {
    const {
        children,
        title,
        ...otherProps
    } = props;

    return (
        <Stack
            {...otherProps}
            bgcolor='background.default'
            borderRadius='5px'
            direction='column'
            height='400px'
            justifyContent='flex-start'
            margin={0}
            padding='8px'
            width='340px'
        >
            <Typography
                fontWeight='bold'
                textAlign='center'
                variant='h6'
            >
                {title}
            </Typography>
            {children}
        </Stack>
    );
}

WidgetBox.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string
};

export default WidgetBox;
