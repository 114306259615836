// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconLoading} from '@deere/icons';

function LoadingIcon(props) {
    const iconLoadingStyle = {
        style: {
            height: props.size,
            width: props.size
        }
    };

    if (props.fill) {
        iconLoadingStyle.style.fill = props.fill;
    }

    return (
        <div className={props.className}>
            <IconLoading iconLoading={iconLoadingStyle}/>
        </div>
    );
}

LoadingIcon.propTypes = {
    className: PropTypes.string.isRequired,
    fill: PropTypes.string,
    size: PropTypes.string.isRequired
};

export default LoadingIcon;
