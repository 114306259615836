// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import Typography from '@mui/material/Typography';
import {DANGER, OK, WARNING} from 'Ui/constants/status/categories';

function getTypographyProps(className, color) {
    return color ? {
        className,
        color
    } : {
        className
    };
}

function StatusText(props) {
    const {
        dangerColor,
        isMobile,
        okColor,
        status,
        warningColor,
        ...otherProps
    } = props;

    const typographyProps = React.useMemo(() => {
        switch (status) {
            case OK:
            case 'green':
                return getTypographyProps(OK, okColor);
            case WARNING:
            case 'orange':
                return getTypographyProps(WARNING, warningColor);
            case DANGER:
            case 'red':
                return getTypographyProps(DANGER, dangerColor);
            default:
                return {};
        }
    }, [dangerColor, okColor, status, warningColor]);

    const maxWidthProp = React.useMemo(() => isMobile ? {
        maxWidth: '70px'
    } : {}, [isMobile]);

    return (
        <Typography
            {...otherProps}
            {...typographyProps}
            {...maxWidthProp}
            component='div'
        />
    );
}

StatusText.propTypes = {
    dangerColor: PropTypes.string,
    isMobile: PropTypes.bool,
    okColor: PropTypes.string,
    status: PropTypes.oneOf([DANGER, 'green', OK, 'orange', 'red', WARNING]),
    warningColor: PropTypes.string
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(StatusText);
