// Unpublished Work © 2020-2024 Deere & Company.

import {VIEW_COURSE_MEASUREMENT_DATA} from 'Common/constants/business-activities';
import CurrentDetails from 'Ui/components/drill-ins/weather-course/current-details/current-details';
import Forecast from 'Ui/components/drill-ins/weather-course/forecast/forecast';

const WEATHER_1_ID = 'b0a794f1-c280-46c3-8c89-babdb94e9003';
const WEATHER_2_ID = '78954d10-a9b7-4a63-9a8c-74fde762ff25';
const WEATHER_3_ID = 'be113916-b88c-41c8-acb5-213562d6c20a';
const WEATHER_5_ID = 'debb2d4f-5de3-4ef4-bcef-2606a2e685ef';
const WEATHER_6_ID = '55e3f2e7-94f6-42dc-870a-5eccf77d26b3';

const TWC_URL = 'dataSources/TWC';
const WEATHER_URL = 'panelTypes/weather_course';

import {graphRedirect, weatherGraphRedirect} from 'Utils/redirect-utils';

export default {
    [WEATHER_1_ID]: {
        component: CurrentDetails,
        name: 'weather_1',
        tileId: WEATHER_1_ID,
        url: [TWC_URL, WEATHER_URL]
    },
    [WEATHER_2_ID]: {
        component: Forecast,
        name: 'weather_2',
        onClick: weatherGraphRedirect,
        tileId: WEATHER_2_ID,
        url: TWC_URL
    },
    [WEATHER_3_ID]: {
        columnCount: 2,
        data: [
            'moisture',
            'temp',
            'ec'
        ],
        dateSelect: 'month',
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'weather_3',
        onClick: graphRedirect,
        tileId: WEATHER_3_ID,
        url: WEATHER_URL
    },
    [WEATHER_5_ID]: {
        data: [
            'green_speed',
            'firmness',
            'firmness_gravities',
            'firmness_f355_equivalents'
        ],
        dateSelect: 'month',
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'weather_5',
        onClick: graphRedirect,
        tileId: WEATHER_5_ID,
        url: WEATHER_URL
    },
    [WEATHER_6_ID]: {
        data: [
            'green_speed',
            'firmness'
        ],
        dateSelect: 'month',
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'weather_6',
        onClick: graphRedirect,
        tileId: WEATHER_6_ID,
        url: WEATHER_URL
    }
};
