// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {getAreaOptions, getHoleOptions, validateDate, getLocalTimeFormats} from 'Ui/components/manual-data/manual-data-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {getLanguagePreference, getPreferredDataTypeForm} from 'Utils/unit-conversion-utils';
import {MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {FIRMNESS, FIRMNESS_F355_EQUIVALENTS, FIRMNESS_GRAVITIES} from 'Common/constants/data-group-constants';
import {getUOMOverrideUnits} from 'Common/constants/uom-override-units';
import moment from 'moment';
import {getDataType} from 'Common/utils/unit-of-measure-utils';

function setupMemos(translations, membership, values) {
    const {
        areaOptions,
        holesOptions,
        firmnessUOM,
        overrideUnits,
        dataType,
        minimumValueMessage
    } = React.useMemo(() => {
        const holesOptions = getHoleOptions(translations);
        const areaOptions = getAreaOptions(translations);
        const overrideUnits = getUOMOverrideUnits(translations);

        const firmnessUOM = values.manualData ? values.manualData[0].data_unit : getPreferredDataTypeForm(
            membership.preferredDataTypes,
            membership.unitOfMeasure,
            [FIRMNESS, FIRMNESS_F355_EQUIVALENTS, FIRMNESS_GRAVITIES]
        );

        const minimumValueMessage = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
            '0': MIN_NUMERIC_VALUE
        });

        const dataType = getDataType(FIRMNESS, firmnessUOM);

        return {
            areaOptions,
            holesOptions,
            firmnessUOM,
            overrideUnits,
            dataType,
            minimumValueMessage
        };
    }, [membership]);

    const uomValue = React.useMemo(() => {
        if (!values.manualData) {
            return '';
        }

        return values.manualData[0].value;
    }, [values.manualData]);

    return {
        areaOptions,
        holesOptions,
        firmnessUOM,
        overrideUnits,
        uomValue,
        dataType,
        minimumValueMessage
    };
}

function FirmnessEntryForm(props) {
    const {
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const [validatedDate, setValidatedDate] = React.useState();
    const {
        areaOptions,
        holesOptions,
        firmnessUOM,
        overrideUnits,
        uomValue,
        dataType,
        minimumValueMessage
    } = setupMemos(translations, membership, values);

    React.useEffect(() => onValuesChange('holeLocation', areaOptions[0].id), []);

    React.useEffect(() => {
        setValidatedDate(validateDate(values.timeSample, setValid));
    }, [values.timeSample]);

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LLL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE_AND_TIME}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
                showTimeSelect={true}
                timeFormat={getLocalTimeFormats()}
                timeIntervals={1}
            />
            <div className='settings-group mobile'>
                <ValidationMultiSelect
                    className='mobile-required-multiselect'
                    disabled={false}
                    error={translations.REQUIRED_FIELD_TEXT}
                    hideCheckboxes={true}
                    items={holesOptions}
                    label={translations.ONLINK_HOLES}
                    multiple={false}
                    name='hole'
                    onChange={(event) => onValuesChange('hole', event[0])}
                    required={true}
                    selectedIds={[values.hole]}
                    setValid={setValid}
                    tabIndex={0}
                />
                <ValidationMultiSelect
                    className='mobile-required-multiselect'
                    disabled={false}
                    error={translations.REQUIRED_FIELD_TEXT}
                    hideCheckboxes={true}
                    items={areaOptions}
                    label={translations.AREA}
                    multiple={false}
                    name='holeLocation'
                    onChange={(event) => event[0] && onValuesChange('holeLocation', event[0])}
                    required={true}
                    selectedIds={[values.holeLocation]}
                    setValid={setValid}
                    tabIndex={0}
                />
            </div>
            <ValidationInput
                errors={{
                    rangeUnderflow: minimumValueMessage
                }}
                label={capitalizeFirstLetter(overrideUnits[firmnessUOM]?.title)}
                min={0}
                name={firmnessUOM}
                onChange={(event) => {
                    const value = event.target.value === '' ? '' : parseFloat(event.target.value);

                    onValuesChange('manualData', [{
                        'data_type': dataType,
                        'data_unit': firmnessUOM,
                        value
                    }]);
                }}
                required={true}
                setValid={setValid}
                tabIndex={0}
                type='number'
                value={uomValue}
            />
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

FirmnessEntryForm.propTypes = {
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default FirmnessEntryForm;
