// Unpublished Work © 2023-2024 Deere & Company.

import {jsPDF} from 'jspdf';
import {addDataBlock} from 'Utils/pdf';
import _ from 'lodash';
import {
    getAllWorkItems,
    getPresentationSettings
} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import {getMembershipById} from 'Services/membership-service';
import {JOBS} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {getWorkboard} from 'Services/work-board-service';
import {sortJobsBySeq} from 'Utils/workboard-utils';
import {ONLINK_CREATION_FLOW} from 'Common/constants/feature-toggles';
import {USER} from 'Ui/constants/workboard-constants';
import {
    sortByEmployeeWorkItemsBySeq,
    sortByJobsWorkItemsByUserOrder
} from 'OnLabor/workboard/utils/workboard-sort';

const FONT_SIZE = 12;
const PAGE_MARGIN = 15;

function createPDF(workboard) {
    const pdf = new jsPDF();

    pdf.setProperties({
        title: `${workboard.name} - ${workboard.startTime}`
    });
    pdf.setFontSize(FONT_SIZE);

    return pdf;
}

function getWorkItemDetails(workItem) {
    const detailItems = [];

    workItem.formattedEquipmentNames?.forEach((formattedEquipmentName) => detailItems.push(formattedEquipmentName));

    if (workItem.route) {
        detailItems.push(workItem.route);
    }

    if (workItem.note) {
        detailItems.push(workItem.note);
    }

    return detailItems;
}

function formatPDFByJob(pdf, currentPosition, workItems, translations, userOrder, creationFlow, featureToggles) {
    let workItemsByJob = Object.values(_.groupBy(workItems, 'jobId'));

    if (featureToggles[ONLINK_CREATION_FLOW] && creationFlow === USER) {
        workItemsByJob = sortByJobsWorkItemsByUserOrder(workItems, userOrder);
    }

    workItemsByJob.forEach((workItemsFromJob) => {
        const data = workItemsFromJob.map((workItem) => {
            const estHours = workItem.estHours ? ` ${workItem.estHours}` : '';

            return [
                `${workItem.appUserName}${estHours}`,
                ...getWorkItemDetails(workItem, translations)
            ];
        });

        addDataBlock(pdf, currentPosition, {
            data,
            header: workItemsFromJob[0].title,
            secondaryHeader: workItemsFromJob[0].secondaryName ? `(${workItemsFromJob[0].secondaryName})` : null,
            translations
        });
    });
}

function formatPDFByEmployee(pdf, currentPosition, workItems, translations, userOrder, creationFlow, featureToggles) {
    let workItemsByEmployee = Object.values(_.groupBy(workItems, 'appUserId'));

    if (featureToggles[ONLINK_CREATION_FLOW] && creationFlow === USER) {
        workItemsByEmployee = sortByEmployeeWorkItemsBySeq(workItemsByEmployee, userOrder);
    }

    workItemsByEmployee.forEach((workItemsFromEmployee) => {
        const data = workItemsFromEmployee.map((workItem) => {
            const estHours = workItem.estHours ? ` ${workItem.estHours}` : '';

            const items = [`${workItem.title}${estHours}`];

            if (workItem.secondaryName) {
                items.push(workItem.secondaryName);
            }

            return [
                ...items,
                ...getWorkItemDetails(workItem, translations)
            ];
        });

        addDataBlock(pdf, currentPosition, {
            data,
            header: workItemsFromEmployee[0].appUserName,
            translations
        });
    });
}

async function generateWorkboardPdf(workboardId, membershipId, translations, featureToggles) {
    const [{
        workboard, userOrder
    }, {membership}] = await Promise.all([
        getWorkboard(workboardId),
        getMembershipById(membershipId)
    ]);

    const jobs = sortJobsBySeq(workboard.jobs);

    const pdf = createPDF(workboard);

    const currentPosition = {
        x: PAGE_MARGIN,
        y: PAGE_MARGIN
    };
    const presentationSettings = getPresentationSettings(membership);

    const workItems = await getAllWorkItems(jobs, translations, presentationSettings);

    if (presentationSettings.grouping === JOBS) {
        formatPDFByJob(pdf, currentPosition, workItems, translations, userOrder, workboard.creationFlow, featureToggles);
    } else {
        formatPDFByEmployee(pdf, currentPosition, workItems, translations, userOrder, workboard.creationFlow, featureToggles);
    }

    return pdf;
}

export {
    generateWorkboardPdf
};
