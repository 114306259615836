// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import ServiceFormAuthWrapper from 'OnEquip/equipment/service-form/service-form-auth-wrapper';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function ServiceFormDialog(props) {
    const {
        closeDialog,
        equipmentId,
        onSave,
        serviceId,
        translations
    } = props;

    return (
        <ServiceFormAuthWrapper
            closeDialogHandler={closeDialog}
            equipmentId={equipmentId}
            onSaveHandler={onSave}
            serviceId={serviceId}
            translations={translations}
        />
    );
}

ServiceFormDialog.propTypes = {
    closeDialog: PropTypes.func,
    equipmentId: PropTypes.string,
    onSave: PropTypes.func,
    serviceId: PropTypes.string,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.SERVICE_FORM_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(ServiceFormDialog);
