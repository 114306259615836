// Unpublished Work © 2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';

function useEventListener(eventTarget, {
    listener,
    options = {},
    type
}, deps) {
    const listenerRef = React.useRef(listener);

    React.useEffect(() => {
        listenerRef.current = listener;
    }, deps);

    const {
        capture, once, passive
    } = options;

    React.useEffect(() => {
        if (!(eventTarget && eventTarget.addEventListener)) {
            return () => {};
        }

        const eventListener = (event) => listenerRef.current(event);
        const eventListenerOptions = {
            capture,
            once,
            passive
        };

        eventTarget.addEventListener(type, eventListener, eventListenerOptions);

        return () => {
            eventTarget.removeEventListener(type, eventListener, eventListenerOptions);
        };
    }, [capture, eventTarget, once, passive, type]);
}

export {
    useEventListener
};
