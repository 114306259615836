// Unpublished Work © 2022-2024 Deere & Company.

import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import PropTypes from 'Utils/prop-type-utils';
import React from 'react';

function ActionsColumn(props) {
    const {actions} = props;

    return (
        <div className='actions-column'>
            {
                actions.map((action, index) => {
                    const IconComponent = action.iconComponent;

                    return (
                        <OnLinkIconButton
                            className={action.buttonClassName}
                            key={index}
                            onClick={action.onClick}
                        >
                            <IconComponent {...action.iconProps}/>
                        </OnLinkIconButton>
                    );
                })
            }
        </div>
    );
}

ActionsColumn.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object)
};

export default ActionsColumn;
