// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconAdd} from '@deere/icons';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {alphaNumericCompare, dateCompare} from 'Ui/models/maintenance';
import {deleteManualData, getManualDataHistory} from 'Services/manual-data-service';
import {configForUnit} from 'Utils/data-utils';
import {getDataUnit} from 'Utils/manual-data-utils';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {forceLocalTimeZone, formatLocalizedTime} from 'Utils/time-utils';
import {convertToUoM, formatUoMValue, getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {DOLLARS, DOLLARS_PER_KILOWATT_HOUR, FEET_AND_INCHES} from 'Common/constants/data-unit-constants';
import {FUEL, FUEL_COST, GAS_COST, WATER_FLOW} from 'Common/constants/data-group-constants';
import {ADD_COURSE_MEASUREMENT_DATA, DELETE_COURSE_MEASUREMENT_DATA, EDIT_COURSE_MEASUREMENT_DATA} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

const ICON_ADD_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};
const MAX_ROW_LIST_ITEMS = 3;
const FIELD_WIDTHS = {
    [WATER_FLOW]: 175,
    [FUEL]: 140,
    DEFAULT: 160
};

function createColumnOrDefault(shouldCreate, column) {
    return shouldCreate ? [column] : [];
}

function rowAccessor(row) {
    return row;
}

function getColumns(translations, form, openManualDataEntryDialog, onClose, label, setColumns) {
    const manualDataColumns = form.fields?.map((fields) => ({
        Header: translations[fields.label],
        accessor: fields.dataType,
        sortMethod: alphaNumericCompare,
        width: FIELD_WIDTHS[form.dataGroup] || FIELD_WIDTHS.DEFAULT,
        Cell(row) {
            const {value} = row;

            if (Array.isArray(value)) {
                const listItems = value.slice(0, MAX_ROW_LIST_ITEMS).map((item) => (
                    <div
                        className='table-list-row'
                        key={`${item.location}-${item.formattedValue}`}
                    >
                        <div className='table-list-cell'>{item.location}</div>
                        <div className='table-list-cell'>{item.formattedValue}</div>
                    </div>
                ));

                return value.length > MAX_ROW_LIST_ITEMS ? [...listItems, '...'] : listItems;
            }

            return value;
        }
    }));

    setColumns([
        {
            Header: translations.DATE,
            accessor: rowAccessor,
            id: 'date',
            className: 'sticky',
            headerClassName: 'sticky',
            sortMethod(a, b) {
                return dateCompare(a.timeSample, b.timeSample);
            },
            width: 120,
            Cell(row) {
                return (
                    <EditLink
                        onClick={() => openManualDataEntryDialog({
                            dataGroup: form.dataGroup,
                            dataTypeTitle: label,
                            manualDataId: row.original.manualDataId,
                            onClose,
                            translations
                        })}
                        requiredMyJdPermissions={[EDIT_COURSE_MEASUREMENT_DATA]}
                    >
                        {row.original.date}
                    </EditLink>
                );
            }
        },
        ...createColumnOrDefault(!form.isAssignment, {
            Header: translations.TIME,
            accessor: rowAccessor,
            id: 'time',
            sortMethod(a, b) {
                return dateCompare(a.timeSample, b.timeSample);
            },
            Cell(row) {
                return row.original.time;
            },
            width: 50
        }),
        ...createColumnOrDefault(form.needsHole, {
            Header: translations.ONLINK_HOLE,
            accessor: 'hole',
            sortMethod: alphaNumericCompare,
            width: 120
        }),
        ...createColumnOrDefault(form.needsHoleLocation, {
            Header: translations.AREA,
            accessor: 'holeLocation',
            sortMethod: alphaNumericCompare,
            width: 120
        }),
        ...manualDataColumns || [],
        {
            Header: translations.NOTES,
            accessor: 'note'
        }
    ]);
}

function getFormattedDataUnitValue(value, {
    currencyPreference,
    dataType,
    dataUnit,
    featureToggles,
    translations
}) {
    const formattingDataUnit = getDataUnit({
        currencyPreference,
        dataType,
        dataUnit,
        featureToggles,
        translations
    });

    const formatting = configForUnit(formattingDataUnit);

    const processedValue = convertToUoM(value, {
        dataType,
        dataUnit,
        featureToggles,
        useNonConverted: true
    });

    return formatUoMValue(processedValue, {
        formatting: {
            ...formatting,
            dataUnits: Array.isArray(formatting.dataUnits) ? formatting.dataUnits : [formatting],
            noDecimalFormatting: formattingDataUnit !== FEET_AND_INCHES
        },
        translations
    });
}

function formatRowValue(value, {
    currencyPreference,
    dataType,
    dataUnit,
    featureToggles,
    translations
}) {
    if (isNullOrUndefined(value)) {
        return '';
    }

    if (dataUnit === DOLLARS && !(dataType === FUEL_COST || dataType === GAS_COST)) {
        return getFormattedCurrency(value, {
            currencyPreference,
            featureToggles
        });
    }

    if (dataUnit === DOLLARS_PER_KILOWATT_HOUR) {
        const formattedValue = getFormattedCurrency(value, {
            currencyPreference,
            featureToggles
        });

        return `${formattedValue}/${translations.ONLINK_KWH}`;
    }

    return getFormattedDataUnitValue(value, {
        currencyPreference,
        dataType,
        dataUnit,
        featureToggles,
        translations
    });
}

function getFormattedValue(value, dataValues, formattingConfig) {
    if (!isNullOrUndefined(dataValues)) {
        return dataValues?.map((dataValue) => ({
            formattedValue: formatRowValue(dataValue.data_value, formattingConfig),
            location: dataValue.sub
        }));
    }

    return formatRowValue(value, formattingConfig);
}

function formatManualData(data, translations, currencyPreference, featureToggles) {
    return data?.map((rowEntry) => {
        const timeStampDate = forceLocalTimeZone(rowEntry.timeSample);

        const date = formatLocalizedTime(timeStampDate, {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        const time = formatLocalizedTime(timeStampDate, {
            hour: 'numeric',
            minute: 'numeric'
        });

        const manualData = rowEntry.jsonData?.reduce((manualDataObject, manualValue) => {
            const {
                data_type: dataType,
                data_unit: dataUnit,
                data_values: dataValues,
                value
            } = manualValue;

            const dataTypeValue = dataType.replace(/_volume|_weight|_f355_equivalents|_gravities/, '');

            return {
                ...manualDataObject,
                [dataTypeValue]: getFormattedValue(value, dataValues, {
                    currencyPreference,
                    dataType,
                    dataUnit,
                    featureToggles,
                    translations
                })
            };
        }, {});

        return {
            ...rowEntry,
            ...manualData,
            date,
            time
        };
    });
}

function getAddButton(translations, form, openManualDataEntryDialog, onClose, label, addButtonLabel) {
    return (
        <div className='extra-table-options right-aligned'>
            <OnlinkButton
                className='primary add-equipment-button'
                leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
                onClick={() => openManualDataEntryDialog({
                    dataGroup: form.dataGroup,
                    dataTypeTitle: label,
                    onClose,
                    translations
                })}
            >
                {addButtonLabel}
            </OnlinkButton>
        </div>
    );
}

function ManualDataHistoryTable(props) {
    const {
        addButtonLabel,
        closeConfirmation,
        currencyPreference,
        featureToggles,
        form,
        label,
        onCloseEntryDialog,
        openConfirmation,
        openManualDataEntryDialog,
        translations
    } = props;

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [columns, setColumns] = React.useState(() => []);

    async function fetchManualDataHistory(isMounted) {
        setLoading(true);

        const {manualDataHistory} = await getManualDataHistory(form.dataGroup);

        if (isMounted()) {
            const formattedManualData = formatManualData(manualDataHistory, translations, currencyPreference, featureToggles);

            setRows(formattedManualData);
            setLoading(false);
        }
    }

    async function onClose() {
        await fetchManualDataHistory(() => true);
        onCloseEntryDialog();
    }

    function handleManualDataDelete(data) {
        openConfirmation({
            message: translations.ONLINK_DELETE_ENTRY_WARNING,
            title: translations.ONLINK_DELETE_ENTRY,
            async onContinue() {
                closeConfirmation();
                await deleteManualData(data.manualDataId);
                await onClose();
            },
            onCancel: closeConfirmation
        });
    }

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        getColumns(translations, form, openManualDataEntryDialog, onClose, label, setColumns);
        await fetchManualDataHistory(isMounted);
    }), [form]);

    const canDelete = isAuthorized({
        myJdPermissions: DELETE_COURSE_MEASUREMENT_DATA
    }, props);

    const canAdd = isAuthorized({
        myJdPermissions: ADD_COURSE_MEASUREMENT_DATA
    }, props);

    return (
        <div className='settings-container'>
            <DataTable
                columns={columns}
                defaultSorted={[{
                    desc: true,
                    id: 'date'
                }]}
                deleteHandler={handleManualDataDelete}
                // below is not actually a filter. just using the space for the add button
                filterComponent={canAdd ? getAddButton(translations, form, openManualDataEntryDialog, onClose, label, addButtonLabel) : null}
                loading={loading}
                rows={rows}
                searchable={true}
                showDeleteColumn={canDelete}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

ManualDataHistoryTable.propTypes = {
    addButtonLabel: PropTypes.string,
    closeConfirmation: PropTypes.func,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    form: PropTypes.object,
    isMigrated: PropTypes.bool,
    label: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    onCloseEntryDialog: PropTypes.func,
    openConfirmation: PropTypes.func,
    openManualDataEntryDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        currencyPreference: state.membership.currencyPreference,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openManualDataEntryDialog(props) {
            dispatch(openDialog(dialogTypes.MANUAL_DATA_ENTRY_DIALOG, props));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualDataHistoryTable);
