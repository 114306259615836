// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createPart(partData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/part', partData);

    return response.data;
}

async function deletePart(partId, inventoryId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/part/${partId}/inventory/${inventoryId}`);

    return response.data;
}

async function updatePart(partId, partData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/part/${partId}`, partData);

    return response.data;
}

async function getCompatibleParts(equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}/compatibleParts`);

    return response.data;
}

async function updatePartRequest(partRequestId, partRequestData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/partRequests/${partRequestId}`, partRequestData);

    return response.data;
}

async function deletePartRequest(partRequestId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/partRequests/${partRequestId}`);

    return response.data;
}

export {
    createPart,
    deletePart,
    updatePart,
    getCompatibleParts,
    updatePartRequest,
    deletePartRequest
};
