// Unpublished Work © 2023-2024 Deere & Company.

export const BODY_FONT_STYLE = {
    fontFamily: '"Noto Sans", -apple-system, blinkmacsystemfont, "Helvetica Neue", arial, sans-serif',
    fontSize: '1.4rem'
};

export const LABEL_FONT_STYLE = {
    ...BODY_FONT_STYLE,
    fontWeight: 'bold'
};
