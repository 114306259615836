// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';

function ErrorView(props) {
    const {
        featureToggles,
        translations
    } = props;

    const {
        errorTypographyProps,
        errorTypographyTitleProps,
        errorViewProps
    } = React.useMemo(() => {
        if (featureToggles[ONLINK_NAVIGATION_REDESIGN]) {
            return {
                errorTypographyProps: {
                    children: translations.ONLINK_ACCESS_LIMITED_MESSAGE,
                    fontSize: '1.2rem',
                    fontWeight: 'medium',
                    paragraph: true
                },
                errorTypographyTitleProps: {
                    children: translations.ONLINK_ACCESS_LIMITED,
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    variant: 'h3'
                },
                errorViewProps: {
                    padding: '4rem 1.25rem'
                }

            };
        }

        return {
            errorTypographyProps: {
                children: `403 ${translations['pages/errors/forbidden']}`,
                fontSize: '3rem',
                variant: 'h2'
            },
            errorViewProps: {
                bgcolor: '#fff',
                borderRadius: '5px',
                boxShadow: '2px 2px 3px 0 rgba(0, 0, 0, .1)',
                margin: 'auto',
                padding: '40px 60px'
            }
        };
    }, [featureToggles[ONLINK_NAVIGATION_REDESIGN]]);

    return (
        <Stack
            {...errorViewProps}
            direction='column'
            spacing={2}
            textAlign='center'
        >
            {
                errorTypographyTitleProps &&
                <Typography {...errorTypographyTitleProps}/>
            }
            <Typography {...errorTypographyProps}/>
        </Stack>
    );
}

ErrorView.propTypes = {
    featureToggles: PropTypes.featureToggles,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(ErrorView);
