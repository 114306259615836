// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import TileCheckbox from 'Ui/components/tile-editor/tile-checkbox';
import {tileTitleTranslationKeys} from 'Ui/components/common/tile-constants/translations';
import {isAuthorized} from 'Common/utils/authorization-handler';
import homeTiles from 'Ui/components/common/tile-constants/tiles/home';

function TileCategory(props) {
    const {
        activeTiles,
        onChange,
        tiles,
        translations,
        userAuth
    } = props;

    return (
        <div className='tile-category'>
            {
                tiles.map(({
                    id,
                    name
                }) => {
                    const checked = activeTiles[id];
                    const disabled = !isAuthorized(homeTiles[id], userAuth);

                    return (
                        <TileCheckbox
                            checked={checked}
                            disabled={disabled && !checked}
                            id={id}
                            key={id}
                            name={translations[tileTitleTranslationKeys[name]]}
                            onChange={onChange}
                        />
                    );
                })
            }
        </div>
    );
}

TileCategory.propTypes = {
    activeTiles: PropTypes.objectOfBool,
    initialActiveTiles: PropTypes.objectOfBool,
    onChange: PropTypes.func,
    tiles: PropTypes.arrayOf(PropTypes.tile),
    translations: PropTypes.translations,
    userAuth: PropTypes.object
};

export default TileCategory;
