// Unpublished Work © 2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {
    batchUpsertMyJdUserLinks,
    batchUpsertOperatorLinks,
    getMyJDOperator,
    getMyJdOperatorLinksByAppUserIds,
    getMyJDUserByOrgId,
    getMyJdUserLinksByAppUserIds
} from 'Services/my-jd-user-service';
import {createUser, updateUserById} from 'Services/user-service';
import {v4} from 'uuid';

function createUpsertInputsAndFlattenedAppUserIds(selectedIds, idName) {
    const flatAppUserIds = [];
    const upsertInputs = [];

    Object.entries(selectedIds).forEach(([id, appUserIds]) => {
        flatAppUserIds.push(...appUserIds);
        upsertInputs.push({
            appUserId: appUserIds[0],
            [idName]: id
        });
    });

    return {
        flatAppUserIds,
        upsertInputs
    };
}

export async function saveOperatorMerge(selectedIds, reloadTable, closeMigrationConfirmationDialog, orgId) {
    const {
        flatAppUserIds,
        upsertInputs: operatorLinkInputs
    } = createUpsertInputsAndFlattenedAppUserIds(selectedIds, 'operatorId');

    await batchUpsertOperatorLinks(operatorLinkInputs);

    const savedOperatorLinks = await getMyJdOperatorLinksByAppUserIds(flatAppUserIds);

    await Promise.all(savedOperatorLinks.map(async (link) => {
        const operator = await getMyJDOperator(orgId, link.operatorId);

        await updateUserById({
            appUserId: link.appUserId,
            firstName: operator.name,
            lastName: ''
        }, link.appUserId);
    }));

    reloadTable(() => true);

    closeMigrationConfirmationDialog();
}

export async function saveUserMerge(selectedIds, reloadTable, closeMigrationConfirmationDialog, orgId) {
    await Promise.all(Object.keys(selectedIds).map(async (id) => {
        if (selectedIds[id][0] === 'noUser') {
            const {user: createdUser} = await createUser({
                appKeys: [],
                email: `${v4()}@platformStaff.com`,
                password: v4()
            });

            selectedIds[id][0] = createdUser.appUserId;
        }
    }));

    const {
        flatAppUserIds,
        upsertInputs: myjdUserLinkInputs
    } = createUpsertInputsAndFlattenedAppUserIds(selectedIds, 'myjdUser');

    await batchUpsertMyJdUserLinks(myjdUserLinkInputs);

    const savedStaffLinks = await getMyJdUserLinksByAppUserIds(flatAppUserIds);

    await Promise.all(savedStaffLinks.map(async (link) => {
        const {user} = await getMyJDUserByOrgId(orgId, link.myjdUser);

        await updateUserById({
            appUserId: link.appUserId,
            firstName: user.givenName,
            lastName: user.familyName
        }, link.appUserId);
    }));

    reloadTable(() => true);

    closeMigrationConfirmationDialog();
}
