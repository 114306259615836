// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import EditableQuickTODOSettings from 'Ui/components/settings/labor/editable-quick-todo-settings';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {createTodoTemplate, updateTodoTemplate} from 'Services/todo-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {useSave} from 'Ui/react-hooks/use-save';

function QuickTodoDialog(props) {
    const {
        closeDialog,
        todoTemplate,
        getUpdatedTodoTemplates,
        invalidInputs,
        setValid,
        translations,
        membershipId
    } = props;

    const [title, setTitle] = React.useState(() => todoTemplate && todoTemplate.title || '');

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const todoTemplatePayload = {
            membershipId,
            ...todoTemplate,
            title
        };

        if (todoTemplate) {
            await updateTodoTemplate(todoTemplatePayload, todoTemplate.todoTemplateId);
        } else {
            await createTodoTemplate(todoTemplatePayload);
        }

        await getUpdatedTodoTemplates();
        closeDialog();
    }, {
        invalidInputs
    });

    const dialogTitle = todoTemplate ?
        translations.ONLINK_EDIT_QUICK_TODO :
        translations.ONLINK_ADD_QUICK_TODO;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className={'quick-todo-dialog dropdown-overflow-dialog'}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={dialogTitle}
            translations={translations}
        >
            <EditableQuickTODOSettings
                onChange={(event) => setTitle(event.target.value)}
                onSave={saveFunc}
                setValid={setValid}
                title={title}
                translations={translations}
            />
        </FormDialog>
    );
}

QuickTodoDialog.propTypes = {
    closeDialog: PropTypes.func,
    getUpdatedTodoTemplates: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipId: PropTypes.string,
    setValid: PropTypes.func,
    todoTemplate: PropTypes.object,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.QUICK_TODO_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(QuickTodoDialog));
