// Unpublished Work © 2022-2024 Deere & Company.

import {formatLocalizedTime, formatMinutesToHours} from 'Utils/time-utils';
import moment from 'moment';
const DATE_FORMAT = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
};

function getMovingDirection(mowingPattern) {
    const direction = mowingPattern?.direction || '';
    const direction2 = mowingPattern?.direction2 ? `, ${mowingPattern?.direction2}` : '';

    return direction + direction2;
}

function makeReportEntry(workItem, title, workboardTitle, mowingPattern, translations) {
    const {
        appUser,
        dateSelect,
        equipment,
        operatorNote,
        route,
        routeCustomDefn,
        preStartCheck
    } = workItem;

    const operator = appUser ? `${appUser.firstName} ${appUser.lastName}` : translations.ONLINK_USER_NOT_AVAILABLE;

    const formattedDate = formatLocalizedTime(moment(dateSelect).toDate(), DATE_FORMAT);

    const equipmentNames = equipment?.map((piece) => piece.equipmentName).join(', ');
    const formattedPreStartCheck = preStartCheck ? translations.yes : translations.no;
    const estDuration = formatMinutesToHours(workItem.estDuration);
    const actualDuration = formatMinutesToHours(workItem.actualDuration);

    const mowingDirection = getMovingDirection(mowingPattern);

    const routeCol = route === 'Custom' ? routeCustomDefn?.join(',') : route;

    return {
        title,
        operator,
        formattedDate,
        equipmentNames,
        estDuration,
        actualDuration,
        operatorNote,
        formattedPreStartCheck,
        mowingDirection,
        route: routeCol,
        workboardTitle
    };
}

function getFormattedJobs(jobList, translations) {
    return jobList.reduce((accumulatedList, job) => {
        const {
            title,
            workItems,
            workboardTitle,
            mowingPattern
        } = job;

        if (workItems?.length) {
            const workItemsMap = workItems.map((workItem) => makeReportEntry(workItem, title, workboardTitle, mowingPattern, translations));

            return accumulatedList.concat(workItemsMap);
        }

        return accumulatedList;
    }, []);
}

export {
    getFormattedJobs
};
