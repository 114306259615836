// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {closeDialog} from 'Store/actions/dialogs';
import {connect} from 'react-redux';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {useSave} from 'Ui/react-hooks/use-save';
import {createBinLocation, updateBinLocation} from 'Services/inventory-service';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {useDeepMemo} from 'Utils/react-utils';

function isDuplicateBinLocationName(binLocation, binLocationIdsByName, binLocationName) {
    const binLocationIdForName = binLocationIdsByName.get(binLocationName.toLowerCase());

    return binLocationIdForName && binLocationIdForName !== binLocation?.binLocationId;
}

function BinLocationDialog(props) {
    const {
        binLocation,
        closeBinLocationDialog,
        fetchBinLocations,
        invalidInputs,
        inventoryBinLocations,
        inventoryId,
        membershipId,
        setValid,
        translations
    } = props;

    const [binLocationName, setBinLocationName] = React.useState(() => binLocation?.name || '');
    const title = binLocation ? translations.EDIT_TYPE : translations.ONLINK_ADD_ITEM;

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const payload = {
            inventoryId,
            membershipId,
            name: binLocationName
        };

        binLocation ?
            await updateBinLocation(binLocation.binLocationId, payload) :
            await createBinLocation(payload);

        await fetchBinLocations();

        closeBinLocationDialog();
    }, {
        invalidInputs
    });

    const binLocationIdsByName = useDeepMemo(() => inventoryBinLocations.reduce((binLocationsMap, binLocation) => {
        const {
            binLocationId,
            name
        } = binLocation;

        if (name?.trim()?.length) {
            binLocationsMap.set(name.toLowerCase(), binLocationId);
        }

        return binLocationsMap;
    }, new Map()), [inventoryBinLocations]);

    const duplicateName = isDuplicateBinLocationName(binLocation, binLocationIdsByName, binLocationName);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeBinLocationDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={replaceTranslationNames(title, {
                '0': translations.ONLINK_BIN_LOCATION
            })}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    debounceTimeout={window.props.debounceTimeout}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        customError: duplicateName ?
                            translations.ONLINK_BIN_LOCATION_NAME_EXISTS : translations.REQUIRED_FIELD_TEXT
                    }}
                    invalidCustomError={binLocationName.trim().length === 0 ||
                        duplicateName
                    }
                    label={translations.ONLINK_BIN_LOCATION}
                    name='binLocationName'
                    onChange={(event) => setBinLocationName(event.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={binLocationName}
                />
            </form>
        </FormDialog>
    );
}

BinLocationDialog.propTypes = {
    binLocation: PropTypes.object,
    closeBinLocationDialog: PropTypes.func,
    fetchBinLocations: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    inventoryBinLocations: PropTypes.arrayOf(PropTypes.object),
    inventoryId: PropTypes.string,
    membershipId: PropTypes.string,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeBinLocationDialog() {
            dispatch(closeDialog(dialogTypes.BIN_LOCATION_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(BinLocationDialog));
