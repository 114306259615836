// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MediaQuery, {MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {IconShown, IconDelete, IconPrint, IconRemove, IconSave} from '@deere/icons';
import {ReactComponent as DuplicateIcon} from '@deere/ux.brand-foundations/icons/duplicate.svg';
import {DropdownMenu} from '@deere/isg.component-library/lib/DropdownMenu';
import ConfirmationMessage from 'OnLabor/workboard/common/confirmation-message';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {openDialog} from 'Store/actions/dialogs';
import {useDeepMemo} from 'Utils/react-utils';
import {getCopiedWorkboardData, initializeEditableWorkboard} from 'OnLabor/workboard/utils/workboard-initialize';
import {generateWorkboardPdf} from 'Ui/features/onlabor/workboards/generate-workboard-pdf';
import dialogTypes from 'Ui/components/common/dialog-types';
import {WORKBOARD_STEP} from 'OnLabor/workboard/constants/workboard-steps';
import {isEqual} from 'lodash';
import Stack from '@mui/material/Stack';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function dropdownItemsOrDefault(condition, ...items) {
    return condition ? items : [];
}

function closeAction(onClose) {
    if (onClose) {
        onClose();
    }
}

async function printWorkboard(savedWorkboardId, membershipId, translations, featureToggles) {
    const workboardPDF = await generateWorkboardPdf(savedWorkboardId, membershipId, translations, featureToggles);

    workboardPDF.autoPrint({
        variant: 'non-conform'
    });
    window.open(workboardPDF.output('bloburl'), '_blank');
}

function WorkboardDialogActions(props) {
    const {
        activeStep,
        cancelActiveWorkboard,
        closeAddWorkboardDialog,
        closeConfirmation,
        deleteActiveWorkboard,
        dialogType,
        disableSave,
        featureToggles,
        initialValuesRef,
        invalidInputs,
        invalidSteps,
        isMobile,
        isTablet,
        membership,
        onClose,
        openConfirmation,
        openWorkboardPreviewDialog,
        saveFunc,
        setActiveStep,
        setDialogType,
        setLoading,
        setValues,
        steps,
        translations,
        values,
        workboardCreationFlow,
        hasMyJdPermissions
    } = props;

    const isUnmodified = useDeepMemo(() => isEqual(initialValuesRef.current, values), [initialValuesRef.current, values]);

    async function showSaveWarningForJoblessOperators(callback) {
        if (invalidInputs.size === 0) {
            const hasOperatorsWithNoJobs = Object.values(values.workItemsByOperator)
                .some((workItems) => workItems.length === 0 || workItems.some(({jobTemplateId}) => !jobTemplateId));

            if (hasOperatorsWithNoJobs) {
                openConfirmation({
                    message: (
                        <ConfirmationMessage
                            message={translations.ONLINK_OPERATOR_UNASSIGNED_JOB_ERROR_MESSAGE}
                            translations={translations}
                        />
                    ),
                    onCancel: closeConfirmation,
                    async onContinue() {
                        await callback();

                        closeConfirmation();
                    },
                    title: translations.ONLINK_OPERATORS_MISSING_JOBS,
                    translations
                });
            } else {
                await callback();
            }
        }
    }

    async function copyActiveWorkboard() {
        await showSaveWarningForJoblessOperators(async () => {
            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: true
            }));

            const copiedValues = await getCopiedWorkboardData({
                saveFunc,
                translations,
                values,
                workboardCreationFlow,
                membership,
                closeAddWorkboardDialog
            });

            initialValuesRef.current = copiedValues;

            setDialogType({
                isCopy: true,
                isEdit: false
            });
            setActiveStep(WORKBOARD_STEP.zero);
            setValues(copiedValues);
            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: false
            }));
        });
    }

    async function handlePreview(workboardId) {
        const previewUrl = `${window.props.onLinkLegacyUri}/labor/workboard/presentation/${membership.membershipId}/${workboardId}`;

        if (isMobile) {
            openWorkboardPreviewDialog({
                previewUrl,
                translations
            });
        } else {
            window.open(previewUrl);
        }
    }

    async function saveAndPreview(stayOnWorkboard) {
        await showSaveWarningForJoblessOperators(async () => {
            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: true
            }));

            let savedWorkboardId = values.workboardId;

            if (!(isUnmodified && dialogType.isEdit)) {
                savedWorkboardId = await saveFunc();

                if (stayOnWorkboard) {
                    await initializeEditableWorkboard({
                        initialValuesRef,
                        isMounted: () => true,
                        setActiveStep,
                        setLoading,
                        setValues,
                        translations,
                        workboardCreationFlow,
                        workboardId: savedWorkboardId
                    });

                    setDialogType({
                        isCopy: false,
                        isEdit: true
                    });
                }
            }

            closeAction(onClose);

            if (!stayOnWorkboard) {
                closeAction(onClose);
                closeAddWorkboardDialog();
            }

            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: false
            }));

            handlePreview(savedWorkboardId);
        });
    }

    async function saveAndPrint() {
        await showSaveWarningForJoblessOperators(async () => {
            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: true
            }));

            let savedWorkboardId = values.workboardId;

            if (!(isUnmodified && dialogType.isEdit)) {
                savedWorkboardId = await saveFunc();

                await initializeEditableWorkboard({
                    initialValuesRef,
                    isMounted: () => true,
                    setActiveStep,
                    setLoading,
                    setValues,
                    translations,
                    workboardCreationFlow,
                    workboardId: savedWorkboardId
                });
            }

            await printWorkboard(savedWorkboardId, membership.membershipId, translations, featureToggles);

            closeAction(onClose);

            setDialogType({
                isCopy: false,
                isEdit: true
            });

            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: false
            }));
        });
    }

    async function handleSave() {
        if (invalidInputs.size === 0) {
            await showSaveWarningForJoblessOperators(async () => {
                await saveFunc();

                closeAction(onClose);

                closeAddWorkboardDialog();
            });
        }
    }

    const [backText, nextText] = useDeepMemo(() => [
        isTablet ? '<' : translations.back,
        isTablet ? '>' : translations.NEXT
    ], [isTablet, translations]);

    const dropdownItems = useDeepMemo(() => {
        const saveIcon = (
            <IconSave iconSave={ICON_STYLE}/>
        );

        return [
            ...dropdownItemsOrDefault(
                hasMyJdPermissions,
                {
                    title: translations.PREVIEW,
                    disabled: disableSave || !hasMyJdPermissions,
                    onClick: () => saveAndPreview(true),
                    Icon: <IconShown iconShown={ICON_STYLE}/>
                },
                {
                    title: isUnmodified && dialogType.isEdit ? translations.PRINT : translations.ONLINK_SAVE_AND_PRINT,
                    disabled: disableSave || !hasMyJdPermissions,
                    onClick: saveAndPrint,
                    Icon: <IconPrint iconPrint={ICON_STYLE}/>
                }
            ),
            ...dropdownItemsOrDefault(
                dialogType.isEdit && hasMyJdPermissions,
                {
                    title: isUnmodified ? translations.COPY : translations.ONLINK_SAVE_AND_COPY,
                    disabled: disableSave || !hasMyJdPermissions,
                    onClick: copyActiveWorkboard,
                    Icon: <DuplicateIcon className='duplicate-icon'/>
                },
                {
                    title: translations.ONLINK_DELETE_WORKBOARD,
                    onClick: deleteActiveWorkboard,
                    Icon: <IconDelete iconDelete={ICON_STYLE}/>
                }
            ),
            ...dropdownItemsOrDefault(
                isTablet,
                {
                    title: translations.CANCEL,
                    onClick: cancelActiveWorkboard,
                    Icon: <IconRemove iconRemove={ICON_STYLE}/>
                }
            ),
            ...dropdownItemsOrDefault(
                isTablet && hasMyJdPermissions,
                {
                    title: translations.ONLINK_SAVE_WORKBOARD,
                    disabled: disableSave || !hasMyJdPermissions,
                    onClick: handleSave,
                    Icon: saveIcon
                },
                {
                    title: translations.ONLINK_SAVE_AND_PREVIEW,
                    disabled: disableSave || !hasMyJdPermissions,
                    onClick: () => saveAndPreview(false),
                    Icon: saveIcon
                }
            )
        ];
    }, [disableSave, initialValuesRef.current, dialogType, isTablet, isUnmodified,
        translations, values, workboardCreationFlow]);

    const backButtonClassName = `onlink-btn${membership.isMigrated ? '' : ' primary'} back-btn`;

    return (
        <>
            {
                activeStep > 0 &&
                <OnlinkButton
                    className={backButtonClassName}
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                >
                    {backText}
                </OnlinkButton>
            }
            <Stack
                direction='row'
                spacing={2}
                sx={{
                    marginLeft: 'auto',
                    paddingRight: '1%',
                    alignItems: 'center'
                }}
            >
                {
                    dropdownItems.length > 0 &&
                    <DropdownMenu
                        buttonTitle={translations.ONLINK_OTHER_ACTIONS}
                        className='onlink-action-dropdown footer-elem'
                        items={dropdownItems}
                    />
                }
                {
                    !isTablet &&
                    <>
                        <OnlinkButton
                            className='onlink-btn footer-elem'
                            onClick={cancelActiveWorkboard}
                        >
                            {translations.CANCEL}
                        </OnlinkButton>
                        {
                            hasMyJdPermissions &&
                            <>
                                <OnlinkButton
                                    className='onlink-btn footer-elem'
                                    disabled={disableSave || !hasMyJdPermissions}
                                    onClick={handleSave}
                                >
                                    {translations.ONLINK_SAVE_WORKBOARD}
                                </OnlinkButton>
                                <OnlinkButton
                                    className='onlink-btn footer-elem'
                                    disabled={disableSave || !hasMyJdPermissions}
                                    onClick={() => saveAndPreview(false)}
                                >
                                    {translations.ONLINK_SAVE_AND_PREVIEW}
                                </OnlinkButton>
                            </>
                        }
                    </>
                }
                {
                    activeStep < steps.length - 1 &&
                    <OnlinkButton
                        className='onlink-btn footer-elem'
                        disabled={disableSave}
                        onClick={() => {
                            if (invalidInputs.size === 0 || !invalidSteps.has(activeStep + 1)) {
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                        }}
                    >
                        {nextText}
                    </OnlinkButton>
                }
            </Stack>
        </>
    );
}

WorkboardDialogActions.propTypes = {
    activeStep: PropTypes.number,
    cancelActiveWorkboard: PropTypes.func,
    closeAddWorkboardDialog: PropTypes.func,
    closeConfirmation: PropTypes.func,
    deleteActiveWorkboard: PropTypes.func,
    dialogType: PropTypes.object,
    disableSave: PropTypes.bool,
    featureToggles: PropTypes.featureToggles,
    hasMyJdPermissions: PropTypes.bool,
    initialValuesRef: PropTypes.reference,
    invalidInputs: PropTypes.instanceOf(Set),
    invalidSteps: PropTypes.instanceOf(Set),
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    membership: PropTypes.membership,
    onClose: PropTypes.func,
    openConfirmation: PropTypes.func,
    openWorkboardPreviewDialog: PropTypes.func,
    saveFunc: PropTypes.func,
    setActiveStep: PropTypes.func,
    setDialogType: PropTypes.func,
    setLoading: PropTypes.func,
    setValues: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    values: PropTypes.object,
    workboardCreationFlow: PropTypes.string
};

export function mapDispatchToProps(dispatch) {
    return {
        openWorkboardPreviewDialog(props) {
            dispatch(openDialog(dialogTypes.WORKBOARD_PREVIEW_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY)(WorkboardDialogActions));
