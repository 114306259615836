// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {
    alphaNumericCompare,
    dateCompare,
    dueColumnCompare,
    formatDateSelect,
    genericServiceCompare, getInterval,
    getPercentClassName,
    getPercentDue,
    numericCompare
} from 'Ui/models/maintenance';
import EditLink from 'Ui/components/common/data-table/edit-link';
import OnLinkProgressBar from 'Ui/components/common/onlink-progress-bar';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import store from 'Store/store';
import {RECOMMENDED} from 'Common/constants/service-status';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

const DECIMAL_TO_PERCENT = 100;

export function getRows(servicePriority = [], translations) {
    const recommendedServices = servicePriority.filter((service) => service.status === RECOMMENDED);

    return recommendedServices.map((service) => {
        const partNumbers = service.partsList?.map((part) => part.partNumber) || [];

        return {
            ...service,
            interval: getInterval(service, translations),
            percentDue: getPercentDue(service),
            formattedAtDateSelect: formatDateSelect(service),
            formattedParts: partNumbers.join(', ')
        };
    });
}

function rowAccessor(row) {
    return row;
}

export function getColumns(translations, updateEquipment) {
    return [
        {
            Header: translations.INTERVAL,
            accessor: rowAccessor,
            id: 'interval',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'interval');
            },
            Cell(row) {
                return row.original.interval;
            }
        },
        {
            Header: translations.ONLINK_SERVICE,
            accessor: rowAccessor,
            id: 'serviceTypeName',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'serviceTypeName');
            },
            Cell(row) {
                const {original: service} = row;

                return (
                    <EditLink
                        onClick={() => {
                            store.dispatch(openReduxDialog(dialogTypes.SERVICE_FORM_DIALOG, {
                                onSave: updateEquipment,
                                serviceId: service.serviceId,
                                translations
                            }));
                        }}
                    >
                        {service.serviceTypeName}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_PARTS_HEADER,
            accessor: rowAccessor,
            id: 'formattedParts',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'formattedParts');
            },
            Cell(row) {
                const {original: service} = row;

                return (
                    <Tooltip
                        placement='bottom'
                        title={service.formattedParts}
                    >
                        <span>
                            {service.formattedParts}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            Header: translations.ONLINK_DUE_DATE,
            accessor: rowAccessor,
            id: 'formattedAtDateSelect',
            width: 150,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'atDateSelect');
            },
            Cell(row) {
                return row.original.formattedAtDateSelect;
            }
        },
        {
            Header: translations.ONLINK_DUE_HOURS,
            accessor: rowAccessor,
            id: 'atHours',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 150,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, numericCompare, 'atHours');
            },
            Cell(row) {
                return row.original.atHours;
            }
        },
        {
            Header: translations.DUE,
            accessor: rowAccessor,
            id: 'dueProgressBar',
            className: 'center',
            width: 100,
            Cell(row) {
                const {original: service} = row;
                const progress = service.percentDue;
                const progressClassName = getPercentClassName(service);

                return (
                    <OnLinkProgressBar
                        barClassName='maintenance-progress-bar'
                        fillClassName={progressClassName}
                        percentFilled={Math.min(1, progress) * DECIMAL_TO_PERCENT}
                    />
                );
            },
            sortMethod: dueColumnCompare
        }
    ];
}
