// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Panel from 'Ui/components/panel/panel';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import Temperature from 'Ui/components/home/current-conditions/temperature';
import Precipitation from 'Ui/components/home/current-conditions/precipitation';
import Wind from 'Ui/components/home/current-conditions/wind';
import {connect} from 'react-redux';

function CurrentConditions(props) {
    const {
        tile,
        onClick,
        translations,
        featureToggles,
        thresholds,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = props;

    const hasWeatherData = tile.dataRollups.length > 0 && Object.keys(tile.dataRollups[0].data).length > 0;

    if (!hasWeatherData) {
        return (
            <Panel
                onClick={onClick}
                title={translations.CURRENT_CONDITIONS}
                translations={translations}
            >
                <NoDataMessage translations={translations}/>
            </Panel>
        );
    }

    const data = tile.dataRollups[0].data;

    return (
        <Panel
            className='current-conditions'
            onClick={onClick}
            title={translations.CURRENT_CONDITIONS}
            translations={translations}
        >
            <div className='panel-row temp-precip'>
                <Temperature
                    featureToggles={featureToggles}
                    iconCode={data.icon_code}
                    obsTimeLocal={data.obs_time_local}
                    phrase22char={data.phrase_22char}
                    temp={data.imperial.temp}
                    tempMax24hour={data.imperial.temp_max_24hour}
                    tempMin24hour={data.imperial.temp_min_24hour}
                    thresholds={thresholds}
                    translations={translations}
                    unitOfMeasure={unitOfMeasure}
                />
                <Precipitation
                    featureToggles={featureToggles}
                    obsTimeLocal={data.obs_time_local}
                    precip1hour={data.imperial.precip_1hour}
                    precip24hour={data.imperial.precip_24hour}
                    thresholds={thresholds}
                    translations={translations}
                    unitOfMeasure={unitOfMeasure}
                />
            </div>
            <Wind
                dewpt={data.imperial.dewpt}
                featureToggles={featureToggles}
                obsTimeLocal={data.obs_time_local}
                rh={data.imperial.rh}
                thresholds={thresholds}
                translations={translations}
                unitOfMeasure={unitOfMeasure}
                unitOfMeasureOverrides={unitOfMeasureOverrides}
                wdirCardinal={data.wdir_cardinal}
                wspd={data.imperial.wspd}
            />
        </Panel>
    );
}

CurrentConditions.propTypes = {
    featureToggles: PropTypes.featureToggles,
    onClick: PropTypes.func,
    thresholds: PropTypes.thresholds,
    tile: PropTypes.panel,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.array
};

export function mapStateToProps(state) {
    return {
        unitOfMeasure: state.membership.unitOfMeasure,
        unitOfMeasureOverrides: state.membership.unitOfMeasureOverrides,
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(CurrentConditions);
