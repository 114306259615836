// Unpublished Work © 2023-2024 Deere & Company.

import {REJECTED} from 'Common/constants/response-status';
import {TOAST_TYPE} from '@deere/toast';
import React from 'react';

function getDeleteSelectedServiceConfirmationMessage(translations) {
    return (
        <div>
            <div className='delete-maintenance'>
                <span className='warning-message'>
                    {translations.ONLINK_WARNING}
                </span>
                {translations.ONLINK_DELETE_SELECTED_SERVICE_MESSAGE}
            </div>
            {translations.ONLINK_CONTINUE_CONFIRMATION_MESSAGE}
        </div>
    );
}

function createOnContinueCallback(closeConfirmation, onSave) {
    return async () => {
        await onSave();
        closeConfirmation();
    };
}

async function modifyServices(services, selectedServices, callback) {
    const serviceMap = services.reduce((mappedServices, service) => {
        mappedServices.set(service.serviceId, service);

        return mappedServices;
    }, new Map());

    const selectedServicesArr = Array.from(selectedServices);
    const toBeModified = selectedServicesArr.map(callback);

    const results = await Promise.allSettled(toBeModified);

    return results.reduce((serviceNames, result, index) => {
        if (result.status === REJECTED) {
            const serviceId = selectedServicesArr[index];
            const service = serviceMap.get(serviceId);

            return serviceNames.concat(`${service.equipmentName}-${service.serviceTypeName}`);
        }

        return serviceNames;
    }, []);
}

function displayErrorMessage(addToast, message, rejectedNames) {
    const toastMessage = message.replace('{0}', rejectedNames);

    addToast({
        message: toastMessage,
        persist: true,
        type: TOAST_TYPE.ERROR
    });
}

export {
    getDeleteSelectedServiceConfirmationMessage,
    createOnContinueCallback,
    modifyServices,
    displayErrorMessage
};
