// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {createIconFillStyle} from 'Utils/icon-utils';
import {IconCaretDown, IconCaretUp} from '@deere/icons';

const ICON_CARET_SIZE = '13px';
const ICON_MARGIN_LEFT = '6px';

function IconCaretDirection(props) {
    const iconCaretStyle = {
        style: {
            alignSelf: 'center',
            height: ICON_CARET_SIZE,
            marginLeft: ICON_MARGIN_LEFT,
            width: ICON_CARET_SIZE,
            ...props.style
        }
    };

    const iconCaretPrimaryStyle = createIconFillStyle(props.color);

    if (props.caretUp) {
        return (
            <IconCaretUp
                iconCaretUp={iconCaretStyle}
                primary={iconCaretPrimaryStyle}
            />
        );
    }

    return (
        <IconCaretDown
            iconCaretDown={iconCaretStyle}
            primary={iconCaretPrimaryStyle}
        />
    );
}

IconCaretDirection.propTypes = {
    caretUp: PropTypes.bool,
    color: PropTypes.string,
    style: PropTypes.shape()
};

export default IconCaretDirection;
