// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import serviceStatusConstants from 'Ui/constants/service-status-constants';

function ServiceStatus(props) {
    const {
        status,
        translations
    } = props;

    const statusConstant = serviceStatusConstants[status];
    const statusText = translations[statusConstant.title] || statusConstant.title;

    if (statusConstant.color) {
        return (
            <span className={statusConstant.color}>
                {statusText}
            </span>
        );
    }

    return statusText;
}

ServiceStatus.propTypes = {
    status: PropTypes.string,
    translations: PropTypes.translations
};

export default ServiceStatus;
