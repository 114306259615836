// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Panel from 'Ui/components/panel/panel';
import PanelDateLabel from 'Ui/components/panel/panel-date-label';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import CurrentTemperature from 'Ui/components/drill-ins/weather-course/current-details/current-temperature';
import CurrentFigures from 'Ui/components/drill-ins/weather-course/current-details/current-figures';
import {getMinuteOrHourMessage} from 'Utils/time-utils';

function CurrentDetails(props) {
    const {
        featureToggles,
        onClick,
        thresholds,
        tile,
        translations,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = props;

    const [{
        dataRollups,
        panelData
    }, {conditions}] = tile;
    const hasDetailsData = dataRollups && conditions &&
        Object.keys(dataRollups).length > 0 &&
        Object.keys(conditions).length > 0;

    if (!hasDetailsData) {
        return (
            <Panel
                onClick={onClick}
                title={translations.ONLINK_CURRENT_DETAILS}
                translations={translations}
            >
                <NoDataMessage translations={translations}/>
            </Panel>
        );
    }

    const skyCoverPanelData = panelData.find((panelDataItem) => panelDataItem.dataType === 'sky_cover');
    const skyCoverDataRollup = dataRollups.find((dataRollupItem) => dataRollupItem.dataType === 'sky_cover');

    const date = getMinuteOrHourMessage(translations, conditions.obs_time_local);

    return (
        <Panel
            className='current-details'
            onClick={onClick}
            title={translations.ONLINK_CURRENT_DETAILS}
            translations={translations}
        >
            <CurrentTemperature
                featureToggles={featureToggles}
                iconCode={conditions.icon_code}
                phrase22char={conditions.phrase_22char}
                temp={conditions.imperial.temp}
                thresholds={thresholds}
                translations={translations}
                unitOfMeasure={unitOfMeasure}
            />
            <CurrentFigures
                altimeter={conditions.imperial.altimeter}
                dewpt={conditions.imperial.dewpt}
                featureToggles={featureToggles}
                precip1hour={conditions.imperial.precip_1hour}
                precip24hour={conditions.imperial.precip_24hour}
                rh={conditions.imperial.rh}
                skyCover={skyCoverDataRollup?.[`${skyCoverPanelData.useField}Value`]}
                sunrise={conditions.sunrise}
                sunset={conditions.sunset}
                tempMax24hour={conditions.imperial.temp_max_24hour}
                tempMin24hour={conditions.imperial.temp_min_24hour}
                thresholds={thresholds}
                translations={translations}
                unitOfMeasure={unitOfMeasure}
                unitOfMeasureOverrides={unitOfMeasureOverrides}
                wdirCardinal={conditions.wdir_cardinal}
                wspd={conditions.imperial.wspd}
            />
            <PanelDateLabel date={date}/>
        </Panel>
    );
}

CurrentDetails.propTypes = {
    featureToggles: PropTypes.featureToggles,
    onClick: PropTypes.func,
    thresholds: PropTypes.thresholds,
    tile: PropTypes.tileData,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        unitOfMeasure: state.membership.unitOfMeasure,
        unitOfMeasureOverrides: state.membership.unitOfMeasureOverrides
    };
}

export default connect(mapStateToProps)(CurrentDetails);
