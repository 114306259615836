// Unpublished Work © 2020-2024 Deere & Company.

import accounting from 'accounting';
import {DATA_TYPE_OVERRIDES} from 'Ui/components/common/data-overrides';
import {configForUnit as commonConfigForUnit} from 'Common/utils/units/config-for-unit';
import React from 'react';

const configForUnit = commonConfigForUnit;

function formatNumberWithUnit(number, unit, includeSpaces) {
    const config = commonConfigForUnit(unit);
    const formattedNumber = accounting.formatNumber(number, config.decimalPlaces);
    const space = includeSpaces ? ' ' : '';

    return config.prefix + space + formattedNumber + space + config.suffix;
}

function formatNumberWithSuffix(number, unit, includeSpaces) {
    const config = commonConfigForUnit(unit);
    const formattedNumber = accounting.formatNumber(number, config.decimalPlaces);
    const space = includeSpaces ? ' ' : '';
    const suffix = config.smallSuffix ? '' : config.suffix;
    const value = config.prefix + space + formattedNumber + space + suffix;

    return {
        value,
        smallSuffix: config.smallSuffix ? config.suffix : ''
    };
}

function createDataTypeMap(dataTypeMap, data) {
    dataTypeMap.set(data.dataType, data);

    return dataTypeMap;
}

function getThreshold(dataType, thresholdMap) {
    if (DATA_TYPE_OVERRIDES[dataType]) {
        return thresholdMap.get(DATA_TYPE_OVERRIDES[dataType]);
    }

    return thresholdMap.get(dataType);
}

function getPanelData(dataType, panelDataMap) {
    const overrideKey = Object.keys(DATA_TYPE_OVERRIDES).find((key) => DATA_TYPE_OVERRIDES[key] === dataType);

    if (panelDataMap.has(overrideKey)) {
        return panelDataMap.get(overrideKey);
    }

    return panelDataMap.get(dataType);
}

function mergeTileData(dataRollups, panelData, thresholds) {
    const panelDataMap = panelData.reduce(createDataTypeMap, new Map());
    const thresholdMap = thresholds.reduce(createDataTypeMap, new Map());

    return dataRollups.map((dataRollup) => {
        const threshold = getThreshold(dataRollup.dataType, thresholdMap);
        const panelData = getPanelData(dataRollup.dataType, panelDataMap);

        const data = {
            dataRollup
        };

        if (panelData) {
            data.panelData = panelData;
        }

        if (threshold) {
            data.threshold = threshold;
        }

        return data;
    });
}

function getDeleteEntryConfirmationMessage(translations) {
    return (
        <div>
            <div className='delete-entry'>
                <span className='warning-message'>
                    {translations.ONLINK_WARNING}
                </span>{translations.ONLINK_DELETE_ENTRY_WARNING}
            </div>
            {translations.ONLINK_CONTINUE_CONFIRMATION_MESSAGE}
        </div>
    );
}

export {
    configForUnit,
    formatNumberWithUnit,
    formatNumberWithSuffix,
    createDataTypeMap,
    mergeTileData,
    getDeleteEntryConfirmationMessage
};
