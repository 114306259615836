// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {useLazyRef} from 'Utils/react-utils';
import {debounce} from 'lodash';

function PageJump(props) {
    const {
        inputType,
        onBlur,
        onChange,
        onKeyPress,
        pageJumpText,
        value
    } = props;

    const debounceRef = useLazyRef(() => debounce(onBlur, window.props.debounceTimeout));

    function cancelAndRunCallback(callback, event) {
        debounceRef.current.cancel();

        callback(event);
    }

    function onChangePage(event) {
        cancelAndRunCallback(onChange, event);

        event.persist();

        debounceRef.current(event);
    }

    return (
        <div className='-pageJump'>
            <input
                aria-label={pageJumpText}
                onBlur={(event) => cancelAndRunCallback(onBlur, event)}
                onChange={onChangePage}
                onKeyPress={(event) => cancelAndRunCallback(onKeyPress, event)}
                type={inputType}
                value={value}
            />
        </div>
    );
}

PageJump.propTypes = {
    inputType: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    pageJumpText: PropTypes.string,
    value: PropTypes.stringOrNumber
};

export default PageJump;
