// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import LoadingIcon from 'Ui/components/common/loading-icon';
import {fetchFromS3} from 'Services/s3-service';
import 'Sass/persistent-header/ph-nav-pill.scss';

let fileUrl;

function WhatsNew(props) {
    const {
        s3Path,
        translations
    } = props;

    const [loading, setLoading] = React.useState(false);

    const downloadFile = React.useCallback(async () => {
        if (!fileUrl) {
            setLoading(true);

            const pdf = await fetchFromS3(s3Path);
            const file = new Blob([Buffer.from(pdf)], {
                type: 'application/pdf'
            });

            setLoading(false);

            fileUrl = URL.createObjectURL(file);
        }

        window.open(fileUrl);
    }, [s3Path]);

    return (
        <button
            className='nav-pill'
            disabled={loading}
            onClick={downloadFile}
            title={translations.new}
        >
            {translations.new}
            {
                loading &&
                <LoadingIcon
                    className='flex-loading-icon'
                    size='14px'
                />
            }
        </button>
    );
}

WhatsNew.propTypes = {
    s3Path: PropTypes.string,
    translations: PropTypes.translations
};

export default WhatsNew;
