// Unpublished Work © 2020-2024 Deere & Company.

const LABOR_1_ID = 'f5d2c96f-ba76-4abe-bd99-21fc17d6fcd1';
const LABOR_2_ID = '6a8027c9-0d51-474b-9f5c-17ffdb21f714';
const LABOR_3_ID = '6d687d5b-1cf7-4014-af12-b5a2b650f42a';
const LABOR_4_ID = 'c39d5da5-785d-426d-b15b-2316c7be7a81';

const LABOR_1_NAME = 'labor_1';
const LABOR_2_NAME = 'labor_2';
const LABOR_3_NAME = 'labor_3';
const LABOR_4_NAME = 'labor_4';

export default {
    tilePositions: [
        {
            tile: {
                id: LABOR_1_ID,
                name: LABOR_1_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: LABOR_1_NAME
            }
        },
        {
            tile: {
                id: LABOR_2_ID,
                name: LABOR_2_NAME
            },
            columnCount: 3,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: LABOR_2_NAME
            }
        },
        {
            tile: {
                id: LABOR_3_ID,
                name: LABOR_3_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 0,
                w: 1,
                h: 2,
                i: LABOR_3_NAME
            }
        },
        {
            tile: {
                id: LABOR_4_ID,
                name: LABOR_4_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: LABOR_4_NAME
            }
        },
        {
            tile: {
                id: LABOR_1_ID,
                name: LABOR_1_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: LABOR_1_NAME
            }
        },
        {
            tile: {
                id: LABOR_2_ID,
                name: LABOR_2_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: LABOR_2_NAME
            }
        },
        {
            tile: {
                id: LABOR_3_ID,
                name: LABOR_3_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: LABOR_3_NAME
            }
        },
        {
            tile: {
                id: LABOR_4_ID,
                name: LABOR_4_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 2,
                w: 1,
                h: 2,
                i: LABOR_4_NAME
            }
        },
        {
            tile: {
                id: LABOR_1_ID,
                name: LABOR_1_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: LABOR_1_NAME
            }
        },
        {
            tile: {
                id: LABOR_2_ID,
                name: LABOR_2_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: LABOR_2_NAME
            }
        },
        {
            tile: {
                id: LABOR_3_ID,
                name: LABOR_3_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 4,
                w: 1,
                h: 2,
                i: LABOR_3_NAME
            }
        },
        {
            tile: {
                id: LABOR_4_ID,
                name: LABOR_4_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 6,
                w: 1,
                h: 2,
                i: LABOR_4_NAME
            }
        }
    ]
};
