// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import StepInstructionsForm from 'Ui/components/common/form/step-instructions-form';
import UserMigrationTable from 'Ui/features/myJohnDeere-migration/user-migration-table';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {getMyJDUserOrganization} from 'Services/membership-service';
import {batchCreateMyJdOperators, batchUpsertOperatorLinks} from 'Services/my-jd-user-service';
import {loadMigrationDataTable} from 'Utils/myJohnDeereMigration/user-migration-table-data';
import dialogTypes from 'Ui/components/common/dialog-types';

function MyJohnDeereOperatorMigration(props) {
    const {
        closeMigrationConfirmationDialog,
        membership,
        openMigrationConfirmationDialog,
        translations,
        userRoles
    } = props;

    const [selectedOperators, setSelectedOperators] = React.useState(() => new Set());
    const [loading, setLoading] = React.useState({
        fetchOrgName: true,
        loadTableData: true
    });
    const [orgName, setOrgName] = React.useState(null);
    const [invites, setInvites] = React.useState({});
    const [rows, setRows] = React.useState([]);

    const onMigrateFunc = () => {
        openMigrationConfirmationDialog({
            translations,
            confirmTitle: translations.ONLINK_CONFIRM_USER_MIGRATION,
            migrationMessageBody: replaceTranslationNames(translations.ONLINK_CONFIRM_OPERATOR_MIGRATION_FOR_ORGANIZATION, {
                '0': selectedOperators.size,
                '1': orgName
            }),
            migrationConfirmButtonText: translations.ONLINK_CONFIRM_MIGRATION,
            successTitle: translations.ONLINK_OPERATORS_MIGRATION_SUCCESSFUL_TITLE,
            migrationSuccessBody: replaceTranslationNames(translations.ONLINK_CONFIRMED_USERS_MIGRATED_AS_OPERATORS, {
                '0': orgName
            }),
            teamManagerLinkText: replaceTranslationNames(translations.ONLINK_VIEW_IN_TEAM, {
                '0': translations.OPERATORS
            }),
            onClose: closeMigrationConfirmationDialog,
            migrationAction: async () => {
                const selectedOperatorsArr = [...selectedOperators];
                const operatorInputs = selectedOperatorsArr.map((appUserId) => ({
                    orgId: membership.orgId,
                    operatorName: invites[appUserId].name
                }));

                const createdOperators = await batchCreateMyJdOperators(operatorInputs);

                const operatorLinkInputs = createdOperators.map(({id: operatorId}, index) => ({
                    appUserId: selectedOperatorsArr[index],
                    operatorId
                }));

                await batchUpsertOperatorLinks(operatorLinkInputs);

                loadMigrationDataTable({
                    filterInvitedUsers: true,
                    isMounted: () => true,
                    setLoading: (loading) => setLoading((prevLoading) => ({
                        ...prevLoading,
                        loadTableData: loading
                    })),
                    setRows,
                    setInvites,
                    setSelected: setSelectedOperators,
                    translations,
                    userRoles
                });
            }
        });
    };

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const {name} = await getMyJDUserOrganization(membership.orgId);

        if (isMounted()) {
            setOrgName(name);
            setLoading((prevLoading) => ({
                ...prevLoading,
                fetchOrgName: false
            }));
        }
    }), []);

    return (
        <StepInstructionsForm
            steps={[
                {
                    title: translations.ONLINK_OPERATOR_MIGRATION_STEP_1_TITLE
                },
                {
                    title: translations.ONLINK_OPERATOR_MIGRATION_STEP_2_TITLE
                },
                {
                    title: translations.ONLINK_STAFF_MIGRATION_STEP3_TITLE,
                    body: [translations.ONLINK_OPERATOR_MIGRATION_STEP_3_BODY],
                    additionalComponent: (
                        <UserMigrationTable
                            disableDuplicateUsers={true}
                            disableMigrate={loading.fetchOrgName || loading.loadTableData || selectedOperators.size === 0}
                            editableEmailColumn={false}
                            filterInvitedUsers={true}
                            loading={loading.fetchOrgName || loading.loadTableData}
                            onMigrate={onMigrateFunc}
                            rows={rows}
                            selected={selectedOperators}
                            setInvites={setInvites}
                            setLoading={(loading) =>
                                setLoading((prevLoading) => ({
                                    ...prevLoading,
                                    loadTableData: loading
                                }))
                            }
                            setRows={setRows}
                            setSelected={setSelectedOperators}
                            translations={translations}
                            userRoles={userRoles}
                        />
                    )
                }
            ]}
            subtitles={[translations.ONLINK_OPERATOR_MIGRATION_SUBTITLE]}
            title={translations.ONLINK_OPERATOR_MIGRATION_TITLE}
        />
    );
}

MyJohnDeereOperatorMigration.propTypes = {
    closeMigrationConfirmationDialog: PropTypes.func,
    membership: PropTypes.membership,
    openMigrationConfirmationDialog: PropTypes.func,
    translations: PropTypes.translations,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        translations: state.translations,
        userRoles: state.role.userRoles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openMigrationConfirmationDialog(props) {
            dispatch(openDialog(dialogTypes.MIGRATION_CONFIRMATION_DIALOG, props));
        },
        closeMigrationConfirmationDialog() {
            dispatch(closeDialog(dialogTypes.MIGRATION_CONFIRMATION_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyJohnDeereOperatorMigration);
