// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {IconEdit} from '@deere/icons';
import {createIconFillStyle} from 'Utils/icon-utils';
import {getLabel} from 'Utils/workboard-settings-utils';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#fff');

function MyWorkSettings(props) {
    const {
        membership,
        setIsEditing,
        translations
    } = props;

    const properties = membership.properties;

    const showProtectionIcons = (
        <div className='settings-group horizontal'>
            <div className='settings-block-row'>{translations.ONLINK_SHOW_PROTECTION_ICONS}</div>
            <div className='settings-block-row'>
                {getLabel('mywork_show_protection_icons', properties.mywork_show_protection_icons, translations)}
            </div>
        </div>
    );

    return (
        <div className='settings-body workboard-settings'>
            <div className='settings-group horizontal-reverse'>
                <div className='edit-button'>
                    <OnlinkButton
                        className='primary'
                        leftIcon={
                            <IconEdit
                                iconEdit={ICON_EDIT_STYLE}
                                primary={PRIMARY_STYLE}
                            />
                        }
                        onClick={setIsEditing}
                    >
                        {translations.EDIT}
                    </OnlinkButton>
                </div>
                <div className='workboard-settings-block mywork-settings-block'>
                    <div className='settings-group mobile'>
                        <div className='settings-block'>
                            <div className='settings-block-row'>{translations.ONLINK_JOBS_DISPLAYED}</div>
                            <div className='settings-block-row'>
                                {getLabel('mywork_jobs_displayed', properties.mywork_jobs_displayed, translations)}
                            </div>
                        </div>
                        <div className='settings-block'>
                            <div className='settings-block-row'>{translations.ONLINK_WORKBOARD_VIEW}</div>
                            <div className='settings-block-row'>
                                {getLabel('mywork_workboard_view', properties.mywork_workboard_view, translations)}
                            </div>
                        </div>
                    </div>
                    <div className='settings-group horizontal'>
                        <div className='settings-block-row'>{translations.ONLINK_SHOW_ESTIMATED_HOURS}</div>
                        <div className='settings-block-row'>
                            {getLabel('mywork_show_estimated_hours', properties.mywork_show_estimated_hours, translations)}
                        </div>
                    </div>
                    <div className='settings-group horizontal'>
                        <div className='settings-block-row'>{translations.ONLINK_SHOW_OPERATOR_NOTE}</div>
                        <div className='settings-block-row'>
                            {getLabel('mywork_show_operator_note', properties.mywork_show_operator_note, translations)}
                        </div>
                    </div>
                    <div className='settings-group horizontal'>
                        <div className='settings-block-row'>{translations.ONLINK_SHOW_PRE_START_CHECK}</div>
                        <div className='settings-block-row'>
                            {getLabel('mywork_show_pre_start_check', properties.mywork_show_pre_start_check, translations)}
                        </div>
                    </div>
                    <div className='settings-group horizontal'>
                        <div className='settings-block-row'>{translations.ONLINK_SHOW_ROUTE}</div>
                        <div className='settings-block-row'>
                            {getLabel('mywork_show_route', properties.mywork_show_route, translations)}
                        </div>
                    </div>
                    {showProtectionIcons}
                </div>
            </div>
        </div>
    );
}

MyWorkSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        translations: state.translations,
        membership: state.membership
    };
}

export default connect(mapStateToProps)(MyWorkSettings);
