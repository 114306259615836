// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Icon, IconAlertTriangle} from '@deere/icons';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import InfoBoxText from 'Ui/components/map/elements/common/info-box-text';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnLinkInfoBox from 'Ui/components/map/elements/common/onlink-info-box';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {useDeepEffect} from 'Utils/react-utils';
import {getElectricMowerThreshold} from 'Utils/threshold-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import moment from 'moment';
import {ELECTRIFICATION} from 'Common/constants/feature-toggles';
import {EXTREME_BATTERY_THRESHOLD} from 'Ui/constants/threshold-constants';

const ICON_ALERT_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function formatMetric(value, precision, unit) {
    const formattedValue = formatNumber(value, {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision
    });

    return `${formattedValue} ${unit}`;
}

function getUtilization(utilization, translations) {
    if (utilization < 1) {
        const minutes = moment.duration(utilization, 'hours').asMinutes();

        return formatMetric(minutes, 2, translations.MIN_TIME);
    }

    return formatMetric(utilization, 2, translations.HOURS_UOM);
}

function formatMachineStateMetrics(state, translations) {
    const stateMetrics = [];

    if (!isNullOrUndefined(state.utilization)) {
        const utilization = getUtilization(state.utilization, translations);

        stateMetrics.push(utilization);
    }

    if (!isNullOrUndefined(state.avgGroundSpeed)) {
        stateMetrics.push(formatMetric(state.avgGroundSpeed, 2, translations.weather_mph));
    }

    return stateMetrics.join(' - ');
}

function getFuelLevels(equipment, machineMeasurements) {
    if (!isNullOrUndefined(equipment.fuelLevel)) {
        return Math.round(equipment.fuelLevel);
    }

    if (machineMeasurements?.fuelTankLevel) {
        return Math.round(machineMeasurements.fuelTankLevel);
    }

    return null;
}

function getBatteryLevels(machineMeasurements, featureToggles) {
    if (featureToggles[ELECTRIFICATION] && machineMeasurements?.batteryLevel) {
        return Math.round(machineMeasurements.batteryLevel);
    }

    return null;
}

function formatValues(equipment, translations, machineMeasurements, featureToggles) {
    const formattedEngineHours = !isNullOrUndefined(equipment.engineHours) ? (
        <InfoBoxText
            label={`${translations.ENGINE_HOURS}: `}
            text={formatMetric(equipment.engineHours, 1, translations.HOURS_UOM)}
        />
    ) : null;

    const fuelLevels = getFuelLevels(equipment, machineMeasurements);
    const batteryLevels = getBatteryLevels(machineMeasurements, featureToggles);

    const formattedFuelLevel = !isNullOrUndefined(fuelLevels) ? (
        <InfoBoxText
            label={`${translations.FUEL}: `}
            text={`${fuelLevels}${UNITS.percent}`}
        />
    ) : null;

    const formattedBatteryLevel = !isNullOrUndefined(batteryLevels) ? (
        <InfoBoxText
            className={getElectricMowerThreshold(batteryLevels).textClassName}
            label={`${translations.ONLINK_BATTERY_LEVEL}: `}
            text={`${batteryLevels}${UNITS.percent}`}
        >
            {
                batteryLevels < EXTREME_BATTERY_THRESHOLD &&
                <div className='low-battery-warning'>
                    <Tooltip
                        placement='bottom'
                        title={translations.ONLINK_LOW_BATTERY_WARNING}
                    >
                        <div>
                            <IconAlertTriangle iconAlertTriangle={ICON_ALERT_STYLE}/>
                        </div>
                    </Tooltip>
                </div>
            }
        </InfoBoxText>
    ) : null;

    const formattedWorking = machineMeasurements && !isNullOrUndefined(machineMeasurements.working) ? (
        <InfoBoxText
            label={`${translations.MACHINE_INSIGHTS_WORKING}: `}
            text={formatMachineStateMetrics(machineMeasurements.working, translations)}
        />
    ) : null;

    const formattedIdle = machineMeasurements && !isNullOrUndefined(machineMeasurements.idle) ? (
        <InfoBoxText
            label={`${translations.MACHINE_INSIGHTS_IDLE}: `}
            text={formatMachineStateMetrics(machineMeasurements.idle, translations)}
        />
    ) : null;

    const formattedTransport = machineMeasurements && !isNullOrUndefined(machineMeasurements.transport) ? (
        <InfoBoxText
            label={`${translations.MACHINE_INSIGHTS_TRANSPORT}: `}
            text={formatMachineStateMetrics(machineMeasurements.transport, translations)}
        />
    ) : null;

    return {
        formattedEngineHours,
        formattedFuelLevel,
        formattedBatteryLevel,
        formattedWorking,
        formattedIdle,
        formattedTransport
    };
}

function EquipmentInfoBox(props) {
    const {
        equipment,
        loading,
        machineMeasurements,
        setSelectedEquipment,
        translations,
        featureToggles,
        setPosition
    } = props;

    const [overlayView, setOverlayView] = React.useState(null);

    useDeepEffect(() => {
        if (overlayView) {
            overlayView.draw();
        }
    }, [loading, machineMeasurements]);

    const {
        formattedEngineHours,
        formattedFuelLevel,
        formattedBatteryLevel,
        formattedWorking,
        formattedIdle,
        formattedTransport
    } = formatValues(equipment, translations, machineMeasurements, featureToggles);

    const equipmentName = equipment.equipmentName ? (
        <div className='bold-body-text-section-title'>
            {equipment.equipmentName}
        </div>
    ) : null;
    const equipmentType = equipment.equipmentType && equipment.modelName ? (
        <div className='info-box-text'>
            {`${equipment.modelName} ${equipment.equipmentType}`}
        </div>
    ) : null;
    const breadCrumbTimeStamp = equipment.timeStamp ? (
        <div className='info-box-text'>
            {formatTimeOrDefault(equipment.timeStamp)}
        </div>
    ) : null;

    const closeHandler = setSelectedEquipment || setPosition ? () => {
        setSelectedEquipment(null);
        setPosition(null);
    } : null;

    return (
        <OnLinkInfoBox
            className='equipment-info-box'
            closeHandler={closeHandler}
            onLoad={setOverlayView}
            position={{
                lat: equipment.lat || 0,
                lng: equipment.lon || 0
            }}
        >
            <LoadingWrapper
                className='equipment-info-box-loading-icon'
                loading={loading}
                size='50px'
            >
                <Icon
                    height={70}
                    iconName={equipment.icon && equipment.icon.name || null}
                    iconStyles={equipment.icon && equipment.icon.iconStyle || null}
                    width={70}
                />
                <div className='info-box-item-block'>
                    {equipmentName}
                    {equipmentType}
                    {breadCrumbTimeStamp}
                </div>
                <div className='info-box-item-block'>
                    {formattedEngineHours}
                    {formattedFuelLevel}
                    {formattedBatteryLevel}
                    {formattedWorking}
                    {formattedIdle}
                    {formattedTransport}
                </div>
            </LoadingWrapper>
        </OnLinkInfoBox>
    );
}

EquipmentInfoBox.propTypes = {
    equipment: PropTypes.equipment,
    featureToggles: PropTypes.featureToggles,
    loading: PropTypes.bool,
    machineMeasurements: PropTypes.machineMeasurements,
    setPosition: PropTypes.func,
    setSelectedEquipment: PropTypes.func,
    translations: PropTypes.translations
};

export default EquipmentInfoBox;
