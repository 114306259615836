// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getPartBins(inventoryId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/inventory/${inventoryId}/partBins`);

    return response.data;
}

async function deletePartBin(partBinId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/partBins/${partBinId}`);

    return response.data;
}

async function getPartsFromInventoryBins(inventories) {
    const {partBins} = await getPartBins(inventories[0].inventoryId);

    return partBins;
}

async function getParts() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/inventory/parts');

    return response.data;
}

async function getManufacturers() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/inventory/manufacturers');

    return response.data;
}

async function getPartTypes(inventoryId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/inventory/${inventoryId}/partTypes`);

    return response.data;
}

async function deletePartTypes(inventoryId, partTypeId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/inventory/${inventoryId}/partTypes/${partTypeId}`);

    return response.data;
}

async function updatePartType(inventoryId, partTypeId, partTypeData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/inventory/${inventoryId}/partTypes/${partTypeId}`, partTypeData);

    return response.data;
}

async function createPartType(inventoryId, partTypeData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post(`/api/inventory/${inventoryId}/partTypes`, partTypeData);

    return response.data;
}

async function postInventoryTransfers(transferData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/inventoryTransfers', transferData);

    return response.data;
}

async function getPartRequestsByDestination(inventoryId, status) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/inventory/${inventoryId}/partRequests/destination/${status}`);

    return response.data;
}

async function getPurchaseOrders(inventoryId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/inventory/${inventoryId}/purchaseOrders`);

    return response.data;
}

async function createPurchaseOrder(purchaseOrder) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/purchaseOrders', purchaseOrder);

    return response.data;
}

async function updatePurchaseOrder(purchaseOrder, startingStatus) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put('/api/purchaseOrders', {
        ...purchaseOrder,
        startingStatus
    });

    return response.data;
}

async function getPurchaseOrder(purchaseOrderId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/purchaseOrders/${purchaseOrderId}`);

    return response.data;
}

async function deletePurchaseOrder(purchaseOrderId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/purchaseOrders/${purchaseOrderId}`);

    return response.data;
}

async function getPartRequestsForInventories(inventories, status) {
    const partRequestsPromises = inventories.map(async (inventory) => {
        const {partRequests} = await getPartRequestsByDestination(inventory.inventoryId, status);

        return partRequests;
    });

    const allPartRequests = await Promise.all(partRequestsPromises);

    return allPartRequests.reduce((combinedPartRequests, partRequests) => combinedPartRequests.concat(partRequests), []);
}

async function getBinLocations(inventoryId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/inventory/${inventoryId}/binLocations`);

    return response.data;
}

async function updatePartBin(partBinId, partBinData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/partBins/${partBinId}`, partBinData);

    return response.data;
}

async function deleteBinLocation(binLocationId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/binLocations/${binLocationId}`);

    return response.data;
}

async function createBinLocation(binLocationData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/binLocations', binLocationData);

    return response.data;
}

async function updateBinLocation(binLocationId, binLocationData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/binLocations/${binLocationId}`, binLocationData);

    return response.data;
}

async function updateInventory(inventoryId, inventoryData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/inventories/${inventoryId}`, inventoryData);

    return response.data;
}

export {
    getPartBins,
    getPartsFromInventoryBins,
    getParts,
    getManufacturers,
    getPartTypes,
    deletePartTypes,
    updatePartType,
    createPartType,
    getPartRequestsForInventories,
    getPurchaseOrders,
    getPurchaseOrder,
    createPurchaseOrder,
    updatePurchaseOrder,
    postInventoryTransfers,
    getBinLocations,
    updatePartBin,
    deleteBinLocation,
    createBinLocation,
    updateBinLocation,
    updateInventory,
    deletePurchaseOrder,
    deletePartBin
};
