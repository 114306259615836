// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import Panel from 'Ui/components/panel/panel';
import {DatePicker} from '@deere/isg.component-library/lib/DatePicker';
import CostByCategory from 'Ui/components/graph/PlayBooks/cost-by-category';
import Inventory from 'Ui/components/graph/PlayBooks/inventory';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import Nutrients from 'Ui/components/graph/PlayBooks/nutrients';
import TabLayout from 'Ui/components/tab/tab-layout';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {VIEW_FINANCIAL_DATA} from 'Common/constants/business-activities';
import {getDataSource} from 'Services/panel-service';

function getTabElements(hasFinanceAuth, playBooksData, sharedProps) {
    const sharedPropsWithFinanceAuth = {
        ...sharedProps,
        hasFinanceAuth
    };

    const tabElementsWithAuth = hasFinanceAuth ? [
        <CostByCategory
            {...sharedPropsWithFinanceAuth}
            data={playBooksData.costByCategory}
            key='costByCategory'
        />
    ] : [];

    return [
        ...tabElementsWithAuth,
        <Inventory
            {...sharedPropsWithFinanceAuth}
            data={playBooksData.inventory}
            key='inventory'
        />,
        <Nutrients
            {...sharedPropsWithFinanceAuth}
            data={playBooksData.nutrients}
            key='nutrients'
        />
    ];
}

function PlayBooks(props) {
    const {
        currencyPreference,
        featureToggles,
        isMigrated,
        membershipId,
        myJdPermissions,
        playbooksUrl,
        translations
    } = props;

    const [playBooksData, setPlayBooksData] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const now = new Date();

    const fetchData = React.useCallback(async (date, isMounted) => {
        setLoading(true);

        const dateSelect = formatTime(date, DATE_FORMATS.day);
        const rawPlayBooksData = await getDataSource('PlayBooks', dateSelect);
        const formattedPlayBooksData = {
            costByCategory: rawPlayBooksData.cost_by_category?.tabularData,
            inventory: rawPlayBooksData.inventory?.tabularData,
            nutrients: rawPlayBooksData.nutrients?.tabularData
        };

        if (isMounted()) {
            setPlayBooksData(formattedPlayBooksData);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => fetchEffectData((isMounted) => fetchData(new Date(), isMounted)), [membershipId]);

    const hasFinanceAuth = useDeepMemo(() => isAuthorized({
        myJdPermissions: VIEW_FINANCIAL_DATA
    }, {
        isMigrated,
        myJdPermissions
    }), [isMigrated, myJdPermissions]);

    const tabs = React.useMemo(() => {
        const tabsWithAuth = hasFinanceAuth ? [translations.ONLINK_COST_BY_CATEGORY] : [];

        return [...tabsWithAuth, translations.ONLINK_INVENTORY, translations.ONLINK_NUTRIENTS];
    }, [hasFinanceAuth, translations.ONLINK_COST_BY_CATEGORY, translations.ONLINK_INVENTORY, translations.ONLINK_NUTRIENTS]);

    return (
        <Panel
            className='integrations'
            title='Playbooks'
            translations={translations}
        >
            <div className='integrations-title'>
                <a
                    href={playbooksUrl}
                    rel='noreferrer'
                    target='_blank'
                >
                    {translations.ONLINK_OPEN_PLAYBOOKS}
                </a>
            </div>
            <div className='integrations-date-picker'>
                <DatePicker
                    defaultValue={now}
                    maxDate={now}
                    onChange={([date]) => fetchData(date, () => true)}
                />
            </div>
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <TabLayout
                    className='play-books-tab-layout'
                    tabs={tabs}
                >
                    {
                        getTabElements(hasFinanceAuth, playBooksData, {
                            currencyPreference,
                            featureToggles,
                            translations
                        })
                    }
                </TabLayout>
            </LoadingWrapper>
        </Panel>
    );
}

PlayBooks.propTypes = {
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    playbooksUrl: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    const properties = state.membership.properties;
    const url = properties ? properties.partner_link_playbooks_url : '';

    return {
        currencyPreference: state.membership.currencyPreference,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        myJdPermissions: state.account.myJdPermissions,
        playbooksUrl: url
    };
}

export default connect(mapStateToProps)(PlayBooks);
