// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import TileCategory from 'Ui/components/tile-editor/tile-category';
import {categories} from 'Ui/components/common/tile-categories';
import {fetchEffectData} from 'Utils/react-utils';
import {isTileVisible} from 'Utils/tile-utils';
import {sortBy} from 'lodash';
import {getTiles} from 'Services/user-view-service';
import {connect} from 'react-redux';

function TileEditor(props) {
    const {
        activeTiles,
        setActiveTiles,
        translations,
        featureToggles,
        isMigrated,
        myJdPermissions
    } = props;

    const initialActiveTiles = React.useRef(activeTiles);

    const onChange = React.useCallback((event) => {
        setActiveTiles({
            ...activeTiles,
            [event.target.id]: event.target.checked
        });
    }, [activeTiles]);

    const userAuth = React.useMemo(() => ({
        isMigrated,
        myJdPermissions,
        featureToggles
    }), [isMigrated, myJdPermissions, featureToggles]);

    const [tilesByCategory, setTilesByCategoryId] = React.useState({});

    React.useEffect(() => fetchEffectData(async () => {
        const tiles = await getTiles();
        const tileMap = tiles.filter(({id}) => initialActiveTiles.current[id] || isTileVisible(id, userAuth)
        ).reduce((map, tile) => {
            map[tile.id] = tile;

            return map;
        }, {});

        setTilesByCategoryId(categories.reduce((map, {
            name, tiles: tileIds
        }) => {
            map[name] = sortBy(
                tileIds.map((id) => tileMap[id]).filter(Boolean),
                'name'
            );

            return map;
        }, {}));
    }), [initialActiveTiles, userAuth]);

    return (
        <div className='tile-editor'>
            {
                categories.map(({name}) => {
                    return (<TileCategory
                        activeTiles={activeTiles}
                        initialActiveTiles={initialActiveTiles.current}
                        key={name}
                        onChange={onChange}
                        tiles={tilesByCategory[name] || []}
                        translations={translations}
                        userAuth={userAuth}
                    />);
                })
            }
        </div>
    );
}

TileEditor.propTypes = {
    activeTiles: PropTypes.objectOfBool,
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    setActiveTiles: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(TileEditor);
