// Unpublished Work © 2021-2024 Deere & Company.

import {redirect} from 'Utils/redirect-utils';

function redirectAndToggleDropdown(navItem, history, toggleDropdown) {
    if (navItem.link.onClick) {
        navItem.link.onClick();
    }

    redirect(navItem.link.path, navItem.link.reactRouter ? history : null);
    toggleDropdown();
}

function isActive(navLink, location) {
    const routeLocation = navLink.reactRouter ? location.pathname : window.location.href;
    const lowerCaseLocation = routeLocation.toLowerCase();

    let noActivePrioritizedLinks = true;

    if (navLink.prioritizeList) {
        noActivePrioritizedLinks = !navLink.prioritizeList.some((path) => {
            return lowerCaseLocation.startsWith(path.toLowerCase());
        });
    }

    return noActivePrioritizedLinks && lowerCaseLocation.startsWith(navLink.basePath?.toLowerCase());
}

export {
    redirectAndToggleDropdown,
    isActive
};
