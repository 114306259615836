// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {onManualDataChange, validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {getCurrencySymbol, getLanguagePreference} from 'Utils/unit-conversion-utils';
import {MIN_NUMERIC_VALUE, MAX_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {WATER_COST} from 'Common/constants/data-group-constants';
import {DOLLARS_PER_THOUSAND_GALLONS, DOLLARS_PER_THOUSAND_LITERS} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import moment from 'moment';

function isUnitOfMeasureMetric(membership, values) {
    if (values?.manualData && values.manualData[0]?.data_unit) {
        return values.manualData[0]?.data_unit === DOLLARS_PER_THOUSAND_LITERS;
    }
    return membership.unitOfMeasure === UNIT_OF_MEASURE.METRIC;
}

function WaterCostEntryForm(props) {
    const {
        featureToggles,
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const [validatedDate, setValidatedDate] = React.useState();

    React.useEffect(() => {
        setValidatedDate(validateDate(values.timeSample, setValid));
    }, [values.timeSample]);

    const isUOMMetric = isUnitOfMeasureMetric(membership, values);
    const unit = isUOMMetric ? translations.STANDARD_UOM_LITER : translations.GALLONS;
    const dataUnit = isUOMMetric ? DOLLARS_PER_THOUSAND_LITERS : DOLLARS_PER_THOUSAND_GALLONS;

    const currencySymbol = getCurrencySymbol({
        currencyPreference: membership.currencyPreference,
        featureToggles
    });

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
            />
            <ValidationInput
                debounceTimeout={window.props.debounceTimeout}
                errors={{
                    rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${MIN_NUMERIC_VALUE}`,
                    rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`
                }}
                label={`${translations.ONLINK_PRICE} (${currencySymbol}/1000 ${unit})`}
                max={MAX_NUMERIC_VALUE}
                min={MIN_NUMERIC_VALUE}
                name={WATER_COST}
                onChange={(event) => onManualDataChange({
                    dataUnit,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                required={true}
                setValid={setValid}
                tabIndex={0}
                type='number'
                value={values.manualData ? values.manualData[0]?.value : ''}
            />
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

WaterCostEntryForm.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(WaterCostEntryForm);
