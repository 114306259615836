// Unpublished Work © 2023-2024 Deere & Company.

import {
    BASKET, CM, COUNT, DEGREES_C, DEGREES_F, DOLLARS, DOLLARS_PER_KILOWATT_HOUR,
    F355_EQUIVALENTS, FEET, FEET_AND_INCHES, GALLONS, GRAVITIES, HOURS, INCHES, KILOWATT_HOURS,
    METERS, MM, OUNCES, OZS, PERCENT, POUNDS, QUARTS, SALINITY, THOUSAND_GALLONS
} from 'Common/constants/data-unit-constants';
import {UNIT_CONFIG, UNITS} from 'Common/constants/units/unit-config-constants';

export function configForUnit(unit, isConfigForGraph = false) {
    const config = {
        unit,
        prefix: '',
        suffix: '',
        decimalPlaces: 0,
        includeSpace: false
    };

    switch (unit) {
        case DEGREES_F:
            return {
                ...config,
                suffix: UNITS.fahrenheit
            };
        case DEGREES_C:
            return {
                ...config,
                suffix: UNITS.celsius
            };
        case PERCENT:
            return {
                ...config,
                suffix: UNITS.percent
            };
        case INCHES:
            return {
                ...config,
                decimalPlaces: 3,
                suffix: UNITS.inches
            };
        case CM:
            return {
                ...config,
                decimalPlaces: 1,
                includeSpace: true,
                suffix: UNITS.cm
            };
        case MM:
            return {
                ...config,
                decimalPlaces: 1,
                includeSpace: true,
                suffix: UNITS.mm
            };
        case FEET:
            return {
                ...config,
                decimalPlaces: 2,
                suffix: UNITS.feet
            };
        case COUNT:
            return config;
        case BASKET:
            return {
                ...config,
                decimalPlaces: 2,
                includeSpace: true,
                suffix: UNITS.basket
            };
        case QUARTS:
            return {
                ...config,
                includeSpace: true,
                smallSuffix: true,
                suffix: UNITS.quarts
            };
        case GALLONS:
            return {
                ...config,
                decimalPlaces: 2,
                includeSpace: true,
                smallSuffix: true,
                suffix: UNITS.gallons
            };
        case THOUSAND_GALLONS:
            return {
                ...config,
                smallSuffix: true,
                suffix: UNITS.oneKGallons
            };
        case DOLLARS:
            return {
                ...config,
                decimalPlaces: 2,
                prefix: UNITS.dollars
            };
        case KILOWATT_HOURS:
            return {
                ...config,
                includeSpace: true,
                smallSuffix: true,
                suffix: UNITS.kilowattHour
            };
        case DOLLARS_PER_KILOWATT_HOUR:
            return {
                ...config,
                decimalPlaces: 2,
                prefix: UNITS.dollars,
                smallSuffix: true,
                suffix: `/${UNITS.kilowattHour}`
            };
        case POUNDS:
            return {
                ...config,
                decimalPlaces: 2,
                includeSpace: true,
                smallSuffix: true,
                suffix: UNITS.pounds
            };
        case HOURS:
            return {
                ...config,
                smallSuffix: true,
                suffix: UNITS.hours
            };
        case SALINITY:
            return {
                ...config,
                decimalPlaces: 2,
                smallSuffix: true,
                suffix: UNITS.ec
            };
        case OUNCES:
        case OZS:
            return {
                ...config,
                decimalPlaces: 2,
                includeSpace: true,
                smallSuffix: true,
                suffix: UNITS.ounces
            };
        case METERS:
            return {
                ...config,
                ...UNIT_CONFIG.METERS
            };
        case FEET_AND_INCHES:
            return isConfigForGraph ? {
                ...config,
                ...UNIT_CONFIG.FEET_AND_INCHES_GRAPH
            } : {
                ...config,
                ...UNIT_CONFIG.FEET_AND_INCHES
            };
        case GRAVITIES:
            return {
                ...config,
                ...UNIT_CONFIG.GRAVITIES
            };
        case F355_EQUIVALENTS:
            return {
                ...config,
                ...UNIT_CONFIG.F355
            };
        default:
            return {
                ...config,
                includeSpace: true,
                suffix: config.unit
            };
    }
}
