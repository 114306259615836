// Unpublished Work © 2020-2024 Deere & Company.

export const DATA_TYPE_OVERRIDES = {
    'water_cost': 'total_water_cost',
    'water_irrigation': 'water_flow',
    'water_usage': 'water_transfer'
};

export const GROUPED_DATA_TYPES = {
    'clipping_yield': ['clipping_yield', 'clipping_yield_volume', 'clipping_yield_weight'],
    'firmness': ['firmness', 'firmness_gravities', 'firmness_f355_equivalents']
};

export const GROUPED_DATA_TYPE_OVERRIDES = Object.keys(GROUPED_DATA_TYPES).reduce((dataTypeOverrides, dataType) => {
    return GROUPED_DATA_TYPES[dataType].reduce((groupedDataTypeOverrides, groupedDataType) => {
        groupedDataTypeOverrides[groupedDataType] = dataType;

        return groupedDataTypeOverrides;
    }, dataTypeOverrides);
}, {});
