// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import {formatNumberWithUnit} from 'Utils/data-utils';
import {formatLocalizedTime} from 'Utils/time-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {DEGREES_F, MILES_PER_HOUR} from 'Common/constants/data-unit-constants';
import {convertToFormattedUoM, convertToUoM} from 'Utils/unit-conversion-utils';
import moment from 'moment';

function mapForecastData(data, tempConversionConfig, windConversionConfig) {
    const day = data.day || data.night;

    const maxTemp = data.max_temp ? convertToFormattedUoM(data.max_temp, tempConversionConfig) : ' ';
    const minTemp = data.min_temp ? convertToFormattedUoM(data.min_temp, tempConversionConfig) : ' ';
    const windSpeed = day.wspd ? Math.round(convertToUoM(day.wspd, windConversionConfig)) : ' ';

    return [
        formatLocalizedTime(moment(day.fcst_valid_local), {
            weekday: 'short',
            day: 'numeric'
        }),
        `${maxTemp}/${minTemp}`,
        <img
            alt={day.phrase_22char}
            className='dashboard-panel-icon'
            src={getWeatherIcon(day.icon_code)}
        />,
        formatNumberWithUnit(day.pop, 'percent', 0),
        formatNumberWithUnit(day.rh, 'percent', 0),
        `${day.wdir_cardinal} ${windSpeed}`
    ];
}

function DailyForecast(props) {
    const {
        featureToggles,
        forecastData,
        translations,
        unitOfMeasure,
        windSpeedUnitOfMeasure
    } = props;

    const headers = [
        translations.ONLINK_DAY,
        `${translations.WEATHER_HIGH}/${translations.WEATHER_LOW}`,
        '',
        translations.ONLINK_PRECIP,
        translations.ONLINK_HUMID,
        translations.weather_WIND
    ];

    const conversionConfig = {
        featureToggles,
        translations,
        unitOfMeasure
    };

    const tempConversionConfig = {
        ...conversionConfig,
        dataUnit: DEGREES_F
    };

    const windConversionConfig = {
        ...conversionConfig,
        unitOfMeasure: windSpeedUnitOfMeasure,
        dataUnit: MILES_PER_HOUR
    };

    const rows = forecastData.map((data) => mapForecastData(data, tempConversionConfig, windConversionConfig));

    return (
        <BasicTable
            className='forecast-table daily'
            headers={headers}
            rows={rows}
            title={`${translations.NEXT} ${rows.length} ${translations.DAYS}`}
        />
    );
}

DailyForecast.propTypes = {
    featureToggles: PropTypes.featureToggles,
    forecastData: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    windSpeedUnitOfMeasure: PropTypes.string
};

export default DailyForecast;
