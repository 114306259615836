// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import WorkboardHistoryWidget from 'Ui/features/workboard-wizard/workboard-history/workboard-history-widget';
import {getJobsForWorkboards} from 'Services/job-service';
import {fetchEffectData, useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import {
    getCurrentWorkboardIndex,
    getCurrentWorkboardItems,
    getListWithCurrentWorkboard,
    getMostRecentWorkboardIndex,
    getWorkboardDetails,
    transformJobsToWorkItems
} from 'Ui/features/workboard-wizard/utils/workboard-history-transformers';
import {ONLABOR_CURRENT_WORKBOARD_WIDGET} from 'Common/constants/feature-toggles';
import {NEW_WORKBOARD_ID} from 'Ui/features/workboard-wizard/constants/workboard-history';
import {cloneDeep} from 'lodash';

const INVALID_DATE = 'Invalid date';

function isValidDate(date) {
    return date && date !== INVALID_DATE;
}

function WorkboardHistoryWidgetWrapper(props) {
    const {
        featureToggles,
        flattenedJobs,
        operatorsMasterList,
        screenTouchListener,
        screenTouchTimerRef,
        selectedIds,
        setWorkItemsByWorkboardMap,
        templatesById,
        translations,
        values,
        workboardId: currentWorkboardId,
        workboards,
        workItemsByWorkboardMap,
        templates,
        workboardCreationFlow,
        readOnly
    } = props;

    const workItemsForCurrentWorkboard = useDeepMemo(() => getCurrentWorkboardItems({
        jobs: flattenedJobs,
        selectedIds,
        templatesById,
        workItemsByOperator: values.workItemsByOperator
    }), [flattenedJobs, selectedIds, templatesById, values.workItemsByOperator]);

    const workboardsToDisplay = useDeepMemo(() => {
        const workboardsWithValidDate = workboards.filter(({dateSelect}) => isValidDate(dateSelect));

        if (!featureToggles[ONLABOR_CURRENT_WORKBOARD_WIDGET]) {
            return workboardsWithValidDate.filter(({workboardId}) => workboardId !== currentWorkboardId);
        }

        return getListWithCurrentWorkboard({
            currentWorkboardId,
            values,
            workboards: workboardsWithValidDate,
            workItemsForCurrentWorkboard
        });
    }, [
        currentWorkboardId,
        featureToggles[ONLABOR_CURRENT_WORKBOARD_WIDGET],
        values.date,
        values.name,
        workboards,
        workItemsForCurrentWorkboard
    ]);

    const [workboardIndex, setWorkboardIndex] = React.useState(() => {
        const initialWorkboardIndex = featureToggles[ONLABOR_CURRENT_WORKBOARD_WIDGET] ?
            getCurrentWorkboardIndex(workboardsToDisplay, currentWorkboardId) :
            getMostRecentWorkboardIndex(workboardsToDisplay, values.date);

        return Math.max(initialWorkboardIndex, 0);
    });

    const {
        pastAndFutureWorkboardIds,
        selectedWorkboard,
        workItemsForSelectedWorkboard
    } = useDeepMemo(() => getWorkboardDetails({
        workboards: workboardsToDisplay,
        workboardIndex,
        workItemsByWorkboard: workItemsByWorkboardMap
    }), [workboardIndex, workboardsToDisplay, workItemsByWorkboardMap]);

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        if (pastAndFutureWorkboardIds.length > 0 && !workItemsForSelectedWorkboard) {
            const {jobs} = await getJobsForWorkboards(pastAndFutureWorkboardIds);

            if (isMounted()) {
                setWorkItemsByWorkboardMap((prevWorkItemsByWorkboardMap) => transformJobsToWorkItems({
                    jobs,
                    pastAndFutureWorkboardIds,
                    workItemsByWorkboard: prevWorkItemsByWorkboardMap
                }));
            }
        } else if (selectedWorkboard?.workboardId === currentWorkboardId) {
            setWorkItemsByWorkboardMap((prevWorkItemsByWorkboardMap) => {
                const clonedWorkItemsByWorkboardMap = cloneDeep(prevWorkItemsByWorkboardMap);

                clonedWorkItemsByWorkboardMap.set(currentWorkboardId, workItemsForCurrentWorkboard);

                return clonedWorkItemsByWorkboardMap;
            });
        }
    }), [
        currentWorkboardId,
        pastAndFutureWorkboardIds,
        selectedWorkboard,
        workItemsForCurrentWorkboard,
        workItemsForSelectedWorkboard
    ]);

    return (
        <WorkboardHistoryWidget
            featureToggles={featureToggles}
            operatorsMasterList={operatorsMasterList}
            readOnly={readOnly}
            screenTouchListener={screenTouchListener}
            screenTouchTimerRef={screenTouchTimerRef}
            setWorkboardIndex={setWorkboardIndex}
            templates={templates}
            translations={translations}
            workItemsForWorkboard={workItemsForSelectedWorkboard}
            workboardCreationFlow={workboardCreationFlow}
            workboardId={currentWorkboardId}
            workboardIndex={workboardIndex}
            workboards={workboardsToDisplay}
        />
    );
}

WorkboardHistoryWidgetWrapper.defaultProps = {
    workboardId: NEW_WORKBOARD_ID
};

WorkboardHistoryWidgetWrapper.propTypes = {
    featureToggles: PropTypes.featureToggles,
    flattenedJobs: PropTypes.arrayOf(PropTypes.object),
    operatorsMasterList: PropTypes.arrayOf(PropTypes.user),
    readOnly: PropTypes.bool,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    setWorkItemsByWorkboardMap: PropTypes.func,
    templates: PropTypes.arrayOf(PropTypes.object),
    templatesById: PropTypes.instanceOf(Map),
    translations: PropTypes.translations,
    values: PropTypes.object,
    workboardCreationFlow: PropTypes.string,
    workboardId: PropTypes.string,
    workboards: PropTypes.arrayOf(PropTypes.object),
    workItemsByWorkboardMap: PropTypes.instanceOf(Map)
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(WorkboardHistoryWidgetWrapper);
