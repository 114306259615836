// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import {getSearchParams} from 'OnEquip/equipment/utils/url-parser';
import EquipmentDetails from 'OnEquip/equipment/equipment-details/equipment-details';
import {ASSIGNMENTS, FLEET, SERVICE_HISTORY, SHOP, SUGGESTED_MAINTENANCE} from 'Common/constants/routes';

const redirectLinks = {
    fleet: {
        path: FLEET
    },
    assignments: {
        path: ASSIGNMENTS
    },
    suggestedMaintenance: {
        path: SUGGESTED_MAINTENANCE
    },
    serviceHistory: {
        path: SERVICE_HISTORY
    },
    shop: {
        path: SHOP
    }
};

function EquipmentDetailsWrapper(props) {
    const {
        location,
        match,
        translations
    } = props;

    const {
        type,
        tab
    } = match.params;

    const {equipmentId} = getSearchParams(location.search);
    const redirectLink = redirectLinks[type];

    return (
        <EquipmentDetails
            equipmentId={equipmentId}
            redirect={redirectLink}
            tab={tab}
            translations={translations}
        />
    );
}

EquipmentDetailsWrapper.propTypes = {
    location: PropTypes.location,
    match: PropTypes.match,
    translations: PropTypes.translations
};

export default withRouter(EquipmentDetailsWrapper);
