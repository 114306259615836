// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import ListBox from 'Ui/components/common/list-box/list-box';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {deleteCourseNameToIdMap, getCourseNames} from 'Services/vendor-service';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {fetchEffectData} from 'Utils/react-utils';
import dialogTypes from 'Ui/components/common/dialog-types';

function PogoCourseNameSettings(props) {
    const {
        closeConfirmation,
        membershipId,
        openConfirmation,
        openCourseNameDialog,
        translations
    } = props;

    const [courseNames, setCourseNames] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {courseNames: courseNamesData} = await getCourseNames(membershipId, 'pogo');

        if (isMounted()) {
            setCourseNames(courseNamesData);
            setLoading(false);
        }
    }), [membershipId]);

    return (
        <div className='settings-container'>
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <ListBox
                    addButtonLabel={translations.ONLINK_ADD_COURSE_NAME}
                    className='settings-body'
                    headerLabel={translations.ONLINK_COURSE_NAMES}
                    items={courseNames}
                    noDataText={translations.ONLINK_NO_COURSE_NAMES_ASSIGNED}
                    onAdd={() => openCourseNameDialog({
                        updateCourseNames(courseName) {
                            setCourseNames((prevCourseNames) => [
                                ...prevCourseNames,
                                courseName
                            ]);
                        }
                    })}
                    onDelete={(removedCourseName) => openConfirmation({
                        message: translations.ONLINK_COURSE_NAME_THIS_CANNOT_BE_UNDONE,
                        title: translations.ONLINK_DELETE_COURSE_NAME,
                        async onContinue() {
                            await deleteCourseNameToIdMap('pogo', removedCourseName);

                            setCourseNames((prevCourseNames) => prevCourseNames
                                .filter((courseName) => courseName !== removedCourseName));

                            closeConfirmation();
                        },
                        onCancel: closeConfirmation
                    })}
                />
            </LoadingWrapper>
        </div>
    );
}

PogoCourseNameSettings.propTypes = {
    closeConfirmation: PropTypes.func,
    membershipId: PropTypes.string,
    openConfirmation: PropTypes.func,
    openCourseNameDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openCourseNameDialog(props) {
            dispatch(openDialog(dialogTypes.COURSE_NAME_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PogoCourseNameSettings);
