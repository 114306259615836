// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import TileLayout from 'Ui/components/dashboard/tile-layout';
import {getMembershipIntegrations} from 'Services/membership-service';
import {getPanelThresholds, getTileData, getMyJDTileData} from 'Services/panel-service';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {getTileIds} from 'Utils/dashboard-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {setActiveFocusInterval} from 'Utils/refresh-utils';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import tileInfo from 'Ui/components/common/tile-constants/tile-info';
import dialogTypes from 'Ui/components/common/dialog-types';
import {MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND} from 'Ui/constants/time-constants';
import {COURSE} from 'Common/constants/membership-type';
import {VIEW_COURSE_MEASUREMENT_DATA} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

const FIVE = 5;
const FIVE_MINUTES_IN_MILLISECONDS = MILLISECONDS_PER_MINUTE * FIVE;

function createTileMapKey(tileInfoForTileId) {
    if (tileInfoForTileId.queryParams) {
        return `${tileInfoForTileId.url}${JSON.stringify(tileInfoForTileId.queryParams())}`;
    }

    return tileInfoForTileId.url || tileInfoForTileId.myjdUrl;
}

function mapTileUrls(tileUrls, tileId, userAuth) {
    const tileInfoForTileId = tileInfo[tileId];

    if (!isAuthorized(tileInfoForTileId, userAuth)) {
        return tileUrls;
    }

    const key = createTileMapKey(tileInfoForTileId);

    if (!tileUrls.has(key)) {
        const tileMapData = {
            queryParams: Object.assign({}, tileInfoForTileId.queryParams?.()),
            tileIds: [],
            tileUrl: tileInfoForTileId.url,
            myjdUrl: tileInfoForTileId.myjdUrl
        };

        if (!tileInfoForTileId.skipDate) {
            tileMapData.queryParams.date = formatTime(new Date(), DATE_FORMATS.day);
        }

        tileUrls.set(key, tileMapData);
    }

    tileUrls.get(key).tileIds.push(tileId);

    return tileUrls;
}

async function getDataForTile(tileUrl, tileIds, queryParams, myjdUrl, hasMyJDSession) {
    const tileData = {
        tileIds
    };

    if (tileUrl) {
        tileData.data = Array.isArray(tileUrl) ?
            await Promise.all(tileUrl.map((url) => getTileData(url, queryParams))) :
            await getTileData(tileUrl, queryParams);
    }

    if (myjdUrl && hasMyJDSession) {
        const myJdData = await getMyJDTileData(myjdUrl);

        tileData.data ? tileData.data.myJdData = myJdData : tileData.data = myJdData;
    }

    return tileData;
}

function getTileDataServerCalls(tileUrls, hasMyJDSession) {
    const serverCalls = [];

    tileUrls.forEach((tile) => {
        const dataForTile = getDataForTile(tile.tileUrl, tile.tileIds, tile.queryParams, tile.myjdUrl, hasMyJDSession);

        serverCalls.push(dataForTile);
    });

    return serverCalls;
}

async function fetchData(layout, hasMyJDSession, userAuth) {
    const tileIds = getTileIds(layout && layout.tilePositions);
    const tileUrls = tileIds.reduce((tileUrls, tileId) => mapTileUrls(tileUrls, tileId, userAuth), new Map());

    const serverCalls = getTileDataServerCalls(tileUrls, hasMyJDSession);

    if (tileUrls.size > 0) {
        serverCalls.unshift(getMembershipIntegrations(), getPanelThresholds());
    }

    const [integrations, thresholds, ...tileData] = await Promise.all(serverCalls);

    const integrationsData = integrations?.vendors || [];
    const thresholdsData = thresholds && thresholds.thresholds || [];

    return [integrationsData, thresholdsData, ...tileData];
}

function initializeState() {
    const [integrations, setIntegrations] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => false);
    const [thresholds, setThresholds] = React.useState(() => []);
    const [tileData, setTileData] = React.useState(() => []);
    const [fetchDate, setFetchDate] = React.useState(() => new Date().toISOString());

    return {
        integrations,
        loading,
        setIntegrations,
        setLoading,
        setThresholds,
        setTileData,
        thresholds,
        tileData,
        fetchDate,
        setFetchDate
    };
}

async function autoRefreshData({
    hasMyJDSession,
    layout,
    setTileData,
    setThresholds,
    setIntegrations,
    setFetchDate,
    userAuth
}, isMounted) {
    if (isMounted()) {
        const [integrationsData, thresholdsData, ...dataForTiles] = await fetchData(layout, hasMyJDSession, userAuth);

        setIntegrations(integrationsData);
        setThresholds(thresholdsData);
        setTileData(dataForTiles);
        setFetchDate(new Date().toISOString());
    }
}

function DashboardLayout(props) {
    const {
        appKeys,
        featureToggles,
        hasMyJDSession,
        isMigrated,
        layout,
        membershipId,
        membershipType,
        myJdPermissions,
        openManualDataDialog,
        translations
    } = props;

    const {
        integrations,
        loading,
        setIntegrations,
        setLoading,
        setThresholds,
        setTileData,
        thresholds,
        tileData,
        fetchDate,
        setFetchDate
    } = initializeState();

    const intervalId = React.useRef(null);

    const userAuth = {
        appKeys,
        featureToggles,
        isMigrated,
        myJdPermissions
    };

    async function fetchTileDataCall() {
        setLoading(true);

        const tileIds = getTileIds(layout && layout.tilePositions);
        const tileUrls = tileIds.reduce((tileUrls, tileId) => mapTileUrls(tileUrls, tileId, userAuth), new Map());

        const serverCalls = getTileDataServerCalls(tileUrls, hasMyJDSession);

        const dataForTiles = await Promise.all(serverCalls);

        setTileData(dataForTiles);
        setLoading(false);
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const autoRefreshDataFunction = () => autoRefreshData({
            layout,
            setTileData,
            setThresholds,
            setIntegrations,
            setFetchDate,
            hasMyJDSession,
            userAuth
        }, isMounted);

        await autoRefreshDataFunction();

        if (isMounted()) {
            setLoading(false);
        }

        if (intervalId.current) {
            clearInterval(intervalId.current);
        }

        intervalId.current = setActiveFocusInterval(autoRefreshDataFunction, FIVE_MINUTES_IN_MILLISECONDS, MILLISECONDS_PER_SECOND);
    }), [layout, membershipId]);

    useNavBarActions([{
        hidden: membershipType !== COURSE,
        Icon: 'icon-add',
        onClick: () => openManualDataDialog({
            onCloseEntryDialog: fetchTileDataCall,
            translations
        }),
        title: 'ONLINK_MANUAL_DATA',
        variant: 'primary',
        requiredMyJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    }]);

    React.useEffect(() => () => clearInterval(intervalId.current), []);

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            <TileLayout
                fetchDate={fetchDate}
                integrations={integrations}
                layout={layout}
                onCloseEntryDialog={fetchTileDataCall}
                thresholds={thresholds}
                tileData={tileData}
                translations={translations}
            />
        </LoadingWrapper>
    );
}

DashboardLayout.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    hasMyJDSession: PropTypes.bool,
    isMigrated: PropTypes.bool,
    layout: PropTypes.layout,
    membershipId: PropTypes.string,
    membershipType: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openManualDataDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        hasMyJDSession: state.account.hasMyJDSession,
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        membershipType: state.membership.membershipType,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openManualDataDialog(props) {
            dispatch(openReduxDialog(dialogTypes.MANUAL_DATA_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
