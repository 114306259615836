// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import {IconGrabZone} from '@deere/icons';
import {moveHandler, onDragStart, onDragStop} from 'Utils/scroll-utils';
import {clsx} from 'clsx';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function WidgetDragHandle(props) {
    const {
        dragData,
        ghostElementRef,
        screenTouchListener,
        screenTouchTimerRef
    } = props;

    const [dragging, setDragging] = React.useState(false);

    const onDragStartCallback = React.useCallback((e) => {
        const {
            dataTransfer,
            nativeEvent: {
                offsetX,
                offsetY
            }
        } = e;

        setDragging(true);

        dataTransfer.setDragImage(ghostElementRef.current, offsetX, offsetY);
        dataTransfer.setData('itemId', dragData);

        onDragStart(dragData, screenTouchListener);
    }, [dragData, screenTouchListener]);

    const onDragEndCallback = React.useCallback(() => {
        setDragging(false);

        onDragStop(dragData, screenTouchListener, screenTouchTimerRef);
    }, [dragData, screenTouchListener, screenTouchTimerRef]);

    const dragHandleClassName = React.useMemo(() => clsx('drag-handle', {
        dragging
    }), [dragging]);

    return (
        <Stack
            className={dragHandleClassName}
            draggable={true}
            onDrag={(e) => moveHandler(e, screenTouchTimerRef, 'job-grid-container')}
            onDragEnd={onDragEndCallback}
            onDragStart={onDragStartCallback}
        >
            <IconGrabZone iconGrabZone={ICON_STYLE}/>
        </Stack>
    );
}

WidgetDragHandle.propTypes = {
    dragData: PropTypes.string,
    ghostElementRef: PropTypes.reference,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference
};

export default WidgetDragHandle;
