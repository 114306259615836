// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {getFormattedCurrency, convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {sortBy} from 'lodash';

const LABEL_TO_DATA_UNIT = {
    gal: 'gallons',
    lb: 'pounds'
};

function makeTable({Data}, conversionConfig, hasFinanceAuth) {
    const sorted = sortBy(Data, 'ProductName');

    return sorted.map((lineData) => {
        const {
            CompanyName, ProductName, Quantity, TotalValue, Unit
        } = lineData;

        const value = convertToFormattedUoM(parseFloat(Quantity), {
            ...conversionConfig,
            dataUnit: LABEL_TO_DATA_UNIT[Unit.toLowerCase()]
        });

        return (
            <div
                className='table-list-items'
                key={`${ProductName}${CompanyName}${Unit}`}
            >
                <div className='table-cell cell-left'>{ProductName}</div>
                <div className='table-cell cell-left'>{CompanyName}</div>
                <div className='table-cell cell-right'>{value}</div>
                {
                    hasFinanceAuth &&
                    <div className='table-cell cell-right'>{getFormattedCurrency(parseFloat(TotalValue), conversionConfig)}</div>
                }
            </div>
        );
    });
}

function Inventory(props) {
    const {
        currencyPreference,
        data,
        featureToggles,
        hasFinanceAuth,
        translations,
        unitOfMeasure
    } = props;

    const conversionConfig = {
        currencyPreference,
        featureToggles,
        translations,
        unitOfMeasure
    };

    return (
        <div className='table-group-card inventory-card'>
            <NoDataMessage
                hasData={Boolean(data)}
                noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
            >
                {
                    data &&
                    <>
                        {
                            hasFinanceAuth &&
                            <div className='inventory-total-cash'>
                                {`${getFormattedCurrency(parseFloat(data.TotalCashValue), conversionConfig)} `}
                                <span className='inventory-total-cash-subtext'>{translations.ONLINK_TOTAL}</span>
                            </div>
                        }
                        <div className='card-header-labels'>
                            <div className='card-header-item cell-left'>{translations.PRODUCTS_NAME}</div>
                            <div className='card-header-item cell-left'>{translations.COMPANY_NAME}</div>
                            <div className='card-header-item cell-right'>{translations['Observation.QUANTITY']}</div>
                            {
                                hasFinanceAuth &&
                                <div className='card-header-item cell-right'>{translations.ONLINK_TOTAL_VALUE}</div>
                            }
                        </div>
                        <div className='table-list'>
                            {makeTable(data, conversionConfig, hasFinanceAuth)}
                        </div>
                    </>
                }
            </NoDataMessage>
        </div>
    );
}

Inventory.propTypes = {
    currencyPreference: PropTypes.string,
    data: PropTypes.object,
    featureToggles: PropTypes.featureToggles,
    hasFinanceAuth: PropTypes.bool,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string
};

export function mapStateToProps(state) {
    return {
        unitOfMeasure: state.membership.unitOfMeasure
    };
}

export default connect(mapStateToProps)(Inventory);
