// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import dialogTypes from 'Ui/components/common/dialog-types';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {getManualData, postManualData, putManualData} from 'Services/manual-data-service';
import {getMembershipFormsByDateSelectDataGroup} from 'Services/membership-service';
import {ENTRY_FORM_TITLES} from 'Ui/components/manual-data/constants/entry-form-titles';
import {MANUAL_ENTRY_FORMS} from 'Ui/constants/manual-data-constants';
import {fetchEffectData} from 'Utils/react-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {isEmptyString, isNullOrUndefined} from 'Common/utils/validation-utils';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {TOAST_TYPE} from '@deere/toast';
import moment from 'moment';

function filterDataValue(dataValue) {
    return Object.keys(dataValue).every((key) => !isEmptyString(dataValue[key]));
}

function getDialogTitle(forms, translations) {
    const [form] = forms;

    const entryFormTitle = ENTRY_FORM_TITLES[form.title];

    return entryFormTitle ? translations[entryFormTitle] : translations[form.title];
}

function ManualDataEntryDialog(props) {
    const {
        addToast,
        closeDialog,
        dataGroup,
        dataTypeTitle,
        invalidInputs,
        manualDataId,
        membership,
        onClose,
        setValid,
        translations
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState();
    const [values, setValues] = React.useState(() => ({
        dataGroup,
        membershipId: membership.membershipId,
        timeSample: moment().format(MANUAL_DATA_TIME_FORMAT)
    }));

    const Component = MANUAL_ENTRY_FORMS[dataGroup];

    async function onSave() {
        const {manualData = [] } = values;
        const valuesToSave = {
            ...values,
            manualData: manualData.reduce((transformedManualData, manualDataItem) => {
                if (!isNullOrUndefined(manualDataItem.value)) {
                    return transformedManualData.concat(manualDataItem);
                }

                if (manualDataItem.data_values) {
                    return transformedManualData.concat({
                        ...manualDataItem,
                        'data_values': manualDataItem.data_values.filter(filterDataValue)
                    });
                }

                return transformedManualData;
            }, [])
        };

        setLoading(true);

        try {
            manualDataId ?
                await putManualData(valuesToSave, manualDataId) :
                await postManualData(valuesToSave);

            if (onClose) {
                onClose();
            }

            closeDialog();
        } catch (e) {
            setLoading(false);

            addToast({
                message: e.response.data.message,
                type: TOAST_TYPE.ERROR
            });
        }
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (manualDataId) {
            setLoading(true);

            const {manualData} = await getManualData(manualDataId);
            const {forms} = await getMembershipFormsByDateSelectDataGroup(
                membership.membershipId,
                dataGroup,
                moment(manualData.timeSample).format('MMDDYYYY')
            );

            manualData.manualData = manualData.jsonData;
            manualData.timeSample = moment.utc(manualData.timeSample).format(MANUAL_DATA_TIME_FORMAT);

            if (isMounted()) {
                setTitle(`${[forms[0].category]} - ${getDialogTitle(forms, translations)}`);
                setValues(manualData);

                setLoading(false);
            }
        }
    }), [manualDataId]);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0}
            footerLoading={loading}
            onSave={onSave}
            saveLabel={translations.save}
            title={title || dataTypeTitle}
            translations={translations}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                {
                    Component ? (
                        <Component
                            membership={membership}
                            setValid={setValid}
                            setValues={setValues}
                            translations={translations}
                            values={values}
                        />
                    ) : <></>
                }
            </LoadingWrapper>
        </FormDialog>
    );
}

ManualDataEntryDialog.propTypes = {
    addToast: PropTypes.func,
    closeDialog: PropTypes.func,
    dataGroup: PropTypes.string,
    dataTypeTitle: PropTypes.string,
    invalidInputs: PropTypes.instanceOf(Set),
    manualDataId: PropTypes.string,
    membership: PropTypes.membership,
    onClose: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.MANUAL_DATA_ENTRY_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(ManualDataEntryDialog));
