// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function BasicTableCell(props) {
    return (
        <div className='basic-table-cell'>
            {props.children}
        </div>
    );
}

BasicTableCell.propTypes = {
    children: PropTypes.node
};

export default BasicTableCell;
