// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ErrorView from 'Ui/components/dashboard/error-view';
import {useAuthContext} from 'Ui/context/auth-context';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {joinClassNames} from 'Utils/html-utils';
import {useDeepMemo} from 'Utils/react-utils';

function renderContentUsingAuth(authorized, Component, translations, wrapperRef) {
    switch (authorized) {
        case true:
            return (
                <Component
                    translations={translations}
                    wrapperRef={wrapperRef}
                />
            );

        case false:
            return <ErrorView translations={translations}/>;

        default:
            return <></>;
    }
}

function EntryItem(props) {
    const {
        appKeys,
        className,
        component,
        featureToggles,
        footer,
        hasMyJDSession,
        hasSubscription,
        isMigrated,
        loadPersistentHeader,
        match: {params},
        myJdPermissions,
        paramsAuth,
        permissions,
        requiredAuth,
        superUser,
        translations
    } = props;

    const {
        authorized,
        setAuthorized
    } = useAuthContext();

    const userAuth = useDeepMemo(() => ({
        appKeys,
        featureToggles,
        hasMyJDSession,
        isMigrated,
        myJdPermissions,
        permissions,
        superUser
    }), [appKeys, featureToggles, hasMyJDSession, isMigrated, myJdPermissions, permissions, superUser]);

    const isValidMembership = React.useMemo(
        () => !loadPersistentHeader || hasSubscription,
        [hasSubscription, loadPersistentHeader]
    );

    const isRouteAuthorized = useDeepMemo(
        () => isValidMembership && isAuthorized(requiredAuth, userAuth),
        [isValidMembership, requiredAuth, userAuth]
    );

    const areParamsAuthorized = useDeepMemo(() => {
        return Object.keys(paramsAuth).every((paramKey) => {
            const paramValue = params[paramKey];
            const requiredAuthForParamKey = paramsAuth[paramKey];

            if (isNullOrUndefined(paramValue) && Object.keys(requiredAuthForParamKey).length > 0) {
                return false;
            }

            const requiredAuthForParamValue = requiredAuthForParamKey[paramValue];

            if (!isMigrated) {
                return !requiredAuthForParamValue?.migratedMembershipAccessOnly;
            }

            return !requiredAuthForParamValue || isAuthorized(requiredAuthForParamValue, userAuth);
        });
    }, [isMigrated, params, paramsAuth, userAuth]);

    React.useEffect(() => {
        setAuthorized(isRouteAuthorized && areParamsAuthorized);
    }, [areParamsAuthorized, isRouteAuthorized]);

    const wrapperRef = React.useRef();

    return (
        <div
            className='dashboard'
            ref={wrapperRef}
        >
            <div className={joinClassNames('dashboard-content', className)}>
                {renderContentUsingAuth(authorized, component, translations, wrapperRef)}
            </div>
            {!isMigrated && footer}
        </div>
    );
}

const authPropType = PropTypes.shape({
    appAccess: PropTypes.string,
    myJdPermissions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    permission: PropTypes.string,
    toggle: PropTypes.string
});

EntryItem.defaultProps = {
    paramsAuth: {}
};

EntryItem.propTypes = {
    appKeys: PropTypes.appKeys,
    className: PropTypes.string,
    component: PropTypes.elementType,
    featureToggles: PropTypes.featureToggles,
    footer: PropTypes.node,
    hasMyJDSession: PropTypes.bool,
    hasSubscription: PropTypes.bool,
    isMigrated: PropTypes.bool,
    loadPersistentHeader: PropTypes.bool,
    match: PropTypes.match,
    myJdPermissions: PropTypes.myJdPermissions,
    paramsAuth: PropTypes.objectOf(authPropType),
    permissions: PropTypes.legacyPermissions,
    requiredAuth: authPropType,
    superUser: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        hasMyJDSession: state.account.hasMyJDSession,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions,
        superUser: state.account.superUser
    };
}

export default connect(mapStateToProps)(withRouter(EntryItem));
