// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import {IconDelete, IconHandleChevronDown, IconHandleChevronUp} from '@deere/icons';
import CollapsedWorkItemInput from 'Ui/features/workboard-wizard/work-item/collapsed-work-item-input';
import WorkItemDragHandle from 'Ui/features/workboard-wizard/work-item/work-item-drag-handle';
import WorkItemInput from 'Ui/features/workboard-wizard/work-item/work-item-input';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {createIconFillStyle} from 'Utils/icon-utils';
import {useDeepMemo} from 'Utils/react-utils';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function WorkItemInputWrapper(props) {
    const {
        collapsedTitle,
        collapseStatusForWorkItem,
        deleteTitle,
        disabledClear,
        displayOrderButton,
        featureToggles,
        onRemove,
        setStatusForWorkItem,
        translations,
        workItem,
        readOnly,
        ...otherProps
    } = props;

    const collapseButton = React.useMemo(() => {
        const collapseIcon = collapseStatusForWorkItem ?
            <IconHandleChevronDown icon={ICON_STYLE}/> :
            <IconHandleChevronUp icon={ICON_STYLE}/>;

        return (
            <Stack marginLeft='auto'>
                <OnlinkButton
                    borderless={true}
                    className='action-button'
                    leftIcon={collapseIcon}
                    onClick={setStatusForWorkItem}
                />
            </Stack>
        );
    }, [collapseStatusForWorkItem]);

    const deleteIcon = React.useMemo(() => (
        <IconDelete
            icon={ICON_STYLE}
            primary={createIconFillStyle('#2677bb')}
        />
    ), []);

    const deleteButton = useDeepMemo(() => (
        <Stack marginLeft='auto'>
            <OnlinkButton
                borderless={true}
                className='action-button assignment-item-btn icon-and-text'
                disabled={disabledClear}
                leftIcon={deleteIcon}
                onClick={onRemove}
            >
                {featureToggles[WORKBOARD_REFACTOR] ? deleteTitle : translations.DELETE}
            </OnlinkButton>
        </Stack>
    ), [deleteTitle, disabledClear, featureToggles[WORKBOARD_REFACTOR], onRemove, translations.DELETE]);

    const orderButton = React.useMemo(() => !readOnly && displayOrderButton && (
        <WorkItemDragHandle className='icon-workitem-orderable assignment-item-btn'/>
    ), [displayOrderButton, readOnly]);

    if (collapseStatusForWorkItem) {
        return (
            <CollapsedWorkItemInput
                collapseButton={collapseButton}
                collapsedTitle={collapsedTitle}
                equipment={workItem.equipment}
                orderButton={orderButton}
                translations={translations}
                workItemId={workItem.workItemId}
            />
        );
    }

    return (
        <WorkItemInput
            {...otherProps}
            collapseButton={collapseButton}
            deleteButton={deleteButton}
            featureToggles={featureToggles}
            orderButton={orderButton}
            readOnly={readOnly}
            translations={translations}
            workItem={workItem}
        />
    );
}

WorkItemInputWrapper.propTypes = {
    children: PropTypes.node,
    collapsedTitle: PropTypes.string,
    collapseStatusForWorkItem: PropTypes.bool,
    deleteTitle: PropTypes.string,
    disabledClear: PropTypes.bool,
    displayOrderButton: PropTypes.bool,
    eligibleEquipmentTypes: PropTypes.arrayOf(PropTypes.object),
    equipmentAreaId: PropTypes.string,
    equipmentByType: PropTypes.instanceOf(Map),
    equipmentInUseMap: PropTypes.reference,
    featureToggles: PropTypes.featureToggles,
    onActHoursChange: PropTypes.func,
    onChange: PropTypes.func,
    onEquipmentChange: PropTypes.func,
    onEstHoursChange: PropTypes.func,
    onRemove: PropTypes.func,
    readOnly: PropTypes.bool,
    setStatusForWorkItem: PropTypes.func,
    translations: PropTypes.translations,
    workItem: PropTypes.object
};

export default WorkItemInputWrapper;
