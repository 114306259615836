// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import {getInventories} from 'Services/membership-service';
import {deleteBinLocation, getBinLocations} from 'Services/inventory-service';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import AddButton from 'Ui/components/settings/common/add-button';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';

function getColumns(translations, inventoryId, openBinLocationDialog, fetchBinLocations, binLocations) {
    return [
        {
            Header: translations.ONLINK_BIN_LOCATION,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openBinLocationDialog({
                            binLocation: original,
                            inventoryBinLocations: binLocations,
                            inventoryId,
                            translations,
                            fetchBinLocations
                        })}
                    >
                        {value}
                    </EditLink>
                );
            }
        }
    ];
}

function getFilterComponent(translations, inventoryId, openBinLocationDialog, fetchBinLocations, binLocations) {
    return (
        <div className='extra-table-options'>
            <AddButton
                label={replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                    '0': translations.ONLINK_BIN_LOCATION
                })}
                onClick={() => openBinLocationDialog({
                    inventoryId,
                    inventoryBinLocations: binLocations,
                    translations,
                    fetchBinLocations
                })}
            />
        </div>
    );
}

function BinLocationsSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openBinLocationDialog,
        openConfirmation,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [binLocations, setBinLocations] = React.useState([]);
    const [inventoryId, setInventoryId] = React.useState(null);

    async function fetchBinLocations(isMounted = () => true) {
        setLoading(true);

        const {inventories} = await getInventories();
        const inventoryId = inventories?.length ? inventories[0].inventoryId : null;
        const {binLocations} = inventoryId ? await getBinLocations(inventoryId) : [];

        if (isMounted()) {
            setBinLocations(binLocations);
            setInventoryId(inventoryId);
            setLoading(false);
        }
    }

    function deleteBinLocationHandler(binLocation) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_BIN_LOCATION.toLowerCase()
            }),
            onCancel: closeConfirmation,
            showLoadingIcon: true,
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_BIN_LOCATION
            }),
            async onContinue() {
                try {
                    await deleteBinLocation(binLocation.binLocationId);
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.ONLINK_BIN_LOCATION.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }

                closeConfirmation();
                fetchBinLocations();
            }
        });
    }

    useDeepEffect(() => fetchEffectData(fetchBinLocations), []);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, inventoryId, openBinLocationDialog, fetchBinLocations, binLocations)}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                deleteHandler={deleteBinLocationHandler}
                filterComponent={getFilterComponent(translations, inventoryId, openBinLocationDialog, fetchBinLocations, binLocations)}
                loading={loading}
                noDataAvailableMessage={translations.ONLINK_NO_BIN_LOCATIONS_FOUND}
                rows={binLocations}
                searchable={true}
                showDeleteColumn={isAuthorized({
                    myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                }, props)}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

BinLocationsSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    openBinLocationDialog: PropTypes.func,
    openConfirmation: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openBinLocationDialog(props) {
            dispatch(openDialog(dialogTypes.BIN_LOCATION_DIALOG, props));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BinLocationsSettings);
