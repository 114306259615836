// Unpublished Work © 2023-2024 Deere & Company.

export const HOC = 'hoc';
export const HOC_SPEC = 'hoc_spec';
export const GREEN_SPEED = 'green_speed';
export const CLIPPING_YIELD = 'clipping_yield';
export const CLIPPING_YIELD_VOLUME = 'clipping_yield_volume';
export const CLIPPING_YIELD_WEIGHT = 'clipping_yield_weight';
export const BUDGET = 'budget';
export const CHEMICALS = 'chemicals';
export const ENVIRONMENT = 'environment';
export const MOWERS = 'mowers';
export const SOIL = 'soil';
export const SULFUR = 'sulfur';
export const WSPD = 'wspd';
export const FIRMNESS = 'firmness';
export const TURF = 'turf';
export const FIRMNESS_F355_EQUIVALENTS = 'firmness_f355_equivalents';
export const FIRMNESS_GRAVITIES = 'firmness_gravities';
export const PRICE_PER_ROUND = 'price_per_round';
export const FUEL = 'fuel';
export const FUEL_COST = 'fuel_cost';
export const GAS = 'gas';
export const GAS_COST = 'gas_cost';
export const ELECTRICAL_COST = 'electrical_cost';
export const MOISTURE = 'moisture';
export const TEMP = 'temp';
export const CURRENCY = 'currency';
export const ELECTRICAL_CONSUMPTION = 'electrical_consumption';
export const REVENUE = 'revenue';
export const TOTAL_WATER_IRRIGATION = 'total_water_irrigation';
export const WATER_COST = 'water_cost';
export const WATER_FLOW = 'water_flow';
export const WATER_TRANSFER = 'water_transfer';
export const EC = 'ec';
