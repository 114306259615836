// Unpublished Work © 2020-2024 Deere & Company.

const ROUNDS_0_ID = '1fcdd0aa-0dd8-4763-b275-669548f1d9c8';
const ROUNDS_1_ID = '11db83ab-0afa-49e8-8973-9980925d5627';
const ROUNDS_2_ID = '02ea3a5a-5a6d-4a04-93d7-8c498b312eb8';

const ROUNDS_0_NAME = 'rounds_0';
const ROUNDS_1_NAME = 'rounds_1';
const ROUNDS_2_NAME = 'rounds_2';

export default {
    tilePositions: [
        {
            tile: {
                id: ROUNDS_0_ID,
                name: ROUNDS_0_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                i: ROUNDS_0_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_1_ID,
                name: ROUNDS_1_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: ROUNDS_1_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_2_ID,
                name: ROUNDS_2_NAME
            },
            columnCount: 3,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: ROUNDS_2_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_0_ID,
                name: ROUNDS_0_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 1,
                i: ROUNDS_0_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_1_ID,
                name: ROUNDS_1_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: ROUNDS_1_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_2_ID,
                name: ROUNDS_2_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: ROUNDS_2_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_0_ID,
                name: ROUNDS_0_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 4,
                w: 1,
                h: 1,
                i: ROUNDS_0_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_1_ID,
                name: ROUNDS_1_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: ROUNDS_1_NAME
            }
        },
        {
            tile: {
                id: ROUNDS_2_ID,
                name: ROUNDS_2_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: ROUNDS_2_NAME
            }
        }
    ]
};
