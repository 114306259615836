// Unpublished Work © 2022-2024 Deere & Company.

export const EXPENSE_TYPES = {
    salesTax: 'Sales Tax',
    propertyTax: 'Property Tax',
    leasePayment: 'Lease Payment',
    service: 'Service',
    parts: 'Parts',
    purchase: 'Purchase',
    finalLeasePayment: 'Final Lease Payment',
    advanceLeasePayment: 'Advance Lease Payment'
};

export const FORM_EXPENSE_TYPES = [
    {
        id: 'purchase',
        label: EXPENSE_TYPES.purchase
    },
    {
        id: 'salesTax',
        label: EXPENSE_TYPES.salesTax
    },
    {
        id: 'propertyTax',
        label: EXPENSE_TYPES.propertyTax
    },
    {
        id: 'parts',
        label: EXPENSE_TYPES.parts
    },
    {
        id: 'service',
        label: EXPENSE_TYPES.service
    }
];
