// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import EditLink from 'Ui/components/common/data-table/edit-link';

function LegacyFooter(props) {
    const {
        copyrightWithYear,
        hideContent,
        openContactUsDialog,
        openFeedbackDialog,
        openTermsAndConditionsDialog,
        translations
    } = props;

    const {footerLinks} = window.props;

    return (
        <div className={'footer'}>
            <span>{copyrightWithYear}</span>
            {
                !hideContent &&
                <div className='link-container'>
                    <EditLink
                        onClick={() => openContactUsDialog({
                            byPhoneUrl: footerLinks.byPhoneUrl,
                            contactUsUrl: footerLinks.contactUsUrl,
                            translations
                        })}
                    >
                        {translations.footer_contactUs}
                    </EditLink>
                    <a
                        href={footerLinks.aboutUsUrl}
                        rel={'noreferrer'}
                        target={'_blank'}
                    >
                        {translations.ONLINK_ABOUT_US}
                    </a>
                    <EditLink
                        onClick={() => openFeedbackDialog({
                            translations
                        })}
                    >
                        {translations.footer_feedback}
                    </EditLink>
                    <a
                        href={footerLinks.privacyAndDataUrl}
                        rel={'noreferrer'}
                        target={'_blank'}
                    >
                        {translations.ONLINK_PRIVACY_AND_DATA}
                    </a>
                    <EditLink
                        onClick={() => openTermsAndConditionsDialog({
                            translations
                        })}
                    >
                        {translations.terms_of_use_link}
                    </EditLink>
                </div>
            }
        </div>
    );
}

LegacyFooter.propTypes = {
    copyrightWithYear: PropTypes.string,
    hideContent: PropTypes.bool,
    openContactUsDialog: PropTypes.func,
    openFeedbackDialog: PropTypes.func,
    openTermsAndConditionsDialog: PropTypes.func,
    translations: PropTypes.translations
};

export default LegacyFooter;
