// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ActionButtons from 'Ui/components/tertiary-navbar/common/action-buttons';
import Tabs from 'Ui/components/tertiary-navbar/common/tabs';
import {joinClassNames} from 'Utils/html-utils';
import 'Sass/persistent-header/ph-tertiary-navbar.scss';

function TertiaryNavBar(props) {
    const {
        children,
        headerText,
        navBarContentClass,
        tabs,
        translations
    } = props;

    const translatedHeaderText = translations[headerText];

    return (
        <Box
            bgcolor='secondary.main'
            className='tertiary-navbar'
        >
            <div className={joinClassNames('navbar-content', navBarContentClass)}>
                <Stack
                    alignItems='center'
                    className='navbar-context'
                    direction='row'
                    spacing={2}
                >
                    {
                        translatedHeaderText &&
                        <Typography variant='h1'>
                            {translatedHeaderText}
                        </Typography>
                    }
                    {children}
                </Stack>
                <ActionButtons translations={translations}/>
            </div>
            {
                tabs &&
                <Tabs
                    tabs={tabs}
                    translations={translations}
                />
            }
        </Box>
    );
}

TertiaryNavBar.propTypes = {
    children: PropTypes.node,
    headerText: PropTypes.string,
    navBarContentClass: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export default TertiaryNavBar;
