// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {BasicDialog} from '@deere/basic-dialog';
import OnLinkDialogNav from 'Ui/components/common/onlink-dialog/onlink-dialog-nav';
import OnLinkDialogSwitch from 'Ui/components/common/onlink-dialog/onlink-dialog-switch';
import {joinClassNames} from 'Utils/html-utils';
import ErrorView from 'Ui/components/dashboard/error-view';
import Box from '@mui/material/Box';

function reduceContent(itemMap, item) {
    itemMap.set(item.id, item);

    return itemMap;
}

function OnLinkDialog(props) {
    const {
        activeTab,
        className,
        content,
        navButton,
        onTabClick,
        routable,
        showError,
        translations,
        ...dialogProps
    } = props;

    const contentMap = content.reduce(reduceContent, new Map());
    const selectedTab = contentMap.has(activeTab) ? activeTab : content[0]?.id;

    const dialogContent = routable ?
        <OnLinkDialogSwitch content={content}/> :
        contentMap.get(selectedTab).element;

    const errorView = (<Box flex={1}>
        <ErrorView translations={translations}/>
    </Box>);

    return (
        <BasicDialog
            className={joinClassNames('onlink-dialog', className)}
            show={true}
            {...dialogProps}
        >
            {showError ? errorView : <>
                <OnLinkDialogNav
                    activeTab={selectedTab}
                    content={content}
                    navButton={navButton}
                    onTabClick={onTabClick}
                />
                {dialogContent}
            </>}
        </BasicDialog>
    );
}

OnLinkDialog.propTypes = {
    activeTab: PropTypes.string,
    className: PropTypes.string,
    closeHandler: PropTypes.func,
    content: PropTypes.arrayOf(PropTypes.dialogContent),
    large: PropTypes.bool,
    navButton: PropTypes.element,
    onTabClick: PropTypes.func,
    routable: PropTypes.bool,
    showError: PropTypes.bool,
    title: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export default connect(mapStateToProps)(OnLinkDialog);
