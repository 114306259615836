// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import BasicTableHeader from 'Ui/components/common/basic-table/basic-table-header';
import BasicTableRow from 'Ui/components/common/basic-table/basic-table-row';
import {joinClassNames} from 'Utils/html-utils';

function BasicTable(props) {
    const orientation = props.vertical ? 'vertical' : 'horizontal';

    return (
        <div className={joinClassNames('basic-table-container', props.className)}>
            {
                props.title &&
                <div className='basic-table-title'>
                    {props.title}
                </div>
            }
            <div className={joinClassNames('basic-table-wrapper', orientation)}>
                <div className='basic-table'>
                    <div className='basic-table-header-container'>
                        {
                            props.headers &&
                            <BasicTableHeader cells={props.headers}/>
                        }
                    </div>
                    <div className='basic-table-row-container'>
                        {
                            props.rows &&
                            Object.keys(props.rows).map((key) => (
                                <BasicTableRow
                                    cells={props.rows[key]}
                                    key={`table-row ${key}`}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

BasicTable.propTypes = {
    className: PropTypes.string,
    headers: PropTypes.row,
    rows: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.row),
        PropTypes.objectOf(PropTypes.row)
    ]),
    title: PropTypes.stringOrNumber,
    vertical: PropTypes.bool
};

export default BasicTable;
