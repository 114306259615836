// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import MembershipSelect from 'Ui/components/main-navbar/membership-select';
import {fetchEffectData} from 'Utils/react-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {getCurrentUserMyJDLinks} from 'Services/my-jd-user-service';
import {BasicDialog} from '@deere/basic-dialog';
import PropTypes from 'Utils/prop-type-utils';
import {reauthenticateMembership} from 'Utils/membership/switch-membership';

function LinkedMembershipSelect(props) {
    const {translations} = props;

    const [loading, setLoading] = React.useState(true);
    const [memberships, setMemberships] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        if (isMounted()) {
            const userLinks = await getCurrentUserMyJDLinks();

            setMemberships(userLinks.map((userLink) => userLink.membership));
        }

        setLoading(false);
    }), []);

    return (
        <LoadingWrapper
            className={'dashboard-loading-icon'}
            loading={loading}
            size={'50px'}
        >
            <BasicDialog
                className={'choose-membership-dialog login'}
                show={true}
                title={translations.SELECT_A_MEMBERSHIP}
            >
                <MembershipSelect
                    changeMembership={reauthenticateMembership}
                    isSelected={false}
                    memberships={memberships}
                    showMigrated={true}
                    showParents={false}
                />
            </BasicDialog>
        </LoadingWrapper>
    );
}

LinkedMembershipSelect.propTypes = {
    translations: PropTypes.translations
};

export default LinkedMembershipSelect;
