// Unpublished Work © 2023-2024 Deere & Company.

import {asNumber} from 'Utils/conversion-utils';
import moment from 'moment';
import {DATE_FORMATS} from 'Utils/time-utils';
import {createPurchaseOrder, updatePurchaseOrder} from 'Services/inventory-service';
import {STATUS} from 'Utils/parts-utils';
import {updatePartRequest} from 'Services/parts-service';

const RECOMMENDED = 'recommended';

function getStatusToSend(values) {
    if (values.status === STATUS.COMPLETED) {
        return STATUS.RECEIVED;
    }

    if (values.status === values.startingStatus) {
        return null;
    }

    return values.status;
}

async function savePurchaseOrder(values, selectedParts, purchaseOrder, onClose, closeDialog, receiveAndComplete, partRequestsToRemove) {
    const parts = selectedParts.map((part) => ({
        ...part,
        name: part.name,
        partId: part.partId,
        quantity: receiveAndComplete ? asNumber(part.quantity) - asNumber(part.quantityReceived) : asNumber(part.quantity),
        totalCost: asNumber(part.totalCost),
        unitCost: asNumber(part.unitCost),
        quantityReceived: asNumber(part.quantityReceived)
    }));

    const purchaseOrderPayload = {
        ...values,
        parts,
        dateSelect: moment(values.dateSelect).format(DATE_FORMATS.day),
        otherCosts: {
            tax: asNumber(values.otherCosts.tax),
            shipping: asNumber(values.otherCosts.shipping),
            handling: asNumber(values.otherCosts.handling)
        },
        receiveAndComplete,
        allowDelete: values.startingStatus === STATUS.PENDING
    };

    const promises = Array.from(partRequestsToRemove).map((partRequestToRemove) => {
        return updatePartRequest(partRequestToRemove, {
            status: RECOMMENDED
        });
    });

    await Promise.all(promises);

    if (purchaseOrder) {
        const partIds = Object.keys(purchaseOrder.partsMap || {});

        purchaseOrderPayload.partRequests = parts.filter((part) => !partIds.includes(part.partId));

        await updatePurchaseOrder(purchaseOrderPayload, values.startingStatus);
    } else {
        purchaseOrderPayload.partRequests = parts;

        await createPurchaseOrder(purchaseOrderPayload);
    }

    if (onClose) {
        onClose();
    }

    closeDialog();
}

export {
    getStatusToSend,
    savePurchaseOrder
};
