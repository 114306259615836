// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconTerminal} from '@deere/icons';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function TelematicsEnabled(props) {
    const {equipment} = props;

    if (equipment?.lastReportedMtgId) {
        return (
            <div className={'inline-icon-btn'}>
                <IconTerminal iconTerminal={ICON_STYLE}/>
            </div>
        );
    }

    return null;
}

TelematicsEnabled.propTypes = {
    equipment: PropTypes.equipment
};

export default TelematicsEnabled;
