// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import {getInventories} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {getCurrencySymbol} from 'Utils/unit-conversion-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {SETTINGS} from 'Common/constants/routes';
import {withRouter} from 'react-router-dom';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {updateInventory} from 'Services/inventory-service';
import {useSave} from 'Ui/react-hooks/use-save';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {VIEW_FINANCIAL_DATA, MODIFY_FINANCIAL_DATA} from 'Common/constants/business-activities';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

const ROUNDING_CONSTANT_TWO_DECIMALS = 100;

function PurchaseOrdersSettings(props) {
    const {
        featureToggles,
        history,
        invalidInputs,
        membership,
        myJdPermissions,
        setValid,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [inventoryId, setInventoryId] = React.useState('');
    const [values, setValues] = React.useState({
        totalBudget: '',
        purchaseOrderPrefix: ''
    });

    function onChange(event) {
        const {
            name,
            value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const totalBudget = Math.round(parseFloat(values.totalBudget) * ROUNDING_CONSTANT_TWO_DECIMALS) / ROUNDING_CONSTANT_TWO_DECIMALS || 0;

        await updateInventory(inventoryId, {
            ...values,
            totalBudget
        });
        setValues((prevValues) => ({
            ...prevValues,
            totalBudget
        }));
    }, {
        disabled: loading,
        invalidInputs
    });

    async function fetchInventories(isMounted = () => true) {
        setLoading(true);

        const {inventories} = await getInventories();

        if (isMounted()) {
            if (inventories?.length) {
                setInventoryId(inventories[0].inventoryId);

                setValues({
                    totalBudget: inventories[0].totalBudget || '',
                    purchaseOrderPrefix: inventories[0].purchaseOrderPrefix
                });
            }

            setLoading(false);
        }
    }

    React.useEffect(() => fetchEffectData(fetchInventories), [membership]);

    const userAuth = {
        isMigrated: membership.isMigrated,
        myJdPermissions
    };
    const canModifyFinancialData = isAuthorized({
        myJdPermissions: MODIFY_FINANCIAL_DATA
    }, userAuth);

    const budgetLabel = `${translations.ONLINK_MONTHLY_BUDGET} (${getCurrencySymbol({
        currencyPreference: membership.currencyPreference,
        featureToggles
    })})`;
    const budgetField = (
        <ReadOnlyWrapperComponent
            label={budgetLabel}
            readOnly={!canModifyFinancialData}
            value={values.totalBudget}
            wrappedComponent={ValidationInput}
            wrappedProps={{
                className: 'budget-input',
                debounceTimeout: window.props.debounceTimeout,
                min: 0,
                name: 'totalBudget',
                onChange,
                setValid,
                tabIndex: 0,
                type: 'number'
            }}
        />
    );

    const startingOrderField = (
        <ReadOnlyWrapperComponent
            label={translations.ONLINK_STARTING_ORDER_NUMBER}
            readOnly={!canModifyFinancialData}
            value={values.purchaseOrderPrefix}
            wrappedComponent={ValidationInput}
            wrappedProps={{
                className: 'budget-input',
                debounceTimeout: window.props.debounceTimeout,
                name: 'purchaseOrderPrefix',
                onChange,
                setValid,
                tabIndex: 0
            }}
        />
    );

    return (
        <LoadingWrapper
            className='onlink-loading-icon'
            loading={loading || isSaving}
            size={'50px'}
        >
            <div className='settings-container'>
                <div className='settings-body'>
                    <form onSubmit={saveFunc}>
                        {
                            isAuthorized({
                                myJdPermissions: VIEW_FINANCIAL_DATA
                            }, userAuth) && budgetField
                        }
                        {startingOrderField}
                    </form>
                </div>
                {canModifyFinancialData && <SaveContentBar
                    disabled={invalidInputs.size > 0 || disableSave}
                    onCancelClick={() => history.push(SETTINGS)}
                    onSaveClick={saveFunc}
                    translations={translations}
                />}
            </div>
        </LoadingWrapper>
    );
}

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        myJdPermissions: state.account.myJdPermissions,
        translations: state.translations,
        membership: state.membership
    };
}

PurchaseOrdersSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.object,
    myJdPermissions: PropTypes.myJdPermissions,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default connect(mapStateToProps)(FormValidator(withRouter(PurchaseOrdersSettings)));
