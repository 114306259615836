// Unpublished Work © 2022-2024 Deere & Company.

import {getActiveMembershipUsers, getMembershipUsers} from 'Services/membership-service';
import {DATE_FORMATS} from 'Utils/time-utils';
import moment from 'moment';

async function fetchAndFilterInactiveOperators(date = new Date()) {
    const formattedDate = moment(date).format(DATE_FORMATS.day);

    const [
        activeOperators,
        {users: allOperators}
    ] = await Promise.all([
        getActiveMembershipUsers(formattedDate),
        getMembershipUsers()
    ]);

    return {
        activeOperators,
        allOperators
    };
}

async function fetchOperators(date) {
    const {
        activeOperators,
        allOperators
    } = await fetchAndFilterInactiveOperators(date);

    return {
        activeOperators,
        allOperators
    };
}

async function fetchAndFormatOperator({
    date,
    isMounted,
    setOperators
}) {
    const {activeOperators} = await fetchAndFilterInactiveOperators(date);

    if (isMounted()) {
        const formattedAssignees = activeOperators.map((operator) => ({
            title: `${operator.firstName} ${operator.lastName}`,
            id: operator.appUserId
        }));

        setOperators(formattedAssignees);
    }
}

export {
    fetchOperators,
    fetchAndFormatOperator,
    fetchAndFilterInactiveOperators
};
