// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {isEqual} from 'lodash';

function fetchEffectData(fetchCallback, returnCallback) {
    let mounted = true;

    fetchCallback(() => mounted);

    return () => {
        mounted = false;

        if (returnCallback) {
            returnCallback();
        }
    };
}

function memoizeDeepCompare(deps) {
    const ref = React.useRef();

    if (!isEqual(deps, ref.current)) {
        ref.current = deps;
    }

    return ref.current;
}

function useDeepEffect(effect, deps) {
    React.useEffect(effect, memoizeDeepCompare(deps));
}

function useDeepMemo(factory, deps) {
    const ref = React.useRef();

    if (!ref.current || !isEqual(deps, ref.current.key)) {
        ref.current = {
            key: deps,
            value: factory()
        };
    }

    return ref.current.value;
}

function useLazyRef(initialValue) {
    const initialized = React.useRef(false);
    const ref = React.useRef();

    if (!initialized.current) {
        initialized.current = true;
        ref.current = initialValue();
    }

    return ref;
}

export {
    fetchEffectData,
    useDeepEffect,
    useDeepMemo,
    useLazyRef
};
