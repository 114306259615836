// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {HOC, HOC_SPEC} from 'Common/constants/data-group-constants';
import dialogTypes from 'Ui/components/common/dialog-types';
import {IconEdit, IconDelete} from '@deere/icons';
import moment from 'moment';
import {deleteManualData} from 'Services/manual-data-service';
import {getDeleteEntryConfirmationMessage} from 'Utils/data-utils';

const ICON_EDIT_STYLE = {
    style: {
        cursor: 'pointer',
        display: 'inline',
        height: '20px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#2677bb');

function EditTableRow(props) {
    const {
        closeConfirmation,
        dataGroup,
        dateSelect,
        manualDataId,
        membership,
        onManualDataUpdate,
        openConfirmation,
        openEdit,
        openManualDataEntryDialog,
        translations,
        value
    } = props;

    function onEditClick() {
        if (dataGroup === HOC || dataGroup === HOC_SPEC) {
            openEdit({
                dataGroup,
                initialDate: dateSelect,
                membership,
                translations,
                updateCourseMembership: onManualDataUpdate
            });
        } else {
            openManualDataEntryDialog({
                dataGroup,
                manualDataId,
                onClose: onManualDataUpdate,
                translations
            });
        }
    }

    async function onDeleteClick() {
        await deleteManualData(manualDataId);

        onManualDataUpdate();
        closeConfirmation();
    }

    return (
        <span className='edit-table-row'>
            {value}
            <OnLinkIconButton
                className='graph-icon edit-manual-data'
                onClick={onEditClick}
            >
                <IconEdit
                    iconEdit={ICON_EDIT_STYLE}
                    primary={PRIMARY_STYLE}
                />
            </OnLinkIconButton>
            {
                <OnLinkIconButton
                    className='graph-icon delete-manual-data'
                    onClick={() => {
                        openConfirmation({
                            translations,
                            onCancel: closeConfirmation,
                            showLoadingIcon: true,
                            message: getDeleteEntryConfirmationMessage(translations),
                            onContinue: onDeleteClick,
                            title: translations.ONLINK_DELETE_ENTRY
                        });
                    }}
                >
                    <IconDelete
                        iconDelete={ICON_EDIT_STYLE}
                        primary={PRIMARY_STYLE}
                    />
                </OnLinkIconButton>
            }
        </span>
    );
}

EditTableRow.propTypes = {
    closeConfirmation: PropTypes.func,
    dataGroup: PropTypes.string,
    dateSelect: PropTypes.instanceOf(moment),
    featureToggles: PropTypes.featureToggles,
    manualDataId: PropTypes.string,
    membership: PropTypes.membership,
    onManualDataUpdate: PropTypes.func,
    openConfirmation: PropTypes.func,
    openEdit: PropTypes.func,
    openManualDataEntryDialog: PropTypes.func,
    translations: PropTypes.translations,
    value: PropTypes.stringOrNumber
};

export function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openEdit(props) {
            dispatch(openDialog(dialogTypes.HOC_EDIT_DIALOG, props));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openManualDataEntryDialog(props) {
            dispatch(openDialog(dialogTypes.MANUAL_DATA_ENTRY_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTableRow);
