// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import {BottomSheet} from 'react-spring-bottom-sheet';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {bottomSheet} from 'Ui/constants/map-constants';
import PropTypes from 'Utils/prop-type-utils';
import {IconHandleChevronUp, IconHandleChevronDown} from '@deere/icons';

const mainNavAndSecondaryNav = bottomSheet.mainNavAndSecondaryNav;
const sheetTab = bottomSheet.sheetTab;
const halfway = bottomSheet.halfway;

function getSnapPoints(maxHeight) {
    const fullScreen = maxHeight - mainNavAndSecondaryNav;

    return [sheetTab, fullScreen / 2, fullScreen];
}

function snapOpenClose(bottomSheetRef, iconArrow) {
    if (bottomSheetRef.current) {
        bottomSheetRef.current.snapTo(({
            maxHeight, snapPoints
        }) =>
            iconArrow ? snapPoints[halfway] : maxHeight
        );
    }
}

function SidebarWrapper(props) {
    const {bottomSheetRef} = props;

    const [iconArrow, setIconArrow] = React.useState(() => false);

    const header = (
        <div
            className={'bottom-sheet-header'}
            onClick={() => snapOpenClose(bottomSheetRef, iconArrow)}
        >
            {iconArrow ? <IconHandleChevronDown/> : <IconHandleChevronUp/>}
        </div>
    );

    const onSpringStart = () => {
        requestAnimationFrame(() => {
            const fullscreen = bottomSheetRef.current.height >= window.innerHeight - mainNavAndSecondaryNav;

            setIconArrow(fullscreen);
        });
    };

    return (
        props.isMobile ?
            (<BottomSheet
                blocking={false}
                className={'bottom-sheet'}
                defaultSnap={({snapPoints}) => snapPoints[0]}
                header={header}
                onSpringStart={onSpringStart}
                open={true}
                ref={bottomSheetRef}
                scrollLocking={false}
                snapPoints={({maxHeight}) => getSnapPoints(maxHeight)}
            >
                {props.children}
            </BottomSheet>) : props.children
    );
}

SidebarWrapper.propTypes = {
    bottomSheetRef: PropTypes.reference,
    children: PropTypes.node,
    closeBottomSheet: PropTypes.bool,
    isMobile: PropTypes.bool
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(SidebarWrapper);
