// Unpublished Work © 2021-2024 Deere & Company.

import {equipmentAreaTranslationKeys} from 'Common/constants/equipment-area-translation-keys';

function replaceTranslationNames(translation, names) {
    const translationOrDefault = translation || '';

    return Object.keys(names).reduce((replacedText, key) => {
        const regexMatch = new RegExp(`\\{${key}}`, 'g');

        return replacedText.replace(regexMatch, names[key]);
    }, translationOrDefault);
}

function capitalizeFirstLetter(sentence) {
    return sentence?.toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase());
}

function translateEquipmentArea(translations, equipmentArea) {
    return translations?.[equipmentAreaTranslationKeys[equipmentArea]];
}

export {
    replaceTranslationNames,
    translateEquipmentArea,
    capitalizeFirstLetter
};
