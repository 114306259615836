// Unpublished Work © 2023-2024 Deere & Company.

import {isEmptyArray} from 'Common/utils/validation-utils';
import {setInArray} from 'Common/utils/array-handlers';

function isCurrentPath(currentPath, {
    basePath = [],
    prioritizeList = []
} = {}) {
    if (isEmptyArray(setInArray(basePath)) && isEmptyArray(prioritizeList)) {
        return false;
    }

    const lowerCasePath = currentPath.toLowerCase();

    const noActivePrioritizedLinks = prioritizeList.every((prioritizedPath) => {
        return !lowerCasePath.startsWith(prioritizedPath.toLowerCase());
    });

    const hasActiveBasePath = setInArray(basePath).some((path) => lowerCasePath.startsWith(path.toLowerCase()));

    return noActivePrioritizedLinks && hasActiveBasePath;
}

export {
    isCurrentPath
};
