// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiSelect} from '@deere/form-controls';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {updateMembershipData} from 'Services/membership-service';
import {updateMembership as updateMembershipRedux} from 'Store/actions/membership';
import {getDefault, mapLabels} from 'Utils/workboard-settings-utils';

function getPropOrDefault(propertyName, membership, translations) {
    return membership.properties && membership.properties[propertyName] || getDefault(propertyName, translations);
}

function getSwitchValue(propertyName, membership, translations) {
    return getPropOrDefault(propertyName, membership, translations) === 'true';
}

function initializeState(membership, translations) {
    const [values, setValues] = React.useState(() => ({
        'mywork_jobs_displayed': getPropOrDefault('mywork_jobs_displayed', membership, translations),
        'mywork_workboard_view': getPropOrDefault('mywork_workboard_view', membership, translations),
        'mywork_show_estimated_hours': getSwitchValue('mywork_show_estimated_hours', membership, translations),
        'mywork_show_route': getSwitchValue('mywork_show_route', membership, translations),
        'mywork_show_operator_note': getSwitchValue('mywork_show_operator_note', membership, translations),
        'mywork_show_pre_start_check': getSwitchValue('mywork_show_pre_start_check', membership, translations),
        'mywork_show_protection_icons': getSwitchValue('mywork_show_protection_icons', membership, translations)
    }));

    return {
        values,
        setValues
    };
}

async function onSave(updateMembership, setIsEditing, membership, values) {
    const updatedProperties = {
        ...membership.properties,
        ...values,
        'mywork_show_estimated_hours': `${values.mywork_show_estimated_hours}`,
        'mywork_show_route': `${values.mywork_show_route}`,
        'mywork_show_operator_note': `${values.mywork_show_operator_note}`,
        'mywork_show_pre_start_check': `${values.mywork_show_pre_start_check}`,
        'mywork_show_protection_icons': `${values.mywork_show_protection_icons}`
    };

    await updateMembershipData({
        properties: updatedProperties
    }, membership.membershipId);

    updateMembership({
        ...membership,
        properties: updatedProperties
    });

    setIsEditing();
}

function EditableMyWorkSettings(props) {
    const {
        membership,
        setIsEditing,
        translations,
        updateMembership
    } = props;

    const {
        values,
        setValues
    } = initializeState(membership, translations);

    function onChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const showProtectionIcons = (
        <div className='settings-group horizontal'>
            <SwitchInput
                checked={values.mywork_show_protection_icons}
                className='workboard-switch-input'
                name='mywork_show_protection_icons'
                onChange={onChange}
                onLabel={translations.ONLINK_SHOW_PROTECTION_ICONS}
                translations={translations}
            />
        </div>
    );

    return (
        <>
            <div className='settings-body'>
                <div className='editable-my-work-settings'>
                    <form>
                        <div className='settings-group mobile end-margin'>
                            <div className='settings-block'>
                                <MultiSelect
                                    className='multiselect'
                                    hideFilter={true}
                                    items={mapLabels('mywork_jobs_displayed', translations)}
                                    label={translations.ONLINK_JOBS_DISPLAYED}
                                    multiple={false}
                                    name='mywork_jobs_displayed'
                                    onChange={(selectedIds) => onChange('mywork_jobs_displayed', selectedIds[0])}
                                    selectedIds={[values.mywork_jobs_displayed]}
                                    tabIndex={0}
                                />
                            </div>
                            <div className='settings-block'>
                                <MultiSelect
                                    className='multiselect'
                                    hideFilter={true}
                                    items={mapLabels('mywork_workboard_view', translations)}
                                    label={translations.ONLINK_WORKBOARD_VIEW}
                                    multiple={false}
                                    name='mywork_workboard_view'
                                    onChange={(selectedIds) => onChange('mywork_workboard_view', selectedIds[0])}
                                    selectedIds={[values.mywork_workboard_view]}
                                    tabIndex={0}
                                />
                            </div>
                        </div>
                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.mywork_show_estimated_hours}
                                className='workboard-switch-input'
                                name='mywork_show_estimated_hours'
                                onChange={onChange}
                                onLabel={translations.ONLINK_SHOW_ESTIMATED_HOURS}
                                translations={translations}
                            />
                        </div>
                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.mywork_show_operator_note}
                                className='workboard-switch-input'
                                name='mywork_show_operator_note'
                                onChange={onChange}
                                onLabel={props.translations.ONLINK_SHOW_OPERATOR_NOTE}
                                translations={props.translations}
                            />
                        </div>
                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.mywork_show_pre_start_check}
                                className='workboard-switch-input'
                                name='mywork_show_pre_start_check'
                                onChange={onChange}
                                onLabel={props.translations.ONLINK_SHOW_PRE_START_CHECK}
                                translations={props.translations}
                            />
                        </div>
                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.mywork_show_route}
                                className='workboard-switch-input'
                                name='mywork_show_route'
                                onChange={onChange}
                                onLabel={translations.ONLINK_SHOW_ROUTE}
                                translations={translations}
                            />
                        </div>
                        {showProtectionIcons}
                    </form>
                </div>
            </div>
            <SaveContentBar
                onCancelClick={setIsEditing}
                onSaveClick={() => onSave(updateMembership, setIsEditing, membership, values)}
                translations={translations}
            />
        </>
    );
}

EditableMyWorkSettings.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    translations: PropTypes.translations,
    updateMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateMembership(value) {
            dispatch(updateMembershipRedux(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditableMyWorkSettings);
