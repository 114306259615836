// Unpublished Work © 2022-2024 Deere & Company.

import * as analyticsActions from 'Store/actions/analytics';

const DEFAULT_STATE = {
    hashedAppUserId: null,
    hashedMembershipId: null,
    hashedCurrentMembershipId: null
};

const analytics = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case analyticsActions.SET_HASHED_USER_VALUES:
            return Object.assign({}, state, {
                hashedAppUserId: action.analytics.hashedAppUserId || state.hashedAppUserId,
                hashedMembershipId: action.analytics.hashedMembershipId || state.hashedMembershipId,
                hashedCurrentMembershipId: action.analytics.hashedCurrentMembershipId || state.hashedCurrentMembershipId
            });
        default:
            return state;
    }
};

export default analytics;
