// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Toast} from '@deere/toast';
import {removeToast as removeReduxToast} from 'Store/actions/toasts';

const FIVE_SECONDS = 5000;

function Toasts(props) {
    const {
        removeToast,
        toasts
    } = props;

    return (
        <div className='toast-container'>
            {
                toasts.map((toast) => {
                    const removeToastHandler = () => removeToast(toast.id);

                    if (!toast.persist) {
                        setTimeout(removeToastHandler, FIVE_SECONDS);
                    }

                    return (
                        <div
                            className='toast-wrapper'
                            key={toast.id}
                        >
                            <Toast
                                closeHandler={removeToastHandler}
                                message={toast.message}
                                position='relative'
                                type={toast.type}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
}

Toasts.propTypes = {
    removeToast: PropTypes.func,
    toasts: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        toasts: state.toasts.toasts
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        removeToast(id) {
            dispatch(removeReduxToast(id));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
