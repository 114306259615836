// Unpublished Work © 2020-2024 Deere & Company.

import * as dialogsActions from 'Store/actions/dialogs';

const DEFAULT_STATE = {
    openDialogs: {}
};

function getDialogCount(openDialogs) {
    return Object.keys(openDialogs)
        .filter((key) => openDialogs[key] !== undefined)
        .length + 1;
}

const dialogs = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case dialogsActions.OPEN_DIALOG:
            return Object.assign({}, state, {
                openDialogs: {
                    ...state.openDialogs,
                    [action.dialogType]: {
                        props: action.props,
                        dialogOrder: getDialogCount(state.openDialogs)
                    }
                }
            });
        case dialogsActions.CLOSE_DIALOG:
            return Object.assign({}, state, {
                openDialogs: {
                    ...state.openDialogs,
                    [action.dialogType]: undefined
                }
            });
        default:
            return state;
    }
};

export default dialogs;
