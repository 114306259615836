// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import SwitchInput from 'Ui/components/common/form/switch-input';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {useSave} from 'Ui/react-hooks/use-save';
import {
    createCourseNameToIdMap,
    createVendor,
    deleteCourseNameToIdMap,
    getVendors,
    isMultiCourse
} from 'Services/vendor-service';
import {fetchEffectData} from 'Utils/react-utils';
import {
    isEmptyString,
    hasWhiteSpace
} from 'Common/utils/validation-utils';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {SETTINGS} from 'Common/constants/routes';
import {TOAST_TYPE} from '@deere/toast';
import {translateErrorMessage} from 'Utils/error-utils';
import {getVendorSearchParams} from 'Common/utils/vendor-utils';

const API_KEY = 'm6z32khtf96j';
const FORETEES = 'ForeTees';

async function getForeTeesData() {
    const [{vendors = [] }, isMultiCourseData] = await Promise.all([
        getVendors(FORETEES),
        isMultiCourse(FORETEES)
    ]);

    const teesheetVendor = vendors.find((vendor) => vendor.apiType === 'teesheet');
    const searchParams = getVendorSearchParams(teesheetVendor);

    return {
        club: searchParams.get('club'),
        course: searchParams.get('course') || '',
        isMultiCourse: isMultiCourseData.isMultiCourse,
        canToggle: isMultiCourseData.canToggle,
        siblingClubName: isMultiCourseData.siblingClubName
    };
}

async function updateCourseName(courseName, courseNameInDatabase) {
    if (courseNameInDatabase.current !== courseName) {
        if (courseName) {
            await createCourseNameToIdMap({
                courseName,
                dataSource: FORETEES
            });
        }

        if (courseNameInDatabase.current) {
            await deleteCourseNameToIdMap(FORETEES, courseNameInDatabase.current);
        }

        courseNameInDatabase.current = courseName;
    }
}

function initializeState() {
    const [canToggleMultiCourse, setCanToggleMultiCourse] = React.useState(false);
    const [clubName, setClubName] = React.useState('');
    const [courseName, setCourseName] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [multiCourse, setMultiCourse] = React.useState(false);

    return {
        canToggleMultiCourse,
        clubName,
        courseName,
        loading,
        multiCourse,
        setCanToggleMultiCourse,
        setClubName,
        setCourseName,
        setLoading,
        setMultiCourse
    };
}

async function updateMultiCourseAndCanToggle(setCanToggleMultiCourse, setMultiCourse) {
    const {
        canToggle, isMultiCourse: isMultiCourseData
    } = await isMultiCourse(FORETEES);

    setCanToggleMultiCourse(canToggle);
    setMultiCourse(isMultiCourseData);
}

function ForeTeesSettings(props) {
    const {
        addToast,
        history,
        invalidInputs,
        membership,
        setValid,
        translations
    } = props;

    const courseNameInDatabase = React.useRef('');

    const {
        canToggleMultiCourse,
        clubName,
        courseName,
        loading,
        multiCourse,
        setCanToggleMultiCourse,
        setClubName,
        setCourseName,
        setLoading,
        setMultiCourse
    } = initializeState();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {
            club,
            course,
            isMultiCourse,
            canToggle,
            siblingClubName
        } = await getForeTeesData();

        courseNameInDatabase.current = course;
        const clubName = club || siblingClubName || '';

        if (isMounted()) {
            setClubName(clubName);
            setCourseName(course);
            setCanToggleMultiCourse(canToggle);
            setMultiCourse(isMultiCourse);
            setLoading(false);
        }
    }), []);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const vendorData = {
            apiKey: API_KEY,
            clubName: clubName || undefined,
            courseName: multiCourse ? courseName || undefined : undefined,
            membershipId: membership.membershipId,
            name: 'ForeTees',
            tzOffsetMillis: 0
        };

        const updatedCourseName = multiCourse ? courseName || '' : '';

        setCourseName(updatedCourseName);

        try {
            await updateCourseName(updatedCourseName, courseNameInDatabase);
            await createVendor(vendorData);

            await updateMultiCourseAndCanToggle(setCanToggleMultiCourse, setMultiCourse);

            addToast({
                message: translations.SUCCESS,
                type: TOAST_TYPE.SUCCESS
            });
        } catch (e) {
            const errorMessage = translateErrorMessage(e.response.data.message, translations);

            addToast({
                message: errorMessage,
                type: TOAST_TYPE.ERROR
            });
        }
    }, {
        disabled: loading,
        invalidInputs
    });

    const isClubNameEmpty = isEmptyString(clubName);
    const isCourseNameEmpty = isEmptyString(courseName);

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        errors: {
            valueMissing: translations.REQUIRED_FIELD_TEXT,
            customError: translations.ONLINK_NO_WHITESPACE
        },
        setValid,
        tabIndex: 0,
        type: 'text'
    };

    return (
        <div className='settings-container'>
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <form
                    className='settings-body'
                    onSubmit={saveFunc}
                >
                    {
                        canToggleMultiCourse &&
                        <SwitchInput
                            checked={multiCourse}
                            className='end-margin'
                            name='multiCourse'
                            onChange={(name, checked) => setMultiCourse(checked)}
                            onLabel={translations.ONLINK_MULTI_COURSE_CONFIG}
                            translations={translations}
                        />
                    }
                    <div className='settings-group'>
                        <ValidationInput
                            {...sharedInputProps}
                            invalidCustomError={hasWhiteSpace(clubName)}
                            label={translations.ONLINK_CLUB_NAME}
                            name='clubName'
                            onChange={(event) => setClubName(event.target.value)}
                            onClear={() => setClubName('')}
                            required={multiCourse && isClubNameEmpty && !isCourseNameEmpty}
                            value={clubName}
                        />
                        {
                            multiCourse &&
                            <ValidationInput
                                {...sharedInputProps}
                                invalidCustomError={hasWhiteSpace(courseName)}
                                label={translations.ONLINK_COURSE_NAME}
                                name='courseName'
                                onChange={(event) => setCourseName(event.target.value)}
                                onClear={() => setCourseName('')}
                                required={multiCourse && !isClubNameEmpty && isCourseNameEmpty}
                                value={courseName}
                            />
                        }
                    </div>
                </form>
            </LoadingWrapper>
            <SaveContentBar
                disabled={disableSave}
                loading={isSaving}
                onCancelClick={() => history.push(SETTINGS)}
                onSaveClick={saveFunc}
                translations={translations}
            />
        </div>
    );
}

ForeTeesSettings.propTypes = {
    addToast: PropTypes.func,
    history: PropTypes.history,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(withRouter(ForeTeesSettings)));
