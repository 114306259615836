// Unpublished Work © 2020-2024 Deere & Company.

import * as membershipActions from 'Store/actions/membership';
import {CURRENCY_PREFERENCE, UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {US} from 'Common/constants/pinpoint-constants';

const DEFAULT_STATE = {
    address1: null,
    address2: null,
    addressElements: [],
    childLevels: null,
    city: null,
    country: null,
    currencyPreference: null,
    fleetId: null,
    latitude: null,
    logoUrl: null,
    longitude: null,
    membershipId: null,
    membershipType: null,
    mowingDirections: [],
    name: null,
    parent: null,
    phone: null,
    preferredDataTypes: [],
    properties: {},
    siblings: null,
    state: null,
    unitOfMeasure: null,
    unitOfMeasureOverrides: [],
    zip: null,
    isMigrated: null,
    orgId: null
};

const membership = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case membershipActions.UPDATE_MEMBERSHIP:
            return Object.assign({}, state, {
                address1: action.membership.address1,
                address2: action.membership.address2,
                addressElements: action.membership.addressElements || [],
                childLevels: action.membership.childLevels,
                city: action.membership.city,
                country: action.membership.country || US,
                currencyPreference: action.membership.currencyPreference || CURRENCY_PREFERENCE.USD,
                fleetId: action.membership.fleetId,
                latitude: action.membership.latitude,
                logoUrl: action.membership.logoUrl,
                longitude: action.membership.longitude,
                membershipId: action.membership.membershipId,
                membershipType: action.membership.membershipType,
                mowingDirections: action.membership.mowingDirections || [],
                name: action.membership.name,
                parent: action.membership.parent,
                phone: action.membership.phone,
                preferredDataTypes: action.membership.preferredDataTypes || [],
                properties: action.membership.properties || {},
                siblings: action.membership.siblings,
                state: action.membership.state,
                unitOfMeasure: action.membership.unitOfMeasure || UNIT_OF_MEASURE.ENGLISH,
                unitOfMeasureOverrides: action.membership.unitOfMeasureOverrides || [],
                zip: action.membership.zip,
                isMigrated: action.membership.isMigrated,
                orgId: action.membership.orgId
            });
        default:
            return state;
    }
};

export default membership;
