// Unpublished Work © 2020-2024 Deere & Company.

import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {US} from 'Common/constants/pinpoint-constants';
import {PAGE_MAP} from 'Ui/constants/analytics-constants';
import {ONLINK_MEMBERSHIP_ID} from 'Ui/constants/membership-properties';
import store from 'Store/store';

const ORG_ID_REGEX = /(org(?:anization)?Id=(\d{4,}))/gi;
const ORG_ID_IN_PATH_REGEX = /(\/)(\d{4,})(?=[/?]|$)/g;
const GUID_REGEX = /([a-z\d]{8}-)([a-z\d]{4}-){3}([a-z\d]{12})/gi;
const IP_REGEX = /(:?(:?\d{1,3}\.){3}(:?\d{1,3}))/g;
const IPV6_REGEX = /(?:[a-z\d]{4}:){7}[a-z\d]{4}/gi;
const EMAIL_REGEX = /[a-z\d]+@[a-z\d]+.[a-z\d]+/gi;
const USER_REGEX = /(user(?:name)?=[a-z0-9]{4,})/gi;
const PIN_VIN_REGEX = /(?=[a-z-]*\d[a-z-]*)(?:(?:[\da-z-]{17})|(?:[\da-z-%]{1,13}))/gi;
const PATH_SEPARATOR_REGEX = /\//g;

const ORG_ID_REPLACEMENT = 'orgId';
const GUID_REPLACEMENT = '*';
const IP_REPLACEMENT = 'ipAddress';
const EMAIL_REPLACEMENT = 'email';
const USER_REPLACEMENT = 'user';
const MACHINE_IDENTIFIER_REPLACEMENT = 'machineIdentifier';
const PARTS_CATALOG = 'Parts Catalog';

export const ANALYTICS_EVENTS = {
    PAGE_LOADED: 'Page Loaded',
    TRACK_EVENT: 'Track Event'
};

export const ACTIONS = {
    FLEET_MENU: 'Fleet-Menu',
    FLEET_MODEL: 'Fleet-Model',
    DASHBOARD_MENU: 'Dashboard Menu',
    PARTS_INVENTORY: 'Parts-Inventory'
};
export const CATEGORY = {
    PARTS_CATALOG,
    ONLINK_DASHBOARD: 'Onlink Dashboard'
};

export const LABELS = {
    PARTS_CATALOG,
    MODEL_CODE: 'Model Code',
    MANAGE_DASHBOARDS: 'Manage-Dashboards',
    PART_NUMBER: 'Part #'
};

const COUNTRIES_WITH_COOKIES = ['AU', 'CA', 'NZ', US];
const EMPLOYEE_MEMBERSHIPS = [ONLINK_MEMBERSHIP_ID, 'd6bdd874-2490-4efb-a5ac-5ea5a907d820'];

function removeOrgId(input) {
    return input.replace(ORG_ID_REGEX, ORG_ID_REPLACEMENT).replace(ORG_ID_IN_PATH_REGEX, `$1${ORG_ID_REPLACEMENT}`);
}

function removeGuids(input) {
    return input.replace(GUID_REGEX, GUID_REPLACEMENT);
}

function removeIpAddress(input) {
    return input.replace(IP_REGEX, IP_REPLACEMENT).replace(IPV6_REGEX, IP_REPLACEMENT);
}

function removeEmail(input) {
    return input.replace(EMAIL_REGEX, EMAIL_REPLACEMENT);
}

function removeUser(input) {
    return input.replace(USER_REGEX, USER_REPLACEMENT);
}

function removePinAndSerialNumber(input) {
    return input.replace(PIN_VIN_REGEX, MACHINE_IDENTIFIER_REPLACEMENT);
}

const SCRUBBERS = [
    removeOrgId,
    removeGuids,
    removeIpAddress,
    removeEmail,
    removeUser,
    removePinAndSerialNumber
];

function scrubPii(input) {
    return SCRUBBERS.reduce((acc, scrubber) => scrubber(acc), input);
}

function createAnalyticsObject(event, eventData) {
    const {location} = document;
    const {
        account, analytics, membership
    } = store.getState();
    const {activeLayout} = account;
    const languagePreference = getLanguagePreference();
    const allowCookies = COUNTRIES_WITH_COOKIES.includes(membership.country) ? 'allowed' : 'not-allowed';

    const analyticsObject = {
        event,
        page: {
            appName: 'isg-onlink',
            dashboard: activeLayout?.name || null,
            language: languagePreference,
            locale: languagePreference.replace('-', '_').toLowerCase(),
            membership: analytics.hashedCurrentMembershipId,
            pageCategory: location.pathname.slice(location.pathname.lastIndexOf('/') + 1),
            pageDomain: scrubPii(location.hostname),
            pageName: PAGE_MAP[location.pathname] || location.pathname.slice(1).replace(PATH_SEPARATOR_REGEX, ' : '),
            pageURL: scrubPii(location.href)
        },
        privacy: {
            analyticsCookies: allowCookies,
            marketingCookies: allowCookies
        },
        user: {
            hashedUserId: analytics.hashedAppUserId,
            loginStatus: account.appUserId ? 'logged-in' : 'guest',
            userType: EMPLOYEE_MEMBERSHIPS.includes(account.membershipId) ? 'employee' : 'customer'
        }
    };

    return Object.assign(analyticsObject, eventData && {
        eventData
    });
}

function trackOnLinkEvent(event, eventData) {
    if (window.props.analyticsEnabled) {
        const analyticsObject = createAnalyticsObject(event, eventData);

        window.adobeDataLayer?.push(analyticsObject);
    }
}

function trackDashboardSelection(dashboard) {
    const dashboardType = dashboard.onlinkUserId ? 'Custom' : 'Default';

    trackOnLinkEvent(`Selected ${dashboardType} Dashboard ${dashboard.name}`);
}

export {
    trackDashboardSelection,
    trackOnLinkEvent
};
