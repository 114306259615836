// Unpublished Work © 2020-2024 Deere & Company.

import {isAuthorized} from 'Common/utils/authorization-handler';

const PANEL_TITLES = {
    'OnApply': 'Playbooks'
};

function skipTile(integrations, layout, userAuth) {
    const requiredAuth = {
        appAccess: layout.appAccess,
        myJdPermissions: layout.myJdPermissions,
        toggle: layout.toggle
    };

    const skipDueToAuthFailure = !isAuthorized(requiredAuth, userAuth);
    const skipDueToIntegration = layout.integration && integrations.every(({name}) => name !== layout.integration);

    return skipDueToAuthFailure || skipDueToIntegration;
}

function createTileFromLayout(tile, layout, integrations, userAuth) {
    const newTile = tile || {};

    if (skipTile(integrations, layout, userAuth)) {
        return null;
    }

    if (!newTile.dataRollups || !layout.data) {
        return newTile;
    }

    const dataRollupMap = newTile.dataRollups.reduce((rollupMap, dataRollup) => {
        rollupMap.set(dataRollup.dataType, dataRollup);

        return rollupMap;
    }, new Map());
    const dataRollups = layout.data.reduce((newDataRollups, dataType) => {
        const dataRollup = dataRollupMap.get(dataType);

        return dataRollup ?
            newDataRollups.concat(dataRollup) :
            newDataRollups;
    }, []);

    if (!layout.data.length) {
        newTile.panelData = null;
    }

    return {
        ...newTile,
        panelTitle: PANEL_TITLES[newTile.panelTitle] || newTile.panelTitle,
        dataRollups
    };
}

function createTileMap(tileMap, tile) {
    tileMap[tile.panelType] = tile;

    return tileMap;
}

function getTileIds(tilePositions) {
    if (!tilePositions) {
        return [];
    }

    const tileIds = tilePositions.map(({tile}) => tile.id);
    const uniqueTileIds = new Set(tileIds);

    return Array.from(uniqueTileIds);
}

export {
    createTileFromLayout,
    createTileMap,
    getTileIds
};
