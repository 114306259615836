// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import React from 'react';
import {fetchEffectData} from 'Utils/react-utils';
import {getTodoTemplates} from 'Services/membership-service';
import DataTable from 'Ui/components/common/data-table/data-table';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {deleteTodoTemplate} from 'Services/todo-service';
import EditLink from 'Ui/components/common/data-table/edit-link';
import AddButton from 'Ui/components/settings/common/add-button';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {FORBIDDEN} from 'Common/constants/errors';

function getColumns(translations, fetchTodoTemplate, openQuickTodoDialog) {
    return [
        {
            Header: translations.ONLINK_QUICK_TODO,
            accessor: 'title',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => openQuickTodoDialog({
                            todoTemplate: original,
                            getUpdatedTodoTemplates: fetchTodoTemplate
                        })}
                    >
                        {value}
                    </EditLink>
                );
            }
        }
    ];
}

function getFilterComponent(translations, openQuickTodoDialog, fetchTodoTemplate) {
    return (
        <div className='extra-table-options'>
            <AddButton
                className='add-quick-todo-button'
                label={translations.ONLINK_ADD_QUICK_TODO}
                onClick={() => {
                    openQuickTodoDialog({
                        translations,
                        getUpdatedTodoTemplates: fetchTodoTemplate
                    });
                }}
            />
        </div>
    );
}

function QuickTodoSettings(props) {
    const {
        translations,
        openConfirmation,
        closeConfirmation,
        openQuickTodoDialog,
        addToast
    } = props;

    const [loading, setLoading] = React.useState(() => true);
    const [todoTemplates, setTodoTemplates] = React.useState(() => []);

    async function fetchTodoTemplates(isMounted = () => true) {
        setLoading(true);

        const {todoTemplates} = await getTodoTemplates();

        if (isMounted()) {
            setTodoTemplates(todoTemplates);
            setLoading(false);
        }
    }

    function deleteTodoTemplateById(todoTemplate) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_QUICK_TODO.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_QUICK_TODO
            }),
            async onContinue() {
                closeConfirmation();
                try {
                    await deleteTodoTemplate(todoTemplate.todoTemplateId);

                    await fetchTodoTemplates();
                } catch (e) {
                    if (e.response.status !== FORBIDDEN) {
                        addToast({
                            message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                                '0': translations.ONLINK_QUICK_TODO.toLowerCase()
                            }),
                            type: TOAST_TYPE.ERROR
                        });
                    }
                }
            },
            onCancel: closeConfirmation
        });
    }

    React.useEffect(() => fetchEffectData(fetchTodoTemplates), []);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, fetchTodoTemplates, openQuickTodoDialog)}
                defaultSorted={[{
                    desc: false,
                    id: 'title'
                }]}
                deleteHandler={deleteTodoTemplateById}
                filterComponent={getFilterComponent(translations, openQuickTodoDialog, fetchTodoTemplates)}
                loading={loading}
                rows={todoTemplates}
                searchable={true}
                showDeleteColumn={true}
                translations={translations}
            />
        </div>
    );
}

QuickTodoSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    openConfirmation: PropTypes.func,
    openQuickTodoDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openQuickTodoDialog(props) {
            dispatch(openDialog(dialogTypes.QUICK_TODO_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickTodoSettings);
