// Unpublished Work © 2023-2024 Deere & Company.

export const VIEW_COURSE_MEASUREMENT_DATA = 'VIEW_COURSE_MEASUREMENT_DATA';
export const VIEW_ORGANIZATIONAL_PREFERENCES = 'VIEW_ORGANIZATIONAL_PREFERENCES';
export const VIEW_STAFF = 'VIEW_STAFF';
export const ADD_REMOVE_STAFF = 'ADD_REMOVE_STAFF';
export const MAKE_CONNECTIONS = 'MAKE_CONNECTIONS';
export const VIEW_EQUIPMENT_LIST_API = 'VIEW_EQUIPMENT_LIST_API';
export const VIEW_FINANCIAL_DATA = 'VIEW_FINANCIAL_DATA';
export const VIEW_WORKBOARD_SUMMARY_DATA = 'VIEW_WORKBOARD_SUMMARY_DATA';
export const UPDATE_ORGANIZATIONAL_PREFERENCES = 'UPDATE_ORGANIZATIONAL_PREFERENCES';
export const ORGANIZATION_PREFERENCES = 'ORGANIZATION_PREFERENCES';
export const VIEW_COURSE_PLAYER_DATA = 'VIEW_COURSE_PLAYER_DATA';
export const VIEW_WORKBOARDS = 'VIEW_WORKBOARDS';
export const MANAGE_TIMEOFF = 'MANAGE_TIMEOFF';
export const MANAGE_WORKBOARDS = 'MANAGE_WORKBOARDS';
export const EDIT_EQUIPMENT_SETTINGS = 'EDIT_EQUIPMENT_SETTINGS';
export const EDIT_COURSE_MEASUREMENT_DATA = 'EDIT_COURSE_MEASUREMENT_DATA';
export const DELETE_COURSE_MEASUREMENT_DATA = 'DELETE_COURSE_MEASUREMENT_DATA';
export const ADD_COURSE_MEASUREMENT_DATA = 'ADD_COURSE_MEASUREMENT_DATA';
export const ADD_COURSE_REVENUE_COST = 'ADD_COURSE_REVENUE_COST';
export const EDIT_EQUIPMENT_LIST = 'EDIT_EQUIPMENT_LIST';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const REMOVE_EQUIPMENT = 'REMOVE_EQUIPMENT';
export const MANAGE_EQUIPMENT_SETTINGS = 'MANAGE_EQUIPMENT_SETTINGS';
export const MODIFY_FINANCIAL_DATA = 'MODIFY_FINANCIAL_DATA';
export const TERMINAL_TRANSFER = 'TERMINAL_TRANSFER';
export const MANAGE_SERVICE = 'MANAGE_SERVICE';
export const VIEW_EQUIPMENT_DETAILS = 'VIEW_EQUIPMENT_DETAILS';
export const VIEW_SERVICE = 'VIEW_SERVICE';
export const VIEW_PARTS = 'VIEW_PARTS';
export const EDIT_PARTS = 'EDIT_PARTS';
export const MANAGE_PARTS = 'MANAGE_PARTS';
export const EDIT_ORGANIZATION_MYJD = 'EDIT_ORGANIZATION_MYJD';
export const VIEW_PRO_GOLF = 'VIEW_PRO_GOLF';
