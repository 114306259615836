// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Datepicker} from '@deere/form-controls';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import Panel from 'Ui/components/panel/panel';
import TeeSheetCard from 'Ui/components/graph/tee-sheet-card';
import {getDataSource} from 'Ui/services/panel-service';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {DATE_FORMATS, DISPLAY_FORMATS, formatTime} from 'Utils/time-utils';
import {formatNumber, getLanguagePreference} from 'Utils/unit-conversion-utils';
import moment from 'moment';
import {chunk} from 'lodash';
import NoDataMessage from 'Ui/components/common/message/no-data-message';

const CHUNK_LENGTH = 26;
const COURSE_INDEX = 3;
const HANDI_INDEX = 21;
const HOLE_18 = 18;
const HOLE_9 = 9;
const HOLES_INDEX = 16;
const NAME_INDEX = 6;
const NH = '-99.0';
const PLAYER_LENGTH = 5;
const TRANS_INDEX = 11;

function getPlayers(data) {
    const playerList = [];

    for (let i = 0; i < PLAYER_LENGTH; i++) {
        const name = data[NAME_INDEX + i];

        if (name !== '') {
            const handicap = data[HANDI_INDEX + i];
            const holes = data[HOLES_INDEX + i] === '0' ? HOLE_18 : HOLE_9;
            const formattedHandiCapValue = handicap ?
                formatNumber(handicap * -1).replace('-', '+') :
                0;

            playerList.push({
                handicap: handicap === NH ? 'NH' : formattedHandiCapValue,
                holes: formatNumber(holes),
                name,
                trans: data[TRANS_INDEX + i]
            });
        }
    }

    return playerList;
}

function parseTeeSheet(foreTeesData) {
    const tabularData = foreTeesData.teesheet?.tabularData || '';
    const dataArray = tabularData ?
        tabularData.substring(0, tabularData.length - 1).split(',') :
        [];
    const chunkedData = chunk(dataArray, CHUNK_LENGTH);

    return chunkedData.map((data) => {
        const formattedData = data.map((item) => item.substring(1, item.length - 1));

        return {
            course: formattedData[COURSE_INDEX],
            date: moment(formattedData[0]).format('M/DD/YYYY'),
            player: getPlayers(formattedData),
            time: moment(formattedData[1], DISPLAY_FORMATS.minute).format('LT')
        };
    });
}

function TeeSheets(props) {
    const {
        membershipId,
        translations
    } = props;

    const [datePickerDay, setDatePickerDay] = React.useState(() => moment());
    const [loading, setLoading] = React.useState(true);
    const [mappedData, setMappedData] = React.useState([]);

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const dateSelect = formatTime(datePickerDay, DATE_FORMATS.day);
        const foreTeesData = await getDataSource('ForeTees', dateSelect);

        if (isMounted()) {
            const parsedData = parseTeeSheet(foreTeesData);

            setMappedData(parsedData);
            setLoading(false);
        }
    }), [datePickerDay, membershipId]);

    const hasData = mappedData.length > 0;

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            <Panel
                className='integrations'
                title='TEE SHEETS'
                translations={translations}
            >
                <div className='integrations-title'>{'FORETEES'}</div>
                <div className='integrations-date-picker'>
                    <Datepicker
                        className='graph-datepicker-integrations'
                        dateFormat='LL'
                        large={true}
                        locale={getLanguagePreference()}
                        maxDate={moment()}
                        onChange={setDatePickerDay}
                        onChangeRaw={(event) => setDatePickerDay(moment(event.target.value))}
                        selected={datePickerDay}
                    />
                </div>
                <NoDataMessage
                    hasData={hasData}
                    noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
                >
                    {
                        mappedData.map((data, i) => (
                            <TeeSheetCard
                                data={data}
                                key={i}
                            />
                        ))
                    }
                </NoDataMessage>
            </Panel>
        </LoadingWrapper>
    );
}

TeeSheets.propTypes = {
    membershipId: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId
    };
}

export default connect(mapStateToProps)(TeeSheets);
