// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';

function setActiveFocusInterval(callback, delay, intervalDelay = delay) {
    let millisecondsElapsed = 0;

    return setInterval(() => {
        millisecondsElapsed += intervalDelay;

        if (document.hasFocus() && millisecondsElapsed >= delay) {
            callback();

            millisecondsElapsed = 0;
        }
    }, intervalDelay);
}

function useRefresh(callback) {
    const [isRefreshing, setIsRefreshing] = React.useState(() => false);

    async function refreshFunc() {
        setIsRefreshing(true);

        const response = await callback();

        setIsRefreshing(false);

        return response;
    }

    return [isRefreshing, refreshFunc];
}

export {
    setActiveFocusInterval,
    useRefresh
};
