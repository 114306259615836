// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

function MapLegend(props) {
    const {translations} = props;

    return (
        <div className={'map-legend'}>
            <span>
                <div className={'location-only'}/>
                {translations.ONLINK_LOCATION_ONLY}
            </span>
            <span>
                <div className={'working'}/>
                {translations.ONLINK_WORKING}
            </span>
            <span>
                <div className={'transporting'}/>
                {translations.ONLINK_TRANSPORT}
            </span>
            <span>
                <div className={'idling'}/>
                {translations.ONLINK_IDLING}
            </span>
        </div>
    );
}

MapLegend.propTypes = {
    translations: PropTypes.translations
};

export default MapLegend;
