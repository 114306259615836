// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import EditableThresholdSettings from 'Ui/components/settings/notifications/editable-threshold-settings';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {updateThreshold} from 'Services/membership-service';
import {convertToUoM, getUoMConfig} from 'Utils/unit-conversion-utils';
import {getUnitOfMeasureOverride} from 'Common/utils/units/unit-converter';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {FUEL_COST, GAS_COST} from 'Common/constants/data-group-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {useSave} from 'Ui/react-hooks/use-save';
import {convert} from '@deere/unit-system';

function getConvertedToUoMValue(conversionConfig, threshold) {
    const {
        dataType,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = conversionConfig;
    const parsedThreshold = parseFloat(threshold);

    const sourceUoM = getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, dataType);

    return sourceUoM === UNIT_OF_MEASURE.METRIC && (dataType === FUEL_COST || dataType === GAS_COST) ?
        convert(parsedThreshold, UNITS.gallons, UNITS.liters) :
        convertToUoM(parsedThreshold, conversionConfig);
}

function getUpdatedThresholds(conversionConfig, threshold) {
    const lowWarning = getConvertedToUoMValue(conversionConfig, threshold.lowWarning);
    const highWarning = getConvertedToUoMValue(conversionConfig, threshold.highWarning);

    return {
        thresholds: {
            lowExtreme: {
                value: lowWarning
            },
            lowWarning: {
                value: lowWarning
            },
            lowNormal: {
                value: getConvertedToUoMValue(conversionConfig, threshold.lowNormal)
            },
            highNormal: {
                value: getConvertedToUoMValue(conversionConfig, threshold.highNormal)
            },
            highWarning: {
                value: highWarning
            },
            highExtreme: {
                value: highWarning
            }
        }
    };
}

function ThresholdEditDialog(props) {
    const {
        closeDialog,
        editHandler,
        invalidInputs,
        original,
        setValid,
        translations,
        membership
    } = props;

    const conversionConfig = {
        dataType: original.threshold?.dataType,
        dataUnit: original.threshold?.dataUnits,
        isConfigForGraph: true,
        unitOfMeasure: membership.unitOfMeasure,
        unitOfMeasureOverrides: membership.unitOfMeasureOverrides,
        metricToImperial: true
    };

    const {formatting: {decimalPlaces = 2}} = getUoMConfig(conversionConfig);

    const [threshold, setThreshold] = React.useState(() => ({
        highNormal: original.highNormal,
        highWarning: original.highWarning,
        lowNormal: original.lowNormal,
        lowWarning: original.lowWarning
    }));

    const onChange = React.useCallback((event) => {
        const {
            name,
            value
        } = event.target;

        const parsedValue = value && value.match(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`)[0] || '';

        setThreshold((prevState) => ({
            ...prevState,
            [name]: parsedValue
        }));
    }, [decimalPlaces, threshold]);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const {dataType} = original.threshold;

        const updatedThreshold = getUpdatedThresholds(conversionConfig, threshold);

        const response = await updateThreshold(updatedThreshold, dataType);

        if (response.success) {
            editHandler(response.dataThreshold);
        }

        closeDialog();
    }, {
        invalidInputs
    });

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={`${translations.EDIT} ${original.types}`}
            translations={translations}
        >
            <EditableThresholdSettings
                {...threshold}
                onChange={onChange}
                setValid={setValid}
                translations={translations}
            />
        </FormDialog>
    );
}

ThresholdEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    editHandler: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    original: PropTypes.editableThreshold,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.THRESHOLD_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(ThresholdEditDialog));
