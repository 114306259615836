// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import EquipmentPage from 'Ui/components/map/equipment-page';
import LocationHistoryPage from 'Ui/components/map/location-history-page';
import {getMapFleetEquipment} from 'Services/membership-service';
import {bottomSheet} from 'Ui/constants/map-constants';
import {fetchEffectData} from 'Utils/react-utils';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {TOAST_TYPE} from '@deere/toast';
import {sortBy} from 'lodash';

const mapPages = {
    equipment: EquipmentPage,
    'location_history': LocationHistoryPage
};

const FORBIDDEN_STATUS_CODE = 403;

function displayToastOnError(fleet, translations, addToast) {
    if (fleet.failedEquipment.some((failedEquipment) => failedEquipment.output.statusCode !== FORBIDDEN_STATUS_CODE)) {
        const message = fleet.successfulEquipment.length > 0 ?
            translations.ONLINK_RETRIEVING_LIST_ERROR_MESSAGE :
            translations.ONLINK_RETRIEVING_INFORMATION_ERROR_MESSAGE;

        addToast({
            message,
            type: TOAST_TYPE.ERROR
        });
    }
}

async function getFleetWithMembership(membership, translations, addToast) {
    const fleet = await getMapFleetEquipment();

    displayToastOnError(fleet, translations, addToast);

    return {
        fleet: sortBy(fleet.successfulEquipment, 'equipmentName'),
        membership: {
            lat: membership.latitude,
            lng: membership.longitude
        }
    };
}

function initializeState() {
    const [fleetWithMembership, setFleetWithMembership] = React.useState({
        fleet: [],
        membership: {}
    });
    const [loading, setLoading] = React.useState({
        breadcrumbsLoading: false,
        fleetLoading: true
    });
    const [selectedEquipment, setSelectedEquipment] = React.useState(null);
    const [map, setMap] = React.useState(null);

    return {
        bottomSheetRef: React.useRef(),
        fleetWithMembership,
        setFleetWithMembership,
        loading,
        params: React.useRef('equipment'),
        setLoading,
        selectedEquipment,
        setSelectedEquipment,
        map,
        setMap
    };
}

function OnlinkMapWrapper(props) {
    const {
        addToast,
        match,
        membership,
        translations
    } = props;

    const {
        bottomSheetRef,
        fleetWithMembership,
        setFleetWithMembership,
        loading,
        params,
        setLoading,
        selectedEquipment,
        setSelectedEquipment,
        map,
        setMap
    } = initializeState();

    const pageSwitchLoading = params.current !== match.params.type;

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading({
            breadcrumbsLoading: match.params.type === 'location_history',
            fleetLoading: true
        });

        const fleetWithMembership = await getFleetWithMembership(membership, translations, addToast);

        if (isMounted()) {
            setFleetWithMembership(fleetWithMembership);

            if (params.current !== match.params.type) {
                params.current = match.params.type;
            }

            setLoading((prevLoading) => ({
                ...prevLoading,
                fleetLoading: false
            }));

            setSelectedEquipment(null);
        }
    }), [membership, match.params.type]);

    const CurrentPageComponent = mapPages[match.params.type];

    async function autoRefreshFunc(callback) {
        const updatedFleetWithMembership = await getFleetWithMembership(membership, translations, addToast);

        await callback(updatedFleetWithMembership);

        setFleetWithMembership(updatedFleetWithMembership);
    }

    function closeBottomSheet() {
        if (bottomSheetRef.current) {
            bottomSheetRef.current.snapTo(bottomSheet.sheetTab);
        }
    }

    return (
        <CurrentPageComponent
            autoRefreshFunc={autoRefreshFunc}
            bottomSheetRef={bottomSheetRef}
            breadcrumbsLoading={loading.breadcrumbsLoading}
            closeBottomSheet={closeBottomSheet}
            fleetWithMembership={fleetWithMembership}
            loading={loading.fleetLoading || pageSwitchLoading}
            map={map}
            selectedEquipment={selectedEquipment}
            setBreadcrumbsLoading={(value) =>
                setLoading((prevLoading) => ({
                    ...prevLoading,
                    breadcrumbsLoading: value
                }))
            }
            setMap={setMap}
            setSelectedEquipment={setSelectedEquipment}
            translations={translations}
        />
    );
}

OnlinkMapWrapper.propTypes = {
    addToast: PropTypes.func,
    match: PropTypes.match,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OnlinkMapWrapper));
