// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {onManualDataChange, validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {getCurrencySymbol, getLanguagePreference} from 'Utils/unit-conversion-utils';
import {isEmptyString, isNullOrUndefined} from 'Common/utils/validation-utils';
import {MAX_NUMERIC_VALUE, MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {DOLLARS, DOLLARS_PER_LITER, GALLONS, LITERS} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import moment from 'moment';

function getUnitsAndLabel(manualDataUnit, unitOfMeasure, translations) {
    const volumeUnit = manualDataUnit || (unitOfMeasure === UNIT_OF_MEASURE.METRIC ? LITERS : GALLONS);

    if (volumeUnit === LITERS) {
        return {
            priceUnit: DOLLARS_PER_LITER,
            priceLabel: translations.ONLINK_LITER,
            volumeLabel: translations.STANDARD_UOM_LITER,
            volumeUnit
        };
    }

    return {
        priceUnit: DOLLARS,
        priceLabel: translations.ONLINK_GALLON,
        volumeLabel: translations.GALLONS,
        volumeUnit
    };
}

function valueOrEmptyString(value) {
    return isNullOrUndefined(value) ? '' : value;
}

function checkIfInvalid(fuel, fuelCost, gas, gasCost) {
    const isFuelEmpty = isEmptyString(fuel);
    const isFuelCostEmpty = isEmptyString(fuelCost);
    const isGasEmpty = isEmptyString(gas);
    const isGasCostEmpty = isEmptyString(gasCost);

    const anyInputFilledOut = !isFuelEmpty || !isFuelCostEmpty || !isGasCostEmpty || !isGasEmpty;
    const inputPairTheSame = isGasEmpty === isGasCostEmpty && isFuelEmpty === isFuelCostEmpty;

    return !(anyInputFilledOut && inputPairTheSame);
}

function getMemoizedData(unitOfMeasure, translations, values) {
    const manualDataMap = useDeepMemo(() => {
        const {manualData = [] } = values;

        return manualData.reduce((manualDataByType, manualData) => {
            manualDataByType.set(manualData.data_type, manualData);

            return manualDataByType;
        }, new Map());
    }, [values.manualData]);

    const fuel = manualDataMap.get('fuel') || {};
    const fuelCost = manualDataMap.get('fuel_cost') || {};
    const gas = manualDataMap.get('gas') || {};
    const gasCost = manualDataMap.get('gas_cost') || {};

    const {
        priceUnit: dieselPriceUnit,
        priceLabel: dieselPriceLabel,
        volumeLabel: dieselVolumeLabel,
        volumeUnit: dieselVolumeUnit
    } = getUnitsAndLabel(fuel.data_unit, unitOfMeasure, translations);

    const {
        priceUnit: gasPriceUnit,
        priceLabel: gasPriceLabel,
        volumeLabel: gasVolumeLabel,
        volumeUnit: gasVolumeUnit
    } = getUnitsAndLabel(gas.data_unit, unitOfMeasure, translations);

    return {
        dieselPrice: valueOrEmptyString(fuelCost.value),
        dieselPriceLabel,
        dieselPriceUnit,
        dieselQuantity: valueOrEmptyString(fuel.value),
        dieselVolumeLabel,
        dieselVolumeUnit,
        gasPrice: valueOrEmptyString(gasCost.value),
        gasPriceLabel,
        gasPriceUnit,
        gasQuantity: valueOrEmptyString(gas.value),
        gasVolumeLabel,
        gasVolumeUnit,
        isInvalid: checkIfInvalid(fuel.value, fuelCost.value, gasCost.value, gas.value)
    };
}

function FuelEntryForm(props) {
    const {
        featureToggles,
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    const [validatedDate, setValidatedDate] = React.useState();
    const {
        dieselPrice,
        dieselPriceLabel,
        dieselPriceUnit,
        dieselQuantity,
        dieselVolumeLabel,
        dieselVolumeUnit,
        gasPrice,
        gasPriceLabel,
        gasPriceUnit,
        gasQuantity,
        gasVolumeLabel,
        gasVolumeUnit,
        isInvalid
    } = getMemoizedData(membership.unitOfMeasure, translations, values);

    React.useEffect(() => {
        const validatedDateFromTime = validateDate(values.timeSample, setValid);

        setValidatedDate(validatedDateFromTime);
    }, [values.timeSample]);

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    const currencySymbol = getCurrencySymbol({
        currencyPreference: membership.currencyPreference,
        featureToggles
    });

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        errors: {
            customError: translations.REQUIRED_FIELD_TEXT,
            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`,
            rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${MIN_NUMERIC_VALUE}`
        },
        invalidCustomError: isInvalid,
        max: MAX_NUMERIC_VALUE,
        min: MIN_NUMERIC_VALUE,
        setValid,
        tabIndex: 0,
        type: 'number'
    };

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
            />
            <div className='settings-group mobile'>
                <ValidationInput
                    {...sharedInputProps}
                    label={`${translations.ONLINK_DIESEL_PRICE} (${currencySymbol}/${dieselPriceLabel})`}
                    name='fuel_cost'
                    onChange={(event) => onManualDataChange({
                        dataUnit: dieselPriceUnit,
                        name: event.target.name,
                        setValues,
                        value: event.target.value
                    })}
                    value={dieselPrice}
                />
                <ValidationInput
                    {...sharedInputProps}
                    label={`${translations.ONLINK_DIESEL_QUANTITY} (${dieselVolumeLabel})`}
                    name='fuel'
                    onChange={(event) => onManualDataChange({
                        dataUnit: dieselVolumeUnit,
                        name: event.target.name,
                        setValues,
                        value: event.target.value
                    })}
                    value={dieselQuantity}
                />
            </div>
            <div className='settings-group mobile'>
                <ValidationInput
                    {...sharedInputProps}
                    label={`${translations.ONLINK_GAS_PRICE} (${currencySymbol}/${gasPriceLabel})`}
                    name='gas_cost'
                    onChange={(event) => onManualDataChange({
                        dataUnit: gasPriceUnit,
                        name: event.target.name,
                        setValues,
                        value: event.target.value
                    })}
                    value={gasPrice}
                />
                <ValidationInput
                    {...sharedInputProps}
                    label={`${translations.ONLINK_GAS_QUANTITY} (${gasVolumeLabel})`}
                    name='gas'
                    onChange={(event) => onManualDataChange({
                        dataUnit: gasVolumeUnit,
                        name: event.target.name,
                        setValues,
                        value: event.target.value
                    })}
                    value={gasQuantity}
                />
            </div>
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

FuelEntryForm.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(FuelEntryForm);
