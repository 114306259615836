// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Marker} from '@react-google-maps/api';
import {MACHINE_STATUS_IDLE_COLOR} from 'Ui/constants/map-constants';

const SMALL_MARKER_SIZE = 2;
const MEDIUM_MARKER_SIZE = 4;
const LARGE_MARKER_SIZE = 6;
const SECONDS_IN_MINUTE = 60;

const SMALL_IDLE_MARKER_THRESHOLD = SECONDS_IN_MINUTE;
const MEDIUM_IDLE_MARKER_THRESHOLD = SECONDS_IN_MINUTE * 2;

const MARKER_OPTIONS = {
    strokeOpacity: 1,
    strokeWeight: 2
};
const DOT_SYMBOL = {
    ...MARKER_OPTIONS,
    fillOpacity: 1,
    strokeColor: MACHINE_STATUS_IDLE_COLOR,
    fillColor: MACHINE_STATUS_IDLE_COLOR
};

function getScale(time) {
    if (time <= SMALL_IDLE_MARKER_THRESHOLD) {
        return SMALL_MARKER_SIZE;
    } else if (time <= MEDIUM_IDLE_MARKER_THRESHOLD) {
        return MEDIUM_MARKER_SIZE;
    }
    return LARGE_MARKER_SIZE;
}

function IdleMarker({
    center, zIndex, elapsedTime
}) {
    const scale = getScale(elapsedTime);

    return (<Marker
        icon={{
            ...DOT_SYMBOL,
            scale,
            path: google.maps.SymbolPath.CIRCLE
        }}
        options={{
            ...MARKER_OPTIONS,
            zIndex
        }}
        position={center}
    />);
}

IdleMarker.propTypes = {
    center: PropTypes.shape(),
    elapsedTime: PropTypes.number,
    zIndex: PropTypes.number
};

export default IdleMarker;
