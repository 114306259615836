// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {IconDotFilled} from '@deere/icons';
import StatusText from 'OnLabor/workboard/common/status-text';
import {getOvertimeStatus} from 'OnLabor/workboard/utils/overtime-status';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatMinutesToHours} from 'Utils/time-utils';
import {groupBy, orderBy} from 'lodash';
import {VALID_HEX_COLORS} from 'Common/constants/valid-hex-color';

const NONE_COLOR = 'none';

function createRoleNameMap(userRoles) {
    return new Map(userRoles.map((userRole) => [userRole.userRoleId, userRole.title]));
}

function getFormattedOperators({
    operators,
    overtimeReachedThreshold,
    overtimeWarningThreshold,
    roleNameMap,
    translations
}) {
    const sortedOperators = orderBy(operators, ['deleted', 'firstName'], ['desc', 'asc']);

    return sortedOperators?.map((operator) => ({
        extraContent: (
            <StatusText
                dangerColor='#f00'
                status={getOvertimeStatus(operator, overtimeReachedThreshold, overtimeWarningThreshold)}
                warningColor='#ff9600'
            >
                {
                    operator.unavailableForSelection && !operator.inactive ? (
                        <div className='deleted-jobs-category'>{translations.ONLINK_OPERATOR_UNAVAILABLE}</div>
                    ) : null
                }
                {
                    operator.inactive ? (
                        <div>{translations.inactive}</div>
                    ) : null
                }
                {
                    operator && !operator.deleted ? replaceTranslationNames(translations.ONLINK_HOURS_THIS_WEEK, {
                        '0': formatMinutesToHours(operator.minutesWorkedForWeek)
                    }) : null
                }
            </StatusText>
        ),
        id: operator.appUserId,
        subtitle: roleNameMap.get(operator.userRoleId),
        title: `${operator.firstName} ${operator.lastName}`,
        equipmentTypes: operator.equipmentTypes
    }));
}

function getGroupedFormattedOperators({
    operators,
    overtimeReachedThreshold,
    overtimeWarningThreshold,
    roleNameMap,
    translations
}) {
    const {
        [NONE_COLOR]: unassignedOperators,
        ...operatorsByColor
    } = groupBy(operators, ({color}) => color || NONE_COLOR);

    const groupedOperators = orderBy(Object.keys(operatorsByColor).map((color) => ({
        Icon: (
            <IconDotFilled
                primary={{
                    style: {
                        fill: color
                    }
                }}
            />
        ),
        id: color,
        title: translations[VALID_HEX_COLORS.find(({id}) => id === color)?.translationKeyWord],
        items: getFormattedOperators({
            operators: operatorsByColor[color],
            overtimeReachedThreshold,
            overtimeWarningThreshold,
            roleNameMap,
            translations
        })
    })), 'title');

    if (unassignedOperators) {
        groupedOperators.push({
            id: NONE_COLOR,
            title: translations.ONLINK_UNASSIGNED,
            items: getFormattedOperators({
                operators: unassignedOperators,
                overtimeReachedThreshold,
                overtimeWarningThreshold,
                roleNameMap,
                translations
            })
        });
    }

    return {
        groupedOperators
    };
}

export {
    createRoleNameMap,
    getGroupedFormattedOperators,
    getFormattedOperators
};
