// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect} from '@deere/isg.component-library';
import {IconAdd, IconHandleChevronDown, IconHandleChevronUp, IconDelete} from '@deere/icons';
import OnlinkButton from 'Ui/components/common/onlink-button';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import WorkItemInputWrapper from 'Ui/features/workboard-wizard/work-item/work-item-input-wrapper';
import {setStatusForAll, setStatusForWorkItem} from 'Ui/features/workboard-wizard/assignments/utils/collapse-status';
import {
    updateWorkItem,
    updateWorkItemEquipment
} from 'Ui/features/workboard-wizard/assignments/utils/work-item-handlers';
import {useDeepMemo, useLazyRef} from 'Utils/react-utils';
import {onDragStart, onDragStop, scrollToNewestItem} from 'Utils/scroll-utils';
import {TRUE} from 'Ui/constants/membership-properties';
import {ONLINK_CREATION_FLOW, WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {v4} from 'uuid';
import {cloneDeep, maxBy, sortBy} from 'lodash';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {connect} from 'react-redux';
import {createIconFillStyle} from 'Utils/icon-utils';
import {preventDefaultAndStopPropagation} from 'Utils/common-handlers';
import {
    changeByOperatorWorkItemLayout,
    getByOperatorWorkItemLayout
} from 'Ui/features/workboard-wizard/assignments/utils/job-grid-layouts';
import GridLayoutWithWidth from 'Ui/components/common/grid-layout-with-width';
import WorkItemDragHandle from 'Ui/features/workboard-wizard/work-item/work-item-drag-handle';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

const GRID_MARGIN = 10;

const ONLINK_BLUE = '#2677bb';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const ICON_ADD_STYLE = {
    style: {
        height: '20px',
        width: '20px',
        fill: ONLINK_BLUE
    }
};

function onChangeJobSelect(ids, workItem, updateOperator, updateWorkItems, shouldCopyEstimatedToActual, workItemIndex, flattenedWorkItems, templatesById) {
    const [jobTemplateId] = ids;

    const jobTemplate = templatesById.get(jobTemplateId);
    const estDuration = jobTemplate?.estDuration || 0;

    updateOperator((clonedOperator) => {
        if (workItem.jobTemplateId) {
            clonedOperator.minutesWorkedForWeek -= workItem.actualDuration;
        }

        if (shouldCopyEstimatedToActual) {
            clonedOperator.minutesWorkedForWeek += estDuration;
        }
    });

    const equipment = jobTemplate?.equipmentTypes?.map((equipmentType) => ({
        equipmentTypeId: equipmentType.equipmentTypeId,
        equipmentType: equipmentType.name
    }));
    const clearEquipment = workItem.jobTemplateId === jobTemplateId;

    updateWorkItems((workItems) => {
        const currentWorkItem = workItems[workItemIndex];

        if (clearEquipment) {
            const {
                equipment: removedEquipment,
                ...updatedWorkItem
            } = currentWorkItem;

            workItems[workItemIndex] = updatedWorkItem;
        } else {
            currentWorkItem.equipment = equipment;
        }

        if (shouldCopyEstimatedToActual) {
            currentWorkItem.actualDuration = estDuration;
        }

        currentWorkItem.estDuration = estDuration;

        const workItemWithLargestSeq = maxBy(flattenedWorkItems.filter((workItem) => workItem.jobTemplateId === jobTemplateId), 'jobSeq');

        if (!workItemWithLargestSeq?.jobSeq) {
            const jobWithLargestSeq = maxBy(flattenedWorkItems, 'jobSeq');

            const uniqueCollectionOfJobs = flattenedWorkItems.reduce((uniqueSet, workItem) => {
                if (workItem.jobTemplateId) {
                    uniqueSet.add(workItem.jobTemplateId);
                }

                return uniqueSet;
            }, new Set());

            updateWorkItem('jobSeq', jobWithLargestSeq?.jobSeq ? jobWithLargestSeq.jobSeq + 1 : uniqueCollectionOfJobs.size + 1, workItems, workItemIndex);
        } else {
            updateWorkItem('jobSeq', workItemWithLargestSeq.jobSeq, workItems, workItemIndex);
        }

        updateWorkItem('seq', workItemWithLargestSeq?.seq ? workItemWithLargestSeq.seq + 1 : 1, workItems, workItemIndex);
        updateWorkItem('jobTemplateId', jobTemplateId, workItems, workItemIndex);
    });
}

function initializeHooks({
    allWorkItemsByOperator,
    appUserId,
    collapseStatus,
    featureToggles,
    isAssignmentMobile,
    membership,
    values
}) {
    const useColumnLayout = isAssignmentMobile || !featureToggles[WORKBOARD_REFACTOR];

    const {
        atLeastOneNotCollapsed,
        collapseStatusForOperator,
        flattenedWorkItems
    } = useDeepMemo(() => {
        const statusForOperator = collapseStatus.get(appUserId);
        const flattenedWorkItems = Object.values(allWorkItemsByOperator)
            .reduce((fullWorkItemList, operatorWorkItems) => fullWorkItemList.concat(operatorWorkItems), []);

        return {
            atLeastOneNotCollapsed: statusForOperator &&
                (statusForOperator.size === 0 || [...statusForOperator.values()].includes(false)),
            collapseStatusForOperator: statusForOperator,
            flattenedWorkItems
        };
    }, [appUserId, collapseStatus, allWorkItemsByOperator]);

    const collapseIcon = React.useMemo(() => atLeastOneNotCollapsed ?
        <IconHandleChevronUp iconHandleChevronUp={ICON_STYLE}/> :
        <IconHandleChevronDown iconHandleChevronDown={ICON_STYLE}/>, [atLeastOneNotCollapsed]);

    return {
        atLeastOneNotCollapsed,
        collapseIcon,
        collapseStatusForOperator,
        flattenedWorkItems,
        jobMultiSelectProps: React.useMemo(() => useColumnLayout ? {} : {
            width: 350
        }, [useColumnLayout]),
        orderRef: useLazyRef(() =>
            sortBy(values.userOrder.find((user) => user.userId === appUserId).workItems, 'seq')
                .map((workItem) => workItem.workItemId)
        ),
        shouldCopyEstimatedToActual: React.useMemo(
            () => membership.properties.workboard_copy_estimated_to_actual === TRUE,
            [membership.properties.workboard_copy_estimated_to_actual]
        )
    };
}

function getJobMultiselectName(workItemId) {
    return `job-${workItemId}`;
}

function OperatorItem(props) {
    const {
        activeOperatorIndex,
        allOperatorIndex,
        allWorkItemsByOperator,
        appUserId,
        closeConfirmation,
        collapseStatus,
        equipmentByType,
        equipmentInUseMap,
        featureToggles,
        fleetEquipment,
        isAssignmentMobile,
        isMultiline,
        membership,
        newestWorkItemIdRef,
        noteHeight,
        openConfirmation,
        operator,
        screenTouchListener,
        screenTouchTimerRef,
        setCollapseStatus,
        setOperators,
        setValid,
        setValues,
        templateItems,
        templatesById,
        translations,
        values,
        workItemsForOperator,
        readOnly
    } = props;

    const {
        atLeastOneNotCollapsed,
        collapseIcon,
        collapseStatusForOperator,
        flattenedWorkItems,
        jobMultiSelectProps,
        orderRef,
        shouldCopyEstimatedToActual
    } = initializeHooks({
        allWorkItemsByOperator,
        appUserId,
        collapseStatus,
        featureToggles,
        isAssignmentMobile,
        membership,
        values
    });

    function updateOperator(callback) {
        setOperators((prevOperators) => {
            const clonedOperators = cloneDeep(prevOperators);
            const clonedActiveOperator = clonedOperators.operatorsMasterList[activeOperatorIndex];

            callback(clonedActiveOperator);

            clonedOperators.allOperators[allOperatorIndex] = clonedActiveOperator;

            return clonedOperators;
        });
    }

    function removeOperator() {
        const workItems = values.workItemsByOperator[operator.appUserId];

        const deleteOperatorCallback = () => {
            setValues((prevValues) => {
                const clonedValues = cloneDeep(prevValues);

                delete clonedValues.workItemsByOperator[operator.appUserId];
                workItems.forEach(({workItemId}) => {
                    setValid(getJobMultiselectName(workItemId), true);
                });
                clonedValues.userOrder = clonedValues.userOrder.filter((user) => user.userId !== operator.appUserId);

                return clonedValues;
            });
        };

        if (workItems.length > 0) {
            openConfirmation({
                translations,
                onCancel: closeConfirmation,
                message: translations.ONLINK_REMOVE_OPERATOR_WARNING,
                onContinue: () => {
                    deleteOperatorCallback();
                    closeConfirmation();
                },
                title: translations.ONLINK_REMOVE_OPERATOR
            });
        } else {
            deleteOperatorCallback();
        }
    }

    function updateWorkItems(callback) {
        setValues((prevValues) => {
            const clonedValues = cloneDeep(prevValues);
            const clonedWorkItemsForOperator = clonedValues.workItemsByOperator[operator.appUserId];

            callback(clonedWorkItemsForOperator);

            return clonedValues;
        });
    }

    function updateOperatorMinutesWorked(minutes, workItem) {
        updateOperator((clonedOperator) => {
            clonedOperator.minutesWorkedForWeek -= workItem.actualDuration;
            clonedOperator.minutesWorkedForWeek += Number(minutes);
        });
    }

    const addOperatorButton = (
        <OnlinkButton
            borderless={true}
            className='icon-and-text'
            disabled={operator?.deleted || operator?.unavailableForSelection}
            leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
            onClick={() => {
                const newWorkItemId = v4();

                newestWorkItemIdRef.current = newWorkItemId;

                setValues((prevValues) => {
                    const clonedValues = cloneDeep(prevValues);
                    const operator = clonedValues.userOrder.find((user) => user.userId === appUserId);

                    operator.workItems.push({
                        seq: operator.workItems.length + 1,
                        workItemId: newWorkItemId
                    });

                    return clonedValues;
                });

                updateWorkItems((workItems) => {
                    workItems.push({
                        actualDuration: 0,
                        appUser: operator,
                        appUserId: operator.appUserId,
                        equipment: [],
                        estDuration: 0,
                        route: undefined,
                        routeCustomDefn: [],
                        workItemId: newWorkItemId
                    });
                });
            }}
        >
            {translations.ONLINK_ADD_JOB}
        </OnlinkButton>
    );

    const workItemButtons = featureToggles[WORKBOARD_REFACTOR] ? (
        <div className={'assignment-buttons'}>
            {addOperatorButton}
            <OnlinkButton
                borderless={true}
                className='icon-and-text'
                leftIcon={<IconDelete
                    iconDelete={ICON_STYLE}
                    primary={createIconFillStyle(ONLINK_BLUE)}
                />}
                onClick={removeOperator}
            >
                {translations.ONLINK_REMOVE_OPERATOR}
            </OnlinkButton>
        </div>
    ) : addOperatorButton;

    return (
        <div
            className='operator-item'
            key={operator?.appUserId}
            onDragEnter={preventDefaultAndStopPropagation}
            onDragOver={preventDefaultAndStopPropagation}
            onDrop={(e) => {
                e.stopPropagation();

                if (operator && !operator.unavailableForSelection) {
                    const jobTemplateId = e.dataTransfer.getData('itemId');

                    const newWorkItem = {
                        actualDuration: 0,
                        appUser: operator,
                        appUserId: operator.appUserId,
                        equipment: [],
                        estDuration: 0,
                        route: undefined,
                        routeCustomDefn: [],
                        workItemId: v4()
                    };

                    setValues((prevValues) => {
                        const clonedValues = cloneDeep(prevValues);
                        const operator = clonedValues.userOrder.find((user) => user.userId === appUserId);

                        operator.workItems.push({
                            seq: operator.workItems.length,
                            workItemId: newWorkItem.workItemId
                        });

                        return clonedValues;
                    });

                    updateWorkItems((workItems) => {
                        workItems.push(newWorkItem);
                    });

                    onChangeJobSelect(
                        [jobTemplateId],
                        newWorkItem,
                        updateOperator,
                        updateWorkItems,
                        shouldCopyEstimatedToActual,
                        workItemsForOperator?.length,
                        flattenedWorkItems,
                        templatesById
                    );
                }
            }}
        >
            <div className='title-section'>
                {
                    featureToggles[ONLINK_CREATION_FLOW] && !readOnly &&
                    <WorkItemDragHandle
                        className='icon-job-orderable assignment-item-btn'
                        id={appUserId}
                    />
                }
                <div className='title-1'>
                    {`${translations.OPERATOR}: ${operator && !operator.deleted ? `${operator.firstName} ${operator.lastName}` : translations.ONLINK_USER_NOT_AVAILABLE}`}
                </div>
                <OnlinkButton
                    borderless={true}
                    leftIcon={collapseIcon}
                    onClick={() => setStatusForAll(setCollapseStatus, appUserId, atLeastOneNotCollapsed)}
                />
            </div>
            <GridLayoutWithWidth
                cols={1}
                draggableHandle='.icon-workitem-orderable'
                isDraggable={!readOnly}
                isResizable={false}
                layout={
                    getByOperatorWorkItemLayout(
                        workItemsForOperator,
                        values.userOrder.find((user) => user.userId === appUserId).workItems,
                        collapseStatus.get(appUserId),
                        isAssignmentMobile,
                        featureToggles[WORKBOARD_REFACTOR],
                        readOnly,
                        noteHeight
                    )
                }
                margin={[0, GRID_MARGIN]}
                onDragStart={(layout, oldItem) => onDragStart(oldItem.i, screenTouchListener)}
                onDragStop={(layout, oldItem) => onDragStop(oldItem.i, screenTouchListener, screenTouchTimerRef)}
                onLayoutChange={(currentLayout) => changeByOperatorWorkItemLayout(currentLayout, orderRef, setValues, appUserId)}
                rowHeight={50}
            >
                {
                    workItemsForOperator.map((workItem, workItemIndex) => {
                        const jobMultiselectName = getJobMultiselectName(workItem.workItemId);
                        const jobTemplateForWorkItem = templatesById.get(workItem.jobTemplateId);
                        const collapseStatusForWorkItem = collapseStatusForOperator.get(workItem.workItemId);

                        const isNew = workItem.workItemId === newestWorkItemIdRef.current;

                        return (
                            <div
                                id={workItem.workItemId}
                                key={workItem.workItemId}
                                onDragEnter={preventDefaultAndStopPropagation}
                                onDragOver={preventDefaultAndStopPropagation}
                                onDrop={(e) => {
                                    e.stopPropagation();

                                    if (operator && !operator.unavailableForSelection) {
                                        const jobTemplateId = e.dataTransfer.getData('itemId');

                                        onChangeJobSelect(
                                            [jobTemplateId],
                                            workItem,
                                            updateOperator,
                                            updateWorkItems,
                                            shouldCopyEstimatedToActual,
                                            workItemIndex,
                                            flattenedWorkItems,
                                            templatesById
                                        );
                                    }
                                }}
                            >
                                <WorkItemInputWrapper
                                    collapseStatusForWorkItem={collapseStatusForWorkItem}
                                    collapsedTitle={jobTemplateForWorkItem ? `${jobTemplateForWorkItem.jobCategory} - ${jobTemplateForWorkItem.title}` : null}
                                    deleteTitle={translations.ONLINK_REMOVE_JOB}
                                    disabledClear={operator?.unavailableForSelection}
                                    displayOrderButton={featureToggles[ONLINK_CREATION_FLOW]}
                                    eligibleEquipmentTypes={operator?.equipmentTypes}
                                    equipmentAreaId={jobTemplateForWorkItem?.equipmentAreaId}
                                    equipmentByType={equipmentByType}
                                    equipmentInUseMap={equipmentInUseMap}
                                    featureToggles={featureToggles}
                                    isMultiline={isMultiline}
                                    noteHeight={noteHeight}
                                    onActHoursChange={(minutes) => {
                                        updateOperatorMinutesWorked(minutes, workItem);

                                        updateWorkItems((workItems) => {
                                            updateWorkItem('actualDuration', minutes, workItems, workItemIndex);
                                        });
                                    }}
                                    onChange={(name, value) => {
                                        updateWorkItems((workItems) => {
                                            updateWorkItem(name, value, workItems, workItemIndex);
                                        });
                                    }}
                                    onEquipmentChange={(selectedEquipmentId, equipmentIndex) => {
                                        const selectedEquipment = fleetEquipment
                                            .find((equipment) => equipment.equipmentId === selectedEquipmentId);

                                        updateWorkItems((workItems) => {
                                            updateWorkItemEquipment({
                                                equipmentIndex,
                                                equipmentInUseMap: equipmentInUseMap.current,
                                                selectedEquipment,
                                                selectedEquipmentId,
                                                workItem: workItems[workItemIndex]
                                            });
                                        });
                                    }}
                                    onEstHoursChange={(minutes) => {
                                        if (shouldCopyEstimatedToActual) {
                                            updateOperatorMinutesWorked(minutes, workItem);
                                        }

                                        updateWorkItems((workItems) => {
                                            updateWorkItem('estDuration', minutes, workItems, workItemIndex);

                                            if (shouldCopyEstimatedToActual) {
                                                updateWorkItem('actualDuration', minutes, workItems, workItemIndex);
                                            }
                                        });
                                    }}
                                    onRemove={() => {
                                        setValid(jobMultiselectName, true);
                                        updateOperatorMinutesWorked(0, workItem);

                                        setValues((prevValues) => {
                                            const clonedValues = cloneDeep(prevValues);

                                            const user = clonedValues.userOrder.find((user) => user.userId === appUserId);

                                            user.workItems = user.workItems.filter(
                                                (workItemSeq) => workItemSeq.workItemId !== workItem.workItemId
                                            );

                                            return clonedValues;
                                        });

                                        updateWorkItems((workItems) => {
                                            workItems[workItemIndex].equipment?.forEach((equipment) => {
                                                equipmentInUseMap.current.set(equipment.equipmentId, null);
                                            });

                                            workItems.splice(workItemIndex, 1);
                                        });
                                    }}
                                    readOnly={readOnly}
                                    setStatusForWorkItem={() => setStatusForWorkItem(setCollapseStatus, appUserId, workItem.workItemId, !collapseStatusForWorkItem)}
                                    translations={translations}
                                    workItem={workItem}
                                >
                                    <div ref={(ref) => scrollToNewestItem(ref, isNew)}>
                                        <ReadOnlyWrapperComponent
                                            {...jobMultiSelectProps}
                                            label={translations.jobs_job}
                                            readOnly={readOnly}
                                            readOnlyProps={{
                                                readOnlyLabelClassName: 'title-1',
                                                readOnlyValueClassName: 'readonly-value',
                                                value: `${jobTemplateForWorkItem?.jobCategory} - ${jobTemplateForWorkItem?.title}`
                                            }}
                                            wrappedComponent={ValidationMultiSelect}
                                            wrappedProps={{
                                                autoFocus: isNew,
                                                component: MultiSelect,
                                                defaultOpen: isNew,
                                                disableClearValid: true,
                                                disabled: operator?.unavailableForSelection,
                                                items: templateItems,
                                                labels: {
                                                    placeholder: translations.ONLINK_SELECT_JOBS
                                                },
                                                name: jobMultiselectName,
                                                onChange: (ids) => onChangeJobSelect(
                                                    ids,
                                                    workItem,
                                                    updateOperator,
                                                    updateWorkItems,
                                                    shouldCopyEstimatedToActual,
                                                    workItemIndex,
                                                    flattenedWorkItems,
                                                    templatesById
                                                ),
                                                onChangeOpen: (open) => {
                                                    if (!open) {
                                                        newestWorkItemIdRef.current = null;
                                                    }
                                                },
                                                selectedIds: [workItem.jobTemplateId],
                                                setValid,
                                                single: true
                                            }}
                                        />
                                    </div>
                                </WorkItemInputWrapper>
                            </div>
                        );
                    })
                }
            </GridLayoutWithWidth>
            {(featureToggles[WORKBOARD_REFACTOR] || atLeastOneNotCollapsed) && !readOnly && workItemButtons}
        </div>
    );
}

OperatorItem.propTypes = {
    activeOperatorIndex: PropTypes.number,
    allOperatorIndex: PropTypes.number,
    allWorkItemsByOperator: PropTypes.object,
    appUserId: PropTypes.string,
    closeConfirmation: PropTypes.func,
    collapseStatus: PropTypes.instanceOf(Map),
    equipmentByType: PropTypes.instanceOf(Map),
    equipmentInUseMap: PropTypes.reference,
    featureToggles: PropTypes.featureToggles,
    fleetEquipment: PropTypes.arrayOf(PropTypes.equipment),
    isAssignmentMobile: PropTypes.bool,
    isMultiline: PropTypes.bool,
    membership: PropTypes.membership,
    newestWorkItemIdRef: PropTypes.reference,
    noteHeight: PropTypes.number,
    openConfirmation: PropTypes.func,
    operator: PropTypes.user,
    readOnly: PropTypes.bool,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    setCollapseStatus: PropTypes.func,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    templateItems: PropTypes.multiSelectList,
    templatesById: PropTypes.instanceOf(Map),
    translations: PropTypes.translations,
    values: PropTypes.object,
    workItemsForOperator: PropTypes.arrayOf(PropTypes.object)
};

export function mapDispatchToProps(dispatch) {
    return {
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(OperatorItem);
