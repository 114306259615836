// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {redirect} from 'Utils/redirect-utils';

function DrillInLinks(props) {
    const {
        history,
        navItems,
        translations
    } = props;

    return (
        <div className='drill-in-links'>
            {
                navItems.map((navItem) => {
                    if (navItem.isDropdown) {
                        const {
                            dropdown: DropDown,
                            links,
                            active,
                            name
                        } = navItem;

                        return (
                            <DropDown
                                active={active}
                                key={name}
                                links={links}
                                name={name}
                                translations={translations}
                            />
                        );
                    }

                    const {
                        active,
                        link,
                        name,
                        type
                    } = navItem;

                    return (
                        <OnlinkButton
                            active={active}
                            borderless={true}
                            className='onlink-btn-nav'
                            color='white'
                            key={type}
                            onClick={() => {
                                redirect(link.path, link.reactRouter ? history : null);
                            }}
                        >
                            {name}
                        </OnlinkButton>
                    );
                })
            }
        </div>
    );
}

DrillInLinks.propTypes = {
    history: PropTypes.history,
    navItems: PropTypes.arrayOf(PropTypes.navItem),
    translations: PropTypes.translations
};

export default DrillInLinks;
