// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import NavItem from 'Ui/components/main-navbar/persistent-header/navigation/nav-item';

function NavList(props) {
    const {
        additionalNavItemProps,
        navbarLinks,
        translations
    } = props;

    return navbarLinks.map((navbarLink) => {
        const {
            description,
            icon,
            name,
            ...otherNavItemProps
        } = navbarLink;

        const {
            [description]: translatedNavItemDescription,
            [name]: translatedNavItemLabel
        } = translations;

        return (
            <NavItem
                {...otherNavItemProps}
                {...additionalNavItemProps}
                description={translatedNavItemDescription}
                iconName={icon}
                key={`${name} ${otherNavItemProps.path}`}
                label={translatedNavItemLabel}
                title={translatedNavItemLabel}
            />
        );
    });
}

NavList.defaultProps = {
    additionalNavItemProps: {}
};

NavList.propTypes = {
    additionalNavItemProps: PropTypes.object,
    navbarLinks: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export default NavList;
