// Unpublished Work © 2020-2024 Deere & Company.
import CurrentConditions from 'Ui/components/home/current-conditions/current-conditions';
import MaintenanceDue from 'Ui/components/home/maintenance-due/maintenance-due';
import Hoc from 'Ui/components/home/hoc/hoc';
import ElectricMowerStatus from 'Ui/components/home/electric-mower-status/electric-mower-status';
import OperatorNotes from 'Ui/components/home/operator-notes/operator-notes';
import LowInventory from 'Ui/components/home/low-inventory/low-inventory';
import {homeRedirect} from 'Utils/redirect-utils';
import {
    VIEW_COURSE_MEASUREMENT_DATA,
    VIEW_EQUIPMENT_LIST_API,
    VIEW_PARTS,
    VIEW_WORKBOARD_SUMMARY_DATA,
    VIEW_WORKBOARDS
} from 'Common/constants/business-activities';
import {ELECTRIFICATION, INVENTORY_LOW_PARTS} from 'Common/constants/feature-toggles';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';

const WEATHER_ID = '54f263a8-806c-4e03-ac71-68c64712ddf4';
const COURSE_ID = 'fb98b151-a2c4-4aa2-8441-afa2faa7d25e';
const LABOR_ID = '043709ce-2f17-43f3-9c1c-ecfadcab504b';
const EQUIPMENT_ID = '9c6dbebe-496b-4b99-9d53-e8822225b0a5';
const WATER_ID = '14825350-ecc6-454a-aa6f-cce2ec175f3d';
const CHEMICALS_ID = 'ba85121f-7dbc-4f7f-99f0-5b5a6812978a';
const ROUNDS_ID = '83603f70-f984-4cbd-aac1-e75a2ee45116';
const HOC_ID = '634ba290-df26-4703-8552-010475ccaab1';
const MAINTENANCE_DUE_ID = 'fd3eea69-8c10-4a63-a2b3-5d5aba7c4db2';
const OPERATOR_NOTES_ID = 'b15d540c-8157-40ae-b7c3-eab6b3db1aba';
const ELECTRIC_MOWER_STATUS_ID = '8187a281-884c-58c4-8368-6581b4dcf52d';
const LOW_INVENTORY_PARTS_ID = 'e04d05e8-6a0f-48af-833a-466358bb08af';

const HOME_URL = 'panelTypes/home';

export default {
    [WEATHER_ID]: {
        component: CurrentConditions,
        name: 'weather',
        onClick: homeRedirect,
        tileId: WEATHER_ID,
        url: HOME_URL
    },
    [COURSE_ID]: {
        columnCount: 2,
        data: [
            'moisture',
            'green_speed',
            'firmness',
            'firmness_gravities',
            'firmness_f355_equivalents'
        ],
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'course',
        onClick: homeRedirect,
        tileId: COURSE_ID,
        url: HOME_URL
    },
    [LABOR_ID]: {
        data: [
            'total_labor_cost',
            'labor_overtime_cost',
            'labor_hours',
            'labor_overtime_hours'
        ],
        myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA,
        name: 'labor',
        onClick: homeRedirect,
        tileId: LABOR_ID,
        url: HOME_URL
    },
    [EQUIPMENT_ID]: {
        data: [
            'total_equipment_cost',
            'percent_uptime'
        ],
        myJdPermissions: VIEW_EQUIPMENT_LIST_API,
        name: 'equipment',
        onClick: homeRedirect,
        tileId: EQUIPMENT_ID,
        url: HOME_URL
    },
    [WATER_ID]: {
        data: [
            'water_transfer',
            'total_water_cost'
        ],
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'water',
        onClick: homeRedirect,
        tileId: WATER_ID,
        url: HOME_URL
    },
    [CHEMICALS_ID]: {
        data: [
            'fert_cost',
            'chem_cost'
        ],
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'chemicals',
        onClick: homeRedirect,
        tileId: CHEMICALS_ID,
        url: HOME_URL
    },
    [ROUNDS_ID]: {
        data: [
            'rounds',
            'total_revenue'
        ],
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA,
        name: 'rounds',
        onClick: homeRedirect,
        tileId: ROUNDS_ID,
        url: HOME_URL
    },
    [HOC_ID]: {
        component: Hoc,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API,
        name: 'hoc',
        tileId: HOC_ID
    },
    [MAINTENANCE_DUE_ID]: {
        component: MaintenanceDue,
        name: 'maintenance',
        queryParams: () => ({
            serviceStatus: 'recommended'
        }),
        myJdPermissions: VIEW_EQUIPMENT_LIST_API,
        skipDate: true,
        tileId: MAINTENANCE_DUE_ID,
        url: 'fleet/maintenanceDue'
    },
    [OPERATOR_NOTES_ID]: {
        component: OperatorNotes,
        myJdPermissions: VIEW_WORKBOARDS,
        name: 'operator_notes',
        queryParams: () => ({
            date: formatTime(new Date(), DATE_FORMATS.day)
        }),
        tileId: OPERATOR_NOTES_ID,
        url: 'workboards'
    },
    [ELECTRIC_MOWER_STATUS_ID]: {
        component: ElectricMowerStatus,
        myJdPermissions: VIEW_EQUIPMENT_LIST_API,
        myjdUrl: 'myJohnDeere/users/currentUser/machines',
        name: 'electric_mower_status',
        skipDate: true,
        tileId: ELECTRIC_MOWER_STATUS_ID,
        toggle: ELECTRIFICATION,
        url: 'equipment'
    },
    [LOW_INVENTORY_PARTS_ID]: {
        component: LowInventory,
        myJdPermissions: VIEW_PARTS,
        name: 'low_inventory_parts',
        skipDate: true,
        tileId: LOW_INVENTORY_PARTS_ID,
        toggle: INVENTORY_LOW_PARTS,
        url: 'partBins',
        requireMigration: true
    }
};
