// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import GraphDateFilter from 'Ui/components/graph/common/graph-date-filter';
import {useDeepMemo} from 'Utils/react-utils';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import tileInfo from 'Ui/components/common/tile-constants/tile-info';

function getTimeScaleItems(tileType, translations) {
    const tileId = Object.keys(tileInfo).find((tileId) => tileInfo[tileId].name === tileType);

    const {dateSelectionTypes = [] } = tileInfo[tileId];
    const timeScaleItems = [
        {
            id: 'day',
            title: translations.DAILY
        },
        {
            id: 'month',
            title: capitalizeFirstLetter(translations.ONLINK_MONTHLY)
        },
        {
            id: 'year',
            title: capitalizeFirstLetter(translations.ONLINK_YEARLY)
        }
    ];

    return dateSelectionTypes.length > 0 ?
        timeScaleItems.filter((item) => dateSelectionTypes.includes(item.id)) :
        timeScaleItems;
}

function GraphDateFilterWrapper(props) {
    const {
        handler,
        tileType,
        timeScale,
        translations
    } = props;

    const timeScaleItems = useDeepMemo(
        () => getTimeScaleItems(tileType, translations),
        [tileType, translations]
    );

    return (
        <GraphDateFilter
            handler={handler}
            numMonths={12}
            numYears={5}
            timeScale={timeScale}
            timeScaleItems={timeScaleItems}
            translations={translations}
        />
    );
}

GraphDateFilterWrapper.propTypes = {
    handler: PropTypes.func,
    tileType: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export default GraphDateFilterWrapper;
