// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import {cloneDeep} from 'lodash';

export default function FormValidator(Component) {
    const formValidator = (props) => {
        const [invalidInputs, setInvalidInputs] = React.useState(() => new Set());

        function setValid(name, valid) {
            setInvalidInputs((prevInvalidInputs) => {
                const hasInvalidInput = prevInvalidInputs.has(name);

                if (valid && !hasInvalidInput || !valid && hasInvalidInput) {
                    return prevInvalidInputs;
                }

                const nextInvalidInputs = cloneDeep(prevInvalidInputs);

                valid ?
                    nextInvalidInputs.delete(name) :
                    nextInvalidInputs.add(name);

                return nextInvalidInputs;
            });
        }

        return (
            <Component
                {...props}
                invalidInputs={invalidInputs}
                setValid={setValid}
            />
        );
    };

    formValidator.FormComponent = Component;

    return formValidator;
}
