// Unpublished Work © 2020-2024 Deere & Company.

import {graphRedirect} from 'Utils/redirect-utils';
import {VIEW_COURSE_MEASUREMENT_DATA, VIEW_FINANCIAL_DATA} from 'Common/constants/business-activities';

const WATER_ENERGY_2_ID = '322740a9-fab4-4cd6-806d-51ae0cfc3258';
const WATER_ENERGY_3_ID = '464419ed-2dad-451b-9894-fbc2c1ccf2b5';
const WATER_ENERGY_4_ID = '61779f7e-abab-4840-8c6c-dca80aeac47b';
const WATER_ENERGY_5_ID = 'b85ae5da-0d9f-4600-801e-7244bea0ee7c';
const WATER_ENERGY_6_ID = 'f87fb419-6a10-4c52-a3f3-2932d14fe230';
const WATER_ENERGY_URL = 'panelTypes/water_energy';

export default {
    [WATER_ENERGY_2_ID]: {
        data: [
            'total_transfer_elect_cons_day_cost',
            'total_irrigation_elect_cons_day_cost',
            'total_transfer_elect_cons_night_cost',
            'total_irrigation_elect_cons_night_cost'
        ],
        dateSelect: 'month',
        name: 'water_energy_2',
        onClick: graphRedirect,
        tileId: WATER_ENERGY_2_ID,
        url: WATER_ENERGY_URL,
        myJdPermissions: VIEW_FINANCIAL_DATA
    },
    [WATER_ENERGY_3_ID]: {
        columnCount: 2,
        data: [
            'total_water_irrigation',
            'water_transfer',
            'total_water_cost'
        ],
        dateSelect: 'month',
        name: 'water_energy_3',
        onClick: graphRedirect,
        tileId: WATER_ENERGY_3_ID,
        url: WATER_ENERGY_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    [WATER_ENERGY_4_ID]: {
        columnCount: 2,
        data: [
            'total_water_irrigation',
            'water_transfer',
            'total_water_cost'
        ],
        dateSelect: 'year',
        name: 'water_energy_4',
        onClick: graphRedirect,
        tileId: WATER_ENERGY_4_ID,
        url: WATER_ENERGY_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    [WATER_ENERGY_5_ID]: {
        data: [
            'water_irrigation',
            'water_transfer',
            'water_cost'
        ],
        dateSelect: 'month',
        name: 'water_energy_5',
        tileId: WATER_ENERGY_5_ID,
        url: WATER_ENERGY_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    [WATER_ENERGY_6_ID]: {
        data: [
            'water_irrigation',
            'water_transfer',
            'water_cost'
        ],
        dateSelect: 'year',
        name: 'water_energy_6',
        tileId: WATER_ENERGY_6_ID,
        url: WATER_ENERGY_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    }
};
