// Unpublished Work © 2020-2024 Deere & Company.

const WEATHER_1_ID = 'b0a794f1-c280-46c3-8c89-babdb94e9003';
const WEATHER_2_ID = '78954d10-a9b7-4a63-9a8c-74fde762ff25';
const WEATHER_3_ID = 'be113916-b88c-41c8-acb5-213562d6c20a';
const WEATHER_5_ID = 'debb2d4f-5de3-4ef4-bcef-2606a2e685ef';

const WEATHER_1_NAME = 'weather_1';
const WEATHER_2_NAME = 'weather_2';
const WEATHER_3_NAME = 'weather_3';
const WEATHER_5_NAME = 'weather_5';

export default {
    tilePositions: [
        {
            tile: {
                id: WEATHER_1_ID,
                name: WEATHER_1_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 3,
                i: WEATHER_1_NAME
            }
        },
        {
            tile: {
                id: WEATHER_2_ID,
                name: WEATHER_2_NAME
            },
            columnCount: 3,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 3,
                i: WEATHER_2_NAME
            }
        },
        {
            tile: {
                id: WEATHER_3_ID,
                name: WEATHER_3_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 0,
                w: 1,
                h: 2,
                i: WEATHER_3_NAME
            }
        },
        {
            tile: {
                id: WEATHER_5_ID,
                name: WEATHER_5_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 2,
                w: 1,
                h: 1,
                i: WEATHER_5_NAME
            }
        },
        {
            tile: {
                id: WEATHER_1_ID,
                name: WEATHER_1_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 3,
                i: WEATHER_1_NAME
            }
        },
        {
            tile: {
                id: WEATHER_2_ID,
                name: WEATHER_2_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 3,
                i: WEATHER_2_NAME
            }
        },
        {
            tile: {
                id: WEATHER_3_ID,
                name: WEATHER_3_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 3,
                w: 1,
                h: 2,
                i: WEATHER_3_NAME
            }
        },
        {
            tile: {
                id: WEATHER_5_ID,
                name: WEATHER_5_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 3,
                w: 1,
                h: 5,
                i: WEATHER_5_NAME
            }
        },
        {
            tile: {
                id: WEATHER_1_ID,
                name: WEATHER_1_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 3,
                i: WEATHER_1_NAME
            }
        },
        {
            tile: {
                id: WEATHER_2_ID,
                name: WEATHER_2_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 3,
                w: 1,
                h: 3,
                i: WEATHER_2_NAME
            }
        },
        {
            tile: {
                id: WEATHER_3_ID,
                name: WEATHER_3_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 6,
                w: 1,
                h: 2,
                i: WEATHER_3_NAME
            }
        },
        {
            tile: {
                id: WEATHER_5_ID,
                name: WEATHER_5_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 8,
                w: 1,
                h: 5,
                i: WEATHER_5_NAME
            }
        }
    ]
};
