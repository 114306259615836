// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import ManualDataHistoryTable from 'Ui/components/manual-data/manual-data-history-table';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {connect} from 'react-redux';
import {fetchEffectData} from 'Utils/react-utils';
import OnLinkDialog from 'Ui/components/common/onlink-dialog/onlink-dialog';
import {getMembershipForms} from 'Services/membership-service';
import {ADD_ENTRY_FORM_TITLES, ENTRY_FORM_TITLES} from 'Ui/components/manual-data/constants/entry-form-titles';
import {BUDGET, CHEMICALS, ENVIRONMENT, MOWERS, PRICE_PER_ROUND, SOIL, SULFUR} from 'Common/constants/data-group-constants';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {sortBy} from 'lodash';

const EXCLUDED_DATA_GROUPS = [BUDGET, CHEMICALS, ENVIRONMENT, MOWERS, SULFUR, PRICE_PER_ROUND];

function formatManualFormData(form, translations, onCloseEntryDialog) {
    const entryFormTitle = ENTRY_FORM_TITLES[form.title];
    const label = `${translations[form.category]} - ${entryFormTitle ? translations[entryFormTitle] : translations[form.title]}`;

    form.fields = form.dataGroup === SOIL ?
        form.fields?.filter((field) => !field.dataType.match(/ec|percolation_rate|thatch/)) :
        form.fields;

    return {
        element: (
            <ManualDataHistoryTable
                addButtonLabel={translations[ADD_ENTRY_FORM_TITLES[form.title]]}
                dataGroup={form.dataGroup}
                form={form}
                label={label}
                onCloseEntryDialog={onCloseEntryDialog}
                translations={translations}
            />
        ),
        id: `${form.category}-${form.title}`,
        label
    };
}

function ManualDataDialog(props) {
    const {
        closeDialog,
        onCloseEntryDialog,
        translations
    } = props;

    const [formData, setFormData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Course-Firmness');

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {forms} = await getMembershipForms();

        if (isMounted()) {
            const filteredFormData = forms.filter((form) => !EXCLUDED_DATA_GROUPS.includes(form.dataGroup));
            const formattedFormData = filteredFormData.map((form) => formatManualFormData(form, translations, onCloseEntryDialog)).sort();
            const sortedFormattedFormData = sortBy(formattedFormData, 'label');

            setFormData(sortedFormattedFormData);
            setActiveTab(sortedFormattedFormData[0]?.id);
            setLoading(false);
        }
    }), []);

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading || formData === null}
            size='50px'
        >
            <OnLinkDialog
                activeTab={activeTab}
                className={joinClassNames('manual-data-dialog', 'settings-dialog')}
                closeHandler={closeDialog}
                content={formData}
                large={true}
                onTabClick={(tabId) => setActiveTab(tabId)}
                routable={false}
                title={translations.ONLINK_MANUAL_DATA_ENTRY}
            />
        </LoadingWrapper>
    );
}

ManualDataDialog.propTypes = {
    closeDialog: PropTypes.func,
    onCloseEntryDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.MANUAL_DATA_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(ManualDataDialog);
