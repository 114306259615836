// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import breakpoints from 'Common/constants/grid-breakpoints';
import cols from 'Common/constants/grid-cols';
import GridColumns from 'Ui/components/grid/grid-columns';
import {joinClassNames} from 'Utils/html-utils';
import {createStaticLayouts, getColumnCount} from 'Utils/layout-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {WidthProvider} from 'react-grid-layout';

function layoutOrDefault(layout) {
    if (isNullOrUndefined(layout) || isNullOrUndefined(layout.tilePositions)) {
        return {};
    }

    return createStaticLayouts(layout);
}

function mapChildren(children) {
    const childMap = {};

    React.Children.forEach(children, (child) => {
        childMap[child.key] = child;
    });

    return childMap;
}

function GridLayout(props) {
    const {
        children,
        className,
        innerRef,
        layout,
        width
    } = props;

    const columnCount = React.useMemo(() => getColumnCount(breakpoints, cols, width), [width]);
    const staticLayouts = React.useMemo(() => layoutOrDefault(layout), [layout]);

    const staticLayout = staticLayouts[columnCount];

    if (React.Children.count(children) === 0 || isNullOrUndefined(staticLayout)) {
        return null;
    }

    return (
        <div
            className={joinClassNames('grid-container', className)}
            ref={innerRef}
        >
            <GridColumns
                columnCount={columnCount}
                columns={staticLayout}
                tiles={mapChildren(children)}
            />
        </div>
    );
}

GridLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    innerRef: PropTypes.reference,
    layout: PropTypes.layout,
    measureBeforeMount: PropTypes.bool,
    width: PropTypes.number
};

function propsAreEqual(prevProps, nextProps) {
    return prevProps.children === nextProps.children &&
        prevProps.layout === nextProps.layout &&
        prevProps.width === nextProps.width;
}

export default WidthProvider(React.memo(GridLayout, propsAreEqual));
