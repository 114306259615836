// Unpublished Work © 2022-2024 Deere & Company.

export const ONLINK_DASHBOARD = 'OnLink Dashboard';
export const ONEQUIP = 'OnEquip';
export const ONLABOR = 'OnLabor';
export const ONLINK_MAP = 'OnLink Map';
export const ONLINK_SETTINGS = 'OnLink Settings';
export const ONLINK_LOGIN = 'OnLink Login';

export const HOME_TITLE = 'home';
export const MAP_TITLE = 'MAP';
export const LABOR_TITLE = 'Labor';
export const FLEET_MAINTENANCE_TITLE = 'ONLINK_FLEET_MAINTENANCE';
export const PARTS_TITLE = 'PARTS';
export const FLEET_TITLE = 'ONLINK_FLEET';
export const ANALYZE_FLEET_TITLE = 'ONLINK_ANALYZE_FLEET';
export const SETTINGS_TITLE = 'ONLINK_SYSTEM_SETTINGS';
export const ANALYZE_OPERATIONS_TITLE = 'ONLINK_COURSE_OPERATIONS';
export const WORKBOARD = 'Workboard';
