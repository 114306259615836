// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {onManualDataChange, validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {isEmptyString, isNullOrUndefined} from 'Common/utils/validation-utils';
import {MAX_NUMERIC_VALUE, MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {KILOWATT_HOURS} from 'Common/constants/data-unit-constants';
import moment from 'moment';

function valueOrEmptyString(value) {
    return isNullOrUndefined(value) ? '' : value;
}

function ElectricalConsumptionEntryForm(props) {
    const {
        membership, setValid, setValues, translations, values
    } = props;

    const [validatedDate, setValidatedDate] = React.useState();

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    React.useEffect(() => {
        const validatedDateFromTime = validateDate(values.timeSample, setValid);

        setValidatedDate(validatedDateFromTime);
    }, [values.timeSample]);

    const {
        irrigation,
        transfer,
        isInvalid
    } = useDeepMemo(() => {
        const {manualData = [] } = values;
        const manualDataMap = manualData.reduce((manualDataByType, manualData) => {
            manualDataByType.set(manualData.data_type, manualData.value);

            return manualDataByType;
        }, new Map());

        const irrigation = manualDataMap.get('irrigation_elect_cons');
        const transfer = manualDataMap.get('transfer_elect_cons');

        return {
            irrigation: valueOrEmptyString(irrigation),
            transfer: valueOrEmptyString(transfer),
            isInvalid: isEmptyString(irrigation) &&
                isEmptyString(transfer)
        };
    }, [values.manualData]);

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        errors: {
            customError: translations.REQUIRED_FIELD_TEXT,
            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`,
            rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${MIN_NUMERIC_VALUE}`
        },
        invalidCustomError: isInvalid,
        max: MAX_NUMERIC_VALUE,
        min: MIN_NUMERIC_VALUE,
        setValid,
        membership,
        tabIndex: 0,
        type: 'number'
    };

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
            />
            <ValidationInput
                {...sharedInputProps}
                label={`${translations.ONLINK_IRRIGATION_PUMP} (kwh)`}
                name='irrigation_elect_cons'
                onChange={(event) => onManualDataChange({
                    dataUnit: KILOWATT_HOURS,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                value={irrigation}
            />
            <ValidationInput
                {...sharedInputProps}
                label={`${translations.ONLINK_TRANSFER_PUMP} (kwh)`}
                name='transfer_elect_cons'
                onChange={(event) => onManualDataChange({
                    dataUnit: KILOWATT_HOURS,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                value={transfer}
            />
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

ElectricalConsumptionEntryForm.propTypes = {
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default ElectricalConsumptionEntryForm;
