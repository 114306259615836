// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {redirect} from 'Utils/redirect-utils';

function EquipmentName(props) {
    const {
        equipment,
        history,
        requiredMyJdPermissions,
        tab,
        type
    } = props;

    const {
        equipmentId,
        equipmentName,
        formattedName
    } = equipment;

    const serviceUrl = `${type}/equipmentDetails/${tab}?equipmentId=${equipmentId}`;

    const redirectParams = [serviceUrl, history];

    return (
        <EditLink
            onClick={() => redirect(...redirectParams)}
            requiredMyJdPermissions={requiredMyJdPermissions}
        >
            {formattedName || equipmentName}
        </EditLink>
    );
}

EquipmentName.propTypes = {
    equipment: PropTypes.equipment,
    history: PropTypes.history,
    requiredMyJdPermissions: PropTypes.arrayOf(PropTypes.string),
    tab: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string
};

export default withRouter(EquipmentName);
