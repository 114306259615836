// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {Accordion} from '@deere/isg.component-library/lib/Accordion/Accordion';
import {Button} from '@deere/isg.component-library/lib/Button';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {MultiselectFilterList} from '@deere/isg.component-library/lib/MultiselectFilterList';
import Stack from '@mui/material/Stack';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnlinkButton from 'Ui/components/common/onlink-button';
import SidebarHeader from 'OnLabor/workboard/calendar/sidebar-header';
import {openDialog} from 'Store/actions/dialogs';
import {getTodos} from 'Services/membership-service';
import {createTodo, updateTodo} from 'Services/todo-service';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {transformAndSortTodos} from 'Utils/workboard-utils';
import {useToggledMemo} from 'Ui/react-hooks/use-toggled-memo';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import dialogTypes from 'Ui/components/common/dialog-types';
import {clsx} from 'clsx';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_WORKBOARDS} from 'Common/constants/business-activities';
import {ListItem, ListItemText} from '@deere/isg.component-library';

async function saveTodo({
    appUserId,
    completed,
    membershipId,
    fetchTodos,
    todoItem
}) {
    const todoData = {
        completed,
        dateSelect: formatTime(new Date(), DATE_FORMATS.day),
        membershipId,
        title: todoItem?.title
    };

    if (completed) {
        await updateTodo(todoData, todoItem.id);
    } else {
        todoData.appUserId = appUserId;

        await createTodo(todoData);
    }

    await fetchTodos();
}

function getQuickTodoList(
    appUserId,
    fetchTodos,
    membershipId,
    todoTemplates,
    translations
) {
    return (
        <MultiSelect
            items={todoTemplates}
            labels={{
                placeholder: translations.ONLINK_QUICK_TODO
            }}
            noSearch={true}
            onChange={([id]) => saveTodo({
                appUserId,
                completed: false,
                membershipId,
                fetchTodos,
                todoItem: todoTemplates?.find((todoTemplate) => todoTemplate.id === id)
            })}
            selectedIds={[]}
            single={true}
        />
    );
}

function getTodosList(
    appUserId,
    isMobile,
    fetchTodos,
    membershipId,
    todosLoading,
    todos,
    canEditTodo
) {
    const classNameValue = clsx('multi-select-filter-list', {
        'mobile-group': isMobile
    });
    const heightValue = isMobile ? '200px' : '100%';

    return (
        <LoadingWrapper
            className='table-loading-icon'
            loading={todosLoading}
            size='50px'
        >
            <Stack
                className={classNameValue}
                height={heightValue}
                marginTop='8px'
                minHeight={52}
            >
                { canEditTodo ?
                    <MultiselectFilterList
                        autoFocus={true}
                        disableSorting={true}
                        items={todos}
                        noSearch={true}
                        onChange={([id]) => saveTodo({
                            appUserId,
                            completed: true,
                            membershipId,
                            fetchTodos,
                            todoItem: todos?.find((todo) => todo.id === id)
                        })}
                        selectedIds={[]}
                        single={true}
                    /> : todos.map((todo) => (
                        <ListItem key={todo.id}>
                            <ListItemText
                                primary={todo.title}
                                secondary={todo.subtitle}
                            />
                        </ListItem>))
                }
            </Stack>
        </LoadingWrapper>
    );
}

function TodoList(props) {
    const {
        appUserId,
        isMobile,
        membershipId,
        openManageTodoDialog,
        todoTemplates,
        translations
    } = props;

    const todosMasterList = React.useRef([]);
    const [todos, setTodos] = React.useState([]);
    const [todosLoading, setTodosLoading] = React.useState(false);

    const canEditTodo = isAuthorized({
        myJdPermissions: MANAGE_WORKBOARDS
    }, props);

    const fetchTodos = React.useCallback(async (isMounted = () => true) => {
        setTodosLoading(true);

        const {todos: todosList} = await getTodos();

        if (isMounted()) {
            todosMasterList.current = todosList;
            setTodos(transformAndSortTodos(todosList));
            setTodosLoading(false);
        }
    }, []);

    React.useEffect(() => fetchEffectData(fetchTodos), [membershipId]);

    const manageTodoProps = useDeepMemo(() => {
        const sharedProps = {
            children: translations.ONLINK_MANAGE_TODOS,
            disabled: todosLoading,
            onClick() {
                openManageTodoDialog({
                    fetchTodos,
                    translations,
                    todos: todosMasterList
                });
            }
        };

        return isMobile ? {
            ...sharedProps,
            className: 'mobile-group',
            sx: {
                marginBottom: '10px',
                width: 'fit-content'
            }
        } : sharedProps;
    }, [fetchTodos, isMobile, todosLoading, todosMasterList.current, translations]);

    const manageTodoButton = useToggledMemo({
        disabledCallback() {
            return (
                <OnlinkButton
                    {...manageTodoProps}
                    color='blue'
                />
            );
        },
        enabledCallback() {
            return (
                <Button
                    {...manageTodoProps}
                    variant='secondary'
                />
            );
        },
        toggle: ONLINK_NAVIGATION_REDESIGN
    }, [manageTodoProps]);

    const quickTodoList = getQuickTodoList(appUserId, fetchTodos, membershipId, todoTemplates, translations);

    return isMobile ? (
        <>
            <SidebarHeader/>
            <Accordion
                className='todos-accordion'
                classes={{
                    root: 'accordion-root'
                }}
                elevation={0}
                headerTitle={translations.ONLINK_TODOS}
                style={{
                    border: 'none',
                    borderRadius: '4px',
                    boxShadow: 'none'
                }}
            >
                <div className='todos-accordion-child'>
                    {manageTodoButton}
                    {canEditTodo && quickTodoList}
                    {getTodosList(appUserId, true, fetchTodos, membershipId, todosLoading, todos, canEditTodo)}
                </div>
            </Accordion>
        </>
    ) : (
        <>
            <SidebarHeader title={translations.ONLINK_TODOS}>
                {manageTodoButton}
            </SidebarHeader>
            {canEditTodo && quickTodoList}
            {getTodosList(appUserId, false, fetchTodos, membershipId, todosLoading, todos, canEditTodo)}
        </>
    );
}

TodoList.propTypes = {
    appUserId: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openManageTodoDialog: PropTypes.func,
    todoTemplates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        openManageTodoDialog(props) {
            dispatch(openDialog(dialogTypes.MANAGE_TODO_DIALOG, props));
        }
    };
}

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(TodoList));
