// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MarkerWithLabel from 'Ui/components/map/elements/common/marker-with-label';
import {Icon} from '@deere/icons';

const ICON_SIZE = 50;
const ICON_STYLE = {
    icon: {
        stroke: '#fff',
        strokeLinejoin: 'round',
        strokeWidth: '2px'
    }
};

function EquipmentMarker(props) {
    const {
        equipment,
        onEquipmentSelect
    } = props;

    const position = {
        lat: equipment && equipment.lat || 0,
        lng: equipment && equipment.lon || 0
    };

    const iconName = equipment && equipment.icon && equipment.icon.name || null;

    const labelContent = (
        <>
            <Icon
                height={ICON_SIZE}
                iconName={iconName}
                iconStyles={ICON_STYLE}
                width={ICON_SIZE}
            />
            <Icon
                height={ICON_SIZE}
                iconName={iconName}
                width={ICON_SIZE}
            />
        </>
    );

    return (
        <MarkerWithLabel
            labelContent={labelContent}
            onClick={() => onEquipmentSelect(equipment)}
            position={position}
        />
    );
}

EquipmentMarker.propTypes = {
    equipment: PropTypes.equipment,
    onEquipmentSelect: PropTypes.func
};

export default EquipmentMarker;
