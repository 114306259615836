// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import DataTable from 'Ui/components/common/data-table/data-table';
import React from 'react';
import {numericCompare} from 'Ui/models/maintenance';
import {BY_USER_STATUS} from 'OnLabor/report/constants/labor-report';
import {dataTableFormatTime} from 'Utils/time-utils';
import {formatNumber} from 'Utils/unit-conversion-utils';

function getColumns({
    dateSelector,
    rows,
    secondarySelector,
    tertiarySelector,
    timeScale,
    translations
}) {
    const formattedDate = dataTableFormatTime(dateSelector, timeScale);
    const total = rows.reduce((totalValue, {total}) => totalValue + total, 0);

    return [
        {
            Header: secondarySelector === BY_USER_STATUS ? translations.STATUS : translations.ONLINK_EMPLOYEE,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            Footer: (
                <span className='workboard-label'>
                    {
                        `${formattedDate} ${translations.ONLINK_TOTAL} - ${tertiarySelector}`
                    }
                </span>
            )
        },
        {
            Header: translations.ONLINK_TOTAL,
            accessor: 'total',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            sortMethod: numericCompare,
            Cell(row) {
                return formatNumber(row.value);
            },
            Footer: (
                <span className='workboard-label'>
                    {formatNumber(total)}
                </span>
            )
        }
    ];
}

function getRows(laborReportData, secondarySelector, tertiarySelector) {
    if (secondarySelector === BY_USER_STATUS) {
        const user = laborReportData?.find((user) => user.name === tertiarySelector);

        return user ? Object.keys(user.optionMap).map((key) => ({
            name: key,
            total: user.optionMap[key]
        })) : [];
    }

    return laborReportData.reduce((data, user) => {
        const {
            name,
            optionMap
        } = user;

        if (optionMap[tertiarySelector]) {
            data.push({
                name,
                total: optionMap[tertiarySelector]
            });
        }

        return data;
    }, []);
}

function ScheduleStatusDataTable(props) {
    const {
        dateSelector,
        laborReportData,
        secondarySelector,
        tertiarySelector,
        timeScale,
        translations
    } = props;

    const rows = getRows(laborReportData, secondarySelector, tertiarySelector);

    return (
        <div className='graph-table-container labor-report'>
            <DataTable
                columns={getColumns({
                    dateSelector,
                    rows,
                    secondarySelector,
                    tertiarySelector,
                    timeScale,
                    translations
                })}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                rows={rows}
                translations={translations}
            />
        </div>
    );
}

ScheduleStatusDataTable.propTypes = {
    dateSelector: PropTypes.string,
    laborReportData: PropTypes.arrayOf(PropTypes.object),
    secondarySelector: PropTypes.string,
    tertiarySelector: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations
};

export default ScheduleStatusDataTable;
