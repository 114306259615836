// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PanelData from 'Ui/components/panel/panel-data';
import PanelDateLabel from 'Ui/components/panel/panel-date-label';
import PropTypes from 'Utils/prop-type-utils';
import {getValueWithThreshold} from 'Utils/threshold-utils';
import {getMinuteOrHourMessage} from 'Utils/time-utils';
import {convertToFormattedUoM, convertToUoM, getUoMConfig} from 'Utils/unit-conversion-utils';
import {getUnitOfMeasureOverride} from 'Common/utils/units/unit-converter';
import {DEGREES_F, MILES_PER_HOUR} from 'Common/constants/data-unit-constants';
import {WSPD} from 'Common/constants/data-group-constants';

function createThresholdMap(thresholdMap, threshold) {
    thresholdMap.set(threshold.dataType, threshold);

    return thresholdMap;
}

function getFormattedValues(conversionConfig, {
    dewpt,
    rh,
    thresholds,
    wdirCardinal,
    wspd
}, windSpeedUnitOfMeasure) {
    const thresholdMap = thresholds.reduce(createThresholdMap, new Map());

    const dewPoint = convertToFormattedUoM(dewpt, {
        ...conversionConfig,
        dataUnit: DEGREES_F
    });

    const windSpeed = Math.round(convertToUoM(wspd, {
        ...conversionConfig,
        dataUnit: MILES_PER_HOUR,
        unitOfMeasure: windSpeedUnitOfMeasure
    }));

    return {
        dewptSpan: getValueWithThreshold(thresholdMap.get('dewpt'), dewpt, dewPoint),
        rhSpan: getValueWithThreshold(thresholdMap.get('rh'), rh, `${rh}%`),
        wspdSpan: getValueWithThreshold(thresholdMap.get('wspd'), wspd, `${windSpeed} ${wdirCardinal}`)
    };
}

function Wind(props) {
    const {
        featureToggles,
        obsTimeLocal,
        translations,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = props;

    const conversionConfig = {
        featureToggles,
        translations,
        unitOfMeasure
    };

    const windSpeedUnitOfMeasure = React.useMemo(() =>
        getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, WSPD)
    , [unitOfMeasureOverrides, unitOfMeasure]);

    const {
        dewptSpan,
        rhSpan,
        wspdSpan
    } = getFormattedValues(conversionConfig, props, windSpeedUnitOfMeasure);

    const {formatting: windSpeedFormatting} = getUoMConfig({
        ...conversionConfig,
        dataUnit: MILES_PER_HOUR,
        unitOfMeasure: windSpeedUnitOfMeasure
    });
    const {suffix: windSpeedLabel} = windSpeedFormatting;

    const date = getMinuteOrHourMessage(translations, obsTimeLocal);

    return (
        <div className='wind internal-panel'>
            <div className='panel-row'>
                <div className='panel-section humidity'>
                    <div className='panel-row'>
                        {translations.RelativeHumidity_prcnt}
                        {rhSpan}
                    </div>
                    <div className='panel-row'>
                        {translations.WEATHER_DEW_POINT}
                        {dewptSpan}
                    </div>
                </div>
                <PanelData
                    className='panel-section'
                    label={`${translations.weather_WIND} (${translations[windSpeedLabel] || windSpeedLabel})`}
                    translations={translations}
                    value={wspdSpan}
                />
            </div>
            <PanelDateLabel
                className='panel-row'
                date={date}
            />
        </div>
    );
}

Wind.propTypes = {
    dewpt: PropTypes.number,
    featureToggles: PropTypes.featureToggles,
    obsTimeLocal: PropTypes.string,
    rh: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.array,
    wdirCardinal: PropTypes.string,
    wspd: PropTypes.number
};

export default Wind;
