// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {getHocData} from 'Services/hoc-service';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import {configForUnit} from 'Utils/data-utils';
import {MultiSelect} from '@deere/form-controls';
import Panel from 'Ui/components/panel/panel';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import HocEditIcon from 'Ui/components/home/hoc/hoc-edit-icon';
import {fetchEffectData} from 'Utils/react-utils';
import {sortBy} from 'lodash';
import {formatUoMValue, getFormatting} from 'Utils/unit-conversion-utils';
import {INCHES} from 'Common/constants/data-unit-constants';
import {COURSE, FACILITY} from 'Common/constants/membership-type';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

const dropDownMargin = 8;
const heightOfDropDownRow = 41;
const heightOfHocRow = 28.8;
const dropDownHeightWhenEmpty = 105;
const tilePadding = 20;

function isCourseOrFacility(membershipType) {
    return membershipType === COURSE || membershipType === FACILITY;
}

function getDropDownHeight(numberOfDropDownRows, numberOfHocRows) {
    const maxDropDownHeight = numberOfDropDownRows * heightOfDropDownRow - dropDownMargin;
    const hocDataHeight = numberOfHocRows ? numberOfHocRows * heightOfHocRow + tilePadding : dropDownHeightWhenEmpty;

    if (maxDropDownHeight > hocDataHeight) {
        return hocDataHeight;
    }

    return null;
}

function getHocValue(hoc, translations) {
    const hasDataValue = hoc.dataValues?.length ? {
        unitOfMeasure: hoc.dataUnit,
        value: hoc.dataValues[0].dataValue
    } : null;
    const hocNumber = hasDataValue || {
        unitOfMeasure: INCHES,
        value: hoc.currentValue
    };

    return hocNumber.value && Number.parseFloat(hocNumber.value) !== 0 ? formatUoMValue(
        hocNumber.value,
        {
            formatting: getFormatting(configForUnit(hocNumber.unitOfMeasure)),
            translations
        }) : '';
}

function getHocRows(activeHocData, translations, featureToggles) {
    if (!activeHocData) {
        return [];
    }

    const formattedValues = activeHocData.hocValues.map((hoc) => {
        const hocValue = getHocValue(hoc, translations, featureToggles);

        return [
            translations[hoc.label],
            hocValue
        ];
    });

    return formattedValues.filter((formattedEntry) => formattedEntry[1]);
}

function initialize(props) {
    const [hocData, setHocData] = React.useState(() => []);
    const [activeHocData, setActiveHocData] = React.useState(() => null);
    const [loading, setLoading] = React.useState(() => false);

    const {
        translations,
        membership,
        featureToggles,
        fetchDate
    } = props;

    const {
        membershipId,
        membershipType
    } = membership;

    return {
        hocData,
        setHocData,
        activeHocData,
        setActiveHocData,
        translations,
        membership,
        membershipId,
        membershipType,
        featureToggles,
        loading,
        setLoading,
        fetchDate
    };
}

function HOC(props) {
    const {
        hocData,
        setHocData,
        activeHocData,
        setActiveHocData,
        translations,
        membership,
        membershipId,
        membershipType,
        featureToggles,
        loading,
        setLoading,
        fetchDate
    } = initialize(props);

    const isCourseOrFacilityValue = isCourseOrFacility(membershipType);

    async function updateHocData(isMounted) {
        if (isCourseOrFacilityValue) {
            setLoading(true);
            const hocDataValue = await getHocData(membership);

            const sortedHocData = sortBy(hocDataValue, 'name');

            if (isMounted()) {
                setHocData(sortedHocData);
                setActiveHocData(sortedHocData?.[0]);
                setLoading(false);
            }
        }
    }

    React.useEffect(() => fetchEffectData(updateHocData), [membershipId, fetchDate]);

    function updateCourseMembership(activeHocData) {
        const activeHocIndex = hocData.map((hoc) => {
            return hoc.membershipId;
        }).indexOf(activeHocData.membershipId);

        hocData[activeHocIndex] = activeHocData;

        setHocData(hocData);
        setActiveHocData(activeHocData);
    }

    function getHeader(numberOfHocRows) {
        if (hocData.length <= 1) {
            return null;
        }

        const formattedHoc = hocData.map((course) => ({
            id: course.membershipId,
            title: course.name
        }));

        const dropDownHeight = getDropDownHeight(formattedHoc.length, numberOfHocRows);
        const dynamicContainerHeight = dropDownHeight ? {
            containerHeight: dropDownHeight
        } : null;

        return (
            <div className='multiselect-container'>
                <MultiSelect
                    disabled={false}
                    hideFilter={true}
                    items={formattedHoc}
                    multiple={false}
                    onChange={(event) => updateCourseMembership(hocData.find((hocValue) => hocValue.membershipId === event[0]))}
                    selectedIds={[activeHocData ? activeHocData.membershipId : null]}
                    tabIndex={0}
                    {...dynamicContainerHeight}
                    className={'multiselect'}
                />
            </div>
        );
    }

    const hocRows = getHocRows(activeHocData, translations, featureToggles);
    const hocTable = hocRows.length === 0 ?
        <NoDataMessage translations={translations}/> :
        (
            <BasicTable
                className='hoc-values'
                rows={hocRows}
                vertical={true}
            />
        );

    const editIcon = (
        <HocEditIcon
            hocData={activeHocData}
            translations={translations}
            updateCourseMembership={() => fetchEffectData(updateHocData)}
        />
    );

    return (
        <Panel
            icon={editIcon}
            title={translations.HEIGHTS_OF_CUT}
            translations={translations}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                <div
                    className='hoc-panel'
                    id='hoc-panel'
                >
                    {isCourseOrFacilityValue && getHeader(hocRows.length)}
                    {isCourseOrFacilityValue && hocTable}
                    {!isCourseOrFacilityValue && translations.FAILURE_TO_DISPLAY_HOC_MESSAGE}
                </div>
            </LoadingWrapper>
        </Panel>
    );
}

HOC.propTypes = {
    featureToggles: PropTypes.featureToggles,
    fetchDate: PropTypes.string,
    membership: PropTypes.shape(),
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: {
            membershipId: state.membership.membershipId,
            membershipType: state.membership.membershipType,
            childLevels: state.membership.childLevels,
            name: state.membership.name
        },
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(HOC);
