// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import {getModelMaintenances} from 'Services/fleet-service';
import {deleteModelMaintenance} from 'Services/model-maintenance-service';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {
    alphaNumericCompare,
    genericServiceCompare,
    getInterval
} from 'Ui/models/maintenance';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {IconAdd} from '@deere/icons';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';

const ADD_ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px',
        fill: '#fff'
    }
};

function getColumns(translations, openAddEditMaintenanceDialog, fetchModelMaintenances) {
    return [
        {
            Header: translations.MODEL,
            accessor: 'modelName',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                return (
                    <EditLink
                        onClick={() => {
                            openAddEditMaintenanceDialog({
                                initialModelMaintenanceId: row.original.modelMaintenanceId,
                                translations,
                                onClose: fetchModelMaintenances
                            });
                        }}
                    >
                        {row.original.modelName}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.BRAND,
            accessor: 'manufacturerName'
        },
        {
            Header: translations.ONLINK_DETAIL,
            accessor: 'description'
        },
        {
            Header: translations.ONLINK_SERVICE_TYPE,
            accessor: 'serviceTypeName'
        },
        {
            Header: translations.INTERVAL,
            accessor: (row) => row,
            id: 'interval',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'interval');
            },
            Cell(row) {
                return row.original.interval;
            }
        }
    ];
}

function MaintenanceSettings(props) {
    const {
        addToast,
        closeConfirmation,
        fleetId,
        openAddEditMaintenanceDialog,
        openConfirmation,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [modelMaintenances, setModelMaintenances] = React.useState([]);

    async function fetchModelMaintenances(isMounted = () => true) {
        setLoading(true);

        const {modelMaintenance} = await getModelMaintenances(fleetId);

        const formattedMaintenance = modelMaintenance.map((maintenanceSchedule) => ({
            ...maintenanceSchedule,
            interval: getInterval(maintenanceSchedule, translations)
        }));

        if (isMounted()) {
            setModelMaintenances(formattedMaintenance);
            setLoading(false);
        }
    }

    function deleteModelMaintenanceHandler(modelMaintenance) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_MAINTENANCE_SCHEDULE.toLowerCase()
            }),
            onCancel: closeConfirmation,
            showLoadingIcon: true,
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_MAINTENANCE_SCHEDULE
            }),
            async onContinue() {
                try {
                    await deleteModelMaintenance(modelMaintenance.modelMaintenanceId);
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.ONLINK_MAINTENANCE_SCHEDULE.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }

                closeConfirmation();
                fetchModelMaintenances();
            }
        });
    }

    useDeepEffect(() => fetchEffectData(fetchModelMaintenances), [fleetId]);

    const addMaintenanceButton = (
        <div className='extra-table-options right-aligned'>
            <OnlinkButton
                className='primary add-maintenance-button'
                leftIcon={<IconAdd iconAdd={ADD_ICON_STYLE}/>}
                onClick={() => {
                    openAddEditMaintenanceDialog({
                        translations,
                        onClose: fetchModelMaintenances
                    });
                }}
            >
                {translations.ONLINK_ADD_MAINTENANCE_SCHEDULE}
            </OnlinkButton>
        </div>
    );

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(translations, openAddEditMaintenanceDialog, fetchModelMaintenances)}
                defaultSorted={[{
                    desc: false,
                    id: 'modelName'
                }]}
                deleteHandler={deleteModelMaintenanceHandler}
                filterComponent={addMaintenanceButton}
                loading={loading}
                noDataAvailableMessage={translations.ONLINK_NO_MAINTENANCE_SCHEDULES_FOUND}
                rows={modelMaintenances}
                searchable={true}
                showDeleteColumn={isAuthorized({
                    myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                }, props)}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

MaintenanceSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    fleetId: PropTypes.string,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    openAddEditMaintenanceDialog: PropTypes.func,
    openConfirmation: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        fleetId: state.membership.fleetId,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openAddEditMaintenanceDialog(props) {
            dispatch(openDialog(dialogTypes.ADD_EDIT_MAINTENANCE_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceSettings);
