// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ReadOnlyValue from 'Ui/components/common/form/read-only/read-only-value';

function ReadOnlyWrapperComponent(props) {
    const {
        component,
        readOnly,
        readOnlyComponent: ReadOnlyComponent,
        readOnlyProps,
        wrappedComponent: WrappedComponent,
        wrappedProps,
        ...sharedProps
    } = props;

    return readOnly ? (
        <ReadOnlyComponent
            {...sharedProps}
            {...readOnlyProps}
        />
    ) : (
        <WrappedComponent
            component={component}
            {...sharedProps}
            {...wrappedProps}
        />
    );
}

ReadOnlyWrapperComponent.defaultProps = {
    readOnlyComponent: ReadOnlyValue,
    readOnlyProps: {},
    wrappedProps: {}
};

ReadOnlyWrapperComponent.propTypes = {
    component: PropTypes.elementType,
    readOnly: PropTypes.bool,
    readOnlyComponent: PropTypes.elementType,
    readOnlyProps: PropTypes.object,
    wrappedComponent: PropTypes.elementType,
    wrappedProps: PropTypes.object
};

export default ReadOnlyWrapperComponent;
