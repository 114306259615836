// Unpublished Work © 2021-2024 Deere & Company.

export const MAX_ENGINE_HOURS = 2000000;
export const ENGINE_HOURS_REGEX = /^-?\d+(?:\.\d?)?/;
export const ELECTRIC_MACHINE_VIN_PREFIXES = [
    '1M0XE2D1',
    '1M0XE8D1',
    '1M0185EA',
    '1M0225EA'
];
export const VIN_PREFIX_LENGTH = 8;
