// Unpublished Work © 2022-2024 Deere & Company.

import {DATE_FORMAT_OPTIONS, DATE_FORMATS, formatLocalizedTime, formatTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import moment from 'moment/moment';
import {findLast, sortBy} from 'lodash';
import {getWorkboardsByMembershipId} from 'Services/membership-service';

export async function getTodaysWorkboards(membershipId) {
    const now = new Date();
    const currentTime = now.getTime();
    const dateSelect = formatTime(now, DATE_FORMATS.day);

    const {workboards: workboardsData} = await getWorkboardsByMembershipId(membershipId, dateSelect);

    const sortedWorkboards = sortBy(workboardsData, (workboard) => new Date(workboard.startTime).getTime());
    const currentWorkboard = findLast(sortedWorkboards, (workboard) => new Date(workboard.startTime).getTime() <= currentTime);

    return currentWorkboard || (sortedWorkboards.length > 0 ? sortedWorkboards[0] : null);
}

export function transformAndSortTodos(todos, hideCompleted = true) {
    const filteredTodos = hideCompleted ? todos.filter((todo) => !todo.completed) : todos;

    const transformedTodos = filteredTodos.map((todoItem) => {
        const {
            appUser,
            dateSelect,
            todoId
        } = todoItem;

        const userData = appUser ? `${appUser.firstName} ${appUser.lastName}` : '';
        const formattedDate = formatLocalizedTime(moment(dateSelect).toDate(), TIME_UNIT_OPTIONS.day);
        const formattedDateWithYear = formatLocalizedTime(moment(dateSelect).toDate(), DATE_FORMAT_OPTIONS);

        return {
            ...todoItem,
            id: todoId,
            userData,
            formattedDate: formattedDateWithYear,
            subtitle: appUser ?
                `${formattedDate} - ${userData}` :
                formattedDate
        };
    });

    return sortBy(transformedTodos, 'dateSelect');
}

export function sortJobsBySeq(jobs) {
    return sortBy(jobs, 'seq').map((job) => {
        const sortedWorkItems = sortBy(job.workItems, 'seq');

        return {
            ...job,
            workItems: sortedWorkItems
        };
    });
}
