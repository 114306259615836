// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {DashboardHeader} from '@deere/onlink-navbar';
import permissions from 'Common/constants/permissions';
import {SETTINGS} from 'Common/constants/routes';
import {hasPermissions} from 'Common/utils/permission-handler';
import {createLink} from 'Utils/navigation-utils';

/* eslint-disable no-magic-numbers */
function addLink(arr, {
    isVisible,
    link
}) {
    if (isVisible) {
        arr.push(link);
    }
}

function createMainHeaderLinksWithMembership(membershipId) {
    const onApply = createLink(`${window.props.onLinkLegacyUri}/onapply/#m/${membershipId}`, 'OnApply');
    const onLabor = createLink(`${window.props.onLinkLegacyUri}/onlabor/#m/${membershipId}/labor`, 'OnLabor');

    return [
        onApply,
        onLabor
    ];
}

function hasMyWorkAccess(appKeys, credentials) {
    return credentials.isMigrated || !appKeys.onlabor && hasPermissions(credentials, permissions.CAN_ACCESS_MY_WORK);
}

function createMainHeaderLinks(appKeys, featureToggles, hasMyJDSession, newMainHeaderLinks, credentials) {
    const {mainHeaderLinks: windowMainHeaderLinks} = window.props.links.oldHeader;
    const mainHeaderLinks = [];

    const hasMapAccess = appKeys.onequip || appKeys.ongolf || appKeys.onlabor;

    addLink(mainHeaderLinks, {
        isVisible: credentials.isMigrated || appKeys.ongolf,
        link: windowMainHeaderLinks[0]
    });
    addLink(mainHeaderLinks, {
        isVisible: credentials.isMigrated || appKeys.onlabor,
        link: windowMainHeaderLinks[2]
    });
    addLink(mainHeaderLinks, {
        isVisible: credentials.isMigrated || hasMyWorkAccess(appKeys, credentials),
        link: windowMainHeaderLinks[5]
    });
    addLink(mainHeaderLinks, {
        isVisible: credentials.isMigrated || appKeys.onequip,
        link: windowMainHeaderLinks[3]
    });
    addLink(mainHeaderLinks, {
        isVisible: credentials.isMigrated || hasMapAccess && hasMyJDSession,
        link: windowMainHeaderLinks[4]
    });

    return mainHeaderLinks;
}

function createSettingsLinks(membershipId) {
    const systemSettingsLink = createLink(SETTINGS, 'System Settings', true);

    const onPerformLink = createLink(`${window.props.onLinkLegacyUri}/onperform/#m/${membershipId}`, 'Map');
    const laborSettingsLink = createLink(`${window.props.onLinkLegacyUri}/onlabor/#m/${membershipId}/settings`, 'OnLabor Settings');
    const equipmentSettingsLink = createLink(`${window.props.onLinkLegacyUri}/onequip/#m/${membershipId}/settings`, 'OnEquip Settings');

    return [
        onPerformLink,
        systemSettingsLink,
        laborSettingsLink,
        equipmentSettingsLink
    ];
}

function createOtherLinks(appKeys, membershipId, isMigrated) {
    const otherLinks = [];

    const settingsLinks = createSettingsLinks(membershipId);

    addLink(otherLinks, {
        isVisible: isMigrated || appKeys.onperform,
        link: settingsLinks[0]
    });

    otherLinks.push(settingsLinks[1]);

    return otherLinks;
}

function DashboardHeaderWrapper(props) {
    const {
        appKeys,
        featureToggles,
        hasMyJDSession,
        hasSomeAppAccess,
        isMigrated,
        membershipId,
        permissions,
        superUser
    } = props;

    const {
        account,
        moreHeaderLinks
    } = window.props.links.oldHeader;

    const logoutLink = createLink('/logout', 'ONLINK_LOGOUT', false, true);

    const newMainHeaderLinks = createMainHeaderLinksWithMembership(membershipId);
    const credentials = {
        permissionMap: permissions,
        superUser
    };

    return (
        <DashboardHeader
            {...props}
            links={{
                mainHeaderLinks: createMainHeaderLinks(appKeys, featureToggles, hasMyJDSession, newMainHeaderLinks, credentials),
                account: {
                    otherLinks: hasSomeAppAccess ? createOtherLinks(appKeys, membershipId, isMigrated) : [],
                    userLinks: [logoutLink],
                    integrationLinks: hasSomeAppAccess ? account.integrationLinks : []
                },
                moreHeaderLinks: hasSomeAppAccess ? moreHeaderLinks.slice(0, 3) : []
            }}
        />
    );
}

DashboardHeaderWrapper.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    hasMyJDSession: PropTypes.bool,
    hasSomeAppAccess: PropTypes.bool,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    permissions: PropTypes.legacyPermissions,
    superUser: PropTypes.bool
};

export function mapStateToProps(state) {
    return {
        hasMyJDSession: state.account.hasMyJDSession,
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        permissions: state.account.permissions,
        superUser: state.account.superUser
    };
}

export default connect(mapStateToProps)(DashboardHeaderWrapper);
