// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import moment from 'moment';
import {HOC} from 'Common/constants/data-group-constants';
import {COURSE} from 'Common/constants/membership-type';

async function getHocDataByTime(membership, dateSelect, dataGroup = HOC) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/memberships/${membership.membershipId}/forms/${dataGroup}`, {
        params: {
            date: dateSelect
        }
    });

    return {
        membershipId: membership.membershipId,
        name: membership.name,
        hocValues: response.data.forms[0].fields,
        manualDataId: response.data.forms[0].manualDataId,
        note: response.data.note,
        originDate: response.data.forms[0].dateSelect
    };
}

async function getHoc(dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/hoc', {
        params: {
            date: dateSelect
        }
    });

    return response.data;
}

async function getHocData(membership, dataGroup = HOC) {
    const dateSelect = moment().format('YYYYMMDD');

    const memberships = membership.membershipType === COURSE ?
        [membership] :
        membership.childLevels.filter((p) => p.membershipType === COURSE);

    const hocData = memberships.map((membership) => getHocDataByTime(membership, dateSelect, dataGroup));

    return Promise.all(hocData);
}

async function putHocManualData(manualData, manualDataId) {
    const axiosClient = createUiAxiosClient();
    const response = await axiosClient.put(`/api/hoc/manualData/${manualDataId}`, manualData);

    return response.data;
}

async function postHocManualData(manualData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/hoc/manualData', manualData);

    return response.data;
}

export {
    getHocData,
    getHocDataByTime,
    getHoc,
    putHocManualData,
    postHocManualData
};
