// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {BasicDialog} from '@deere/basic-dialog';
import {Document, Page} from 'react-pdf';
import termsAndConditions from 'Ui/pdfs/termsAndConditions.pdf';
import {saveAs} from 'file-saver';
import LoadingIcon from 'Ui/components/common/loading-icon';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

function footerActions(translations) {
    const downloadTermsAndConditions = React.useCallback(() => {
        saveAs(termsAndConditions, 'OnLink Terms and Conditions.pdf');
    }, []);

    const downloadButton = {
        callback: downloadTermsAndConditions,
        cssClasses: 'onlink-btn',
        title: translations.download
    };

    return [downloadButton];
}

export function TermsAndConditionsDialog(props) {
    const {
        closeDialog,
        translations
    } = props;

    const [numPages, setNumPages] = React.useState(null);

    const onDocumentLoadSuccess = React.useCallback(({numPages}) => {
        setNumPages(numPages);
    }, []);

    const renderPages = React.useCallback(() => Array.from({
        length: numPages
    }, (x, i) => (
        <Page
            key={i}
            pageNumber={i + 1}
            scale={1.25}
        />
    )), [numPages]);

    return (
        <BasicDialog
            className='terms-and-conditions-dialog'
            closeHandler={closeDialog}
            footerActions={footerActions(translations)}
            show={true}
            title={translations.my_team_terms_and_conditions}
        >
            <div className='terms-and-conditions-document'>
                <Document
                    file={termsAndConditions}
                    loading={
                        <LoadingIcon
                            className='table-loading-icon'
                            size='50px'
                        />
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {renderPages()}
                </Document>
            </div>
        </BasicDialog>
    );
}

TermsAndConditionsDialog.propTypes = {
    closeDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => {
            dispatch(closeDialog(dialogTypes.TERMS_AND_CONDITIONS_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(TermsAndConditionsDialog);
