// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import {useLazyRef} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {v4} from 'uuid';

function getLabelText(files, translations) {
    if (!files || files.length === 0) {
        return translations.NO_FILE_CHOSEN;
    }

    if (files.length === 1) {
        return files[0].name;
    }

    return replaceTranslationNames(translations.FILES_COUNT, {
        '0': files.length
    });
}

function FileInput(props) {
    const {
        className,
        files,
        icon,
        translations,
        ...otherProps
    } = props;

    const inputId = useLazyRef(() => v4());
    const labelText = getLabelText(files, translations);

    return (
        <div className='file-input-container'>
            <input
                {...otherProps}
                className='file-input-hidden'
                id={inputId.current}
                type='file'
            />
            <label
                className={joinClassNames('file-input', className)}
                htmlFor={inputId.current}
                title={labelText}
            >
                <div className='file-input-btn'>
                    {icon}
                    <span className='file-input-btn-text'>
                        {translations.CHOOSE_FILE}
                    </span>
                </div>
                <span className='file-input-text'>
                    {labelText}
                </span>
            </label>
        </div>
    );
}

FileInput.propTypes = {
    className: PropTypes.string,
    files: PropTypes.files,
    icon: PropTypes.node,
    translations: PropTypes.translations
};

export default FileInput;
