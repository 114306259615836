// Unpublished Work © 2021-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import React from 'react';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import {ENGINE_HOURS_REGEX, MAX_ENGINE_HOURS} from 'Ui/constants/equipment-constants';
import ValidationInput from 'Ui/components/common/form/validation-input';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {connect} from 'react-redux';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {addToast} from 'Store/actions/toasts';
import {updateEngineHours} from 'Services/equipment-service';
import {TOAST_TYPE} from '@deere/toast';
import {INTERNAL_SERVER_ERROR} from 'Common/constants/errors';
import {bulkUpdateEquipment} from 'Utils/equipment-utils';

function initializeState(workItemEquipment) {
    const [equipmentCollection, setEquipmentCollection] = React.useState(() => workItemEquipment.reduce((equipmentCollection, equipment) => {
        equipmentCollection[equipment.equipmentId] = {
            ...equipment,
            totalHours: ''
        };

        return equipmentCollection;
    }, {}));
    const [loading, setLoading] = React.useState(false);

    return {
        equipmentCollection,
        setEquipmentCollection,
        loading,
        setLoading
    };
}

function getFormContent(translations, equipmentCollection, setEquipmentCollection, setValid) {
    const valueGreaterThanOrEqual = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    });

    function onChange(event, equipment) {
        const {value} = event.target;
        const parsedValue = value && value.match(ENGINE_HOURS_REGEX)[0] || '';

        setEquipmentCollection((prevValues) => ({
            ...prevValues,
            [equipment.equipmentId]: {
                ...equipment,
                totalHours: parsedValue
            }
        }));
    }

    const equipmentArray = Object.values(equipmentCollection).map(
        (equipment) => {
            return (
                <div key={equipment.equipmentId}>
                    <div className='bold-body-text-section-title'>{equipment.equipmentName}</div>
                    <div
                        className='equipment-detail-break-bottom'
                    >{`${equipment.modelName} ${equipment.equipmentType}`}</div>
                    <ValidationInput
                        errors={{
                            rangeUnderflow: valueGreaterThanOrEqual,
                            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_ENGINE_HOURS}`
                        }}
                        label={translations.HOURS}
                        max={MAX_ENGINE_HOURS}
                        min={0}
                        name={equipment.equipmentId}
                        onChange={(event) => onChange(event, equipment)}
                        placeholder={equipment.equipmentTotalHours}
                        setValid={setValid}
                        tabIndex={0}
                        type='number'
                        value={equipment.totalHours}
                    />
                </div>
            );
        }
    );

    return (
        <form
            className={'settings-body work-item-engine-hour-confirmation'}
        >
            {equipmentArray}
        </form>
    );
}

function WorkItemEquipmentEngineHoursDialog(props) {
    const {
        addToast,
        invalidInputs,
        onClose,
        translations,
        workItemEquipment,
        setValid,
        isMigrated,
        membershipId
    } = props;

    const {
        equipmentCollection,
        setEquipmentCollection,
        loading,
        setLoading
    } = initializeState(workItemEquipment);

    function addErrorMessage(rejectedNames) {
        const message = translations.ONLINK_BULK_HOURS_ERROR.replace('{0}', rejectedNames);

        addToast({
            message,
            persist: true,
            type: TOAST_TYPE.ERROR
        });
    }

    async function onSave() {
        setLoading(true);

        const engineHours = Object.values(equipmentCollection).map((equipment) => {
            const {
                equipmentId,
                totalHours
            } = equipment;

            return {
                equipmentId,
                totalHours
            };
        });

        if (isMigrated) {
            const result = await updateEngineHours(engineHours, membershipId);

            setLoading(false);

            if (result === INTERNAL_SERVER_ERROR) {
                addToast({
                    message: translations.ERROR_OCCURRED_TITLE,
                    persist: true,
                    type: TOAST_TYPE.ERROR
                });
            } else {
                onClose();
            }
        } else {
            const rejectedResults = await bulkUpdateEquipment(engineHours);

            setLoading(false);

            if (rejectedResults.length !== 0) {
                const rejectedNames = rejectedResults.join(', ');

                addErrorMessage(rejectedNames);
            } else {
                onClose();
            }
        }
    }

    const equipmentArray = getFormContent(translations, equipmentCollection, setEquipmentCollection, setValid);
    const disableSaveButton = loading || invalidInputs.size > 0 ||
        Object.keys(equipmentCollection).every((key) => equipmentCollection[key].totalHours === '');

    return (
        <FormDialog
            addToast={addToast}
            cancelLabel={translations.ONLINK_SKIP}
            className={'onlink-dialog equipment-engine-hours-dialog'}
            closeHandler={onClose}
            disableSave={disableSaveButton}
            footerLoading={loading}
            onSave={onSave}
            saveLabel={translations.save}
            title={translations.ONLINK_UPDATE_ENGINE_HOURS}
            translations={translations}
        >
            {equipmentArray}
        </FormDialog>
    );
}

WorkItemEquipmentEngineHoursDialog.propTypes = {
    addToast: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    onClose: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    workItemEquipment: PropTypes.array
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        isMigrated: state.membership.isMigrated
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToast(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(WorkItemEquipmentEngineHoursDialog));
