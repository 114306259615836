// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import {createJobCategory, updateJobCategory} from 'Services/job-service';
import {useDeepMemo} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {useSave} from 'Ui/react-hooks/use-save';
import dialogTypes from 'Ui/components/common/dialog-types';

function isDuplicateJobCategoryTitle(jobCategoryIdsByTitle, jobCategoryTitle, jobCategory) {
    const jobCategoryIdForTitle = jobCategoryIdsByTitle.get(jobCategoryTitle?.toLowerCase());

    return Boolean(jobCategoryIdForTitle && jobCategoryIdForTitle !== jobCategory?.jobCategoryId);
}

function JobCategoryDialog(props) {
    const {
        closeDialog,
        invalidInputs,
        jobCategory,
        membershipId,
        membershipJobCategories,
        setValid,
        translations,
        updateJobCategories
    } = props;

    const [jobCategoryTitle, setJobCategoryTitle] = React.useState(() => jobCategory?.title || '');

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const jobCategoryPayload = {
            membershipId,
            title: jobCategoryTitle
        };

        jobCategory ?
            await updateJobCategory(jobCategory.jobCategoryId, jobCategoryPayload) :
            await createJobCategory(jobCategoryPayload);

        await updateJobCategories();

        closeDialog();
    }, {
        invalidInputs
    });

    const jobCategoryIdsByTitle = useDeepMemo(() => membershipJobCategories.reduce((jobCategoriesMap, membershipJobCategory) => {
        const {
            jobCategoryId,
            title
        } = membershipJobCategory;

        if (title) {
            jobCategoriesMap.set(title.toLowerCase(), jobCategoryId);
        }

        return jobCategoriesMap;
    }, new Map()), [membershipJobCategories]);

    const title = jobCategory ? translations.EDIT_TYPE : translations.ONLINK_ADD_ITEM;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={replaceTranslationNames(title, {
                '0': translations.ONLINK_JOB_CATEGORY
            })}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    debounceTimeout={window.props.debounceTimeout}
                    errors={{
                        valueMissing: translations.REQUIRED_FIELD_TEXT,
                        customError: translations.ONLINK_JOB_CATEGORY_NAME_EXISTS
                    }}
                    invalidCustomError={isDuplicateJobCategoryTitle(jobCategoryIdsByTitle, jobCategoryTitle, jobCategory)}
                    label={translations.ONLINK_JOB_CATEGORY}
                    name='jobCategoryTitle'
                    onChange={(event) => setJobCategoryTitle(event.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='text'
                    value={jobCategoryTitle}
                />
            </form>
        </FormDialog>
    );
}

JobCategoryDialog.propTypes = {
    closeDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    jobCategory: PropTypes.object,
    membershipId: PropTypes.string,
    membershipJobCategories: PropTypes.arrayOf(PropTypes.object),
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateJobCategories: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.JOB_CATEGORY_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(JobCategoryDialog));
