// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import SidebarHeader from 'Ui/components/map/sidebar/common/sidebar-header';
import SidebarLayout from 'Ui/components/map/sidebar/common/sidebar-layout';
import moment from 'moment';

function SidebarList(props) {
    const {
        appliedFilters,
        children,
        dateSelected,
        emptyMessage,
        loading,
        membership,
        onSearchChange,
        search,
        setDate,
        setIsFilterVisible,
        showDateFilter,
        showFilter,
        translations
    } = props;

    const sidebarRef = React.useRef();

    React.useEffect(() => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollTop = 0;
        }
    }, [dateSelected, membership.membershipId]);

    const header = (
        <SidebarHeader
            appliedFilters={appliedFilters}
            dateSelected={dateSelected}
            onFilterClick={setIsFilterVisible}
            onSearchChange={onSearchChange}
            search={search}
            setDate={setDate}
            showDateFilter={showDateFilter}
            showFilter={showFilter}
            translations={translations}
        />
    );

    return (
        <SidebarLayout
            header={header}
            loading={loading}
            sidebarRef={sidebarRef}
            translations={translations}
        >
            <div className='map-sidebar-list'>
                {
                    React.Children.count(children) ?
                        children :
                        <div className='empty-message'>{emptyMessage}</div>
                }
            </div>
        </SidebarLayout>
    );
}

SidebarList.propTypes = {
    appliedFilters: PropTypes.appliedFilters,
    children: PropTypes.node,
    dateSelected: PropTypes.instanceOf(moment),
    emptyMessage: PropTypes.string,
    loading: PropTypes.bool,
    membership: PropTypes.membership,
    onSearchChange: PropTypes.func,
    search: PropTypes.string,
    setDate: PropTypes.func,
    setIsFilterVisible: PropTypes.func,
    showDateFilter: PropTypes.bool,
    showFilter: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership
    };
}

export default connect(mapStateToProps)(SidebarList);
