// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {useDeepMemo} from 'Utils/react-utils';
import {SUPPORTED_COUNTRY_CODES} from '@deere/onlink-shared-constants';
import {uniqBy} from 'lodash';

const SHARED_MULTISELECT_PROPS = {
    component: MultiSelect,
    noSearch: true,
    single: true
};

function AddressFieldInputs(props) {
    const {
        addressElements,
        addressFields,
        country,
        isRequired,
        loading,
        onAddressElementChange,
        onInputChange,
        setValid,
        translations
    } = props;

    function onMultiSelectChange(name, value) {
        onInputChange({
            target: {
                name,
                value
            }
        });
    }

    const countryDropdownItems = useDeepMemo(() => SUPPORTED_COUNTRY_CODES.map((supportedCountry) => ({
        id: supportedCountry.code,
        title: translations[supportedCountry.translationKey]
    })), [translations]);

    function getDynamicAddressFields() {
        return (
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size='50px'
            >
                <div className='settings-group horizontal'>
                    <ValidationMultiSelect
                        {...SHARED_MULTISELECT_PROPS}
                        items={countryDropdownItems}
                        label={translations.organizationSetup_country}
                        labels={{}}
                        name='country'
                        onChange={([selectedId]) => onMultiSelectChange('country', selectedId)}
                        required={isRequired}
                        selectedIds={[country]}
                        setValid={setValid}
                    />
                </div>
                {
                    addressFields.map((addressField) => {
                        const {
                            addressFormatElementClass,
                            dropDownElements,
                            mandatory,
                            name
                        } = addressField;

                        const {[addressFormatElementClass]: addressElement} = addressElements;
                        const sharedProps = {
                            label: name,
                            name: addressFormatElementClass,
                            required: isRequired && mandatory,
                            setValid
                        };

                        if (dropDownElements?.length) {
                            const dropDownElementItems = dropDownElements.map((dropDownElement) => ({
                                id: dropDownElement.abbreviation || dropDownElement.name,
                                title: dropDownElement.name
                            }));

                            return (
                                <div
                                    className='settings-group horizontal'
                                    key={addressFormatElementClass}
                                >
                                    <ValidationMultiSelect
                                        {...sharedProps}
                                        {...SHARED_MULTISELECT_PROPS}
                                        items={uniqBy(dropDownElementItems, 'id')}
                                        labels={{}}
                                        onChange={([selectedId]) => onAddressElementChange(addressFormatElementClass, selectedId)}
                                        selectedIds={[addressElement]}
                                    />
                                </div>
                            );
                        }

                        return (
                            <ValidationInput
                                {...sharedProps}
                                debounceTimeout={window.props.debounceTimeout}
                                errors={{
                                    valueMissing: translations.REQUIRED_FIELD_TEXT
                                }}
                                key={addressFormatElementClass}
                                onChange={(event) => onAddressElementChange(event.target.name, event.target.value)}
                                tabIndex={0}
                                type='text'
                                value={addressElement}
                            />
                        );
                    })
                }
            </LoadingWrapper>
        );
    }

    return getDynamicAddressFields();
}

AddressFieldInputs.defaultProps = {
    isRequired: false
};

AddressFieldInputs.propTypes = {
    address1: PropTypes.string,
    address2: PropTypes.string,
    addressElements: PropTypes.object,
    addressFields: PropTypes.arrayOf(PropTypes.object),
    city: PropTypes.string,
    country: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    isRequired: PropTypes.bool,
    loading: PropTypes.bool,
    onAddressElementChange: PropTypes.func,
    onInputChange: PropTypes.func,
    setValid: PropTypes.func,
    state: PropTypes.string,
    translations: PropTypes.translations,
    zip: PropTypes.string
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(AddressFieldInputs);
