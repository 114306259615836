// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiSelect} from '@deere/form-controls';
import {IconEdit} from '@deere/icons';
import {DatePicker, DropdownMenu} from '@deere/isg.component-library';
import Stack from '@mui/material/Stack';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import TodoList from 'OnLabor/workboard/common/todo-list';
import WorkBoardWeather from 'OnLabor/my-work/work-board-tile/work-board-weather';
import {openDialog} from 'Store/actions/dialogs';
import {updateWorkboardLayers} from 'Store/actions/workboard';
import {createIconFillStyle} from 'Utils/icon-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatLocalizedTime, WEEKDAY_AND_NUMERIC_DATE_OPTIONS} from 'Utils/time-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {getPresentationUrl} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import {useToggledMemo} from 'Ui/react-hooks/use-toggled-memo';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import dialogTypes from 'Ui/components/common/dialog-types';
import moment from 'moment';
import {MANAGE_TIMEOFF, MANAGE_WORKBOARDS, VIEW_WORKBOARDS} from 'Common/constants/business-activities';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

function onLayersSelectionChange(layers, selectedIds, updateLayersState) {
    const newLayers = selectedIds.reduce((layersSelected, selectedId) => {
        layersSelected[selectedId] = true;

        return layersSelected;
    }, {});

    updateLayersState(newLayers);
}

function getWorkboardsForMobile(
    dateValue,
    fetchWorkboards,
    layers,
    openAddWorkboardDialog,
    workboards
) {
    const filteredWorkboards = workboards.filter((workboard) => workboard.dateSelect === dateValue);

    return layers.showWorkboards && filteredWorkboards?.length > 0 && (
        <>
            <div className='title-with-underline'/>
            {
                filteredWorkboards.map((workboardData) => (
                    <div
                        className='sidebar-group horizontal'
                        key={`workboard-${workboardData.id}`}
                    >
                        <span className='workboard-item'>
                            {workboardData.title}
                        </span>
                        <OnLinkIconButton
                            onClick={() => openAddWorkboardDialog({
                                onClose: fetchWorkboards,
                                workboards,
                                workboardId: workboardData.id
                            })}
                        >
                            <IconEdit
                                iconEdit={ICON_EDIT_STYLE}
                                primary={createIconFillStyle('#333')}
                            />
                        </OnLinkIconButton>
                    </div>
                ))
            }
        </>
    );
}

function getTimeOffLayerData(
    dateValue,
    layers,
    availabilityByDate
) {
    const filteredUsers = availabilityByDate.filter((user) => user.start === dateValue);

    return layers.showTimeOff && filteredUsers?.length > 0 && (
        <>
            <div className='title-with-underline'/>
            {
                filteredUsers.map((availability) => (
                    <span
                        className='workboard-item'
                        key={availability.title}
                    >
                        {availability.title}
                    </span>
                ))
            }
        </>
    );
}

function findWeatherForTheDay(formattedDate, weather) {
    const selectedDateWeather = weather?.find((dayData) =>
        dayData?.forecast?.fcst_valid_local && moment(dayData.forecast.fcst_valid_local).format(DATE_FORMATS.day) === formattedDate);

    if (selectedDateWeather?.forecast) {
        return (
            <div className='work-board-weather'>
                <img
                    alt={selectedDateWeather.forecast.icon_code.toString()}
                    className='workboard-weather-icon'
                    src={getWeatherIcon(selectedDateWeather.forecast.icon_code)}
                />
                {selectedDateWeather.title}
            </div>
        );
    }

    return null;
}

function getDropdownMenuItems(openWorkboardPreviewDialog, openAddWorkboardDialog, openAddTimeOffDialog, workboards,
    fetchWorkboards, fetchAvailabilityByDate, translations, membershipId, featureToggles, loading, isMigrated, myJdPermissions) {
    const navbarActions = [];

    if (!isMigrated || myJdPermissions[VIEW_WORKBOARDS]) {
        navbarActions.push({
            title: translations.ONLINK_VIEW_WORKBOARD,
            onClick() {
                openWorkboardPreviewDialog({
                    previewUrl: getPresentationUrl(membershipId, featureToggles),
                    translations
                });
            }
        });
    }

    if (!isMigrated || myJdPermissions[MANAGE_WORKBOARDS]) {
        navbarActions.push({
            disabled: loading.workboards,
            title: translations.ONLINK_ADD_WORKBOARD,
            onClick() {
                openAddWorkboardDialog({
                    onClose: fetchWorkboards,
                    workboards
                });
            }
        });
    }

    if (!isMigrated || myJdPermissions[MANAGE_TIMEOFF]) {
        navbarActions.push({
            title: translations.ONLINK_ADD_TIME_OFF,
            onClick() {
                openAddTimeOffDialog({
                    onClose: fetchAvailabilityByDate
                });
            }
        });
    }

    return navbarActions;
}

function getCalendarLayerToggles(translations, isMigrated, myJdPermissions) {
    const calendarLayerToggles = [
        {
            title: translations.ONLINK_WORKBOARDS,
            id: 'showWorkboards'
        }
    ];

    if (!isMigrated || myJdPermissions[MANAGE_TIMEOFF]) {
        calendarLayerToggles.push({
            title: translations.ONLINK_TIME_OFF,
            id: 'showTimeOff'
        });
    }

    return calendarLayerToggles;
}

function CalendarMobile(props) {
    const {
        appUserId,
        availabilityByDate,
        dateValue,
        featureToggles,
        fetchAvailabilityByDate,
        fetchWorkboards,
        isMigrated,
        layers,
        loading,
        membershipId,
        myJdPermissions,
        openAddTimeOffDialog,
        openAddWorkboardDialog,
        openWorkboardPreviewDialog,
        setDateValue,
        setStartAndEndTime,
        todoTemplates,
        translations,
        updateLayersState,
        weather,
        weatherRef,
        workboards
    } = props;

    const dateMoment = moment(dateValue);
    const formattedDateValue = dateMoment.format(DATE_FORMATS.day);

    const selectedLayers = useDeepMemo(() => {
        return Object.entries(layers)
            .filter(([, enabled]) => enabled === true)
            .map(([layerId]) => layerId);
    }, [layers]);

    const stackProps = useToggledMemo({
        disabledCallback: () => ({}),
        enabledCallback: () => ({
            bgcolor: '#fff',
            border: '1px solid',
            borderColor: 'grey.200'
        }),
        toggle: ONLINK_NAVIGATION_REDESIGN
    });

    return (
        <Stack
            {...stackProps}
            className='workboard-container'
            direction='column'
            flex={1}
            paddingX='12px'
            sx={{
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Stack
                alignItems='flex-start'
                className='mobile-group'
                direction='row'
                justifyContent='space-between'
                padding='5px'
            >
                <MultiSelect
                    allItemsLabel={translations.ONLINK_ALL_LAYERS}
                    className='multiselect hide-all-option layers-multiselect'
                    defaultSelectLabel={translations.ONLINK_SELECT_LAYER}
                    hideFilter={true}
                    items={getCalendarLayerToggles(translations, isMigrated, myJdPermissions)}
                    multiple={true}
                    multipleItemsSelectedLabel={translations.ONLINK_LAYERS}
                    onChange={(selectedIds) => onLayersSelectionChange(layers, selectedIds, updateLayersState)}
                    selectedIds={selectedLayers}
                    tabIndex={0}
                />
                {
                    !featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
                    <DropdownMenu
                        buttonTitle={translations.ACTIONS}
                        className='onlink-action-dropdown'
                        items={
                            getDropdownMenuItems(
                                openWorkboardPreviewDialog, openAddWorkboardDialog, openAddTimeOffDialog, workboards,
                                fetchWorkboards, fetchAvailabilityByDate, translations, membershipId, featureToggles,
                                loading, isMigrated, myJdPermissions
                            )
                        }
                    />
                }
            </Stack>
            <DatePicker
                InputProps={{
                    inputProps: {
                        hidden: true
                    },
                    labelVisuallyHidden: true,
                    type: 'hidden'
                }}
                inline={true}
                onChange={([selectedDate]) => {
                    setDateValue(selectedDate);

                    const formattedDate = moment(selectedDate).format(DATE_FORMATS.day);

                    setStartAndEndTime({
                        start: formattedDate,
                        end: formattedDate
                    });
                }}
                value={dateValue}
            />
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading.workboards || loading.availability}
                size='50px'
            >
                <div className='sidebar-group horizontal mobile-group weather'>
                    {formatLocalizedTime(dateMoment, WEEKDAY_AND_NUMERIC_DATE_OPTIONS)}
                    {findWeatherForTheDay(formattedDateValue, weather)}
                </div>
                {
                    dateMoment.isSame(new Date(), 'day') && weatherRef.current &&
                    <WorkBoardWeather
                        date={formattedDateValue}
                        weather={weatherRef.current}
                    />
                }
                {getWorkboardsForMobile(formattedDateValue, fetchWorkboards, layers, openAddWorkboardDialog, workboards)}
                {getTimeOffLayerData(formattedDateValue, layers, availabilityByDate)}
            </LoadingWrapper>
            <TodoList
                appUserId={appUserId}
                featureToggles={featureToggles}
                membershipId={membershipId}
                todoTemplates={todoTemplates}
                translations={translations}
            />
        </Stack>
    );
}

CalendarMobile.propTypes = {
    appUserId: PropTypes.string,
    availabilityByDate: PropTypes.arrayOf(PropTypes.object),
    dateValue: PropTypes.instanceOf(Date),
    featureToggles: PropTypes.featureToggles,
    fetchAvailabilityByDate: PropTypes.func,
    fetchWorkboards: PropTypes.func,
    isMigrated: PropTypes.bool,
    layers: PropTypes.object,
    loading: PropTypes.object,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openAddTimeOffDialog: PropTypes.func,
    openAddWorkboardDialog: PropTypes.func,
    openWorkboardPreviewDialog: PropTypes.func,
    setDateValue: PropTypes.func,
    setStartAndEndTime: PropTypes.func,
    todoTemplates: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    updateLayersState: PropTypes.func,
    weather: PropTypes.arrayOf(PropTypes.object),
    weatherRef: PropTypes.reference,
    workboards: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        appUserId: state.account.appUserId,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openWorkboardPreviewDialog(props) {
            dispatch(openDialog(dialogTypes.WORKBOARD_PREVIEW_DIALOG, props));
        },
        updateLayersState(props) {
            dispatch(updateWorkboardLayers(props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMobile);
