// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Input} from '@deere/form-controls';
import {isEmptyString} from 'Common/utils/validation-utils';
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const URL_REGEX = /^[^:/?#]+:\/\/[^/?#]+[^?#]*(?:\?[^#]*)?(?:#.*)?/;

function isTypeMismatch(type, value) {
    switch (type) {
        case 'email':
            return !EMAIL_REGEX.test(value);

        case 'url':
            return !URL_REGEX.test(value);

        default:
            return false;
    }
}

function getInputValidity(inputProps) {
    const {
        inputProps: componentLibraryInputProps,
        invalidCustomError,
        max,
        maxLength,
        min,
        minLength,
        pattern,
        required,
        step,
        type,
        value
    } = inputProps;

    const componentLibraryMax = componentLibraryInputProps?.max;
    const componentLibraryMin = componentLibraryInputProps?.min;

    const valueMissing = isEmptyString(value);

    return {
        customError: invalidCustomError,
        patternMismatch: !valueMissing && pattern && !new RegExp(pattern).test(value),
        rangeOverflow: !valueMissing && (value > max || value > componentLibraryMax),
        rangeUnderflow: !valueMissing && (value < min || value < componentLibraryMin),
        stepMismatch: !valueMissing && step && step !== 'any' && value % step !== 0,
        tooLong: !valueMissing && value.length > maxLength,
        tooShort: !valueMissing && value.length < minLength,
        typeMismatch: !valueMissing && isTypeMismatch(type, value),
        valueMissing: valueMissing && required
    };
}

function ValidationInput(props) {
    const {
        component: Component,
        errors,
        invalidCustomError,
        setValid,
        ...inputProps
    } = props;

    const {
        max,
        maxLength,
        min,
        minLength,
        name,
        pattern,
        required,
        step,
        type,
        value
    } = inputProps;

    const [validity, setValidity] = React.useState(() => getInputValidity(props));

    React.useEffect(() => {
        const inputValidity = getInputValidity(props);
        const valid = Object.keys(inputValidity).every((key) => !inputValidity[key]);

        setValid(name, valid);
        setValidity(inputValidity);

        return () => {
            setValid(name, true);
        };
    }, [invalidCustomError, max, maxLength, min, minLength, pattern, required, step, type, value]);

    const errorKey = Object.keys(errors).find((key) => validity[key]);
    const input = (
        <Component
            {...inputProps}
            error={errors[errorKey] || null}
            helperText={errors[errorKey] || null}
        />
    );

    if (required) {
        return (
            <div className='required-input'>
                {input}
            </div>
        );
    }

    return input;
}

ValidationInput.defaultProps = {
    component: Input,
    errors: {}
};

ValidationInput.propTypes = {
    component: PropTypes.elementType,
    errors: PropTypes.errors,
    invalidCustomError: PropTypes.bool,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    minLength: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    pattern: PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp),
        PropTypes.string
    ]),
    required: PropTypes.bool,
    setValid: PropTypes.func,
    step: PropTypes.stringOrNumber,
    type: PropTypes.string,
    value: PropTypes.stringOrNumber
};

export default ValidationInput;
