// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {BasicDialog} from '@deere/basic-dialog';
import {connect} from 'react-redux';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function footerActions(props) {
    const continueButton = {
        callback: props.onContinue,
        cssClasses: 'onlink-btn primary',
        disabled: props.disabled,
        loading: props.showLoadingIcon && props.disabled,
        title: props.continueTitle ? props.continueTitle : props.translations.yes
    };
    const cancelButton = {
        callback: props.onCancel,
        cssClasses: 'onlink-btn',
        disabled: props.disabled,
        title: props.cancelTitle ? props.cancelTitle : props.translations.no
    };

    return [cancelButton, continueButton];
}

function ConfirmationDialog(props) {
    const [disabled, setDisabled] = React.useState(false);

    const footerActionProps = {
        ...props,
        disabled,
        onContinue() {
            setDisabled(true);
            props.onContinue();
        }
    };

    return (
        <BasicDialog
            className='confirmation-dialog'
            closeHandler={props.onCancel}
            footerActions={footerActions(footerActionProps)}
            show={true}
            title={props.title}
        >
            <LoadingWrapper
                className='table-loading-icon'
                loading={disabled}
                size='50px'
            >
                {props.message}
            </LoadingWrapper>
        </BasicDialog>
    );
}

ConfirmationDialog.defaultProps = {
    showLoadingIcon: false
};

ConfirmationDialog.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    onCancel: PropTypes.func,
    onContinue: PropTypes.func,
    showLoadingIcon: PropTypes.bool,
    title: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export default connect(mapStateToProps)(ConfirmationDialog);
