// Unpublished Work © 2020-2024 Deere & Company.

function asNumber(value) {
    if (value === null || value === '' || isNaN(value)) {
        return null;
    }

    return Number(value);
}

export {
    asNumber
};
