// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {ONLINK_CREATION_FLOW} from 'Common/constants/feature-toggles';
import PresentationJobView from 'Ui/features/onlabor/workboards/presentation/presentation-job-view';
import PresentationEmployeeView from 'Ui/features/onlabor/workboards/presentation/presentation-employee-view';
import {groupBy} from 'lodash';

import {JOBS} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {USER} from 'Ui/constants/workboard-constants';
import {sortByEmployeeWorkItemsBySeq, sortWorkItemsByUserOrder} from 'OnLabor/workboard/utils/workboard-sort';

function getCorrectComponent(workItems, presentationSettings, featureToggles, workboard) {
    const byUser = Object.values(groupBy(workItems, 'appUserId'));
    const byJob = presentationSettings.grouping === JOBS;

    if (featureToggles[ONLINK_CREATION_FLOW] && workboard.creationFlow === USER) {
        const sortedWorkItemsByUser = sortByEmployeeWorkItemsBySeq(byUser, workboard.userOrder);

        if (byJob) {
            const sortedWorkItems = sortWorkItemsByUserOrder(workItems, workboard.userOrder);

            return (
                <PresentationJobView
                    properties={presentationSettings}
                    workItems={sortedWorkItems}
                    workItemsByEmployee={sortedWorkItemsByUser}
                />
            );
        }

        return (
            <PresentationEmployeeView
                properties={presentationSettings}
                workItemsByEmployee={sortedWorkItemsByUser}
            />
        );
    }

    if (byJob) {
        return (
            <PresentationJobView
                properties={presentationSettings}
                workItems={workItems}
                workItemsByEmployee={byUser}
            />
        );
    }
    return (
        <PresentationEmployeeView
            properties={presentationSettings}
            workItemsByEmployee={byUser}
        />
    );
}

function JobsContainer(props) {
    const {
        featureToggles,
        workboard,
        workItems,
        presentationSettings
    } = props;

    return getCorrectComponent(workItems, presentationSettings, featureToggles, workboard);
}

JobsContainer.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.object,
    translations: PropTypes.translations,
    workboard: PropTypes.object
};

export default JobsContainer;
