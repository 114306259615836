// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MediaQuery from 'Ui/components/higher-order-components/media-query';
import {MultiSelect} from '@deere/isg.component-library';
import {Input, TextArea} from '@deere/form-controls';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import EquipmentMultiSelect from 'Ui/features/workboard-wizard/work-item/equipment-multiselect';
import DurationInput from 'OnLabor/workboard/common/duration-input';
import HoleInput from 'OnLabor/workboard/common/hole-input';
import {CUSTOM_ROUTE, DEFAULT_TRAVEL_ROUTES} from 'Ui/constants/workboard-constants';
import {ASSIGNMENT_MOBILE_MEDIA_QUERY} from 'Ui/features/workboard-wizard/constants/assignment-media-query';
import {WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {useDeepMemo} from 'Utils/react-utils';
import Typography from '@mui/material/Typography';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

const ONE_THIRD_COLUMN_SIZE = 4;
const HALF_COLUMN_SIZE = 6;
const TWO_THIRDS_COLUMN_SIZE = ONE_THIRD_COLUMN_SIZE * 2;
const FULL_COLUMN_SIZE = HALF_COLUMN_SIZE * 2;

const READONLY_NOTE_LABEL_HEIGHT = 20.02;
const READONLY_NOTE_LABEL_MARGIN = 2;

function createEquipmentMultiSelects({
    eligibleEquipmentTypes,
    equipmentAreaId,
    equipmentByType,
    equipmentInUseMap,
    onEquipmentChange,
    translations,
    useColumnLayout,
    readOnly,
    workItem: {
        appUserId,
        equipment = [],
        workItemId
    }
}) {
    const translatedHours = translations.HOURS.toLowerCase();
    const columnCount = useColumnLayout || equipment.length === 1 ? FULL_COLUMN_SIZE : HALF_COLUMN_SIZE;

    return equipment.map((equipmentType, equipmentTypeIndex) =>
        !readOnly ? <EquipmentMultiSelect
            appUserId={appUserId}
            columnCount={columnCount}
            eligibleEquipmentTypes={eligibleEquipmentTypes}
            equipmentAreaId={equipmentAreaId}
            equipmentByType={equipmentByType}
            equipmentId={equipmentType.equipmentId}
            equipmentInUseMap={equipmentInUseMap}
            equipmentType={equipmentType.equipmentType}
            equipmentTypeId={equipmentType.equipmentTypeId}
            equipmentTypeIndex={equipmentTypeIndex}
            hoursLabel={translatedHours}
            key={`${equipmentType.equipmentType}-${equipmentType.equipmentTypeId}`}
            onChange={onEquipmentChange}
            translations={translations}
            useColumnLayout={useColumnLayout}
            workItemId={workItemId}
        /> : <Grid
            item={true}
            key={`${equipmentType.equipmentType}-${equipmentType.equipmentTypeId}`}
            xs={columnCount}
        >
            <div className={'title-1'}>{equipmentType.equipmentType}</div>
            {equipmentType.equipmentName ?
                <div className={'title'}>{equipmentType.equipmentName}</div> :
                <Typography color='warning.main'>
                    {translations.ASSIGN_EQUIPMENT}
                </Typography>
            }
        </Grid>
    );
}

function calculateGridSizes(equipment = []) {
    switch (equipment.length) {
        case 0:
            return {
                childrenSize: HALF_COLUMN_SIZE,
                routeMultiSelectSize: 5,
                equipmentMultiSelectSize: 0
            };
        case 1:
            return {
                childrenSize: ONE_THIRD_COLUMN_SIZE,
                routeMultiSelectSize: 3,
                equipmentMultiSelectSize: ONE_THIRD_COLUMN_SIZE
            };
        default:
            return {
                childrenSize: 3,
                routeMultiSelectSize: 2,
                equipmentMultiSelectSize: HALF_COLUMN_SIZE
            };
    }
}

function WorkItemInput(props) {
    const {
        children,
        collapseButton,
        deleteButton,
        eligibleEquipmentTypes,
        equipmentAreaId,
        equipmentByType,
        equipmentInUseMap,
        featureToggles,
        isAssignmentMobile,
        isMultiline,
        noteHeight,
        onActHoursChange,
        onChange,
        onEquipmentChange,
        onEstHoursChange,
        orderButton,
        readOnly,
        translations,
        workItem
    } = props;

    const travelRoutes = useDeepMemo(() => DEFAULT_TRAVEL_ROUTES.map((travelRoute) => ({
        id: travelRoute.id,
        title: translations[travelRoute.title]
    })), [translations]);

    const useColumnLayout = React.useMemo(
        () => isAssignmentMobile || !featureToggles[WORKBOARD_REFACTOR],
        [featureToggles[WORKBOARD_REFACTOR], isAssignmentMobile]
    );

    const {
        childrenSize,
        routeMultiSelectSize,
        equipmentMultiSelectSize
    } = React.useMemo(() => calculateGridSizes(workItem.equipment), [workItem.equipment?.length]);

    const equipmentMultiSelects = createEquipmentMultiSelects({
        eligibleEquipmentTypes,
        equipmentAreaId,
        equipmentByType,
        equipmentInUseMap,
        onEquipmentChange,
        translations,
        useColumnLayout,
        readOnly,
        workItem
    });

    const hoursInputs = (
        <Grid
            container={true}
            direction='row'
            item={true}
            spacing={2}
            xs={3}
        >
            <Grid
                item={true}
                xs={HALF_COLUMN_SIZE}
            >
                <DurationInput
                    fullWidth={true}
                    label={translations.ONLINK_EST_HRS}
                    onChange={onEstHoursChange}
                    readOnly={readOnly}
                    tabIndex={1}
                    value={workItem.estDuration}
                />
            </Grid>
            <Grid
                item={true}
                xs={HALF_COLUMN_SIZE}
            >
                <DurationInput
                    fullWidth={true}
                    label={translations.ONLINK_ACT_HRS}
                    onChange={onActHoursChange}
                    readOnly={readOnly}
                    tabIndex={2}
                    value={workItem.actualDuration}
                />
            </Grid>
        </Grid>
    );

    const {
        customRouteWidth,
        routeInputsWidth,
        ...stackContainerProps
    } = React.useMemo(() => readOnly ? {
        customRouteWidth: HALF_COLUMN_SIZE,
        routeInputsWidth: HALF_COLUMN_SIZE
    } : {
        customRouteWidth: ONE_THIRD_COLUMN_SIZE,
        height: '100%',
        routeInputsWidth: TWO_THIRDS_COLUMN_SIZE
    }, [readOnly]);

    const routeInputs = (
        <Grid
            container={true}
            direction='row'
            item={true}
            marginBottom='auto'
            spacing={2}
            xs={routeMultiSelectSize}
        >
            <Grid
                item={true}
                xs={useColumnLayout ? routeInputsWidth : FULL_COLUMN_SIZE}
            >
                <ReadOnlyWrapperComponent
                    label={translations.ONLINK_ROUTE}
                    readOnly={readOnly}
                    readOnlyProps={{
                        readOnlyLabelClassName: 'title-1',
                        readOnlyValueClassName: 'readonly-route',
                        value: workItem.route
                    }}
                    width={175}
                    wrappedComponent={MultiSelect}
                    wrappedProps={{
                        autoFocus: true,
                        disableSorting: true,
                        items: travelRoutes,
                        labels: {
                            placeholder: translations.ONLINK_SELECT_ROUTE
                        },
                        onChange: ([id]) => onChange('route', id),
                        selectedIds: [workItem.route],
                        single: true
                    }}
                />
            </Grid>
            {
                workItem.route === CUSTOM_ROUTE &&
                <Grid
                    item={true}
                    xs={useColumnLayout ? customRouteWidth : FULL_COLUMN_SIZE}
                >
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_HOLES}
                        readOnly={readOnly}
                        readOnlyProps={{
                            readOnlyLabelClassName: 'title-1',
                            value: `${workItem.routeCustomDefn?.length || 0} ${translations.ONLINK_HOLES}`
                        }}
                        wrappedComponent={HoleInput}
                        wrappedProps={{
                            onChange: (ids) => onChange('routeCustomDefn', ids),
                            routeCustomDefn: workItem.routeCustomDefn,
                            translations
                        }}
                    />
                </Grid>
            }
        </Grid>
    );

    const {
        NoteInput,
        readOnlyValueClassName
    } = React.useMemo(() => isMultiline ? {
        NoteInput: TextArea,
        readOnlyValueClassName: 'multiline-readonly-value'
    } : {
        NoteInput: Input,
        readOnlyValueClassName: 'readonly-workboard-note'
    }, [isMultiline]);

    const baseGrid = (
        <Stack
            direction='column'
            flex='1'
            overflow='hidden'
        >
            <Grid
                columns={14}
                container={true}
                direction={useColumnLayout ? 'column' : 'row'}
                overflow='hidden'
                spacing={2}
                wrap='nowrap'
            >
                <Grid
                    item={true}
                    xs={childrenSize}
                >
                    {children}
                </Grid>
                {routeInputs}
                {
                    equipmentMultiSelects.length > 0 &&
                    <Grid
                        container={true}
                        direction='row'
                        item={true}
                        spacing={2}
                        xs={equipmentMultiSelectSize}
                    >
                        {equipmentMultiSelects}
                    </Grid>
                }
                {hoursInputs}
            </Grid>
            <ReadOnlyWrapperComponent
                label={translations.NOTE}
                readOnly={readOnly}
                readOnlyProps={{
                    readOnlyLabelProps: {
                        height: `${READONLY_NOTE_LABEL_HEIGHT}px`
                    },
                    readOnlyValueProps: {
                        height: `${noteHeight - READONLY_NOTE_LABEL_HEIGHT - READONLY_NOTE_LABEL_MARGIN}px`
                    },
                    readOnlyValueClassName,
                    height: `${noteHeight}px`
                }}
                value={workItem.note}
                wrappedComponent={NoteInput}
                wrappedProps={{
                    style: {
                        height: `${noteHeight}px`
                    },
                    autoComplete: 'note',
                    debounceTimeout: window.props.debounceTimeout,
                    fullWidth: true,
                    name: 'note',
                    allowResize: false,
                    onChange: (event) => {
                        onChange('note', event.target.value);
                    },
                    tabIndex: 3
                }}
            />
        </Stack>
    );

    return (
        <Stack
            bgcolor='background.default'
            borderRadius='5px'
            className='work-item-input'
            direction='column'
            id={workItem.workItemId}
            padding='8px'
            spacing={2}
            {...stackContainerProps}
        >
            {
                useColumnLayout ? (
                    <>
                        <Stack
                            className='work-item-content'
                            direction='row'
                        >
                            {!readOnly && orderButton}
                            {collapseButton}
                        </Stack>
                        {baseGrid}
                        {!readOnly && deleteButton}
                    </>
                ) : (
                    <>
                        <Stack
                            className='work-item-content'
                            direction='row'
                        >
                            {!readOnly && orderButton}
                            {baseGrid}
                            {collapseButton}
                        </Stack>
                        {!readOnly && deleteButton}
                    </>
                )
            }
        </Stack>
    );
}

WorkItemInput.propTypes = {
    children: PropTypes.node,
    collapseButton: PropTypes.node,
    deleteButton: PropTypes.node,
    eligibleEquipmentTypes: PropTypes.arrayOf(PropTypes.object),
    equipmentAreaId: PropTypes.string,
    equipmentByType: PropTypes.instanceOf(Map),
    equipmentInUseMap: PropTypes.reference,
    featureToggles: PropTypes.featureToggles,
    isAssignmentMobile: PropTypes.bool,
    isMultiline: PropTypes.bool,
    noteHeight: PropTypes.number,
    onActHoursChange: PropTypes.func,
    onChange: PropTypes.func,
    onEquipmentChange: PropTypes.func,
    onEstHoursChange: PropTypes.func,
    orderButton: PropTypes.node,
    readOnly: PropTypes.bool,
    translations: PropTypes.translations,
    workItem: PropTypes.object
};

export default MediaQuery(ASSIGNMENT_MOBILE_MEDIA_QUERY)(WorkItemInput);
