// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {redirect} from 'Utils/redirect-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {MY_JOHN_DEERE, ONLINK} from 'Ui/constants/replace-constants';

function LinkReminder(props) {
    const {
        redirectParam,
        translations
    } = props;

    return (
        <div className='link-profile-prompt'>
            <div className='prompt-message'>
                {
                    replaceTranslationNames(translations.ONLINK_MYJD_REMINDER_TEXT_1, {
                        '1': MY_JOHN_DEERE
                    })
                }
            </div>
            <div className='prompt-message'>
                {
                    replaceTranslationNames(translations.ONLINK_MYJD_REMINDER_TEXT_2, {
                        '0': ONLINK,
                        '1': MY_JOHN_DEERE
                    })
                }
            </div>
            <div className='prompt-actions'>
                <OnlinkButton onClick={() => redirect(`/login-redirect${redirectParam}`)}>
                    {translations.no}
                </OnlinkButton>
                <OnlinkButton
                    className='primary'
                    onClick={() => redirect(`/myjd-login${redirectParam}`)}
                >
                    {translations.yes}
                </OnlinkButton>
            </div>
        </div>
    );
}

LinkReminder.propTypes = {
    redirectParam: PropTypes.string,
    translations: PropTypes.translations
};

export default LinkReminder;
