// Unpublished Work © 2023-2024 Deere & Company.

import {FUEL, GAS, TOTAL_WATER_IRRIGATION, WATER_FLOW, WATER_TRANSFER} from 'Common/constants/data-group-constants';
import {
    DEGREES_C, DEGREES_F, FEET, GALLONS, INCHES, KILOGRAMS, KILOMETERS_PER_HOUR,
    LITERS, METERS, MILES_PER_HOUR, MILLIMETERS, POUNDS, QUARTS
} from 'Common/constants/data-unit-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';

const FAHRENHEIT_CELSIUS_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: DEGREES_F,
    [UNIT_OF_MEASURE.METRIC]: DEGREES_C
};
const FEET_METERS_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: FEET,
    [UNIT_OF_MEASURE.METRIC]: METERS
};
const GALLONS_LITERS_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: GALLONS,
    [UNIT_OF_MEASURE.METRIC]: LITERS
};
const INCHES_MILLIMETERS_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: INCHES,
    [UNIT_OF_MEASURE.METRIC]: MILLIMETERS
};
const MPH_KPH_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: MILES_PER_HOUR,
    [UNIT_OF_MEASURE.METRIC]: KILOMETERS_PER_HOUR
};
const POUNDS_KILOGRAMS_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: POUNDS,
    [UNIT_OF_MEASURE.METRIC]: KILOGRAMS
};
const QUARTS_LITERS_NAMES = {
    [UNIT_OF_MEASURE.ENGLISH]: QUARTS,
    [UNIT_OF_MEASURE.METRIC]: LITERS
};

export const UNIT_NAME_CONFIG = {
    [DEGREES_C]: FAHRENHEIT_CELSIUS_NAMES,
    [DEGREES_F]: FAHRENHEIT_CELSIUS_NAMES,
    [GALLONS]: GALLONS_LITERS_NAMES,
    [FEET]: FEET_METERS_NAMES,
    [INCHES]: INCHES_MILLIMETERS_NAMES,
    [KILOGRAMS]: POUNDS_KILOGRAMS_NAMES,
    [KILOMETERS_PER_HOUR]: MPH_KPH_NAMES,
    [LITERS]: {
        ...QUARTS_LITERS_NAMES,
        [FUEL]: GALLONS_LITERS_NAMES,
        [GAS]: GALLONS_LITERS_NAMES,
        [TOTAL_WATER_IRRIGATION]: GALLONS_LITERS_NAMES,
        [WATER_FLOW]: GALLONS_LITERS_NAMES,
        [WATER_TRANSFER]: GALLONS_LITERS_NAMES
    },
    [METERS]: FEET_METERS_NAMES,
    [MILES_PER_HOUR]: MPH_KPH_NAMES,
    [MILLIMETERS]: INCHES_MILLIMETERS_NAMES,
    [POUNDS]: POUNDS_KILOGRAMS_NAMES,
    [QUARTS]: QUARTS_LITERS_NAMES
};
