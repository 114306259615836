// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import {fetchEffectData} from 'Utils/react-utils';
import {getLogo} from 'Services/membership-service';
import {getBase64DataURL} from 'Utils/html-utils';

function useGetMembershipLogo(membershipId) {
    const [logoDataUrl, setLogoDataUrl] = React.useState('');
    const [logoLoading, setLogoLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLogoLoading(true);

        const {
            imageData, extension
        } = await getLogo(membershipId);

        if (isMounted()) {
            setLogoDataUrl(imageData ? getBase64DataURL(`image/${extension}`, imageData) : '');
            setLogoLoading(false);
        }
    }), [membershipId]);

    return {
        logoDataUrl,
        logoLoading
    };
}

export {
    useGetMembershipLogo
};
