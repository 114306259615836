// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import {connect} from 'react-redux';
import {closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import PropTypes from 'Utils/prop-type-utils';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {useSave} from 'Ui/react-hooks/use-save';
import FormDialog from 'Ui/features/dialogs/form-dialog';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {TextField} from '@deere/isg.component-library';
import {sendFeedback} from 'Services/feedback-service';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {TOAST_TYPE} from '@deere/toast';

const MIN_ROWS = 3;
const MAX_ROWS = 6;

function FeedbackDialog(props) {
    const {
        addToast,
        closeFeedBackDialog,
        invalidInputs,
        translations,
        setValid
    } = props;

    const [values, setValues] = React.useState({
        feedbackCategory: null,
        note: ''
    });

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        await sendFeedback(values);
        addToast({
            message: translations.FEEDBACK_SUBMITTED,
            type: TOAST_TYPE.SUCCESS
        });

        closeFeedBackDialog();
    }, {
        invalidInputs
    });

    function onChange(name, value) {
        setValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const feedBackCategories = [
        {
            id: 'General Feedback',
            title: translations.FEEDBACK_CATEGORY_GENERAL
        },
        {
            id: 'Report a Problem',
            title: translations.ONLINK_REPORT_PROBLEM
        }
    ];

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeFeedBackDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.feeback_submit}
            title={translations.footer_feedback}
            translations={translations}
        >
            <div className='settings-block-row'>
                <ValidationMultiSelect
                    autofocus={true}
                    component={SortedMultiSelect}
                    defaultSelectLabel={translations.ONLINK_SELECT_FEEDBACK_CATEGORY}
                    error={translations.REQUIRED_FIELD_TEXT}
                    items={feedBackCategories}
                    label={translations.ONLINK_FEEDBACK_CATEGORY}
                    multiple={false}
                    name='feedbackCategory'
                    onChange={(selectedIds) => onChange('feedbackCategory', selectedIds[0])}
                    required={true}
                    selectedIds={[values.feedbackCategory]}
                    setValid={setValid}
                    tabIndex={0}
                />
            </div>
            <ValidationInput
                component={TextField}
                debounceTimeout={window.props.debounceTimeout}
                errors={{
                    valueMissing: translations.REQUIRED_FIELD_TEXT
                }}
                fullWidth={true}
                label={translations.NOTE}
                maxRows={MAX_ROWS}
                minRows={MIN_ROWS}
                multiline={true}
                name='note'
                onChange={(e) => onChange('note', e.target.value)}
                required={true}
                setValid={setValid}
                type='text'
                value={values.note}
            />
        </FormDialog>
    );
}
FeedbackDialog.propTypes = {
    addToast: PropTypes.func,
    closeFeedBackDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        closeFeedBackDialog() {
            dispatch(closeDialog(dialogTypes.FEEDBACK_DIALOG));
        },
        addToast(value) {
            dispatch(addToastRedux(value));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(FeedbackDialog));
