// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createRole(roleData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/userRoles', roleData);

    return response.data;
}

async function deleteRoleById(roleId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/userRoles/${roleId}`);

    return response.data;
}

async function updateRoleById(roleData, roleId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/userRoles/${roleId}`, roleData);

    return response.data;
}

export {
    createRole,
    deleteRoleById,
    updateRoleById
};
