// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import EditorContentActions from 'Ui/components/layout-editor/editor-content-actions';
import EditorContentHeader from 'Ui/components/layout-editor/editor-content-header';
import EditableGridLayout from 'Ui/components/layout-editor/editable-grid-layout';
import {useDeepEffect} from 'Utils/react-utils';
import {getTileArray, mapTilePositionForSave} from 'Utils/tile-utils';

function prepareTilePositionsForSave(layout) {
    return layout.tilePositions.map(mapTilePositionForSave);
}

function EditorContent(props) {
    const {
        currentLayout,
        isSaving,
        onCancel,
        onCopy,
        onDelete,
        onLayoutChange,
        onSave,
        openTileEdit,
        translations
    } = props;

    const [layout, setLayout] = React.useState(currentLayout);
    const [layoutName, setLayoutName] = React.useState(currentLayout.name);
    const [tilePositions, setTilePositions] = React.useState(() => prepareTilePositionsForSave(currentLayout));

    useDeepEffect(() => {
        const normalizedTilePositions = prepareTilePositionsForSave(currentLayout);

        setLayout(currentLayout);
        setLayoutName(currentLayout.name);
        setTilePositions(normalizedTilePositions);
    }, [currentLayout]);

    const isDefaultLayout = !currentLayout.onlinkUserId;

    return (
        <div className='editor-content'>
            <EditorContentHeader
                isDefaultLayout={isDefaultLayout}
                isNew={currentLayout.isNew}
                name={layoutName}
                onCopy={onCopy}
                onDelete={onDelete}
                onLayoutNameChange={(event) => {
                    const updatedLayoutName = event.target.value;

                    setLayoutName(updatedLayoutName);

                    onLayoutChange({
                        name: updatedLayoutName,
                        tilePositions
                    });
                }}
                translations={translations}
            />
            <div className='editor-content-body'>
                <div className='editor-content-directions note-message'>
                    {
                        isDefaultLayout ?
                            translations.DEFAULT_DASHBOARD_MESSAGE :
                            translations.DRAG_AND_DROP_MESSAGE
                    }
                </div>
                <EditableGridLayout
                    isDefaultLayout={isDefaultLayout}
                    layout={layout}
                    onLayoutChange={(layouts) => {
                        const updatedTilePositions = getTileArray(layouts);

                        setTilePositions(updatedTilePositions);

                        onLayoutChange({
                            name: layoutName,
                            tilePositions: updatedTilePositions
                        });
                    }}
                    translations={translations}
                />
            </div>
            <EditorContentActions
                isDefaultLayout={isDefaultLayout}
                isSaving={isSaving}
                onCancel={onCancel}
                onSave={onSave}
                openTileEdit={() => openTileEdit({
                    onSave(updatedTilePositions) {
                        setLayout({
                            ...layout,
                            tilePositions: updatedTilePositions
                        });
                    },
                    tilePositions
                })}
                translations={translations}
            />
        </div>
    );
}

EditorContent.propTypes = {
    currentLayout: PropTypes.layout,
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func,
    onCopy: PropTypes.func,
    onDelete: PropTypes.func,
    onLayoutChange: PropTypes.func,
    onSave: PropTypes.func,
    openTileEdit: PropTypes.func,
    translations: PropTypes.translations
};

export default EditorContent;
