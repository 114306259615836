// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {Button, MultiSelect} from '@deere/isg.component-library';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {useDeepEffect} from 'Utils/react-utils';

function NotLinkedOnlinkUserMultiselect(props) {
    const {
        translations,
        id,
        userOptions,
        setSelectedIds,
        selectedIds,
        setValid,
        name
    } = props;

    function checkDuplicates(id) {
        const thisSelection = selectedIds[id]?.[0];

        if (Object.keys(selectedIds).length < 2 || thisSelection === 'noUser') {
            return false;
        }

        const otherSelection = {
            ...selectedIds
        };

        delete otherSelection[id];

        return Object.keys(otherSelection).some((key) =>
            otherSelection[key] && otherSelection[key][0] === thisSelection
        );
    }

    const [validity, setValidity] = React.useState(() => !checkDuplicates(id));

    useDeepEffect(() => {
        const valid = !checkDuplicates(id);

        setValid(name, valid);
        setValidity(valid);
    }, [selectedIds]);

    const footerAction = (
        <Button
            onClick={() => {
                setSelectedIds((prev) => {
                    const newSelected = {
                        ...prev
                    };

                    delete newSelected[id];
                    return newSelected;
                });
                setValid(name, validity);
            }}
        >
            {translations.ONLINK_CLEAR_SELECTED}
        </Button>
    );

    return (
        <MultiSelect
            className='merge-multiselect'
            error={!validity}
            footerAction={footerAction}
            helperText={!validity && translations.ONLINK_DUPLICATE_ENTRY}
            items={userOptions}
            labels={{
                placeholder: translations.ONLINK_SELECT_USER,
                emptyList: translations.empty_result
            }}
            name={name}
            onChange={(option) => {
                setSelectedIds((prev) => ({
                    ...prev,
                    [id]: option
                }));
                setValid(name, validity);
            }}
            selectedIds={selectedIds[id] || []}
            single
        />
    );
}

NotLinkedOnlinkUserMultiselect.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    selectedIds: PropTypes.object,
    setSelectedIds: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    userOptions: PropTypes.arrayOf(PropTypes.object)
};

export default NotLinkedOnlinkUserMultiselect;
