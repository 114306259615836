// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';
import {clsx} from 'clsx';

function AdditionalActionButton(props) {
    const {
        children,
        className,
        disabled,
        label,
        onClick
    } = props;

    const baseClassName = clsx('additional-actions', {
        disabled
    });

    return (
        <div
            className={joinClassNames(baseClassName, className)}
            onClick={disabled ? null : onClick}
        >
            <div className='additional-actions-text bold-body-text-section-title'>
                {label}
            </div>
            {children}
        </div>
    );
}

AdditionalActionButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func
};

export default AdditionalActionButton;
