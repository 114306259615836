// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import {IconOrderable} from '@deere/icons';
import {clsx} from 'clsx';

const ICON_ORDERABLE_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function WorkItemDragHandle(props) {
    const {
        className,
        ...otherProps
    } = props;

    const [dragging, setDragging] = React.useState(false);

    const onMouseDownHandler = React.useCallback((e) => {
        if (e.button === 0) {
            setDragging(true);
        }
    }, []);
    const onMouseUpHandler = React.useCallback(() => setDragging(false), []);

    const dragHandleIcon = React.useMemo(() => <IconOrderable iconOrderable={ICON_ORDERABLE_STYLE}/>, []);
    const stackClassName = React.useMemo(() => clsx('drag-handle', {
        [className]: className,
        dragging
    }), [className, dragging]);

    return (
        <Stack
            {...otherProps}
            className={stackClassName}
            onMouseDown={onMouseDownHandler}
            onMouseUp={onMouseUpHandler}
        >
            {dragHandleIcon}
        </Stack>
    );
}

WorkItemDragHandle.propTypes = {
    className: PropTypes.string
};

export default WorkItemDragHandle;
