// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import {fetchEffectData} from 'Utils/react-utils';
import {postWorkItemLog} from 'Services/work-item-service';
import logStateConstants from 'OnEquip/equipment/service-form/utils/log-state-constants';
import moment from 'moment';
import {MILLISECONDS_PER_SECOND} from 'Ui/constants/time-constants';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {getLogsTotalTime} from 'Utils/time-utils';

function initializeState() {
    const [activeLogType, setActiveLogType] = React.useState(() => null);
    const [startTime, setStartTime] = React.useState(() => null);

    return {
        activeLogType,
        setActiveLogType,
        startTime,
        setStartTime,
        intervalIdRef: React.useRef()
    };
}

function getTimerSection(timerState, translations, workItemId, appUserId, setActiveLogType, setStartTime, updateWorkItem) {
    function onClickEvent(logState) {
        setActiveLogType(logState);
        postWorkItemLog(workItemId, {
            appUserId,
            logType: logState
        });
        updateWorkItem();
    }

    switch (timerState) {
        case logStateConstants.start:
        case logStateConstants.unpause:
            return (
                <OnlinkButton
                    className='timer-pause primary'
                    onClick={(event) => {
                        event.preventDefault();
                        onClickEvent(logStateConstants.pause);
                    }}
                >
                    {translations.ONLINK_PAUSE}
                </OnlinkButton>
            );
        case logStateConstants.pause:
            return (
                <OnlinkButton
                    className='timer-unpause primary'
                    onClick={(event) => {
                        event.preventDefault();
                        onClickEvent(logStateConstants.unpause);
                    }}
                >
                    {translations.ONLINK_UNPAUSE}
                </OnlinkButton>
            );
        default:
            return (
                <OnlinkButton
                    className='timer-start primary'
                    onClick={(event) => {
                        event.preventDefault();

                        const formattedStartTime = moment().format('h:mm a');

                        setStartTime(formattedStartTime);
                        onClickEvent(logStateConstants.start);
                    }}
                >
                    {translations.START}
                </OnlinkButton>
            );
    }
}

function WorkItemTimer(props) {
    const {
        closeDialog,
        onTimeChange,
        translations,
        workItem: {
            workItemId,
            workItemLogs,
            appUserId
        },
        showConfirmationDialog,
        updateWorkItem
    } = props;

    const {
        activeLogType,
        setActiveLogType,
        startTime,
        setStartTime,
        intervalIdRef
    } = initializeState();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (workItemLogs?.length) {
            const totalTimeData = getLogsTotalTime(workItemLogs);

            const startTime = workItemLogs[workItemLogs.length - 1].timeLog;
            const formattedStartTime = moment(startTime).format('h:mm a');

            if (isMounted()) {
                setActiveLogType(workItemLogs[0].logType);
                onTimeChange(totalTimeData);
                setStartTime(formattedStartTime);
            }
        }
    }), [workItemLogs]);

    React.useEffect(() => {
        if (activeLogType === logStateConstants.start || activeLogType === logStateConstants.unpause) {
            intervalIdRef.current = setInterval(() => {
                onTimeChange((prevTotalTime) => moment.duration(prevTotalTime + MILLISECONDS_PER_SECOND));
            }, MILLISECONDS_PER_SECOND);
        } else {
            clearInterval(intervalIdRef.current);
        }

        return () => clearInterval(intervalIdRef.current);
    }, [activeLogType]);

    const TimerSection = getTimerSection(activeLogType, translations, workItemId, appUserId,
        setActiveLogType, setStartTime, updateWorkItem);

    return (
        <div className={'work-item-timer'}>
            <div className='work-item-details-time-section'>
                {startTime && `${translations.ONLINK_STARTED}: ${startTime}`}
            </div>
            <div className='work-item-timer-buttons'>
                {TimerSection}
                <OnlinkButton
                    className='timer-complete primary'
                    onClick={async (event) => {
                        event.preventDefault();

                        if (!activeLogType) {
                            await postWorkItemLog(workItemId, {
                                appUserId,
                                logType: logStateConstants.stop
                            });
                            await updateWorkItem();
                            closeDialog();
                        } else {
                            showConfirmationDialog();
                        }
                    }}
                >
                    {translations.COMPLETE}
                </OnlinkButton>
            </div>
        </div>
    );
}

WorkItemTimer.propTypes = {
    closeDialog: PropTypes.func,
    onTimeChange: PropTypes.func,
    showConfirmationDialog: PropTypes.func,
    translations: PropTypes.translations,
    updateWorkItem: PropTypes.func,
    workItem: PropTypes.object
};

export default WorkItemTimer;
