// Unpublished Work © 2020-2024 Deere & Company.

import {orderBy, sortBy} from 'lodash';
import {isEmptyArray} from 'Common/utils/validation-utils';
import {mergeTileData} from 'Utils/data-utils';
import {DATA_TYPE_OVERRIDES} from 'Ui/components/common/data-overrides';

function mapMembership(membership) {
    return {
        id: membership.membershipId,
        title: membership.name
    };
}

function getGraphLabel({dataLabel}, translations) {
    return translations[dataLabel] || dataLabel;
}

export function translateGraphLabel(config, key, translations) {
    const dataTypeOverride = DATA_TYPE_OVERRIDES[key];

    if (dataTypeOverride) {
        return config && config.dataType === dataTypeOverride && getGraphLabel(config, translations) || key;
    }

    return config && config.dataType === key && getGraphLabel(config, translations) || key;
}

export function getMemberships(membershipData) {
    const memberships = [mapMembership(membershipData)];

    if (membershipData.childLevels) {
        const sortedChildLevels = sortBy(membershipData.childLevels, 'name');

        return memberships.concat(sortedChildLevels.map(mapMembership));
    }

    return memberships;
}

function getDataRollups(mergedData) {
    return mergedData.dataRollup;
}

function populateTransformedData(transformedData, data, key) {
    transformedData[key].membershipId = data.dataRollup.membershipId;

    if (data.panelData) {
        transformedData[key].useField = data.panelData.useField;
    }

    if (data.threshold) {
        transformedData[key].dataUnits = data.threshold.dataUnits;
    }
}

function createTransformedChartData(transformedData, mergedData, key) {
    const [firstData] = mergedData;

    transformedData[key] = {
        data: mergedData.map(getDataRollups),
        dataType: firstData.dataRollup.dataType
    };

    populateTransformedData(transformedData, firstData, key);

    if (firstData.panelData) {
        transformedData[key].timeScale = firstData.panelData.timeScale;
    }

    if (firstData.threshold) {
        transformedData[key].config = firstData.threshold;
    }

    return transformedData;
}

function createTransformedDataTableData(transformedData, mergedData, key) {
    const [firstData] = mergedData;

    transformedData[key] = {
        data: orderBy(mergedData.map(getDataRollups), 'timeSelect', 'desc'),
        key
    };

    populateTransformedData(transformedData, firstData, key);

    if (firstData.threshold) {
        transformedData[key].dataLabel = firstData.threshold.dataLabel;

        if (DATA_TYPE_OVERRIDES[key]) {
            transformedData[key].key = firstData.threshold.dataType === DATA_TYPE_OVERRIDES[key] && firstData.threshold.dataLabel || key;
        } else {
            transformedData[key].key = firstData.threshold.dataType === key && firstData.threshold.dataLabel || key;
        }
    }

    return transformedData;
}

export function transformData(tileData, panelData, thresholds, type) {
    if (!tileData || isEmptyArray(panelData) || isEmptyArray(thresholds)) {
        return {};
    }

    return Object.keys(tileData).reduce((data, key) => {
        const mergedData = mergeTileData(tileData[key], panelData, thresholds);

        if (mergedData.length > 0) {
            return type === 'chart' ?
                createTransformedChartData(data, mergedData, key) :
                createTransformedDataTableData(data, mergedData, key);
        }
        return data;
    }, {});
}

export function getGraphTimeScaleUnit(timeScale) {
    switch (timeScale) {
        case 'yearly':
        case 'year':
            return 'month';
        case 'daily':
        case 'day':
            return 'minute';
        default:
            return 'day';
    }
}
