// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import {connect} from 'react-redux';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import PropTypes from 'Utils/prop-type-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {getOrganizationOperators} from 'Services/my-jd-user-service';
import {getMyJDUserOrganization} from 'Services/membership-service';
import {replaceTranslationNames} from 'Utils/translation-utils';
import NotLinkedOnlinkUserMultiselect from 'Ui/components/settings/myJohnDeere/migrations/not-linked-onlink-user-multiselect';
import {
    filterUsersByInviteStatus,
    getLinkedUsers,
    makeInviteStatusMap
} from 'Utils/myJohnDeereMigration/user-migration-table-data';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import UserMergeTable from 'Ui/features/myJohnDeere-migration/user-merge-table';
import {saveOperatorMerge} from 'Utils/myJohnDeereMigration/user-migration-save';

function MyJohnDeereMergeExistingOperator(props) {
    const {
        translations,
        openMigrationConfirmationDialog,
        orgId,
        closeMigrationConfirmationDialog,
        setValid,
        invalidInputs
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [operators, setOperators] = React.useState([]);
    const [userOptions, setUserOptions] = React.useState([]);
    const [selectedIds, setSelectedIds] = React.useState({});
    const orgName = React.useRef('');

    async function reloadTable(isMounted) {
        setLoading(true);

        const [
            fetchedOperators,
            {
                users,
                linkedOperators,
                linkedUsersSet
            },
            inviteStatusMap,
            {name}
        ] = await Promise.all([
            getOrganizationOperators(orgId),
            getLinkedUsers(),
            makeInviteStatusMap(),
            getMyJDUserOrganization(orgId)
        ]);

        const filteredOperators = fetchedOperators
            .filter(({id}) => !linkedOperators.some(({operatorId}) => id === operatorId))
            .sort((operatorA, operatorB) => operatorA.name.localeCompare(operatorB.name));

        orgName.current = name;

        const filteredUsers = filterUsersByInviteStatus(
            users,
            linkedUsersSet,
            inviteStatusMap,
            true
        );

        if (isMounted()) {
            setOperators(filteredOperators);
            setUserOptions(filteredUsers.map((user) => ({
                ...user,
                id: user.appUserId,
                title: `${user.firstName} ${user.lastName}`
            })));
            setSelectedIds({});

            setLoading(false);
        }
    }

    React.useEffect(() => fetchEffectData(reloadTable), []);

    function openMigrationDialog() {
        openMigrationConfirmationDialog({
            confirmTitle: translations.ONLINK_CONFIRM_MERGE,
            migrationAction: () => saveOperatorMerge(selectedIds, reloadTable, closeMigrationConfirmationDialog, orgId),
            migrationConfirmButtonText: translations.ONLINK_CONFIRM_MERGE,
            migrationMessageBody: replaceTranslationNames(translations.ONLINK_CONFIRM_MERGE_FOR_ORGANIZATION, {
                '0': Object.keys(selectedIds).length,
                '1': translations.OPERATORS.toLowerCase(),
                '2': orgName.current
            }),
            migrationSuccessBody: replaceTranslationNames(translations.ONLINK_CONFIRMED_USERS_MIGRATED, {
                '0': orgName.current,
                '1': translations.OPERATORS.toLowerCase()
            }),
            onClose: closeMigrationConfirmationDialog,
            successTitle: translations.ONLINK_USERS_SUCCESSFULLY_MERGED,
            teamManagerLinkText: replaceTranslationNames(translations.ONLINK_VIEW_IN_TEAM, {
                '0': translations.OPERATORS
            }),
            translations
        });
    }

    function getColumns() {
        return [
            {
                Header: `${translations.MY_OPERATIONS} ${translations.OPERATOR}`,
                accessor: 'name'
            },
            {
                Header: replaceTranslationNames(translations.ONLINK_USER, {
                    '1': 'OnLink'
                }),
                accessor: 'appUser',
                Cell(row) {
                    const {original} = row;

                    return (
                        <NotLinkedOnlinkUserMultiselect
                            id={original.id}
                            name={`operator-${original.id}`}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            setValid={setValid}
                            translations={translations}
                            userOptions={userOptions}
                        />
                    );
                }
            }
        ];
    }

    return (
        <UserMergeTable
            columns={getColumns()}
            invalidInputs={invalidInputs}
            loading={loading}
            onMerge={openMigrationDialog}
            rows={operators}
            selectedIds={selectedIds}
            step1Body={[translations.ONLINK_MERGE_OPERATOR_STEP_1_DETAILS]}
            step1Title={translations.ONLINK_MERGE_OPERATOR_STEP_1}
            step2Body={[replaceTranslationNames(translations.ONLINK_MERGE_STAFF_STEP_2_DETAILS, {
                '0': translations.OPERATOR
            })]}
            step2Title={translations.ONLINK_MERGE_OPERATOR_STEP_2}
            subtitles={[translations.ONLINK_MERGE_OPERATOR_FORM_SUBTITLE]}
            title={translations.ONLINK_MERGE_OPERATOR_FORM_TITLE}
            translations={translations}
        />
    );
}

MyJohnDeereMergeExistingOperator.propTypes = {
    closeMigrationConfirmationDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    openMigrationConfirmationDialog: PropTypes.func,
    orgId: PropTypes.number,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        orgId: state.membership.orgId,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openMigrationConfirmationDialog(props) {
            dispatch(openDialog(dialogTypes.MIGRATION_CONFIRMATION_DIALOG, props));
        },
        closeMigrationConfirmationDialog() {
            dispatch(closeDialog(dialogTypes.MIGRATION_CONFIRMATION_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(MyJohnDeereMergeExistingOperator));
