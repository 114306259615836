// Unpublished Work © 2020-2024 Deere & Company.

import {Responsive} from 'react-grid-layout';
import {sortBy} from 'lodash';

function createObjectProperty(object, key, value) {
    if (!object[key]) {
        object[key] = value;
    }
}

function addArrayObject(array, ...args) {
    array.push(Object.assign({}, ...args));
}

function getTileInfo(tilePosition) {
    if (tilePosition.tile) {
        return {
            tileId: tilePosition.tile.id,
            tileType: tilePosition.tile.name
        };
    }

    return {
        tileId: tilePosition.tileId,
        tileType: tilePosition.tileType
    };
}

function byColumnCount(object, tilePosition) {
    const {
        columnCount,
        position
    } = tilePosition;

    createObjectProperty(object, columnCount, []);

    addArrayObject(object[columnCount], getTileInfo(tilePosition), position);

    return object;
}

function createDraggableLayouts(layout) {
    return layout.tilePositions.reduce(byColumnCount, {});
}

function byColumn(object, tilePosition) {
    const {
        columnCount,
        position
    } = tilePosition;

    createObjectProperty(object, columnCount, {});
    createObjectProperty(object[columnCount], position.x, []);

    addArrayObject(object[columnCount][position.x], getTileInfo(tilePosition), position);

    return object;
}

function sortPositions(layouts) {
    return Object.keys(layouts).reduce((newLayouts, columnCount) => {
        const columns = layouts[columnCount];

        newLayouts[columnCount] = Object.keys(columns).reduce((newColumns, x) => {
            newColumns[x] = sortBy(columns[x], 'y');

            return newColumns;
        }, {});

        return newLayouts;
    }, {});
}

function createStaticLayouts(layout) {
    const layouts = layout.tilePositions.reduce(byColumn, {});

    return sortPositions(layouts);
}

function getColumnCount(breakpoints, cols, width) {
    const breakpoint = Responsive.utils.getBreakpointFromWidth(breakpoints, width);

    return Responsive.utils.getColsFromBreakpoint(breakpoint, cols);
}

function getLayoutById(layouts, layoutId) {
    return layouts.find((layout) => layout.id === layoutId);
}

function mergeLayouts(layout1, layout2) {
    const layout1Keys = Object.keys(layout1);
    const layout1Map = layout1Keys.reduce((layoutMap, key) => {
        const positionMap = layout1[key].reduce((positionMap, position) => {
            positionMap.set(position.i, position);

            return positionMap;
        }, new Map());

        layoutMap.set(key, positionMap);

        return layoutMap;
    }, new Map());

    return layout1Keys.reduce((newLayout, key) => {
        newLayout[key] = layout2[key].map((position) => ({
            ...layout1Map.get(key).get(position.i),
            ...position
        }));

        return newLayout;
    }, {});
}

export {
    createDraggableLayouts,
    createStaticLayouts,
    getColumnCount,
    getLayoutById,
    mergeLayouts
};
