// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Box from '@mui/material/Box';
import ReadOnlyList from 'Ui/components/common/form/read-only/read-only-list';
import {formatMinutesToHours} from 'Utils/time-utils';

function SelectJobsFormReadOnly(props) {
    const {
        translations,
        values
    } = props;

    const jobItems = [];
    const selectedJobIds = [];

    Object.keys(values.jobs).forEach((jobCategory) => {
        const {[jobCategory]: selectedJobsForCategory} = values.jobs;

        selectedJobsForCategory.forEach((job) => {
            jobItems.push({
                id: job.jobTemplateId,
                subtitle: `${formatMinutesToHours(job.estDuration)} ${translations.HOURS}`,
                title: job.title || ''
            });
            selectedJobIds.push(job.jobTemplateId);
        });
    });

    return (
        <Box className='add-workboard-form select-form-columns'>
            <ReadOnlyList
                items={jobItems}
                label={translations.ONLINK_SELECTED_JOBS}
                noItemsSelectedLabel={translations.ONLINK_NO_JOBS_SELECTED}
                selectedIds={selectedJobIds}
            />
        </Box>
    );
}

SelectJobsFormReadOnly.propTypes = {
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default SelectJobsFormReadOnly;
