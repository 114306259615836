// Unpublished Work © 2020-2024 Deere & Company.

export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';

export function updateUserRoles(userRoles) {
    return {
        type: UPDATE_USER_ROLES,
        userRoles
    };
}
