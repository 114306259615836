// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconChevronLeft, IconChevronRight} from '@deere/icons';
import NavItem from 'Ui/components/main-navbar/persistent-header/navigation/nav-item';
import {createIconFillStyle} from 'Utils/icon-utils';
import {clsx} from 'clsx';

const ICON_CHEVRON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};
const ICON_CHEVRON_PRIMARY_STYLE = createIconFillStyle('#156cb6');

function DrillInNavItem(props) {
    const {
        children,
        groupNavItemClassName,
        isSelected,
        setIsSelected,
        ...navItemProps
    } = props;

    const iconProps = React.useMemo(() => {
        if (isSelected) {
            return {
                leftIcon: (
                    <IconChevronLeft
                        iconChevronLeft={ICON_CHEVRON_STYLE}
                        primary={ICON_CHEVRON_PRIMARY_STYLE}
                    />
                )
            };
        }

        const iconChevronRightStyle = {
            style: {
                ...ICON_CHEVRON_STYLE.style,
                marginLeft: 'auto'
            }
        };

        return {
            rightIcon: (
                <IconChevronRight
                    iconChevronRight={iconChevronRightStyle}
                    primary={ICON_CHEVRON_PRIMARY_STYLE}
                />
            )
        };
    }, [isSelected]);

    return (
        <>
            <NavItem
                {...navItemProps}
                {...iconProps}
                className={clsx('drill-in', groupNavItemClassName, {
                    opened: isSelected
                })}
                onClick={setIsSelected}
            />
            {
                isSelected &&
                <div className='nav-items'>
                    {children}
                </div>
            }
        </>
    );
}

DrillInNavItem.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    groupNavItemClassName: PropTypes.string,
    iconName: PropTypes.string,
    isSelected: PropTypes.bool,
    label: PropTypes.node,
    path: PropTypes.string,
    prioritizeList: PropTypes.arrayOf(PropTypes.string),
    reactRouter: PropTypes.bool,
    setIsSelected: PropTypes.func,
    title: PropTypes.string
};

export default DrillInNavItem;
