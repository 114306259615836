// Unpublished Work © 2022-2024 Deere & Company.

export const MOWING_PATTERNS = [
    {
        area: 'Approaches',
        equipmentAreaId: '958244bd-deeb-4199-9ac8-0a27fdfe1418',
        label: 'APPROACHES'
    },
    {
        area: 'Collars',
        equipmentAreaId: '289ddb77-f6dd-4249-899d-b49c2c4ce29f',
        label: 'COLLARS'
    },
    {
        area: 'Fairways',
        equipmentAreaId: '9d367cdb-5bf3-4502-b234-ac149f1fe5a3',
        label: 'FAIRWAYS'
    },
    {
        area: 'Greens',
        equipmentAreaId: '117c2fc7-ce25-4533-a1e7-367d54843f54',
        label: 'GREENS'
    },
    {
        area: 'Intermediate',
        equipmentAreaId: 'fd112d95-29a8-401b-a3cd-46e534070f50',
        label: 'INTERMEDIATE'
    },
    {
        area: 'Rough',
        equipmentAreaId: '9c8edd33-8c09-4a2f-879e-b0f39d48b25c',
        label: 'ROUGH'
    },
    {
        area: 'Surrounds',
        equipmentAreaId: '6953593a-7201-480f-beb4-db05c281c611',
        label: 'ONLINK_SURROUNDS'
    },
    {
        area: 'Tees',
        equipmentAreaId: '178e9623-477a-42bb-894c-50e4a074ae65',
        label: 'TEES'
    }
];

export const MOWING_DIRECTION_REMAPPING = {
    '3-9': '3 - 9',
    '7-1': '7 - 1',
    '8-2': '8 - 2',
    '10-4': '10 - 4',
    '11-5': '11 - 5',
    '12-6': '12 - 6',
    '2 Colors': '50/50 - Away Left'
};
