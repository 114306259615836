// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconCaretDirection} from 'Ui/components/main-navbar';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {clsx} from 'clsx';

function MobileNav(props) {
    const {
        children,
        dropdownOpen,
        dropdownTitle,
        toggleDropdown
    } = props;

    const buttonClassName = clsx('onlink-btn-nav onlink-btn-right', {
        active: dropdownOpen
    });

    const iconCaret = (
        <IconCaretDirection
            caretUp={dropdownOpen}
            color='#fff'
        />
    );

    return (
        <div className='mobile-nav'>
            <OnlinkButton
                className={buttonClassName}
                color='white'
                onClick={toggleDropdown}
                rightIcon={iconCaret}
            >
                {dropdownTitle}
            </OnlinkButton>
            {
                dropdownOpen && (
                    <>
                        <div className='mobile-nav-content-container'>
                            {children}
                        </div>
                        <div
                            className='overlay'
                            onClick={toggleDropdown}
                        >
                            <div className='overlay-shadow'/>
                        </div>
                    </>
                )
            }
        </div>
    );
}

MobileNav.propTypes = {
    children: PropTypes.node,
    dropdownOpen: PropTypes.bool,
    dropdownTitle: PropTypes.string,
    toggleDropdown: PropTypes.func
};

export default MobileNav;
