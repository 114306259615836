// Unpublished Work © 2023-2024 Deere & Company.

import StepInstructionsForm from 'Ui/components/common/form/step-instructions-form';
import DataTable from 'Ui/components/common/data-table/data-table';
import React from 'react';
import {useSave} from 'Ui/react-hooks/use-save';
import PropTypes from 'Utils/prop-type-utils';
import {Button} from '@deere/isg.component-library';

function UserMergeTable(props) {
    const {
        step1Title,
        step1Body,
        step2Title,
        step2Body,
        title,
        subtitles,
        onMerge,
        selectedIds,
        invalidInputs,
        loading,
        translations,
        columns,
        rows
    } = props;

    const [mergeFunc, disableMerge, isMerging] = useSave(onMerge, {
        disabled: loading || Object.keys(selectedIds).length === 0,
        invalidInputs
    });

    return (
        <div className='table-scroll'>
            <StepInstructionsForm
                steps={[
                    {
                        title: step1Title,
                        body: step1Body
                    },
                    {
                        title: step2Title,
                        body: step2Body,
                        additionalComponent: (
                            <form onSubmit={mergeFunc}>
                                <DataTable
                                    columns={columns}
                                    defaultSorted={[{
                                        desc: false,
                                        id: 'name'
                                    }]}
                                    loading={loading || isMerging}
                                    rows={rows}
                                    searchable={true}
                                    translations={translations}
                                />
                                <Button
                                    disabled={disableMerge}
                                    onClick={mergeFunc}
                                    sx={{
                                        marginLeft: '10px'
                                    }}
                                    variant='primary'
                                >
                                    {translations.ONLINK_MERGE}
                                </Button>
                            </form>
                        )
                    }
                ]}
                subtitles={subtitles}
                title={title}

            />
        </div>
    );
}

UserMergeTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    invalidInputs: PropTypes.instanceOf(Set),
    loading: PropTypes.bool,
    onMerge: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.object,
    step1Body: PropTypes.arrayOf(PropTypes.string),
    step1Title: PropTypes.string,
    step2Body: PropTypes.arrayOf(PropTypes.string),
    step2Title: PropTypes.string,
    subtitles: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    translations: PropTypes.translations
};

export default UserMergeTable;
