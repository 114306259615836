// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Panel from 'Ui/components/panel/panel';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import HourlyForecast from 'Ui/components/drill-ins/weather-course/forecast/hourly-forecast';
import DailyForecast from 'Ui/components/drill-ins/weather-course/forecast/daily-forecast';
import {connect} from 'react-redux';
import {getUnitOfMeasureOverride} from 'Common/utils/units/unit-converter';
import {WSPD} from 'Common/constants/data-group-constants';

function Forecast(props) {
    const {
        onClick,
        featureToggles,
        tile,
        translations,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = props;

    const hasHourlyForecastData = tile.hourlyForecast?.length > 0;
    const hasDailyForecastData = tile.dailyForecast?.length > 0;

    if (!hasHourlyForecastData && !hasDailyForecastData) {
        return (
            <Panel
                onClick={onClick}
                title={translations.ONLINK_FORECAST_CONDITIONS}
                translations={translations}
            >
                <NoDataMessage translations={translations}/>
            </Panel>
        );
    }

    const windSpeedUnitOfMeasure = React.useMemo(() =>
        getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, WSPD)
    , [unitOfMeasureOverrides, unitOfMeasure]);

    return (
        <Panel
            className='forecast'
            onClick={onClick}
            title={translations.ONLINK_FORECAST_CONDITIONS}
            translations={translations}
        >
            {
                hasHourlyForecastData &&
                <HourlyForecast
                    featureToggles={featureToggles}
                    forecastData={tile.hourlyForecast}
                    translations={translations}
                    unitOfMeasure={unitOfMeasure}
                    windSpeedUnitOfMeasure={windSpeedUnitOfMeasure}
                />
            }
            {
                hasDailyForecastData &&
                <DailyForecast
                    featureToggles={featureToggles}
                    forecastData={tile.dailyForecast}
                    translations={translations}
                    unitOfMeasure={unitOfMeasure}
                    windSpeedUnitOfMeasure={windSpeedUnitOfMeasure}
                />
            }
        </Panel>
    );
}

Forecast.propTypes = {
    featureToggles: PropTypes.featureToggles,
    onClick: PropTypes.func,
    tile: PropTypes.panel,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string,
    unitOfMeasureOverrides: PropTypes.array
};

export function mapStateToProps(state) {
    return {
        unitOfMeasure: state.membership.unitOfMeasure,
        unitOfMeasureOverrides: state.membership.unitOfMeasureOverrides,
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(Forecast);
