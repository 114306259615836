// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import TileEditor from 'Ui/components/tile-editor/tile-editor';
import {createDraggableLayouts} from 'Utils/layout-utils';
import {getTilePositions, sortTiles} from 'Utils/tile-utils';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import cols from 'Common/constants/grid-cols';
import dialogTypes from 'Ui/components/common/dialog-types';

function getActiveTiles(tilePositions) {
    if (tilePositions && tilePositions.length > 0) {
        const draggableLayouts = createDraggableLayouts({
            tilePositions
        });

        return sortTiles(draggableLayouts)[1].reduce((activeTiles, tile) => {
            activeTiles[tile.id] = true;

            return activeTiles;
        }, {});
    }

    return {};
}

function TileEditDialog(props) {
    const {
        closeDialog,
        onSave,
        tilePositions,
        translations
    } = props;

    const [activeTiles, setActiveTiles] = React.useState(() => getActiveTiles(tilePositions));

    async function onSaveTiles() {
        const newTilePositions = getTilePositions(tilePositions, activeTiles, cols);

        await onSave(newTilePositions);
        closeDialog();
    }

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='tile-editor-dialog'
            closeHandler={closeDialog}
            disableSave={Object.keys(activeTiles).every((key) => !activeTiles[key])}
            onSave={onSaveTiles}
            saveLabel={translations.DONE}
            title={translations.EDIT_TILES}
            translations={translations}
        >
            <TileEditor
                activeTiles={activeTiles}
                setActiveTiles={setActiveTiles}
                translations={translations}
            />
        </FormDialog>
    );
}

TileEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    onSave: PropTypes.func,
    tilePositions: PropTypes.arrayOf(PropTypes.tilePosition),
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.TILE_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TileEditDialog);
