// Unpublished Work © 2022-2024 Deere & Company.

import {
    CLOCKWISE_CIRCLE,
    CONTOUR,
    COUNTER_CLOCKWISE_CIRCLE,
    SINGLE_GREEN_TO_TEE,
    SINGLE_TEE_TO_GREEN,
    TWO_COLORS_AWAY_LEFT,
    TWO_COLORS_AWAY_RIGHT
} from 'OnLabor/workboard/constants/mowing-directions';

export const BACKTRACK_CUT = 'Backtrack';
export const DOUBLE_CUT = 'Double';
export const SINGLE_CUT = 'Single';

const EXCLUDED_DIRECTIONS = [CLOCKWISE_CIRCLE, COUNTER_CLOCKWISE_CIRCLE, CONTOUR, SINGLE_GREEN_TO_TEE,
    SINGLE_TEE_TO_GREEN, TWO_COLORS_AWAY_LEFT, TWO_COLORS_AWAY_RIGHT];

export const EXCLUDED_MOWING_DIRECTIONS_FOR_CUT = {
    [BACKTRACK_CUT]: EXCLUDED_DIRECTIONS,
    [DOUBLE_CUT]: EXCLUDED_DIRECTIONS
};
