// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import PropTypes from 'Utils/prop-type-utils';

function InventoryPartsTotalCost(props) {
    const {
        currencyPreference,
        featureToggles,
        selectedParts,
        translations,
        otherCost
    } = props;

    const selectedPartsFormattedTotal = React.useMemo(() => {
        const selectedPartsTotal = selectedParts.reduce((total, part) => {
            return total + part.quantity * part.unitCost;
        }, 0);

        return getFormattedCurrency(selectedPartsTotal, {
            currencyPreference,
            featureToggles
        });
    },
    [selectedParts, otherCost]
    );

    return (
        <div className='selected-parts-total-cost'>
            {`${translations.ONLINK_TOTAL}: ${selectedPartsFormattedTotal}`}
        </div>
    );
}

InventoryPartsTotalCost.propTypes = {
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    otherCost: PropTypes.object,
    selectedParts: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations
};

export default InventoryPartsTotalCost;
