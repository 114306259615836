// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {joinClassNames} from 'Utils/html-utils';

function OnLinkDialogContentBar(props) {
    const {
        children,
        className
    } = props;

    return (
        <div className={joinClassNames('onlink-dialog-content-bar-wrapper', className)}>
            <div className='onlink-dialog-content-bar'>
                {children}
            </div>
        </div>
    );
}

OnLinkDialogContentBar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default OnLinkDialogContentBar;
