// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ReadOnlyValue from 'Ui/components/common/form/read-only/read-only-value';
function EditableCell(props) {
    const {
        errors,
        name,
        max,
        min,
        onChange,
        setValid,
        type,
        initialValue,
        disabled
    } = props;

    const [value, setValue] = React.useState(initialValue);

    function onChangeWrapper(event) {
        const newValue = onChange(event);

        setValue(newValue || event.target.value);
    }

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const CellComponent = disabled ? <ReadOnlyValue value={value}/> :
        (<ValidationInput
            errors={errors}
            max={max}
            min={min}
            name={name}
            onChange={onChangeWrapper}
            setValid={setValid}
            step='any'
            tabIndex={0}
            type={type}
            value={value}
        />);

    return (
        <form className='editable-cell'>
            {CellComponent}
        </form>
    );
}

EditableCell.propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    setValid: PropTypes.func,
    type: PropTypes.string
};

export default EditableCell;
