// Unpublished Work © 2020-2024 Deere & Company.

import axios from 'axios';

async function fetchFromS3(s3Path) {
    const response = await axios.get(s3Path);

    return response.data;
}

export {
    fetchFromS3
};
