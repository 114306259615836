// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconUpload} from '@deere/icons';
import {MultiSelect, TextArea} from '@deere/form-controls';
import FileInput from 'Ui/components/common/form/file-input';
import RadioButton from 'Ui/components/common/form/radio-button';
import SwitchInput from 'Ui/components/common/form/switch-input';
import ValidationInput from 'Ui/components/common/form/validation-input';
import OnlinkButton from 'Ui/components/common/onlink-button';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import {postLogo, updateMembershipData} from 'Services/membership-service';
import {updateMembership as updateMembershipRedux} from 'Store/actions/membership';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {getDefault, getLabel, mapLabels} from 'Utils/workboard-settings-utils';
import defaultImage from 'Ui/images/bg/default_membership_image.jpg';
import {MAX_FILE_SIZE} from 'Common/constants/files';
import {TOAST_TYPE} from '@deere/toast';
import {useGetMembershipLogo} from 'Ui/react-hooks/use-get-membership-logo';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

const ICON_UPLOAD_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

function getPropOrDefault(propertyName, membership, translations) {
    return membership.properties && membership.properties[propertyName] || getDefault(propertyName, translations);
}

function getSwitchValue(propertyName, membership, translations) {
    return getPropOrDefault(propertyName, membership, translations) === 'true';
}

function initializeState(membership, translations) {
    const [files, setFiles] = React.useState(() => []);
    const [values, setValues] = React.useState(() => ({
        'workboard_week_start_day': getPropOrDefault('workboard_week_start_day', membership, translations),
        'workboard_default_start_time': getPropOrDefault('workboard_default_start_time', membership, translations),
        'workboard_weekly_work_hours': getPropOrDefault('workboard_weekly_work_hours', membership, translations),
        'workboard_employee_daily_hours_limit': getPropOrDefault('workboard_employee_daily_hours_limit', membership, translations),
        'workboard_presentation_show_weather': getSwitchValue('workboard_presentation_show_weather', membership, translations),
        'workboard_presentation_show_estimated_hours': getSwitchValue('workboard_presentation_show_estimated_hours', membership, translations),
        'workboard_copy_estimated_to_actual': getSwitchValue('workboard_copy_estimated_to_actual', membership, translations),
        'workboard_presentation_groups': getPropOrDefault('workboard_presentation_groups', membership, translations),
        'workboard_presentation_autoscroll_speed': getPropOrDefault('workboard_presentation_autoscroll_speed', membership, translations),
        'workboard_presentation_font_size': getPropOrDefault('workboard_presentation_font_size', membership, translations),
        'workboard_presentation_font_size_notes': getPropOrDefault('workboard_presentation_font_size_notes', membership, translations),
        'workboard_presentation_note': getPropOrDefault('workboard_presentation_note', membership, translations),
        'workboard_presentation_column_count': getPropOrDefault('workboard_presentation_column_count', membership, translations),
        'workboard_show_employee_image': getSwitchValue('workboard_show_employee_image', membership, translations),
        'workboard_show_employee_group_color': getSwitchValue('workboard_show_employee_group_color', membership, translations),
        'workboard_show_route': getSwitchValue('workboard_show_route', membership, translations),
        'workboard_show_protection_icons': getSwitchValue('workboard_show_protection_icons', membership, translations),
        'workboard_tile_opacity': getSwitchValue('workboard_tile_opacity', membership, translations),
        'workboard_creation_flow': getPropOrDefault('workboard_creation_flow', membership, translations)
    }));

    return {
        values,
        setValues,
        files,
        setFiles
    };
}

async function onSave(values, membership, updateMembership, setIsEditing) {
    const updatedProperties = {
        ...membership.properties,
        ...values,
        'workboard_presentation_show_weather': `${values.workboard_presentation_show_weather}`,
        'workboard_presentation_show_estimated_hours': `${values.workboard_presentation_show_estimated_hours}`,
        'workboard_copy_estimated_to_actual': `${values.workboard_copy_estimated_to_actual}`,
        'workboard_show_employee_image': `${values.workboard_show_employee_image}`,
        'workboard_show_employee_group_color': `${values.workboard_show_employee_group_color}`,
        'workboard_show_route': `${values.workboard_show_route}`,
        'workboard_show_protection_icons': `${values.workboard_show_protection_icons}`,
        'workboard_tile_opacity': `${values.workboard_tile_opacity}`
    };

    await updateMembershipData({
        properties: updatedProperties
    }, membership.membershipId);

    updateMembership({
        ...membership,
        properties: updatedProperties
    });

    setIsEditing();
}

async function onSubmit(files, setFiles, translations, event, addToast) {
    event.preventDefault();

    try {
        await postLogo(files[0], translations);

        addToast({
            message: translations.ONLINK_IMPORT_IMAGE_SUCCESS,
            type: TOAST_TYPE.SUCCESS
        });
    } catch (e) {
        addToast({
            message: e.message,
            type: TOAST_TYPE.ERROR
        });
    }

    setFiles([]);
}

function getWorkboardCreationSetting(
    featureToggles,
    onNonEventChange,
    translations,
    values
) {
    return (
        <div className='settings-block workboard-toggle-group'>
            <div className='settings-block-row'>
                {translations.ONLINK_WORKBOARD_CREATION}
            </div>
            <div className='settings-group horizontal'>
                {
                    ['user', 'job'].map((label, index) => (
                        <RadioButton
                            className='workboard-creation-option'
                            id={label}
                            isSelected={values.workboard_creation_flow === label}
                            key={index}
                            label={getLabel('workboard_creation_flow', label, translations)}
                            onChange={(event) => onNonEventChange('workboard_creation_flow', event.target.value)}
                            value={label}
                        />
                    ))
                }
            </div>
        </div>
    );
}

function EditableWorkboardSettings(props) {
    const {
        addToast,
        invalidInputs,
        featureToggles,
        membership,
        setIsEditing,
        setValid,
        translations,
        updateMembership
    } = props;

    const {
        values,
        setValues,
        files,
        setFiles
    } = initializeState(membership, translations);

    function onChange(event) {
        const {
            name,
            value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onNonEventChange(name, value) {
        onChange({
            target: {
                name,
                value
            }
        });
    }

    function onMultiSelectChange(selectedIds, name) {
        if (selectedIds.length > 0) {
            onNonEventChange(name, selectedIds[0]);
        }
    }

    const {
        logoDataUrl, logoLoading
    } = useGetMembershipLogo(membership.membershipId);

    const valueGreaterThanOrEqualMessage = replaceTranslationNames(translations.VALUE_BETWEEN, {
        '0': '1',
        '1': '4'
    });

    const greaterThanMessage = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    });

    const onLaborUpdatesSettings = (
        <>
            <div className='settings-group horizontal'>
                <SwitchInput
                    checked={values.workboard_show_employee_image}
                    className='workboard-switch-input'
                    name='workboard_show_employee_image'
                    onChange={onNonEventChange}
                    onLabel={translations.ONLINK_SHOW_EMPLOYEE_IMAGE}
                    translations={translations}
                />
            </div>
            <div className='settings-group horizontal'>
                <SwitchInput
                    checked={values.workboard_show_employee_group_color}
                    className='workboard-switch-input'
                    name='workboard_show_employee_group_color'
                    onChange={onNonEventChange}
                    onLabel={translations.ONLINK_SHOW_EMPLOYEE_GROUP_COLOR}
                    translations={translations}
                />
            </div>
            <div className='settings-group horizontal'>
                <SwitchInput
                    checked={values.workboard_show_route}
                    className='workboard-switch-input'
                    name='workboard_show_route'
                    onChange={onNonEventChange}
                    onLabel={translations.ONLINK_SHOW_ROUTE}
                    translations={translations}
                />
            </div>
        </>
    );

    const onLaborRefactorSettings = (
        <div className='settings-group mobile'>
            <div className='settings-block'>
                <SwitchInput
                    checked={values.workboard_show_protection_icons}
                    className='workboard-switch-input'
                    name='workboard_show_protection_icons'
                    onChange={onNonEventChange}
                    onLabel={translations.ONLINK_SHOW_PROTECTION_ICONS}
                    translations={translations}
                />
            </div>
            <div className='settings-block'>
                <SwitchInput
                    checked={values.workboard_tile_opacity}
                    className='workboard-switch-input'
                    name='workboard_tile_opacity'
                    onChange={onNonEventChange}
                    onLabel={translations.ONLINK_TILE_OPACITY}
                    translations={translations}
                />
            </div>
        </div>
    );

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={logoLoading}
            size='50px'
        >
            <div className='settings-body'>
                <div className='editable-workboard-settings'>
                    <form>
                        <div className='settings-group mobile'>
                            <div className='settings-block'>
                                <MultiSelect
                                    className='multiselect'
                                    defaultSelectLabel={getLabel('workboard_week_start_day', values.workboard_week_start_day, translations)}
                                    hideFilter={true}
                                    items={mapLabels('workboard_week_start_day', translations)}
                                    label={translations.ONLINK_WEEK_START_DAY}
                                    multiple={false}
                                    name='weekStartDay'
                                    onChange={(ids) => onMultiSelectChange(ids, 'workboard_week_start_day')}
                                    selectedIds={[values.workboard_week_start_day]}
                                    tabIndex={0}
                                />
                            </div>
                            <div className='settings-block'>
                                <MultiSelect
                                    className='multiselect'
                                    defaultSelectLabel={getLabel('workboard_default_start_time', values.workboard_default_start_time, translations)}
                                    hideFilter={true}
                                    items={mapLabels('workboard_default_start_time', translations)}
                                    label={translations.ONLINK_WORKBOARD_START_TIME}
                                    multiple={false}
                                    name='startTime'
                                    onChange={(ids) => onMultiSelectChange(ids, 'workboard_default_start_time')}
                                    selectedIds={[values.workboard_default_start_time]}
                                    tabIndex={0}
                                />
                            </div>
                        </div>
                        <div className='settings-group mobile'>
                            <div className='settings-block'>
                                <ValidationInput
                                    debounceTimeout={window.props.debounceTimeout}
                                    errors={{
                                        rangeUnderflow: greaterThanMessage,
                                        valueMissing: translations.REQUIRED_FIELD_TEXT
                                    }}
                                    label={translations.ONLINK_WEEKLY_WORK_HOURS}
                                    min={0}
                                    name='workboard_weekly_work_hours'
                                    onChange={onChange}
                                    required={true}
                                    setValid={setValid}
                                    step='any'
                                    tabIndex={0}
                                    type='number'
                                    value={values.workboard_weekly_work_hours}
                                />
                            </div>
                            <div className='settings-block'>
                                <ValidationInput
                                    debounceTimeout={window.props.debounceTimeout}
                                    errors={{
                                        rangeUnderflow: greaterThanMessage,
                                        valueMissing: translations.REQUIRED_FIELD_TEXT
                                    }}
                                    label={translations.ONLINK_DAILY_WORK_HOURS}
                                    min={0}
                                    name='workboard_employee_daily_hours_limit'
                                    onChange={onChange}
                                    required={true}
                                    setValid={setValid}
                                    step='any'
                                    tabIndex={0}
                                    type='number'
                                    value={values.workboard_employee_daily_hours_limit}
                                />
                            </div>
                        </div>
                        <div className='settings-group mobile'>
                            {getWorkboardCreationSetting(featureToggles, onNonEventChange, translations, values)}
                        </div>
                        <fieldset className='workboard-block editable'>
                            <legend className='workboard-settings-title'>
                                {translations.ONLINK_WORKBOARD_DISPLAY_SETTINGS}
                            </legend>
                            <div className='settings-group mobile'>
                                <div className='settings-block'>
                                    <div className='settings-block-row'>
                                        {translations.ONLINK_WORKBOARD_DISPLAY_GROUPING}
                                    </div>
                                    <div className='settings-group horizontal groups workboard-toggle-group'>
                                        {
                                            ['users', 'jobs'].map((label, i) => (
                                                <RadioButton
                                                    className={`workboard-${label}`}
                                                    id={label}
                                                    isSelected={values.workboard_presentation_groups === label}
                                                    key={i}
                                                    label={getLabel('workboard_presentation_groups', label, translations)}
                                                    onChange={(event) => onNonEventChange('workboard_presentation_groups', event.target.value)}
                                                    value={label}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='settings-block'>
                                    <MultiSelect
                                        className='multiselect'
                                        defaultSelectLabel={getLabel('workboard_presentation_font_size', values.workboard_presentation_font_size, translations)}
                                        hideFilter={true}
                                        items={mapLabels('workboard_presentation_font_size', translations)}
                                        label={translations.ONLINK_WORKBOARD_DISPLAY_FONT}
                                        multiple={false}
                                        name='fontSize'
                                        onChange={(ids) => onMultiSelectChange(ids, 'workboard_presentation_font_size')}
                                        selectedIds={[values.workboard_presentation_font_size]}
                                        tabIndex={0}
                                    />
                                </div>
                            </div>
                            <div className='settings-group mobile'>
                                <div className='settings-block'>
                                    <ValidationInput
                                        debounceTimeout={window.props.debounceTimeout}
                                        errors={{
                                            rangeUnderflow: valueGreaterThanOrEqualMessage,
                                            rangeOverflow: valueGreaterThanOrEqualMessage,
                                            valueMissing: translations.REQUIRED_FIELD_TEXT
                                        }}
                                        label={translations.ONLINK_WORKBOARD_COLUMNS}
                                        max={4}
                                        min={1}
                                        name='workboard_presentation_column_count'
                                        onChange={onChange}
                                        required={true}
                                        setValid={setValid}
                                        step='any'
                                        tabIndex={0}
                                        type='number'
                                        value={values.workboard_presentation_column_count}
                                    />
                                </div>
                                <div className='settings-block'>
                                    <MultiSelect
                                        className='multiselect'
                                        defaultSelectLabel={getLabel('workboard_presentation_autoscroll_speed', values.workboard_presentation_autoscroll_speed, translations)}
                                        hideFilter={true}
                                        items={mapLabels('workboard_presentation_autoscroll_speed', translations)}
                                        label={translations.ONLINK_WORKBOARD_DISPLAY_AUTO_SCROLL}
                                        multiple={false}
                                        name='autoScrollValue'
                                        onChange={(ids) => onMultiSelectChange(ids, 'workboard_presentation_autoscroll_speed')}
                                        selectedIds={[values.workboard_presentation_autoscroll_speed]}
                                        tabIndex={0}
                                    />
                                </div>
                            </div>
                            <div className='settings-group mobile'>
                                <div className='settings-block'>
                                    <MultiSelect
                                        className='multiselect'
                                        defaultSelectLabel={getLabel('workboard_presentation_font_size_notes', values.workboard_presentation_font_size_notes, translations)}
                                        hideFilter={true}
                                        items={mapLabels('workboard_presentation_font_size_notes', translations)}
                                        label={translations.ONLINK_NOTE_DISPLAY_FONT}
                                        multiple={false}
                                        name='notesFontSize'
                                        onChange={(ids) => onMultiSelectChange(ids, 'workboard_presentation_font_size_notes')}
                                        selectedIds={[values.workboard_presentation_font_size_notes]}
                                        tabIndex={0}
                                    />
                                </div>
                                <div className='settings-block'>
                                    <TextArea
                                        allowResize={false}
                                        className='workboard-text-area'
                                        debounceTimeout={window.props.debounceTimeout}
                                        label={translations.NOTES}
                                        name='workboard_presentation_note'
                                        onChange={onChange}
                                        tabIndex={0}
                                        value={values.workboard_presentation_note}
                                    />
                                </div>
                            </div>
                            {onLaborRefactorSettings}
                        </fieldset>

                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.workboard_presentation_show_weather}
                                className='workboard-switch-input'
                                name='workboard_presentation_show_weather'
                                onChange={onNonEventChange}
                                onLabel={translations.ONLINK_SHOW_WEATHER}
                                translations={translations}
                            />
                        </div>
                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.workboard_presentation_show_estimated_hours}
                                className='workboard-switch-input'
                                name='workboard_presentation_show_estimated_hours'
                                onChange={onNonEventChange}
                                onLabel={translations.ONLINK_SHOW_ESTIMATED_HOURS}
                                translations={translations}
                            />
                        </div>
                        <div className='settings-group horizontal'>
                            <SwitchInput
                                checked={values.workboard_copy_estimated_to_actual}
                                className='workboard-switch-input'
                                name='workboard_copy_estimated_to_actual'
                                onChange={onNonEventChange}
                                onLabel={translations.ONLINK_COPY_ESTIMATED_HOURS}
                                translations={translations}
                            />
                        </div>
                        {onLaborUpdatesSettings}
                    </form>
                    <div className='settings-block cover-photo-upload'>
                        <div className='settings-block-row'>{translations.ONLINK_COVER_PHOTO}</div>
                        <img
                            alt='Cover Photo'
                            className='cover-photo'
                            src={
                                membership.logoUrl === 'images/bg/default_membership_image.jpg' ?
                                    defaultImage : logoDataUrl
                            }
                        />
                        <form
                            encType='multipart/form-data'
                            onSubmit={(event) => onSubmit(files, setFiles, translations, event, addToast)}
                        >
                            <FileInput
                                accept='image/*'
                                files={files}
                                onChange={(event) => setFiles(event.target.files)}
                                tabIndex={0}
                                translations={translations}
                            />

                            <div className='max-image'>
                                {
                                    replaceTranslationNames(translations.ONLINK_MAX_IMAGE_SIZE, {
                                        '': MAX_FILE_SIZE
                                    })
                                }
                            </div>

                            <OnlinkButton
                                className='primary import-button'
                                leftIcon={<IconUpload iconUpload={ICON_UPLOAD_STYLE}/>}
                                type='submit'
                            >
                                {translations.UPLOAD}
                            </OnlinkButton>
                        </form>
                    </div>
                </div>
            </div>
            <SaveContentBar
                disabled={invalidInputs.size > 0}
                onCancelClick={setIsEditing}
                onSaveClick={() => onSave(values, membership, updateMembership, setIsEditing)}
                translations={translations}
            />
        </LoadingWrapper>
    );
}

EditableWorkboardSettings.propTypes = {
    addToast: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    setIsEditing: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateMembership(value) {
            dispatch(updateMembershipRedux(value));
        },
        addToast(value) {
            dispatch(addToastRedux(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EditableWorkboardSettings));
