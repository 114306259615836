// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect} from '@deere/form-controls';
import {useDeepEffect} from 'Utils/react-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

function getInputValidity(selectedIds, required, invalidCustomError) {
    return !invalidCustomError && (!required || selectedIds.some((id) => !isNullOrUndefined(id)));
}

function ValidationMultiSelect(props) {
    const {
        component: Component,
        disableClearValid,
        error,
        invalidCustomError,
        required,
        setValid,
        ...inputProps
    } = props;

    const {
        name,
        selectedIds
    } = inputProps;

    const [validity, setValidity] = React.useState(() => getInputValidity(selectedIds, required, invalidCustomError));

    useDeepEffect(() => {
        const valid = getInputValidity(selectedIds, required, invalidCustomError);

        setValid(name, valid);
        setValidity(valid);

        return () => {
            if (!disableClearValid) {
                setValid(name, true);
            }
        };
    }, [invalidCustomError, required, selectedIds]);

    const multiSelectComponent = (
        <Component
            {...inputProps}
            error={required && !validity && error || null}
        />
    );

    const requiredMultiSelectComponent = required ? (
        <div className='required-input'>
            {multiSelectComponent}
        </div>
    ) : multiSelectComponent;

    if (disableClearValid) {
        return (
            <div className={!validity ? 'in-valid-multiselect' : 'valid-multiselect'}>
                {requiredMultiSelectComponent}
            </div>
        );
    }

    return requiredMultiSelectComponent;
}

ValidationMultiSelect.defaultProps = {
    component: MultiSelect,
    disableClearValid: false,
    invalidCustomError: false,
    required: true
};

ValidationMultiSelect.propTypes = {
    component: PropTypes.elementType,
    disableClearValid: PropTypes.bool,
    error: PropTypes.string,
    invalidCustomError: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    selectedIds: PropTypes.arrayOf(PropTypes.stringOrNumber).isRequired,
    setValid: PropTypes.func.isRequired
};

export default ValidationMultiSelect;
