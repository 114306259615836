// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {MultiSelect} from '@deere/form-controls';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {cloneDeep} from 'lodash';

function getMultiSelectTitleById(list, id) {
    const listItem = list.find((item) => item.id === id);

    return listItem && listItem.title || null;
}

function EquipmentTypeInput(props) {
    const {
        equipmentAreaItems,
        equipmentTypeItems,
        equipmentTypeName,
        equipmentTypes,
        index,
        onChange,
        setValid,
        translations
    } = props;

    const equipmentType = equipmentTypes[index];

    const equipmentTypeId = equipmentTypeItems.length > 0 ? [equipmentType.equipmentTypeId] : [];
    const equipmentAreaId = equipmentAreaItems.length > 0 ? [equipmentType.equipmentAreaId] : [];

    return (
        <>
            <ValidationMultiSelect
                className='multiselect'
                defaultSelectLabel={translations.ONLINK_SELECT_EQUIPMENT_TYPE}
                error={translations.REQUIRED_FIELD_TEXT}
                items={equipmentTypeItems}
                label={translations.ONLINK_EQUIPMENT_TYPE}
                multiple={false}
                name={equipmentTypeName}
                onChange={(equipmentTypeIds) => {
                    const selectedEquipmentTypeId = equipmentTypeIds[0];
                    const clonedEquipmentTypes = cloneDeep(equipmentTypes);
                    const clonedEquipmentType = clonedEquipmentTypes[index];

                    clonedEquipmentType.equipmentTypeId = selectedEquipmentTypeId;
                    clonedEquipmentType.name = getMultiSelectTitleById(equipmentTypeItems, selectedEquipmentTypeId);

                    onChange(clonedEquipmentTypes);
                }}
                selectedIds={equipmentTypeId}
                setValid={setValid}
                tabIndex={0}
            />
            <MultiSelect
                className='multiselect'
                defaultSelectLabel={translations.ONLINK_ALL_AREAS}
                hideFilter={true}
                items={equipmentAreaItems}
                label={translations.AREA}
                multiple={false}
                name='equipmentArea'
                onChange={(equipmentAreaIds) => {
                    const selectedEquipmentAreaId = equipmentAreaIds[0];
                    const clonedEquipmentTypes = cloneDeep(equipmentTypes);
                    const clonedEquipmentType = clonedEquipmentTypes[index];

                    clonedEquipmentType.equipmentArea = getMultiSelectTitleById(equipmentAreaItems, selectedEquipmentAreaId);
                    clonedEquipmentType.equipmentAreaId = selectedEquipmentAreaId;

                    onChange(clonedEquipmentTypes);
                }}
                selectedIds={equipmentAreaId}
                tabIndex={0}
            />
        </>
    );
}

EquipmentTypeInput.propTypes = {
    equipmentAreaItems: PropTypes.multiSelectList,
    equipmentTypeItems: PropTypes.multiSelectList,
    equipmentTypeName: PropTypes.string,
    equipmentTypes: PropTypes.arrayOf(PropTypes.equipmentType),
    index: PropTypes.number,
    onChange: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default EquipmentTypeInput;
