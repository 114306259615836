// Unpublished Work © 2022-2024 Deere & Company.

import ClippingYieldEntryForm from 'Ui/components/manual-data/data-entry-forms/clipping-yield-entry-form';
import ElectricalCostEntryForm from 'Ui/components/manual-data/data-entry-forms/electrical-cost-entry-form';
import FirmnessEntryForm from 'Ui/components/manual-data/data-entry-forms/firmness-entry-form';
import RevenueEntryForm from 'Ui/components/manual-data/data-entry-forms/revenue-entry-form';
import GreenSpeedEntryForm from 'Ui/components/manual-data/data-entry-forms/green-speed-entry-form';
import SoilMoistureEntryForm from 'Ui/components/manual-data/data-entry-forms/soil-moisture-entry-form';
import FuelEntryForm from 'Ui/components/manual-data/data-entry-forms/fuel-entry-form';
import ElectricalConsumptionEntryForm
    from 'Ui/components/manual-data/data-entry-forms/electrical-consumption-entry-form';
import WaterCostEntryForm from 'Ui/components/manual-data/data-entry-forms/water-cost-entry-form';
import WaterFlowEntryForm from 'Ui/components/manual-data/data-entry-forms/water-flow-entry-form';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {
    CLIPPING_YIELD,
    ELECTRICAL_COST,
    ELECTRICAL_CONSUMPTION,
    FIRMNESS,
    FUEL,
    GREEN_SPEED,
    REVENUE,
    SOIL,
    TURF,
    WATER_COST,
    WATER_FLOW
} from 'Common/constants/data-group-constants';
import {BASKET, FEET_AND_INCHES, INCHES, METERS, MM} from 'Common/constants/data-unit-constants';

export const MANUAL_ENTRY_FORMS = {
    [GREEN_SPEED]: GreenSpeedEntryForm,
    [TURF]: FirmnessEntryForm,
    [CLIPPING_YIELD]: ClippingYieldEntryForm,
    [SOIL]: SoilMoistureEntryForm,
    [ELECTRICAL_COST]: ElectricalCostEntryForm,
    [TURF]: FirmnessEntryForm,
    [ELECTRICAL_CONSUMPTION]: ElectricalConsumptionEntryForm,
    [FUEL]: FuelEntryForm,
    [REVENUE]: RevenueEntryForm,
    [WATER_COST]: WaterCostEntryForm,
    [WATER_FLOW]: WaterFlowEntryForm
};

export const DEFAULT_UOM_FOR_DATATYPE = {
    [CLIPPING_YIELD]: {
        [UNIT_OF_MEASURE.ENGLISH]: BASKET,
        [UNIT_OF_MEASURE.METRIC]: BASKET
    },
    [GREEN_SPEED]: {
        [UNIT_OF_MEASURE.ENGLISH]: FEET_AND_INCHES,
        [UNIT_OF_MEASURE.METRIC]: METERS
    },
    [FIRMNESS]: {
        [UNIT_OF_MEASURE.ENGLISH]: INCHES,
        [UNIT_OF_MEASURE.METRIC]: MM
    }
};
