// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import SidebarListItem from 'Ui/components/map/sidebar/common/sidebar-list-item';
import {NOT_AVAILABLE} from 'Ui/constants/common-constants';
import {Icon} from '@deere/icons';
import accounting from 'accounting';
import {formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';

function EquipmentListItem(props) {
    const {
        active,
        equipment,
        onEquipmentSelect,
        translations
    } = props;

    const {
        engineHours,
        equipmentName,
        equipmentType,
        modelName,
        reportTime,
        serialNumber,
        icon
    } = equipment;

    const formattedReportTime = reportTime ?
        formatTimeOrDefault(reportTime) :
        NOT_AVAILABLE;

    const formattedEngineHours = engineHours ?
        accounting.formatNumber(engineHours, 1) :
        NOT_AVAILABLE;

    return (
        <SidebarListItem
            active={active}
            onClick={() => onEquipmentSelect(equipment)}
        >
            <div className='list-item-group'>
                <div className='icon-block'>
                    <Icon
                        height={53}
                        iconName={icon.name}
                        iconStyles={icon.iconStyle}
                        width={53}
                    />
                </div>
                <div className='list-item-block'>
                    <div className='bold-body-text-section-title'>{equipmentName}</div>
                    <div>{`${modelName} ${equipmentType}`}</div>
                    <div className='list-item-break-bottom'>{serialNumber}</div>
                </div>
            </div>
            <div className='list-item-group'>
                <div className='list-item-block'>
                    <div className='list-item-block-header'>{translations.ENGINE_HOURS}</div>
                    <div>{formattedEngineHours}</div>
                </div>
                <div className='list-item-block'>
                    <div className='list-item-block-header'>{translations.LAST_UPDATED}</div>
                    <div>{formattedReportTime}</div>
                </div>
            </div>
        </SidebarListItem>
    );
}

EquipmentListItem.propTypes = {
    active: PropTypes.bool,
    equipment: PropTypes.equipment,
    onEquipmentSelect: PropTypes.func,
    translations: PropTypes.translations
};

export default EquipmentListItem;
