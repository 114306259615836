// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';

function EditableDepartmentSettings(props) {
    const {
        name,
        onChange,
        onSave,
        setValid,
        translations
    } = props;

    return (
        <form
            className='settings-body editable-department-settings'
            onSubmit={onSave}
        >
            <ValidationInput
                autoFocus={true}
                debounceTimeout={window.props.debounceTimeout}
                errors={{
                    valueMissing: translations.REQUIRED_FIELD_TEXT
                }}
                label={translations.NAME}
                name='name'
                onChange={onChange}
                required={true}
                setValid={setValid}
                tabIndex={0}
                type='text'
                value={name}
            />
        </form>
    );
}

EditableDepartmentSettings.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default EditableDepartmentSettings;
