// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {USER} from 'Ui/constants/workboard-constants';

function WorkBoardJobDetailsSubCard(props) {
    const {
        item,
        translations,
        showName,
        showRoute
    } = props;

    const routeShown = showRoute && item.route;

    return (
        <div>
            <div
                className={'job-details'}
                key={`${item.title} ${item.user}`}
            >
                <div className={'job-details-block'}>
                    <div className={'job-details-label'}>
                        {showName === USER ? translations.ONLINK_EMPLOYEE : translations.jobs_job}
                    </div>
                    <div className={'job-details-content'}>
                        {`${item[showName]} ${item.estHours || ''}`}
                    </div>
                    {
                        showName !== USER && item.secondaryName &&
                        <div className='secondary-name'>
                            {`(${item.secondaryName})`}
                        </div>
                    }
                </div>

                {
                    item.equipment &&
                    <div className={'job-details-block'}>
                        <div className={'job-details-label'}>
                            {translations.IWP_EQUIPMENT_LABEL}
                        </div>
                        {
                            item.equipment.map((equip, index) => (
                                <div
                                    className={'job-details-content'}
                                    key={`${equip.equipmentName} ${index}`}
                                >
                                    {equip.equipmentName}
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    routeShown &&
                    <div className={'job-details-block'}>
                        <div className={'job-details-label'}>
                            {translations.ONLINK_ROUTE}
                        </div>
                        <div className={'job-details-content'}>
                            {
                                item.routeCustomDefn?.length > 0 ?
                                    `${item.route} - ${translations.ONLINK_HOLES} ${item.routeCustomDefn.join(', ')}` :
                                    item.route
                            }
                        </div>
                    </div>
                }
            </div>
            {
                item.note &&
                    <div className={'job-details-block'}>
                        <div className={'job-details-label'}>
                            {translations.NOTE}
                        </div>
                        <div className={'job-details-content'}>
                            {item.note}
                        </div>
                    </div>
            }
        </div>
    );
}

WorkBoardJobDetailsSubCard.propTypes = {
    item: PropTypes.object,
    showName: PropTypes.string,
    showRoute: PropTypes.bool,
    translations: PropTypes.translations

};

export default WorkBoardJobDetailsSubCard;
