// Unpublished Work © 2023-2024 Deere & Company.

import {
    ADD_COURSE_REVENUE_COST,
    MANAGE_EQUIPMENT_SETTINGS,
    ADD_COURSE_MEASUREMENT_DATA,
    DELETE_COURSE_MEASUREMENT_DATA,
    EDIT_COURSE_MEASUREMENT_DATA,
    VIEW_COURSE_MEASUREMENT_DATA
} from 'Common/constants/business-activities';
import {
    ELECTRICAL_COST,
    FUEL,
    REVENUE,
    WATER_COST,
    HOC,
    HOC_SPEC
} from 'Common/constants/data-group-constants';

// manual data forms organized by data groups
export const MANUAL_DATA_GROUP_REQUIREMENTS = {
    [ELECTRICAL_COST]: ADD_COURSE_REVENUE_COST,
    [FUEL]: ADD_COURSE_REVENUE_COST,
    [REVENUE]: ADD_COURSE_REVENUE_COST,
    [WATER_COST]: ADD_COURSE_REVENUE_COST,
    [HOC]: MANAGE_EQUIPMENT_SETTINGS,
    [HOC_SPEC]: MANAGE_EQUIPMENT_SETTINGS
};

export const MANUAL_DATA_PERMISSIONS_BY_REQUEST_TYPE = {
    get: VIEW_COURSE_MEASUREMENT_DATA,
    put: EDIT_COURSE_MEASUREMENT_DATA,
    post: ADD_COURSE_MEASUREMENT_DATA,
    delete: DELETE_COURSE_MEASUREMENT_DATA
};
