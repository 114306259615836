// Unpublished Work © 2020-2024 Deere & Company.

import {graphRedirect, foreTeesRedirect} from 'Utils/redirect-utils';
import {VIEW_COURSE_PLAYER_DATA, VIEW_COURSE_MEASUREMENT_DATA} from 'Common/constants/business-activities';

const ROUNDS_0_ID = '1fcdd0aa-0dd8-4763-b275-669548f1d9c8';
const ROUNDS_1_ID = '11db83ab-0afa-49e8-8973-9980925d5627';
const ROUNDS_2_ID = '02ea3a5a-5a6d-4a04-93d7-8c498b312eb8';

const ROUNDS_URL = 'panelTypes/rounds';

export default {
    [ROUNDS_0_ID]: {
        data: [],
        integration: 'ForeTees',
        name: 'rounds_0',
        onClick: foreTeesRedirect,
        tileId: ROUNDS_0_ID,
        url: ROUNDS_URL,
        myJdPermissions: VIEW_COURSE_PLAYER_DATA
    },
    [ROUNDS_1_ID]: {
        data: [
            'rounds',
            'total_revenue'
        ],
        dateSelect: 'month',
        graphTitle: 'Rounds',
        name: 'rounds_1',
        onClick: graphRedirect,
        tileId: ROUNDS_1_ID,
        url: ROUNDS_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    },
    [ROUNDS_2_ID]: {
        data: [
            'rounds',
            'total_revenue'
        ],
        dateSelect: 'year',
        graphTitle: 'Rounds',
        name: 'rounds_2',
        onClick: graphRedirect,
        tileId: ROUNDS_2_ID,
        url: ROUNDS_URL,
        myJdPermissions: VIEW_COURSE_MEASUREMENT_DATA
    }
};
