// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiselectFilterList, Typography} from '@deere/isg.component-library';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import Stack from '@mui/material/Stack';
import {MultiSelect} from '@deere/form-controls';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import MultiSelectWithCheckList from 'Ui/components/common/form/multi-select-with-checklist';
import Notes from 'OnEquip/equipment/service-form/notes';
import ReadOnlyList from 'Ui/components/common/form/read-only/read-only-list';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';
import ServiceFormParts from 'Ui/features/onequip/equipment/service-form/service-form-parts';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import TelematicsEnabled from 'Ui/components/common/data-table/telematics-enabled';
import Timer from 'OnEquip/equipment/service-form/timer';
import ValidationDate from 'Ui/components/common/form/validation-date';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {ENGINE_HOURS_REGEX, MAX_ENGINE_HOURS} from 'Ui/constants/equipment-constants';
import permissionsConstants from 'Common/constants/permissions';
import serviceStatusConstants from 'Ui/constants/service-status-constants';
import {LABEL_FONT_STYLE} from 'Ui/constants/read-only/font-style';
import serviceGroupConstants from 'OnEquip/equipment/service-form/utils/service-group-constants';
import {updateActualEngineHours, updateHoc} from 'Ui/features/onequip/equipment/service-form/service-form-helper';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {hasPermissions} from 'Common/utils/permission-handler';
import moment from 'moment';
import {IconHelp} from '@deere/icons';
import {SERVICE_TICKET_EDIT, SERVICE_TICKET_INFO_ICON} from 'Common/constants/feature-toggles';
import {JD_GREEN_THEME, ONLINK_BLUE} from 'Ui/constants/theme';

const SHARED_CONTAINER_PROPS = {
    marginBottom: '10px'
};
const SHARED_TYPOGRAPHY_PROPS = {
    ...LABEL_FONT_STYLE,
    noWrap: true
};
const ICON_PRIMARY_STYLE = (isMigrated) => ({
    style: {
        fill: isMigrated ? JD_GREEN_THEME : ONLINK_BLUE
    }
});
const ICON_STYLE = {
    style: {
        verticalAlign: 'middle',
        height: '20px',
        width: '20px'
    }
};

function ServiceFormInputs(props) {
    const {
        allData,
        currentEquipment,
        editedServiceStatus,
        hoc,
        invalidInputs,
        loading,
        membership,
        modelMaintenanceServiceTaskIds,
        permissions,
        readOnly,
        serviceId,
        setCurrentEquipment,
        setHoc,
        setValid,
        setValues,
        statusCollection,
        superUser,
        timerStatusChange,
        translations,
        values,
        isMigrated,
        featureToggles
    } = props;

    const onChange = React.useCallback((event) => {
        const {
            name,
            value
        } = event.target;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }, []);

    const onChangeFormatValue = React.useCallback((event) => {
        const {
            name,
            value
        } = event.target;
        const parsedValue = value?.match(ENGINE_HOURS_REGEX)?.[0] || '';

        onChange({
            target: {
                name,
                value: parsedValue
            }
        });
    }, []);

    const onChangeArrayWrapper = React.useCallback((name, value) => {
        const event = {
            target: {
                name,
                value
            }
        };

        onChange(event);
    }, []);

    const {
        additionalServiceTasks,
        disableCompletedServiceFields,
        editingNonCompleted
    } = React.useMemo(() => {
        return {
            additionalServiceTasks: values.serviceTasks.map((serviceTask) => ({
                ...serviceTask,
                id: serviceTask.serviceTaskTypeId,
                label: serviceTask.serviceTask
            })),
            disableCompletedServiceFields: editedServiceStatus === serviceStatusConstants.completed.id &&
                !(isMigrated && featureToggles[SERVICE_TICKET_EDIT]),
            editingNonCompleted: Boolean(values.serviceId) && editedServiceStatus !== serviceStatusConstants.completed.id
        };
    }, [values.serviceTasks, values.serviceId, editedServiceStatus, isMigrated, featureToggles]);

    const disableDuration = React.useMemo(() => {
        const credentials = {
            permissionMap: permissions,
            superUser
        };

        const isNewOrInProcess = !serviceId || editedServiceStatus === serviceStatusConstants.in_process.id;
        const doesNotHavePermission = !credentials.isMigrated && isNewOrInProcess &&
            !hasPermissions(credentials, permissionsConstants.DELETE_SERVICE_TICKETS);

        return disableCompletedServiceFields || doesNotHavePermission;
    }, [editedServiceStatus]);

    const {
        actualEngineHoursLabelProps,
        assignedToLabelProps
    } = React.useMemo(() => {
        if (readOnly) {
            return {
                actualEngineHoursLabelProps: {},
                assignedToLabelProps: {}
            };
        }

        const sharedLabelProps = {
            fontSize: '16px'
        };

        return {
            actualEngineHoursLabelProps: {
                ...sharedLabelProps,
                marginBottom: '8px'
            },
            assignedToLabelProps: sharedLabelProps
        };
    }, [readOnly]);
    const languagePreference = React.useMemo(getLanguagePreference, []);

    return (
        <form className='settings-body'>
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                <div className='settings-group mobile'>
                    <ReadOnlyWrapperComponent
                        label={translations.IWP_EQUIPMENT_LABEL}
                        readOnly={readOnly}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            value: currentEquipment?.equipmentName
                        }}
                        wrappedComponent={ValidationMultiSelect}
                        wrappedProps={{
                            className: 'mobile-required-multiselect',
                            component: SortedMultiSelect,
                            defaultSelectLabel: translations.FILES_SELECT_EQUIPMENT,
                            disabled: Boolean(values.serviceId),
                            error: translations.REQUIRED_FIELD_TEXT,
                            items: allData.equipmentOptions,
                            name: 'equipment',
                            onChange: ([selectedId]) => {
                                updateHoc(allData.equipmentOptions, selectedId, values.serviceDate, membership, setHoc, translations);
                                onChangeArrayWrapper('equipmentId', selectedId);
                                updateActualEngineHours(allData.equipmentOptions, selectedId, setValues, setCurrentEquipment);
                            },
                            required: true,
                            selectedIds: [values.equipmentId],
                            setValid,
                            tabIndex: 0
                        }}
                    />
                </div>
                {
                    hoc &&
                    <div className='settings-group mobile'>
                        <div className='hoc'>
                            <div className='equipment-area title-1'>
                                {hoc.equipmentArea}
                            </div>
                            <div className='hoc-value'>
                                {hoc.value}
                            </div>
                        </div>
                    </div>
                }
                <div className='settings-group mobile'>
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_DUE_AT_HOURS}
                        readOnly={readOnly}
                        readOnlyProps={SHARED_CONTAINER_PROPS}
                        value={values.atHours}
                        wrappedComponent={ValidationInput}
                        wrappedProps={{
                            disabled: editingNonCompleted || disableCompletedServiceFields,
                            errors: {
                                rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
                                    '0': 0
                                }),
                                rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_ENGINE_HOURS}`
                            },
                            max: MAX_ENGINE_HOURS,
                            min: 0,
                            name: 'atHours',
                            onChange,
                            setValid,
                            tabIndex: 0,
                            type: 'number'
                        }}
                    />
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_DUE_AT_DATE}
                        readOnly={readOnly}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            value: moment(values.dueAtDate).format('L')
                        }}
                        wrappedComponent={ValidationDate}
                        wrappedProps={{
                            dateFormat: 'L',
                            large: true,
                            locale: languagePreference,
                            name: 'dueAtDate',
                            onChange: (date) => {
                                if (date) {
                                    onChange({
                                        target: {
                                            name: 'dueAtDate',
                                            value: date
                                        }
                                    });
                                }
                            },
                            readOnly: disableCompletedServiceFields,
                            required: values.dueAtDateRequired,
                            selected: values.dueAtDate,
                            setValid,
                            tabIndex: 0
                        }}
                    />
                </div>
                <div className='settings-group mobile'>
                    <div>
                        <Stack direction='row'>
                            <Typography
                                {...SHARED_TYPOGRAPHY_PROPS}
                                {...actualEngineHoursLabelProps}
                                id='actual-engine-hours-label'
                                marginRight='8px'
                            >
                                {translations.ONLINK_ACTUAL_ENGINE_HOURS}
                            </Typography>
                            <TelematicsEnabled equipment={currentEquipment}/>
                            {
                                featureToggles[SERVICE_TICKET_INFO_ICON] &&
                                <Tooltip
                                    placement='right'
                                    title={translations.ONLINK_ENGINE_HOURS_NOTICE}
                                >
                                    <span className={`tooltip-icon ${isMigrated ? 'green-hover' : 'blue-hover'}`}>
                                        <IconHelp
                                            iconHelp={ICON_STYLE}
                                            primary={ICON_PRIMARY_STYLE(isMigrated)}
                                        />
                                    </span>
                                </Tooltip>
                            }
                        </Stack>
                        <ReadOnlyWrapperComponent
                            readOnly={readOnly}
                            readOnlyProps={SHARED_CONTAINER_PROPS}
                            value={values.totalHours}
                            wrappedComponent={ValidationInput}
                            wrappedProps={{
                                errors: {
                                    rangeUnderflow: replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
                                        '0': 0
                                    }),
                                    rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_ENGINE_HOURS}`
                                },
                                max: MAX_ENGINE_HOURS,
                                min: 0,
                                name: 'totalHours',
                                onChange: onChangeFormatValue,
                                setValid,
                                tabIndex: 0,
                                type: 'number'
                            }}
                        />
                    </div>
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_SERVICE_DATE}
                        readOnly={readOnly}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            value: moment(values.serviceDate).format('L')
                        }}
                        wrappedComponent={ValidationDate}
                        wrappedProps={{
                            dateFormat: 'L',
                            large: true,
                            locale: languagePreference,
                            name: 'serviceDate',
                            onChange: (date) => {
                                updateHoc(allData.equipmentOptions, values.equipmentId, date, membership, setHoc, translations);
                                onChange({
                                    target: {
                                        name: 'serviceDate',
                                        value: date
                                    }
                                });
                            },
                            readOnly: disableCompletedServiceFields,
                            required: true,
                            selected: values.serviceDate,
                            setValid,
                            tabIndex: 0
                        }}
                    />
                </div>
                <div className='settings-group mobile'>
                    <ReadOnlyWrapperComponent
                        label={translations.STATUS}
                        readOnly={readOnly}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            value: serviceStatusConstants[values.status]?.title
                        }}
                        wrappedComponent={MultiSelect}
                        wrappedProps={{
                            className: 'multiselect',
                            items: statusCollection,
                            name: 'status',
                            onChange: ([selectedId]) => {
                                if (selectedId) {
                                    onChangeArrayWrapper('status', selectedId);
                                }
                            },
                            selectedIds: [values.status],
                            tabIndex: 0
                        }}
                    />
                    <Timer
                        disabled={invalidInputs.size > 0 || loading || editedServiceStatus === serviceStatusConstants.completed.id}
                        onStatusChange={timerStatusChange}
                        readOnly={readOnly}
                        serviceId={values.serviceId}
                        translations={translations}
                    />
                </div>
                <div className='settings-group mobile'>
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_SERVICE_TYPE}
                        readOnly={readOnly}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            value: values.serviceTypeName
                        }}
                        wrappedComponent={ValidationMultiSelect}
                        wrappedProps={{
                            className: 'mobile-required-multiselect',
                            component: SortedMultiSelect,
                            defaultSelectLabel: translations.ONLINK_SELECT_SERVICE_TYPE,
                            disabled: Boolean(values.serviceId),
                            error: translations.REQUIRED_FIELD_TEXT,
                            items: allData.serviceTypesOptions,
                            name: 'serviceType',
                            onChange: ([selectedId]) => onChangeArrayWrapper('serviceTypeId', selectedId),
                            selectedIds: [values.serviceTypeId],
                            setValid,
                            tabIndex: 0
                        }}
                    />
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_SERVICE_GROUP}
                        readOnly={readOnly}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            value: values.serviceGroup
                        }}
                        wrappedComponent={MultiSelect}
                        wrappedProps={{
                            className: 'multiselect',
                            disabled: false,
                            items: [
                                {
                                    id: serviceGroupConstants.maintenance,
                                    title: translations.ONLINK_MAINTENANCE
                                },
                                {
                                    id: serviceGroupConstants.repair,
                                    title: translations.Repair
                                },
                                {
                                    id: serviceGroupConstants.damageRepair,
                                    title: translations.ONLINK_DAMAGE_REPAIR
                                }
                            ],
                            name: 'serviceGroup',
                            onChange: ([selectedId]) => onChangeArrayWrapper('serviceGroup', selectedId),
                            selectedIds: [values.serviceGroup],
                            tabIndex: 0
                        }}
                    />
                </div>
                <div className='settings-group mobile'>
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_SERVICE_TASKS}
                        readOnly={readOnly}
                        readOnlyComponent={ReadOnlyList}
                        readOnlyProps={{
                            ...SHARED_CONTAINER_PROPS,
                            items: additionalServiceTasks.map((serviceTask) => ({
                                id: serviceTask.id,
                                title: serviceTask.label
                            })),
                            noItemsSelectedLabel: translations.ONLINK_NO_SERVICE_TASKS_SELECTED,
                            selectedIds: values.serviceTasksCheckedIds
                        }}
                        wrappedComponent={MultiSelectWithCheckList}
                        wrappedProps={{
                            additionalCheckListItems: additionalServiceTasks,
                            allMultiSelectOptions: allData.serviceTasksOptions,
                            checkListName: 'serviceTasksCheckedIds',
                            checkListSelectedIds: values.serviceTasksCheckedIds,
                            defaultSelectLabel: translations.ONLINK_ADD_SERVICE_TASK,
                            multiSelectName: 'serviceTasksSelectedIds',
                            multiSelectSelectedIds: values.serviceTasksSelectedIds,
                            onChange: onChangeArrayWrapper,
                            onDelete: (serviceTask) => {
                                setValues((prevValues) => ({
                                    ...prevValues,
                                    serviceTasksSelectedIds: prevValues.serviceTasksSelectedIds.filter((id) => id !== serviceTask.id),
                                    serviceTasksCheckedIds: prevValues.serviceTasksCheckedIds.filter((id) => id !== serviceTask.id)
                                }));
                            },
                            showDelete: (id) => !modelMaintenanceServiceTaskIds.has(id)
                        }}
                    />
                    <Stack
                        className='assign-form-columns'
                        height='100%'
                        paddingBottom='5px'
                        sx={{
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                        <Typography
                            {...SHARED_TYPOGRAPHY_PROPS}
                            {...assignedToLabelProps}
                            marginBottom='2px'
                        >
                            {translations.ONLINK_ASSIGNED_TO}
                        </Typography>
                        <ReadOnlyWrapperComponent
                            items={disableCompletedServiceFields ?
                                allData.serviceAssignableUsers.map((item) => {
                                    return {
                                        ...item,
                                        disabled: true
                                    };
                                }) : allData.serviceAssignableUsers}
                            readOnly={readOnly}
                            readOnlyComponent={ReadOnlyList}
                            readOnlyProps={SHARED_CONTAINER_PROPS}
                            selectedIds={values.assignedTo}
                            wrappedComponent={MultiselectFilterList}
                            wrappedProps={{
                                className: 'mobile-required-multiselect',
                                emptyListLabel: translations.ONLINK_SELECT_ASSIGNEE,
                                noItemMatchLabel: translations.ONLINK_SELECT_ASSIGNEE,
                                onChange: (selectedIds) => onChangeArrayWrapper('assignedTo', selectedIds),
                                selectAllItemLabel: null
                            }}
                        />
                    </Stack>
                </div>
                <div className='settings-group mobile'>
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_ESTIMATED_DURATION_MINUTES}
                        readOnly={readOnly}
                        readOnlyProps={SHARED_CONTAINER_PROPS}
                        value={values.estDuration}
                        wrappedComponent={ValidationInput}
                        wrappedProps={{
                            name: 'estDuration',
                            onChange,
                            setValid,
                            tabIndex: 0,
                            type: 'number'
                        }}
                    />
                    <ReadOnlyWrapperComponent
                        label={translations.ONLINK_ACTUAL_DURATION_MINUTES}
                        readOnly={readOnly}
                        readOnlyProps={SHARED_CONTAINER_PROPS}
                        value={values.actualDuration}
                        wrappedComponent={ValidationInput}
                        wrappedProps={{
                            disabled: disableDuration,
                            name: 'actualDuration',
                            onChange,
                            setValid,
                            tabIndex: 0,
                            type: 'number'
                        }}
                    />
                </div>
                <Notes
                    editableNote={values.editableNote}
                    existingNotes={values.notes}
                    onChange={onChange}
                    readOnly={readOnly}
                    translations={translations}
                />
                <ServiceFormParts
                    disabled={disableCompletedServiceFields}
                    readOnly={readOnly}
                    selectedParts={values.partsList}
                    setSelectedParts={onChange}
                    setValid={setValid}
                    translations={translations}
                />
            </LoadingWrapper>
        </form>
    );
}

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        permissions: state.account.permissions,
        superUser: state.account.superUser
    };
}

ServiceFormInputs.propTypes = {
    allData: PropTypes.object,
    currentEquipment: PropTypes.object,
    editedServiceStatus: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    hoc: PropTypes.object,
    invalidInputs: PropTypes.instanceOf(Set),
    isMigrated: PropTypes.bool,
    loading: PropTypes.bool,
    membership: PropTypes.membership,
    modelMaintenanceServiceTaskIds: PropTypes.instanceOf(Set),
    permissions: PropTypes.legacyPermissions,
    readOnly: PropTypes.bool,
    serviceId: PropTypes.string,
    setCurrentEquipment: PropTypes.func,
    setHoc: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    statusCollection: PropTypes.arrayOf(PropTypes.object),
    superUser: PropTypes.bool,
    timerStatusChange: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default connect(mapStateToProps)(ServiceFormInputs);
