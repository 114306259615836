// Unpublished Work © 2023-2024 Deere & Company.

import {convert} from '@deere/unit-system';
import {FUEL_COST, GAS_COST, GREEN_SPEED} from 'Common/constants/data-group-constants';
import {UNIT_OF_MEASURE} from 'Common/constants/preference-constants';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {convertToUoM, getUnitOfMeasureOverride, isMetricUoM, roundValue} from 'Common/utils/units/unit-converter';

function convertThresholdForSave(thresholdValue, conversionConfig) {
    const {
        dataType,
        metricToImperial,
        shouldConvertValue = isMetricUoM,
        unitOfMeasure,
        unitOfMeasureOverrides
    } = conversionConfig;

    if (dataType === FUEL_COST || dataType === GAS_COST) {
        if (shouldConvertValue(getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, dataType))) {
            return metricToImperial ?
                convert(thresholdValue, UNITS.liters, UNITS.gallons) :
                convert(thresholdValue, UNITS.gallons, UNITS.liters);
        }

        return thresholdValue;
    }

    return convertToUoM(thresholdValue, conversionConfig);
}

function convertThresholdToUoM(thresholdValue, conversionConfig) {
    const {
        dataType, unitOfMeasure, unitOfMeasureOverrides
    } = conversionConfig;

    if (dataType === GREEN_SPEED &&
        getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, GREEN_SPEED) === UNIT_OF_MEASURE.ENGLISH) {
        return roundValue(thresholdValue, conversionConfig);
    }

    const convertedValue = convertToUoM(thresholdValue, conversionConfig);

    return roundValue(convertedValue, conversionConfig);
}

export {
    convertThresholdForSave,
    convertThresholdToUoM
};
