// Unpublished Work © 2021-2024 Deere & Company.

import * as mowingPatternIcons from 'Ui/images/mowing-patterns';
import mergeImages from 'merge-images';
import {
    IconChaps,
    IconEyeProtection,
    IconGloves,
    IconHardHat,
    IconHearingProtection,
    IconProtectiveFootwear,
    IconRespirator,
    IconSafetySuit,
    IconSunHat,
    IconSunscreen,
    IconVest
} from '@deere/icons';
import React from 'react';
import {PPE_ICONS} from 'Ui/constants/labor-constants';

function getMowingPatternIcon(iconNameAlias) {
    return mowingPatternIcons[iconNameAlias];
}

async function getMowingPatternCompositeImage(iconAliasArray) {
    const icons = iconAliasArray.map((iconAlias) => getMowingPatternIcon(iconAlias));

    return mergeImages(icons);
}

function createIconFillStyle(fill) {
    return {
        style: {
            fill
        }
    };
}

function createPpeIconMap(ICON_STYLE, PRIMARY_ICON_STYLE = {}) {
    return {
        [PPE_ICONS.CHAPS]: <IconChaps
            iconChaps={ICON_STYLE}
            key='iconChaps'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.EYE_PROTECTION]: <IconEyeProtection
            iconEyeProtection={ICON_STYLE}
            key='iconEyeProtection'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.GLOVES]: <IconGloves
            iconGloves={ICON_STYLE}
            key='iconGloves'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.HARD_HAT]: <IconHardHat
            iconHardHat={ICON_STYLE}
            key='iconHardHat'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.HEARING_PROTECTION]: <IconHearingProtection
            iconHearingProtection={ICON_STYLE}
            key='iconHearingProtection'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.PROTECTIVE_FOOTWEAR]: <IconProtectiveFootwear
            iconProtectiveFootwear={ICON_STYLE}
            key='iconProtectiveFootwear'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.RESPIRATOR]: <IconRespirator
            iconRespirator={ICON_STYLE}
            key='iconRespirator'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.SAFETY_SUIT]: <IconSafetySuit
            iconSafetySuit={ICON_STYLE}
            key='iconSafetySuit'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.VEST]: <IconVest
            iconVest={ICON_STYLE}
            key='iconVest'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.SUN_HAT]: <IconSunHat
            iconSunHat={ICON_STYLE}
            key='iconSunHat'
            primary={PRIMARY_ICON_STYLE}
        />,
        [PPE_ICONS.SUNSCREEN]: <IconSunscreen
            iconSunscreen={ICON_STYLE}
            key='iconSunscreen'
            primary={PRIMARY_ICON_STYLE}
        />
    };
}

export {
    createIconFillStyle,
    getMowingPatternCompositeImage,
    createPpeIconMap
};
