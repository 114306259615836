// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import LaborReport from 'OnLabor/report/labor-report';
import WorkboardReport from 'OnLabor/report/workboard-report';
import {withRouter} from 'react-router-dom';

const laborReportPages = {
    'labor': LaborReport,
    'workboard': WorkboardReport
};

function ReportWrapper(props) {
    const {
        translations,
        match: {params: {type}}
    } = props;

    const ReportComponentToRender = React.useMemo(
        () => laborReportPages[type],
        [type]
    );

    return (
        <div className='report-wrapper'>
            <ReportComponentToRender
                translations={translations}
            />
        </div>
    );
}

ReportWrapper.propTypes = {
    match: PropTypes.match,
    translations: PropTypes.translations
};

export default withRouter(ReportWrapper);
