// Unpublished Work © 2022-2024 Deere & Company.

import DataTable from 'Ui/components/common/data-table/data-table';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {transformAndSortTodos} from 'Utils/workboard-utils';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {BasicDialog} from '@deere/basic-dialog';
import {Checkbox} from '@deere/isg.component-library';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {deleteTodo, updateTodo} from 'Services/todo-service';
import {connect} from 'react-redux';
import {addToast} from 'Store/actions/toasts';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import {dateCompare} from 'Ui/models/maintenance';
import OnlinkButton from 'Ui/components/common/onlink-button';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {MANAGE_WORKBOARDS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

function getColumns(translations, saveTodo, fetchTodos, openAddTodoDialog, canEditTodo) {
    return [
        {
            Header: `${translations.COMPLETE}?`,
            accessor: 'completed',
            width: 130,
            Cell(row) {
                return (
                    <Checkbox
                        checked={row.original.completed}
                        classes={{
                            root: 'checkbox-root'
                        }}
                        disabled={!canEditTodo}
                        onChange={() => saveTodo(row.original)}
                        size='small'
                    />
                );
            }

        },
        {
            Header: translations.DATE,
            width: 90,
            sortMethod: dateCompare,
            accessor: 'formattedDate'
        },
        {
            Header: translations.ONLINK_ASSIGNED_TO,
            width: 160,
            accessor: 'userData'
        },
        {
            Header: translations.ONLINK_TODO,
            accessor: 'title',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                return (
                    <EditLink
                        onClick={() => {
                            openAddTodoDialog({
                                todo: original,
                                fetchTodos
                            });
                        }}
                        requiredMyJdPermissions={[MANAGE_WORKBOARDS]}
                    >
                        {value}
                    </EditLink>
                );
            }
        }
    ];
}

function getFilterComponent(translations, hideCompleted, setHideCompleted, openAddTodoDialog, fetchTodos, canEditTodo) {
    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={hideCompleted}
                name='showCompleted'
                onChange={() => setHideCompleted(!hideCompleted)}
                onLabel={translations.ONLINK_HIDE_COMPLETED}
                translations={translations}
            />
            {
                canEditTodo && <OnlinkButton
                    className={'onlink-btn todo-btn'}
                    onClick={() => openAddTodoDialog({
                        fetchTodos
                    })}
                >
                    {translations.ONLINK_ADD_TODO}
                </OnlinkButton>
            }
        </div>
    );
}

function ManageTodoDialog(props) {
    const {
        todos,
        translations,
        closeManageTodoDialog,
        addToast,
        fetchTodos,
        openConfirmation,
        openAddTodoDialog,
        closeConfirmation
    } = props;

    const [loading, setLoading] = React.useState(() => false);
    const [hideCompleted, setHideCompleted] = React.useState(() => true);
    const [filteredTodos, setFilteredTodos] = React.useState([]);

    function filterData() {
        setFilteredTodos(transformAndSortTodos(todos.current, hideCompleted));
    }

    async function fetchAndFilterData() {
        await fetchTodos();
        filterData();
    }

    async function saveTodo(todoItem) {
        const todoData = {
            ...todoItem,
            completed: !todoItem.completed,
            dateSelect: formatTime(new Date(), DATE_FORMATS.day)
        };

        setLoading(true);
        await updateTodo(todoData, todoItem.id);
        await fetchAndFilterData();
        setLoading(false);
    }

    async function deleteTodoById(todo) {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_TODO.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_TODO
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    setLoading(true);
                    await deleteTodo(todo.todoId);
                    await fetchAndFilterData();
                    setLoading(false);
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.ONLINK_TODO.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }
    React.useEffect(() => fetchEffectData(filterData), [todos.current, hideCompleted]);

    const canEditTodo = isAuthorized({
        myJdPermissions: MANAGE_WORKBOARDS
    }, props);

    return (
        <BasicDialog
            className='manage-todo-dialog'
            closeHandler={() => {
                closeManageTodoDialog();
            }}
            show={true}
            title={translations.ONLINK_QUICK_TODOS}
        >
            <div className='table-cover'>
                <DataTable
                    columns={getColumns(translations, saveTodo, fetchAndFilterData, openAddTodoDialog, canEditTodo)}
                    defaultSorted={[{
                        desc: true,
                        id: 'formattedDate'
                    }]}
                    deleteHandler={deleteTodoById}
                    filterComponent={getFilterComponent(translations, hideCompleted, setHideCompleted, openAddTodoDialog, fetchAndFilterData, canEditTodo)}
                    loading={loading}
                    rows={filteredTodos}
                    searchable={true}
                    showDeleteColumn={canEditTodo}
                    translations={translations}
                />
            </div>
        </BasicDialog>
    );
}

ManageTodoDialog.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    closeManageTodoDialog: PropTypes.func,
    fetchTodos: PropTypes.func,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    onClose: PropTypes.func,
    openAddTodoDialog: PropTypes.func,
    openConfirmation: PropTypes.func,
    todos: PropTypes.object,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToast(value));
        },
        closeManageTodoDialog() {
            dispatch(closeDialog(dialogTypes.MANAGE_TODO_DIALOG));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openAddTodoDialog(props) {
            dispatch(openDialog(dialogTypes.ADD_TODO_DIALOG, props));
        }
    };
}

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTodoDialog);
