// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getManualData(manualDataId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/manualData/${manualDataId}`);

    return response.data;
}

async function postManualData(manualData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/manualData', manualData);

    return response.data;
}

async function putManualData(manualData, manualDataId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/manualData/${manualDataId}`, manualData);

    return response.data;
}

async function deleteManualData(manualDataId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/manualData/${manualDataId}`);

    return response.data;
}

async function getManualDataHistory(dataGroup) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/manualDataHistory/${dataGroup}`);

    return response.data;
}

export {
    getManualData,
    getManualDataHistory,
    postManualData,
    putManualData,
    deleteManualData
};
