// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MyJohnDeereDataTable from 'Ui/components/settings/myJohnDeere/myJohnDeere-data-table';
import OnlinkButton from 'Ui/components/common/onlink-button';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {getMyJDConnections} from 'Services/my-jd-connection-service';
import {getMyJDMakeConnectionsAccess, getMyJDUserId} from 'Services/my-jd-user-service';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast} from 'Store/actions/toasts';
import {AVAILABLE, CONNECTED, INVALID} from 'Common/constants/connection-status';
import {ACCOUNT_MISMATCH} from 'Common/constants/errors';
import dialogTypes from 'Ui/components/common/dialog-types';
import {IconNewWindow} from '@deere/icons';
import {TOAST_TYPE} from '@deere/toast';

const ICON_STYLE = {
    style: {
        height: '17px',
        width: '17px'
    }
};

const STATUS_CLASSNAMES = {
    [AVAILABLE]: 'green',
    [INVALID]: 'red'
};

function openManageConnectionsModal(translations, openConfirmation, closeConfirmation, url) {
    openConfirmation({
        message: translations.ONLINK_MANAGE_CONNECTIONS_MESSAGE,
        title: translations.ONLINK_MANAGE_CONNECTIONS,
        onContinue() {
            closeConfirmation();
            window.open(url, '_blank');
        },
        onCancel: closeConfirmation
    });
}

function getColumns(translations, hasMakeConnectionsAccess, openConfirmation, closeConfirmation) {
    return [
        {
            Header: translations.ONLINK_MEMBERSHIP,
            accessor: 'membershipName',
            className: 'sticky',
            headerClassName: 'sticky'
        },
        {
            Header: translations.organization,
            accessor: 'orgName'
        },
        {
            Header: translations.ID,
            accessor: 'orgId',
            width: 100
        },
        {
            Header: translations.user,
            accessor: 'createdBy',
            width: 130
        },
        {
            Header: translations.STATUS,
            accessor: 'status',
            width: 130,
            Cell(row) {
                const {status} = row.original;
                const statusClassName = STATUS_CLASSNAMES[status];

                return (
                    <span className={statusClassName}>
                        {translations[status] || status}
                    </span>
                );
            }
        },
        {
            accessor: 'actions',
            resizable: false,
            sortable: false,
            width: 30,
            Cell(row) {
                const {
                    isOrgMember,
                    orgId,
                    status
                } = row.original;
                const connectionsUrlWithAppId = `${window.props.connectionsUrl}/connections/${window.props.applicationId}`;

                if (hasMakeConnectionsAccess && isOrgMember && status === CONNECTED) {
                    const manageConnectionsUrl = `${connectionsUrlWithAppId}/connections-dialog?orgId=${orgId}`;

                    return (
                        <OnLinkIconButton
                            onClick={() => {
                                openManageConnectionsModal(translations, openConfirmation, closeConfirmation, manageConnectionsUrl);
                            }}
                        >
                            <IconNewWindow iconNewWindow={ICON_STYLE}/>
                        </OnLinkIconButton>
                    );
                }

                return null;
            }
        }
    ];
}

function getFilterComponent(translations, connections, hasMakeConnectionsAccess, connectionsLoading, closeConfirmation, openConfirmation) {
    const hasConnectedOrg = connections.some(({status}) => status === CONNECTED);

    const createConnectionsButton = hasMakeConnectionsAccess ? (
        <div className='connection-button'>
            <OnlinkButton
                className='primary'
                onClick={() => window.location.assign('/myjd-explicit-login')}
            >
                {translations.ONLINK_CREATE_CONNECTIONS}
            </OnlinkButton>
        </div>
    ) : null;

    const manageConnectionsButton = hasMakeConnectionsAccess && hasConnectedOrg ? (
        <div className='connection-button'>
            <OnlinkButton
                className='primary'
                onClick={() => {
                    openManageConnectionsModal(translations, openConfirmation, closeConfirmation, window.props.connectionsUrl);
                }}
            >
                {translations.ONLINK_MANAGE_CONNECTIONS}
            </OnlinkButton>
        </div>
    ) : null;

    return connectionsLoading ?
        null : (
            <div className='connection-buttons'>
                {createConnectionsButton}
                {manageConnectionsButton}
            </div>
        );
}

function MyJohnDeereConnections(props) {
    const {
        addToast,
        closeConfirmation,
        hasMyJDSession,
        location,
        myJdId,
        openConfirmation,
        translations
    } = props;

    const [accessLoading, setAccessLoading] = React.useState(true);
    const [connections, setConnections] = React.useState([]);
    const [connectionsLoading, setConnectionsLoading] = React.useState(true);
    const [hasMakeConnectionsAccess, setMakeConnectionsAccess] = React.useState(false);
    const [hasMyJdUser, setHasMyJdUser] = React.useState(true);

    function toastOnError() {
        const urlParams = new URLSearchParams(location.search);
        const errorParam = urlParams.get('error');

        if (errorParam) {
            const errorMessage = errorParam === ACCOUNT_MISMATCH ?
                replaceTranslationNames(translations.ONLINK_ACCOUNT_MISMATCH_ERROR, {
                    '1': myJdId
                }) : translations.ERROR_OCCURRED_TITLE;

            addToast({
                message: errorMessage,
                persist: true,
                type: TOAST_TYPE.ERROR
            });
        }
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setConnectionsLoading(true);

        const dataCalls = [getMyJDUserId()];

        if (hasMyJDSession) {
            dataCalls.push(getMyJDConnections());
        }

        const [myJDUser, connectionsData = []] = await Promise.all(dataCalls);

        if (isMounted()) {
            setConnections(connectionsData);
            setHasMyJdUser(Boolean(myJDUser));
            setConnectionsLoading(false);
        }
    }), []);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setAccessLoading(true);

        let hasMyJDSessionAndMakeConnectionsAccess = false;

        if (hasMyJDSession) {
            hasMyJDSessionAndMakeConnectionsAccess = await getMyJDMakeConnectionsAccess();
        }

        if (isMounted()) {
            setMakeConnectionsAccess(hasMyJDSessionAndMakeConnectionsAccess);
            setAccessLoading(false);
        }

        toastOnError();
    }), [location.search]);

    return (
        <MyJohnDeereDataTable
            columns={getColumns(translations, hasMakeConnectionsAccess, openConfirmation, closeConfirmation)}
            filterComponent={getFilterComponent(translations, connections, hasMakeConnectionsAccess,
                connectionsLoading, closeConfirmation, openConfirmation)}
            hasMyJDSession={hasMyJDSession}
            hasMyJDUser={hasMyJdUser}
            loading={accessLoading || connectionsLoading}
            rows={connections}
            searchable={false}
            showDeleteColumn={false}
            translations={translations}
        />
    );
}

MyJohnDeereConnections.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    hasMyJDSession: PropTypes.bool,
    location: PropTypes.location,
    myJdId: PropTypes.string,
    openConfirmation: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        hasMyJDSession: state.account.hasMyJDSession,
        myJdId: state.account.myJdId,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyJohnDeereConnections));
