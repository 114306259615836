// Unpublished Work © 2022-2024 Deere & Company.

import {asNumber} from 'Utils/conversion-utils';
import moment from 'moment';
import {cloneDeep} from 'lodash';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import {HOLE_ITEMS} from 'Ui/constants/labor-constants';

const DATA_VALUES_PROPERTY = 'data_values';
const DATA_UNIT = 'data_unit';

function validateDate(date, setValid) {
    const selectedDate = moment(date);
    const isValidDate = Boolean(date) && selectedDate.isValid();

    setValid('timeSample', isValidDate);

    return isValidDate ? selectedDate : undefined;
}

function getAreaOptions(translations) {
    return [
        {
            id: 'Green',
            title: translations.ONLINK_GREEN
        },
        {
            id: 'Collar',
            title: translations.ONLINK_COLLAR
        },
        {
            id: 'Approach',
            title: translations.APPROACH
        },
        {
            id: 'Fairway',
            title: translations.ONLINK_FAIRWAY
        },
        {
            id: 'Intermediate',
            title: translations.INTERMEDIATE
        },
        {
            id: 'Tee',
            title: translations.ONLINK_TEE
        },
        {
            id: 'Bunker',
            title: translations.ONLINK_BUNKER
        },
        {
            id: 'Other',
            title: translations.OTHER
        }
    ];
}

function getHoleOptions(translations) {
    return [
        ...HOLE_ITEMS,
        {
            id: 'Practice Green',
            title: translations.ONLINK_PRACTICE_GREEN
        },
        {
            id: 'Other',
            title: translations.OTHER
        }
    ];
}

function onManualDataChange({
    dataUnit,
    name,
    setValues,
    value
}) {
    const isArrayValue = Array.isArray(value);

    setValues((prevValues) => {
        const clonedManualData = cloneDeep(prevValues.manualData) || [];
        const manualDataItem = clonedManualData.find((manualData) => manualData.data_type === name);

        if (manualDataItem) {
            if (isArrayValue) {
                manualDataItem[DATA_VALUES_PROPERTY] = value;
            } else {
                manualDataItem.value = asNumber(value);
                manualDataItem[DATA_UNIT] = dataUnit;
            }
        } else {
            const newManualData = isArrayValue ? {
                [DATA_VALUES_PROPERTY]: value
            } : {
                value: asNumber(value)
            };

            clonedManualData.push({
                'data_type': name,
                'data_unit': dataUnit,
                ...newManualData
            });
        }

        return {
            ...prevValues,
            manualData: clonedManualData
        };
    });
}

function getLocalTimeFormats() {
    return moment.localeData(getLanguagePreference()).longDateFormat('LT');
}

export {
    validateDate,
    getAreaOptions,
    getHoleOptions,
    onManualDataChange,
    getLocalTimeFormats
};
