// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiSelect, Tooltip} from '@deere/isg.component-library';
import {IconAdd, IconDelete, IconHandleChevronDown, IconHandleChevronUp} from '@deere/icons';
import Stack from '@mui/material/Stack';
import GridLayoutWithWidth from 'Ui/components/common/grid-layout-with-width';
import OnlinkButton from 'Ui/components/common/onlink-button';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import WorkItemDragHandle from 'Ui/features/workboard-wizard/work-item/work-item-drag-handle';
import WorkItemInputWrapper from 'Ui/features/workboard-wizard/work-item/work-item-input-wrapper';
import {setStatusForAll, setStatusForWorkItem} from 'Ui/features/workboard-wizard/assignments/utils/collapse-status';
import {
    changeWorkItemLayout,
    getWorkItemLayout,
    sortBySeq
} from 'Ui/features/workboard-wizard/assignments/utils/job-grid-layouts';
import {
    updateWorkItem,
    updateWorkItemEquipment
} from 'Ui/features/workboard-wizard/assignments/utils/work-item-handlers';
import {preventDefaultAndStopPropagation} from 'Utils/common-handlers';
import {useDeepMemo, useLazyRef} from 'Utils/react-utils';
import {onDragStart, onDragStop, scrollToNewestItem} from 'Utils/scroll-utils';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {TRUE} from 'Ui/constants/membership-properties';
import {WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {cloneDeep} from 'lodash';
import {v4} from 'uuid';
import dialogTypes from 'Ui/components/common/dialog-types';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

const ONLINK_BLUE = '#2677bb';

const ICON_COLLAPSE_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const ICON_ADD_STYLE = {
    style: {
        height: '20px',
        width: '20px',
        fill: ONLINK_BLUE
    }
};

const ICON_STYLE = {
    primary: {
        style: {
            fill: ONLINK_BLUE
        }
    },
    icon: {
        style: {
            height: '20px',
            width: '20px'
        }
    }
};

const GRID_MARGIN = 10;

function assignOperatorToJob({
    appUserId,
    operators,
    updateOperators,
    updateJob,
    workItem,
    workItemIndex
}) {
    updateOperators((clonedOperators) => {
        if (workItem.appUserId) {
            const prevOperator = clonedOperators.operatorsMasterList
                .find((operator) => operator.appUserId === workItem.appUserId);

            if (prevOperator) {
                prevOperator.minutesWorkedForWeek -= workItem.actualDuration;
            }
        }

        if (workItem.appUserId !== appUserId) {
            const currentOperator = clonedOperators.operatorsMasterList
                .find((operator) => operator.appUserId === appUserId);

            if (currentOperator) {
                currentOperator.minutesWorkedForWeek += workItem.actualDuration;
            }
        }
    });

    updateJob(({workItems}) => {
        const assignedOperator = workItem.appUserId === appUserId ?
            null :
            operators.operatorsMasterList
                .find((operator) => operator.appUserId === appUserId);

        updateWorkItem('appUser', assignedOperator, workItems, workItemIndex);
        updateWorkItem('appUserId', appUserId, workItems, workItemIndex);
    });
}

function createWorkItem(job, shouldCopyEstimatedToActual, workItemId) {
    return {
        actualDuration: shouldCopyEstimatedToActual ? job.estDuration : 0,
        equipment: job.jobTemplate?.equipmentTypes?.map((equipmentType) => ({
            equipmentTypeId: equipmentType.equipmentTypeId,
            equipmentType: equipmentType.name
        })),
        estDuration: job.estDuration,
        route: undefined,
        routeCustomDefn: [],
        seq: job.workItems.length + 1,
        workItemId
    };
}

function initializeHooks({
    collapseStatus,
    featureToggles,
    isAssignmentMobile,
    job,
    membership
}) {
    const useColumnLayout = isAssignmentMobile || !featureToggles[WORKBOARD_REFACTOR];

    const {
        atLeastOneNotCollapsed,
        collapseStatusForJob
    } = useDeepMemo(() => {
        const statusForJob = collapseStatus.get(`${job.jobId || ''} ${job.jobTemplateId}`);

        return {
            atLeastOneNotCollapsed: statusForJob &&
                (statusForJob.size === 0 || [...statusForJob.values()].includes(false)),
            collapseStatusForJob: statusForJob
        };
    }, [job.jobTemplateId, job.jobId, collapseStatus]);

    const collapseIcon = React.useMemo(() => atLeastOneNotCollapsed ?
        <IconHandleChevronUp iconHandleChevronUp={ICON_COLLAPSE_STYLE}/> :
        <IconHandleChevronDown iconHandleChevronDown={ICON_COLLAPSE_STYLE}/>, [atLeastOneNotCollapsed]);

    return {
        atLeastOneNotCollapsed,
        collapseIcon,
        collapseStatusForJob,
        operatorMultiSelectProps: React.useMemo(() => useColumnLayout ? {} : {
            width: 350
        }, [useColumnLayout]),
        orderRef: useLazyRef(() => sortBySeq(job.workItems, 'workItemId')),
        shouldCopyEstimatedToActual: React.useMemo(
            () => membership.properties.workboard_copy_estimated_to_actual === TRUE,
            [membership.properties.workboard_copy_estimated_to_actual]
        )
    };
}

function getOperatorMultiselectName(workItemId) {
    return `operator-${workItemId}`;
}

function JobItem(props) {
    const {
        closeConfirmation,
        collapseStatus,
        equipmentByType,
        equipmentInUseMap,
        featureToggles,
        fleetEquipment,
        isAssignmentMobile,
        isMultiline,
        job,
        jobIndex,
        membership,
        newestWorkItemIdRef,
        noteHeight,
        openConfirmation,
        operatorItems,
        operators,
        screenTouchListener,
        screenTouchTimerRef,
        setCollapseStatus,
        setOperators,
        setValid,
        setValues,
        translations,
        readOnly
    } = props;

    const {
        atLeastOneNotCollapsed,
        collapseIcon,
        collapseStatusForJob,
        operatorMultiSelectProps,
        orderRef,
        shouldCopyEstimatedToActual
    } = initializeHooks({
        collapseStatus,
        featureToggles,
        isAssignmentMobile,
        job,
        membership
    });

    function updateOperators(callback) {
        setOperators((prevOperators) => {
            const clonedOperators = cloneDeep(prevOperators);

            callback(clonedOperators);

            return clonedOperators;
        });
    }

    function updateJob(callback) {
        setValues((prevValues) => {
            const clonedValues = cloneDeep(prevValues);
            const jobCategory = job.jobTemplate?.jobCategory;

            let clonedJob;

            if (jobCategory) {
                clonedJob = clonedValues.jobs[jobCategory][jobIndex];
            } else {
                clonedJob = clonedValues.jobs['Deleted Jobs']?.[jobIndex];
            }

            callback(clonedJob);

            return clonedValues;
        });
    }

    function updateOperatorMinutesWorked(minutes, workItem) {
        if (workItem.appUserId) {
            updateOperators((clonedOperators) => {
                const operatorForWorkItem = clonedOperators.operatorsMasterList
                    .find((operator) => operator.appUserId === workItem.appUserId);

                if (operatorForWorkItem) {
                    operatorForWorkItem.minutesWorkedForWeek -= workItem.actualDuration;
                    operatorForWorkItem.minutesWorkedForWeek += Number(minutes);
                }
            });
        }
    }

    function removeJobAndWorkItemsFromValues() {
        setValues((prevValues) => {
            const clonedValues = cloneDeep(prevValues);
            const jobCategory = job.jobTemplate?.jobCategory;

            if (jobCategory) {
                const jobsByCategory = clonedValues.jobs[jobCategory];

                jobsByCategory[jobIndex].workItems.forEach((workItem) => {
                    setValid(getOperatorMultiselectName(workItem.workItemId), true);
                    updateOperatorMinutesWorked(0, workItem);
                });

                if (jobsByCategory.length > 1) {
                    jobsByCategory.splice(jobIndex, 1);
                } else {
                    delete clonedValues.jobs[jobCategory];
                }
            } else {
                const deletedJobs = clonedValues.jobs['Deleted Jobs'];

                deletedJobs?.[jobIndex].workItems.forEach((workItem) => {
                    setValid(getOperatorMultiselectName(workItem.workItemId), true);
                    updateOperatorMinutesWorked(0, workItem);
                });

                if (deletedJobs?.length > 1) {
                    deletedJobs.splice(jobIndex, 1);
                } else {
                    delete clonedValues.jobs['Deleted Jobs'];
                }
            }

            return clonedValues;
        });
    }

    function deleteJob() {
        if (job.workItems.length > 0) {
            openConfirmation({
                title: translations.ONLINK_REMOVE_JOB,
                message: translations.ONLINK_REMOVE_JOB_WARNING,
                onContinue: () => {
                    removeJobAndWorkItemsFromValues();
                    closeConfirmation();
                },
                onCancel: closeConfirmation
            });
        } else {
            removeJobAndWorkItemsFromValues();
        }
    }

    return (
        <div
            className='job-item'
            key={job.jobTemplateId}
            onDragEnter={preventDefaultAndStopPropagation}
            onDragOver={preventDefaultAndStopPropagation}
            onDrop={(e) => {
                e.stopPropagation();

                const appUserId = e.dataTransfer.getData('itemId');

                const newWorkItemId = v4();

                updateJob((clonedJob) => {
                    clonedJob.workItems.push({
                        ...createWorkItem(clonedJob, shouldCopyEstimatedToActual, newWorkItemId),
                        appUserId
                    });
                });
            }}
        >
            <div className='title-section'>
                {
                    !readOnly && <WorkItemDragHandle
                        className='icon-job-orderable assignment-item-btn'
                        id={`${job.jobId || ''} ${job.jobTemplateId}`}
                    />
                }
                <Tooltip
                    placement='bottom'
                    title={`${job.jobTemplate?.jobCategory || translations.ONLINK_DELETED_JOBS}: ${job.title}`}
                >
                    <div className='title-1'>
                        {`${job.jobTemplate?.jobCategory || translations.ONLINK_DELETED_JOBS}: ${job.title}`}
                    </div>
                </Tooltip>
                <div className='title-buttons'>
                    <OnlinkButton
                        borderless={true}
                        leftIcon={collapseIcon}
                        onClick={() => setStatusForAll(
                            setCollapseStatus,
                            `${job.jobId || ''} ${job.jobTemplateId}`,
                            atLeastOneNotCollapsed
                        )}
                    />
                </div>
            </div>
            <GridLayoutWithWidth
                cols={1}
                draggableHandle='.icon-workitem-orderable'
                isDraggable={!readOnly}
                isResizable={false}
                layout={getWorkItemLayout(job, collapseStatusForJob, isAssignmentMobile, featureToggles[WORKBOARD_REFACTOR], readOnly, noteHeight)}
                margin={[0, GRID_MARGIN]}
                onDragStart={(layout, oldItem) => onDragStart(oldItem.i, screenTouchListener)}
                onDragStop={(layout, oldItem) => onDragStop(oldItem.i, screenTouchListener, screenTouchTimerRef)}
                onLayoutChange={(currentLayout) => changeWorkItemLayout(currentLayout, orderRef, setValues, job)}
                rowHeight={50}
            >
                {
                    job.workItems.map((workItem, workItemIndex) => {
                        const foundAppUser = operatorItems.activeOperatorItems.find((item) => item.id === workItem.appUserId);

                        const isUserAvailable = !foundAppUser && Boolean(workItem.appUserId);
                        const fullOperatorList = isUserAvailable ?
                            operatorItems.inactiveOperatorItems :
                            operatorItems.activeOperatorItems;

                        const operatorMultiselectName = getOperatorMultiselectName(workItem.workItemId);
                        const collapseStatusForWorkItem = collapseStatusForJob?.get(workItem.workItemId);

                        const isNew = workItem.workItemId === newestWorkItemIdRef.current;

                        return (
                            <div
                                id={workItem.workItemId}
                                key={workItem.workItemId}
                                onDragEnter={preventDefaultAndStopPropagation}
                                onDragOver={preventDefaultAndStopPropagation}
                                onDrop={(e) => {
                                    e.stopPropagation();

                                    const appUserId = e.dataTransfer.getData('itemId');

                                    if (workItem.appUserId !== appUserId) {
                                        assignOperatorToJob({
                                            appUserId,
                                            operators,
                                            updateOperators,
                                            updateJob,
                                            workItem,
                                            workItemIndex
                                        });
                                    }
                                }}
                            >
                                <WorkItemInputWrapper
                                    collapseStatusForWorkItem={collapseStatusForWorkItem}
                                    collapsedTitle={isUserAvailable ? translations.ONLINK_USER_NOT_AVAILABLE : foundAppUser?.title}
                                    deleteTitle={translations.ONLINK_REMOVE_OPERATOR}
                                    displayOrderButton={true}
                                    eligibleEquipmentTypes={foundAppUser?.equipmentTypes}
                                    equipmentAreaId={job.jobTemplate?.equipmentAreaId}
                                    equipmentByType={equipmentByType}
                                    equipmentInUseMap={equipmentInUseMap}
                                    featureToggles={featureToggles}
                                    isMultiline={isMultiline}
                                    noteHeight={noteHeight}
                                    onActHoursChange={(minutes) => {
                                        updateOperatorMinutesWorked(minutes, workItem);

                                        updateJob(({workItems}) => {
                                            updateWorkItem('actualDuration', minutes, workItems, workItemIndex);
                                        });
                                    }}
                                    onChange={(name, value) => {
                                        updateJob(({workItems}) => {
                                            updateWorkItem(name, value, workItems, workItemIndex);
                                        });
                                    }}
                                    onEquipmentChange={(selectedEquipmentId, equipmentIndex) => {
                                        const selectedEquipment = fleetEquipment
                                            .find((equipment) => equipment.equipmentId === selectedEquipmentId);

                                        updateJob(({workItems}) => {
                                            updateWorkItemEquipment({
                                                equipmentIndex,
                                                equipmentInUseMap: equipmentInUseMap.current,
                                                selectedEquipment,
                                                selectedEquipmentId,
                                                workItem: workItems[workItemIndex]
                                            });
                                        });
                                    }}
                                    onEstHoursChange={(minutes) => {
                                        if (shouldCopyEstimatedToActual) {
                                            updateOperatorMinutesWorked(minutes, workItem);
                                        }

                                        updateJob(({workItems}) => {
                                            updateWorkItem('estDuration', minutes, workItems, workItemIndex);

                                            if (shouldCopyEstimatedToActual) {
                                                updateWorkItem('actualDuration', minutes, workItems, workItemIndex);
                                            }
                                        });
                                    }}
                                    onRemove={() => {
                                        setValid(operatorMultiselectName, true);
                                        updateOperatorMinutesWorked(0, workItem);

                                        updateJob(({workItems}) => {
                                            workItems[workItemIndex].equipment?.forEach((equipment) => {
                                                equipmentInUseMap.current.set(equipment.equipmentId, null);
                                            });

                                            workItems.splice(workItemIndex, 1);
                                        });
                                    }}
                                    readOnly={readOnly}
                                    setStatusForWorkItem={() => setStatusForWorkItem(
                                        setCollapseStatus,
                                        `${job.jobId || ''} ${job.jobTemplateId}`,
                                        workItem.workItemId,
                                        !collapseStatusForWorkItem
                                    )}
                                    translations={translations}
                                    workItem={workItem}
                                >
                                    <div ref={(ref) => scrollToNewestItem(ref, isNew)}>
                                        <ReadOnlyWrapperComponent
                                            {...operatorMultiSelectProps}
                                            label={translations.OPERATOR}
                                            readOnly={readOnly}
                                            readOnlyProps={{
                                                readOnlyLabelClassName: 'title-1',
                                                readOnlyValueClassName: 'readonly-value',
                                                value: foundAppUser?.title
                                            }}
                                            wrappedComponent={ValidationMultiSelect}
                                            wrappedProps={{
                                                autoFocus: isNew,
                                                component: MultiSelect,
                                                defaultOpen: isNew,
                                                disableClearValid: true,
                                                disabled: isUserAvailable,
                                                items: fullOperatorList,
                                                labels: {
                                                    placeholder: isUserAvailable ?
                                                        translations.ONLINK_USER_NOT_AVAILABLE :
                                                        translations.ONLINK_SELECT_OPERATOR
                                                },
                                                name: operatorMultiselectName,
                                                onChange: ([appUserId]) => assignOperatorToJob({
                                                    appUserId,
                                                    operators,
                                                    updateOperators,
                                                    updateJob,
                                                    workItem,
                                                    workItemIndex
                                                }),
                                                onChangeOpen: (open) => {
                                                    if (!open) {
                                                        newestWorkItemIdRef.current = null;
                                                    }
                                                },
                                                selectedIds: [workItem.appUserId],
                                                setValid,
                                                single: true
                                            }}
                                        />
                                    </div>
                                </WorkItemInputWrapper>
                            </div>
                        );
                    })
                }
            </GridLayoutWithWidth>
            {
                (featureToggles[WORKBOARD_REFACTOR] || atLeastOneNotCollapsed) && !readOnly &&
                <Stack
                    direction='row'
                    justifyContent='space-between'
                >
                    <OnlinkButton
                        borderless={true}
                        className='icon-and-text'
                        leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
                        onClick={() => {
                            const newWorkItemId = v4();

                            newestWorkItemIdRef.current = newWorkItemId;

                            updateJob((clonedJob) => {
                                const newWorkItem = createWorkItem(clonedJob, shouldCopyEstimatedToActual, newWorkItemId);

                                clonedJob.workItems.push(newWorkItem);
                            });
                        }}
                    >
                        {translations.ONLINK_ADD_OPERATOR}
                    </OnlinkButton>
                    <OnlinkButton
                        borderless={true}
                        className='icon-and-text'
                        leftIcon={<IconDelete {...ICON_STYLE}/>}
                        onClick={() => {
                            deleteJob();
                        }}
                    >
                        {translations.ONLINK_REMOVE_JOB}
                    </OnlinkButton>
                </Stack>
            }
        </div>
    );
}

JobItem.propTypes = {
    closeConfirmation: PropTypes.func,
    collapseStatus: PropTypes.instanceOf(Map),
    equipmentByType: PropTypes.instanceOf(Map),
    equipmentInUseMap: PropTypes.reference,
    featureToggles: PropTypes.featureToggles,
    fleetEquipment: PropTypes.arrayOf(PropTypes.equipment),
    isAssignmentMobile: PropTypes.bool,
    isMultiline: PropTypes.bool,
    job: PropTypes.object,
    jobIndex: PropTypes.number,
    membership: PropTypes.membership,
    newestWorkItemIdRef: PropTypes.reference,
    noteHeight: PropTypes.number,
    openConfirmation: PropTypes.func,
    operatorItems: PropTypes.object,
    operators: PropTypes.object,
    readOnly: PropTypes.bool,
    screenTouchListener: PropTypes.func,
    screenTouchTimerRef: PropTypes.reference,
    setCollapseStatus: PropTypes.func,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(JobItem);
