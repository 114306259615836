// Unpublished Work © 2020-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {PROFILE_PIC_PATTERN} from 'Common/constants/regex';

async function getUser() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/users/currentUser');

    return response.data;
}

async function createUser(userData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/users', userData);

    return response.data;
}

async function deleteUserById(userId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/users/${userId}`);

    return response.data;
}

async function updateUserById(userData, userId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/users/${userId}`, userData);

    return response.data;
}

async function getMyJDUser() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/users/currentUser/myJohnDeere/profile');

    return response.data;
}

async function uploadProfilePic(file) {
    const formData = new FormData();

    formData.append('filedata', file);

    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/users/profilePic', formData);

    return response.data;
}

async function getProfilePic(location) {
    const profilePicLocation = location ? location.match(PROFILE_PIC_PATTERN) : null;

    if (profilePicLocation) {
        const axiosClient = createUiAxiosClient();

        const response = await axiosClient.get(`/api/users/profilePic/${profilePicLocation[0]}`);

        return response.data;
    }

    return {};
}

async function getWorkItemsForUser(dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/users/currentUser/workItems', {
        params: {
            date: dateSelect
        }
    });

    return response.data;
}

async function updateEmployeeStatusByDateSelect(statusData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put('/api/users/employeeStatus', statusData);

    return response.data;
}

async function deleteEmployeeStatusByDateSelect(statusData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete('/api/users/employeeStatus', {
        params: {
            appUserId: statusData.appUserId,
            dateSelect: statusData.dateSelect
        }
    });

    return response.data;
}

export {
    getUser,
    createUser,
    deleteUserById,
    updateUserById,
    getMyJDUser,
    uploadProfilePic,
    getWorkItemsForUser,
    updateEmployeeStatusByDateSelect,
    deleteEmployeeStatusByDateSelect,
    getProfilePic
};
