// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import DataTable from 'Ui/components/common/data-table/data-table';
import Status from 'Ui/components/common/data-table/status';
import {getPurchaseOrders, getPartBins} from 'Services/inventory-service';
import {getInventories} from 'Services/membership-service';
import {alphaNumericCompare, dateCompare} from 'Ui/models/maintenance';
import {fetchEffectData} from 'Utils/react-utils';
import {DATE_FORMAT_OPTIONS, formatLocalizedTime} from 'Utils/time-utils';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import BudgetSidebar from 'Ui/components/common/budget-sidebar';
import OrdersFilterComponent from 'Ui/features/onequip/parts/orders/orders-filter-component';
import {handleDeletePurchaseOrder} from 'Ui/features/onequip/parts/orders/utils/purchase-order-delete';
import moment from 'moment';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {connect} from 'react-redux';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {VIEW_FINANCIAL_DATA, VIEW_EQUIPMENT_LIST_API, MANAGE_PARTS} from 'Common/constants/business-activities';

function filterPurchaseHistory(statusFilter, purchaseOrders) {
    return purchaseOrders.filter((orders) => statusFilter.includes(orders.status));
}

function getColumns(translations, callGetPurchaseOrders, openAddPurchaseOrderDialog, inventories, featureToggles, membership, hasViewEquipmentListApiPerms) {
    return [
        {
            Header: `${translations.ORDER} #`,
            accessor: 'referenceNumber',
            sortMethod: alphaNumericCompare,
            width: 120,
            Cell(row) {
                return (
                    hasViewEquipmentListApiPerms &&
                    <EditLink
                        onClick={() => {
                            openAddPurchaseOrderDialog({
                                purchaseOrder: row.original,
                                inventories,
                                translations,
                                featureToggles,
                                onClose: callGetPurchaseOrders,
                                membership
                            });
                        }}
                    >
                        {row.original.referenceNumber}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_INVOICE,
            accessor: 'invoiceNumber',
            sortMethod: alphaNumericCompare,
            width: 120
        },
        {
            Header: translations.DATE,
            accessor: 'formattedDateSelect',
            sortMethod: dateCompare,
            width: 90
        },
        {
            Header: translations.DEALER,
            accessor: 'distributorName',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.ONLINK_TOTAL,
            accessor: 'formattedTotalCost',
            sortMethod: alphaNumericCompare,
            width: 90,
            requiredMyJdPermissions: VIEW_FINANCIAL_DATA
        },
        {
            Header: translations.STATUS,
            accessor: 'status',
            width: 180,
            sortMethod: alphaNumericCompare,
            Cell(row) {
                return (
                    <Status
                        className={'orders-status'}
                        formattedStatus={translations[row.original.status]}
                        status={row.original.status}
                    />
                );
            }
        }
    ];
}

function Orders(props) {
    const {
        addToast,
        closeConfirmation,
        featureToggles,
        translations,
        membership,
        openAddPurchaseOrderDialog,
        openConfirmation,
        myJdPermissions
    } = props;

    const [purchaseOrders, setPurchaseOrders] = React.useState([]);
    const [budget, setBudget] = React.useState({
        inventory: {},
        partBins: []
    });
    const [inventories, setInventories] = React.useState(null);
    const [statusFilter, setStatusFilter] = React.useState([
        'ordered',
        'completed',
        'pending',
        'received'
    ]);
    const [loading, setLoading] = React.useState(true);

    const userAuth = {
        myJdPermissions,
        isMigrated: membership.isMigrated
    };

    const hasViewFinancialDataPermission = isAuthorized({
        myJdPermissions: VIEW_FINANCIAL_DATA
    }, userAuth);

    const hasViewEquipmentListApiPerms = isAuthorized({
        myJdPermissions: VIEW_EQUIPMENT_LIST_API
    }, userAuth);

    const hasManageParts = isAuthorized({
        myJdPermissions: MANAGE_PARTS
    }, userAuth);

    async function callGetPurchaseOrders(isMounted = () => true) {
        setLoading(true);

        const {inventories} = await getInventories();

        const {partBins} = await getPartBins(inventories[0].inventoryId);

        const {purchaseOrders: purchaseOrdersCall} = await getPurchaseOrders(inventories[0].inventoryId);

        const formattedOrders = purchaseOrdersCall.map((order) => ({
            ...order,
            distributorName: order.distributor?.name,
            formattedDateSelect: formatLocalizedTime(moment(order.dateSelect), DATE_FORMAT_OPTIONS),
            formattedTotalCost: getFormattedCurrency(order.totalCost, {
                currencyPreference: membership.currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            })
        }));

        if (isMounted()) {
            setPurchaseOrders(formattedOrders);
            setInventories(inventories);
            setBudget({
                inventory: inventories[0],
                partBins
            });
            setLoading(false);
        }
    }

    React.useEffect(() => fetchEffectData(callGetPurchaseOrders), [membership.membershipId]);

    return (
        <DataTable
            columns={getColumns(translations, callGetPurchaseOrders, openAddPurchaseOrderDialog, inventories, featureToggles, membership, hasViewEquipmentListApiPerms)}
            defaultSorted={[{
                desc: true,
                id: 'formattedDateSelect'
            }, {
                desc: true,
                id: 'referenceNumber'
            }]}
            deleteHandler={(purchaseOrder) => handleDeletePurchaseOrder(openConfirmation, closeConfirmation, purchaseOrder, callGetPurchaseOrders, translations, addToast)}
            filterComponent={
                <OrdersFilterComponent
                    callGetPurchaseOrders={callGetPurchaseOrders}
                    featureToggles={featureToggles}
                    inventories={inventories}
                    loading={loading}
                    membership={membership}
                    openAddPurchaseOrderDialog={openAddPurchaseOrderDialog}
                    setStatusFilter={setStatusFilter}
                    statusFilter={statusFilter}
                    translations={translations}
                />}
            loading={loading}
            rows={filterPurchaseHistory(statusFilter, purchaseOrders)}
            searchable={true}
            showDeleteColumn={hasManageParts}
            showPagination={true}
            sideComponent={
                hasViewFinancialDataPermission &&
                <BudgetSidebar
                    budget={budget}
                    currencyPreference={membership.currencyPreference}
                    featureToggles={featureToggles}
                    translations={translations}
                />
            }
            translations={translations}
        />
    );
}

Orders.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    openAddPurchaseOrderDialog: PropTypes.func,
    openConfirmation: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openAddPurchaseOrderDialog(props) {
            dispatch(openDialog(dialogTypes.ADD_EDIT_PURCHASE_ORDER_DIALOG, props));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}
export function mapStateToProps(state) {
    return {
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
