// Unpublished Work © 2021-2024 Deere & Company.

export default {
    ACCESS_MULTI_USER_MODE: 'can_access_multi-user_mode',
    ACCESS_SUPERINTENDENT_MODE: 'can_access_superintendent_mode',
    ASSIGNED_TO_SERVICE_TICKETS: 'can_be_assigned_to_service_tickets',
    DELETE_SERVICE_TICKETS: 'can_delete_service_tickets',
    EDIT_APP_ACCESS: 'can_edit_app_access',
    EDIT_ROLES: 'can_edit_roles',
    RECEIVES_ALERTS: 'receives_alerts',
    VIEW_HOURLY_RATES: 'can_view_hourly_rates',
    VIEW_SALARIES: 'can_view_salaries',
    MANAGE_MYJD_CONFIGURATION: 'manage_myjohndeere_configuration',
    CAN_ACCESS_MY_WORK: 'can_access_my_work'
};
