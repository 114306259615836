// Unpublished Work © 2023-2024 Deere & Company.

export const SET_TERTIARY_NAVBAR_ACTIONS = 'SET_TERTIARY_NAVBAR_ACTIONS';

export function setTertiaryNavBarActions(navbarActions) {
    return {
        type: SET_TERTIARY_NAVBAR_ACTIONS,
        navbarActions
    };
}
