// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Dialogs from 'Ui/components/dialogs/dialogs';
import EntryComponent from 'Ui/components/entry/entry-component';
import EntryPublic from 'Ui/components/entry/entry-public';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import MainContentBackground from 'Ui/components/main-content-background';
import Toasts from 'Ui/components/common/toasts';
import {
    setFeatureToggles as setFeatureTogglesRedux,
    setHasMyJDSession as setHasMyJDSessionRedux,
    setMyJdId as setMyJdIdRedux,
    setPermissions as setPermissionsRedux,
    updateUser as updateUserRedux,
    setMyJdPermissions as setMyJdPermissionsRedux,
    setMyJdUserOrgs as setMyJdUserOrgsRedux
} from 'Store/actions/account';
import {updateHashedUserValues as updateHashedReduxValues} from 'Store/actions/analytics';
import {updateMembership as updateMembershipRedux} from 'Store/actions/membership';
import {updateUserRoles as updateUserRolesRedux} from 'Store/actions/role';
import {setTranslations as setTranslationsRedux} from 'Store/actions/translations';
import {getFeatureToggles as getFeatureTogglesForUser} from 'Services/feature-toggle-service';
import {getActiveMembership, getUserRoles} from 'Services/membership-service';
import {getUser as getCurrentUser} from 'Services/user-service';
import {ANALYTICS_EVENTS, trackOnLinkEvent} from 'Utils/analytics-utils';
import {fetchEffectData, useLazyRef} from 'Utils/react-utils';
import {MILLISECONDS_PER_SECOND} from 'Ui/constants/time-constants';
import onlinkImage from 'Ui/images/onlink.png';
import {redirect} from 'Utils/redirect-utils';
import {debounce} from 'lodash';
import LoadingIcon from 'Ui/components/common/loading-icon';
import {
    getSessionStrategy,
    refreshMyJdPermissions
} from 'Services/session-service';

const LOGIN_PATH = '/login';
const PROFILE_LINK_PATH = '/profile/link';
const WORKBOARD_PRESENTATION_PATH = '/labor/workboard/presentation';
const ONLINK_SESSION = 'onlink-session';
const MAP_PATH = '/map';
const SETTINGS_PATH = '/home/details/settings';

async function getMembershipAndRoles(hasMyJDSession) {
    const {
        membership,
        hashedCurrentMembershipId
    } = await getActiveMembership();

    const [
        {userRoles},
        myJdPermissions
    ] = await Promise.all([
        membership.isMigrated ? {
            userRoles: []
        } : getUserRoles(),
        membership.orgId && hasMyJDSession ? refreshMyJdPermissions() : {}
    ]);

    return {
        analytics: {
            hashedCurrentMembershipId
        },
        membership,
        userRoles,
        myJdPermissions
    };
}

async function getUser() {
    const {
        appKeyMap,
        appUser,
        hasMyJDSession,
        myJdId,
        permissionMap,
        hashedAppUserId,
        hashedMembershipId,
        myJdUserOrgs
    } = await getCurrentUser();

    return {
        appUser: {
            ...appUser,
            appKeys: appKeyMap
        },
        analytics: {
            hashedAppUserId,
            hashedMembershipId
        },
        myJdId,
        hasMyJDSession,
        membership: appUser.membership,
        permissionMap,
        myJdUserOrgs
    };
}

const getFeatureToggles = async () => ({
    featureToggles: await getFeatureTogglesForUser()
});

async function getRestrictedData(hasMyJDSession) {
    const restrictedData = await Promise.all([
        getFeatureToggles(),
        getMembershipAndRoles(hasMyJDSession)
    ]);

    return restrictedData.reduce((combinedServerData, data) => ({
        ...combinedServerData,
        ...data
    }), {});
}

function handleOpscenterRedirect(sessionStrategy, opscenterOrPHRedirect, setOpscenterOrPHRedirect, history) {
    if (sessionStrategy?.strategy === ONLINK_SESSION && opscenterOrPHRedirect.opsCenterSource) {
        setOpscenterOrPHRedirect(null);
        history.replace({
            search: ''
        });
    } else {
        redirect(`/api/myjd-create-session?redirect=${opscenterOrPHRedirect.redirect}`);
    }
}

function handleMapOrSettingsRedirect(setIsMigrated, restrictedData, isMapPage, isSettingsPage) {
    const isMigrated = restrictedData.membership?.isMigrated;

    setIsMigrated(isMigrated);

    if (isMigrated) {
        if (isMapPage) {
            redirect(window.props.mapLink);
        } else if (isSettingsPage) {
            redirect(window.props.teamManagerLink);
        }
    }
}

function EntryWrapper(props) {
    const {
        history,
        setFeatureToggles,
        setHasMyJDSession,
        setMyJdId,
        setMyJdPermissions,
        setMyJdUserOrgs,
        setPermissions,
        setTranslations,
        updateMembership,
        updateUser,
        updateHashedUserValues,
        updateUserRoles,
        ...mainNavBarProps
    } = props;

    const [opscenterOrPHRedirect, setOpscenterOrPHRedirect] = React.useState(() => {
        const urlParams = new URLSearchParams(mainNavBarProps.location.search);
        const opsCenterSource = urlParams.get('source') === 'opscenter';
        const opsCenterRedirect = opsCenterSource ? mainNavBarProps.location.pathname : false;
        const redirect = opsCenterRedirect || urlParams.get('next');

        return {
            opsCenterSource,
            redirect
        };
    });

    const debouncedPageChangeTracker = useLazyRef(() => debounce(trackOnLinkEvent, MILLISECONDS_PER_SECOND));
    const [loading, setLoading] = React.useState(true);
    const [isMigrated, setIsMigrated] = React.useState(true);

    const {
        isLoginPage,
        isPublicPage,
        isStandalonePage,
        isSettingsPage,
        isMapPage
    } = React.useMemo(() => {
        const isLoginPage = mainNavBarProps.location.pathname.startsWith(LOGIN_PATH);
        const isProfileLink = mainNavBarProps.location.pathname.startsWith(PROFILE_LINK_PATH);
        const isWorkboardPresentation = mainNavBarProps.location.pathname.startsWith(WORKBOARD_PRESENTATION_PATH);
        const isPublicPage = isLoginPage || isProfileLink;
        const isMapPage = mainNavBarProps.location.pathname.startsWith(MAP_PATH);
        const isSettingsPage = mainNavBarProps.location.pathname.startsWith(SETTINGS_PATH);

        return {
            isLoginPage,
            isPublicPage,
            isSettingsPage,
            isMapPage,
            isStandalonePage: isWorkboardPresentation
        };
    }, [mainNavBarProps.location.pathname]);

    const setPublicReduxState = React.useCallback((serverData) => {
        setHasMyJDSession(serverData.hasMyJDSession);
        setMyJdId(serverData.myJdId);
        setPermissions(serverData.permissionMap);
        setMyJdUserOrgs(serverData.myJdUserOrgs);
        updateMembership(serverData.membership);
        updateUser(serverData.appUser);
        setTranslations(mainNavBarProps.translations);
    }, [mainNavBarProps.translations]);

    const setRestrictedReduxState = React.useCallback((serverData) => {
        if (!isPublicPage) {
            setMyJdPermissions(serverData.myJdPermissions);
            updateUserRoles(serverData.userRoles);
            setFeatureToggles(serverData.featureToggles);
            updateHashedUserValues(serverData.analytics);
        }
    }, [isPublicPage]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        if (opscenterOrPHRedirect?.redirect) {
            const sessionStrategy = await getSessionStrategy();

            handleOpscenterRedirect(sessionStrategy, opscenterOrPHRedirect, setOpscenterOrPHRedirect, history);
        } else if (!isLoginPage) {
            const userData = await getUser();
            const restrictedData = !isPublicPage ? await getRestrictedData(userData.hasMyJDSession) : {
                analytics: {}
            };

            if (isMounted()) {
                handleMapOrSettingsRedirect(setIsMigrated, restrictedData, isMapPage, isSettingsPage);
                setPublicReduxState({
                    ...userData,
                    membership: restrictedData.membership || userData.membership
                });
                setRestrictedReduxState({
                    ...restrictedData,
                    analytics: {
                        ...userData.analytics,
                        ...restrictedData.analytics
                    }
                });
            }
        }

        if (isMounted()) {
            setLoading(false);
        }
    }), [opscenterOrPHRedirect, isMapPage, isSettingsPage]);

    React.useEffect(() => {
        if (!loading && !isPublicPage) {
            debouncedPageChangeTracker.current(`${ANALYTICS_EVENTS.PAGE_LOADED}`);
        }
    }, [mainNavBarProps.location.pathname, loading]);

    if (opscenterOrPHRedirect?.redirect || isMigrated && (isMapPage || isSettingsPage)) {
        return (
            <div className='main-page-header'>
                <LoadingIcon
                    className='dashboard-loading-icon'
                    size='50px'
                />
            </div>
        );
    }

    return isPublicPage ? (
        <>
            <div className='main-page-public'>
                <div className='main-navbar public-navbar'>
                    <img
                        alt='OnLink Logo'
                        className='onlink-image'
                        src={onlinkImage}
                    />
                </div>
                <MainContentBackground/>
                <LoadingWrapper
                    className='dashboard-loading-icon'
                    loading={loading}
                    size='50px'
                >
                    <EntryPublic
                        location={mainNavBarProps.location}
                        translations={mainNavBarProps.translations}
                    />
                </LoadingWrapper>
            </div>
            <Dialogs/>
            <Toasts/>
        </>
    ) : (
        <>
            <div className='main-page-header'>
                <LoadingWrapper
                    className='dashboard-loading-icon'
                    loading={loading}
                    size='50px'
                >
                    <EntryComponent
                        isStandalonePage={isStandalonePage}
                        location={mainNavBarProps.location}
                        mainNavBarProps={mainNavBarProps}
                        translations={mainNavBarProps.translations}
                    />
                </LoadingWrapper>
            </div>
            <Dialogs/>
            <Toasts/>
        </>
    );
}

EntryWrapper.propTypes = {
    history: PropTypes.history,
    location: PropTypes.location,
    setFeatureToggles: PropTypes.func,
    setHasMyJDSession: PropTypes.func,
    setMyJdId: PropTypes.func,
    setMyJdPermissions: PropTypes.func,
    setMyJdUserOrgs: PropTypes.func,
    setPermissions: PropTypes.func,
    setTranslations: PropTypes.func,
    translations: PropTypes.translations,
    updateHashedUserValues: PropTypes.func,
    updateMembership: PropTypes.func,
    updateUser: PropTypes.func,
    updateUserRoles: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        setFeatureToggles(value) {
            dispatch(setFeatureTogglesRedux(value));
        },
        setHasMyJDSession(value) {
            dispatch(setHasMyJDSessionRedux(value));
        },
        setMyJdUserOrgs(value) {
            dispatch(setMyJdUserOrgsRedux(value));
        },
        setMyJdId(value) {
            dispatch(setMyJdIdRedux(value));
        },
        setMyJdPermissions(value) {
            dispatch(setMyJdPermissionsRedux(value));
        },
        setPermissions(value) {
            dispatch(setPermissionsRedux(value));
        },
        setTranslations(value) {
            dispatch(setTranslationsRedux(value));
        },
        updateMembership(value) {
            dispatch(updateMembershipRedux(value));
        },
        updateUser(value) {
            dispatch(updateUserRedux(value));
        },
        updateHashedUserValues(value) {
            dispatch(updateHashedReduxValues(value));
        },
        updateUserRoles(value) {
            dispatch(updateUserRolesRedux(value));
        }
    };
}

export default connect(null, mapDispatchToProps)(withRouter(EntryWrapper));
