// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import ListItem from 'Ui/components/common/list/list-item';
import MobileNav from 'Ui/components/secondary-navbar/common/mobile-nav';
import MobileNavTabs from 'Ui/components/secondary-navbar/common/mobile-nav-tabs';
import MobileNavList from 'Ui/components/secondary-navbar/common/mobile-nav-list';
import dialogTypes from 'Ui/components/common/dialog-types';
import {redirectAndToggleDropdown} from 'Ui/components/secondary-navbar/utils/nav-helpers';
import {ANALYTICS_EVENTS, trackDashboardSelection, trackOnLinkEvent, CATEGORY, ACTIONS, LABELS} from 'Utils/analytics-utils';
import {setActiveLayout as setActiveLayoutService} from 'Services/user-view-service';
import {setActiveLayout as setReduxActiveLayout} from 'Store/actions/account';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';

const DASHBOARD_TAB = 'dashboard';
const DETAIL_TAB = 'detail';

function MobileNavDashboardWrapper(props) {
    const {
        activeLayoutId,
        availableLayouts,
        history,
        match,
        navItems,
        openDialog,
        setActiveLayout,
        toggleDropdown,
        translations,
        appUserId
    } = props;

    const footer = (
        <div
            className='layout-dropdown-footer subtitle-1'
            onClick={() => {
                trackOnLinkEvent(ANALYTICS_EVENTS.TRACK_EVENT, {
                    category: CATEGORY.ONLINK_DASHBOARD,
                    label: LABELS.MANAGE_DASHBOARDS,
                    action: ACTIONS.DASHBOARD_MENU,
                    value: ''
                });
                openDialog();
                toggleDropdown();
            }}
        >
            {translations.MANAGE_DASHBOARDS}
        </div>
    );

    const dashboardList = availableLayouts.map((layout) => (
        <ListItem
            active={layout.id === activeLayoutId}
            key={layout.id}
            onClick={() => {
                trackDashboardSelection(layout);
                history.push('/home');

                setActiveLayoutService(layout.id, appUserId);
                setActiveLayout(layout);

                toggleDropdown();
            }}
            text={layout.name}
        />
    ));

    const dashboardTabContent = (
        <>
            <div className='mobile-nav-list-container'>
                <div className='mobile-nav-list'>
                    {dashboardList}
                </div>
            </div>
            {footer}
        </>
    );

    const navListComponent = (
        <MobileNavList
            navItems={navItems}
            onClick={(navItem) => redirectAndToggleDropdown(navItem, history, toggleDropdown)}
        />
    );

    return (
        <MobileNav
            {...props}
            dropdownTitle={translations.DASHBOARD}
        >
            <MobileNavTabs
                initialActiveTab={match.params.type ? DETAIL_TAB : DASHBOARD_TAB}
                tabs={[
                    {
                        element: dashboardTabContent,
                        name: DASHBOARD_TAB,
                        title: translations.ONLINK_DASHBOARDS
                    },
                    {
                        element: navListComponent,
                        name: DETAIL_TAB,
                        title: translations.ONLINK_DETAILED_VIEWS
                    }
                ]}
            />
        </MobileNav>
    );
}

MobileNavDashboardWrapper.propTypes = {
    activeLayoutId: PropTypes.string,
    appUserId: PropTypes.string,
    availableLayouts: PropTypes.arrayOf(PropTypes.layout),
    history: PropTypes.history,
    match: PropTypes.match,
    navItems: PropTypes.arrayOf(PropTypes.navItem),
    openDialog: PropTypes.func,
    setActiveLayout: PropTypes.func,
    toggleDropdown: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        activeLayoutId: state.account.activeLayout.id,
        availableLayouts: state.account.availableLayouts,
        appUserId: state.account.appUserId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setActiveLayout(value) {
            dispatch(setReduxActiveLayout(value));
        },
        openDialog() {
            dispatch(openReduxDialog(dialogTypes.LAYOUT_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavDashboardWrapper);
