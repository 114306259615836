// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddButton from 'Ui/components/settings/common/add-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {getManufacturers} from 'Services/inventory-service';
import {deleteManufacturer} from 'Services/manufacturer-service';
import {getInventories} from 'Services/membership-service';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import dialogTypes from 'Ui/components/common/dialog-types';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';

function isManufacturerModifiable(manufacturerInventoryId, membershipInventoryId, superUser) {
    return superUser || manufacturerInventoryId === membershipInventoryId;
}

function getColumns(translations, openManufacturerDialog, existingManufacturers, inventoryIdRef, onClose, superUser) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                if (isManufacturerModifiable(original.inventoryId, inventoryIdRef.current, superUser)) {
                    return (
                        <EditLink
                            onClick={() => openManufacturerDialog({
                                existingManufacturers,
                                inventoryIdRef,
                                manufacturer: original,
                                onClose,
                                translations
                            })}
                        >
                            {value}
                        </EditLink>
                    );
                }

                return value;
            }
        }
    ];
}

function getFilterComponent(translations, openManufacturerDialog, existingManufacturers, inventoryIdRef, onClose, showOnlyMyManufacturers, setShowOnlyMyManufacturers) {
    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={showOnlyMyManufacturers}
                name='showOnlyMyManufacturers'
                offLabel={`${capitalizeFirstLetter(translations.ONLINK_ONLY_MY_MANUFACTURERS)}`}
                onChange={() => setShowOnlyMyManufacturers(!showOnlyMyManufacturers)}
                translations={translations}
            />
            <AddButton
                label={replaceTranslationNames(translations.ONLINK_ADD_ITEM, {
                    '0': translations.MANUFACTURER
                })}
                onClick={() => openManufacturerDialog({
                    existingManufacturers,
                    inventoryIdRef,
                    manufacturer: null,
                    onClose,
                    translations
                })}
            />
        </div>
    );
}

function ManufacturerSettings(props) {
    const {
        addToast,
        closeConfirmation,
        openConfirmation,
        openManufacturerDialog,
        superUser,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [manufacturers, setManufacturers] = React.useState({
        allManufacturers: [],
        membershipManufacturers: []
    });
    const inventoryIdRef = React.useRef(null);
    const [showOnlyMyManufacturers, setShowOnlyMyManufacturers] = React.useState(true);

    const existingManufacturers = useDeepMemo(() => manufacturers.allManufacturers.reduce((manufacturerNames, manufacturer) => {
        manufacturerNames.add(manufacturer.name);

        return manufacturerNames;
    }, new Set()), [manufacturers]);

    async function fetchManufacturers(isMounted = () => true) {
        setLoading(true);

        const {manufacturers} = await getManufacturers();

        if (isMounted()) {
            const filteredManufacturers = manufacturers.filter(({name}) => name);

            setManufacturers({
                allManufacturers: filteredManufacturers,
                membershipManufacturers: filteredManufacturers
                    .filter(({inventoryId}) => inventoryId === inventoryIdRef.current)
            });
            setLoading(false);
        }
    }

    function deleteManufacturerHandler(manufacturer) {
        const lowerCaseManufacturer = translations.MANUFACTURER.toLowerCase();

        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': lowerCaseManufacturer
            }),
            onCancel: closeConfirmation,
            showLoadingIcon: true,
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.MANUFACTURER
            }),
            async onContinue() {
                try {
                    await deleteManufacturer(manufacturer.manufacturerId);
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': lowerCaseManufacturer
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }

                closeConfirmation();
                fetchManufacturers();
            }
        });
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {inventories} = await getInventories();

        inventoryIdRef.current = inventories?.length ? inventories[0].inventoryId : null;

        fetchManufacturers(isMounted);
    }), []);

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns(
                    translations,
                    openManufacturerDialog,
                    existingManufacturers,
                    inventoryIdRef,
                    fetchManufacturers,
                    superUser
                )}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                deleteFilter={(manufacturer) => isManufacturerModifiable(manufacturer.inventoryId, inventoryIdRef.current, superUser)}
                deleteHandler={deleteManufacturerHandler}
                filterComponent={getFilterComponent(
                    translations,
                    openManufacturerDialog,
                    existingManufacturers,
                    inventoryIdRef,
                    fetchManufacturers,
                    showOnlyMyManufacturers,
                    setShowOnlyMyManufacturers
                )}
                loading={loading}
                noDataAvailableMessage={translations.ONLINK_NO_MANUFACTURERS_FOUND}
                rows={showOnlyMyManufacturers ? manufacturers.membershipManufacturers : manufacturers.allManufacturers}
                searchable={true}
                showDeleteColumn={isAuthorized({
                    myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                }, props)}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

ManufacturerSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openManufacturerDialog: PropTypes.func,
    superUser: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions,
        translations: state.translations,
        superUser: state.account.superUser
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openManufacturerDialog(props) {
            dispatch(openDialog(dialogTypes.MANUFACTURER_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerSettings);
