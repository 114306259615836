// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {IconShown, IconWrench, IconDelete} from '@deere/icons';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {createIconFillStyle} from 'Utils/icon-utils';
import {openDialog as openReduxDialog, closeDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {FLEET} from 'Common/constants/routes';
import {deleteEquipment} from 'Services/equipment-service';
import {
    EDIT_EQUIPMENT_LIST,
    EDIT_SERVICE,
    REMOVE_EQUIPMENT
} from 'Common/constants/business-activities';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function getDeleteEquipmentConfirmationMessage(translations) {
    return (
        <div>
            <div className='delete-maintenance'>
                <span className='warning-message'>
                    {translations.ONLINK_WARNING}
                </span>
                {translations.ONLINK_DELETE_EQUIPMENT_WARNING}
            </div>
            {translations.ONLINK_CONTINUE_CONFIRMATION_MESSAGE}
        </div>
    );
}

function createOnContinueCallback(closeConfirmation, {equipmentId}, updateFleetEquipment) {
    return async () => {
        await deleteEquipment(equipmentId);
        closeConfirmation();
        updateFleetEquipment();
    };
}

function EquipmentActions(props) {
    const {
        closeConfirmation,
        equipment,
        history,
        isMigrated,
        myJdPermissions,
        openDialog,
        openConfirmation,
        translations,
        updateFleetEquipment
    } = props;

    const createServiceUrl = `${FLEET}/serviceForm?equipmentId=${equipment.equipmentId}`;
    const deleteCallback = createOnContinueCallback(closeConfirmation, equipment, updateFleetEquipment);

    const deleteButton = (
        <OnLinkIconButton
            className='inline-icon-btn equipment-actions'
            onClick={() => {
                openConfirmation({
                    translations,
                    onCancel: closeConfirmation,
                    showLoadingIcon: true,
                    message: getDeleteEquipmentConfirmationMessage(translations),
                    onContinue: deleteCallback,
                    title: translations.ONLINK_DELETE_EQUIPMENT
                });
            }}
        >
            <IconDelete
                iconDelete={ICON_STYLE}
            />
        </OnLinkIconButton>
    );

    return (
        <>
            {
                (!isMigrated || myJdPermissions[EDIT_SERVICE]) &&
                <OnLinkIconButton
                    className='inline-icon-btn equipment-actions'
                    onClick={() => history.push(createServiceUrl)}
                >
                    <IconWrench
                        iconWrench={ICON_STYLE}
                        primary={createIconFillStyle('#f6a623')}
                    />
                </OnLinkIconButton>
            }
            {
                (!isMigrated || myJdPermissions[EDIT_EQUIPMENT_LIST]) &&
                <OnLinkIconButton
                    className='inline-icon-btn equipment-actions'
                    onClick={() => {
                        openDialog({
                            equipment,
                            updateFleetEquipment
                        });
                    }}
                >
                    <IconShown
                        iconShown={{
                            ...ICON_STYLE,
                            viewBox: '3 3 26 26'
                        }}
                        primary={createIconFillStyle('#357edd')}
                    />
                </OnLinkIconButton>
            }
            {
                (!isMigrated || myJdPermissions[REMOVE_EQUIPMENT]) && deleteButton
            }
        </>
    );
}

EquipmentActions.propTypes = {
    closeConfirmation: PropTypes.func,
    equipment: PropTypes.equipment,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openDialog: PropTypes.func,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openReduxDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openDialog(props) {
            dispatch(openReduxDialog(dialogTypes.CHECK_EQUIPMENT_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentActions);
