// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import dialogTypes from 'Ui/components/common/dialog-types';
import ListItem from 'Ui/components/common/list/list-item';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {ANALYTICS_EVENTS, trackDashboardSelection, trackOnLinkEvent, CATEGORY, ACTIONS, LABELS} from 'Utils/analytics-utils';

function LayoutDropdownContent(props) {
    const {
        activeLayoutId,
        availableLayouts,
        onClick,
        openDialog,
        toggleDropdown,
        translations
    } = props;

    return (
        <div className='layout-dropdown-container'>
            <div className='layout-dropdown-header title-1'>
                {translations.SELECT_DASHBOARD}
            </div>
            <div className='layout-dropdown-list'>
                {
                    availableLayouts.map((layout) => (
                        <ListItem
                            active={layout.id === activeLayoutId}
                            key={layout.id}
                            onClick={() => {
                                trackDashboardSelection(layout);
                                onClick(layout.id);
                                toggleDropdown();
                            }}
                            text={layout.name}
                        />
                    ))
                }
            </div>
            <div
                className='layout-dropdown-footer subtitle-1'
                onClick={() => {
                    trackOnLinkEvent(ANALYTICS_EVENTS.TRACK_EVENT, {
                        category: CATEGORY.ONLINK_DASHBOARD,
                        label: LABELS.MANAGE_DASHBOARDS,
                        action: ACTIONS.DASHBOARD_MENU,
                        value: ''
                    });
                    openDialog();
                    toggleDropdown();
                }}
            >
                {translations.MANAGE_DASHBOARDS}
            </div>
        </div>
    );
}

LayoutDropdownContent.propTypes = {
    activeLayoutId: PropTypes.string,
    availableLayouts: PropTypes.arrayOf(PropTypes.layout),
    onClick: PropTypes.func,
    openDialog: PropTypes.func,
    toggleDropdown: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        openDialog() {
            dispatch(openReduxDialog(dialogTypes.LAYOUT_EDIT_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(LayoutDropdownContent);
