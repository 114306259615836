// Unpublished Work © 2023-2024 Deere & Company.

import OnLinkProgressBar from 'Ui/components/common/onlink-progress-bar';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import {IconAlertTriangle} from '@deere/icons';
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';

const ICON_ALERT_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function MowerStatusBatteryLevel(props) {
    const {
        translations,
        thresholdClassName,
        lowBatteryWarning,
        row
    } = props;

    return (
        <div className={'battery-level-container'}>
            <div className={'battery-percentage'}>
                <span
                    className={`${thresholdClassName}-text  small-body-text-bold`}
                >
                    {`${row.original.value}%`}
                </span>
                <div className={'warning-tooltip-container'}>
                    {
                        lowBatteryWarning &&
                        <Tooltip
                            placement='bottom'
                            title={translations.ONLINK_BATTERY_CHARGE_WARNING}
                        >
                            <div>
                                <IconAlertTriangle
                                    iconAlertTriangle={ICON_ALERT_STYLE}
                                />
                            </div>
                        </Tooltip>
                    }
                </div>
            </div>
            <OnLinkProgressBar
                barClassName={'battery-progress-bar'}
                fillClassName={thresholdClassName}
                percentFilled={Number(row.original.value)}
            />
        </div>
    );
}

MowerStatusBatteryLevel.propTypes = {
    lowBatteryWarning: PropTypes.bool,
    row: PropTypes.object,
    thresholdClassName: PropTypes.string,
    translations: PropTypes.translations
};

export default MowerStatusBatteryLevel;
