// Unpublished Work © 2022-2024 Deere & Company.

import {deleteEquipmentExpense, getEquipmentExpenses} from 'Services/equipment-service';
import {dateCompare, genericServiceCompare, numericCompare} from 'Ui/models/maintenance';
import {formatDateOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import accounting from 'accounting';
import {EXPENSE_TYPES as equipmentExpenseTypes} from 'Ui/constants/equipment-expense-types-constants';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import EditLink from 'Ui/components/common/data-table/edit-link';
import React from 'react';
import {IconDelete} from '@deere/icons';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {getDeleteEntryConfirmationMessage} from 'Utils/data-utils';
import {EQUIPMENT_EXPENSE_EDIT} from 'Common/constants/feature-toggles';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

export async function getRowData(equipment, translations) {
    if (equipment?.equipmentId) {
        const {equipmentExpenses} = await getEquipmentExpenses(equipment.equipmentId);

        return equipmentExpenses.map((equipmentExpense) => {
            const expenseType = equipmentExpenseTypes[equipmentExpense.expenseType];

            return {
                ...equipmentExpense,
                formattedDateSelect: formatDateOrDefault(equipmentExpense.dateSelect),
                expenseType: capitalizeFirstLetter(translations[expenseType] || expenseType),
                formattedAmount: `$${accounting.formatNumber(equipmentExpense.amount, 2)}`
            };
        });
    }

    return [];
}

export function getColumns(translations, openAddEditExpenseDialog, openConfirmation, onCancel,
    updateEquipment, isMigrated, featureToggles) {
    return [
        {
            Header: translations.DATE,
            accessor(row) {
                return row;
            },
            id: 'formattedDateSelect',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'dateSelect');
            },
            Cell(row) {
                return isMigrated && featureToggles[EQUIPMENT_EXPENSE_EDIT] ?
                    <EditLink onClick={openAddEditExpenseDialog(row.original.equipmentExpenseId)}>
                        {row.original.formattedDateSelect}
                    </EditLink> :
                    row.original.formattedDateSelect;
            }
        },
        {
            Header: translations.TYPE,
            accessor: 'expenseType'
        },
        {
            Header: translations.ONLINK_AMOUNT,
            accessor(row) {
                return row;
            },
            id: 'amount',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 100,
            sortMethod(a, b) {
                return numericCompare(parseInt(a.amount, 10), parseInt(b.amount, 10));
            },
            Cell(row) {
                return row.original.formattedAmount;
            }
        },
        {
            accessor: 'actions',
            resizable: false,
            sortable: false,
            requireMigrated: true,
            featureToggle: EQUIPMENT_EXPENSE_EDIT,
            width: 30,
            Cell(row) {
                return (
                    <OnLinkIconButton
                        onClick={() => openConfirmation({
                            translations,
                            showLoadingIcon: true,
                            message: getDeleteEntryConfirmationMessage(translations),
                            onContinue: async () => {
                                await deleteEquipmentExpense(row.original.equipmentExpenseId);
                                onCancel();
                                updateEquipment();
                            },
                            title: translations.ONLINK_DELETE_ENTRY,
                            onCancel
                        })}
                    >
                        <IconDelete
                            iconDelete={ICON_STYLE}
                        />
                    </OnLinkIconButton>
                );
            }
        }
    ].filter((column) => (isMigrated || !isMigrated && !column.requireMigrated) &&
        (!column.featureToggle || featureToggles[column.featureToggle]));
}
