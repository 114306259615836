// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {useSave} from 'Ui/react-hooks/use-save';
import {createVendor, deleteVendorByMembershipId} from 'Services/vendor-service';
import {updateMembership as updateReduxMembership} from 'Store/actions/membership';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {SETTINGS} from 'Common/constants/routes';
import {TOAST_TYPE} from '@deere/toast';
import {cloneDeep} from 'lodash';
import {PLAYBOOK_URL_PATTERN} from 'Common/constants/regex';

const PLAYBOOKS_URL_PROPERTY = 'partner_link_playbooks_url';
const PLAYBOOKS_URL = 'https://www.covsys.net/GoPlayBooksService/Report.svc';
const INTERNATIONAL_PLAYBOOKS_URL = 'https://www.covsys.net/GoPlayBooksServiceInternational/Report.svc';

function PlaybooksSettings(props) {
    const {
        addToast,
        history,
        invalidInputs,
        membership,
        setValid,
        translations,
        updateMembership
    } = props;

    const [playbooksUrl, setPlaybooksUrl] = React.useState(() => membership.properties?.partner_link_playbooks_url || '');

    async function invokeApi(callback, data) {
        try {
            await callback(data);

            addToast({
                message: translations.SUCCESS,
                type: TOAST_TYPE.SUCCESS
            });

            const clonedMembership = cloneDeep(membership);

            if (!clonedMembership.properties) {
                clonedMembership.properties = {};
            }

            clonedMembership.properties[PLAYBOOKS_URL_PROPERTY] = playbooksUrl;

            updateMembership(clonedMembership);
        } catch (err) {
            addToast({
                message: translations.ERROR_OCCURRED_TITLE,
                type: TOAST_TYPE.ERROR
            });
        }
    }

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        if (playbooksUrl) {
            const parsedPlaybooksUrl = new URL(playbooksUrl);
            const pathName = parsedPlaybooksUrl.pathname.split('/')[1];
            const searchParams = new URLSearchParams(parsedPlaybooksUrl.search);

            await invokeApi(createVendor, {
                apiKey: searchParams.get('courseurl'),
                isDaily: true,
                membershipId: membership.membershipId,
                name: 'PlayBooks',
                tzOffsetMillis: 0,
                url: pathName?.toLowerCase() === 'premiuminternational' ? INTERNATIONAL_PLAYBOOKS_URL : PLAYBOOKS_URL,
                vendorUrl: playbooksUrl
            });
        } else {
            await invokeApi(deleteVendorByMembershipId, membership.membershipId);
        }
    }, {
        invalidInputs
    });

    return (
        <div className='playbooks-settings'>
            <form
                className='playbooks-url'
                onSubmit={saveFunc}
            >
                <ValidationInput
                    autoFocus={true}
                    className='playbooks-input'
                    errors={{
                        patternMismatch: translations['JQUERY.VALIDATOR.URL']
                    }}
                    inputMode='url'
                    label={translations.ONLINK_URL}
                    name='playbooksUrl'
                    onChange={(event) => setPlaybooksUrl(event.target.value)}
                    onClear={() => setPlaybooksUrl('')}
                    pattern={PLAYBOOK_URL_PATTERN}
                    placeholder={translations.ONLINK_URL}
                    setValid={setValid}
                    tabIndex={0}
                    type='url'
                    value={playbooksUrl}
                />
            </form>
            <SaveContentBar
                disabled={disableSave}
                loading={isSaving}
                onCancelClick={() => history.push(SETTINGS)}
                onSaveClick={saveFunc}
                translations={translations}
            />
        </div>
    );
}

PlaybooksSettings.propTypes = {
    addToast: PropTypes.func,
    history: PropTypes.history,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        updateMembership(value) {
            dispatch(updateReduxMembership(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(withRouter(PlaybooksSettings)));
