// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import {clsx} from 'clsx';
import {IconCaretDirection} from 'Ui/components/main-navbar';
import {isActive} from 'Ui/components/secondary-navbar/utils/nav-helpers';
import ListItem from 'Ui/components/common/list/list-item';

function SecondaryMobileDropdown(props) {
    const {
        active,
        location,
        links,
        onClick,
        name
    } = props;

    const [open, setOpen] = React.useState(() => active);

    const linksComponent = links.map((link) => {
        const activeLink = isActive(link, location);
        const navItem = {
            link
        };

        return (
            <ListItem
                active={activeLink}
                key={link.name}
                onClick={() => onClick(navItem)}
                text={link.name}
            />
        );
    });

    const dropdown = (
        <div
            className={'small-body-text-bold secondary-nav-mobile-dropdown'}
            onClick={(e) => e.stopPropagation()}
        >
            {linksComponent}
        </div>
    );

    const className = clsx('medium-section-title', {
        'open-dropdown': open
    });

    const icon = (
        <IconCaretDirection
            caretUp={open}
            color='#000'
        />
    );

    return (
        <div className={'secondary-nav-mobile-dropdown-wrapper'}>
            <ListItem
                active={active && !open}
                className={className}
                icon={icon}
                onClick={() => setOpen(!open)}
                text={name}
            />
            {open && dropdown}
        </div>
    );
}

SecondaryMobileDropdown.propTypes = {
    active: PropTypes.bool,
    history: PropTypes.history,
    links: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.location,
    name: PropTypes.string,
    onClick: PropTypes.func
};

export default withRouter(SecondaryMobileDropdown);
