// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createService(serviceData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/service', serviceData);

    return response.data;
}

async function updateService(serviceData, serviceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/service/${serviceId}`, serviceData);

    return response.data;
}

async function skipService(serviceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/service/${serviceId}/skip`);

    return response.data;
}

async function getService(serviceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/service/${serviceId}`);

    return response.data;
}

async function deleteService(serviceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/service/${serviceId}`);

    return response.data;
}

async function getServiceTasks() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/serviceTasks');

    return response.data;
}

async function getServiceTypes() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/serviceTypes');

    return response.data;
}

async function getServiceLogs(serviceId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/service/${serviceId}/logs`);

    return response.data;
}

async function postServiceLog(serviceId, logState) {
    const serviceLogData = {
        logType: logState
    };

    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post(`/service/${serviceId}/logs`, serviceLogData);

    return response.data;
}

export {
    createService,
    updateService,
    getService,
    deleteService,
    getServiceTasks,
    getServiceTypes,
    getServiceLogs,
    postServiceLog,
    skipService
};
