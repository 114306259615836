// Unpublished Work © 2021-2024 Deere & Company.

export default {
    maintenance: 'Maintenance',
    repair: 'Repair',
    damageRepair: 'Damage Repair',
    translationMapping: {
        Maintenance: 'ONLINK_MAINTENANCE',
        Repair: 'Repair',
        'Damage Repair': 'ONLINK_DAMAGE_REPAIR'
    }
};
