// Unpublished Work © 2021-2024 Deere & Company.

export const THRESHOLD_CLASSNAMES = {
    extremeThreshold: 'extreme-threshold',
    normalThreshold: 'normal-threshold',
    warningThreshold: 'warning-threshold',
    extremeThresholdText: 'extreme-threshold-text',
    normalThresholdText: 'normal-threshold-text',
    warningThresholdText: 'warning-threshold-text'
};

export const EXTREME_BATTERY_THRESHOLD = 10;
export const WARNING_BATTERY_THRESHOLD = 60;
