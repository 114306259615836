// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditableCell from 'Ui/components/common/data-table/editable-cell';
import SwitchInput from 'Ui/components/common/form/switch-input';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {fetchEffectData} from 'Utils/react-utils';
import {getFilteredFleetEquipment} from 'Services/membership-service';
import {TOAST_TYPE} from '@deere/toast';
import {bulkUpdateEquipment} from 'Utils/equipment-utils';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {dateCompare} from 'Ui/models/maintenance';
import {formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {MAX_ENGINE_HOURS} from 'Ui/constants/equipment-constants';
import {translateEquipmentArea} from 'Utils/translation-utils';

const TEN = 10;

function parseToTenths(value) {
    return Math.floor(parseFloat(value) * TEN) / TEN;
}

function getColumns(translations, updateEngineHours, setUpdateEngineHours, setValid, currentFleet, setCurrentFleet) {
    return [
        {
            Header: translations.NAME,
            accessor: 'equipmentName',
            className: 'sticky bulk-engine',
            headerClassName: 'sticky'
        },
        {
            Header: translations.TYPE,
            accessor: 'equipmentType'
        },
        {
            Header: translations.BRAND,
            accessor: 'manufacturerName'
        },
        {
            Header: translations.MODEL,
            accessor: 'modelName'
        },
        {
            Header: translations.ONLINK_SERIAL_NUMBER,
            accessor: 'serialNumber'
        },
        {
            Header: translations.ONLINK_LAST_ENGINE_HOURS_UPDATE,
            accessor(row) {
                return row;
            },
            id: 'timeLastMeterReading',
            width: 255,
            sortMethod(a, b) {
                return dateCompare(a.timeLastMeterReading, b.timeLastMeterReading);
            },
            Cell(row) {
                return row.original.formattedTimeLastMeterReading;
            }
        },
        {
            Header: translations.HOURS,
            accessor: 'totalHours',
            className: 'sticky-right editable-cell',
            headerClassName: 'right-aligned sticky-right',
            width: 110,
            Cell(row) {
                const equipment = row.original;

                function onChange(event) {
                    const {value} = event.target;
                    const parsedValue = value ? parseToTenths(value) : '';

                    const index = updateEngineHours.findIndex((equipmentEntry) => equipmentEntry.equipmentId === equipment.equipmentId);
                    const fleetIndex = currentFleet.findIndex((equipmentEntry) => equipmentEntry.equipmentId === equipment.equipmentId);

                    currentFleet[fleetIndex].totalHours = parsedValue;

                    if (index !== -1) {
                        updateEngineHours[index].totalHours = parsedValue;
                    } else {
                        updateEngineHours.push({
                            equipmentId: equipment.equipmentId,
                            equipmentName: equipment.equipmentName,
                            totalHours: parsedValue
                        });
                    }

                    setCurrentFleet(currentFleet);
                    setUpdateEngineHours(updateEngineHours);

                    return parsedValue;
                }

                return (
                    <EditableCell
                        errors={{
                            rangeUnderflow: ' ',
                            rangeOverflow: ' '
                        }}
                        initialValue={parseToTenths(equipment.totalHours)}
                        max={MAX_ENGINE_HOURS}
                        min={0}
                        name={`engineHours${row.original.equipmentId}`}
                        onChange={onChange}
                        setValid={setValid}
                        type='number'
                    />
                );
            }
        }
    ];
}

function getFilterComponent(showTelematicEnabled, setShowTelematicEnabled, translations) {
    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={showTelematicEnabled}
                name='showTelematicEnabled'
                offLabel={translations.ONLINK_SHOW_TELEMATIC_ENABLED}
                onChange={() => setShowTelematicEnabled(!showTelematicEnabled)}
                onLabel={translations.ONLINK_SHOW_TELEMATIC_ENABLED}
                translations={translations}
            />
        </div>
    );
}

function initializeState() {
    const [showTelematicEnabled, setShowTelematicEnabled] = React.useState(() => false);
    const [currentFleet, setCurrentFleet] = React.useState(() => []);
    const [updateEngineHours, setUpdateEngineHours] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => false);
    const [footerLoading, setFooterLoading] = React.useState(() => false);

    return {
        showTelematicEnabled,
        setShowTelematicEnabled,
        currentFleet,
        setCurrentFleet,
        updateEngineHours,
        setUpdateEngineHours,
        loading,
        setLoading,
        footerLoading,
        setFooterLoading
    };
}

function BulkEngineHoursDialog(props) {
    const {
        addToast,
        closeDialog,
        invalidInputs,
        membershipId,
        setValid,
        title,
        translations,
        updateFleetEquipment
    } = props;

    const {
        showTelematicEnabled,
        setShowTelematicEnabled,
        currentFleet,
        setCurrentFleet,
        updateEngineHours,
        setUpdateEngineHours,
        loading,
        setLoading,
        footerLoading,
        setFooterLoading
    } = initializeState();

    async function fetchFleetData(isMounted) {
        setLoading(true);

        const equipment = await getFilteredFleetEquipment();

        const nonArchivedEquipment = equipment
            .map((nonArchived) => ({
                ...nonArchived,
                equipmentArea: translateEquipmentArea(translations, nonArchived.equipmentArea) || translations.ONLINK_UNASSIGNED,
                formattedTimeLastMeterReading: formatTimeOrDefault(nonArchived.timeLastMeterReading)
            }));

        if (isMounted()) {
            setCurrentFleet(nonArchivedEquipment);
            setUpdateEngineHours([]);
            setLoading(false);
        }
    }

    async function addErrorMessage(rejectedNames) {
        const message = translations.ONLINK_BULK_HOURS_ERROR.replace('{0}', rejectedNames);

        addToast({
            message,
            persist: true,
            type: TOAST_TYPE.ERROR
        });

        await fetchFleetData(() => true);
    }

    async function onSave() {
        setFooterLoading(true);

        const rejectedResults = await bulkUpdateEquipment(updateEngineHours);

        setFooterLoading(false);

        if (rejectedResults.length !== 0) {
            const rejectedNames = rejectedResults.join(', ');

            await addErrorMessage(rejectedNames);
        } else {
            updateFleetEquipment();
            closeDialog();
        }
    }

    React.useEffect(() => fetchEffectData(fetchFleetData), [membershipId]);

    const filteredFleet = showTelematicEnabled ?
        currentFleet :
        currentFleet.filter((equipment) => !equipment.lastReportedMtgId);

    return (
        <FormDialog
            addToast={addToast}
            cancelLabel={translations.CANCEL}
            className='bulk-engine-hours-dialog'
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0}
            footerLoading={footerLoading}
            onSave={onSave}
            saveLabel={translations.save}
            title={title}
            translations={translations}
        >
            <DataTable
                columns={getColumns(translations, updateEngineHours, setUpdateEngineHours, setValid, currentFleet, setCurrentFleet)}
                defaultSorted={[{
                    desc: false,
                    id: 'equipmentName'
                }]}
                filterComponent={getFilterComponent(showTelematicEnabled, setShowTelematicEnabled, translations)}
                loading={loading}
                rows={filteredFleet}
                searchable={true}
                translations={translations}
            />
        </FormDialog>
    );
}

BulkEngineHoursDialog.propTypes = {
    addToast: PropTypes.func,
    closeDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipId: PropTypes.string,
    setValid: PropTypes.func,
    title: PropTypes.string,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.BULK_ENGINE_HOURS_DIALOG));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(BulkEngineHoursDialog));
