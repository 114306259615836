// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createCourseNameToIdMap(courseNameData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/vendors/courseNames', courseNameData);

    return response.data;
}

async function createVendor(vendorData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/vendors', vendorData);

    return response.data;
}

async function deleteCourseNameToIdMap(dataSource, courseName) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/vendors/courseNames/${dataSource}/${encodeURIComponent(courseName)}`);

    return response.data;
}

async function deleteVendorByMembershipId(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/vendors/${membershipId}`);

    return response.data;
}

async function getCourseNames(courseId, dataSource) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/vendors/courseNames/${courseId}/${dataSource}`);

    return response.data;
}

async function getVendors(dataSource) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/vendors/${dataSource}`);

    return response.data;
}

async function isMultiCourse(dataSource) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/vendors/isMultiCourse/${dataSource}`);

    return response.data;
}

async function getSensorIds() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/vendors/sensorIds');

    return response.data;
}

async function createSensor(sensorData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/vendors/sensorId', sensorData);

    return response.data;
}

async function deleteSensor(sensorId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/vendors/sensorId/${encodeURIComponent(sensorId)}`);

    return response.data;
}

export {
    createCourseNameToIdMap,
    createVendor,
    deleteCourseNameToIdMap,
    deleteVendorByMembershipId,
    getCourseNames,
    getVendors,
    isMultiCourse,
    getSensorIds,
    createSensor,
    deleteSensor
};
