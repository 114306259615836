// Unpublished Work © 2022-2024 Deere & Company.

import DataTable from 'Ui/components/common/data-table/data-table';
import React from 'react';
import {numericCompare} from 'Ui/models/maintenance';
import PropTypes from 'Utils/prop-type-utils';
import {ALL, INVENTORIES} from 'Ui/components/graph/constants/graph-filters';

function getCostColumns(secondarySelector, translations) {
    const equipmentColumnsSection = secondarySelector === ALL ? [] :
        [
            {
                Header: translations.BRAND,
                accessor: 'brand'
            },
            {
                Header: translations.MODEL,
                accessor: 'model'
            },
            {
                Header: translations.ONLINK_JOINED,
                accessor: 'joined'
            }
        ];

    return [
        {
            Header: translations.NAME,
            accessor: 'name'
        },
        ...equipmentColumnsSection,
        {
            Header: translations.ONLINK_PURCHASE,
            id: 'purchaseCost',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.purchaseCost, b.purchaseCost);
            },
            Cell(row) {
                return row.original.purchaseCostFormatted;
            }
        },
        {
            Header: translations.ONLINK_TAX,
            id: 'tax',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.tax, b.tax);
            },
            Cell(row) {
                return row.original.taxFormatted;
            }
        },
        {
            Header: translations.PARTS,
            id: 'parts',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.parts, b.parts);
            },
            Cell(row) {
                return row.original.partsFormatted;
            }
        },
        {
            Header: translations.ONLINK_SERVICE,
            id: 'service',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.service, b.service);
            },
            Cell(row) {
                return row.original.serviceFormatted;
            }
        },
        {
            Header: translations.ONLINK_TOTAL,
            id: 'total',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.total, b.total);
            },
            Cell(row) {
                return row.original.totalFormatted;
            }
        },
        {
            Header: translations.HOURS,
            id: 'engineHours',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.engineHours, b.engineHours);
            },
            Cell(row) {
                return row.original.engineHoursFormatted;
            }
        },
        {
            Header: translations.ONLINK_COST_PER_HOUR,
            id: 'costPerHour',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.costPerEngineHour, b.costPerEngineHour);
            },
            Cell(row) {
                return row.original.costPerEngineHourFormatted;
            }
        }
    ];
}

function getInventoriesColumns(translations) {
    return [
        {
            Header: translations.ONLINK_PART_TYPE,
            accessor: 'partType'
        },
        {
            Header: translations.ONLINK_TOTAL_COST,
            id: 'totalCost',
            accessor(row) {
                return row;
            },
            sortMethod(a, b) {
                return numericCompare(a.totalCost, b.totalCost);
            },
            Cell(row) {
                return row.original.formattedTotalCost;
            }
        }
    ];
}

function getColumns(primarySelector, secondarySelector, translations) {
    if (primarySelector === INVENTORIES) {
        return getInventoriesColumns(translations);
    }

    return getCostColumns(secondarySelector, translations);
}

function LifetimeReportDataTable(props) {
    const {
        primarySelector,
        secondarySelector,
        tableData,
        translations
    } = props;

    return (
        <DataTable
            columns={getColumns(primarySelector, secondarySelector, translations)}
            defaultSorted={[{
                desc: false,
                id: primarySelector === INVENTORIES ? 'partType' : 'name'
            }]}
            rows={tableData}
        />
    );
}

LifetimeReportDataTable.propTypes = {
    primarySelector: PropTypes.string,
    secondarySelector: PropTypes.string,
    tableData: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations

};

export default LifetimeReportDataTable;
