// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import ConfirmationDialog from 'Ui/components/dialogs/confirmation-dialog';
import {connect} from 'react-redux';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {TOAST_TYPE} from '@deere/toast';
import {addToast} from 'Store/actions/toasts';
import PropTypes from 'Utils/prop-type-utils';
function MigrationConfirmationDialog(props) {
    const {
        translations,
        confirmTitle,
        migrationMessageBody,
        migrationConfirmButtonText,
        successTitle,
        migrationSuccessBody,
        teamManagerLinkText,
        openMigrationSuccessDialog,
        onClose,
        closeConfirmation,
        migrationAction,
        addToast
    } = props;

    function closeActions() {
        closeConfirmation();
        onClose();
    }

    function openTeamManager() {
        closeActions();
        window.open(window.props.teamManagerLink, '_blank');
    }

    async function doMigrationAction() {
        try {
            await migrationAction();
            openMigrationSuccessDialog({
                title: successTitle,
                message: migrationSuccessBody,
                continueTitle: teamManagerLinkText,
                cancelTitle: translations.CLOSE,
                onCancel: closeActions,
                onContinue: openTeamManager
            });
        } catch (e) {
            addToast({
                message: translations.ERROR_OCCURRED_TITLE,
                type: TOAST_TYPE.ERROR
            });
        }
    }

    return (
        <ConfirmationDialog
            cancelTitle={translations.CANCEL}
            continueTitle={migrationConfirmButtonText || translations.continue}
            message={migrationMessageBody}
            onCancel={closeActions}
            onContinue={doMigrationAction}
            showLoadingIcon={true}
            title={confirmTitle}
        />
    );
}

MigrationConfirmationDialog.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    confirmTitle: PropTypes.string,
    migrationAction: PropTypes.func,
    migrationConfirmButtonText: PropTypes.string,
    migrationMessageBody: PropTypes.string,
    migrationSuccessBody: PropTypes.string,
    onClose: PropTypes.func,
    openMigrationSuccessDialog: PropTypes.func,
    successTitle: PropTypes.string,
    teamManagerLinkText: PropTypes.string,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        openMigrationSuccessDialog(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        addToast(value) {
            dispatch(addToast(value));
        }
    };
}

export default connect(null, mapDispatchToProps)(MigrationConfirmationDialog);
