// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import moment from 'moment';
import onlaborImage from 'Ui/images/onlabor-white.png';
import jdImage from 'Ui/images/jd-ag.svg';
import {formatLocalizedTime} from 'Utils/time-utils';
import {getWeatherIcon} from 'Utils/weather-utils';
import {getLocalTimeFormats} from 'Ui/components/manual-data/manual-data-utils';
import {DEGREES_F} from 'Common/constants/data-unit-constants';
import {convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {formatNumberWithUnit} from 'Utils/data-utils';
import {TRUE} from 'Ui/constants/membership-properties';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import Box from '@mui/material/Box';

function getStyles(isMigrated, isMobile) {
    const migratedStyles = isMigrated ? {
        backgroundColor: '#fff',
        color: '#000',
        justifyContent: 'space-between',
        padding: '0px 16px'
    } : {
        backgroundColor: '#4c4c4c',
        color: '#fff',
        justifyContent: 'space-around',
        padding: '0'
    };

    const imgStyles = isMobile ? {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '12px'
    } : {};

    const headerTextStyles = isMobile ? {
        fontSize: 'smaller',
        padding: '5px',
        fontWeight: 'bold'
    } : {
        fontSize: 'large',
        fontWeight: 'bold'
    };

    return {
        headerTextStyles,
        imgStyles,
        migratedStyles
    };
}

function formatWeather(data, featureToggles, translations, membership) {
    const formattedData = [];
    const NUM_WEATHER_CELLS = 6;

    const tempConversionConfig = {
        featureToggles,
        translations,
        unitOfMeasure: membership.unitOfMeasure,
        dataUnit: DEGREES_F
    };

    for (const element of data?.slice(0, NUM_WEATHER_CELLS)) {
        const time = moment(element.fcst_valid_local);
        const temp = convertToFormattedUoM(element.temp, tempConversionConfig);
        const precipitation = formatNumberWithUnit(element.pop, 'percent', 0);

        formattedData.push(
            <li key={`weather-data-${element.fcst_valid}`}>
                <b>{moment(time).format(getLocalTimeFormats())}</b>{` | ${temp}, ${precipitation} ${translations.ONLINK_PRECIP}`}
                <img
                    alt={element.icon_code.toString()}
                    className='preview-weather-icon'
                    src={getWeatherIcon(element.icon_code)}
                />
            </li>
        );
    }

    return formattedData;
}

function Header(props) {
    const {
        featureToggles,
        isMobile,
        isPreview,
        membership,
        translations,
        weather,
        workboard
    } = props;

    const showWeather = membership.properties.workboard_presentation_show_weather === TRUE;

    const {
        headerTextStyles,
        imgStyles,
        migratedStyles
    } = getStyles(membership.isMigrated, isMobile);

    return (
        <>
            <Stack
                {...migratedStyles}
                alignItems='center'
                direction='row'
                flexWrap='wrap'
                fontSize='inherit'
                minHeight={60}
            >
                <Box {...imgStyles}>
                    {
                        membership.isMigrated ?
                            <img
                                alt='johndeere'
                                height={32}
                                src={jdImage}
                                title='JohnDeereLogo'
                                width={162}
                            /> :
                            <img
                                alt='OnLabor'
                                className={'preview-logo'}
                                src={onlaborImage}
                                title='OnLabor'
                            />
                    }
                </Box>
                <Typography {...headerTextStyles}>
                    {membership.name}
                </Typography>
                <Typography {...headerTextStyles}>
                    {workboard.name}
                </Typography>
                <Typography {...headerTextStyles}>{
                    formatLocalizedTime(moment(workboard.startTime).toDate(), {
                        weekday: 'long',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })
                }</Typography>
            </Stack>
            {
                showWeather && Boolean(weather?.length) &&
                <ul className={'preview-element preview-weather'}>
                    {formatWeather(weather, featureToggles, translations, membership)}
                </ul>
            }
            {
                isPreview &&
                <div className={'preview-element preview-ribbon'}>
                    {translations.ONLINK_PREVIEW_MODE.toUpperCase()}
                </div>
            }
        </>
    );
}

Header.propTypes = {
    featureToggles: PropTypes.featureToggles,
    isMobile: PropTypes.bool,
    isPreview: PropTypes.bool,
    membership: PropTypes.object,
    translations: PropTypes.translations,
    weather: PropTypes.arrayOf(PropTypes.object),
    workboard: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(Header));
