// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {postInventoryTransfers} from 'Services/inventory-service';
import {useSave} from 'Ui/react-hooks/use-save';
import SelectedPartQuantityWithCost from 'Ui/components/common/parts-selectors/selected-part-quantity-with-cost';
import PartSelector from 'Ui/components/common/parts-selectors/part-selector';
import InventoryPartsTotalCost from 'Ui/features/onequip/parts/inventory/inventory-parts-total-cost';
import {asNumber} from 'Utils/conversion-utils';

function StockInventoryDialog(props) {
    const {
        availableParts,
        closeDialog,
        currencyPreference,
        featureToggles,
        initialSelectedPart,
        invalidInputs,
        inventories,
        onClose,
        setValid,
        translations
    } = props;

    const [selectedParts, setSelectedParts] = React.useState(initialSelectedPart ? [{
        ...initialSelectedPart,
        id: initialSelectedPart.partId,
        quantity: 0,
        subtitle: `${initialSelectedPart.manufacturerName} - ${initialSelectedPart.name}`,
        title: initialSelectedPart.partNumber,
        unitCost: 0
    }] : []);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const partTransfers = selectedParts.map((part) => ({
            ...part,
            quantity: asNumber(part.quantity),
            totalCost: asNumber(part.totalCost),
            unitCost: asNumber(part.unitCost)
        }));

        const transferData = {
            destinationInventoryId: inventories[0].inventoryId,
            partTransfers
        };

        await postInventoryTransfers(transferData);

        if (onClose) {
            onClose();
        }

        closeDialog();
    }, {
        invalidInputs
    });

    const initializedAvailableParts = React.useMemo(() => availableParts.map((part) => ({
        ...part,
        quantity: 0,
        unitCost: 0
    })), [availableParts]);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='stock-inventory-dialog dropdown-overflow-dialog'
            closeHandler={closeDialog}
            disableSave={disableSave || selectedParts.length === 0}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.ONLINK_STOCK_INVENTORY}
            title={translations.ONLINK_STOCK_INVENTORY}
            translations={translations}
        >
            <form className='stock-inventory-form'>
                <PartSelector
                    additionalSubTitleSelector='partNumber'
                    availableParts={initializedAvailableParts}
                    currencyPreference={currencyPreference}
                    disabled={false}
                    featureToggles={featureToggles}
                    inventories={inventories}
                    partsInputComponent={SelectedPartQuantityWithCost}
                    selectedParts={selectedParts}
                    selectedPartsClassName={'selected-parts'}
                    setSelectedParts={setSelectedParts}
                    setValid={setValid}
                    showAddNewPartOption={true}
                    subTitleSelector='manufacturerName'
                    titleSelector='name'
                    translations={translations}
                />
            </form>
            <InventoryPartsTotalCost
                currencyPreference={currencyPreference}
                featureToggles={featureToggles}
                selectedParts={selectedParts}
                translations={translations}
            />
        </FormDialog>
    );
}

StockInventoryDialog.propTypes = {
    availableParts: PropTypes.arrayOf(PropTypes.object),
    closeDialog: PropTypes.func,
    currencyPreference: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    initialSelectedPart: PropTypes.object,
    invalidInputs: PropTypes.instanceOf(Set),
    inventories: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        currencyPreference: state.membership.currencyPreference,
        featureToggles: state.account.featureToggles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.STOCK_INVENTORY_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(StockInventoryDialog));
