// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Search from 'Ui/components/search/search';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {IconFilter, IconRemove} from '@deere/icons';
import PillButton from 'Ui/components/common/pill-button';

const ICON_FILTER_STYLE = {
    style: {
        cursor: 'pointer',
        display: 'inline',
        height: '15px',
        width: '15px',
        fill: '#000'
    }
};

const ICON_REMOVE_STYLE = {
    style: {
        cursor: 'pointer',
        height: '15px',
        width: '15px',
        alignSelf: 'center'
    }
};

const icon = (
    <IconRemove iconRemove={ICON_REMOVE_STYLE}/>
);

function SidebarSearchbar(props) {
    const {
        appliedFilters,
        onFilterClick,
        onSearchChange,
        search,
        translations
    } = props;

    return (
        <div className='map-search'>
            <div className='search-row'>
                <Search
                    handler={onSearchChange}
                    search={search}
                    translations={translations}
                />
                <OnLinkIconButton
                    className='filter-button'
                    onClick={onFilterClick}
                >
                    <IconFilter iconFilter={ICON_FILTER_STYLE}/>
                </OnLinkIconButton>
            </div>
            <div className='applied-filters'>
                {
                    appliedFilters.map((filter, i) => (
                        <PillButton
                            callBack={filter.clearCallBack}
                            icon={icon}
                            key={i}
                        >
                            {filter.name}
                        </PillButton>
                    ))
                }
            </div>
        </div>
    );
}

SidebarSearchbar.propTypes = {
    appliedFilters: PropTypes.appliedFilters,
    onFilterClick: PropTypes.func,
    onSearchChange: PropTypes.func,
    search: PropTypes.string,
    translations: PropTypes.translations
};

export default SidebarSearchbar;
