// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import PartsMultiSelect from 'Ui/components/common/parts-selectors/parts-multi-select';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function getSubtitle(part, subTitleSelector, additionalSubTitleSelector) {
    const baseSubtitle = part[subTitleSelector];

    return additionalSubTitleSelector ?
        `${baseSubtitle} - ${part[additionalSubTitleSelector]}` :
        baseSubtitle;
}

function PartSelector(props) {
    const {
        additionalSubTitleSelector,
        availableParts,
        currencyPreference,
        disabled,
        featureToggles,
        hideFinancialData,
        inventories,
        openDialog,
        partsInputComponent,
        readOnly,
        selectedParts,
        setAvailableParts,
        setPartRequestsToRemove,
        setSelectedParts,
        setValid,
        showAddNewPartOption,
        subTitleSelector,
        titleSelector,
        translations,
        selectedPartsClassName,
        newPartQuantity
    } = props;

    const filteredParts = React.useMemo(() => {
        return availableParts
            .filter(({partId}) => selectedParts.every(({id: selectedId}) => selectedId !== partId))
            .map((part) => ({
                ...part,
                id: part.partId,
                title: part[titleSelector] || '',
                subtitle: getSubtitle(part, subTitleSelector, additionalSubTitleSelector)
            }));
    }, [availableParts, selectedParts]);

    function onPartSelect([partId]) {
        const selectedPart = filteredParts.find(({id}) => id === partId);

        setSelectedParts([...selectedParts, selectedPart]);
    }

    const footer = showAddNewPartOption ? {
        disabled: false,
        onClick() {
            openDialog({
                inventories,
                onPartSave(savedPart) {
                    const mappedPart = {
                        ...savedPart,
                        id: savedPart.partId,
                        title: savedPart[titleSelector],
                        subtitle: getSubtitle(savedPart, subTitleSelector, additionalSubTitleSelector),
                        quantity: newPartQuantity,
                        unitCost: 0
                    };

                    if (!selectedParts.some(({id}) => id === mappedPart.id)) {
                        setSelectedParts([...selectedParts, mappedPart]);

                        if (setAvailableParts) {
                            setAvailableParts([...availableParts, mappedPart]);
                        }
                    }
                },
                translations
            });
        },
        title: translations.ONLINK_ADD_NEW_PART
    } : null;

    return (
        <PartsMultiSelect
            currencyPreference={currencyPreference}
            disabled={disabled}
            featureToggles={featureToggles}
            filteredParts={filteredParts}
            footerAction={footer}
            hideFinancialData={hideFinancialData}
            onPartSelect={onPartSelect}
            partsInputComponent={partsInputComponent}
            readOnly={readOnly}
            selectedParts={selectedParts}
            selectedPartsClassName={selectedPartsClassName}
            setPartRequestsToRemove={setPartRequestsToRemove}
            setSelectedParts={setSelectedParts}
            setValid={setValid}
            translations={translations}
        />
    );
}

PartSelector.defaultProps = {
    newPartQuantity: 0
};

PartSelector.propTypes = {
    additionalSubTitleSelector: PropTypes.string,
    availableParts: PropTypes.arrayOf(PropTypes.object),
    currencyPreference: PropTypes.string,
    disabled: PropTypes.bool,
    featureToggles: PropTypes.featureToggles,
    hideFinancialData: PropTypes.bool,
    inventories: PropTypes.arrayOf(PropTypes.object),
    newPartQuantity: PropTypes.number,
    openDialog: PropTypes.func,
    partsInputComponent: PropTypes.elementType,
    readOnly: PropTypes.bool,
    selectedParts: PropTypes.arrayOf(PropTypes.object),
    selectedPartsClassName: PropTypes.string,
    setAvailableParts: PropTypes.func,
    setPartRequestsToRemove: PropTypes.func,
    setSelectedParts: PropTypes.func,
    setValid: PropTypes.func,
    showAddNewPartOption: PropTypes.bool,
    subTitleSelector: PropTypes.string,
    titleSelector: PropTypes.string,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        openDialog(value) {
            dispatch(openReduxDialog(dialogTypes.PART_DIALOG, value));
        }
    };
}

export default connect(null, mapDispatchToProps)(PartSelector);
