// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Datepicker} from '@deere/form-controls';
import {IconEdit} from '@deere/icons';
import {DropdownMenu} from '@deere/isg.component-library/lib/DropdownMenu/DropdownMenu';
import DataTable from 'Ui/components/common/data-table/data-table';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {getHoc} from 'Services/hoc-service';
import {createIconFillStyle} from 'Utils/icon-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {getHocValue, convertToFormattedUoM, getLanguagePreference} from 'Utils/unit-conversion-utils';
import {openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {COURSE} from 'Common/constants/membership-type';
import {HOC as HOC_CONSTANT, HOC_SPEC as HOC_SPEC_CONSTANT} from 'Common/constants/data-group-constants';
import {INCHES} from 'Common/constants/data-unit-constants';
import {sortBy} from 'lodash';
import moment from 'moment';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

function getColumns(translations) {
    return [
        {
            Header: translations.AREA,
            accessor: 'name'
        },
        {
            Header: translations.ONLINK_HOC,
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            accessor: 'hocValue'
        },
        {
            Header: translations.ONLINK_SPECIAL,
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            accessor: 'hocSpecialValue'
        }
    ];
}

async function openHocEditDialog(date, openDialog, membership, updateCourseMembership, translations, dataGroup) {
    openDialog({
        initialDate: date,
        membership,
        translations,
        updateCourseMembership,
        dataGroup
    });
}

function getHOCButtons(isMobile, date, openDialog, membership, updateCourseMembership, translations, editHocDialog, editSpecialHocDialog) {
    if (isMobile) {
        const items = [
            {
                title: translations.EDIT,
                onClick: editHocDialog
            },
            {
                title: translations.ONLINK_EDIT_SPECIAL,
                onClick: editSpecialHocDialog
            }
        ];

        return (
            <DropdownMenu
                PopoverProps={{
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom'
                    },
                    transformOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    }
                }}
                buttonTitle={translations.ACTIONS}
                className='onlink-action-dropdown'
                items={items}
            />
        );
    }

    return (
        <div className='table-buttons'>
            <OnlinkButton
                className='primary'
                leftIcon={
                    <IconEdit
                        iconEdit={ICON_EDIT_STYLE}
                        primary={createIconFillStyle('#fff')}
                    />
                }
                onClick={editHocDialog}
            >
                {translations.EDIT}
            </OnlinkButton>
            <OnlinkButton
                className='primary'
                leftIcon={
                    <IconEdit
                        iconEdit={ICON_EDIT_STYLE}
                        primary={createIconFillStyle('#fff')}
                    />
                }
                onClick={editSpecialHocDialog}
            >
                {translations.ONLINK_EDIT_SPECIAL}
            </OnlinkButton>
        </div>
    );
}

function getFilterComponent(date, setDate, openDialog, translations, membership, updateCourseMembership, isMobile, membershipIsACourse, featureToggles, hasMyJdPermissions) {
    const editHocDialog = () => openHocEditDialog(date, openDialog, membership, updateCourseMembership, translations, HOC_CONSTANT);
    const editSpecialHocDialog = () => openHocEditDialog(date, openDialog, membership, updateCourseMembership, translations, HOC_SPEC_CONSTANT);

    const itemsNewNav = membershipIsACourse && featureToggles[ONLINK_NAVIGATION_REDESIGN] ? [
        {
            title: 'EDIT',
            onClick: editHocDialog,
            variant: 'primary',
            Icon: 'icon-edit',
            requiredMyJdPermissions: MANAGE_EQUIPMENT_SETTINGS
        },
        {
            title: 'ONLINK_EDIT_SPECIAL',
            onClick: editSpecialHocDialog,
            Icon: 'icon-edit',
            requiredMyJdPermissions: MANAGE_EQUIPMENT_SETTINGS
        }
    ] : [];

    useNavBarActions(itemsNewNav);

    return (
        <div className='hoc-extra-table-options extra-table-options'>
            <div className={'hoc'}>
                <Datepicker
                    containerClassName='assignments-datepicker'
                    dateFormat='LL'
                    large={true}
                    locale={getLanguagePreference()}
                    onChange={setDate}
                    selected={date}
                />
            </div>
            {membershipIsACourse && !featureToggles[ONLINK_NAVIGATION_REDESIGN] && hasMyJdPermissions && getHOCButtons(
                isMobile,
                date,
                openDialog,
                membership,
                updateCourseMembership,
                translations,
                editHocDialog,
                editSpecialHocDialog
            )}
        </div>
    );
}

function initializeState() {
    const [date, setDate] = React.useState(() => moment());
    const [hoc, setHoc] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => true);

    return {
        date,
        setDate,
        hoc,
        setHoc,
        loading,
        setLoading
    };
}

function getHocBasedOnMembershipType(hoc, hocSpecial, translations, membershipIsACourse, unitOfMeasure, featureToggles) {
    if (membershipIsACourse) {
        return {
            hocValue: getHocValue(hoc, translations),
            hocSpecialValue: getHocValue(hocSpecial, translations)
        };
    }

    const sharedConversionConfig = {
        dataUnit: INCHES,
        featureToggles,
        translations,
        unitOfMeasure
    };

    return {
        hocValue: convertToFormattedUoM(hoc?.hocValue, sharedConversionConfig),
        hocSpecialValue: convertToFormattedUoM(hocSpecial?.hocValue, sharedConversionConfig)
    };
}

function getFormattedHOCData(equipmentAreas, featureToggles, translations, membershipIsACourse, unitOfMeasure) {
    const hocs = equipmentAreas.filter((hoc) => !hoc.name.includes('Special'));
    const hocsSpecial = equipmentAreas.filter((hoc) => hoc.name.includes('Special'));

    const hocData = hocs.map((hoc) => {
        const hocSpecial = hocsSpecial.find((hocSpec) => hocSpec.name === `${hoc.name} Special`);

        const {
            hocValue, hocSpecialValue
        } = getHocBasedOnMembershipType(hoc, hocSpecial, translations, membershipIsACourse, unitOfMeasure, featureToggles);

        return {
            ...hoc,
            name: translations[`HOC ${hoc.name}`],
            hocValue,
            hocSpecialValue
        };
    });

    const filterHocData = hocData.filter((hoc) => hoc.hocValue || hoc.hocSpecialValue);

    return sortBy(filterHocData, 'seq');
}

function HOC(props) {
    const {
        featureToggles,
        membership,
        openDialog,
        translations,
        isMobile,
        isMigrated,
        myJdPermissions
    } = props;

    const {
        date,
        setDate,
        hoc,
        setHoc,
        loading,
        setLoading
    } = initializeState();

    const membershipIsACourse = membership.membershipType === COURSE;

    const updateHocData = () => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const formattedDate = date.format('YYYYMMDD');

        const {equipmentAreas} = await getHoc(formattedDate);

        const hocData = getFormattedHOCData(equipmentAreas, featureToggles, translations, membershipIsACourse, membership.unitOfMeasure);

        if (isMounted()) {
            setHoc(hocData);
            setLoading(false);
        }
    });

    const updateCourseMembership = ({dateSelected}) => {
        if (dateSelected.isSame(date, 'day')) {
            updateHocData();
        }
    };

    React.useEffect(updateHocData, [membership, date]);

    const hasMyJdPermissions = React.useMemo(() => {
        return isAuthorized({
            myJdPermissions: [MANAGE_EQUIPMENT_SETTINGS]
        }, {
            isMigrated,
            myJdPermissions
        });
    }, [isMigrated, myJdPermissions]);

    return (
        <DataTable
            columns={getColumns(translations)}
            filterComponent={getFilterComponent(date, setDate, openDialog, translations, membership, updateCourseMembership, isMobile, membershipIsACourse, featureToggles, hasMyJdPermissions)}
            loading={loading}
            noDataAvailableMessage={translations.ONLINK_NO_HOCS_FOR_DATE_SELECTED}
            rows={hoc}
            translations={translations}
        />
    );
}

HOC.propTypes = {
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    membership: PropTypes.object,
    myJdPermissions: PropTypes.myJdPermissions,
    openDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        featureToggles: state.account.featureToggles,
        myJdPermissions: state.account.myJdPermissions
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openDialog: (props) => {
            dispatch(openDialog(dialogTypes.HOC_EDIT_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(withRouter(HOC)));
