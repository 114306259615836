// Unpublished Work © 2023-2024 Deere & Company.

function isEmptyArray(arr) {
    return !Array.isArray(arr) || arr.length === 0;
}

function isNullOrUndefined(value) {
    return value === undefined || value === null;
}

function isEmptyString(value) {
    return isNullOrUndefined(value) || value === '';
}

function hasWhiteSpace(s) {
    return /\s/g.test(s);
}

export {
    isEmptyArray,
    isEmptyString,
    isNullOrUndefined,
    hasWhiteSpace
};
