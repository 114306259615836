// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import ValidationInput from 'Ui/components/common/form/validation-input';
import OnlinkButton from 'Ui/components/common/onlink-button';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {addToast} from 'Store/actions/toasts';
import {authenticate} from 'Services/login-service';
import {getSessionStrategy} from 'Services/session-service';
import {fetchEffectData} from 'Utils/react-utils';
import {redirect} from 'Utils/redirect-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {MY_JOHN_DEERE, ONLINK} from 'Ui/constants/replace-constants';
import {INACTIVE_ACCOUNT, UNLINKED_ACCOUNT, USER_INACTIVE_ACCESS, UNAUTHORIZED} from 'Common/constants/errors';
import {TOAST_TYPE} from '@deere/toast';

function getRedirectPath(urlParams) {
    const path = urlParams.get('redirect');

    return path ? `?redirect=${path}` : '';
}

function isInvalidAccount(urlParams, accountErrorType) {
    const errorParam = urlParams.get('error');

    return errorParam === accountErrorType;
}

async function redirectLoggedInUser(path) {
    const session = await getSessionStrategy();

    if (!isNullOrUndefined(session.strategy)) {
        redirect(`/login-redirect${path}`);
    }
}

function toOktaLogin(path, unlinkedAccount, inactiveAccount) {
    const loginPath = `/myjd-login${path}`;

    if (unlinkedAccount || inactiveAccount) {
        redirect(`/logout/myJohnDeere?target=${loginPath}`);
    } else {
        redirect(loginPath);
    }
}

function initializeState() {
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState('');

    return {
        password,
        username,
        setPassword,
        setUsername
    };
}

function initializeRefs() {
    return {
        path: React.useRef(''),
        unlinkedAccount: React.useRef(false),
        inactiveAccount: React.useRef(false)
    };
}

function addToastError(urlParams, unlinkedAccount, inactiveAccount, translations, myJDSignInMessage, addToast) {
    unlinkedAccount.current = isInvalidAccount(urlParams, UNLINKED_ACCOUNT);
    inactiveAccount.current = isInvalidAccount(urlParams, INACTIVE_ACCOUNT);

    if (unlinkedAccount.current) {
        addToast({
            message: replaceTranslationNames(translations.ONLINK_UNLINKED_ACCOUNT_ERROR, {
                '0': ONLINK,
                '1': `"${myJDSignInMessage}"`
            }),
            type: TOAST_TYPE.ERROR,
            persist: true
        });
    }

    if (inactiveAccount.current) {
        addToast({
            message: translations.ONLINK_INACTIVE_ACCOUNT_ERROR,
            type: TOAST_TYPE.ERROR,
            persist: true
        });
    }
}

function Login(props) {
    const {
        addToast,
        redirectParam,
        setValid,
        translations
    } = props;

    const {
        password,
        username,
        setPassword,
        setUsername
    } = initializeState();

    const {
        path,
        unlinkedAccount,
        inactiveAccount
    } = initializeRefs();

    const myJDSignInMessage = replaceTranslationNames(translations.ONLINK_MYJOHNDEERE_SIGNIN, {
        '0': MY_JOHN_DEERE
    });

    React.useEffect(() => fetchEffectData(async () => {
        const urlParams = new URLSearchParams(redirectParam);

        path.current = getRedirectPath(urlParams);

        await redirectLoggedInUser(path.current);

        addToastError(urlParams, unlinkedAccount, inactiveAccount, translations, myJDSignInMessage, addToast);
    }), []);

    const onSave = React.useCallback(async (event) => {
        event.preventDefault();

        const payload = {
            email: username,
            password
        };

        try {
            await authenticate(payload);

            document.forms.login.submit();
        } catch (e) {
            const unauthorizedMessageOrDefault = e.response?.data?.statusCode === UNAUTHORIZED ?
                translations.LOGIN_FAIL_INVALID_CREDENTIALS : e.response?.data?.message;
            const message = e.response?.data?.message === USER_INACTIVE_ACCESS ?
                translations.ONLINK_INACTIVE_ACCOUNT_ERROR : unauthorizedMessageOrDefault;

            addToast({
                message,
                type: TOAST_TYPE.ERROR
            });
        }
    }, [password, username]);

    const myJohnDeereLogin = (
        <>
            <div className='login-or'>{translations.Or}</div>
            <div className='okta-login'>
                <span className='okta-login-message'>
                    {
                        replaceTranslationNames(translations.ONLINK_MYJOHNDEERE_LOGIN_MESSAGE, {
                            '0': MY_JOHN_DEERE
                        })
                    }
                </span>
                <OnlinkButton
                    className='primary okta-login-btn'
                    onClick={() => toOktaLogin(path.current, unlinkedAccount.current, inactiveAccount.current)}
                >
                    {myJDSignInMessage}
                </OnlinkButton>
            </div>
        </>
    );

    return (
        <div className='onlink-tile login-tile'>
            <h1>{translations.ONLINK_WELCOME}</h1>
            <form
                action={`/login${path.current}`}
                className='login-form'
                method='post'
                name='login'
                onSubmit={onSave}
            >
                <ValidationInput
                    errors={{}}
                    label={translations.ONLINK_USERNAME}
                    name='username'
                    onChange={(e) => setUsername(e.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='email'
                    value={username}
                />
                <ValidationInput
                    errors={{}}
                    label={translations.ONLINK_PASSWORD}
                    name='password'
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                    setValid={setValid}
                    tabIndex={0}
                    type='password'
                    value={password}
                />
                <OnlinkButton
                    className='primary login-btn'
                    type='submit'
                >
                    {
                        replaceTranslationNames(translations.ONLINK_SIGNIN, {
                            '0': ONLINK
                        })
                    }
                </OnlinkButton>
            </form>
            {myJohnDeereLogin}
        </div>
    );
}

Login.propTypes = {
    addToast: PropTypes.func,
    redirectParam: PropTypes.string,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToast(value));
        }
    };
}

export default connect(null, mapDispatchToProps)(FormValidator(Login));
