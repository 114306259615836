// Unpublished Work © 2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Accordion, EllipsisOverflow} from '@deere/isg.component-library';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {formatTime} from 'Utils/time-utils';
import {THIRTY_DAYS} from 'Ui/constants/time-constants';
import moment from 'moment';

const MAX_DATE_KEYS = 3;

function addPinLocationFromWorkboardToMap(pinLocationHistoryMap, isNewDateSelect, {
    dateSelect,
    pinLocation
}) {
    if (!pinLocation) {
        return;
    }

    if (isNewDateSelect) {
        pinLocationHistoryMap.set(dateSelect, []);
    }

    pinLocationHistoryMap.get(dateSelect).push(pinLocation);
}

function createPinLocationElements(pinLocationHistoryMap) {
    const pastPinLocations = [];

    for (const [dateSelect, pinLocationsForDateSelect] of pinLocationHistoryMap) {
        pastPinLocations.push(
            <Grid
                container={true}
                key={dateSelect}
            >
                <Grid
                    item={true}
                    xs={4}
                >
                    {formatTime(dateSelect, 'MM/DD')}
                </Grid>
                <Grid
                    component={EllipsisOverflow}
                    item={true}
                    tooltipProps={{
                        placement: 'right'
                    }}
                    xs={8}
                >
                    {pinLocationsForDateSelect.join(', ')}
                </Grid>
            </Grid>
        );
    }

    return pastPinLocations;
}

function PinLocationInput(props) {
    const {
        onChange,
        readOnly,
        setValid,
        translations,
        values,
        workboards
    } = props;

    const pinLocationElements = React.useMemo(() => {
        const pinLocationHistoryMap = new Map();
        const thirtyDaysAgo = moment().subtract(THIRTY_DAYS, 'days');

        for (let i = workboards.length - 1; i >= 0; i--) {
            const workboard = workboards[i];
            const isNewDateSelect = !pinLocationHistoryMap.has(workboard.dateSelect);

            if (pinLocationHistoryMap.size >= MAX_DATE_KEYS && isNewDateSelect ||
                thirtyDaysAgo.isAfter(workboard.dateSelect, 'day')) {
                break;
            }

            addPinLocationFromWorkboardToMap(pinLocationHistoryMap, isNewDateSelect, workboard);
        }

        return createPinLocationElements(pinLocationHistoryMap);
    }, [workboards]);

    return (
        <Grid
            container={true}
            sx={{
                marginLeft: 'auto',
                marginTop: '8px'
            }}
        >
            <Grid
                item={true}
                xs={5}
            >
                <ReadOnlyWrapperComponent
                    label={translations.PRO_GOLF_PIN_LOCATION}
                    readOnly={readOnly}
                    readOnlyProps={{
                        readOnlyLabelClassName: 'title-1',
                        readOnlyValueClassName: 'workboard-details-readonly-value'
                    }}
                    style={{
                        width: '100%'
                    }}
                    value={values.pinLocation || ''}
                    wrappedComponent={ValidationInput}
                    wrappedProps={{
                        errors: {
                            tooLong: translations.PRO_GOLF_PIN_LOCATION_TOO_LONG_ERROR
                        },
                        maxLength: 2,
                        name: 'pinLocation',
                        onChange,
                        setValid,
                        tabIndex: 0,
                        type: 'text'
                    }}
                />
            </Grid>
            <Grid
                item={true}
                sx={{
                    marginTop: '27px',
                    paddingLeft: '1rem'
                }}
                xs={7}
            >
                <Accordion
                    className='borderless-accordion slim-accordion'
                    classes={{
                        root: 'accordion-root'
                    }}
                    elevation={0}
                    headerTitle={translations.PRO_GOLF_PIN_LOCATION_HISTORY}
                >
                    <Stack
                        direction='column'
                        spacing={3}
                        sx={{
                            padding: '6px 12px'
                        }}
                    >
                        {pinLocationElements.length > 0 ? pinLocationElements : translations.ONLINK_NO_HISTORY_30_DAYS}
                    </Stack>
                </Accordion>
            </Grid>
        </Grid>
    );
}

PinLocationInput.propTypes = {
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object,
    workboards: PropTypes.arrayOf(PropTypes.object)
};

export default PinLocationInput;
