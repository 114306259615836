// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {fetchEffectData} from 'Utils/react-utils';
import {redirect} from 'Utils/redirect-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {getMyJDProfile} from 'Services/my-jd-user-service';
import {MY_JOHN_DEERE, ONLINK} from 'Ui/constants/replace-constants';

function LinkError(props) {
    const {
        onLinkMembership,
        redirectParam,
        translations
    } = props;

    const [myJDName, setMyJDName] = React.useState('');

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const {user} = await getMyJDProfile();

        if (isMounted()) {
            setMyJDName(user.accountName);
        }
    }), []);

    const splitAlreadyLinkedMessage = React.useMemo(() => {
        const alreadyLinkedMessage = replaceTranslationNames(translations.ONLINK_MY_JD_ACCOUNT_ALREADY_LINKED_IN_MEMBERSHIP, {
            '0': MY_JOHN_DEERE,
            '2': ONLINK,
            '3': onLinkMembership
        });

        return alreadyLinkedMessage.split('{1}');
    }, [onLinkMembership, translations.ONLINK_MY_JD_ACCOUNT_ALREADY_LINKED_IN_MEMBERSHIP]);
    const selectDifferentAccount = React.useMemo(() => replaceTranslationNames(translations.ONLINK_SELECT_DIFFERENT_ACCOUNT, {
        '0': MY_JOHN_DEERE
    }), [translations.ONLINK_SELECT_DIFFERENT_ACCOUNT]);

    return (
        <div className='link-profile-prompt'>
            <div className='prompt-message'>
                {
                    splitAlreadyLinkedMessage.length > 1 &&
                    <>
                        {splitAlreadyLinkedMessage[0]}
                        <b>{myJDName}</b>
                        {splitAlreadyLinkedMessage[1]}
                    </>
                }
            </div>
            <div className='prompt-message'>
                {selectDifferentAccount}
            </div>
            <div className='prompt-actions link-error-buttons'>
                <OnlinkButton onClick={() => redirect(`/myjd-cancel${redirectParam}`)}>
                    {translations.CANCEL}
                </OnlinkButton>
                <OnlinkButton
                    className='primary'
                    onClick={() => redirect(`${window.props.myjdLogoutUrl}?TARGET=${window.props.myjdRedirectLocation}/myjd-register`)}
                >
                    {translations.ONLINK_LINK_DIFFERENT_ACCOUNT}
                </OnlinkButton>
            </div>
        </div>
    );
}

LinkError.propTypes = {
    onLinkMembership: PropTypes.string,
    redirectParam: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        onLinkMembership: state.membership.name
    };
}

export default connect(mapStateToProps)(LinkError);
