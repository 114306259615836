// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {joinClassNames} from 'Utils/html-utils';
import {IconAdd} from '@deere/icons';

const ICON_ADD_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

function AddButton(props) {
    return (
        <div className='extra-table-options right-aligned'>
            <OnlinkButton
                className={joinClassNames(props.className, 'primary')}
                disabled={props.disabled}
                leftIcon={<IconAdd iconAdd={ICON_ADD_STYLE}/>}
                onClick={props.onClick}
            >
                {props.label}
            </OnlinkButton>
        </div>
    );
}

AddButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func
};

export default AddButton;
