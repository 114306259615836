// Unpublished Work © 2020-2024 Deere & Company.

const EQUIPMENT_1_ID = 'be4ade6b-4c17-432d-b746-496f2a2051d8';
const EQUIPMENT_2_ID = 'a3125a0f-7373-457d-981b-766f3c86e141';
const EQUIPMENT_3_ID = '8f0f8b8b-76bd-439e-b53a-aec73412ee02';
const EQUIPMENT_4_ID = 'fd3eea69-8c10-4a63-a2b3-5d5aba7c4db1';

const EQUIPMENT_1_NAME = 'equipment_1';
const EQUIPMENT_2_NAME = 'equipment_2';
const EQUIPMENT_3_NAME = 'equipment_3';
const EQUIPMENT_4_NAME = 'equipment_4';

export default {
    tilePositions: [
        {
            tile: {
                id: EQUIPMENT_1_ID,
                name: EQUIPMENT_1_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 1,
                i: EQUIPMENT_1_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_2_ID,
                name: EQUIPMENT_2_NAME
            },
            columnCount: 3,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: EQUIPMENT_2_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_3_ID,
                name: EQUIPMENT_3_NAME
            },
            columnCount: 3,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: EQUIPMENT_3_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_4_ID,
                name: EQUIPMENT_4_NAME
            },
            columnCount: 3,
            position: {
                x: 2,
                y: 0,
                w: 1,
                h: 3,
                i: EQUIPMENT_4_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_1_ID,
                name: EQUIPMENT_1_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 2,
                w: 1,
                h: 1,
                i: EQUIPMENT_1_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_2_ID,
                name: EQUIPMENT_2_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: EQUIPMENT_2_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_3_ID,
                name: EQUIPMENT_3_NAME
            },
            columnCount: 2,
            position: {
                x: 1,
                y: 0,
                w: 1,
                h: 2,
                i: EQUIPMENT_3_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_4_ID,
                name: EQUIPMENT_4_NAME
            },
            columnCount: 2,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 3,
                i: EQUIPMENT_4_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_1_ID,
                name: EQUIPMENT_1_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 6,
                w: 1,
                h: 1,
                i: EQUIPMENT_1_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_2_ID,
                name: EQUIPMENT_2_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: EQUIPMENT_2_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_3_ID,
                name: EQUIPMENT_3_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 2,
                w: 1,
                h: 2,
                i: EQUIPMENT_3_NAME
            }
        },
        {
            tile: {
                id: EQUIPMENT_4_ID,
                name: EQUIPMENT_4_NAME
            },
            columnCount: 1,
            position: {
                x: 0,
                y: 4,
                w: 1,
                h: 3,
                i: EQUIPMENT_4_NAME
            }
        }
    ]
};
