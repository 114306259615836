// Unpublished Work © 2020-2024 Deere & Company.

export const SET_USER_VARIABLES = 'SET_USER_VARIABLES';
export const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';
export const SET_HAS_MY_JD_SESSION = 'SET_HAS_MY_JD_SESSION';
export const SET_MY_JD_ID = 'SET_MY_JD_ID';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_AVAILABLE_LAYOUTS = 'SET_AVAILABLE_LAYOUTS';
export const SET_ACTIVE_LAYOUT = 'SET_ACTIVE_LAYOUT';
export const SET_MYJD_PERMISSIONS = 'SET_MYJD_PERMISSIONS';
export const SET_MYJD_USER_ORGS = 'SET_MYJD_USER_ORGS';

export function updateUser(user) {
    return {
        type: SET_USER_VARIABLES,
        user
    };
}

export function setFeatureToggles(featureToggles) {
    return {
        type: SET_FEATURE_TOGGLES,
        featureToggles
    };
}

export function setMyJdId(myJdId) {
    return {
        type: SET_MY_JD_ID,
        myJdId
    };
}

export function setHasMyJDSession(hasMyJDSession) {
    return {
        type: SET_HAS_MY_JD_SESSION,
        hasMyJDSession
    };
}

export function setMyJdUserOrgs(myJdUserOrgs) {
    return {
        type: SET_MYJD_USER_ORGS,
        myJdUserOrgs
    };
}

export function setPermissions(permissions) {
    return {
        type: SET_PERMISSIONS,
        permissions
    };
}

export function setAvailableLayouts(availableLayouts) {
    return {
        type: SET_AVAILABLE_LAYOUTS,
        availableLayouts
    };
}

export function setActiveLayout(activeLayout) {
    return {
        type: SET_ACTIVE_LAYOUT,
        activeLayout
    };
}

export function setMyJdPermissions(myJdPermissions) {
    return {
        type: SET_MYJD_PERMISSIONS,
        myJdPermissions
    };
}
