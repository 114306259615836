// Unpublished Work © 2021-2024 Deere & Company.

import {EQUIPMENT_SEARCH_FIELDS} from 'Ui/constants/search-constants';
import {useDeepMemo, fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import {hasLatitudeAndLongitude} from 'Utils/map-utils';

function getModelNames(fleet) {
    return [...new Set(fleet.map((equipment) => equipment.modelName))];
}

function getEquipmentTypes(fleet) {
    return [...new Set(fleet.map((equipment) => equipment.equipmentType))];
}

function getFilteredFleet(fleet, modelFilterSelectedIDs, typeFilterSelectedIDs, applySearch) {
    const filteredFleet = fleet.filter(
        (equipment) =>
            modelFilterSelectedIDs.includes(equipment.modelName) &&
            typeFilterSelectedIDs.includes(equipment.equipmentType)
    );

    return applySearch(filteredFleet, EQUIPMENT_SEARCH_FIELDS);
}

function getLocationsWithMembership(fleetWithLocations, membership) {
    if (hasLatitudeAndLongitude(membership.lat, membership.lng)) {
        return [...fleetWithLocations, membership];
    }

    return fleetWithLocations;
}

export function getMemoizedData(fleet, search, modelFilterSelectedIDs, typeFilterSelectedIDs, callBacks, membership) {
    const {
        applySearch,
        updateTypeFilterSelectedIDs,
        updateModelFilterSelectedIDs
    } = callBacks;

    const modelNames = useDeepMemo(
        () => getModelNames(fleet, updateModelFilterSelectedIDs),
        [fleet]
    );

    useDeepEffect(() => fetchEffectData((isMounted) => {
        if (isMounted()) {
            updateModelFilterSelectedIDs(modelNames);
        }
    }), [modelNames]);

    const equipmentTypes = useDeepMemo(
        () => getEquipmentTypes(fleet, updateTypeFilterSelectedIDs),
        [fleet]
    );

    useDeepEffect(() => fetchEffectData((isMounted) => {
        if (isMounted()) {
            updateTypeFilterSelectedIDs(equipmentTypes);
        }
    }), [equipmentTypes]);

    const filteredFleet = useDeepMemo(
        () => getFilteredFleet(fleet, modelFilterSelectedIDs, typeFilterSelectedIDs, applySearch),
        [fleet, modelFilterSelectedIDs, search, typeFilterSelectedIDs]
    );

    const fleetWithLocations = useDeepMemo(
        () => filteredFleet.filter((equipment) => hasLatitudeAndLongitude(equipment.lat, equipment.lon)),
        [filteredFleet]
    );

    const fleetWithLocationsAndMembership = useDeepMemo(
        () => getLocationsWithMembership(fleetWithLocations, membership),
        [fleetWithLocations, membership]
    );

    return {
        equipmentTypes,
        filteredFleet,
        fleetWithLocations,
        modelNames,
        fleetWithLocationsAndMembership
    };
}
