// Unpublished Work © 2022-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

import {getMembershipHierarchy, getRootMembershipOfCurrentlySelectedMembership} from 'Services/membership-service';
import {ONLINK_MEMBERSHIP_ID} from 'Ui/constants/membership-properties';

async function getRootMembershipIdForSelection(superUser, appUserMembershipId) {
    if (superUser) {
        const {rootMembershipId} = await getRootMembershipOfCurrentlySelectedMembership();

        return rootMembershipId;
    }

    return appUserMembershipId;
}

async function getHierarchy(superUser, appUserMembershipId) {
    const rootMembershipIdOfSelection = await getRootMembershipIdForSelection(superUser, appUserMembershipId);

    if (rootMembershipIdOfSelection && rootMembershipIdOfSelection !== ONLINK_MEMBERSHIP_ID) {
        const {hierarchy} = await getMembershipHierarchy(rootMembershipIdOfSelection);

        return hierarchy;
    }

    return {};
}

function addHierarchyOfMembershipsToList(parentName, membership, equipmentMembershipId) {
    const parentSection = parentName ? `${parentName} / ` : '';
    const title = `${parentSection}${membership.membershipName}`;

    const results = membership.children ? membership.children.flatMap((childMembership) => {
        return addHierarchyOfMembershipsToList(title, childMembership, equipmentMembershipId);
    }) : [];

    if (membership.membershipId !== equipmentMembershipId) {
        results.push({
            id: membership.membershipId,
            title
        });
    }

    return results;
}

export {
    getHierarchy,
    addHierarchyOfMembershipsToList
};
