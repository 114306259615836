// Unpublished Work © 2023-2024 Deere & Company.

import {isEmptyString} from 'Common/utils/validation-utils';

function compareMissingValues(a, b) {
    const isADefined = !isEmptyString(a);
    const isBDefined = !isEmptyString(b);

    if (isADefined && isBDefined) {
        return 0;
    } else if (isADefined) {
        return -1;
    } else if (isBDefined) {
        return 1;
    }

    return 0;
}

export {
    compareMissingValues
};
