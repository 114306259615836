// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import GraphDateFilter from 'Ui/components/graph/common/graph-date-filter';
import GraphFilter from 'Ui/components/graph/common/graph-filter';
import {useDeepMemo} from 'Utils/react-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {
    BY_EQUIPMENT,
    BY_EQUIPMENT_TYPE,
    BY_MODEL,
    COST_BY_EQUIPMENT_AREA,
    COST_BY_EQUIPMENT_MODEL,
    COST_BY_EQUIPMENT_TYPE,
    DOWNTIME_HOURS,
    EQUIPMENT_COST,
    EQUIPMENT_UPTIME,
    INVENTORIES,
    LIFETIME,
    UPTIME_HOURS,
    UPTIME_VS_DOWNTIME
} from 'Ui/components/graph/constants/graph-filters';
import {sortBy} from 'lodash';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {VIEW_EQUIPMENT_DETAILS, VIEW_FINANCIAL_DATA} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {connect} from 'react-redux';

const STANDARD_WIDTH = 200;
const MIN_YEAR = 2015;

function equipmentToEquipmentTypeItem(equipment) {
    return {
        id: equipment.equipmentTypeId,
        title: equipment.equipmentType
    };
}

const EQUIPMENT_TO_ITEM_CALLBACKS = {
    [COST_BY_EQUIPMENT_AREA]: (equipment) => ({
        id: equipment.equipmentAreaId,
        title: equipment.equipmentArea
    }),
    [COST_BY_EQUIPMENT_MODEL]: (equipment) => ({
        id: equipment.modelId,
        title: equipment.modelName
    }),
    [COST_BY_EQUIPMENT_TYPE]: equipmentToEquipmentTypeItem,
    [EQUIPMENT_COST]: equipmentToEquipmentTypeItem
};

const FILTER_WIDTHS = {
    [EQUIPMENT_COST]: 350,
    [LIFETIME]: 250
};

function getUniqueEquipmentItems(fleetEquipment, equipmentToItemCallback) {
    const uniqueEquipmentItems = fleetEquipment.reduce((equipmentMap, equipment) => {
        const equipmentItem = equipmentToItemCallback(equipment);

        if (!isNullOrUndefined(equipmentItem.id)) {
            equipmentMap.set(equipmentItem.id, equipmentItem);
        }

        return equipmentMap;
    }, new Map());

    const equipmentItemArr = Array.from(uniqueEquipmentItems.values());

    return sortBy(equipmentItemArr, 'title');
}

function getPrimaryFilterData(timeScale, translations, myJdPermissions, isMigrated) {
    const permissionsFilter = (options) => {
        return options.filter((option) => {
            return option.myJdPermissions.every((jdPermission) => isAuthorized({
                myJdPermissions: jdPermission
            }, {
                myJdPermissions,
                isMigrated
            }));
        });
    };

    switch (timeScale) {
        case LIFETIME:
            return permissionsFilter([
                {
                    id: COST_BY_EQUIPMENT_AREA,
                    title: translations.ONLINK_COST_BY_EQUIPMENT_AREA,
                    myJdPermissions: [VIEW_FINANCIAL_DATA]
                },
                {
                    id: COST_BY_EQUIPMENT_MODEL,
                    title: translations.ONLINK_COST_BY_EQUIPMENT_MODEL,
                    myJdPermissions: [VIEW_FINANCIAL_DATA]
                },
                {
                    id: COST_BY_EQUIPMENT_TYPE,
                    title: translations.ONLINK_COST_BY_EQUIPMENT_TYPE,
                    myJdPermissions: [VIEW_FINANCIAL_DATA]
                },
                {
                    id: INVENTORIES,
                    title: translations.ONLINK_INVENTORY_BY_PART_TYPE,
                    myJdPermissions: [VIEW_EQUIPMENT_DETAILS, VIEW_FINANCIAL_DATA]
                }
            ]);
        default:
            return permissionsFilter([
                {
                    id: EQUIPMENT_COST,
                    title: translations.ONLINK_EQUIPMENT_COST,
                    myJdPermissions: [VIEW_FINANCIAL_DATA]
                },
                {
                    id: EQUIPMENT_UPTIME,
                    title: translations.ONLINK_EQUIPMENT_UPTIME,
                    myJdPermissions: [VIEW_EQUIPMENT_DETAILS]
                }
            ]);
    }
}

function getTertiaryFilterData(primarySelector, secondarySelector, fleetEquipment, translations) {
    switch (primarySelector) {
        case EQUIPMENT_COST: {
            const filteredEquipment = fleetEquipment.filter(({equipmentTypeId}) => equipmentTypeId === secondarySelector);

            return getUniqueEquipmentItems(filteredEquipment, (equipment) => ({
                id: equipment.equipmentId,
                title: `${equipment.equipmentName} (${equipment.manufacturerName} ${equipment.modelName})`
            }));
        }
        case EQUIPMENT_UPTIME:
            return [
                {
                    id: UPTIME_VS_DOWNTIME,
                    title: translations.ONLINK_UPTIME_VS_DOWNTIME
                },
                {
                    id: UPTIME_HOURS,
                    title: translations.ONLINK_UPTIME_HOURS
                },
                {
                    id: DOWNTIME_HOURS,
                    title: translations.ONLINK_DOWNTIME_HOURS
                }
            ];
        default:
            return null;
    }
}

function getFilterWidth(isMobile, filterValue) {
    if (isMobile) {
        return STANDARD_WIDTH;
    }

    return FILTER_WIDTHS[filterValue] || STANDARD_WIDTH;
}

function EquipmentReportFilters(props) {
    const {
        fleetEquipment,
        isMobile,
        primarySelector,
        secondarySelector,
        tertiarySelector,
        timeScale,
        translations,
        updateDateFilter,
        updatePrimarySelector,
        updateSecondarySelector,
        updateTertiarySelector,
        myJdPermissions,
        isMigrated
    } = props;

    const {
        primaryFilterData,
        timeScaleItems
    } = useDeepMemo(() => ({
        primaryFilterData: getPrimaryFilterData(timeScale, translations, myJdPermissions, isMigrated),
        timeScaleItems: [
            {
                id: 'day',
                title: translations.ONLINK_DAILY_REPORT
            },
            {
                id: 'month',
                title: translations.ONLINK_MONTHLY_REPORT
            },
            {
                id: 'year',
                title: translations.ANNUAL_REPORT
            },
            {
                id: LIFETIME,
                title: translations.ONLINK_LIFETIME_REPORT,
                myJdPermissions: VIEW_FINANCIAL_DATA
            }
        ].filter((option) => {
            if (option.myJdPermissions) {
                return isAuthorized({
                    myJdPermissions: option.myJdPermissions
                }, {
                    myJdPermissions,
                    isMigrated
                });
            }
            return true;
        })
    }), [timeScale, translations]);

    const secondaryFilterData = useDeepMemo(() => {
        if (primarySelector === EQUIPMENT_UPTIME) {
            return [
                {
                    id: BY_EQUIPMENT,
                    title: translations.ONLINK_FLEET
                },
                {
                    id: BY_EQUIPMENT_TYPE,
                    title: translations.MACHINE_INSIGHTS_MACHINE_TYPES
                },
                {
                    id: BY_MODEL,
                    title: translations.MODELS
                }
            ];
        }

        const equipmentToItemCallback = EQUIPMENT_TO_ITEM_CALLBACKS[primarySelector];

        if (equipmentToItemCallback) {
            const equipmentItems = getUniqueEquipmentItems(fleetEquipment, equipmentToItemCallback);

            return equipmentItems;
        }

        return null;
    }, [fleetEquipment, primarySelector, translations]);

    const tertiaryFilterData = useDeepMemo(() => {
        return getTertiaryFilterData(primarySelector, secondarySelector, fleetEquipment, translations);
    }, [fleetEquipment, primarySelector, secondarySelector]);

    return (
        <Stack
            className='graph-filters'
            direction='row'
        >
            <GraphDateFilter
                handler={updateDateFilter}
                numMonths={24}
                numYears={new Date().getFullYear() - MIN_YEAR}
                timeScale={timeScale}
                timeScaleItems={timeScaleItems}
                translations={translations}
            />
            <GraphFilter
                handler={updatePrimarySelector}
                items={primaryFilterData}
                noSearch={true}
                selectedIds={[primarySelector]}
                single={true}
                width={getFilterWidth(isMobile, timeScale)}
            />
            {
                !isNullOrUndefined(secondaryFilterData) &&
                <GraphFilter
                    handler={updateSecondarySelector}
                    items={secondaryFilterData}
                    noSearch={false}
                    selectedIds={[secondarySelector]}
                    single={true}
                />
            }
            {
                !isNullOrUndefined(tertiaryFilterData) &&
                <GraphFilter
                    handler={updateTertiarySelector}
                    items={tertiaryFilterData}
                    noSearch={false}
                    selectedIds={[tertiarySelector]}
                    single={true}
                    width={getFilterWidth(isMobile, primarySelector)}
                />
            }
        </Stack>
    );
}

EquipmentReportFilters.propTypes = {
    fleetEquipment: PropTypes.arrayOf(PropTypes.equipment),
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    primarySelector: PropTypes.string,
    secondarySelector: PropTypes.string,
    tertiarySelector: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations,
    updateDateFilter: PropTypes.func,
    updatePrimarySelector: PropTypes.func,
    updateSecondarySelector: PropTypes.func,
    updateTertiarySelector: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(EquipmentReportFilters));
