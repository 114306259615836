// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import GraphDateFilter from 'Ui/components/graph/common/graph-date-filter';
import GraphFilter from 'Ui/components/graph/common/graph-filter';
import {SCHEDULE_STATUS, WORKBOARDS} from 'Ui/components/graph/constants/graph-filters';
import {
    ACTUAL_COST,
    ACTUAL_DURATION,
    BY_JOB_CATEGORY,
    BY_JOB_TEMPLATE,
    BY_STATUS,
    BY_TIME,
    BY_USER,
    BY_USER_STATUS,
    ESTIMATED_COST,
    ESTIMATED_DURATION,
    JOB_CATEGORIES_BY_TIME,
    JOB_TEMPLATES_BY_TIME,
    OVERTIME_COST,
    OVERTIME_DURATION,
    USERS_BY_TIME
} from 'OnLabor/report/constants/labor-report';
import {useDeepMemo} from 'Utils/react-utils';
import {sortBy} from 'lodash';
import {
    VIEW_FINANCIAL_DATA,
    MANAGE_TIMEOFF
} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {connect} from 'react-redux';

function filterDataByPermissions(graphFilters, myJdPermissions, isMigrated) {
    return graphFilters.filter((item) => isAuthorized({
        myJdPermissions: item.requiredMyJdPermissions
    }, {
        myJdPermissions,
        isMigrated
    }));
}

function getScheduleStatusFilters(secondarySelector, laborReportData) {
    if (secondarySelector === BY_STATUS) {
        const statuses = laborReportData.reduce((allStatuses, user) => {
            return Object.keys(user.optionMap).reduce((statusesForUser, status) => {
                statusesForUser.add(status);

                return statusesForUser;
            }, allStatuses);
        }, new Set());

        const sortedStatuses = sortBy(Array.from(statuses));

        return sortedStatuses.map((status) => ({
            id: status,
            title: status
        }));
    }

    const sortedUsers = sortBy(laborReportData, 'name');

    return sortedUsers.map((user) => ({
        id: user.name,
        title: user.name
    }));
}

function buildDropDownOptionsList(primarySelector, secondarySelector, laborReportData, translations) {
    if (primarySelector === SCHEDULE_STATUS) {
        return {
            secondaryFilterData: [
                {
                    id: BY_USER_STATUS,
                    title: translations.ONLINK_EMPLOYEE_DETAIL
                },
                {
                    id: BY_STATUS,
                    title: translations.ONLINK_STATUS_DETAIL
                }
            ],
            tertiaryFilterData: getScheduleStatusFilters(secondarySelector, laborReportData)
        };
    }

    return {
        secondaryFilterData: [
            {
                id: BY_JOB_CATEGORY,
                title: translations.ONLINK_JOB_CATEGORIES
            },
            {
                id: JOB_CATEGORIES_BY_TIME,
                title: translations.ONLINK_JOB_CATEGORIES_BY_TIME
            },
            {
                id: BY_JOB_TEMPLATE,
                title: translations.JOBS
            },
            {
                id: JOB_TEMPLATES_BY_TIME,
                title: translations.ONLINK_JOBS_BY_TIME
            },
            {
                id: BY_USER,
                title: translations.ONLINK_EMPLOYEES
            },
            {
                id: USERS_BY_TIME,
                title: translations.ONLINK_EMPLOYEES_BY_TIME
            },
            {
                id: BY_TIME,
                title: translations.OVERVIEW
            }
        ],
        tertiaryFilterData: [
            {
                id: ACTUAL_COST,
                title: translations.ONLINK_ACTUAL_COST,
                requiredMyJdPermissions: VIEW_FINANCIAL_DATA
            },
            {
                id: ACTUAL_DURATION,
                title: translations.ONLINK_ACTUAL_HOURS
            },
            {
                id: ESTIMATED_COST,
                title: translations.ONLINK_ESTIMATED_COST,
                requiredMyJdPermissions: VIEW_FINANCIAL_DATA
            },
            {
                id: ESTIMATED_DURATION,
                title: translations.ONLINK_EST_LABOR_HOURS
            },
            {
                id: OVERTIME_COST,
                title: translations.ONLINK_OVERTIME_COST,
                requiredMyJdPermissions: VIEW_FINANCIAL_DATA
            },
            {
                id: OVERTIME_DURATION,
                title: translations.ONLINK_OVERTIME_HOURS
            }
        ]
    };
}

function LaborReportFilters(props) {
    const {
        laborReportData,
        primarySelector,
        secondarySelector,
        tertiarySelector,
        timeScale,
        translations,
        updateDateFilter,
        updatePrimarySelector,
        updateSecondarySelector,
        updateTertiarySelector,
        setTitleDate,
        isMigrated,
        myJdPermissions
    } = props;

    const {
        primaryFilterData,
        timeScaleItems
    } = useDeepMemo(() => ({
        primaryFilterData: [
            {
                id: WORKBOARDS,
                title: translations.ONLINK_WORKBOARDS
            },
            {
                id: SCHEDULE_STATUS,
                title: translations.ONLINK_SCHEDULE_STATUS,
                requiredMyJdPermissions: MANAGE_TIMEOFF
            }
        ],
        timeScaleItems: [
            {
                id: 'day',
                title: translations.ONLINK_DAILY_REPORT
            },
            {
                id: 'month',
                title: translations.ONLINK_MONTHLY_REPORT
            },
            {
                id: 'year',
                title: translations.ANNUAL_REPORT
            }
        ]
    }), [translations]);

    const {
        secondaryFilterData,
        tertiaryFilterData
    } = useDeepMemo(
        () => buildDropDownOptionsList(primarySelector, secondarySelector, laborReportData, translations),
        [laborReportData, primarySelector, secondarySelector, translations]
    );

    return (
        <Stack
            className='graph-filters'
            direction='row'
            justifyContent='center'
        >
            <GraphDateFilter
                handler={updateDateFilter}
                numMonths={12}
                numYears={5}
                setTitleDate={setTitleDate}
                timeScale={timeScale}
                timeScaleItems={timeScaleItems}
                translations={translations}
            />
            <GraphFilter
                handler={updatePrimarySelector}
                items={filterDataByPermissions(primaryFilterData, myJdPermissions, isMigrated)}
                noSearch={true}
                selectedIds={[primarySelector]}
                single={true}
            />
            <GraphFilter
                handler={updateSecondarySelector}
                items={secondaryFilterData}
                noSearch={false}
                selectedIds={[secondarySelector]}
                single={true}
            />
            {
                secondarySelector !== BY_TIME &&
                <GraphFilter
                    handler={updateTertiarySelector}
                    items={filterDataByPermissions(tertiaryFilterData, myJdPermissions, isMigrated)}
                    noSearch={false}
                    selectedIds={[tertiarySelector]}
                    single={true}
                />
            }
        </Stack>
    );
}

LaborReportFilters.defaultProps = {
    laborReportData: []
};

LaborReportFilters.propTypes = {
    isMigrated: PropTypes.bool,
    laborReportData: PropTypes.arrayOf(PropTypes.object),
    myJdPermissions: PropTypes.myJdPermissions,
    primarySelector: PropTypes.string,
    secondarySelector: PropTypes.string,
    setTitleDate: PropTypes.func,
    tertiarySelector: PropTypes.string,
    timeScale: PropTypes.string,
    translations: PropTypes.translations,
    updateDateFilter: PropTypes.func,
    updatePrimarySelector: PropTypes.func,
    updateSecondarySelector: PropTypes.func,
    updateTertiarySelector: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(LaborReportFilters);
