// Unpublished Work © 2021-2024 Deere & Company.

export const JOHN_DEERE_MANUFACTURER_IDS = [
    '6a01747e-7e51-4d98-b50b-43629e99d947',
    '6ce673e8-d545-439d-8419-8963d8456107',
    'dbaf6053-417a-4615-9bf2-ac7cfb2f2660',
    '9bf8b1d6-686c-414b-b9a1-ddb2a9219ef5',
    '8e839789-9e62-428b-84c9-a181a5c497be',
    '3de00006-8fab-4838-b6bc-6b129cf393cc',
    '904f89b0-889b-4e96-83b9-86b6aa12a653',
    'bb18143b-cdd3-49b1-8634-0849d8814bcb',
    'd4dcfbce-0a41-4964-8635-f7bcdc9c7005'
];
