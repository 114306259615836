// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LoadingIcon from 'Ui/components/common/loading-icon';
import MyJohnDeereLogin from 'Ui/components/settings/myJohnDeere/myJohnDeere-login';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {getMyJDUserId} from 'Services/my-jd-user-service';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {MY_JOHN_DEERE} from 'Ui/constants/replace-constants';
import {redirect} from 'Utils/redirect-utils';

function MyJohnDeereLinkedAccount(props) {
    const {
        hasMyJDSession,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [myJDUser, setMyJDUser] = React.useState();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const myJDUserData = await getMyJDUserId();

        if (isMounted()) {
            setMyJDUser(myJDUserData);
            setLoading(false);
        }
    }), []);

    if (loading) {
        return (
            <div className='table-scroll'>
                <LoadingIcon
                    className='table-loading-icon center-loading-icon'
                    size='50px'
                />
            </div>
        );
    }

    if (myJDUser && !hasMyJDSession) {
        return (
            <div className='linked-account-body'>
                <MyJohnDeereLogin translations={translations}/>
            </div>
        );
    }

    return (
        <div className='linked-account-body'>
            <h2 className='linked-account-header'>
                {
                    myJDUser ?
                        replaceTranslationNames(translations.ONLINK_CURRENTLY_LINKED_MYJOHNDEERE_USER, {
                            '0': MY_JOHN_DEERE
                        }) :
                        replaceTranslationNames(translations.ONLINK_NOT_CURRENTLY_LINKED_MYJOHNDEERE_USER, {
                            '0': MY_JOHN_DEERE
                        })
                }
            </h2>
            {
                myJDUser && <div className='linked-account-userId'>{myJDUser.myjdUser}</div>
            }
            <OnlinkButton
                className='primary'
                onClick={() => redirect(myJDUser ? '/logout/myJohnDeere/myjd-link' : '/myjd-register')}
            >
                {myJDUser ? translations.ONLINK_LINK_TO_A_DIFFERENT_USER : translations.ONLINK_LINK_ACCOUNT}
            </OnlinkButton>
        </div>
    );
}

MyJohnDeereLinkedAccount.propTypes = {
    hasMyJDSession: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        hasMyJDSession: state.account.hasMyJDSession,
        translations: state.translations
    };
}

export default connect(mapStateToProps)(MyJohnDeereLinkedAccount);
