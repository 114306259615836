// Unpublished Work © 2023-2024 Deere & Company.

export const INVITE_SENT = 'inviteSent';
export const REJECTED = 'rejected';
export const NOT_INVITED = 'notInvited';
export const ACCEPTED = 'accepted';
export const ALREADY_EXISTS = 'alreadyExists';

export const STATUS_TRANSLATION_MAP = {
    [ALREADY_EXISTS]: 'ONLINK_ALREADY_EXISTS_STATUS',
    [INVITE_SENT]: 'ONLINK_INVITE_SENT',
    [REJECTED]: 'ONLINK_REJECTED',
    [NOT_INVITED]: 'ONLINK_NOT_INVITED'
};
