// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import IdleMarkers from 'Ui/components/map/location-history/idle-markers';
import MachineStatePolylines from 'Ui/components/map/location-history/machine-state-polylines';
import {machineState} from 'Common/constants/machine-state';
import {sortBy} from 'lodash';
import EquipmentInfoBox from 'Ui/components/map/elements/equipment-info-box';

const LOCATION = 'location';
const EXPECTED_MACHINE_STATES = new Set([
    machineState.IDLE,
    machineState.WORKING,
    machineState.TRANSPORT
]);

function sortByEventTimestamp(breadcrumb) {
    return new Date(breadcrumb.meta.eventTimestamp);
}

function getBreadcrumbType(breadcrumb) {
    const {machineState} = breadcrumb.meta;

    if (machineState && machineState.value) {
        const machineStateValue = machineState.value.toLowerCase();

        if (EXPECTED_MACHINE_STATES.has(machineStateValue)) {
            return machineStateValue;
        }
    }

    return LOCATION;
}

function createPolyLines(unsortedBreadcrumbsData) {
    const polylines = [];

    let currentPolyline = [];

    const breadcrumbsData = sortBy(unsortedBreadcrumbsData, sortByEventTimestamp);

    if (breadcrumbsData.length) {
        let currentPolylineType = getBreadcrumbType(breadcrumbsData[0]);

        breadcrumbsData.forEach((breadcrumb) => {
            const currentBreadcrumbType = getBreadcrumbType(breadcrumb);

            if (currentPolylineType !== currentBreadcrumbType) {
                currentPolyline.push(breadcrumb);

                polylines.push({
                    path: currentPolyline,
                    type: currentPolylineType
                });

                currentPolyline = [];
                currentPolylineType = currentBreadcrumbType;
            }

            currentPolyline.push(breadcrumb);
        });

        polylines.push({
            path: currentPolyline,
            type: currentPolylineType
        });
    }

    return polylines;
}

function MachineStateLayer(props) {
    const polylines = createPolyLines(props.breadcrumbs);
    const [idlePolylines, otherPolylines] = polylines
        .reduce(([idle, other], cur) => {
            return cur.type === machineState.IDLE ?
                [[...idle, cur], other] :
                [idle, [...other, cur]];
        }, [[], []]);

    return (
        <>
            <IdleMarkers
                polylines={idlePolylines}
                zIndex={otherPolylines.length}
            />
            {
                props.equipment.icon ?
                    <EquipmentInfoBox
                        equipment={props.equipment}
                        featureToggles={props.featureToggles}
                        key={props.equipment.serialNumber}
                        loading={false}
                        translations={props.translations}
                    /> : null
            }
            <MachineStatePolylines polylines={otherPolylines}/>
        </>
    );
}

MachineStateLayer.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape()),
    equipment: PropTypes.equipment,
    featureToggles: PropTypes.featureToggles,
    translations: PropTypes.translations
};

export default MachineStateLayer;
