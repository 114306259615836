// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {getServiceLogs, postServiceLog} from 'Services/maintenance-service';
import {fetchEffectData} from 'Utils/react-utils';
import {getLogsTotalTime} from 'Utils/time-utils';
import serviceStatusConstants from 'Ui/constants/service-status-constants';
import logStateConstants from 'OnEquip/equipment/service-form/utils/log-state-constants';
import {MILLISECONDS_PER_SECOND} from 'Ui/constants/time-constants';
import moment from 'moment';

function getTimerSection({
    activeLogType,
    disabled,
    onStatusChange,
    readOnly,
    serviceId,
    setActiveLogType,
    totalTime,
    translations
}) {
    const formattedTime = moment.utc(totalTime).format('HH:mm:ss');

    switch (activeLogType) {
        case logStateConstants.start:
        case logStateConstants.unpause:
            return (
                <>
                    {
                        !readOnly &&
                        <div className='timer-section-top-row'>
                            <OnlinkButton
                                className='timer-pause primary'
                                disabled={disabled}
                                onClick={(event) => {
                                    event.preventDefault();

                                    setActiveLogType(logStateConstants.pause);
                                    postServiceLog(serviceId, logStateConstants.pause);
                                }}
                            >
                                {translations.ONLINK_PAUSE}
                            </OnlinkButton>
                            <OnlinkButton
                                className='timer-complete primary'
                                disabled={disabled}
                                onClick={(event) => {
                                    event.preventDefault();

                                    onStatusChange(serviceStatusConstants.completed.id);
                                }}
                            >
                                {translations.COMPLETE}
                            </OnlinkButton>
                        </div>
                    }
                    <div className='bold-body-text-section-title'>
                        {formattedTime}
                    </div>
                </>
            );
        case logStateConstants.pause:
            return (
                <>
                    {
                        !readOnly &&
                        <div className='timer-section-top-row'>
                            <OnlinkButton
                                className='timer-unpause primary'
                                disabled={disabled}
                                onClick={(event) => {
                                    event.preventDefault();

                                    setActiveLogType(logStateConstants.unpause);
                                    postServiceLog(serviceId, logStateConstants.unpause);
                                }}
                            >
                                {translations.ONLINK_UNPAUSE}
                            </OnlinkButton>
                        </div>
                    }
                    <div className='bold-body-text-section-title'>
                        {formattedTime}
                    </div>
                </>
            );
        case logStateConstants.stop:
            return (
                <div className='bold-body-text-section-title'>
                    {formattedTime}
                </div>
            );
        default:
            return readOnly ? null : (
                <div className='timer-section-top-row'>
                    <OnlinkButton
                        className='timer-start primary'
                        disabled={disabled}
                        onClick={(event) => {
                            event.preventDefault();

                            setActiveLogType(logStateConstants.start);
                            onStatusChange(serviceStatusConstants.in_process.id);
                        }}
                    >
                        {translations.START}
                    </OnlinkButton>
                </div>
            );
    }
}

function Timer(props) {
    const {
        disabled,
        onStatusChange,
        readOnly,
        serviceId,
        translations
    } = props;

    const [activeLogType, setActiveLogType] = React.useState(null);
    const [totalTime, setTotalTime] = React.useState(0);
    const intervalIdRef = React.useRef();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (serviceId) {
            const {timeclockLogs} = await getServiceLogs(serviceId);

            if (isMounted()) {
                const totalTimeData = getLogsTotalTime(timeclockLogs);

                setActiveLogType(timeclockLogs[0]?.logType);
                setTotalTime(totalTimeData.asMilliseconds());
            }
        }
    }), []);

    React.useEffect(() => {
        if (activeLogType === logStateConstants.start || activeLogType === logStateConstants.unpause) {
            intervalIdRef.current = setInterval(() => {
                setTotalTime((prevTotalTime) => prevTotalTime + MILLISECONDS_PER_SECOND);
            }, MILLISECONDS_PER_SECOND);
        } else {
            clearInterval(intervalIdRef.current);
        }
        return () => clearInterval(intervalIdRef.current);
    }, [activeLogType, totalTime]);

    return (
        <div className='timer'>
            {
                getTimerSection({
                    activeLogType,
                    disabled,
                    onStatusChange,
                    readOnly,
                    serviceId,
                    setActiveLogType,
                    totalTime,
                    translations
                })
            }
        </div>
    );
}

Timer.propTypes = {
    disabled: PropTypes.bool,
    onStatusChange: PropTypes.func,
    readOnly: PropTypes.bool,
    serviceId: PropTypes.string,
    translations: PropTypes.translations
};

export default Timer;
