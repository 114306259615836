// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {useDeepMemo} from 'Utils/react-utils';

function CollapsedWorkItemInput(props) {
    const {
        collapseButton,
        collapsedTitle,
        equipment,
        orderButton,
        translations,
        workItemId
    } = props;

    const titleText = React.useMemo(
        () => collapsedTitle || translations.ONLINK_NEEDS_ASSIGNMENT,
        [collapsedTitle, translations.ONLINK_NEEDS_ASSIGNMENT]
    );

    const missingEquipment = useDeepMemo(() => equipment.some(({equipmentId}) => isNullOrUndefined(equipmentId)), [equipment]);

    const collapsedTileTitleProps = React.useMemo(() => {
        if (collapsedTitle) {
            return {};
        }

        return {
            className: 'extreme-threshold-text'
        };
    }, [collapsedTitle]);

    return (
        <Stack
            bgcolor='background.default'
            borderRadius='5px'
            direction='column'
            id={workItemId}
            margin='0 10px 10px'
            padding='8px'
        >
            <Stack direction='row'>
                {orderButton}
                <Tooltip
                    placement='bottom'
                    title={titleText}
                >
                    <Typography
                        {...collapsedTileTitleProps}
                        alignSelf='center'
                        margin={0}
                        noWrap={true}
                        overflow='hidden'
                        variant='body1'
                    >
                        {titleText}
                    </Typography>
                </Tooltip>
                {collapseButton}
            </Stack>
            {
                missingEquipment &&
                <Typography
                    className='collapsed-red-text'
                    color='warning.main'
                    fontWeight='600'
                    marginLeft='8px'
                >
                    {translations.ASSIGN_EQUIPMENT}
                </Typography>
            }
        </Stack>
    );
}

CollapsedWorkItemInput.defaultProps = {
    equipment: []
};

CollapsedWorkItemInput.propTypes = {
    collapseButton: PropTypes.node,
    collapsedTitle: PropTypes.string,
    equipment: PropTypes.arrayOf(PropTypes.equipment),
    orderButton: PropTypes.node,
    translations: PropTypes.translations,
    workItemId: PropTypes.string
};

export default CollapsedWorkItemInput;
