// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import layoutGuids from 'Common/constants/layout-guids';
import {getLayoutById} from 'Utils/layout-utils';

const DEFAULT_LAYOUT_TRANSLATION_KEYS = {
    [layoutGuids.AGRONOMY_ID]: 'AGRONOMY',
    [layoutGuids.GENERAL_ID]: 'GENERAL',
    [layoutGuids.EQUIPMENT_ID]: 'EQUIPMENT_LIST',
    [layoutGuids.PROPERTY_ID]: 'PROPERTY'
};

function getLayoutNameTranslation(layout, translations) {
    const defaultLayoutTranslationKey = DEFAULT_LAYOUT_TRANSLATION_KEYS[layout.id];

    if (defaultLayoutTranslationKey) {
        return translations[defaultLayoutTranslationKey];
    }

    return layout.name;
}

function sortByDefault(isDefaultA, isDefaultB) {
    if (!isDefaultA && isDefaultB) {
        return 1;
    }

    if (isDefaultA && !isDefaultB) {
        return -1;
    }

    return 0;
}

function sortByName(nameA, nameB) {
    if (nameA < nameB) {
        return -1;
    }

    if (nameA > nameB) {
        return 1;
    }

    return 0;
}

function sortLayouts(a, b) {
    const isDefaultA = isNullOrUndefined(a.onlinkUserId);
    const isDefaultB = isNullOrUndefined(b.onlinkUserId);

    const differentDefaults = sortByDefault(isDefaultA, isDefaultB);

    if (differentDefaults) {
        return differentDefaults;
    }

    return sortByName(a.name, b.name);
}

async function getTiles() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/userView/tiles');

    return response.data;
}

async function getUserLayouts(translations) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/users/currentUser/layouts');

    const {
        availableLayouts,
        activeLayoutId
    } = response.data;
    const translatedLayouts = availableLayouts.map((layout) => ({
        ...layout,
        name: getLayoutNameTranslation(layout, translations)
    }));

    translatedLayouts.sort(sortLayouts);
    const actualActiveLayoutId = activeLayoutId || layoutGuids.GENERAL_ID;

    return {
        ...response.data,
        availableLayouts: translatedLayouts,
        activeLayout: getLayoutById(translatedLayouts, actualActiveLayoutId)
    };
}

async function setActiveLayout(activeLayoutId, currentUserId) {
    const axiosClient = createUiAxiosClient();

    await axiosClient.put('/users/currentUserActiveLayout', {
        activeLayoutId,
        currentUserId
    });

    return activeLayoutId;
}

async function createLayout(layout) {
    const axiosClient = createUiAxiosClient();

    await axiosClient.post(
        '/users/currentUser/layouts',
        layout
    );
}

async function updateLayout(layout) {
    const axiosClient = createUiAxiosClient();

    await axiosClient.put(
        `/users/currentUser/layouts/${layout.id}`,
        layout
    );
}

async function deleteLayout(id) {
    const axiosClient = createUiAxiosClient();

    await axiosClient.delete(
        `/users/currentUser/layouts/${id}`
    );
}

export {
    getTiles,
    getUserLayouts,
    setActiveLayout,
    createLayout,
    updateLayout,
    deleteLayout
};
