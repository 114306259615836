// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import store from 'Store/store';
import {EntryWrapper} from 'Ui/components/entry';
import OnlinkThemeProvider from 'Src/ui-core/components/onlink-theme-provider';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

function InitialPage(props) {
    const {translations} = props;

    return (
        <Provider store={store}>
            <OnlinkThemeProvider translations={translations}>
                <Router>
                    <EntryWrapper translations={translations}/>
                </Router>
            </OnlinkThemeProvider>
        </Provider>
    );
}

InitialPage.propTypes = {
    translations: PropTypes.translations
};

export default InitialPage;
