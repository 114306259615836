// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import WorkBoardDialogContent from 'OnLabor/my-work/work-board-dialog/work-board-dialog-content';
import {connect} from 'react-redux';
import accounting from 'accounting';
import WorkBoardJobDetails from 'OnLabor/my-work/work-board-dialog/work-board-dialog-jobs/work-board-job-details';
import {TRUE} from 'Ui/constants/membership-properties';

const ONE_HOUR = 60.0;

function getJobDetails(workboard, translations, properties, featureToggles) {
    const {jobs} = workboard;
    const showEstimatedHours = properties.workboard_presentation_show_estimated_hours === TRUE;

    const workItems = jobs.flatMap((job) => {
        const {
            jobTemplate,
            title,
            workItems
        } = job;

        return workItems.map((workItem) => {
            const {
                appUser,
                estDuration,
                equipment,
                note,
                route,
                routeCustomDefn,
                workItemId
            } = workItem;

            return {
                appUserId: appUser?.appUserId,
                estHours: showEstimatedHours ? accounting.formatNumber(estDuration / ONE_HOUR, 1) : null,
                equipment,
                jobTemplateId: jobTemplate?.jobTemplateId,
                note,
                route,
                routeCustomDefn,
                secondaryName: jobTemplate?.secondaryName,
                title,
                user: appUser ? `${appUser.firstName} ${appUser.lastName}` : '',
                workItemId
            };
        });
    });

    if (workItems.length === 0) {
        return (
            <div className='empty-workboard-message'>
                {translations.ONLINK_NO_WORK_ASSIGNED}
            </div>
        );
    }

    return (
        <WorkBoardJobDetails
            featureToggles={featureToggles}
            properties={properties}
            translations={translations}
            workItems={workItems}
            workboard={workboard}
        />
    );
}

function WorkBoardDialogJobs(props) {
    const {
        featureToggles,
        translations,
        properties,
        workboard
    } = props;

    return (
        <WorkBoardDialogContent
            loading={false}
            translations={translations}
            workboard={workboard}
        >
            <div className={'job-card-flex'}>
                {workboard && getJobDetails(workboard, translations, properties, featureToggles)}
            </div>
        </WorkBoardDialogContent>
    );
}

WorkBoardDialogJobs.propTypes = {
    featureToggles: PropTypes.featureToggles,
    properties: PropTypes.object,
    translations: PropTypes.translations,
    workboard: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        properties: state.membership.properties
    };
}

export default connect(mapStateToProps)(WorkBoardDialogJobs);
