// Unpublished Work © 2023-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import {setActiveLayout as setActiveLayoutService} from 'Services/user-view-service';
import {setActiveLayout as setActiveLayoutRedux} from 'Store/actions/account';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {
    ACTIONS,
    ANALYTICS_EVENTS,
    CATEGORY,
    LABELS,
    trackDashboardSelection,
    trackOnLinkEvent
} from 'Utils/analytics-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {getLayoutById} from 'Utils/layout-utils';
import dialogTypes from 'Ui/components/common/dialog-types';

function LayoutSelect(props) {
    const {
        activeLayoutId,
        appUserId,
        availableLayouts,
        hasSubscription,
        isMigrated,
        history,
        openDialog,
        setActiveLayout,
        translations
    } = props;

    const layoutItems = useDeepMemo(() => availableLayouts.map((layout) => ({
        id: layout.id,
        title: layout.name
    })), [availableLayouts]);

    const displayManageDashboard = isMigrated ? appUserId && hasSubscription : appUserId;

    return (
        <MultiSelect
            footerAction={displayManageDashboard && {
                onClick() {
                    trackOnLinkEvent(ANALYTICS_EVENTS.TRACK_EVENT, {
                        category: CATEGORY.ONLINK_DASHBOARD,
                        label: LABELS.MANAGE_DASHBOARDS,
                        action: ACTIONS.DASHBOARD_MENU,
                        value: ''
                    });
                    openDialog();
                },
                title: translations.MANAGE_DASHBOARDS
            }}
            items={layoutItems}
            labels={{
                placeholder: translations.DASHBOARD
            }}
            onChange={(selectedIds) => {
                const [id] = selectedIds;

                const selectedLayout = availableLayouts.find((layout) => layout.id === id);

                trackDashboardSelection(selectedLayout);
                setActiveLayoutService(id, appUserId);
                setActiveLayout(getLayoutById(availableLayouts, id));

                history.push('/home');
            }}
            selectedIds={[activeLayoutId]}
            single={true}
            width={180}
        />
    );
}

LayoutSelect.propTypes = {
    activeLayoutId: PropTypes.string,
    appUserId: PropTypes.string,
    availableLayouts: PropTypes.arrayOf(PropTypes.layout),
    hasSubscription: PropTypes.bool,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    openDialog: PropTypes.func,
    setActiveLayout: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        activeLayoutId: state.account.activeLayout.id,
        availableLayouts: state.account.availableLayouts,
        appUserId: state.account.appUserId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setActiveLayout(value) {
            dispatch(setActiveLayoutRedux(value));
        },
        openDialog() {
            dispatch(openReduxDialog(dialogTypes.LAYOUT_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutSelect));
