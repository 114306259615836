// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {BasicDialog} from '@deere/basic-dialog';
import {TOAST_TYPE} from '@deere/toast';
import {joinClassNames} from 'Utils/html-utils';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {CONFLICT} from 'Common/constants/errors';

function FormDialog(props) {
    const {
        addToast,
        cancelLabel,
        className,
        closeHandler,
        disableSave,
        footerLoading,
        onSave,
        isMigrated,
        saveLabel,
        translations,
        ...otherProps
    } = props;

    const saveCallback = React.useCallback(async (event) => {
        try {
            await onSave(event);
        } catch (err) {
            if (err.response.status !== CONFLICT) {
                addToast({
                    message: translations.ERROR_OCCURRED_TITLE,
                    type: TOAST_TYPE.ERROR
                });
            }
        }
    }, [onSave, translations.ERROR_OCCURRED_TITLE]);

    const footerActions = [
        {
            callback: closeHandler,
            cssClasses: 'onlink-btn',
            title: cancelLabel
        },
        {
            callback: saveCallback,
            cssClasses: 'onlink-btn primary',
            loading: footerLoading,
            disabled: disableSave,
            title: saveLabel
        }
    ];

    return (
        <BasicDialog
            {...otherProps}
            className={joinClassNames('form-dialog', className, isMigrated ? 'jd-green' : '')}
            closeHandler={closeHandler}
            footerActions={footerActions}
            show={true}
        />
    );
}

FormDialog.propTypes = {
    addToast: PropTypes.func,
    cancelLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    closeHandler: PropTypes.func.isRequired,
    disableSave: PropTypes.bool,
    footerLoading: PropTypes.bool,
    isMigrated: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    saveLabel: PropTypes.string.isRequired,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        }
    };
}

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
