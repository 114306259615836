// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AddButton from 'Ui/components/settings/common/add-button';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {alphaNumericCompare} from 'Ui/models/maintenance';
import {getParts} from 'Services/inventory-service';
import {getInventories} from 'Services/membership-service';
import {deletePart} from 'Services/parts-service';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {fetchEffectData, useLazyRef} from 'Utils/react-utils';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import dialogTypes from 'Ui/components/common/dialog-types';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {MANAGE_EQUIPMENT_SETTINGS} from 'Common/constants/business-activities';

function isPartModifiable(inventoryId, membershipId, inventoryToMembershipMap, superUser) {
    return superUser || inventoryToMembershipMap.current.get(inventoryId) === membershipId;
}

function getColumns({
    inventories,
    inventoryToMembershipMap,
    membershipId,
    onPartSave,
    openPartDialog,
    superUser,
    translations,
    allParts
}) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            sortMethod: alphaNumericCompare,
            className: 'sticky',
            headerClassName: 'sticky',
            Cell(row) {
                const {
                    original,
                    value
                } = row;

                if (isPartModifiable(original.inventoryId, membershipId, inventoryToMembershipMap, superUser)) {
                    return (
                        <EditLink
                            onClick={() => openPartDialog({
                                inventories,
                                onPartSave,
                                part: original,
                                translations,
                                allParts: allParts.filter(({partId}) => partId !== original.partId)
                            })}
                        >
                            {value}
                        </EditLink>
                    );
                }

                return value;
            }
        },
        {
            Header: translations.PART_NUMBER,
            sortMethod: alphaNumericCompare,
            accessor: 'partNumber'
        },
        {
            Header: translations.TYPE,
            sortMethod: alphaNumericCompare,
            accessor: 'partType'
        },
        {
            Header: translations.MANUFACTURER,
            sortMethod: alphaNumericCompare,
            accessor: 'manufacturerName'
        }
    ];
}

function getFilterComponent(translations, showOnlyMyParts, setShowOnlyMyParts, openPartDialog, onPartSave, inventories, loading, allParts) {
    const capitalizedParts = capitalizeFirstLetter(translations.ONLINK_ONLY_MY_PARTS);

    return (
        <div className='extra-table-options'>
            <SwitchInput
                checked={showOnlyMyParts}
                name='showOnlyMyParts'
                offLabel={`${capitalizedParts} (${translations.ONLINK_LOADING_ALL_PARTS_WARNING})`}
                onChange={() => setShowOnlyMyParts(!showOnlyMyParts)}
                translations={translations}
            />
            <AddButton
                disabled={loading}
                label={translations.ONLINK_ADD_PART}
                onClick={() => openPartDialog({
                    inventories,
                    onPartSave,
                    translations,
                    allParts
                })}
            />
        </div>
    );
}

function getUpdatedParts(parts, partIdToRemove, updatedPart) {
    const allParts = [...parts.allParts];
    const allPartIndex = allParts.findIndex(({partId}) => partId === partIdToRemove);

    updatedPart ?
        allParts.splice(allPartIndex, 1, updatedPart) :
        allParts.splice(allPartIndex, 1);

    const membershipParts = [...parts.membershipParts];
    const membershipPartIndex = membershipParts.findIndex(({partId}) => partId === partIdToRemove);

    if (membershipPartIndex > -1) {
        updatedPart ?
            membershipParts.splice(membershipPartIndex, 1, updatedPart) :
            membershipParts.splice(membershipPartIndex, 1);
    }

    return {
        allParts,
        membershipParts
    };
}

function initializeState() {
    const [inventories, setInventories] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [parts, setParts] = React.useState({
        allParts: [],
        membershipParts: []
    });
    const [showOnlyMyParts, setShowOnlyMyParts] = React.useState(true);

    return {
        inventories,
        loading,
        parts,
        setInventories,
        setLoading,
        setParts,
        setShowOnlyMyParts,
        showOnlyMyParts
    };
}

function PartsSettings(props) {
    const {
        addToast,
        closeConfirmation,
        membershipId,
        openConfirmation,
        openPartDialog,
        superUser,
        translations
    } = props;

    const {
        inventories,
        loading,
        parts,
        setInventories,
        setLoading,
        setParts,
        setShowOnlyMyParts,
        showOnlyMyParts
    } = initializeState();

    const inventoryToMembershipMap = useLazyRef(() => new Map());

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const [{parts: partsData}, {inventories: inventoriesData}] = await Promise.all([
            getParts(),
            getInventories()
        ]);

        if (isMounted()) {
            inventoryToMembershipMap.current = inventoriesData.reduce((inventoryMembershipMap, inventory) => {
                inventoryMembershipMap.set(inventory.inventoryId, inventory.membershipId);

                return inventoryMembershipMap;
            }, new Map());

            setInventories(inventoriesData);
            setParts({
                allParts: partsData,
                membershipParts: partsData
                    .filter(({inventoryId}) => inventoryToMembershipMap.current.get(inventoryId) === membershipId)
            });
            setLoading(false);
        }
    }), []);

    function deletePartHandler(part) {
        const lowerCasePart = translations.ONLINK_PART.toLowerCase();

        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': lowerCasePart
            }),
            onCancel: closeConfirmation,
            showLoadingIcon: true,
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_PART
            }),
            async onContinue() {
                const {
                    inventoryId,
                    partId
                } = part;

                try {
                    await deletePart(partId, inventoryId);

                    const filteredParts = getUpdatedParts(parts, partId);

                    setParts(filteredParts);
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations[e.response.data.errorTranslation], {
                            '0': lowerCasePart
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }

                closeConfirmation();
            }
        });
    }

    return (
        <div className='settings-container'>
            <DataTable
                columns={getColumns({
                    inventories,
                    inventoryToMembershipMap,
                    membershipId,
                    onPartSave(editedPart) {
                        const updatedParts = getUpdatedParts(parts, editedPart.partId, editedPart);

                        setParts(updatedParts);
                    },
                    openPartDialog,
                    superUser,
                    translations,
                    allParts: parts.allParts
                })}
                defaultSorted={[{
                    desc: false,
                    id: 'name'
                }]}
                deleteFilter={(part) => isPartModifiable(part.inventoryId, membershipId, inventoryToMembershipMap, superUser)}
                deleteHandler={deletePartHandler}
                filterComponent={getFilterComponent(
                    translations,
                    showOnlyMyParts,
                    setShowOnlyMyParts,
                    openPartDialog,
                    (createdPart) => setParts({
                        allParts: [...parts.allParts, createdPart],
                        membershipParts: inventoryToMembershipMap.current.get(createdPart.inventoryId) === membershipId ?
                            [...parts.membershipParts, createdPart] :
                            [...parts.membershipParts]
                    }),
                    inventories,
                    loading,
                    parts.allParts
                )}
                loading={loading}
                noDataAvailableMessage={translations.ONLINK_NO_PARTS}
                rows={showOnlyMyParts ? parts.membershipParts : parts.allParts}
                searchable={true}
                showDeleteColumn={isAuthorized({
                    myJdPermissions: MANAGE_EQUIPMENT_SETTINGS
                }, props)}
                showPagination={true}
                translations={translations}
            />
        </div>
    );
}

PartsSettings.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openPartDialog: PropTypes.func,
    superUser: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        myJdPermissions: state.account.myJdPermissions,
        superUser: state.account.superUser,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openPartDialog(props) {
            dispatch(openDialog(dialogTypes.PART_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartsSettings);
