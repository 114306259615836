// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function getJobWithLimitedWorkboardData(jobId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/job/${jobId}`);

    return response.data;
}

async function postJob(jobData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/job', jobData);

    return response.data;
}

async function deleteJob(jobId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/job/${jobId}`);

    return response.data;
}

async function updateJob(jobId, payload) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/job/${jobId}`, payload);

    return response.data;
}

async function getJobsBetweenDates(startDate, endDate) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient
        .get('/api/jobs/between', {
            params: {
                startDate,
                endDate
            }
        });

    return response.data;
}

async function getJobsForWorkboards(workboardIds) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/jobs/workboards', {
        workboardIds
    });

    return response.data;
}

async function getJobTemplates() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/jobTemplates');

    return response.data;
}

async function createJobTemplate(jobTemplate) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/jobTemplates', jobTemplate);

    return response.data;
}

async function updateJobTemplate(jobTemplateId, jobTemplate) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/jobTemplates/${jobTemplateId}`, jobTemplate);

    return response.data;
}

async function getJobCategories() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/jobCategories');

    return response.data;
}

async function deleteJobTemplateById(jobTemplateId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/jobTemplates/${jobTemplateId}`);

    return response.data;
}

async function deleteJobCategoryById(jobCategoryId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/jobCategories/${jobCategoryId}`);

    return response.data;
}

async function createJobCategory(jobCategoryPayload) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/jobCategories', jobCategoryPayload);

    return response.data;
}

async function updateJobCategory(jobCategoryId, jobCategoryPayload) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/jobCategories/${jobCategoryId}`, jobCategoryPayload);

    return response.data;
}

export {
    getJobWithLimitedWorkboardData,
    getJobsBetweenDates,
    getJobsForWorkboards,
    getJobTemplates,
    createJobTemplate,
    updateJobTemplate,
    getJobCategories,
    deleteJobTemplateById,
    postJob,
    deleteJobCategoryById,
    createJobCategory,
    updateJobCategory,
    deleteJob,
    updateJob
};
