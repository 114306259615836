// Unpublished Work © 2023-2024 Deere & Company.

import store from 'Store/store';
import {useDeepMemo} from 'Utils/react-utils';

function useToggledMemo({
    disabledCallback,
    enabledCallback,
    toggle
}, deps = []) {
    const {account} = store.getState();
    const {[toggle]: isToggleEnabled} = account.featureToggles;

    return useDeepMemo(() => {
        return isToggleEnabled ?
            enabledCallback() :
            disabledCallback();
    }, [isToggleEnabled, ...deps]);
}

export {
    useToggledMemo
};
