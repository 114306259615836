// Unpublished Work © 2023-2024 Deere & Company.

import {JOHN_DEERE_MANUFACTURER_IDS} from 'Ui/constants/john-deere-manufacturer-ids';

function formatModels(models, equipment, includeJohnDeere = false, activeModelId) {
    const uniqueModelsMap = new Map();
    const fleetModelIds = equipment.map(({modelId}) => modelId);

    models.forEach((model) => uniqueModelsMap.set(model.modelId, model));
    const filteredDuplicateModels = Array.from(uniqueModelsMap.values());

    const mappedModels = filteredDuplicateModels
        .filter((model) => model.inactive !== true && (includeJohnDeere || !JOHN_DEERE_MANUFACTURER_IDS.includes(model.manufacturerId)) || activeModelId === model.modelId)
        .map((model) => ({
            ...model,
            id: model.modelId,
            title: `${model.modelName || ''} - ${model.manufacturerName || ''}`,
            subtitle: model.description || ''
        }));

    return {
        filteredModels: mappedModels.filter(({modelId}) => fleetModelIds.includes(modelId) || activeModelId === modelId),
        fullListModels: mappedModels
    };
}

function formatServiceTypes(serviceTypes, activeServiceTypeId) {
    return serviceTypes
        .filter(({
            inactive, serviceTypeId
        }) => !inactive || serviceTypeId === activeServiceTypeId)
        .map((serviceType) => ({
            ...serviceType,
            id: serviceType.serviceTypeId
        }));
}

function filterAndFormatServiceTaskTypes(serviceTaskTypes, serviceTaskSelectedIds) {
    return serviceTaskTypes.reduce((taskTypes, taskType) => {
        const inactiveServiceTaskIDFound = serviceTaskSelectedIds.find((inactiveServiceTask) => inactiveServiceTask.serviceTaskTypeId === taskType.serviceTaskTypeId);

        if (!taskType.inactive || inactiveServiceTaskIDFound) {
            taskTypes.push({
                ...taskType,
                id: taskType.serviceTaskTypeId,
                label: taskType.title || ''
            });
        }
        return taskTypes;
    }, []);
}

function formatParts(parts, setQuantity, quantity = 0) {
    return parts.map((part) => ({
        ...part,
        id: part.partId,
        quantity: setQuantity ? quantity : part.quantity,
        subtitle: `${part.manufacturerName || ''} - ${part.name || ''}`,
        title: part.partNumber || ''
    }));
}

export {
    formatModels,
    formatServiceTypes,
    filterAndFormatServiceTaskTypes,
    formatParts
};
