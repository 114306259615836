// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import BasicTable from 'Ui/components/common/basic-table/basic-table';
import {formatNumberWithUnit} from 'Utils/data-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {getValueWithThreshold} from 'Utils/threshold-utils';
import {formatLocalizedTime, TIME_UNIT_OPTIONS} from 'Utils/time-utils';
import {convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {getUnitOfMeasureOverride} from 'Common/utils/units/unit-converter';
import {DEGREES_F, INCHES, MILES_PER_HOUR} from 'Common/constants/data-unit-constants';
import {WSPD} from 'Common/constants/data-group-constants';
import moment from 'moment';

function createDataTableRow(label, value) {
    return [
        `${label}:`,
        value
    ];
}

function getFormattedValues({
    dewpt,
    featureToggles,
    precip1hour,
    precip24hour,
    tempMax24hour,
    tempMin24hour,
    translations,
    wspd,
    unitOfMeasure,
    unitOfMeasureOverrides
}) {
    const sharedConversionConfig = {
        featureToggles,
        translations,
        unitOfMeasure
    };

    const tempConversionConfig = {
        ...sharedConversionConfig,
        dataUnit: DEGREES_F
    };

    const inchesConversionConfig = {
        ...sharedConversionConfig,
        dataUnit: INCHES
    };

    const windSpeedUnitOfMeasure = useDeepMemo(
        () => getUnitOfMeasureOverride(unitOfMeasureOverrides, unitOfMeasure, WSPD),
        [unitOfMeasure, unitOfMeasureOverrides]
    );

    const mphConversionConfig = {
        ...sharedConversionConfig,
        dataUnit: MILES_PER_HOUR,
        unitOfMeasure: windSpeedUnitOfMeasure
    };

    return {
        convertedDewpt: convertToFormattedUoM(dewpt, tempConversionConfig),
        convertedPrecip1Hour: convertToFormattedUoM(precip1hour, inchesConversionConfig),
        convertedPrecip24Hour: convertToFormattedUoM(precip24hour, inchesConversionConfig),
        convertedTempMax24hour: convertToFormattedUoM(tempMax24hour, tempConversionConfig),
        convertedTempMin24hour: convertToFormattedUoM(tempMin24hour, tempConversionConfig),
        convertedWspd: convertToFormattedUoM(wspd, mphConversionConfig)
    };
}

function formatValues(props) {
    const {
        altimeter,
        dewpt,
        precip24hour,
        rh,
        skyCover,
        sunrise,
        sunset,
        thresholds,
        translations,
        wdirCardinal,
        wspd
    } = props;

    const {
        convertedDewpt,
        convertedPrecip1Hour,
        convertedPrecip24Hour,
        convertedTempMax24hour,
        convertedTempMin24hour,
        convertedWspd
    } = getFormattedValues(props);

    const thresholdMap = thresholds.reduce((thresholdDataMap, threshold) => {
        thresholdDataMap.set(threshold.dataType, threshold);

        return thresholdDataMap;
    }, new Map());

    const dewptSpan = getValueWithThreshold(thresholdMap.get('dewpt'), dewpt, convertedDewpt);
    const precip24hourSpan = getValueWithThreshold(thresholdMap.get('precip_24hour'), precip24hour, convertedPrecip24Hour);
    const rhSpan = getValueWithThreshold(
        thresholdMap.get('rh'),
        rh,
        formatNumberWithUnit(rh, 'percent', 0)
    );
    const skyCoverSpan = getValueWithThreshold(
        thresholdMap.get('sky_cover'),
        skyCover,
        formatNumberWithUnit(skyCover, 'percent', 0)
    );
    const wspdSpan = getValueWithThreshold(thresholdMap.get('wspd'), wspd, `${convertedWspd} (${wdirCardinal})`);

    return {
        altimeter: `${altimeter} ${translations.weather_hg}`,
        dewpt: dewptSpan,
        precip1hour: convertedPrecip1Hour,
        precip24hour: precip24hourSpan,
        rh: rhSpan,
        skyCover: skyCoverSpan,
        sunrise: formatLocalizedTime(moment(sunrise, 'LT'), TIME_UNIT_OPTIONS.minute),
        sunset: formatLocalizedTime(moment(sunset, 'LT'), TIME_UNIT_OPTIONS.minute),
        tempMax24hour: convertedTempMax24hour,
        tempMin24hour: convertedTempMin24hour,
        wspd: wspdSpan
    };
}

function CurrentFigures(props) {
    const {translations} = props;
    const {
        altimeter,
        dewpt,
        precip1hour,
        precip24hour,
        rh,
        skyCover,
        sunrise,
        sunset,
        tempMax24hour,
        tempMin24hour,
        wspd
    } = formatValues(props);

    return (
        <BasicTable
            className='current-figures'
            rows={[
                createDataTableRow(
                    `${translations.WEATHER_HIGH} / ${translations.WEATHER_LOW}`,
                    `${tempMax24hour} / ${tempMin24hour}`
                ),
                createDataTableRow(translations.weather_WIND, wspd),
                createDataTableRow(translations.RelativeHumidity_prcnt, rh),
                createDataTableRow(translations.WEATHER_DEW_POINT, dewpt),
                createDataTableRow(`${translations.weather_PRECIPITATION} (${translations.LAST_HOUR})`, precip1hour),
                createDataTableRow(`${translations.weather_PRECIPITATION} (${translations.LAST_24_HOURS})`, precip24hour),
                createDataTableRow(translations.PRESSURE, altimeter),
                createDataTableRow(translations.ONLINK_SKY_COVER, skyCover),
                createDataTableRow(translations.ONLINK_SUNRISE, sunrise),
                createDataTableRow(translations.ONLINK_SUNSET, sunset)
            ]}
            vertical={true}
        />
    );
}

CurrentFigures.propTypes = {
    altimeter: PropTypes.number,
    dewpt: PropTypes.number,
    precip1hour: PropTypes.number,
    precip24hour: PropTypes.number,
    rh: PropTypes.number,
    skyCover: PropTypes.stringOrNumber,
    sunrise: PropTypes.string,
    sunset: PropTypes.string,
    tempMax24hour: PropTypes.number,
    tempMin24hour: PropTypes.number,
    thresholds: PropTypes.thresholds,
    translations: PropTypes.translations,
    unitOfMeasureOverrides: PropTypes.arrayOf(PropTypes.object),
    wdirCardinal: PropTypes.string,
    wspd: PropTypes.number
};

export default CurrentFigures;
