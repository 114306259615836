// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconButton, MultiSelect, TextField} from '@deere/isg.component-library';
import Stack from '@mui/material/Stack';
import {IconAdd} from '@deere/icons';
import {useDeepMemo} from 'Utils/react-utils';
import {isEmptyString} from 'Common/utils/validation-utils';
import {HOLE_ITEMS} from 'Ui/constants/labor-constants';

const ICON_ADD_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const MIN_HOLE_NUMBER = 19;

function isInvalidHole(holeValue, holeItems) {
    if (isEmptyString(holeValue)) {
        return true;
    }

    const numericHoleValue = Number(holeValue);

    return numericHoleValue < MIN_HOLE_NUMBER || !Number.isInteger(numericHoleValue) ||
        holeItems.some((holeItem) => holeItem.id === holeValue);
}

function HoleInput(props) {
    const {
        onChange,
        routeCustomDefn,
        translations
    } = props;

    const [holeItems, setHoleItems] = React.useState(() => [
        ...HOLE_ITEMS,
        ...routeCustomDefn.filter((hole) => Number(hole) >= MIN_HOLE_NUMBER).map((hole) => ({
            id: hole,
            title: hole
        }))
    ]);
    const [holeValue, setHoleValue] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const addHoleHandler = React.useCallback(() => {
        const truncatedHoleValue = Number(holeValue).toFixed(0);

        onChange([...routeCustomDefn, truncatedHoleValue]);
        setHoleItems((prevHoleItems) => [
            ...prevHoleItems,
            {
                id: truncatedHoleValue,
                title: truncatedHoleValue
            }
        ]);
        setHoleValue('');
        setOpen(false);
    }, [holeValue, onChange, routeCustomDefn]);

    const addIcon = React.useMemo(() => <IconAdd iconAdd={ICON_ADD_STYLE}/>, []);

    const addHoleInput = useDeepMemo(() => (
        <Stack
            alignItems='center'
            direction='row'
        >
            <TextField
                inputProps={{
                    min: MIN_HOLE_NUMBER
                }}
                label={translations.ONLINK_HOLE}
                labelVisuallyHidden={true}
                onChange={(event) => setHoleValue(event.target.value)}
                placeholder={translations.ONLINK_ADD_HOLE}
                step={1}
                type='number'
                value={holeValue}
            />
            <IconButton
                disabled={isInvalidHole(holeValue, holeItems)}
                icon={addIcon}
                onClick={addHoleHandler}
                size='small'
                sx={{
                    marginLeft: '.8rem'
                }}
                variant='secondary'
            />
        </Stack>
    ), [addHoleHandler, holeValue, translations.ONLINK_ADD_HOLE, translations.ONLINK_HOLE]);

    return (
        <MultiSelect
            autoFocus={true}
            footerAction={addHoleInput}
            items={holeItems}
            label={translations.ONLINK_HOLES}
            labels={{
                allSelect: translations.ONLINK_ALL_HOLES,
                multiSelect: translations.ONLINK_HOLES,
                placeholder: translations.ONLINK_SELECT_HOLES,
                selectAllItems: translations.SELECT_ALL
            }}
            onChange={onChange}
            onChangeOpen={setOpen}
            open={open}
            selectedIds={routeCustomDefn}
            width={175}
        />
    );
}

HoleInput.defaultProps = {
    routeCustomDefn: []
};

HoleInput.propTypes = {
    onChange: PropTypes.func,
    routeCustomDefn: PropTypes.arrayOf(PropTypes.string),
    translations: PropTypes.translations
};

export default HoleInput;
