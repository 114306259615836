// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LegacyFooter from 'Ui/components/footer/legacy-footer';
import RedesignFooter from 'Ui/components/footer/redesign-footer';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {openDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';

function Footer(props) {
    const {
        featureToggles,
        hideContent,
        openContactUsDialog,
        openFeedbackDialog,
        openTermsAndConditionsDialog,
        translations
    } = props;

    const copyrightWithYear = React.useMemo(() => replaceTranslationNames(translations.ONLINK_FOOTER_COPYRIGHT, {
        '0': new Date().getFullYear()
    }), [translations.ONLINK_FOOTER_COPYRIGHT]);

    const FooterComponent = featureToggles[ONLINK_NAVIGATION_REDESIGN] ? RedesignFooter : LegacyFooter;

    return (
        <FooterComponent
            copyrightWithYear={copyrightWithYear}
            hideContent={hideContent}
            openContactUsDialog={openContactUsDialog}
            openFeedbackDialog={openFeedbackDialog}
            openTermsAndConditionsDialog={openTermsAndConditionsDialog}
            translations={translations}
        />
    );
}

Footer.propTypes = {
    featureToggles: PropTypes.featureToggles,
    hideContent: PropTypes.bool,
    membershipId: PropTypes.string,
    openContactUsDialog: PropTypes.func,
    openFeedbackDialog: PropTypes.func,
    openTermsAndConditionsDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openContactUsDialog(props) {
            dispatch(openDialog(dialogTypes.CONTACT_US_DIALOG, props));
        },
        openFeedbackDialog(props) {
            dispatch(openDialog(dialogTypes.FEEDBACK_DIALOG, props));
        },
        openTermsAndConditionsDialog(props) {
            dispatch(openDialog(dialogTypes.TERMS_AND_CONDITIONS_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
