// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import WidgetItem from 'OnLabor/workboard/widget/widget-item';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';

function HistoryItems(props) {
    const {
        sortedWorkItems,
        translations,
        workboardCreationFlow
    } = props;

    return sortedWorkItems.map((workItem) => {
        const {
            appUser,
            jobTemplate,
            workItemId
        } = workItem;

        const userName = appUser ? {
            text: `${appUser.firstName} ${appUser.lastName}`
        } : {
            color: 'text.secondary',
            text: translations.ONLINK_UNASSIGNED
        };

        const jobTitle = jobTemplate ? {
            text: jobTemplate.title
        } : {
            color: 'text.secondary',
            text: translations.ONLINK_UNASSIGNED
        };

        const itemsProp = workboardCreationFlow === JOB ? [userName, jobTitle] : [jobTitle, userName];

        return (
            <WidgetItem
                draggable={false}
                id={workItemId}
                items={itemsProp}
                key={workItemId}
            />
        );
    });
}

HistoryItems.propTypes = {
    sortedWorkItems: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.translations,
    workboardCreationFlow: PropTypes.string
};

export default HistoryItems;
