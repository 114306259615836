// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {IconChevronLeft, IconChevronRight} from '@deere/icons';
import Stack from '@mui/material/Stack';
import OnLinkIconButton from 'Ui/components/common/onlink-icon-button';
import {isNullOrUndefined} from 'Common/utils/validation-utils';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

function SpinnerControls(props) {
    const {
        className,
        index,
        maxIndex,
        minIndex,
        setIndex,
        text,
        hasCurrentWorkboardWidgetToggle
    } = props;

    const textAndNextButton = hasCurrentWorkboardWidgetToggle ?
        (<>
            <OnLinkIconButton
                disabled={!isNullOrUndefined(maxIndex) && index >= maxIndex}
                onClick={() => setIndex((prevIndex) => prevIndex + 1)}
            >
                <IconChevronRight iconChevronRight={ICON_STYLE}/>
            </OnLinkIconButton>
            <div className='bold-body-text-section-title'>
                {text}
            </div>
        </>) :
        (<>
            <div className='small-body-text'>
                {text}
            </div>
            <OnLinkIconButton
                disabled={!isNullOrUndefined(maxIndex) && index >= maxIndex}
                onClick={() => setIndex((prevIndex) => prevIndex + 1)}
            >
                <IconChevronRight iconChevronRight={ICON_STYLE}/>
            </OnLinkIconButton>
        </>);

    return (
        <Stack
            alignItems='center'
            className={className}
            direction='row'
            justifyContent='space-between'
            spacing={2}
        >
            <OnLinkIconButton
                disabled={!isNullOrUndefined(minIndex) && index <= minIndex}
                onClick={() => setIndex((prevIndex) => prevIndex - 1)}
            >
                <IconChevronLeft iconChevronLeft={ICON_STYLE}/>
            </OnLinkIconButton>
            {textAndNextButton}
        </Stack>
    );
}

SpinnerControls.propTypes = {
    className: PropTypes.string,
    hasCurrentWorkboardWidgetToggle: PropTypes.bool,
    index: PropTypes.number,
    maxIndex: PropTypes.number,
    minIndex: PropTypes.number,
    setIndex: PropTypes.func,
    text: PropTypes.string
};

export default SpinnerControls;
