// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import Container from 'OnEquip/common/container';
import {EquipmentContextProvider} from 'OnEquip/equipment/context/equipment-context';
import Fleet from 'Ui/pages/onequip/equipment/fleet';
import Maintenance from 'OnEquip/equipment/maintenance/maintenance';
import Assignments from 'OnEquip/equipment/assignments/assignments';
import Hoc from 'OnEquip/equipment/hoc/hoc';
import ServiceFormWrapper from 'OnEquip/equipment/service-form-wrapper';
import EquipmentDetailsWrapper from 'OnEquip/equipment/equipment-details-wrapper';
import ServiceHistory from 'OnEquip/equipment/service-history/service-history';
import Shop from 'OnEquip/equipment/shop/shop';
import {EQUIPMENT_DETAILS, FLEET, SUGGESTED_MAINTENANCE} from 'Common/constants/routes';
import {FLEET_TITLE} from 'Ui/constants/tab-header-constants';
import Helmet from 'react-helmet';
import SettingsDialog from 'Ui/components/settings/settings-dialog';
import {fetchEffectData} from 'Utils/react-utils';
import {getAvailableSettings} from 'Utils/settings-utils';

const equipmentTabs = {
    fleet: Fleet,
    assignments: Assignments,
    hoc: Hoc,
    serviceHistory: ServiceHistory,
    shop: Shop,
    suggestedMaintenance: Maintenance
};

function createFleetSettingsDialogRoute(componentPathString, settings) {
    const fleetTile = settings.find((tile) => tile.tileId === 'Fleet');

    if (!fleetTile) {
        return null;
    }

    const lowerCaseTileId = fleetTile.tileId.toLowerCase();
    const pathString = `/equipment/${componentPathString}` === SUGGESTED_MAINTENANCE ? SUGGESTED_MAINTENANCE : FLEET;
    const basePath = `${pathString}/settings/${lowerCaseTileId}`;

    return (
        <Route
            key={fleetTile.tileId}
            path={[`${basePath}/:tab`, basePath]}
        >
            <SettingsDialog
                basePath={pathString}
                tileId={`settings/${lowerCaseTileId}`}
                {...fleetTile.dialogProps}
            />
        </Route>
    );
}

function Equipment(props) {
    const {
        appKeys,
        featureToggles,
        match,
        membership,
        myJdPermissions,
        permissions,
        translations
    } = props;

    const [availableSettings, setAvailableSettings] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const settings = await getAvailableSettings(translations, membership, appKeys, permissions, myJdPermissions, featureToggles);

        if (isMounted()) {
            setAvailableSettings(settings);
        }
    }), [membership, appKeys]);

    const equipmentTabHeader = {
        fleet: translations[FLEET_TITLE],
        hoc: translations[FLEET_TITLE]
    };

    const CurrentPageComponent = equipmentTabs[match.params.type];
    const currentPageTabHeader = equipmentTabHeader[match.params.type];

    const helmet = currentPageTabHeader &&
        <Helmet>
            <title>{currentPageTabHeader}</title>
        </Helmet>;

    return (
        <EquipmentContextProvider>
            {helmet}
            <Container>
                <CurrentPageComponent
                    isMigrated={membership.isMigrated}
                    membershipId={membership.membershipId}
                    translations={translations}
                />
            </Container>
            <Switch>
                <Route
                    exact={true}
                    path='/equipment/:type/serviceForm'
                >
                    <ServiceFormWrapper translations={translations}/>
                </Route>
                <Route
                    exact={true}
                    path={`/equipment/:type${EQUIPMENT_DETAILS}/:tab`}
                >
                    <EquipmentDetailsWrapper translations={translations}/>
                </Route>
                {createFleetSettingsDialogRoute(match.params.type, availableSettings)}
            </Switch>
        </EquipmentContextProvider>
    );
}

Equipment.propTypes = {
    appKeys: PropTypes.appKeys,
    featureToggles: PropTypes.featureToggles,
    match: PropTypes.match,
    membership: PropTypes.object,
    myJdPermissions: PropTypes.myJdPermissions,
    permissions: PropTypes.legacyPermissions,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appKeys: state.account.appKeys,
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions
    };
}

export default connect(mapStateToProps)(withRouter(Equipment));
