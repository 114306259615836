// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {TextArea, Datepicker} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {onManualDataChange, validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {isEmptyString, isNullOrUndefined} from 'Common/utils/validation-utils';
import {getLanguagePreference, getCurrencySymbol} from 'Utils/unit-conversion-utils';
import {MAX_NUMERIC_VALUE, MIN_NUMERIC_VALUE} from 'Ui/constants/common-constants';
import {DOLLARS_PER_KILOWATT_HOUR} from 'Common/constants/data-unit-constants';
import moment from 'moment';

function valueOrEmptyString(value) {
    return isNullOrUndefined(value) ? '' : value;
}

function ElectricalCostEntryForm(props) {
    const {
        featureToggles,
        membership,
        setValid,
        setValues,
        translations,
        values
    } = props;

    const [validatedDate, setValidatedDate] = React.useState();
    const {
        isInvalid,
        nightRate,
        peakRate
    } = useDeepMemo(() => {
        const {manualData = [] } = values;

        const manualDataMap = manualData.reduce((manualDataByType, manualData) => {
            manualDataByType.set(manualData.data_type, manualData.value);

            return manualDataByType;
        }, new Map());

        const peakRate = manualDataMap.get('electrical_cost');
        const nightRate = manualDataMap.get('electrical_cost_night');

        return {
            isInvalid: isEmptyString(peakRate) && isEmptyString(nightRate),
            nightRate: valueOrEmptyString(nightRate),
            peakRate: valueOrEmptyString(peakRate)
        };
    }, [values.manualData]);

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    React.useEffect(() => {
        const validatedDateFromTime = validateDate(values.timeSample, setValid);

        setValidatedDate(validatedDateFromTime);
    }, [values.timeSample]);

    const currencyLabel = getCurrencySymbol({
        currencyPreference: membership.currencyPreference,
        featureToggles
    });

    const sharedInputProps = {
        debounceTimeout: window.props.debounceTimeout,
        errors: {
            customError: translations.REQUIRED_FIELD_TEXT,
            rangeOverflow: `${translations.ONLINK_VALUE_LESS_THAN_OR_EQUAL} ${MAX_NUMERIC_VALUE}`,
            rangeUnderflow: `${translations.ONLINK_VALUE_GREATER_THAN_OR_EQUAL} ${MIN_NUMERIC_VALUE}`
        },
        invalidCustomError: isInvalid,
        max: MAX_NUMERIC_VALUE,
        min: MIN_NUMERIC_VALUE,
        setValid,
        tabIndex: 0,
        type: 'number'
    };

    return (
        <div className='settings-body manual-data-form'>
            <Datepicker
                dateFormat='LL'
                error={validatedDate ? '' : translations.INVALIDATED_DATE}
                label={translations.DATE}
                large={true}
                locale={getLanguagePreference()}
                onChange={(date) => onValuesChange('timeSample', date.format(MANUAL_DATA_TIME_FORMAT))}
                onChangeRaw={(event) => onValuesChange('timeSample', moment(event.target.value).format(MANUAL_DATA_TIME_FORMAT))}
                selected={validatedDate}
            />
            <ValidationInput
                {...sharedInputProps}
                label={`${translations.ONLINK_PEAK_RATE} (${currencyLabel}/${translations.ONLINK_KWH})`}
                name='electrical_cost'
                onChange={(event) => onManualDataChange({
                    dataUnit: DOLLARS_PER_KILOWATT_HOUR,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                value={peakRate}
            />
            <ValidationInput
                {...sharedInputProps}
                label={`${translations.ONLINK_NIGHT_RATE} (${currencyLabel}/${translations.ONLINK_KWH})`}
                name='electrical_cost_night'
                onChange={(event) => onManualDataChange({
                    dataUnit: DOLLARS_PER_KILOWATT_HOUR,
                    name: event.target.name,
                    setValues,
                    value: event.target.value
                })}
                value={nightRate}
            />
            <TextArea
                label={translations.NOTES}
                name='note'
                onChange={(event) => onValuesChange('note', event.target.value)}
                tabIndex={0}
                value={values.note || ''}
            />
        </div>
    );
}

ElectricalCostEntryForm.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(ElectricalCostEntryForm);
