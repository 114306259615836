// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {useDeepMemo} from 'Utils/react-utils';
import {MultiSelect} from '@deere/form-controls';
import {sortBy} from 'lodash';

function getLowerCaseTitle(item) {
    return item.title?.toString().toLowerCase().trim();
}

function sortAlphabetically(items) {
    return sortBy(items, getLowerCaseTitle);
}

function SortedMultiSelect(props) {
    const {
        items,
        sortFunc,
        ...otherProps
    } = props;

    const sortedItems = useDeepMemo(
        () => sortFunc(items),
        [items, sortFunc]
    );

    return (
        <MultiSelect
            items={sortedItems}
            {...otherProps}
        />
    );
}

SortedMultiSelect.defaultProps = {
    sortFunc: sortAlphabetically
};

SortedMultiSelect.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    sortFunc: PropTypes.func
};

export default SortedMultiSelect;
