// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import {useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import PropTypes from 'Utils/prop-type-utils';
import PlayPauseButton from 'Ui/components/map/playback-controls/play-pause-buttons';
import {NUM_DELTAS} from 'Ui/constants/map-constants';

function reduceBreadCrumbs(breadcrumbs, breadcrumb) {
    const {
        lat,
        lng
    } = breadcrumb;

    const timeStamp = breadcrumb.meta.eventTimestamp;

    return breadcrumbs.concat({
        lat,
        lon: lng,
        timeStamp
    });
}

function getLatLng(breadcrumbs) {
    return breadcrumbs.reduce(reduceBreadCrumbs, []);
}

function PlaybackControls(props) {
    const {
        breadcrumbs,
        onChange,
        selectedEquipment
    } = props;

    const reducedBreadcrumbs = useDeepMemo(
        () => getLatLng(breadcrumbs),
        [breadcrumbs]
    );

    const [rangeIndex, setRangeIndex] = React.useState(() => {
        onChange(reducedBreadcrumbs[reducedBreadcrumbs.length - 1]);

        return reducedBreadcrumbs.length - 1;
    });

    const playbackRef = React.useRef({
        isPlaying: false,
        breadcrumbTransitionProgress: 0,
        rangeIndex
    });

    useDeepEffect(() => setRangeIndex(reducedBreadcrumbs.length - 1), [selectedEquipment]);

    function handleChange(event) {
        const trackerValue = event.target.value;

        playbackRef.current.rangeIndex = parseInt(trackerValue, 10);
        playbackRef.current.breadcrumbTransitionProgress = NUM_DELTAS;
        setRangeIndex(trackerValue);
        onChange(reducedBreadcrumbs[trackerValue]);
    }

    return (
        <div className={'trace-control'}>
            <PlayPauseButton
                playbackRef={playbackRef}
                reducedBreadcrumbs={reducedBreadcrumbs}
                setRangeIndex={setRangeIndex}
                updateMapMarker={onChange}
            />
            <input
                max={reducedBreadcrumbs.length - 1}
                min={0}
                onChange={handleChange}
                type={'range'}
                value={rangeIndex}
            />
        </div>
    );
}

PlaybackControls.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape()),
    onChange: PropTypes.func,
    selectedEquipment: PropTypes.equipment
};

export default PlaybackControls;
